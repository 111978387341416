<template>
  <div class="broadband-offerings-page">
    <template>
      <parallax-wrapper
        :direction="-1"
        axis="y"
        :speed="0.3"
        class="broadband-offerings-page__pebble-bg-wrapper"
      >
        <span class="broadband-offerings-page__pebble-bg"></span>
      </parallax-wrapper>
    </template>
    <div
      v-if="networking.pricesError"
      class="broadband-offerings-page__network-banner"
    >
      <div class="broadband-offerings-page__network-banner__inner">
        <c-icon
          class="broadband-offerings-page__network-banner__icon"
          symbol-id="service-alert"
        ></c-icon>
        {{ content.networkErrorDisclaimer }}
      </div>
    </div>
    <header
      v-if="init && hasHeaderContent"
      class="broadband-offerings-page__header"
    >
      <epi-content v-if="selectedHeaderHtml" v-html="selectedHeaderHtml" />
      <slot v-else name="header" />
    </header>
    <template>
      <!-- TODO DKT-11055: slice this into smaller, more manageable components -->
      <section
        class="
          broadband-offerings-page__wizard
          c-wrapper
          cm-o-layout cm-o-layout--main
        "
      >
        <c-row
          dir="column"
          align-items="flex-end"
          class="broadband-offerings-page__wizard-grid"
          :class="{
            'broadband-offerings-page__wizard-grid-no-offerings': !hasOfferings
          }"
        >
          <c-col
            v-if="selectedImage"
            cols="6"
            sm="12"
            class="broadband-offerings-page__gallery"
            align-self="flex-start"
          >
            <div class="broadband-offerings-page__image">
              <img v-if="selectedImage" :src="selectedImage" />
            </div>
            <product-splash
              v-if="selectedImageSplash"
              v-bind="selectedImageSplash"
              class="broadband-offerings-page__splash"
            />
            <!-- TODO DKT-10796: product-gallery styling is tightly coupled 
            with product-details page and is not reusable. We need 
            to fix this. Until we do this, we show single image. -->
            <product-gallery v-if="false" :image-urls="selectedGalleryImages" />
          </c-col>

          <c-col
            v-if="!init || (!selectedImage && offerings.length)"
            cols="6"
            sm="12"
            class="broadband-offerings-page__gallery"
            align-self="flex-start"
          >
            <skeleton-loader
              class="broadband-offerings-page__gallery__skeleton-loader"
            />
          </c-col>

          <c-col
            v-if="hasOfferings"
            :cols="hasOfferings ? 6 : 12"
            sm="12"
            class="broadband-offerings-page__heading"
          >
            <h1>
              {{ broadbandOfferingsPageHeading }}
            </h1>
          </c-col>
          <c-col
            v-if="init"
            :cols="hasOfferings ? 6 : 12"
            sm="12"
            class="broadband-offerings-page__heading"
          >
            <div class="broadband-offerings-page__heading__address">
              <c-icon
                class="broadband-offerings-page__heading__address__checkmark"
                symbol-id="service-added"
              ></c-icon>
              <span>{{ address.tekst }}</span>
              <a
                v-if="content.changeAddressLink"
                :href="content.changeAddressLink"
                >{{ content.changeAddressLinkText }}</a
              >
            </div>
          </c-col>

          <c-col
            v-if="hasOfferings"
            cols="6"
            sm="12"
            class="broadband-offerings-page__heading"
          >
            <c-information-box
              v-if="selected && selected.hasExistingBroadbandConnection"
              icon="contact-information"
              type="informative"
            >
              <strong slot="header">{{
                content.existingBroadbandAlert
                  ? ''
                  : content.existingBroadbandAlert.heading
              }}</strong>
              <p v-html="content.existingBroadbandAlert.content"></p>
            </c-information-box>
          </c-col>

          <c-col
            v-if="!init && !hasOfferings"
            cols="6"
            sm="12"
            class="broadband-offerings-page__heading"
            :class="{ 'skeleton-loader-margin': !init && !hasOfferings }"
          >
            <skeleton-loader
              class="broadband-offerings-page__heading__skeleton-loader"
            />
            <skeleton-loader
              class="
                broadband-offerings-page__heading__address__skeleton-loader
              "
            />
          </c-col>

          <c-col
            v-if="init && hasOfferings"
            cols="6"
            sm="12"
            class="broadband-offerings-page__technology"
          >
            <c-carousel
              v-bind="technologyCarouselOptions"
              class="broadband-offerings-page__technology-carousel"
              @closeTongue="closeTongue = true"
            >
              <template slot="slide" slot-scope="{ data: offering }">
                <subscription-card
                  class="broadband-offerings-page__technology-card"
                  :value="offering.code"
                  :selected="selectedCode"
                  :title="offering.variant.displayName"
                  :description="offering.variant.shortDescription"
                  :price-text="`${offering.price.monthly.formattedValueCurrency}`"
                  :action-selected-text="content.selectedButtonText"
                  :action-text="content.selectButtonText"
                  :tongue-trigger-text="content.technologyCardTongueText"
                  :is-vas-display-disabled="isVasDisplayDisabled"
                  :vas="offering.vas"
                  :close-tongue="closeTongue"
                  :ribbon-text="offering.variant.ribbon"
                  :badge-text="
                    getVariantPropsWhenAvailable(offering.variant, 'text')
                  "
                  @change="select(offering.code)"
                  @collapsed="closeTongue = false"
                >
                  <template slot="attr">
                    <!-- TODO DKT-10799: use typography component instead -->
                    <div class="broadband-offerings-page__technology-speed">
                      {{
                        getVariantPropsWhenAvailable(offering.variant, 'speed')
                      }}
                      <span>{{
                        getVariantPropsWhenAvailable(offering.variant, 'unit')
                      }}</span>
                    </div>
                  </template>
                </subscription-card>
              </template>
            </c-carousel>
          </c-col>

          <c-col
            v-if="!init && !hasOfferings"
            cols="6"
            sm="12"
            class="broadband-offerings-page__technology"
          >
            <skeleton-loader
              class="
                broadband-offerings-page__technology-carousel__skeleton-loader
              "
            />
          </c-col>

          <c-col
            v-if="
              hasOfferings &&
              content.fiveGFallBackToggle &&
              selected &&
              selected.broadband5GFallback
            "
            cols="6"
            sm="12"
            class="broadband-offerings-page__5G-fallback-disclaimer"
          >
            <c-information-box icon="contact-information" type="informative">
              <strong slot="header">
                {{ content.fiveGFallBackDisclaimerHeadLine }}
              </strong>

              <p v-html="content.fiveGFallBackDisclaimerText" />
            </c-information-box>
          </c-col>

          <template v-if="selectedInstallationFees.length">
            <template v-if="init && !loading">
              <c-col
                v-for="(fee, index) in selectedInstallationFees"
                :key="index"
                cols="6"
                sm="12"
                class="broadband-offerings-page__technician"
              >
                <c-information-box
                  icon="contact-information"
                  type="informative"
                  animatein
                >
                  <strong v-if="fee.shortDescription" slot="header">{{
                    fee.shortDescription
                  }}</strong>
                  <template v-if="fee.description">
                    <p>{{ fee.description }}</p>
                    <a
                      v-if="content.feesDescriptionRedirectUrl"
                      class="broadband-offerings-page__technician-link"
                      :href="content.feesDescriptionRedirectUrl"
                      target="_blank"
                      >{{
                        fee.descriptionLink || content.feesDescriptionTrigger
                      }}</a
                    >
                  </template>
                </c-information-box>
              </c-col>
            </template>

            <c-col
              v-if="!init || loading"
              cols="6"
              sm="12"
              class="broadband-offerings-page__info"
            >
              <skeleton-loader
                id="skeleton-loader"
                class="broadband-offerings-page__info__skeleton-loader"
              />
            </c-col>
          </template>
          <c-col
            v-if="hasOfferings"
            cols="6"
            sm="12"
            class="broadband-offerings-page__info"
          >
            <product-short-description
              v-if="selected && selected.variant"
              :description="broadbandOfferingsPageDescription"
              :description-link-text="selected.variant.shortDescriptionLinkText"
            />
          </c-col>
        </c-row>
      </section>
      <section
        v-if="
          init &&
          selected &&
          selected.installationSteps &&
          selected.installationSteps.length
        "
        class="
          broadband-offerings-page__steps
          c-wrapper
          cm-o-layout cm-o-layout--main
        "
      >
        <h2 v-if="content.installationStepsSectionHeading && !loading">
          {{ content.installationStepsSectionHeading }}
        </h2>
        <skeleton-loader
          v-if="content.installationStepsSectionHeading && loading"
          class="broadband-offerings-page__steps__heading-skeleton-loader"
        />
        <c-row v-if="init && !loading">
          <c-col
            v-for="(step, idx) in selected.installationSteps"
            :key="idx"
            :cols="selected.installationSteps.length > 3 ? 3 : 4"
            sm="12"
            class="broadband-offerings-page__step"
          >
            <div class="broadband-offerings-page__step-wrapper">
              <a-lottie-animation
                v-if="step.animationUrl"
                :path="step.animationUrl"
                in-view-play
                class="broadband-offerings-page__step-animation"
              />
              <img
                v-else-if="step.iconUrl"
                :src="step.iconUrl"
                :alt="step.iconAlt"
              />
              <c-icon v-else :symbol-id="step.iconId" />
              <div>
                <strong>{{ step.headline }}</strong>
              </div>
              <p>{{ step.text }}</p>
            </div>
          </c-col>
        </c-row>
        <c-row v-if="!init || loading">
          <c-col
            v-for="(step, idx) in 4"
            :key="idx"
            cols="3"
            sm="12"
            class="broadband-offerings-page__step"
          >
            <skeleton-loader
              class="broadband-offerings-page__step__skeleton-loader"
            />
          </c-col>
        </c-row>
      </section>
    </template>

    <div
      v-show="init && !hasOfferings"
      class="broadband-offerings-page__mobile-offerings-only-content"
    >
      <slot name="mobile-only"></slot>
    </div>

    <c-layout-section white>
      <c-tabs v-if="hasOfferings" v-model="tabs.active">
        <c-tab
          v-if="hasMarketingContent"
          :id="tabs.ids.MARKETING"
          :title="content.marketingTabButtonText"
        >
          <epi-content
            v-if="selectedMarketingHtml"
            :content="selectedMarketingHtml"
          />
          <slot v-else name="marketing" />
        </c-tab>
        <c-tab
          v-if="content.priceSummaryButtonText"
          :id="tabs.ids.PRICES"
          :title="content.priceSummaryButtonText"
        >
          <c-row justify="end" class="broadband-offerings-page__prices">
            <div
              v-if="selectedPriceSummaryImage"
              class="broadband-offerings-page__prices-image"
              :style="{
                backgroundImage: `url(${selectedPriceSummaryImage.src})`
              }"
            ></div>
            <c-col cols="6" sm="12">
              <price-summary
                v-if="selectedPriceSummary"
                :loading="loading"
                v-bind="selectedPriceSummary"
              />
            </c-col>
          </c-row>
        </c-tab>
        <c-tab
          v-if="hasFaqContent"
          :id="tabs.ids.FAQ"
          :title="content.faqTabButtonText"
          class="broadband-offerings-page__faq"
        >
          <epi-content v-if="selectedFaqHtml" v-html="selectedFaqHtml" />
          <slot v-else name="faq" />
        </c-tab>
      </c-tabs>
    </c-layout-section>

    <shop-footer v-if="init && hasOfferings" :columns="shopFooterColumns">
      <!-- TODO DKT-10798: add ribbon if selected product has it -->
      <template v-if="selected" slot="cta">
        <div class="broadband-offerings-page__footer-cta">
          <!-- TODO DKT-10795: use c-button instead -->
          <!-- TODO DKT-10797: figure out why shop-footer__cta class is exposed -->
          <button
            v-if="!loading"
            class="shop-footer__cta c-btn c-btn--wide"
            :class="shopFooterCta.buttonClassModifier"
            :disabled="shopFooterCta.disabled"
            @click.prevent="buy()"
          >
            <span class="c-btn__column">
              <c-icon
                v-if="shopFooterCta.iconSymbolId"
                class="c-btn--icon"
                :symbol-id="shopFooterCta.iconSymbolId"
              ></c-icon>
              {{ shopFooterCta.buttonText }}
            </span>
            <span
              v-if="shopFooterCta.offering"
              class="c-btn__column shop-footer__cta-price"
            >
              <span>
                <count-up
                  :end-val="shopFooterCta.offering.value"
                  :options="shopFooterCta.offering.countUpOptions"
                />
              </span>
              &nbsp;
              <span> {{ shopFooterCta.offering.label }} </span>
            </span>
          </button>
          <skeleton-loader
            v-if="loading"
            class="
              c-btn--wide
              shop-footer__cta shop-footer__cta--skeleton-loader
            "
          />
          <product-stock-status v-if="!loading">
            <small
              v-if="shopFooterCta.disclaimer"
              slot="footer"
              class="
                shop-footer__minimum-price shop-footer__minimum-price--clickable
              "
              @click="handleTotalPriceClicked()"
            >
              {{ shopFooterCta.disclaimer }}
            </small>
          </product-stock-status>
        </div>
      </template>
      <div slot="columns" class="cm-grid">
        <div
          v-for="(column, index) in shopFooterColumns"
          :key="index"
          :class="index > 0 && 'has-divider'"
          class="cm-grid__col--xs-6 cm-grid__col--xl-4"
        >
          <div class="shop-footer__column">
            <p class="shop-footer__item-label">
              {{ column.label }}
            </p>
            <strong v-if="column.label" class="shop-footer__item-value">
              {{ column.value }}
            </strong>
          </div>
        </div>
      </div>
    </shop-footer>
    <c-modal
      ref="singleBroadbandErrorModal"
      centered
      :title="content.singleBroadbandErrorModal.heading"
      content-centered
      show-close-button
    >
      <div class="broadband-offerings-page__modal">
        <div
          class="broadband-offerings-page__modal__message"
          v-html="content.singleBroadbandErrorModal.bodyText"
        ></div>
        <div class="broadband-offerings-page__modal__buttons">
          <button
            type="button"
            class="c-btn c-btn--light"
            @click.prevent="closeErrorModal('singleBroadbandErrorModal')"
          >
            {{ content.singleBroadbandErrorModal.cancelButtonLabel }}
          </button>
          <button
            type="button"
            class="c-btn c-btn--primary"
            @click.prevent="replace()"
          >
            {{ content.singleBroadbandErrorModal.acceptButtonLabel }}
          </button>
        </div>
      </div>
    </c-modal>
    <subscription-page-loyalty-overlay
      :show-loyalty-vas-modal="showLoyaltyVasModal"
      :content="content"
      :loyalty-vases="loyaltyVases"
      :is-broadband="true"
      @buyWithVas="buyWithVas"
      @close="closeLoyaltyVasModal()"
    >
    </subscription-page-loyalty-overlay>
    <c-modal
      :visible="showTooManyNodesModal"
      centered
      content-centered
      show-close-button
      @close="closeTooManyNodesModal()"
    >
      <div class="broadband-offerings-page__modal">
        <h2 class="broadband-offerings-page__modal__title">
          {{ content.tooManyNodesBroadbandAlert.heading }}
        </h2>
        <div
          class="broadband-offerings-page__modal__message text-center"
          v-html="content.tooManyNodesBroadbandAlert.content"
        />
        <div class="broadband-offerings-page__modal__buttons">
          <button
            type="button"
            class="c-btn c-btn--primary"
            @click.prevent="closeTooManyNodesModal()"
          >
            {{ content.tooManyNodesBroadbandAlert.buttonText }}
          </button>
        </div>
      </div>
    </c-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CountUp from 'vue-countup-v2';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin.js';
import { HTTP_STATUS_CODES } from 'olympus/utils/http.js';
import { setUrlFragment } from 'webshop/js/url';
import {
  CRow,
  CCol,
  CTabs,
  CTab,
  CInformationBox,
  CModal
} from 'olympus/components/index.js';
import ALottieAnimation from 'olympus/animations/a-lottie-animation/a-lottie-animation.vue';
import { ACTIONS as BASKET_ACTIONS } from '../../../shared/store/basket/index.js';
import {
  ACTIONS as OFFERING_ACTIONS,
  GETTERS as OFFERING_GETTERS
} from '../../../shared/store/offerings/index.js';
import { GETTERS as PRODUCT_PAGE_GETTERS } from 'webshop/components/product/store/product-page';
import {
  ACTIONS as CATALOG_ACTIONS,
  GETTERS as CATALOG_GETTERS
} from '../../../shared/store/catalog/index.js';
import PRODUCT_ASSOCIATION_LINK_TYPES from '../../../shared/enums/product-association-link-types.js';
import REFERENCE_TYPES from '../../../shared/enums/reference-types.js';
import ITEM_EQUIPMENT_TYPES from '../../../shared/enums/item-equipment-types.js';
import { ITEM_TYPES as ACCESS_TECHNOLOGY_TYPES } from '../../../shared/enums/access-technology-types.js';
import { MEDIA_GROUP_NAMES, MEDIA_TYPES } from '../../../shared/enums/media.js';
import { CART_MODIFY_ERROR_TYPES } from '../../../shared/enums/error-types.js';
import { PRODUCT_TABS } from 'webshop/enums/product-tabs';

import { getLoyaltyVases } from 'webshop/api/vas-service';

import STOCK_STATUSES from 'webshop/enums/stock-statuses.js';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import CCarousel from 'webshop/components/c-carousel/c-carousel.vue';
import SubscriptionCard from 'webshop/components/subscription/subscription-card/subscription-card.vue';
import ProductShortDescription from 'webshop/components/product/product-short-description.vue';
import ProductGallery from 'webshop/components/product/product-gallery.vue';
import ProductSplash from 'webshop/components/product/product-splash.vue';
import ProductStockStatus from 'webshop/components/product/product-stock-status.vue';
import PriceSummary from 'webshop/components/prices/price-summary.vue';
import EpiContent from 'webshop/components/epi-content/epi-content.vue';
import CLayoutSection from 'webshop/components/layout/c-layout-section.vue';
import SkeletonLoader from '../../../shared/components/skeleton-loader/skeleton-loader.vue';
import ParallaxWrapper from 'webshop/components/generic/parallax-wrapper.vue';
import SubscriptionPageLoyaltyOverlay from '../../../shared/components/subscription/subscription-page-loyalty-overlay.vue';
import { BROADBAND_SUBSCRIPTION_TYPES } from 'webshop/enums/broadband-subscription-types.js';
import methods from './methods.js';

export default {
  name: 'BroadbandOfferingsPage',

  components: {
    CLayoutSection,
    CountUp,
    CRow,
    CCol,
    CTabs,
    CTab,
    CIcon,
    CCarousel,
    CModal,
    SubscriptionCard,
    CInformationBox,
    ALottieAnimation,
    ProductShortDescription,
    ProductGallery,
    ProductSplash,
    ProductStockStatus,
    PriceSummary,
    EpiContent,
    SkeletonLoader,
    ParallaxWrapper,
    SubscriptionPageLoyaltyOverlay
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    address: {
      type: Object,
      required: true
    },
    isVasDisplayDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      init: false,
      selectedCode: null,
      technician: false,
      networking: {
        prices: false,
        pricesError: false
      },
      tabs: {
        active: PRODUCT_TABS.MARKETING,
        ids: { ...PRODUCT_TABS }
      },
      closeTongue: false,
      replaceCurrent: false,
      showLoyaltyVasModal: false,
      loyaltyVases: [],
      showTooManyNodesModal: false,
      loading: false,
      offerings: []
    };
  },

  computed: {
    ...mapGetters({
      broadbandOfferingsByAddressId:
        OFFERING_GETTERS.BROADBAND_OFFERINGS_BY_ADDRESS_ID,
      offeringPriceSummary: OFFERING_GETTERS.OFFERING_PRICE_SUMMARY_BY_CODE,
      getVariantByCode: CATALOG_GETTERS.GET_VARIANT_BY_CODE,
      getIncluded: CATALOG_GETTERS.GET_INCLUDED,
      activeTab: PRODUCT_PAGE_GETTERS.GET_ACTIVE_TAB
    }),

    addressId() {
      return this.$route.query.addressId;
    },

    hasOfferings() {
      return this.offerings?.length > 0 ?? false;
    },

    selected() {
      return this.offerings.find(x => x.code === this.selectedCode);
    },

    selectedIndex() {
      return this.offerings.indexOf(this.selected);
    },

    selectedInstallationFees() {
      if (!this.selected) {
        return [];
      }
      return this.selected.fees
        ? this.selected.fees.filter(
            f =>
              f.feeType === ITEM_EQUIPMENT_TYPES.INSTALLATIONFEE &&
              this.selected.offeringFees.some(
                fee =>
                  fee.code === f.code &&
                  fee.feeType === ITEM_EQUIPMENT_TYPES.TECHNICIANFEE
              )
          )
        : [];
    },

    selectedProduct() {
      const { id: code, attributes = {} } = this.selected?.product || {};
      return { code, marketingData: attributes.marketingData };
    },

    selectedContent() {
      return (
        this.content.productContentMap?.find(x =>
          x.productsFilter.includes(this.selectedProduct.code)
        ) || {}
      );
    },

    selectedHeaderHtml() {
      const { headerHtml } = this.selectedContent;
      return headerHtml;
    },

    selectedMarketingHtml() {
      const { marketingHtml } = this.selectedContent;
      return marketingHtml || this.selectedProduct.marketingData;
    },

    selectedFaqHtml() {
      const { faqHtml } = this.selectedContent;
      return faqHtml;
    },

    selectedImage() {
      if (
        this.selected?.broadband5GFallback &&
        this.content.fiveGFallBackToggle &&
        this.content.fiveGFallBackImage
      ) {
        return this.content.fiveGFallBackImage;
      }

      return this.selectedGalleryImages.length && this.selectedGalleryImages[0];
    },

    selectedImageSplash() {
      const type = this.selected?.variant?.badge.type;
      const splashText = this.selected?.variant?.badge.text;
      if (!splashText) {
        return false;
      }

      return {
        splashText,
        type,
        size: 'lg'
      };
    },

    selectedGalleryImages() {
      const images = this.selected?.variant?.mediaResources
        ?.filter(
          x =>
            x.type === MEDIA_TYPES.IMAGE &&
            x.groupNames &&
            x.groupNames.length &&
            x.groupNames.includes(MEDIA_GROUP_NAMES.GALLERY)
        )
        .map(({ src }) => src);
      return images || [];
    },

    selectedPriceSummaryImage() {
      const images = this.selected?.variant?.mediaResources?.filter(
        x => x.type === MEDIA_TYPES.IMAGE
      );
      if (!images || !images.length) {
        return false;
      }

      return (
        images.find(
          x =>
            x.groupNames &&
            x.groupNames.length &&
            !!x.groupNames.find(
              g =>
                g === MEDIA_GROUP_NAMES.PRICE_SUMMARY ||
                g === MEDIA_GROUP_NAMES.DEFAULT
            )
        ) || images[0]
      );
    },

    selectedPriceSummaryData() {
      const summary = this.offeringPriceSummary(this.selectedCode);
      if (!summary || !summary.groups || !summary.groups.length) {
        return false;
      }
      return summary;
    },

    selectedIsSubscription() {
      const summary = this.selectedPriceSummaryData;
      if (!summary) {
        return false;
      }

      return !!summary.groups[0].lineItems.find(
        x => x.type === ACCESS_TECHNOLOGY_TYPES.SUBSCRIPTION
      );
    },

    selectedPriceSummary() {
      const summary = this.selectedPriceSummaryData;
      if (!summary && !this.selected) {
        return false;
      }
      const { groups, paymentFee, bindingPeriod, shipping } = summary;
      const { lineItems } = summary && groups[0];

      const item = lineItems?.find(this.broadbandOrSubscription);
      const recurentFees = [
        {
          label: populateContentProperty(
            this.content.subscriptionPriceSummaryLabelTemplate || '',
            {
              name: item
                ? item.displayName
                : this.selected?.variant.displayName,
              binding: item
                ? item.bindingPeriod
                : this.selected?.variant.bindingPeriod,
              price: item
                ? item.priceMonthly.formattedValueCurrency
                : this.selected?.price.monthly.formattedValueCurrency
            }
          ),
          value: item
            ? item.priceBindingPeriod.formattedValueCurrency
            : this.selected?.price.monthly.formattedValueCurrency
        }
      ];

      if (paymentFee) {
        recurentFees.push({
          label: populateContentProperty(
            this.content.paymentFeePriceSummaryLabelTemplate || '',
            {
              name: paymentFee.displayName,
              binding: bindingPeriod,
              price: paymentFee.priceMonthly.formattedValueCurrency
            }
          ),
          value: paymentFee.priceBindingPeriod.formattedValueCurrency
        });
      }

      const oneTimeFees = [];

      // TODO DKT-10790: add CMS fallback and interpolatn
      const installationFee = lineItems?.find(
        x => x.type === ACCESS_TECHNOLOGY_TYPES.INSTALLATION_FEE
      );
      if (installationFee) {
        oneTimeFees.push({
          label: installationFee.displayName,
          value: installationFee.price.formattedValueCurrency
        });
      }

      // TODO DKT-10790: add CMS fallback and interpolatn
      const activationFee = lineItems?.find(
        x => x.type === ACCESS_TECHNOLOGY_TYPES.ACTIVATION_FEE
      );
      if (activationFee) {
        oneTimeFees.push({
          label: activationFee.displayName,
          value: activationFee.price.formattedValueCurrency
        });
      }

      if (shipping) {
        oneTimeFees.push({
          label: shipping.displayName,
          value: shipping.price.formattedValueCurrency
        });
      }

      return {
        headingLine: {
          label: item ? item.displayName : this.selected?.variant.displayName,
          value: item
            ? item.priceMonthly.formattedValueCurrency
            : this.selected?.price.monthly.formattedValueCurrency
        },
        minimumLine: {
          label: this.content.minimumPriceLabel,
          value: summary ? summary.total.formattedValueCurrency : ''
        },
        sections: [recurentFees, oneTimeFees],
        footerText: this.selected.variant.legalDescription,
        error: this.networking.pricesError
          ? this.content.networkErrorDisclaimer
          : ''
      };
    },

    selectedStatus() {
      if (this.networking.prices || this.networking.pricesError) {
        return false;
      }
      return STOCK_STATUSES.IN_STOCK;
    },

    hasHeaderContent() {
      return Boolean(this.selectedHeaderHtml || this.$slots.header);
    },

    hasMarketingContent() {
      return Boolean(
        this.content.marketingTabButtonText &&
          (this.selectedMarketingHtml || this.$slots.marketing)
      );
    },

    /**
     * Notes:
     * breakpoints are taken from our config file and are multiplied by two
     * to accomodate 50% width.
     *
     */
    technologyCarouselOptions() {
      let perPage = 1;

      // TODO DKT-1403: remove current mq plugin and use something nicer with better api.
      // that will remove hardcoded values.
      if (this.$resize && this.$mq.above(600 * 2)) {
        perPage = 2;
      }

      const navigateToIdx = () => {
        if (this.offerings.length <= 2 && this.$mq.above(1200)) {
          return 0;
        }
        if (
          this.selectedIndex === this.offerings.length - 1 &&
          this.$mq.above(1200)
        ) {
          return this.selectedIndex - 1;
        }
        return this.selectedIndex;
      };

      return {
        slides: this.offerings,
        useSlideOffset: this.$resize && this.$mq.below(800),
        slideOffset: 10,
        navigateTo: navigateToIdx(),
        navigationEnabled: this.$resize && this.$mq.above(800),
        navigationNextLabel:
          "<span class='VueCarousel-navigation-button__arrow VueCarousel-navigation-button__arrow--next'></span>",
        navigationPrevLabel:
          "<span class='VueCarousel-navigation-button__arrow VueCarousel-navigation-button__arrow--prev'></span>",
        navigationClickTargetSize: 14,
        scrollPerPage: false,
        paginationEnabled: this.$resize && this.$mq.below(800),
        paginationSize: 4,
        paginationActiveColor: '#222222',
        //33 is hex for 20% opacity, the format is #AARRGGBB
        paginationColor: '#33222222',
        paginationPadding: 12,
        perPage
      };
    },

    shopFooterColumns() {
      const summary = this.selectedPriceSummaryData;
      if (!summary) {
        return [];
      }

      const { groups, totalUpfront } = summary;
      const { lineItems } = groups[0];

      const item = lineItems.find(this.broadbandOrSubscription);
      if (!item || !item.priceMonthly) {
        return [];
      }

      const columns = [
        {
          label: this.content.subscriptionColumnLabel,
          value: item.priceMonthly.formattedValueCurrency
        }
      ];

      if (totalUpfront) {
        columns.push({
          label: this.content.totalUpfrontColumnLabel,
          value: totalUpfront.formattedValueCurrency
        });
      }

      return columns;
    },

    shopFooterCta() {
      const prices = {};
      const summary = this.selectedPriceSummaryData;
      if (summary) {
        const { groups, total } = summary;
        const { lineItems } = groups[0];

        const item = lineItems.find(this.broadbandOrSubscription);
        if (item) {
          const { value, label } = item.priceMonthly;
          prices.offering = {
            label,
            value,
            countUpOptions: {
              duration: 0.5,
              decimalPlaces: value - Math.floor(value) !== 0 ? 2 : 0,
              separator: '.',
              decimal: ','
            }
          };
        }
        if (total) {
          prices.disclaimer = `${this.content.minimumPriceLabel} ${total.formattedValueCurrency}`;
        }
      }

      let disabledStatusSetting = {};
      const statusSettings = {
        [STOCK_STATUSES.IN_STOCK]: {
          iconSymbolId: 'menu-buy',
          buttonClassModifier: 'c-btn--transactional',
          buttonText: this.content.ctaStatusAvailable
        },
        [STOCK_STATUSES.OUT_OF_STOCK]: (disabledStatusSetting = {
          iconSymbolId: '',
          disabled: true,
          buttonClassModifier: 'c-btn--disabled',
          buttonText: 'Tilføj til kurv'
        }),
        disabled: disabledStatusSetting
      };

      this.$nextTick(() => {
        this.$globals.eventBus.$emit('shopFooterChanged');
      });

      const status =
        !this.selected.hasExistingBroadbandConnection &&
        statusSettings[this.selectedStatus]
          ? statusSettings[this.selectedStatus]
          : statusSettings.disabled;

      return {
        ...status,
        ...prices
      };
    },

    broadbandOfferingsPageHeading() {
      if (
        this.content.fiveGFallBackToggle &&
        this.selected?.broadband5GFallback
      ) {
        return this.content.fiveGFallBackHeadline;
      }

      if (this.offerings.length) {
        return this.content.wizardHeadlineInternet;
      }

      return this.content.wizardHeadlineOnlyMobileInternet;
    },

    broadbandOfferingsPageDescription() {
      if (
        this.content.fiveGFallBackToggle &&
        this.selected?.broadband5GFallback
      ) {
        return this.content.fiveGFallBackDescriptionText;
      }

      return this.selected.variant.description;
    }
  },

  watch: {
    activeTab(newValue) {
      if (!newValue) {
        return;
      }

      if (newValue.scrollToTab) {
        this.tabs.active = newValue.id;
        this.$nextTick(() => {
          this.$scrollTo(`#${newValue.id}`);
        });
      }
    }
  },

  created() {
    this.fetchOfferings()
      .then(() => {
        this.getBroadbandOfferings();
        if (!this.offerings.length) {
          this.init = true;
          return;
        }
        const codes = this.offerings.map(x => x.code);
        this.fetchVariants({
          codes,
          req: {
            include: [
              PRODUCT_ASSOCIATION_LINK_TYPES.ITEM_VAS,
              PRODUCT_ASSOCIATION_LINK_TYPES.ITEM_FEE,
              REFERENCE_TYPES.PRODUCT_BASE
            ]
          }
        }).then(() => {
          this.transformOfferings();
          this.selectDefault();
          this.pushEecObjectToGtm('productImpression');
          this.init = true;
        });
      })
      .catch(e => {
        if (e.status === HTTP_STATUS_CODES.CONFLICT) {
          this.showTooManyNodesModal = true;
        } else {
          document.location = `${this.content.redirect || '/'}?err=${e.status}`;
        }
      });
  },

  methods: {
    ...mapActions({
      addBroadband: BASKET_ACTIONS.ADD_BROADBAND,
      addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION,
      fetchBroadbandOfferings: OFFERING_ACTIONS.FETCH_BROADBAND_OFFERINGS,
      fetchOfferingPrices: OFFERING_ACTIONS.FETCH_OFFERING_PRICES,
      fetchVariants: CATALOG_ACTIONS.FETCH_VARIANTS
    }),

    ...mapMutations(['SHOW_SPINNER', 'HIDE_SPINNER']),

    broadbandOrSubscription(x) {
      return (
        x.type === ACCESS_TECHNOLOGY_TYPES.BROADBAND ||
        x.type === ACCESS_TECHNOLOGY_TYPES.SUBSCRIPTION
      );
    },

    fetchOfferings(code) {
      return this.fetchBroadbandOfferings({
        addressId: this.addressId,
        selectedProductId: code
      });
    },

    closeTooManyNodesModal() {
      document.location = `${this.content.redirect}`;
      this.showTooManyNodesModal = false;
    },

    fetchOfferingPriceSummary(code) {
      this.networking.prices = true;
      this.networking.pricesError = false;
      return this.fetchOfferingPrices({ code: code, addressId: this.addressId })
        .then(() => {
          this.networking.prices = false;
          this.loading = false;
        })
        .catch(() => {
          this.networking.prices = false;
          this.networking.pricesError = true;
          this.loading = false;
          // TODO DKT-1116: Handle price summary errors (which essentually will prevent add to cart)
        });
    },

    select(code) {
      this.networking.pricesError = false;
      this.selectedCode = code;
      this.setUrlParams();
      this.pushEecObjectToGtm('productClick');

      if (!this.checkIfDsl(this.selectedCode)) {
        this.fetchOfferings(code)
          .then(() => this.fetchOfferingPriceSummary(this.selectedCode))
          .catch(e => {
            if (e.status === HTTP_STATUS_CODES.CONFLICT) {
              this.showTooManyNodesModal = true;
            } else {
              document.location = `${this.content.redirect || '/'}?err=${
                e.status
              }`;
            }
          });
      }
    },

    /**
     * @description Current logic: If subscriptionId exists in url params,
     * use preselected. Otherwise we select the first entry in this.offerings[].
     */
    selectDefault() {
      // TODO DKT-10792: discuss with business which should be preselected on init.
      const preselected = this.$route.query.subscriptionId;
      const targetCode = preselected ? preselected : this.offerings[0]?.code;

      this.select(targetCode);
      this.loading = true;
      if (this.checkIfDsl(targetCode)) {
        this.fetchOfferingPriceSummary(this.selectedCode);
      }
    },

    buy(replaceCurrent = false, bundleCode, continueWithoutLoyaltyVas) {
      this.SHOW_SPINNER();
      this.closeErrorModal('singleBroadbandErrorModal');
      this.closeLoyaltyVasModal();

      if (this.selectedIsSubscription) {
        this.addSubscription({
          code: this.selectedCode,
          upsale: true,
          bundleCode,
          continueWithoutLoyaltyVas:
            !this.content.enableTryAndBuyOffering || continueWithoutLoyaltyVas
        });
      } else {
        this.addBroadband({
          code: this.selectedCode,
          upsale: true,
          replaceCurrent,
          bundleCode,
          continueWithoutLoyaltyVas:
            !this.content.enableTryAndBuyOffering || continueWithoutLoyaltyVas,
          addressId: this.addressId,
          extraTechnician: this.technician
        })
          .then(() => {
            this.pushEecObjectToGtm('addToCart');
          })
          .catch(({ errors }) => {
            const singleBroadbandAllowedError =
              errors &&
              errors.find(
                x =>
                  x.code === HTTP_STATUS_CODES.CONFLICT &&
                  x.title === CART_MODIFY_ERROR_TYPES.SINGLE_BROADBAND_ALLOWED
              );
            if (singleBroadbandAllowedError) {
              this.$refs.singleBroadbandErrorModal.show();
            }

            const loyaltyVasRequiredError =
              errors &&
              errors.find(
                x =>
                  x.code === HTTP_STATUS_CODES.CONFLICT &&
                  x.title === CART_MODIFY_ERROR_TYPES.LOYALTY_VAS_REQURIED
              );

            if (loyaltyVasRequiredError) {
              this.showLoyaltyVasPopup();
            }
          })
          .finally(() => {
            this.HIDE_SPINNER();
          });
      }
    },

    async showLoyaltyVasPopup() {
      this.loyaltyVases = await getLoyaltyVases(
        this.selectedCode,
        this.addressId
      );
      this.showLoyaltyVasModal = true;
    },

    buyWithVas(bundleCode) {
      this.buy(this.replaceCurrent, bundleCode, bundleCode ? false : true);
    },

    replace() {
      this.replaceCurrent = true;
      this.buy(true);
    },

    pushEecObjectToGtm(eventType) {
      window.dataLayer = window.dataLayer || [];
      let eecObject = {};

      if (eventType === 'addToCart') {
        const product = {
          id: this.selected?.code?.toLowerCase(),
          name: this.selected?.variant?.displayName?.toLowerCase(),
          brand: this.selected?.brand?.toLowerCase(),
          category: this.selected?.category?.toLowerCase(),
          price: this.selected?.price.monthly?.value,
          quantity: 1
        };

        eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            ['add']: {
              products: [product]
            }
          },
          addToCartLocation: 'broadband_address_search',
          event: eventType
        };
      }

      if (eventType === 'productClick') {
        const product = {
          id: this.selected?.code?.toLowerCase(),
          name: this.selected?.variant?.displayName?.toLowerCase(),
          brand: this.selected?.brand?.toLowerCase(),
          category: this.selected?.category?.toLowerCase(),
          price: this.selected?.price.monthly?.value,
          position: this.selectedIndex + 1
        };

        eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            ['click']: {
              actionField: { list: 'Broadband product click' },
              products: [product]
            }
          },
          event: eventType
        };
      }

      if (eventType === 'productImpression') {
        const impressions = this.offerings?.map((offering, index) => {
          return {
            id: offering?.code?.toLowerCase(),
            name: offering?.variant?.displayName?.toLowerCase(),
            brand: offering?.brand?.toLowerCase(),
            category: offering?.category?.toLowerCase(),
            list: 'broadband product page',
            price: offering?.price?.monthly?.value,
            position: index + 1
          };
        });

        eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            impressions
          },
          event: eventType
        };
      }

      window.dataLayer.push(eecObject);
    },

    setUrlParams() {
      this.$route.query.subscriptionId = this.selectedCode;
      setUrlFragment(this.$route.query, false);
    },

    closeErrorModal(name) {
      const modal = this.$refs[name];
      if (modal) {
        modal.hide();
      }
    },

    closeLoyaltyVasModal() {
      this.showLoyaltyVasModal = false;
    },

    handleTotalPriceClicked() {
      const target = this.tabs.ids.PRICES;
      this.tabs.active = target;
      this.$nextTick(() => {
        this.$scrollTo(`#${target}`);
      });
    },
    getProductSpeed(offering, attributes) {
      if (offering.downloadSpeed && offering.uploadSpeed) {
        return {
          value: `${offering.downloadSpeed.value}/${offering.uploadSpeed.value}`,
          unit: offering.uploadSpeed.unit || offering.downloadSpeed.unit
        };
      }
      return attributes
        ? {
            value: `${attributes.downloadSpeed.value}/${attributes.uploadSpeed.value}`,
            unit: attributes.downloadSpeed.unit
          }
        : null;
    },
    hasFaqContent() {
      return Boolean(
        this.content.faqTabButtonText &&
          (this.selectedFaqHtml || this.$slots.faq)
      );
    },
    //vue 2 doesn't like template expressions, this switch can be ditched after vue update for inline optional chaining in props
    getVariantPropsWhenAvailable(variant, prop) {
      switch (prop) {
        case 'text':
          return variant?.badge?.text;
        case 'speed':
          return variant?.speed?.value;
        case 'unit':
          return variant?.speed?.unit;
        default:
          return;
      }
    },
    getBroadbandOfferings() {
      this.offerings = this.broadbandOfferingsByAddressId({
        addressId: this.addressId,
        sortMethod: this.content.sortMethod,
        sortOverrides: this.content.sortMethodOverrides
      });
    },

    transformOfferings() {
      const transformed = this.offerings.map(offering => {
        const initVariant = this.getVariantByCode(offering.code);
        const included = this.getIncluded(initVariant);
        const product = included.productBaseResponse[0] || {};
        const installationSteps = methods.getInstallationSteps(
          this.content.productContentMap,
          product,
          offering,
          included.itemToItemFee
        );
        const vas = included.itemToItemValueAddedService?.map(
          ({ attributes: v }) => ({
            title: v.displayName,
            imageUrl: v.mediaResources[0]?.src
          })
        );
        const fees = included.itemToItemFee?.map(f => ({
          code: f.id,
          ...f.attributes
        }));
        const { attributes } = initVariant || {};
        const variant = {
          ...attributes,
          speed: this.getProductSpeed(offering, attributes)
        };
        return {
          ...offering,
          installationSteps,
          product,
          variant,
          vas,
          fees
        };
      });
      this.offerings = transformed;
    },
    checkIfDsl(targetCode) {
      const technologyType = this.offerings.find(
        offering => offering.code === targetCode
      )?.technologyType;
      return technologyType === BROADBAND_SUBSCRIPTION_TYPES.DSL;
    }
  }
};
</script>
