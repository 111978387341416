var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-list-select__vas" }, [
    _c("div", { staticClass: "cm-c-list-select__vas-services" }, [
      _c("div", { staticClass: "cm-c-list-select__vas-info" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.vasText) } })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cm-c-product-vas" },
        _vm._l(_vm.services, function(item) {
          return _c("subscription-value-added-service", {
            key: item.code,
            attrs: {
              item: item,
              "expand-label": _vm.expandServiceLabel,
              "collapse-label": _vm.collapseLabel
            }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }