import { createNamespace } from '../../utils.js';
import { get } from 'webshop/api/api.js';

export const GETTERS = createNamespace('NUMBER-PORTING-CONFIG/GETTERS', [
  'GET_B2BADDRESS'
]);

export const MUTATIONS = createNamespace('NUMBER-PORTING-CONFIG/MUTATIONS', [
  'SET_B2BADDRESS'
]);

export const ACTIONS = createNamespace('NUMBER-PORTING-CONFIG/ACTIONS', [
  'FETCH_B2BADDRESS'
]);

export default {
  state: {
    b2BAddress: null
  },

  getters: {
    [GETTERS.GET_B2BADDRESS](state) {
      return state.b2BAddress;
    }
  },

  mutations: {
    [MUTATIONS.SET_B2BADDRESS](state, b2BAddress) {
      state.b2BAddress = b2BAddress;
    }
  },

  actions: {
    [ACTIONS.FETCH_B2BADDRESS]: (_, cvr) => {
      const config = {
        headers: {
          accept: 'application/vnd.api+json'
        }
      };
      return get(`/companies/${cvr}/address`, config);
    }
  }
};
