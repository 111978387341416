<template>
  <div>
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col" cols="12">
        <h3 class="cm-c-form__header">
          {{ numberConfigVM.optionalSettingsHeadline }}
        </h3>
      </c-col>
    </c-row>

    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col" align-content="center">
        <c-checkbox
          id="hiddenNumber"
          v-model="hiddenNumber"
          name="isNumberHidden"
          styled-checkbox
          :checked="hiddenNumber"
          @change="emitHiddenNumber"
        >
          {{ numberConfigVM.secretNumberLabel }}
        </c-checkbox>

        <popover-panel
          :description="numberConfigVM.secretNumberDescription"
          :tooltip-screen-reader-text="numberConfigVM.secretNumberDescription"
          :popover-triggers="['hover', 'click']"
          base-classes="cm-numcon__information"
        >
          <c-icon
            symbol-id="service-information"
            class="cm-numcon__information__icon"
          />
        </popover-panel>
      </c-col>
    </c-row>

    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col" align-content="center">
        <c-checkbox
          id="unlistedNumber"
          v-model="unlistedNumber"
          name="isNumberUnlisted"
          styled-checkbox
          :checked="unlistedNumber"
          @change="emitUnlistedNumber"
        >
          {{ numberConfigVM.unlistedNumberLabel }}
        </c-checkbox>

        <popover-panel
          :description="numberConfigVM.unlistedNumberDescription"
          :tooltip-screen-reader-text="numberConfigVM.unlistedNumberDescription"
          :popover-triggers="['hover', 'click']"
          base-classes="cm-numcon__information"
        >
          <c-icon
            symbol-id="service-information"
            class="cm-numcon__information__icon"
          />
        </popover-panel>
      </c-col>
    </c-row>

    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <p>{{ numberConfigVM.barringsInformation }}</p>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CRow, CCol, CCheckbox } from 'olympus/components/index.js';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';

export default {
  name: 'NumberOptional',

  components: {
    CRow,
    CCol,
    CCheckbox,
    PopoverPanel
  },

  data() {
    return {
      hiddenNumber: false,
      unlistedNumber: false
    };
  },

  computed: {
    ...mapGetters(['numberConfigVM', 'numberConfigActiveProduct'])
  },

  mounted() {
    this.changeLocalData();
  },

  methods: {
    changeLocalData() {
      if (this.numberConfigActiveProduct) {
        this.hiddenNumber = this.numberConfigActiveProduct.hiddenNumber;
        this.unlistedNumber = this.numberConfigActiveProduct.unlistedNumber;
      }
    },

    emitHiddenNumber(bool) {
      this.$emit('hiddenNumber', bool);
    },

    emitUnlistedNumber(bool) {
      this.$emit('unlistedNumber', bool);
    }
  }
};
</script>
