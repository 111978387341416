<template>
  <div
    :id="uid"
    :class="classObject"
    role="tabpanel"
    :aria-hidden="(!activeLocal).toString()"
  >
    <!--aria-labelledby="not_done_yet"-->
    <slot />
  </div>
</template>
<script>
export default {
  name: 'c-tab',

  props: {
    title: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      uid: -1,
      context: null,
      activeLocal: this.active || false
    };
  },

  computed: {
    classObject() {
      return {
        'c-tab': true,
        'c-tab--active': this.activeLocal
      };
    }
  },

  created() {
    let parent = this.$parent;

    while (parent && parent.$options.name !== 'c-tabs') {
      parent = parent.$parent;
    }

    if (!parent) {
      throw new Error(
        'Rendering c-tab outside of c-tabs context is not allowed.'
      );
    }

    this.context = parent;
    this.uid = this.id || parent.items.length;
    parent.items.push(this);
  },

  methods: {
    activate() {
      this.activeLocal = true;
    },
    deactivate() {
      this.activeLocal = false;
    }
  }
};
</script>
<style lang="scss">
.c-tab {
  display: none;

  &--active {
    display: block;
  }
}
</style>
