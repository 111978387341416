<template>
  <a
    class="c-product-card"
    :class="modifier"
    itemtype="http://schema.org/Product"
    itemscope
    :href="productUrl"
  >
    <div
      v-if="ribbonText"
      class="c-product-card__ribbon c-badge c-badge--top-left c-badge--primary"
    >
      {{ ribbonText }}
    </div>

    <div class="c-product-card__top">
      <div class="c-product-card__image">
        <img :alt="productModel || productName" :src="productPreviewImage" />
        <p v-if="splash" class="c-splash c-splash--product c-splash--accent">
          <span>
            {{ splash }}
          </span>
        </p>
      </div>
      <div class="c-product-card__content">
        <section>
          <small class="c-product-card__brand">{{ productBrand }}</small>
          <div class="c-product-card__details-wrapper">
            <h3 class="c-product-card__title">
              {{ productModel || productName }}
            </h3>
            <ul v-if="variants && variants.length > 0">
              <li
                v-for="variant in variants"
                :key="variant"
                class="c-product-card__variant"
                :class="{
                  'c-product-card__variant--border': isColorWhite(variant)
                }"
                :style="{ backgroundColor: variant }"
              />
            </ul>
          </div>
        </section>

        <section>
          <p class="c-product-card__price">
            {{ price.amount }} <span>{{ price.unit }}</span>
            <small v-for="text in priceDescriptions" :key="text" v-trim>
              {{ text }}
            </small>
          </p>
          <button v-if="content.actionButtonLabel" class="c-btn c-btn--sm">
            {{ content.actionButtonLabel }}
          </button>
        </section>
      </div>
    </div>
  </a>
</template>

<script>
import ProductCardMetaMixin from 'olympus/mixins/product-card-meta-mixin.js';
import ColorMixin from 'webshop/mixins/color-mixin.js';

export default {
  name: 'CProductCard',
  mixins: [ProductCardMetaMixin, ColorMixin],
  props: {
    minimumPrice: {
      type: String,
      default: ''
    },
    monthlyPrice: {
      type: String,
      default: ''
    },
    fullPrice: {
      type: String,
      default: ''
    },
    installmentPeriod: {
      type: Number,
      default: null
    },
    productImageUrl: {
      type: String,
      default: ''
    },
    productBrand: {
      type: String,
      default: ''
    },
    productName: {
      type: String,
      default: ''
    },
    productModel: {
      type: String,
      default: ''
    },
    productUrl: {
      type: String,
      default: ''
    },
    badgeText: {
      type: Object,
      default: () => ({})
    },
    ribbonText: {
      type: String,
      default: ''
    },
    modifier: {
      type: String,
      default: ''
    },
    variants: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => ({})
    },
    metaDetailsHidden: {
      type: Boolean,
      default: false
    },
    meta: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    price() {
      const [amount, unit] = this.monthlyPrice.split(' ');

      return {
        amount,
        unit
      };
    },

    priceDescriptions() {
      if (this.metaDetailsHidden) {
        return [];
      }

      const metaLines = this.productCardMetaData(
        {
          installmentPeriod: this.installmentPeriod,
          minimumPrice: this.minimumPrice,
          fullPrice: this.fullPrice
        },
        this.meta
      );

      return metaLines.length ? metaLines : [this.minimumPrice];
    },

    splash() {
      if (!this.badgeText) {
        return;
      }

      return this.badgeText.item2;
    },

    productPreviewImage() {
      return this.productImageUrl
        ? `${this.productImageUrl}?width=${
            window.__APP__?.productPreviewImageWidth || 200
          }`
        : '';
    }
  }
};
</script>
