var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "limited-menu" }, [
    _c("div", { staticClass: "c-wrapper limited-menu__inner-wrapper" }, [
      _c(
        "a",
        { staticClass: "limited-menu__logo", attrs: { href: "/" } },
        [
          _c("c-icon", {
            staticClass: "limited-menu__logo-icon",
            attrs: { "symbol-id": "menu-logo" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "limited-menu__contact" },
        _vm._l(_vm.content.items, function(item, index) {
          return _c("span", {
            key: index,
            domProps: { innerHTML: _vm._s(item.rawHtml) }
          })
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }