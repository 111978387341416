import { hasBadContrast, parseToRgba } from 'color2k';

/**
 * Use this mixin to expose color-related methods to components.
 */
export default {
  methods: {
    /**
     * Removes white space from colors to prevent parsing errors.
     *
     * @param { string } color
     */
    sanitizeColor(color) {
      return color.replace(/\s+/g, '');
    },

    /**
     * Checks if a color string is considered to be 'light'.
     * This is useful for deciding if a text, icon, etc. placed on top of an element should be dark or light in order to create enough contrast.
     *
     * @param { string } color The input color. Can be a RGB, RBGA, HSL, HSLA, HEX or named color.
     * @return { boolean }
     */
    isColorLight(color) {
      return hasBadContrast(this.sanitizeColor(color), 'readable');
    },

    /**
     * Checks if a color string is white.
     *
     * @param { string } color The input color. Can be a RGB, RBGA, HSL, HSLA, HEX or named color.
     * @return { boolean }
     */
    isColorWhite(color) {
      const parsedColorString = parseToRgba(this.sanitizeColor(color))
        .slice(0, 3)
        .toString();
      return parsedColorString === '255,255,255';
    }
  }
};
