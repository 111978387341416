var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "expand" },
      on: { enter: _vm.enter, "after-enter": _vm.afterEnter, leave: _vm.leave }
    },
    [
      _vm.show
        ? _c(
            _vm.tag,
            {
              tag: "component",
              staticClass: "c-collapse",
              class: _vm.modifiers,
              style: _vm.transitionProps,
              attrs: { id: _vm.id, "aria-hidden": _vm.show ? "false" : "true" }
            },
            [_vm._t("default", null, { expanded: _vm.show })],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }