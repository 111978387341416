<template>
  <div class="address-search">
    <div class="address-search__card" :class="modifiers">
      <p class="address-search__description" v-html="content.description"></p>
      <div class="address-search__group">
        <c-dropdown
          v-model="selected"
          :attributes="{
            options: addresses,
            noDrop: !showOptions,
            maxInputLength: content.maxInputLength || 100
          }"
          :events="{
            'option:selected': handleOptionSelected,
            search: handleSearchInput,
            'search:blur': handleSearchBlur,
            'search:focus': handleSearchFocus
          }"
          :search-input-placeholder="content.searchInputPlaceholder"
          :enable-local-loader="content.enableLocalLoader"
          :search-string="content.searchString"
          :skinny="!isBlock"
        >
          <template slot="no-options">
            <div
              v-if="showError && content.searchErrorText"
              v-html="content.searchErrorText"
            />
            <div
              v-if="!showError && content.noResultsText"
              v-html="content.noResultsText"
            />
          </template>
        </c-dropdown>
      </div>
    </div>
    <div v-if="content.triggerText" class="address-search__tongue">
      <template v-if="content.collapseContent">
        <c-collapse-trigger
          id="address-search-tongue"
          data-testid="address-search-tongue"
          class="address-search__tongue-trigger"
        >
          <template slot-scope="{ expanded }">
            <span
              v-if="content.triggerImages && content.triggerImages.length"
              class="address-search__tongue-images"
            >
              <img
                v-for="(image, i) in content.triggerImages"
                :key="i"
                :src="image"
              />
            </span>
            <span class="address-search__tongue-title">
              {{ content.triggerText }}
            </span>
            <c-icon
              class="address-search__tongue-icon"
              :class="{ 'address-search__tongue-icon--expanded': expanded }"
              symbol-id="arrow-chevron"
            />
          </template>
        </c-collapse-trigger>
        <c-collapse id="address-search-tongue">
          <div
            class="address-search__tongue-content"
            v-html="content.collapseContent"
          ></div>
        </c-collapse>
      </template>
      <template v-else>
        <div class="address-search__tongue-footer">
          <span
            v-if="content.triggerImages && content.triggerImages.length"
            class="address-search__tongue-images"
          >
            <img
              v-for="(image, i) in content.triggerImages"
              :key="i"
              :src="image"
            />
          </span>
          <span class="address-search__tongue-title">
            {{ content.triggerText }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { get } from '../../api/api.js';

import CDropdown from '../c-dropdown/c-dropdown.vue';
import { CCollapse, CCollapseTrigger } from 'olympus/components';
import CIcon from 'webshop/components/c-icon/c-icon.vue';

// TODO DKT-1459: we need to align the card above with the rest of them.
export default {
  name: 'AddressSearch',

  components: {
    CDropdown,
    CCollapse,
    CCollapseTrigger,
    CIcon
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    isBlock: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selected: null,
      showOptions: false,
      showError: false,
      typing: {
        interval: null,
        delay: this.content.inputDelay
      },
      /**
       * { tekst: '', adresse: { adgangsadresseid, vejnavn, tekst, id } }
       */
      // undone: we need to get rid of the danish property names inhere
      addresses: []
    };
  },

  computed: {
    modifiers() {
      return {
        'search-address--no-separators': !this.noSeparators,
        'address-search__card--is-block': this.isBlock
      };
    },

    searchString() {
      return this.content.searchString;
    }
  },

  watch: {
    searchString(value) {
      this.handleSearchInput(value, () => '', true);
    }
  },

  methods: {
    searchAddress(phrase, selectFirst) {
      this.showOptions = true;
      this.showError = false;
      return get('https://dawa.aws.dk/adresser/autocomplete', {
        params: {
          q: phrase,
          usefuzzysearch: this.content.useFuzzySearch
        },
        mvc: true
      })
        .then(({ data }) => {
          this.addresses = data.map(({ adresse, tekst }) => {
            return {
              value: adresse.Id || adresse.id, //temporary support both
              label: tekst,
              data: adresse
            };
          });

          if (selectFirst) {
            this.selected = this.addresses[0];
          }
        })
        .catch(() => {
          this.showError = true;
          this.addresses = [];
        });
    },

    handleOptionSelected(option) {
      if (!option || !this.content.redirect) {
        this.$emit('select', option);
        return;
      }

      document.location = `${this.content.redirect}?addressId=${option.value}`;
    },

    handleSearchInput(search, loading, selectFirst = false) {
      if (!search || search.length < 3) {
        this.clearSearch();
        return;
      }

      const callback = () => {
        loading(true);
        this.searchAddress(search, selectFirst).then(() => {
          loading(false);
        });
      };

      clearTimeout(this.typing.interval);
      this.typing.interval = setTimeout(callback, this.typing.delay);
    },

    handleSearchBlur() {
      this.clearSearch();
    },

    handleSearchFocus() {
      this.clearSelected();
    },

    clearSearch() {
      if (this.addresses.length > 0) {
        this.addresses = [];
      }
      this.showOptions = false;
    },

    clearSelected() {
      if (this.selected) {
        this.selected = null;
      }
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$inputHeight: $u-700 + $u-200;
$inputBorderWidth: 1px;

.address-search {
  position: relative;

  $root: &;

  // .address-search__card
  &__card {
    // .address-search__card--is-block
    &--is-block {
      background: $c-pm-800;
      border-radius: 3px;
      box-shadow: $box-shadow-default;
      color: $color-white;
      padding: $u-400 24px $u-500;
      text-align: center;

      @include mq($screen-md, min) {
        padding-left: 100px;
        padding-right: 100px;
      }

      // .address-search__card--is-block .address-search__description
      #{$root}__description {
        display: block;
        margin-bottom: $u-300;
      }
    }
  }

  // .address-search__description
  &__description {
    display: none;
  }

  // .address-search__group
  &__group {
    color: $c-nt-900;
    position: relative;
  }

  $vasImageSize: 24;
  $vasImageMargin: 2;
  $vasMinCount: 1;
  $vasMaxCount: 6;

  // .address-search__tongue
  &__tongue {
    background: $color-white;
    border-radius: 0 0 3px 3px;
    box-shadow: $box-shadow-default;
    margin: 0 8px;
    padding: $u-250 $u-300 $u-250;

    // .address-search__tongue-trigger,
    // .address-search__tongue-footer
    &-trigger,
    &-footer {
      position: relative;
      display: flex;
      align-items: center;
    }

    // .address-search__tongue-images
    &-images {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      min-width: #{($vasImageSize + $vasImageMargin) * $vasMinCount}px;
      max-width: #{($vasImageSize + $vasImageMargin) * $vasMaxCount}px;
      max-height: #{$vasImageSize}px;

      // .address-search__tongue-images > img
      & > img {
        height: #{$vasImageSize}px;
        width: #{$vasImageSize}px;
        max-width: 100%;
        height: auto;
        vertical-align: middle;

        &:not(:last-child) {
          margin-right: #{$vasImageMargin}px;
        }
      }
    }

    // .address-search__tongue-title
    &-title {
      flex: 1 0 0;
      margin: 0 1rem;
      white-space: nowrap;
    }

    // .address-search__tongue-icon
    &-icon {
      fill: $color-black;
      height: 6px;
      position: absolute;
      right: 0;
      top: 8px;
      transform: rotate(0);
      transition: transform 0.2s ease;
      width: 10px;
      will-change: transform;

      // .address-search__tongue-icon--expanded
      &--expanded {
        transform: rotate(180deg);
      }
    }

    // .address-search__tongue-content
    &-content {
      padding-top: 16px;
    }
  }
}
</style>
