<template>
  <div
    :class="{
      'c-existing-subscription-selector': true,
      'c-existing-subscription-selector--inactive': !allowActions
    }"
    v-on="allowActions ? { click: showNumberSelectModal } : {}"
  >
    <div class="c-existing-subscription-selector__inner">
      <div class="c-existing-subscription-selector__text">
        {{ subscriptionToNumberLabel }}
        <b v-if="!selectedPhoneNumber && !preselectedPhoneNumber">{{
          newNumberLabel
        }}</b>
        <span v-else>{{ existingNumberLabel }}</span>
      </div>
      <div
        v-if="allowActions || preselectedPhoneNumber"
        class="c-existing-subscription-selector__actions"
      >
        {{ phoneNumberFormatted }}
        <c-icon
          v-if="!preselectedPhoneNumber"
          symbol-id="arrow-chevron"
        ></c-icon>
      </div>
    </div>

    <c-modal
      centered
      :visible="showNumberSelectionModal && content.showNumberSelectorPopUp"
      @close="hideNumberSelectModal"
    >
      <product-number-selector
        v-if="showNumberSelectionModal"
        :product-code="productCode"
        :content="content"
        :selected-number="selectedPhoneNumber"
        @number-selected="numberSelected"
      ></product-number-selector>
    </c-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIONS, GETTERS } from '../../store/number-select/index.js';
import { CIcon } from '../c-icon/index.js';
import ProductNumberSelector from 'webshop/components/product/product-number-selector.vue';
import numberMixin from '../../mixins/number-mixin.js';

export default {
  name: 'existing-subscription-selector',

  components: {
    CIcon,
    ProductNumberSelector
  },

  mixins: [numberMixin],

  props: {
    productCode: {
      type: String,
      default: ''
    },

    changeNumberLabel: {
      type: String,
      default: 'Skift'
    },

    subscriptionToNumberLabel: {
      type: String,
      default: 'Abonnement til'
    },

    existingNumberLabel: {
      type: String,
      default: 'nummer'
    },

    newNumberLabel: {
      type: String,
      default: 'nyt nummer'
    },

    preselectedPhoneNumber: {
      type: String,
      default: undefined
    },

    allowActions: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selected: this.selectedOption,
      content: window.__APP__?.modalContent.existingNumber
    };
  },

  computed: {
    ...mapGetters({
      selectedPhoneNumber: GETTERS.GET_SELECTED_PHONE_NUMBER,
      showNumberSelectionModal: GETTERS.GET_NUMBER_MODAL_VALUE
    }),

    phoneNumberFormatted() {
      if (this.selectedPhoneNumber) {
        return this.formatNumberByDigits(this.selectedPhoneNumber);
      } else if (this.preselectedPhoneNumber) {
        return this.formatNumberByDigits(this.preselectedPhoneNumber);
      }

      return this.changeNumberLabel;
    }
  },

  methods: {
    ...mapActions({
      showNumberSelectModal: ACTIONS.SHOW_NUMBER_MODAL,
      hideNumberSelectModal: ACTIONS.HIDE_NUMBER_MODAL,
      setSelectedPhoneNumber: ACTIONS.SET_SELECTED_PHONE_NUMBER
    }),

    /**
     * Callback for when the number-selected event was triggered.
     *
     * @param { object } eventData
     * @param { number } [eventData.phoneNumber] the chosen phone number (or falsy if no number selected).
     */
    numberSelected({ phoneNumber }) {
      this.hideNumberSelectModal();
      this.setSelectedPhoneNumber({ selectedPhoneNumber: phoneNumber });
    }
  }
};
</script>
