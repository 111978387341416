export const CTableHead = {
  name: 'c-table-head',

  render(h) {
    return h('thead', {}, this.$slots.default);
  }
};

export const CTableBody = {
  name: 'c-table-body',

  render(h) {
    return h('tbody', {}, this.$slots.default);
  }
};

export const CTableFoot = {
  name: 'c-table-foot',

  render(h) {
    return h('tfoot', {}, this.$slots.default);
  }
};

export const CTr = {
  name: 'c-tr',

  render(h) {
    return h('tr', {}, this.$slots.default);
  }
};
