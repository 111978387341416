let scrollEventListener;

const isElementInViewport =
  (el, offsetHeight = 0, onEnter, onExit = () => null) =>
  () => {
    if (!el || !onEnter) {
      return false;
    }

    const rect = el.getBoundingClientRect();

    const isInViewport =
      rect.bottom > rect.height &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.top <
        (window.innerHeight - offsetHeight ||
          document.documentElement.clientHeight - offsetHeight);

    if (isInViewport) {
      onEnter();
    } else {
      onExit();
    }
  };

/**
 * Use this directive to detect element positions relative to the viewport.
 */
export default {
  /**
   * Checks if the given element is in the viewport.
   * Useful for animating elements when they become visible.
   *
   * @param { HTMLElement } el
   * @param { object } binding
   * @property { function } binding.enter Callback for when the element enteres the viewport
   * @property { function } [binding.exit] Callback for when the element exists the viewport
   * @property { number } [binding.offsetHeight] Arbitrary height to subtract from the viewport in order to account for i.e. fixed elements.
   *
   * @return { boolean }
   */

  bind(el, binding) {
    const { enter, exit, offsetHeight } = binding.value;
    scrollEventListener = isElementInViewport(el, offsetHeight, enter, exit);
    window.addEventListener('scroll', scrollEventListener);
  },

  inserted() {
    scrollEventListener(); // Ensures detection of the viewport upon load
  },

  unbind() {
    window.removeEventListener('scroll', scrollEventListener);
  }
};
