import { get, post } from 'axios';
import store from '../store/shop/index.js';

function removeFromOrder(id) {
  const contextId = store.state.global.contextId;
  return post(
    '/api/olympus/BusinessOrder/RemoveOrderLine?contextId=' + contextId,
    {
      data: id
    }
  );
}

function finalizeOrder(orderId) {
  const contextId = store.state.global.contextId;

  return get('/api/olympus/BusinessOrder/ConfirmOrder?contextId=' + contextId, {
    params: { orderId }
  });
}

function updateOrderLines() {
  const contextId = store.state.global.contextId;

  return get('/api/olympus/BusinessOrder/GetOrderLines?contextId=' + contextId);
}

function addOrderLine(order) {
  const contextId = store.state.global.contextId;

  return post(
    '/api/olympus/BusinessOrder/AddOrderLine?contextId=' + contextId,
    {
      data: order
    }
  );
}

export { removeFromOrder, finalizeOrder, updateOrderLines, addOrderLine };
