<template>
  <div class="c-datepicker">
    <DatePicker
      v-model="date"
      lang="da"
      :range="range"
      :popup-class="popupModifiers"
      :disabled-date="isDisabledDate"
      :clearable="clearable"
      @change="onChange"
      @open="open = true"
      @close="open = false"
    >
      <c-icon
        slot="icon-calendar"
        :symbol-id="open ? 'arrow-up-small' : 'arrow-down-small'"
        class="icon-calendar"
      ></c-icon>
      <c-icon
        v-if="clearable"
        slot="icon-clear"
        symbol-id="menu-close"
        class="icon-clear"
      ></c-icon>
      <c-input-group
        slot="input"
        :value="stringifyDate"
        :disable-clear="true"
        :place-holder-label="label"
      ></c-input-group>
    </DatePicker>
    <!-- Holds the value that is sent on form submission -->
    <input :value="formDate" type="hidden" :name="inputName" />
  </div>
</template>

<script>
import Vue from 'vue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/da';

import addDays from 'date-fns/addDays';
import isWeekend from 'date-fns/isWeekend';
import isFriday from 'date-fns/isFriday';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';

import CIcon from '../c-icon/c-icon.vue';
import CInputGroup from '../c-input/c-input-group.vue';

export default Vue.extend({
  name: 'CDatePicker',
  components: {
    CInputGroup,
    DatePicker,
    CIcon
  },
  props: {
    initialDate: {
      type: String,
      default: ''
    },
    firstAvailableDate: {
      type: [String, null],
      default: null
    },
    dateFormat: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    disableMonthAndYears: {
      type: Boolean,
      default: true
    },
    disableWeekends: {
      type: Boolean,
      default: true
    },
    disableFriday: {
      type: Boolean,
      default: false
    },
    disablePast: {
      type: Boolean,
      default: true
    },
    disableFuture: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Array,
      default: () => []
    },
    disabledDays: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Vælg dato'
    },
    minimumDays: {
      type: Number,
      default: 0
    },
    maximumDays: {
      type: Number,
      default: null
    },
    range: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: this.initialDate ? new Date(this.initialDate) : null,
      today: new Date(),
      open: false
    };
  },
  computed: {
    popupModifiers() {
      return {
        'mx--disable-month-and-years': this.disableMonthAndYears,
        'mx-datepicker-popup-left-bottom': true
      };
    },
    stringifyDate() {
      if (!this.date) {
        return '';
      }
      /**
       * @returns {(string|Array)} from date<String> or dateRange<Array>
       */
      if (Array.isArray(this.date)) {
        const result =
          this.date.filter(i => !!i).length > 0
            ? this.date.map(d => format(d, this.dateFormat)).join(' - ')
            : '';
        return result;
      }
      return format(this.date, this.dateFormat);
    },
    formDate() {
      return this.date ? format(this.date, 'yyyy-MM-dd') : null;
    },
    todayUTC() {
      return this.today.toLocaleString('en-GB', {
        timeZone: 'Europe/Copenhagen'
      });
    }
  },
  watch: {
    initialDate(value) {
      this.date = new Date(value);
    },
    date(val) {
      this.$emit('change', val);
    }
  },
  mounted() {
    // If date is pre set then we need to send an change event to the parent
    if (this.date) {
      this.$emit('change', this.date);
    }
  },
  methods: {
    onChange(date) {
      this.$emit('change', date);
    },
    isDisabledDate(date) {
      if (this.maximumDays && this.dateIsBeforeMaximumDays(date)) {
        return true;
      } else if (this.minimumDays && this.dateIsAfterMinimumDays(date)) {
        return true;
      } else if (this.disableWeekends && this.dateIsWeekend(date)) {
        return true;
      } else if (this.disableFriday && this.dateIsFriday(date)) {
        return true;
      } else if (this.disablePast && this.dateIsPast(date)) {
        return true;
      } else if (
        this.firstAvailableDate &&
        this.dateIsBeforeFirstAvailableDate(date)
      ) {
        return true;
      } else if (this.disableFuture && this.dateIsFuture(date)) {
        return true;
      } else if (this.disabledDates && this.dateIsInDisabledArray(date)) {
        return true;
      } else if (this.disabledDays && this.dateIsInDisabledDay(date)) {
        return true;
      }

      return false;
    },
    dateIsAfterMinimumDays(date) {
      return date < addDays(parseISO(this.todayUTC), this.minimumDays);
    },
    dateIsBeforeMaximumDays(date) {
      return date > addDays(parseISO(this.todayUTC), this.maximumDays);
    },
    dateIsWeekend(date) {
      return isWeekend(date);
    },
    dateIsFriday(date) {
      return isFriday(date);
    },

    dateIsPast(date) {
      return !isToday(date) && isPast(date);
    },
    dateIsBeforeFirstAvailableDate(date) {
      return (
        date < addDays(parseISO(this.firstAvailableDate), this.minimumDays)
      );
    },
    dateIsFuture(date) {
      return isFuture(date);
    },
    dateIsInDisabledDay(date) {
      return this.disabledDays.some(
        disabledDay => disabledDay === date.getDay()
      );
    },
    dateIsInDisabledArray(date) {
      return this.disabledDates.some(
        disabledDate =>
          format(new Date(disabledDate.value), this.dateFormat) ===
          format(date, this.dateFormat)
      );
    }
  }
});
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$duration: 0.3s;
$timing: cubic-bezier(0.23, 1, 0.32, 1);
$cell-border-radius: 999px;

@import '~vue2-datepicker/scss/index.scss';

@mixin typographyDatepicker() {
  font-family: $global-font-family;
  font-size: $global-font-size;
}

.mx-datepicker {
  max-width: 100%;
  width: 100%;

  .mx-icon-calendar {
    fill: $c-pm-500;
    right: $u-250;
  }

  .icon-calendar {
    fill: $c-pm-500;
    height: $u-400;
    width: $u-400;
  }
  .icon-clear {
    fill: $c-nt-600;
    height: $u-400;
    width: $u-400;
  }

  @include mq($screen-sm, max) {
    .mx-input-wrapper:hover {
      .mx-icon-clear {
        display: none;
        + .mx-icon-calendar {
          display: block;
        }
      }
    }
  }
}

.mx-datepicker-popup {
  box-sizing: border-box;

  &-left-bottom {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}

.mx-datepicker-main {
  border-radius: 3px;
  box-shadow: $box-shadow-hover;
  max-width: 100%;
  width: 384px;
  padding: $u-400;
  color: $color-black;

  .mx-calendar {
    width: 100%;
    padding: 0;
    + .mx-calendar {
      display: none !important;
    }
  }

  .mx-calendar-content {
    height: auto;
  }

  .mx-calendar-panel-month,
  .mx-calendar-panel-year {
    .cell {
      background: none;
      text-transform: capitalize;

      @include typographyDatepicker();

      > div {
        align-items: center;
        border-radius: $cell-border-radius;
        display: flex;
        justify-content: center;
        min-height: $u-500;
        padding: 0 $u-300;
        &:hover {
          background-color: $c-pm-200;
        }
      }

      &.active > div {
        background-color: $c-pm-500;
      }
    }
  }

  .mx-calendar-panel-date {
    flex-direction: row;
    justify-content: center;

    .cell {
      background: transparent;

      height: 48px;
      @include mq($screen-tiny, max) {
        height: 38px;
      }

      position: relative;

      > div {
        position: relative;
        z-index: 3;
      }

      &.disabled {
        background-color: $c-nt-100;
        &.not-current-month {
          background-color: $c-nt-100;
        }
      }

      &.today:not(.active) {
        position: relative;
        &::before,
        &::after {
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
        }
        &::after {
          background-color: $c-pm-200;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
          z-index: 2;
        }
        > div {
          position: relative;
          z-index: 3;
        }
      }

      &:not(.disabled) {
        &.today:not(.active) {
          color: $color-black;
        }
        &:not(.active):hover {
          color: $color-black;
          position: relative;
          &::after {
            background-color: $c-pm-200;
            border-radius: $cell-border-radius;
            left: 0;
            width: 100%;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
      }

      &::before {
        z-index: 1;
      }

      &::after {
        z-index: 2;
      }

      &.in-range,
      &.hover-in-range {
        background: $c-pm-100;
        border-radius: 0;
        + .active::before {
          left: 0;
          right: auto;
          width: 50%;
        }
      }

      &:hover + .hover-in-range {
        box-shadow: -16px 0 0 $c-pm-100;
      }

      &.hover-in-range + :hover {
        background: transparent;
        position: relative;
        &::before {
          background-color: $c-pm-100;
          left: 0;
          width: 50%;
        }
        &::after {
          background-color: $c-pm-200;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
        }
      }

      &.active {
        &::before {
          background-color: $c-pm-100;
          right: 0;
        }
        &::after {
          background-color: $c-pm-500;
          border-radius: $cell-border-radius;
          left: 0;
          width: 100%;
        }

        + .in-range,
        + .hover-in-range {
          box-shadow: -20px 0 0 $c-pm-100;
        }
      }
    }
  }

  .mx-btn {
    color: $c-pm-500;
  }

  .mx-btn-current-month,
  .mx-btn-current-year {
    text-transform: capitalize;
  }

  .mx-calendar-header-label .mx-btn {
    @include typographyDatepicker();
    font-weight: bold;
  }

  .mx-table-date th {
    @include typographyDatepicker();

    text-transform: capitalize;
  }

  .mx-table-date thead th {
    @include typographyDatepicker();
    font-weight: bold;
  }

  .mx-table-date td {
    @include typographyDatepicker();

    height: $u-600;
  }

  &.mx--disable-month-and-years {
    .mx-btn-current-month,
    .mx-btn-current-year {
      color: $color-black;
      pointer-events: none;
    }
  }
}

.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transform: translateY(0);
  transform-origin: center top;
  transition: transform $duration $timing, opacity $duration $timing;
}

.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 0;
  transform: translateY(-$u-300);
}
</style>
