export const createNamespace = function(namespace, names) {
  const namespaced = names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: namespace + '/' + name
    }),
    {}
  );

  return namespaced;
};
