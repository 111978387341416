/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */

/**
 * OneBlock Widgets.
 * @module oneblock-widgets.mjs
 * @version 1.0.03
 * @summary 23-10-2019
 * @author Mads Stoumann
 * @description Custom Widgets and extensions for OneBlock, can be added though data-js, data-js-event and data-js-param.
 * NOTE: Either import or paste the code for custom widgets in this module.
 */

/**
 * @function countDown
 * @param {Event} event
 * @param {Node} hook
 * @param {String} selector
 * @param {String} timeStamp
 * @param {String} lblDays
 * @param {String} lblHours
 * @param {String} lblMins
 * @param {String} lblSecs
 * @description Renders a countDown-widget in `selector`-element. Creadits to: https://codepen.io/SitePoint/pen/QpwRxz
 */
export function countDown(event, hook, selector, timeStamp, lblDays, lblHours, lblMins, lblSecs) {
	const ob = hook.closest('.c-ob');
	if (!ob) { return false; }
	const clock = ob.querySelector(`.${selector}`)
	const endTime = new Date(timeStamp).getTime();
	
	function getRemainingTime(deadline) {
		const currentTime = new Date().getTime();
		return deadline - currentTime;
	}

	function pad(value) {
		return ('0' + Math.floor(value)).slice(-2);
	}

	function showTime() {
		const remainingTime = getRemainingTime(endTime);
		const seconds = pad((remainingTime / 1000) % 60);
		const minutes = pad((remainingTime / (60 * 1000)) % 60);
		const hours = pad((remainingTime / (60 * 60 * 1000)) % 24);
		const days = pad(remainingTime / (24 * 60 * 60 * 1000));
		clock.innerHTML = `
		<div class="c-clock">
			<div class="c-clock__item">${days}<span>${lblDays}</span></div>
			<div class="c-clock__item">${hours}<span>${lblHours}</span></div>
			<div class="c-clock__item">${minutes}<span>${lblMins}</span></div>
			<div class="c-clock__item">${seconds}<span>${lblSecs}</span></div>
		</div>`

		if (remainingTime >= 1000) {
			requestAnimationFrame(showTime);
		}
	}
	requestAnimationFrame(showTime);
}

/**
 * @function scrollToElm
 * @param {Event} event
 * @param {Node} hook
 * @param {String} selector
 * @description Scrolls to element matching `selector`, or to the bottom of hook-parent
 */
export function scrollToElm(event, hook, selector) { 
	const ob = hook.closest('.c-ob');
	if (!ob) { return false; }
	const element = selector ? ob.querySelector(`.${selector}`) : ob;
	const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
	if (element) {
		const rect = element.getBoundingClientRect();
		if (isIE11) {
			window.scrollTo(0, selector ? rect.top : rect.bottom);
		}
		else {
			window.scrollBy({ 
				top: selector ? rect.top : rect.bottom,
				behavior: 'smooth' 
			});
		}
	}
}

/**
 * @function getBrand
 * @description Return current brand
 */
export function getBrand() {
	const hostname = window.location.hostname;
  	if (hostname.includes('callme.dk')) {
    	return 'CALLME';
  	} else if (hostname.includes('norlys')) {
    	return 'NORLYS';
  	} else {
    	return 'TELIA';
  	}
}

/**
 * @function resolveMetaTagContent
 * @param {NodeList} metaTags
 * @param {String} itemprop
 * @description Resolve meta tag content from NodeList of <meta />
 */
export function resolveMetaTagContent(metaTags, itemprop) {
	return (
		Array.from(metaTags).filter(
			mt => mt.getAttribute('itemprop') === itemprop
		)[0]?.content ?? ''
	);
}
