<template>
  <div class="sim-card-selection">
    <legend class="sim-card-selection__legend">
      {{ options.legendText }}
    </legend>

    <c-form-radio-group
      v-model="localSelectedSimcardType"
      name="simOption"
      stacked
      highlighted
    >
      <c-form-radio
        v-for="option in filteredSimOptions"
        :id="option.value"
        :key="option.value"
        :value="option.value"
        :description-text="option.descriptionText"
        :has-tooltip="radioHasTooltip(option)"
        :tooltip-description-text="showTooltipDescriptionText(option)"
        :tooltip-trigger-text="showTooltipTriggerText(option)"
      >
        <span>{{ option.label }}</span>
      </c-form-radio>
    </c-form-radio-group>

    <c-input-group
      v-if="localSelectedSimcardType === options.newSimOptionValue"
      v-model="localNewSimNumber"
      name="newSimNumber"
      :input-error="newSimNumberError"
      :place-holder-label="options.placeholderLabel"
    />
  </div>
</template>

<script>
import { CFormRadio, CFormRadioGroup } from 'olympus/components';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import { SIM_OPTIONS } from 'webshop/enums/sim-options';
import {
  requiredIf,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators/index.js';
import { mapActions, mapGetters } from 'vuex';
import {
  GETTERS as PRODUCT_CONFIG_GETTERS,
  ACTIONS as PRODUCT_CONFIG_ACTIONS
} from 'webshop/store/product-config/index.js';

export default {
  name: 'SimCardSelection',

  components: { CFormRadio, CFormRadioGroup, CInputGroup },

  props: {
    options: {
      type: Object,
      required: true
    },
    configData: {
      type: Object,
      default: () => ({})
    },
    checkedSimRadio: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      localSelectedSimcardType: this.checkedSimRadio || '',
      localNewSimNumber: '',
      SIM_OPTIONS
    };
  },

  validations: {
    localNewSimNumber: {
      required: requiredIf(
        localSelectedSimcardType =>
          localSelectedSimcardType === SIM_OPTIONS.NEW_SIM
      ),
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(20)
    }
  },

  computed: {
    ...mapGetters({
      getErrorByMemberName: PRODUCT_CONFIG_GETTERS.GET_ERROR_BY_MEMBER_NAME
    }),

    newSimNumberError() {
      if (this.localNewSimNumber && this.$v.localNewSimNumber.$invalid) {
        return this.getErrorByMemberName(['NewSimNumber']);
      }

      this.removeErrors(['NewSimNumber']);

      return '';
    },

    /**
     * Filtering sim options based on which labels are coming from the backend.
     * @var incomingSimOptionsFromBE Array in form of ['NewEsim', 'NewSim'] etc.
     * @var portingConfigCMSoptions CMS properties, associated with @method getSimCardSettings
     */
    filteredSimOptions() {
      const incomingSimOptionsFromBE = this.configData.attributes.simOptions;
      const portingConfigCMSoptions = this.options.simOptions;

      return portingConfigCMSoptions.filter(option =>
        incomingSimOptionsFromBE.includes(option.value)
      );
    },

    radioHasTooltip() {
      return option => this.isEsimOption(option);
    },

    showTooltipTriggerText() {
      return option =>
        this.isEsimOption(option) ? option.tooltipTriggerText : '';
    },

    showTooltipDescriptionText() {
      return option =>
        this.isEsimOption(option) ? option.tooltipDescriptionText : '';
    }
  },

  methods: {
    ...mapActions({
      removeErrors: PRODUCT_CONFIG_ACTIONS.REMOVE_ERRORS
    }),

    isEsimOption(option) {
      return option.value === SIM_OPTIONS.ESIM;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.sim-card-selection {
  &__legend {
    margin-bottom: $u-250;
    font-weight: 700;
  }
}
</style>
