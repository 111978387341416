<template>
  <div class="cm-c-filter-nav__block" :class="facet.facetCssClass">
    <div class="cm-c-filter-nav__head">
      <h3 class="cm-c-filter-nav__title">
        {{ facet.displayName }}
      </h3>
      <p v-if="showReset" class="cm-c-filter-nav__link-del">
        <a @click="resetFacetGroup(facet.name)">{{ resetText }}</a>
      </p>
    </div>
    <div
      ref="facetBody"
      :class="{
        'cm-c-filter-nav__body--show-all':
          !truncateFacetLines || allFacetItemsShown
      }"
      class="cm-c-filter-nav__body"
      :style="{ maxHeight: maxBodyHeight }"
    >
      <product-category-facet-item
        v-for="(item, itemkey) in facet.values"
        :key="itemkey"
        :item="item"
        :facet="facet.name"
        :facet-type="facet.facetType"
        :facet-property-name="facet.propertyName"
      ></product-category-facet-item>
    </div>

    <template v-if="isTruncated || allFacetItemsShown">
      <button
        type="button"
        class="cm-c-filter-nav__toggle cm-c-filter-nav__toggle--icon-right"
        @click="toggleShowAllFacets"
      >
        <template v-if="allFacetItemsShown">
          {{ facetShowFewerLabel }}
          <c-icon
            class="cm-c-filter-nav__toggle__icon"
            symbol-id="arrow-up"
          ></c-icon>
        </template>

        <template v-else>
          {{ facetShowAllLabel }}
          <c-icon
            class="cm-c-filter-nav__toggle__icon"
            symbol-id="arrow-down"
          ></c-icon>
        </template>
      </button>
    </template>
  </div>
</template>

<script>
import ProductCategoryFacetItem from './product-category-facet-item.vue';

export default {
  name: 'ProductCategoryFacet',

  components: {
    'product-category-facet-item': ProductCategoryFacetItem
  },

  props: {
    facet: {
      type: Object,
      default: undefined
    },
    resetText: {
      type: String,
      default: ''
    },
    showReset: {
      type: Boolean,
      default: true
    },
    facetShowAllLabel: {
      type: String,
      default: ''
    },
    facetShowFewerLabel: {
      type: String,
      default: ''
    },
    facetMaxLines: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      expandToggleObserver: null,
      isTruncated: false,
      allFacetItemsShown: false,
      facetItemHeight: undefined,
      facetItemMarginBottom: undefined
    };
  },

  computed: {
    truncateFacetLines() {
      return this.facetMaxLines !== -1;
    },

    maxBodyHeight() {
      const restrictHeight =
        this.truncateFacetLines && !this.allFacetItemsShown;

      if (restrictHeight) {
        if (this.facetItemHeight && this.facetItemMarginBottom) {
          return (
            (parseInt(this.facetItemMarginBottom, 10) +
              parseInt(this.facetItemHeight, 10)) *
              this.facetMaxLines +
            'px'
          );
        }
      }

      return 'none';
    }
  },

  mounted() {
    const facetBodyObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const facetBody = entry;

        if (facetBody.target.scrollHeight > facetBody.contentRect.height) {
          this.isTruncated = true;
        } else {
          this.isTruncated = false;
        }
      }
    });

    facetBodyObserver.observe(this.$refs.facetBody);
    this.expandToggleObserver = facetBodyObserver;

    const facetBodyStyle = getComputedStyle(this.$refs.facetBody);
    this.facetItemHeight = facetBodyStyle.getPropertyValue(
      '--facet-item-height'
    );
    this.facetItemMarginBottom = facetBodyStyle.getPropertyValue(
      '--facet-item-margin-bottom'
    );
  },

  beforeDestroy() {
    this.expandToggleObserver.unobserve(this.$refs.facetBody);
  },

  methods: {
    // TODO DKT-1456: this should be refactored to eliminate any query selectors. Facet items can react to a state change themselves.
    resetFacetGroup: function (facetGroup) {
      const items = document.querySelectorAll(
        '[data-group=' + facetGroup + ']'
      );
      if (items.length) {
        items.forEach(item => (item.checked = false));
      }
      this.$store.dispatch('resetFacetGroup', { name: facetGroup });

      const currentRoute = this.$router.currentRoute;

      const query = Object.keys(currentRoute.query).reduce((obj, k) => {
        if (k !== this.facet.propertyName) {
          obj[k] = currentRoute.query[k];
        }
        return obj;
      }, {});

      this.$router.replace({
        ...currentRoute,
        query
      });
    },

    toggleShowAllFacets() {
      this.allFacetItemsShown = !this.allFacetItemsShown;
    }
  }
};
</script>
