var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    { ref: "vasModal", attrs: { wide: "", large: "", gray: "" } },
    [
      _c("c-vas-overview", {
        attrs: {
          "block-id": _vm.vasCheckoutModuleBlockId,
          "group-id": _vm.group.id
        }
      }),
      _vm._v(" "),
      _vm.group
        ? _c(
            "button",
            {
              staticClass: "c-btn c-btn--wide c-btn--block c-btn--align-center",
              on: { click: _vm.closeVasModal }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.content.closeVasOverlayText) + "\n  "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }