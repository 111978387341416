var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "overlay",
      class: [_vm.isInline ? "imagegallery__hidden" : "imagegallery__overlay"],
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeGallery($event)
        }
      }
    },
    [
      _c("nav", { staticClass: "imagegallery__wrapper" }, [
        !_vm.isInline
          ? _c(
              "button",
              {
                ref: "close",
                staticClass: "imagegallery__close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.closeGallery($event)
                  }
                }
              },
              [_vm._t("nav-close", [_vm._v("✕")])],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "inner",
            staticClass: "imagegallery__inner",
            attrs: { itemscope: "", itemtype: "http://schema.org/ImageGallery" }
          },
          _vm._l(_vm.images, function(image, imageindex) {
            return _c(
              "figure",
              {
                key: imageindex,
                staticClass: "imagegallery__image__wrapper",
                attrs: {
                  itemprop: "associatedMedia",
                  itemscope: "",
                  itemtype: [
                    image.type === "video"
                      ? "http://schema.org/VideoObject"
                      : "http://schema.org/ImageObject"
                  ]
                }
              },
              [
                image.type === "video"
                  ? _vm._t("default", [
                      _c("meta", {
                        attrs: {
                          itemprop: "description",
                          content: image.description
                        }
                      }),
                      _vm._v(" "),
                      _c("meta", {
                        attrs: { itemprop: "name", content: image.caption }
                      }),
                      _vm._v(" "),
                      _c("meta", {
                        attrs: {
                          itemprop: "thumbnailUrl",
                          content: _vm.domain + image.thumbnail
                        }
                      }),
                      _vm._v(" "),
                      _c("meta", {
                        attrs: {
                          itemprop: "uploadDate",
                          content: image.uploadDate
                        }
                      }),
                      _vm._v(" "),
                      _vm.videoLocal(image.src)
                        ? _c(
                            "video",
                            {
                              staticClass: "imagegallery__video",
                              attrs: { controls: "" }
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: image.src,
                                  type: _vm.videoType(image.src),
                                  tabindex: "-1"
                                }
                              })
                            ]
                          )
                        : _c("iframe", {
                            staticClass: "imagegallery__iframe",
                            attrs: {
                              src: image.src.replace("watch?v=", "embed/"),
                              frameborder: "0",
                              tabindex: "-1",
                              allow: "autoplay; encrypted-media",
                              allowfullscreen: ""
                            }
                          })
                    ])
                  : _c("img", {
                      staticClass: "imagegallery__image",
                      attrs: {
                        src: image.srcCurrent + "?w=768",
                        srcset: _vm.getSrcSet(image.srcCurrent),
                        itemprop: "image",
                        alt: image.caption,
                        ondragstart: "return false"
                      }
                    }),
                _vm._v(" "),
                !_vm.isInline
                  ? _c("figcaption", {
                      staticClass: "imagegallery__caption",
                      attrs: { itemprop: "caption description" }
                    })
                  : _vm._e(),
                _vm._v(" "),
                image.copyright
                  ? _c("meta", {
                      attrs: {
                        itemprop: "copyrightHolder",
                        content: image.copyright
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.imageList.length &&
        _vm.imageList.length > 1 &&
        _vm.selectedImageIndex != 0
          ? _c(
              "button",
              {
                staticClass: "imagegallery__nav--left",
                attrs: {
                  type: "button",
                  rel: "prev",
                  "aria-label": _vm.labelPrev
                },
                on: {
                  click: function($event) {
                    return _vm.navGallery(false)
                  }
                }
              },
              [
                _vm._t("nav-left", [
                  _c(
                    "svg",
                    {
                      staticClass: "imagegallery__svg",
                      attrs: { viewBox: "0 0 50 100" }
                    },
                    [_c("path", { attrs: { d: "M50 0 L0 50 L50 100" } })]
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.imageList.length &&
        _vm.imageList.length > 1 &&
        _vm.selectedImageIndex != _vm.imageList.length - 1
          ? _c(
              "button",
              {
                staticClass: "imagegallery__nav--right",
                attrs: {
                  type: "button",
                  rel: "next",
                  "aria-label": _vm.labelNext
                },
                on: { click: _vm.navGallery }
              },
              [
                _vm._t("nav-right", [
                  _c(
                    "svg",
                    {
                      staticClass: "imagegallery__svg",
                      attrs: { viewBox: "0 0 50 100" }
                    },
                    [_c("path", { attrs: { d: "M0 0 L50 50 L0 100" } })]
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isInline && _vm.zoomInline,
                expression: "isInline && zoomInline"
              }
            ],
            staticClass: "imagegallery__nav--zoom",
            attrs: { type: "button", "aria-label": _vm.labelZoom },
            on: { click: _vm.openGallery }
          },
          [
            _vm._t("nav-zoom", [
              _c(
                "svg",
                {
                  staticClass: "imagegallery__svg",
                  attrs: { viewBox: "0 0 100 100" }
                },
                [
                  _c("circle", { attrs: { r: "25", cx: "40", cy: "40" } }),
                  _vm._v(" "),
                  _c("line", {
                    attrs: { x1: "60", y1: "60", x2: "80", y2: "80" }
                  })
                ]
              )
            ])
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "imagegallery__hidden",
            attrs: { "aria-live": "polite", "aria-atomic": "true" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.selectedImageIndex + 1) +
                " / " +
                _vm._s(_vm.imageLen) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.isInline
        ? _c(
            "nav",
            {
              staticClass: "imagegallery__dots",
              attrs: {
                itemscope: "",
                itemtype: "http://www.schema.org/SiteNavigationElement"
              }
            },
            _vm._l(_vm.images, function(dot, dotindex) {
              return _c("span", {
                key: dotindex,
                staticClass: "imagegallery__dot",
                class: { "-selected": dotindex === _vm.selectedImageIndex },
                on: {
                  click: function($event) {
                    return _vm.goToSlide(dotindex)
                  }
                }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isInline && !_vm.thumbnailsInline
        ? _c(
            "nav",
            {
              staticClass: "imagegallery__thumbbar",
              attrs: {
                itemscope: "",
                itemtype: "http://www.schema.org/SiteNavigationElement"
              }
            },
            _vm._l(_vm.images, function(thumb, thumbindex) {
              return _c(
                "figure",
                {
                  key: thumbindex,
                  staticClass: "imagegallery__thumb",
                  class: {
                    "-selected": thumbindex === _vm.selectedImageIndex,
                    "-videoicon": thumb.type === "video" && _vm.thumbVideoIcon
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToSlide(thumbindex)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: thumb.thumbnailCurrent + "?w=75",
                      itemprop: "name",
                      alt: thumb.caption
                    }
                  })
                ]
              )
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }