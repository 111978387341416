var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shopping-cart-purchase-member" }, [
    _c(
      "div",
      { staticClass: "shopping-cart-purchase-member__icon-container" },
      [
        _c("c-icon", {
          staticClass: "shopping-cart-purchase-member__icon",
          attrs: {
            "symbol-id": "service-guiding",
            "data-testid": "purchase-member-icon"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.reachedMaxMemberCount
      ? _c("div", { staticClass: "shopping-cart-purchase-member__desc" }, [
          _c("h3", [
            _vm._v(
              _vm._s(_vm.contentLocal.purchaseMemberMaxMemberCountReachedText)
            )
          ])
        ])
      : _c("div", { staticClass: "shopping-cart-purchase-member__desc" }, [
          _vm.contentLocal.purchaseMemberIntroText &&
          _vm.offering &&
          _vm.getRelatedMaster
            ? _c("h3", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.populateContentProperty(
                      _vm.contentLocal.purchaseMemberIntroText,
                      {
                        maxMembers: _vm.offering.maxLimit,
                        subscriptionName: _vm.getRelatedMaster.name.display
                      }
                    )
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.contentLocal.purchaseMemberDescription &&
          _vm.offering &&
          _vm.getRelatedMaster
            ? _c("small", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.populateContentProperty(
                      _vm.contentLocal.purchaseMemberDescription,
                      {
                        maxMembers: _vm.offering.maxLimit,
                        subscriptionName: _vm.getRelatedMaster.name.display,
                        subscriptionMemberName: _vm.offering.name.display
                      }
                    )
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.contentLocal.purchaseMemberButtonText && _vm.offering
            ? _c("button", {
                staticClass:
                  "\n        c-btn c-btn--light c-btn--sm\n        shopping-cart-purchase-member__button\n      ",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.populateContentProperty(
                      _vm.contentLocal.purchaseMemberButtonText,
                      {
                        subscriptionMemberName: _vm.offering.name.display,
                        subscriptionMemberPrice:
                          _vm.offering.price.formattedValueCurrency
                      }
                    )
                  )
                },
                on: { click: _vm.add }
              })
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }