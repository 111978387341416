// TODO: move the toast events here, instead of a plugin, because not every component
// needs to know about them, only the ones that inject it.
/**
 * Use as injector of theme variant values.
 *
 */
const providerKey = 'TOASTS';

const eventToastKeys = {
  ITEM_ADDED_TO_BASKET_SUCCESS: 'itemAddedToBasket',
  ITEM_ADDED_TO_BASKET_ERROR: 'itemNotAddedToBasket',
  ITEM_REMOVED_TO_BASKET_SUCCESS: 'itemRemovedFromBasket'
};

/**
 * Use this mixin with overrides to access
 * top level reference and functionality for icons
 *
 */
export default {
  data() {
    return {
      toasts: {
        ...window.__APP__?.toastMessages
      }
    };
  },
  provide() {
    return {
      [providerKey]: {
        getToastData: eventKey => {
          return this.toasts[eventKey] || {};
        }
      }
    };
  }
};

export { providerKey, eventToastKeys };
