var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "product-promotion-block", class: _vm.modifiers },
    [
      _vm.endTime
        ? _c(
            "ribbon-banner",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCounter,
                  expression: "showCounter"
                }
              ],
              attrs: {
                theme: _vm.notExpired ? "discount" : "assent",
                "flip-position": _vm.flipPosition,
                size: "small"
              }
            },
            [
              _vm._t("ribbon", [
                _vm.notExpired
                  ? _c(
                      "span",
                      { staticClass: "product-promotion-block__ribbon" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "product-promotion-block__ribbon__text"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.expireDateTimeLabel) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.countdownData
                          ? _c("countdown", {
                              attrs: { data: _vm.countdownData },
                              on: { countdownEnded: _vm.onCountdownEnded }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.expiredLabel) + "\n      "
                      )
                    ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imageUrl
        ? _c("figure", { staticClass: "product-promotion-block__image" }, [
            _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.imageAlt } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "product-promotion-block__content" }, [
        _c("h3", { staticClass: "product-promotion-block__heading" }, [
          _vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "product-promotion-block__description" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.priceText) } })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }