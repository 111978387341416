var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "c-dropdown",
          class: {
            "c-dropdown--searchable": _vm.filterable,
            "c-dropdown--skinny": _vm.skinny
          },
          attrs: { value: _vm.selectedLocal },
          scopedSlots: _vm._u([
            {
              key: "spinner",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  loading && _vm.enableLocalLoader
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "c-dropdown__loader cm-c-spinner cm-c-spinner--local"
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading...")
                          ])
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "c-dropdown__icon",
                          class: {
                            "c-dropdown__icon--touchable": _vm.selectedLocal
                          },
                          on: { click: _vm.clearSelected }
                        },
                        [
                          _c("c-icon", {
                            attrs: {
                              "symbol-id": !_vm.filterable
                                ? "arrow-down"
                                : !_vm.selectedLocal
                                ? "menu-search"
                                : "menu-close"
                            }
                          })
                        ],
                        1
                      )
                ]
              }
            },
            {
              key: "search",
              fn: function(inputScope) {
                return [
                  _c(
                    "input",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filterable,
                              expression: "filterable"
                            }
                          ],
                          staticClass: "vs__search",
                          class: {
                            "vs__search--empty":
                              !inputScope.attributes.value && !_vm.selectedLocal
                          },
                          attrs: {
                            id: "c-dropdown",
                            maxlength: _vm.attributes.maxInputLength
                          }
                        },
                        "input",
                        inputScope.attributes,
                        false
                      ),
                      inputScope.events
                    )
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "c-dropdown" } }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.searchInputPlaceholder) + "\n    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { type: "hidden", name: _vm.inputName },
                    domProps: { value: _vm.inputValue }
                  })
                ]
              }
            }
          ])
        },
        "v-select",
        Object.assign({}, _vm.attributes, {
          filterable: !_vm.serverSideFiltering && _vm.filterable
        }),
        false
      ),
      Object.assign({}, _vm.events, { input: _vm.emitState })
    ),
    [
      _vm._v(" "),
      _c("span", { attrs: { slot: "open-indicator" }, slot: "open-indicator" }),
      _vm._v(" "),
      _c("template", { slot: "no-options" }, [_vm._t("no-options")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }