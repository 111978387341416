<template>
  <div class="c-modal-wrapper-inner">
    <header class="c-modal-content__header">
      <h3 v-html="contentObject.headline"></h3>
      <p v-html="contentObject.description"></p>
    </header>

    <img v-if="contentObject.image" :src="contentObject.image" />

    <div
      v-if="contentObject.details"
      class="c-modal-content__section cm-c-total cm-mb-top--md"
    >
      <div
        v-for="rowsItem in contentObject.details.rows"
        :key="rowsItem.property"
      >
        <div
          v-for="row in rowsItem"
          :key="row.property"
          class="cm-c-total__row"
        >
          <div class="cm-c-total__name" v-html="row.property"></div>
          <div class="cm-c-total__value" v-html="row.value"></div>
        </div>
      </div>

      <div
        v-if="
          contentObject.details.total && contentObject.details.total.property
        "
        class="cm-c-total__row"
      >
        <div class="cm-c-total__name">
          <strong v-html="contentObject.details.total.property"></strong>
        </div>
        <div
          class="cm-c-total__value"
          v-html="contentObject.details.total.value"
        ></div>
      </div>
      <div
        v-if="contentObject.details.terms"
        class="cm-c-total__row cm-c-modal__terms"
      >
        <div v-for="(term, index) in contentObject.details.terms" :key="index">
          <div v-html="term"></div>
        </div>
      </div>
    </div>

    <footer class="c-modal-content__footer">
      <div
        :class="[contentObject.buttonLink ? 'cm-text-center' : 'cm-text-right']"
      >
        <a
          v-if="contentObject.buttonLink"
          class="c-btn c-btn--primary"
          :href="contentObject.buttonLink"
          v-html="contentObject.buttonLabel"
        ></a>
        <button
          v-else
          class="c-btn c-btn--primary"
          @click="HIDE_DIALOG"
          v-html="contentObject.buttonLabel"
        ></button>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ContentDialog',

  props: {
    contentObject: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    ...mapMutations(['HIDE_DIALOG'])
  }
};
</script>
