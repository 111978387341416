<template>
  <div v-if="inputType === 'radio'" class="cm-c-filter-nav__block">
    <div class="cm-c-filter-nav__head">
      <h3 class="cm-c-filter-nav__title">
        {{ pricePlan.header }}
      </h3>
    </div>
    <div class="cm-c-filter-nav__body">
      <label
        v-for="(price, index) in pricePlan.values"
        :key="index"
        class="cm-c-filter-nav__item cm-c-radio"
      >
        <input
          v-model="pricePlan.value"
          type="radio"
          name="priceplan"
          class="cm-c-radio__o-input"
          :value="price.value"
          @click="setPricePlan(price.value)"
        />
        <span class="cm-c-radio__input"></span>
        <span v-radio="price.name" class="cm-c-radio__label"></span>
      </label>
    </div>
  </div>
  <div
    v-else-if="inputType === 'select'"
    class="cm-c-sort-nav cm-c-sort-nav--simple cm-c-sort-nav--push-right"
  >
    <div class="cm-c-sort-nav__control-group">
      <div class="cm-c-form-control__select cm-c-sort-nav__control">
        <label for="s-1" class="cm-c-sort-nav__label">
          {{ selectLabel }}
          <c-icon class="cm-c-sort-nav__label__icon" symbol-id="arrow-down" />
        </label>

        <select id="s-1" name="s-1" @change="setPricePlan($event.target, true)">
          <option
            v-for="(price, index) in pricePlan.values"
            :key="index"
            :value="price.value"
          >
            {{ price.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-category-price',
  directives: {
    radio: {
      bind: function(el, binding) {
        const [label, installment] = binding.value.split(':');
        el.innerHTML = installment
          ? `<span class="cm-c-filter-price">${label}:</span><span class="cm-c-filter-price__value">${installment}</span>`
          : binding.value;
      }
    }
  },
  props: {
    inputType: {
      type: String,
      default: 'radio'
    }
  },
  data() {
    return {
      selectLabel: ''
    };
  },
  computed: {
    pricePlan: function() {
      return Object.assign(
        {},
        this.$store.state.shop.productSearchResult.pricePlanFilter,
        { value: this.$store.state.shop.searchSettings.pricePlanFilterValue }
      );
    }
  },
  watch: {
    pricePlan(price) {
      if (price && price.value && price.values && price.values.length) {
        this.selectLabel = price.values.filter(obj => {
          return obj.value === parseInt(price.value, 10);
        })[0].name;
      }
    }
  },
  methods: {
    setPricePlan: function(price, select = false) {
      let thisPrice = price;
      if (select) {
        thisPrice = price.value;
      }
      this.$store.dispatch('selectPricePlan', thisPrice);
    }
  }
};
</script>
