<template>
  <div>
    <c-card
      ref="fieldset"
      :key="`numberexisting${keyToReRender}`"
      class="cm-numcon"
    >
      <div class="cm-numcon__config">
        <div class="cm-numcon__product">
          <div class="cm-numcon__product__info">
            <img
              :src="numberConfigActiveProduct.img"
              class="cm-numcon__image"
              :alt="numberConfigActiveProduct.subscriptionName"
            />
            <section class="cm-numcon__product__text">
              <p
                v-if="numberConfigVM.subscriptionLabel"
                class="cm-numcon__product__pre-label"
              >
                {{ numberConfigVM.subscriptionLabel }}
              </p>

              <h2
                v-if="numberConfigActiveProduct.subscriptionName"
                class="
                  cm-numcon__product__header
                  cm-numcon__product__header--specific
                "
              >
                {{ numberConfigActiveProduct.subscriptionName }}
              </h2>
              <div
                v-if="numberConfigActiveProduct.handsetName"
                class="cm-numcon__product__header"
              >
                {{ numberConfigActiveProduct.handsetName }}
              </div>
            </section>
          </div>
        </div>

        <div class="cm-numcon__options">
          <div
            class="cm-numcon__options__item cm-numcon__options__item--active"
          >
            <div
              class="cm-numcon__options__link cm-numcon__options__link--default"
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="service-sync" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{
                  numberConfigVM.existingCustomerOverviewHeadline
                }}</strong>
              </span>
            </div>

            <div class="cm-numcon__options__content">
              <div
                v-if="!numberConfigVM.enableExistingNumberSelection"
                class="cm-grid mb-4"
              >
                <div class="cm-grid__col--xs-12 cm-grid__col--sm">
                  <c-input-group
                    v-model="phoneNumber"
                    type="tel"
                    :input-error="phoneNumberError"
                    :place-holder-label="
                      numberConfigVM.existingNumberPlaceholderText
                    "
                  ></c-input-group>
                </div>
              </div>

              <template v-else>
                <div v-if="!init" class="cm-c-spinner cm-c-spinner--local">
                  <span class="sr-only">Loading...</span>
                </div>
                <template v-else-if="existingNumbers && existingNumbers.length">
                  <p class="cm-c-checkout__text">
                    {{ numberConfigVM.existingCustomerDescription }}
                  </p>
                  <user-card
                    v-for="{
                      id,
                      name,
                      phoneNumber,
                      isAvailable,
                      errorMessage
                    } in existingNumbers"
                    :key="id"
                    :subscription-name="name"
                    :phone-number="phoneNumber"
                    :disabled="!isAvailable"
                    :right-action-button-text="
                      numberConfigVM.selectExistingNumberButtonText
                    "
                    :right-action-disabled-button-text="
                      numberConfigVM.selectExistingNumberDisabledButtonText
                    "
                    @rightActionPressed="onRightActionPressed"
                  >
                    <span v-if="errorMessage" v-html="errorMessage"></span>
                  </user-card>
                </template>
                <p v-else class="cm-c-checkout__text">
                  {{ numberConfigVM.noExistingNumberAvailableLabel }}
                </p>
              </template>

              <number-simcard-info
                v-if="
                  IS_THIRD_PARTY_RESELLER &&
                  numberConfigVM.showSimDeliveryOptions
                "
                :key="`simcardinfo${keyToReRender}`"
                :active-product="$data"
                :is-existing-number="true"
              />
            </div>
          </div>
        </div>
      </div>
    </c-card>
    <div class="cm-numcon__actions">
      <c-button to="/" variant="secondary">Tilbage</c-button>
      <c-button
        v-if="
          !numberConfigVM.enableExistingNumberSelection ||
          (existingNumbers && existingNumbers.length)
        "
        :disabled="!ready"
        @click="accept"
      >
        {{ numberConfigVM.acceptNumberButtonText }}
      </c-button>
    </div>
  </div>
</template>

<script>
import { getNumberConfigurationGroupsByGroupId } from '../../api/checkout-service.js';
import numberConfigMixin from './numberConfigMixin.js';
import NumberSimcardInfo from './number-simcard-info.vue';
import CCard from 'webshop/components/c-card/c-card.vue';
import UserCard from '../c-user-card/c-user-card.vue';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import { CButton } from 'olympus/components/index.js';

import {
  required,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators/index.js';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

export default {
  name: 'NumberExisting',

  components: { UserCard, CCard, NumberSimcardInfo, CInputGroup, CButton },

  mixins: [numberConfigMixin],

  data() {
    return {
      init: false,
      existingNumbers: []
    };
  },

  computed: {
    phoneNumberError() {
      return this.$v.phoneNumber.$invalid &&
        this.$v.phoneNumber.$model.length >= 9
        ? this.numberConfigVM.errorMessageNumberNotValidMoveNumber
        : '';
    },

    ready() {
      return !this.$v.$invalid;
    }
  },

  validations: {
    phoneNumber: {
      required,
      numeric,
      dkPhoneNumber,
      minLength: minLength(8),
      maxLength: maxLength(8)
    }
  },

  activated() {
    if (this.numberConfigVM.enableExistingNumberSelection) {
      this.init = false;
      this.fetchData();
    }
  },

  methods: {
    onRightActionPressed({ phoneNumber }) {
      this.phoneNumber = phoneNumber;
    },

    fetchData() {
      const groupId = this.numberConfigActiveProduct.groupId;

      return getNumberConfigurationGroupsByGroupId(groupId)
        .then(({ data }) => {
          this.existingNumbers = data;
          this.init = true;
        })
        .catch(() => {
          // TODO dan: should show a UI error
          this.init = true;
        });
    }
  }
};
</script>
