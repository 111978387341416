export const BASKET_ITEM_TYPES = {
  HARDWARE: 'Hardware',
  ACCESSORY: 'Accessories',
  SERVICE: 'Service',
  SUBSCRIPTION: 'Subscription'
};

export const BASKET_ITEM_SUB_TYPES = {
  INSURANCE: 'Insurance',
  VOICE: 'voice',
  DATA: 'data',
  EXTRA_DATA_CARD: 'extradatacard',
  BROADBAND: 'Broadband'
};

export const BASKET_ITEM_ACTIONS = {
  NONE: 'none',
  REMOVE: 'remove',
  TOGGLE: 'toggle'
};

export const BASKET_OFFERING_TYPE = {
  PURCHASE_VAS: 'PurchaseVAS',
  PURCHASE_HANDSET: 'PurchaseHandset',
  PURCHASE_EDC: 'PurchaseExtraDataCard',
  PURCHASE_INSURANCE: 'PurchaseInsurance',
  PURCHASE_MEMBER: 'PurchaseMember'
};
