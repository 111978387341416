<template>
  <config-group-item
    :title="title"
    :icon="icon"
    :expanded="expanded"
    :integrated="true"
    @expanded="handleExpanded"
  >
    <fieldset class="internet-config">
      <!-- We will help you get started  | Subscription not cancelled (DSL) -->
      <div v-if="showProviderChange" class="internet-config__start">
        <h3 class="cm-c-form__header">
          {{ content.broadband.providerChangeSectionTitle }}
        </h3>

        <c-form-radio-group
          v-model="form.cancelationAssist"
          name="cancelationAssist"
          class="internet-config__start__selection"
          :highlighted="true"
          stacked
        >
          <c-form-radio id="first" value="true">
            <div class="internet-config__start__selection__label">
              {{ content.broadband.yesNeedHelpButtonLabel }}
            </div>
          </c-form-radio>
          <c-form-radio id="second" value="false">
            <div class="internet-config__start__selection__label">
              {{ content.broadband.noDontNeedHelpButtonLabel }}
            </div>
          </c-form-radio>
        </c-form-radio-group>
        <c-collapse
          id="showCurrentProviderDisclaimer"
          :visible="showCancellationAssistNeeded"
        >
          <c-information-box
            class="mt-2 mb-2"
            icon="contact-information"
            type="informative"
          >
            <div
              v-html="content.broadband.cancellationAssistReminderText"
            ></div>
          </c-information-box>

          <!-- Who's your current provider? Subscription not cancelled (DSL) | Disabled: no selection, -->
          <h4 class="cm-c-form__header cm-c-form__header--small">
            {{ content.broadband.currentProviderHeadline }}
          </h4>
          <c-dropdown
            v-model="form.currentProvider"
            input-name="currentProvider"
            input-value-key="value"
            :attributes="{
              options: providers
            }"
            :search-input-placeholder="content.broadband.providerPlaceholder"
          >
            <div
              slot="no-options"
              v-html="content.broadband.noResultsText"
            ></div>
          </c-dropdown>
        </c-collapse>
        <c-collapse
          id="showCancellationAssistNotNeededDisclaimer"
          :visible="showCancellationAssistNotNeeded"
        >
          <c-information-box
            class="mt-2"
            icon="contact-information"
            type="informative"
          >
            <div v-html="content.broadband.cancellationAssistNotNeeded"></div>
          </c-information-box>
        </c-collapse>
      </div>

      <!-- Which day should Norlys activate your internet? -->
      <div class="internet-config__section">
        <h3 class="cm-c-form__header">
          {{ content.broadband.activationText }}
        </h3>
        <c-row class="internet-config__section__row">
          <c-col cols="12" class="internet-config__section__row__col-chip">
            <c-form-radio-group
              v-model="form.enableSubscriptionAsap"
              name="startSubscriptionSelect"
              class="internet-config__start__selection"
              :highlighted="true"
              stacked
            >
              <c-form-radio
                id="startSubscriptionAsap"
                value="true"
                data-testid="internet-config__chip-input"
              >
                <div class="internet-config__start__selection__label">
                  {{ content.broadband.activationAsapButtonText }}
                </div>
              </c-form-radio>
              <c-form-radio
                id="startSubscriptionDate"
                value="false"
                data-testid="internet-config__chip-input"
              >
                <div class="internet-config__start__selection__label">
                  {{ content.broadband.activationSpecificDateButtonText }}
                </div>
              </c-form-radio>
            </c-form-radio-group>
          </c-col>
        </c-row>

        <c-information-box
          v-if="showStartDateCannotBeChangedDisclaimer"
          class="internet-config__cancellation-manual-information"
          icon="contact-information"
          type="informative"
        >
          <div
            v-html="content.broadband.startDateCannotBeChangedDisclaimer"
          ></div>
        </c-information-box>

        <c-collapse
          id="datepicker"
          :visible="showDateSelectorStep"
          class="internet-config__select-date"
        >
          <c-row class="internet-config__row">
            <c-col>
              <c-datepicker
                :label="content.chooseDateDatePickerLabel"
                :disabled-dates="restrictedDates"
                :disabled-days="restrictedWeekDays"
                :disable-weekends="false"
                class="internet-config__datepicker"
                :initial-date="activationDate"
                :first-available-date="firstAvailableDate"
                data-testid="internet-config__datepicker"
                input-name="activationDate"
                @change="setSelectedDate"
              />
            </c-col>
          </c-row>
        </c-collapse>
      </div>

      <c-collapse id="asapDisclaimer" :visible="showActivationAsapDisclaimer">
        <div
          class="internet-config__disclaimer"
          v-html="content.broadband.activationAsapDisclaimerText"
        ></div>
      </c-collapse>

      <c-collapse id="dateDisclaimer" :visible="showDateSelectedDisclaimer">
        <div
          class="internet-config__disclaimer"
          v-html="content.broadband.activationDateSelectedDisclaimer"
        ></div>
      </c-collapse>
    </fieldset>
  </config-group-item>
</template>

<script>
import {
  CInformationBox,
  CRow,
  CCol,
  CFormRadio,
  CFormRadioGroup,
  CCollapse
} from 'olympus/components';
import ConfigGroupItem from '../config-group-item/config-group-item.vue';
import CDropdown from 'webshop/components/c-dropdown/c-dropdown.vue';
import CDatepicker from 'webshop/components/c-datepicker/c-datepicker.vue';

import { BROADBAND_SUBSCRIPTION_TYPES } from 'webshop/enums/broadband-subscription-types.js';

import { required, requiredIf } from 'vuelidate/lib/validators/index.js';

export default {
  name: 'InternetConfig',

  components: {
    ConfigGroupItem,
    CInformationBox,
    CRow,
    CCol,
    CFormRadio,
    CFormRadioGroup,
    CDropdown,
    CCollapse,
    CDatepicker
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    },
    configData: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      form: {
        cancelationAssist: '',
        enableSubscriptionAsap: 'true',
        subscriptionStartDate: '',
        currentProvider: ''
      },
      restrictedDates: [],
      restrictedWeekDays: []
    };
  },

  validations: {
    form: {
      cancelationAssist: {
        required: requiredIf(function () {
          return this.showProviderChange; // eslint-disable-line no-invalid-this
        })
      },
      currentProvider: {
        required: requiredIf(function () {
          return (
            this.content.broadband.isMandatory && // eslint-disable-line no-invalid-this
            this.showCancellationAssistNeeded // eslint-disable-line no-invalid-this
          );
        })
      },
      enableSubscriptionAsap: {
        required
      },
      subscriptionStartDate: {
        required: requiredIf(function () {
          return this.showDateSelectorStep; // eslint-disable-line no-invalid-this
        })
      }
    }
  },

  computed: {
    ready() {
      return !this.$v.$invalid;
    },
    showCancellationAssistNeeded() {
      return (
        this.content.broadband.cancellationAssistReminderText &&
        this.form.cancelationAssist === 'true' &&
        this.showProviderChange
      );
    },
    showCancellationAssistNotNeeded() {
      return (
        this.content.broadband.cancellationAssistNotNeeded &&
        this.form.cancelationAssist === 'false' &&
        this.showProviderChange
      );
    },
    showStartDateCannotBeChangedDisclaimer() {
      return (
        this.content.broadband.startDateCannotBeChangedDisclaimer &&
        this.form.enableSubscriptionAsap === 'false' &&
        this.informStartDateCannotBeChangedDisclaimer
      );
    },
    showDateSelectorStep() {
      return this.form.enableSubscriptionAsap === 'false' ?? false;
    },
    showActivationAsapDisclaimer() {
      return this.form.enableSubscriptionAsap === 'true';
    },
    showDateSelectedDisclaimer() {
      return this.form.enableSubscriptionAsap === 'false';
    },
    subscriptionCancelled() {
      return (
        this.configData.attributes &&
        !(this.configData.attributes.currentState === 'Active')
      );
    },
    showProviderChange() {
      return (
        this.configData.attributes?.type !== BROADBAND_SUBSCRIPTION_TYPES.FWA
      );
    },
    providers() {
      return (
        this.content.broadband &&
        this.content.broadband.providers?.map(p => ({
          value: p.name,
          label: p.name,
          hasCollaboration: p.hasCollaboration
        }))
      );
    },
    activationDate() {
      // If cancellationsAssist is not set or false AND if ASAP chip is selected, the backend expects the activationDate to be null - hence we add this check here
      if (
        (!this.form.cancelationAssist ||
          this.form.cancelationAssist === 'false') &&
        this.showActivationAsapDisclaimer
      ) {
        return 'null';
      }

      // If cancellationAssist is true && if ASAP chip is selected, set activationdate according to backend data
      if (
        this.form.cancelationAssist === 'true' &&
        this.showActivationAsapDisclaimer
      ) {
        return this.configData?.attributes
          ?.firstAvailableDateChangeProviderActivationDate?.value;
      }

      // IF activationDate has been saved previously AND if custom date chip is selected
      if (
        this.configData?.attributes?.activationDate?.value &&
        this.showDateSelectedDisclaimer
      ) {
        const firstAvailableDate = new Date(this.firstAvailableDate);
        const activationDate = new Date(
          this.configData?.attributes?.activationDate?.value
        );
        if (activationDate < firstAvailableDate) return this.firstAvailableDate;
        return this.configData?.attributes?.activationDate?.value;
      }

      // If cancellationAssist is true && if custom date chip is selected, set activationdate according to backend data
      if (
        this.form.cancelationAssist === 'true' &&
        this.showDateSelectedDisclaimer
      ) {
        return this.configData?.attributes
          ?.firstAvailableDateProviderActivationDateSelected?.value;
      }

      return (
        this.configData?.attributes?.activationDate?.value ||
        this.configData?.attributes?.firstAvailableDate?.value
      );
    },
    firstAvailableDate() {
      const cancelationAssist = this.form.cancelationAssist;

      if (
        (!cancelationAssist || cancelationAssist === 'false') &&
        this.showActivationAsapDisclaimer
      ) {
        return null;
      }

      // If cancellationAssist is true && if ASAP chip is selected, set activationdate according to backend data
      if (cancelationAssist === 'true' && this.showActivationAsapDisclaimer) {
        return this.configData?.attributes
          ?.firstAvailableDateChangeProviderActivationDate?.value;
      }

      // If cancellationAssist is true && if custom date chip is selected, set activationdate according to backend data
      if (cancelationAssist === 'true' && this.showDateSelectedDisclaimer) {
        return this.configData?.attributes
          ?.firstAvailableDateProviderActivationDateSelected?.value;
      }

      return this.configData?.attributes?.firstAvailableDate?.value;
    },
    preSelectedAsapOption() {
      return this.configData?.attributes?.activationDateAsap
        ? this.configData?.attributes?.activationDateAsap
        : this.configData?.attributes?.activationDate?.value === undefined;
    },
    disabledDates() {
      return this.configData.attributes?.restrictedDates;
    },
    disabledDays() {
      return this.configData.attributes?.restrictedWeekDays;
    },
    informStartDateCannotBeChangedDisclaimer() {
      return this.configData?.attributes
        ?.showStartDateCannotBeChangedDisclaimer;
    }
  },

  watch: {
    disabledDates(value) {
      this.restrictedDates = value;
    },

    disabledDays(value) {
      this.restrictedWeekDays = value;
    },

    configData: {
      deep: true,
      handler() {
        if (this.configData.attributes?.cancelationAssist !== null) {
          this.form.cancelationAssist = this.configData.attributes
            .cancelationAssist
            ? 'true'
            : 'false';
          this.form.enableSubscriptionAsap = this.configData.attributes
            .activationDateAsap
            ? 'true'
            : 'false';
        }
        this.form.currentProvider = this.providers?.find(
          p => p.value === this.configData.attributes.currentProvider
        );
      }
    },

    ready(value) {
      this.$emit('ready', value);
    }
  },

  mounted() {
    this.$emit('ready', !this.$v.$invalid);
  },

  methods: {
    handleExpanded(expanded) {
      this.$emit('expanded');

      // Reset vuelidate and component state on collapse
      if (!expanded) {
        if (this.$v) {
          this.$v.$reset();
        }
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    setSelectedDate(e) {
      this.form.subscriptionStartDate = e;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.internet-config {
  &__section {
    margin-top: $u-600;

    &__row {
      margin-top: $u-400;
      margin-bottom: $u-350;

      @include mq($screen-md, max) {
        &__col-chip:last-of-type {
          margin-top: $u-200;
        }
      }
    }
  }

  &__info {
    &__text {
      a {
        color: $c-pm-500;
      }
    }
  }

  &__start {
    &__legend {
      font-size: $font-size-h2;
      font-weight: 700;
      margin-bottom: $u-300;
    }

    &__selection {
      .c-form-radio-group-wrapper {
        div[role='radiogroup'] {
          gap: $u-300;
        }
      }

      &__label {
        font-weight: 700;
      }
    }
  }

  &__datepicker {
    padding-bottom: $u-250;
  }

  &__disclaimer {
    font-size: 14px;
  }

  &__cancellation-information {
    margin-bottom: $u-600;
    margin-top: Calc($u-400 * -1);
  }

  &__cancellation-manual-information {
    margin-top: $u-500;
  }
}
</style>
