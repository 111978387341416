<template>
  <div class="mb-4">
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <h3 class="cm-c-form__header">
          {{ numberConfigVM.poaHeadline }}
        </h3>
        <p>
          {{ numberConfigVM.poaDescription }}
        </p>
      </c-col>
    </c-row>
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <p class="cm-numcon__subtitle">
          <strong>{{ numberConfigVM.subscriptionOwnerHeadline }}</strong>
        </p>
      </c-col>
    </c-row>
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <c-form-radio-group v-model="ownNumber" name="ownNumber" stacked>
          <c-form-radio id="yes" checked-border value="yes">
            <span>{{ numberConfigVM.subscriptionOwnerYes }}</span>
          </c-form-radio>
          <c-form-radio id="no" checked-border value="no">
            <span>{{ numberConfigVM.subscriptionOwnerNo }}</span>
          </c-form-radio>
        </c-form-radio-group>
      </c-col>
    </c-row>

    <c-collapse id="b2b-or-private" :visible="ownNumber === 'yes'">
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col">
          <p class="cm-numcon__subtitle">
            <strong>{{
              numberConfigVM.subscriptionOwnerCustomerSegmentHeadline
            }}</strong>
          </p>
        </c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col">
          <c-form-radio-group
            v-model="b2bOrPrivate"
            name="b2bOrPrivate"
            stacked
          >
            <c-form-radio id="private" checked-border value="private">
              <span>{{
                numberConfigVM.subscriptionOwnerCustomerSegmentPrivate
              }}</span>
            </c-form-radio>
            <c-form-radio id="b2b" checked-border value="b2b">
              <span>{{
                numberConfigVM.subscriptionOwnerCustomerSegmentBusiness
              }}</span>
            </c-form-radio>
          </c-form-radio-group>
        </c-col>
      </c-row>

      <!-- TODO @backend: We need to detect if ePoa is allowed in backend. -->
      <c-collapse id="private" :visible="b2bOrPrivate === 'private'">
        <c-row class="cm-c-form__row">
          <c-col class="cm-c-form__col">
            <p class="cm-numcon__subtitle">
              {{ numberConfigVM.poaHeadline }}
            </p>
          </c-col>
        </c-row>
        <c-row class="cm-c-form__row">
          <c-col class="cm-c-form__col">
            <c-checkbox
              id="allowEpoa"
              v-model="productObj.allowEpoa"
              :checked="productObj.allowEpoa"
              name="allowEpoa"
              styled-checkbox
            >
              {{ numberConfigVM.poaLabel }}
            </c-checkbox>
          </c-col>
        </c-row>
      </c-collapse>

      <c-collapse id="b2b" :visible="b2bOrPrivate === 'b2b'">
        <!-- Searches for numbers as users type (autosubmit); layout is a form -->
        <template v-if="!numberConfigVM.companyManualSearch">
          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col">
              <p class="cm-numcon__subtitle">
                <strong>{{ numberConfigVM.poaHeadline }}</strong>
              </p>
            </c-col>
          </c-row>

          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col" cols="4">
              <c-input-group
                v-model="productObj.b2BCvr"
                type="tel"
                :input-error="cvrError"
                :place-holder-label="numberConfigVM.b2BCvrPlaceholderText"
                @input="checkCVR"
              ></c-input-group>
            </c-col>
          </c-row>
          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col">
              <c-input-group
                v-model="productObj.b2BOrganization"
                :input-error="organizationError"
                :place-holder-label="
                  numberConfigVM.b2BOrganizationPlaceholderText
                "
                readonly
              ></c-input-group>
            </c-col>
          </c-row>
          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col">
              <c-input-group
                v-model="productObj.b2BAddress"
                :input-error="addressError"
                :place-holder-label="numberConfigVM.b2BAddressPlaceholderText"
                readonly
              ></c-input-group>
            </c-col>
          </c-row>
          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col" cols="6">
              <c-input-group
                v-model="productObj.b2BZipCode"
                :input-error="zipCodeError"
                :place-holder-label="numberConfigVM.b2BZipCodePlaceholderText"
                readonly
              ></c-input-group>
            </c-col>
            <c-col class="cm-c-form__col" cols="6">
              <c-input-group
                v-model="productObj.b2BCity"
                :input-error="cityError"
                :place-holder-label="numberConfigVM.b2BCityPlaceholderText"
                readonly
              ></c-input-group>
            </c-col>
          </c-row>
        </template>

        <!-- Only searches for a cvr number when users press the button (manual submit); layout is text -->
        <template v-else>
          <c-row class="cm-c-form__row">
            <c-col class="cm-c-form__col" cols="8" xs="12">
              <c-input-group
                v-model="productObj.b2BCvr"
                :input-error="cvrError"
                :place-holder-label="numberConfigVM.b2BCvrPlaceholderText"
              ></c-input-group>
            </c-col>
            <c-col class="cm-c-form__col" cols="4" xs="12">
              <c-button small @click="checkCVR">
                {{ numberConfigVM.companyCheckButtonText }}
              </c-button>
            </c-col>
          </c-row>
          <c-row v-if="businessInfoAvailable" class="cm-c-form__row">
            <c-col class="cm-c-form__col">
              <div class="cm-numcon__poa__business-info">
                <p class="cm-numcon__subtitle">
                  {{ numberConfigVM.companyInfoSectionTitle }}
                </p>
                {{ productObj.b2BOrganization }}<br />
                {{ productObj.b2BAddress }}<br />
                {{ productObj.b2BZipCode }} {{ productObj.b2BCity }}
              </div>
            </c-col>
          </c-row>
        </template>

        <c-row class="cm-c-form__row">
          <c-col class="cm-c-form__col">
            <p class="cm-numcon__subtitle">
              <strong>{{ numberConfigVM.poaHeadline }}</strong>
            </p>
          </c-col>
        </c-row>
        <c-row class="cm-c-form__row">
          <c-col class="cm-c-form__col">
            <c-checkbox
              id="allowEpoa"
              v-model="productObj.allowEpoa"
              :checked="productObj.allowEpoa"
              name="allowEpoa"
              styled-checkbox
            >
              {{ numberConfigVM.poaLabel }}
            </c-checkbox>
          </c-col>
        </c-row>
        <c-row class="cm-c-form__row">
          <c-col class="cm-c-form__col">
            <p>{{ numberConfigVM.poaManualLabel }}</p>
          </c-col>
        </c-row>
      </c-collapse>
    </c-collapse>

    <c-collapse id="poa-info" :visible="ownNumber === 'no'">
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col">
          <p>
            {{ numberConfigVM.notSubscriptionOwnerDescription }}
          </p>
        </c-col>
      </c-row>
    </c-collapse>
  </div>
</template>

<script>
function handleErrors(response) {
  if (!response.ok) {
    throw Error('ERRORTEXT');
  }
  return response;
}

import { mapGetters, mapMutations } from 'vuex';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import {
  CCollapse,
  CRow,
  CCol,
  CCheckbox,
  CFormRadio,
  CFormRadioGroup,
  CButton
} from 'olympus/components/index.js';

import {
  requiredIf,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';

export default {
  name: 'NumberPoa',

  components: {
    CInputGroup,
    CCollapse,
    CRow,
    CCol,
    CCheckbox,
    CFormRadio,
    CFormRadioGroup,
    CButton
  },

  props: {
    product: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return {
      isHidden: true,
      inputClass: 'cm-c-form-control cm-js-material-input',
      inputFilledClass: 'cm-c-form-control--filled',
      b2bOrPrivate: '',
      ownNumber: '',
      productObj: this.product
    };
  },

  computed: {
    ...mapGetters(['numberConfigVM']),

    businessInfoAvailable() {
      return !!this.productObj.b2BOrganization;
    },

    cvrError() {
      return this.$v.b2BCvr.$invalid && this.$v.b2BCvr.$model.length
        ? this.numberConfigVM.errorMessageB2BCvrInvalid
        : '';
    },

    organizationError() {
      return this.$v.b2BOrganization.$invalid &&
        this.$v.b2BOrganization.$model.length
        ? this.numberConfigVM.errorMessageB2BOrganizationInvalid
        : '';
    },

    addressError() {
      return this.$v.b2BAddress.$invalid && this.$v.b2BAddress.$model.length
        ? this.numberConfigVM.errorMessageB2BAddressInvalid
        : '';
    },

    zipCodeError() {
      return this.$v.b2BZipCode.$invalid && this.$v.b2BZipCode.$model.length
        ? this.numberConfigVM.errorMessageB2BZipCodeInvalid
        : '';
    },

    cityError() {
      return this.$v.b2BCity.$invalid && this.$v.b2BCity.$model.length
        ? this.numberConfigVM.errorMessageB2BCityInvalid
        : '';
    }
  },

  validations: {
    b2BCvr: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      }),
      numeric,
      minLength: minLength(8),
      maxLength: maxLength(8)
    },
    b2BOrganization: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BAddress: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BZipCode: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BCity: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    allowEpoa: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    }
  },

  watch: {
    b2bOrPrivate(newValue) {
      this.productObj.isB2BCustomer = Boolean(newValue === 'b2b');
    },
    ownNumber(newValue) {
      this.productObj.isNotLegalOwner = Boolean(newValue === 'no');
    }
  },

  methods: {
    ...mapMutations(['HIDE_SPINNER', 'SHOW_DIALOG', 'SHOW_SPINNER']),
    checkCVR() {
      if (this.productObj.b2BCvr.length === 8) {
        this.fetchCVR();
      }
    },
    async fetchCVR() {
      this.SHOW_SPINNER();
      await fetch(
        `${this.numberConfigVM.apiCvr}&cvr=${this.productObj.b2BCvr}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then(handleErrors)
        .then(response => {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            this.isHidden = true;
            return response.json().then(data => {
              this.productObj.b2BOrganization = data.organization;
              this.productObj.b2BAddress = data.address;
              this.productObj.b2BZipCode = data.zipCode;
              this.productObj.b2BCity = data.city;
            });
            // call function that will make a change on the input field, so that it changes
          }
        })
        .catch(() => {
          this.productObj.isHidden = false;
          this.productObj.b2BOrganization = '';
          this.productObj.b2BAddress = '';
          this.productObj.b2BZipCode = '';
          this.productObj.b2BCity = '';

          this.SHOW_DIALOG({
            componentName: 'content-dialog',
            componentProps: {
              contentObject: {
                headline: this.numberConfigVM.errorMessageHeadline,
                description: this.numberConfigVM.errorMessageB2BCvrNotFound,
                buttonLabel: this.numberConfigVM.errorMessageButtonText
              }
            }
          });
        });
      this.HIDE_SPINNER();
    }
  }
};
</script>
