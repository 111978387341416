var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      ref: "handsetsModal",
      attrs: { wide: "", large: "" },
      on: { close: _vm.handleClose }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm.wizard.currentState === _vm.wizard.states.CUSTOMIZE
          ? _c(
              "button",
              {
                staticClass: "c-btn c-btn--as-link c-btn--back-link",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.wizard.currentState = _vm.wizard.states.LISTING
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.content.hardwareWizardStepBackwardText) +
                    "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("header", { staticClass: "cm-c-product-headline" }, [
          _c("h2", { staticClass: "cm-c-product-headline--caption" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.content.hardwareWizardHeading) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _vm.content.hardwareWizardSubHeading
            ? _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.hardwareWizardSubHeading) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-up", mode: "out-in" } }, [
        _vm.wizard.currentState == _vm.wizard.states.FETCHING
          ? _c("div", { key: _vm.wizard.states.FETCHING }, [
              _c("div", { staticClass: "cm-c-spinner" }, [
                _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
              ])
            ])
          : _vm.wizard.currentState == _vm.wizard.states.LISTING
          ? _c(
              "div",
              { key: _vm.wizard.states.LISTING, staticClass: "cm-grid" },
              _vm._l(_vm.wizard.products, function(product, item) {
                return _c(
                  "div",
                  {
                    key: product.productId + item,
                    staticClass:
                      "\n          cm-grid__col--xs-12\n          cm-grid__col--sm-6\n          cm-grid__col--lg-4\n          cm-grid__col--xxl-3\n        "
                  },
                  [
                    _c("product-card", {
                      attrs: {
                        "track-by": "product.productId",
                        product: product,
                        "select-label":
                          _vm.content.hardwareWizardSelectPhoneText,
                        "compare-label": _vm.content.hardwareWizardCompareText,
                        "compare-disabled": true,
                        "minimum-price-disabled": false,
                        "subscription-text": product.subscriptionText,
                        "product-position": item,
                        link: false
                      },
                      on: { select: _vm.handleHandsetSelected }
                    })
                  ],
                  1
                )
              }),
              0
            )
          : _vm.wizard.currentState == _vm.wizard.states.CUSTOMIZE
          ? _c(
              "div",
              { key: _vm.wizard.states.CUSTOMIZE },
              [
                _vm.wizard.product && _vm.wizard.product.code
                  ? _c(
                      "product-page",
                      {
                        attrs: {
                          "product-code": _vm.wizard.product.code,
                          "product-brand": _vm.wizard.product.brand,
                          "product-model": _vm.wizard.product.model,
                          "product-name": _vm.wizard.product.displayName,
                          "product-description": _vm.wizard.product.description,
                          "product-equipment-type":
                            _vm.wizard.product.itemEquipmentType,
                          "product-description-link-text":
                            _vm.wizard.product.descriptionLinkText,
                          content: _vm.wizard.product.content,
                          "gallery-content": _vm.wizard.product.gallery,
                          "restricted-ban-modal-content":
                            _vm.wizard.product.restrictedBanModal,
                          "default-variant": _vm.wizard.product.selectedVariant,
                          "default-variant-attributes":
                            _vm.wizard.product.variantAttributes,
                          "marketing-data": _vm.wizard.product.marketingData,
                          specifications:
                            _vm.wizard.product.groupedSpecifications,
                          "existing-subscriptions":
                            _vm.wizard.product.existingSubscriptions,
                          "payment-code": String(_vm.installments),
                          "disable-installments": _vm.hasFinancedItems,
                          external: ""
                        },
                        on: { submit: _vm.handleVariantSelected }
                      },
                      [
                        _c(
                          "template",
                          { slot: "product-notify-form" },
                          [_vm._t("product-notify-form")],
                          2
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.networking
                  ? _c("div", { staticClass: "cm-c-spinner" }, [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading...")
                      ])
                    ])
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }