<template>
  <div class="empty-basket">
    <c-button :class="buttonClasses" reset @click="handleModalState(true)">
      <span>{{ contentLocal.clearBasketLabel }}</span>
      <c-icon
        class="empty-basket__button-link-icon"
        symbol-id="service-trash"
      ></c-icon>
    </c-button>

    <c-modal centered :visible="isModalOpen" @close="handleModalState(false)">
      <div class="empty-basket__modal-content">
        <h2 class="empty-basket__modal-message">
          {{ contentLocal.heading }}
        </h2>

        <div>
          {{ contentLocal.bodyText }}
        </div>

        <c-button
          class="empty-basket__modal-accept-button"
          @click="handleClearBasket"
        >
          {{ contentLocal.acceptButtonLabel }}
        </c-button>

        <c-button
          class="empty-basket__button-link"
          reset
          @click="handleModalState(false)"
        >
          {{ contentLocal.cancelButtonLabel }}
        </c-button>
      </div>
    </c-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { CModal, CButton } from 'olympus/components';

import {
  GETTERS as BASKET_GETTERS,
  ACTIONS as BASKET_ACTIONS
} from '../../store/basket';

const DEFAULT_CONTENT = {
  clearBasketLabel: 'Tøm kurv',
  heading: 'Tøm kurv',
  bodyText: 'Er du sikker på at du vil tømme kurven?',
  acceptButtonLabel: 'Bekræft',
  cancelButtonLabel: 'Annuller'
};

const ICON_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right'
};

export default {
  name: 'empty-basket',

  components: {
    CButton,
    CModal
  },

  props: {
    iconPosition: {
      type: String,
      default: ICON_POSITIONS.RIGHT
    },
    content: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      },
      isModalOpen: false
    };
  },

  computed: {
    ...mapGetters({
      isEmpty: BASKET_GETTERS.IS_EMPTY
    }),
    buttonClasses() {
      return {
        'empty-basket__button-link': true,
        'empty-basket__button-link--left':
          this.iconPosition === ICON_POSITIONS.LEFT
      };
    }
  },

  methods: {
    ...mapActions({
      clearBasket: BASKET_ACTIONS.CLEAR_BASKET
    }),
    handleModalState(newValue) {
      this.isModalOpen = newValue;
    },
    handleClearBasket() {
      this.clearBasket();
      this.handleModalState(false);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.empty-basket {
  // .empty-basket__button-link
  &__button-link {
    display: flex;
    align-items: flex-end;
    color: $c-pm-500;
    font-weight: 400;
    font-size: $global-font-size-sm;
    height: fit-content;
    padding: 0;

    @include mq($screen-lg, min) {
      font-size: $global-font-size-md;
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  // .empty-basket__button-link-icon
  &__button-link-icon {
    width: 18px;
    height: 18px;
    margin: 0 0 2px $u-150;
    fill: $c-pm-500;
  }

  // .shopping-cart__modal-content
  &__modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $u-850 $u-250 $u-850;
  }

  // .shopping-cart__modal-accept-button
  &__modal-accept-button {
    margin-top: $u-350;
    margin-bottom: $u-200;
  }

  //shopping-cart__modal-message
  &__modal-message {
    text-align: center;
    font-weight: 700;
    font-size: $font-size-h4;
  }
}
</style>
