var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidden
    ? _c(
        "portal",
        { attrs: { to: "slim" } },
        [
          _c(
            "c-sticky-footer",
            {
              ref: "shopFooter",
              class: _vm.classObject,
              style: _vm.stickyFooterStyle,
              attrs: { id: "sticky-footer" }
            },
            [
              _vm.expandableOnMobile
                ? _c(
                    "c-collapse-trigger",
                    {
                      staticClass: "shop-footer__trigger",
                      attrs: { id: "shop-footer-content" }
                    },
                    [
                      _c("c-icon", {
                        staticClass: "shop-footer__trigger-icon",
                        attrs: { "symbol-id": "arrow-down" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "cm-container" }, [
                _c("div", { staticClass: "cm-grid" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n            cm-grid__col--xs-12 cm-grid__col--md-7 cm-grid__col--xl-offset-1\n            shop-footer__grid-column\n          "
                    },
                    [
                      _vm._t("ribbon"),
                      _vm._v(" "),
                      _c(
                        "c-collapse",
                        {
                          attrs: {
                            id: "shop-footer-content",
                            "transition-duration": "100ms",
                            "transition-easing": "linear"
                          },
                          model: {
                            value: _vm.isExpanded,
                            callback: function($$v) {
                              _vm.isExpanded = $$v
                            },
                            expression: "isExpanded"
                          }
                        },
                        [
                          _vm._t("columns", [
                            _vm.columns && _vm.columns.length
                              ? _c(
                                  "div",
                                  { staticClass: "cm-grid" },
                                  _vm._l(_vm.columns, function(column, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "cm-grid__col--xs cm-grid__col--md-4",
                                        class: index > 0 && "has-divider"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "shop-footer__column"
                                          },
                                          [
                                            _vm._t(
                                              "column",
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "shop-footer__item-label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(column.label) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "shop-footer__item-value"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(column.value) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              { column: column }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ])
                        ],
                        2
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-grid__col--xs-12 cm-grid__col--md-5 cm-grid__col--xl-4"
                    },
                    [_vm._t("cta")],
                    2
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }