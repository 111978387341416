<template>
  <div class="c-button-group">
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'c-button-group',

  props: {
    // eslint-disable-next-line
    // undone: when enabled, it should stack the buttons on top of each other
    stacked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.c-button-group {
  display: inline-flex;
  vertical-align: middle;

  & > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  & > button:not(:last-child) {
    border-right: 0;
  }

  & > button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>


