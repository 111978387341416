<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="shopping-cart-related-group">
    <div class="cm-grid cm-grid--middle-xs">
      <div class="cm-grid__col--md-1 cm-grid__col--xs-2">
        <div class="cm-c-toggle">
          <input
            v-model="service.isSelected"
            class="cm-c-toggle__checkbox"
            type="checkbox"
            @click="toggleService($event)"
          />
          <span class="cm-c-toggle__btn"></span>
        </div>
      </div>
      <div class="cm-grid__col--md-2 cm-grid__col--xs-3">
        <p class="cm-text-center">
          <img
            :src="service.imageUrl"
            :alt="service.imageUrl ? service.displayName : ''"
          />
        </p>
      </div>
      <div class="cm-grid__col--md-8 cm-grid__col--xs-7">
        <div class="cm-grid cm-grid--middle-md">
          <div class="cm-grid__col--md-8 cm-grid__col--xs-12">
            <h3 class="cm-c-cart-list__product-title">
              {{ service.displayName }}
              <br />
              {{ service.price.formattedValueCurrency }}
            </h3>
            <div class="cm-c-cart-list__product-desc">
              <p>{{ service.shortDescription }}</p>
              <div v-if="!service.isDescriptionEmpty">
                <p :class="[descriptionClassObject]">
                  {{ service.description }}
                </p>
                <p>
                  <a
                    href="#"
                    class="cm-link--red"
                    @click.prevent="toggleDescription"
                  >
                    {{ descriptionToggled ? readLessLabel : readMoreLabel }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isEnterprise && service.isSelected && service.showActivateSimCard"
      class="cm-c-cart-list"
    >
      <div class="cm-grid cm-grid--middle-xs">
        <div class="cm-grid__col--md-1 cm-grid__col--xs-2"></div>
        <div class="cm-grid__col--md-8 cm-grid__col--xs-7 cm-c-extra-simcard">
          <h4 class="cm-c-cart-list">
            {{ activateSimcardHeading }}
          </h4>
          <div class="cm-grid cm-c-checkout__form-group">
            <div
              class="
                cm-grid__col--xs-12 cm-grid__col--sm
                cm-c-form-control-group
              "
            >
              <label class="cm-c-radio">
                <input
                  v-model="service.isActivateSimCard"
                  type="radio"
                  class="cm-c-radio__o-input"
                  name="actsim"
                  :value="false"
                  @click="fetchActiveSimNumber"
                />
                <span class="cm-c-radio__input"></span>
                <span class="cm-c-radio__label">{{ refuseLabel }}</span>
              </label>
            </div>
          </div>

          <div class="cm-grid cm-c-checkout__form-group">
            <div
              class="
                cm-grid__col--xs-12 cm-grid__col--sm
                cm-c-form-control-group
              "
            >
              <label class="cm-c-radio">
                <input
                  v-model="service.isActivateSimCard"
                  type="radio"
                  class="cm-c-radio__o-input"
                  name="actsim"
                  :value="true"
                />
                <span class="cm-c-radio__input"></span>
                <span class="cm-c-radio__label">{{ acceptLabel }}</span>
              </label>
            </div>
          </div>
          <div
            v-if="service.isActivateSimCard"
            class="cm-grid cm-c-checkout__form-group"
          >
            <div
              class="
                cm-grid__col--xs-12 cm-grid__col--md-8
                cm-c-form-control-group
              "
            >
              <input
                ref="simcard"
                v-model="service.activateSimCardNumber"
                :required="service.isActivateSimCard"
                pattern="^\d{11,20}$"
                type="text"
                class="cm-c-form-control cm-js-material-input"
                @keyup.enter="fakeSubmit"
                @mouseleave="fakeSubmit"
                @input="isHidden = true"
              />
              <span class="cm-c-form-control__bar"></span>
              <label class="cm-c-form-control__label">
                simNumberPlaceholderText
              </label>
              <span class="field-validation-error" :hidden="isHidden">
                errorMessageSimNumberInvalid
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ACTIONS } from '../../store/shop/mutation-types.js';
import { ACTIONS as BASKET_ACTIONS } from '../../store/basket';

export default {
  // TODO DKT-1440: Can we remove this component entirely
  //TODO DKT-1440 refactor the name of this module to reflect that it's not only used for insurance
  name: 'PowerStepInsuranceCard',

  props: [
    'service',
    'groupId',
    'readMoreLabel',
    'readLessLabel',
    'refuseLabel',
    'acceptLabel',
    'activateSimcardHeading'
  ],

  data() {
    return {
      descriptionToggled: false,
      isHidden: true,
      serviceObj: this.service
    };
  },

  computed: {
    ...mapGetters(['isEnterprise']),
    descriptionClassObject() {
      return this.descriptionToggled
        ? ''
        : 'cm-c-cart-list__product-desc--collapsed';
    }
  },

  watch: {
    'serviceObj.isSelected': function (newVal) {
      this.service.activateSimCardNumber = // eslint-disable-line vue/no-mutating-props
        newVal === false ? '' : this.service.activateSimCardNumber;
    },
    'serviceObj.isActivateSimCard': function (newVal) {
      this.service.activateSimCardNumber = // eslint-disable-line vue/no-mutating-props
        newVal === false ? '' : this.service.activateSimCardNumber;
      if (newVal === true) {
        const self = this;
        setTimeout(function () {
          self.$refs.simcard.focus();
        }, 1);
      }
    }
  },

  methods: {
    ...mapActions({
      addActivateSim: ACTIONS.ADD_ACTIVATE_SIM,
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET,
      addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION,
      addService: BASKET_ACTIONS.ADD_SERVICE
    }),

    toggleDescription() {
      this.descriptionToggled = !this.descriptionToggled;
    },

    fakeSubmit() {
      const self = this;
      const simcard = self.$refs.simcard;
      if (!simcard.checkValidity()) {
        this.isHidden = false;
      } else {
        this.fetchActiveSimNumber();
      }
    },

    fetchActiveSimNumber() {
      const self = this;
      setTimeout(function () {
        const activateSimCardArray = [
          self.service.code,
          self.groupId,
          self.service.isActivateSimCard,
          self.service.activateSimCardNumber
        ];
        self.addActivateSim(activateSimCardArray);
      }, 1);
    },

    toggleService($event) {
      if (!$event.target.checked) {
        // TODO DKT-1440: remove relationType when we implement using offeringApi.
        this.removeFromBasket({
          code: this.service.code,
          groupId: this.groupId
        });
      } else if (this.service.isSubscriptionType) {
        this.addSubscription({
          code: this.service.code,
          groupId: this.groupId,
          relationType: 'extraDataCard'
        });
      } else {
        this.addService({
          vasCode: this.service.code,
          groupId: this.groupId
        });
      }

      if (this.isEnterpeise) {
        if (!$event.target.checked) {
          this.service.isActivateSimCard = false; // eslint-disable-line vue/no-mutating-props
        } else if (
          this.service.showActivateSimCard &&
          !this.service.isActivateSimCard
        ) {
          setTimeout(this.fetchActiveSimNumber, 500);
        }
      }
    }
  }
};
</script>
