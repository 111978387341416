<template>
  <div class="c-search-panel">
    <div class="epi-form-text">
      <input
        id="c-search-panel"
        ref="input"
        type="text"
        class="epi-form-text__input c-search-panel__input"
        placeholder=" "
        :value="searchString"
        @input="handleInputChange"
        @keyup.enter="inputKeyupEnter"
      />
      <label for="c-search-panel" class="epi-form-text__label">
        {{ placeholder }}
      </label>
      <span class="epi-form-text__input-ui">
        <span @click="clearInput">
          <slot v-if="searchString.length === 0" name="input-icon-search" />
          <slot v-if="searchString.length > 0" name="input-icon-clear" />
        </span>
      </span>
    </div>

    <div v-if="searchResults.length === 0" class="c-search-panel__quick-links">
      <h3 class="c-search-panel__quick-links-headline">
        {{ quickLinksHeader }}
      </h3>
      <ul>
        <li
          v-for="quickLink in quickLinksList"
          :key="quickLink.url + quickLink.title"
        >
          <a :href="quickLink.url">{{ quickLink.title }}</a>
        </li>
      </ul>
    </div>

    <div v-if="searchResults.length > 0" class="c-search-panel__results">
      <ul>
        <li v-for="result in searchResults" :key="result.url + result.title">
          <a :href="result.url">
            <span class="c-search-panel__result-headline">
              {{ result.title }}
            </span>
            <span v-if="result.excerpt" class="c-search-panel__result-excerpt">
              {{ result.excerpt }}
            </span>
          </a>
        </li>
      </ul>

      <span class="c-search-panel__view-all">
        <a :href="searchUrl" @click="closePanel">{{ viewAllText }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { post } from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchPanel',

  props: {
    quickLinksHeader: {
      type: String,
      default: ''
    },
    quickLinksList: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchPageUrl: {
      type: String,
      default: ''
    },
    viewAllText: {
      type: String,
      default: ''
    },
    apiUrl: {
      type: String,
      default: ''
    },
    culture: {
      type: String,
      default: ''
    },
    filterSegments: {
      type: Array,
      default: () => []
    },
    sortCategories: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchString: '',
      searchResults: [],
      searchUrl: ''
    };
  },

  computed: {
    ...mapGetters(['contextId'])
  },

  watch: {
    searchString: function (val) {
      this.getResults(val);
      this.updateSearchUrl(val);
    },
    '$route.query.query'() {
      this.searchString = this.$route.query.query ?? '';
    }
  },

  mounted() {
    this.searchString = this.$route.query.query ?? '';
  },

  methods: {
    handleInputChange(event) {
      this.searchString = event.target.value;
    },
    updateSearchUrl(query = '') {
      this.searchUrl =
        this.searchPageUrl +
        '?query=' +
        query +
        '&segment=' +
        this.filterSegments;
    },
    clearInput() {
      this.searchString = '';
      this.$refs.input.focus();
    },
    inputKeyupEnter() {
      window.location = this.searchUrl;

      if (window.location.pathname.indexOf(this.searchPageUrl) >= 0) {
        this.closePanel();
      }
    },
    closePanel() {
      this.$root.$refs.menuoverlay.checked = true;
    },
    async getResults(string) {
      if (string.length <= 2) {
        this.searchResults = [];
        return;
      }

      const result = await post(this.apiUrl + `?contextId=${this.contextId}`, {
        query: string,
        culture: this.culture,
        filterSegments: this.filterSegments,
        sortCategories: this.sortCategories
      });

      this.searchResults = result.data.items;
    }
  },

  render(h) {
    return h();
  }
};
</script>
