const getTime = () => {
  return new Date().getTime();
};

const randomInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default {
  getTime,
  randomInterval
};
