<script>
/**
 * This component is primarily used to handle the logic of sending GTM data (Google Tracking Manager).
 * Can be found used in the razor file under 'MobileSubscriptionPagePartial'.
 */

import ShoutingFont from 'webshop/components/generic/shouting-font.vue';
export default {
  name: 'SubscriptionPageSubcard',

  components: {
    ShoutingFont
  },

  props: {
    productName: {
      type: String,
      required: true
    },

    productPosition: {
      type: Number,
      required: true
    },

    brandName: {
      type: String,
      required: true
    },

    code: {
      type: String,
      required: true
    },

    minimumPrice: {
      type: String,
      required: true
    },

    redirectUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isAddedAsImpression: false // Specifies whether this component has been added to the list of impression (when viewed at least 2 seconds)
    };
  },

  mounted() {
    this.addEcommerceImpressionToDataLayer();
    this.addListenerForImpression();
  },

  methods: {
    getDataLayerEcommerceObj() {
      return window.dataLayer?.find(
        gtmObj => 'ecommerce' in gtmObj && 'impressions' in gtmObj.ecommerce
      );
    },

    addListenerForImpression() {
      if (!this.isAddedAsImpression) {
        this.setupIntersectionObserver();
      }
    },

    /**
     * Observes when this component is visible (isIntersecting) - in that case:
     *   after 2 seconds (without scroll away from it) add the impression to the datalayer for GTM data
     *   and stop observing on this component
     */
    setupIntersectionObserver() {
      const timeouts = {};
      let observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // timeout is necessary to make sure we only addImpression if user looks at it for at least 2000ms.
            timeouts[entry.target.id] = setTimeout(() => {
              this.addImpression();
              obs.unobserve(entry.target);
            }, 2000);
          } else {
            clearTimeout(timeouts[entry.target.id]);
          }
        });
      });
      observer.observe(this.$refs.subcard, {
        root: null,
        rootMargin: '0px'
      });
    },

    addEcommerceImpressionToDataLayer() {
      // Check if we gtm-object that holds a list of impression exists - if false, create that object such that,
      // when mounted, we can push impressions to it
      // eslint-disable-next-line
      const gtmObjWithImpressions = this.getDataLayerEcommerceObj() ?? null;
      if (!gtmObjWithImpressions) {
        const gtmData = {
          event: 'ecommerce',
          ecommerce: {
            impressions: []
          }
        };

        this.$globals.pushToDataLayer(gtmData);
      }
    },

    addImpression() {
      if (!this.isAddedAsImpression) {
        const impression = {
          id: this.code?.toLowerCase(),
          name: this.productName?.toLowerCase(),
          brand: this.brandName?.toLowerCase(),
          category: 'sim',
          list: this.isExternalList() ? 'search results' : 'category page',
          position: this.productPosition
        };

        this.getDataLayerEcommerceObj().ecommerce.impressions.push(impression);
        this.isAddedAsImpression = true;
      }
    },

    // Are we coming from an external link, e.g. Google, or did we find the subscrption subcard by navigating on Norlys
    isExternalList() {
      //TODO DKT-39277 Remove Telia
      return (
        !document.referrer.includes('telia') &&
        !document.referrer.includes('norlys') &&
        !document.referrer.includes('callme')
      );
    },

    //Checks if GTM is fully loaded and working
    isGtmLoaded() {
      let gtm_started_event = window.dataLayer?.find(
        element => element['gtm.start']
      );

      if (!gtm_started_event) {
        return false; // Not even the GTM inline config has executed
      }

      if (!gtm_started_event['gtm.uniqueEventId']) {
        return false; // GTM inline config has ran, but main GTM js is not loaded (likely AdBlock, NoScript, URL blocking etc.)
      }

      return true; // GTM is fully loaded and working
    },

    /**
     * Push GTM data to dataLayer (triggered when a subscription card is clicked on)
     */
    sendGTMDataAndRedirect() {
      if (!this.isGtmLoaded()) {
        document.location = this.redirectUrl;
        return;
      }

      const product = {
        id: this.code?.toLowerCase(),
        name: this.productName?.toLowerCase(),
        brand: this.brandName?.toLowerCase(),
        category: 'sim',
        price: this.minimumPrice,
        position: this.productPosition
      };

      const gtmData = {
        event: 'productClick',
        ecommerce: {
          currencyCode: 'DKK',
          click: {
            actionField: {
              list: this.isExternalList() ? 'search results' : 'category page'
            },
            products: [product]
          }
        },
        // Event fires when all the data is successfully pushed to Google Tag Manager
        eventCallback: () => {
          document.location = this.redirectUrl;
        },
        eventTimeout: 2000
      };

      this.$globals.pushToDataLayer(gtmData);
    }
  }
};
</script>
