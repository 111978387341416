var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.classObject,
      attrs: {
        id: _vm.uid,
        role: "tabpanel",
        "aria-hidden": (!_vm.activeLocal).toString()
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }