<template>
  <component :is="tag" :class="modifiers" class="c-row">
    <slot />
  </component>
</template>

<script>
// TODO TC-4909: use render function instead, to remove
// extra dynamic component resolve
export default {
  name: 'c-row',

  props: {
    // TODO TC-4909: create tag template with validator
    tag: {
      type: String,
      default: 'div'
    },
    align: {
      // TODO TC-4909: add validator (between)
      type: String,
      default: null
    },
    noGutters: {
      type: Boolean,
      default: false
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    justified: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: null
    },
    gutters: {
      // TODO TC-4909: add validator
      type: [Number, String],
      default: 1
    },
    dir: {
      // TODO TC-4909: add validator
      type: String,
      default: null
    },
    alignItems: {
      // TODO TC-4909: add validator
      type: String,
      default: null
    }
  },

  computed: {
    modifiers() {
      return {
        'c-row--no-gutters': this.noGutters,
        'c-row--no-wrap': this.noWrap,
        'c-row--justified': this.justified,
        [`c-row--${this.align}`]: this.align,
        [`c-row--gutters-${this.gutters}`]: Number(this.gutters) > 1,
        [`c-row--${this.dir}`]: !!this.dir,
        [`c-row--align-items-${this.alignItems}`]: !!this.alignItems,
        [`c-row--justified-${this.justify}`]: !!this.justify
      };
    }
  }
};
</script>

<style lang="scss">
// TODO TC-4909: settings instead?
$u-300: 20px !default;
$gutter-width: $u-300 !default;
$half-gutter-width: $gutter-width * 0.5;

.c-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$half-gutter-width;
  margin-left: -$half-gutter-width;

  // .c-row--justified
  // .c-row--between
  &--justified,
  &--between {
    justify-content: space-between;
  }

  &--justified-center {
    justify-content: center;
  }

  &--justified-end {
    justify-content: end;
  }

  // TODO TC-4909: add more
  // .c-row--column
  &--column {
    flex-direction: column;
  }

  // TODO TC-4909: add more
  // .c-row--align-items-flex-end
  &--align-items-flex-end {
    align-items: flex-end;
  }

  // .c-row--no-gutters
  &--no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > .c-col,
    & > [class*='c-col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // .c-row--no-wrap
  &--no-wrap {
    flex-wrap: nowrap;
  }

  @for $i from 2 through 5 {
    // .c-row--gutters-{i:2-5}
    &--gutters-#{$i} {
      margin-right: -$half-gutter-width * $i;
      margin-left: -$half-gutter-width * $i;

      & > .c-col {
        padding-right: $half-gutter-width * $i;
        padding-left: $half-gutter-width * $i;
      }
    }
  }
}
</style>
