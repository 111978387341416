<template>
  <div>
    <template v-if="state === STATES.INIT">
      <form v-show="false" ref="theForm" method="post">
        <input
          v-model="encryptedCardNumber"
          type="hidden"
          name="encryptedCardNumber"
        />
        <input
          v-model="encryptedExpiryMonth"
          type="hidden"
          name="encryptedExpiryMonth"
        />
        <input
          v-model="encryptedExpiryYear"
          type="hidden"
          name="encryptedExpiryYear"
        />
        <input
          v-model="encryptedSecurityCode"
          type="text"
          name="encryptedSecurityCode"
        />
        <input type="submit" value="submit" class="btn btn-default" />
      </form>

      <portal v-if="formContainerInserted" to="adyen-checkout">
        <div v-if="hasError" class="checkout-payment__error-container">
          {{ content.errorText }}
        </div>
      </portal>

      <div
        id="dropin-container"
        :class="{ 'dropin-container--invalid': !isValid }"
      ></div>
    </template>

    <template v-if="state === STATES.VALIDATED">
      <activity-indicator
        :label="content.activityIndicatorLabel"
        fullscreen
      ></activity-indicator>
    </template>
  </div>
</template>

<script>
import ActivityIndicator from 'webshop/components/generic/activity-indicator/activity-indicator.vue';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

// NOTE: This component is just a wrapper for functionality provided by a 3rd party.
// DOCS: https://docs.adyen.com/payment-methods/cards/web-drop-in
const STATES = {
  INIT: 'INIT',
  VALIDATED: 'VALIDATED'
};

export default {
  name: 'CheckoutPaymentAdyen',

  components: { ActivityIndicator },

  props: {
    state: {
      type: String,
      default: STATES.INIT
    },
    paymentsConfig: {
      type: Object,
      required: true
    },
    hasError: {
      type: Boolean,
      required: false
    },
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isValid: false,
      encryptedCardNumber: '-',
      encryptedExpiryMonth: '-',
      encryptedExpiryYear: '-',
      encryptedSecurityCode: '-',
      containerSelector: '#dropin-container',
      formContainerSelector: '.adyen-checkout__loading-input__form',
      formContainerInserted: false,

      STATES
    };
  },

  mounted() {
    this[this.state]();
  },

  beforeDestroy() {
    // TODO DKT-10788: use event helper to remove duplicate code
    const container = document.querySelector(this.containerSelector);

    if (container) {
      container.removeEventListener(
        'DOMNodeInserted',
        this.onContainerNodeInserted
      );
    }
  },

  methods: {
    [STATES.INIT]() {
      const {
        payButtonCaption,
        creditCardNumberFieldPlaceHolder,
        creditCardExpiryDateFieldPlaceHolder,
        creditCvcDateFieldPlaceHolder
      } = this.content;

      // Overrides default translations provided by Adyen
      // Full list of available translation propertiesc can be found at:
      // https://github.com/Adyen/adyen-web/blob/master/packages/lib/src/language/locales/da-DK.json
      const translationsConfig = {
        'da-DK': {
          payButton: payButtonCaption || '',
          'creditCard.numberField.title':
            creditCardNumberFieldPlaceHolder || '',
          'creditCard.expiryDateField.title':
            creditCardExpiryDateFieldPlaceHolder || '',
          'creditCard.cvcField.title': creditCvcDateFieldPlaceHolder || ''
        }
      };

      const {
        locale,
        paasAdyenClientKey,
        paasAdyenTargetEnvironment,
        allowedCards
      } = this.paymentsConfig;

      // We have to construct this config ourselves, since BE "cannot" provide us with this structure
      const paymentMethodsResponseConfig = {
        paymentMethods: [
          {
            brands: allowedCards,
            type: 'scheme',
            // HACK: Styling bellow "hides"(changes actual placeholder to be transparent) Adyen input placeholders
            // in order to display input placeholder/label animation with the rest of the project
            styles: {
              placeholder: {
                color: 'rgba(255, 0, 0, 0)'
              }
            }
          }
        ]
      };

      const configuration = {
        environment: paasAdyenTargetEnvironment,
        paymentMethodsResponse: paymentMethodsResponseConfig,
        clientKey: paasAdyenClientKey,
        locale: locale,
        translations: translationsConfig,
        onSubmit: state => {
          const paymentMethod = state.data.paymentMethod;

          this.encryptedCardNumber = paymentMethod.encryptedCardNumber;
          this.encryptedExpiryMonth = paymentMethod.encryptedExpiryMonth;
          this.encryptedExpiryYear = paymentMethod.encryptedExpiryYear;
          this.encryptedSecurityCode = paymentMethod.encryptedSecurityCode;

          this.$nextTick(() => {
            this.$refs.theForm.submit();
          });
        },
        onChange: state => {
          this.isValid = state.isValid;
        }
      };

      const checkout = new AdyenCheckout(configuration);

      // TODO DKT-10788: use event helper to remove duplicate code
      const container = document.querySelector(this.containerSelector);

      if (container) {
        container.addEventListener(
          'DOMNodeInserted',
          this.onContainerNodeInserted
        );
      }

      checkout.create('dropin').mount(this.containerSelector);
    },
    [STATES.VALIDATED]() {
      const { continuePaymentUrl } = this.paymentsConfig;
      location.replace(continuePaymentUrl);
    },

    /**
     * @description Since Adyen configuration does not provide us with proper
     * callback for when the form is mounted in the dom, we use
     * our own event handler on dom node inserted and check for
     * the targeted elements where we want to transport our
     * custom nodes to, ex. error message, or helper texts.
     */
    onContainerNodeInserted() {
      if (!this.formContainerInserted) {
        const target = document.querySelector(this.formContainerSelector);

        if (target) {
          this.formContainerInserted = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'olympus/styles/tools/_tools.media-query.scss';
@import 'olympus/styles/settings/_settings.media-query.scss';

.checkout-payment__error-container {
  background: $color-white;
  color: $c-sp-red-500;
  padding: $u-400 0 $u-300;
  text-align: center;
}

// NOTE: Bellow styling is only for Adyen elements
// NOTE: The styling approach is based on the documentation provided by Adyen,
// which basically is to override their styling via their classes
// https://docs.adyen.com/online-payments/drop-in-web/customization#styling

.adyen-checkout__payment-method {
  border: none;
  background: transparent;

  @include mq($screen-md, min) {
    max-width: 100%;
  }

  &__brands {
    & .adyen-checkout__payment-method__image__wrapper {
      margin-right: $u-200;
    }
  }
}

.adyen-checkout__payment-method__header {
  display: flex;
  justify-content: flex-end;
  background: $color-white;
  max-width: 684px; // fixed width to match Adyen input fields
  padding: 0 $u-200;
  margin: 0 auto;

  @include mq($screen-md, min) {
    max-width: 100%;
  }

  .adyen-checkout__payment-method--standalone & {
    padding-bottom: 0;
  }
}

// Hides payment method name and icon
.adyen-checkout__payment-method__header__title {
  display: none;
}

.adyen-checkout__card-input--credit {
  @include mq($screen-md, min) {
    & > div {
      padding: 0 20%; // hack: using percentages due to how HTML is structured
      background: $color-white;
      width: 100%;
    }
  }
}

.adyen-checkout__card-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adyen-checkout__loading-input__form {
  background: $color-white;
  width: 100%;
  padding: 0 $u-200 $u-350;
}

.adyen-checkout__payment-method__details {
  margin: 0 auto;
  padding: 0;
}

.adyen-checkout__field--expiryDate {
  width: 70%;
}

.adyen-checkout__field--securityCode {
  width: 30%;
}

.adyen-checkout__input {
  color: $c-nt-600;
  border-radius: 2px;
  height: 54px; // Fixed height because not possible to expand with padding (it pushes input content down)
  padding: $u-400 $u-250 $u-200;

  &:hover {
    border-color: $c-nt-500;
    box-shadow: none;
  }

  &:focus,
  &:active {
    border-color: $c-pm-500;
    box-shadow: none;
  }

  &--focus {
    box-shadow: none;
    border-color: $c-pm-500;

    &:hover {
      border: 1px solid $c-pm-500;
    }
  }

  &--error {
    border-color: $c-sp-red-500;
    background: $c-sp-red-100;

    &:hover {
      border-color: $c-sp-red-500;
    }
  }
}

.adyen-checkout__error-text {
  color: $c-sp-red-500;
}

.adyen-checkout__label {
  $text: '';
  position: relative;

  &__text {
    color: $c-nt-500;
    font-size: $global-font-size;
    left: $u-250;
    position: absolute;
    top: 22px;
    transform: translateY(0);
    transition: transform 0.2s ease, font-size 0.2s ease, color 0.2s ease;
    will-change: transform, font-size, color;
    z-index: 1;

    $text: &;
  }

  &--filled,
  &--focused {
    #{$text} {
      font-size: $global-font-size-sm;
      transform: translateY(-$u-250);
    }
  }

  &--filled {
    #{$text} {
      color: $c-nt-600;

      &--error {
        color: $c-sp-red-500;
      }
    }
  }

  &--focused {
    #{$text} {
      color: $c-pm-500;
    }
  }
}

.adyen-checkout__button {
  background: $c-sp-green-500;
  border: transparent;

  &:focus {
    background: $c-sp-green-600;
    box-shadow: none;
  }

  &:hover {
    background: $c-sp-green-400;
    box-shadow: none;

    &:focus {
      background: $c-sp-green-600;
      box-shadow: none;
    }
  }

  &--pay {
    width: fit-content;
    padding: $u-250 $u-500;
    background: $c-sp-green-500;
    border-radius: 24px;
    color: $color-white;
    border: transparent;

    &.adyen-checkout__button {
      margin-top: $u-600;
    }
  }

  &__icon {
    display: none;
  }

  &__text {
    color: $color-black;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: 32px;
      height: 32px;
      margin-right: $u-200;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.997 16.41V8.995a3.998 3.998 0 00-4-3.996H6C3.79 5 2 6.79 2 8.996v9.99a3.998 3.998 0 004 3.997h9.148a7.5 7.5 0 0013.996 2 7.488 7.488 0 00-1.147-8.574zM6 6.997h17.998c1.104 0 2 .895 2 1.998v1H4v-1c0-1.103.895-1.998 2-1.998zm-2 4.995v6.994c0 1.103.895 1.998 2 1.998h9.024a7.492 7.492 0 013.825-6.035 7.504 7.504 0 017.148-.09v-2.867H4zM22.498 26.98c-3.038 0-5.5-2.46-5.5-5.494a5.497 5.497 0 015.5-5.495c3.037 0 5.5 2.46 5.5 5.495a5.497 5.497 0 01-5.5 5.494zm1.657-7.57c.31-.422.934-.536 1.396-.254a.924.924 0 01.43.59.861.861 0 01-.153.694l-3.023 4.155c-.167.229-.437.378-.736.406h-.1c-.265.003-.52-.09-.711-.259l-2.016-1.846a.868.868 0 01.053-1.254 1.076 1.076 0 011.368-.048l1.15 1.048 2.343-3.232z' fill='currentColor'/%3E%3C/svg%3E");
    }
  }
}

.adyen-checkout__input--valid {
  border-color: inherit;
}

.dropin-container {
  &--invalid {
    .adyen-checkout__button {
      background: $c-nt-400;
      pointer-events: none;

      &__text {
        color: $color-white;

        &:before {
          filter: invert(1);
        }
      }
    }
  }
}
</style>
