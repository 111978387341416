<template>
  <div
    class="cm-c-checkout__termbox__one-consent-wrapper"
    :class="wrapperModifiers"
  >
    <div
      v-cloak
      class="cm-c-checkout__termbox cm-c-checkout__module--no-padding"
      :class="moduleModifiers"
    >
      <h3
        v-if="content.headline"
        class="cm-c-checkout__termbox__headline"
        v-html="content.headline"
      />

      <c-checkbox
        id="checkout-one-consent"
        name="checkout-one-consent"
        large-checkbox
        :header="content.consentCheckboxHeader"
        :description="content.consentAccepted"
        @event-id="updatedConsentStatus"
      />
    </div>
  </div>
</template>

<script>
import CCheckbox from 'olympus/components/c-checkbox/c-checkbox.vue';
import { mapActions } from 'vuex';

import checkoutOneConsentStore, {
  ACTIONS as ONE_CONSENT_ACTIONS
} from 'webshop/components/checkout/store';

const CHECKOUT_ONE_CONSENT_STORE_NAMESPACE = 'CheckoutOneConsent';

export default {
  name: 'CheckoutOneConsent',

  components: { CCheckbox },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isOneConsentChecked: false
    };
  },

  computed: {
    wrapperModifiers() {
      return {
        'cm-c-checkout__termbox__one-consent-wrapper--no-bg':
          this.content.hiddenBackground
      };
    },

    moduleModifiers() {
      return {
        'cm-c-checkout__module--no-bg': this.content.hiddenBackground
      };
    }
  },

  beforeCreate() {
    this.$store.registerModule(
      CHECKOUT_ONE_CONSENT_STORE_NAMESPACE,
      checkoutOneConsentStore
    );
  },

  beforeDestroy() {
    this.$store.unregisterModule(CHECKOUT_ONE_CONSENT_STORE_NAMESPACE);
  },

  methods: {
    ...mapActions({
      setOneConsentStatus: ONE_CONSENT_ACTIONS.UPDATE_CONSENT_STATUS
    }),

    updatedConsentStatus() {
      this.isOneConsentChecked = !this.isOneConsentChecked;
      this.setOneConsentStatus(this.isOneConsentChecked);
    }
  }
};
</script>
