import {
  PHONE_SMALL,
  PHONE_MAX,
  PHABLET,
  PHABLET_MAX,
  TABLET,
  DESKTOP,
  DESKTOP_LARGE,
  DESKTOP_XLARGE
} from './breakpoints.js';

const MQ_PHONE_SMALL = `(min-width: ${PHONE_SMALL}px)`;
const MQ_PHONE_MAX = `(max-width: ${PHONE_MAX}px)`;
const MQ_PHABLET = `(min-width: ${PHABLET}px)`;
const MQ_PHABLET_MAX = `(max-width: ${PHABLET_MAX}px)`;
const MQ_TABLET = `(min-width: ${TABLET}px)`;
const MQ_DESKTOP = `(min-width: ${DESKTOP}px)`;
const MQ_DESKTOP_LARGE = `(min-width: ${DESKTOP_LARGE}px)`;
const MQ_DESKTOP_XLARGE = `(min-width: ${DESKTOP_XLARGE}px)`;

export { MQ_PHONE_SMALL as PHONE_SMALL };
export { MQ_PHONE_MAX as PHONE_MAX };
export { MQ_PHABLET as PHABLET };
export { MQ_PHABLET_MAX as PHABLET_MAX };
export { MQ_TABLET as TABLET };
export { MQ_DESKTOP as DESKTOP };
export { MQ_DESKTOP_LARGE as DESKTOP_LARGE };
export { MQ_DESKTOP_XLARGE as DESKTOP_XLARGE };
