import { render, staticRenderFns } from "./subscription-value-added-services-selector.vue?vue&type=template&id=ab461406&"
import script from "./subscription-value-added-services-selector.vue?vue&type=script&lang=js&"
export * from "./subscription-value-added-services-selector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab461406')) {
      api.createRecord('ab461406', component.options)
    } else {
      api.reload('ab461406', component.options)
    }
    module.hot.accept("./subscription-value-added-services-selector.vue?vue&type=template&id=ab461406&", function () {
      api.rerender('ab461406', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/webshop/shared/components/product/subscription-value-added-services-selector.vue"
export default component.exports