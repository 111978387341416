<template>
  <div v-if="content" class="cm-o-layout cm-o-layout--main">
    <div v-if="content.headline" class="cm-c-checkout">
      <h2 class="cm-c-checkout__title cm-c-checkout__title--center">
        {{ getContentPropertyValue(content.headline) }}
      </h2>
      <div
        class="
          cm-c-checkout__subtitle
          cm-c-checkout__subtitle--center
          cm-c-checkout__subtitle--body
        "
        v-html="getContentPropertyValue(content.description)"
      ></div>

      <template v-if="init">
        <c-product-card-vas
          v-for="(product, index) in promotedServices"
          :key="index"
          transition="staggered"
          stagger="150"
          :vas-product="product"
          :group-id="groupId"
          promoted
          :local-loading="false"
          :read-more="getContentPropertyValue(content.expandLabel)"
          :price-disclaimer-text="
            getContentPropertyValue(content.priceDisclaimerText)
          "
          :close="getContentPropertyValue(content.collapseLabel)"
          @update="handleServiceStateUpdate"
          @show-multiple-overlay="handleShowMultipleOverlay"
        >
        </c-product-card-vas>

        <div class="cm-grid-vaslist">
          <transition-group
            class="cm-c-category-grid"
            tag="section"
            appear
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <c-product-card-vas
              v-for="(product, index) in newServices"
              :key="index"
              transition="staggered"
              stagger="150"
              :vas-product="product"
              :group-id="groupId"
              :local-loading="false"
              :read-more="getContentPropertyValue(content.expandLabel)"
              :price-disclaimer-text="
                getContentPropertyValue(content.priceDisclaimerText)
              "
              :close="getContentPropertyValue(content.collapseLabel)"
              @update="handleServiceStateUpdate"
              @show-multiple-overlay="handleShowMultipleOverlay"
            >
            </c-product-card-vas>
          </transition-group>
        </div>

        <template v-if="existingServices.length">
          <p class="cm-c-checkout__subtitle">
            {{ getContentPropertyValue(content.existingVasHeadline) }}
          </p>

          <p class="cm-c-checkout__subtitle cm-c-checkout__subtitle--body">
            {{ existingVasDescription }}
          </p>

          <div class="cm-grid-vaslist">
            <transition-group
              class="cm-c-category-grid"
              tag="section"
              appear
              @before-enter="beforeEnter"
              @enter="enter"
              @leave="leave"
            >
              <c-product-card-vas
                v-for="(product, index) in existingServices"
                :key="index"
                transition="staggered"
                stagger="150"
                :vas-product="product"
                :group-id="groupId"
                :local-loading="false"
                :read-more="getContentPropertyValue(content.expandLabel)"
                :price-disclaimer-text="
                  getContentPropertyValue(content.priceDisclaimerText)
                "
                :close="getContentPropertyValue(content.collapseLabel)"
                :old-vas-price-description="
                  getContentPropertyValue(content.oldVasPriceDescription)
                "
                @update="handleServiceStateUpdate"
                @show-multiple-overlay="handleShowMultipleOverlay"
              >
              </c-product-card-vas>
            </transition-group>
          </div>
        </template>
      </template>

      <div v-if="!init || networking" class="cm-c-spinner">
        <span class="sr-only">Loading...</span>
      </div>

      <c-vas-consent-overlay
        v-if="!hasVasConsent"
        :open="init && Boolean(substitutes.length)"
        :vases="substitutes"
        :group-id="groupId"
      />

      <c-vas-multiple-overlay
        :visible="showMultipleVasOverlay"
        :content="content"
        :services="multiVasOverlayServices"
        :brand="multiVasOverlayBrand"
        :group-id="groupId"
        :handle-service-state-update="handleServiceStateUpdate"
        :networking="networking"
      >
      </c-vas-multiple-overlay>
    </div>
  </div>
  <div v-else>
    <div class="cm-c-spinner cm-c-spinner--local">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import contentProviderMixin, {
  populateContentProperty,
  getContentPropertyValue
} from 'olympus/mixins/content-property-mixin';
import { GETTERS, ACTIONS } from 'webshop/store/groups/index.js';
import {
  GETTERS as CONTENT_GETTERS,
  ACTIONS as CONTENT_ACTIONS
} from 'webshop/store/content';
import { GETTERS as BASKET_GETTERS } from 'webshop/store/basket';
import { BASKET_ITEM_TYPES } from '../shopping-cart/basket-constants';
import { getVasContent } from 'webshop/api/vas-service';

import StaggeringFadeIn from 'webshop/mixins/staggering-fade-in.js';
import CProductCardVas from 'webshop/components/c-product-card/c-product-card-vas.vue';
import CVasConsentOverlay from './c-vas-consent-overlay.vue';
import CVasMultipleOverlay from './c-vas-multiple-overlay.vue';
import { OFFERING_TYPE, STATE_INTERACTION } from './vas-constants';

export default {
  name: 'c-vas-overview',

  components: {
    CProductCardVas,
    CVasConsentOverlay,
    CVasMultipleOverlay
  },

  mixins: [StaggeringFadeIn, contentProviderMixin],

  props: {
    blockId: {
      type: [Number, String],
      required: true
    },
    groupId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      init: false,
      networking: false,
      showMultipleVasOverlay: false,
      multiVasOverlayServices: [],
      multiVasOverlayBrand: null,
      promotedVasCode: undefined,
      promotedVasImageUrl: undefined,
      priceDisclaimerText: undefined,
      hasVasConsent: false
    };
  },

  computed: {
    ...mapGetters({
      getVasForGroup: GETTERS.GET_VAS_FOR_GROUP,
      getContentById: CONTENT_GETTERS.CONTENT_BY_ID,
      basketGetItem: BASKET_GETTERS.GET_ITEM
    }),

    /**
     * All services for a given vas group.
     */
    services() {
      const isFeatured = code =>
        code === getContentPropertyValue(this.content.promotedVasCode);

      return this.getVasForGroup(this.groupId)?.map(service => {
        const isProductFeatured = isFeatured(service.code);

        return {
          ...service,
          isFeatured: isProductFeatured,
          featuredImageUrl: isProductFeatured
            ? getContentPropertyValue(this.content.promotedVasImageUrl)
            : '',
          price:
            service.price && this.content.fromPriceLabel
              ? {
                  ...service.price,
                  formattedValueCurrency: populateContentProperty(
                    getContentPropertyValue(this.content.fromPriceLabel),
                    { monthlyPrice: service.price.formattedValueCurrency }
                  )
                }
              : null,
          variants: service.variants.map(variant => {
            const isVariantFeatured = isFeatured(variant.code);

            return {
              ...variant,
              isFeatured: isVariantFeatured,
              featuredImageUrl: isVariantFeatured
                ? getContentPropertyValue(this.content.promotedVasImageUrl)
                : ''
            };
          })
        };
      });
    },

    /**
     * Services that can be added to basket; those that have offering type == 'New'.
     * To avoid showing duplicate promoted & regular products,
     * the following non-promoted cards are filtered out:
     * - those that have the vas product (parent) promoted.
     * - those that have only 1 variant are shown
     *
     * In both cases, the promoted vas would be the same as the non-promoted vas,
     * and the non-promoted vas would therefore be redundant to show.
     */
    newServices() {
      return this.services
        ? this.services.filter(service => {
            const promoted = this.isVasProductPromoted(service);
            const skipPromoted =
              promoted &&
              (!(service.variants.length > 1) || service.isFeatured);

            return (
              service.offeringType === OFFERING_TYPE.NEW_VAS && !skipPromoted
            );
          })
        : [];
    },

    /**
     * Services previously added.
     */
    existingServices() {
      return this.services
        ? this.services.filter(service => {
            return service.offeringType === OFFERING_TYPE.EXISTING_VAS;
          })
        : [];
    },

    /**
     * Promoted services (can be new or existing).
     */
    promotedServices() {
      return this.services
        ? this.services.filter(this.isVasProductPromoted)
        : [];
    },

    /**
     * Existing service substitutes.
     */
    substitutes() {
      return this.services
        ? this.services.filter(service => {
            return (
              service.offeringType === OFFERING_TYPE.EXISTING_VAS &&
              (service.variants.find(variant => variant.substitute?.length) ||
                service.variants.find(
                  variant =>
                    variant.state.interaction === STATE_INTERACTION.NO_CHANGE
                ))
            );
          })
        : [];
    },

    content() {
      const vasContent = this.getContentById(this.blockId) || {};
      return {
        ...vasContent,
        promotedVasCode: this.promotedVasCode,
        promotedVasImageUrl: this.promotedVasImageUrl,
        priceDisclaimerText: this.priceDisclaimerText
      };
    },

    subscriptionName() {
      const subscription = this.basketGetItem({
        groupId: this.groupId,
        type: BASKET_ITEM_TYPES.SUBSCRIPTION
      });
      return subscription.name.display;
    },

    existingVasDescription() {
      return populateContentProperty(
        getContentPropertyValue(this.content.existingVasDescription),
        { subscriptionName: this.subscriptionName }
      );
    }
  },

  created() {
    this.fetchData().then(() => (this.init = true));
  },

  methods: {
    ...mapActions({
      fetchVasForGroup: ACTIONS.FETCH_VAS_FOR_GROUP,
      fetchContentById: CONTENT_ACTIONS.FETCH_CONTENT_BY_ID
    }),

    fetchData() {
      return Promise.all([
        this.fetchVasForGroup({
          blockContextId: this.blockId,
          groupId: this.groupId
        }),
        this.fetchContentById({
          id: this.blockId,
          expand: ['promotedVasImage']
        })
      ])
        .then(() => getVasContent(this.blockId, this.groupId))
        .then(
          ({
            promotedVasCode,
            promotedVasImageUrl,
            priceDisclaimerText,
            hasVasConsent
          }) => {
            this.promotedVasCode = promotedVasCode;
            this.promotedVasImageUrl = promotedVasImageUrl;
            this.hasVasConsent = hasVasConsent;
            this.priceDisclaimerText = priceDisclaimerText;
          }
        );
    },

    async handleServiceStateUpdate(state) {
      if (!state.networking) {
        await this.fetchData().then(() => {
          this.networking = false;
          this.showMultipleVasOverlay = false;
        });
      } else {
        this.networking = true;
      }
    },

    handleShowMultipleOverlay({ vasProduct }) {
      const variantToVasProduct = variant => {
        return {
          ...variant,
          variants: [variant]
        };
      };

      this.showMultipleVasOverlay = true;
      this.multiVasOverlayServices =
        vasProduct.variants.map(variantToVasProduct);
      this.multiVasOverlayBrand = vasProduct.brand;
    },

    /**
     * Checks if a vas product or any of its variants are promoted.
     */
    isVasProductPromoted(vasProduct) {
      return (
        (vasProduct.isFeatured &&
          vasProduct.offeringType !== OFFERING_TYPE.EXISTING_VAS) ||
        vasProduct.variants.some(
          v => v.isFeatured && v.offeringType !== OFFERING_TYPE.EXISTING_VAS
        )
      );
    }
  }
};
</script>
