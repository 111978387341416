var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    {
      staticClass: "c-form-radio-group",
      class: _vm.modifiers,
      attrs: { disabled: _vm.disabled }
    },
    [
      _c(
        "div",
        { staticClass: "c-form-radio-group-wrapper" },
        [
          _vm.hasLabel
            ? _c("legend", [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")])
            : _vm._t("header"),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { role: "radiogroup" } },
            [
              _vm._t("default"),
              _vm._v(" "),
              !_vm.localChecked
                ? _c("input", {
                    attrs: { type: "hidden", name: _vm.name, value: "" }
                  })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("footer")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }