// TODO: move to provider instead, because not every component
// needs to know about them, only the ones that inject it.
const Toast = {
  install: function(Vue) {
    Vue.prototype.$addToast = function(toast, titleTransformCallback) {
      if (!toast.title && !toast.paragraph) {
        return false;
      }

      const title =
        titleTransformCallback && toast.title
          ? titleTransformCallback(toast.title)
          : toast.title;
      const id = new Date().getTime();

      this.$root.$emit('add-toast', { ...toast, id, title });
    };

    Vue.prototype.$removeToast = function(uniqueKey) {
      this.$root.$emit('remove-toast', uniqueKey);
    };
  }
};

export default Toast;
