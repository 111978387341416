<template>
  <fieldset :disabled="disabled" :class="modifiers" class="c-form-radio-group">
    <div class="c-form-radio-group-wrapper">
      <legend v-if="hasLabel">
        {{ label }}
      </legend>
      <slot v-else name="header" />
      <div role="radiogroup">
        <slot />
        <input v-if="!localChecked" type="hidden" :name="name" value="" />
      </div>
      <slot name="footer" />
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'CFormRadioGroup',

  model: {
    prop: 'checked',
    event: 'input'
  },

  props: {
    checked: {
      type: [String, Object, Number, Boolean],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    stacked: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    displayInline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  provide() {
    return {
      bvRadioGroup: this
    };
  },

  data() {
    return {
      localChecked: this.checked
    };
  },

  computed: {
    modifiers() {
      return {
        'c-form-radio-group--stacked': this.stacked,
        'c-form-radio-group--highlighted': this.highlighted,
        'c-form-radio-group--inline': this.displayInline
      };
    },

    groupName() {
      return this.name;
    },

    hasLabel() {
      return this.label !== null;
    }
  },

  watch: {
    checked(newVal) {
      this.localChecked = newVal;
    },
    localChecked(newVal) {
      this.$emit('input', newVal);
    }
  }
};
</script>

<style lang="scss">
.c-form-radio-group {
  position: relative;

  // .c-form-radio-group-wrapper
  &-wrapper {
    & > legend {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bolder;
    }
  }

  // .c-form-radio-group [role="radiogroup"]
  & [role='radiogroup'] {
    display: flex;
  }

  // .c-form-radio-group--stacked
  &--stacked {
    & [role='radiogroup'] {
      display: block;
    }
  }

  // .c-form-radio-group--inline
  &--inline {
    & [role='radiogroup'] {
      .c-form-radio {
        display: inline-block;
      }
    }
  }
}
</style>
