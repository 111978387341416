var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "fieldset" },
    [
      _c(
        "h2",
        { staticClass: "cm-c-checkout__title cm-c-checkout__title--center" },
        [_vm._v("\n    " + _vm._s(_vm.divaSimHeadline) + "\n  ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.simDataList, function(simData, index) {
        return _c("number-diva-items", {
          key: index,
          attrs: {
            "sim-data": simData,
            "move-label": _vm.divaMoveLabel,
            "sim-label": _vm.divaSimLabel,
            "sim-invalid": _vm.divaSimInvalid
          }
        })
      }),
      _vm._v(" "),
      _vm.simDataList.length
        ? _c("div", { staticClass: "cm-c-checkout__simnumber-help" }, [
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.divaHowToFindSim) }
            }),
            _vm._v(" "),
            _c("img", { attrs: { src: _vm.divaImageUrl } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("button", {
        staticClass: "c-btn c-btn--secondary cm-c-checkout__btn",
        attrs: { id: "c-btn-fake-submit", type: "button" },
        on: { click: _vm.accept }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }