import Vue from 'vue';
import { get } from '../../api/api.js';
import { createNamespace } from '../utils';

import REFERENCE_TYPES from '../../../shared/enums/reference-types.js';

const MUTATIONS = createNamespace('CATALOG/MUTATIONS', ['SET_ENTITY']);

export const ACTIONS = createNamespace('CATALOG/ACTIONS', ['FETCH_VARIANTS']);

export const GETTERS = createNamespace('CATALOG/GETTERS', [
  'GET_ENTITY_BY_CODE_AND_TYPE',
  'GET_VARIANT_BY_CODE',
  'GET_VARIANTS_BY_CODE',
  'GET_PRODUCT_BY_CODE',
  'GET_INCLUDED'
]);

const getCollectionByType = (state, type) => {
  let collection = null;
  switch (type) {
    case REFERENCE_TYPES.VARIANT_BASE:
    case REFERENCE_TYPES.VARIANT_BROADBAND:
    case REFERENCE_TYPES.VARIANT_FEE:
    case REFERENCE_TYPES.VARIANT_MOBILE_SUBSCRIPTION:
    case REFERENCE_TYPES.VARIANT_HANDSET:
      collection = state.variants;
      break;
    case REFERENCE_TYPES.PRODUCT_BASE:
      collection = state.products;
      break;
    default:
      collection = state[REFERENCE_TYPES.UNKNOWN];
  }
  return collection;
};

export default {
  state: {
    variants: {},
    products: {},
    [REFERENCE_TYPES.UNKNOWN]: {}
  },

  mutations: {
    [MUTATIONS.SET_ENTITY](state, { id, type, ...data }) {
      let collection = getCollectionByType(state, type);
      Vue.set(collection, id, {
        id,
        type,
        ...data
      });
    }
  },

  actions: {
    [ACTIONS.FETCH_VARIANTS](
      { commit },
      { codes, req: { hateoas, relationships, include } }
    ) {
      const options = {
        params: { codes: codes.join(',') }
      };

      if (typeof hateoas === 'undefined' || hateoas) {
        options.headers = {
          accept: 'application/vnd.api+json'
        };
      }

      if (relationships && relationships.length) {
        options.params.related = relationships.join(',');
      }

      if (include) {
        if (typeof include === 'string') {
          options.params.include = include;
        } else if (include.length) {
          options.params.include = include.join(',');
        }
      }

      return get('/catalog/variants', options)
        .then(response => response.data)
        .then(({ data, included }) => {
          data.forEach(entity => {
            commit(MUTATIONS.SET_ENTITY, entity);
          });

          if (included) {
            included.forEach(entity => {
              commit(MUTATIONS.SET_ENTITY, entity);
            });
          }
          return data;
        });
    }
  },

  getters: {
    [GETTERS.GET_ENTITY_BY_CODE_AND_TYPE]: state => (code, type) => {
      let collection = getCollectionByType(state, type);
      return collection[code] || null;
    },

    [GETTERS.GET_VARIANT_BY_CODE]: state => code => {
      return state.variants[code] || null;
    },

    // Get all variants where object key matches a code.
    [GETTERS.GET_VARIANTS_BY_CODE]: state => codes => {
      return (
        Object.keys(state.variants)
          .filter(code => codes.includes(code))
          .reduce((obj, key) => {
            // Reduce simply turns the array back into an object
            obj[key] = state.variants[key];
            return obj;
          }, {}) || null
      );
    },

    [GETTERS.GET_PRODUCT_BY_CODE]: state => code => {
      return state.products[code] || null;
    },

    /**
     * @description: Searches included variants based on array of
     * keys. Those are camelized, due to how the api contract is set.
     */
    [GETTERS.GET_INCLUDED]: (_state, getters) => entity => {
      const { relationships } = entity || {};
      if (!relationships) {
        return {};
      }
      return Object.keys(relationships).reduce((result, key) => {
        const { data } = relationships[key];
        if (data && data.length) {
          const entities = data.map(({ id: code, type }) =>
            getters[GETTERS.GET_ENTITY_BY_CODE_AND_TYPE](code, type)
          );
          result[key] = entities;
        }
        return result;
      }, {});
    }
  }
};
