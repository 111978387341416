var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    _vm._l(_vm.disclaimers, function(disclaimer, index) {
      return _c(
        "c-information-box",
        {
          key: index,
          attrs: { icon: "service-warning", type: "informative", animatein: "" }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(disclaimer) } })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }