<script>
import { mapGetters } from 'vuex';

import {
  CCheckbox,
  CFormRadioGroup,
  CFormRadio,
  CCollapse,
  CRow,
  CCol,
  CInformationBox
} from 'olympus/components';
import { CAccordion, CAccordionItem } from 'webshop/components';

export default {
  name: 'checkout-creditcheck',

  components: {
    CCheckbox,
    CFormRadioGroup,
    CFormRadio,
    CCollapse,
    CRow,
    CCol,
    CAccordion,
    CAccordionItem,
    CInformationBox
  },

  props: {
    selectedPaymentType: {
      type: String,
      default: ''
    },
    isExistingNumber: {
      type: Boolean,
      default: false
    },
    paymentTypes: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      paymentType: this.selectedPaymentType
    };
  },

  computed: {
    ...mapGetters(['ban', 'banSelectorEnabled']),

    enablePaymentOptions() {
      if (this.isExistingNumber || this.banSelectorEnabled) {
        return false;
      }

      return !this.ban;
    }
  },

  methods: {
    setSelectedPayment(type) {
      this.selectedPaymentType = type;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.checkout-creditcheck-header {
  margin-top: $u-300;
  margin-bottom: $u-600;
}

.checkout-creditcheck {
  border: 1px solid $c-nt-300;
  padding: 1px;
  border-radius: $u-200;
  background: $color-white;
  margin-bottom: $u-250;

  // .checkout-creditcheck--active
  &--active {
    border: 2px solid $color-border-focus;
    padding: 0;
  }

  &__info {
    width: 100%;
    padding: $u-300;
    display: flex;
    justify-content: space-between;
    font-size: $font-size-h4;

    &__left {
      display: flex;

      &__title {
        display: flex;
        align-items: center;
        color: $c-nt-900;
        font-weight: 700;
        text-align: left;
      }
    }

    &__right__title {
      display: flex;
      align-items: center;
      color: $c-nt-900;
      font-weight: 700;
      text-align: right;
    }
  }

  // .checkout-creditcheck__image
  &__image {
    min-width: $u-600;
    min-height: $u-600;
    overflow: hidden;
    border-radius: $u-200;
    margin-right: $u-250;
    display: flex;
    align-items: center;
    justify-content: center;

    // .checkout-creditcheck__image__icon
    &__icon {
      fill: $color-white;
      width: $u-600;
      height: $u-600;
    }
  }

  // .checkout-creditcheck__content
  &__content {
    margin: 0 $u-250 $u-250;

    &__description {
      margin-top: $u-400;

      &:first-child {
        margin-top: 0;
      }

      @include mq($screen-xs, max) {
        margin-top: $u-550;
      }
    }

    @include mq($screen-sm, min) {
      margin: 0 $u-850 $u-550 $u-850;
    }
  }
}

.creditcheck {
  &-payment {
    // .creditcheck-payment__radio
    &__radio {
      font-weight: bold;

      // .creditcheck-payment__radio > label
      & > label {
        display: flex;
        justify-content: space-between;
      }
    }

    // .creditcheck-payment__description
    &__description {
      padding: 10px 10px 10px 1.5rem;
      font-size: x-small;
      border: 1px solid rgb(230, 0, 50);
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
