<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default {
  name: 'checkout-select-number',
  props: {
    apiUrl: String,
    numbers: Array,
    hasMoreNumbers: Boolean,
    selectedNumber: String,
    radioFieldName: String
  },
  data() {
    return {
      currentNumber: this.selectedNumber,
      availableNumbers: this.numbers,
      hasMoreAvailableNumbers: this.hasMoreNumbers
    };
  },
  methods: {
    ...mapMutations(['SHOW_SPINNER', 'HIDE_SPINNER']),
    getNumbers() {
      this.SHOW_SPINNER();

      fetch(this.apiUrl)
        .then(response => response.json())
        .then(data => {
          this.availableNumbers = [...this.availableNumbers, ...data.numbers];

          this.hasMoreAvailableNumbers = data.hasMoreNumbers;

          this.HIDE_SPINNER();
        })
        .catch(error => {
          this.HIDE_SPINNER();

          this.$store.commit('SHOW_DIALOG', {
            componentName: 'error-dialog',
            componentProps: {}
          });
        });
    }
  }
};
</script>
