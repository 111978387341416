import { setUrlFragment } from 'webshop/js/url';

/**
 * Use this mixin to parse the url hash (#, fragment)
 * in order to read (optional) product information.
 */
export default {
  methods: {
    /**
     * Parses the url fragment to extract product information.
     *
     * The url fragment is expected to either contain 2 or 4 properties,
     * based on capacity availability.
     *
     * Url format with capacity & binding period:
     * <url>/#/{color}/{capacity}/{paymentPlanCode}/{bindingPeriodCode}
     *
     * Url format without capacity:
     * <url>/#/{color}/{paymentPlanCode}
     *
     * @returns { Object } ProductInfo.
     * @property { string } color
     * @property { number } [capacity]
     * @property { string } paymentPlanCode
     * @property { string } [bindingPeriodCode]
     */
    parseProductUrlFragments() {
      if (!document.location.hash) {
        return null;
      }

      const fragments = document.location.hash
        .replace(/^[#/]*/, '')
        .replace(/-/g, ' ')
        .split('/');
      const hasCapacityOptions = fragments.length > 2;

      if (!hasCapacityOptions) {
        return {
          color: fragments[0],
          paymentPlanCode: fragments[1]
        };
      }

      return {
        color: fragments[0],
        capacity: parseInt(fragments[1], 10),
        paymentPlanCode: fragments[2],
        bindingPeriodCode: fragments[3]
      };
    },

    /**
     * Builds a product url from the provided attributes.
     *
     * @param { string } color
     * @param { string } [capacity]
     * @param { string } [capacityDataUnit]
     * @param { string } paymentPlanCode
     * @param { string } [bindingPeriodCode]
     *
     * @return { string } url fragment
     */
    buildProductUrlFragment(
      color,
      capacity,
      capacityDataUnit = 'gb',
      paymentPlanCode,
      bindingPeriodCode
    ) {
      if (!color) {
        return false;
      }

      let fragment = '';

      fragment += `/${color}`;

      if (typeof capacity === 'number' && capacity !== 0) {
        fragment += `/${capacity}-${capacityDataUnit}`;
      }

      if (paymentPlanCode) {
        fragment += `/${paymentPlanCode}`;
      }

      if (bindingPeriodCode || bindingPeriodCode === 0) {
        fragment += `/${bindingPeriodCode}`;
      }

      return fragment.replace(/[\s]/g, '-')?.toLowerCase();
    },

    /**
     * Sets the product url fragment to the provided values.
     *
     * @param { string } color
     * @param { string } [capacity]
     * @param { string } [capacityDataUnit]
     * @param { string } paymentPlanCode
     * @param { string } [bindingPeriodCode]
     */
    setProductUrlFragment(
      color,
      capacity,
      capacityDataUnit = 'gb',
      paymentPlanCode,
      bindingPeriodCode
    ) {
      const urlFragment = this.buildProductUrlFragment(
        color,
        capacity,
        capacityDataUnit,
        paymentPlanCode,
        bindingPeriodCode
      );
      if (!this.external) {
        this.setUrlFragment(urlFragment);
      }
    },

    setUrlFragment
  }
};
