// TODO dan: this should be broken down into smaller modules. Getters, mutations, etc. should also be moved to modules (i.e. grouped by domain).
import * as getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations';

const app = window.__APP__ || {};

//todo: DKT-19107: update shop module with the current store architecture
export const GETTERS = {
  IS_ENTERPRISE: 'isEnterprise',
  SORT_BY: 'sortBy',
  IS_EXTERNAL_RESELLER: 'isExternalReseller',
  MINIMUM_PRICE_DETAILS: 'SHOP_GETTERS/MINIMUM_PRICE_DETAILS',
  BASKET_PRICE_DETAILS: 'SHOP_GETTERS/BASKET_PRICE_DETAILS',
  PRICES: 'SHOP_GETTERS/PRICES',
  BASKET_PRICE_SUMMARY: 'SHOP_GETTERS/BASKET_PRICE_SUMMARY',
  IS_GLOBAL_SPINNER_OPEN: 'SHOP_GETTERS/IS_GLOBAL_SPINNER_OPEN'
};

/**
 * @param {Object} state.selectedFacets Contains a grouped facet
 * values, formatted:
 * {
 *    id: 'Facet_ID',
 *    name: 'PropertyName',
 *    values: ['Value_1', 'Value_2', ...]
 * }
 *
 */
const appState = {
  basketPrices: {
    data: {},
    networking: false
  },
  checkout: {
    ban: undefined,
    banSelectorEnabled: false
  },
  compare: [],
  compareSettings: {
    maxCompare: 0,
    minCompare: 0,
    url: ''
  },
  dialog: {
    currentDialog: null,
    currentProps: null,
    visible: false
  },
  errors: {
    badInput: 'Ugyldig værdi',
    patternMismatch: 'Værdi svarer ikke til det valgte format',
    rangeOverflow: 'Værdi større en tilladt',
    rangeUnderflow: 'Værdi mindre end tilladt',
    tooLong: 'Værdi er for lang',
    tooShort: 'Værdi er for kort',
    typeMismatch: 'Ugyldig værdi',
    valueMissing: 'Feltet er ikke udfyldt'
  },
  global: {
    basketUrl: '',
    contextId: app.contextId,
    currentBalance: 0,
    enterprise: false,
    externalReseller: false
  },
  minimumPrice: {},
  modal: false,
  numberConfig: {
    active: 0,
    items: [],
    vm: {}
  },
  orderNumber: null,
  prices: {},
  pricesSummary: {
    data: {},
    networking: false
  },
  productSearchResult: {},
  searchSettings: {},
  selectedFacets: [],
  sortBy: '',
  spinnerOpen: false,
  spinnerShowTimeout: null
};

export default {
  state: appState,
  actions,
  getters: {
    ...getters,
    [GETTERS.IS_ENTERPRISE]: getters.isEnterprise,
    [GETTERS.PRICES]: getters.prices,
    [GETTERS.SORT_BY]: getters.sortBy,
    [GETTERS.IS_EXTERNAL_RESELLER]: getters.isExternalReseller,
    [GETTERS.BASKET_PRICE_DETAILS]: state => state.basketPrices.data,
    [GETTERS.BASKET_PRICE_SUMMARY]: state => state.pricesSummary.data,
    [GETTERS.IS_GLOBAL_SPINNER_OPEN]: state => state.spinnerOpen
  },
  mutations
};
