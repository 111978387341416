export default {
  //TODO: Make this an animation component instead of a mixin
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },

    enter(el) {
      var delay = this.indexSubtraction
        ? (el.dataset.index - this.indexSubtraction) * 150
        : el.dataset.index * 150;
      setTimeout(function() {
        el.style.opacity = 1;
      }, delay);
    },

    leave(el) {
      var delay = 0;
      setTimeout(function() {
        el.style.opacity = 0;
      }, delay);
    }
  }
};
