import { setUrlFragment } from 'webshop/js/url';

/**
 * Use this mixin to parse the url hash (#, fragment)
 * in order to read (optional) subscription information.
 */
export default {
  methods: {
    /**
     * Parses the url fragment to extract subscription information.
     *
     * Url format with binding period code:
     * <url>/#/{bindingPeriodCode}
     *
     * @returns { Object } SubscriptionInfo.
     * @property { string } [bindingPeriodCode]
     */
    parseSubscriptionUrlFragments() {
      if (!document.location.hash) {
        return null;
      }

      const fragments = document.location.hash
        .replace(/^[#/]*/, '')
        .replace(/-/g, ' ')
        .split('/');

      return {
        bindingPeriodCode: fragments[0]
      };
    },

    /**
     * Builds a subscription url from the provided attributes.
     *
     * @param { string } [bindingPeriodCode]
     *
     * @return { string } url fragment
     */
    buildSubscriptionUrlFragment(bindingPeriodCode) {
      let fragment = '';

      if (bindingPeriodCode || bindingPeriodCode === 0) {
        fragment += `/${bindingPeriodCode}`;
      }

      return fragment.replace(/[\s]/g, '-').toLowerCase();
    },

    /**
     * Sets the subscription url fragment to the provided values.
     *
     * @param { string } [bindingPeriodCode]
     */
    setSubscriptionUrlFragment(bindingPeriodCode) {
      const urlFragment = this.buildSubscriptionUrlFragment(bindingPeriodCode);
      this.setUrlFragment(urlFragment);
    },

    setUrlFragment
  }
};
