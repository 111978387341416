<template>
  <div class="c-box" :class="modifiers">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'c-box',

  props: {
    shadow: {
      type: Boolean,
      default: false
    },
    animatein: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modifiers() {
      return {
        'c-box--shadow': this.shadow,
        'c-box--animate-in': this.animatein,
        'c-box--white': this.white
      };
    }
  }
};
</script>

<style lang="scss">
.c-box {
  background-color: #f2f2f2;
  margin-bottom: 15px;
  padding: 20px;

  // .c-box--shadow
  &--shadow {
    box-shadow: 0 2px 2px hsla(0, 0%, 60%, 0.24), 0 0 2px hsla(0, 0%, 60%, 0.12);
  }

  // .c-box--animate-in
  &--animate-in {
    animation-name: animateInCbox;
    animation-duration: 300ms;
    animation-iteration-count: 1;
    animation-direction: forwards;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    @keyframes animateInCbox {
      0% {
        opacity: 0;
        transform: scale(0.5, 0.8);
      }
      60% {
        opacity: 1;
        transform: scale(1, 0.8);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }

  // .c-box--white
  &--white {
    background-color: #fff;
  }
}
</style>
