/**
 * Checks if there is an installment period mismatch
 * between product already existing in the basket and
 * the currently selected product installment period.
 *
 *  TODO DKT-3279 & DKT-3119 no need for this to be a mixin anymore
 */
export default {
  computed: {
    existingInstallmentsPeriod() {
      return this.installmentsMismatchError?.currentInstallmentPeriod;
    },

    selectedInstallmentCode() {
      return parseInt(this.selectedInstallment?.code, 10);
    },

    installmentMismatch() {
      return (
        !!this.existingInstallmentsPeriod &&
        !!this.selectedInstallment &&
        this.selectedInstallmentCode !== this.existingInstallmentsPeriod
      );
    },

    installmentMismatchInterpolants() {
      return {
        existing: this.existingInstallmentsPeriod,
        selected: this.selectedInstallmentCode
      };
    },

    installmentMatchSuggest() {
      return (
        this.installmentMismatch &&
        this.selectedVariant?.installment?.data?.find(
          x => parseInt(x.code, 10) === this.existingInstallmentsPeriod
        )
      );
    },

    // todo: Improve. Due to bad state handling in the component
    // there is mismatch between vm/local/cart item representation
    // of the same object, therefore this.
    selectedBasketProduct() {
      if (!this.selectedVariant || !this.selectedInstallment) {
        return {};
      }

      return {
        image: this.selectedVariant.imageUrls[0],
        name: this.productName,
        payment: this.selectedInstallment.name,
        price: this.selectedInstallment.priceText
      };
    }
  },

  methods: {
    async resolveInstallmentMismatch(accepted) {
      if (accepted) {
        await this.onInstallmentSelected(this.installmentMatchSuggest);
        this.addToBasket();
      }

      this.$refs.installmentsModal.hide();
      this.installmentsMismatchError = null;
    }
  }
};
