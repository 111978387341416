import { render, staticRenderFns } from "./checkout-summary-configuration.vue?vue&type=template&id=b4f8b724&scoped=true&"
import script from "./checkout-summary-configuration.vue?vue&type=script&lang=js&"
export * from "./checkout-summary-configuration.vue?vue&type=script&lang=js&"
import style0 from "./checkout-summary-configuration.vue?vue&type=style&index=0&id=b4f8b724&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4f8b724",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4f8b724')) {
      api.createRecord('b4f8b724', component.options)
    } else {
      api.reload('b4f8b724', component.options)
    }
    module.hot.accept("./checkout-summary-configuration.vue?vue&type=template&id=b4f8b724&scoped=true&", function () {
      api.rerender('b4f8b724', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/webshop/shared/components/checkout/checkout-summary-configuration/checkout-summary-configuration.vue"
export default component.exports