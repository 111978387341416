
/**
 * OneBlock Custom.
 * @module oneblock-custom.mjs
 * @version 1.0.20
 * @summary 26-02-2020
 * @author Mads Stoumann
 * @description Custom client-code / functionality for OneBlock, not part of standard package.
 */

/**
 * @function oneBlockCustom
 * @description Runs exported custom functionality, both client-side and in OB Editor.
 * @param { Boolean } editMode If `true`, will only run in editor.
 */
export function oneBlockCustom(editMode = false) {
	renderShapes();
}

/* Custom SVG Shapes for Telia / Callme. 
NOTE: clipPaths must be in a `viewBox` of 1, so divide `1` with the values in the existing viewBox and add to a `transform="scale"`.
Example: Original `viewBox="0 0 50 100"`. Add `transform="scale(0.02, 0.01)"` to `clipPath`.
*/
export function renderShapes() {
	document.body.insertAdjacentHTML('beforeend', `
	<svg style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<defs>
			<clipPath id="svg-curve-dwn" clipPathUnits="objectBoundingBox">
				<path d="M0,0 L1,0 L1,1 Q0.5,0.75 0,1"></path>
			</clipPath>
			<clipPath id="svg-curve-telia" clipPathUnits="objectBoundingBox" transform="scale(0.025)">
				<path d="M0,0V39s11.41-4.5,20.09-5.13c7.49-.54,11.25.14,13.45.81A35.31,35.31,0,0,1,40,37.39V0Z"/>
			</clipPath>
			<clipPath id="svg-pebble-telia" clipPathUnits="objectBoundingBox" transform="scale(0.01)">
				<path d="M9.5 68.2l.6 1c.1.2.2.4.3.5.5.8 1.1 1.7 1.6 2.5v.1c.1.2.2.4.4.5.3.5.6.9.9 1.4.8 1.2 1.7 2.3 2.6 3.5.6.7 1.1 1.5 1.7 2.2.6.7 1.1 1.3 1.7 2 .2.2.3.4.5.5l.1.1c3.3 2.5 3.3 2.5 0 0 .5.5 1 1.1 1.5 1.6h.1c1.1 1.2 2.2 2.3 3.3 3.3l.7.7c.1.1.2.1.2.2.2.2.4.4.7.6.7.7 1.5 1.3 2.3 1.9.7.5 1.3 1 2 1.5 1.4 1 2.8 1.9 4.1 2.8 1.5.9 2.9 1.6 4.4 2.3 1.2.5 2.3.9 3.5 1.3 2 .6 4 .9 5.9.9 7.9 0 17.3-5.3 25.8-14.5l.1-.1c2.5-2.7 4.9-5.8 7.1-9.1l2.1-3.3c1.5-2.6 3-5.3 4.3-8.1.2-.4.4-.8.5-1.1.8-1.8 1.6-3.6 2.3-5.5.4-1 .7-2 1.1-3 .5-1.3.9-2.7 1.3-4.1v-.1c.2-.8.4-1.6.7-2.4 0 0 0-.1.1-.1.1-.3.1-.6.2-.9.3-1.3.6-2.7.9-4.1v-.1c.2-1 .4-2.1.6-3.1.2-1.3.4-2.6.6-4 .1-1 .2-1.9.3-2.9.2-1.7.3-3.3.4-5v-1.6-.2c0-.8.1-1.6.1-2.5 0-1-.1-2-.1-3-.1-1.1-.2-2.1-.4-3.1-.2-1.3-.6-2.4-1-3.6-.4-1-.8-1.9-1.2-2.7v-.1c-.1-.1-.1-.2-.2-.4-.5-.8-1-1.6-1.5-2.3-.5-.6-1-1.2-1.6-1.8-.7-.7-1.5-1.4-2.3-2-.6-.5-1.3-.9-2-1.3-1.3-.8-2.7-1.4-4.2-1.9 0 0-.1 0-.1-.1-1.6-.5-3.2-1-4.9-1.3-2.1-.4-4.4-.6-6.7-.7h-1.1c-2.7 0-5.4.2-8.2.5-1.9.2-3.8.5-5.7.8h-.1L55 1c-3.1.6-6.1 1.3-9.2 2.2-9.1 2.7-17.8 6.9-25 12.1-.1.1-.3.2-.4.3-.1.1-.2.1-.2.2-.2.2-.4.3-.6.5h-.1c-2 1.5-3.9 3.1-5.7 4.8v.1c-1.5 1.5-2.9 3-4.2 4.6-.2.2-.3.4-.5.7l-.1.1c-.8 1.1-1.6 2.2-2.3 3.4-.8 1.3-1.4 2.5-2 3.8-.6 1.3-1.1 2.7-1.5 4.1v.1c-.1 1.2-.2 1.2 0 0-.4 1.4-.7 2.8-.8 4.2-.1.9-.2 1.9-.2 2.8v.1c0 1.4.1 2.8.4 4.2.1.8.3 1.5.5 2.3.4 1.7.9 3.3 1.5 5.1.1.4.3.8.5 1.2.5 1.4 1.1 2.7 1.7 4.1.3.6.6 1.3.9 1.9l.1.1.2.4c.5.9 1 1.9 1.5 2.8.1.1.1.2.2.4-.3.5-.3.5-.2.6 3.2 2.8 3.2 2.8 0 0z"/>
			</clipPath>
		</defs>
	</svg>`);
}