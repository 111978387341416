var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-o-layout cm-o-layout--main" }, [
    _c("div", { staticClass: "cm-container" }, [
      _c("div", [
        _c(
          "a",
          {
            staticClass: "cm-link--back",
            attrs: { href: _vm.productComparison.previousPageLink }
          },
          [_vm._v(_vm._s(_vm.productComparison.previousPageText))]
        )
      ]),
      _vm._v(" "),
      _c("fieldset", [
        _c("section", { staticClass: "c-compare" }, [
          _c("h2", { staticClass: "sr-only" }, [_vm._v("Comparison")]),
          _vm._v(" "),
          _c("h2", { staticClass: "c-compare__headline c-typography-h4" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.productComparison.pageHeadLine) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "header",
            { staticClass: "c-compare__header" },
            [
              _vm._l(_vm.productComparison.products, function(item, itemIdx) {
                return [
                  _c("c-compare-product-card", {
                    key: itemIdx,
                    attrs: {
                      product: item.key,
                      "button-caption": _vm.productComparison.buttonCaption
                    }
                  })
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "c-compare__body" },
            [
              _vm._l(_vm.productComparison.specificationLabels, function(
                specificationLabel,
                specificationLabelIdx
              ) {
                return [
                  _c(
                    "div",
                    {
                      key: specificationLabelIdx,
                      staticClass: "c-compare__specification_container"
                    },
                    [
                      _c("h2", { staticClass: "c-typography-h5" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(specificationLabel.headLine) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("c-compare-icon-row", {
                        attrs: {
                          "specification-label": specificationLabel,
                          "product-property-values": _vm.productPropertyValues
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "c-compare__toggle-button c-typography-body-sm",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.toggleSection(specificationLabelIdx)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.expandedSections.includes(
                                  specificationLabelIdx
                                )
                                  ? _vm.productComparison.showLessText
                                  : _vm.productComparison.showMoreText
                              ) +
                              "\n                "
                          ),
                          _c("c-icon", {
                            attrs: {
                              "symbol-id": _vm.expandedSections.includes(
                                specificationLabelIdx
                              )
                                ? "arrow-up"
                                : "arrow-down"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "c-compare__specification-list",
                          class: {
                            expanded: _vm.expandedSections.includes(
                              specificationLabelIdx
                            )
                          }
                        },
                        [
                          specificationLabel.propertyGroups.length > 0
                            ? _vm._l(
                                specificationLabel.propertyGroups,
                                function(propertyGroup, propertyGroupIdx) {
                                  return _c(
                                    "div",
                                    { key: propertyGroupIdx },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "c-typography-body" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(propertyGroup.headLine) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("c-compare-specification-list-row", {
                                        attrs: {
                                          "specification-list":
                                            propertyGroup.specificationList,
                                          headline: propertyGroup.headLine,
                                          "product-property-values":
                                            _vm.productPropertyValues
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              )
                            : [
                                _c("c-compare-specification-list-row", {
                                  attrs: {
                                    "specification-list":
                                      specificationLabel.specificationList,
                                    headline: specificationLabel.headLine,
                                    "product-property-values":
                                      _vm.productPropertyValues
                                  }
                                })
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }