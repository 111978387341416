<template>
  <div class="shopping-cart-purchase-member">
    <div class="shopping-cart-purchase-member__icon-container">
      <c-icon
        symbol-id="service-guiding"
        class="shopping-cart-purchase-member__icon"
        data-testid="purchase-member-icon"
      ></c-icon>
    </div>

    <div
      v-if="reachedMaxMemberCount"
      class="shopping-cart-purchase-member__desc"
    >
      <h3>{{ contentLocal.purchaseMemberMaxMemberCountReachedText }}</h3>
    </div>

    <div v-else class="shopping-cart-purchase-member__desc">
      <h3
        v-if="
          contentLocal.purchaseMemberIntroText && offering && getRelatedMaster
        "
        v-html="
          populateContentProperty(contentLocal.purchaseMemberIntroText, {
            maxMembers: offering.maxLimit,
            subscriptionName: getRelatedMaster.name.display
          })
        "
      ></h3>

      <small
        v-if="
          contentLocal.purchaseMemberDescription && offering && getRelatedMaster
        "
        v-html="
          populateContentProperty(contentLocal.purchaseMemberDescription, {
            maxMembers: offering.maxLimit,
            subscriptionName: getRelatedMaster.name.display,
            subscriptionMemberName: offering.name.display
          })
        "
      ></small>

      <button
        v-if="contentLocal.purchaseMemberButtonText && offering"
        class="
          c-btn c-btn--light c-btn--sm
          shopping-cart-purchase-member__button
        "
        @click="add"
        v-html="
          populateContentProperty(contentLocal.purchaseMemberButtonText, {
            subscriptionMemberName: offering.name.display,
            subscriptionMemberPrice: offering.price.formattedValueCurrency
          })
        "
      ></button>
    </div>
  </div>
</template>

<script>
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import { mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../../store/basket';

import { ITEM_TYPES } from '../../../enums/access-technology-types.js';
import { CIcon } from '../../c-icon';

const DEFAULT_CONTENT = {
  purchaseMemberButtonText: 'Tilkøb',
  purchaseMemberDescription:
    'Tilføj et abonnement – så sparer du 30 kr/md og de får fri data.',
  purchaseMemberIntroText:
    'Del indholdet af {{subscriptionName}} abonnementet med op til {{maxMembers}} ekstra brugere',
  purchaseMemberMaxMemberCountReachedText: null
};

export default {
  name: 'PurchaseMember',

  components: { CIcon },

  mixins: [ContentPropertyMixin],

  props: {
    group: {
      type: Object,
      required: true
    },
    offering: {
      type: Object,
      required: true
    },
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  computed: {
    getRelatedMaster() {
      return this.group
        ? this.group.items.find(item => item.type === ITEM_TYPES.SUBSCRIPTION)
        : null;
    },
    currentGroupMemberCount() {
      return this.getRelatedMaster
        ? this.getRelatedMaster.attributes?.membersCount
        : 0;
    },
    reachedMaxMemberCount() {
      return this.offering
        ? this.currentGroupMemberCount >= this.offering.maxLimit
        : null;
    }
  },

  methods: {
    ...mapActions({
      addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION
    }),
    add() {
      this.addSubscription({
        code: this.offering.code,
        groupId: this.group.id,
        quantity: 1,
        continueWithoutLoyaltyVas: true
      });
    }
  }
};
</script>
