var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-product-tooltip", class: _vm.className },
    [
      _vm.data && !_vm.data.length
        ? _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.data, function(row, rowIndex) {
        return _c(
          "div",
          { key: rowIndex, staticClass: "cm-c-product-tooltip--row" },
          [
            _c("div", { staticClass: "cm-c-total__row" }, [
              _c("div", { staticClass: "cm-c-total__name" }, [
                _vm._v("\n        " + _vm._s(row.key) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-c-total__value" }, [
                _vm._v("\n        " + _vm._s(row.value) + "\n      ")
              ])
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }