var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classObject }, [
    _c("div", { staticClass: "c-tabs-nav" }, [
      _c(
        "ul",
        _vm._l(_vm.items, function(tab, idx) {
          return _c(
            "li",
            {
              key: tab.uid,
              class: { active: tab.uid === _vm.activeLocal.uid }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    role: "tab",
                    href: "#",
                    target: "_self",
                    "aria-selected": tab.uid === _vm.activeLocal.uid,
                    "aria-controls": tab.uid,
                    "aria-posinset": idx,
                    "aria-setsize": _vm.items.length
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toggle(tab)
                    }
                  }
                },
                [_vm._v(_vm._s(tab.title))]
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "content", staticClass: "c-tabs-content" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }