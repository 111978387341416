<template>
  <div class="ribbon-banner" :class="getClass">
    <div class="ribbon-banner__angle"></div>
    <div class="ribbon-banner__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const positions = ['top-left', 'bottom-left', 'bottom-right', 'top-right'];
const sizes = ['large', 'small'];
const themes = ['discount', 'assent', 'current'];

export default {
  name: 'RibbonBanner',
  props: {
    flipPosition: {
      type: String,
      default: 'top-left',
      validator: val => positions.includes(val)
    },
    size: {
      type: String,
      default: 'small',
      validator: val => sizes.includes(val)
    },
    theme: {
      type: String,
      default: 'assent',
      validator: val => themes.includes(val)
    }
  },
  computed: {
    getClass() {
      return [
        `ribbon-banner--${this.size}`,
        `ribbon-banner--${this.theme}`,
        `ribbon-banner--${this.flipPosition}`
      ];
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.ribbon-banner {
  $root: &;
  position: absolute;
  display: inline-block;
  color: $color-white;
  font-weight: 400;
  display: flex;
  align-items: center;

  // Angle sizes
  &--small {
    --angle-size-width: #{$u-200};
    --angle-size-height: #{$u-150};
  }

  &--large {
    --angle-size-width: #{$u-300};
    --angle-size-height: #{$u-200};
  }

  // Ribbon-banner color variants
  &--assent {
    --background: #{$c-sp-green-500};
    --background-dark: #{$c-sp-green-700};
    color: $c-nt-900;
  }

  &--discount {
    --background: #{$c-sp-red-500};
    --background-dark: #{$c-sp-red-700};
  }

  &--current {
    --background: #{$c-pm-200};
    --background-dark: #{$c-pm-300};
    color: $c-nt-900;
  }

  // Border-Radius depending on size & position
  $ribbon-b-radius: 3px;

  &--small {
    &#{$root}--top-left {
      --text-container-border-radius: 0 #{$ribbon-b-radius} #{$ribbon-b-radius}
        #{$ribbon-b-radius};
    }
    &#{$root}--top-right {
      --text-container-border-radius: 0 #{$ribbon-b-radius} 0 #{$ribbon-b-radius};
    }
    &#{$root}--bottom-left {
      --text-container-border-radius: #{$ribbon-b-radius} #{$ribbon-b-radius} #{$ribbon-b-radius}
        0;
    }
    &#{$root}--bottom-right {
      --text-container-border-radius: #{$ribbon-b-radius} 0 #{$ribbon-b-radius}
        #{$ribbon-b-radius};
    }
  }

  &--large {
    &#{$root}--top-left {
      --text-container-border-radius: 0 #{$u-200} #{$ribbon-b-radius} #{$ribbon-b-radius};
    }
    &#{$root}--top-right {
      --text-container-border-radius: #{$ribbon-b-radius} #{$u-200} 0 #{$ribbon-b-radius};
    }
    &#{$root}--bottom-left {
      --text-container-border-radius: #{$u-200} #{$ribbon-b-radius} #{$ribbon-b-radius}
        0;
    }
    &#{$root}--bottom-right {
      --text-container-border-radius: #{$ribbon-b-radius} 0 #{$u-200} #{$ribbon-b-radius};
    }
  }

  // Ribbon-Banner Angle Positioning
  &--top-left {
    --angle-position-bottom: unset;
    --angle-position-left: calc(-1 * var(--angle-size-width));
    --angle-position-right: unset;
    --angle-position-top: 0;
    --angle-transform-origin: left top;
    --text-transform-origin: top center;
  }

  &--bottom-left {
    --angle-position-bottom: 0;
    --angle-position-left: calc(-1 * var(--angle-size-width));
    --angle-position-right: unset;
    --angle-position-top: unset;
    --angle-transform-origin: left top;
    --text-transform-origin: bottom center;
  }

  &--bottom-right {
    --angle-position-bottom: unset;
    --angle-position-left: unset;
    --angle-position-right: 0;
    --angle-position-top: calc(-1 * var(--angle-size-height));
    --angle-transform-origin: right top;
    --text-transform-origin: right top;
    --angle-size-width: #{$u-150};
    --angle-size-height: #{$u-200};
  }

  &--top-right {
    --angle-position-bottom: calc(-1 * var(--angle-size-height));
    --angle-position-left: unset;
    --angle-position-right: 0;
    --angle-position-top: unset;
    --angle-transform-origin: right top;
    --text-transform-origin: right top;
    --angle-size-width: #{$u-150};
    --angle-size-height: #{$u-200};
  }

  &__angle {
    width: var(--angle-size-width);
    height: var(--angle-size-height);
    position: absolute;
    transform: scale(0);
    animation: transformNoneAnimation 0.1s 0.5s forwards ease-in;
    top: var(--angle-position-top);
    bottom: var(--angle-position-bottom);
    left: var(--angle-position-left);
    right: var(--angle-position-right);
    &:before {
      display: block;
      content: '';
      background: var(--background);
      position: absolute;
      z-index: 1;
    }
    &:after {
      display: block;
      content: '';
      background: var(--background-dark);
      position: absolute;
      z-index: 2;
    }
  }

  // Ribbon-Banner Positioning
  $angle-b-radius: 100px;

  &--top-left {
    top: 0;
    transform: translateY(calc(-1 * var(--angle-size-height)));
    #{$root}__angle {
      transform-origin: var(--angle-transform-origin);
      &:before {
        width: 50%;
        height: 100%;
        top: 0;
        right: 0;
      }
      &:after {
        display: block;
        width: 100%;
        height: 100%;
        border-top-left-radius: $angle-b-radius;
        border-top-right-radius: $angle-b-radius;
        top: 0;
      }
    }
  }

  &--top-right {
    top: calc(-1 * var(--angle-size-height));
    left: unset !important;
    right: calc(-1 * var(--angle-size-width));
    #{$root}__angle {
      transform-origin: var(--angle-transform-origin);
      animation-delay: 0.8s;
      &:before {
        width: 100%;
        height: 50%;
        top: 0;
        right: 0;
      }
      &:after {
        display: block;
        width: 100%;
        height: 100%;
        border-top-right-radius: $angle-b-radius;
        border-bottom-right-radius: $angle-b-radius;
        top: 0;
      }
    }
  }

  &--bottom-left {
    bottom: calc(-1 * var(--angle-size-height));
    #{$root}__angle {
      transform-origin: var(--angle-transform-origin);
      &:before {
        width: 50%;
        height: 100%;
        bottom: 0;
        right: 0;
      }
      &:after {
        display: block;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: $angle-b-radius;
        border-bottom-right-radius: $angle-b-radius;
        bottom: 0;
      }
    }
  }

  &--bottom-right {
    bottom: calc(-1 * var(--angle-size-height));
    left: unset !important;
    right: calc(-1 * var(--angle-size-width));
    #{$root}__angle {
      transform-origin: var(--angle-transform-origin);
      &:before {
        width: 100%;
        height: 50%;
        bottom: 0;
        right: 0;
      }
      &:after {
        display: block;
        width: 100%;
        height: 100%;
        border-bottom-right-radius: $angle-b-radius;
        border-top-right-radius: $angle-b-radius;

        bottom: 0;
      }
    }
  }

  &__text {
    display: inline-block;
    opacity: 0;
    background: var(--background);
    transform-origin: var(--text-transform-origin);
    transform: scaleY(0);
    animation: transformNoneAnimation 0.3s 0.6s ease-out forwards;
    z-index: -1;
    border-radius: var(--text-container-border-radius);
    padding: $u-200 $u-250;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  &--small {
    font-size: $font-size-h6;
    line-height: 1;
  }

  &--large {
    font-size: $font-size-h5;
    line-height: $u-300;
    @include mq($screen-sm, min) {
      line-height: $u-350;
    }
  }
}

@keyframes transformNoneAnimation {
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes textAnimation {
  to {
    opacity: 1;
    transform: none;
  }
}
</style>
