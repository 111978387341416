var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.computedTag,
    _vm._b(
      {
        tag: "component",
        staticClass: "c-btn",
        class: _vm.modifiers,
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      },
      "component",
      _vm.computedProps,
      false
    ),
    [
      _vm.isClose ? _c("span", [_vm._v("Close")]) : _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }