var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-chase" }, [
      _c(
        "div",
        { staticClass: "spinner-chase__section spinner-chase__section--right" },
        [
          _c("div", {
            staticClass:
              "spinner-chase__section__bar spinner-chase__section__bar--right"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "spinner-chase__section spinner-chase__section--left" },
        [
          _c("div", {
            staticClass:
              "spinner-chase__section__bar spinner-chase__section__bar--left"
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }