var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "empty-basket" },
    [
      _c(
        "c-button",
        {
          class: _vm.buttonClasses,
          attrs: { reset: "" },
          on: {
            click: function($event) {
              return _vm.handleModalState(true)
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.contentLocal.clearBasketLabel))]),
          _vm._v(" "),
          _c("c-icon", {
            staticClass: "empty-basket__button-link-icon",
            attrs: { "symbol-id": "service-trash" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: { centered: "", visible: _vm.isModalOpen },
          on: {
            close: function($event) {
              return _vm.handleModalState(false)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "empty-basket__modal-content" },
            [
              _c("h2", { staticClass: "empty-basket__modal-message" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.contentLocal.heading) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n        " + _vm._s(_vm.contentLocal.bodyText) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "c-button",
                {
                  staticClass: "empty-basket__modal-accept-button",
                  on: { click: _vm.handleClearBasket }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.contentLocal.acceptButtonLabel) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "c-button",
                {
                  staticClass: "empty-basket__button-link",
                  attrs: { reset: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleModalState(false)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.contentLocal.cancelButtonLabel) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }