var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "attribute-selector", class: _vm.classObject },
    [
      _vm.title
        ? _c(
            "h3",
            { staticClass: "attribute-selector__title" },
            [
              _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
              _vm.tooltipText
                ? _c(
                    "popover-panel",
                    {
                      staticClass: "attribute-selector__tooltip",
                      attrs: {
                        description: _vm.tooltipText,
                        "popover-offset-x": -90
                      }
                    },
                    [
                      _c("c-icon", {
                        staticClass: "attribute-selector__tooltip__icon",
                        attrs: { "symbol-id": "service-information" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("attribute-description"),
      _vm._v(" "),
      _c("div", { staticClass: "attribute-selector__items" }, [
        _c(
          "ul",
          { class: _vm.listModifiers },
          _vm._l(_vm.attributes, function(attribute) {
            return _c(
              "li",
              {
                key: attribute.code,
                class:
                  _vm.disabledAttributeNames.indexOf(attribute.name) !== -1
                    ? "last"
                    : "first"
              },
              [
                _c(
                  !_vm.linkCloud ? "button" : "a",
                  {
                    tag: "component",
                    attrs: {
                      type: !_vm.linkCloud ? "button" : null,
                      href: !_vm.linkCloud ? null : attribute.url,
                      disabled:
                        _vm.disabledAttributeNames.indexOf(attribute.name) !==
                        -1
                    },
                    on: {
                      click: function($event) {
                        return _vm.attributeSelected(attribute)
                      }
                    }
                  },
                  [
                    _vm._t("button-" + attribute.code, [
                      _c(
                        "div",
                        {
                          staticClass: "attribute-selector__item",
                          class: {
                            "attribute-selector__item--selected":
                              attribute.name === _vm.selectedAttributeName,
                            "attribute-selector__item--link-cloud": !!_vm.linkCloud
                          }
                        },
                        [
                          _vm._t("text-" + attribute.code, [
                            _vm._v(
                              "\n                " +
                                _vm._s(attribute.name) +
                                "\n              "
                            )
                          ])
                        ],
                        2
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }