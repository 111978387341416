export function sumPriceAndFormatForPeriod(item, periodLabel) {
  const price = item.price ?? item.priceMonthly;
  let newName = item.displayName;
  let newPrice = price.formattedValueCurrency;
  if (item.duplicatesIncluded?.length > 0) {
    newName = `${item.quantity} x ${item.displayName}`;
    const priceTotalSum = item.duplicatesIncluded.reduce(
      (accumulator, currentItem) => {
        const currentItemPrice = currentItem.price ?? currentItem.priceMonthly;
        return accumulator + currentItemPrice.value;
      },
      price.value
    );
    newPrice = `${priceTotalSum.toFixed(price.numberOfDecimalSymbols)} ${
      price.label
    }`;
  }
  if (item.bindingPeriod > 1) {
    newName = `${newName ?? item.displayName} (${
      item.bindingPeriod
    } ${periodLabel})`;
  }
  return {
    label: newName,
    value: newPrice,
    basePrice: item.basePrice?.formattedValueCurrency,
    promotionSaved: item.promotionSaved?.formattedValueCurrency
  };
}

export function markDuplicates(items) {
  return items.reduce((accumulator, currentItem) => {
    const existingItem = accumulator.find(
      item =>
        item.type === currentItem.type &&
        item.displayName === currentItem.displayName &&
        item.promotionSaved === currentItem.promotionSaved
    );
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 1) + 1;
      // We need to save references of the duplicates to get a correct price calculation later.
      if (!existingItem.duplicatesIncluded) {
        existingItem.duplicatesIncluded = [];
      }
      existingItem.duplicatesIncluded.push(currentItem);
    } else {
      accumulator.push(currentItem);
    }
    return accumulator;
  }, []);
}
