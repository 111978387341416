<template>
  <portal v-if="!hidden" :to="container">
    <a-slide
      :direction="direction"
      offset="30px"
      :use-opacity="true"
      @after-leave="onAfterLeave($event)"
    >
      <div v-show="isVisible" class="c-toast">
        <div class="c-toast__container">
          <div class="c-toast__container-icon" :class="toastState.iconClass">
            <c-icon :symbol-id="toastState.iconType"></c-icon>
          </div>
          <div class="c-toast__container-text">
            <div class="c-toast__header">
              <slot />
            </div>
            <div class="c-toast__paragraph">
              <slot name="paragraph"></slot>
            </div>
          </div>
        </div>
        <button v-if="!showCloseButton" @click="hide">
          <slot name="close">
            <c-icon symbol-id="menu-close"></c-icon>
          </slot>
        </button>
      </div>
    </a-slide>
  </portal>
</template>
<script>
import { ASlide } from 'olympus/animations/index.js';
import { SLIDE_DIR, AXELS } from 'olympus/animations/a-directions.js';
import TOAST_STATES from 'webshop/enums/toast-states.js'; // TODO TC-4916: should not use webshop enums in a global shared component or make the enums global.

const TOAST_POSITIONS = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT'
};

export default {
  name: 'CToast',

  components: {
    ASlide
  },

  model: {
    prop: 'visible',
    event: 'update'
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 0
    },
    axel: {
      type: String,
      default: AXELS.VERTICAL
    },
    position: {
      type: String,
      default: TOAST_POSITIONS.TOP_LEFT,
      validator(val) {
        return Boolean(TOAST_POSITIONS[val]);
      }
    },
    state: {
      type: String,
      default: 'success'
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      manager: null,
      hidden: true,
      isVisible: false,
      container: this.position.toLowerCase().replace('_', '-'),
      TOAST_STATES: TOAST_STATES
    };
  },

  computed: {
    showCloseButton() {
      return this.duration === 0;
    },
    direction() {
      let dir = 'LEFT';
      if (this.axel === AXELS.VERTICAL) {
        dir =
          this.position === TOAST_POSITIONS.TOP_LEFT ||
          this.position === TOAST_POSITIONS.TOP_RIGHT
            ? SLIDE_DIR.DOWN
            : SLIDE_DIR.UP;
      } else if (this.axel === AXELS.HORIZONTAL) {
        dir =
          this.position === TOAST_POSITIONS.TOP_LEFT ||
          this.position === TOAST_POSITIONS.BOTTOM_LEFT
            ? SLIDE_DIR.LEFT
            : SLIDE_DIR.RIGHT;
      }

      return dir;
    },

    toastState() {
      const statusSettings = {
        [TOAST_STATES.SUCCESS]: {
          iconType: 'service-checkmark',
          iconClass: 'c-toast__container-icon--success'
        },
        [TOAST_STATES.ERROR]: {
          iconType: 'service-warning',
          iconClass: 'c-toast__container-icon--error'
        }
      };

      return {
        iconType: statusSettings[this.state].iconType,
        iconClass: statusSettings[this.state].iconClass
      };
    }
  },

  watch: {
    visible(value) {
      this.$nextTick(value ? this.show : this.hide);
    }
  },

  mounted() {
    let managerNode = this.$parent;

    while (managerNode && managerNode.$options.name !== 'c-toast-manager') {
      managerNode = managerNode.$parent;
    }

    if (!managerNode) {
      const manager = document.getElementById('c-toast-manager');
      managerNode = manager ? manager.__vue__ : false;

      if (!managerNode || managerNode.$options.name !== 'c-toast-manager') {
        // TODO TC-4916: create manager at runtime
      }
    }

    this.manager = managerNode;

    if (this.visible) {
      this.$nextTick(this.show);
    }
  },

  methods: {
    show() {
      this.hidden = false;
      this.$nextTick(() => {
        this.isVisible = true;
        if (this.duration) {
          setTimeout(() => this.hide(), this.duration);
        }
      });
    },
    hide() {
      this.isVisible = false;
      setTimeout(() => {
        this.removeToast();
      }, 300);
    },
    onAfterLeave(el) {
      el.style.maxHeight = 0;
    },
    removeToast() {
      setTimeout(() => {
        this.hidden = true;
        this.$removeToast(this.index);
        this.$emit('update', !this.hidden);
      }, 300);
    }
  }
};
</script>
