var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.discountEnabled
    ? _c(
        "div",
        { class: _vm.voucherClasses },
        [
          !_vm.hasCoupons
            ? [
                _c("c-collapse-trigger", {
                  staticClass: "voucher__header voucher__collapse-trigger",
                  attrs: { id: "cart-voucher-collapse" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c("c-icon", {
                              staticClass:
                                "voucher__icon voucher__icon--voucher",
                              attrs: { "symbol-id": "service-gift" }
                            }),
                            _vm._v(" "),
                            _c("h4", { staticClass: "voucher__title" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.contentLocal.discountCodeLabel) +
                                  "\n        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("c-icon", {
                              staticClass: "voucher__icon voucher__icon--arrow",
                              attrs: {
                                "symbol-id": slotProps.expanded
                                  ? "arrow-up"
                                  : "arrow-down"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3523104168
                  )
                }),
                _vm._v(" "),
                _c(
                  "c-collapse",
                  {
                    attrs: {
                      id: "cart-voucher-collapse",
                      "transition-duration": "150ms",
                      "transition-easing": "linear"
                    }
                  },
                  [
                    _c("div", { staticClass: "voucher__inputs" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.discountCode,
                            expression: "discountCode"
                          }
                        ],
                        staticClass: "cm-c-form-control cm-js-material-input",
                        attrs: { type: "text", name: "discountcode" },
                        domProps: { value: _vm.discountCode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.discountCode = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "cm-c-form-control__label",
                          attrs: { for: "discountcode" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.contentLocal.discountCodePlaceholder) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "c-btn c-btn--sm voucher-code-btn",
                          class: [
                            !_vm.discountCode
                              ? "c-btn--disabled"
                              : "c-btn--empty"
                          ],
                          attrs: { disabled: !_vm.discountCode },
                          on: { click: _vm.add }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.contentLocal.discountButtonText) +
                              "\n        "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            : _vm._l(_vm.coupons, function(coupon, couponsIndex) {
                return _c(
                  "div",
                  { key: couponsIndex, staticClass: "voucher-container" },
                  [
                    _c("span", [
                      _c("strong", { staticClass: "voucher-code-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.contentLocal.discountSuccessText) +
                            "\n        "
                        )
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getDiscountDescription(coupon)) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "voucher-remove-btn",
                        attrs: { role: "button" },
                        on: {
                          click: function($event) {
                            return _vm.remove(coupon.coupon)
                          }
                        }
                      },
                      [
                        _c("c-icon", {
                          attrs: {
                            "symbol-id": _vm.contentLocal.discountTrashIcon
                              ? "service-trash"
                              : "menu-close"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "cm-sr-only" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.contentLocal.removeButtonText) +
                              "\n        "
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              }),
          _vm._v(" "),
          _c(
            "c-modal",
            {
              attrs: {
                visible: _vm.networking.hasNetworkError,
                title: _vm.networking.errorPopUpHeaderText,
                "ok-button-text": _vm.networking.errorPopUpButtonText,
                "content-centered": !!_vm.networking.errorPopUpButtonText,
                centered: "",
                "show-ok-button": ""
              },
              on: { close: _vm.closeErrorModal }
            },
            [
              _c("div", {
                staticClass: "basket-coupon__modal__message",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.networking.error || _vm.networking.errorPopUpMessageText
                  )
                }
              })
            ]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }