<template>
  <div class="c-layer-manager">
    <transition name="c-layer-manager--fade">
      <div class="c-layer-manager__back" v-if="hasLayerExpanded" @click="close"></div>
    </transition>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'c-layer-manager',

  data() {
    return {
      layers: []
    };
  },

  computed: {
    hasLayerExpanded() {
      return !!this.layers.find(x => x.localExpanded);
    }
  },

  mounted() {
    this.layers = this.$children.filter(child => {
      return child.$options.name === 'c-layer-panel';
    });
  },

  methods: {
    close() {
      const target = this.layers.find(x => x.localExpanded);

      if (target) {
        target.collapse();
      }
    }
  }
};
</script>

<style lang="scss">
.c-layer-manager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;

  &__back {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: auto;
  }

  &--fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>

