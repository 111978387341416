<template>
  <div
    class="c-vas-card-substitute"
    :class="{ 'c-vas-card-substitute--current': current }"
  >
    <div class="c-vas-card-substitute__image">
      <img :src="vas.imageUrl" :alt="vas.displayName" />
    </div>
    <div class="c-vas-card-substitute__text">
      <div class="c-vas-card-substitute__name">
        {{ vas.displayName }}
      </div>
      <div class="c-vas-card-substitute__price">
        {{ vas.price.formattedValueCurrency }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CVasCardSubstitute',

  props: {
    vas: {
      type: Object,
      default: () => ({})
    },

    current: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-vas-card-substitute {
  background: $color-white;
  border-radius: 3px;
  box-shadow: $box-shadow-default;
  display: flex;
  flex-grow: 1;
  max-width: 220px;
  padding: $u-300;

  $substitute: &;

  @include mq($screen-md, max) {
    flex-direction: column;
    padding: $u-300;
  }

  &__image {
    height: $u-600;
    flex-shrink: 0;
    margin-right: $u-300;
    width: $u-600;

    @include mq($screen-md, max) {
      height: $u-400;
      margin: 0 0 $u-200;
      width: $u-400;
    }
  }

  &__name {
    font-size: $font-size-h5;
    font-weight: 600;
  }

  &__price {
    font-size: $font-size-h4;

    @include mq($screen-md, max) {
      font-size: $font-size-h5;
    }
  }

  &--current {
    #{$substitute}__image,
    #{$substitute}__text {
      opacity: 0.5;
    }
  }
}
</style>
