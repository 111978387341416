/**
 * Concatinates objects and returns an array of them.
 *
 * @param  {...any} args Objects to concatinate.
 * @return array
 */
export const concat = function(...args) {
  return Array.prototype.concat.apply([], args);
};

/**
 * Determines if value is present.
 *
 * @param Array    array The array to search in.
 * @param {...any} value The value to search for.
 * @return Boolean
 *
 */
export const includes = (array, value) => array.indexOf(value) !== -1;

export const unionBy = (array, src, prop) => {
  for (let i = 0; i < src.length; i++) {
    var target = src[i];
    var found = false;
    for (let j = 0; j < array.length; j++) {
      if (target[prop] == array[j][prop]) {
        found = true;
        break;
      }
    }

    if (!found) {
      array.push(target);
    }
  }

  return array;
}
