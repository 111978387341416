var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "config-group-item",
    {
      attrs: {
        title: _vm.title,
        icon: _vm.icon,
        expanded: _vm.expanded,
        integrated: true
      },
      on: { expanded: _vm.handleExpanded }
    },
    [
      _c(
        "fieldset",
        { staticClass: "internet-config" },
        [
          _vm.showProviderChange
            ? _c(
                "div",
                { staticClass: "internet-config__start" },
                [
                  _c("h3", { staticClass: "cm-c-form__header" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.content.broadband.providerChangeSectionTitle
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-form-radio-group",
                    {
                      staticClass: "internet-config__start__selection",
                      attrs: {
                        name: "cancelationAssist",
                        highlighted: true,
                        stacked: ""
                      },
                      model: {
                        value: _vm.form.cancelationAssist,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cancelationAssist", $$v)
                        },
                        expression: "form.cancelationAssist"
                      }
                    },
                    [
                      _c(
                        "c-form-radio",
                        { attrs: { id: "first", value: "true" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "internet-config__start__selection__label"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.content.broadband.yesNeedHelpButtonLabel
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-form-radio",
                        { attrs: { id: "second", value: "false" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "internet-config__start__selection__label"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.content.broadband
                                      .noDontNeedHelpButtonLabel
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-collapse",
                    {
                      attrs: {
                        id: "showCurrentProviderDisclaimer",
                        visible: _vm.showCancellationAssistNeeded
                      }
                    },
                    [
                      _c(
                        "c-information-box",
                        {
                          staticClass: "mt-2 mb-2",
                          attrs: {
                            icon: "contact-information",
                            type: "informative"
                          }
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.content.broadband
                                  .cancellationAssistReminderText
                              )
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h4",
                        {
                          staticClass:
                            "cm-c-form__header cm-c-form__header--small"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.content.broadband.currentProviderHeadline
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-dropdown",
                        {
                          attrs: {
                            "input-name": "currentProvider",
                            "input-value-key": "value",
                            attributes: {
                              options: _vm.providers
                            },
                            "search-input-placeholder":
                              _vm.content.broadband.providerPlaceholder
                          },
                          model: {
                            value: _vm.form.currentProvider,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "currentProvider", $$v)
                            },
                            expression: "form.currentProvider"
                          }
                        },
                        [
                          _c("div", {
                            attrs: { slot: "no-options" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.content.broadband.noResultsText
                              )
                            },
                            slot: "no-options"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-collapse",
                    {
                      attrs: {
                        id: "showCancellationAssistNotNeededDisclaimer",
                        visible: _vm.showCancellationAssistNotNeeded
                      }
                    },
                    [
                      _c(
                        "c-information-box",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            icon: "contact-information",
                            type: "informative"
                          }
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.content.broadband
                                  .cancellationAssistNotNeeded
                              )
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "internet-config__section" },
            [
              _c("h3", { staticClass: "cm-c-form__header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.broadband.activationText) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "internet-config__section__row" },
                [
                  _c(
                    "c-col",
                    {
                      staticClass: "internet-config__section__row__col-chip",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "c-form-radio-group",
                        {
                          staticClass: "internet-config__start__selection",
                          attrs: {
                            name: "startSubscriptionSelect",
                            highlighted: true,
                            stacked: ""
                          },
                          model: {
                            value: _vm.form.enableSubscriptionAsap,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "enableSubscriptionAsap", $$v)
                            },
                            expression: "form.enableSubscriptionAsap"
                          }
                        },
                        [
                          _c(
                            "c-form-radio",
                            {
                              attrs: {
                                id: "startSubscriptionAsap",
                                value: "true",
                                "data-testid": "internet-config__chip-input"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "internet-config__start__selection__label"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.content.broadband
                                          .activationAsapButtonText
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "c-form-radio",
                            {
                              attrs: {
                                id: "startSubscriptionDate",
                                value: "false",
                                "data-testid": "internet-config__chip-input"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "internet-config__start__selection__label"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.content.broadband
                                          .activationSpecificDateButtonText
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showStartDateCannotBeChangedDisclaimer
                ? _c(
                    "c-information-box",
                    {
                      staticClass:
                        "internet-config__cancellation-manual-information",
                      attrs: {
                        icon: "contact-information",
                        type: "informative"
                      }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.content.broadband
                              .startDateCannotBeChangedDisclaimer
                          )
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "c-collapse",
                {
                  staticClass: "internet-config__select-date",
                  attrs: { id: "datepicker", visible: _vm.showDateSelectorStep }
                },
                [
                  _c(
                    "c-row",
                    { staticClass: "internet-config__row" },
                    [
                      _c(
                        "c-col",
                        [
                          _c("c-datepicker", {
                            staticClass: "internet-config__datepicker",
                            attrs: {
                              label: _vm.content.chooseDateDatePickerLabel,
                              "disabled-dates": _vm.restrictedDates,
                              "disabled-days": _vm.restrictedWeekDays,
                              "disable-weekends": false,
                              "initial-date": _vm.activationDate,
                              "first-available-date": _vm.firstAvailableDate,
                              "data-testid": "internet-config__datepicker",
                              "input-name": "activationDate"
                            },
                            on: { change: _vm.setSelectedDate }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            {
              attrs: {
                id: "asapDisclaimer",
                visible: _vm.showActivationAsapDisclaimer
              }
            },
            [
              _c("div", {
                staticClass: "internet-config__disclaimer",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.content.broadband.activationAsapDisclaimerText
                  )
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            {
              attrs: {
                id: "dateDisclaimer",
                visible: _vm.showDateSelectedDisclaimer
              }
            },
            [
              _c("div", {
                staticClass: "internet-config__disclaimer",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.content.broadband.activationDateSelectedDisclaimer
                  )
                }
              })
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }