var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-modal",
        {
          ref: "homeOfficeValidationModal",
          attrs: {
            id: "homeOfficeValidationModal",
            centered: "",
            "show-close-button": "",
            visible: _vm.visible
          },
          on: { close: _vm.onModalClose }
        },
        [
          _c(
            "c-heading",
            { attrs: { slot: "header", level: "3", as: "3" }, slot: "header" },
            [_vm._v("\n      " + _vm._s(_vm.modalTitle) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.validationMessage) } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }