<template>
  <div class="c-wrapper">
    <keep-alive>
      <component
        :is="activeView"
        :content="content"
        :orders="orders"
        :handle-view-click="handleViewClick"
        :fetch-orders="fetchOrders"
        :get-from-date="getFromDate"
        :get-to-date="getToDate"
        :order-id="orderId"
        :is-fetching="isFetching"
        :is-api-error="isApiError"
      />
    </keep-alive>
  </div>
</template>

<script>
import OrderList from './order-list.vue';
import OrderDetail from './order-detail.vue';
import { getOrdersByCurrentDealer } from '../../../shared/api/order-service.js';

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isFetching: false,
      isApiError: false,
      orders: [],
      activeView: OrderList
    };
  },

  computed: {
    orderId() {
      return this.$route.query.orderId;
    }
  },

  watch: {
    orderId() {
      this.activeView = this.orderId ? OrderDetail : OrderList;
    }
  },

  created() {
    if (this.$route.query.orderId) {
      this.$router.push({ query: '' });
    }

    const fromDate = this.getFromDate();
    const toDate = this.getToDate();

    this.fetchOrders(fromDate, toDate, 'ALL');
  },

  methods: {
    handleViewClick(orderId) {
      this.$router.push({ query: { orderId } });
    },
    fetchOrders(fromDate, toDate, status) {
      if (!!fromDate && !!toDate && toDate > fromDate) {
        this.isFetching = true;

        return getOrdersByCurrentDealer(fromDate, toDate, status)
          .then(({ data }) => {
            this.isFetching = false;
            if (data && data.length > 0) {
              this.orders = data.map(order => {
                return {
                  orderId: order.orderId,
                  customer: order.orderer,
                  contactNumber: order.orderer?.telephoneNumber?.localNumber,
                  createdDate: order.createdDate
                    ? new Date(order.createdDate).toISOString().split('T')[0]
                    : '',
                  completedDate: order.completedDate
                    ? new Date(order.completedDate).toISOString().split('T')[0]
                    : '',
                  processingStatus: this.getStatus(order.processingStatus),
                  processingStatusDetail: order.processingStatusDetail,
                  channel: order.channel,
                  dealer: order.dealer,
                  handsets: order.handsets,
                  subscriptions: order.subscriptions,
                  accessories: order.accessories
                };
              });
            } else {
              this.orders = [];
            }
          })
          .catch(() => {
            this.isFetching = false;
            this.isApiError = true;
          });
      }
    },
    getFromDate: function () {
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      // TODO DKT-1441: Use common mixin FormatDate
      return date.toISOString().split('T')[0];
    },
    getToDate: function () {
      return new Date().toISOString().split('T')[0];
    },
    // TODO DKT-1441: "vise versa" the key value pairs
    getStatus: function (status) {
      return this.content.listView.statusOptions[status]?.key;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.c-wrapper {
  margin: $u-400 auto;

  > div {
    flex-grow: 1;
  }
}
</style>
