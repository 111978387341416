<template>
  <div class="cm-c-card-product__gallery-viewport">
    <div class="cm-c-card-product__gallery-container" :style="containerStyles">
      <transition
        name="slide"
        mode="out-in"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <img
          v-if="!!currentImgSrc"
          ref="image"
          :key="currentImgSrc"
          :src="currentImgSrc"
          :class="{ '--left': direction == 'left', '--loaded': loaded }"
          :alt="productName"
          loading="lazy"
          @load="onImgLoad"
        />
      </transition>
      <button
        v-if="hasGallery"
        type="button"
        class="
          cm-c-card-product__gallery-nav cm-c-card-product__gallery-nav--prev
        "
        @click.stop.prevent="prev"
      ></button>
      <button
        v-if="hasGallery"
        type="button"
        class="
          cm-c-card-product__gallery-nav cm-c-card-product__gallery-nav--next
        "
        @click.stop.prevent="next"
      ></button>
    </div>
  </div>
</template>

<script>
const DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left'
};

export default {
  props: ['images', 'productName'],
  data() {
    return {
      index: 0,
      direction: DIRECTION.RIGHT,
      loaded: false,
      animating: false,
      minimumHeight: 'none'
    };
  },
  computed: {
    currentImgSrc() {
      return `${this.images[this.index]}?width=${
        window.__APP__?.productPreviewImageWidth || 200
      }`;
    },
    hasGallery() {
      if (this.images) {
        return this.images.length > 1;
      }
      return false;
    },
    containerStyles() {
      if (this.animating) {
        return {
          minHeight: `${this.minimumHeight}px`
        };
      }
      return {};
    }
  },
  watch: {
    images() {
      this.index = 0;
    }
  },
  methods: {
    getMinimumHeight() {
      if (this.$refs.image) {
        return this.$refs.image.clientHeight;
      }
      return 'none';
    },
    next() {
      this.direction = DIRECTION.RIGHT;
      setTimeout(() => {
        this.index += 1;
        if (this.index > this.images.length - 1) {
          this.index = 0;
        }
      }, 0);
    },
    prev() {
      this.direction = DIRECTION.LEFT;
      setTimeout(() => {
        this.index -= 1;
        if (this.index < 0) {
          this.index = this.images.length - 1;
        }
      }, 0);
    },
    afterEnter() {
      this.animating = false;
      this.minimumHeight = 'none';
    },
    beforeLeave() {
      this.minimumHeight = this.getMinimumHeight();
    },
    leave() {
      this.animating = true;
    },
    onImgLoad() {
      this.loaded = true;
    }
  }
};
</script>
