<template>
  <div
    :class="[{ 'c-theme-block__item--image': image }, 'c-theme-block__item']"
  >
    <img
      class="c-theme-block__image"
      v-if="image"
      :src="image"
      :alt="heading"
    />
    <div class="c-theme-block__content">
      <p class="c-theme-block__subtitle" v-if="subtitle">
        {{ subtitle }}
      </p>
      <h3 class="c-theme-block__heading" v-if="heading">
        {{ heading }}
      </h3>
      <p class="c-theme-block__description" v-if="description">
        {{`${description.substring(0, ellipsisLimit)}…`}}
      </p>
    </div>
    <a class="c-theme-block__link" :href="link"></a>
  </div>
</template>

<script>
export default {
  name: 'c-theme-block-item',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary'
    },
    small: {
      type: Boolean,
      default: false
    },
    subtitle: String,
    heading: String,
    description: String,
    link: String,
    image: String,
    index: Number
  },
  computed: {
    ellipsisLimit: function() {
      if ([0, 6].includes(this.index)) return 99;

      return 85;
    }
  }
};
</script>
