import Vue from 'vue';
import { get } from '../../api/api.js';

const MUTATIONS = {
  SET_CONTENT_FOR_ID: 'SET_CONTENT_FOR_ID'
};

export const ACTIONS = {
  FETCH_CONTENT_BY_ID: 'FETCH_CONTENT_BY_ID'
};

export const GETTERS = {
  CONTENT_BY_ID: 'CONTENT_BY_ID'
};

/**
 * Content Delivery API spec.
 *
 * Used to tell api to expand all expandable objects in the response,
 * example ContentLink, ContentArea and other complex types.
 *
 */

export default {
  state: () => ({
    blocks: {}
  }),
  mutations: {
    [MUTATIONS.SET_CONTENT_FOR_ID](state, { content, id }) {
      const contentId = id || content.contentLink.id;
      Vue.set(state.blocks, contentId, content);
    }
  },
  actions: {
    [ACTIONS.FETCH_CONTENT_BY_ID]({ commit }, { id, expand }) {
      const params = {};
      if (expand && expand.length) {
        params.expand = expand.join(',');
      }

      // todo: [TSS-385] remove hardcoded links and header values
      return get(`api/episerver/v2.0/content/${id}`, {
        mvc: true,
        headers: {
          'Accept-Language': 'da-DK',
          'Content-Type': 'application/json'
        },
        params
      })
        .then(response => {
          commit(MUTATIONS.SET_CONTENT_FOR_ID, { content: response.data, id });
        })
        .catch(e => {
          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log(
              id,
              'fail to load content. Status.code:',
              e.response.status
            );
          }
        });
    }
  },
  getters: {
    [GETTERS.CONTENT_BY_ID]: state => id => {
      return state.blocks[id];
    }
  }
};
