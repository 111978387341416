<script>
import withTemplate from 'theme/templates/price-plan-selector.html';
import { mapGetters } from 'vuex';
import { GETTERS, GETTERS as APP_GETTERS } from 'webshop/store/store';
import { CAccordion, CAccordionItem } from 'webshop/components';
import {
  CModal,
  CButton,
  CCollapse,
  CCollapseTrigger,
  CCarousel
} from 'olympus/components';
import { CIcon } from '../c-icon/index.js';
import VToggle from 'olympus/directives/v-toggle.js';

import SubscriptionValueAddedServicesSelector from './subscription-value-added-services-selector.vue';
import ExistingSubscriptionSelector from './existing-subscription-selector.vue';
import RibbonBanner from '../ribbon-banner/ribbon-banner.vue';
import SubscriptionCard from '../subscription/subscription-card/subscription-card.vue';
import { GETTERS as CREDIT_CHECK_GETTERS } from 'webshop/store/credit-check/index.js';

const DESCRIPTION_TEMPLATES = {
  HIDDEN: 'Hidden',
  FREE_TEXT: 'FreeText',
  VAS: 'Vas',
  HARDBUNDLE: 'Hardbundle'
};

export default withTemplate({
  name: 'price-plan-selector',

  components: {
    CModal,
    CAccordion,
    CAccordionItem,
    CButton,
    CCollapse,
    CCollapseTrigger,
    CCarousel,
    RibbonBanner,
    CIcon,
    SubscriptionValueAddedServicesSelector,
    ExistingSubscriptionSelector,
    SubscriptionCard
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedOption: {
      type: Object,
      default: () => ({})
    },
    headline: {
      type: String,
      default: ''
    },
    vasHeadline: {
      type: String,
      default: ''
    },
    vasText: {
      type: String,
      default: ''
    },
    expandLabel: {
      type: String,
      default: ''
    },
    collapseLabel: {
      type: String,
      default: ''
    },
    existingSubscriptionAccountNote: {
      type: String,
      default: ''
    },
    productCode: {
      type: String,
      default: '',
      required: true
    },
    enableProlonging: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      selectedIndex: 0,
      DESCRIPTION_TEMPLATES,
      vasImageSize: 24,
      vasImageMargin: 2,
      closeTongue: false
    };
  },

  computed: {
    ...mapGetters({
      isB2C: GETTERS.IS_B2C,
      creditCheckDetails: CREDIT_CHECK_GETTERS.GET_CREDIT_CHECK_DETAILS,
      loggedIn: APP_GETTERS.USER_AUTHENTICATED,
      isAgent: APP_GETTERS.IS_AGENT
    }),

    showExistingSubscriptionSelector() {
      if (this.isAgent) {
        return this.creditCheckActive();
      }
      return this.loggedIn && this.isB2C;
    },

    selected: {
      // TODO DKT-1439: refactor to reference selectedOption directly?
      get() {
        return this.selectedOption;
      },
      set() {
        /* noop */
      }
    }
  },

  directives: {
    toggle: VToggle
  },

  methods: {
    onPaymentPlanSelected(paymentPlan) {
      const selectedIndex = this.options.findIndex(
        x => x.code === paymentPlan.code
      );
      this.selectedIndex = selectedIndex || 0;

      this.$emit('select-option', paymentPlan);
    },

    dataIncluded(value, unit) {
      return isNaN(value) ? value : `${value} ${unit}`;
    },

    // TODO DKT-1439: we should probably cache this
    getHardbundle(option) {
      return (
        option.associations &&
        option.associations.find(x => x.type === 'ItemToItemHardBundle')?.link
      );
    },

    carouselReady() {
      this.selectedIndex =
        this.options?.findIndex(e => e.code === this.selectedOption.code) ?? 0;
    },

    /**
     * @description calculates if option's vas are overflowing
     * on multiple lines.
     */
    hasHiddenVas(option) {
      const wrapper = this.$refs[`vasImagesWrapper_${option.code}`];
      return Boolean(
        wrapper &&
          wrapper.offsetWidth <
            (option.valueAddedServices?.length || 0) * this.vasImageSize +
              (option.valueAddedServices?.length - 1 || 0) * this.vasImageMargin
      );
    },

    formattedDescription(data) {
      return `${data.voiceIncluded} ∙ ${this.dataIncluded(
        data.dataIncluded,
        data.dataUnit
      )} ${data.shortDescription}`;
    },

    creditCheckActive() {
      return (
        this.creditCheckDetails &&
        this.creditCheckDetails.cpr !== '' &&
        this.creditCheckDetails.cpr !== null
      );
    }
  }
});
</script>
