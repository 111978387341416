class Banner {
  constructor(element, settings = {}) {
    this.element = element;
    this.settings = settings;
    this.bannerId = `banner-${this.settings.id}`;

    this.init();
  }

  init() {
    if (!sessionStorage.getItem(this.bannerId)) {
      this.showBanner();
    }

    this.settings.closeButton.addEventListener('click', e => {
      e.preventDefault();

      sessionStorage.setItem(this.bannerId, 'true');

      this.hideBanner();
    });
  }

  hideBanner() {
    this.element.classList.add(this.settings.hiddenClass);
  }

  showBanner() {
    this.element.classList.remove(this.settings.hiddenClass);
  }
}

export const banner = (element, settings) => new Banner(element, settings);
