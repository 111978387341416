import Vue from 'vue';

/* eslint-disable */ // Todo: Remove this line later
import { createNamespace } from '../utils.js';

// TODO: Use below imports when implementing the ACTIONS
import {
  getProductConfigs,
  getNumberPool
} from 'webshop/api/product-config-service';
import { post } from '../../api/api.js';

export const MUTATIONS = createNamespace('PRODUCT-CONFIG/MUTATIONS', [
  'SET_PRODUCTS',
  'SET_PRODUCT',
  'SET_ERRORS',
  'REMOVE_ERRORS',
  'REMOVE_PRODUCT'
]);

export const ACTIONS = createNamespace('PRODUCT-CONFIG/ACTIONS', [
  'FETCH_PRODUCTS',
  'SAVE_PRODUCT',
  'SET_ERRORS',
  'REMOVE_ERRORS'
]);

export const GETTERS = createNamespace('PRODUCT-CONFIG/GETTERS', [
  'GET_PRODUCTS',
  'GET_ERRORMAP',
  'GET_ERRORS',
  'GET_ERROR_BY_MEMBER_NAME'
]);

/**
 * Manages state for product configuration page.
 */
export default {
  state: {
    products: [],
    errorMap: window.__APP__?.checkoutErrorMap,
    submitErrors: []
  },

  mutations: {
    [MUTATIONS.SET_PRODUCTS](state, products) {
      state.products = products;
    },
    [MUTATIONS.SET_PRODUCT](state, data) {
      const productIndex = state.products.findIndex(
        product => product.attributes.groupId === data.attributes.groupId
      );
      Vue.set(state.products[productIndex], 'attributes', data.attributes);
    },
    [MUTATIONS.SET_ERRORS](state, errors) {
      state.submitErrors = errors
        //TODO discuss if its a viable way to solve error display while avoiding conditional meta filtering
        .map(e => ({
          ...e,
          content: state.errorMap[e.code],
          meta: e.meta || {}
        }));
    },
    [MUTATIONS.REMOVE_ERRORS](state, errorNames) {
      const errors = state.submitErrors.filter(e =>
        errorNames.includes(e.meta?.memberName)
      );

      if (errors.length) {
        state.submitErrors = state.submitErrors.filter(
          e => !errors.includes(e)
        );
      }
    },
    [MUTATIONS.REMOVE_PRODUCT](state, product) {
      state.products = state.products.filter(
        item => item.attributes.groupId !== product
      );
    }
  },

  actions: {
    [ACTIONS.FETCH_PRODUCTS]: async ({ commit }) => {
      const { data } = await getProductConfigs();
      commit(MUTATIONS.SET_PRODUCTS, data.data);
    },
    [ACTIONS.SAVE_PRODUCT]: async ({ commit }, { url, data }) => {
      return post(url, data, {
        mvc: true,
        headers: {
          accept: 'application/vnd.api+json'
        }
      })
        .then(response => {
          if (response.data) {
            commit(MUTATIONS.SET_PRODUCT, response.data.data);
            return Promise.resolve(response);
          }
        })
        .catch(response => {
          if (response.errors) {
            commit(MUTATIONS.SET_ERRORS, response.errors);
            return Promise.reject(response);
          }
        });
    },
    [ACTIONS.SET_ERRORS]: ({ commit }, errors) => {
      commit(MUTATIONS.SET_ERRORS, errors);
    },
    [ACTIONS.REMOVE_ERRORS]: ({ commit }, errorNames) => {
      commit(MUTATIONS.REMOVE_ERRORS, errorNames);
    }
  },

  getters: {
    [GETTERS.GET_PRODUCTS]: state => {
      return state.products;
    },
    [GETTERS.GET_ERRORMAP]: state => {
      return state.errorMap;
    },
    [GETTERS.GET_ERRORS]: state => {
      return state.submitErrors;
    },
    // e.meta.memberName: String. Example: 'Cvr', 'B2BCvr', 'Number'
    [GETTERS.GET_ERROR_BY_MEMBER_NAME]: state => arrayOfMemberNames => {
      return state.submitErrors.find(e =>
        arrayOfMemberNames.includes(e.meta.memberName)
      )?.content.title;
    }
  }
};
