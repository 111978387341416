var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    { attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" } },
    [
      _c("h3", { staticClass: "cm-c-checkout__subtitle--mb" }, [
        _vm._v(_vm._s(_vm.content.headline))
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "cm-c-checkout__text",
        domProps: { innerHTML: _vm._s(_vm.description) }
      }),
      _vm._v(" "),
      _c("employee-receipt-disclaimers", {
        attrs: { disclaimers: _vm.disclaimers }
      }),
      _vm._v(" "),
      _c("employee-receipt-order-details", {
        attrs: {
          content: _vm.content,
          "order-data": _vm.orderData,
          "employee-data": _vm.employeeData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }