var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-gallery-content",
        _vm._b({}, "c-gallery-content", _vm.$props, false),
        [
          _c(
            "template",
            { slot: "selectedFooter" },
            [_vm._t("selectedFooter")],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _vm.overlay
        ? _c("c-gallery-overlay", { ref: "galleryOverlay" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }