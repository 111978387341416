var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "config-group-item",
    {
      attrs: { title: _vm.title, icon: _vm.icon, expanded: _vm.expanded },
      on: { expanded: _vm.handleExpanded }
    },
    [
      _c(
        "fieldset",
        { staticClass: "new-number-config" },
        [
          _c("div", { staticClass: "new-number-config__number" }, [
            _c(
              "div",
              {
                staticClass:
                  "cm-c-form-control-group new-number-config__input-wrapper"
              },
              [
                _c("c-input-group", {
                  staticClass: "new-number-config__input-wrapper__search",
                  class: {
                    "new-number-config__input-wrapper__search--with-error":
                      _vm.numberSearchError
                  },
                  attrs: {
                    name: "numbersearch",
                    "place-holder-label":
                      _vm.content.newNumber.numberSearchBoxPlaceholderText,
                    "input-error": _vm.numberSearchError,
                    maxlength: 8
                  },
                  on: {
                    submit: _vm.fetchAndSearchNewNumbers,
                    keypress: function($event) {
                      return _vm.handleNumberInput($event)
                    }
                  },
                  model: {
                    value: _vm.search,
                    callback: function($$v) {
                      _vm.search = $$v
                    },
                    expression: "search"
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "\n            cm-c-form-control__button\n            new-number-config__input-wrapper__fetch-btn\n          ",
                    attrs: { disabled: !_vm.search },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.fetchAndSearchNewNumbers($event)
                      }
                    }
                  },
                  [
                    _c("c-icon", {
                      staticClass: "cm-numcon__number-search-button--icon",
                      attrs: { "symbol-id": "menu-search" }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.content.newNumber.numberSearchTipsLabel
              ? _c(
                  "div",
                  { staticClass: "new-number-config__number__search-tips" },
                  [
                    _c(
                      "popover-panel",
                      {
                        attrs: {
                          description:
                            _vm.content.newNumber.numberSearchTipsText,
                          "tooltip-screen-reader-text":
                            _vm.content.tooltipScreenReaderText,
                          "popover-triggers": ["hover", "click"],
                          "base-classes": "new-number-tooltip"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "new-number-tooltip__icon-wrapper" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.content.newNumber.numberSearchTipsLabel
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("c-icon", {
                              staticClass:
                                "\n                new-number-tooltip__icon-wrapper__icon\n                new-number-tooltip__icon-wrapper__icon--purple\n              ",
                              attrs: {
                                "symbol-id":
                                  _vm.content.newNumber.numberSearchTipsIcon
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "new-number-config__number__no-numbers" },
              [
                !(_vm.numbersToShow && _vm.numbersToShow.length)
                  ? _c("label", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.content.newNumber.noResultText) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "new-number-config__number-chips" },
              _vm._l(_vm.numbersToShow, function(numberObj) {
                return _c(
                  "c-chip",
                  {
                    key: numberObj.number,
                    attrs: {
                      "data-testid": "new-number-config__number-chips-item",
                      name: "number",
                      "input-value": numberObj.number
                    },
                    on: {
                      change: function($event) {
                        return _vm.chipChanged(numberObj)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.formatNumber(numberObj.number)) +
                        "\n        "
                    )
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _vm.showMoreNumbersButton
              ? _c(
                  "div",
                  { staticClass: "new-number-config__number__more-numbers" },
                  [
                    _c(
                      "c-button",
                      {
                        attrs: { variant: "link" },
                        on: { click: _vm.fetchMoreNumbers }
                      },
                      [
                        _c("c-icon", {
                          staticClass:
                            "new-number-config__number__more-numbers__icon",
                          attrs: {
                            "symbol-id":
                              _vm.content.newNumber.seeMoreNumbersIcon
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.newNumber.seeMoreNumbersLabel) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "new-number-config__separator" }),
          _vm._v(" "),
          !_vm.content.hideSimTypeOptions
            ? _c("sim-card-selection", {
                staticClass: "new-number-config__simcard-selection",
                attrs: {
                  "config-data": _vm.configData,
                  "checked-sim-radio": _vm.checkedSimRadio,
                  options: _vm.simCardSettings
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.SIM_OPTIONS.SHIP_SIM,
                    expression: "SIM_OPTIONS.SHIP_SIM"
                  }
                ],
                attrs: { type: "hidden", name: "SimOption" },
                domProps: { value: _vm.SIM_OPTIONS.SHIP_SIM },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.SIM_OPTIONS, "SHIP_SIM", $event.target.value)
                  }
                }
              }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "new-number-config__hidden-or-unlisted" },
            [
              _c(
                "legend",
                {
                  staticClass: "new-number-config__hidden-or-unlisted__legend"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.content.optionalSettingsHeadline) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "new-number-config__section__row" },
                [
                  _c("c-col", [
                    _c(
                      "div",
                      {
                        staticClass: "new-number-config__section__row__tooltip",
                        class: {
                          "new-number-config__section__row__tooltip--checked-border":
                            _vm.isNumberHidden
                        }
                      },
                      [
                        _c(
                          "c-checkbox",
                          {
                            attrs: {
                              id: "new-number-config-hidden",
                              checked: _vm.isNumberHidden,
                              name: "isNumberHidden",
                              "styled-checkbox": ""
                            },
                            on: {
                              "event-id": function($event) {
                                return _vm.toggleIsNumberHidden()
                              }
                            },
                            model: {
                              value: _vm.isNumberHidden,
                              callback: function($$v) {
                                _vm.isNumberHidden = $$v
                              },
                              expression: "isNumberHidden"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.content.secretNumberLabel) +
                                "\n              "
                            ),
                            _c("span", { staticClass: "description" }, [
                              _vm._v(
                                _vm._s(_vm.content.secretNumberDescription)
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "popover-panel",
                          {
                            attrs: {
                              description: _vm.content.secretNumberDescription,
                              "tooltip-screen-reader-text":
                                _vm.content.tooltipScreenReaderText,
                              "popover-triggers": ["hover", "click"],
                              "base-classes": "new-number-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "new-number-tooltip__icon-wrapper"
                              },
                              [
                                _c("c-icon", {
                                  staticClass:
                                    "new-number-tooltip__icon-wrapper__icon",
                                  class: {
                                    "new-number-tooltip__icon-wrapper__icon--disabled": !_vm.isNumberHidden
                                  },
                                  attrs: { "symbol-id": "service-information" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "new-number-config__section__row" },
                [
                  _c("c-col", [
                    _c(
                      "div",
                      {
                        staticClass: "new-number-config__section__row__tooltip",
                        class: {
                          "new-number-config__section__row__tooltip--checked-border":
                            _vm.isNumberUnlisted
                        }
                      },
                      [
                        _c(
                          "c-checkbox",
                          {
                            attrs: {
                              id: "new-number-config-unlisted",
                              checked: _vm.isNumberUnlisted,
                              name: "isNumberUnlisted",
                              "styled-checkbox": ""
                            },
                            on: {
                              "event-id": function($event) {
                                return _vm.toggleIsNumberUnlisted()
                              }
                            },
                            model: {
                              value: _vm.isNumberUnlisted,
                              callback: function($$v) {
                                _vm.isNumberUnlisted = $$v
                              },
                              expression: "isNumberUnlisted"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.content.unlistedNumberLabel) +
                                "\n              "
                            ),
                            _c("span", { staticClass: "description" }, [
                              _vm._v(
                                _vm._s(_vm.content.unlistedNumberDescription)
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "popover-panel",
                          {
                            attrs: {
                              description:
                                _vm.content.unlistedNumberDescription,
                              "tooltip-screen-reader-text":
                                _vm.content.tooltipScreenReaderText,
                              "popover-triggers": ["hover", "click"],
                              "base-classes": "new-number-tooltip"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "new-number-tooltip__icon-wrapper"
                              },
                              [
                                _c("c-icon", {
                                  staticClass:
                                    "new-number-tooltip__icon-wrapper__icon",
                                  class: {
                                    "new-number-tooltip__icon-wrapper__icon--disabled": !_vm.isNumberUnlisted
                                  },
                                  attrs: { "symbol-id": "service-information" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }