var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-grid" }, [
    _c("div", { staticClass: "cm-grid__col--xs-2" }, [
      _c("p", { staticClass: "cm-text-center" }, [
        _vm.image
          ? _c("img", { attrs: { src: _vm.imageSrc, alt: _vm.name } })
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cm-grid__col--xs-10 cm-c-cart-mini_basket-grid" },
      [
        _c(
          "h3",
          {
            staticClass:
              "cm-c-cart-list__product-title cm-c-cart-list__product-title--sm"
          },
          [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cm-grid" }, [
          _c("div", { staticClass: "cm-grid__col--xs-8" }, [
            _c("p", [_vm._v(_vm._s(_vm.payment))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cm-grid__col--xs-4" }, [
            _c("p", { staticClass: "cm-text-right" }, [
              _vm._v(_vm._s(_vm.price))
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }