var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-popover",
    {
      staticClass: "tooltip__container",
      class: _vm.baseClasses,
      attrs: {
        trigger: _vm.popoverTriggersString,
        placement: _vm.popoverPlacement,
        offset: _vm.popoverOffsetString,
        "popover-class": _vm.popoverModifiers
      }
    },
    [
      _vm._t("default", [_c("p", [_vm._v("No slot content provided")])]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "popover" },
        [
          _c(
            "c-button",
            {
              directives: [
                {
                  name: "close-popover",
                  rawName: "v-close-popover",
                  value: true,
                  expression: "true"
                }
              ],
              staticClass: "tooltip__popover-close-button",
              attrs: { reset: "", type: "button" }
            },
            [
              _c("c-icon", {
                staticClass: "tooltip__popover-close-button-icon",
                attrs: { "aria-hidden": "true", "symbol-id": "menu-close" }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "cm-sr-only",
                  attrs: { "data-testid": "tooltip__popover-sr" }
                },
                [_vm._v(_vm._s(_vm.tooltipScreenReaderText))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("popoverContent"),
          _vm._v(" "),
          _c("div", {
            staticClass: "tooltip__popover__description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }