/* TODO: Remove jquery dependency */
/* eslint-disable */
var dataBox,
  localCallBox,
  callReceivedBox,
  smsBox,
  callToDkBox,
  callOtherBox,
  callCountrySelect,
  country,
  countryID,
  countries,
  isBusiness,
  isPrivate,
  mainContainer,
  operator,
  productType,
  productTypes,
  searchCountrySelect,
  searchOperatorSelect,
  zone,
  numDecimals;

if (document.getElementById('udlandspriser')) {
  $(function () {
    productTypes = ['erhverv', 'privat'];
    countryID = getURLParameter('id');
    mainContainer = $('#udlandspriser');
    productType =
      $.inArray(getURLParameter('type').toLowerCase(), productTypes) !== -1
        ? getURLParameter('type').toLowerCase()
        : '';
    searchCountrySelect = $('#search-country select');
    searchOperatorSelect = $('#search-operator select');
    callCountrySelect = $('#calltoothers select');

    dataBox = $('.blocks .tallbox').eq(0);
    localCallBox = $('.blocks .box').eq(0);
    callReceivedBox = $('.blocks .box').eq(1);
    smsBox = $('.blocks .box').eq(2);
    callToDkBox = $('.blocks .box').eq(3);
    callOtherBox = $('.blocks .widebox').eq(0);

    if (productType !== '') {
      $(
        '#udlandspriser-areas .tsr-btn[data-type="' + productType + '"]'
      ).addClass('tsr-btn-blue-selected');
      setTypes();
      loadCountries(productType, loadCountriesCallback);
    }

    searchCountrySelect.change(function () {
      var isSelected = $(this).val() !== '' ? true : false;

      if (isSelected) {
        // Enable all options, then disable selected country in the 'opkald til' dropdown
        $('#calltoothers select')
          .find('option:disabled')
          .prop('disabled', false);
        $('#calltoothers select')
          .find('option[value=' + $(this).val() + ']')
          .prop('disabled', true);

        loadCountry($(this).val(), productType, loadCountryCallback);
      }

      mainContainer.toggleClass('active', isSelected);
    });

    callCountrySelect.change(callcountryChange);

    $('#udlandspriser-areas .tsr-btn').click(function (e) {
      e.preventDefault();

      if (!$(this).hasClass('tsr-btn-blue-selected')) {
        productType = $(this).data('type');

        $(this)
          .addClass('tsr-btn-blue-selected')
          .siblings()
          .removeClass('tsr-btn-blue-selected');
        mainContainer.removeClass('active show');

        setTypes();
        loadCountries(productType, loadCountriesCallback);
      }
    });

    if (
      productType === null ||
      productType === 'undefined' ||
      productType === ''
    ) {
      productType = $('#udlandspriser-areas').data('type');

      if (
        productType !== null &&
        productType !== 'undefined' &&
        productType !== ''
      ) {
        setTypes();
        loadCountries(productType, loadCountriesCallback);
      }
    }
  });

  var loadCountryCallback = function (data) {
    country = data;
    operator = country.Operators[0];
    updateContent();
    calculateOtherCountriesCall();
  };

  var loadCountriesCallback = function (data) {
    countries = data;

    searchCountrySelect.empty();
    $('<option />').appendTo(searchCountrySelect);
    callCountrySelect.empty();
    $('<option />').appendTo(callCountrySelect);

    $(countries).each(function () {
      var option = $('<option />')
        .attr('value', this.ID)
        .attr('data-zone', this.ZoneID)
        .text(this.Name);
      option.appendTo(searchCountrySelect);
      option.clone().appendTo(callCountrySelect);
    });

    showSelections();
  };

  var callcountryChange = function () {
    var isSelected = $(this).val() !== '' ? true : false;

    if (isSelected) {
      zone = $(this).find('*:selected').data('zone');
    } else {
      zone = null;
    }

    calculateOtherCountriesCall();
  };

  var showSelections = function () {
    mainContainer.toggleClass('show', productType !== '');
    mainContainer.removeClass('active');
  };

  var setTypes = function () {
    isBusiness = productType === productTypes[0] ? true : false;
    isPrivate = productType === productTypes[1] ? true : false;
  };

  var calculateOtherCountriesCall = function () {
    var value;

    if (operator != null && zone != null) {
      value =
        zone > 2
          ? setPrice(operator.CallsToZone3To6)
          : setPrice(operator.CallsToZone1To2);
    } else {
      value = '-';
    }
    $('#callotherprice .large').text(value);
    $('#callother .head').text(
      operator.PriceCallOtherBoxHeader +
        ' ' +
        $('#callcountrydropdown option:selected').text()
    );
  };

  var updateOperatorSelect = function () {
    searchOperatorSelect.empty();

    var lowestPrice = 10000000;
    var lowestOperator = '';
    operator = $(country.Operators).eq(0)[0];

    $(country.Operators).each(function (index) {
      var option = $('<option />').text(this.Name).val(index);
      option.appendTo(searchOperatorSelect);

      if (
        parseInt(this.PricePrMB) > 0 &&
        parseFloat(this.PricePrMB) < lowestPrice
      ) {
        lowestPrice = parseFloat(this.PricePrMB);
        lowestOperator = index;

        operator = this;
      }
    });

    var lowestOperatorSelect = searchOperatorSelect.find(
      '*[value="' + lowestOperator + '"]'
    );

    if (lowestOperatorSelect.length > 0) {
      lowestOperatorSelect.attr('selected', true);
    } else {
      searchOperatorSelect.children().eq(0).attr('selected', true);
    }

    searchOperatorSelect.select2({
      width: 200
    });

    searchOperatorSelect.change(function () {
      operator = country.Operators[$(this).val()];
      updateContent();
      calculateOtherCountriesCall();
    });
  };

  var updateContent = function () {
    $('.country').text(country.Name);

    if (operator.CountryText == null) {
      $('#countrytext').hide();
    } else {
      $('#spancountrytext').text(operator.CountryText);
      $('#countrytext').show();
    }

    if (operator.ChargingText == null) {
      $('#chargingtext').hide();
    } else {
      $('#chargingtext').show();
      $('#spanchargingtext').text(operator.ChargingText);
    }

    var dataPrice =
      operator.PricePrMB === 0
        ? '<span><b>0</b> ' + operator.PriceSmsBoxUnit + '</span>'
        : '<span class="large">' +
          getPrice(operator.PricePrMB) +
          '</span><span class="unit"> ' +
          operator.PriceSmsBoxUnit +
          '</span><br /><span class="subhead">Afregning pr. ' +
          operator.MinChargeKB +
          'kB</span>';
    var localCallPrice =
      operator.callLocalPrice === 0
        ? '<span><b>0</b> ' + operator.PriceCallToDkBoxUnit + '</span>'
        : '<span class="large">' +
          setPrice(operator.LocalCall) +
          '</span><span class="unit"> ' +
          operator.PriceCallToDkBoxUnit +
          '</span>';
    var callReceivedPrice =
      operator.ReceivedCall === 0
        ? '<span><b>0</b> ' + operator.PriceDataBoxUnit + '</span>'
        : '<span class="large">' +
          getPrice(operator.ReceivedCall) +
          '</span><span class="unit"> ' +
          operator.PriceDataBoxUnit +
          '</span>';
    var smsPrice =
      operator.SmsPrice === 0
        ? '<span><b>0</b> ' + operator.PriceCallReceivedBoxUnit + '</span>'
        : '<span class="large">' +
          getPrice(operator.SmsPrice) +
          '</span><span class="unit"> ' +
          operator.PriceCallReceivedBoxUnit +
          '</span>';
    var callToDkPrice =
      operator.CallToDenmark === 0
        ? '<span><b>0</b> ' + operator.PriceLocalCallBoxUnit + '</span>'
        : '<span class="large">' +
          getPrice(operator.CallToDenmark) +
          '</span><span class="unit"> ' +
          operator.PriceLocalCallBoxUnit +
          '</span>';

    dataBox.html(
      '<div class="head">' + operator.PriceSmsBoxHeader + '</div>' + dataPrice
    );
    localCallBox.html(
      '<div class="head">' +
        operator.PriceCallToDkBoxHeader +
        '</div>' +
        localCallPrice
    );
    callReceivedBox.html(
      '<div class="head">' +
        operator.PriceDataBoxHeader +
        '</div>' +
        callReceivedPrice
    );
    smsBox.html(
      '<div class="head">' +
        operator.PriceCallReceivedBoxHeader +
        '</div>' +
        smsPrice
    );
    callToDkBox.html(
      '<div class="head">' +
        operator.PriceLocalCallBoxHeader +
        '</div>' +
        callToDkPrice
    );

    var dropdownCallCountry = document.getElementById('callcountrydropdown');

    callOtherBox.html(
      '<div class="clear left pad"><span class="head">' +
        operator.CallOtherInnerBoxHeader +
        '</span><br />' +
        operator.CallOtherInnerBoxSubHeader +
        '<div id="calltoothers"></div></div>' +
        '<div id="callother" class="callother"><span class="head ib marg">' +
        operator.PriceCallOtherBoxHeader +
        '</span><br /></div>' +
        '<div id="callotherprice" class="callotherprice ib cm-mb-top--ssm"><span class="large">-</span><span class="unit"> ' +
        operator.PriceCallOtherBoxUnit +
        '</span></div>'
    );

    var dropdownDiv = document.getElementById('calltoothers');
    dropdownDiv.append(dropdownCallCountry);

    // The new select components has multiple elements, make sure the correct field is targeted
    if ($(dropdownCallCountry).get(0).tagName.toLowerCase() !== 'select') {
      var dropdownCallCountrySelect =
        dropdownCallCountry.getElementsByTagName('select');
    }
    // Have to re-bind the eventhandling after re-appending the dropdown to the DOM.
    $(
      dropdownCallCountrySelect
        ? dropdownCallCountrySelect
        : dropdownCallCountry
    ).change(callcountryChange);

    //this adds a class specific for callme, to change the color of price text: class"large callme_color"
    if (document.URL.split(/\./g).indexOf('callme') !== -1) {
      $('.large').addClass('callme_color');
    }
  };

  var getPrice = function (rawValue) {
    if (rawValue == null || rawValue === '-' || rawValue === '') {
      return '-';
    }

    if (!isBusiness) {
      rawValue = parseFloat(rawValue) * 1.25;
    }

    numDecimals = decimalPlaces(parseFloat(rawValue));

    if (numDecimals > 2) {
      var numbers = rawValue.toString().split('.');
      var secondDecimal = numbers[1].charAt(1);
      var integerSecondDecimal = parseInt(secondDecimal) + 1;

      rawValue = numbers[0] + '.' + numbers[1].charAt(0) + integerSecondDecimal;
    }

    return parseFloat(rawValue).toFixed(2).replace('.', ',');
  };

  var setPrice = function (rawValue) {
    var price =
      rawValue == null || rawValue === '-' || rawValue === ''
        ? '-'
        : getPrice(rawValue);

    return price;
  };

  var setMinChage = function (val) {
    var dataValue = val === 0 ? '-' : val + ' kB.';

    return dataValue;
  };

  var getURLParameter = function (name) {
    return decodeURI(
      (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, null])[1]
    );
  };

  var decimalPlaces = function (num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
    );
  };
}
