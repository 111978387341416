<template>
  <section class="product-tabs cm-container cm-container--lg">
    <c-tabs
      id="navbars"
      ref="tabs"
      class="product-tabs__tabs"
      @activated="onTabActivated"
    >
      <c-tab
        v-if="marketingData"
        :id="PRODUCT_TABS.MARKETING"
        :title="contentLocal.productDetailsHeading"
        class="
          cm-c-details
          product-tabs__content product-tabs__content--full-width
        "
      >
        <slot
          v-if="marketingData.hasAlternativeText"
          name="marketing-alternative-content"
        ></slot>

        <div
          v-else
          class="product-tabs__content__markup"
          v-html="marketingData.productUniqueSellingPoints"
        ></div>
      </c-tab>

      <c-tab
        v-if="hasSpecifications"
        :id="PRODUCT_TABS.SPECIFICATIONS"
        :title="contentLocal.specificationsHeading"
        class="cm-c-specs product-tabs__content"
      >
        <div class="cm-grid">
          <div class="cm-grid__col--md-12 cm-grid__col--first-sm">
            <div
              v-for="(specs, index) in filteredSpecifications"
              :key="index"
              class="cm-c-specs__content cm-mb--sm"
            >
              <h3 class="cm-c-specs__title">
                <c-icon
                  v-if="!specs.hideIcon"
                  :symbol-id="getSpecificationIcon(specs)"
                ></c-icon>
                {{ specs.headline }}
              </h3>
              <ul class="cm-c-specs__list">
                <li
                  v-for="(item, j) in specs.specifications"
                  :key="j"
                  class="cm-c-specs__item"
                >
                  <template v-if="item.value">
                    <span class="cm-c-specs__prop">{{ item.key }}</span>
                    <span class="cm-c-specs__value">{{ item.value }}</span>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </c-tab>

      <c-tab
        :id="PRODUCT_TABS.PRICES"
        :title="contentLocal.productTermsTabLabel"
        class="cm-c-terms product-tabs__content"
      >
        <div class="cm-grid">
          <div
            class="
              cm-grid__col--xs-2 cm-grid__col--xl-offset-2
              cm-c-total__image
            "
          >
            <img
              v-if="imageUrls[0]"
              :src="imageUrls[0] + '?w=480'"
              :selected="product.name"
            />
          </div>
          <div
            class="cm-grid__col--xl-6 cm-grid__col--lg-10 cm-grid__col--xs-12"
          >
            <div v-if="prices" class="cm-c-total cm-c-total--box">
              <div
                class="
                  cm-c-total__row cm-c-total__row--lg cm-c-total__row--bold
                "
              >
                {{ product.name }}, {{ variantSummary }}
                <div class="cm-c-total__value">
                  <span>
                    {{
                      hasInstallments
                        ? prices.productMonthlyPrice.formattedValueCurrency
                        : prices.productPrice.formattedValueCurrency
                    }}
                  </span>
                </div>
              </div>
              <div
                v-if="selectedSubscription"
                class="
                  cm-c-total__row
                  cm-c-total__row--lg
                  cm-c-total__row--bold
                  cm-c-total__row--bottom-separator
                "
              >
                <div class="cm-c-total__name">
                  {{ selectedSubscription.displayName }} -
                  {{ selectedSubscription.dataIncluded }},
                  {{ selectedSubscription.includedEuData }}
                  {{ selectedSubscription.inEuLabel }},
                  {{ selectedSubscription.name }}
                </div>
                <div
                  v-if="selectedSubscription.price.productBasePrice"
                  class="cm-c-total__value"
                >
                  {{
                    selectedSubscription.price.productBasePrice
                      .formattedValueCurrency
                  }}
                </div>
              </div>
              <div class="cm-c-total__accordion with-tooltip">
                <input
                  id="accordion-trigger"
                  ref="accordiontrigger"
                  type="checkbox"
                  name="accordion-trigger"
                  checked
                />

                <template
                  v-if="
                    prices.productDiscountedPrice &&
                    prices.productDiscountedPrice.value !== 0
                  "
                >
                  <div class="cm-c-total__row cm-c-total__row--sm">
                    <div class="cm-c-total__name">
                      {{ contentLocal.basePriceLabel }}
                    </div>
                    <div class="cm-c-total__value">
                      {{ prices.productBasePrice.formattedValue }}
                      {{ prices.productBasePrice.currency }}
                    </div>
                  </div>
                  <div class="cm-c-total__row cm-c-total__row--sm">
                    <div class="cm-c-total__name">
                      {{ contentLocal.discountPriceLabel }}
                    </div>
                    <div class="cm-c-total__value">
                      {{ prices.productDiscountedPrice.formattedValue }}
                      {{ prices.productBasePrice.currency }}
                    </div>
                  </div>
                  <div
                    class="
                      cm-c-total__row
                      cm-c-total__row--sm
                      cm-c-total__row--bottom-separator
                    "
                  >
                    <div class="cm-c-total__name">
                      {{ contentLocal.productPriceLabel }}
                    </div>
                    <div class="cm-c-total__value">
                      {{ prices.productPrice.formattedValue }}
                      {{ prices.productBasePrice.currency }}
                    </div>
                  </div>
                </template>

                <label for="accordion-trigger" class="cm-c-total__trigger">
                  <c-icon symbol-id="arrow-chevron"></c-icon>
                  <div class="cm-c-total__row cm-c-total__row--sm with-tooltip">
                    <div class="cm-c-total__name">
                      {{ contentLocal.minimumPriceText }}
                    </div>
                    <div class="cm-c-total__value">
                      {{ prices.minimumPrice.formattedValue }}
                      {{ prices.minimumPrice.currency }}
                    </div>
                  </div>
                </label>

                <div class="cm-c-total__content">
                  <product-minimum-price-tooltip
                    v-if="minimumPriceData"
                    :data="minimumPriceData"
                    accordion
                    hide-headlines
                    hide-totals
                  >
                  </product-minimum-price-tooltip>
                </div>

                <div
                  v-if="selectedSubscription"
                  class="cm-c-total__summary"
                  v-html="selectedSubscription.legalDescription"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </c-tab>
    </c-tabs>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  GETTERS,
  ACTIONS
} from 'webshop/components/product/store/product-page';
import { PRODUCT_TABS } from 'webshop/enums/product-tabs';

import ProductMinimumPriceTooltip from './minimum-price-tooltip.vue';
import { CTab, CTabs } from 'olympus/components';

/**
 * A wrapper around generic tabs
 * to implement styles and behaviour for the product page.
 */
export default {
  name: 'ProductTabs',

  components: {
    ProductMinimumPriceTooltip,
    CTab,
    CTabs
  },

  props: {
    isAccessory: {
      type: Boolean,
      default: false
    },

    marketingData: {
      type: Object,
      default: undefined
    },

    specifications: {
      type: Array,
      default: () => []
    },

    imageUrls: {
      type: Array,
      default: () => []
    },

    variantSummary: {
      type: String,
      default: ''
    },

    external: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return { PRODUCT_TABS };
  },

  computed: {
    ...mapGetters({
      activeTab: GETTERS.GET_ACTIVE_TAB,
      contentLocal: GETTERS.GET_CONTENT_LOCAL,
      product: GETTERS.GET_PRODUCT,
      selectedInstallment: GETTERS.GET_SELECTED_INSTALLMENT,
      selectedSubscription: GETTERS.GET_SELECTED_SUBSCRIPTION,
      prices: GETTERS.GET_PRICES,
      hasInstallments: GETTERS.HAS_INSTALLMENTS
    }),

    filteredSpecifications() {
      return this.specifications?.filter(g => g.specifications?.length);
    },

    hasSpecifications() {
      return this.filteredSpecifications.length;
    },

    minimumPriceData() {
      return {
        details: this.prices?.detailed?.details
      };
    }
  },

  watch: {
    activeTab(newValue, oldValue) {
      if (!newValue) {
        return;
      }

      if (newValue.id !== oldValue.id) {
        this.$refs.tabs.toggleById(newValue.id);
      }

      if (newValue.scrollToTab) {
        const navbarsElement = document.body.querySelector(
          `.c-modal .c-modal-content__body #navbars`
        );
        this.$nextTick(() =>
          !this.external
            ? this.$scrollTo(`#${newValue.id}`)
            : navbarsElement?.scrollIntoView({ behavior: 'smooth' })
        );
      }
    }
  },

  methods: {
    ...mapActions({
      setActiveTab: ACTIONS.SET_ACTIVE_TAB
    }),

    onTabActivated(id) {
      this.setActiveTab({ id, scrollToTab: false });
    },

    groupedSpecsContainsValues(groupedSpecs) {
      return groupedSpecs?.specifications?.find(spec => {
        return !!spec.value;
      });
    },

    getSpecificationIcon(groupedSpecs) {
      return groupedSpecs.iconSymbolId || 'general-placeholder';
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-tabs {
  padding: $u-500 0;

  @include mq($screen-md, max) {
    max-width: 100%;
  }

  // .product-tabs__tabs
  &__tabs {
    [class$='-nav'] {
      & > ul {
        padding: 0 0 $u-700 + $u-350 0;

        @include mq($screen-md, max) {
          padding-bottom: $u-700;
        }

        li {
          margin-right: $u-500;

          @include mq($screen-md, min) {
            margin-right: $u-700 + $u-200;
          }

          &.active {
            a {
              color: $c-pm-500;
              border-bottom-color: $c-pm-500;
            }
          }
        }

        a {
          font-size: $font-size-h4;
          padding-bottom: $u-200;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  // .product-tabs__content
  &__content {
    max-width: 792px;
    margin: 0 auto;

    &--full-width {
      max-width: 100%;
    }

    &__markup {
      h1,
      h2,
      h3,
      h4 {
        font-family: $global-font-family-2;
        font-size: $font-size-h2;
        padding: $u-300 0 $u-150;
        text-transform: uppercase;
      }

      p {
        line-height: 1.3;
      }
    }
  }

  .cm-c-total {
    margin-top: $u-300;

    // .cm-c-total__row
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    // .cm-c-total__value
    &__value {
      flex-shrink: 0;
      margin-left: $u-300;
    }
  }
}
</style>
