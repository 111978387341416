var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-modal__content" }, [
    _c(
      "div",
      { staticClass: "cm-c-modal__article cm-text-center" },
      [
        _c(
          "c-heading",
          { attrs: { slot: "header", level: "6", as: "4" }, slot: "header" },
          [_vm._v("\n      " + _vm._s(_vm.contentObject.heading) + "\n    ")]
        ),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.contentObject.bodyText) }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cm-c-modal__footer cm-c-modal__footer--with-buttons" },
      [
        _c(
          "button",
          {
            staticClass: "c-btn c-btn--primary",
            attrs: { type: "button" },
            on: { click: _vm.accept }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.contentObject.acceptButtonLabel) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "c-btn c-btn--as-link",
            attrs: { type: "button" },
            on: { click: _vm.hideDialog }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.contentObject.cancelButtonLabel) +
                "\n    "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }