<template>
  <div class="cm-container">
    <div class="cm-o-layout cm-o-layout--main">
      <div class="cm-grid shopping-cart">
        <div class="cm-grid cm-grid__col--xs-12">
          <div class="cm-grid cm-grid__col--xs-12">
            <div
              class="
                cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2
                shopping-cart__header
              "
            >
              <h2 class="shopping-cart__title">
                {{ basketPageHeading }}
              </h2>

              <empty-basket
                v-if="content.clearBasketEnabled && !isEmpty"
                :content="emptyBasketModal"
              />
            </div>
          </div>
        </div>

        <div v-if="!isInit || isUpdating" class="cm-c-spinner"></div>

        <!-- OB uses lazy loading of images and if the DOM is not rendered, we are having race issues, therefore
              we are forced to use v-show instead.  -->
        <div v-show="isInit" class="cm-grid cm-grid__col--xs-12">
          <div
            v-show="content.emptyBasketPageDescription && isEmpty"
            class="
              shopping-cart--empty
              cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2
            "
            v-html="content.emptyBasketPageDescription"
          ></div>

          <div
            v-show="!isEmpty"
            class="
              cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2
            "
          >
            <slot name="main-content"></slot>
          </div>
          <div v-show="!isEmpty" class="cm-grid__col--xs-12">
            <slot name="full-width-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BasketStepProvider from './basket-step-provider.js';

import EmptyBasket from './empty-basket.vue';

import {
  GETTERS as BASKET_GETTERS,
  ACTIONS as BASKET_ACTIONS
} from '../../store/basket';

const DEFAULT_CONTENT = {
  nextPageButtonText: 'Videre',
  nextPage: '#'
};

export default {
  name: 'BasketPage',

  components: {
    EmptyBasket
  },

  mixins: [BasketStepProvider],

  props: {
    basketPageHeading: { type: String, required: true },
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    emptyBasketModal: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  computed: {
    ...mapGetters({
      isInit: BASKET_GETTERS.IS_INIT,
      isUpdating: BASKET_GETTERS.IS_UPDATING,
      isEmpty: BASKET_GETTERS.IS_EMPTY
    })
  },

  created() {
    /**
     * We are fetching the basket earlier, to block
     * mini basket call and ensure offerings are also
     * included in the response.
     */
    this.initBasket();
  },

  methods: {
    ...mapActions({
      initBasket: BASKET_ACTIONS.INIT_BASKET
    })
  }
};
</script>
