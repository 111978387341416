var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-modal-wrapper-inner" }, [
    _c("header", { staticClass: "c-modal-content__header" }, [
      _c("h3", {
        domProps: { innerHTML: _vm._s(_vm.errorObject.errorHeader) }
      }),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.errorObject.errorText) } }),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.errorObject.errorTextSub) } })
    ]),
    _vm._v(" "),
    _vm.errorObject.errorLink
      ? _c("footer", { staticClass: "c-modal-content__footer" }, [
          _vm.errorObject.errorLink.post
            ? _c(
                "button",
                {
                  staticClass: "c-btn c-btn--primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.postURL(_vm.errorObject.errorLink.href)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errorObject.errorLink.label || "OK") +
                      "\n    "
                  )
                ]
              )
            : _vm.errorObject.errorLink.href
            ? _c("a", {
                staticClass: "c-btn c-btn--primary",
                attrs: { href: _vm.errorObject.errorLink.href },
                domProps: {
                  innerHTML: _vm._s(_vm.errorObject.errorLink.label || "OK")
                }
              })
            : _c(
                "a",
                {
                  staticClass: "c-btn c-btn--primary",
                  on: { click: _vm.HIDE_DIALOG }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.errorObject.errorLink.label || "OK") +
                      "\n    "
                  )
                ]
              )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }