<template>
  <button class="c-btn shopping-cart-purchase" @click="handleClick">
    <c-icon
      class="shopping-cart-purchase__icon shopping-cart-purchase__icon--type"
      :symbol-id="offering.icon"
    />

    <span>{{ offering.label }}</span>

    <c-icon
      class="shopping-cart-purchase__icon shopping-cart-purchase__icon--plus"
      symbol-id="service-compare"
    />
  </button>
</template>

<script>
import { ITEM_TYPES } from '../../enums/access-technology-types.js';

// TODO DKT-1425: this entire component should be mixed with c-box--dashed
// as it does not do anything special than styling and event
// that does not belong to it.
export default {
  name: 'BasketOffering',

  props: {
    offering: {
      type: Object,
      default: () => ({})
    },
    group: {
      type: Object,
      required: true
    }
  },

  computed: {
    isSubscription() {
      return this.group.items.find(x => x.type === ITEM_TYPES.SUBSCRIPTION);
    }
  },

  methods: {
    handleClick() {
      this.$emit('activate', this.offering.type);
    }
  }
};
</script>
