var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newVM.length > 0
    ? _c("div", { staticClass: "price-section", class: _vm.modifiers }, [
        !_vm.noHeading && _vm.headingLabel
          ? _c("div", { staticClass: "price-section__heading" }, [
              _c("dl", { staticClass: "text-bold" }, [
                _c("dt", [_vm._v(_vm._s(_vm.headingLabel))])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "price-section__descriptions" },
            _vm._l(_vm.formattedItems, function(line, lineIdx) {
              return _c("div", { key: line.label + lineIdx }, [
                _c("dl", [
                  _c("dt", [_vm._v(_vm._s(line.label))]),
                  _vm._v(" "),
                  line.promotionSaved
                    ? _c("dd", { staticClass: "savedAmount" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(line.basePrice) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(line.value))])
                ]),
                _vm._v(" "),
                line.promotionSaved
                  ? _c("dl", { staticClass: "savedAmount" }, [
                      _c("dt", [
                        _vm._v(_vm._s(_vm.content.promotionCodeAppliedLabel))
                      ]),
                      _vm._v(" "),
                      _c("dd", [_vm._v("-" + _vm._s(line.promotionSaved))])
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      ])
    : _vm.vm && _vm.vm.items
    ? _c("div", { staticClass: "price-section", class: _vm.modifiers }, [
        !_vm.noHeading && _vm.vm.heading
          ? _c("div", { staticClass: "price-section__heading" }, [
              _c("dl", { staticClass: "text-bold" }, [
                _c("dt", [_vm._v(_vm._s(_vm.vm.heading.label))]),
                _vm._v(" "),
                !_vm.upfrontPayment
                  ? _c("dd", [_vm._v(_vm._s(_vm.vm.heading.value))])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          _vm._l(_vm.vm.items, function(group, groupIdx) {
            return _c(
              "div",
              { key: groupIdx, staticClass: "price-section__descriptions" },
              _vm._l(group, function(line, lineIdx) {
                return _c("div", { key: lineIdx }, [
                  _c("dl", [
                    _c("dt", [_vm._v(_vm._s(line.label))]),
                    _vm._v(" "),
                    _c("dd", [_vm._v(_vm._s(line.fullPrice || line.value))])
                  ]),
                  _vm._v(" "),
                  line.subscriptionSaved
                    ? _c("dl", { staticClass: "savedAmount" }, [
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.subscriptionDiscountLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v("-" + _vm._s(line.subscriptionSaved))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  line.offerSaved
                    ? _c("dl", { staticClass: "savedAmount" }, [
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.offerDiscountLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v("-" + _vm._s(line.offerSaved))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  line.savedAmmount
                    ? _c("dl", { staticClass: "savedAmount" }, [
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.promotionCodeAppliedLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v("-" + _vm._s(line.savedAmmount))])
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }