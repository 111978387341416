<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import {
  ACTIONS as BASKET_ACTIONS,
  GETTERS as BASKET_GETTERS
} from '../../store/basket';

import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import { CButton } from 'olympus/components';
import PurchaseMember from './purchase-member/purchase-member.vue';
import BasketGroup from './basket-group.vue';
import BasketCoupon from './basket-coupon.vue';
import ShoppingCartItem from './shopping-cart-item/shopping-cart-item.vue';
import AddEdcOverlay from './add-edc-overlay.vue';
import AddHardwareOverlay from './add-hardware-overlay.vue';
import AddVasOverlay from './add-vas-overlay.vue';
import { useCheckoutFinancingValidation } from './checkout-financing-validation.js';
import FinancingConflictOverlay from './financing-conflict-overlay.vue';
import withTemplate from 'theme/templates/shopping-cart-overview.html';
import { BASKET_OFFERING_TYPE } from './basket-constants';
import RootBusMixin from 'olympus/mixins/root-bus-mixin.js';
import { providerKey as BASKET_STEP_PROVIDER_KEY } from './basket-step-provider.js';

const DEFAULT_CONTENT = {
  buyButtonText: 'Næste',
  emptyBasketLabel: 'Kurven er tom',
  headLine: 'Kurv',
  purchaseButtonEdcLabel: 'Tilføj EDC',
  purchaseButtonHandsetLabel: 'Tilføj Mobil',
  purchaseButtonVasLabel: 'Tilføj Service',
  purchaseButtonInsuranceLabel: 'Purchase Insurance',
  purchaseHandsetText: 'Tilkøb af mobiltelefon',
  payNowText: 'Betal nu',
  pricePerMonthText: 'Pris pr. mdr.',
  promoPhoneDiscountText:
    'Dit abonnement giver dig {{discount}} rabat på mobiltelefon.',
  installmentForMonthsTextTemplate: 'i {{period}} mdr.',
  bindingPeriodText: '{{paymentPeriod}} mdrs. binding'
};

export default withTemplate({
  name: 'shopping-cart-overview',

  inject: [BASKET_STEP_PROVIDER_KEY],

  components: {
    CButton,

    BasketGroup,
    BasketCoupon,
    ShoppingCartItem,
    PurchaseMember,
    AddEdcOverlay,
    FinancingConflictOverlay,
    AddHardwareOverlay,
    AddVasOverlay
  },

  mixins: [ContentPropertyMixin, RootBusMixin],

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },

    blockId: {
      type: Number,
      required: true
    },

    vasCheckoutModuleBlockId: {
      type: Number,
      default: undefined
    },

    isFinancingConflictEnabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const checkoutFinancingValidation = useCheckoutFinancingValidation(this);

    return {
      removeMasterModal: {
        productCode: '',
        groupId: ''
      },

      isInitialCartLoaded: false,

      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      },

      checkoutFinancingValidation
    };
  },

  computed: {
    ...mapGetters({
      isInit: BASKET_GETTERS.IS_INIT,
      relatedGroups: BASKET_GETTERS.GROUPS,
      isEmpty: BASKET_GETTERS.IS_EMPTY
    }),

    ...mapState({
      // TODO DKT-1422: use getter
      basketPrices: state => state.shop.basketPrices
    }),

    shopFooterColumns() {
      const { totalMonthly, totalUpfront } = this.basketPrices.data;
      const columns = [];

      if (totalMonthly) {
        columns.push({ ...totalMonthly });
      }

      if (totalUpfront) {
        columns.push({ ...totalUpfront });
      }
      return columns;
    },

    // TODO DKT-1422: this belongs to basket-group not here...
    hasHandsetOfferingGroup() {
      const relatedGroups = this.relatedGroups.filter(relatedGroup =>
        relatedGroup.groups.filter(group =>
          group.offerings.some(
            offering =>
              offering.offering === BASKET_OFFERING_TYPE.PURCHASE_HANDSET
          )
        )
      );
      return Boolean(relatedGroups.length);
    },

    nextPage() {
      return this[BASKET_STEP_PROVIDER_KEY].nextPage;
    },

    nextPageButtonText() {
      return this[BASKET_STEP_PROVIDER_KEY].nextPageButtonText;
    },

    financingOverlayContent() {
      return {
        title: this.content.financingConflictTitle,
        message: this.content.financingConflictMessage,
        approveButton: this.content.financingConflictApproveButton,
        declineButton: this.content.financingConflictDeclineButton
      };
    }
  },

  mounted() {
    this.initBasket();
  },

  methods: {
    ...mapActions({
      initBasket: BASKET_ACTIONS.INIT_BASKET,
      gotoCheckoutFlow: 'gotoCheckoutFlow'
    }),

    onNextButtonClicked() {
      this.checkoutFinancingValidation.checkForFinancingConflict();
    },

    navigateToNextPage() {
      window.location = this.nextPage;
    }
  }
});
</script>
