<template>
  <c-modal
    ref="sessionTimeoutOverlay"
    centered
    gray
    non-closable-modal
    :show-close-button="false"
  >
    <div class="session-timeout-overlay text-center">
      <c-heading
        slot="header"
        class="session-timeout-overlay__title text-center px-3"
        level="2"
        as="1"
      >
        {{ content.title }}
      </c-heading>
      <p class="session-timeout-overlay__description">
        {{ populatedTexts.subTitle }}
      </p>
      <div class="session-timeout-overlay__footer">
        <button
          class="c-btn c-btn--primary"
          @click="reloadPage"
          v-html="content.primaryButtonText"
        >
          {{ content.primaryButtonText }}
        </button>
        <form ref="logoutForm" action="/api/olympus/users/logout" method="post">
          <button class="c-btn c-btn--as-link" type="submit">
            {{ content.logoutButtonText }}
          </button>
        </form>
      </div>
      <div class="session-timeout-overlay__countdown">
        <p>{{ populatedTexts.autoLogoutText }}</p>
      </div>
    </div>
  </c-modal>
</template>

<script>
import { CHeading } from 'olympus/components/index.js';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin';

export default {
  name: 'SessionTimeoutOverlay',

  components: {
    CHeading
  },

  props: {
    content: {
      type: Object,
      default: () => window.__APP__.modalContent.sessionTimeout || {}
    }
  },

  data() {
    return {
      inactivityTime:
        window.__APP__.sessionTimeoutPopup?.inactivityTimeInSeconds,
      secondsUntilAutoLogout:
        window.__APP__.sessionTimeoutPopup?.sessionTimeInSeconds,
      countdownHandler: null
    };
  },

  computed: {
    inactivityTimeInMS() {
      return this.inactivityTime * 1000;
    },

    inactiveTime() {
      const minutes = Math.floor(this.inactivityTime / 60);
      const seconds = this.inactivityTime % 60;

      return `${minutes}${seconds ? ':' + seconds : ''}`;
    },

    populatedTexts() {
      return {
        subTitle: populateContentProperty(this.content.subTitle || '', {
          inactiveMinutes: this.inactiveTime
        }),
        autoLogoutText: populateContentProperty(
          this.content.autoLogoutText || '',
          {
            secondsUntilAutoLogout: String(this.secondsUntilAutoLogout)
          }
        )
      };
    }
  },

  watch: {
    secondsUntilAutoLogout(value) {
      if (value === 0) {
        this.logOut();
      }
    }
  },

  mounted() {
    if (window.__APP__.sessionTimeoutPopup) {
      this.startTimeTracking();
    }
  },

  methods: {
    startTimeTracking() {
      this.countdownHandler = setInterval(this.counter, 1000);
      setTimeout(() => {
        this.$refs.sessionTimeoutOverlay.show();
      }, this.inactivityTimeInMS);
    },

    counter() {
      this.secondsUntilAutoLogout--;
    },

    reloadPage() {
      window.location.reload();
    },

    logOut() {
      this.$refs.logoutForm.submit();
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.session-timeout-overlay {
  &__title {
    font-family: $global-font-family-2;
    margin-bottom: $u-300;
  }

  &__description {
    margin-bottom: $u-550;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: $u-450;

    .c-btn--primary {
      margin-bottom: $u-200;
    }

    .c-btn--as-link {
      font-size: $font-size-h4;
    }
  }
}
</style>
