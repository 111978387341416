var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "cm-c-total__row cm-c-total__row--bold cm-c-total-toggleitem"
      },
      [
        _c("div", { staticClass: "cm-c-total__name" }, [
          _c("div", { staticClass: "cm-c-toggle" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedLocal,
                  expression: "selectedLocal"
                }
              ],
              staticClass: "cm-c-toggle__checkbox",
              attrs: { id: _vm.item.code, type: "checkbox" },
              domProps: {
                value: _vm.item.code,
                checked: Array.isArray(_vm.selectedLocal)
                  ? _vm._i(_vm.selectedLocal, _vm.item.code) > -1
                  : _vm.selectedLocal
              },
              on: {
                click: _vm.toggleSelection,
                change: function($event) {
                  var $$a = _vm.selectedLocal,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.item.code,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.selectedLocal = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selectedLocal = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selectedLocal = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-toggle__btn" }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "cm-c-toggle__label",
                attrs: { for: _vm.item.code }
              },
              [_vm._v(_vm._s(_vm.item.displayName))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-total__value" }, [
          _vm._v("\n      " + _vm._s(_vm.priceText) + "\n    ")
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cm-c-total__row--bottom-separator" }, [
      _c(
        "ul",
        { staticClass: "cm-c-total__description" },
        _vm._l(_vm.item.uniqueSellingPointsPlus, function(prop, idx) {
          return _c("li", {
            key: idx,
            class: _vm.cannotBeInterpolated(prop)
              ? "cm-c-total__description__list-item"
              : "cm-c-total__description__link-item",
            domProps: { innerHTML: _vm._s(_vm.populateInsuranceLink(prop)) }
          })
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }