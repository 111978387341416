var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.classObject }, [
    _vm.images
      ? _c(
          "div",
          {
            staticClass: "product-gallery__image",
            attrs: { id: "open-gallery" }
          },
          [
            _vm.$resize && _vm.$mq.above(1181)
              ? _c(
                  "c-gallery",
                  {
                    staticClass: "product-gallery__container",
                    attrs: {
                      slot: "gallery",
                      items: _vm.images,
                      loop: false,
                      overlay: "",
                      navigation: false,
                      "small-image-size": 190,
                      "large-image-size": 290,
                      "small-image-width": "190px",
                      "large-image-width": "290px",
                      "selected-image-breakpoint": 600,
                      "thumbnail-position": _vm.contentLocal.thumbnailPosition,
                      "min-visible": _vm.contentLocal.minVisible
                    },
                    slot: "gallery"
                  },
                  [
                    _c(
                      "div",
                      {
                        attrs: { slot: "selectedFooter" },
                        slot: "selectedFooter"
                      },
                      [_vm._t("splash")],
                      2
                    )
                  ]
                )
              : _c("c-carousel", {
                  staticClass: "product-carousel",
                  attrs: {
                    slot: "carousel",
                    "center-slides": true,
                    "page-dots": true,
                    "single-slider-speed": 300,
                    data: _vm.images,
                    index: 0,
                    navigation: false,
                    loop: false,
                    "smooth-swiping": true
                  },
                  slot: "carousel",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "slide",
                        fn: function(slotProps) {
                          return [
                            _c("img", {
                              attrs: {
                                alt: slotProps.data.alt,
                                src: slotProps.data.src + "?w=480",
                                selected: slotProps.data.caption
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    862693769
                  )
                })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "footer",
      { staticClass: "product-gallery__footer" },
      [_vm._t("footer")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }