var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      attrs: {
        visible: _vm.visible,
        "non-closable-modal": _vm.networking,
        gray: "",
        wide: ""
      },
      on: { close: _vm.close }
    },
    [
      _c("div", { staticClass: "c-vas-multiple-overlay" }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "h2",
            {
              staticClass: "cm-c-checkout__title cm-c-checkout__title--center"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.getContentPropertyValue(
                      _vm.content.multipleVasModalHeadline
                    )
                  ) +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "c-vas-multiple-overlay__content" },
          _vm._l(_vm.services, function(vasProduct, index) {
            return _c("c-product-card-vas", {
              key: index,
              attrs: {
                transition: "staggered",
                stagger: "150",
                "vas-product": vasProduct,
                brand: _vm.brand,
                "group-id": _vm.groupId,
                "local-loading": false,
                "read-more": _vm.getContentPropertyValue(
                  _vm.content.expandLabel
                ),
                "price-disclaimer-text": _vm.getContentPropertyValue(
                  _vm.content.priceDisclaimerText
                ),
                close: _vm.getContentPropertyValue(_vm.content.collapseLabel)
              },
              on: { update: _vm.handleVasUpdate }
            })
          }),
          1
        )
      ]),
      _vm._v(" "),
      _vm.networking
        ? _c(
            "div",
            {
              staticClass: "cm-c-spinner cm-c-spinner--local",
              attrs: { slot: "inner" },
              slot: "inner"
            },
            [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }