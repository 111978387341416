var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-menu__side-button c-menu__side-button--box" },
    [
      _c(
        "label",
        {
          staticClass: "c-menu__item c-menu__item--icon",
          attrs: { for: "menu-credit-check", tabindex: "-1" },
          on: {
            click: function($event) {
              return _vm.$refs.creditCheckModal.show()
            }
          }
        },
        [
          _c("c-icon", { attrs: { "symbol-id": _vm.content.icon.markupId } }),
          _vm._v(" "),
          _c("span", { staticClass: "c-menu__item-txt" }, [
            _vm._v(_vm._s(_vm.content.openPopUpButtonCaption))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          ref: "creditCheckModal",
          attrs: {
            id: "creditCheckModal",
            centered: "",
            "is-credit-check": "",
            title: _vm.content.popUpHeaderText
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "credit-check__body" }, [
              !_vm.showDetails
                ? _c(
                    "div",
                    { staticClass: "credit-check__form" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "credit-check__form__input",
                          class: {
                            "credit-check__form__input--error": _vm.inputError
                          }
                        },
                        [
                          _c("c-input-group", {
                            attrs: {
                              "place-holder-label": _vm.content.cprInputLabel,
                              "input-error": _vm.inputError,
                              "disable-autocomplete": "",
                              type: "text"
                            },
                            on: { keypress: _vm.clearErrors },
                            model: {
                              value: _vm.searchText,
                              callback: function($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText"
                            }
                          }),
                          _vm._v(" "),
                          _c("c-icon", {
                            staticClass: "credit-check__form__input__icon",
                            attrs: { "symbol-id": "menu-search" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "c-checkbox",
                        {
                          staticClass: "credit-check__form__terms-checkbox",
                          attrs: {
                            id: "acceptTerms",
                            checked: _vm.acceptCheckbox
                          },
                          on: { "event-id": _vm.toggleAcceptCheckbox },
                          model: {
                            value: _vm.acceptCheckbox,
                            callback: function($$v) {
                              _vm.acceptCheckbox = $$v
                            },
                            expression: "acceptCheckbox"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.content.checkboxLabelText) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showSearchErrorMessage
                        ? _c(
                            "p",
                            {
                              staticClass: "credit-check__form__error-message"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.searchErrorMessage) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.enableNumberPortingOnCreditCheckModal ||
                      _vm.content.enableBuyHardwareOnCreditCheckModal
                        ? _c(
                            "c-heading",
                            {
                              staticClass: "credit-check__form__header",
                              attrs: { level: "2", as: "4" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.content
                                      .creditCheckModalMoreInformationHeader
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.enableNumberPortingOnCreditCheckModal
                        ? _c(
                            "c-checkbox",
                            {
                              staticClass:
                                "credit-check__form__nr-porting-checkbox",
                              attrs: { id: "numberPorting" },
                              on: {
                                "event-id": _vm.toggleNumberPortingCheckbox
                              },
                              model: {
                                value: _vm.numberPorting,
                                callback: function($$v) {
                                  _vm.numberPorting = $$v
                                },
                                expression: "numberPorting"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.content.numberPortingCheckBoxText
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.enableBuyHardwareOnCreditCheckModal
                        ? _c(
                            "c-checkbox",
                            {
                              attrs: { id: "hardwareWithCredit" },
                              on: {
                                "event-id": _vm.toggleHardwareWithCreditCheckbox
                              },
                              model: {
                                value: _vm.hardwareWithCredit,
                                callback: function($$v) {
                                  _vm.hardwareWithCredit = $$v
                                },
                                expression: "hardwareWithCredit"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.content.buyHardwareOnCreditCheckBoxText
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.enablePaymentOptionsOnCreditCheckModal
                        ? _c(
                            "c-heading",
                            {
                              staticClass: "credit-check__form__header",
                              attrs: { level: "2", as: "4" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.content.paymentMethodHeader) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.content.enablePaymentOptionsOnCreditCheckModal
                        ? _c(
                            "c-form-radio-group",
                            {
                              attrs: {
                                id: "paymentMethod",
                                name: "paymentMethod",
                                stacked: ""
                              },
                              model: {
                                value: _vm.paymentMethod,
                                callback: function($$v) {
                                  _vm.paymentMethod = $$v
                                },
                                expression: "paymentMethod"
                              }
                            },
                            [
                              _c(
                                "c-form-radio",
                                {
                                  attrs: {
                                    id: "option1",
                                    value: _vm.PAYMENT_METHODS.PBS
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.content.pbsPaymentRadioButtonLabel
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "c-form-radio",
                                {
                                  attrs: {
                                    id: "option2",
                                    value: _vm.PAYMENT_METHODS.GIRO
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.content.giroPaymentRadioButtonLabel
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "credit-check__result" },
                    [
                      _c("c-data-list", { attrs: { tabled: "" } }, [
                        _c("dt", [_vm._v(_vm._s(_vm.content.popUpNameLabel))]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(_vm.creditCheckDetails.name))]),
                        _vm._v(" "),
                        _vm.content.popUpCprLabel
                          ? _c("dt", [
                              _vm._v(_vm._s(_vm.content.popUpCprLabel))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.content.popUpCprLabel
                          ? _c("dd", [
                              _vm._v(_vm._s(_vm.creditCheckDetails.cpr))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("dt", [
                          _vm._v(
                            _vm._s(_vm.content.popUpCurrentSubscriptionsLabel)
                          )
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(_vm.creditCheckDetails.currentSubscriptions)
                          )
                        ]),
                        _vm._v(" "),
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.popUpMaxSubscriptionsLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            _vm._s(_vm.creditCheckDetails.maxSubscriptions)
                          )
                        ]),
                        _vm._v(" "),
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.popUpStatusLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", { class: _vm.statusClassModifier }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.creditCheckDetails.status) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("dt", [
                          _vm._v(_vm._s(_vm.content.popUpStatusMessageLabel))
                        ]),
                        _vm._v(" "),
                        _c("dd", [_vm._v(_vm._s(_vm.message))])
                      ])
                    ],
                    1
                  )
            ]),
            _vm._v(" "),
            _vm.content.enablePaymentOptionsOnCreditCheckModal &&
            _vm.content.popUpCreditCheckLink.value &&
            _vm.content.popUpCreditCheckLink.key
              ? _c("div", { staticClass: "credit-check-info" }, [
                  _vm.content.popUpCreditCheckTitle
                    ? _c("h4", { staticClass: "credit-check-info__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.popUpCreditCheckTitle) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.popUpCreditCheckDescription
                    ? _c(
                        "p",
                        { staticClass: "credit-check-info__description" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.content.popUpCreditCheckDescription) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "credit-check-info__button" }, [
                    _c(
                      "a",
                      {
                        key: "credit-check-button",
                        staticClass: "c-btn--secondary c-btn",
                        attrs: { href: _vm.content.popUpCreditCheckLink.key }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.content.popUpCreditCheckLink.value) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.showDetails
              ? _c(
                  "div",
                  { staticClass: "credit-check__footer" },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "credit-check__footer__initiate-btn",
                        attrs: { type: "button", disabled: !_vm.ready },
                        on: { click: _vm.initiateCreditCheck }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.searchButtonCaption) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "c-button",
                      {
                        attrs: { variant: _vm.BUTTON_VARIANTS.TERTIARY },
                        on: { click: _vm.closeModal }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.cancelButtonText) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "credit-check__footer" },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "credit-check__footer__initiate-btn",
                        attrs: { type: "button" },
                        on: { click: _vm.initiateCreditCheck }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.reloadButtonCaption) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "c-button",
                      {
                        attrs: { variant: _vm.BUTTON_VARIANTS.TERTIARY },
                        on: { click: _vm.handleClearBasket }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.resetButtonText) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }