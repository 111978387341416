<template>
  <div>
    <c-row
      v-if="!loading"
      class="price-summary__section price-summary__section--heading"
      tag="dl"
      no-gutters
    >
      <c-col cols="6" tag="dt">
        <strong>{{ headingLine.label }}</strong>
      </c-col>
      <c-col cols="6" tag="dd" class="text-right">
        <strong>{{ headingLine.value }}</strong>
      </c-col>
    </c-row>
    <div v-if="error" class="price-summary__section">
      <c-row tag="dl" class="price-summary__section-line">
        <c-col cols="12" tag="dt">{{ error }}</c-col>
      </c-row>
    </div>
    <c-collapse-trigger
      v-if="!loading"
      id="price-summary-sections"
      class="price-summary__trigger"
    >
      <template slot="default" slot-scope="{ expanded }">
        <c-icon symbol-id="arrow-chevron" :class="{ expanded }" />
        {{ minimumLine.label }}
        <span>{{ minimumLine.value }}</span>
      </template>
    </c-collapse-trigger>
    <c-collapse id="price-summary-sections">
      <div
        v-for="(section, sectionIdx) in sections"
        :key="sectionIdx"
        class="price-summary__section"
      >
        <c-row
          v-for="(line, lineIdx) in section"
          :key="lineIdx"
          tag="dl"
          class="price-summary__section-line"
        >
          <c-col cols="6" tag="dt">{{ line.label }}</c-col>
          <c-col cols="6" tag="dd">
            {{ line.value }}
          </c-col>
        </c-row>
      </div>
    </c-collapse>
    <skeleton-loader v-if="loading" class="price-summary__skeleton-loader" />
    <slot name="footer">
      <div
        v-if="footerText"
        class="price-summary__footer"
        v-html="footerText"
      ></div>
    </slot>
  </div>
</template>
<script>
import {
  CRow,
  CCol,
  CCollapse,
  CCollapseTrigger
} from 'olympus/components/index.js';
import SkeletonLoader from 'webshop/components/skeleton-loader/skeleton-loader.vue';

export default {
  name: 'PriceSummary',

  components: {
    CRow,
    CCol,
    CCollapse,
    CCollapseTrigger,
    SkeletonLoader
  },

  props: {
    headingLine: {
      type: Object,
      default: null
    },
    minimumLine: {
      type: Object,
      default: null
    },
    sections: {
      type: Array,
      default: () => []
    },
    footerText: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.price-summary {
  position: relative;

  // .price-summary__section
  &__section {
    border-bottom: 1px solid $c-nt-400;
    margin-bottom: $u-300;
    padding-bottom: $u-300;
    font-size: $global-font-size-md;
    line-height: 1.3;

    &-line {
      padding: $u-200 0;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      & > dd {
        text-align: right;
      }
    }

    // .price-summary__section--heading
    &--heading {
      font-size: $global-font-size;
      line-height: 1.5;
    }
  }

  &__trigger {
    position: relative;
    padding-left: $u-350;
    font-size: $global-font-size-md;
    line-height: 1.3;
    color: $c-nt-600;
    display: flex;
    justify-content: space-between;
    margin-bottom: $u-300;
    cursor: pointer;

    // .broadband-offering-page__prices-trigger > svg
    & > svg {
      position: absolute;
      top: 2px;
      left: 0;
      width: 14px;
      height: 14px;
      fill: $c-nt-600;
      transition: all 0.2s ease-in-out;

      // .broadband-offering-page__prices-trigger > svg.expanded
      &.expanded {
        transform: rotateZ(180deg);
      }
    }
  }

  // .price-summary__skeleton-loader
  &__skeleton-loader {
    width: 478px;
    height: 164px;
  }

  // .price-summary__footer
  &__footer {
    margin-bottom: $u-300;
    margin-top: $u-300;
    font-size: $global-font-size-sm;
    line-height: 18px;
    color: $c-nt-600;
    font-weight: 400;
  }
}
</style>
