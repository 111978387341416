import { SIM_OPTIONS } from 'webshop/enums/sim-options';

/**
 * Update the SIM-only properties based on the provided attributes.
 *
 * @param {object} attributes - The attributes object containing isEsimOnly and isPhysicalSimOnly.
 * @returns {object} - The updated attributes object.
 */
export const updateSimOnlyProperties = ({ isEsimOnly, isPhysicalSimOnly }) => {
  if (isEsimOnly && isPhysicalSimOnly) {
    return { isEsimOnly: false, isPhysicalSimOnly: false };
  }

  return { isEsimOnly, isPhysicalSimOnly };
};

/**
 * Get the SIM card settings based on the provided content and configuration.
 *
 * @param {object} content Content coming from the CMS holding the text.
 * @param {object} config Includes properties like the simOptions array and isEsimOnly, isPhysicalSimOnly.
 * @returns {object} texts which will be assigned to the radio buttons, after checking for the isEsimOnly, isPhysicalSimOnly conditions.
 */
export const getSimCardSettings = (content, config) => {
  let isEsimOnly, isPhysicalSimOnly;

  if (config.attributes) {
    ({ isEsimOnly, isPhysicalSimOnly } = updateSimOnlyProperties(
      config.attributes
    ));
  }

  return {
    legendText: content.cardSettings.cardTypeText,
    simOptions: [
      {
        value: SIM_OPTIONS.ESIM,
        label: content.cardSettings.esimCardText,
        descriptionText: !isEsimOnly
          ? content.cardSettings.esimCardDescriptionText
          : content.cardSettings.esimCardOnlyDescriptionText,
        tooltipDescriptionText: content.cardSettings.esimCardTooltipArea,
        tooltipTriggerText: content.cardSettings.esimCardTooltipText
      },
      {
        value: SIM_OPTIONS.SHIP_SIM,
        label: content.cardSettings.physicalSimCardText,
        descriptionText: !isPhysicalSimOnly
          ? content.cardSettings.simCardDescriptionText
          : content.cardSettings.simCardOnlyDescriptionText
      },
      {
        value: SIM_OPTIONS.NEW_SIM,
        label: content.cardSettings.immediateSimCardText,
        descriptionText: content.cardSettings.immediateSimCardDescriptionText
      },
      {
        value: SIM_OPTIONS.EXISTING_SIM,
        label: content.cardSettings.existingSimCardText
      }
    ],
    newSimOptionValue: SIM_OPTIONS.NEW_SIM,
    placeholderLabel: content.simCardNumberPlaceholderRequired
  };
};
