var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-checkbox", class: _vm.modifiers }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.computedLocalChecked,
          expression: "computedLocalChecked"
        }
      ],
      ref: "input",
      staticClass: "c-checkbox__input",
      attrs: {
        id: _vm.id,
        type: "checkbox",
        name: _vm.name,
        disabled: _vm.disabled
      },
      domProps: {
        checked: Array.isArray(_vm.computedLocalChecked)
          ? _vm._i(_vm.computedLocalChecked, null) > -1
          : _vm.computedLocalChecked
      },
      on: {
        click: function($event) {
          return _vm.emitID($event)
        },
        change: [
          function($event) {
            var $$a = _vm.computedLocalChecked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.computedLocalChecked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.computedLocalChecked = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.computedLocalChecked = $$c
            }
          },
          _vm.emitState
        ]
      }
    }),
    _vm._v(" "),
    !_vm.computedLocalChecked
      ? _c("input", {
          attrs: { type: "hidden", name: _vm.name, value: "false" }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "c-checkbox__label",
        class: _vm.labelModifiers,
        attrs: { for: _vm.id }
      },
      [
        _c(
          "span",
          {
            staticClass: "c-checkbox__label-slot-wrapper",
            class: {
              "sr-only": _vm.boxOnly,
              "c-checkbox__label-disabled": !_vm.isChecked
            }
          },
          [
            _vm.largeCheckbox
              ? _c("div", { staticClass: "c-checkbox__inner-wrapper" }, [
                  _c("span", {
                    staticClass: "c-checkbox__header",
                    domProps: { innerHTML: _vm._s(_vm.header) }
                  }),
                  _vm._v(" "),
                  _vm.description
                    ? _c("span", {
                        staticClass: "c-checkbox__description",
                        domProps: { innerHTML: _vm._s(_vm.description) }
                      })
                    : _vm._e()
                ])
              : _vm._t("default")
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }