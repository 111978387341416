<template>
  <article
    :id="[product.code !== numberConfigActive ? '' : 'focusEl']"
    class="cm-numcon__product"
    :class="[
      !product.configComplete && product.code !== numberConfigActive
        ? 'cm-numcon__confignotcompelete'
        : ''
    ]"
    @click="setActive(product.code, product.configComplete)"
  >
    <button
      v-if="
        product.phoneNumber &&
        configType === -1 &&
        product.configComplete &&
        product.configEdited
      "
      type="button"
      class="cm-numcon__header--close"
      @click="changeConfigClose(product.code)"
    >
      {{ numberConfigVM.configurationHeadline }}
    </button>

    <slot name="number-product-trigger">
      <button
        type="button"
        class="cm-numcon__header--toggle-open"
        @click="changeConfig(product.code)"
      >
        <c-icon symbol-id="arrow-down"></c-icon>
      </button>
    </slot>

    <!-- TODO: good candidate for a component that should be shared between overivew, move, new & existing number components -->
    <div class="cm-numcon__product__info">
      <img
        :src="product.img"
        class="cm-numcon__image"
        :alt="product.subscriptionName"
      />
      <section class="cm-numcon__product__text">
        <p
          v-if="numberConfigVM.subscriptionLabel"
          class="cm-numcon__product__pre-label"
        >
          {{ numberConfigVM.subscriptionLabel }}
        </p>

        <h2
          v-if="product.subscriptionName"
          class="
            cm-numcon__product__header cm-numcon__product__header--specific
          "
        >
          {{ product.subscriptionName }}
        </h2>
        <div
          v-if="product.phoneNumber && configType === -1"
          class="cm-numcon__product__header--configured"
        >
          <span>{{ numberConfigText(product.configType) }}</span>
          <span>({{ showNewNumber(product.phoneNumber) }})</span
          ><span>{{
            product.hiddenNumber ? `, ${numberConfigVM.secretNumberLabel}` : ''
          }}</span
          ><span>{{
            product.unlistedNumber
              ? `, ${numberConfigVM.unlistedNumberLabel}`
              : ''
          }}</span>
          <button
            v-if="
              !product.isReadOnly &&
              product.isConfigurable &&
              product.configComplete &&
              !product.configEdited
            "
            type="button"
            class="c-btn c-btn--sm c-btn--as-link cm-numcon__choose"
            @click="changeConfig(product.code)"
          >
            {{ numberConfigVM.configurationChangeLabel }}
          </button>
        </div>
        <div v-if="productMeta" class="cm-numcon__product__header">
          {{ productMeta }}
        </div>
      </section>
    </div>
    <div
      v-if="
        product.phoneNumber &&
        configType === -1 &&
        product.isExistingNumberAndCprMismatch
      "
      class="cm-numcon__product__error"
    >
      {{ numberConfigVM.errorMessageMismatchCprAndMsisdn }}
    </div>
  </article>
</template>
<script>
import numberMixin from '../../mixins/number-mixin.js';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../store/basket';

export default {
  name: 'NumberProduct',

  mixins: [numberMixin],

  props: {
    configType: {
      type: Number,
      default: -1
    },
    product: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'numberConfigActive',
      'numberConfigText',
      'numberConfigVM',
      'numberConfigItems'
    ]),

    productMeta() {
      const handset = this.product.handsetName;
      const vases = this.product.vases;

      if (vases) {
        if (handset) {
          return vases.length > 0 ? `${handset}, ${vases.join(', ')}` : handset;
        }

        return vases.join(', ');
      }

      return handset;
    }
  },

  methods: {
    ...mapMutations([
      'SET_NUMCONF_ACTIVE',
      'SET_NUMCONF_COMPLETE',
      'SET_NUMCONF_ACTIVE_ClOSE',
      'SET_NUMCONF_DATA',
      'SHOW_SPINNER',
      'HIDE_SPINNER',
      'SHOW_DIALOG'
    ]),

    ...mapActions({
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET
    }),

    changeConfig(code) {
      this.SET_NUMCONF_COMPLETE({ code, edited: true });
      this.SET_NUMCONF_ACTIVE(code);
      setTimeout(() => {
        this.$scrollTo('#focusEl');
      }, 300);
    },

    async removeItem() {
      this.SHOW_SPINNER();

      const payload = {
        code: this.product.subscriptionCode,
        groupId: this.product.groupId,
        lineItemId: this.product.lineItemId
      };

      /**
       * Checks that after a product removal if the basket is empty,
       * returning an action that can be called (sync or async).
       *
       * If all items are removed, perform a page reload
       * so the back-end business logic can kick in (redirect, etc).
       * History: each basket removal action used to reload the page,
       * the UI was not responsible for making API calls.
       *
       * Proper fix: modify api so redirects can be handled by the UI,
       * based on api responses.
       */
      const checkForEmptyBasketOnProductRemove = () => {
        const filteredConfigItems = this.numberConfigItems.filter(
          item => item.code !== this.product.code
        );

        return {
          remainingItems: filteredConfigItems,
          isEmpty: filteredConfigItems.length === 0,
          action: () => {
            window.location.reload();
          }
        };
      };

      try {
        await this.removeFromBasket(payload);

        const { remainingItems, isEmpty, action } =
          checkForEmptyBasketOnProductRemove();

        // After removing the item from basket,
        // update numberConfig data to reflect the change,
        // setting the active number to the 1st if
        // the removed item happened to be currently active
        this.SET_NUMCONF_DATA({
          data: remainingItems,
          setFirstItemActive: this.numberConfigActive === this.product.code
        });

        if (isEmpty) {
          action();
        }
      } catch (error) {
        if (error.code === 409) {
          // Shows a conflict modal if the BE
          // Returns a 409. Users can resolve the conflict
          // or go back (handled inside the modal component).
          this.SHOW_DIALOG({
            componentName: 'prevent-accessory-financing-dialog',
            componentProps: {
              contentObject: error.errors[0].detail,
              onAccept: () => {
                const { isEmpty, action } =
                  checkForEmptyBasketOnProductRemove();

                if (isEmpty) {
                  action();
                }
              },
              payload
            }
          });
        }

        // TODO DKT-3794: handle other exceptions?
      }

      this.HIDE_SPINNER();
    },

    changeConfigClose(code) {
      this.SET_NUMCONF_ACTIVE_ClOSE(code);

      if (this.$router.currentRoute.path !== '/') {
        this.$router.push({ path: '/' });
      }
    },

    setActive(code, configComplete) {
      if (code === this.numberConfigActive || configComplete) {
        return;
      }

      this.SET_NUMCONF_ACTIVE(code);
      setTimeout(() => {
        this.$scrollTo('#focusEl');
      }, 300);
    },

    showNewNumber(number) {
      /**
       * If number equals "noNumberPoolNumber" (numberppol down or
       * similar), show label, otherwise return number.
       */
      return number === this.numberConfigVM.noNumberPoolNumber
        ? this.numberConfigVM.selectedNumberWhenNoNumberPool
        : number.length > 8
        ? this.$globals.formatNumber(number)
        : this.formatNumberByDigits(number);
    }
  }
};
</script>
