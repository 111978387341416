/**
 * Use this mixin to prompt and get users location in the browser
 */
export default {
  methods: {
    /**
     * @function getUserLocation
     * @description Prompts for geolocation access in the client, and handles the callback from the geolocator
     * For local testing in Chrome: Requests from insecure urls (http) are blocked in Chrome. To fix this, go to:
     * chrome://flags/ and paste "Insecure origins treated as secure" and add your local url to the input field:
     * "http://localhost.norlys.dk,http://www.localhost.norlys.dk" and change feature to "Enabled" and restart browser.
     */
    async getUserLocation() {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation not available in the client.'));
        }

        navigator.geolocation.getCurrentPosition(
          position => {
            resolve(position);
          },
          error => {
            reject(error);
          },
          {
            enableHighAccuracy: true,
            timeout: Infinity,
            maximumAge: 0
          }
        );
      });
    },

    getDistanceBetweenToPoints(positionLat, positionLng, targetLat, targetLng) {
      const earthRadius = 6371;
      // Convert latitude and longitude to radians
      const userLatRadians = this.toRadians(positionLat);
      const userLngRadians = this.toRadians(positionLng);
      const storeLatRadians = this.toRadians(targetLat);
      const storeLngRadians = this.toRadians(targetLng);

      // Calculate the differences in latitude and longitude
      const dLat = userLatRadians - storeLatRadians;
      const dLng = userLngRadians - storeLngRadians;

      // Calculate the square of half the chord length between the points
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(storeLatRadians) *
          Math.cos(userLatRadians) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);

      // Calculate the angular distance in radians
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      // Calculate the actual distance in kilometers
      const distance = earthRadius * c;

      // Return the distance rounded to 2 decimal places
      return Number(distance.toFixed(2));
    }
  }
};
