<template>
  <section class="attribute-selector" :class="classObject">
    <h3 v-if="title" class="attribute-selector__title">
      {{ title }}
      <popover-panel
        v-if="tooltipText"
        class="attribute-selector__tooltip"
        :description="tooltipText"
        :popover-offset-x="-90"
      >
        <c-icon
          class="attribute-selector__tooltip__icon"
          symbol-id="service-information"
        />
      </popover-panel>
    </h3>

    <!-- @slot holds a description shown just below the title -->
    <slot name="attribute-description"></slot>

    <div class="attribute-selector__items">
      <ul :class="listModifiers">
        <li
          v-for="attribute in attributes"
          :key="attribute.code"
          :class="
            disabledAttributeNames.indexOf(attribute.name) !== -1
              ? 'last'
              : 'first'
          "
        >
          <component
            :is="!linkCloud ? 'button' : 'a'"
            :type="!linkCloud ? 'button' : null"
            :href="!linkCloud ? null : attribute.url"
            :disabled="disabledAttributeNames.indexOf(attribute.name) !== -1"
            @click="attributeSelected(attribute)"
          >
            <!-- @slot Dynamic slot that can be overriden with arbitrary markup for the button attribute content -->
            <slot :name="`button-${attribute.code}`">
              <div
                class="attribute-selector__item"
                :class="{
                  'attribute-selector__item--selected':
                    attribute.name === selectedAttributeName,
                  'attribute-selector__item--link-cloud': !!linkCloud
                }"
              >
                <!-- @slot Dynamic slot that can be overriden with arbitrary markup for the text inside the button -->
                <slot :name="`text-${attribute.code}`">
                  {{ attribute.name }}
                </slot>
              </div>
            </slot>
          </component>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';

/**
 * Generic component that allows selecting a given product attribute, i.e:
 * - color
 * - storage
 * - installment length.
 */
export default {
  name: 'AttributeSelector',

  components: {
    PopoverPanel
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    attributes: {
      type: Array,
      default: () => []
    },

    selectedAttributeName: {
      type: [String, Number],
      default: undefined
    },

    disabledAttributeNames: {
      type: Array,
      default: () => []
    },

    stacked: {
      type: Boolean,
      default: false
    },

    linkCloud: {
      type: Boolean,
      default: false
    },

    tooltipText: {
      type: String,
      default: ''
    }
  },

  computed: {
    classObject() {
      return {
        'attribute-selector--stacked': this.stacked,
        'attribute-selector--link-cloud': this.linkCloud
      };
    },
    listModifiers() {
      return {
        disabled: this.disabledAttributeNames.length
      };
    }
  },

  methods: {
    attributeSelected(attribute) {
      /**
       * Triggers when an attribute is selected,
       * passing on the new attribute.
       */
      this.$emit('attribute-selected', attribute);
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$list-margin: $u-350;
$attribute-height: 40px;
$tooltip-icon-size: 24px;

.attribute-selector {
  // .attribute-selector__tooltip
  &__tooltip {
    display: flex;
    align-items: center;

    // .attribute-selector__tooltip__icon
    &__icon {
      max-height: $tooltip-icon-size;
      max-width: $tooltip-icon-size;
      line-height: $tooltip-icon-size;
      fill: $c-pm-500;
    }
  }

  h3 {
    display: flex;
    color: $c-nt-900;
    font-size: $u-300;
    font-weight: 700;
    padding-top: $u-500;
    margin-bottom: $u-250;

    @include mq($screen-md, min) {
      font-size: $font-size-h5;
    }
  }

  // attribute-selector__items
  &__items {
    padding: 0 $u-350;
    margin-left: -$u-350;
    margin-right: -$u-350;
    overflow-x: auto;

    button {
      border: 0;
      background: 0;
      padding: 0;
      margin: 0;
      -webkit-tap-highlight-color: transparent;

      &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        color: $c-nt-400;
      }

      &:focus {
        outline: 0;

        .attribute-selector__item {
          &:not(.attribute-selector__item--selected) {
            border-style: dashed; // a11y: present a slight highlight of the border on
            border-color: $c-nt-300;
            transition: 0.3s border;
            transition-delay: 0.3s;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;

      @include mq($screen-md, max) {
        flex-wrap: nowrap; // On mobile, make it seem like the content flows edge to edge while horizontal scrolling.
        overflow-x: auto;
        scrollbar-width: none;
        margin: 0 $list-margin * -1;
        padding: 0 $list-margin;
        width: calc(100% + #{$list-margin * 2});

        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          background: transparent;
        }
      }

      li {
        flex-shrink: 0;
        margin-bottom: $u-200;

        &:after {
          content: '';
          display: inline-block;
          margin-right: $u-250; // margin right is defined as a pseudo element to correctly calculate scroll width when content is off-screen
        }
      }

      &.disabled {
        > .first {
          order: 0;
        }

        > .last {
          order: 1;
        }
      }
    }
  }

  // .attribute-selector__item
  &__item {
    display: flex;
    align-items: center;
    background-color: $color-white;
    height: $attribute-height;
    padding: $u-200;
    border: 1px solid $c-nt-400;
    margin: 1px; // selected border is 2px, the margin is used to maintain layout flow
    border-radius: 8px;
    font-size: $font-size-h5;
    font-weight: bold;
    line-height: 1em;
    -webkit-tap-highlight-color: transparent;

    @include mq($screen-md, min) {
      padding: $u-350/2 $u-250;
    }

    // .attribute-selector__item--selected
    &--selected {
      margin: 0; // margin has to be removed in order to increase border sizes without changing the layout flow
      border: 2px solid $c-pm-500;
    }
  }

  // .attribute-selector--stacked
  &--stacked {
    ul {
      flex-direction: column;

      li {
        width: 100%;

        &:after {
          display: none;
        }

        button {
          width: 100%;
        }
      }
    }
  }

  &--link-cloud {
    h3 {
      margin-bottom: $u-300;
      font-family: $global-font-family-2;
      text-align: center;
      font-weight: normal;
      display: block;
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0;

      @include mq($screen-md, min) {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }
    }

    ul {
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin-bottom: $u-250;

        .attribute-selector__item {
          border: 0;
        }

        a {
          background: white;
          color: $c-pm-500;
          border-radius: $u-200;
          border: 0;
          display: inline-block;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
