import { render, staticRenderFns } from "./c-compare-specification-list-row.vue?vue&type=template&id=bbb481ce&"
import script from "./c-compare-specification-list-row.vue?vue&type=script&lang=js&"
export * from "./c-compare-specification-list-row.vue?vue&type=script&lang=js&"
import style0 from "./c-compare-specification-list-row.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bbb481ce')) {
      api.createRecord('bbb481ce', component.options)
    } else {
      api.reload('bbb481ce', component.options)
    }
    module.hot.accept("./c-compare-specification-list-row.vue?vue&type=template&id=bbb481ce&", function () {
      api.rerender('bbb481ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/webshop/shared/components/catalog/c-compare/c-compare-specification-list-row.vue"
export default component.exports