<template>
  <div class="cm-c-modal__content">
    <div class="cm-c-modal__article cm-text-center">
      <c-heading slot="header" level="6" as="4">
        {{ contentObject.heading }}
      </c-heading>

      <div v-html="contentObject.bodyText"></div>
    </div>

    <div class="cm-c-modal__footer cm-c-modal__footer--with-buttons">
      <button type="button" class="c-btn c-btn--primary" @click="accept">
        {{ contentObject.acceptButtonLabel }}
      </button>

      <button type="button" class="c-btn c-btn--as-link" @click="hideDialog">
        {{ contentObject.cancelButtonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import { CHeading } from 'olympus/components';
import { mapMutations, mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';

export default {
  name: 'PreventAccessoryFinancingDialog',

  components: {
    CHeading
  },

  props: {
    contentObject: {
      type: Object,
      default: () => ({})
    },
    payload: {
      type: Object,
      default: () => ({})
    },
    onAccept: {
      type: Function,
      default: undefined
    }
  },
  methods: {
    ...mapActions({
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET
    }),

    ...mapMutations({
      hideDialog: 'HIDE_DIALOG'
    }),

    accept() {
      this.removeFromBasket({
        ...this.payload,
        acceptFinancingConflict: true
      }).then(() => {
        this.hideDialog();

        if (this.onAccept) {
          return this.onAccept();
        }
      });
    }
  }
};
</script>
