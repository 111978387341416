var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.splashText
    ? _c("div", { staticClass: "cm-c-splash", class: _vm.classList }, [
        _c("span", {
          staticClass: "cm-c-splash__wrap",
          domProps: { innerHTML: _vm._s(_vm.splashText) }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }