<template>
  <!-- This component will be deprecated and swapped out with a new radio-button -->
  <section :class="userCardClasses">
    <article class="user-card__content">
      <figure>
        <!-- @slot Optional user image to be displayed on the left. -->
        <slot name="user-image">
          <c-icon v-if="!disabled" :symbol-id="enabledIcon"></c-icon>
          <c-icon v-else :symbol-id="disabledIcon"></c-icon>
        </slot>

        <figcaption>
          <p>
            <strong>
              {{ phoneNumber }}
              <span v-if="userName">({{ userName }})</span>
            </strong>
          </p>
          <p>{{ subscriptionName }}</p>
        </figcaption>
      </figure>

      <!-- @slot Optional right action. -->
      <slot name="right-action">
        <button
          type="button"
          class="c-btn c-btn--sm"
          :class="{ 'c-btn--disabled': disabled }"
          @click="userRightActionPressed"
        >
          {{ disabled ? rightActionDisabledButtonText : rightActionButtonText }}
        </button>
      </slot>
    </article>

    <div class="user-card__description">
      <!-- @slot An optional description markup can be passed as the default slot. -->
      <slot></slot>
    </div>
  </section>
</template>

<script>
import CIcon from 'webshop/components/c-icon/c-icon.vue';

/**
 * A generic user card that optionally displays:
 *  - image/icon
 *  - phone
 *  - name
 *  - description
 *  - subscription
 *  - right action slot
 */
export default {
  name: 'UserCard',
  components: { CIcon },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    userName: {
      type: String,
      default: undefined
    },
    phoneNumber: {
      type: [String, Number],
      default: undefined
    },
    subscriptionName: {
      type: String,
      default: undefined
    },
    rightActionButtonText: {
      type: String,
      default: undefined
    },
    rightActionDisabledButtonText: {
      type: String,
      default: undefined
    },
    enabledIcon: {
      type: String,
      default: 'menu-user'
    },
    disabledIcon: {
      type: String,
      default: 'service-warning'
    }
  },

  computed: {
    userCardClasses() {
      return {
        'user-card': true,
        'user-card--selected': this.selected,
        'user-card--disabled': this.disabled
      };
    }
  },

  methods: {
    userRightActionPressed() {
      this.$emit('rightActionPressed', {
        phoneNumber: this.phoneNumber,
        subscriptionName: this.subscriptionName
      });
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

$icon-size: 30px;
$icon-margin: $u-300;

.user-card {
  border: 2px solid $c-nt-300;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $u-250;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $u-350 $u-300 $u-300 $u-350;

    figure {
      display: flex;
      align-items: center;

      svg {
        width: $icon-size;
        height: $icon-size;
        margin-right: $icon-margin;
      }
    }

    button {
      flex-shrink: 0;
    }
  }

  &__description {
    * {
      font-size: $font-size-h5;
    }

    &:not(:empty) {
      padding: 0 $icon-size + $icon-margin * 2;
      margin-bottom: $u-300;
    }

    a {
      color: $c-pm-600;
    }
  }

  &--selected {
    border-color: $c-pm-300;
  }

  &--disabled {
    p {
      color: $c-nt-400;
    }

    svg {
      fill: $c-nt-400;
    }

    .user-card__description {
      color: $color-black;
    }
  }
}
</style>
