var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-modal__content" }, [
    _c("div", { staticClass: "cm-c-modal__article" }, [
      _c("h3", { domProps: { innerHTML: _vm._s(_vm.contentObject.headline) } }),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.contentObject.description) }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cm-c-form" }, [
      _c(
        "fieldset",
        [
          _vm._l(_vm.inputs, function(input, inputindex) {
            return _c(
              "div",
              {
                key: inputindex,
                staticClass: "cm-c-form__row",
                class: { hidden: input.type == "hidden" }
              },
              [
                _c("div", { staticClass: "cm-c-form-control-group" }, [
                  input.type === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.inputs[inputindex].value,
                            expression: "inputs[inputindex].value"
                          }
                        ],
                        staticClass: "cm-c-form-control cm-js-material-input",
                        attrs: {
                          required: "",
                          name: input.name,
                          autocomplete: "new-password",
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.inputs[inputindex].value)
                            ? _vm._i(_vm.inputs[inputindex].value, null) > -1
                            : _vm.inputs[inputindex].value
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.inputs[inputindex].value,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.inputs[inputindex],
                                    "value",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.inputs[inputindex],
                                    "value",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.inputs[inputindex], "value", $$c)
                            }
                          }
                        }
                      })
                    : input.type === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.inputs[inputindex].value,
                            expression: "inputs[inputindex].value"
                          }
                        ],
                        staticClass: "cm-c-form-control cm-js-material-input",
                        attrs: {
                          required: "",
                          name: input.name,
                          autocomplete: "new-password",
                          type: "radio"
                        },
                        domProps: {
                          checked: _vm._q(_vm.inputs[inputindex].value, null)
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.inputs[inputindex],
                              "value",
                              null
                            )
                          }
                        }
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.inputs[inputindex].value,
                            expression: "inputs[inputindex].value"
                          }
                        ],
                        staticClass: "cm-c-form-control cm-js-material-input",
                        attrs: {
                          required: "",
                          name: input.name,
                          autocomplete: "new-password",
                          type: input.type
                        },
                        domProps: { value: _vm.inputs[inputindex].value },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.inputs[inputindex],
                              "value",
                              $event.target.value
                            )
                          }
                        }
                      }),
                  _vm._v(" "),
                  _c("span", { staticClass: "cm-c-form-control__bar" }),
                  _vm._v(" "),
                  _c("label", {
                    staticClass: "cm-c-form-control__label",
                    domProps: { innerHTML: _vm._s(input.label) }
                  })
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "cm-c-form__row" }, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, index) {
                return _c("li", {
                  key: index,
                  staticClass: "cm-c-checkout__link",
                  domProps: { innerHTML: _vm._s(error) }
                })
              }),
              0
            )
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cm-c-form__row cm-c-form__row--footer cm-text-right" },
      [
        _c("button", {
          staticClass: "c-btn c-btn--primary c-btn--sm c-btn--inline",
          attrs: { disabled: !_vm.isValid },
          domProps: { innerHTML: _vm._s(_vm.contentObject.buttonLabel) },
          on: {
            click: function($event) {
              return _vm.accept()
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }