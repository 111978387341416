var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "text-bold", attrs: { cols: "6", sm: "12" } },
            [_c("strong", [_vm._v(_vm._s(_vm.content.orderReferenceLabel))])]
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "text-right", attrs: { cols: "6", sm: "12" } },
            [_vm._v("\n      " + _vm._s(_vm.orderData.orderId) + "\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "text-bold", attrs: { cols: "6", sm: "12" } },
            [_c("strong", [_vm._v(_vm._s(_vm.content.activationDateLabel))])]
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "text-right", attrs: { cols: "6", sm: "12" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.formatDate(_vm.orderData.orderDate)) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.content.shippingInfoLabelRouter
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "text-bold", attrs: { cols: "6", sm: "12" } },
                [_c("strong", [_vm._v(_vm._s(_vm.content.shippingInfoLabel))])]
              ),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-right", attrs: { cols: "6", sm: "12" } },
                [
                  _vm.orderData.shippingInformation &&
                  _vm.orderData.shippingInformation.companyName
                    ? _c("p", [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.orderData.shippingInformation.companyName
                            ) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.firstName + " " + _vm.lastName) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatStreet(_vm.shippingAddress)) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatCity(_vm.shippingAddress)) +
                        "\n      "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isShippingAddressAvailable && _vm.content.shippingInfoLabelBroadband
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "text-bold", attrs: { cols: "6", sm: "12" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.content.shippingInfoLabelBroadband))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-right", attrs: { cols: "6", sm: "12" } },
                [
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.installationAddressFirstName +
                            " " +
                            _vm.installationAddressLastName
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatStreet(_vm.businessAddress)) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatCity(_vm.businessAddress)) +
                        "\n      "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }