var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cm-c-checkout__termbox__one-consent-wrapper",
      class: _vm.wrapperModifiers
    },
    [
      _c(
        "div",
        {
          staticClass:
            "cm-c-checkout__termbox cm-c-checkout__module--no-padding",
          class: _vm.moduleModifiers
        },
        [
          _vm.content.headline
            ? _c("h3", {
                staticClass: "cm-c-checkout__termbox__headline",
                domProps: { innerHTML: _vm._s(_vm.content.headline) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("c-checkbox", {
            attrs: {
              id: "checkout-one-consent",
              name: "checkout-one-consent",
              "large-checkbox": "",
              header: _vm.content.consentCheckboxHeader,
              description: _vm.content.consentAccepted
            },
            on: { "event-id": _vm.updatedConsentStatus }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }