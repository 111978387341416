/* 
TODO DKT-10787: due to using different components for the same data (baket items)
we are forced to handle the item display name and title per brand,
per component. We need to fix this and align mini-basket with basket.
*/

export const miniBasketItemMixin = {
  computed: {
    name() {
      const nameMap = {
        [this.BASKET_ITEM_TYPES.HARDWARE]: this.item.name.variant,
        [this.BASKET_ITEM_TYPES.ACCESSORY]: this.item.name.variant
      };
      return nameMap[this.item.type] || this.item.name.display;
    },

    title() {
      const titleMap = {
        [this.BASKET_ITEM_SUB_TYPES.VOICE]: this.content.subscriptionVoiceLabel,
        [this.BASKET_ITEM_SUB_TYPES.DATA]: this.content.subscriptionDataLabel,
        [this.BASKET_ITEM_SUB_TYPES.EXTRA_DATA_CARD]:
          this.content.subscriptionExtraDataCardLabel,
        [this.BASKET_ITEM_SUB_TYPES.BROADBAND]:
          this.content.subscriptionBroadbandLabel
      };

      return titleMap[this.item.subType] || this.item.brand;
    }
  }
};

export default {
  computed: {
    items() {
      const getName = item => {
        const nameMap = {
          [this.BASKET_ITEM_TYPES.HARDWARE]: item.name.variant,
          [this.BASKET_ITEM_TYPES.ACCESSORY]: item.name.variant
        };
        return nameMap[item.type] || item.name.display;
      };

      const getTitle = item => {
        const titleMap = {
          [this.BASKET_ITEM_SUB_TYPES.VOICE]:
            this.content.subscriptionVoiceLabel,
          [this.BASKET_ITEM_SUB_TYPES.DATA]: this.content.subscriptionDataLabel,
          [this.BASKET_ITEM_SUB_TYPES.EXTRA_DATA_CARD]:
            this.content.subscriptionExtraDataCardLabel,
          [this.BASKET_ITEM_SUB_TYPES.BROADBAND]:
            this.content.subscriptionBroadbandLabel
        };

        return titleMap[item.subType] || item.brand;
      };

      return this.group.items.reduce((arr, x, idx) => {
        const primary = idx === 0;
        arr.push({
          ...x,
          title: getTitle(x),
          name: getName(x),
          primary,
          bundled:
            x.subType === this.BASKET_ITEM_TYPES.SUBSCRIPTION &&
            !!this.group.items.length,
          isOffering: false,
          action: this.BASKET_ITEM_ACTIONS.REMOVE
        });

        return arr;
      }, []);
    }
  }
};
