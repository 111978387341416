import { createNamespace } from '../../utils.js';
import { safeWrite, safeRead, safeDelete } from 'olympus/utils/storage.js';

export const GETTERS = createNamespace('NUMBER-CONFIG/GETTERS', [
  'GET_NUMBERS_TO_SHOW',
  'GET_NUMBERPOOL',
  'GET_NUMBERPOOL_LIFESPAN',
  'GET_NUMBERPOOL_EDITED_TIME',
  'GET_NUMBERPOOL_SIZE'
]);

export const MUTATIONS = createNamespace('NUMBER-CONFIG/MUTATIONS', [
  'SET_NUMBERPOOL',
  'CLEAR_NUMBERPOOL',
  'SET_NUMBERPOOL_EDITED_TIME'
]);

export const ACTIONS = createNamespace('NUMBER-CONFIG/ACTIONS', [
  'SET_NUMBERPOOL_AND_EDITED_TIME',
  'CHECK_NUMBERPOOL_IN_TIMESPAN'
]);

export default {
  state: function () {
    let numberPoolParsed = [];
    const numberPollEditedTimeParsed = safeRead(
      'numberPoolEdited',
      new Date(),
      localStorage
    );

    // Due to the product configuration refactoring, the numberpool now has a different structure in localstorage
    // Therefore we need to check whether the json-parsing crashes (in case it's the old structure) and then delete it.
    try {
      numberPoolParsed = JSON.parse(
        safeRead('numberPool', JSON.stringify([]), localStorage)
      );
    } catch {
      safeDelete('numberPool', localStorage);
      numberPoolParsed = [];
    }

    return {
      numberPoolSize: 18, // Double the shown numbers size to have pool of similar numbers
      numberPoolLifeSpan: 3600, // TODO Backend: This info is currently coming from CMS and should probably be delivered when fetching products on first load.
      numberPool: numberPoolParsed,
      numberPoolEditedTime: numberPollEditedTimeParsed
    };
  },

  getters: {
    [GETTERS.GET_NUMBERPOOL_LIFESPAN](state) {
      return state.numberPoolLifeSpan;
    },

    [GETTERS.GET_NUMBERPOOL_EDITED_TIME](state) {
      return state.numberPoolEditedTime;
    },

    [GETTERS.GET_NUMBERPOOL_SIZE](state) {
      return state.numberPoolSize;
    },

    /*
     * We are fetching twice the numbers we need to show, hence only half should be shown
     * The reason we are fetching twice the numbers we are showing:
     * The numbers are 'grouped together', and when a customer wants to configure multiple numbers
     * then we can show 9 numbers on 2nd, 3rd, etc. project that are in the same group as the first one
     */
    [GETTERS.GET_NUMBERS_TO_SHOW](state, getters) {
      const numbersToShowSize = state.numberPoolSize / 2;
      const numberPool = getters[GETTERS.GET_NUMBERPOOL];
      return numberPool.length > numbersToShowSize
        ? numberPool.slice(0, numbersToShowSize)
        : numberPool;
    },

    [GETTERS.GET_NUMBERPOOL](state) {
      return state.numberPool;
    }
  },

  mutations: {
    [MUTATIONS.SET_NUMBERPOOL](state, numbers) {
      safeWrite('numberPool', JSON.stringify(numbers), localStorage);
      state.numberPool = numbers;
    },

    [MUTATIONS.SET_NUMBERPOOL_EDITED_TIME](state) {
      const timeNow = new Date();
      safeWrite('numberPoolEdited', timeNow, localStorage);
      state.numberPoolEditedTime = timeNow;
    },

    [MUTATIONS.CLEAR_NUMBERPOOL](state) {
      safeDelete('numberPool', localStorage);
      state.numberPool = [];
    }
  },

  actions: {
    [ACTIONS.SET_NUMBERPOOL_AND_EDITED_TIME]({ commit }, numbers) {
      commit(MUTATIONS.SET_NUMBERPOOL, numbers);
      commit(MUTATIONS.SET_NUMBERPOOL_EDITED_TIME);
    },

    [ACTIONS.CHECK_NUMBERPOOL_IN_TIMESPAN]({ commit, getters }) {
      const latestUpdateMilliseconds = Math.abs(
        new Date(getters[GETTERS.GET_NUMBERPOOL_EDITED_TIME]) - new Date()
      );
      const validNumberpool =
        latestUpdateMilliseconds / 1000 <
        getters[GETTERS.GET_NUMBERPOOL_LIFESPAN];

      if (!validNumberpool) {
        commit(MUTATIONS.CLEAR_NUMBERPOOL);
      }

      return getters[GETTERS.GET_NUMBERPOOL];
    }
  }
};
