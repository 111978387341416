import Vue from 'vue';

import { DESKTOP } from '../../../shared/utils/media-queries.js';

export const GlobalObject = {
  eventBus: new Vue(),
  isDesktop: function() {
    return window.matchMedia(DESKTOP).matches;
  },
  formatNumber: function(number) {
    return number.slice(-8);
  },
  buildStringRegex: function(
    acceptDigits = true,
    acceptSpecialChars = true,
    acceptLetters = true,
    lengthArr = ['', '']
  ) {
    const digits = acceptDigits ? '0-9' : '';
    const specialChars = acceptSpecialChars ? '!@#$%^&* ' : '';
    const letters = acceptLetters ? 'a-zA-Z' : '';

    const length =
      lengthArr.length == 1
        ? `{${lengthArr[0]}}`
        : `{${lengthArr[0]},${lengthArr[1]}}`;

    return new RegExp(`^[${digits}${specialChars.trim()}${letters}]${length}$`);
  },
  pushToDataLayer: function(obj) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
};
