/* eslint-disable */ // Todo: Remove this line later
import { createNamespace } from '../utils.js';
import { get, post } from '../../api/api.js';
import { MUTATIONS as CHECKOUT_MUTATIONS } from 'webshop/components/checkout/store';
import { isNullOrUndefined } from 'olympus/utils/inspect.js';

import { MUTATIONS as COMMON_MUTATIONS } from '../shop/mutation-types.js';

export const MUTATIONS = createNamespace('HOME_OFFICE/MUTATIONS', [
  'SET_IS_LOADING',
  'SET_EMPLOYEE_DATA',
  'SET_ORDER_DATA',
  'SET_SELECTED_OFFERING',
  'SET_OFFERINGS',
  'SET_VARIANT_CODES',
  'SET_VARIANTS',
  'SET_CARD_DATA',
  'SHIFT_SELECTED_OFFERING',
  'SET_SHOW_CHECKOUT_ERROR'
]);

export const ACTIONS = createNamespace('HOME_OFFICE/ACTIONS', [
  'FETCH_EMPLOYEE_DATA',
  'FETCH_OFFERINGS',
  'FETCH_VARIANTS',
  'FETCH_RECEIPT_DATA'
]);

export const GETTERS = createNamespace('HOME_OFFICE/GETTERS', [
  'GET_EMPLOYEE_DATA',
  'GET_ORDER_DATA',
  'GET_CARD_DATA',
  'GET_SELECTED_OFFERING',
  'GET_IS_LOADING',
  'GET_SHOW_CHECKOUT_ERROR',
  'GET_CHECKOUT_ERROR_MESSAGE'
]);

/**
 * Manages state for product configuration page.
 */
export default {
  state: {
    isLoading: false,
    networking: false,
    showCheckoutError: false,
    employeeData: {},
    orderData: {},
    selectedOffering: {},
    offerings: [],
    variants: [],
    variantCodes: [],
    cardData: []
  },

  mutations: {
    [MUTATIONS.SET_EMPLOYEE_DATA](state, employeeData) {
      // We need to convert null values to empty strings as we use the values for the input fields
      Object.entries(employeeData).forEach(function ([k, v]) {
        if (!v || !v.value) {
          return;
        }

        return (this[k].value = v.value === null ? '' : v.value);
      }, employeeData);

      state.employeeData = employeeData;
    },
    [MUTATIONS.SET_ORDER_DATA](state, orderData) {
      state.orderData = orderData;
    },
    [MUTATIONS.SET_SELECTED_OFFERING](state, selectedOffering) {
      state.selectedOffering = selectedOffering;
    },
    [MUTATIONS.SET_OFFERINGS](state, payload) {
      const persistingOfferings = payload.selectedTechnologyType
        ? state.offerings.filter(
            offering =>
              offering.technologyType !== payload.selectedTechnologyType
          )
        : payload.offerings;
      state.offerings = Object.assign(persistingOfferings, payload.offerings);
      this.commit(MUTATIONS.SET_VARIANT_CODES, payload.offerings);
    },
    [MUTATIONS.SET_VARIANTS](state, variants) {
      state.variants = variants;
    },
    [MUTATIONS.SET_VARIANT_CODES](state, offerings) {
      state.variantCodes = offerings.map(offering => offering.code);
    },
    [MUTATIONS.SET_CARD_DATA](state) {
      const cardData = JSON.parse(JSON.stringify(state.offerings));
      cardData.forEach((offering, index) => {
        cardData[index].variant = state.variants.find(
          variant => variant.code === offering.code
        );
      });
      state.cardData = cardData;
    },
    [MUTATIONS.SHIFT_SELECTED_OFFERING](state, selectedOfferingCode) {
      const selectedIndex = state.cardData.findIndex(
        card => card.code === selectedOfferingCode
      );
      state.cardData.unshift(state.cardData.splice(selectedIndex, 1)[0]);
    },
    [MUTATIONS.SET_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [MUTATIONS.SET_SHOW_CHECKOUT_ERROR](state, showCheckoutError) {
      state.showCheckoutError = showCheckoutError;
    }
  },

  actions: {
    [ACTIONS.FETCH_EMPLOYEE_DATA]: ({ commit }, orderId) => {
      // If orderId is set - The basket will be prepared
      // in the backend to work with the home office flow
      const options = {
        headers: {
          accept: 'application/vnd.api+json'
        }
      };

      const setOrderIdRequest = {
        data: {
          type: 'SetOrderIdRequest',
          attributes: {
            orderId: orderId
          }
        }
      };

      const request = orderId
        ? post('homeoffice/SetOrderId/', setOrderIdRequest, options)
        : get('homeoffice/', options);

      commit(COMMON_MUTATIONS.SHOW_SPINNER);
      return request.then(response => {
        if (response.data) {
          commit(
            MUTATIONS.SET_EMPLOYEE_DATA,
            response.data.data.attributes.employee
          );
          commit(MUTATIONS.SET_ORDER_DATA, response.data.data.attributes.order);
          commit(
            MUTATIONS.SET_SELECTED_OFFERING,
            response.data.data.attributes.offering
          );

          if (!isNullOrUndefined(response.data.data.attributes.order)) {
            const { shippingInformation } = response.data.data.attributes.order;

            if (!isNullOrUndefined(shippingInformation)) {
              const checkoutAltAdressFormData = {
                firstName: shippingInformation.firstName,
                lastName: shippingInformation.lastName,
                company: shippingInformation.companyName,
                addressId: shippingInformation.shippingAddress?.altAddressId,
                streetName: shippingInformation.shippingAddress?.streetName,
                houseNumber: shippingInformation.shippingAddress?.houseNumber,
                houseLetter: shippingInformation.shippingAddress?.houseLetter,
                floor: shippingInformation.shippingAddress?.floor,
                postalCode: shippingInformation.shippingAddress?.postalCode,
                city: shippingInformation.shippingAddress?.city
              };
              commit(
                CHECKOUT_MUTATIONS.SET_CHECKOUT_FORM_DATA,
                checkoutAltAdressFormData
              );
            }
          }

          commit(COMMON_MUTATIONS.HIDE_SPINNER);

          return Promise.resolve(response);
        }

        commit(COMMON_MUTATIONS.HIDE_SPINNER);
      });
    },
    [ACTIONS.FETCH_OFFERINGS]: ({ commit, dispatch }, payload) => {
      commit(MUTATIONS.SET_IS_LOADING, true);
      get(
        `offerings/broadband?addressId=${payload.addressId}${
          payload.selectedProductId
            ? `&selectedProductId=${payload.selectedProductId}`
            : ''
        }&orderId=${payload.orderId}`
      )
        .then(response => {
          if (!response.data.length) {
            commit(MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true);
            commit(MUTATIONS.SET_IS_LOADING, false);
            return;
          }
          commit(MUTATIONS.SET_OFFERINGS, {
            offerings: response.data,
            selectedTechnologyType: payload.selectedTechnologyType
          });
          dispatch(ACTIONS.FETCH_VARIANTS);
        })
        .catch(err => {
          commit(MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true);
          commit(MUTATIONS.SET_IS_LOADING, false);
          console.log(err);
        });
    },
    [ACTIONS.FETCH_VARIANTS]: ({ commit, state }) => {
      get(`catalog/variants?codes=${state.variantCodes}`)
        .then(response => {
          if (response.data) {
            commit(MUTATIONS.SET_VARIANTS, response.data);
            commit(MUTATIONS.SET_CARD_DATA);
          }
        })
        .catch(err => console.log(err))
        .finally(commit(MUTATIONS.SET_IS_LOADING, false));
    },
    [ACTIONS.FETCH_RECEIPT_DATA]: ({ commit }, orderId) => {
      get(`homeoffice/receipt/?orderId=${orderId}`)
        .then(response => {
          if (response.data) {
            commit(MUTATIONS.SET_ORDER_DATA, response.data.order);
            commit(MUTATIONS.SET_EMPLOYEE_DATA, response.data.employee);
          }
        })
        .catch(err => console.log(err))
        .finally(commit(MUTATIONS.SET_IS_LOADING, false));
    }
  },

  getters: {
    [GETTERS.GET_EMPLOYEE_DATA]: state => {
      return state.employeeData;
    },
    [GETTERS.GET_ORDER_DATA]: state => {
      return state.orderData;
    },
    [GETTERS.GET_SELECTED_OFFERING]: state => {
      return state.selectedOffering;
    },
    [GETTERS.GET_CARD_DATA]: state => {
      return state.cardData;
    },
    [GETTERS.GET_IS_LOADING]: state => {
      return state.isLoading;
    },
    [GETTERS.GET_SHOW_CHECKOUT_ERROR]: state => {
      return state.showCheckoutError;
    }
  }
};
