<template>
  <v-select
    :value="selectedLocal"
    class="c-dropdown"
    :class="{
      'c-dropdown--searchable': filterable,
      'c-dropdown--skinny': skinny
    }"
    v-bind="{
      ...attributes,
      filterable: !serverSideFiltering && filterable
    }"
    v-on="{
      ...events,
      input: emitState
    }"
  >
    <template slot="spinner" slot-scope="{ loading }">
      <div
        v-if="loading && enableLocalLoader"
        class="c-dropdown__loader cm-c-spinner cm-c-spinner--local"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div
        v-else
        class="c-dropdown__icon"
        :class="{ 'c-dropdown__icon--touchable': selectedLocal }"
        @click="clearSelected"
      >
        <c-icon
          :symbol-id="
            !filterable
              ? 'arrow-down'
              : !selectedLocal
              ? 'menu-search'
              : 'menu-close'
          "
        />
      </div>
    </template>
    <span slot="open-indicator"></span>
    <template slot="no-options"><slot name="no-options" /></template>
    <template slot="search" slot-scope="inputScope">
      <input
        v-show="filterable"
        id="c-dropdown"
        class="vs__search"
        :maxlength="attributes.maxInputLength"
        :class="{
          'vs__search--empty': !inputScope.attributes.value && !selectedLocal
        }"
        v-bind="inputScope.attributes"
        v-on="inputScope.events"
      />
      <label for="c-dropdown">
        {{ searchInputPlaceholder }}
      </label>
      <input type="hidden" :name="inputName" :value="inputValue" />
    </template>
  </v-select>
</template>
<script>
import VSelect from 'vue-select';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import 'vue-select/dist/vue-select.css';

const MODEL = {
  PROP: 'selected',
  EVENT: 'change'
};

export default {
  name: 'CDropdown',

  components: {
    VSelect,
    CIcon
  },

  model: {
    prop: MODEL.PROP,
    event: MODEL.EVENT
  },

  props: {
    [MODEL.PROP]: {
      type: Object,
      default: null
    },
    attributes: {
      type: Object,
      default: () => ({ options: [] })
    },
    events: {
      type: Object,
      default: () => ({})
    },
    searchInputPlaceholder: {
      type: String,
      default: null
    },
    enableLocalLoader: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      default: null
    },
    inputValueKey: {
      type: String,
      default: null
    },
    skinny: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedLocal: this.selected
    };
  },

  computed: {
    serverSideFiltering() {
      return !!this.events.search;
    },

    filterable() {
      return (
        !Object.prototype.hasOwnProperty.call(this.attributes, 'filterable') ||
        this.attributes.filterable
      );
    },

    inputValue() {
      if (!this.selected) {
        return null;
      }

      if (this.inputValueKey) {
        return this.selected[this.inputValueKey];
      }

      return this.selected;
    }
  },

  watch: {
    selected(value) {
      this.selectedLocal = value;
    }
  },

  methods: {
    emitState(value) {
      this.selectedLocal = value;
      this.$emit(MODEL.EVENT, value);
    },

    clearSelected() {
      if (this.selectedLocal) {
        this.selectedLocal = null;
        this.$emit(MODEL.EVENT, null);
      }
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$inputHeight: $u-700 + $u-200;
$inputBorderWidth: 2px;

.c-dropdown {
  & .vs__actions {
    padding: 0px;
  }

  & .vs__dropdown-toggle {
    background-color: $color-white;
    box-shadow: $box-shadow-default;
    border-radius: 3px;
    height: $inputHeight;
    padding-bottom: 0px;
    border: none;
  }

  & .vs__selected-options {
    padding: 0px;
    width: 100%;
    height: $inputHeight;

    & > label {
      bottom: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      text-align: left;
      padding-left: $u-250;
      padding-top: 18px; // $u-300 + 2px
      font-size: $global-font-size;
      color: $c-nt-500;

      transition: all 0.2s ease;
    }

    & > input {
      margin-top: 0px;
      padding-top: $u-300;
      padding-left: $u-250;
      outline-offset: 0px;
      position: absolute;
      width: 100%;
      height: 100%;

      font-size: $global-font-size;
      line-height: $global-font-size;

      height: $inputHeight;
      border-radius: 3px;
      border: $inputBorderWidth solid $c-nt-300;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: $c-nt-500;
      }

      &:disabled {
        border-color: $c-nt-300;

        & + label {
          color: $c-nt-300;
        }
      }

      &:focus,
      &:not(.vs__search--empty) {
        border-color: $c-pm-500;
        & + label {
          color: $c-pm-500;
          font-size: $global-font-size-sm;
          padding-top: $u-200;
        }
      }
    }
  }

  & .vs__dropdown-option--highlight {
    background-color: $c-nt-200;
    color: $c-nt-900;
  }

  & .vs__dropdown-menu {
    top: 100%;
    border-radius: 3px;
    padding-top: 0px;
  }

  & .vs__no-options {
    color: $c-nt-900;
    min-height: $inputHeight;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .vs__dropdown-option {
    line-height: $inputHeight;
  }

  & .vs__selected {
    padding-top: 16px;
    padding-left: 12px;
    margin: 0px;
    position: relative !important;
  }

  & .vs__clear {
    display: none;
  }

  &__icon {
    height: calc(100% - #{2 * $inputBorderWidth});
    position: absolute;
    right: $inputBorderWidth;
    top: $inputBorderWidth;
    width: 60px;
    background-color: white;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      height: $u-400;
      width: $u-400;
      transition: all 0.2s ease;

      .vs--disabled & {
        fill: $c-nt-300;
      }
    }

    &--touchable {
      cursor: pointer;
      z-index: 10;
    }
  }

  &__loader {
    position: absolute;

    &:before {
      left: calc(100% - 3em) !important;
    }
  }

  &:not(.c-dropdown--searchable) {
    .vs__selected-options {
      border-radius: 3px;
      border: $inputBorderWidth solid $c-nt-300;
      transition: border-color 0.2s ease;
    }

    &.vs--open {
      .vs__selected-options {
        border-color: $c-pm-500;
      }
      .c-dropdown__icon > svg {
        transform: rotateZ(180deg);
      }
    }
  }

  &--skinny {
    .vs__dropdown-toggle {
      box-shadow: none;
    }

    .vs__selected-options > input {
      border-width: 1px;
    }
  }
}
</style>
