<template>
  <c-card
    :class="modifiers"
    :value="value"
    :selected="selected"
    :no-padding="noPadding"
    :no-border="employeeConfigCard"
    @change="handleChange"
  >
    <template slot="sections">
      <c-card-section
        class="subscription-card__top"
        primary
        :ribbon-text="ribbonText"
        :badge-text="badgeText"
        :style="cssProps"
        :class="{ 'subscription-card__top--bg': cardBackgroundColor }"
      >
        <!-- TODO DKT-1417: v-trim is broken as it does not respect reactivity. Needs fix before we can use here. -->
        <div v-if="title" class="subscription-card__title">
          {{ title }}
        </div>
        <div v-if="hasAttribute" class="subscription-card__attr">
          <slot name="attr">{{ attr }}</slot>
        </div>
        <p v-if="description" class="subscription-card__description">
          {{ description }}
        </p>
        <c-button
          v-if="actionText && employeeConfigCard"
          class="subscription-card__employee-button"
          :class="{
            'c-btn--primary-alternative c-btn--sm': !selectedLocal,
            'subscription-card__employee-button--selected': selectedLocal
          }"
          :reset="selectedLocal"
          :icon-button="selectedLocal"
          @click.prevent="handleActionClicked"
        >
          <span
            v-if="selectedLocal"
            class="
              subscription-card__action
              subscription-card__action--selected
              subscription-card__action--white
            "
          >
            <c-icon symbol-id="service-checkmark" />
            {{ actionSelectedText }}
          </span>
          <span v-else>{{ actionText }}</span>
        </c-button>
      </c-card-section>
      <c-card-section v-if="!employeeConfigCard" class="subscription-card__bot">
        <div class="subscription-card__footer">
          <div class="subscription-card__prices">
            <div
              v-if="originalPriceText"
              class="
                subscription-card__price subscription-card__price--original
              "
            >
              {{ originalPriceText }}
            </div>
            <div class="subscription-card__price">{{ priceText }}</div>
          </div>
          <div v-if="actionText" class="subscription-card__action">
            <slot name="action">
              <transition name="subscription-card__scale">
                <div
                  v-if="selectedLocal"
                  class="subscription-card__action--selected"
                >
                  <c-icon symbol-id="service-checkmark" />
                  <template v-if="actionSelectedText">
                    {{ actionSelectedText }}
                  </template>
                </div>
                <c-button
                  v-else
                  class="subscription-card__action-button"
                  tag="div"
                  @click.prevent="handleActionClicked"
                >
                  <span>{{ actionText }}</span>
                </c-button>
              </transition>
            </slot>
          </div>
        </div>
      </c-card-section>
    </template>
    <div
      v-if="hasTongue"
      slot="tongue"
      class="subscription-card__tongue"
      @click="toggleTongue"
    >
      <slot v-if="hasTongueText" name="tongue">
        {{ tongueText }}
      </slot>
      <template v-else-if="hasTongueCollapse">
        <c-collapse-trigger :id="'tongue_' + value">
          <template slot="default" slot-scope="{ expanded }">
            <slot name="tongueTrigger">
              <div
                class="subscription-card__tongue-trigger"
                :class="{
                  'subscription-card__tongue-trigger--expanded': expanded
                }"
              >
                <div
                  v-if="hasVas"
                  class="subscription-card__tongue-vas-container"
                >
                  <span
                    ref="vasImagesWrapper"
                    class="subscription-card__tongue-vas"
                  >
                    <img
                      v-for="(vasItem, idx) in vasMapped"
                      :key="idx"
                      :src="vasItem.imageUrl"
                      :title="vasItem.title"
                    />
                  </span>
                  <span v-if="needIndicator">+</span>
                </div>
                <span class="subscription-card__tongue-text">{{
                  tongueTriggerText
                }}</span>
                <c-icon
                  v-if="tongueCollapseText || hasVas"
                  symbol-id="arrow-down"
                />
              </div>
            </slot>
          </template>
        </c-collapse-trigger>
        <c-collapse
          v-show="tongueCollapseText || hasVas"
          :id="'tongue_' + value"
          :visible="forceCollapseTongue"
        >
          <slot name="tongueCollapse">
            <div class="subscription-card__tongue-collapse">
              <template v-if="tongueCollapseText">
                {{ tongueCollapseText }}
              </template>
              <ul v-for="(vasItem, idx) in vasMapped" :key="idx">
                <li>
                  <slot name="vas" :data="vasItem">{{
                    vasItem.title || vasItem.displayName
                  }}</slot>
                </li>
              </ul>
            </div>
          </slot>
        </c-collapse>
      </template>
    </div>
  </c-card>
</template>

<script>
import CCard from '../../c-card/c-card.vue';
import CCardSection from '../../c-card/c-card-section.vue';
import { CButton, CCollapse, CCollapseTrigger } from 'olympus/components';

export default {
  name: 'SubscriptionCard',

  components: {
    CCard,
    CCardSection,
    CButton,
    CCollapse,
    CCollapseTrigger
  },

  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    cardBackgroundColor: {
      type: String,
      default: ''
    },
    attr: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    originalPriceText: {
      type: String,
      default: ''
    },
    priceText: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    },
    actionSelectedText: {
      type: String,
      default: ''
    },
    ribbonText: {
      type: String,
      default: ''
    },
    badgeText: {
      type: String,
      default: ''
    },
    selected: {
      type: [Boolean, Number, String],
      default: null
    },
    value: {
      type: [Boolean, Number, String],
      default: null
    },
    tongueText: {
      type: String,
      default: ''
    },
    tongueTriggerText: {
      type: String,
      default: ''
    },
    tongueCollapseText: {
      type: String,
      default: ''
    },
    vas: {
      type: Array,
      default: () => []
    },
    closeTongue: {
      type: Boolean,
      default: false
    },
    employeeConfigCard: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    preSelected: {
      type: [String],
      default: null
    },
    isVasDisplayDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      forceCollapseTongue: false,
      vasImagesWrapperWidth: 0
    };
  },

  computed: {
    modifiers() {
      return {
        'subscription-card': true,
        'subscription-card--no-details': this.employeeConfigCard
      };
    },
    selectedLocal() {
      return !!this.value && this.selected === this.value;
    },
    cssProps() {
      return {
        '--card-bg-color': this.cardBackgroundColor
      };
    },
    hasAttribute() {
      return Boolean(this.attr || this.$slots.attr);
    },
    hasTongue() {
      return (
        (this.hasTongueText || this.hasTongueCollapse) &&
        !this.isVasDisplayDisabled
      );
    },
    hasTongueText() {
      return Boolean(this.tongueText || this.$slots.tongue);
    },
    hasTongueCollapse() {
      return Boolean(
        (this.tongueTriggerText || this.$slots.tongueTrigger) &&
          (this.hasVas || this.tongueCollapseText)
      );
    },
    hasVas() {
      return Boolean(this.vasMapped && this.vasMapped.length);
    },
    vasMapped() {
      return this.vas.map(x => {
        const { imageUrl, title } = x;
        return {
          imageUrl,
          title,
          ...x
        };
      });
    },
    totalVasWidth() {
      return (24 + 2) * this.vas?.length - 2;
    },
    needIndicator() {
      if (this.vasImagesWrapperWidth < this.totalVasWidth) {
        return true;
      }
      return false;
    }
  },

  watch: {
    closeTongue() {
      this.forceCollapseTongue = false;
      this.$nextTick(() => this.$emit('collapsed'));
    },

    $resize() {
      this.vasImagesWrapperWidth = this.$refs.vasImagesWrapper?.offsetWidth;
    }
  },

  async mounted() {
    if (this.preSelected) {
      this.handleChange(this.preSelected);
    }
  },

  methods: {
    toggleTongue() {
      this.forceCollapseTongue = !this.forceCollapseTongue;
    },
    handleChange(value) {
      this.forceCollapseTongue = false;
      this.$emit('change', value);
    },
    handleActionClicked() {
      this.$emit('action');
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'src/shared/styles/breakpoints.scss';

$employee-button-width: 111px;

.subscription-card {
  position: relative;
  width: 100%;

  $subscription-card: &;

  // .subscription-card__top
  &__top {
    position: relative;
    padding-top: $u-550;
    min-height: 200px;

    &--bg {
      background-color: var(--card-bg-color);
    }
  }

  // .subscription-card__bot
  &__bot {
    padding: $u-300 $u-400;
  }

  // .subscription-card__title
  &__title {
    font-family: $global-font-family-2;
    font-size: $font-size-h5;
    line-height: 24px;
    white-space: normal;
  }

  // .subscription-card__attr
  &__attr {
    font-family: $global-font-family-2;
    font-size: $font-size-m + 2px; /* TODO DKT-1417: update design with font-size that corresponds to our variables? */
    font-weight: 700;
    text-transform: uppercase;
  }

  // .subscription-card__description
  &__description {
    font-size: $font-size-h5;
    line-height: 20px;
    white-space: normal;
  }

  // .subscription-card__footer
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .subscription-card__prices
  &__prices {
    position: relative;
  }

  // .subscription-card__price
  &__price {
    font-size: $global-font-size;
    font-weight: 700;
    line-height: 22px;

    // .subscription-card__price--original
    &--original {
      color: $c-pm-800;
      text-decoration: line-through;
    }
  }

  // .subscription-card__action
  &__action {
    position: relative;
    height: $u-500;

    // TODO DKT-1417: c-button styles are not properly
    &-button {
      font-size: $global-font-size-md !important;
      height: 2rem !important;
      min-width: 70px;
      padding: 0 1rem !important;
      position: absolute !important;
      right: 0;
      top: 0;
    }

    // .subscription-card__action--selected
    &--selected {
      display: flex;
      align-items: center;
      color: #222;
      flex: 0 0 100%;
      font-size: $global-font-size-md;
      line-height: $u-500;
      padding: 0 $u-300;

      & > svg {
        fill: currentColor;
        height: 17px;
        margin-right: -2px;
        width: 17px;
      }
    }

    &--white {
      color: $color-white;
    }
  }

  &__tongue {
    font-size: $global-font-size-sm;
    line-height: 18px;

    &-trigger {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: $u-350;

      & > svg {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0px;
        top: calc(50% - 6px);
        transition: transform 0.2s ease;
      }

      &--expanded > svg {
        transform: rotateZ(180deg);
      }
    }

    &-collapse {
      padding-top: $u-200;
    }

    $vasImageSize: 24;
    $vasImageMargin: 2;
    $vasMinCount: 2;
    $vasMaxCount: 6;

    &-vas-container {
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
    }

    &-vas {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      min-width: #{($vasImageSize + $vasImageMargin) * $vasMinCount}px;
      max-width: #{($vasImageSize + $vasImageMargin) * $vasMaxCount}px;
      max-height: #{$vasImageSize}px;

      & > img {
        height: #{$vasImageSize}px;
        width: #{$vasImageSize}px;
        max-width: 100%;
        height: auto;
        vertical-align: middle;

        &:not(:last-child) {
          margin-right: #{$vasImageMargin}px;
        }
      }
    }

    &-text {
      flex: 1 0 0;
      white-space: nowrap;
    }
  }

  &__scale {
    &-enter-active,
    &-leave-active {
      transition: transform 0.2s;
    }

    &-enter,
    &-leave-to {
      transform: scale(0);
    }
  }

  &--fluid {
    // Note: card is used inside carousel and min-width can break
    // carousel offsets. Instead width is controlled by slide count
    // and breakpoints.

    min-width: unset;
  }

  &.subscription-card--no-details {
    div.subscription-card__top {
      border: 2px solid $c-pm-900;
    }
    &.c-card--selected {
      .subscription-card__top {
        border: 2px solid $c-pm-500;
      }
    }
  }

  &__employee-button {
    min-width: $employee-button-width;
    margin-top: $u-500;

    &--selected.c-btn {
      margin-top: $u-700;
      padding: 0;

      #{$subscription-card}__action {
        padding: 0;
      }
    }
  }
}
</style>
