var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", xs: "12" } },
            [
              _c("c-input-group", {
                attrs: {
                  "input-error": _vm.firstNameError,
                  "place-holder-label":
                    _vm.numberConfigVM.subscriptionReceiverFirstNameText
                },
                model: {
                  value: _vm.product.subscriptionReceiverFirstName,
                  callback: function($$v) {
                    _vm.$set(_vm.product, "subscriptionReceiverFirstName", $$v)
                  },
                  expression: "product.subscriptionReceiverFirstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", xs: "12" } },
            [
              _c("c-input-group", {
                attrs: {
                  "input-error": _vm.lastNameError,
                  "place-holder-label":
                    _vm.numberConfigVM.subscriptionReceiverLastNameText
                },
                model: {
                  value: _vm.product.subscriptionReceiverLastName,
                  callback: function($$v) {
                    _vm.$set(_vm.product, "subscriptionReceiverLastName", $$v)
                  },
                  expression: "product.subscriptionReceiverLastName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", xs: "12" } },
            [
              _c("c-input-group", {
                attrs: {
                  type: "email",
                  "input-error": _vm.emailError,
                  "place-holder-label":
                    _vm.numberConfigVM.subscriptionReceiverEmailText
                },
                model: {
                  value: _vm.product.subscriptionReceiverEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.product, "subscriptionReceiverEmail", $$v)
                  },
                  expression: "product.subscriptionReceiverEmail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", xs: "12" } },
            [
              _c("c-input-group", {
                ref: "repeatField",
                attrs: {
                  type: "email",
                  "input-error": _vm.repeatEmailError,
                  "place-holder-label":
                    _vm.numberConfigVM.subscriptionReceiverRepeatEmailText
                },
                model: {
                  value: _vm.product.subscriptionReceiverRepeatedEmail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.product,
                      "subscriptionReceiverRepeatedEmail",
                      $$v
                    )
                  },
                  expression: "product.subscriptionReceiverRepeatedEmail"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }