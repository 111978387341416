<template>
  <!-- eslint-disable -->
  <div class="cm-c-modal__content">
    <div class="cm-c-modal__article">
      <p><img :src="image.url" :alt="headline" /></p>
      <h3 v-html="headline"></h3>
      <p v-bind:html="description"></p>
    </div>
    <div class="cm-c-form">
      <fieldset>
        <div class="cm-c-form__row">
          <div class="cm-c-form-control-group">
            <input
              type="text"
              required
              class="cm-c-form-control cm-js-material-input"
              v-model="membershipNumber"
              autocomplete="new-password"
            />
            <span class="cm-c-form-control__bar"></span>
            <label
              class="cm-c-form-control__label"
              v-html="numberField.label"
            ></label>
          </div>
        </div>
        <div class="cm-c-form__row" v-if="errors">
          <ul>
            <li
              class="cm-c-checkout__link"
              v-for="(error, idx) in errors"
              v-html="error"
              :key="idx"
            ></li>
          </ul>
        </div>
        <div class="cm-c-form__row cm-c-form__row--footer cm-text-right">
          <button
            @click="accept"
            :disabled="!membershipNumber || !membershipNumber.match(regex)"
            class="c-btn c-btn--primary c-btn--sm c-btn--inline"
            v-html="buttons.accept.label"
          ></button>
        </div>
      </fieldset>
    </div>
  </div>
  <!-- eslint-enable -->
</template>

<script>
/* eslint-disable */
// TODO DKT-1443: lint this code
import { mapMutations, mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../../store/basket';

export default {
  name: 'membership-dialog',
  data() {
    return {
      membershipNumber: ''
    };
  },
  props: {
    code: String,
    name: String,
    headline: String,
    description: String,
    image: {
      url: String
    },
    numberField: {
      label: String
    },
    buttons: {
      accept: {
        label: String
      }
    }
  },
  computed: {
    regex() {
      return this.buildRegex(this.name);
    }
  },
  methods: {
    ...mapMutations(['HIDE_DIALOG']),
    ...mapActions({
      addMembership: BASKET_ACTIONS.ADD_MEMBERSHIP
    }),
    accept() {
      if (!this.membershipNumber || !this.membershipNumber.match(this.regex)) {
        return;
      }

      this.addMembership({
        code: this.code,
        membershipCode: this.membershipNumber
      });
    },
    buildRegex(name) {
      // Club Matas - Only numbers, length is exactly 12 digits
      // Forbrugsforeningen - Only numbers, length is between 6 - 10 digits
      let regex;

      switch (name) {
        case 'clubmatas':
          regex = this.$globals.buildStringRegex(true, false, false, [12]);
          break;
        case 'forbrugsforeningen':
          regex = this.$globals.buildStringRegex(true, false, false, [6, 10]);
          break;
        default:
          regex = null;
      }

      return regex;
    }
  }
};
/* eslint-enable */
</script>
