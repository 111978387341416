var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-subcard c-subcard--generic",
      class: [_vm.modifier ? "c-subcard--" + _vm.modifier : ""]
    },
    [
      _c("div", { staticClass: "c-subcard__inner" }, [
        _c("strong", { staticClass: "c-subcard__headline" }, [
          _vm._v(_vm._s(_vm.subHeading))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-subcard__name" }, [
          _vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "c-subcard__description",
          attrs: { itemprop: "description" },
          domProps: { innerHTML: _vm._s(_vm.body) }
        }),
        _vm._v(" "),
        _vm.buttonText
          ? _c(
              "a",
              { staticClass: "c-subcard__url", attrs: { href: _vm.buttonUrl } },
              [_vm._v(_vm._s(_vm.buttonText))]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }