import NumberExisting from './number-existing.vue';
import NumberList from './number-list.vue';
import NumberMove from './number-move.vue';
import NumberNew from './number-new.vue';

export default {
  mode: 'history',
  base: window.location.pathname,
  routes: [
    { path: '/', component: NumberList },
    {
      name: 'number-existing',
      path: '/number-existing',
      component: NumberExisting,
      props: true
    },
    {
      name: 'number-move',
      path: '/number-move',
      component: NumberMove,
      props: true
    },
    {
      name: 'number-new',
      path: '/number-new',
      component: NumberNew,
      props: true
    }
  ],
  scrollBehavior(to, from) {
    let position = { x: 0, y: 0 };

    const hasPathChanged = to?.path !== from?.path;

    if (!hasPathChanged && !to?.hash) {
      return;
    }

    if (to && to.hash) {
      position = { selector: to.hash };
    }
    return position;
  }
};
