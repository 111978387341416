import Vue from 'vue';
import { get } from '../../api/api.js';
import { createNamespace } from '../utils.js';

const namespace = 'SUBSCRIPTIONS_PAGE';

export const MUTATIONS = createNamespace(`${namespace}/MUTATIONS`, [
  'SET_VARIANT'
]);
export const ACTIONS = createNamespace(`${namespace}/ACTIONS`, [
  'FETCH_VARIANT'
]);
export const GETTERS = createNamespace(`${namespace}/GETTERS`, [
  'GET_VARIANT_BY_CODE'
]);

export default {
  state: () => ({
    variants: {}
  }),

  mutations: {
    [MUTATIONS.SET_VARIANT](state, { code, data }) {
      var key = code || data.code;
      if (!key) {
        console.warn('Missing key for storing subscription variant.');
        return;
      }
      Vue.set(state.variants, key, data);
    }
  },

  actions: {
    [ACTIONS.FETCH_VARIANT]({ commit, getters }, { code, contentId }) {
      var existing = getters[GETTERS.GET_VARIANT_BY_CODE](code);
      if (existing) {
        return Promise.resolve(existing);
      }

      return get('/api/olympus/MobileSubscription/Get', {
        mvc: true,
        params: {
          code,
          contentId
        }
      }).then(({ data }) => {
        commit(MUTATIONS.SET_VARIANT, { code, data });
      });
      // todo: error handling
    }
  },

  getters: {
    [GETTERS.GET_VARIANT_BY_CODE]: state => code => state.variants[code]
  }
};
