<template>
  <div class="c-compare__specification-list-row">
    <div
      v-for="(item, itemIdx) in specificationList"
      :key="itemIdx"
      class="c-compare__specification-list-row--item"
    >
      <h5 class="c-typography-body-sm">
        {{ item.propertyLabel }}
      </h5>
      <div class="c-compare__specification-list-row--values">
        <div
          v-for="(
            productPropertyValue, productPropertyValueIdx
          ) in productPropertyValues"
          :key="productPropertyValueIdx"
          class="c-typography-body-sm"
        >
          {{
            productPropertyValues[productPropertyValueIdx][item.property] || '-'
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CCompareSpecificationListRow',
  props: {
    specificationList: {
      type: Array,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    productPropertyValues: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-compare__specification-list-row {
  display: flex;
  flex-direction: column;
  &--item {
    border-bottom: 1px solid $c-nt-300;
    padding-bottom: $u-400;
  }
  h5 {
    padding: $u-300 0;
  }
  &--values {
    display: flex;
    > div {
      flex: 1;
      text-align: center;
    }
    @include mq($screen-md, max) {
      gap: $u-250;
    }
  }
}
</style>
