<div :class="containerClassModifier">
  <div :class="subContainerClassModifier">
    <template>
      <div v-if="summary.networking" class="cm-c-spinner"></div>
      <div v-else>
        <div v-if="!upfrontPayment" class="price-summary__total">
          <h2 v-if="content.heading" class="price-summary__total__title">
            {{ content.heading }}
          </h2>
        </div>

        <price-section
          v-if="summary.data.subscriptions.length > 0"
          :newVM="summary.data.subscriptions"
          no-separators
          :headingLabel="content.subscriptionsLabel"
          :content="sectionContent"
        ></price-section>

        <price-section
          v-if="summary.data.installments.length > 0"
          :newVM="summary.data.installments"
          no-separators
          :headingLabel="content.installmentsLabel"
          :content="sectionContent"
        >
        </price-section>

        <price-section
          v-if="summary.data.upfrontItems.length > 0"
          :newVM="summary.data.upfrontItems"
          no-separators
          no-margin-bottom
          :headingLabel="content.productSectionHeadline"
          :content="sectionContent"
        ></price-section>

        <div
          v-if="summary.data.totalUpfront && summary.data.totalUpfront.formattedValueCurrency"
          class="price-summary__total__section"
        >
          <div class="cm-c-total__row">
            <strong class="price-summary__total__heading">
              {{ content.totalLabel }}
            </strong>
            <strong class="price-summary__total__heading">
              {{ summary.data.totalUpfront.formattedValueCurrency }}
            </strong>
          </div>
        </div>
      </div>

      <div v-if="basketPrices.networking" class="cm-c-spinner"></div>

      <div v-else class="price-summary__details">
        <c-collapse-trigger
          id="basket-price-details"
          class="price-summary__details-trigger"
        >
          <template slot-scope="{ expanded }">
            <c-icon
              symbol-id="arrow-chevron"
              :class="{ 'up': expanded }"
            ></c-icon>
            <!-- TODO DKT-1382: use grid component instead -->
            <div
              v-if="basketPrices.data.details.heading"
              class="cm-c-total__row cm-c-total__row--sm"
            >
              <div class="cm-c-total__name price-summary__details-trigger-name">
                {{ basketPrices.data.details.heading.label }}
              </div>
              <div class="cm-c-total__value">
                {{ basketPrices.data.details.heading.value }}
              </div>
            </div>
          </template>
        </c-collapse-trigger>
        <c-collapse id="basket-price-details">
          <!-- TODO DKT-1382: use grid component instead -->
          <price-section
            :vm="basketPrices.data.details"
            no-heading
            :content="minimalPriceContent"
          >
          </price-section>
          <!-- TODO TC-4930: use grid component instead -->
          <div v-if="basketPrices.data.details.heading" class="cm-c-total__row">
            <div>
              <strong>{{ basketPrices.data.details.heading.label }}</strong>
            </div>
            <div>
              <strong>{{ basketPrices.data.details.heading.value }}</strong>
            </div>
          </div>
        </c-collapse>
      </div>

      <checkout-finance-summary
        v-if="summary.data.financeSummary && summary.data.financeSummary.length"
        :sections="summary.data.financeSummary"
      ></checkout-finance-summary>
    </template>
  </div>
</div>
