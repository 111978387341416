<template>
  <div class="mini-cart-item" :class="getClass">
    <div v-if="spinning" class="cm-c-spinner"></div>
    <div class="mini-cart-item__image">
      <img v-if="item.image.src" :src="item.image.src" :alt="item.image.alt" />
    </div>
    <div class="mini-cart-item__info-container">
      <small v-if="title" class="mini-cart-item__brand">{{ title }}</small>

      <div class="mini-cart-item__specs-container">
        <div class="mini-cart-item__title-container">
          <h3 class="mini-cart-item__title">
            {{ name }}
          </h3>
          <div class="mini-cart-item__meta">
            <span v-for="(meta, i) in item.meta" :key="i">
              {{ meta }}
            </span>
          </div>
          <div class="mini-cart-item__montly-price-label">
            <template v-if="item.installments === 1">
              {{ content.upFrontPriceLabel }}
            </template>
            <template v-else>
              {{ content.pricePerMonthLabel }}
            </template>
          </div>
        </div>
        <div class="mini-cart-item__price-container">
          <p v-if="item.prices.monthly" class="mini-cart-item__price">
            {{ item.prices.monthly.formattedValueCurrency }}
          </p>
          <p v-else class="mini-cart-item__price">
            {{ item.prices.full.formattedValueCurrency }}
          </p>
          <div v-if="item.paymentPeriod" class="mini-cart-item__price-info">
            {{ item.paymentPeriodText }}
          </div>
        </div>
      </div>
      <div class="mini-cart__remove-btn-container">
        <button
          type="button"
          class="mini-cart__remove-btn"
          @click="$emit('removeProduct')"
        >
          <c-icon symbol-id="service-trash"></c-icon>
          <span class="cm-sr-only">Removebutton</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { miniBasketItemMixin } from 'theme/mixins/basket-group-mixin.js';
import {
  BASKET_ITEM_TYPES,
  BASKET_ITEM_SUB_TYPES
} from '../basket-constants.js';

export default {
  mixins: [miniBasketItemMixin],

  props: {
    content: {
      type: Object,
      default: () => ({
        subscriptionVoiceLabel: '',
        subscriptionDataLabel: '',
        subscriptionExtraDataCardLabel: '',
        subscriptionBroadbandLabel: ''
      })
    },
    item: {
      type: Object,
      required: true
    },
    groupId: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    spinning: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      BASKET_ITEM_TYPES,
      BASKET_ITEM_SUB_TYPES
    };
  },

  computed: {
    getClass() {
      return {
        'mini-cart-item--primary': this.index === 0,
        'mini-cart-item--child': this.index !== 0,
        'mini-cart-item--subscription': this.item.type === 'Subscription',
        'mini-cart-item--service': this.item.type === 'Service',
        'mini-cart-item--hardware': this.item.type === 'Hardware'
      };
    }
  }
};
</script>
