import { get } from './api.js';

export const getProductConfigs = (lineItemId = '') => {
  let config = {
    headers: {
      accept: 'application/vnd.api+json'
    }
  };
  return get(
    `/carts/groups/items/${
      lineItemId ? lineItemId + '/' : lineItemId
    }configurations`,
    config
  );
};

export const getNumberPool = (contextId, searchPattern, size) =>
  get(
    `/checkout/numbersearch?contextId=${contextId}&pattern=${searchPattern}&pagesize=${size}`,
    {
      keepalive: true
    }
  );
