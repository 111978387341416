<template>
  <div class="shopping-cart-group">
    <shopping-cart-item
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :item="item"
      :index="itemIndex"
      :installment-for-months-text-template="
        contentLocal.installmentForMonthsTextTemplate
      "
      :binding-period-text="contentLocal.bindingPeriodText"
      :is-bundle="isBundle"
      :promotion-code-applied-label="contentLocal.promotionCodeAppliedLabel"
      @basket-item-action="handleItemAction"
    >
    </shopping-cart-item>
    <div v-if="group.offerings.length" class="shopping-cart__offering-actions">
      <!-- TODO DKT-1426: use computed value for the filter -->
      <basket-offering
        v-for="(offering, offeringIndex) in offerings"
        :key="offeringIndex"
        :offering="offering"
        :group="group"
        @activate="handleOfferingClick"
      ></basket-offering>
    </div>

    <c-modal ref="removeMasterModal">
      <div slot="header">
        <h2>
          {{ contentLocal.removeMasterSubscriptionModalHeading }}
        </h2>
      </div>
      <div class="mb-3">
        {{ contentLocal.removeMasterSubscriptionModalBodyText }}
      </div>
      <div class="mb-2">
        <c-button @click="closeRemoveMasterModal">
          {{ contentLocal.removeMasterSubscriptionModalCancelButtonLabel }}
        </c-button>
        <c-button @click="removeMaster">
          {{ contentLocal.removeMasterSubscriptionModalAcceptButtonLabel }}
        </c-button>
      </div>
    </c-modal>

    <c-modal
      ref="removeProlongingGroupModal"
      @close="clearRemoveProlongingGroupCache"
    >
      <div
        v-html="contentLocal.removeProlongingSubscriptionModalDescription"
      ></div>
      <div class="shopping-cart-group__remove-actions">
        <button
          class="c-btn c-btn--primary c-btn--wide mb-1"
          @click.prevent="acceptRemoveProlongingGroup"
        >
          {{ contentLocal.removeProlongingSubscriptionModalAcceptButtonText }}
        </button>
        <button
          class="c-btn c-btn--as-link c-btn--wide"
          @click.prevent="declineRemoveProlongingGroup"
        >
          {{ contentLocal.removeProlongingSubscriptionModalDeclineButtonText }}
        </button>
      </div>
    </c-modal>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../../shared/store/basket';
import NUMBER_CONFIGURATION_TYPES from '../../../shared/enums/number-configuration-types.js';

import ShoppingCartItem from './shopping-cart-item/shopping-cart-item.vue';
import BasketOffering from './basket-offering.vue';
import {
  BASKET_ITEM_ACTIONS,
  BASKET_ITEM_TYPES,
  BASKET_OFFERING_TYPE,
  BASKET_ITEM_SUB_TYPES
} from './basket-constants.js';

import BasketGroupMixin from 'theme/mixins/basket-group-mixin.js';

const DEFAULT_CONTENT = {
  purchaseButtonVasLabel: 'Tilføj Service',
  purchaseButtonHandsetLabel: 'Tilføj Mobil',
  purchaseButtonEdcLabel: 'Tilføj EDC',
  purchaseButtonInsuranceLabel: 'Tilføj Mobilforsikring',
  installmentForMonthsTextTemplate: 'i {{period}} mdr.',
  bindingPeriodText: '{{paymentPeriod}} mdrs. binding',
  removeMasterSubscriptionModalAcceptButtonLabel: 'OK',
  removeMasterSubscriptionModalBodyText:
    'Hvis du fjerner dette abonnement vil alle tilknyttede abonnementer ligeledes blive fjernet fra kurven.',
  removeMasterSubscriptionModalCancelButtonLabel: 'Fortryd',
  removeMasterSubscriptionModalHeading: 'Fjern abonnement'
};

export default {
  name: 'BasketGroup',

  components: {
    ShoppingCartItem,
    BasketOffering
  },

  mixins: [BasketGroupMixin],

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    group: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      BASKET_ITEM_ACTIONS,
      BASKET_ITEM_TYPES,
      BASKET_ITEM_SUB_TYPES,
      BASKET_OFFERING_TYPE,
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      },
      cache: {
        removeTargetItem: null
      }
    };
  },

  computed: {
    offerings() {
      const actions = {
        [BASKET_OFFERING_TYPE.PURCHASE_VAS]: {
          icon: 'service-entertainment',
          label: this.contentLocal.purchaseButtonVasLabel
        },
        [BASKET_OFFERING_TYPE.PURCHASE_HANDSET]: {
          icon: 'service-mobil',
          label: this.contentLocal.purchaseButtonHandsetLabel
        },
        // TODO DKT-1426: UPDATE ACCORDING DESIGN, YET TO BE DEFINED
        [BASKET_OFFERING_TYPE.PURCHASE_EDC]: {
          icon: 'service-gift',
          label: this.contentLocal.purchaseButtonEdcLabel
        },
        [BASKET_OFFERING_TYPE.PURCHASE_INSURANCE]: {
          icon: 'service-gift',
          label: this.contentLocal.purchaseButtonInsuranceLabel
        }
      };

      return this.group.offerings
        .filter(
          x =>
            x.type !== BASKET_OFFERING_TYPE.PURCHASE_MEMBER &&
            x.type !== BASKET_OFFERING_TYPE.PURCHASE_INSURANCE
        )
        .map(x => {
          const actionContent = actions[x.type];
          return {
            ...x,
            ...actionContent
          };
        });
    },

    isBundle() {
      return this.group.items.some(
        item => item.type === BASKET_ITEM_TYPES.HARDWARE
      );
    }
  },

  methods: {
    ...mapActions({
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET,
      addService: BASKET_ACTIONS.ADD_SERVICE
    }),

    ...mapMutations({
      showDialog: 'SHOW_DIALOG'
    }),

    toGroupItem(offeringItem) {
      const { code, image, name, price, type } = offeringItem;
      return {
        code,
        image,
        name,
        primary: false,
        bundled: type === BASKET_ITEM_TYPES.SUBSCRIPTION,
        prices: {
          monthly: price
        },
        isOffering: true,
        groupId: this.group.id,
        action:
          type === BASKET_OFFERING_TYPE.PURCHASE_INSURANCE
            ? BASKET_ITEM_ACTIONS.TOGGLE
            : BASKET_ITEM_ACTIONS.REMOVE
      };
    },

    handleItemAction({ type, item }) {
      return this[type] && this[type](item);
    },

    [BASKET_ITEM_ACTIONS.REMOVE](item, accepted = false) {
      const prolongingDialogEnabled =
        this.content.removeProlongingSubscriptionModalEnabled;

      if (
        prolongingDialogEnabled &&
        item.type === BASKET_ITEM_TYPES.HARDWARE &&
        !accepted
      ) {
        const prolongingSubscriptionItem = this.items.find(
          x =>
            x.type === BASKET_ITEM_TYPES.SUBSCRIPTION &&
            x.attributes.numberType === NUMBER_CONFIGURATION_TYPES.EXISTING
        );
        if (prolongingSubscriptionItem) {
          this.$refs.removeProlongingGroupModal.show();
          this.cache.removeTargetItem = item;
          return;
        }
      }

      try {
        this.trackRemove(item);
      } catch {
        // silent
      }
      (item.bundled ? this.removeGroup : this.removeSingle)(item);
    },

    [BASKET_ITEM_ACTIONS.TOGGLE](item) {
      if (item.isOffering) {
        this.addService({ vasCode: item.code, groupId: item.groupId });
      } else {
        this[BASKET_ITEM_ACTIONS.REMOVE](item);
      }
    },

    acceptRemoveProlongingGroup() {
      this[BASKET_ITEM_ACTIONS.REMOVE](this.cache.removeTargetItem, true);
      this.$refs.removeProlongingGroupModal.hide();
    },

    declineRemoveProlongingGroup() {
      this.$refs.removeProlongingGroupModal.hide();
    },

    clearRemoveProlongingGroupCache() {
      this.cache.removeTargetItem = null;
    },

    removeSingle(item) {
      const payload = { code: item.code, groupId: item.groupId };
      this.removeFromBasket(payload).catch(error =>
        this.handleAcceptFinanceConflict(error, payload)
      );
    },

    removeGroup(item) {
      this.removeMasterModal.productCode = item.code;
      this.removeMasterModal.groupId = item.groupId;
      this.$refs.removeMasterModal.show();
    },

    trackRemove(item) {
      window.dataLayer = window.dataLayer || [];
      let productData = {};

      if (item.type === BASKET_ITEM_TYPES.HARDWARE) {
        productData = {
          name: item.name?.model,
          id: item.code,
          price: item.prices.base?.formattedValue,
          brand: item.brand,
          category: item.type,
          variant: item.name.variant
        };
      } else {
        productData = {
          name: item.name?.display,
          id: item.code,
          price: item.prices.base?.formattedValue,
          brand: item.brand,
          category: item.type,
          variant: null
        };
      }

      window.dataLayer.push({
        ecommerce: {
          remove: {
            products: [productData]
          }
        },
        event: 'ecommerce'
      });
    },

    // TODO DKT-1426: check if this is suppose to be here
    closeRemoveMasterModal() {
      this.$refs.removeMasterModal.hide();
    },

    // TODO DKT-1426: check if this is suppose to be here
    removeMaster() {
      this.closeRemoveMasterModal();

      const payload = {
        code: this.removeMasterModal.productCode,
        groupId: this.removeMasterModal.groupId
      };

      this.removeFromBasket(payload).catch(e => {
        if (e.code === 409) {
          this.handleAcceptFinanceConflict(e, payload);
        }
        // TODO DKT-1426: handle other exceptions?
      });
      this.removeMasterModal.productCode = '';
      this.removeMasterModal.groupId = '';
    },

    /**
     * Shows a conflict modal if the BE
     * returns a 409 conflict.
     * Users can resolve the conflict
     * or go back (handled inside the modal component).
     *
     * @param { Object } error Axios error object
     * @param { Object } payload Remove basket items payload object
     */
    handleAcceptFinanceConflict(error, payload) {
      this.showDialog({
        componentName: 'prevent-accessory-financing-dialog',
        componentProps: { contentObject: error.errors[0].detail, payload }
      });
    },

    handleOfferingClick(offeringType) {
      const data = this.offerings.find(
        offering => offering.type === offeringType
      );

      switch (offeringType) {
        case BASKET_OFFERING_TYPE.PURCHASE_EDC:
          this.$root.$emit('add-edc-overlay', {
            group: this.group,
            data: data
          });
          break;
        case BASKET_OFFERING_TYPE.PURCHASE_HANDSET:
          this.$root.$emit('add-hardware-overlay', {
            relations: data.relations,
            group: this.group
          });
          break;
        case BASKET_OFFERING_TYPE.PURCHASE_VAS:
          this.$root.$emit('add-vas-overlay', {
            group: this.group
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>
