/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
/**
 * A11y Menu.
 * @module a11yMenu.mjs
 * @version 0.0.05
 * @summary 03-02-2020
 * @author Mads Stoumann
 * @description a11y Menu.
 */
import Velocity from 'velocity-animate';
import KeyHandler from 'webshop/js/keyhandler.js';

class Menu {
  constructor(wrapper, eventBus, settings) {
    this.settings = Object.assign(
      {
        chars: 'abcdefghijklmnopqrstuvwxyzæøå0123456789',
        clsMenuItem: 'c-menu__item-details',
        clsMenuItemLink: 'c-menu__link',
        clsMenuItemName: 'c-menu__item',
        clsMenuPanel: 'c-menu__panel',
        clsMenuGroup: 'c-menu__group',
        clsMenuGroupItem: 'c-menu__group-item',
        clsMenuPanelBackground: 'c-menu__panel-background',
        clsMenuMobileCloseButton: 'c-menu__close',
        clsMenuMobileRadioToggle: 'state--menu-main',
        megaMenu: true,
        megaMenuBreakpoint: 800,
        outerWrapper: '',
        overlay: ''
      },
      settings
    );
    this.eventBus = eventBus;
    this.init(wrapper);
  }

  /**
   * @function init
   * @description Create menu from data, add eventListeners etc.
   */
  async init(wrapper) {
    if (wrapper === null) {
      return;
    }

    if (this.settings.menuData) {
      try {
        const data = await (await fetch(this.settings.menuData)).json();
        wrapper.innerHTML = this.renderMenu(data, 1);
      } catch (err) {}
    }

    if (this.settings.megaMenu) {
      this.calculateMenuPanelHeight(wrapper);
      this.showMegaMenu = null;
      this.overlay = document.querySelector(`${this.settings.overlay}`);
      this.externalOverlay = document.getElementById('menu-overlay');
      this.panels = [
        ...wrapper.querySelectorAll(`.${this.settings.clsMenuItem}--1`)
      ];
      this.panels.forEach(panel => {
        panel.keyHandler = new KeyHandler(panel, {
          callBack: this.handleKeys,
          callBackScope: this,
          preventDefaultKeys: ''
        });
      });

      /* Autofocus search-field when panel is opened */
      const searchInput = document.getElementById('c-search-panel');

      if (searchInput) {
        const searchPanel =
          searchInput.closest('.c-menu__panel').previousElementSibling
            .previousElementSibling;
        if (searchPanel) {
          searchPanel.addEventListener('click', () => {
            searchInput.focus();
          });
        }
      }

      /* Add outside-click listener, if panels exists */
      if (this.panels) {
        this.handleClickEvent = this.togglePanels.bind(this);
        document.addEventListener('click', event => {
          if (!wrapper.contains(event.target)) {
            this.togglePanels(null, true);
          }
        });

        /* Use ResizeObserver to collapse/expand all sub-groups based on screen-resolution, add and remove eventListeners based on viewPort */
        const RO = new ResizeObserver(entries => {
          const mobileMenuToggle = document.querySelector(
            `.${this.settings.clsMenuMobileRadioToggle}`
          );
          const mobileCloseButton = document.querySelector(
            `.${this.settings.clsMenuMobileCloseButton}`
          );

          if (mobileMenuToggle.checked) {
            mobileCloseButton?.click();
          }

          return entries.forEach(entry => {
            const showMegaMenu =
              window.innerWidth > this.settings.megaMenuBreakpoint;
            if (this.showMegaMenu !== showMegaMenu) {
              this.showMegaMenu = showMegaMenu;
              this.togglePanels(
                null,
                true
              ); /* Collapse open panels when resize occurs */
              const subpanels = entry.target.querySelectorAll(
                `.${this.settings.clsMenuItem}--2`
              );
              subpanels.forEach(subpanel => {
                subpanel.open =
                  showMegaMenu; /* Expand sub-panels if mega-menu is active */
              });
              /* For First Level, add or remove keyHandler-eventListener if desktop/mobile */
              this.panels.forEach(panel => {
                panel.keyHandler.toggleKeyEvent(showMegaMenu);
                this.toggleClickEvent(panel.firstElementChild, showMegaMenu);
              });

              entry.target
                .querySelectorAll('[type="radio"]:not([id="menu-overlay"])')
                .forEach(el => {
                  if (!el.checked) {
                    return;
                  }

                  el.checked = !el.checked;
                });
            }

            this.calculateMenuPanelHeight(wrapper, true);
          });
        });
        RO.observe(
          this.settings.outerWrapper
            ? document.querySelector(this.settings.outerWrapper)
            : wrapper
        );
      }
    }

    /* Use MutationObserver to detect <details [open]> changes */
    const MO = new MutationObserver(mutations => {
      return mutations.forEach(mutation => {
        const elm = mutation.target;
        if (elm.tagName.toLowerCase() === 'details') {
          const summary = elm.firstElementChild;
          summary.setAttribute('aria-expanded', elm.open);

          if (this.overlay && this.panels.includes(elm)) {
            this.overlay.style.display = elm.open ? 'block' : 'none';
          }

          this.handleNextElement(elm);
          this.animateVisiblePanel(elm);
        }
      });
    });
    MO.observe(wrapper, {
      attributes: true,
      subtree: true
    });
  }

  animateVisiblePanel(elm) {
    if (elm.classList.contains(this.settings.clsMenuItem)) {
      if (elm.open) {
        this.handlePanelOpen(elm);
      } else {
        this.handlePanelClose(elm);
      }
    }
  }

  handlePanelOpen(elm) {
    const background = document.querySelector(
      `.${this.settings.clsMenuPanelBackground}`
    );
    const menuGroup = elm.querySelectorAll(`.${this.settings.clsMenuGroup}`);
    const panelHeight = elm.querySelector('div').dataset.contentHeight;

    Velocity(
      background,
      { height: Number(panelHeight) },
      {
        duration: 200,
        easing: 'ease-out',
        begin: () => {
          menuGroup.forEach(group => {
            const menuGroupItems = group.querySelectorAll(
              `.${this.settings.clsMenuGroupItem}`
            );

            menuGroupItems.forEach((item, index) => {
              Velocity(
                item,
                { opacity: 1 },
                { duration: 350, delay: 50 * index }
              );
            });
          });
        }
      }
    );
  }

  handlePanelClose(elm) {
    const items = elm.querySelectorAll(`.${this.settings.clsMenuGroupItem}`);

    Velocity(items, { opacity: 0 }, { duration: 0 });
  }

  calculateMenuPanelHeight(wrapper, hide = true) {
    const details = wrapper.querySelectorAll(`.${this.settings.clsMenuItem}`);

    details.forEach(detail => {
      const stayOpen = detail.hasAttribute('open');
      const detailContent = detail.querySelector('div');
      detail.open = true;

      detailContent.setAttribute(
        'data-content-height',
        detailContent.scrollHeight
      );

      if (!hide && stayOpen) {
        return;
      }

      detail.open = false;
    });
  }

  handleNextElement(element) {
    const target = element.nextElementSibling;

    if (
      this.showMegaMenu ||
      !target ||
      !target.querySelector('.c-menu__group-header--empty')
    ) {
      return;
    }

    const summary = target.firstElementChild;

    target[element.open ? 'setAttribute' : 'removeAttribute'](
      'open',
      element.open
    );
    summary.setAttribute('aria-expanded', element.open);
    summary.classList.toggle(this.settings.clsMenuItemNameOpen, element.open);
  }

  togglePanelBackground(height = 0) {
    const background = document.querySelector(
      `.${this.settings.clsMenuPanelBackground}`
    );

    Velocity(background, { height: Number(height) });
  }

  /**
   * @function handleKeys
   * @param {Object} obj
   * @description Handle key-navigation from keyhandler-obj
   */
  handleKeys(obj) {
    if (!obj) {
      return;
    }
    const firstPanel = this.panels[0].firstElementChild;
    const handler = obj.element.keyHandler;
    const isPanel = obj.active.tagName === 'SUMMARY';
    const lastPanel = this.panels[this.panels.length - 1].firstElementChild;
    let index = obj.end ? obj.last : obj.row || 0;

    switch (obj.key) {
      /* Space Key: Toggle panel (default) or go to link */
      case ' ':
        if (obj.active.tagName === 'A') {
          location.href = obj.active;
        } else {
          obj.event.preventDefault();
        }
        break;
      case 'ArrowDown':
        if (isPanel) {
          index = 0;
        }
        if (obj.end) {
          index = 0;
        }
        break;
      case 'ArrowLeft':
        {
          const prevPanel = obj.element.previousElementSibling;
          if (prevPanel && prevPanel.tagName === 'DETAILS') {
            prevPanel.firstElementChild.focus();
            if (!isPanel) {
              prevPanel.open = true;
              obj.element.open = false;
            }
          } else {
            /* Go to last panel */
            lastPanel.focus();
          }
        }
        break;
      case 'ArrowRight':
        {
          const nextPanel = obj.element.nextElementSibling;
          if (nextPanel && nextPanel.tagName === 'DETAILS') {
            nextPanel.firstElementChild.focus();
            if (!isPanel) {
              nextPanel.open = true;
              obj.element.open = false;
            }
          } else {
            /* Go to first panel */
            firstPanel.focus();
          }
        }
        break;
      case 'End':
        if (isPanel) {
          lastPanel.focus();
        } else {
          index = obj.last;
        }
        break;
      case 'Enter':
        if (isPanel) {
          obj.open = true;
        } else {
          if (obj.active.tagName === 'A') {
            location.href = obj.active;
          }
        }
        break;
      case 'Escape':
        index = 0;
        obj.element.open = false;
        obj.element.firstElementChild.focus();

        this.togglePanelBackground();
        break;
      case 'Home':
        if (isPanel) {
          firstPanel.focus();
        } else {
          index = 0;
        }
        break;
      case 'Tab':
        if (!isPanel) {
          obj.event.preventDefault();
        }
        break;
      default:
        /* keyChar Search */
        if (this.settings.chars.includes(obj.key.toLowerCase())) {
          if (isPanel) {
            /* Create array of indexes for panel headers starting with obj.key */
            const indexes = Array.from(this.panels).reduce((arr, item, row) => {
              if (
                item.firstElementChild.innerText
                  .toLowerCase()
                  .startsWith(obj.key)
              ) {
                arr.push(row);
              }
              return arr;
            }, []);

            /* Get index of panel which has focus */
            const panelIndex = Array.from(this.panels).findIndex(item => {
              return item.firstElementChild === document.activeElement;
            });

            /* Get colIndex for next panel with an index larger than currently selected item */
            let colIndex = indexes.find(item => {
              return item > panelIndex;
            });
            if (!colIndex && indexes.length > 0) {
              colIndex = indexes[0];
            }
            if (colIndex > -1) {
              this.panels[colIndex].firstElementChild.focus();
            }

            /* Subpanel */
          } else {
            /* Create array of indexes for items starting with obj.key */
            const indexes = obj.rows.reduce((arr, item, row) => {
              if (item.innerText.toLowerCase().startsWith(obj.key)) {
                arr.push(row);
              }
              return arr;
            }, []);

            /* Get rowIndex for next item with an index larger than currently selected item */
            let rowIndex = indexes.find(item => {
              return item > index;
            });
            if (!rowIndex && indexes.length > 0) {
              rowIndex = indexes[0];
            }
            if (rowIndex > -1) {
              index = rowIndex;
            }
          }
        }
        break;
    }

    if (isPanel) {
      obj.element.open = obj.open;
    }

    handler.row = index;
    if (obj && obj.rows[index]) {
      obj.rows[index].focus();
    }
  }

  /**
   * @function renderMenu
   * @param {Object} data
   * @description Render menu from (json) object, recursive nested levels from `children`.
   */
  renderMenu(data, level) {
    return data
      .map(item => {
        return item.children
          ? `<details class="${this.settings.clsMenuItem}--${level}"${
              level > 1 ? ` open` : ''
            }>
				<summary class="${
          this.settings.clsMenuItemName
        }--${level}" role="button" aria-expanded="${
              level > 1 ? `true` : `false`
            }" data-level="${level}">${item.name}</summary>
				<div class="${
          this.settings.clsMenuPanel
        }--${level}" role="menu">${this.renderMenu(
              item.children,
              level + 1
            )}</div>
			</details>`
          : `<a class="${this.settings.clsMenuItemLink}--${level}"${
              level > 1 ? ` role="menuitem"` : ''
            } href="${item.url}">${item.name}</a>`;
      })
      .join('');
  }

  /**
   * @function stringToType
   * @param {Object} obj
   * @description Convert data-attribute value to type-specific value. Prefix non-strings with ":", example = :true
   */
  stringToType(obj) {
    const object = Object.assign({}, obj);
    Object.keys(object).forEach(key => {
      if (object[key].charAt(0) === ':') {
        object[key] = JSON.parse(object[key].slice(1));
      }
    });
    return object;
  }

  /**
   * @function toggleClickEvent
   * @param {Boolean} bool
   * @description Adds or removes click-listener
   */
  toggleClickEvent(panel, bool = true) {
    if (bool) {
      panel.addEventListener('click', this.handleClickEvent);
    } else {
      panel.removeEventListener('click', this.handleClickEvent);
    }
  }

  /**
   * @function togglePanels
   * @param {Node} panel
   * @description Collapses all panels except `event.target`(if specified and matches a panel)
   */
  togglePanels(event, closeAll = false) {
    let elm = event && event.target;
    if (elm && elm.parentNode) {
      elm = elm.parentNode;
    }

    const background = document.querySelector(
      `.${this.settings.clsMenuPanelBackground}`
    );

    if (closeAll) {
      Velocity(background, { height: 0 });
    }

    if (event && elm.hasAttribute('open')) {
      Velocity(background, { height: 0 }, { duration: 200 });
    } else {
      this.panels.forEach(panel => {
        panel.open = elm === panel ? panel.open : false;
      });
    }

    if (elm && this.externalOverlay.checked === false) {
      this.externalOverlay.checked = true;
    }
  }
}

export function a11yMenu(eventBus, { expandedPanels = false }) {
  if (!document.querySelector('.c-menu')) {
    return;
  }

  return new Menu(
    document.querySelector('div.c-menu__state.c-menu__main'),
    eventBus,
    {
      outerWrapper: '.c-menu',
      overlay: '.c-menu__overlay-main',
      expandedPanels
    }
  );
}
