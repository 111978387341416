var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-modal-wrapper-inner" }, [
    _c("header", { staticClass: "c-modal-content__header" }, [
      _c("h3", { domProps: { innerHTML: _vm._s(_vm.contentObject.headline) } }),
      _vm._v(" "),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.contentObject.description) }
      })
    ]),
    _vm._v(" "),
    _vm.contentObject.image
      ? _c("img", { attrs: { src: _vm.contentObject.image } })
      : _vm._e(),
    _vm._v(" "),
    _vm.contentObject.details
      ? _c(
          "div",
          { staticClass: "c-modal-content__section cm-c-total cm-mb-top--md" },
          [
            _vm._l(_vm.contentObject.details.rows, function(rowsItem) {
              return _c(
                "div",
                { key: rowsItem.property },
                _vm._l(rowsItem, function(row) {
                  return _c(
                    "div",
                    { key: row.property, staticClass: "cm-c-total__row" },
                    [
                      _c("div", {
                        staticClass: "cm-c-total__name",
                        domProps: { innerHTML: _vm._s(row.property) }
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "cm-c-total__value",
                        domProps: { innerHTML: _vm._s(row.value) }
                      })
                    ]
                  )
                }),
                0
              )
            }),
            _vm._v(" "),
            _vm.contentObject.details.total &&
            _vm.contentObject.details.total.property
              ? _c("div", { staticClass: "cm-c-total__row" }, [
                  _c("div", { staticClass: "cm-c-total__name" }, [
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.contentObject.details.total.property
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "cm-c-total__value",
                    domProps: {
                      innerHTML: _vm._s(_vm.contentObject.details.total.value)
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.contentObject.details.terms
              ? _c(
                  "div",
                  { staticClass: "cm-c-total__row cm-c-modal__terms" },
                  _vm._l(_vm.contentObject.details.terms, function(
                    term,
                    index
                  ) {
                    return _c("div", { key: index }, [
                      _c("div", { domProps: { innerHTML: _vm._s(term) } })
                    ])
                  }),
                  0
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("footer", { staticClass: "c-modal-content__footer" }, [
      _c(
        "div",
        {
          class: [
            _vm.contentObject.buttonLink ? "cm-text-center" : "cm-text-right"
          ]
        },
        [
          _vm.contentObject.buttonLink
            ? _c("a", {
                staticClass: "c-btn c-btn--primary",
                attrs: { href: _vm.contentObject.buttonLink },
                domProps: { innerHTML: _vm._s(_vm.contentObject.buttonLabel) }
              })
            : _c("button", {
                staticClass: "c-btn c-btn--primary",
                domProps: { innerHTML: _vm._s(_vm.contentObject.buttonLabel) },
                on: { click: _vm.HIDE_DIALOG }
              })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }