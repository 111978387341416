var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("c-card", [
    _c(
      "div",
      {
        staticClass: "config-group",
        class: { "config-group--disabled": !_vm.enabled }
      },
      [
        _c("article", { staticClass: "config-group__header" }, [
          _c("div", { staticClass: "config-group__image" }, [
            _c("img", { attrs: { src: _vm.image, role: "presentation" } })
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "config-group__info" }, [
            _c("div", { staticClass: "config-group__info__left" }, [
              _c(
                "div",
                {
                  staticClass: "config-group__info__left-title",
                  attrs: { "data-testid": "info-left-title" }
                },
                [_vm._v("\n            " + _vm._s(_vm.title) + "\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "config-group__info__left-description",
                  attrs: { "data-testid": "info-left-description" }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.subTitle) + "\n          "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.configurationType
                ? _c(
                    "div",
                    { staticClass: "config-group__info__left-completed-text" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            "" +
                              _vm.configurationTextPopulated +
                              _vm.numberConfigurationLabels
                          ) +
                          "\n          "
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "config-group__info__right" },
              [
                _c("div", { staticClass: "config-group__info__right-title" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.rightTitle) + "\n          "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.rightSubTitleList, function(rightSubTitle, idx) {
                  return _c(
                    "div",
                    {
                      key: idx,
                      staticClass:
                        "config-group__info__right-description text-right"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(rightSubTitle) +
                          "\n          "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.enabled
            ? _c(
                "div",
                { staticClass: "config-group__toggle" },
                [
                  _c(
                    "c-collapse-trigger",
                    { attrs: { id: "product-config-group-trigger" } },
                    [
                      _c(
                        "c-button",
                        {
                          staticClass: "config-group__toggle-button",
                          attrs: { reset: "", type: "button" },
                          on: { click: _vm.handleToggle }
                        },
                        [
                          _c("c-icon", {
                            staticClass: "config-group__toggle-button-icon",
                            attrs: {
                              "aria-hidden": "true",
                              "symbol-id": _vm.expanded
                                ? "arrow-up"
                                : "arrow-down"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "cm-sr-only" }, [
                            _vm._v("Toggle content")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.expanded
          ? _c(
              "c-collapse",
              {
                attrs: {
                  id: "product-config-group-trigger",
                  visible: _vm.expanded
                }
              },
              [
                _c(
                  "c-form",
                  {
                    staticClass: "config-group__body",
                    attrs: {
                      "data-testid": "config-group-slot-container",
                      action: ""
                    },
                    on: { submit: _vm.handleFormSubmit }
                  },
                  [
                    _vm._t("default"),
                    _vm._v(" "),
                    _c(
                      "c-button",
                      {
                        staticClass: "config-group__submit-button",
                        attrs: {
                          type: "submit",
                          disabled: !_vm.enableSubmitButton
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.saveConfigurationButtonText) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  2
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }