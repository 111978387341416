var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSelectedPaymentMethodCalculated
    ? _c(
        "c-accordion",
        {
          staticClass: "mt-3",
          attrs: {
            "expanded-id": "" + _vm.selectedPaymentMethodValue,
            "is-model-bound": true
          }
        },
        _vm._l(_vm.activePaymentMethods, function(paymentMethod) {
          return _c(
            "div",
            {
              key: paymentMethod.value,
              staticClass: "checkout-select-payment",
              class: {
                "checkout-select-payment--active":
                  _vm.selectedPaymentMethodValue === paymentMethod.value,
                "checkout-select-payment--disabled": paymentMethod.isDisabled
              }
            },
            [
              _c(
                "c-accordion-item",
                {
                  attrs: { "item-id": paymentMethod.value },
                  on: {
                    change: function($event) {
                      return _vm.setSelectedPaymentMethod(paymentMethod)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "checkout-select-payment__info",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "checkout-select-payment__info__left" },
                        [
                          _c(
                            "div",
                            { staticClass: "checkout-select-payment__image" },
                            [
                              paymentMethod.logoUrl
                                ? _c("img", {
                                    staticClass:
                                      "checkout-select-payment__image__icon",
                                    attrs: {
                                      src: paymentMethod.logoUrl,
                                      alt: paymentMethod.logoAltText,
                                      role: "presentation"
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkout-select-payment__info__left__title-container"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "checkout-select-payment__info__left__title"
                                },
                                [_vm._v(_vm._s(paymentMethod.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "checkout-select-payment__info__left__sub-title"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(paymentMethod.subTitle) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "checkout-select-payment__info__right" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkout-select-payment__info__right__title"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(paymentMethod.displayValue) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      paymentMethod.value === _vm.loadingState
                        ? _c(
                            "div",
                            { staticClass: "cm-c-spinner cm-c-spinner--local" },
                            [
                              _c("span", { staticClass: "sr-only" }, [
                                _vm._v("Indlæser...")
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "checkout-select-payment__content",
                      attrs: { slot: "content" },
                      slot: "content"
                    },
                    [
                      paymentMethod.expandText
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "checkout-select-payment__content__description"
                            },
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(paymentMethod.expandText)
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  paymentMethod.paymentMethodAlert
                    ? _c(
                        "c-information-box",
                        {
                          staticClass:
                            "checkout-select-payment__content__info-box",
                          attrs: {
                            slot: "info-box",
                            icon: _vm.getIconName(paymentMethod),
                            type: _vm.getIconType(paymentMethod)
                          },
                          slot: "info-box"
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                paymentMethod.paymentMethodAlert.alertText
                              )
                            }
                          }),
                          _vm._v(" "),
                          _vm.showAlertActionButton(paymentMethod)
                            ? _c(
                                "c-button",
                                {
                                  staticClass:
                                    "checkout-select-payment__content__info-box-button",
                                  attrs: { variant: "link" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateResursAmount(
                                        paymentMethod
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        paymentMethod.paymentMethodAlert
                                          .alertAction.actionLinkText
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }