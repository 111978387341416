var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "12" } },
            [
              _c("h3", { staticClass: "cm-c-form__header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.numberConfigVM.optionalSettingsHeadline) +
                    "\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            {
              staticClass: "cm-c-form__col",
              attrs: { "align-content": "center" }
            },
            [
              _c(
                "c-checkbox",
                {
                  attrs: {
                    id: "hiddenNumber",
                    name: "isNumberHidden",
                    "styled-checkbox": "",
                    checked: _vm.hiddenNumber
                  },
                  on: { change: _vm.emitHiddenNumber },
                  model: {
                    value: _vm.hiddenNumber,
                    callback: function($$v) {
                      _vm.hiddenNumber = $$v
                    },
                    expression: "hiddenNumber"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.numberConfigVM.secretNumberLabel) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "popover-panel",
                {
                  attrs: {
                    description: _vm.numberConfigVM.secretNumberDescription,
                    "tooltip-screen-reader-text":
                      _vm.numberConfigVM.secretNumberDescription,
                    "popover-triggers": ["hover", "click"],
                    "base-classes": "cm-numcon__information"
                  }
                },
                [
                  _c("c-icon", {
                    staticClass: "cm-numcon__information__icon",
                    attrs: { "symbol-id": "service-information" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            {
              staticClass: "cm-c-form__col",
              attrs: { "align-content": "center" }
            },
            [
              _c(
                "c-checkbox",
                {
                  attrs: {
                    id: "unlistedNumber",
                    name: "isNumberUnlisted",
                    "styled-checkbox": "",
                    checked: _vm.unlistedNumber
                  },
                  on: { change: _vm.emitUnlistedNumber },
                  model: {
                    value: _vm.unlistedNumber,
                    callback: function($$v) {
                      _vm.unlistedNumber = $$v
                    },
                    expression: "unlistedNumber"
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.numberConfigVM.unlistedNumberLabel) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "popover-panel",
                {
                  attrs: {
                    description: _vm.numberConfigVM.unlistedNumberDescription,
                    "tooltip-screen-reader-text":
                      _vm.numberConfigVM.unlistedNumberDescription,
                    "popover-triggers": ["hover", "click"],
                    "base-classes": "cm-numcon__information"
                  }
                },
                [
                  _c("c-icon", {
                    staticClass: "cm-numcon__information__icon",
                    attrs: { "symbol-id": "service-information" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c("c-col", { staticClass: "cm-c-form__col" }, [
            _c("p", [_vm._v(_vm._s(_vm.numberConfigVM.barringsInformation))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }