<template>
  <div class="epi-form-text" :class="{ 'epi-form-text--error': !!inputError }">
    <c-input
      v-bind="$props"
      @change="onChange"
      @input="onInput"
      @keyup.enter="performSubmit"
      @keypress="keypress"
    />
    <span v-if="inputError" class="field-validation-error c-input__error">
      {{ inputError }}
    </span>

    <label for="c-input" class="epi-form-text__label c-input__label">
      {{ placeHolderLabel }}
    </label>
  </div>
</template>

<script>
import CInput from './c-input.vue';

export default {
  name: 'CInputGroup',

  components: {
    CInput
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    placeHolderLabel: {
      type: String,
      default: ''
    },
    inputError: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, InputEvent],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minlength: {
      type: Number,
      default: 0
    },
    maxlength: {
      type: Number,
      default: 999
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    onChange(value) {
      this.$emit('change', value);
    },

    performSubmit() {
      this.$emit('submit', this.value);
    },

    keypress(event) {
      this.$emit('keypress', event);
    }
  }
};
</script>
<style lang="scss" scoped>
.c-input {
  &__error {
    position: initial;
  }

  &__label {
    pointer-events: none;
  }
}
</style>
