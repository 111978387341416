var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-cart-mini" }, [
    _vm.isNetworking
      ? _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" })
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "cm-c-cart-mini__header" }, [
      _c("p", { staticClass: "cm-c-cart-mini__title" }, [
        _vm._v("\n      " + _vm._s(_vm.basketContent.basketTitle) + "\n    ")
      ]),
      _vm._v(" "),
      _c("hr")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "cm-c-cart-mini__body" },
      [
        _vm.isEmpty
          ? _c("div", { staticClass: "mini-cart__empty" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.basketContent.basketIsEmptyLabel) +
                  "\n    "
              )
            ])
          : _vm._l(_vm.relatedGroups, function(
              relatedGroup,
              relatedGroupsIndex
            ) {
              return _c(
                "div",
                {
                  key: relatedGroupsIndex,
                  staticClass: "mini-cart-related-group"
                },
                _vm._l(relatedGroup.groups, function(group, groupIndex) {
                  return _c(
                    "div",
                    {
                      key: groupIndex,
                      staticClass: "mini-cart-related-group__child",
                      attrs: { "data-groupid": group.id }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mini-cart-group" },
                        _vm._l(group.items, function(item, itemIndex) {
                          return _c("mini-shopping-cart-item", {
                            key: itemIndex,
                            attrs: {
                              content: _vm.basketContent,
                              item: item,
                              index: itemIndex,
                              "group-id": group.id
                            }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "mini-cart-group__devider" })
                    ]
                  )
                }),
                0
              )
            })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mini-cart__footer" }, [
      !_vm.isEmpty && _vm.basketUrl
        ? _c(
            "p",
            {
              staticClass: "mini-cart__footer__button-wrapper",
              class: {
                "mini-cart__footer__button-wrapper__with-disclaimer":
                  _vm.priceDisclaimerText
              }
            },
            [
              _vm.showDeleteIcon || _vm.isSalesAgent
                ? _c("empty-basket", {
                    attrs: {
                      "icon-position": "left",
                      content: _vm.emptyBasketContent
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "c-btn", attrs: { href: _vm.basketUrl } },
                [_vm._v(_vm._s(_vm.basketUrlTitle))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isEmpty && _vm.priceDisclaimerText
        ? _c(
            "p",
            {
              class: {
                "mini-cart__footer__disclaimer-text": _vm.priceDisclaimerText
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.priceDisclaimerText) + "\n    ")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }