import { ACTIONS, MUTATIONS } from './mutation-types.js';

import basketService from '../../api/BasketService.js';
import trackingService from '../../api/TrackingService.js';
import { get, post } from '../../api/api.js';

// TODO: remove all methods that are not really actions
// TODO: remove the entire show_ hide_ madness - it does not belong here!

export default {
  [ACTIONS.GO_TO_CHECKOUT_FLOW]({ commit }, [blockId]) {
    basketService.basketServiceRequest(
      null,
      'POST',
      'GoToCheckout',
      { blockId: blockId },
      gotoCheckoutFlow => commit(MUTATIONS.STORE_TO_CART, gotoCheckoutFlow),
      exception => commit(MUTATIONS.SHOW_ALERT, exception)
    );
  },

  [ACTIONS.RESET_FACET_GROUP]({ commit, state }, facet) {
    const facetIndex = state.selectedFacets
      .map(function (obj) {
        return obj.id;
      })
      .indexOf(facet.name);
    if (facetIndex > -1) {
      commit(MUTATIONS.REM_FACET, facetIndex);
    }

    commit(MUTATIONS.RESET_PAGE);
    this.dispatch('searchProducts');
  },

  [ACTIONS.RESET_ALL_FACET_GROUP]({ commit }) {
    commit(MUTATIONS.REM_FACETS);
    commit(MUTATIONS.RESET_PAGE);
    this.dispatch('searchProducts');
  },

  [ACTIONS.UPDATE_SHIPPING_METHOD](
    { commit },
    { shippingId, deliveryLocationCode }
  ) {
    commit(MUTATIONS.SHOW_SPINNER);
    return post('/cart/shipping', {
      shippingId,
      deliveryLocationCode
    })
      .then(({ data }) => {
        commit(MUTATIONS.SHOW_SPINNER);
        commit(MUTATIONS.UPDATE_CART, data);
        commit(MUTATIONS.HIDE_DIALOG);
        commit(MUTATIONS.RELOAD_PAGE);
      })
      .catch(() => {
        commit(MUTATIONS.HIDE_SPINNER);
        commit(MUTATIONS.SHOW_DIALOG, {
          componentName: 'error-dialog',
          componentProps: {}
        });
      });
  },

  [ACTIONS.SELECT_FACET]({ commit, state }, facet) {
    function clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    }
    const facetIndex = state.selectedFacets
      .map(function (obj) {
        return obj.name;
      })
      .indexOf(facet.name);
    const facetGroup =
      facetIndex > -1
        ? clone(state.selectedFacets[facetIndex])
        : { name: facet.name, values: [] };
    const valueIndex = facetGroup.values
      .map(function (obj) {
        return obj.value;
      })
      .indexOf(facet.value);

    if (valueIndex > -1) {
      // Remove item from facetGroup
      facetGroup.values.splice(valueIndex, 1);
    } else {
      // Add item to facetGroup
      facetGroup.values.push({ value: facet.value });
    }

    if (facetGroup.values.length) {
      // Update existing facetGroup
      if (facetIndex > -1) {
        commit(MUTATIONS.UPD_FACET, { index: facetIndex, facet: facetGroup });
      }
      // Or add a new facetGroup
      else {
        commit(MUTATIONS.ADD_FACET, facetGroup);
      }
    } else {
      // No selected facets in grouo, delete facetGroup
      commit(MUTATIONS.REM_FACET, facetIndex);
    }

    commit(MUTATIONS.RESET_PAGE);
    this.dispatch('searchProducts');
  },

  [ACTIONS.SET_FACET_STATE]({ commit }, { id, name, value }) {
    commit(MUTATIONS.SET_FACET_STATE, { id, name, value });
    commit(MUTATIONS.RESET_PAGE);
    this.dispatch('searchProducts');
  },

  [ACTIONS.SELECT_PRICE_PLAN]({ commit }, price) {
    commit(MUTATIONS.SET_PRICEPLAN, price);
    commit(MUTATIONS.RESET_PAGE);
    this.dispatch('searchProducts');
  },

  [ACTIONS.SEARCH_PRODUCTS]({ commit, state }) {
    const facetsWithValues = (state.selectedFacets || []).filter(
      ({ values }) => values.length
    );
    const selectedMainFacet = facetsWithValues[0]?.id;

    const searchRequest = {
      ...state.searchSettings,
      selectedMainFacet,
      selectedFacets: state.selectedFacets.map(x => {
        return {
          name: x.id,
          values: x.values.map(v => ({ value: v }))
        };
      }),
      sort: state.sortBy
    };

    // todo: missing error handling
    // todo: remove the whole spinner stuff
    commit(MUTATIONS.SHOW_SPINNER);
    return post('/catalog/search', searchRequest, { cached: true }).then(
      ({ data }) => {
        commit(MUTATIONS.SEARCH_PRODUCTS, data);
        commit(MUTATIONS.HIDE_SPINNER);

        // todo: figure out why this code is here.
        // its an endless push of data, when user clicks
        // around searching for product...
        window.dataLayer = window.dataLayer || [];

        let impressions = data.hits.reduce((arr, item, index) => {
          const defaultColor = item.availableColors?.find(x =>
            x.variants?.find(v => v.isDefaultVariant)
          );
          const defaultVariant = defaultColor?.variants?.find(
            x => x.isDefaultVariant
          );
          let variantData = [];

          if (defaultColor?.colorName) {
            variantData.push(defaultColor.colorName);
          }

          if (defaultVariant?.minimumStorageCapacity) {
            variantData.push(`${defaultVariant.minimumStorageCapacity}gb`);
          }

          if (item?.productScreenSizeInches) {
            variantData.push(item.productScreenSizeInches);
          }

          arr.push({
            name: item.productName?.toLowerCase(),
            id: item.productId,
            brand: item.productBrand?.toLowerCase(),
            category: item.productCategory?.toLowerCase(),
            list: 'category page',
            position: index,
            variant: variantData.join('|')?.toLowerCase()
          });
          return arr;
        }, []);

        let ecommerceLayer = window.dataLayer.find(
          o => o.event === 'ecommerce'
        );
        if (ecommerceLayer) {
          ecommerceLayer.ecommerce.impressions.push(...impressions);
        } else {
          window.dataLayer.push({
            ecommerce: {
              currencyCode: 'DKK',
              impressions
            },
            event: 'ecommerce'
          });
        }
        return data;
      }
    );
  },

  [ACTIONS.SET_COMPARE_ITEM]({ commit, state }, product) {
    const index = state.compare
      .map(function (obj) {
        return obj.productId;
      })
      .indexOf(product.productId);

    if (index > -1) {
      commit(MUTATIONS.REM_COMPARE, index);
    } else {
      const productIndex = state.productSearchResult.hits
        .map(function (obj) {
          return obj.productId;
        })
        .indexOf(product.productId);
      const productItem = state.productSearchResult.hits[productIndex];
      commit(MUTATIONS.ADD_COMPARE, productItem);
    }
  },

  [ACTIONS.UPDATE_PRICES]({ commit }, obj) {
    return basketService.basketServiceRequest(
      null,
      'POST',
      'GetPrice',
      obj,
      getPrices => {
        commit(MUTATIONS.UPDATE_PRICES, getPrices);
      }
    );
  },

  getMinimumPrice({ commit }) {
    return basketService.basketServiceRequest(
      null,
      'GET',
      'GetMinimumPriceDetails',
      null,
      minimumPrice => {
        commit(MUTATIONS.SET_MINIMUM_PRICE_DETAILS, minimumPrice);
      }
    );
  },

  [ACTIONS.FETCH_BASKET_PRICE_SUMMARY](
    { commit },
    { upfrontPayment, orderNumber, includeFinanceSummary }
  ) {
    commit(MUTATIONS.SET_BASKET_PRICE_SUMMARY_NETWORKING, true);
    if (!upfrontPayment) {
      return get('/prices/summary', {
        params: { orderNumber, includeFinanceSummary }
      }).then(json => {
        commit(MUTATIONS.SET_BASKET_PRICE_SUMMARY, json);
        commit(MUTATIONS.SET_BASKET_PRICE_SUMMARY_NETWORKING, false);
      });
    }
    const config = {
      headers: {
        accept: 'application/vnd.api+json'
      }
    };
    return get('/prices/upfrontsummary', config).then(response => {
      const data = {
        data: response.data.data.attributes
      };
      commit(MUTATIONS.SET_BASKET_PRICE_SUMMARY, data);
      commit(MUTATIONS.SET_BASKET_PRICE_SUMMARY_NETWORKING, false);
    });
  },

  [ACTIONS.FETCH_BASKET_PRICE_DETAILS](
    { commit },
    { blockId, upfrontPayment, orderNumber }
  ) {
    commit(MUTATIONS.SET_BASKET_PRICE_NETWORKING, true);
    return get('/prices/minimumprice', {
      params: { blockId, convertToUpfront: upfrontPayment, orderNumber }
    }).then(json => {
      commit(MUTATIONS.SET_BASKET_PRICE_DETAILS, json);
      commit(MUTATIONS.SET_BASKET_PRICE_NETWORKING, false);
    });
  },

  [ACTIONS.TRACK_PRODUCT]({ state }, product) {
    const trackId = state.productSearchResult.trackId;
    const queryForTracking = state.productSearchResult.queryForTracking;
    const parameters = {
      query: queryForTracking,
      hitId: product.hitId,
      trackId: trackId
    };

    trackingService.track(parameters);
  },

  [ACTIONS.ADD_ACTIVATE_SIM](
    { commit },
    [code, groupId, isActivateSimCard, activateSimCardNumber]
  ) {
    commit(MUTATIONS.SHOW_SPINNER);
    basketService.basketServiceRequest(
      null,
      'POST',
      'addActivateSim',
      { code, groupId, isActivateSimCard, activateSimCardNumber },
      addActivateSim => {
        if (addActivateSim.items) {
          commit(MUTATIONS.UPDATE_CART, addActivateSim);
        }
        commit(MUTATIONS.HIDE_SPINNER);
      },
      () => {
        commit(MUTATIONS.HIDE_SPINNER);
        commit(MUTATIONS.SHOW_DIALOG, {
          componentName: 'error-dialog',
          componentProps: {}
        });
      }
    );
  },

  [ACTIONS.CHANGE_QUANTITY]({ commit }, [groupId, quantity]) {
    basketService.basketServiceRequest(
      null,
      'POST',
      'ChangeQuantity',
      { groupId: groupId, quantity: quantity },
      changeQuantity => {
        commit(MUTATIONS.SHOW_SPINNER);
        commit(MUTATIONS.UPDATE_CART, changeQuantity);
        commit(MUTATIONS.RELOAD_PAGE);
      },
      () => {
        commit(MUTATIONS.HIDE_SPINNER);
        commit(MUTATIONS.SHOW_DIALOG, {
          componentName: 'error-dialog',
          componentProps: {}
        });
      }
    );
  },

  [ACTIONS.FETCH_PICKUP_POINTS]() {
    return get('/inventory/pickuppoints').then(response => response.data);
  },

  /**
   * Shows a spinner while waiting for a promise to resolve.
   * The spinner can be optionally throttled to prevent flashing for quick requests.
   */
  [ACTIONS.WAIT]({ commit }, options) {
    if (!options.promise) {
      return;
    }

    const throttleTime = options?.throttleTime || 0;
    const throttle = new Promise(resolve =>
      setTimeout(() => {
        commit(MUTATIONS.SHOW_SPINNER);
        resolve();
      }, throttleTime)
    );

    return Promise.all([options.promise, throttle])
      .then(() => {
        commit(MUTATIONS.HIDE_SPINNER);
      })
      .catch(() => {
        commit(MUTATIONS.HIDE_SPINNER);
      });
  }
};
