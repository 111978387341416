<template>
  <div ref="lottieContainer" v-in-viewport="{ enter: onViewportEnter }" />
</template>

<script lang="ts">
import lottie from 'lottie-web';
import Vue from 'vue';
import InViewport from '../../directives/in-viewport-directive';

export default Vue.extend({
  name: 'ALottieAnimation',

  directives: { InViewport },

  props: {
    path: {
      type: String,
      default: ''
    },
    // Path to animation data.json
    animationData: {
      type: [String, Object],
      default: null
    },

    // Should animation loop?
    loop: {
      type: Boolean,
      default: false
    },

    // Should animation autoplay?
    autoplay: {
      type: Boolean,
      default: true
    },

    inViewPlay: {
      type: Boolean,
      default: false
    },

    // Start frame of the loop
    startLoopFrame: {
      type: Number,
      default: 0
    },

    // End frame of the loop
    endLoopFrame: {
      type: Number,
      default: 0
    },

    // Should loop start directly?
    forceLoopFrames: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      anim: null,
      totalFrames: 0
    };
  },

  computed: {
    isInit() {
      return !!this.anim;
    }
  },

  watch: {
    loop() {
      this.anim.loop = this.loop;
    },
    startLoopFrame() {
      this.playSegment();
    },
    endLoopFrame() {
      this.playSegment();
    }
  },

  mounted() {
    this.tryInit();

    this.totalFrames = this.anim.getDuration(true);
    this.anim.addEventListener('complete', this.complete);

    if (this.startLoopFrame && this.endLoopFrame) {
      this.playSegment();
    }
  },

  methods: {
    playSegment() {
      if (this.startLoopFrame && this.endLoopFrame) {
        this.anim.playSegments(
          [this.startLoopFrame, this.endLoopFrame],
          this.forceLoopFrames
        );
      } else if (this.totalFrames) {
        this.anim.playSegments([0, this.totalFrames], this.forceLoopFrames);
      }
    },
    complete() {
      this.$emit('complete');
    },
    onViewportEnter() {
      if (this.inViewPlay) {
        this.tryInit();
        this.anim.play();
      }
    },
    /**
     * @description this will ensure animation is init once
     * if in view port is triggered during binding, before mounting.
     */
    tryInit() {
      try {
        if (!this.anim) {
          this.anim = lottie.loadAnimation({
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: this.loop,
            autoplay: !this.inViewPlay && this.autoplay,
            path: this.path,
            animationData: this.animationData
          });
        }
      } catch {
        // silence
      }
    }
  }
});
</script>
