<template>
  <form ref="form" :action="action" :method="method" @submit.prevent="onSubmit">
    <slot />
  </form>
</template>

<script>
export default {
  name: 'c-form',

  props: {
    action: {
      type: String,
      required: true
    },
    // eslint-disable-next-line
    // TODO TC-4912: add method validation
    method: {
      type: String,
      default: 'get'
    }
  },

  methods: {
    onSubmit(e) {
      this.$emit('submit', e);
    },

    reset() {
      this.$refs.form.reset();
    }
  }
};
</script>
