<template>
  <div v-show="spinnerOpen" class="cm-c-spinner"></div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GETTERS as SHOP_GETTERS } from 'webshop/store/shop';

export default {
  name: 'OverlaySpinner',
  computed: {
    ...mapGetters({
      spinnerOpen: SHOP_GETTERS.IS_GLOBAL_SPINNER_OPEN
    })
  }
};
</script>
