<template>
  <div v-if="newVM.length > 0" class="price-section" :class="modifiers">
    <div v-if="!noHeading && headingLabel" class="price-section__heading">
      <dl class="text-bold">
        <dt>{{ headingLabel }}</dt>
      </dl>
    </div>
    <div>
      <div class="price-section__descriptions">
        <div
          v-for="(line, lineIdx) in formattedItems"
          :key="line.label + lineIdx"
        >
          <dl>
            <dt>{{ line.label }}</dt>
            <dd v-if="line.promotionSaved" class="savedAmount">
              {{ line.basePrice }}
            </dd>
            <dd>{{ line.value }}</dd>
          </dl>
          <dl v-if="line.promotionSaved" class="savedAmount">
            <dt>{{ content.promotionCodeAppliedLabel }}</dt>
            <dd>-{{ line.promotionSaved }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="vm && vm.items" class="price-section" :class="modifiers">
    <div v-if="!noHeading && vm.heading" class="price-section__heading">
      <dl class="text-bold">
        <dt>{{ vm.heading.label }}</dt>
        <dd v-if="!upfrontPayment">{{ vm.heading.value }}</dd>
      </dl>
    </div>
    <div>
      <div
        v-for="(group, groupIdx) in vm.items"
        :key="groupIdx"
        class="price-section__descriptions"
      >
        <div v-for="(line, lineIdx) in group" :key="lineIdx">
          <dl>
            <dt>{{ line.label }}</dt>
            <dd>{{ line.fullPrice || line.value }}</dd>
          </dl>
          <dl v-if="line.subscriptionSaved" class="savedAmount">
            <dt>{{ content.subscriptionDiscountLabel }}</dt>
            <dd>-{{ line.subscriptionSaved }}</dd>
          </dl>
          <dl v-if="line.offerSaved" class="savedAmount">
            <dt>{{ content.offerDiscountLabel }}</dt>
            <dd>-{{ line.offerSaved }}</dd>
          </dl>
          <dl v-if="line.savedAmmount" class="savedAmount">
            <dt>{{ content.promotionCodeAppliedLabel }}</dt>
            <dd>-{{ line.savedAmmount }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sumPriceAndFormatForPeriod } from '../../../components/shopping-cart/price-summary-helpers';
// TODO TC-4985: remove once we make use of new price api and substitute with price-summary.vue
export default {
  name: 'PriceSection',

  props: {
    vm: {
      type: Object,
      default: () => ({})
    },
    newVM: {
      type: Array,
      default: () => []
    },
    noHeading: {
      type: Boolean,
      default: false
    },
    noSeparators: {
      type: Boolean,
      default: false
    },
    noMarginBottom: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: null
    },
    headingLabel: {
      type: String,
      default: null
    },
    upfrontPayment: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modifiers() {
      return {
        'price-section--no-separators': !this.noSeparators,
        'price-section--no-margin-bottom': this.noMarginBottom,
        'price-section--small': this.small
      };
    },
    formattedItems() {
      if (!this.newVM) {
        return [];
      }
      return this.newVM.map(e =>
        sumPriceAndFormatForPeriod(e, this.content.periodLabel)
      );
    }
  }
};
</script>
