export const OFFERING_TYPE = {
  NEW_VAS: 'New',
  EXISTING_VAS: 'Existing'
};

export const STATE_INTERACTION = {
  ADD: 'Add',
  REMOVE: 'Remove',
  NO_CHANGE: 'NoChange',
  OPEN_POPUP: 'OpenPopup',
  DISABLED: 'Disabled'
};
