var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shipping" }, [
    _c(
      "div",
      { staticClass: "shipping__inner-wrapper cm-c-checkout__module" },
      [
        _c(
          "c-card",
          { attrs: { "no-padding": "", "no-shadow": "", "no-rounding": "" } },
          [
            _c("h2", { staticClass: "cm-c-checkout__subtitle" }, [
              _vm._v(_vm._s(_vm.content.block.headline))
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "cm-c-checkout__text shipping__description-text",
              domProps: { innerHTML: _vm._s(_vm.showShippingBlockText) }
            }),
            _vm._v(" "),
            _vm._l(_vm.mappedDisclaimers, function(disclaimer, index) {
              return [
                disclaimer.visible && disclaimer.text
                  ? _c(
                      "c-information-box",
                      {
                        key: index,
                        staticClass: "shipping__disclaimers",
                        attrs: {
                          icon: disclaimer.icon,
                          type: "informative",
                          animatein: ""
                        }
                      },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(disclaimer.text) }
                        })
                      ]
                    )
                  : _vm._e()
              ]
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "shipping__options" },
              _vm._l(_vm.content.shippingMethods, function(method) {
                return _c(
                  "config-group-item",
                  {
                    key: method.id,
                    attrs: {
                      title: method.displayName,
                      "right-text":
                        method.price && method.price.formattedValueCurrency,
                      "right-text-centered": true,
                      icon: _vm.getShippingMethodIcon(method.name),
                      "prevent-expand": !_vm.expand,
                      expanded: method.name === _vm.selectedShippingMethod.name
                    },
                    on: {
                      expanded: function($event) {
                        return _vm.handleExpanded(method)
                      }
                    }
                  },
                  [
                    _vm.showAddress && _vm.content.billingAddress
                      ? _c("div", [
                          _vm.content.billingAddress.organization
                            ? _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.content.billingAddress.organization
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.content.billingAddress.line1) +
                                "\n              " +
                                _vm._s(_vm.content.billingAddress.houseNumber) +
                                _vm._s(_vm.content.billingAddress.houseLetter) +
                                "\n              " +
                                _vm._s(_vm.content.billingAddress.floor) +
                                "\n              " +
                                _vm._s(_vm.content.billingAddress.doorNumber) +
                                "\n              " +
                                _vm._s(_vm.content.billingAddress.direction) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.content.billingAddress.postalCode) +
                                "\n              " +
                                _vm._s(_vm.content.billingAddress.city) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm.fetchingUserData && !_vm.isGlobalSpinnerOpen
                            ? _c("div", {
                                staticClass: "cm-c-spinner cm-c-spinner--local"
                              })
                            : method.allowOtherAddress
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "c-row",
                                    { staticClass: "mt-2 mb-4" },
                                    [
                                      _c(
                                        "c-col",
                                        [
                                          _c(
                                            "c-checkbox",
                                            {
                                              attrs: {
                                                id: "show-address-form",
                                                "styled-checkbox": ""
                                              },
                                              on: {
                                                "event-id":
                                                  _vm.toggleShowAddressForm
                                              },
                                              model: {
                                                value: _vm.form.showAddressForm,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "showAddressForm",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.showAddressForm"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    method.allowOtherAddressLabel
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "c-collapse",
                                    {
                                      attrs: {
                                        id: "show-address-form",
                                        visible: _vm.form.showAddressForm
                                      }
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "cm-c-form__header" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                method.deliveryAddressTitle
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "6", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "firstName",
                                                  "place-holder-label":
                                                    method.firstNameLabel
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.firstName
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.firstName,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.firstName.$model"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "6", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "lastName",
                                                  "place-holder-label":
                                                    method.lastNameLabel
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.lastName.$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.lastName,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.lastName.$model"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        {
                                          staticClass:
                                            "cm-c-form__row cm-c-form__row--extra-spacing"
                                        },
                                        [
                                          _c(
                                            "c-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "company",
                                                  "place-holder-label":
                                                    method.companyLabel
                                                },
                                                model: {
                                                  value: _vm.form.company,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "company",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.company"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("address-search", {
                                                attrs: {
                                                  content: {
                                                    searchInputPlaceholder:
                                                      method.addressSearchLabel,
                                                    searchString:
                                                      _vm.prefilledAddress
                                                  },
                                                  "is-block": false
                                                },
                                                on: { select: _vm.setAddress }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "streetname",
                                                  "place-holder-label":
                                                    method.addressStreetNameLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.streetName
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.streetName,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.streetName.$model"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "4", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "houseNumber",
                                                  "place-holder-label":
                                                    method.addressHouseNumberLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.houseNumber
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.houseNumber,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.houseNumber.$model"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "8", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "houseLetter",
                                                  "place-holder-label":
                                                    method.addressHouseLetterLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value: _vm.form.houseLetter,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "houseLetter",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.houseLetter"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "4", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "floor",
                                                  "place-holder-label":
                                                    method.addressFloorLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value: _vm.form.floor,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "floor",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.floor"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "8", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "Apartment",
                                                  "place-holder-label":
                                                    method.addressApartmentLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value: _vm.form.apartment,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "apartment",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.apartment"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "c-row",
                                        { staticClass: "cm-c-form__row" },
                                        [
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "4", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "postalcode",
                                                  "place-holder-label":
                                                    method.addressZipCodeLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.postalCode
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.postalCode,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.postalCode.$model"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "c-col",
                                            {
                                              staticClass: "cm-c-form__col",
                                              attrs: { cols: "8", sm: "12" }
                                            },
                                            [
                                              _c("c-input-group", {
                                                attrs: {
                                                  name: "city",
                                                  "place-holder-label":
                                                    method.addressTownLabel,
                                                  disabled: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.form.city.$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.form.city,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.form.city.$model"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasNoShippingRequired
                      ? _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(method.description) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    method.hasDescription && !_vm.hasNoShippingRequired
                      ? _c(
                          "div",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(method.description) +
                                " " +
                                _vm._s(_vm.selectedPhoneNumber) +
                                "\n            "
                            ),
                            method.modifyTelephoneNumber
                              ? _c(
                                  "c-button",
                                  {
                                    attrs: { variant: "link" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.openNewNumberOverlay($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(method.modifyTelephoneNumberLabel)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedShippingMethod.name ===
                      _vm.pickupPointFreeMethod ||
                    _vm.selectedShippingMethod.name ===
                      _vm.callmePickupPointMethod
                      ? _c(
                          "div",
                          { staticClass: "shipping__pickup-points" },
                          [
                            _c("store-autocomplete", {
                              attrs: {
                                placeholder: _vm.content.block
                                  .searchInputPlaceholder
                                  ? _vm.content.block.searchInputPlaceholder
                                  : "",
                                "shop-data": _vm.mappedPickupPoints,
                                "address-title":
                                  _vm.content.block.addressSearchSuggestionTitle
                              },
                              on: {
                                "select-shop": _vm.selectShop,
                                "select-address": _vm.selectAddress
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "shipping__geolocation" },
                              [
                                _c(
                                  "c-button",
                                  {
                                    attrs: { "icon-button": "" },
                                    on: { click: _vm.searchByGeolocation }
                                  },
                                  [
                                    _c("c-icon", {
                                      attrs: {
                                        "symbol-id": "service-markeroutline"
                                      }
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.content.block
                                            .useMyLocationLinkText
                                        ) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.pickupPoints.length ||
                            _vm.pickupPoint.networking
                              ? _c(
                                  "div",
                                  { staticClass: "shipping__locations" },
                                  [
                                    _c(
                                      "c-form-radio-group",
                                      {
                                        attrs: {
                                          name: "selectedPickupPoint",
                                          stacked: "",
                                          highlighted: ""
                                        },
                                        model: {
                                          value: _vm.pickupPoint.selected,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pickupPoint,
                                              "selected",
                                              $$v
                                            )
                                          },
                                          expression: "pickupPoint.selected"
                                        }
                                      },
                                      _vm._l(_vm.mappedPickupPoints, function(
                                        location
                                      ) {
                                        return _c(
                                          "c-form-radio",
                                          {
                                            key: location.id,
                                            ref: "location-" + location.id,
                                            refInFor: true,
                                            attrs: {
                                              id: location.id,
                                              value: location.id
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "shipping__locations__pickuppoint"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "shipping__locations__pickuppoint__text"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "shipping__locations__pickuppoint__distance"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              location.distance
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          location.storeName
                                                        )
                                                      )
                                                    ]),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            location.streetAndNumber
                                                          ) +
                                                          ",\n                        " +
                                                          _vm._s(
                                                            location.postCode
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                location.openingHours
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "shipping__locations__pickuppoint__opening-hours"
                                                      },
                                                      _vm._l(
                                                        location.openingHours,
                                                        function(hours, index) {
                                                          return _c(
                                                            "p",
                                                            { key: index },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    hours.firstDay
                                                                  )
                                                              ),
                                                              hours.lastDay
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      "-" +
                                                                        _vm._s(
                                                                          hours.lastDay
                                                                        )
                                                                    )
                                                                  ])
                                                                : _vm._e(),
                                                              _vm._v(
                                                                ": " +
                                                                  _vm._s(
                                                                    hours.from
                                                                  ) +
                                                                  "-" +
                                                                  _vm._s(
                                                                    hours.to
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "c-modal",
              {
                ref: "changeContactNumberModal",
                attrs: {
                  id: "changeContactNumberModal",
                  gray: "",
                  centered: ""
                }
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "c-heading",
                      {
                        staticClass:
                          "shipping__overlay__title text-center px-3",
                        attrs: { slot: "header", level: "2", as: "1" },
                        slot: "header"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.content.block.changeContactNumberHeadline
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "shipping__overlay__body" },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.content.block.changeContactNumberDescription
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("c-input", {
                          attrs: {
                            "place-holder-label":
                              _vm.content.block.changeContactNumberInputLabel,
                            type: "text"
                          },
                          model: {
                            value: _vm.newPhoneNumber,
                            callback: function($$v) {
                              _vm.newPhoneNumber = $$v
                            },
                            expression: "newPhoneNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "shipping__overlay__footer" },
                      [
                        _c(
                          "c-button",
                          {
                            attrs: { type: "button", disabled: !_vm.ready },
                            on: { click: _vm.closeNewNumberOverlay }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.content.block
                                    .changeContactNumberOkButtonText
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("home-office-validation-modal", {
              attrs: {
                title: _vm.content.block.validationErrorModalText.heading,
                "error-message":
                  _vm.content.block.validationErrorModalText.content,
                visible: _vm.showCheckoutError
              }
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }