var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    staticClass:
      "\n    c-badge\n    c-badge--tertiary\n    c-badge--round\n    c-badge--round--small\n  ",
    class: { "c-badge--active": _vm.hasBasketItems }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }