var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "c-toast-manager" } },
    [
      _c(
        "div",
        { staticClass: "top-left" },
        [_c("portal-target", { attrs: { name: "top-left", slim: "" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "top-right" },
        [_c("portal-target", { attrs: { name: "top-right", slim: "" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-right" },
        [_c("portal-target", { attrs: { name: "bottom-right", slim: "" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-left" },
        [_c("portal-target", { attrs: { name: "bottom-left", slim: "" } })],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.toastCollection, function(toast) {
        return _c(
          "c-toast",
          {
            key: toast.id,
            attrs: {
              position: "TOP_RIGHT",
              state: toast.state,
              index: toast.id,
              visible: "",
              duration: toast.duration
            }
          },
          [
            _vm._v("\n    " + _vm._s(toast.title) + "\n    "),
            _c("template", { slot: "paragraph" }, [
              _vm._v("\n      " + _vm._s(toast.paragraph) + "\n    ")
            ])
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }