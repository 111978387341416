var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c(
        "c-row",
        { staticClass: "carousel__header" },
        [
          _c("c-col", [
            _c("h1", [_vm._v(_vm._s(_vm.content.headline))]),
            _vm._v(" "),
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.content.description) }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.offerings.length && _vm.isLoading
        ? _c(
            "c-row",
            _vm._l(2, function(step, idx) {
              return _c(
                "c-col",
                {
                  key: idx,
                  staticClass: "carousel__page-step",
                  attrs: { cols: "6", sm: "12" }
                },
                [
                  _c("skeleton-loader", {
                    staticClass: "carousel__page-step__skeleton-loader"
                  })
                ],
                1
              )
            }),
            1
          )
        : _c(
            "c-row",
            [
              _vm.offerings.length
                ? _c(
                    "c-col",
                    { staticClass: "carousel__wrapper" },
                    [
                      _vm.$resize && _vm.$mq.above(600)
                        ? _c(
                            "c-carousel",
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "slide",
                                      fn: function(ref) {
                                        var offering = ref.data
                                        return [
                                          _c(
                                            "subscription-card",
                                            {
                                              staticClass:
                                                "carousel__card subscription-card--fluid",
                                              attrs: {
                                                value: offering.code,
                                                "pre-selected":
                                                  _vm.preSelectedCode,
                                                selected: _vm.selectedCode,
                                                title: _vm.getVariantPropsWhenAvailable(
                                                  offering.variant,
                                                  "displayName"
                                                ),
                                                description: _vm.getVariantPropsWhenAvailable(
                                                  offering.variant,
                                                  "shortDescription"
                                                ),
                                                "action-selected-text":
                                                  _vm.content
                                                    .offeringCardButtonSelectedText,
                                                "action-text":
                                                  _vm.content
                                                    .offeringCardButtonText,
                                                "ribbon-text": _vm.getVariantPropsWhenAvailable(
                                                  offering.variant,
                                                  "ribbon"
                                                ),
                                                "employee-config-card": true,
                                                "badge-text": _vm.getVariantPropsWhenAvailable(
                                                  offering.variant,
                                                  "text"
                                                ),
                                                "no-padding": ""
                                              },
                                              on: { change: _vm.select }
                                            },
                                            [
                                              _c("template", { slot: "attr" }, [
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.getVariantPropsWhenAvailable(
                                                          offering.variant,
                                                          "speed"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getVariantPropsWhenAvailable(
                                                          offering.variant,
                                                          "unit"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ])
                                              ])
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  394902572
                                )
                              },
                              "c-carousel",
                              _vm.technologyCarouselOptions,
                              false
                            )
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.filteredOfferings, function(offering, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "carousel__mobile" },
                          [
                            _vm.$resize && _vm.$mq.below(600)
                              ? _c(
                                  "div",
                                  { staticClass: "carousel__mobile__card" },
                                  [
                                    _c(
                                      "subscription-card",
                                      {
                                        staticClass:
                                          "carousel__card subscription-card--fluid",
                                        attrs: {
                                          value: offering.code,
                                          selected: _vm.selectedCode,
                                          title: _vm.getVariantPropsWhenAvailable(
                                            offering.variant,
                                            "displayName"
                                          ),
                                          description: _vm.getVariantPropsWhenAvailable(
                                            offering.variant,
                                            "shortDescription"
                                          ),
                                          "action-selected-text":
                                            _vm.content
                                              .offeringCardButtonSelectedText,
                                          "action-text":
                                            _vm.content.offeringCardButtonText,
                                          "ribbon-text": _vm.getVariantPropsWhenAvailable(
                                            offering.variant,
                                            "ribbon"
                                          ),
                                          "employee-config-card": true,
                                          "badge-text": _vm.getVariantPropsWhenAvailable(
                                            offering.variant,
                                            "text"
                                          ),
                                          "no-padding": ""
                                        },
                                        on: { change: _vm.select }
                                      },
                                      [
                                        _c("template", { slot: "attr" }, [
                                          _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getVariantPropsWhenAvailable(
                                                    offering.variant,
                                                    "speed"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getVariantPropsWhenAvailable(
                                                    offering.variant,
                                                    "unit"
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }