<template>
  <keep-alive>
    <h1>{{ vm.headline }}</h1>
    <router-view v-cloak></router-view>
  </keep-alive>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { providerKey as checkoutProvider } from '../../providers/checkout-provider.js';
import VueRouter from 'vue-router';
import routerConfig from './numberRouter.js';

const DEFAULT_CONTENT = {
  findNewNumberLabel: 'Find dit nyt nummer',
  searchNewNumberLabel: 'Søg efter et nummer',
  moveNumberSelectButtonText: 'Vælg',
  moveNumberDateHeadline: 'Hvornår skal dit nummer flyttes?',
  moveNumberDateLabel: 'Vælg ønskedato',
  moveNumberDateDescription: 'Bestemt dato',
  subscriptionOwnerHeadline: 'Er du ejer af nummeret?',
  subscriptionOwnerYes: 'Ja',
  subscriptionOwnerNo: 'Nej',
  subscriptionOwnerCustomerSegmentHeadline:
    'Er nummeret tilknyttet et erhvervs- eller privat abonnement?',
  subscriptionOwnerCustomerSegmentPrivate: 'Privat',
  subscriptionOwnerCustomerSegmentBusiness: 'Erhverv',
  poaHeadline: 'Fuldmagt til nummerflytning',
  poaLabel: 'Jeg giver tilladelse til nummerflytning',
  poaManualLabel:
    'Vi sender dig en fuldmagt, som skal underskrives og sendes til din nuværende udbyder',
  notSubscriptionOwnerDescription:
    'Da du ikke er ejer af nummeret sender vi en fuldmagt, som ejeren af nummeret skal udfylde.',
  selectExistingNumberButtonText: 'Vælg',
  selectExistingNumberDisabledButtonText: 'Kan ikke vælges'
};

export default {
  name: 'NumberWrapper',

  router: new VueRouter(routerConfig),

  inject: [checkoutProvider],

  props: {
    apiBan: {
      type: String,
      required: true
    },
    apiNumberStatus: {
      type: String,
      required: true
    },
    apiCvr: {
      type: String,
      default: ''
    },
    apiGet: {
      type: String,
      required: true
    },
    apiSet: {
      type: String,
      required: true
    },
    configurationTypes: {
      type: Array,
      default: () => []
    },
    blockId: {
      type: String,
      required: true
    },
    dialogImageUrl: {
      type: String,
      default: ''
    },
    noNumberPoolNumber: {
      type: String,
      default: '04500000000'
    },
    simOrderPopupHeadline: {
      type: String,
      default: '',
      required: true
    },
    simOrderPopupDescription: {
      type: String,
      default: '',
      required: false
    },
    simOrderPopupButtontext: {
      type: String,
      default: '',
      required: true
    },
    simOrderPopupButtonlink: {
      type: String,
      default: '',
      required: false
    },
    availableNumbers: {
      type: Array,
      default: () => []
    },
    hasMoreAvailableNumbers: {
      type: Boolean
    },
    bindingPeriodLabel: {
      type: String,
      default: '',
      required: false
    },
    vm: {
      type: Object,
      default: () => null,
      required: false
    },
    disabledDates: {
      type: Array,
      default: () => [],
      required: false
    },
    validateNumberPorting: {
      type: Boolean
    },
    moveNumberSelectButtonText: {
      type: String,
      default: 'Vælg'
    },
    enableNumberSearch: {
      type: Boolean
    },
    enableExistingNumberSelection: {
      type: Boolean
    },
    noExistingNumberAvailableLabel: {
      type: String,
      default: undefined
    },
    isDivaEnabled: {
      type: Boolean,
      default: false
    },
    showSimDeliveryOptions: {
      type: Boolean,
      default: false
    },
    numberPoolLifeSpan: {
      type: Number,
      default: 3600 // Set default to 1 hour (in milliseconds)
    },
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  computed: {
    ...mapGetters([
      'numberConfigCompleteLength',
      'numberConfigTotal',
      'contextId'
    ]),
    proceed() {
      return this.numberConfigCompleteLength === this.numberConfigTotal;
    }
  },

  watch: {
    proceed() {
      this.updateCheckoutForm();
    },
    $route() {
      this.updateCheckoutForm();
    }
  },

  mounted() {
    /* Temporary solution */
    document.getElementById('app').classList.add('cm-c-app__checkout');
    /* Remove when BE is in place */
    setTimeout(() => {
      if (this.apiGet) {
        this.fetchContentCommit(
          this.apiGet + `?contextId=${this.contextId}`,
          this.SET_NUMCONF_DATA
        );
      }
      const vm = Object.assign({}, this.vm, {
        apiBan: this.apiBan + `?contextId=${this.contextId}`,
        apiCvr: this.apiCvr + `?contextId=${this.contextId}`,
        apiSearchNumber: `/api/olympus/checkout/numbersearch?contextId=${this.contextId}&pattern=`,
        apiSet: this.apiSet + `?contextId=${this.contextId}`,
        apiNumberStatus: this.apiNumberStatus + `?contextId=${this.contextId}`,
        configTypes: this.configurationTypes,
        dialogImageUrl: this.dialogImageUrl,
        bindingPeriodLabel: this.bindingPeriodLabel,
        availableNumbers: this.availableNumbers,
        hasMoreAvailableNumbers: this.hasMoreAvailableNumbers,
        noNumberPoolNumber: this.noNumberPoolNumber,
        disabledDates: this.disabledDates,
        disableNewNumber: this.contentLocal.disableNewNumber,
        isDivaEnabled: this.isDivaEnabled,
        showSimDeliveryOptions: this.showSimDeliveryOptions,
        validateNumberPorting: this.validateNumberPorting,
        simOnlyOrderPopup: {
          headline: this.simOrderPopupHeadline,
          description: this.simOrderPopupDescription,
          buttonLabel: this.simOrderPopupButtontext,
          buttonLink: this.simOrderPopupButtonlink
        },
        enableNumberSearch: this.enableNumberSearch,
        findNewNumberLabel: this.contentLocal.findNewNumberLabel,
        searchNewNumberLabel: this.contentLocal.searchNewNumberLabel,
        moveNumberDateHeadline: this.contentLocal.moveNumberDateHeadline,
        moveNumberDateLabel: this.contentLocal.moveNumberDateLabel,
        subscriptionOwnerHeadline: this.contentLocal.subscriptionOwnerHeadline,
        subscriptionOwnerYes: this.contentLocal.subscriptionOwnerYes,
        subscriptionOwnerNo: this.contentLocal.subscriptionOwnerNo,
        subscriptionOwnerCustomerSegmentHeadline:
          this.contentLocal.subscriptionOwnerCustomerSegmentHeadline,
        subscriptionOwnerCustomerSegmentPrivate:
          this.contentLocal.subscriptionOwnerCustomerSegmentPrivate,
        subscriptionOwnerCustomerSegmentBusiness:
          this.contentLocal.subscriptionOwnerCustomerSegmentBusiness,
        poaHeadline: this.contentLocal.poaHeadline,
        poaLabel: this.contentLocal.poaLabel,
        poaManualLabel: this.contentLocal.poaManualLabel,
        notSubscriptionOwnerDescription:
          this.contentLocal.notSubscriptionOwnerDescription,
        selectExistingNumberButtonText:
          this.contentLocal.selectExistingNumberButtonText,
        selectExistingNumberDisabledButtonText:
          this.contentLocal.selectExistingNumberDisabledButtonText,
        enableExistingNumberSelection: this.enableExistingNumberSelection,
        noExistingNumberAvailableLabel: this.noExistingNumberAvailableLabel,
        numberPoolLifeSpan: this.numberPoolLifeSpan,
        companyCheckButtonText: this.contentLocal.companyCheckButtonText,
        companyInfoSectionTitle: this.contentLocal.companyInfoSectionTitle,
        companyManualSearch: this.contentLocal.companyManualSearch,
        enableNumberConfigurationConsents:
          this.contentLocal.enableNumberConfigurationConsents,
        consentAcceptText: this.contentLocal.consentAcceptText,
        consents: this.contentLocal.consents
      });
      this.SET_NUMCONF_VM(vm);
      this.updateCheckoutForm();
    }, 300);
  },

  methods: {
    ...mapMutations(['SET_NUMCONF_DATA', 'SET_NUMCONF_VM']),
    async fetchContentCommit(url, stateMutation) {
      let data = await (await fetch(url)).json();
      if (data && data.message) {
        console.error(data);
      } else {
        /* WIP: configComplete and ConfigEdited are set from FE */
        data.forEach(item => {
          item.configComplete = item.phoneNumber ? true : false;
          item.configEdited = false;
          item.code = this.uuid();
          item.blockId = this.blockId;
        });
        stateMutation({ data });
      }
    },
    updateCheckoutForm() {
      const onRoot = this.$router.currentRoute.path === '/';
      const show = onRoot && this.proceed;

      this[checkoutProvider].toggleSubmitButton(show);
    },
    uuid() {
      return '_' + Math.random().toString(36).substr(2, 9);
    }
  }
};
</script>
