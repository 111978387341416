<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { GETTERS as BASKET_GETTERS } from '../../store/basket';
import { ACTIONS } from '../../store/shop/mutation-types.js';
import { useCheckoutFinancingValidation } from './checkout-financing-validation.js';
import FinancingConflictOverlay from './financing-conflict-overlay.vue';
import PriceSection from './price-section/price-section.vue';
import {
  CCollapseTrigger,
  CCollapse,
  CButton
} from 'olympus/components/index.js';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';

import { providerKey as BASKET_STEP_PROVIDER_KEY } from './basket-step-provider.js';

import template from 'theme/templates/price-summary.html';
import RootBusMixin from 'olympus/mixins/root-bus-mixin.js';

import CheckoutFinanceSummary from 'webshop/components/checkout/checkout-finance-summary.vue';

export default template({
  name: 'price-summary',

  inject: [BASKET_STEP_PROVIDER_KEY],

  components: {
    PriceSection,
    PopoverPanel,
    CCollapseTrigger,
    CCollapse,
    CButton,
    FinancingConflictOverlay,
    CheckoutFinanceSummary
  },

  mixins: [RootBusMixin],

  data() {
    const checkoutFinancingValidation = useCheckoutFinancingValidation(this);

    return {
      sectionContent: {
        periodLabel: this.content.periodLabel,
        promotionCodeAppliedLabel: this.content.promotionCodeAppliedLabel
      },
      minimalPriceContent: {
        promotionCodeAppliedLabel: this.content.promotionCodeAppliedLabel,
        subscriptionDiscountLabel: this.content.subscriptionDiscountLabel,
        offerDiscountLabel: this.content.offerDiscountLabel
      },

      checkoutFinancingValidation
    };
  },

  props: {
    content: {
      type: Object,
      default: () => ({
        heading: '',
        totalMonthlyLabel: '',
        totalUpfrontLabel: '',
        subscriptionsLabel: '',
        installmentsLabel: '',
        upfrontLabel: '',
        periodLabel: '',
        totalUpfrontPriceLabelVAT: '',
        totalUpfrontVATAmountLabel: '',
        financingConflictTitle: '',
        financingConflictMessage: '',
        financingConflictApproveButton: '',
        financingConflictDeclineButton: '',
        productSectionHeadline: ''
      })
    },
    blockId: {
      type: Number,
      required: true
    },
    upfrontPayment: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isFinancingConflictEnabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      basket: BASKET_GETTERS.GROUPS,
      orderNumber: 'orderNumber'
    }),
    ...mapState({
      basketPrices: state => {
        return state.shop.basketPrices;
      },
      summary: state => {
        return state.shop.pricesSummary;
      }
    }),
    nextPage() {
      return this[BASKET_STEP_PROVIDER_KEY].nextPage;
    },
    nextPageButtonText() {
      return this[BASKET_STEP_PROVIDER_KEY].nextPageButtonText;
    },
    financingOverlayContent() {
      return {
        title: this.content.financingConflictTitle,
        message: this.content.financingConflictMessage,
        approveButton: this.content.financingConflictApproveButton,
        declineButton: this.content.financingConflictDeclineButton
      };
    },
    containerClassModifier() {
      return {
        'cm-grid__col--xs-12': !this.fullWidth
      };
    },
    subContainerClassModifier() {
      return {
        'price-summary': !this.fullWidth
      };
    }
  },

  watch: {
    basket: {
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      fetchBasketPriceDetails: ACTIONS.FETCH_BASKET_PRICE_DETAILS,
      fetchBasketPriceSummary: ACTIONS.FETCH_BASKET_PRICE_SUMMARY
    }),
    fetchData() {
      this.fetchBasketPriceDetails({
        blockId: this.blockId,
        upfrontPayment: this.upfrontPayment,
        orderNumber: this.orderNumber
      });
      this.fetchBasketPriceSummary({
        upfrontPayment: this.upfrontPayment,
        orderNumber: this.orderNumber,
        includeFinanceSummary: this.content.includeFinanceSummary
      });
    },

    onNextButtonClicked() {
      this.checkoutFinancingValidation.checkForFinancingConflict();
    },

    navigateToNextPage() {
      window.location = this.nextPage;
    }
  }
});
</script>
