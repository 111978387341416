var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "split-navigation c-wrapper" }, [
    _c("div", { staticClass: "split-navigation__header" }, [
      _c(
        "a",
        { attrs: { href: "/" } },
        [
          _c("c-icon", {
            staticClass: "split-navigation__menu-icon",
            attrs: { "symbol-id": "menu-logo" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "split-navigation__container" }, [
      _c(
        "section",
        { staticClass: "split-navigation__option" },
        [
          _c(
            "div",
            { staticClass: "split-navigation__symbols" },
            [
              _vm.legacyBadgeText
                ? _c("div", { staticClass: "split-navigation__badge" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.legacyBadgeText) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("c-icon", {
                staticClass: "split-navigation__icon",
                attrs: { "symbol-id": _vm.legacyIcon.markupId }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("h2", { staticClass: "split-navigation__heading" }, [
            _vm._v(_vm._s(_vm.legacyHeadline))
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "split-navigation__description",
            domProps: { innerHTML: _vm._s(_vm.legacyDescription) }
          }),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "c-btn--inline",
              on: {
                click: function($event) {
                  return _vm.goTo(_vm.legacyButtonUrl)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.legacyButtonText) + "\n      ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "split-navigation__divider" }),
      _vm._v(" "),
      _c("section", { staticClass: "split-navigation__option" }, [
        _c(
          "div",
          { staticClass: "split-navigation__symbols" },
          [
            _vm.betaBadgeText
              ? _c("div", { staticClass: "split-navigation__badge" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.betaBadgeText) + "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("c-icon", {
              staticClass: "split-navigation__icon",
              attrs: { "symbol-id": _vm.betaIcon.markupId }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "split-navigation__heading" }, [
          _vm._v(_vm._s(_vm.betaHeadline))
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("div", {
              staticClass: "split-navigation__description",
              domProps: { innerHTML: _vm._s(_vm.betaDescription) }
            }),
            _vm._v(" "),
            _c(
              "c-button",
              {
                staticClass: "c-btn--inline",
                on: {
                  click: function($event) {
                    return _vm.goTo(_vm.betaButtonUrl)
                  }
                }
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.betaButtonText) + "\n        "
                )
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }