var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      attrs: { visible: _vm.showLoyaltyVasModal, gray: "" },
      on: {
        close: function($event) {
          return _vm.closeModal()
        }
      }
    },
    [
      [
        _c(
          "div",
          {
            staticClass: "text-center",
            attrs: { slot: "header" },
            slot: "header"
          },
          [
            _c("h2", [
              _c("strong", [_vm._v(_vm._s(_vm.content.loyaltyVasHeadline))])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2 text-center" }, [
          _c("h4", [_vm._v(_vm._s(_vm.content.loyaltyVasDescription))])
        ])
      ],
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "cm-grid-vaslist cm-c-category-col" },
          _vm._l(_vm.loyaltyVases, function(loyaltyVas, index) {
            return _c("c-vas-card", {
              key: index,
              attrs: { vas: loyaltyVas, "as-radio-select": true },
              on: { "loyalty-vas-click": _vm.handleLoyaltyVasClick }
            })
          }),
          1
        )
      ],
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "c-button",
            {
              attrs: {
                disabled: _vm.networking,
                "data-testid": "continue",
                type: "button"
              },
              on: { click: _vm.handleLoyaltyVasSelected }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.content.loyaltyVasButtonText) + "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.showLoyaltyVasModalError
            ? _c(
                "span",
                {
                  staticClass: "error-message",
                  attrs: { "data-testid": "error-message" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.content.loyaltyVasErrorMessage)
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "c-button",
            {
              attrs: {
                "data-testid": "cancel",
                type: "button",
                variant: "link",
                disabled: _vm.networking
              },
              on: { click: _vm.handleLoyaltyVasNotSelected }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.content.loyaltyVasLinkText) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }