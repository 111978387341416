const MUTATIONS = {
  SHOW_NUMBER_MODAL: 'MUTATIONS/SHOW_NUMBER_MODAL',
  HIDE_NUMBER_MODAL: 'MUTATIONS/HIDE_NUMBER_MODAL',
  SET_SELECTED_PHONE_NUMBER: 'MUTATIONS/SET_SELECTED_PHONE_NUMBER'
};

export const ACTIONS = {
  SHOW_NUMBER_MODAL: 'ACTIONS/SHOW_NUMBER_MODAL',
  HIDE_NUMBER_MODAL: 'ACTIONS/HIDE_NUMBER_MODAL',
  SET_SELECTED_PHONE_NUMBER: 'ACTIONS/SET_SELECTED_PHONE_NUMBER'
};

export const GETTERS = {
  GET_NUMBER_MODAL_VALUE: 'GETTERS/GET_NUMBER_MODAL_VALUE',
  GET_SELECTED_PHONE_NUMBER: 'GETTERS/GET_SELECTED_PHONE_NUMBER'
};

/**
 * Manages state for the number select modal that is shown to logged in users coming back to a product page.
 *
 * state {
 *  showNumberSelectionModal: boolean,
 *  selectedPhoneNumber: string
 * }
 */
export default {
  state: {
    showNumberSelectionModal: false,
    selectedPhoneNumber: null
  },

  mutations: {
    [MUTATIONS.SHOW_NUMBER_MODAL](state) {
      state.showNumberSelectionModal = true;
    },

    [MUTATIONS.HIDE_NUMBER_MODAL](state) {
      state.showNumberSelectionModal = false;
    },

    [MUTATIONS.SET_SELECTED_PHONE_NUMBER](state, { selectedPhoneNumber }) {
      state.selectedPhoneNumber = selectedPhoneNumber;
    }
  },

  actions: {
    [ACTIONS.SHOW_NUMBER_MODAL]({ commit }) {
      commit(MUTATIONS.SHOW_NUMBER_MODAL);
    },

    [ACTIONS.HIDE_NUMBER_MODAL]({ commit }) {
      commit(MUTATIONS.HIDE_NUMBER_MODAL);
    },

    [ACTIONS.SET_SELECTED_PHONE_NUMBER]({ commit }, { selectedPhoneNumber }) {
      commit(MUTATIONS.SET_SELECTED_PHONE_NUMBER, { selectedPhoneNumber });
    }
  },

  getters: {
    [GETTERS.GET_NUMBER_MODAL_VALUE]: state => state.showNumberSelectionModal,
    [GETTERS.GET_SELECTED_PHONE_NUMBER]: state => state.selectedPhoneNumber
  }
};
