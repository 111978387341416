<template>
  <div class="resurs-banner__container">
    <c-icon class="resurs-banner__resurs-logo" symbol-id="logo-resurs-bank" />

    <div class="resurs-banner__header">
      <span class="resurs-banner__title">
        {{ content.header }}
      </span>

      <span
        v-if="content.elaborativeHeader"
        class="resurs-banner__title--elaborative"
      >
        {{ content.elaborativeHeader }}
      </span>
    </div>

    <div class="resurs-banner__description">
      <div class="resurs-banner__content" v-html="content.description"></div>
    </div>

    <div v-if="content.buttonText" class="resurs-banner__button">
      <a
        class="c-btn c-btn--xs resurs-banner__link"
        :target="content.linkTarget"
        :href="content.url"
      >
        {{ content.buttonText }}
      </a>
    </div>
  </div>
</template>
<script>
import CIcon from 'webshop/components/c-icon/c-icon.vue';

export default {
  name: 'ResursBanner',

  components: {
    CIcon
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  }
};
</script>
