var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { class: _vm.userCardClasses }, [
    _c(
      "article",
      { staticClass: "user-card__content" },
      [
        _c(
          "figure",
          [
            _vm._t("user-image", [
              !_vm.disabled
                ? _c("c-icon", { attrs: { "symbol-id": _vm.enabledIcon } })
                : _c("c-icon", { attrs: { "symbol-id": _vm.disabledIcon } })
            ]),
            _vm._v(" "),
            _c("figcaption", [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.phoneNumber) +
                      "\n            "
                  ),
                  _vm.userName
                    ? _c("span", [_vm._v("(" + _vm._s(_vm.userName) + ")")])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.subscriptionName))])
            ])
          ],
          2
        ),
        _vm._v(" "),
        _vm._t("right-action", [
          _c(
            "button",
            {
              staticClass: "c-btn c-btn--sm",
              class: { "c-btn--disabled": _vm.disabled },
              attrs: { type: "button" },
              on: { click: _vm.userRightActionPressed }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.disabled
                      ? _vm.rightActionDisabledButtonText
                      : _vm.rightActionButtonText
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "user-card__description" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }