<template>
  <div
    ref="carousel"
    class="c-carousel"
    :class="{
      'c-carousel--draggable': draggable,
      'c-carousel--multislider': !singleSlide,
      'c-carousel--dots': pageDots
    }"
  >
    <ul
      ref="list"
      class="c-carousel__list"
      :class="{
        'c-carousel__list--multilist': !singleSlide,
        'c-carousel__list--swipe': smoothSwiping
      }"
      @scroll="setLeftScroll"
    >
      <li
        v-for="(currentSlide, idx) in list"
        :key="idx"
        :ref="'slide' + idx"
        :data-index="idx"
        :class="{
          'c-carousel__item': true,
          'c-carousel__item--to-right': currentSlide.toRight,
          'c-carousel__item--to-left': currentSlide.toLeft,
          'c-carousel__item--right': currentSlide.right,
          'c-carousel__item--left': currentSlide.left,
          'c-carousel__item--active': currentSlide.active,
          'c-carousel__item--center': centerSlides,
          'c-carousel__item--flex': !singleSlide || smoothSwiping,
          'c-carousel__item--swipe': smoothSwiping
        }"
        :style="getSlideStyles"
      >
        <slot name="slide" :data="currentSlide.content">
          {{ currentSlide }}
        </slot>
      </li>
    </ul>
    <div
      v-if="navigation"
      class="c-carousel__navigation"
      :class="{
        'c-carousel__navigation--rounded': roundedNavigationButtons,
        'c-carousel__navigation--position-top': navigationPositionFixed
      }"
    >
      <button
        v-show="hasPrev"
        type="button"
        itemscope
        itemtype="http://www.schema.org/SiteNavigationElement"
        data-slide="prev"
        :style="getNavigationTopPosition"
        @click="prev()"
      >
        <span
          class="
            c-carousel__navigation-arrow c-carousel__navigation-arrow--prev
          "
        ></span>
        <span class="c-carousel__navigation-text">Previous Slide</span>
      </button>
      <button
        v-show="hasNext"
        type="button"
        itemscope
        itemtype="http://www.schema.org/SiteNavigationElement"
        data-slide="next"
        :style="getNavigationTopPosition"
        @click="next()"
      >
        <span
          class="
            c-carousel__navigation-arrow c-carousel__navigation-arrow--next
          "
        ></span>
        <span class="c-carousel__navigation-text">Next Slide</span>
      </button>
    </div>
    <ol v-if="pageDots && slides > 1" class="c-carousel__page-dots">
      <li
        v-for="idx in slides"
        :key="idx"
        :aria-label="`Carousel page dot ${idx}`"
        class="c-carousel__page-dots__dot"
        :class="{
          'c-carousel__page-dots__dot--active': idx - 1 === indexLocal
        }"
        @click="dotClickHandler(idx - 1)"
      ></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'CCarousel',

  model: {
    prop: 'index',
    event: 'update'
  },

  props: {
    data: {
      type: Array,
      default: undefined
    },
    index: {
      type: Number,
      default: 0
    },
    navigation: {
      type: Boolean,
      default: true
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayInterval: {
      type: Number,
      default: 5000
    },
    singleSliderSpeed: {
      type: Number,
      default: 600
    },
    multiSliderSpeed: {
      type: Number,
      default: 300
    },
    draggable: {
      type: Boolean,
      default: false
    },
    pageDots: {
      type: Boolean,
      default: false
    },
    centerSlides: {
      type: Boolean,
      default: false
    },
    smoothSwiping: {
      type: Boolean,
      default: false
    },
    itemsPerSlide: {
      type: Number,
      default: 1
    },
    roundedNavigationButtons: {
      type: Boolean,
      default: false
    },
    navigationPositionFixed: {
      type: Boolean,
      default: false
    }
  },

  emits: ['ready'],

  data() {
    return {
      endX: 0,
      endY: 0,
      list: [],
      playing: false,
      sliding: false,
      startX: 0,
      startY: 0,
      timeout: 0,
      dragStartX: 0,
      dragStartY: 0,
      dragThreshold: 10,
      itemsPerSlideFixed: this.itemsPerSlide,
      indexLocal: 0,
      leftScroll: 0,
      isScrolling: null,
      carouselWidth: 0,
      slotObserver: null,
      navigationPositionTop: 0
    };
  },

  computed: {
    hasMultiple() {
      return Boolean(this.data && this.data.length > 1);
    },
    hasPrev() {
      if (this.hideNav) {
        return false;
      }

      return (
        (this.hasMultiple && this.indexLocal > 0) ||
        (this.hasMultiple && this.loop)
      );
    },
    hasNext() {
      if (this.hideNav) {
        return false;
      }

      return (
        (this.hasMultiple && this.indexLocal < this.slides - 1) ||
        (this.hasMultiple && this.loop)
      );
    },
    hideNav() {
      return Boolean(this.data && this.data.length <= this.itemsPerSlide);
    },
    getSlideStyles() {
      let cardFlex = 100 / this.itemsPerSlideFixed;
      if (this.itemsPerSlide > 1 && this.itemsPerSlideFixed === 1) {
        cardFlex -= 16;
      } else if (this.itemsPerSlide > 2 && this.itemsPerSlideFixed === 2) {
        cardFlex -= 6;
      }

      return {
        '--transition': `transform ${this.multiSliderSpeed}ms ease`,
        '--card-flex': `${cardFlex}%`,
        '--card-transform': `translateX(-${
          this.itemsPerSlideFixed * this.indexLocal * 100
        }%)`
      };
    },
    getNavigationTopPosition() {
      return {
        '--position-top': `${this.navigationPositionTop / 2}px`
      };
    },
    slides() {
      return Math.ceil(this.data.length / this.itemsPerSlideFixed);
    },
    singleSlide() {
      return this.itemsPerSlide === 1;
    },
    slideInView() {
      const carouselHalf = this.carouselWidth / 2;
      return (
        Math.floor((this.leftScroll + carouselHalf) / this.carouselWidth) + 1
      );
    }
  },

  watch: {
    data() {
      this.init();
    },
    index(val) {
      const calcSlide = Math.floor(val / this.itemsPerSlideFixed);
      this.slideTo(calcSlide);
      if (this.itemsPerSlideFixed === 1) {
        this.snapToIndex();
      }
    },
    indexLocal(val) {
      this.$emit('update', val);
    }
  },

  created() {
    this.init();
    this.$emit('ready');
  },

  mounted() {
    try {
      const config = { attributes: true, childList: true, subtree: true };
      this.slotObserver = new MutationObserver(() => {
        this.setCarouselWidth(true);
      });
      this.slotObserver.observe(this.$refs.slide0[0], config);
      document.addEventListener('visibilitychange', this.onVisibilityChange);
      if (this.autoplay) {
        this.play();
      }
      const { carousel } = this.$refs;
      if (this.draggable) {
        carousel.addEventListener('mousedown', this.onDragStart);
        carousel.addEventListener('mouseup', this.onDragEnd);
        carousel.addEventListener('touchstart', this.onDragStart);
        carousel.addEventListener('touchend', this.onDragEnd);
      }
      this.$nextTick(() => {
        window.addEventListener('resize', this.windowSizeHandler);
      });
      this.setCarouselWidth();

      if (this.navigationPositionFixed) {
        this.navigationPositionTop =
          this.$refs.list.getBoundingClientRect().height;
      }

      this.windowSizeHandler();
    } catch {
      // Failed to initialize carousel. Possibly no slides passed.
    }
  },

  beforeDestroy() {
    this.slotObserver.disconnect();
    document.removeEventListener('visibilitychange', this.onVisibilityChange);

    if (this.draggable) {
      const { carousel } = this.$refs;
      carousel.removeEventListener('mousedown', this.onDragStart);
      carousel.removeEventListener('mouseup', this.onDragEnd);
      carousel.removeEventListener('touchstart', this.onDragStart);
      carousel.removeEventListener('touchend', this.onDragEnd);
    }

    window.removeEventListener('resize', this.windowSizeHandler);
  },

  methods: {
    init() {
      const { data } = this;

      if (data && data.length) {
        this.indexLocal = Math.min(this.indexLocal, data.length - 1);
        this.list = data
          .filter(x => Boolean(x))
          .map((x, index) => {
            const active =
              this.itemsPerSlide > 1 || this.smoothSwiping
                ? true
                : index === this.indexLocal;
            return {
              ...(x && x.content !== undefined
                ? x
                : {
                    content: x
                  }),
              raw: x,
              active,
              sliding: active,
              left: false,
              right: false,
              toLeft: false,
              toRight: false
            };
          });
      } else {
        this.list = [];
      }
    },
    onVisibilityChange() {
      if (this.playing) {
        if (document.visibilityState === 'visible') {
          this.cycle();
        } else {
          this.pause();
        }
      }
    },
    play() {
      if (!this.playing) {
        this.playing = true;
        this.cycle();
      }
    },
    cycle() {
      if (this.playing) {
        this.pause();
        this.timeout = setTimeout(() => {
          this.next(() => {
            this.cycle();
          });
        }, this.autoplayInterval);
      }
    },
    pause() {
      clearTimeout(this.timeout);
      this.timeout = 0;
    },
    stop() {
      if (this.playing) {
        this.pause();
        this.playing = false;
      }
    },
    prev() {
      this.slideTo(this.indexLocal - 1);
    },
    next() {
      this.slideTo(this.indexLocal + 1);
    },
    slide(index, reverse = false) {
      let slideIndex = index;

      if (document.hidden || this.sliding) {
        return;
      }

      this.sliding = true;

      const { list } = this;
      const minIndex = 0;
      const maxIndex = this.slides - 1;
      if (slideIndex > maxIndex) {
        slideIndex = minIndex;
      } else if (slideIndex < minIndex) {
        slideIndex = maxIndex;
      }
      const active =
        this.singleSlide && !this.smoothSwiping ? list[this.indexLocal] : false;
      const next =
        this.singleSlide && !this.smoothSwiping ? list[slideIndex] : false;

      if (slideIndex === this.indexLocal) {
        return;
      }

      if (this.singleSlide && !this.smoothSwiping) {
        next.right = !reverse;
        next.left = reverse;
      }

      this.$nextTick(() => {
        if (this.singleSlide && !this.smoothSwiping) {
          active.toLeft = !reverse;
          active.toRight = reverse;
          next.toLeft = !reverse;
          next.toRight = reverse;

          active.sliding = false;
          next.sliding = true;
        }
        if (this.singleSlide && !this.smoothSwiping) {
          setTimeout(() => {
            active.active = false;
            active.right = false;
            active.left = false;
            active.toRight = false;
            active.toLeft = false;

            next.active = true;
            next.right = false;
            next.left = false;
            next.toRight = false;
            next.toLeft = false;

            this.indexLocal = slideIndex;
            this.sliding = false;
          }, this.singleSliderSpeed);
        } else {
          this.indexLocal = slideIndex;
          this.sliding = false;
        }
      });
    },
    slideTo(index) {
      if (index === this.indexLocal) {
        return;
      }
      let reverse = index < this.indexLocal;
      this.slide(index, reverse);
    },
    snapToSlide(element, to, duration) {
      const start = element.scrollLeft;
      const change = to - start;
      const startDate = +new Date();
      const easeInOutQuad = function (t, b, c, d) {
        let nt = t;
        nt /= d / 2;
        if (nt < 1) {
          return (c / 2) * nt * nt + b;
        }
        nt--;
        return (-c / 2) * (nt * (nt - 2) - 1) + b;
      };
      const animateScroll = function () {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollLeft = parseInt(
          easeInOutQuad(currentTime, start, change, duration),
          10
        );
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollLeft = to;
        }
      };
      animateScroll();
    },
    snapToIndex() {
      this.snapToSlide(
        this.$refs.list,
        this.carouselWidth * Math.floor(this.index / this.itemsPerSlideFixed),
        200
      );
    },
    getPressPosition(event) {
      let x = 0,
        y = 0;

      try {
        const { clientX, clientY } = event.changedTouches[0];
        x = clientX;
        y = clientY;
      } catch {
        const { clientX, clientY } = event;
        x = clientX;
        y = clientY;
      }

      return { x: x || 0, y: y || 0 };
    },
    onDragStart(event) {
      if (this.singleSlide && !this.smoothSwiping) {
        const { x, y } = this.getPressPosition(event);

        this.dragStartX = x;
        this.dragStartY = y;
      }
    },
    onDragEnd(event) {
      if (this.singleSlide && !this.smoothSwiping) {
        const { x } = this.getPressPosition(event);

        const xDelta = this.dragStartX - x;

        if (xDelta >= this.dragThreshold) {
          this.slide(this.indexLocal + 1);
        } else if (xDelta < -this.dragThreshold) {
          this.slide(this.indexLocal - 1);
        }
      }
    },
    windowSizeHandler() {
      if (this.smoothSwiping) {
        const width = document.documentElement.clientWidth;

        if (!this.singleSlide) {
          if (width < 600 && this.itemsPerSlideFixed !== 1) {
            this.itemsPerSlideFixed = 1;
            this.resetScrollPosition();
          } else if (width < 1024 && this.itemsPerSlideFixed > 2) {
            this.itemsPerSlideFixed = 2;
            this.resetScrollPosition();
          } else if (width > 1024) {
            this.itemsPerSlideFixed = this.itemsPerSlide;
            this.resetScrollPosition();
          }
        }
      }
    },
    resetScrollPosition() {
      this.slideTo(this.indexLocal);
      this.snapToIndex();
    },
    setLeftScroll(event) {
      const element = event.target;
      const width = document.documentElement.clientWidth;
      this.leftScroll = element.scrollLeft;

      if (width < 800) {
        if (!this.isScrolling) {
          this.slideTo(this.slideInView - 1);
        }

        clearTimeout(this.isScrolling);

        this.isScrolling = setTimeout(() => {
          this.slideTo(this.slideInView - 1);
          this.snapToSlide(
            this.$refs.list,
            this.carouselWidth * (this.slideInView - 1),
            200
          );
        }, 80);
      }
    },
    setCarouselWidth(slotLoaded = false) {
      const { list, slide0 } = this.$refs;

      if (slotLoaded && this.slotObserver) {
        this.slotObserver.disconnect();
      }

      this.carouselWidth =
        this.itemsPerSlideFixed < 3
          ? slide0[0].getBoundingClientRect().width * this.itemsPerSlideFixed
          : list.getBoundingClientRect().width;
    },
    dotClickHandler(index) {
      this.slideTo(index);

      const width = document.documentElement.clientWidth;
      if (width < 800) {
        this.snapToSlide(this.$refs.list, this.carouselWidth * index, 200);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/tools/_tools.media-query.scss';
@import '../../styles/breakpoints.scss';

$button-size: 18px;
$arrow-width: 3px;
$dot-size: 4px;

.c-carousel {
  position: relative;
  user-select: none;
  padding: 0 $button-size;

  &__list {
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__item {
    display: none;
    margin: 0;

    img {
      &:not(.allow-click) {
        pointer-events: none;
      }

      &.allow-click {
        cursor: pointer;
        user-select: none;
      }
    }

    &--active,
    &--right,
    &--left {
      display: block;
      transition: transform 0.3s ease-in-out;
      width: 100%;
      will-change: transform, opacity;

      &.c-carousel__item--center {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }

    &--right,
    &--left {
      left: 0;
      position: absolute;
      top: 0;
    }

    &--to-right {
      transform: translateX(-100%);
    }

    &--to-left {
      transform: translateX(100%);
    }

    &--right {
      transform: translateX(100%);
    }

    &--left {
      transform: translateX(-100%);
    }

    &--active {
      position: relative;
      transform: translate(0, 0);

      &.c-carousel__item--to-right {
        transform: translateX(100%);
      }

      &.c-carousel__item--to-left {
        transform: translateX(-100%);
      }
    }

    &--flex {
      flex: 0 0 var(--card-flex);
      opacity: 1;
      padding: 0 10px;
      transform: var(--card-transform);
      transition: var(--transition);
      will-change: transform;

      @include mq($breakpoint-desktop, min) {
        padding: 0 12px;
      }
    }
  }

  // .c-carousel__navigation
  &__navigation {
    & button {
      border: none;
      border-radius: 0;
      outline: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      color: inherit;
      font: inherit;
      line-height: normal;
      appearance: none;
      position: absolute;
      top: calc(50% - #{$button-size / 2});

      &:first-child {
        left: $arrow-width;
      }

      &:last-child {
        right: $arrow-width;
      }
    }

    &-text {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    &-arrow {
      border-bottom: $arrow-width solid black;
      border-right: $arrow-width solid black;
      content: '';
      display: block;
      height: $button-size;
      width: $button-size;

      &--prev {
        transform: rotate(135deg);
      }

      &--next {
        transform: rotate(-45deg);
      }
    }
  }

  // .c-carousel__page-dots
  &__page-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &__dot {
      width: $dot-size;
      height: $dot-size;
      margin: $dot-size * 3 $dot-size * 1.5 0 $dot-size * 1.5;
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.1);

      &:after {
        // Create a larger invisble touch area to interact with each dots for increased a11y.
        content: '';
        cursor: pointer;
        display: inline-block;
        width: $dot-size * 3;
        height: $dot-size * 3;
        flex-shrink: 0;
      }

      &--active {
        background: #222;
      }
    }
  }

  // .c-carousel--draggable
  &--draggable {
    cursor: pointer;
  }

  // .c-carousel--dots
  &--dots {
    margin-bottom: $dot-size * 3;
  }
}
</style>
