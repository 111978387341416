import { setAttr, removeAttr, addClass, removeClass } from '../utils/dom.js';
import { eventOn, eventOff, getEventName } from '../utils/event.js';
import { resetProp } from '../utils/object.js';
import EVENT_NAMESPACES from '../constants/events.js';

const EVENT_STATE = getEventName(EVENT_NAMESPACES.COLLAPSE, 'state');
const EVENT_REQUEST_STATE = getEventName(
  EVENT_NAMESPACES.TOGGLE,
  'request-state'
);
const EVENT_TOGGLE = getEventName(EVENT_NAMESPACES.TOGGLE, 'collapse');

const V_TOGGLE = '__V_toggle__';
const V_TOGGLE_STATE = '__V_toggle_STATE__';
const V_TOGGLE_CONTROLS = '__V_toggle_CONTROLS__';
const V_TOGGLE_TARGETS = '__V_toggle_TARGETS__';

// todo: move into target util - binds elements together
const VBoundListeners = '__V_boundEventListeners__';

// adds event listner to context node and stores the event referencce
// on the element for removing the event later on.
const bindTargets = (vnode, binding, events, fn, el) => {
  const _targets = [binding.value];

  const listener = () => {
    const targets = el[V_TOGGLE_TARGETS];
    fn({ targets, vnode });
  };

  events.forEach(eventType => {
    eventOn(vnode.elm, eventType, listener);

    const boundListeners = vnode.elm[VBoundListeners] || {};
    boundListeners[eventType] = boundListeners[eventType] || [];
    boundListeners[eventType].push(listener);
    vnode.elm[VBoundListeners] = boundListeners;
  });

  return _targets;
};

const unbindTargets = (vnode, binding, events) => {
  events.forEach(eventType => {
    const eventListners =
      vnode.elm[VBoundListeners] && vnode.elm[VBoundListeners][eventType];

    if (eventListners) {
      eventListners.forEach(fn => {
        eventOff(vnode.elm, eventType, fn);
      });
      delete vnode.elm[VBoundListeners][eventType];
    }
  });
};

const handleTargets = ({ targets, vnode }) => {
  targets.forEach(target => {
    vnode.context.$root.$emit(EVENT_TOGGLE, target);
  });
};

const bind = function (el, binding, vnode) {
  const targets = bindTargets(vnode, binding, ['click'], handleTargets, el);

  el[V_TOGGLE_TARGETS] = targets;
  el[V_TOGGLE_CONTROLS] = targets.join(' ');
  el[V_TOGGLE_STATE] = false;
  setAttr(el, 'aria-controls', el[V_TOGGLE_CONTROLS]);
  setAttr(el, 'aria-expanded', 'false');

  const toggle = (id, state) => {
    const _targets = el[V_TOGGLE_TARGETS] || [];
    if (_targets.indexOf(id) !== -1) {
      setAttr(el, 'aria-expanded', state ? 'true' : 'false');
      el[V_TOGGLE_STATE] = state;
      if (state) {
        removeClass(el, 'collapsed');
      } else {
        addClass(el, 'collapsed');
      }
    }
  };

  el[V_TOGGLE] = toggle;

  vnode.context.$root.$on(EVENT_STATE, el[V_TOGGLE]);
};

const unbind = function (el, binding, vnode) {
  unbindTargets(vnode, binding, ['click']);

  vnode.context.$root.$off(EVENT_STATE, el[V_TOGGLE]);
  [V_TOGGLE, V_TOGGLE_STATE, V_TOGGLE_CONTROLS, V_TOGGLE_TARGETS].forEach(
    prop => resetProp(el, prop)
  );

  removeClass(el, 'collapsed');
  removeAttr(el, 'aria-expanded');
  removeAttr(el, 'aria-controls');
};

const handleUpdate = (el, binding, vnode) => {
  const targets = [binding.value];
  if (el[V_TOGGLE_CONTROLS] !== binding.value) {
    el[V_TOGGLE_TARGETS] = targets;
    el[V_TOGGLE_CONTROLS] = targets.join(' ');
    vnode.context.$root.$emit(EVENT_REQUEST_STATE, binding.value);
  }

  if (el[V_TOGGLE_STATE] === true) {
    addClass(el, 'collapsed');
    setAttr(el, 'aria-expanded', 'true');
  } else if (el[V_TOGGLE_STATE] === false) {
    removeClass(el, 'collapsed');
    setAttr(el, 'aria-expanded', 'false');
  }
  setAttr(el, 'aria-controls', el[V_TOGGLE_CONTROLS]);
};

export default {
  bind,
  componentUpdated: handleUpdate,
  updated: handleUpdate,
  unbind
};
