var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-compare__icon-row" },
    [
      _vm._l(_vm.productPropertyValues, function(
        productValues,
        productValuesIdx
      ) {
        return [
          _c(
            "div",
            { key: productValuesIdx, staticClass: "c-compare__icon-row--item" },
            [
              _c("c-icon", {
                attrs: { "symbol-id": _vm.specificationLabel.icon }
              }),
              _vm._v(" "),
              _vm.specificationLabel.iconProperties
                ? _vm._l(_vm.iconProperties(productValues), function(
                    line,
                    lineIdx
                  ) {
                    return _c("div", {
                      key: lineIdx,
                      staticClass: "c-typography-body-sm",
                      domProps: { innerHTML: _vm._s(line) }
                    })
                  })
                : _vm._e()
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }