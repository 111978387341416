var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c("div", { staticClass: "cm-o-layout cm-o-layout--main" }, [
        _vm.content.headline
          ? _c(
              "div",
              { staticClass: "cm-c-checkout" },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "cm-c-checkout__title cm-c-checkout__title--center"
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.getContentPropertyValue(_vm.content.headline)
                        ) +
                        "\n    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "\n        cm-c-checkout__subtitle\n        cm-c-checkout__subtitle--center\n        cm-c-checkout__subtitle--body\n      ",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.getContentPropertyValue(_vm.content.description)
                    )
                  }
                }),
                _vm._v(" "),
                _vm.init
                  ? [
                      _vm._l(_vm.promotedServices, function(product, index) {
                        return _c("c-product-card-vas", {
                          key: index,
                          attrs: {
                            transition: "staggered",
                            stagger: "150",
                            "vas-product": product,
                            "group-id": _vm.groupId,
                            promoted: "",
                            "local-loading": false,
                            "read-more": _vm.getContentPropertyValue(
                              _vm.content.expandLabel
                            ),
                            "price-disclaimer-text": _vm.getContentPropertyValue(
                              _vm.content.priceDisclaimerText
                            ),
                            close: _vm.getContentPropertyValue(
                              _vm.content.collapseLabel
                            )
                          },
                          on: {
                            update: _vm.handleServiceStateUpdate,
                            "show-multiple-overlay":
                              _vm.handleShowMultipleOverlay
                          }
                        })
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cm-grid-vaslist" },
                        [
                          _c(
                            "transition-group",
                            {
                              staticClass: "cm-c-category-grid",
                              attrs: { tag: "section", appear: "" },
                              on: {
                                "before-enter": _vm.beforeEnter,
                                enter: _vm.enter,
                                leave: _vm.leave
                              }
                            },
                            _vm._l(_vm.newServices, function(product, index) {
                              return _c("c-product-card-vas", {
                                key: index,
                                attrs: {
                                  transition: "staggered",
                                  stagger: "150",
                                  "vas-product": product,
                                  "group-id": _vm.groupId,
                                  "local-loading": false,
                                  "read-more": _vm.getContentPropertyValue(
                                    _vm.content.expandLabel
                                  ),
                                  "price-disclaimer-text": _vm.getContentPropertyValue(
                                    _vm.content.priceDisclaimerText
                                  ),
                                  close: _vm.getContentPropertyValue(
                                    _vm.content.collapseLabel
                                  )
                                },
                                on: {
                                  update: _vm.handleServiceStateUpdate,
                                  "show-multiple-overlay":
                                    _vm.handleShowMultipleOverlay
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.existingServices.length
                        ? [
                            _c(
                              "p",
                              { staticClass: "cm-c-checkout__subtitle" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.getContentPropertyValue(
                                        _vm.content.existingVasHeadline
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "cm-c-checkout__subtitle cm-c-checkout__subtitle--body"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.existingVasDescription) +
                                    "\n        "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "cm-grid-vaslist" },
                              [
                                _c(
                                  "transition-group",
                                  {
                                    staticClass: "cm-c-category-grid",
                                    attrs: { tag: "section", appear: "" },
                                    on: {
                                      "before-enter": _vm.beforeEnter,
                                      enter: _vm.enter,
                                      leave: _vm.leave
                                    }
                                  },
                                  _vm._l(_vm.existingServices, function(
                                    product,
                                    index
                                  ) {
                                    return _c("c-product-card-vas", {
                                      key: index,
                                      attrs: {
                                        transition: "staggered",
                                        stagger: "150",
                                        "vas-product": product,
                                        "group-id": _vm.groupId,
                                        "local-loading": false,
                                        "read-more": _vm.getContentPropertyValue(
                                          _vm.content.expandLabel
                                        ),
                                        "price-disclaimer-text": _vm.getContentPropertyValue(
                                          _vm.content.priceDisclaimerText
                                        ),
                                        close: _vm.getContentPropertyValue(
                                          _vm.content.collapseLabel
                                        ),
                                        "old-vas-price-description": _vm.getContentPropertyValue(
                                          _vm.content.oldVasPriceDescription
                                        )
                                      },
                                      on: {
                                        update: _vm.handleServiceStateUpdate,
                                        "show-multiple-overlay":
                                          _vm.handleShowMultipleOverlay
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.init || _vm.networking
                  ? _c("div", { staticClass: "cm-c-spinner" }, [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading...")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasVasConsent
                  ? _c("c-vas-consent-overlay", {
                      attrs: {
                        open: _vm.init && Boolean(_vm.substitutes.length),
                        vases: _vm.substitutes,
                        "group-id": _vm.groupId
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("c-vas-multiple-overlay", {
                  attrs: {
                    visible: _vm.showMultipleVasOverlay,
                    content: _vm.content,
                    services: _vm.multiVasOverlayServices,
                    brand: _vm.multiVasOverlayBrand,
                    "group-id": _vm.groupId,
                    "handle-service-state-update": _vm.handleServiceStateUpdate,
                    networking: _vm.networking
                  }
                })
              ],
              2
            )
          : _vm._e()
      ])
    : _c("div", [_vm._m(0)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }