var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass: "checkout-summary-configuration",
      attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h3", { staticClass: "cm-c-form__header" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.content.productConfigurationHeading) + "\n  "
        )
      ]),
      _vm._v(" "),
      _vm.subscriptionItems.length
        ? _c(
            "section",
            {
              class: { "cm-c-checkout__text mb-4": _vm.broadbandItems.length }
            },
            [
              _c(
                "c-row",
                [
                  _c("c-col", [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.content.mobileSubscriptionName))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.subscriptionItems, function(subscription, index) {
                return _c(
                  "div",
                  {
                    key: subscription.itemDisplayName + index,
                    staticClass: "checkout-summary-configuration__subscription"
                  },
                  [
                    _c(
                      "c-row",
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "mb-1",
                            attrs: { cols: "6", xs: "12" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(subscription.itemDisplayName) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        subscription.phoneNumber
                          ? _c(
                              "c-col",
                              {
                                staticClass: "cm-text-right-md",
                                attrs: { cols: "6", xs: "12" }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.formatNumber(
                                        subscription.phoneNumber.substr(
                                          Math.max(
                                            subscription.phoneNumber.length - 8,
                                            0
                                          )
                                        ),
                                        subscription.subscriptionConfigurationType
                                      )
                                    ) +
                                    "\n          "
                                ),
                                _vm._l(
                                  _vm.formatSubscriptionDetails(subscription),
                                  function(info, idx) {
                                    return _c("div", { key: idx }, [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(info) +
                                          "\n          "
                                      )
                                    ])
                                  }
                                )
                              ],
                              2
                            )
                          : _c(
                              "c-col",
                              {
                                staticClass: "cm-text-right-md",
                                attrs: { cols: "6", xs: "12" }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.content.numberSelectionFailedText
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.broadbandItems.length
        ? _c(
            "section",
            { staticClass: "cm-c-checkout__text" },
            [
              !_vm.homeOfficeFlow
                ? _c(
                    "c-row",
                    [
                      _c("c-col", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.content.broadbandSubscriptionName))
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.broadbandItems, function(broadband, index) {
                return _c(
                  "div",
                  {
                    key: broadband.itemDisplayName + index,
                    staticClass: "checkout-summary-configuration__subscription"
                  },
                  [
                    _c(
                      "c-row",
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "mb-1",
                            attrs: { cols: "6", xs: "12" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(broadband.itemDisplayName) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.homeOfficeFlow
                      ? [
                          _c(
                            "c-row",
                            { staticClass: "mb-2" },
                            [
                              _c("c-col", { attrs: { cols: "6" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(broadband.itemSpeedInfoText) +
                                    ", " +
                                    _vm._s(broadband.itemHelpText) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "c-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.content.activationDateText) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "c-row",
                            { staticClass: "mb-2" },
                            [
                              _c("c-col", { attrs: { cols: "6" } }, [
                                _c("strong", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.content.installationAddressText
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "c-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(broadband.customerName)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.formatStreet(
                                          broadband.installationAddress
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.formatCity(
                                          broadband.installationAddress
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "c-row",
                            [
                              _c("c-col", { attrs: { cols: "6" } }, [
                                _c("strong", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.content.additionalInformationText
                                      ) +
                                      "\n            "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "c-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.showTechnicianNeededText(
                                          _vm.content
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.content.powerOfAttorneyMailText
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "c-row",
                            [
                              _c("c-col", { attrs: { cols: "6", xs: "12" } }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(broadband.itemDownSpeed) +
                                    "/" +
                                    _vm._s(broadband.itemUpSpeed) +
                                    "\n            " +
                                    _vm._s(broadband.itemMonetaryUnit) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              [
                                _c(
                                  "c-col",
                                  { attrs: { cols: "6", xs: "12" } },
                                  [
                                    _c(
                                      "ul",
                                      { staticClass: "cm-text-right-md" },
                                      [
                                        broadband.installationAddress
                                          ? _c("li", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.content
                                                      .installationAddressText
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.formatStreet(
                                                      broadband.installationAddress
                                                    )
                                                  )
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.formatCity(
                                                      broadband.installationAddress
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        broadband.isDeliveryAsapRequired
                                          ? _c("li", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.content
                                                      .itemDeliveryAsapMessage
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _c("li", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.content
                                                      .itemDeliveryMessage
                                                  ) +
                                                  "\n                  " +
                                                  _vm._s(
                                                    broadband.deliveryDate
                                                  ) +
                                                  "\n                "
                                              )
                                            ]),
                                        _vm._v(" "),
                                        broadband.isTerminationRequired
                                          ? _c("li", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.content
                                                      .itemTerminatedByUserMessage
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                          : _c("li", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.content
                                                    .itemTerminatedByCompanyMessage
                                                )
                                              )
                                            ]),
                                        _vm._v(" "),
                                        broadband.isTechnicianRequired
                                          ? _c(
                                              "li",
                                              [
                                                _c(
                                                  "popover-panel",
                                                  {
                                                    attrs: {
                                                      description:
                                                        _vm.content
                                                          .technicianTooltipText
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.content
                                                            .technicianLabel
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("c-icon", {
                                                      staticClass:
                                                        "\n                        checkout-summary-configuration__technician-information-icon\n                      ",
                                                      attrs: {
                                                        "symbol-id":
                                                          "service-information"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ],
                            2
                          )
                        ]
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: {
            centered: "",
            "show-close-button": "",
            visible: _vm.hasConfigurationError
          }
        },
        [
          _c(
            "c-heading",
            { attrs: { slot: "header", level: "3", as: "3" }, slot: "header" },
            [_vm._v(_vm._s(_vm.configurationErrorTitle))]
          ),
          _vm._v(" "),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.configurationErrorDescription) }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }