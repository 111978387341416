export default {
  methods: {
    unifiedAddressObject(addressData) {
      const isDAWAData = !!addressData.data?.adresseringsvejnavn;

      return {
        streetName: isDAWAData
          ? addressData.data.adresseringsvejnavn
          : addressData.streetName,
        houseNumber: isDAWAData
          ? addressData.data.husnr.match(/[0-9]/g).join('')
          : addressData.houseNumber,
        houseLetter: isDAWAData
          ? addressData.data.husnr.match(/[a-zA-Z]/g)
          : addressData.houseLetter,
        floor: isDAWAData ? addressData.data.etage : addressData.floor,
        door: isDAWAData ? addressData.data.dør : addressData.door,
        postalCode: isDAWAData
          ? addressData.data.postnr
          : addressData.postalCode || addressData.postNumber,
        city: isDAWAData ? addressData.data.postnrnavn : addressData.city,
        addressId: isDAWAData ? addressData.data.id : addressData.addressId
      };
    },

    createAddressStringFromData(addressData, full = true, onlyCity = false) {
      if (!addressData) {
        return '';
      }

      const address = this.unifiedAddressObject(addressData);

      if (full) {
        return `${address.streetName} ${address.houseNumber}${
          address.houseLetter ? address.houseLetter : ''
        }${address.floor ? ` ${address.floor}` : ''}${
          address.door ? ` ${address.door}` : ''
        }, ${address.postalCode} ${address.city}`;
      }

      if (onlyCity) {
        return `${address.postalCode} ${address.city}`;
      }

      return `${address.streetName} ${address.houseNumber}${
        address.houseLetter ? address.houseLetter : ''
      }${address.floor ? ` ${address.floor}` : ''}${
        address.door ? ` ${address.door}` : ''
      }`;
    }
  }
};
