<template>
  <div>
    <div
      class="
        cm-c-checkout__module
        cm-c-checkout__module--pt
        cm-c-checkout__module--mb
      "
    >
      <c-heading slot="header" level="3" as="3" class="mb-1">
        {{ contentModel.pageTitle }}
      </c-heading>
      <p class="description" v-html="contentModel.pageDescription"></p>

      <checkout-select-payment
        :payment-methods="paymentMethods"
        :selected-payment-method-value="selectedPaymentMethodValue"
        :finance-type="parsedFinanceType"
        :is-tf-supported="contentModel.tfSupported"
        :loading-state="paymentOptionLoadingState"
        @payment-method-selected="selectPaymentMethod"
        @update-credit-agreement-amount="updateCreditAgreementAmount"
      >
      </checkout-select-payment>

      <div
        v-if="showActivationBox"
        class="checkout-select-payment__activation-box"
      >
        <div class="checkout-select-payment__activation-box-image">
          <img
            v-if="contentModel.genericFinancePaymentOptionIcon"
            :src="contentModel.genericFinancePaymentOptionIcon"
            role="presentation"
            class="checkout-select-payment__activation-box-image__icon"
          />
        </div>
        <div class="checkout-select-payment__activation-box-content">
          <div class="checkout-select-payment__activation-box-content-title">
            {{ contentModel.genericFinancePaymentOptionTitle }}
          </div>
          <div
            class="checkout-select-payment__activation-box-content-description"
            v-html="contentModel.genericFinancePaymentOptionDescription"
          ></div>
          <div class="checkout-select-payment__activation-box-content-button">
            <c-button
              v-if="!contentModel.disableFinance"
              @click="openFinanceModal"
            >
              {{ contentModel.genericFinancePaymentOptionButtonText }}
            </c-button>
          </div>
        </div>
      </div>

      <div
        v-if="showResursBanner"
        class="checkout-select-payment__banner-container"
      >
        <slot name="banner" />
      </div>

      <div>
        <c-button
          v-if="showChangeFinanceLink && !contentModel.disableFinance"
          variant="link"
          @click="openFinanceModal"
          >{{ reOpenModalLabel }}</c-button
        >
      </div>
    </div>

    <checkout-finance-summary
      :sections="paymentSummary"
      :full-width="true"
    ></checkout-finance-summary>

    <div
      v-if="isButtonsShowing"
      class="cm-c-checkout cm-c-checkout-summary__button-container"
    >
      <c-button
        v-if="contentModel.previousButtonText"
        variant="secondary"
        @click="goToPreviousPage"
      >
        {{ contentModel.previousButtonText }}
      </c-button>

      <c-button
        v-if="contentModel.nextButtonText"
        :disabled="!selectionIsValid"
        @click="goToNextPage"
      >
        {{ contentModel.nextButtonText }}
      </c-button>
    </div>

    <c-modal ref="financeModal" gray>
      <div class="checkout-select-payment__modal-container">
        <h3 class="checkout-select-payment__modal-heading">
          {{ contentModel.modalDialogTitle }}
        </h3>
        <div
          class="checkout-select-payment__modal-content"
          v-html="contentModel.modalDialogDescription"
        ></div>

        <c-form-radio-group
          ref="financeRadioGroup"
          v-model="modalSelectedOption"
          name="oldFinanceSelection"
          class="checkout-select-payment__modal-selection"
          :highlighted="true"
          stacked
        >
          <c-form-radio
            v-for="option in contentModel.modelDialogOptions"
            :id="option.upfrontPaymentMethodFilter.toString()"
            :key="option.upfrontPaymentMethodFilter.toString()"
            :value="option.upfrontPaymentMethodFilter.toString()"
          >
            <div class="checkout-select-payment__modal-selection__label">
              {{ option.optionText }}
            </div>
          </c-form-radio>
        </c-form-radio-group>

        <div class="checkout-select-payment__modal-button-container">
          <c-button variant="tertiary" @click="closeFinanceModal()">{{
            contentModel.modalDialogCancelButtonText
          }}</c-button>

          <c-button @click="selectOldFinance()">{{
            contentModel.modalDialogContinueButtonText
          }}</c-button>
        </div>
      </div>
    </c-modal>
  </div>
</template>

<script>
import {
  CHeading,
  CButton,
  CModal,
  CFormRadioGroup,
  CFormRadio
} from 'olympus/components';
import CheckoutSelectPayment from './checkout-select-payment.vue';
import CheckoutFinanceSummary from './checkout-finance-summary.vue';

/**
 * Finance type enum.
 * @enum {string}
 */
const FinanceType = {
  /** Card option */
  DEFAULT: '1',
  /** Resurs finance system */
  RESURS: '2',
  /** TF Customer finance option */
  TF: '3'
};

export default {
  name: 'CheckoutSelectPaymentWrapper',

  components: {
    CModal,
    CHeading,
    CheckoutSelectPayment,
    CheckoutFinanceSummary,
    CButton,
    CFormRadioGroup,
    CFormRadio
  },

  props: {
    contentModel: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      paymentMethods: [],
      selectedPaymentMethodValue: 'none',
      isLoading: false,
      paymentSummary: [],
      displayFinanceModal: false,
      useOldFinance: FinanceType.DEFAULT,
      modalSelectedOption: FinanceType.DEFAULT,
      paymentOptionLoadingState: ''
    };
  },

  computed: {
    selectionIsValid() {
      if (this.isLoading) {
        return false;
      }

      const selectedPaymentMethod = this.paymentMethods.find(
        x => x.value === this.selectedPaymentMethodValue
      );

      if (
        !selectedPaymentMethod ||
        selectedPaymentMethod.isDisabled ||
        this.paymentOptionLoadingState
      ) {
        return false;
      }

      return true;
    },
    reOpenModalLabel() {
      return this.useOldFinance !== FinanceType.TF
        ? this.contentModel.isTFCustomerLinkText
        : this.contentModel.notTFCustomerLinkText;
    },
    showChangeFinanceLink() {
      return (
        this.contentModel.showToggleDialogLink &&
        this.useOldFinance !== FinanceType.DEFAULT
      );
    },
    isButtonsShowing() {
      return (
        (this.contentModel.nextPageUrl || this.contentModel.previousPageUrl) &&
        (this.contentModel.nextButtonText ||
          this.contentModel.previousButtonText)
      );
    },
    showResursBanner() {
      return (
        this.contentModel.showResursBanner &&
        (this.useOldFinance === FinanceType.RESURS ||
          !this.contentModel.tfSupported)
      );
    },
    parsedFinanceType() {
      return parseInt(this.useOldFinance, 10) || 1;
    },
    showActivationBox() {
      return (
        this.useOldFinance === FinanceType.DEFAULT &&
        this.contentModel.tfSupported
      );
    }
  },

  async mounted() {
    if (this.contentModel.disableFinance) {
      sessionStorage.removeItem('useOldFinance');
    } else {
      this.useOldFinance =
        sessionStorage.getItem('useOldFinance') || FinanceType.DEFAULT;
    }

    this.paymentSummary = this.contentModel.paymentSummary;
    this.paymentMethods = this.contentModel.paymentMethods;
    this.selectedPaymentMethodValue =
      this.paymentMethods.find(x => x.isSelected)?.value || '';
    if (this.contentModel.modelDialogOptions) {
      this.modalSelectedOption = FinanceType.DEFAULT;
    }

    this.handleCallback();
  },

  methods: {
    handleCallback() {
      const verifyIdData = JSON.parse(sessionStorage.getItem('verifyId'));
      if (verifyIdData) {
        this.isLoading = true;

        this.paymentOptionLoadingState = verifyIdData.paymentMethodValue;

        this.$http
          .post(this.contentModel.creditAgreementApiVerifyEndpoint, {
            verifyId: verifyIdData.verifyId,
            spmpId: this.contentModel.pageId,
            language: this.contentModel.language
          })
          .then(data => {
            this.paymentMethods = data.body.paymentMethods;
          })
          .catch(() => {
            this.$addToast({
              title: this.contentModel.apiEndpointErrorTitle,
              paragraph: this.contentModel.apiEndpointErrorDescription,
              state: 'error',
              duration: 5000
            });
          })
          .finally(() => {
            // Cleanup state.
            sessionStorage.removeItem('verifyId');
            this.paymentOptionLoadingState = '';
            this.isLoading = false;
          });
      }
    },

    goToNextPage() {
      window.location.href = `${this.contentModel.nextPageUrl}`;
    },

    goToPreviousPage() {
      window.location.href = `${this.contentModel.previousPageUrl}`;
    },

    selectPaymentMethod(value) {
      this.isLoading = true;
      this.selectedPaymentMethodValue = value;
      this.$http
        .post(this.contentModel.apiEndpoint, {
          PaymentMethod: value
        })
        .then(data => {
          if (data.body.priceSpecificationGroups) {
            this.paymentSummary = data.body.priceSpecificationGroups;
          }
        })
        .catch(() => {
          this.$addToast({
            title: this.contentModel.apiEndpointErrorTitle,
            paragraph: this.contentModel.apiEndpointErrorDescription,
            state: 'error',
            duration: 5000
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateCreditAgreementAmount(paymentMethod) {
      this.isLoading = true;

      this.paymentOptionLoadingState = paymentMethod.value;

      this.$http
        .post(this.contentModel.creditAgreementApiUpdateAmountEndpoint, {
          callbackUrl: window.location.href,
          paymentMethod: paymentMethod.value
        })
        .then(data => {
          const verifyIdData = {
            verifyId: data.body.verifyId,
            paymentMethodValue: paymentMethod.value
          };
          sessionStorage.setItem('verifyId', JSON.stringify(verifyIdData));
          window.location.href = data.body.redirectUrl;
        })
        .catch(() => {
          this.$addToast({
            title: this.contentModel.apiEndpointErrorTitle,
            paragraph: this.contentModel.apiEndpointErrorDescription,
            state: 'error',
            duration: 5000
          });
          this.paymentOptionLoadingState = '';
          this.isLoading = false;
        });
    },

    selectOldFinance() {
      sessionStorage.setItem('useOldFinance', this.modalSelectedOption);
      this.useOldFinance = this.modalSelectedOption;
      this.closeFinanceModal();
    },

    openFinanceModal() {
      if (this.useOldFinance !== FinanceType.DEFAULT) {
        this.modalSelectedOption = this.useOldFinance;
      }
      this.displayFinanceModal = true;
      this.$nextTick(() => {
        if (this.$refs.financeModal) {
          this.$refs.financeModal.show();
        }
      });
    },

    closeFinanceModal() {
      if (this.$refs.financeModal) {
        // Remove focus specifically from radio group, otherwise closing the modal throws an aria error.
        if (this.$refs.financeRadioGroup) {
          const radioInputs = this.$refs.financeRadioGroup.$el.querySelectorAll(
            'input[type="radio"]'
          );
          radioInputs.forEach(input => {
            input.setAttribute('tabindex', '-1');
          });
        }

        this.$refs.financeModal.hide();
      }
      this.$nextTick(() => {
        this.displayFinanceModal = false;
      });
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.checkout-select-payment {
  // .checkout-select-payment__activation-box
  &__activation-box {
    border: $u-100 solid $c-nt-300;
    border-radius: $u-200;
    background: $color-white;
    margin-bottom: $u-250;
    display: flex;
    width: 100%;
    padding: $u-300;

    // .checkout-select-payment__activation-box-image
    &-image {
      min-width: $u-600;
      min-height: $u-600;
      overflow: hidden;
      border-radius: $u-200;
      margin-right: $u-250;
      display: flex;
      justify-content: center;

      // .checkout-select-payment__image__icon
      &__icon {
        fill: $color-white;
        width: $u-600;
        height: $u-600;
      }
    }

    // .checkout-select-payment__activation-box-content
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: $font-size-h4;

      // .checkout-select-payment__activation-box-content-title
      &-title {
        color: $c-nt-900;
        font-weight: 700;
      }

      // .checkout-select-payment__activation-box-content-description
      &-description {
        color: $c-nt-500;
        margin-bottom: $u-200;

        ul {
          list-style: disc;
          padding-left: $u-350;
        }
      }
    }
  }

  // .checkout-select-payment__modal-container
  &__modal-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  // .checkout-select-payment__modal-button-container
  &__modal-button-container {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  // .checkout-select-payment__banner-container
  &__banner-container {
    // .checkout-select-payment__banner-container .resurs-banner
    .resurs-banner {
      // .checkout-select-payment__banner-container .resurs-banner__container
      &__container {
        box-shadow: unset;
        border: $u-100 solid $c-nt-300;

        ul {
          list-style: disc;
          padding-left: $u-350;
        }
      }
    }
  }
}
</style>
