var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "product-tabs cm-container cm-container--lg" },
    [
      _c(
        "c-tabs",
        {
          ref: "tabs",
          staticClass: "product-tabs__tabs",
          attrs: { id: "navbars" },
          on: { activated: _vm.onTabActivated }
        },
        [
          _vm.marketingData
            ? _c(
                "c-tab",
                {
                  staticClass:
                    "\n        cm-c-details\n        product-tabs__content product-tabs__content--full-width\n      ",
                  attrs: {
                    id: _vm.PRODUCT_TABS.MARKETING,
                    title: _vm.contentLocal.productDetailsHeading
                  }
                },
                [
                  _vm.marketingData.hasAlternativeText
                    ? _vm._t("marketing-alternative-content")
                    : _c("div", {
                        staticClass: "product-tabs__content__markup",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.marketingData.productUniqueSellingPoints
                          )
                        }
                      })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasSpecifications
            ? _c(
                "c-tab",
                {
                  staticClass: "cm-c-specs product-tabs__content",
                  attrs: {
                    id: _vm.PRODUCT_TABS.SPECIFICATIONS,
                    title: _vm.contentLocal.specificationsHeading
                  }
                },
                [
                  _c("div", { staticClass: "cm-grid" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cm-grid__col--md-12 cm-grid__col--first-sm"
                      },
                      _vm._l(_vm.filteredSpecifications, function(
                        specs,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "cm-c-specs__content cm-mb--sm"
                          },
                          [
                            _c(
                              "h3",
                              { staticClass: "cm-c-specs__title" },
                              [
                                !specs.hideIcon
                                  ? _c("c-icon", {
                                      attrs: {
                                        "symbol-id": _vm.getSpecificationIcon(
                                          specs
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(specs.headline) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "cm-c-specs__list" },
                              _vm._l(specs.specifications, function(item, j) {
                                return _c(
                                  "li",
                                  { key: j, staticClass: "cm-c-specs__item" },
                                  [
                                    item.value
                                      ? [
                                          _c(
                                            "span",
                                            { staticClass: "cm-c-specs__prop" },
                                            [_vm._v(_vm._s(item.key))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "cm-c-specs__value"
                                            },
                                            [_vm._v(_vm._s(item.value))]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "c-tab",
            {
              staticClass: "cm-c-terms product-tabs__content",
              attrs: {
                id: _vm.PRODUCT_TABS.PRICES,
                title: _vm.contentLocal.productTermsTabLabel
              }
            },
            [
              _c("div", { staticClass: "cm-grid" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "\n            cm-grid__col--xs-2 cm-grid__col--xl-offset-2\n            cm-c-total__image\n          "
                  },
                  [
                    _vm.imageUrls[0]
                      ? _c("img", {
                          attrs: {
                            src: _vm.imageUrls[0] + "?w=480",
                            selected: _vm.product.name
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "cm-grid__col--xl-6 cm-grid__col--lg-10 cm-grid__col--xs-12"
                  },
                  [
                    _vm.prices
                      ? _c(
                          "div",
                          { staticClass: "cm-c-total cm-c-total--box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                cm-c-total__row cm-c-total__row--lg cm-c-total__row--bold\n              "
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.product.name) +
                                    ", " +
                                    _vm._s(_vm.variantSummary) +
                                    "\n              "
                                ),
                                _c(
                                  "div",
                                  { staticClass: "cm-c-total__value" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.hasInstallments
                                              ? _vm.prices.productMonthlyPrice
                                                  .formattedValueCurrency
                                              : _vm.prices.productPrice
                                                  .formattedValueCurrency
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.selectedSubscription
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                cm-c-total__row\n                cm-c-total__row--lg\n                cm-c-total__row--bold\n                cm-c-total__row--bottom-separator\n              "
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cm-c-total__name" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.selectedSubscription
                                                .displayName
                                            ) +
                                            " -\n                " +
                                            _vm._s(
                                              _vm.selectedSubscription
                                                .dataIncluded
                                            ) +
                                            ",\n                " +
                                            _vm._s(
                                              _vm.selectedSubscription
                                                .includedEuData
                                            ) +
                                            "\n                " +
                                            _vm._s(
                                              _vm.selectedSubscription.inEuLabel
                                            ) +
                                            ",\n                " +
                                            _vm._s(
                                              _vm.selectedSubscription.name
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.selectedSubscription.price
                                      .productBasePrice
                                      ? _c(
                                          "div",
                                          { staticClass: "cm-c-total__value" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.selectedSubscription.price
                                                    .productBasePrice
                                                    .formattedValueCurrency
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cm-c-total__accordion with-tooltip"
                              },
                              [
                                _c("input", {
                                  ref: "accordiontrigger",
                                  attrs: {
                                    id: "accordion-trigger",
                                    type: "checkbox",
                                    name: "accordion-trigger",
                                    checked: ""
                                  }
                                }),
                                _vm._v(" "),
                                _vm.prices.productDiscountedPrice &&
                                _vm.prices.productDiscountedPrice.value !== 0
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cm-c-total__row cm-c-total__row--sm"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cm-c-total__name" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.contentLocal
                                                      .basePriceLabel
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cm-c-total__value"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.prices.productBasePrice
                                                      .formattedValue
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.prices.productBasePrice
                                                      .currency
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cm-c-total__row cm-c-total__row--sm"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cm-c-total__name" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.contentLocal
                                                      .discountPriceLabel
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cm-c-total__value"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.prices
                                                      .productDiscountedPrice
                                                      .formattedValue
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.prices.productBasePrice
                                                      .currency
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "\n                    cm-c-total__row\n                    cm-c-total__row--sm\n                    cm-c-total__row--bottom-separator\n                  "
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cm-c-total__name" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.contentLocal
                                                      .productPriceLabel
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "cm-c-total__value"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.prices.productPrice
                                                      .formattedValue
                                                  ) +
                                                  "\n                    " +
                                                  _vm._s(
                                                    _vm.prices.productBasePrice
                                                      .currency
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "cm-c-total__trigger",
                                    attrs: { for: "accordion-trigger" }
                                  },
                                  [
                                    _c("c-icon", {
                                      attrs: { "symbol-id": "arrow-chevron" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cm-c-total__row cm-c-total__row--sm with-tooltip"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "cm-c-total__name" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.contentLocal
                                                    .minimumPriceText
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "cm-c-total__value" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.prices.minimumPrice
                                                    .formattedValue
                                                ) +
                                                "\n                    " +
                                                _vm._s(
                                                  _vm.prices.minimumPrice
                                                    .currency
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "cm-c-total__content" },
                                  [
                                    _vm.minimumPriceData
                                      ? _c("product-minimum-price-tooltip", {
                                          attrs: {
                                            data: _vm.minimumPriceData,
                                            accordion: "",
                                            "hide-headlines": "",
                                            "hide-totals": ""
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.selectedSubscription
                                  ? _c("div", {
                                      staticClass: "cm-c-total__summary",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.selectedSubscription
                                            .legalDescription
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }