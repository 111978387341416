<script>
// TODO DKT-1435: use c-form once we have proper component there
const FORM_STATES = {
  NOT_SUBMIT: 'NOT_SUBMIT',
  SUBMITTING: 'SUBMITTING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

import { GETTERS } from 'webshop/components/product/store/product-page';
import { mapGetters } from 'vuex';

/**
 * Renders and handles submission of the 'product notify' form.
 */
export default {
  name: 'ProductNotify',

  data() {
    return {
      email: '',
      currentState: FORM_STATES.NOT_SUBMIT,
      states: {
        ...FORM_STATES
      }
    };
  },

  computed: {
    ...mapGetters({ selectedVariant: GETTERS.GET_SELECTED_VARIANT }),

    btnData() {
      if (!this.selectedVariant) {
        return null;
      }

      return {
        iconType: 'contact-email',
        btnClass: 'c-btn--light'
      };
    }
  },

  methods: {
    onSubmit() {
      if (this.currentState !== this.states.NOT_SUBMIT) {
        return;
      }

      this.currentState = this.states.SUBMITTING;

      this.$http
        .post(
          '/ProductNotify/Subscribe',
          {
            productCode: this.selectedVariant?.code || this.$refs.code.value,
            email: this.email,
            __RequestVerificationToken: this.getToken()
          },
          {
            emulateJSON: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            }
          }
        )
        .then(() => {
          this.currentState = this.states.SUCCESS;
        })
        .catch(() => {
          this.currentState = this.states.FAIL;
        });
    },

    getToken() {
      const parent = this.$refs.form;
      const tokenElement = parent.querySelector(
        'input[name="__RequestVerificationToken"]'
      );

      return tokenElement.value;
    },

    resetForm() {
      this.email = '';
      this.currentState = this.states.NOT_SUBMIT;
    }
  }
};
</script>
