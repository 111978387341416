<script>
import FormInputMixin from '../../mixins/form-input-mixin.js';
import FormTextMixin from '../../mixins/form-text-mixin.js';

export default {
  name: 'c-text-area',

  mixins: [FormInputMixin, FormTextMixin],

  props: {
    rows: {
      type: [Number, String],
      default: 2
    },
    wrap: {
      // TODO TC-4910: add validation for soft, hard or off
      type: String,
      default: 'soft'
    }
  },

  render(h) {
    const self = this;

    return h('textarea', {
      ref: 'input',
      class: 'c-text-area',
      directives: [
        {
          name: 'model',
          rawName: 'v-model',
          value: self.localValue,
          expression: 'localValue'
        }
      ],
      attrs: {
        id: self.id,
        name: self.name,
        disabled: self.disabled,
        placeholder: self.placeholder,
        rows: self.rows,
        wrap: self.wrap || null
      },
      domProps: {
        value: self.localValue
      },
      on: {
        input: self.onInput,
        change: self.onChange,
        blur: self.onBlur
      }
    });
  }
};
</script>

<style lang="scss">
.c-text-area {
  overflow: auto;
  display: block;
  width: 441px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  margin: 0px 2px 0px 0px;
  height: 95px;
}
</style>
