import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// TODO: use bootstrap instead of entire store module.
import store from '../store/shop/index.js';

let xCorrelationHeader = {
  'x-correlation-id': uuidv4()
};

function configureApiRequest() {
  return config => {
    let path = config.url;
    if (path[0] !== '/' && !path.startsWith('http')) {
      path = `/${path}`;
    }
    const excludeContext = config?.excludeContext;
    return {
      ...config,
      url: config.mvc ? path : '/api/olympus' + path,
      params: {
        ...config.params,
        contextId: !excludeContext ? store.state.global.contextId : null
      },
      headers: {
        ...xCorrelationHeader,
        ...config.headers,
        ...(config.cached
          ? {}
          : {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0'
            })
      }
    };
  };
}

// TODO: use bootstrapped values instead.
const DEFAULT_ERROR_TITLE = 'Noget gik desværre galt';
const DEFAULT_ERROR_MESSAGE =
  'Det lader desværre til at vi har lidt problemer.';
const DEFAULT_ERROR_STATUS = 500;

function rejectWithError({ message, title, status, ...rest } = {}) {
  const error = new Error(message || DEFAULT_ERROR_MESSAGE);
  error.title = title || DEFAULT_ERROR_TITLE;
  error.status = status || DEFAULT_ERROR_STATUS;

  return Promise.reject(
    Object.keys(rest).reduce((acc, key) => {
      acc[key] = rest[key];
      return acc;
    }, error)
  );
}

function handleError(error) {
  if (error.response) {
    const { data, status } = error.response;

    if (data && data.errors && data.errors.length) {
      const { code, title, detail } = data.errors[0];

      return rejectWithError({
        errors: data.errors,
        status,
        code,
        title,
        message: detail
      });
    }

    return rejectWithError({ status });
  }

  return rejectWithError({
    message: error.message,
    title: error.title
  });
}

const requester = axios.create();
requester.interceptors.request.use(configureApiRequest());

export function get(...args) {
  return requester.get(...args).catch(handleError);
}

export function post(...args) {
  return requester.post(...args).catch(handleError);
}

export function put(...args) {
  return requester.put(...args).catch(handleError);
}

export function patch(...args) {
  return requester.patch(...args).catch(handleError);
}

export function del(...args) {
  return requester.delete(...args).catch(handleError);
}
