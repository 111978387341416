<template>
  <section class="product-number-selector">
    <c-icon
      class="product-number-selector__icon"
      symbol-id="service-mobil"
    ></c-icon>
    <h2>
      {{
        anyNumbersRegistered
          ? content.headingText
          : content.unavailableNumbersHeadingText
      }}
    </h2>
    <h3>
      {{
        anyNumbersRegistered
          ? content.subHeading
          : content.unavailableNumbersSubHeading
      }}
    </h3>

    <section class="product-number-selector__numbers">
      <div v-if="!init" class="cm-c-spinner cm-c-spinner--local">
        <span class="sr-only">Loading...</span>
      </div>
      <template v-else-if="anyNumbersRegistered">
        <user-card
          v-for="{
            id,
            name,
            phoneNumber,
            isAvailable,
            errorMessage
          } in existingNumbers"
          :key="id"
          :subscription-name="name"
          :phone-number="phoneNumber"
          :right-action-button-text="content.selectButtonText"
          :right-action-disabled-button-text="content.disabledButtonText"
          :disabled="!isAvailable"
          @rightActionPressed="selectNumber"
        >
          <span v-if="errorMessage" v-html="errorMessage"></span>
          <span
            v-if="selectedNumber === phoneNumber"
            slot="right-action"
            class="product-number-selector__selected-label"
          >
            <c-icon symbol-id="service-checkmark" />
            {{ content.selectedNumberLabel }}
          </span>
        </user-card>
      </template>

      <user-card
        :subscription-name="content.noNumberHelpLabel"
        :phone-number="content.noNumberLabel"
      >
        <c-icon slot="user-image" symbol-id="service-simcard"></c-icon>
        <button
          slot="right-action"
          type="button"
          class="c-btn c-btn--sm"
          @click="selectNumber({ phoneNumber: null })"
        >
          {{ content.selectButtonText }}
        </button>
      </user-card>
    </section>
  </section>
</template>

<script>
import { getNumberConfigurationGroupsByProductId } from '../../api/checkout-service.js';
import UserCard from '../c-user-card/c-user-card.vue';

/**
 * Allows logged-in users to select a valid existing number or a new number on the product page
 * to be used in the product wizard configuration.
 */
export default {
  name: 'ProductNumberSelector',

  components: {
    UserCard
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    productCode: {
      type: String,
      default: undefined
    },

    selectedNumber: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      init: false,
      existingNumbers: []
    };
  },

  computed: {
    anyNumbersRegistered() {
      return Boolean(this.existingNumbers && this.existingNumbers.length);
    }
  },

  mounted() {
    this.init = false;
    this.fetchData();
  },

  methods: {
    selectNumber({ phoneNumber } = {}) {
      /**
       * Triggers when a new number is selected, passing it along.
       * The new phone number could also null (select no number / existing number).
       */
      this.$emit('number-selected', {
        phoneNumber
      });
    },

    fetchData() {
      if (!this.productCode) {
        return Promise.resolve();
      }

      return getNumberConfigurationGroupsByProductId(this.productCode)
        .then(({ data }) => {
          this.existingNumbers = data;
          this.init = true;
        })
        .catch(() => {
          this.init = true;
          // TODO DKT-1434: should show error in UI
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

h2 {
  font-family: $global-font-family-2;
  font-size: $font-size-h3;
  margin-top: $u-350;
}

h3 {
  font-size: $font-size-h4;
  margin-top: $u-200;
}

.product-number-selector {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 601px) {
    padding: 0 $u-800 $u-350 $u-800;
  }

  // .product-number-selector__icon
  &__icon {
    width: 30px;
    height: 30px;
  }

  // .product-number-selector__numbers
  &__numbers {
    width: 100%;
    max-width: 430px;
    margin-top: $u-300;
  }

  // .product-number-selector__selected-label
  &__selected-label {
    display: flex;
    align-items: center;
    padding: 0 $u-300;

    svg {
      $checkmark-icon-size: 24px;

      width: $checkmark-icon-size;
      height: $checkmark-icon-size;
    }
  }
}
</style>
