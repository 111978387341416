var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      staticClass: "cm-numcon__product",
      class: [
        !_vm.product.configComplete &&
        _vm.product.code !== _vm.numberConfigActive
          ? "cm-numcon__confignotcompelete"
          : ""
      ],
      attrs: {
        id: [_vm.product.code !== _vm.numberConfigActive ? "" : "focusEl"]
      },
      on: {
        click: function($event) {
          return _vm.setActive(_vm.product.code, _vm.product.configComplete)
        }
      }
    },
    [
      _vm.product.phoneNumber &&
      _vm.configType === -1 &&
      _vm.product.configComplete &&
      _vm.product.configEdited
        ? _c(
            "button",
            {
              staticClass: "cm-numcon__header--close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.changeConfigClose(_vm.product.code)
                }
              }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.numberConfigVM.configurationHeadline) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("number-product-trigger", [
        _c(
          "button",
          {
            staticClass: "cm-numcon__header--toggle-open",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.changeConfig(_vm.product.code)
              }
            }
          },
          [_c("c-icon", { attrs: { "symbol-id": "arrow-down" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cm-numcon__product__info" }, [
        _c("img", {
          staticClass: "cm-numcon__image",
          attrs: { src: _vm.product.img, alt: _vm.product.subscriptionName }
        }),
        _vm._v(" "),
        _c("section", { staticClass: "cm-numcon__product__text" }, [
          _vm.numberConfigVM.subscriptionLabel
            ? _c("p", { staticClass: "cm-numcon__product__pre-label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.numberConfigVM.subscriptionLabel) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.subscriptionName
            ? _c(
                "h2",
                {
                  staticClass:
                    "\n          cm-numcon__product__header cm-numcon__product__header--specific\n        "
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.product.subscriptionName) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product.phoneNumber && _vm.configType === -1
            ? _c(
                "div",
                { staticClass: "cm-numcon__product__header--configured" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.numberConfigText(_vm.product.configType)))
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(_vm.showNewNumber(_vm.product.phoneNumber)) +
                        ")"
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.product.hiddenNumber
                          ? ", " + _vm.numberConfigVM.secretNumberLabel
                          : ""
                      )
                    )
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.product.unlistedNumber
                          ? ", " + _vm.numberConfigVM.unlistedNumberLabel
                          : ""
                      )
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.product.isReadOnly &&
                  _vm.product.isConfigurable &&
                  _vm.product.configComplete &&
                  !_vm.product.configEdited
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "c-btn c-btn--sm c-btn--as-link cm-numcon__choose",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.changeConfig(_vm.product.code)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.numberConfigVM.configurationChangeLabel
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productMeta
            ? _c("div", { staticClass: "cm-numcon__product__header" }, [
                _vm._v("\n        " + _vm._s(_vm.productMeta) + "\n      ")
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.product.phoneNumber &&
      _vm.configType === -1 &&
      _vm.product.isExistingNumberAndCprMismatch
        ? _c("div", { staticClass: "cm-numcon__product__error" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.numberConfigVM.errorMessageMismatchCprAndMsisdn) +
                "\n  "
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }