<template>
  <component :is="tag" v-toggle="id">
    <slot :expanded="expanded" />
  </component>
</template>

<script>
import VToggle from '../../directives/v-toggle.js';
import { getEventName } from '../../utils/event.js';
import EVENT_NAMESPACES from '../../constants/events.js';

const EVENT_STATE = getEventName(EVENT_NAMESPACES.COLLAPSE, 'state');

export default {
  name: 'c-collapse-trigger',

  directives: {
    toggle: VToggle
  },

  props: {
    id: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
      // TODO TC-4924: add element name validator
    }
  },

  data() {
    return {
      expanded: false
    };
  },

  mounted() {
    this.$root.$on(EVENT_STATE, this.handleStateChange);
  },

  beforeDestroy() {
    this.$root.$off(EVENT_STATE, this.handleStateChange);
  },

  methods: {
    handleStateChange(id, state) {
      if (id === this.id) {
        this.expanded = state;
      }
    }
  }
};
</script>
