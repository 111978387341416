<template>
  <div class="store-autocomplete">
    <div class="store-autocomplete__search">
      <c-input-group
        id="search"
        ref="autoComplete"
        v-model="autoCompleteValue"
        name="searchAddress"
        :place-holder-label="placeholder"
        disable-autocomplete
        prevent-submit
        @input="showAutoCompleteResults = true"
        @keyup.down="onArrowDown"
        @keyup.up="onArrowUp"
        @keyup.enter="onEnter"
        @keypress.enter.prevent
        @blur="resultsIndexCounter = 0"
      ></c-input-group>
      <div
        :class="{
          'store-autocomplete__search__icon': true,
          'store-autocomplete__search__icon--fill': !autoCompleteValue,
          'store-autocomplete__search__icon--stroke': autoCompleteValue
        }"
        @click="autoCompleteCloseClickHandler"
      >
        <c-icon v-if="!autoCompleteValue" symbol-id="menu-search" />
        <c-icon v-if="autoCompleteValue" class="close" symbol-id="menu-close" />
      </div>
    </div>

    <ul
      v-cloak
      v-if="
        showAutoCompleteResults &&
        searchResults.data.length &&
        searchResults.type === searchResultTypes.shop
      "
      class="
        store-autocomplete__results store-autocomplete__results--is-full-width
      "
    >
      <li
        v-for="(result, i) in searchResults.data"
        :key="result.id"
        :class="{
          'store-autocomplete__results__item': true,
          'store-autocomplete__results__item--flex': true,
          'store-autocomplete__results__item--is-focused':
            i === resultsIndexCounter
        }"
        @mouseOver="resultsIndexCounter = i"
        @click="selectAutoCompleteResult(result.id, result.storeName)"
      >
        <div class="store-autocomplete__results__item-texts">
          <p class="store-autocomplete__results__item__headline">
            {{ result.storeName }}
          </p>
          <p class="store-autocomplete__results__item__address">
            {{ result.streetAndNumber }}, {{ result.zipCode }} {{ result.city }}
          </p>
        </div>
        <div
          v-if="result.distance"
          class="store-autocomplete__results__item__distance"
        >
          {{ result.distance }}
        </div>
      </li>
    </ul>

    <ul
      v-cloak
      v-else-if="
        showAutoCompleteResults &&
        searchResults.data.length &&
        searchResults.type === searchResultTypes.address
      "
      class="
        store-autocomplete__results store-autocomplete__results--is-full-width
      "
    >
      <li
        v-if="addressTitle"
        class="
          store-autocomplete__results__item
          store-autocomplete__results__item--is-caption
        "
      >
        {{ addressTitle }}
      </li>
      <li
        v-for="(result, i) in searchResults.data"
        :ref="`result-${i}`"
        :key="'results-' + i"
        :class="{
          'store-autocomplete__results__item': true,
          'store-autocomplete__results__item--is-focused':
            i === resultsIndexCounter
        }"
        @mouseOver="resultsIndexCounter = i"
        @click="selectAddressResult"
      >
        <p class="store-autocomplete__results__item__headline">
          {{ result.displayName }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import geoLocationMixin from 'webshop/mixins/geo-location-mixin.js';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import getSuggestions from 'webshop/api/address-service';

import { TYPES } from 'webshop/enums/pickup-points';

export default {
  name: 'StoreAutocomplete',
  components: {
    CInputGroup,
    CIcon
  },
  mixins: [geoLocationMixin],

  props: {
    placeholder: {
      type: String,
      default: ''
    },
    shopData: {
      type: Array,
      required: true
    },
    addressTitle: {
      type: String,
      default: ''
    },
    useRelationsEndpoint: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      autoCompleteValue: null,
      showAutoCompleteResults: false,
      resultsIndexCounter: 0,
      searchResults: {
        type: undefined,
        data: []
      },
      searchResultTypes: {
        shop: TYPES.SHOP,
        address: TYPES.ADDRESS
      }
    };
  },

  watch: {
    autoCompleteValue(newValue) {
      if (!newValue) {
        this.setAddressResults([]);
        return;
      }

      const results = this.shopData
        .filter(({ storeName, streetAndNumber, zipCode, city }) => {
          const searchString = `${storeName} ${streetAndNumber} ${zipCode} ${city}`;
          return searchString
            .toUpperCase()
            .includes(this.autoCompleteValue.toUpperCase());
        })
        .slice(0, this.numberOfSearchResults);

      if (results.length) {
        this.searchResults.type = this.searchResultTypes.shop;
        this.setAddressResults(results);
        return;
      }

      getSuggestions({
        query: newValue,
        hasStreetName: true,
        pageSize: this.numberOfSearchResults,
        streetNameAndPostCodeRelationOnly: this.useRelationsEndpoint,
        callback: addresses => {
          this.searchResults.type = this.searchResultTypes.address;
          this.setAddressResults(addresses);
        }
      });
    }
  },

  methods: {
    setAddressResults(results) {
      this.searchResults.data = results;
    },

    /**
     * @function selectAutoCompleteResult
     * @param {Number} id
     * @param {string} newAutoCompleteValue The value of the autocomplete input field
     * @description Acts as a click handler for the click event on the autocomplete search results
     */
    selectAutoCompleteResult(id, newAutoCompleteValue) {
      if (this.searchResults.type === this.searchResultTypes.shop) {
        this.$emit('select-shop', id);
      }

      this.autoCompleteValue = newAutoCompleteValue;
      this.showAutoCompleteResults = false;
      this.resultsIndexCounter = 0;
    },

    selectAddressResult() {
      const selectedAddress = this.searchResults.data[this.resultsIndexCounter];

      if (this.searchResults.type === this.searchResultTypes.address) {
        this.$emit('select-address', {
          lat: selectedAddress.coordinate_y,
          lng: selectedAddress.coordinate_x,
          address: selectedAddress.displayName,
          street: selectedAddress.streetName,
          streetNumber: selectedAddress.streetNumber,
          postCode: selectedAddress.postCode
        });
      }

      this.autoCompleteValue = selectedAddress.displayName;
      this.showAutoCompleteResults = false;
      this.resultsIndexCounter = 0;
    },

    autoCompleteCloseClickHandler() {
      if (this.autoCompleteValue) {
        this.autoCompleteValue = null;
        this.$refs.autoComplete.focus();
        this.resultsIndexCounter = 0;
      }
    },

    onArrowDown() {
      if (this.resultsIndexCounter < this.searchResults.data.length - 1) {
        this.resultsIndexCounter++;
        this.scrollResultIntoView();
      } else {
        this.resultsIndexCounter = 0;
      }
    },

    onArrowUp() {
      if (this.resultsIndexCounter > 0) {
        this.resultsIndexCounter--;
        this.scrollResultIntoView();
      }
    },

    scrollResultIntoView() {
      const refName = `result-${this.resultsIndexCounter}`;
      this.$refs[refName][0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    },

    onEnter() {
      if (this.searchResults.type === this.searchResultTypes.shop) {
        const { id, storeName } =
          this.searchResults.data[this.resultsIndexCounter];
        this.selectAutoCompleteResult(id, storeName);
      } else if (this.searchResults.type === this.searchResultTypes.address) {
        this.selectAddressResult();
      }

      this.showAutoCompleteResults = false;
      this.resultsIndexCounter = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

// .store-autocomplete
.store-autocomplete {
  position: relative;

  // .store-autocomplete__search
  &__search {
    position: relative;

    // .store-autocomplete__search__icon
    &__icon {
      height: $u-400;
      position: absolute;
      right: $u-300;
      top: 14px;
      width: $u-400;

      &--fill > svg {
        fill: $c-nt-500;
      }

      &--stroke {
        height: $u-350;
        top: $u-300;
        width: $u-350;

        > svg {
          stroke: $c-nt-500;
          stroke-width: $u-150;
        }
      }

      // .store-autocomplete__search__icon svg
      > svg {
        height: 100%;
        width: 100%;

        // .store-autocomplete__search__icon svg.close
        &.close {
          cursor: pointer;
        }
      }
    }
  }

  // .store-autocomplete__results
  &__results {
    background: $color-white;
    border: 1px solid;
    border-color: $c-nt-300;
    font-size: $global-font-size-md;
    max-height: 500px;
    overflow: auto;
    position: absolute;

    z-index: 1;

    // .store-autocomplete__results--is-full-width
    &--is-full-width {
      width: 100%;
    }

    // .store-autocomplete__results__item
    &__item {
      border-bottom: 1px solid $c-nt-200;
      cursor: pointer;
      padding: $u-300 $u-200;
      transition: 0.2s ease-out;

      // .store-autocomplete__results__item--is-focused
      // .store-autocomplete__results__item:hover
      &--is-focused,
      &:hover {
        background: $c-nt-200;
      }

      // .store-autocomplete__results__item--is-caption
      &--is-caption {
        font-size: 10px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        cursor: initial;
        color: $c-nt-500;

        // .store-autocomplete__results__item--is-caption:hover
        &:hover {
          background: initial;
        }
      }

      &--flex {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
      }

      // .store-autocomplete__results__item__headline
      &__headline {
        font-size: $font-size-h4;
      }

      // .store-autocomplete__results__item__address
      &__address {
        font-size: $global-font-size-md;
        font-style: normal;
      }
    }
  }
}
</style>
