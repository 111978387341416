<template>
  <div>
    <template v-if="unaffected && unaffected.length">
      <h2>{{ contentLocal.unaffectedHeader }}</h2>
      <hr class="c-vas-configuration__divider" />
      <div class="c-vas-configuration c-vas-configuration--list">
        <c-vas-card-substitute
          v-for="(vas, index) in unaffected"
          :key="index"
          :vas="vas.attributes.vasProductViewModel.variants[0]"
        />
      </div>
    </template>
    <div v-if="showAffectedHeader">
      <h2>{{ contentLocal.affectedHeader }}</h2>
      <hr class="c-vas-configuration__divider" />
    </div>
    <template v-if="priceIncreases && priceIncreases.length">
      <p>
        <strong>{{ contentLocal.priceIncreaseHeader }}</strong>
      </p>
      <div class="c-vas-configuration">
        <div
          v-for="(vas, index) in priceIncreases"
          :key="index"
          class="c-vas-configuration__item"
        >
          <c-vas-card-substitute :vas="vas.variants[0]" current />
          <c-icon symbol-id="arrow-right" />
          <c-vas-card-substitute
            :vas="vas.attributes.vasProductViewModel.variants[0].substitute"
          />
        </div>
      </div>
    </template>
    <template v-if="priceDecreases && priceDecreases.length">
      <p>
        <strong>{{ contentLocal.priceDecreeseHeader }}</strong>
      </p>
      <div class="c-vas-configuration">
        <div
          v-for="(vas, index) in priceDecreases"
          :key="index"
          class="c-vas-configuration__item"
        >
          <c-vas-card-substitute
            :vas="vas.attributes.vasProductViewModel.variants[0]"
            current
          />
          <c-icon symbol-id="arrow-right" />
          <c-vas-card-substitute
            :vas="vas.attributes.vasProductViewModel.variants[0].substitute"
          />
        </div>
      </div>
    </template>
    <template v-if="affected && affected.length">
      <p>
        <strong>{{ contentLocal.newVasModalServicesinBasketText }}</strong>
      </p>
      <div class="c-vas-configuration c-vas-configuration--list">
        <c-vas-card-substitute
          v-for="(vas, index) in affected"
          :key="index"
          :vas="vas.attributes.vasProductViewModel.variants[0]"
        />
      </div>
    </template>
    <template v-if="removables && removables.length">
      <p>
        <strong>{{ contentLocal.removablesHeader }}</strong>
      </p>
      <p v-if="showInsuranceDisclaimer" class="c-vas-configuration__disclaimer">
        {{ contentLocal.insuranceDisclaimer }}
      </p>
      <div class="c-vas-configuration c-vas-configuration--list">
        <c-vas-card-substitute
          v-for="(vas, index) in removables"
          :key="index"
          :vas="vas.attributes.vasProductViewModel.variants[0]"
        />
      </div>
    </template>
  </div>
</template>

<script>
import CVasCardSubstitute from './c-vas-card-substitute.vue';
import { ITEM_TYPES } from '../../enums/access-technology-types';
import { STATE_INTERACTION } from './vas-constants';

const DEFAULT_CONTENT = {
  priceIncreaseHeader: 'Fordele der bliver dyrere',
  priceDecreasesHeader: 'Fordele der bliver billigere',
  removablesHeader: 'Fordele der forsvinder',
  affectedHeader: 'Fordele der ikke er tilgængelige',
  unaffectedHeader: 'Fordele der ikke ændres',
  insuranceDisclaimer:
    'Når du køber en ny telefon, kan du desværre ikke fortsætte med den gamle forsikring. Du kan altid vælge forsikring til din nye telefon.'
};

export default {
  name: 'c-vas-configuration',

  components: {
    CVasCardSubstitute
  },

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    vases: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  computed: {
    priceIncreases() {
      return this.vases.filter(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant =>
            variant.substitute !== null &&
            variant.price.value < variant.substitute.price.value
        );
      });
    },

    priceDecreases() {
      return this.vases.filter(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant =>
            variant.substitute !== null &&
            variant.price.value > variant.substitute.price.value
        );
      });
    },

    removables() {
      return this.vases.filter(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant =>
            variant.state.interaction === STATE_INTERACTION.REMOVE &&
            variant.substitute === null
        );
      });
    },

    affected() {
      return this.vases.filter(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant => variant.state.interaction === STATE_INTERACTION.DISABLED
        );
      });
    },

    unaffected() {
      return this.vases?.filter(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant => variant.state.interaction === STATE_INTERACTION.ADD
        );
      });
    },

    showInsuranceDisclaimer() {
      return this.removables.find(vas => {
        return vas.attributes.vasProductViewModel.variants?.find(
          variant => variant.type === ITEM_TYPES.INSURANCE
        );
      });
    },

    showAffectedHeader() {
      return (
        (this.priceIncreases && this.priceIncreases.length) ||
        (this.affected && this.affected.length) ||
        (this.removables && this.removables.length) ||
        (this.priceDecreases && this.priceDecreases.length) ||
        (this.showInsuranceDisclaimer && this.showInsuranceDisclaimer.length)
      );
    }
  }
};
</script>

<style lang="scss">
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.c-vas-configuration {
  padding-bottom: $u-350;

  &__disclaimer {
    font-size: $font-size-h5;
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $u-350;

    > svg {
      flex-shrink: 0;
      height: 19px;
      margin: 0 $u-450;
      width: 19px;

      @include mq($screen-md, max) {
        margin: 0 $u-150;
      }
    }
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin-bottom: $u-350;
    }
  }

  &__divider {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
}
</style>
