import { hasDocumentSupport } from './environment.js';

const documentElement = hasDocumentSupport ? document : {};

export const bodyElement = hasDocumentSupport ? document.body : {};

/**
 * Checks if the passed object is a DOM element.
 *
 * @param {*} el The object to check.
 * @return {Boolean} True if object is an element node.
 *
 */
export const isElement = function(el) {
  return Boolean(el && el.nodeType === Node.ELEMENT_NODE);
};

/**
 * Selects an element from the DOM via a selector.
 *
 * @param {*} selector CSS selector or element.
 * @param {*} root Optional - narrows the search.
 *
 */
export const selectElement = function(selector, root) {
  return (isElement(root) ? root : documentElement).querySelector(selector);
};

/**
 * Creates an element node and appends it inside a target
 * element.
 *
 * @param {string} tag The new element's tag.
 * @param {Element} parent The parent of the new element.
 * @return {Element} The newly created element.
 *
 */
export const insertElement = function(tag, parent) {
  const el = documentElement.createElement(tag);

  (isElement(parent) ? parent : documentElement).appendChild(el);
  return el;
};

export const setAttr = (el, attr, val) => {
  el.setAttribute(attr, val);
};

export const removeAttr = (el, attr) => {
  el.removeAttribute(attr);
};

export const getAttr = (el, attr) => {
  return el.getAttribute(attr);
};

export const hasAttr = (el, attr) => (attr && isElement(el) ? el.hasAttribute(attr) : null)

// todo: write description
export const addClass = (el, className) => {
  el.classList.add(className);
};

export const removeClass = (el, className) => {
  el.classList.remove(className);
};

// todo: write description
export const isDisabled = el =>
  !isElement(el) || el.disabled || hasAttr(el, 'disabled') || hasClass(el, 'disabled')

// todo: write description
export const matches = (el, selector) => (isElement(el) ? matchesEl.call(el, selector) : false)

// todo: write description
export const select = (selector, root) =>
  (isElement(root) ? root : d).querySelector(selector) || null

// todo: write description
export const hasClass = (el, className) => {
  if (className && isElement(el) && el.classList) {
    return el.classList.contains(className)
  }
  return false
}

// todo: write description
const elProto = typeof Element !== 'undefined' ? Element.prototype : {}

// todo: write description
export const matchesEl =
  elProto.matches || elProto.msMatchesSelector || elProto.webkitMatchesSelector