<template>
  <div>
    <span v-for="language in languages" :key="language">
      | {{ language }} |
    </span>
  </div>
</template>

<script>
export default {
  name: 'topup-language-switch',
  components: {},
  props: {},

  data() {
    return {
      languages: ['DK', 'ENG']
    };
  },
  methods: {}
};
</script>
