<template>
  <div class="store-stock-information">
    <div class="product-stock-status">
      <div class="product-stock-status__container">
        <p
          class="product-stock-status__text"
          :class="!loadingStoresStockData && stockStatusColor"
        >
          <template v-if="loadingStoresStockData">
            <skeleton-loader
              class="product-stock-status__text__skeleton-loader"
            />
          </template>

          <template v-else-if="totalInStock">
            På lager i&nbsp;
            <span
              class="product-stock-status__text--shop-stock"
              :class="
                !!totalInStock
                  ? 'product-stock-status__text--shop-stock-enabled'
                  : 'product-stock-status__text--shop-stock-disabled'
              "
              @click.prevent="totalInStock ? openStoreListModal() : null"
            >
              {{ storeLabelSingularOrPluralForm }}
            </span>
          </template>

          <template v-else>
            {{ labelNotInStock }}
          </template>
        </p>
      </div>
    </div>

    <c-modal
      v-if="displayStoreListModal"
      ref="storeListModal"
      gray
      is-full-height
      @close="clearExpandedStores"
    >
      <div class="store-list__heading">{{ modalHeader }}</div>
      <div class="store-list">
        <div class="store-list__search">
          <div class="epi-form-text">
            <input
              id="c-search-panel"
              ref="input"
              v-model="keyword"
              type="text"
              class="epi-form-text__input c-search-panel__input"
              placeholder=" "
            />

            <label for="c-search-panel" class="epi-form-text__label">
              {{ searchPlaceholderLabel }}
            </label>

            <span class="epi-form-text__input-ui" @click="keyword = ''">
              <c-icon
                :symbol-id="keyword.length > 0 ? 'menu-close' : 'menu-search'"
              />
            </span>
          </div>
        </div>

        <div class="store-list__filters">
          <c-checkbox
            id="only-show-nearby-stores"
            v-model="isNearbyStoresChecked"
          >
            {{ filterNearMeLabel }}
          </c-checkbox>

          <c-checkbox
            id="only-display-stores-with-stock"
            v-model="onlyShowStoresWithStock"
          >
            {{ filterOnlyInStockLabel }}
          </c-checkbox>
        </div>

        <div
          v-for="store in filteredStores"
          :key="store.shopCode"
          class="store-list__store"
          :class="{
            expanded: storeIsExpanded(store.shopCode)
          }"
          @click="
            !storeIsExpanded(store.shopCode) && togglestore(store.shopCode)
          "
        >
          <div class="store-list__store__header">
            <div class="store-list__store__header-meta">
              <p class="store-list__store__header-title">
                {{ store.storeName }}
              </p>

              <span
                v-if="userPosition"
                class="store-list__store__header-distance"
              >
                {{ store.prettyDistance }}
              </span>
            </div>

            <c-button
              reset
              icon-button
              class="store-list__store__header-toggle"
              @click.stop="togglestore(store.shopCode)"
            >
              <c-icon
                :symbol-id="`arrow-${
                  storeIsExpanded(store.shopCode) ? 'up' : 'down'
                }`"
              />
            </c-button>
          </div>

          <address class="store-list__store__address">
            {{ store.prettyAddress }}
          </address>

          <div class="store-list__stock-status">
            <div
              class="store-list__stock-status-indicator"
              :class="storeStockStatusColor(store.stockCount)"
            ></div>

            <template v-if="store.hasStock">
              {{
                store.stockCount >= stockThresholdIndicator
                  ? labelStoreInStockMany
                  : labelStoreInStockFew
              }}
            </template>

            <template v-else>{{ labelStoreNotInStock }}</template>
          </div>

          <template v-if="storeIsExpanded(store.shopCode)">
            <div class="store-list__store__actions">
              <a
                class="store-list__store__actions__action"
                :href="`tel:${store.phoneNumber}`"
              >
                <c-icon symbol-id="service-phone" />
                {{ store.phoneNumber }}
              </a>

              <a
                class="store-list__store__actions__action"
                :href="`https://www.google.com/maps/dir/?api=1&destination=${store.prettyAddress}`"
              >
                <c-icon symbol-id="arrow-directions" />
                {{ directionsLabel }}
              </a>
            </div>

            <div class="store-list__store__opening-hours">
              <c-heading level="4">{{ openingHoursLabel }}</c-heading>
              <div
                v-for="(openingHour, idx) in store.openingHours"
                :key="idx"
                class="store-list__store__opening-hours__list"
              >
                <span class="store-list__store__opening-hours__list-item">
                  {{ openingHour.key }}
                </span>

                <span class="store-list__store__opening-hours__list-item">
                  {{ openingHour.value }}
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </c-modal>
  </div>
</template>

<script>
import { get } from '../../api/api.js';
import geoLocationMixin from 'webshop/mixins/geo-location-mixin.js';

import {
  CHeading,
  CButton,
  CCheckbox,
  CModal
} from 'olympus/components/index.js';
import CIcon from 'webshop/components/c-icon/c-icon.vue';

import SkeletonLoader from '../skeleton-loader/skeleton-loader.vue';

export default {
  name: 'StoreStockInformation',

  components: {
    CButton,
    CIcon,
    CHeading,
    CModal,
    CCheckbox,
    SkeletonLoader
  },

  mixins: [geoLocationMixin],
  props: {
    selectedVariant: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loadingStoresStockData: false,
      displayStoreListModal: false,
      keyword: '',
      isNearbyStoresChecked: false,
      maxRange:
        this.selectedVariant.inventoryStatus.inventoryStatusModalMaxDistance,
      userPosition: null,
      expandedStores: [],
      onlyShowStoresWithStock: false,
      storesWithStock: [],
      stockThresholdIndicator:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalInStockMinAmount,
      storeData: [],
      labelNotInStock:
        this.selectedVariant.inventoryStatus.inventoryStatusLabelNotInStock,
      labelStoreInStockMany:
        this.selectedVariant.inventoryStatus.inventoryStatusLabelManyInStock,
      labelStoreInStockFew:
        this.selectedVariant.inventoryStatus.inventoryStatusLabelFewInStock,
      labelStoreNotInStock:
        this.selectedVariant.inventoryStatus
          .inventoryStatusLabelStoreNotInStock,
      modalHeader:
        this.selectedVariant.inventoryStatus.inventoryStatusModalHeader,
      searchPlaceholderLabel:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalSearchPlaceholderLabel,
      filterNearMeLabel:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalFilterNearMeLabel,
      filterOnlyInStockLabel:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalFilterOnlyInStockLabel,
      directionsLabel:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalDirectionsLabel,
      openingHoursLabel:
        this.selectedVariant.inventoryStatus
          .inventoryStatusModalOpeningHoursLabel
    };
  },

  computed: {
    filteredStores() {
      let stores = this.storeData
        .map(store => {
          const distance = this.getDistanceToStore(store);
          return {
            ...store,
            prettyAddress: `${store.streetAndNumber}, ${store.zipCode} ${store.city}`,
            hasStock: this.storesWithStock.some(
              i => i.shopCode === store.shopCode
            ),
            stockCount:
              this.storesWithStock.find(i => i.shopCode === store.shopCode)
                ?.quantity ?? 0,
            distance: distance,
            prettyDistance: this.getPrettyDistance(distance),
            storeKeywords:
              `${store.shopName} ${store.streetAndNumber} ${store.zipCode} ${store.city}`.toLocaleLowerCase()
          };
        })
        .filter(store => (this.onlyShowStoresWithStock ? store.hasStock : true))
        .filter(store =>
          store.storeKeywords.includes(this.keyword.toLocaleLowerCase())
        );

      if (this.isNearbyStoresChecked) {
        stores.sort((a, b) => a.distance - b.distance);
      }

      return stores;
    },

    totalInStock() {
      let totalInStock = 0;
      // Loop through stores with stock and add to total stock
      this.storesWithStock?.forEach(stockValue => {
        totalInStock += stockValue.quantity;
      });

      return totalInStock;
    },

    storesWithStockCount() {
      let count = 0;
      this.storesWithStock?.forEach(stockValue => {
        if (stockValue.quantity > 0) {
          count += 1;
        }
      });

      return count;
    },

    stockStatusColor() {
      return this.totalInStock ? 'green enabled' : 'red disabled';
    },

    storeLabelSingularOrPluralForm() {
      return this.storesWithStockCount > 1
        ? `${this.storesWithStockCount} butikker`
        : `${this.storesWithStockCount} butik`;
    },

    variantCode() {
      return this.selectedVariant ? this.selectedVariant.code : null;
    }
  },

  watch: {
    isNearbyStoresChecked(checked) {
      if (checked && !this.userPosition) {
        this.fetchUserLocation();
      }
    },

    variantCode: {
      handler(variantCode) {
        this.getStockInStores(variantCode);
      },
      immediate: true
    }
  },

  mounted() {
    this.getStoreData();
  },

  methods: {
    showErrorToast(errorMessage) {
      this.$addToast({
        title: 'Fejl',
        paragraph: errorMessage,
        state: 'error',
        duration: 10000
      });
    },

    async fetchUserLocation() {
      try {
        const userLocationResponse = await this.getUserLocation();
        this.userPosition = {
          lat: userLocationResponse.coords.latitude,
          lng: userLocationResponse.coords.longitude
        };
      } catch {
        this.showErrorToast(
          'Kunne ikke finde din placering. Aktiver placeringstjenesterne.'
        );
      }
    },

    async getStockInStores(variantCode) {
      this.loadingStoresStockData = true;

      try {
        const response = await get('/inventory/stockinstores', {
          params: {
            code: variantCode
          }
        });
        this.storesWithStock = response.data;
      } catch {
        this.showErrorToast(
          'Kunne ikke indlæse lageroplysninger. Prøv igen senere. Hvis problemet fortsætter, kontakt da kundeservice.'
        );
      } finally {
        this.loadingStoresStockData = false;
      }
    },

    async getStoreData() {
      try {
        const response = await get('/inventory/stores');
        this.storeData = response.data;
      } catch {
        this.showErrorToast(
          'Kunne ikke indlæse butiksdata. Prøv igen senere. Hvis problemet fortsætter, kontakt da kundeservice.'
        );
      }
    },

    openStoreListModal() {
      this.displayStoreListModal = true;
      this.$nextTick(() => {
        this.$refs.storeListModal.show();
      });
    },

    storeStockStatusColor(stock) {
      if (stock) {
        return stock >= this.stockThresholdIndicator ? 'green' : 'yellow';
      }
      return 'red';
    },

    getDistanceToStore(store) {
      if (this.userPosition) {
        return this.getDistanceBetweenToPoints(
          this.userPosition.lat,
          this.userPosition.lng,
          store.lat,
          store.lng
        );
      }
      return 0;
    },

    getPrettyDistance(distance) {
      return new Intl.NumberFormat('da-DK', {
        style: 'unit',
        unit: 'kilometer'
      }).format(distance);
    },

    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },

    storeIsExpanded(storeId) {
      return this.expandedStores.includes(storeId);
    },

    togglestore(storeId) {
      if (this.storeIsExpanded(storeId)) {
        this.expandedStores.splice(this.expandedStores.indexOf(storeId), 1);
      } else {
        this.expandedStores.push(storeId);
      }
    },

    clearExpandedStores() {
      this.expandedStores = [];
    }
  }
};
</script>

<style lang="scss">
@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-stock-status__text {
  font-size: $global-font-size-md;
  line-height: $global-line-height-xs;

  // .product-stock-status__text--shop-stock-enabled
  &--shop-stock-enabled {
    color: $c-pm-500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__skeleton-loader {
    height: $u-300;
    width: $u-900;
  }
}

.store-list {
  &__heading {
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;

    @include mq($screen-md, max) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__search {
    margin: $u-500 0 $u-300;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: $u-300;
    margin-bottom: $u-400;

    @include from-tablet {
      flex-direction: row;
      align-items: center;
      gap: $u-400;
      margin-bottom: $u-500;
    }
  }

  &__stock-status {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: $u-200;
    line-height: $u-400;

    &-indicator {
      width: $u-250;
      height: $u-250;
      border-radius: 50%;
      background: $c-sp-red-500;

      &.green {
        background: $c-sp-green-500;
      }

      &.yellow {
        background: $c-sp-orange-500;
      }
    }
  }

  &__store {
    padding: $u-300;
    border-top: $u-100 solid $c-nt-300;

    &:hover:not(.expanded) {
      cursor: pointer;
      background-color: $c-pm-100;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: $u-200;
      line-height: $u-400;

      &-meta {
        display: flex;
        align-items: center;
        gap: $u-200;
      }

      &-title {
        font-weight: 700;
      }

      &-km-label,
      &-distance {
        font-size: $global-font-size-md;
        line-height: $global-line-height-xs;
        display: flex;
        align-items: center;
        gap: $u-200;
      }

      &-distance {
        &:before {
          display: block;
          width: $u-150;
          height: $u-150;
          background: $c-nt-900;
          border-radius: 50%;
          content: '';
        }

        &__skeleton-loader {
          height: $u-250;
          width: $u-350;
        }
      }

      &-toggle {
        overflow: hidden;
        color: $c-pm-500;
      }
    }

    &__address {
      margin-bottom: $u-200;
      font-style: normal;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: $u-200;
      margin: $u-300 0 $u-250;

      &__action {
        line-height: $u-400;
        color: $c-pm-500;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: $u-200;

        svg {
          fill: $c-pm-500;
          stroke: $c-pm-500;
          width: $u-350;
          height: $u-350;
        }
      }
    }

    &__opening-hours__list {
      display: flex;
      line-height: $u-400;

      &-item {
        flex-basis: 160px;
      }
    }
  }
}
</style>
