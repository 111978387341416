<template>
  <div :class="modifiers" class="c-form-group">
    <label v-if="showLabel" :for="labelFor">
      <template>{{ label }}</template>
    </label>
    <slot />
    <template v-if="dirty">
      <template v-if="state">
        <div v-if="successMessage" class="c-form-group__success">{{ successMessage }}</div>
        <slot v-else name="successMessage" />
      </template>
      <template v-else>
        <div v-if="errorMessage" class="c-form-group__error">{{ errorMessage }}</div>
        <slot v-else name="errorMessage" />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'c-form-group',

  props: {
    label: {
      type: String,
    },
    labelFor: {
      type: String,
    },
    labelSrOnly: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    successMessage: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dirty: false
    }
  },

  watch: {
    state(value) {
      if (!this.dirty) {
        this.dirty = true; 
      }
    }
  },

  computed: {
    showLabel() {
      return !!this.label;
    },

    modifiers() {
      return {
        'c-form-group--sr-only': this.labelSrOnly,
        'c-form-group--inline': this.inline
      }
    }
  }
}
</script>

<style lang="scss">
.c-form-group {
  position: relative;
  margin-bottom: 10px;

  // .c-form-group > label
  & > label {
    display: inline-block;
    margin-bottom: .5rem;
  }

  // .c-form-group__success
  &__success {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745;
  }

  // .c-form-group__error
  &__error {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }

  // .c-form-group--sr-only
  &--sr-only {
    & > label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }
  }

  &--inline {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 50%;
      width: 0;
    }

    & > label {
      line-height: 1.5;
      padding-top: calc(.375rem + 1px);
      padding-bottom: calc(.375rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
    }
  }
}
</style>
