var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidden
    ? _c("portal", { attrs: { to: "portal" } }, [
        _c(
          "div",
          { staticClass: "c-modal", class: _vm.modifiers },
          [
            _c(
              "transition",
              {
                attrs: { name: "cm" },
                on: {
                  "before-enter": _vm.onBeforeEnter,
                  "after-leave": _vm.onAfterLeave
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisible,
                        expression: "isVisible"
                      }
                    ],
                    ref: "modal",
                    staticClass: "c-modal-wrapper",
                    attrs: {
                      "aria-hidden": _vm.isVisible ? "false" : "true",
                      role: "dialog",
                      tabindex: "0"
                    },
                    on: { keyup: _vm.onKeyUp, click: _vm.onClick }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "c-modal-wrapper-inner" },
                      [
                        _vm._t("inner"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "content",
                            staticClass: "c-modal-content",
                            class: _vm.modifiersContent
                          },
                          [
                            _vm.showLocalSpinner
                              ? _c("div", {
                                  staticClass:
                                    "cm-c-spinner cm-c-spinner--local"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showHeader
                              ? _c(
                                  "header",
                                  {
                                    staticClass: "c-modal-content__header",
                                    class: _vm.modifiersHeader
                                  },
                                  [
                                    _vm.showHeaderText
                                      ? _vm._t("header", [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "c-modal-content__header--text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.title) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showCloseButton
                                      ? _c(
                                          "c-button",
                                          {
                                            attrs: { reset: "" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.hide($event)
                                              }
                                            }
                                          },
                                          [_vm._t("close")],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "c-modal-content__body" },
                              [_vm._t("default")],
                              2
                            ),
                            _vm._v(" "),
                            _vm.showFooter
                              ? _c(
                                  "footer",
                                  { staticClass: "c-modal-content__footer" },
                                  [
                                    _vm._t("footer"),
                                    _vm._v(" "),
                                    _vm.showOkButton
                                      ? _c(
                                          "c-button",
                                          {
                                            staticClass:
                                              "co-c-btn co-c-btn__shout",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.hide($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.okButtonText) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      2
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "transition",
              {
                attrs: { name: "cm-backdrop" },
                on: {
                  "before-enter": _vm.onBeforeEnter,
                  "after-leave": _vm.onAfterLeave
                }
              },
              [
                _vm.isVisible
                  ? _c("div", { staticClass: "c-modal-backdrop" })
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }