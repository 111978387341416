var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-color",
      class: _vm.productColorClass,
      on: { click: _vm.colorPressed }
    },
    [
      _c("span", {
        staticClass: "product-color__circle",
        class: {
          "product-color__circle--light": _vm.isBackgroundLight(_vm.value)
        },
        style: { backgroundColor: _vm.value }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "product-color__text" }, [
        _vm._v(_vm._s(_vm.name))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }