var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    {
      staticClass: "c-information-box",
      class: _vm.modifiers,
      attrs: { shadow: _vm.shadow, animatein: _vm.animatein }
    },
    [
      _vm.icon
        ? _c(
            "div",
            { staticClass: "c-information-box__icon" },
            [_c("c-icon", { attrs: { "symbol-id": _vm.icon } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-information-box__content" },
        [_vm._t("header"), _vm._v(" "), _vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }