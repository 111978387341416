<template>
  <div class="mb-4">
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <h3 class="cm-c-form__header">
          {{ numberConfigVM.activateSIMHeading }}
        </h3>
      </c-col>
    </c-row>

    <c-row v-if="isExistingNumber" class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <c-form-radio-group v-model="selectedSimOption" name="actsim" stacked>
          <c-form-radio
            id="existingSimCard"
            checked-border
            :value="simOptionType.existingSimCard"
            @change="OnSimOptionChanged(simOptionType.existingSimCard)"
          >
            <span>{{ numberConfigVM.existingActiveSIMText }}</span>
          </c-form-radio>

          <c-form-radio
            id="calendar"
            checked-border
            :value="simOptionType.shipSimCard"
            @change="OnSimOptionChanged(simOptionType.shipSimCard)"
          >
            <span>{{ numberConfigVM.acceptActivateSIMText }}</span>
          </c-form-radio>

          <c-form-radio
            id="newSimCard"
            checked-border
            :value="simOptionType.newSimCard"
            @change="OnSimOptionChanged(simOptionType.newSimCard)"
          >
            <span>{{ numberConfigVM.refuseActivateSIMText }}</span>
          </c-form-radio>
        </c-form-radio-group>
      </c-col>
    </c-row>

    <c-row v-else class="cm-c-form__row">
      <c-col class="cm-c-form__col">
        <c-form-radio-group v-model="selectedSimOption" name="actsim" stacked>
          <c-form-radio
            id="calendar"
            checked-border
            :value="simOptionType.shipSimCard"
            @change="OnSimOptionChanged(simOptionType.shipSimCard)"
          >
            <span>{{ numberConfigVM.acceptActivateSIMText }}</span>
          </c-form-radio>

          <c-form-radio
            id="newSimCard"
            checked-border
            :value="simOptionType.newSimCard"
            @change="OnSimOptionChanged(simOptionType.newSimCard)"
          >
            <span>{{ numberConfigVM.refuseActivateSIMText }}</span>
          </c-form-radio>
        </c-form-radio-group>
      </c-col>
    </c-row>

    <c-row
      v-if="selectedSimOption == simOptionType.newSimCard"
      class="cm-c-form__row"
    >
      <c-col class="cm-c-form__col">
        <!--
        Prevent Enter button on the field to be able to use hand-scanners.
        Enter-button triggers a submit-form and then the "Accept-changes" on click-event on the button on the parent component, doesn't get called.
        Todo: will need some refactoring at some time.
        -->
        <c-input-group
          v-model="product.activateSimCardNumber"
          :input-error="simNumberError"
          :place-holder-label="numberConfigVM.simNumberPlaceholderText"
          @keydown.enter.stop.prevent
        />
      </c-col>
    </c-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';

import {
  CRow,
  CCol,
  CFormRadio,
  CFormRadioGroup
} from 'olympus/components/index.js';

import {
  maxLength,
  minLength,
  numeric,
  requiredIf
} from 'vuelidate/lib/validators/index.js';

const simOptionType = {
  existingSimCard: 1,
  shipSimCard: 2,
  newSimCard: 3
};

export default {
  name: 'NumberSimcardInfo',

  components: {
    CInputGroup,
    CRow,
    CCol,
    CFormRadio,
    CFormRadioGroup
  },

  props: {
    activeProduct: {
      type: Object,
      default: () => ({})
    },
    isExistingNumber: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      product: this.activeProduct,
      simOptionType,
      selectedSimOption: this.getSelectedSimOption()
    };
  },

  computed: {
    ...mapGetters(['numberConfigVM']),

    simNumberError() {
      return this.$v.product.activateSimCardNumber.$invalid &&
        this.$v.product.activateSimCardNumber.$model.length
        ? this.numberConfigVM.errorMessageSimNumberInvalid
        : '';
    }
  },

  validations: {
    product: {
      activateSimCardNumber: {
        required: requiredIf(selectedSimOption => {
          return selectedSimOption === simOptionType.newSimCard;
        }),
        minLength: minLength(11),
        maxLength: maxLength(20),
        numeric
      }
    }
  },

  created() {
    this.product.hasExistingActiveSim = this.isExistingNumber;
  },

  methods: {
    getSelectedSimOption() {
      if (this.isExistingNumber) {
        return simOptionType.existingSimCard;
      }

      if (this.activeProduct.activateSimCardNumber.length) {
        return simOptionType.newSimCard;
      }

      return simOptionType.shipSimCard;
    },

    OnSimOptionChanged: function (value) {
      switch (value) {
        case simOptionType.newSimCard:
          this.product.activateSimCard = true;
          this.product.hasExistingActiveSim = false;
          break;
        case simOptionType.existingSimCard:
          this.product.activateSimCard = false;
          this.product.activateSimCardNumber = '';
          this.product.hasExistingActiveSim = true;
          break;
        case simOptionType.shipSimCard:
          this.product.activateSimCard = false;
          this.product.activateSimCardNumber = '';
          this.product.hasExistingActiveSim = false;
          break;
        default:
          this.product.hasExistingActiveSim = false;
          this.product.activateSimCard = false;
          this.product.activateSimCardNumber = '';
      }
    }
  }
};
</script>
