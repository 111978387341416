var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      ref: "substitutesOverlay",
      attrs: { visible: _vm.open, gray: "", "show-close-button": false },
      on: { close: _vm.accept }
    },
    [
      _c("h2", { staticClass: "vas-consent__title" }, [
        _vm._v(_vm._s(_vm.contentLocal.overlayHeader))
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "vas-consent__description",
        domProps: { innerHTML: _vm._s(_vm.contentLocal.overlayManchet) }
      }),
      _vm._v(" "),
      _c("c-vas-substitutes", {
        attrs: { content: _vm.content.substitutes, vases: _vm.vases }
      }),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass: "c-vas-consent-overlay__button",
          on: { click: _vm.close }
        },
        [_vm._v("\n    " + _vm._s(_vm.contentLocal.closeButton) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }