var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-input-group",
      class: _vm.modifiers,
      attrs: { role: "group" }
    },
    [
      _vm.showPrepend
        ? _c(
            "div",
            { staticClass: "c-input-group-prepend" },
            [
              _vm._t("prepend", [
                _c("div", { staticClass: "c-input-group-text" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " + _vm._s(_vm.prependText) + "\n        "
                    )
                  ])
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.showAppend
        ? _c(
            "div",
            { staticClass: "c-input-group-append" },
            [
              _vm._t("append", [
                _c("div", { staticClass: "c-input-group-text" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " + _vm._s(_vm.appendText) + "\n        "
                    )
                  ])
                ])
              ])
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }