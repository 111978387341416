/* eslint-disable import/no-unresolved */
/**
 * OneBlock Init
 * @module oneblock-init.mjs
 * @version 1.1.21
 * @summary 02-06-2020
 * @author Mads Stoumann
 * @description Code for initialising OneBlock, running observers, polyfills etc.
 */
import {
	setJSHooks,
	observeResize,
	renderSplash,
	setCustom,
	setSectionTypes,
	setVideoPlay
} from './oneblock-client.mjs';
import { renderAll } from './oneblock-templates.mjs';
import { lazyLoad } from './common.mjs';

/**
 * @function detailsPolyfill
 * @description Adds support for <details>-element
 * @param {Node} Scope
 * @param {String} selector Defaults to attribute-selector `data-obj-fit`
 * @param {String} cls
 */
export function detailsPolyfill(scope = document, selector = 'details') {
	if (!('open' in document.createElement('details'))) {
		const details = scope.querySelectorAll(selector);
		details.forEach(detail => {
			detail.addEventListener('click', () => {
				detail.toggleAttribute('open');
			});
		});
	}
}

/**
 * @function objectFit
 * @description Adds objectFit-support
 * @param {Node} Scope
 * @param {String} selector Defaults to attribute-selector `data-obj-fit`
 * @param {String} cls
 */
export function objectFit(
	scope = document,
	selector = '[data-obj-fit]',
	cls = 'c-ob__bg'
) {
	const supportsObjectFit = 'objectFit' in document.documentElement.style;
	if (!supportsObjectFit) {
		const objFit = Array.from(scope.querySelectorAll(selector));
		objFit.forEach(image => {
			const parent = document.createElement('div');
			parent.className = cls;
			parent.style.cssText = `background-image:url(${image.src ||
				image.dataset
					.src}); background-size:cover; background-position: center center;`;
			image.parentNode.insertBefore(parent, image);
			image.parentNode.removeChild(image);
		});
	}
}

/**
 * @function svgUse
 * @description Looks up <symbol> in external <svg>, adds content of <symbol> to local <svg>
 * @param {Node} Scope
 * @param {String} cls
 */
export function svgUse(scope = document) {
	const use = scope.querySelectorAll('.c-ob__splash-elm');
	if (use) {
		use.forEach(elm => {
			const [url, id] = elm.getAttribute('xlink:href').split('#');
			fetch(url)
				.then(data => {
					return data.text();
				})
				.then(text => {
					const parser = new DOMParser();
					const doc = parser.parseFromString(text, 'text/xml');
					const svg = doc.getElementById(id);
					const fragment = document.createDocumentFragment();
					const viewBox = svg.getAttribute('viewBox');
					Array.from(svg.childNodes).forEach(node => {
						return fragment.appendChild(node);
					});
					if (viewBox) {
						elm.parentNode.setAttribute('viewBox', viewBox);
					}
					elm.parentNode.replaceChild(fragment, elm);
				})
				.catch(function(err) {
					// eslint-disable-next-line
					console.log(err);
				});
		});
	}
}

/**
 * @function initOneBlock
 * @description Inits OneBlocks clientside, adds ResizeObserver etc.
 * @param {Boolean} render Render OneBlocks clientside from JSON
 * @param {Boolean} initClasses Set classes from first breakpoint clientside
 */
export default async function initOneBlock(
	render = false,
	initClasses = false
) {
	if (render) {
		const app = document.getElementById('app');
		let content = await (await fetch('/demo/data/demo.coloplast.json')).json();
		app.innerHTML = renderAll(content);
	}
	setSectionTypes(); /* Must run BEFORE observeResize */
	observeResize('c-ob', initClasses);
	renderSplash(); /* Must run AFTER observeResize */
	setJSHooks();
	setVideoPlay();
	setCustom(false);
	lazyLoad();

	/* Polyfills for IE11 */
	const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
	if (isIE11) {
		detailsPolyfill();
		objectFit();

		const splashes = document.querySelectorAll('.c-ob__splash');
		if (splashes) {
			splashes.forEach(splash => {
				splash.parentNode.removeChild(splash);
			});
		}
	}
}
