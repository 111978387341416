<!-- TODO DKT-1440: We need to revisit the long class names in this template -->
<template>
  <div
    v-if="vm.accessories.variants.length"
    class="
      cm-grid__col--md-4 cm-grid__col--xs-12
      cm-c-power-step-accessory-card
    "
  >
    <div
      :id="`gallery-${accessoryIndex}`"
      class="cm-c-power-step-accessory-card__image-container"
    >
      <figure class="cm-c-card-product__photo cm-accessory-card">
        <div
          v-if="
            selectedVariant &&
            selectedVariant.badge &&
            selectedVariant.badgeText
          "
          class="cm-c-card-product__splash cm-c-splash"
          :class="selectedVariant.badgeText.item1"
        >
          <span class="cm-c-splash__wrap">{{
            selectedVariant.badgeText.item2
          }}</span>
        </div>
        <div class="cm-c-card-product__gallery-container cm-c-accesory-photo">
          <img
            v-if="selectedImage"
            class="cm-c-power-step-accessory-card__image"
            :src="selectedImage.src"
            :alt="selectedImage.caption"
          />
        </div>
      </figure>
    </div>
    <div class="cm-c-power-step-accessory-card__content">
      <h3 class="cm-c-cart-list__product-title">
        <nav class="cm-c-product-colors">
          <div
            v-for="(color, index) in vm.accessories.colors.data"
            :key="index"
            class="cm-c-product-colors-color"
            :style="{ background: color.description }"
            :class="{
              '-border': checkColor(color.description),
              '-selected':
                color && selectedVariant && color.name === selectedVariant.name
            }"
            @click="selectVariant(color)"
          ></div>
        </nav>
        <a :href="accessoryUrl" class="cm-c-power-step-accessory-card__url">{{
          vm.displayName
        }}</a>
        <span class="cm-c-power-step-accessory-card__price">
          {{ cashPriceLabel }} {{ vm.price.formattedValueCurrency }}
        </span>
      </h3>
      <div class="cm-c-cart-list__product-desc">
        <p v-if="vm.shortDescription">
          {{ vm.shortDescription }}
        </p>
        <p v-if="vm.description" v-html="vm.description"></p>
      </div>
      <a class="cm-c-power-step-accessory-card__link" :href="accessoryUrl">{{
        readMoreLabel
      }}</a>
    </div>
    <div class="cm-c-power-step-accessory-card__call-to-action-container">
      <transition name="flip">
        <a
          v-if="!inBasket"
          key="add"
          href="#"
          class="
            c-btn
            c-btn--block
            c-btn--secondary
            c-btn--secondary-transactional
            c-btn--md
            cm-mb
            cm-c-power-step-accessory-card__call-to-action
          "
          @click.prevent="addToBasket"
        >
          {{ addToBasketLabel }}
        </a>
      </transition>
      <transition name="flip">
        <a
          v-if="inBasket"
          key="navigate"
          :href="basketUrl"
          class="
            c-btn c-btn--block c-btn--secondary c-btn--md
            cm-mb
            cm-c-power-step-accessory-card__call-to-action
          "
        >
          {{ goToBasketLabel }}
        </a>
      </transition>
      <transition name="flip">
        <div
          v-if="inBasket"
          class="cm-c-power-step-accessory-card__in-basket-msg"
        >
          {{ addedToBasketLabel }}
        </div>
      </transition>
    </div>
    <image-gallery
      :open-link-elm="`#gallery-${accessoryIndex}`"
      :image-list="images"
      :zoom-inline="false"
    ></image-gallery>
  </div>
</template>
<script>
import ImageGallery from '../product/image-gallery.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  ACTIONS as BASKET_ACTIONS,
  GETTERS as BASKET_GETTERS
} from '../../store/basket';
import colorMixin from '../../mixins/color-mixin';

// TODO DKT-1440: Can we remove this component entirely
// todo: this needs heavy refactoring
// as it is mutating a properties, broken
// state management.
export default {
  name: 'PowerStepAccessoryCard',

  components: {
    'image-gallery': ImageGallery
  },

  mixins: [colorMixin],

  props: [
    'vm',
    'groupId',
    'accessoryUrl',
    'basketUrl',
    'addToBasketLabel',
    'cashPriceLabel',
    'readMoreLabel',
    'goToBasketLabel',
    'addedToBasketLabel',
    'accessoryIndex',
    'installmentsPeriod'
  ],

  data() {
    return {
      selectedVariant: null,
      images: this.vm.accessories.variants.length
        ? this.getGalleryImages(
            this.vm.accessories.defaultVariant.code,
            this.vm.accessories.defaultVariant.name,
            true
          )
        : null,
      selected: this.vm.accessories.variants.length
        ? this.createSelected(
            this.vm.accessories.defaultVariant.code,
            this.vm.accessories.defaultVariant.name,
            true
          )
        : null
    };
  },

  computed: {
    ...mapGetters({
      basketContainsItem: BASKET_GETTERS.CONTAINS_ITEM
    }),
    getCode() {
      if (!this.selectedVariant) {
        return null;
      }

      const color = this.selectedVariant.code;
      const variant = this.vm.accessories.variants.find(item => {
        return item.key === color;
      });
      return variant.variants[0].code;
    },
    inBasket() {
      return this.basketContainsItem(this.getCode);
    },
    variants() {
      return this.vm.accessories.variants.reduce((arr, group) => {
        return arr.concat(group.variants);
      }, []);
    },
    selectedImage() {
      if (!this.getCode) {
        return null;
      }

      const selected = this.variants.find(x => {
        return x.code === this.getCode;
      });

      return selected.variantImage;
    }
  },

  mounted() {
    if (this.vm.accessories.colors) {
      this.vm.accessories.colors.data.forEach(variant => {
        const variantBadge = this.vm.accessories.variants.find(item => {
          return item.key === variant.code;
        });
        //create the badge object
        if (this.showBadge(variantBadge.variants[0].badgeText)) {
          variant.badge = true;
          variant.badgeText = variantBadge.variants[0].badgeText;
        } else {
          variant.badge = false;
        }

        if (variant.isDefaultVariant) {
          this.selectedVariant = variant;
        }
      });

      this.selectedVariant = this.vm.accessories.colors.data.find(item => {
        if (!this.vm.accessories.defaultVariant) {
          return false;
        }
        return item.code === this.vm.accessories.defaultVariant.name;
      });
      if (this.selectedVariant === null) {
        this.selectedVariant = this.vm.accessories.colors.data[0];
      }
    }
  },

  methods: {
    ...mapActions({
      addAccessory: BASKET_ACTIONS.ADD_ACCESSORY
    }),
    addToBasket() {
      const payload = {
        code: this.getCode,
        groupId: this.groupId,
        quantity: 1,
        installmentType: this.installmentsPeriod,
        upsale: false
      };
      this.addAccessory(payload);
    },
    /* Detect if BadgeText is empty */
    showBadge(badgeTextObj) {
      return !Object.keys(badgeTextObj).reduce(
        (res, k) =>
          res &&
          !(
            !!badgeTextObj[k] ||
            badgeTextObj[k] === false ||
            !isNaN(parseInt(badgeTextObj[k], 10))
          ),
        true
      );
    },
    selectVariant(variant) {
      this.selectedVariant = variant;
    },
    checkColor(color) {
      return this.isColorWhite(color);
    },
    getGalleryImages(productCode, colorCode) {
      let dataVariant = null;
      dataVariant = dataVariant
        ? dataVariant
        : this.getVariant(productCode, colorCode);
      return dataVariant.images.map(image => ({
        thumbnail: image.src,
        src: image.src,
        type: image.type
      }));
    },
    getColorGroupAndCodesFromFragment() {
      let colorGroup = null;
      let productCode = null;
      let colorCode = null;
      if (document.location.hash) {
        const fragments = document.location.hash
          .replace(/^[#/]*/, '')
          .split('/');
        const colorNameFromFragment = fragments.shift();
        colorGroup = this.vm.accessories.variants.find(
          item =>
            item.key.replace(/[\s/]/g, '-').toLowerCase() ===
            colorNameFromFragment
        );
        productCode =
          colorGroup && colorGroup.variants && colorGroup.variants.length
            ? colorGroup.variants[0].code
            : productCode;
        colorCode = colorGroup ? colorGroup.key : colorCode;
      }
      return {
        colorGroup,
        productCode,
        colorCode
      };
    },
    createSelected(productCode, colorCode, isDefault = false) {
      let colorGroup = null;

      /* This function creates a "selected"-object, which will build the data needed for wizard-selection */
      colorGroup = colorGroup
        ? colorGroup
        : this.vm.accessories.variants.find(item => item.key === colorCode);

      /* If no productCode is specified, take first variant from color-group */
      let itemCode = productCode;
      if (!itemCode) {
        const tmpStorageVariant = colorGroup.storages.data.find(elm => {
          return elm.storage === this.selectedStorage;
        });
        itemCode = tmpStorageVariant
          ? tmpStorageVariant.code
          : colorGroup.storages.data[0].code;
      }
      const dataVariant = this.getVariant(productCode, colorCode);

      const selected = {
        productCode: itemCode,
        data: {
          inventoryStatus: dataVariant.inventoryStatus,
          image: dataVariant.variantImage,
          price: dataVariant.minimumMonthlyPrice
        }
      };

      // Update gallery to use images on variant level - only on item color change
      if (!isDefault && !productCode) {
        this.images = dataVariant.images.map(image => ({
          thumbnail: image.src,
          src: image.src,
          type: image.type
        }));
      }

      return selected;
    },
    buildImagesObject(arrImages) {
      return arrImages.map(image => ({
        thumbnail: image.src || image,
        src: image.src || image,
        type: image.type ? image.type.toLowerCase() : '',
        caption: image.caption ? image.caption : ''
      }));
    },
    getVariant(productCode, colorCode) {
      const colorGroup = this.vm.accessories.variants.find(
        item => item.key === colorCode
      );
      /* If no productCode is specified, take first variant from color-group */
      const itemCode = productCode
        ? productCode
        : colorGroup.storages.data[0].code;
      const dataVariant =
        itemCode && colorGroup.variants.find(item => item.code === itemCode);
      return dataVariant;
    }
  }
};
</script>

<style lang="scss">
.c-btn--block.cm-c-power-step-accessory-card__call-to-action {
  display: flex;
}
</style>
