var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cm-c-countdown",
      class: { "cm-c-countdown--expired": !_vm.isEnabled }
    },
    [
      _c("h3", { staticClass: "cm-c-countdown__title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cm-c-countdown__watch" }, [
        _c("div", { staticClass: "cm-c-countdown__number-label-container" }, [
          _c("div", { staticClass: "cm-c-countdown__number-container" }, [
            _c(
              "span",
              {
                staticClass: "cm-c-countdown__number",
                class: { "-animating": _vm.animateDays }
              },
              [_vm._v("\n          " + _vm._s(_vm.days) + "\n        ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-countdown__number" }, [
              _vm._v("\n          " + _vm._s(_vm.nextDays) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "cm-c-countdown__label" }, [
            _vm._v("\n        " + _vm._s(_vm.daysLabel) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__separator" }),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__number-label-container" }, [
          _c("div", { staticClass: "cm-c-countdown__number-container" }, [
            _c(
              "span",
              {
                staticClass: "cm-c-countdown__number",
                class: { "-animating": _vm.animateHours }
              },
              [_vm._v("\n          " + _vm._s(_vm.hours) + "\n        ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-countdown__number" }, [
              _vm._v("\n          " + _vm._s(_vm.nextHours) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "cm-c-countdown__label" }, [
            _vm._v("\n        " + _vm._s(_vm.hoursLabel) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__separator" }),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__number-label-container" }, [
          _c("div", { staticClass: "cm-c-countdown__number-container" }, [
            _c(
              "span",
              {
                staticClass: "cm-c-countdown__number",
                class: { "-animating": _vm.animateMinutes }
              },
              [_vm._v("\n          " + _vm._s(_vm.minutes) + "\n        ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-countdown__number" }, [
              _vm._v("\n          " + _vm._s(_vm.nextMinutes) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "cm-c-countdown__label" }, [
            _vm._v("\n        " + _vm._s(_vm.minutesLabel) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__separator" }),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-countdown__number-label-container" }, [
          _c("div", { staticClass: "cm-c-countdown__number-container" }, [
            _c(
              "span",
              {
                staticClass: "cm-c-countdown__number",
                class: { "-animating": _vm.animateSeconds }
              },
              [_vm._v("\n          " + _vm._s(_vm.seconds) + "\n        ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-countdown__number" }, [
              _vm._v("\n          " + _vm._s(_vm.nextSeconds) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "cm-c-countdown__label" }, [
            _vm._v("\n        " + _vm._s(_vm.secondsLabel) + "\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.showParallaxBackground
        ? _c(
            "parallax-wrapper",
            {
              staticClass: "cm-c-countdown__background-wrapper",
              attrs: { direction: -1, axis: "y", speed: 0.08 }
            },
            [
              _c("span", {
                staticClass: "cm-c-countdown__background",
                style: _vm.backgroundImageStyle,
                attrs: { "data-testid": "countdown-background" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }