import { render, staticRenderFns } from "./c-box.vue?vue&type=template&id=00b1ab9b&"
import script from "./c-box.vue?vue&type=script&lang=js&"
export * from "./c-box.vue?vue&type=script&lang=js&"
import style0 from "./c-box.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00b1ab9b')) {
      api.createRecord('00b1ab9b', component.options)
    } else {
      api.reload('00b1ab9b', component.options)
    }
    module.hot.accept("./c-box.vue?vue&type=template&id=00b1ab9b&", function () {
      api.rerender('00b1ab9b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/shared/components/c-box/c-box.vue"
export default component.exports