var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._l(_vm.shoutingText, function(word, i) {
      return _c(
        "span",
        { key: word + i, staticClass: "shouting-font" },
        [
          _vm._l(word, function(character, ci) {
            return _c(
              "span",
              {
                key: character + ci,
                class: _vm.isAlphaNumeric(character)
                  ? "shouting-font__letter"
                  : "shouting-font__other"
              },
              [_vm._v(_vm._s(character))]
            )
          }),
          _vm._v(" "),
          _vm.shoutingText.length !== i + 1 ? _c("span") : _vm._e()
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }