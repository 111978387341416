<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <component
      :is="tag"
      :id="id"
      ref="mutationObserver"
      :class="modifiers"
      :style="transitionProps"
      class="c-collapse"
      :aria-hidden="show ? 'false' : 'true'"
    >
      <slot :expanded="show" />
    </component>
  </transition>
</template>

<script>
import { getEventName } from '../../utils/event.js';
import EVENT_NAMESPACES from '../../constants/events.js';

const EVENT_STATE = getEventName(EVENT_NAMESPACES.COLLAPSE, 'state');
const EVENT_REQUEST_STATE = getEventName(
  EVENT_NAMESPACES.TOGGLE,
  'request-state'
);
const EVENT_TOGGLE = getEventName(EVENT_NAMESPACES.TOGGLE, 'collapse');

export default {
  name: 'CCollapseObserve',

  model: {
    prop: 'visible',
    event: 'change'
  },

  props: {
    id: {
      type: String,
      // `id` is required so vue can reference back to it
      // if you get a warning: `[Vue warn]: Missing required prop: "id"`
      // means you need to set `id` for your c-collapse component
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    tag: {
      // TODO TC-4924: add tag validator
      type: String,
      default: 'div'
    },
    fogged: {
      type: Boolean,
      default: false
    },
    transitionDuration: {
      type: String,
      default: '500ms'
    },
    transitionEasing: {
      type: String,
      default: 'ease-in-out'
    }
  },

  data() {
    return {
      show: this.visible,
      maxHeight: 0,
      paddingTop: '0px',
      paddingBottom: '0px',
      altTransitionDuration: false,
      altTransitionTimingFunction: 'linear',
      observer: null
    };
  },

  computed: {
    modifiers() {
      return {
        'c-collapse--expanded': this.show,
        'c-collapse--fogged': this.fogged,
        'c-collapse--padding': this.usePadding
      };
    },
    transitionProps() {
      return {
        transitionDuration: this.altTransitionDuration
          ? this.altTransitionDuration
          : this.transitionDuration,
        transitionTimingFunction: this.altTransitionDuration
          ? this.altTransitionTimingFunction
          : this.transitionEasing,
        '--maxheight': this.maxHeight + 'px',
        '--paddingtop': this.paddingTop,
        '--paddingbottom': this.paddingBottom
      };
    },
    usePadding() {
      return this.paddingTop !== '0px' || this.paddingBottom !== '0px';
    }
  },

  watch: {
    visible(value) {
      this.show = value;
    },
    show() {
      this.emitState();
    }
  },

  mounted() {
    this.$root.$on(EVENT_TOGGLE, this.handleToggleEvent);
    this.$root.$on(EVENT_REQUEST_STATE, this.handleToggleRequestStateEvent);

    this.$nextTick(() => {
      const style = window.getComputedStyle(this.$el);
      this.emitState();
      this.maxHeight = this.$el.scrollHeight;
      this.paddingTop = style.getPropertyValue('padding-top');
      this.paddingBottom = style.getPropertyValue('padding-bottom');
    });

    this.observer = new MutationObserver(() => {
      let transitionChecker;
      this.maxHeight = this.$el.scrollHeight;
      transitionChecker = setInterval(() => {
        if (this.maxHeight !== this.$el.scrollHeight) {
          this.altTransitionDuration = '0ms';
          this.maxHeight = this.$el.scrollHeight;
        } else {
          this.altTransitionDuration = false;
          clearInterval(transitionChecker);
        }
      }, 100);
    });

    this.observer.observe(this.$refs.mutationObserver, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    });
  },

  beforeDestroy() {
    this.$root.$off(EVENT_TOGGLE, this.handleToggleEvent);
    this.$root.$off(EVENT_REQUEST_STATE, this.handleToggleRequestStateEvent);

    this.observer.disconnect();
  },

  methods: {
    handleToggleEvent(target) {
      if (target !== this.id) {
        return;
      }
      this.toggle();
    },

    handleToggleRequestStateEvent(target) {
      if (target !== this.id) {
        return;
      }
      this.$root.$emit(EVENT_STATE, this.id, this.show);
    },

    toggle() {
      this.show = !this.show;
    },

    emitState() {
      this.$root.$emit(EVENT_STATE, this.id, this.show);
      this.$emit(this.show ? 'expand' : 'collapse');
      this.$emit('change', this.show);
    },

    enter(element) {
      this.maxHeight = element.scrollHeight;
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.width = null;
    },
    afterEnter(element) {
      this.maxHeight = element.scrollHeight;
    },
    leave(element) {
      this.maxHeight = element.scrollHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  will-change: max-height, padding;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.c-collapse {
  max-height: 0;
  overflow: hidden;

  // .c-collapse--padding
  &--padding {
    padding: 0;
  }

  // .c-collapse--expanded
  &--expanded {
    max-height: calc(
      var(--maxheight) + var(--paddingtop) + var(--paddingbottom)
    );
    padding-top: var(--paddingtop);
    padding-bottom: var(--paddingbottom);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      // IE fallback since IE doesn't support custom properties/CSS vars
      max-height: 4000px;
    }
  }

  // .c-collapse--fogged
  &--fogged {
    padding-bottom: 15px;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 15px;
      position: absolute;
      left: 0;
      bottom: 0px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.expand-enter-active,
.expand-leave-active {
  transition-property: max-height, padding;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
}
</style>
