var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datepicker" },
    [
      _c("input", {
        ref: "input",
        class: _vm.inputClasses,
        attrs: {
          type: "text",
          name: _vm.inputName,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled
        },
        domProps: { value: _vm.inputValue },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleCalendar()
          },
          keydown: _vm.handleKeys
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "datepicker__label",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.toggleCalendar()
            }
          }
        },
        [_vm._t("icon")],
        2
      ),
      _vm._v(" "),
      _vm._t("label"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            },
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.hideCal,
              expression: "hideCal"
            }
          ],
          ref: "calendar",
          staticClass: "calendar",
          attrs: { role: "grid" }
        },
        [
          _vm._t("header"),
          _vm._v(" "),
          _c("div", { staticClass: "calendar__header__wrapper" }, [
            _c("nav", { staticClass: "calendar__nav" }, [
              _c(
                "button",
                {
                  staticClass: "calendar__nav--left",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.setCal(_vm.year, _vm.month - 1, 1)
                    }
                  }
                },
                [
                  _c("c-icon", {
                    staticClass: "cm-numcon__number-search-help--icon",
                    attrs: { "symbol-id": "arrow-left-small" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "calendar__header", attrs: { role: "header" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.caption) +
                      " " +
                      _vm._s(_vm.year) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "calendar__nav--right",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.setCal(_vm.year, _vm.month + 1, 1)
                    }
                  }
                },
                [
                  _c("c-icon", {
                    staticClass: "cm-numcon__number-search-help--icon",
                    attrs: { "symbol-id": "arrow-right-small" }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _vm._l(_vm.weekdays, function(weekday, weekdayindex) {
            return _c(
              "span",
              {
                key: weekdayindex,
                staticClass: "calendar__days",
                attrs: { role: "columnheader" }
              },
              [_vm._v("\n      " + _vm._s(weekday) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.days, function(day) {
            return _c(
              "span",
              { key: day.timestamp, staticClass: "calendar__day" },
              [
                day.value
                  ? _c(
                      "a",
                      {
                        class: _vm.dayClasses(day),
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.insertDate(day, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.dayFormat(day.value)) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _vm._t("footer")
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }