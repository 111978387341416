var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-compare__product-card" },
    [
      _c(
        "h4",
        {
          staticClass:
            "c-compare__product-card__product-brand c-typography-caption"
        },
        [_vm._v("\n    " + _vm._s(_vm.product.productBrand) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "h3",
        {
          staticClass: "c-compare__product-card__product-title c-typography-h6"
        },
        [
          _c("a", { attrs: { href: "item.key.ProductUrl" } }, [
            _vm._v(
              _vm._s(
                _vm.product.productName.replace(_vm.product.productBrand, "")
              )
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-compare__product-card__product-photo" }, [
        _c("img", {
          attrs: {
            src: _vm.product.productImageUrl,
            alt: _vm.product.productName
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-compare__product-card__product-price-block" },
        [
          _c("p", { staticClass: "c-compare__product-card--product-price" }, [
            _c("span", { staticClass: "c-typography-h4" }, [
              _vm._v(_vm._s(_vm.productPricePerMonth))
            ]),
            _vm._v(" "),
            _c("small", [_vm._v(_vm._s(_vm.productPricePerMonthSuffix))])
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "\n        c-compare__product-card__text\n        c-compare__product-card__text--sm\n        c-compare__product-card__text--light\n      "
            },
            [_vm._v("\n      " + _vm._s(_vm.product.minimumPrice) + "\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass:
            "c-link-button c-link-button--small c-link-button--primary",
          attrs: { href: _vm.product.productUrl }
        },
        [_vm._v("\n    " + _vm._s(_vm.buttonCaption) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }