/**
 * Use this mixin to expose number-related methods to components.
 */
export default {
  methods: {
    /**
     * Groups the passed number by the number of provided digits.
     * @example formatNumberByDigits(20304050, 2); // '20 30 40 50';
     *
     * @param { number } number
     * @param { number } digits number of digits to group by.
     *
     * @returns { String } formatted number.
     */
    formatNumberByDigits(number, digits = 2) {
      if (!number) {
        return '';
      }

      const expression = new RegExp(`\\d{1,${digits}}`, 'g');

      return number
        ?.toString()
        ?.match(expression)
        ?.reduce((acc, cur) => `${acc} ${cur}`, '')
        ?.trim();
    }
  }
};
