var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass:
        "\n    cm-c-checkout__module cm-c-checkout__module--mb\n    cm-c-total__section\n    billing-address\n  ",
      attrs: { "no-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h3", { staticClass: "cm-c-checkout__subtitle" }, [
        _vm._v(_vm._s(_vm.content.header))
      ]),
      _vm._v(" "),
      _c(
        "c-row",
        [
          _c("c-col", [
            _c("strong", [_vm._v(_vm._s(_vm.content.paymentTypeLabel))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        [
          _c("c-col", { attrs: { sm: "12", cols: "6" } }, [
            _vm._v(_vm._s(_vm.content.paymentMethodLabel))
          ]),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "cm-text-right-md", attrs: { sm: "12", cols: "6" } },
            [
              _c("ul", [
                _c("li", [_vm._v(_vm._s(_vm.data.recurringPaymentTypeName))]),
                _vm._v(" "),
                _vm.data.recurringPaymentType === "Pbs" &&
                _vm.data.pbsRegNumber &&
                _vm.data.pbsAccountNumber
                  ? _c("li", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.data.pbsRegNumber +
                              " " +
                              _vm.data.pbsAccountNumber
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.content.upfrontPaymentMethodLabel
        ? [
            _c(
              "c-row",
              [
                _c("c-col", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.content.productHeadingLabel))
                  ])
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "c-row",
              [
                _c("c-col", { attrs: { sm: "12", cols: "6" } }, [
                  _vm._v(_vm._s(_vm.content.paymentMethodLabel))
                ]),
                _vm._v(" "),
                _c(
                  "c-col",
                  {
                    staticClass: "cm-text-right-md",
                    attrs: { sm: "12", cols: "6" }
                  },
                  [
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.content.cardPaymentIncludedLabel +
                                " " +
                                _vm.content.upfrontPaymentMethodLabel
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.data.installmentPeriod &&
                      _vm.data.installmentPeriodUnit
                        ? _c("li", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.data.installmentPeriod +
                                    " " +
                                    _vm.data.installmentPeriodUnit
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }