<script>
import { mapGetters } from 'vuex';

export default {
  name: 'checkout-upfront-payment-method',
  props: [
    'paymentTypeCreditCard',
    'paymentTypeInvoice',
    'paymentTypeSubsidy',
    'paymentTypeNone',
    'subsidyPaymentDisabled',
    'selectedPaymentType'
  ],

  data() {
    return {
      paymentType: this.selectedPaymentType
    };
  }
};
</script>
