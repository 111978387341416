<template>
  <div class="plug-config">
    <div v-if="offerings.length && !offering && isLoading">
      <c-row>
        <c-col cols="6">
          <skeleton-loader class="plug-config__skeleton-loader__title" />
        </c-col>
      </c-row>
      <c-row>
        <c-col cols="10">
          <skeleton-loader class="plug-config__skeleton-loader__description" />
        </c-col>
      </c-row>
      <c-row>
        <c-col cols="6" sm="12">
          <skeleton-loader class="plug-config__skeleton-loader__plug-type" />
        </c-col>
        <c-col cols="6" sm="12" align-self="center">
          <skeleton-loader class="plug-config__skeleton-loader__radio" />
          <skeleton-loader class="plug-config__skeleton-loader__radio" />
        </c-col>
      </c-row>
    </div>
    <!-- Plug information -->
    <template v-if="offerings.length && offering && !isLoading && askAboutPlug">
      <div class="mb-3">
        <h3 class="cm-c-form__header">
          {{ content.plugSectionHeader }}
        </h3>
        <p>
          {{ content.plugSectionDescription }}
        </p>
      </div>
      <c-row class="mb-3">
        <c-col cols="6" sm="12">
          <c-icon
            v-for="plug in selectedPlug"
            :key="plug"
            class="plug-config__plug__icon"
            :symbol-id="plug"
          />
        </c-col>
        <c-col cols="6" sm="12" align-self="center">
          <c-form-radio-group
            v-model="form.hasPlug"
            name="hasPlug"
            stacked
            highlighted
            @input="updatePlugInfo"
          >
            <c-form-radio id="plugDoesExist" :value="true">
              <span>{{ content.plugExistButtonLabel }}</span>
            </c-form-radio>
            <c-form-radio id="plugDoesNotExist" :value="false">
              <span>{{ content.plugDoesntExistButtonLabel }}</span>
            </c-form-radio>
          </c-form-radio-group>
        </c-col>
      </c-row>
      <c-collapse
        id="plugDoesNotExistInformation"
        :visible="
          technicianRequiredDisclaimer || showPlugDoesNotExistDisclaimer
        "
      >
        <c-row>
          <c-col>
            <c-information-box
              icon="contact-information"
              type="informative"
              class="plug-config__plug__information"
            >
              <span v-html="content.technicianHelpRequiredDisclaimer"></span>
            </c-information-box>
          </c-col>
        </c-row>
      </c-collapse>
    </template>
  </div>
</template>

<script>
import {
  CRow,
  CCol,
  CFormRadioGroup,
  CFormRadio,
  CCollapse,
  CInformationBox
} from 'olympus/components/index.js';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import SkeletonLoader from 'webshop/components/skeleton-loader/skeleton-loader.vue';

import { mapGetters } from 'vuex';
import { GETTERS as HOME_OFFICE_GETTERS } from 'webshop/store/home-office/index.js';

import { PLUGS_TYPES } from 'webshop/enums/broadband-subscription-types.js';

export default {
  name: 'EmployeeConfigPlug',

  components: {
    CRow,
    CCol,
    CFormRadioGroup,
    CFormRadio,
    CCollapse,
    CInformationBox,
    CIcon,
    SkeletonLoader
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    offering: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: {
        hasPlug: this.orderData ? this.orderData.hasPlug : null
      },
      technicianRequiredDisclaimer: false,
      askAboutPlug: this.orderData ? this.orderData.askAboutPlug : true
    };
  },

  computed: {
    ...mapGetters({
      isLoading: HOME_OFFICE_GETTERS.GET_IS_LOADING,
      offerings: HOME_OFFICE_GETTERS.GET_CARD_DATA,
      orderData: HOME_OFFICE_GETTERS.GET_ORDER_DATA
    }),

    selectedPlug() {
      return this.offering ? PLUGS_TYPES[this.offering.technologyType] : [];
    },

    showPlugDoesNotExistDisclaimer() {
      return this.form.hasPlug === false;
    }
  },
  watch: {
    orderData: {
      deep: true,
      handler(value) {
        this.askAboutPlug = value.askAboutPlug;
        this.form.hasPlug = value.askAboutPlug ? value.hasPlug : null;
        this.technicianRequiredDisclaimer = value.technicianRequiredDisclaimer;
      }
    }
  },

  methods: {
    updatePlugInfo() {
      this.$emit('update', this.form);
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

$plug-icon-width: 154px;

.plug-config {
  width: 100%;
  display: flex;
  flex-flow: column;

  &__skeleton-loader {
    &__title {
      height: 20px;
      margin-bottom: $u-300;
    }

    &__description {
      height: 40px;
      margin-bottom: $u-500;
    }

    &__plug-type {
      height: 150px;
      margin: 0 $u-500;
      width: $plug-icon-width;
    }

    &__radio {
      height: 58px;
      margin-bottom: $u-200;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__plug {
    margin-bottom: $u-800;

    &__icon {
      margin: 0 $u-500;
      width: $plug-icon-width;

      &:not(:first-child) {
        border-top: 1px dashed $c-nt-500;
        margin-top: $u-250;
        padding-top: $u-250;
      }
    }

    &__information {
      margin-bottom: $u-600;
    }
  }
}
</style>
