<script>
import {
  CCheckbox,
  CFormRadioGroup,
  CFormRadio,
  CCollapse,
  CInformationBox
} from 'olympus/components';

export default {
  name: 'checkout-customer-identification',

  components: {
    CCheckbox,
    CFormRadioGroup,
    CFormRadio,
    CCollapse,
    CInformationBox
  },

  props: {
    isDivaEnabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      divaSelection: this.isDivaEnabled
    };
  }
};
</script>

<style lang="scss" scoped>
.checkout-customer-identification {
  // .checkout-customer-identification--form
  &--form {
    padding: 0 0 15px 0;
  }
}
</style>
