<template>
  <div>
    <template v-if="hasConfigurationGroups">
      <div
        v-for="(group, idx) in configGroups"
        :key="idx"
        class="product-configuration"
      >
        <config-group
          class="product-configuration__group"
          v-bind="group"
          :content="content"
          :enable-submit-button="enableSubmitButton"
          :configuration-text="getConfigurationText(group.configurationType)"
          @click="handleConfigGroupClick(idx)"
          @submit="handleConfigGroupItemSubmission"
        >
          <component
            :is="type.component"
            v-for="(type, index) in availableConfigurationItemTypes"
            :id="type.config.id"
            :key="index"
            :ref="type.config.id"
            :title="type.content.text"
            :icon="type.content.icon"
            :config-data="type.configData"
            :checked-sim-radio="group.selectedSimOption"
            :group-id="group.groupId"
            :prevent-expand="!type.config.attributes.isAvailable"
            :content="content"
            :errors="errors"
            :expanded="type.expanded"
            :selected-number="group.number"
            @expanded="configGroupExpanded(type.config.id)"
            @fetchMoreNumbers="setNewNumberConfigData(true)"
            @fetchAndSearchNewNumbers="fetchAndSearchNewNumbers"
            @removeErrors="removeErrors"
            @ready="toggleSubmitButton"
          ></component>
        </config-group>
      </div>
    </template>
    <c-modal
      ref="prolongingUnavailableModal"
      :title="content.prolongingUnavailablePopUpHeading"
      centered
    >
      <p>
        {{ content.prolongingUnavailablePopUpText }}
      </p>
      <c-button
        slot="footer"
        :href="content.prolongingUnavailablePopUpButtonLink"
        variant="link"
        >{{ content.prolongingUnavailablePopUpButtonText }}</c-button
      >
    </c-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { get } from 'webshop/api/api.js';
import {
  ACTIONS as PRODUCT_CONFIG_ACTIONS,
  GETTERS as PRODUCT_CONFIG_GETTERS,
  MUTATIONS as PRODUCT_CONFIG_MUTATIONS
} from '../../../store/product-config/index.js';
import {
  GETTERS as NUMBER_CONFIG_GETTERS,
  MUTATIONS as NUMBER_CONFIG_MUTATIONS,
  ACTIONS as NUMBER_CONFIG_ACTIONS
} from '../../../store/product-config/new-number-config/index.js';
import {
  ACTIONS as CATALOG_ACTIONS,
  GETTERS as CATALOG_GETTERS
} from '../../../store/catalog/index.js';
import { ACTIONS as BASKET_ACTIONS } from '../../../store/basket/index.js';

import { PRODUCT_CONFIGURATION_TYPES } from '../../../enums/product-configuration-types';
import { CModal, CButton } from 'olympus/components/index.js';

import ConfigGroup from './product-config/private/config-group/config-group.vue';
import NewNumberConfig from './product-config/private/new-number-config/new-number-config.vue';
import ExistingNumberConfig from './product-config/private/existing-number-config/existing-number-config.vue';
import PortingConfig from './product-config/private/porting-config/porting-config.vue';
import InternetConfig from './product-config/private/internet-config/internet-config.vue';

import { providerKey as checkoutProvider } from '../../../providers/checkout-provider.js';

const COMPONENTS = {
  Prolonging: 'existing-number-config',
  Porting: 'porting-config',
  NewNumber: 'new-number-config',
  Broadband: 'internet-config'
};

export default {
  name: 'CheckoutProductConfiguration',

  components: {
    ConfigGroup,
    NewNumberConfig,
    ExistingNumberConfig,
    PortingConfig,
    InternetConfig,
    CModal,
    CButton
  },

  inject: [checkoutProvider],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      configGroups: [],
      expandedGroupItem: '',
      enableSubmitButton: false,
      availableConfigurationItemTypes: [],
      configs: [],
      currentData: {},
      isRemoveProductModalVisible: false,
      productToRemove: {}
    };
  },

  computed: {
    ...mapGetters({
      configurationGroups: PRODUCT_CONFIG_GETTERS.GET_PRODUCTS,
      errorMap: PRODUCT_CONFIG_GETTERS.GET_ERRORMAP,
      errors: PRODUCT_CONFIG_GETTERS.GET_ERRORS,
      getVariantsByCode: CATALOG_GETTERS.GET_VARIANTS_BY_CODE
    }),
    hasConfigurationGroups() {
      return this.configurationGroups && this.configurationGroups.length > 0;
    },
    // If one or more groups in the configGroup does not have a configurationType, then it still needs to be configured.
    configurationsComplete() {
      return !this.configGroups.find(group => group.configurationType === null);
    },
    groups() {
      var alreadyExpanded = false;
      var disable = false;
      return this.configurationGroups.map(x => {
        // When the first is expanded then all other should be disabled
        if (alreadyExpanded) {
          disable = true;
        }

        // Find first non configured
        const expandConfig =
          (!x.attributes.configurationType && !alreadyExpanded) ||
          (x.attributes.isDirty && x.attributes.number);
        if (expandConfig) {
          alreadyExpanded = true;
        }

        const variants = this.getVariantsByCode([
          x.attributes.productCode,
          x.attributes.handsetCode
        ]);

        // If any of the product-configuration objects don't have an 'attributes' property, we cannot use them
        if (
          !Object.entries(variants).find(([, value]) =>
            Object.prototype.hasOwnProperty.call(value, 'attributes')
          )
        ) {
          return [];
        }

        if (expandConfig) {
          // Fetch data for the product
          this.fetchConfiguration(x.links.configurations.href);
        }

        return {
          expanded: expandConfig,
          enabled: !!x.attributes.configurationType || !disable,
          configurationType: x.attributes.configurationType,
          number: x.attributes.number,
          href: x.links.configurations.href,
          groupId: x.attributes.groupId,
          productCode: x.attributes.productCode,
          installationAddressObj: x.attributes.installationAddress,
          variants: variants,
          isNumberHidden: x.attributes.isNumberHidden,
          isNumberUnlisted: x.attributes.isNumberUnlisted,
          configurationAttributes: x.attributes,
          selectedSimOption: x.attributes.simOption
        };
      });
    },

    configurationItemTypes() {
      return Object.values(PRODUCT_CONFIGURATION_TYPES).reduce(
        (configurationItemTypes, itemType) => {
          const config = this.configs.find(c => c.id === itemType);

          configurationItemTypes[itemType] = {
            content: this.content.configurationTypes.find(
              configType => configType.type === itemType
            ),
            config: config,
            configData:
              this.currentData.type === `${itemType}Options`
                ? this.currentData
                : {},
            configPost: config?.links?.configure,
            expanded: this.expandedGroupItem === itemType,
            component: COMPONENTS[itemType]
          };

          return configurationItemTypes;
        },
        {}
      );
    }
  },

  watch: {
    groups: {
      deep: true,
      handler() {
        this.configGroups = this.groups;
      }
    },

    configurationItemTypes: {
      deep: true,
      handler(value) {
        this.availableConfigurationItemTypes = Object.values(value).filter(
          c => {
            return c?.config?.id;
          }
        );
      }
    },

    errors(value) {
      // Show all errors as toast
      value.forEach(error => {
        let title = error.title;
        let paragraph = error.detail;
        const errorContent = this.errorMap[error.code];
        if (error.code && errorContent) {
          title = errorContent.title;
          paragraph = errorContent.description;
        }

        this.$root.$addToast({
          title,
          paragraph,
          state: 'error',
          duration: 5000
        });
      });
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapMutations(['HIDE_SPINNER', 'SHOW_SPINNER']),
    ...mapActions({
      fetchConfigurationGroups: PRODUCT_CONFIG_ACTIONS.FETCH_PRODUCTS,
      fetchVariants: CATALOG_ACTIONS.FETCH_VARIANTS,
      saveConfigurationGroup: PRODUCT_CONFIG_ACTIONS.SAVE_PRODUCT,
      removeErrors: PRODUCT_CONFIG_ACTIONS.REMOVE_ERRORS,
      setNumberpoolAndEditedTime:
        NUMBER_CONFIG_ACTIONS.SET_NUMBERPOOL_AND_EDITED_TIME,
      checkNumberpoolInTimespan:
        NUMBER_CONFIG_GETTERS.CHECK_NUMBERPOOL_IN_TIMESPAN,
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET
    }),
    ...mapMutations({
      clearNumberpool: NUMBER_CONFIG_MUTATIONS.CLEAR_NUMBERPOOL,
      removeProduct: PRODUCT_CONFIG_MUTATIONS.REMOVE_PRODUCT
    }),
    toggleSubmitButton(value) {
      this.enableSubmitButton = value;
    },
    async fetchData() {
      // Reset state
      this.expandedGroupItem = '';

      await this.fetchConfigurationGroups();

      // Produces a list with the product code and optionally the handsetCOde if there is such a value.
      const productCodes = this.configurationGroups.flatMap(x => [
        x.attributes.productCode,
        ...(x.attributes.handsetCode ? [x.attributes.handsetCode] : [])
      ]);

      await this.fetchVariants({
        codes: productCodes,
        req: {}
      });

      // Set the 'next' button disabled state based on whether we have completed the config for all products
      this[checkoutProvider].setDisableSubmitButton(
        !this.configurationsComplete
      );
    },
    async fetchConfiguration(url) {
      this.SHOW_SPINNER();

      const { data } = await get(url, {
        mvc: true,
        headers: {
          accept: 'application/vnd.api+json'
        }
      });

      this.configs = data.data;

      // If there's only one config type then it should be expanded
      if (data.data.length === 1) {
        // The expand needs to happen in the next tick
        this.$nextTick(() => {
          this.configGroupExpanded(data.data[0].id);
        });
      }

      this.HIDE_SPINNER();
    },
    handleConfigGroupClick(idx) {
      // Reset all to false, except the one that was clicked
      this.configGroups.forEach((x, i) => {
        if (i !== idx) {
          x.expanded = false;
        }
      });
      /*TODO DKT-11885: this needs rewriting, did a temporary fix to not block the release.
        This makes api call on toggle and didn't work before because it tried to expand component before data was recieved.
        Would be a good idea to review all of this flow.*/
      if (Object.keys(this.configGroups[idx]).length) {
        this.fetchConfiguration(this.configGroups[idx].href).then(() => {
          this.configGroups[idx].expanded = !this.configGroups[idx].expanded;
        });
      }
      // Collapse all config group items
      this.expandedGroupItem = '';
    },
    async fetchConfigData(configurationOptions, params = null) {
      const { data } = await get(configurationOptions.href, {
        mvc: true,
        ...(params && params),
        headers: {
          accept: 'application/vnd.api+json'
        }
      });
      return data.data;
    },
    async configGroupExpanded(configType) {
      if (this.expandedGroupItem !== configType) {
        this.SHOW_SPINNER();
      }
      if (this.expandedGroupItem !== configType) {
        if (configType === PRODUCT_CONFIGURATION_TYPES.NEW_NUMBER) {
          await this.setNewNumberConfigData(false);
        }

        if (
          configType === PRODUCT_CONFIGURATION_TYPES.PROLONGING &&
          !this.configurationItemTypes[configType].config.attributes.isAvailable
        ) {
          this.showDialog();
        } else if (
          configType === PRODUCT_CONFIGURATION_TYPES.PROLONGING ||
          configType === PRODUCT_CONFIGURATION_TYPES.BROADBAND ||
          configType === PRODUCT_CONFIGURATION_TYPES.PORTING
        ) {
          this.currentData = await this.fetchConfigData(
            this.configurationItemTypes[configType].config.links
              .configurationOptions
          );
        }
      }

      this.setExpandedConfigGroupItem(configType);

      this.HIDE_SPINNER();
    },
    setExpandedConfigGroupItem(configType) {
      this.expandedGroupItem =
        this.expandedGroupItem === configType ? '' : configType;
    },
    handleConfigGroupItemSubmission({ configType, data }) {
      const postUrl = this.configurationItemTypes[configType].configPost.href;

      this.SHOW_SPINNER();

      this.saveConfigurationGroup({
        url: postUrl,
        data: data
      })
        .then(response => {
          if (response?.data) {
            this.$addToast({
              title: this.content.configurationSavedText,
              duration: 5000
            });

            this.fetchData();
          }
        })
        .finally(() => {
          this.HIDE_SPINNER();
          window.location.reload();
        });
    },
    async fetchAndSearchNewNumbers(numberSearch) {
      let paramsObj = null;
      if (numberSearch !== '') {
        paramsObj = {
          params: {
            search: numberSearch
          }
        };
      }

      this.fetchNewNumbers(paramsObj);
    },
    async fetchNewNumbers(params) {
      const newNumberConfig = this.configs.find(
        c => c.id === PRODUCT_CONFIGURATION_TYPES.NEW_NUMBER
      );
      this.currentData = await this.fetchConfigData(
        newNumberConfig.links.configurationOptions,
        params
      );
      if (
        this.configurationItemTypes[PRODUCT_CONFIGURATION_TYPES.NEW_NUMBER]
          .configData?.attributes?.newNumbers?.length
      ) {
        this.setNumberpoolAndEditedTime(
          this.configurationItemTypes[PRODUCT_CONFIGURATION_TYPES.NEW_NUMBER]
            .configData.attributes.newNumbers
        );
      }
    },
    async setNewNumberConfigData(force = false) {
      // If we don't have numbers in localStorage, fetch new
      const noNumbers = !this.checkNumberpoolInTimespan.length;
      if (noNumbers || force) {
        await this.fetchNewNumbers(null);
      } else {
        // If we do have numbers stored, set its config data to empty object.
        // the new-number-config component will check if it's empty and use the numbers from localStorage.
        this.currentData = {};
      }
    },
    showDialog() {
      this.$refs.prolongingUnavailableModal.show();
    },
    getConfigurationText(configurationType) {
      return this.configurationItemTypes[configurationType]?.content
        ? this.configurationItemTypes[configurationType].content?.configuredText
        : '';
    },
    remove() {
      this.removeProduct(this.productToRemove.groupId);
      this.removeFromBasket({
        code: this.productToRemove.productCode,
        groupId: this.productToRemove.groupId
      });
      this.productToRemove = {};
      this.isRemoveProductModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors';

.product-configuration {
  position: relative;

  &:first-child {
    margin-top: $u-600;
  }

  &__group {
    margin-bottom: $u-200;
  }

  &__remove {
    display: flex;
    align-items: center;
    $btn-size: $u-500;
    background: none;
    padding: 0;
    height: $btn-size / 2;
    position: absolute;
    right: $u-300;
    top: $u-300;
    width: $btn-size / 2;
    z-index: 1;

    svg {
      height: 100%;
      width: 100%;
      fill: $c-pm-500;
    }
  }

  &__modal__message {
    text-align: center;
    margin: $u-250;
  }
}
</style>
