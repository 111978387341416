var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("checkout-confirm-details", {
        staticClass: "cm-c-form__row",
        attrs: { content: _vm.content, "customer-data": _vm.employeeData }
      }),
      _vm._v(" "),
      _c("checkout-summary-configuration", {
        staticClass: "cm-c-form__row",
        attrs: {
          content: _vm.configurationContent,
          data: _vm.configurationData,
          "home-office-flow": ""
        }
      }),
      _vm._v(" "),
      _c("checkout-delivery-address", {
        staticClass: "cm-c-form__row",
        attrs: {
          content: _vm.addressContent,
          data: _vm.addressData,
          "home-office-flow": ""
        }
      }),
      _vm._v(" "),
      _c("home-office-validation-modal", {
        attrs: {
          title: _vm.content.validationErrorModalText.heading,
          "error-message": _vm.content.validationErrorModalText.content,
          visible: _vm.showCheckoutError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }