<template>
  <img
    sizes="(max-width: 1600px) 100vw, 1600px"
    :srcset="
      `
    ${imageUrl}?w=320 320w,
    ${imageUrl}?w=480 480w,
    ${imageUrl}?w=768 768w,
    ${imageUrl}?w=1024 1024w,
    ${imageUrl}?w=1600 1600w`
    "
    :alt="altText"
  />
</template>

<script>
export default {
  name: 'srcset-image',
  props: {
    imageUrl: '',
    altText: ''
  }
};
</script>
