var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-checkout__module" },
    _vm._l(_vm.numberConfigVM.consents, function(consent, consentGroupIndex) {
      return _c("div", { key: consentGroupIndex }, [
        _c("div", { staticClass: "cm-c-checkout__form-group" }, [
          _c("div", { staticClass: "cm-c-checkout__subtitle" }, [
            _vm._v("\n        " + _vm._s(consent.titleText) + "\n      ")
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "cm-c-checkout__consenttext",
            domProps: { innerHTML: _vm._s(consent.description) }
          })
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "cm-c-checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.product.acceptedConsents,
                expression: "product.acceptedConsents"
              }
            ],
            staticClass: "cm-c-checkbox__o-input",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.product.acceptedConsents)
                ? _vm._i(_vm.product.acceptedConsents, null) > -1
                : _vm.product.acceptedConsents
            },
            on: {
              change: function($event) {
                var $$a = _vm.product.acceptedConsents,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.product,
                        "acceptedConsents",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.product,
                        "acceptedConsents",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.product, "acceptedConsents", $$c)
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "cm-c-checkbox__input" }),
          _vm._v(" "),
          _c("span", { staticClass: "cm-c-checkbox__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.numberConfigVM.consentAcceptText) +
                "\n      "
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }