<template>
  <c-modal
    :visible="visible"
    no-content-padding
    :show-ok-button="false"
    @close="HIDE_DIALOG"
  >
    <keep-alive>
      <component :is="component" v-bind="props"></component>
    </keep-alive>
  </c-modal>
</template>

<script>
// TODO DKT-1445: cm-c-overlay & cm-c-modal should be deprecated in favor of c-modal.
import { mapState, mapMutations } from 'vuex';

import CallBackDialog from './dialogs/call-back-dialog.vue';
import MembershipDialog from './dialogs/membership-dialog.vue';
import ErrorDialog from './dialogs/error-dialog.vue';
import ContentDialog from './dialogs/content-dialog.vue';
import FormDialog from './dialogs/form-dialog.vue';
import PreventAccessoryFinancingDialog from './dialogs/prevent-accessory-financing-dialog.vue';

const { genericErrorHeader, genericErrorText } = window.__APP__;
const GENERIC_ERROR = {
  errorHeader: genericErrorHeader,
  errorText: genericErrorText
};

export default {
  name: 'ModalDialog',
  components: {
    'call-back-dialog': CallBackDialog,
    'membership-dialog': MembershipDialog,
    'error-dialog': ErrorDialog,
    'content-dialog': ContentDialog,
    'form-dialog': FormDialog,
    'prevent-accessory-financing-dialog': PreventAccessoryFinancingDialog
  },

  data() {
    return {
      component: null,
      props: {
        errorObject: {
          ...GENERIC_ERROR
        },
        hideCloseButton: false
      }
    };
  },

  computed: {
    ...mapState({
      visible: state => state.shop.dialog.visible,
      currentDialog: state => state.shop.dialog.currentDialog,
      currentProps: state => state.shop.dialog.currentProps
    }),
    noDialogSupport() {
      return typeof HTMLDialogElement !== 'function';
    }
  },

  watch: {
    currentDialog(componentName) {
      if (!componentName) {
        return;
      }

      this.component = componentName;
    },
    currentProps(propsObj) {
      if (!propsObj || Object.keys(propsObj).length === 0) {
        /*
         * Empty props obj is sent with default error dialog
         * so we need to reset default error object
         */

        this.props.errorObject = {
          ...GENERIC_ERROR
        };

        return;
      }

      this.props = { ...propsObj };
    }
  },

  mounted() {
    /*
     * Map initial vuex bindings
     * for dialogs that are called on load
     */
    this.component = this.currentDialog;
    this.props = { ...this.props, ...this.currentProps };
  },
  methods: {
    ...mapMutations(['HIDE_DIALOG'])
  }
};
</script>
