<script>
import VariantMixin from '../../mixins/variant-mixin.js';

export default {
  name: 'c-text',

  mixins: [VariantMixin],

  props: {
    // TODO TC-4919: create tag template with validator
    tag: {
      type: String,
      default: undefined
    },
    right: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    striked: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: undefined
    }
  },

  computed: {
    modifiers() {
      const mods = {
        [this.variantModifier]: !!this.variant,
        'c-text--striked': this.striked,
        'c-text--block': this.block
      };

      if (this.right) {
        mods['c-text--right'] = true;
      } else if (this.center) {
        mods['c-text--center'] = true;
      }

      return mods;
    },
    autotag() {
      return this.tag || (this.content ? 'p' : 'span');
    }
  },

  render(h) {
    const common = {
      class: this.modifiers
    };

    if (this.content) {
      return h(this.autotag, {
        ...common,
        domProps: {
          innerHTML: this.content
        }
      });
    }

    return h(this.autotag, common, this.$slots.default);
  }
};
</script>

<style lang="scss">
// TODO TC-4919: maybe use global styles (ex. text-right), so we
// ensure reusability around?
.c-text {
  // .c-text--right
  &--right {
    text-align: right;
  }

  // .c-text--center
  &--center {
    text-align: center;
  }

  // .c-text--striked
  &--striked {
    text-decoration: line-through;
  }

  // .c-text--block
  &--block {
    display: block;
  }
}
</style>
