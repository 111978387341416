var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CVASCard", {
    attrs: {
      vas: _vm.displayedVasVariant,
      promoted: _vm.promoted,
      "promoted-image-url": _vm.promotedImageUrl,
      "read-more": _vm.readMore,
      "old-vas-price-description": _vm.oldVasPriceDescription,
      close: _vm.close,
      "local-loading": _vm.localLoading,
      networking: _vm.networking,
      "handle-add-click-event": _vm.handleAddClickEvent,
      "class-object": _vm.classObject,
      added: _vm.added,
      "price-disclaimer-text": _vm.priceDisclaimerText
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }