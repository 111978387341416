<template>
  <div class="cm-c-cart-mini">
    <div v-if="isNetworking" class="cm-c-spinner cm-c-spinner--local"></div>
    <div class="cm-c-cart-mini__header">
      <p class="cm-c-cart-mini__title">
        {{ basketContent.basketTitle }}
      </p>
      <hr />
    </div>

    <div class="cm-c-cart-mini__body">
      <div v-if="isEmpty" class="mini-cart__empty">
        {{ basketContent.basketIsEmptyLabel }}
      </div>
      <div
        v-for="(relatedGroup, relatedGroupsIndex) in relatedGroups"
        v-else
        :key="relatedGroupsIndex"
        class="mini-cart-related-group"
      >
        <div
          v-for="(group, groupIndex) in relatedGroup.groups"
          :key="groupIndex"
          :data-groupid="group.id"
          class="mini-cart-related-group__child"
        >
          <div class="mini-cart-group">
            <mini-shopping-cart-item
              v-for="(item, itemIndex) in group.items"
              :key="itemIndex"
              :content="basketContent"
              :item="item"
              :index="itemIndex"
              :group-id="group.id"
            >
            </mini-shopping-cart-item>
          </div>
          <hr class="mini-cart-group__devider" />
        </div>
      </div>
    </div>
    <div class="mini-cart__footer">
      <p
        v-if="!isEmpty && basketUrl"
        class="mini-cart__footer__button-wrapper"
        :class="{
          'mini-cart__footer__button-wrapper__with-disclaimer':
            priceDisclaimerText
        }"
      >
        <empty-basket
          v-if="showDeleteIcon || isSalesAgent"
          icon-position="left"
          :content="emptyBasketContent"
        />

        <a :href="basketUrl" class="c-btn">{{ basketUrlTitle }}</a>
      </p>
      <p
        v-if="!isEmpty && priceDisclaimerText"
        :class="{ 'mini-cart__footer__disclaimer-text': priceDisclaimerText }"
      >
        {{ priceDisclaimerText }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MiniShoppingCartItem from './mini-shopping-cart-item.vue';

import {
  ACTIONS as BASKET_ACTIONS,
  GETTERS as BASKET_GETTERS
} from '../../../store/basket';
import EmptyBasket from '../empty-basket.vue';

export default {
  name: 'MiniShoppingCart',

  components: {
    MiniShoppingCartItem,
    EmptyBasket
  },

  props: {
    isSalesAgent: {
      type: Boolean,
      default: false
    },
    showDeleteIcon: {
      type: Boolean,
      default: true
    },
    basketUrl: {
      type: String,
      default: ''
    },
    basketUrlTitle: {
      type: String,
      default: ''
    },
    basketContent: {
      type: Object,
      default() {
        return {};
      }
    },
    priceDisclaimerText: {
      type: String,
      default: ''
    },
    clearBasketLabel: {
      type: String,
      default: ''
    },
    emptyBasketModal: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      emptyBasketContent: {
        clearBasketLabel: this.clearBasketLabel,
        ...this.emptyBasketModal
      }
    };
  },

  computed: {
    ...mapGetters({
      isEmpty: BASKET_GETTERS.IS_EMPTY,
      isInit: BASKET_GETTERS.IS_INIT,
      isNetworking: BASKET_GETTERS.IS_NETWORKING,
      relatedGroups: BASKET_GETTERS.GROUPS
    }),
    basketItemsCount() {
      return this.relatedGroups.length;
    }
  },

  mounted() {
    this.initBasket({ excludeOffering: true });
  },

  methods: {
    ...mapActions({
      // TODO DKT-1108: add new action
      initBasket: BASKET_ACTIONS.INIT_BASKET
    })
  }
};
</script>
