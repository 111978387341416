var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-accordion-item" },
    [
      _c(
        "div",
        {
          staticClass: "c-accordion-item__trigger",
          attrs: {
            role: "button",
            "aria-expanded": _vm.isSelected ? "true" : "false",
            "aria-controls": "accordion-content-" + _vm.itemId
          },
          on: {
            click: function($event) {
              return _vm.toggleCollapse()
            }
          }
        },
        [_vm._t("title", null, { expanded: _vm.isSelected })],
        2
      ),
      _vm._v(" "),
      _c(
        "c-collapse",
        {
          attrs: {
            id: "accordion-content-" + _vm.itemId,
            visible: _vm.isSelected,
            role: "region",
            "transition-duration": _vm.transitionDuration,
            "transation-easing": _vm.transitionEasing,
            "aria-labelled-by": "accordion-button-" + _vm.itemId,
            "aria-hidden": !_vm.isSelected ? "true" : "false"
          }
        },
        [_vm._t("content", null, { expanded: _vm.isSelected })],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "aria-controls": "accordion-content-" + _vm.itemId } },
        [_vm._t("info-box")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }