export function protoType() {
  /* IIFE */
  (function() {
    // Mini cart height
    function MiniCartHeight() {
      if (document.querySelector('.cm-c-cart-mini') != null) {
        var winHeight = window.innerHeight,
          winWidth = window.innerWidth,
          minicartHeight = document.getElementsByClassName('cm-c-cart-mini')[0]
            .offsetHeight;
        if (minicartHeight + 50 >= winHeight && winWidth > 480) {
          document.getElementsByClassName('cm-c-cart-mini')[0].style.height =
            winHeight - 80 + 'px';
          document
            .getElementsByClassName('cm-c-cart-mini')[0]
            .classList.add('cm-c-cart-mini--scroll');
        } else {
          document.getElementsByClassName('cm-c-cart-mini')[0].style.height =
            'auto';
          document
            .getElementsByClassName('cm-c-cart-mini')[0]
            .classList.remove('cm-c-cart-mini--scroll');
        }
      }
    }

    window.adjustMiniCartHeight = MiniCartHeight;

    // Open filter-nav
    if (document.querySelector('.cm-js-open-filter-nav') != null) {
      document.getElementsByClassName(
        'cm-js-open-filter-nav'
      )[0].onclick = function() {
        document
          .getElementsByClassName('cm-c-filter-nav')[0]
          .classList.toggle('is-open');
        document
          .getElementsByClassName('cm-js-open-filter-nav')[0]
          .classList.toggle('is-active');
      };
    }

    /* Quick fix for Enterprise sticky line*/
    function channelHeaderHeight() {
      const channelHeader = document.getElementById('channel-header');
      const compareBox = document.getElementById('cm-c-compare-layer');
      const stickyFooter = document.getElementById('sticky-footer');
      
      if (channelHeader) {
        document.body.style.marginBottom = channelHeader.offsetHeight + 'px';
        if (compareBox)
          compareBox.style.bottom = channelHeader.offsetHeight + 'px';
        if (stickyFooter){
          stickyFooter.style.bottom = channelHeader.offsetHeight + 'px';
        }
      }
    }

    document.addEventListener('DOMContentLoaded', function() {
      MiniCartHeight();
      channelHeaderHeight();
    });

    // Window resize
    window.addEventListener('resize', function() {
      MiniCartHeight();
      channelHeaderHeight();
    });

    // Open modal
    var moooodals = document.querySelectorAll('.js-open-modal');
    for (var i = 0; i < moooodals.length; i++)
      (function(i) {
        moooodals[i].onclick = function() {
          var modal = this.getAttribute('data-modal');
          var staticModal = this.hasAttribute('data-modal-static')
            ? this.getAttribute('data-modal-static') !== 'false'
            : false;
          document.getElementById(modal).style.display = 'block';
          document.body.classList.add('cm-c-overlay--is-visible');

          // Close modal on ESC key
          !staticModal && document.addEventListener('keyup', closeOnEsc);

          // Close modal on click on the background
          document.querySelector('.cm-c-modal').onclick = function(e) {
            if (!staticModal && e.target === e.currentTarget) {
              closeModal('cm-c-modal');
            }
          };
        };
      })(i);

    // Close modal
    if (document.querySelector('.js-open-modal') != null) {
      document.getElementsByClassName(
        'cm-c-modal__close'
      )[0].onclick = function() {
        closeModal('cm-c-modal');
      };
    }

    // Init Material Design Inputs
    initMaterialInputs();
    initInViewPortAnimations();
  })();

  // Toggle function
  function toggle(className, displayState) {
    var elements = document.getElementsByClassName(className);
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = displayState;
    }
  }
  // Close modal on ESC key
  function closeOnEsc(e) {
    if (e.keyCode === 27) {
      closeModal('cm-c-modal');
    }
  }

  // Modal close
  function closeModal(modalClass) {
    toggle(modalClass, 'none');
    document.body.classList.remove('cm-c-overlay--is-visible');
    document.removeEventListener('keyup', closeOnEsc, false);
  }

  function initInViewPortAnimations() {
    if (!window.hasOwnProperty('IntersectionObserver')) {
      return;
    }
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.remove('js-rm-in-viewport');
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    );
    const elms = document.querySelectorAll('.js-rm-in-viewport');
    elms.forEach(elm => {
      observer.observe(elm);
    });
  }

  function initMaterialInputs() {
    const cssTgl = 'cm-c-form-control--filled';
    document.body.addEventListener(
      'input',
      function(evt) {
        if (evt.target.classList.contains('cm-js-material-input')) {
          /* Check if element is using vuejs-datepicker */
          if (evt.target.classList.contains('datepicker__input')) {
            const parent = evt.target.closest('.datepicker');
            parent.classList.toggle(cssTgl, true);
          } else {
            evt.target.classList.toggle(cssTgl, evt.target.value.length > 0);
            nameAndEmailInputHandler();
          }
        }
      },
      false
    );
    document.body.addEventListener(
      'change',
      function(evt) {
        if (evt.target.classList.contains('cm-js-material-input')) {
          if (evt.target.tagName.toUpperCase() == 'SELECT') {
            evt.target.classList.toggle(cssTgl, evt.target.value.length > 0);
            nameAndEmailInputHandler();
          }
        }
      },
      false
    );

    const materialInputs = Array.prototype.slice.call(
      document.querySelectorAll('.cm-js-material-input')
    );
    materialInputs.forEach(input => {
      /* Check if element is using vuejs-datepicker */
      if (input.classList.contains('datepicker__input')) {
        const parent = input.closest('.datepicker');
        parent.classList.toggle(cssTgl, true);
      } else {
        input.classList.toggle(cssTgl, input.value.length > 0);
      }
    });
  }

  function nameAndEmailInputHandler() {
    var isIE =
      !!navigator.userAgent.match(/Trident/g) ||
      !!navigator.userAgent.match(/MSIE/g);
    if (isIE) {
      const materialInputs = Array.prototype.slice.call(
        document.querySelectorAll('.js-material-input')
      );
      const cssTgl = 'cm-c-form-control--filled';
      for (var i = 0; i < materialInputs.length; i++) {
        var input = materialInputs[i];
        if (input.value.length > 0 && !input.classList.contains(cssTgl))
          input.classList.toggle(cssTgl, false);
      }
    }
  }
}

// Polyfill for implementing element.closest.
if (!Element.prototype.matches)
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

/* Quick fix for Menu on Mobile */
document.addEventListener('DOMContentLoaded', function() {
  const menuLinks = Array.from(
    document.querySelectorAll('.cm-c-main-nav__link-withnav')
  );
  menuLinks.forEach(item => {
    item.addEventListener('click', function(event) {
      const width = window.innerWidth;
      const foldOut = this.nextElementSibling;
      if (width <= 800) {
        event.preventDefault();
        this.classList.toggle('clicked');
        foldOut.classList.toggle('hidden');
      }
    });
  });
});

/* Show/Hide Sections from checkboxes with data-js-toggle="QUERYSELECTOR" */
(() => {
  document.addEventListener('DOMContentLoaded', function() {
    const togglers = document.querySelectorAll('[data-js-toggle]');
    togglers.forEach(toggle => {
      toggle.addEventListener('click', toggleSection);
      toggleSection.call(toggle);
    });
    function toggleSection() {
      const section = document.querySelector(this.dataset.jsToggle);
      if (section) section.hidden = !this.checked;
    }
  });
})();

/* Expand Component: Move to own module, when we agree on structure */
(() => {
  document.addEventListener('DOMContentLoaded', function() {
    function toggleOverflow(element) {
      const content = element.previousElementSibling;
      const isToggled = element.getAttribute('aria-expanded') === 'true';
      element.innerText = isToggled
        ? element.dataset.toggleMore
        : element.dataset.toggleLess;
      content.classList.toggle('c-exp__content--toggle', !isToggled);
      element.setAttribute('aria-expanded', !isToggled);
      if (!isToggled) {
        const scrollY =
          document.documentElement.scrollTop +
          content.parentNode.getBoundingClientRect().top;
        document.documentElement.scrollTo(0, scrollY);
      }
    }
    const togglers = document.querySelectorAll('.c-exp__toggle');
    togglers.forEach(toggle => {
      toggle.addEventListener('click', () => {
        toggleOverflow(toggle);
      });
    });
  });
})();

/* TODO: Move to own module when we agree on a structure */
/* Add wrapper to tables defined in tinyMCE, remove `border` and `style`-attributes */
(() => {
  const tables = document.querySelectorAll('[class*="c-table"]');
  tables.forEach(table => {
    const wrapper = document.createElement('div');
    wrapper.className = 'c-table__wrapper';
    table.removeAttribute('border');
    table.removeAttribute('style');
    table.parentNode.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });
})();

/* Add click event for toggle on Enterprise Theme Elements */
(() => {
  document.addEventListener('DOMContentLoaded', function() {
    const blocks = document.querySelectorAll('.c-enterprise-block');
    const blockContainer = document.querySelectorAll('.c-enterprise-blocks');

    blocks.forEach(block => {
      const trigger = block.querySelector('.c-enterprise-block__links-toggle');

      trigger.addEventListener('click', () => {
        block.classList.toggle('enterprise-block-is-active');
        document
          .querySelectorAll('.c-enterprise-blocks')[0]
          .classList.toggle('enterprise-blocks-is-active');
      });
    });

    blockContainer.forEach(container => {
      const closeOverlay = container.querySelector(
        '.c-enterprise-blocks__overlay'
      );

      closeOverlay.addEventListener('click', () => {
        const openOvaerlays = document.querySelectorAll(
          '.c-enterprise-blocks.enterprise-blocks-is-active'
        );

        openOvaerlays.forEach(overlay => {
          overlay.classList.remove('enterprise-blocks-is-active');
          overlay
            .querySelector('.enterprise-block-is-active')
            .classList.remove('enterprise-block-is-active');
        });
      });
    });
  });
})();
