/**
 * @description Reference types used for mapping out api response types.
 */
export default {
  UNKNOWN: 'Unknown',
  PRODUCT_BASE: 'ProductBaseResponse',
  VARIANT_BASE: 'VariantBaseResponse',
  VARIANT_BROADBAND: 'VariantBroadbandResponse',
  VARIANT_FEE: 'VariantFeeResponse',
  VARIANT_MOBILE_SUBSCRIPTION: 'VariantMobileSubscriptionResponse',
  VARIANT_HANDSET: 'VariantHandsetResponse'
};
