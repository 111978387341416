var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "input",
    staticClass: "epi-form-text__input c-input__input",
    attrs: {
      id: "c-input",
      type: _vm.type,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      placeholder: " ",
      name: _vm.name,
      minlength: _vm.minlength,
      maxlength: _vm.maxlength,
      autocomplete: _vm.disableAutocomplete ? "off" : "on"
    },
    domProps: { value: _vm.value },
    on: {
      change: _vm.onChange,
      input: _vm.onInput,
      keyup: [
        function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.performSubmit($event)
        },
        _vm.onKeyup
      ],
      keypress: _vm.keypress
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }