var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "carousel",
      staticClass: "c-carousel",
      class: {
        "c-carousel--draggable": _vm.draggable,
        "c-carousel--multislider": !_vm.singleSlide,
        "c-carousel--dots": _vm.pageDots
      }
    },
    [
      _c(
        "ul",
        {
          ref: "list",
          staticClass: "c-carousel__list",
          class: {
            "c-carousel__list--multilist": !_vm.singleSlide,
            "c-carousel__list--swipe": _vm.smoothSwiping
          },
          on: { scroll: _vm.setLeftScroll }
        },
        _vm._l(_vm.list, function(currentSlide, idx) {
          return _c(
            "li",
            {
              key: idx,
              ref: "slide" + idx,
              refInFor: true,
              class: {
                "c-carousel__item": true,
                "c-carousel__item--to-right": currentSlide.toRight,
                "c-carousel__item--to-left": currentSlide.toLeft,
                "c-carousel__item--right": currentSlide.right,
                "c-carousel__item--left": currentSlide.left,
                "c-carousel__item--active": currentSlide.active,
                "c-carousel__item--center": _vm.centerSlides,
                "c-carousel__item--flex": !_vm.singleSlide || _vm.smoothSwiping,
                "c-carousel__item--swipe": _vm.smoothSwiping
              },
              style: _vm.getSlideStyles,
              attrs: { "data-index": idx }
            },
            [
              _vm._t(
                "slide",
                [_vm._v("\n        " + _vm._s(currentSlide) + "\n      ")],
                { data: currentSlide.content }
              )
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.navigation
        ? _c(
            "div",
            {
              staticClass: "c-carousel__navigation",
              class: {
                "c-carousel__navigation--rounded": _vm.roundedNavigationButtons,
                "c-carousel__navigation--position-top":
                  _vm.navigationPositionFixed
              }
            },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasPrev,
                      expression: "hasPrev"
                    }
                  ],
                  style: _vm.getNavigationTopPosition,
                  attrs: {
                    type: "button",
                    itemscope: "",
                    itemtype: "http://www.schema.org/SiteNavigationElement",
                    "data-slide": "prev"
                  },
                  on: {
                    click: function($event) {
                      return _vm.prev()
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "\n          c-carousel__navigation-arrow c-carousel__navigation-arrow--prev\n        "
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "c-carousel__navigation-text" }, [
                    _vm._v("Previous Slide")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasNext,
                      expression: "hasNext"
                    }
                  ],
                  style: _vm.getNavigationTopPosition,
                  attrs: {
                    type: "button",
                    itemscope: "",
                    itemtype: "http://www.schema.org/SiteNavigationElement",
                    "data-slide": "next"
                  },
                  on: {
                    click: function($event) {
                      return _vm.next()
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass:
                      "\n          c-carousel__navigation-arrow c-carousel__navigation-arrow--next\n        "
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "c-carousel__navigation-text" }, [
                    _vm._v("Next Slide")
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pageDots && _vm.slides > 1
        ? _c(
            "ol",
            { staticClass: "c-carousel__page-dots" },
            _vm._l(_vm.slides, function(idx) {
              return _c("li", {
                key: idx,
                staticClass: "c-carousel__page-dots__dot",
                class: {
                  "c-carousel__page-dots__dot--active":
                    idx - 1 === _vm.indexLocal
                },
                attrs: { "aria-label": "Carousel page dot " + idx },
                on: {
                  click: function($event) {
                    return _vm.dotClickHandler(idx - 1)
                  }
                }
              })
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }