<template>
  <c-modal
    :visible="visible"
    :non-closable-modal="networking"
    gray
    wide
    @close="close"
  >
    <div class="c-vas-multiple-overlay">
      <div slot="header">
        <h2 class="cm-c-checkout__title cm-c-checkout__title--center">
          {{ getContentPropertyValue(content.multipleVasModalHeadline) }}
        </h2>
      </div>

      <div class="c-vas-multiple-overlay__content">
        <c-product-card-vas
          v-for="(vasProduct, index) in services"
          :key="index"
          transition="staggered"
          stagger="150"
          :vas-product="vasProduct"
          :brand="brand"
          :group-id="groupId"
          :local-loading="false"
          :read-more="getContentPropertyValue(content.expandLabel)"
          :price-disclaimer-text="
            getContentPropertyValue(content.priceDisclaimerText)
          "
          :close="getContentPropertyValue(content.collapseLabel)"
          @update="handleVasUpdate"
        >
        </c-product-card-vas>
      </div>
    </div>

    <div
      v-if="networking"
      slot="inner"
      class="cm-c-spinner cm-c-spinner--local"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </c-modal>
</template>

<script>
import contentProviderMixin from 'olympus/mixins/content-property-mixin.js';
import CProductCardVas from 'webshop/components/c-product-card/c-product-card-vas.vue';
import { CModal } from 'olympus/components';

/**
 * Overlay that allows picking between vas variants.
 */
export default {
  name: 'CVasMultipleOverlay',

  components: {
    CProductCardVas,
    CModal
  },

  mixins: [contentProviderMixin],

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    services: {
      type: Array,
      default: () => []
    },

    brand: {
      type: [String, null],
      default: null
    },

    visible: {
      type: Boolean,
      default: false
    },

    groupId: {
      type: String,
      default: ''
    },

    handleServiceStateUpdate: {
      type: Function,
      default: () => null
    },

    networking: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleVasUpdate(payload) {
      this.handleServiceStateUpdate(payload);
    },

    close() {
      this.handleServiceStateUpdate({});
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';

$contentMaxWidth: 384px; // TODO DKT-1415: this should be aligned with the design team and ev. made a global design token instead of a magic number.

.c-vas-multiple-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;

  // .c-vas-multiple-overlay__content
  &__content {
    margin-top: $u-500;
    max-width: $contentMaxWidth;
  }
}
</style>
