<template>
  <svg v-if="symbolId">
    <use :xlink:href="icons(symbolId)" />
  </svg>
  <i v-else class="c-icon" :class="modifiers" />
</template>

<script>
import VariantMixin from 'olympus/mixins/variant-mixin.js';

export const icons = [
  'calendar',
  'angle-right',
  'octagon',
  'gallery',
  'lyd',
  'update',
  'adgangskode',
  'arrow-left',
  'arrow-right',
  'bluetooth2',
  'cart2',
  'computer4',
  'dokument',
  'drift2',
  'facebook2',
  'favorit2',
  'forbrug',
  'forsikring',
  'forsikring_2',
  'gps',
  'help2',
  'hjerte',
  'indstilliner_2',
  'indstillinger',
  'info',
  'kamera',
  'kontakt',
  'network2',
  'network_2',
  'profil',
  'sim-kort',
  'smartphone',
  'smartphone_2',
  'sms2',
  'søg',
  'udland2',
  'wi-fi'
];

export default {
  name: 'CIcon',

  mixins: [VariantMixin],

  props: {
    type: {
      type: String,
      required: false,
      default: ''
    },

    rounded: {
      type: Boolean,
      default: false
    },

    symbolId: {
      type: String,
      default: ''
    }
  },

  computed: {
    modifiers() {
      const mods = [
        this.textColorClass,
        this.borderColorClass,
        `c-icon--${this.type}`
      ];

      if (this.rounded) {
        mods.push('c-icon--rounded');
      }

      return mods;
    }
  },

  methods: {
    icons(iconName) {
      // TODO DKT-39277 replace with norlys when it's time to do cleanup this is just a fallback to telia-icons.
      const iconPath = window.__APP__?.iconsPath || '/images/telia-icons.svg';

      return `${iconPath}#${iconName}`;
    }
  }
};
</script>

<style lang="scss">
// TODO DKT-1457: remove relative path and use alias instead
$icomoon-font-path: '../../styles/fonts/icons' !default;

// TODO DKT-1457: theme this (and dont use callme explicitly for namings)
@font-face {
  font-family: 'callme';
  src: url('#{$icomoon-font-path}/callme.ttf?g8olqu') format('truetype'),
    url('#{$icomoon-font-path}/callme.woff?g8olqu') format('woff'),
    url('#{$icomoon-font-path}/callme.svg?g8olqu#callme') format('svg');
  font-weight: normal;
  font-style: normal;
}

$icons: (
  calendar: '\e903',
  angle-right: '\e902',
  octagon: '\e901',
  gallery: '\e900',
  lyd: '\e93d',
  update: '\e91d',
  adgangskode: '\e91e',
  arrow-left: '\e91f',
  arrow-right: '\e920',
  bluetooth2: '\e921',
  cart2: '\e922',
  computer2: '\e923',
  dokument: '\e924',
  drift2: '\e925',
  facebook2: '\e926',
  favorit2: '\e927',
  forbrug: '\e928',
  forsikring: '\e929',
  forsikring_2: '\e92a',
  gps: '\e92b',
  help2: '\e92c',
  hjerte: '\e92d',
  indstilliner_2: '\e92e',
  indstillinger: '\e92f',
  info: 'i',
  kamera: '\e930',
  kontakt: '\e931',
  network2: '\e933',
  network_2: '\e934',
  profil: '\e935',
  sim-kort: '\e936',
  smartphone: '\e937',
  smartphone_2: '\e938',
  sms2: '\e939',
  søg: '\e93a',
  udland2: '\e93b',
  wi-fi: '\e93c'
);

.c-icon {
  font-family: 'callme' !important;
  speak: none;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // .c-icon--rounded
  &--rounded {
    border: 1px solid;
    border-radius: 50%;
  }

  // .c-icon--{name}
  @each $name, $value in $icons {
    &--#{$name}:before {
      content: $value;
    }
  }
}
</style>
