var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        class: {
          "c-existing-subscription-selector": true,
          "c-existing-subscription-selector--inactive": !_vm.allowActions
        }
      },
      _vm.allowActions ? { click: _vm.showNumberSelectModal } : {}
    ),
    [
      _c("div", { staticClass: "c-existing-subscription-selector__inner" }, [
        _c("div", { staticClass: "c-existing-subscription-selector__text" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.subscriptionToNumberLabel) + "\n      "
          ),
          !_vm.selectedPhoneNumber && !_vm.preselectedPhoneNumber
            ? _c("b", [_vm._v(_vm._s(_vm.newNumberLabel))])
            : _c("span", [_vm._v(_vm._s(_vm.existingNumberLabel))])
        ]),
        _vm._v(" "),
        _vm.allowActions || _vm.preselectedPhoneNumber
          ? _c(
              "div",
              { staticClass: "c-existing-subscription-selector__actions" },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.phoneNumberFormatted) + "\n      "
                ),
                !_vm.preselectedPhoneNumber
                  ? _c("c-icon", { attrs: { "symbol-id": "arrow-chevron" } })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: {
            centered: "",
            visible:
              _vm.showNumberSelectionModal &&
              _vm.content.showNumberSelectorPopUp
          },
          on: { close: _vm.hideNumberSelectModal }
        },
        [
          _vm.showNumberSelectionModal
            ? _c("product-number-selector", {
                attrs: {
                  "product-code": _vm.productCode,
                  content: _vm.content,
                  "selected-number": _vm.selectedPhoneNumber
                },
                on: { "number-selected": _vm.numberSelected }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }