<script>
import TopupLanguageSwitch from './topup-language-switch.vue';
import TopupNua from './topup-nua.vue';
import TopupRcc from './topup-rcc.vue';
import TopupTsrr from './topup-tsrr.vue';

export default {
  name: 'TopupLandingPage',
  components: {
    TopupLanguageSwitch,
    TopupNua,
    TopupRcc,
    TopupTsrr
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';

.topup {
  &__wrapper {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__headline {
    & > h1 {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
    }
    & > h2 {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }
    & > h3 {
      font-size: 14px;
      text-align: center;
      font-weight: bold;
    }
  }
  &__section {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    & > p {
      padding-bottom: 5px;
      padding-left: 5px;
    }
  }
  &__products {
    background-color: #ffffff;
    margin-left: 18px;
    margin-right: 18px;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    box-shadow: $box-shadow-default;
    border-radius: 3px;
  }
  &__product {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &__product-description {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
  }
  &__push-right {
    margin-left: auto;
  }
  &__center {
    text-align: center;
  }
  &__orange-text {
    color: #ff5800;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
  }
  &__orange-button {
    background-color: #ff5800 !important;
    color: #ffffff !important;
    border: none !important;
  }
  &__green-button {
    background-color: #00d066 !important;
    color: #000000 !important;
    border: none !important;
  }
  &__white-button {
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 2px solid !important;
  }
  &__buy-button {
    height: 2.3rem !important;
    width: 5rem;
  }
}
</style>
