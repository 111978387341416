var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.languages, function(language) {
      return _c("span", { key: language }, [
        _vm._v("\n    | " + _vm._s(language) + " |\n  ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }