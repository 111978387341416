<template>
  <div class="cm-grid">
    <div class="cm-grid__col--xs-2">
      <p class="cm-text-center">
        <img v-if="image" :src="imageSrc" :alt="name" />
      </p>
    </div>
    <div class="cm-grid__col--xs-10 cm-c-cart-mini_basket-grid">
      <h3
        class="cm-c-cart-list__product-title cm-c-cart-list__product-title--sm"
      >
        {{ name }}
      </h3>
      <div class="cm-grid">
        <div class="cm-grid__col--xs-8">
          <p>{{ payment }}</p>
        </div>
        <div class="cm-grid__col--xs-4">
          <p class="cm-text-right">{{ price }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SProductItem',

  props: {
    image: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    payment: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    }
  },

  computed: {
    imageSrc() {
      return `${this.image}?w=45`;
    }
  }
};
</script>
