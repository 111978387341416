<script>
// TODO DKT-2351 - remove this component when its no longer in use
// TODO DKT-2351 - make sure to tidy away 'isExternalReseller' getter/mutation. IS_THIRD_PARTY_RESELLER should be used instead
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ChannelHeader',

  props: {
    balance: {
      type: Number,
      default: 0,
      required: true
    },
    enterprise: {
      type: Boolean,
      default: false,
      required: true
    },
    externalReseller: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isEnterprise', 'currentBalance'])
  },
  mounted() {
    this.SET_ENTERPRISE(this.enterprise);
    this.SET_CURRENTBALANCE(this.balance);
    this.SET_EXTERNAL_RESELLER(this.externalReseller);
  },
  methods: {
    ...mapMutations([
      'SET_ENTERPRISE',
      'SET_CURRENTBALANCE',
      'SET_EXTERNAL_RESELLER'
    ])
  }
};
</script>
