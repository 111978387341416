<template>
  <div
    v-show="compare.length > 0"
    id="cm-c-compare-layer"
    class="cm-c-compare-layer"
  >
    <div class="cm-container">
      <div class="cm-grid">
        <div
          class="
            cm-grid__col--md-9 cm-grid__col--md-offset-3 cm-grid__col--xs-12
          "
        >
          <div class="cm-grid cm-grid--bottom-md">
            <div class="cm-grid__col--md-8 cm-grid__col--xs-12">
              <ul class="cm-c-compare-layer__list">
                <li
                  v-for="item in compare"
                  :key="item.productId"
                  class="cm-c-compare-layer__item"
                  @click="setCompareItem(item)"
                >
                  <p class="cm-c-compare-layer__item-photo">
                    <img :src="item.productImageUrl" :alt="item.productName" />
                  </p>
                  <p class="cm-c-compare-layer__item-name">
                    {{ item.productName }}
                  </p>
                </li>

                <li
                  v-for="empty in compareMax - compareCount"
                  :key="empty"
                  class="
                    cm-c-compare-layer__item cm-c-compare-layer__item--empty
                  "
                >
                  <p class="cm-c-compare-layer__item-name">{{ phoneLabel }}</p>
                </li>
              </ul>
            </div>
            <div class="cm-grid__col--md-4 cm-grid__col--xs-12">
              <p class="cm-c-compare-layer__text">{{ compareText }}</p>

              <c-button
                class="cm-c-compare-layer__button"
                :variant="BUTTON_VARIANTS.TRANSACTIONAL"
                :disabled="compareDisabled"
                @click="compareProducts"
              >
                {{ compareLabel }}
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CButton } from 'olympus/components';
import { BUTTON_VARIANTS } from 'olympus/components/c-button/types/c-button.types';

export default {
  name: 'ProductCompare',

  components: { CButton },

  props: {
    compareLabel: {
      type: String,
      default: ''
    },
    compareText: {
      type: String,
      default: ''
    },
    phoneLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      BUTTON_VARIANTS
    };
  },

  computed: {
    ...mapGetters(['compare', 'compareCount', 'compareMax', 'compareMin']),
    compareDisabled() {
      return this.compareCount < this.compareMin;
    }
  },

  methods: {
    ...mapActions(['setCompareItem']),
    compareProducts() {
      const products = this.$store.state.shop.compare.map(
        product => product.productId
      );
      window.location.href =
        this.$store.state.shop.compareSettings.url + products.join('&codes=');
    }
  }
};
</script>
