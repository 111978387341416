var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-form-radio" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.computedLocalChecked,
            expression: "computedLocalChecked"
          }
        ],
        ref: "input",
        attrs: {
          id: _vm.id,
          name: _vm.getName,
          disabled: _vm.isDisabled,
          type: "radio",
          autocomplete: "off"
        },
        domProps: {
          value: _vm.value,
          checked: _vm._q(_vm.computedLocalChecked, _vm.value)
        },
        on: {
          change: [
            function($event) {
              _vm.computedLocalChecked = _vm.value
            },
            _vm.onChange
          ]
        }
      }),
      _vm._v(" "),
      !_vm.isGrouped && !_vm.computedLocalChecked
        ? _c("input", { attrs: { type: "hidden", name: _vm.name, value: "" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.descriptionText
            ? _c("p", {
                staticClass: "c-form-radio__description-text",
                domProps: { innerHTML: _vm._s(_vm.descriptionText) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.additionalText
            ? _c("p", {
                staticClass: "c-form-radio__additional-text",
                domProps: { innerHTML: _vm._s(_vm.additionalText) }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("footer"),
      _vm._v(" "),
      _vm.hasTooltip
        ? _c(
            "popover-panel",
            {
              attrs: {
                "popover-triggers": ["hover", "click"],
                description: _vm.tooltipDescriptionText
              }
            },
            [
              _c(
                "div",
                { staticClass: "c-form-radio__info-tooltip" },
                [
                  _c("c-icon", {
                    staticClass: "c-form-radio__info-tooltip-icon",
                    attrs: { "symbol-id": "contact-information" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.tooltipTriggerText))])
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }