var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-filter-nav__block", class: _vm.facet.facetCssClass },
    [
      _c("div", { staticClass: "cm-c-filter-nav__head" }, [
        _c("h3", { staticClass: "cm-c-filter-nav__title" }, [
          _vm._v("\n      " + _vm._s(_vm.facet.displayName) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.showReset
          ? _c("p", { staticClass: "cm-c-filter-nav__link-del" }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetFacetGroup(_vm.facet.name)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.resetText))]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "facetBody",
          staticClass: "cm-c-filter-nav__body",
          class: {
            "cm-c-filter-nav__body--show-all":
              !_vm.truncateFacetLines || _vm.allFacetItemsShown
          },
          style: { maxHeight: _vm.maxBodyHeight }
        },
        _vm._l(_vm.facet.values, function(item, itemkey) {
          return _c("product-category-facet-item", {
            key: itemkey,
            attrs: {
              item: item,
              facet: _vm.facet.name,
              "facet-type": _vm.facet.facetType,
              "facet-property-name": _vm.facet.propertyName
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isTruncated || _vm.allFacetItemsShown
        ? [
            _c(
              "button",
              {
                staticClass:
                  "cm-c-filter-nav__toggle cm-c-filter-nav__toggle--icon-right",
                attrs: { type: "button" },
                on: { click: _vm.toggleShowAllFacets }
              },
              [
                _vm.allFacetItemsShown
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.facetShowFewerLabel) +
                          "\n        "
                      ),
                      _c("c-icon", {
                        staticClass: "cm-c-filter-nav__toggle__icon",
                        attrs: { "symbol-id": "arrow-up" }
                      })
                    ]
                  : [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.facetShowAllLabel) +
                          "\n        "
                      ),
                      _c("c-icon", {
                        staticClass: "cm-c-filter-nav__toggle__icon",
                        attrs: { "symbol-id": "arrow-down" }
                      })
                    ]
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }