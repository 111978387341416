/**
 * OneBlock Templates: Sections
 * @module oneblock-client.mjs
 * @version 1.0.04
 * @summary 04-11-2019
 * @author Mads Stoumann
 * @description Templates for Custom Section types
 */
import { tmplOneBlock } from './oneblock-templates.mjs';

/**
 * @function tmplSectionCarousel
 * @param {Object} data
 * @description Wraps OneBlocks in a carousel
 */

export function tmplSectionCarousel(data) {
	return `
	<div class="c-carousel c-carousel--indicator-lines c-carousel--nav-below c-carousel--tar-1-1" data-section-type="carousel" data-render-nav-inline=":false" data-render-thumbnails=":true">
		${data.content
			.map(item => {
				return `<div class="c-carousel__item-content">${tmplOneBlock(
					item
				)}</div>`;
			})
			.join('')}
	</div>`;
}

/**
 * @function tmplSection
 * @param {Object} data
 * @description Wraps OneBlocks in a section (with optional modifiers)
 * STUB <p class="c-section__description"></p>
 */

export function tmplSection(data) {
	return `
	<section class="c-section c-section--width-m c-section--carousel h-bg15">
		<div class="c-section__intro">
			<h2 class="c-section__headline">
				<span class="c-section__headline-text">Our best Stoma bags</span>
				<span class="c-section__nav">
					<span class="c-section__counter"></span>
					<span class="c-section__page"></span>
				</span>
			</h2>
		</div>
		<div class="c-section__inner">
			${data.content
				.map(item => {
					return `<div class="c-section__item">${tmplOneBlock(item)}</div>`;
				})
				.join('')}
		</div>
	</section>`;
}
