import { get, del, post } from 'webshop/api/api.js';
import { createNamespace } from '../utils.js';
import { ITEM_TYPES } from 'webshop/enums/basket-item-types.js';
import { BASKET_OPERATIONS } from 'webshop/enums/basket-operation-types.js';
import GROUP_RELATION_TYPES from 'webshop/enums/basket-group-relation-types.js';

const MUTATIONS = createNamespace('BASKET/MUTATIONS', [
  'SET_BASKET',
  'SET_NETWORKING',
  'INITIALIZE'
]);

export const ACTIONS = createNamespace('BASKET/ACTIONS', [
  'FETCH_BASKET',
  'INIT_BASKET',
  'REMOVE_FROM_BASKET', // todo: rename to REMOVE_ITEM
  'ADD_ITEM',
  'ADD_SUBSCRIPTION',
  'ADD_LOYALTY_VAS_ITEM',
  'ADD_LOYALTY_VAS_SUBSCRIPTION',
  'ADD_HANDSET',
  'ADD_ACCESSORY',
  'ADD_SERVICE',
  'ADD_DISCOUNT',
  'ADD_MEMBERSHIP',
  'ADD_BROADBAND',
  'REMOVE_DISCOUNT',
  'TOGGLE_DISCOUNT',
  'CLEAR_BASKET'
]);

export const GETTERS = createNamespace('BASKET/GETTERS', [
  'IS_INIT',
  'IS_NETWORKING',
  'IS_UPDATING',
  'IS_EMPTY',
  'GROUPS',
  'COUPONS',
  'INSTALLMENTS',
  'HAS_COUPONS',
  'ALL_ITEMS',
  'GET_ITEM',
  'CONTAINS_ITEM',
  'FINANCED_ITEMS',
  'HAS_FINANCED_ITEMS',
  'NUMBER_CONFIGURATION_ENABLED',
  'HAS_INSTALLMENTS'
]);

/**
 * Handles updating cart items response.
 *
 * @param {axios formatted response} response
 */
const cartItemsUpdateRedirectHandler = response => {
  var redirect = response.headers['x-redirect'] || response.data.location;
  if (redirect) {
    window.location.href = redirect;
  }
  return response.data;
};

const DEFAULT_INSTALLMENT = null;

const initialState = {
  installments: DEFAULT_INSTALLMENT,
  numberConfigurationEnabled: false,
  relatedGroups: [],
  coupons: []
};

export default {
  state: () => ({
    data: initialState,
    networking: false,
    init: false
  }),
  mutations: {
    [MUTATIONS.SET_BASKET](state, data) {
      // todo: handle brand differences
      state.data = {
        ...data,
        relatedGroups: data.relatedGroups?.map(x => {
          return {
            groups: x.groups.reduce((arr, group) => {
              if (
                group.relation &&
                group.relation.type === GROUP_RELATION_TYPES.EXTRA_DATA_CARD
              ) {
                return arr;
              }

              const edcGroup = x.groups.find(
                g =>
                  g.relation &&
                  g.relation.type === GROUP_RELATION_TYPES.EXTRA_DATA_CARD &&
                  g.relation.relationGroupId === group.id
              );

              const items = group.items.map(i => ({ ...i, groupId: group.id }));

              if (edcGroup) {
                const types = items.map(i => i.type);
                const pos = Math.max(
                  types.indexOf(ITEM_TYPES.SUBSCRIPTION),
                  types.indexOf(ITEM_TYPES.HARDWARE)
                );
                const edcGroupItems = edcGroup.items.map(i => ({
                  ...i,
                  groupId: edcGroup.id
                }));
                items.splice(pos + 1, 0, ...edcGroupItems);
              }

              arr.push({
                ...group,
                items
              });

              return arr;
            }, [])
          };
        })
      };
    },
    [MUTATIONS.SET_NETWORKING](state, networking) {
      state.networking = networking;
    },
    [MUTATIONS.INITIALIZE](state) {
      state.init = true;
    }
  },

  actions: {
    /**
     * Safe guarded single entry call for initializing the basket.
     */
    [ACTIONS.INIT_BASKET]({ commit, dispatch, getters }, params) {
      if (getters[GETTERS.IS_INIT]) {
        return;
      }

      commit(MUTATIONS.INITIALIZE);

      return dispatch(ACTIONS.FETCH_BASKET, params);
    },

    [ACTIONS.FETCH_BASKET]({ commit, getters }, params) {
      if (getters[GETTERS.IS_NETWORKING]) {
        return;
      }
      // todo: add error handling
      commit(MUTATIONS.SET_NETWORKING, true);
      return get('/cart/items', {
        params: { ...params }
      }).then(({ data }) => {
        commit(MUTATIONS.SET_BASKET, data);
        commit(MUTATIONS.SET_NETWORKING, false);
      });
    },

    [ACTIONS.ADD_LOYALTY_VAS_ITEM]({ commit }, payload) {
      // todo: add error handling
      commit(MUTATIONS.SET_NETWORKING, true);
      return post('/api/olympus/MobileSubscription/Buy', payload, { mvc: true })
        .then(cartItemsUpdateRedirectHandler)
        .then(data => {
          commit(MUTATIONS.SET_BASKET, data);
          commit(MUTATIONS.SET_NETWORKING, false);
        })
        .catch(error => {
          commit(MUTATIONS.SET_NETWORKING, false);
          return Promise.reject(error);
        });
    },

    [ACTIONS.ADD_ITEM]({ commit }, payload) {
      // todo: add error handling
      commit(MUTATIONS.SET_NETWORKING, true);
      return post('/cart/add', payload)
        .then(cartItemsUpdateRedirectHandler)
        .then(data => {
          commit(MUTATIONS.SET_BASKET, data);
          commit(MUTATIONS.SET_NETWORKING, false);
        })
        .catch(error => {
          commit(MUTATIONS.SET_NETWORKING, false);
          return Promise.reject(error);
        });
    },

    [ACTIONS.ADD_LOYALTY_VAS_SUBSCRIPTION](
      { dispatch },
      {
        code,
        bundleCode,
        groupId,
        upsale,
        relationType,
        existingMsisdn,
        membersQuantity,
        pageId,
        continueWithoutLoyaltyVas
      }
    ) {
      return dispatch(ACTIONS.ADD_LOYALTY_VAS_ITEM, {
        code,
        bundleCode,
        groupId,
        upsale,
        relationType,
        subscriptionExistingMsisdn: existingMsisdn,
        membersQuantity,
        pageId,
        continueWithoutLoyaltyVas,
        op: BASKET_OPERATIONS.ADD_SUBSCRIPTION
      });
    },

    [ACTIONS.ADD_SUBSCRIPTION](
      { dispatch },
      {
        code,
        bundleCode,
        groupId,
        upsale,
        relationType,
        existingMsisdn,
        continueWithoutLoyaltyVas
      }
    ) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code,
        bundleCode,
        groupId,
        upsale,
        relationType,
        existingMsisdn,
        continueWithoutLoyaltyVas,
        op: BASKET_OPERATIONS.ADD_SUBSCRIPTION
      });
    },

    [ACTIONS.ADD_HANDSET](
      { dispatch },
      {
        handsetCode,
        installments,
        subscriptionCode,
        subscriptionExistingMsisdn,
        vas,
        acceptBanRestriction,
        groupId,
        upsale,
        continueWithoutLoyaltyVas
      }
    ) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code: handsetCode,
        subscriptionCode,
        vas,
        installments,
        subscriptionExistingMsisdn,
        acceptBanRestriction,
        upsale,
        groupId,
        continueWithoutLoyaltyVas,
        op: BASKET_OPERATIONS.ADD_HARDWARE
      });
    },

    [ACTIONS.ADD_ACCESSORY](
      { dispatch },
      { code, groupId, quantity, installmentType, upsale }
    ) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code,
        groupId,
        quantity,
        installments: installmentType,
        upsale,
        op: BASKET_OPERATIONS.ADD_ACCESSORY
      });
    },

    [ACTIONS.ADD_SERVICE]({ dispatch }, { vasCode, groupId }) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code: vasCode,
        groupId,
        op: BASKET_OPERATIONS.ADD_SERVICE
      });
    },

    [ACTIONS.ADD_MEMBERSHIP]({ dispatch }, { code, membershipCode }) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code,
        membershipCode,
        op: BASKET_OPERATIONS.ADD_MEMBERSHIP
      });
    },

    // todo: find product name for extraTechnician
    [ACTIONS.ADD_BROADBAND](
      { dispatch },
      {
        code,
        addressId,
        extraTechnician,
        replaceCurrent,
        upsale,
        continueWithoutLoyaltyVas,
        bundleCode,
        op = false,
        orderId = false
      }
    ) {
      return dispatch(ACTIONS.ADD_ITEM, {
        code,
        upsale,
        addressId,
        extraTechnician,
        replaceCurrent,
        continueWithoutLoyaltyVas,
        bundleCode,
        op: op ? op : BASKET_OPERATIONS.ADD_BROADBAND,
        orderId
      });
    },

    [ACTIONS.REMOVE_FROM_BASKET](
      { commit },
      { code, groupId, lineItemId = 0, acceptFinancingConflict = false }
    ) {
      commit(MUTATIONS.SET_NETWORKING, true);
      return del('/cart/remove', {
        data: {
          code: [code],
          groupId,
          lineItemId: lineItemId,
          op: BASKET_OPERATIONS.REMOVE,
          acceptFinancingConflict
        }
      })
        .then(({ data }) => {
          commit(MUTATIONS.SET_BASKET, data);
          commit(MUTATIONS.SET_NETWORKING, false);
        })
        .catch(error => {
          commit(MUTATIONS.SET_NETWORKING, false);
          return Promise.reject(error);
        });
    },

    [ACTIONS.REMOVE_EXISTING_PRODUCTS](_, { codes, groupId }) {
      return del('/cart/remove', {
        data: {
          code: codes,
          groupId,
          op: BASKET_OPERATIONS.REMOVE_EXISTING
        }
      });
    },

    [ACTIONS.TOGGLE_DISCOUNT]({ commit }, promise) {
      commit(MUTATIONS.SET_NETWORKING, true);
      return promise
        .then(({ data }) => {
          commit(MUTATIONS.SET_BASKET, data);
          commit(MUTATIONS.SET_NETWORKING, false);
        })
        .catch(e => {
          commit(MUTATIONS.SET_NETWORKING, false);
          return Promise.reject(e);
        });
    },

    [ACTIONS.ADD_DISCOUNT]({ dispatch }, { code, blockId }) {
      // todo: add error handling
      const promise = post('/cart/discount', {
        promotionCode: code,
        blockId
      });
      return dispatch(ACTIONS.TOGGLE_DISCOUNT, promise);
    },

    [ACTIONS.REMOVE_DISCOUNT]({ dispatch }, { code, blockId }) {
      // todo: add error handling

      const promise = del('/cart/discount', {
        data: {
          promotionCode: code,
          blockId
        }
      });
      return dispatch(ACTIONS.TOGGLE_DISCOUNT, promise);
    },

    [ACTIONS.CLEAR_BASKET]({ getters, commit }) {
      if (getters[GETTERS.IS_NETWORKING]) {
        return;
      }

      commit(MUTATIONS.SET_NETWORKING, true);

      return del('/cart/empty')
        .then(() => {
          commit(MUTATIONS.SET_BASKET, initialState);
          commit(MUTATIONS.SET_NETWORKING, false);
        })
        .catch(() => {
          // todo: add error handling
          commit(MUTATIONS.SET_NETWORKING, false);
        });
    }
  },

  getters: {
    [GETTERS.IS_INIT]: state => state.init,

    [GETTERS.IS_UPDATING]: (_state, getters) =>
      getters[GETTERS.IS_INIT] && getters[GETTERS.IS_NETWORKING],

    [GETTERS.IS_NETWORKING]: state => state.networking,

    [GETTERS.GROUPS]: state => state.data?.relatedGroups || [],

    [GETTERS.COUPONS]: state => state.data?.coupons || [],

    [GETTERS.INSTALLMENTS]: state =>
      state.data?.installments || DEFAULT_INSTALLMENT,

    [GETTERS.HAS_COUPONS]: (_state, getters) =>
      Boolean(getters[GETTERS.COUPONS].length),

    [GETTERS.IS_EMPTY]: (_state, getters) => !getters[GETTERS.GROUPS].length,

    [GETTERS.ALL_ITEMS]: (_state, getters) =>
      getters[GETTERS.GROUPS].reduce((arr, { groups }) => {
        if (groups.length) {
          groups.forEach(group => group.items.forEach(item => arr.push(item)));
        }
        return arr;
      }, []),

    [GETTERS.GET_ITEM]: (_state, getters) => data =>
      getters[GETTERS.ALL_ITEMS].find(
        item => item.groupId === data.groupId && item.type === data.type
      ),

    [GETTERS.CONTAINS_ITEM]: (_state, getters) => code =>
      getters[GETTERS.ALL_ITEMS].find(item => item.code === code),

    [GETTERS.FINANCED_ITEMS]: (_state, getters) =>
      getters[GETTERS.ALL_ITEMS].filter(
        x =>
          x.installments >= 1 &&
          (x.type === ITEM_TYPES.HARDWARE || x.type === ITEM_TYPES.ACCESSORY)
      ),

    [GETTERS.HAS_FINANCED_ITEMS]: (_state, getters) =>
      !!getters[GETTERS.FINANCED_ITEMS].length,

    [GETTERS.NUMBER_CONFIGURATION_ENABLED]: state =>
      state.data.numberConfigurationEnabled,

    [GETTERS.HAS_INSTALLMENTS]: (_state, getters) =>
      !!getters[GETTERS.INSTALLMENTS]
  }
};
