<script>
import nemidMixin from '../../mixins/nemid-mixin';
import { providerKey as checkoutProviderKey } from '../../providers/checkout-provider.js';

export default {
  name: 'checkout-nemid',

  mixins: [nemidMixin],

  props: {
    secret: { type: String, default: '' },
    token: { type: String, default: '' },
    isdivaflow: Boolean,
    skipCpr: Boolean,
    loggedInUserMismatch: Boolean
  },

  data() {
    return {
      entered: false,
      segment: '1'
    };
  },
  inject: [checkoutProviderKey],

  created: function() {
    this.temporaryModel.secret = this.secret ? this.secret : '';
    this.temporaryModel.token = this.token ? this.token : '';
    this.entered = !!this.temporaryModel.secret && !!this.temporaryModel.token;

    if (!this.hasParameters) {
      this._fetchParams();
    }
  },
  methods: {
    nemidResponseSuccess() {
      this[checkoutProviderKey].submit();
    }
  }
};
</script>
