var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "c-product-card",
      class: _vm.modifier,
      attrs: {
        itemtype: "http://schema.org/Product",
        itemscope: "",
        href: _vm.productUrl
      }
    },
    [
      _vm.ribbonText
        ? _c(
            "div",
            {
              staticClass:
                "c-product-card__ribbon c-badge c-badge--top-left c-badge--primary"
            },
            [_vm._v("\n    " + _vm._s(_vm.ribbonText) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "c-product-card__top" }, [
        _c("div", { staticClass: "c-product-card__image" }, [
          _c("img", {
            attrs: {
              alt: _vm.productModel || _vm.productName,
              src: _vm.productPreviewImage
            }
          }),
          _vm._v(" "),
          _vm.splash
            ? _c(
                "p",
                { staticClass: "c-splash c-splash--product c-splash--accent" },
                [
                  _c("span", [
                    _vm._v("\n          " + _vm._s(_vm.splash) + "\n        ")
                  ])
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-product-card__content" }, [
          _c("section", [
            _c("small", { staticClass: "c-product-card__brand" }, [
              _vm._v(_vm._s(_vm.productBrand))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "c-product-card__details-wrapper" }, [
              _c("h3", { staticClass: "c-product-card__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.productModel || _vm.productName) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _vm.variants && _vm.variants.length > 0
                ? _c(
                    "ul",
                    _vm._l(_vm.variants, function(variant) {
                      return _c("li", {
                        key: variant,
                        staticClass: "c-product-card__variant",
                        class: {
                          "c-product-card__variant--border": _vm.isColorWhite(
                            variant
                          )
                        },
                        style: { backgroundColor: variant }
                      })
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("section", [
            _c(
              "p",
              { staticClass: "c-product-card__price" },
              [
                _vm._v("\n          " + _vm._s(_vm.price.amount) + " "),
                _c("span", [_vm._v(_vm._s(_vm.price.unit))]),
                _vm._v(" "),
                _vm._l(_vm.priceDescriptions, function(text) {
                  return _c(
                    "small",
                    {
                      directives: [{ name: "trim", rawName: "v-trim" }],
                      key: text
                    },
                    [_vm._v("\n            " + _vm._s(text) + "\n          ")]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.content.actionButtonLabel
              ? _c("button", { staticClass: "c-btn c-btn--sm" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.content.actionButtonLabel) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }