import { MUTATIONS } from './mutation-types.js';
import { markDuplicates } from '../../components/shopping-cart/price-summary-helpers';

// TODO: move all funcs below into a single obj
// TODO: use types instead of hard coded text
export default {
  ['mutations/SET_FACET_STATE'](state, { id, name, value }) {
    const group = state.selectedFacets.find(x => x.id === id);

    if (group) {
      const valueIndex = group.values.indexOf(value);
      if (valueIndex !== -1) {
        group.values.splice(valueIndex, 1);
      } else {
        group.values.push(value);
      }
    } else {
      const newGroup = {
        id,
        name,
        values: [value]
      };
      state.selectedFacets.push(newGroup);
    }
  },

  ['SHOW_ALERT'](data) {
    // eslint-disable-next-line
    console.log(data);
  },

  ['RELOAD_PAGE']() {
    location.reload();
  },

  // UNDONE: leftover from susbscription add to cart prototype
  ['STORE_TO_CART'](_state, code) {
    window.location.href = code.redirectUrl;
  },

  ['UPDATE_CART'](state, payload) {
    state.basket = { ...state.basket, ...payload };
  },

  ['ADD_COMPARE'](state, product) {
    state.compare.push(product);
  },

  ['REM_COMPARE'](state, productIdx) {
    // eslint-disable-next-line
    state.compare.splice(productIdx, 1);
  },

  ['ADD_FACET'](state, facet) {
    state.selectedFacets.push(facet);
  },

  ['REM_FACET'](state, facetIdx) {
    // eslint-disable-next-line
    state.selectedFacets.splice(facetIdx, 1);
  },

  ['REM_FACETS'](state) {
    state.selectedFacets = [];
  },

  ['UPD_FACET'](state, payload) {
    state.selectedFacets[payload.index] = payload.facet;
  },

  ['MODAL'](state, boolean) {
    state.modal = boolean;
  },

  ['SEARCH_PRODUCTS'](state, searchResult) {
    if (searchResult.page <= 1 || !state.productSearchResult.hits) {
      state.productSearchResult = searchResult;
    } else {
      state.productSearchResult.hits = [
        ...state.productSearchResult.hits,
        ...searchResult.hits
      ];
    }
  },

  ['SEARCH_SETTINGS'](state, settings) {
    state.searchSettings = Object.assign({}, settings);
  },

  ['COMPARE_SETTINGS'](state, settings) {
    state.compareSettings = Object.assign({}, settings);
  },

  ['SET_PRICEPLAN'](state, price) {
    state.searchSettings.pricePlanFilterValue = price;
  },

  ['SET_PAGE'](state, page) {
    state.searchSettings.page = page;
  },

  ['RESET_PAGE'](state) {
    state.searchSettings.page = 1;
  },

  ['REMOVE_SIZE'](state) {
    delete state.searchSettings.size;
  },

  ['SORT_BY'](state, sortby) {
    state.searchSettings.page = 1;
    state.sortBy = sortby;
  },

  ['SHOW_DIALOG'](state, { componentName, componentProps }) {
    state.dialog.visible = true;
    state.dialog.currentDialog = componentName;
    state.dialog.currentProps = componentProps;
  },

  ['HIDE_DIALOG'](state) {
    state.dialog.visible = false;
    state.dialog.currentProps = {};
  },

  ['SHOW_SPINNER'](state) {
    state.spinnerOpen = true;
  },

  ['HIDE_SPINNER'](state) {
    state.spinnerOpen = false;
  },

  [MUTATIONS.SET_ORDER_NUMER](state, orderNumber) {
    state.orderNumber = orderNumber;
  },

  [MUTATIONS.SET_SPINNER_SHOW_TIMEOUT](state, timeout) {
    state.spinnerShowTimeout = timeout;
  },

  ['UPDATE_PRICES'](state, object) {
    state.prices = { ...state.prices, ...object };
  },

  [MUTATIONS.SET_MINIMUM_PRICE_DETAILS](state, object) {
    state.minimumPrice = { ...state.minimumPrice, ...object };
  },

  [MUTATIONS.SET_BASKET_PRICE_DETAILS](state, { data }) {
    state.basketPrices.data = data;
  },

  [MUTATIONS.SET_BASKET_PRICE_NETWORKING](state, value) {
    state.basketPrices.networking = value;
  },

  ['UPDATE_BAN'](state, ban) {
    state.checkout.ban = ban;
  },

  ['UPDATE_BAN_SELECTOR_ENABLED'](state, enabled) {
    state.checkout.banSelectorEnabled = enabled;
  },

  /* GLOBAL */
  ['SET_ENTERPRISE'](state, boolean) {
    state.global.enterprise = boolean;
  },

  ['SET_CURRENTBALANCE'](state, number) {
    state.global.currentBalance = number;
  },

  ['SET_EXTERNAL_RESELLER'](state, boolean) {
    state.global.externalReseller = boolean;
  },

  ['SET_CONTEXTID'](state, String) {
    state.global.contextId = String;
  },

  /* Work-In-Progress: Number Configuration */
  ['SET_NUMCONF_ACTIVE'](state, productCode) {
    const index = state.numberConfig.items.findIndex(
      product => product.code === state.numberConfig.active
    );
    const newactiveindex = state.numberConfig.items.findIndex(
      product => product.code === productCode
    );
    state.numberConfig.items[index].configEdited = false;
    state.numberConfig.items[newactiveindex].configEdited = true;
    state.numberConfig.active = productCode;
  },

  ['SET_NUMCONF_ACTIVE_ClOSE'](state, productCode) {
    const index = state.numberConfig.items.findIndex(
      product => product.code === productCode
    );
    state.numberConfig.items[index].configEdited = false;
  },

  ['SET_NUMCONF_COMPLETE'](state, payload) {
    const index = state.numberConfig.items.findIndex(
      product => product.code === payload.code
    );
    state.numberConfig.items[index].configEdited = payload.edited;
    state.numberConfig.items[index].configComplete =
      state.numberConfig.items[index].phoneNumber !== null ? true : false;
  },

  ['SET_NUMCONF_DATA'](state, { data, setFirstItemActive = true }) {
    state.numberConfig.items = data;
    const FIRST_ITEM = 0;

    if (setFirstItemActive) {
      state.numberConfig.active = data[FIRST_ITEM]?.code;
    }
  },

  ['SET_NUMCONF_ITEM'](state, data) {
    const index = state.numberConfig.items.findIndex(
      product => product.code === state.numberConfig.active
    );
    state.numberConfig.items[index] = Object.assign(
      state.numberConfig.items[index],
      data
    );
  },

  ['SET_NUMCONF_VM'](state, data) {
    state.numberConfig.vm = data;
  },

  ['SET_AVAILABLE_NUMBERS'](state, payload) {
    state.numberConfig.vm.availableNumbers = [
      ...state.numberConfig.vm.availableNumbers,
      ...payload.numbers
    ];
    state.numberConfig.vm.hasMoreAvailableNumbers = payload.hasMoreNumbers;
  },

  ['CLEAR_AVAILABLE_NUMBERS'](state) {
    state.numberConfig.vm.availableNumbers = [];
    state.numberConfig.vm.hasMoreAvailableNumbers = false;
  },

  [MUTATIONS.SET_BASKET_PRICE_SUMMARY](state, payload) {
    const {
      totalMonthly,
      totalUpFront,
      totalVat,
      totalUpFrontWithVAT,
      subscriptionItems,
      financingItems,
      financeSummary,
      upFrontItems,
      shippingSummary,
      paymentFee
    } = payload.data;

    const subscriptions = markDuplicates(subscriptionItems);
    const installments = markDuplicates(financingItems);
    const upfrontItems = markDuplicates(upFrontItems);

    if (shippingSummary) {
      installments.push(shippingSummary);
    }

    if (paymentFee) {
      installments.push(paymentFee);
    }

    state.pricesSummary.data = {
      totalMonthly: totalMonthly,
      totalUpfront: totalUpFront,
      totalVAT: totalVat,
      totalIncludingVAT: totalUpFrontWithVAT,
      subscriptions: subscriptions,
      installments: installments,
      upfrontItems: upfrontItems,
      financeSummary: financeSummary
    };
  },

  [MUTATIONS.SET_BASKET_PRICE_SUMMARY_NETWORKING](state, value) {
    state.pricesSummary.networking = value;
  }
};
