var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-stock-information" },
    [
      _c("div", { staticClass: "product-stock-status" }, [
        _c("div", { staticClass: "product-stock-status__container" }, [
          _c(
            "p",
            {
              staticClass: "product-stock-status__text",
              class: !_vm.loadingStoresStockData && _vm.stockStatusColor
            },
            [
              _vm.loadingStoresStockData
                ? [
                    _c("skeleton-loader", {
                      staticClass: "product-stock-status__text__skeleton-loader"
                    })
                  ]
                : _vm.totalInStock
                ? [
                    _vm._v("\n          På lager i \n          "),
                    _c(
                      "span",
                      {
                        staticClass: "product-stock-status__text--shop-stock",
                        class: !!_vm.totalInStock
                          ? "product-stock-status__text--shop-stock-enabled"
                          : "product-stock-status__text--shop-stock-disabled",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.totalInStock ? _vm.openStoreListModal() : null
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.storeLabelSingularOrPluralForm) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                : [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.labelNotInStock) +
                        "\n        "
                    )
                  ]
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.displayStoreListModal
        ? _c(
            "c-modal",
            {
              ref: "storeListModal",
              attrs: { gray: "", "is-full-height": "" },
              on: { close: _vm.clearExpandedStores }
            },
            [
              _c("div", { staticClass: "store-list__heading" }, [
                _vm._v(_vm._s(_vm.modalHeader))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "store-list" },
                [
                  _c("div", { staticClass: "store-list__search" }, [
                    _c("div", { staticClass: "epi-form-text" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.keyword,
                            expression: "keyword"
                          }
                        ],
                        ref: "input",
                        staticClass:
                          "epi-form-text__input c-search-panel__input",
                        attrs: {
                          id: "c-search-panel",
                          type: "text",
                          placeholder: " "
                        },
                        domProps: { value: _vm.keyword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.keyword = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "epi-form-text__label",
                          attrs: { for: "c-search-panel" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.searchPlaceholderLabel) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "epi-form-text__input-ui",
                          on: {
                            click: function($event) {
                              _vm.keyword = ""
                            }
                          }
                        },
                        [
                          _c("c-icon", {
                            attrs: {
                              "symbol-id":
                                _vm.keyword.length > 0
                                  ? "menu-close"
                                  : "menu-search"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "store-list__filters" },
                    [
                      _c(
                        "c-checkbox",
                        {
                          attrs: { id: "only-show-nearby-stores" },
                          model: {
                            value: _vm.isNearbyStoresChecked,
                            callback: function($$v) {
                              _vm.isNearbyStoresChecked = $$v
                            },
                            expression: "isNearbyStoresChecked"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.filterNearMeLabel) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-checkbox",
                        {
                          attrs: { id: "only-display-stores-with-stock" },
                          model: {
                            value: _vm.onlyShowStoresWithStock,
                            callback: function($$v) {
                              _vm.onlyShowStoresWithStock = $$v
                            },
                            expression: "onlyShowStoresWithStock"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.filterOnlyInStockLabel) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.filteredStores, function(store) {
                    return _c(
                      "div",
                      {
                        key: store.shopCode,
                        staticClass: "store-list__store",
                        class: {
                          expanded: _vm.storeIsExpanded(store.shopCode)
                        },
                        on: {
                          click: function($event) {
                            !_vm.storeIsExpanded(store.shopCode) &&
                              _vm.togglestore(store.shopCode)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "store-list__store__header" },
                          [
                            _c(
                              "div",
                              { staticClass: "store-list__store__header-meta" },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "store-list__store__header-title"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(store.storeName) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.userPosition
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "store-list__store__header-distance"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(store.prettyDistance) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "c-button",
                              {
                                staticClass: "store-list__store__header-toggle",
                                attrs: { reset: "", "icon-button": "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.togglestore(store.shopCode)
                                  }
                                }
                              },
                              [
                                _c("c-icon", {
                                  attrs: {
                                    "symbol-id":
                                      "arrow-" +
                                      (_vm.storeIsExpanded(store.shopCode)
                                        ? "up"
                                        : "down")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "address",
                          { staticClass: "store-list__store__address" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(store.prettyAddress) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "store-list__stock-status" },
                          [
                            _c("div", {
                              staticClass: "store-list__stock-status-indicator",
                              class: _vm.storeStockStatusColor(store.stockCount)
                            }),
                            _vm._v(" "),
                            store.hasStock
                              ? [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        store.stockCount >=
                                          _vm.stockThresholdIndicator
                                          ? _vm.labelStoreInStockMany
                                          : _vm.labelStoreInStockFew
                                      ) +
                                      "\n          "
                                  )
                                ]
                              : [_vm._v(_vm._s(_vm.labelStoreNotInStock))]
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.storeIsExpanded(store.shopCode)
                          ? [
                              _c(
                                "div",
                                { staticClass: "store-list__store__actions" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "store-list__store__actions__action",
                                      attrs: {
                                        href: "tel:" + store.phoneNumber
                                      }
                                    },
                                    [
                                      _c("c-icon", {
                                        attrs: { "symbol-id": "service-phone" }
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(store.phoneNumber) +
                                          "\n            "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "store-list__store__actions__action",
                                      attrs: {
                                        href:
                                          "https://www.google.com/maps/dir/?api=1&destination=" +
                                          store.prettyAddress
                                      }
                                    },
                                    [
                                      _c("c-icon", {
                                        attrs: {
                                          "symbol-id": "arrow-directions"
                                        }
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.directionsLabel) +
                                          "\n            "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "store-list__store__opening-hours"
                                },
                                [
                                  _c("c-heading", { attrs: { level: "4" } }, [
                                    _vm._v(_vm._s(_vm.openingHoursLabel))
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(store.openingHours, function(
                                    openingHour,
                                    idx
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass:
                                          "store-list__store__opening-hours__list"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "store-list__store__opening-hours__list-item"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(openingHour.key) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "store-list__store__opening-hours__list-item"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(openingHour.value) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }