var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "subscription-overview" }, [
    _c("div", { staticClass: "subscription-overview__image" }, [
      _c("picture", { staticClass: "subscription-overview__image__hero" }, [
        _c("source", {
          attrs: {
            media: "(max-width: 400px)",
            srcset: _vm.content.imageMobile.path
          }
        }),
        _vm._v(" "),
        _c("img", { attrs: { src: _vm.content.imageDesktop.path } })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "subscription-overview__header" }, [
      _c("div", { staticClass: "c-wrapper" }, [
        _c("h2", {
          staticClass: "subscription-overview__headline",
          domProps: { innerHTML: _vm._s(_vm.content.headline) }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "subscription-overview__description" },
          [_vm._t("description")],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "subscription-overview__rows" }, [
      _c(
        "div",
        { staticClass: "c-wrapper" },
        [
          _c(
            "div",
            {
              class: [
                "subscription-overview__chips",
                "subscription-overview__chips--" + _vm.selectedChip
              ]
            },
            _vm._l(_vm.content.groups, function(item, index) {
              return _c(
                "c-chip",
                {
                  key: index,
                  class: { checked: index === _vm.selectedChip },
                  attrs: {
                    name: "selectedRow",
                    "input-value": item.selectionLabel,
                    preselected: index === 0,
                    wide: ""
                  },
                  on: { change: _vm.setSelectedChip }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(item.selectionLabel) + "\n        "
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm._t("subscription-cards")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }