<template>
  <div>
    <p
      v-if="!content.isMatchingCpr && content.isSuccessfulLogin"
      class="cm-c-checkout__error"
    >
      {{ content.errorMessageCprMisMatch }}
    </p>
    <p class="cm-c-checkout__text" v-html="content.description"></p>
    <a class="c-btn c-btn--primary c-btn--block" :href="content.brokerUrl">
      {{ content.buttonText }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'CheckoutEid',

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss"></style>
