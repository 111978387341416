var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    { ref: "galleryOverlay", attrs: { medium: "", gray: "" } },
    [
      _c(
        "c-gallery-content",
        _vm._b(
          {
            staticClass: "c-gallery--modal",
            attrs: {
              "alt-props": {
                overlay: false,
                navigation: true,
                minVisible: 10,
                loop: false,
                thumbnailPosition: "bottom",
                navigationRounded: true,
                smallImageSize: "250",
                largeImageSize: "450",
                smallImageWidth: "250px",
                largeImageWidth: "450px",
                selectedImageBreakpoint: "1100"
              }
            }
          },
          "c-gallery-content",
          _vm.$parent.$props,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }