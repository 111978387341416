var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass: "upfront-payment",
      attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h1", { staticClass: "c-typography-h5" }, [
        _vm._v(_vm._s(_vm.content.headline))
      ]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.content.description) } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }