var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      attrs: {
        visible: _vm.visible,
        "no-content-padding": "",
        "show-ok-button": false
      },
      on: { close: _vm.HIDE_DIALOG }
    },
    [
      _c(
        "keep-alive",
        [
          _c(
            _vm.component,
            _vm._b({ tag: "component" }, "component", _vm.props, false)
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }