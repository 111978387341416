<template>
  <div :class="modifiers">
    <c-button
      v-if="showNextButton"
      type="button"
      :disabled="!isValidPage"
      :variant="BUTTON_STYLES[content.nextButtonColor]"
      @click.prevent="submitForm"
    >
      {{ content.nextButtonLabelText }}
    </c-button>

    <c-button
      v-if="showPrevButton"
      :href="content.previousButtonLink"
      variant="secondary"
    >
      {{ content.previousButtonLabelText }}
    </c-button>
  </div>
</template>

<script>
import { CButton } from 'olympus/components/index.js';

import { mapGetters, mapActions } from 'vuex';
import checkoutStore, {
  GETTERS as CHECKOUT_GETTERS,
  ACTIONS as CHECKOUT_ACTIONS
} from 'webshop/components/checkout/store';

import { BUTTON_STYLES } from 'olympus/components/c-button/types/c-button.types';
import { GETTERS as HOME_OFFICE_GETTERS } from 'webshop/store/home-office/index.js';

const CHECKOUT_STORE_NAMESPACE = 'checkoutPage';

export default {
  name: 'CheckoutNavigation',

  components: {
    CButton
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isNetworking: false,
      BUTTON_STYLES
    };
  },

  computed: {
    ...mapGetters({
      isValidPage: CHECKOUT_GETTERS.IS_VALID_PAGE,
      checkoutFormData: CHECKOUT_GETTERS.CHECKOUT_FORM_DATA,
      endPoint: CHECKOUT_GETTERS.CHECKOUT_ENDPOINT,
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA
    }),

    showPrevButton() {
      return (
        !!this.content.previousButtonLink &&
        !!this.content.previousButtonLabelText
      );
    },

    showNextButton() {
      return (
        !!this.content.nextButtonLink && !!this.content.nextButtonLabelText
      );
    },

    modifiers() {
      return {
        'checkout-navigation': true,
        'checkout-navigation--flex-end':
          this.showPrevButton && !this.showNextButton,
        'checkout-navigation--flex-center':
          this.content.isButtonCentered &&
          !this.showPrevButton &&
          this.showNextButton
      };
    }
  },

  beforeCreate() {
    if (!this.$store.hasModule(CHECKOUT_STORE_NAMESPACE)) {
      this.$store.registerModule(CHECKOUT_STORE_NAMESPACE, checkoutStore);
    }
  },

  beforeDestroy() {
    if (!this.$store.hasModule(CHECKOUT_STORE_NAMESPACE)) {
      this.$store.unregisterModule(CHECKOUT_STORE_NAMESPACE);
    }
  },

  methods: {
    ...mapActions({
      submitCheckoutForm: CHECKOUT_ACTIONS.SUBMIT_CHECKOUT_FORM
    }),

    submitForm() {
      if (!this.endPoint) {
        this.redirectToDefault();
        return;
      }
      if (this.isNetworking) {
        return;
      }

      this.isNetworking = true;
      this.submitCheckoutForm()
        .then(() => {
          this.redirectToDefault();
        })
        .catch(error => {
          // DO ERROR HANDLING
          console.error(error);
        })
        .finally(() => {
          this.isNetworking = false;
        });
    },

    redirectToDefault() {
      let linkUrl = this.content.nextButtonLink;
      if (this.employeeData?.orderId) {
        linkUrl = linkUrl + `?horef=${this.employeeData?.orderId}`;
      }
      window.location.href = linkUrl;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';

.checkout-navigation {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: $u-800;

  &--flex-end {
    justify-content: flex-end;
  }

  &--flex-center {
    justify-content: center;
  }
}
</style>
