<template>
  <div :class="classModifiers">
    <div
      v-for="(group, groupIndex) in filteredItems"
      :key="groupIndex"
      class="c-theme-block__container"
    >
      <c-theme-block-item
        v-for="(
          { image, link, heading, tagLine: subtitle, teaser: description },
          index
        ) in group"
        :key="`${groupIndex}_${index}`"
        :subtitle="subtitle"
        :heading="heading"
        :description="description"
        :image="image"
        :link="link"
        :index="index"
      />
    </div>
    <button
      v-if="!depleted && isGridLayout"
      class="c-theme-block__more c-link"
      @click="fetchArticles(true)"
    >
      <slot name="button" />
    </button>
  </div>
</template>

<script>
import { post } from 'axios';
import ThemeBlockItem from './c-theme-block-item.vue';

export default {
  name: 'c-theme-block',
  components: {
    'c-theme-block-item': ThemeBlockItem
  },
  props: {
    blockId: {
      type: Number,
      required: true
    },
    culture: {
      type: String,
      default: 'da-DK'
    },
    searchUrl: {
      type: String,
      required: true
    },
    pageSize: {
      type: Number,
      default: 13
    },
    layout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      totalPages: 0,
      page: 1,
      items: [],
      rowSize: 3
    };
  },
  computed: {
    isGridLayout: function () {
      return this.layout < 1;
    },
    depleted: function () {
      return this.page >= this.totalPages && this.isGridLayout;
    },
    filteredItems: function () {
      if (this.isGridLayout) {
        return this.items;
      }

      return this.items.map(group =>
        group.filter((item, index) => index < this.rowSize)
      );
    },
    classModifiers() {
      return [
        {
          'c-theme-block--row': !this.isGridLayout
        },
        'c-theme-block'
      ];
    }
  },
  mounted() {
    this.fetchArticles();
  },
  methods: {
    async fetchArticles(nextPage = false) {
      if (nextPage) {
        this.page = this.page + 1;
      }

      const { blockId, culture, pageSize, page } = this;

      /*
        TODO TC-4918:
        Swtich this out with custome axios instance (also create a custom axios instance for webshop/cms)
      */
      const {
        data: { items, totalPages }
      } = await post(`${this.searchUrl}?contextid=${this.$root.contextid}`, {
        blockId,
        culture,
        pageSize,
        page
      });

      if (totalPages !== this.totalPages) {
        this.totalPages = totalPages;
      }

      this.items.push(items);
    }
  }
};
</script>
