<script>
import { mapActions, mapGetters } from 'vuex';
import { GETTERS, ACTIONS } from '../../store/groups/index.js';
import { GETTERS as BASKET_GETTERS } from '../../store/basket/index.js';
import { ITEM_TYPES as ITEM_TYPE } from '../../../shared/enums/access-technology-types.js';

import AttributeSelector from 'webshop/components/product/attribute-selector.vue';
import ProductCard from '../catalog/product-card/product-card.vue';

export default {
  name: 'CheckoutUpsale',

  components: {
    ProductCard,
    AttributeSelector
  },

  props: {
    blockId: {
      type: String,
      required: true
    },
    groupId: {
      type: String,
      required: true
    },
    installments: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      productList: [],
      init: false,
      networking: false,
      selectedBindingPeriod: {},
      installmentsList: []
    };
  },

  computed: {
    ...mapGetters({
      getUpsaleForGroup: GETTERS.GET_UPSALE_FOR_GROUP,
      hasInstallmentsInBasket: BASKET_GETTERS.HAS_INSTALLMENTS,
      installmentsInBasket: BASKET_GETTERS.INSTALLMENTS
    }),

    products() {
      return this.productList;
    },

    hasAccessories() {
      return this.productList?.some(x => x.type === ITEM_TYPE.ACCESSORY);
    }
  },

  created() {
    this.fetchData().then(() => {
      this.init = true;
    });
    this.installmentsList = this.installments.map(i => ({
      name: i.shortName,
      code: i.interval
    }));
    // Default to 1
    this.selectedBindingPeriod = this.installmentsList?.find(i => i.code === 1);
  },

  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },

    ...mapActions({
      fetchUpsaleForGroup: ACTIONS.FETCH_UPSALE_FOR_GROUP
    }),

    fetchData() {
      let installments;
      // If the basket has installments then we use that one, otherwise the user should be able to select it
      if (this.hasInstallmentsInBasket) {
        installments = this.installmentsInBasket;
      } else if (this.selectedBindingPeriod) {
        installments = this.selectedBindingPeriod.code;
      }

      return this.fetchUpsaleForGroup({
        blockContextId: this.blockId,
        groupId: this.groupId,
        installment: installments
      }).then(() => {
        this.productList = this.getUpsaleForGroup(this.groupId);
      });
    },

    enter(el) {
      var delay = el.dataset.index * 150;
      setTimeout(function () {
        el.style.opacity = 1;
      }, delay);
    },

    leave(el) {
      var delay = 0;
      setTimeout(function () {
        el.style.opacity = 0;
      }, delay);
    },

    handleProductStateUpdate(state) {
      if (!state.networking) {
        this.fetchData().then(() => (this.networking = false));
      } else {
        this.networking = true;
      }
    },

    onBindingPeriodSelected(data) {
      this.selectedBindingPeriod = data;

      // Fetch products based on the binding period, done by referencing the products.
      this.fetchData();
    }
  }
};
</script>
