<script>
import CButton from '../../../../../shared/components/c-button/c-button.vue';
import MdwcPortletLanguageSwitch from './topup-language-switch.vue';

export default {
  name: 'topup-result',
  components: {
    CButton,
    MdwcPortletLanguageSwitch
  },
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
