var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-tvcard",
      class: [
        _vm.modifierClass ? "c-tvcard--" + _vm.modifierClass : "",
        _vm.onlyChannels ? "c-tvcard--only-channels" : ""
      ]
    },
    [
      _c("div", { staticClass: "c-tvcard__inner" }, [
        _vm.onlyChannels != "true"
          ? _c("div", { staticClass: "c-tvcard__main" }, [
              _c(
                "div",
                {
                  staticClass: "c-subcard c-subcard--tv-package",
                  class: _vm.subcardClasses
                },
                [
                  _vm.showRibbon
                    ? _c("div", { staticClass: "c-subcard__ribbon-wrapper" }, [
                        _vm.ribbonText
                          ? _c("em", { staticClass: "c-subcard__ribbon" }, [
                              _vm._v(_vm._s(_vm.ribbonText))
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-subcard__inner" }, [
                    _c("div", { staticClass: "c-subcard__headline" }, [
                      _vm._v(
                        "\n            " + _vm._s(_vm.headline) + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "c-subcard__name" }, [
                      _vm._v(
                        "\n            " + _vm._s(_vm.name) + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "c-subcard__description" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.description) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.channelDescription))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-subcard__offer" }, [
                    _c("div", { staticClass: "c-subcard__offer-spec" }, [
                      _c("span", { staticClass: "c-subcard__offer-price" }, [
                        _vm._v(_vm._s(_vm.price))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "c-subcard__offer-price-labels" },
                        [
                          _c(
                            "span",
                            { staticClass: "c-subcard__offer-currency" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.priceLabel) +
                                  "\n              "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "c-subcard__offer-description" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.minPrice) +
                              "\n            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "c-subcard__url c-subcard__url--list",
                        attrs: { href: _vm.url }
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    )
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.onlyCard != "true"
          ? _c("div", { staticClass: "c-tvcard__details" }, [
              _vm.categories
                ? _c("div", { staticClass: "c-tvcard__categories" }, [
                    _c(
                      "p",
                      [
                        _vm._t("checkmark-icon"),
                        _vm._v(
                          "\n          " + _vm._s(_vm.categories) + "\n        "
                        )
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.channelsHeadline
                ? _c("h3", { staticClass: "c-tvcard__channels-headline" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.channelsHeadline) + "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.channels.length > 0
                ? _c(
                    "ul",
                    {
                      staticClass: "c-tvcard__channels",
                      class: [
                        _vm.channelsWide ? "c-tvcard__channels--wide" : ""
                      ]
                    },
                    [
                      _vm._l(_vm.channels, function(channel, idx) {
                        return _c("li", { key: idx }, [
                          _c("img", {
                            attrs: { src: channel.item1, alt: channel.item2 }
                          })
                        ])
                      }),
                      _vm._v(" "),
                      _vm.description
                        ? _c(
                            "li",
                            { staticClass: "c-tvcard__channels--remaining" },
                            [
                              _vm._v(
                                "\n          +" +
                                  _vm._s(
                                    _vm.description.substring(0, 2) -
                                      _vm.channelsToDisplay
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }