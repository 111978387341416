/**
 * OneBlock Client
 * @module oneblock-client.mjs
 * @version 1.0.20
 * @summary 23-02-2020
 * @author Mads Stoumann
 * @description Code for running OneBlocks client-side.
 */

/* Import Sections */
import * as Section from './oneblock-sections.mjs';

/* Import Widgets */
import * as Widget from './oneblock-widgets.mjs';

/* Import Custom */
import { oneBlockCustom } from './oneblock-custom.mjs';

/**
 * @function observeIntersections
 * @description Watches classes with transitions, triggers animation-play-state when in viewPort
 * @param {String} selector
 * TODO: Maybe narrow IO: if (entry.boundingClientRect.bottom >= entry.intersectionRect.bottom) { ... }
 */
function observeIntersections(elements) {
	/* Check if an array or `array of arrays` is empty */
	let isEmpty = a => {return Array.isArray(a) && a.every(isEmpty)};
	const io = new IntersectionObserver((entries) => {	
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				const ob = entry.target.ob;
				const animation = ob.animations[ob.index];
				if (animation.length > 0) {
					animation.forEach(item => {
						entry.target.classList.add(item);
					});
					ob.animations[ob.index] = [];
					/* If no animations left (for any breakpoint), unobserve */
					if (isEmpty(ob.animations)) {
						io.unobserve(entry.target);
					}
				}
			}
		},
		{
			threshold: [0.5]
		});
	});

	elements.forEach(ob => {
		io.observe(ob);
	});
}

/**
 * @function observeResize
 * @description Creates an `ob`-object per OneBlock, adds ResizeObserver, IntersectionObserver etc.
 * @param {String} selector
 * @param {Boolean} initClasses Set initial classes for elements with only one breakpoint
 */
export function observeResize(selector = 'c-ob', initClasses = false) {
	const elements = document.querySelectorAll(`.${selector}[data-ob-ps]`);
	let intersections = [];

	/* Get current breakpoint-index for a given element */
	const getBreakpointIndex = (obj, width) => {
		let index = 0;
		if (width >= obj.max) {
			index = obj.last;
		}
		else if (width >= obj.min) {
			index = obj.breakpoints.findIndex(item => {return width < item}) - 1;
		}
		return index;
	}

	/* Use device-widths / classic RWD for breakpoints */
	const deviceRWD = (element) => {
		const updateBP = (breakpoint) => {
			try { 
				if (breakpoint.matches) {
					let [media, query] = breakpoint.media.match('min-width:(.*)px'); // eslint-disable-line no-unused-vars
					query = parseInt(query, 10);
					const index = element.ob.breakpoints.findIndex(item => {return query === item}) || 0;
					element.className = `${selector} ${element.ob.values[index]}`;
				}
			}
			catch(err){
				console.log(err); // eslint-disable-line
			}
		}

		let media = [];
		element.ob.breakpoints.forEach((breakpoint, index) => {
			if (index === element.ob.breakpoints.length - 1) {
				media.push(window.matchMedia(`(min-width:${breakpoint}px)`));
			}
			else {
				media.push(window.matchMedia(`(min-width:${breakpoint}px) and (max-width: ${element.ob.breakpoints[index + 1]}px)`));
			}
		});

		media.forEach(breakpoint => {
			return breakpoint.addListener(updateBP.bind(breakpoint));
		});

		/* Iterate breakpoints to find closest initial match */
		media.forEach((breakpoint) => {
			if (breakpoint.matches) {
				updateBP(breakpoint);
			}
		});
	}

	if (elements) {
		const ro = new ResizeObserver( entries => {
			for (let entry of entries) {
				const index = getBreakpointIndex(entry.target.ob, entry.contentRect.width);
				if (index !== entry.target.ob.index) {
					entry.target.ob.index = index;
					entry.target.className = `${selector} ${entry.target.ob.values[index]}`;
				}
			}
		});

		elements.forEach(element => {
			const data = element.dataset && element.dataset.obPs;
			if (!data) {
				return false;
			}
			const preset = JSON.parse(decodeURIComponent(data));
			const last = preset.breakpoints.length - 1;
			const max = preset.breakpoints[last];
			const min = preset.breakpoints[1] || 0;
			delete element.dataset.obPs;

			let animations = [];
			let deviceWidth = false;
			let hasAnimations = false;
			let values = [];

			preset.values.forEach((arr) => {
				let ani = [];
				let val = [];
				arr.forEach(item => {
					if (item.includes('E-ts')) {
						hasAnimations = true;
						ani.push(item.replace(/E-ts/g, 'ts'))
					}
					else {
						val.push(item)
					}
					if (item.includes('-O-dw')) {
						deviceWidth = true;
					}
				})
				animations.push(ani);
				values.push(val.join(' '));
			})

			element.ob = {
				animations,
				breakpoints: preset.breakpoints,
				values,
				index: 0,
				last,
				max,
				min,
				splash: preset.splash || {}
			}

			if (hasAnimations && !deviceWidth) {
				intersections.push(element);
			}

			if (last === 0 && initClasses) {
				element.className = `${selector} ${element.ob.values[0]}`
			}

			if (last > 0) {
				element.ob.index = -1;
				if (deviceWidth) {
					deviceRWD(element);
				} else {
					ro.observe(element);
				}
			}
		});

		if (intersections.length > 0) {
			observeIntersections(intersections);
		}
	}
}

/**
 * @function renderSplash
 * @description Client-side rendering of splashes
 */
export function renderSplash() {
	const splashes = document.querySelectorAll('.c-ob__splash');
	splashes.forEach(splash => {
		const parent = splash.closest('.c-ob');
		if (parent) {
			const file = splash.dataset.splashFile;
			const lines = splash.dataset.splashLines.split('|');
			const data = parent.ob.splash;

			splash.innerHTML = `
			<svg viewBox="0 0 100 100">
				<use class="c-ob__splash-elm" xlink:href="${file}#${data.name}" />
				${lines.map((item, index) => {
					return `<text x="${data.content[index].x || 50}" y="${data.content[index].y || 50}" text-anchor="middle" class="c-ob__splash-line-${index + 1}">${item}</text>`
				}).join('')}
			</svg>`
		}
	})
}

/**
 * @function setCustom
 * @param { Boolean } editMode
 * @description Runs custom client-code
 */
export function setCustom(editMode) {
	oneBlockCustom(editMode);
}

/**
 * @function setJSHooks
 * @param {String} selector
 * @description Adds eventListeners to OneBlock-links with data-js-hooks-attributes
 */
export function setJSHooks(selector = '[data-js]') {
	const elements = document.querySelectorAll(selector);
	if (elements) {
		elements.forEach(element => {
			/* Test if widgets have been loaded and if method exists */
			if (Widget[element.dataset.js]) {
				const func = Widget[element.dataset.js];
				const param = element.dataset.jsParam.split(',') || [];
				const event = element.dataset.jsEvent;
				if (func) {
					if (event) {
						switch(event) {
							case 'click':
								element.addEventListener('click', (evt) => {return func(evt, element, ...param)});
								break;
							case 'load':
								func(event, element, ...param);
								// window.addEventListener('DOMContentLoaded', (evt) => {return func(evt, element, ...param)});
								break;
							default: break;
						}
					}
					else {
						func(element, ...param);
					}
				}
			}
		});
	}
}

/**
 * @function setSectionTypes
 * @param {String} selector
 * @description Adds Section Types (carousels, tabs etc.) for OneBlock
 */
export function setSectionTypes(selector = '[data-section-type]') {
	const sections = document.querySelectorAll(selector);
	sections.forEach(section => {
		if (Section[section.dataset.sectionType]) {
			new Section[section.dataset.sectionType](section, section.dataset);
		}
	})
}

/**
 * @function setVideoPlay
 * @param {String} selector
 * @description Adds click-handlers for video-overlays
 */
export function setVideoPlay(
	selector = '.c-ob__bg-play',
	hideClass = 'c-ob__bg-video'
) {
	const overlays = document.querySelectorAll(selector);
	overlays.forEach(overlay => {
		const wrapper = overlay.parentNode;
		const video = wrapper.querySelector(`[itemprop="video"]`);
		const metaTags = wrapper.querySelectorAll('meta');
		const videoType = video.tagName === 'VIDEO' ? 1 : 2;
		const thumbnailUrl = Widget.resolveMetaTagContent(metaTags, 'thumbnailUrl');

		// Try to resolve maximum resolution cover for Youtube posters
		if (videoType === 2) {
			const youtubeId = video.getAttribute('youtubeId');
			if (thumbnailUrl.includes('hqdefault')) {
				const img = document.createElement('img');
				img.src = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
				// Load image
				img.onload = function () {
					// Validate dimensions of loaded image
					if (this.height > 90 && this.width > 120) {
						// Replace if loaded image has larger dimensions than Youtube fallback image
						wrapper.querySelector('[itemprop="image"]').src = img.src;
					}
				};
			}
		}

		return overlay.addEventListener('click', async event => {
			wrapper.classList.add(hideClass);
			/* <video> */
			if (videoType === 1) {
				video.play();
				video.addEventListener('ended', function videoEnded() {
					wrapper.classList.remove(hideClass);
					video.removeEventListener('ended', videoEnded);
				});
			} else {
				const name = Widget.resolveMetaTagContent(metaTags, 'name');
				const description = Widget.resolveMetaTagContent(
					metaTags,
					'description'
				);
				const uploadDate = Widget.resolveMetaTagContent(metaTags, 'uploadDate');
				let videoSrc = video.getAttribute('src');
				// /* <iframe> */
				let delimiter = '?';
				if (/\?/.test(videoSrc)) {
					delimiter = '&';
				}
				videoSrc = videoSrc += delimiter + 'autoplay=1';
				video.innerHTML = `<iframe
					class="c-ob__bg"
					itemprop="video"
					src="${videoSrc}"
					tabindex="-1"
					allow="autoplay; encrypted-media"
					allowfullscreen="allowfullscreen"
					frameborder="0"
					name="${name}"
					description="${description}"
					thumbnailUrl="${thumbnailUrl}"
					uploadDate="${uploadDate}"
				</iframe>`;
			}
		});
	});
}
