var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shopping-cart-group" },
    [
      _vm._l(_vm.items, function(item, itemIndex) {
        return _c("shopping-cart-item", {
          key: itemIndex,
          attrs: {
            item: item,
            index: itemIndex,
            "installment-for-months-text-template":
              _vm.contentLocal.installmentForMonthsTextTemplate,
            "binding-period-text": _vm.contentLocal.bindingPeriodText,
            "is-bundle": _vm.isBundle,
            "promotion-code-applied-label":
              _vm.contentLocal.promotionCodeAppliedLabel
          },
          on: { "basket-item-action": _vm.handleItemAction }
        })
      }),
      _vm._v(" "),
      _vm.group.offerings.length
        ? _c(
            "div",
            { staticClass: "shopping-cart__offering-actions" },
            _vm._l(_vm.offerings, function(offering, offeringIndex) {
              return _c("basket-offering", {
                key: offeringIndex,
                attrs: { offering: offering, group: _vm.group },
                on: { activate: _vm.handleOfferingClick }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("c-modal", { ref: "removeMasterModal" }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c("h2", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.contentLocal.removeMasterSubscriptionModalHeading) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.contentLocal.removeMasterSubscriptionModalBodyText) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("c-button", { on: { click: _vm.closeRemoveMasterModal } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.contentLocal
                      .removeMasterSubscriptionModalCancelButtonLabel
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("c-button", { on: { click: _vm.removeMaster } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.contentLocal
                      .removeMasterSubscriptionModalAcceptButtonLabel
                  ) +
                  "\n      "
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          ref: "removeProlongingGroupModal",
          on: { close: _vm.clearRemoveProlongingGroupCache }
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.contentLocal.removeProlongingSubscriptionModalDescription
              )
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "shopping-cart-group__remove-actions" }, [
            _c(
              "button",
              {
                staticClass: "c-btn c-btn--primary c-btn--wide mb-1",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.acceptRemoveProlongingGroup($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.contentLocal
                        .removeProlongingSubscriptionModalAcceptButtonText
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "c-btn c-btn--as-link c-btn--wide",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.declineRemoveProlongingGroup($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.contentLocal
                        .removeProlongingSubscriptionModalDeclineButtonText
                    ) +
                    "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }