var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.products.length > 0
    ? _c(
        "section",
        { staticClass: "c-product-ribbon" },
        [
          _c("c-carousel", {
            attrs: {
              slot: "carousel",
              "page-dots": true,
              data: _vm.products,
              "smooth-swiping": true,
              "items-per-slide": 3,
              "rounded-navigation-buttons": true,
              "navigation-position-fixed": true,
              draggable: true,
              "multi-slider-speed": 500
            },
            slot: "carousel",
            scopedSlots: _vm._u(
              [
                {
                  key: "slide",
                  fn: function(ref) {
                    var data = ref.data
                    return [
                      _c(
                        "c-product-card",
                        _vm._b(
                          {
                            staticClass:
                              "c-product-ribbon__item c-product-card--ribbon-version"
                          },
                          "c-product-card",
                          Object.assign({}, data, {
                            content: _vm.content,
                            meta: _vm.meta,
                            metaDetailsHidden: _vm.metaDetailsHidden
                          }),
                          false
                        )
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              995449671
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }