var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-map", style: _vm.calcMapHeight }, [
    _c("div", {
      ref: "mapContainer",
      staticClass: "c-map__map c-map__map--large"
    }),
    _vm._v(" "),
    _c("article", { ref: "storeInfo", staticClass: "c-map__store-info" }, [
      _c("h5", { staticClass: "c-typography-h5 c-map__title" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "section",
          [
            _c(
              "h4",
              { staticClass: "fs__list-item-headline--md pb-1 text-bold" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.openingHoursHeadline) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.openingHours, function(entry, i) {
              return _c(
                "dl",
                {
                  key: i,
                  staticClass:
                    "\n            fs__list-item-hours\n            fs__list-item-hours--line-below\n            fs__list-item-hours--spacing\n          "
                },
                [
                  _c("dt", [_vm._v(_vm._s(entry.key))]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(entry.value))])
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        Array.isArray(_vm.specialHours) && _vm.specialHours.length > 0
          ? _c(
              "section",
              [
                _c(
                  "h6",
                  { staticClass: "fs__list-item-headline--sm pb-1 text-bold" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.specialHoursHeadline) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.specialHours, function(entry, i) {
                  return _c(
                    "dl",
                    {
                      key: i,
                      staticClass:
                        "\n            fs__list-item-hours\n            fs__list-item-hours--line-below\n            fs__list-item-hours--spacing\n          "
                    },
                    [
                      _c("dt", [_vm._v(_vm._s(entry.key))]),
                      _vm._v(" "),
                      _c("dd", [_vm._v(_vm._s(entry.value))])
                    ]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("section", {
        ref: "mapContainerSmall",
        staticClass: "c-map__map c-map__map--small"
      }),
      _vm._v(" "),
      _c("section", [
        _c("h4", { staticClass: "fs__list-item-headline--md pb-1 text-bold" }, [
          _vm._v("\n        " + _vm._s(_vm.contactInfoHeadline) + "\n      ")
        ]),
        _vm._v(" "),
        _c("address", { staticClass: "c-map__address mb-3" }, [
          _c("span", [_vm._v(_vm._s(_vm.streetAndNumber))]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.zipCode))]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.city))])
        ]),
        _vm._v(" "),
        _c("address", { staticClass: "c-map__contact-info" }, [
          _vm.phoneNumber
            ? _c("p", { staticClass: "c-map__contact-info-item" }, [
                _c(
                  "svg",
                  {
                    staticClass: "c-map__contact-icon",
                    attrs: { xlink: "http://www.w3.org/2000/svg" }
                  },
                  [_c("use", { attrs: { "xlink:href": _vm.phoneIconPath } })]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "fs__list-item-option fs__list-item-option--gap-left",
                    attrs: { href: "tel:" + _vm.phoneNumber, target: "_blank" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.phoneNumber) +
                        "\n          "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.email
            ? _c(
                "p",
                { staticClass: "fs--padding-top-sm c-map__contact-info-item" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "c-map__contact-icon",
                      attrs: { xlink: "http://www.w3.org/2000/svg" }
                    },
                    [_c("use", { attrs: { "xlink:href": _vm.emailIconPath } })]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "fs__list-item-option fs__list-item-option--gap-left",
                      attrs: { href: "mailto:" + _vm.email, target: "_blank" }
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.email) + "\n          "
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "fs--padding-top-sm c-map__contact-info-item" },
            [
              _c(
                "svg",
                {
                  staticClass: "c-map__contact-icon",
                  attrs: { xlink: "http://www.w3.org/2000/svg" }
                },
                [_c("use", { attrs: { "xlink:href": _vm.directionsIconPath } })]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "fs__list-item-option fs__list-item-option--gap-left",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.directionsLinkClickHandler($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.directionsLinkLabel) +
                      "\n          "
                  )
                ]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }