/**
 * @function observeAnimations
 * @param {NodeList} selector
 * @description Observes sections with animations
 */
export function observeAnimations(
  selector = document.querySelectorAll(
    '[data-animation]:not([data-animation="none"])'
  ),
  itemSelector = '.c-section__item'
) {
  const io = new IntersectionObserver(entries => {
    entries.forEach(
      entry => {
        if (entry.isIntersecting) {
          const section = entry.target;
          const animation = section.dataset.animation;
          const target = section.dataset.animationTarget;
          if (target === 'items' || target === 'both') {
            const items = section.querySelectorAll(itemSelector);
            items.forEach(item => {
              item.classList.add(animation);
            });
            if (target === 'section' || target === 'both') {
              section.classList.add(animation);
            }
          } else {
            section.classList.add(animation);
          }
          io.unobserve(entry.target);
        }
      },
      {
        threshold: [0.5]
      }
    );
  });

  selector.forEach(section => {
    io.observe(section);
  });
}
