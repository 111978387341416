var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classObject }, [
    _c("span", {
      ref: "activityIndicator",
      staticClass: "activity-indicator__animation"
    }),
    _vm._v(" "),
    _c("p", [_c("strong", [_vm._v(_vm._s(_vm.label))])])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }