var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.priceRises && _vm.priceRises.length
        ? [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.contentLocal.riseHeader))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "c-vas-substitutes" },
              _vm._l(_vm.priceRises, function(vas, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "c-vas-substitutes__item" },
                  [
                    _c("c-vas-card-substitute", {
                      attrs: { vas: vas.variants[0], current: "" }
                    }),
                    _vm._v(" "),
                    _c("c-icon", { attrs: { "symbol-id": "arrow-right" } }),
                    _vm._v(" "),
                    _c("c-vas-card-substitute", {
                      attrs: { vas: vas.variants[0].substitute }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.priceDrops && _vm.priceDrops.length
        ? [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.contentLocal.dropHeader))])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "c-vas-substitutes" },
              _vm._l(_vm.priceDrops, function(vas, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "c-vas-substitutes__item" },
                  [
                    _c("c-vas-card-substitute", {
                      attrs: { vas: vas.variants[0], current: "" }
                    }),
                    _vm._v(" "),
                    _c("c-icon", { attrs: { "symbol-id": "arrow-right" } }),
                    _vm._v(" "),
                    _c("c-vas-card-substitute", {
                      attrs: { vas: vas.variants[0].substitute }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.removables && _vm.removables.length
        ? [
            _c("p", [
              _c("strong", [_vm._v(_vm._s(_vm.contentLocal.removablesHeader))])
            ]),
            _vm._v(" "),
            _vm.showInsuranceDisclaimer
              ? _c("p", { staticClass: "c-vas-substitutes__disclaimer" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.contentLocal.insuranceDisclaimer) +
                      "\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "c-vas-substitutes c-vas-substitutes--list" },
              _vm._l(_vm.removables, function(vas, index) {
                return _c("c-vas-card-substitute", {
                  key: index,
                  attrs: { vas: vas.variants[0] }
                })
              }),
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }