import { isUndefined, isNull } from '../utils/inspect.js';

/**
 * todo: write description
 * 
 */
export default {
  model: {
    prop: 'value',
    event: 'update'
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    number: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localValue: this.stringifyValue(this.value)
    };
  },

  watch: {
    value(v) {
      if (v !== this.localValue) {
        this.localValue = this.stringifyValue(v);
      }
    }
  },

  methods: {
    stringifyValue(value) {
      return isUndefined(value) || isNull(value) ? '' : String(value);
    },

    updateValue(value) {
      value = this.stringifyValue(value);

      if (value !== this.localValue) {
        this.localValue = value;
        if (this.number) {
          const num = parseFloat(value);
          value = isNaN(num) ? value : num;
        } else if (this.trim) {
          value = value.trim();
        }
        this.$emit('update', value);
      } else if (this.$refs.input && value !== this.$refs.input.value) {
        this.$refs.input.value = value;
      }
    },
    onInput(event) {
      if (event.target.composing) {
        return;
      }

      if (event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.updateValue(event.target.value);
      this.$emit('input', this.localValue);
    },
    onChange(event) {
      if (event.target.composing) {
        return;
      }

      if (event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.updateValue(event.target.value);
      this.$emit('change', this.localValue);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    focus() {
      if (!this.disabled) {
        this.$el.focus();
      }
    },
    blur() {
      if (!this.disabled) {
        this.$el.blur();
      }
    }
  },
}