<template>
  <div
    class="cm-c-countdown"
    :class="{ 'cm-c-countdown--expired': !isEnabled }"
  >
    <h3 class="cm-c-countdown__title">
      {{ title }}
    </h3>

    <div class="cm-c-countdown__watch">
      <div class="cm-c-countdown__number-label-container">
        <div class="cm-c-countdown__number-container">
          <span
            class="cm-c-countdown__number"
            :class="{ '-animating': animateDays }"
          >
            {{ days }}
          </span>

          <span class="cm-c-countdown__number">
            {{ nextDays }}
          </span>
        </div>
        <label class="cm-c-countdown__label">
          {{ daysLabel }}
        </label>
      </div>

      <div class="cm-c-countdown__separator"></div>

      <div class="cm-c-countdown__number-label-container">
        <div class="cm-c-countdown__number-container">
          <span
            class="cm-c-countdown__number"
            :class="{ '-animating': animateHours }"
          >
            {{ hours }}
          </span>

          <span class="cm-c-countdown__number">
            {{ nextHours }}
          </span>
        </div>
        <label class="cm-c-countdown__label">
          {{ hoursLabel }}
        </label>
      </div>

      <div class="cm-c-countdown__separator"></div>

      <div class="cm-c-countdown__number-label-container">
        <div class="cm-c-countdown__number-container">
          <span
            class="cm-c-countdown__number"
            :class="{ '-animating': animateMinutes }"
          >
            {{ minutes }}
          </span>

          <span class="cm-c-countdown__number">
            {{ nextMinutes }}
          </span>
        </div>
        <label class="cm-c-countdown__label">
          {{ minutesLabel }}
        </label>
      </div>
      <div class="cm-c-countdown__separator"></div>

      <div class="cm-c-countdown__number-label-container">
        <div class="cm-c-countdown__number-container">
          <span
            class="cm-c-countdown__number"
            :class="{ '-animating': animateSeconds }"
          >
            {{ seconds }}
          </span>

          <span class="cm-c-countdown__number">
            {{ nextSeconds }}
          </span>
        </div>
        <label class="cm-c-countdown__label">
          {{ secondsLabel }}
        </label>
      </div>
    </div>

    <parallax-wrapper
      v-if="showParallaxBackground"
      :direction="-1"
      axis="y"
      :speed="0.08"
      class="cm-c-countdown__background-wrapper"
    >
      <span
        class="cm-c-countdown__background"
        :style="backgroundImageStyle"
        data-testid="countdown-background"
      ></span>
    </parallax-wrapper>
  </div>
</template>

<script>
import ParallaxWrapper from '../../../generic/parallax-wrapper.vue';

export default {
  name: 'countdown',

  components: { ParallaxWrapper },

  props: ['data'],

  data() {
    return this.data
      ? {
          milisecondsToEnd: this.data.endDateTime * 1000 - new Date(),
          timeLeft: this.calcTimeLeft(
            this.data.endDateTime * 1000 - new Date()
          ),
          timeLeftNextTick: this.calcTimeLeft(
            this.data.endDateTime * 1000 - new Date()
          ),
          timer: null,
          animateSeconds: false,
          animateMinutes: false,
          animateHours: false,
          animateDays: false
        }
      : {};
  },

  computed: {
    isEnabled() {
      return this.data
        ? !!this.data.isEnabled && this.milisecondsToEnd >= 0
        : false;
    },
    end() {
      return this.data ? new Date(this.data.endDateTime * 1000) : null;
    },
    beginning() {
      return this.isEnabled ? new Date(this.data.startDateTime * 1000) : null;
    },
    title() {
      return (
        this.data && (this.isEnabled ? this.data.title : this.data.endTitle)
      );
    },
    daysLabel() {
      return this.data ? this.data.days : 'Dage';
    },
    days() {
      return this.timeLeft ? this.formatNumber(this.timeLeft.days) : null;
    },
    nextDays() {
      return this.timeLeftNextTick
        ? this.formatNumber(this.timeLeftNextTick.days)
        : null;
    },

    hoursLabel() {
      return this.data ? this.data.hours : 'Timer';
    },
    hours() {
      return this.timeLeft ? this.formatNumber(this.timeLeft.hours) : null;
    },
    nextHours() {
      return this.timeLeftNextTick
        ? this.formatNumber(this.timeLeftNextTick.hours)
        : null;
    },
    minutesLabel() {
      return this.data ? this.data.minutes : 'Min.';
    },
    minutes() {
      return this.timeLeft ? this.formatNumber(this.timeLeft.minutes) : null;
    },
    nextMinutes() {
      return this.timeLeftNextTick
        ? this.formatNumber(this.timeLeftNextTick.minutes)
        : null;
    },

    secondsLabel() {
      return this.data ? this.data.seconds : 'Sek.';
    },
    seconds() {
      return this.timeLeft ? this.formatNumber(this.timeLeft.seconds) : null;
    },
    nextSeconds() {
      return this.timeLeftNextTick
        ? this.formatNumber(this.timeLeftNextTick.seconds)
        : null;
    },
    showParallaxBackground() {
      return this.data && !!this.data.backgroundImage && this.isEnabled;
    },
    backgroundImageStyle() {
      return this.data.backgroundImage
        ? 'background-image: url(' + this.data.backgroundImage + ');'
        : '';
    }
  },

  watch: {
    end(newEndDate, oldEndDate) {
      if (!newEndDate || newEndDate.getTime() !== oldEndDate.getTime()) {
        clearInterval(this.timer);
        this.resetTimer();
        this.timer = setInterval(this.tick, 1000);
      }
    },

    milisecondsToEnd(milisecondsToEnd) {
      if (milisecondsToEnd <= 0) {
        this.$emit('countdownEnded');
        clearInterval(this.timer);
      }
    }
  },

  created() {
    this.timer = setInterval(this.tick, 1000);
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    getEmptyTimeStructure() {
      return {
        miliseconds: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        secondsChanged: false,
        minutesChanged: false,
        hoursChanged: false,
        daysChanged: false
      };
    },
    calcTimeLeft(milisecondsToEnd) {
      const res = this.getEmptyTimeStructure();
      res.miliseconds = milisecondsToEnd;

      if (this.isEnabled) {
        const dtDays = milisecondsToEnd / 86400000;
        res.days = Math.floor(dtDays);
        const dtHours = (dtDays % 1) * 24;
        res.hours = Math.floor(dtHours);
        const dtMinutes = (dtHours % 1) * 60;
        res.minutes = Math.floor(dtMinutes);
        const dtSeconds = (dtMinutes % 1) * 60;
        res.seconds = Math.floor(dtSeconds);
      }
      return res;
    },
    updateTimeLeft() {
      this.animateSeconds = false;
      this.animateMinutes = false;
      this.animateHours = false;
      this.animateDays = false;
      this.timeLeft = this.timeLeftNextTick;
    },
    tick() {
      this.milisecondsToEnd -= 1000;
      const t = this.calcTimeLeft(this.milisecondsToEnd);
      t.secondsChanged = t.seconds !== this.timeLeft.seconds;
      t.minutesChanged = t.minutes !== this.timeLeft.minutes;
      t.hoursChanged = t.hours !== this.timeLeft.hours;
      t.daysChanged = t.days !== this.timeLeft.days;
      if (t.secondsChanged) {
        this.initiateAnimation(t);
      }
    },
    initiateAnimation(t) {
      this.timeLeftNextTick = t;
      this.animateSeconds = t.secondsChanged;
      this.animateMinutes = t.minutesChanged;
      this.animateHours = t.hoursChanged;
      this.animateDays = t.daysChanged;
      setTimeout(this.updateTimeLeft, 900);
    },
    formatNumber(n) {
      if (n < 0) {
        return '00';
      } else if (n < 10) {
        return `0${n}`;
      }

      return `${n}`;
    },
    resetTimer() {
      this.milisecondsToEnd = this.end - new Date();
      this.timeLeft = this.calcTimeLeft(this.milisecondsToEnd);
      this.timeLeftNextTick = this.timeLeft;
    }
  }
};
</script>
