import { get } from './api.js';

export const getProductVariantByCode = (
  productCode,
  subscriptionCode,
  paymentPlanCode,
  vasCodes = [],
  existingmsisdn
) => {
  return get(`commerce/catalog/products/variant/${productCode}`, {
    params: {
      subscriptionId: encodeURIComponent(subscriptionCode),
      installment: paymentPlanCode,
      services: vasCodes,
      existingmsisdn: existingmsisdn
    }
  }).then(res => res.data);
};

export const getProductRelations = code => {
  return get(`commerce/catalog/categories`, {
    params: {
      code: code
    }
  }).then(res => {
    return res.data;
  });
};
