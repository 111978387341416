var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ribbon-banner", class: _vm.getClass }, [
    _c("div", { staticClass: "ribbon-banner__angle" }),
    _vm._v(" "),
    _c("div", { staticClass: "ribbon-banner__text" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }