var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass: "c-employee-contact-info",
      attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h3", { staticClass: "c-employee-contact-info__contact__headline" }, [
        _vm._v("\n    " + _vm._s(_vm.content.headline) + "\n  ")
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "c-employee-contact-info__contact__description",
        domProps: { innerHTML: _vm._s(_vm.content.description) }
      }),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "mb-4" },
        [
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", sm: "12" } },
            [
              _c("c-input-group", {
                attrs: {
                  name: "number",
                  "place-holder-label": _vm.content.telephoneNumberLabel,
                  type: "number",
                  readonly: _vm.form.phoneNumber.isReadOnly
                },
                on: {
                  change: function($event) {
                    return _vm.setFormData(_vm.filteredFormData)
                  }
                },
                model: {
                  value: _vm.$v.form.phoneNumber.value.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.form.phoneNumber.value, "$model", $$v)
                  },
                  expression: "$v.form.phoneNumber.value.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "cm-c-form__col", attrs: { cols: "6", sm: "12" } },
            [
              _c("c-input-group", {
                attrs: {
                  name: "email",
                  "place-holder-label": _vm.content.emailLabel,
                  type: "email",
                  readonly: _vm.form.email.isReadOnly
                },
                on: {
                  change: function($event) {
                    return _vm.setFormData(_vm.filteredFormData)
                  }
                },
                model: {
                  value: _vm.$v.form.email.value.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.form.email.value, "$model", $$v)
                  },
                  expression: "$v.form.email.value.$model"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-4" },
        [
          _c("h3", { staticClass: "cm-c-form__header" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.content.installationAddressTitle) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row cm-c-form__row--extra-spacing" },
            [
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "6", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "firstName",
                      "place-holder-label": _vm.content.firstNameLabel,
                      readonly: _vm.form.firstName.isReadOnly
                    },
                    on: {
                      change: function($event) {
                        return _vm.setFormData(_vm.filteredFormData)
                      }
                    },
                    model: {
                      value: _vm.$v.form.firstName.value.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.firstName.value, "$model", $$v)
                      },
                      expression: "$v.form.firstName.value.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "6", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "lastName",
                      "place-holder-label": _vm.content.lastNameLabel,
                      readonly: _vm.form.lastName.isReadOnly
                    },
                    on: {
                      change: function($event) {
                        return _vm.setFormData(_vm.filteredFormData)
                      }
                    },
                    model: {
                      value: _vm.$v.form.lastName.value.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.form.lastName.value, "$model", $$v)
                      },
                      expression: "$v.form.lastName.value.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { attrs: { cols: "12" } },
                [
                  _vm.employeeData.address &&
                  _vm.employeeData.address.isReadOnly
                    ? _c("c-input-group", {
                        attrs: {
                          name: "address",
                          "place-holder-label": _vm.content.addressSearchLabel,
                          readonly: ""
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address"
                        }
                      })
                    : _c("address-search", {
                        attrs: {
                          content: {
                            searchInputPlaceholder:
                              _vm.content.addressSearchLabel,
                            searchString: _vm.prefilledAddress
                          },
                          "is-block": false
                        },
                        on: { select: _vm.setAddress }
                      })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { attrs: { cols: "12" } },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "street",
                      "place-holder-label": _vm.content.addressStreetNameLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.street,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "street", $$v)
                      },
                      expression: "form.street"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "4", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "houseNumber",
                      "place-holder-label": _vm.content.addressHouseNumberLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.houseNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "houseNumber", $$v)
                      },
                      expression: "form.houseNumber"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "8", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "houseLetter",
                      "place-holder-label": _vm.content.addressHouseLetterLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.houseLetter,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "houseLetter", $$v)
                      },
                      expression: "form.houseLetter"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "4", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "floor",
                      "place-holder-label": _vm.content.addressFloorLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.floor,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "floor", $$v)
                      },
                      expression: "form.floor"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "8", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "Apartment",
                      "place-holder-label": _vm.content.addressApartmentLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.apartment,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "apartment", $$v)
                      },
                      expression: "form.apartment"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "4", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "zipcode",
                      "place-holder-label": _vm.content.addressZipCodeLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.zipCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "zipCode", $$v)
                      },
                      expression: "form.zipCode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-c-form__col",
                  attrs: { cols: "8", sm: "12" }
                },
                [
                  _c("c-input-group", {
                    attrs: {
                      name: "city",
                      "place-holder-label": _vm.content.addressCityLabel,
                      disabled: ""
                    },
                    model: {
                      value: _vm.form.city,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "c-employee-contact-info__disclaimer",
        domProps: { innerHTML: _vm._s(_vm.content.footer) }
      }),
      _vm._v(" "),
      _vm.content.validationErrorModalText
        ? _c("home-office-validation-modal", {
            attrs: {
              title: _vm.content.validationErrorModalText.heading,
              "error-message": _vm.content.validationErrorModalText.content,
              visible: _vm.showCheckoutError
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }