<template>
  <section v-if="products.length > 0" class="c-product-ribbon">
    <c-carousel
      slot="carousel"
      :page-dots="true"
      :data="products"
      :smooth-swiping="true"
      :items-per-slide="3"
      :rounded-navigation-buttons="true"
      :navigation-position-fixed="true"
      :draggable="true"
      :multi-slider-speed="500"
    >
      <template slot="slide" slot-scope="{ data }">
        <c-product-card
          class="c-product-ribbon__item c-product-card--ribbon-version"
          v-bind="{
            ...data,
            content,
            meta,
            metaDetailsHidden
          }"
        />
      </template>
    </c-carousel>
  </section>
</template>

<script>
import { post } from 'axios';
import { CCarousel } from 'olympus/components';
import { mapGetters } from 'vuex';

export default {
  name: 'CProductRibbon',
  components: {
    'c-carousel': CCarousel
  },
  props: {
    searchUrl: {
      type: String,
      default: ''
    },
    blockId: {
      type: Number,
      default: 0
    },
    culture: {
      type: String,
      default: ''
    },
    queryForTracking: {
      type: String,
      default: ''
    },
    meta: {
      type: Array,
      default: () => []
    },
    metaDetailsHidden: {
      type: Boolean,
      default: false
    },
    pricePlanFilterValue: {
      type: String,
      default: '0'
    },
    content: {
      type: Object,
      default() {
        return {
          actionButtonLabel: ''
        };
      }
    }
  },
  data() {
    return {
      products: []
    };
  },
  computed: {
    ...mapGetters(['contextId'])
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      const {
        $props: { searchUrl, ...body }
      } = this;

      const {
        data: { hits }
      } = await post(`${searchUrl}?contextid=${this.contextId}`, body);

      this.products = this.flattenProducts(hits);
    },
    flattenProducts(products) {
      return products.map(({ availableColors, ...product }) => {
        const defaultBadgeAndRibbon = availableColors.find(({ variants }) =>
          variants.find(variant => variant.isDefaultVariant)
        );
        const { badgeText, ribbonText } = defaultBadgeAndRibbon?.variants[0];

        return {
          ...product,
          variants: availableColors.map(({ hexCode }) => hexCode),
          badgeText,
          ribbonText
        };
      });
    }
  }
};
</script>
