<template>
  <span>{{ countDown }}</span>
</template>
<script>
/**
 * A basic component that shows countdown of number remaining by each second.
 * It should be used instead of countdown.vue if only number is shown for countdown.
 * e.g. it can be used for showing seconds remaining before automatic redirect
 */
export default {
  name: 'c-number-countdown',

  props: {
    timerCount: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      countDown: this.timerCount
    };
  },
  watch: {
    countDown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countDown--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  }
};
</script>
