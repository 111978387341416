var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-search-results" }, [
    _c("div", { staticClass: "c-search-results__header" }, [
      _c("div", { staticClass: "c-wrapper" }, [
        _c("div", { staticClass: "c-search-results__header-inner" }, [
          _c("div", { staticClass: "epi-form-text" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchString,
                  expression: "searchString"
                }
              ],
              ref: "input",
              staticClass: "epi-form-text__input c-search-results__input",
              attrs: { id: "c-search-results", type: "text", placeholder: " " },
              domProps: { value: _vm.searchString },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.inputKeyupEnter($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchString = $event.target.value
                }
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.inputKeyupEnter($event)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "epi-form-text__label",
                attrs: { for: "c-search-results" }
              },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.placeholder) + "\n          "
                )
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "epi-form-text__input-ui" }, [
              _c(
                "span",
                { on: { click: _vm.clearInput } },
                [
                  !_vm.searchString ? _vm._t("input-icon-search") : _vm._e(),
                  _vm._v(" "),
                  _vm.searchString ? _vm._t("input-icon-clear") : _vm._e()
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c-search-results__term" }, [
            !_vm.searchResults.loading
              ? _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.termSentence) +
                      "\n\n            "
                  ),
                  _vm.segments.length > 1
                    ? _c(
                        "span",
                        { staticClass: "c-search-results__term-dropdown" },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.segment,
                                  expression: "segment"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.segment = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.segments, function(item) {
                              return _c(
                                "option",
                                { key: item.id, domProps: { value: item.id } },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.networking
      ? _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" }, [
          _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.searchResults.categories.length > 0
      ? _c("div", { staticClass: "c-search-results__body" }, [
          _c("div", { staticClass: "c-search-results__tabs" }, [
            _c("div", { staticClass: "c-wrapper" }, [
              _c(
                "ul",
                _vm._l(_vm.searchResults.categories, function(item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "button",
                      {
                        class: { "is-active": item.facet.id === _vm.tab },
                        on: {
                          click: function($event) {
                            _vm.tab = item.facet.id
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.getCategoryName(item.facet.id)) +
                            " (" +
                            _vm._s(item.facet.total) +
                            ")\n            "
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-wrapper" },
            _vm._l(_vm.searchResults.categories, function(category, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tab === category.facet.id,
                      expression: "tab === category.facet.id"
                    }
                  ],
                  key: index,
                  staticClass: "c-search-results__content"
                },
                [
                  _vm.getContentType(category.facet.id) === "Grid"
                    ? _c(
                        "div",
                        { staticClass: "c-search-results__content-wrapper" },
                        _vm._l(category.items, function(item, itemsIndex) {
                          return _c(
                            "div",
                            {
                              key: itemsIndex,
                              staticClass:
                                "\n              c-search-results__content-item\n              c-search-results__content-item--grid\n            ",
                              on: {
                                click: function($event) {
                                  return _vm.handleCardClick(item, itemsIndex)
                                }
                              }
                            },
                            [
                              item.template === "product"
                                ? _c(
                                    "c-product-card",
                                    {
                                      attrs: {
                                        "minimum-price":
                                          item.product.minimumPrice,
                                        "monthly-price":
                                          item.product.monthlyPrice,
                                        "product-image-url": item.image,
                                        "product-name": item.title,
                                        "product-url": item.url,
                                        variants: item.product.availableColors,
                                        "badge-text": {
                                          props1: "",
                                          item2: item.product.badgeText
                                        },
                                        "product-brand":
                                          item.product.productBrand,
                                        "product-model":
                                          item.product.productModel,
                                        modifier:
                                          "c-product-card--variant-details"
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "action-button" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.title) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.template === "genericproduct"
                                ? _c("generic-product", {
                                    attrs: {
                                      "sub-heading":
                                        item.genericProduct.subHeading,
                                      heading: item.genericProduct.heading,
                                      body: item.genericProduct.body,
                                      "button-url":
                                        item.genericProduct.buttonUrl,
                                      "button-text":
                                        item.genericProduct
                                          .readMoreButtonCaption,
                                      modifier: "generic-search"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.template === "tvpackage"
                                ? _c("tv-card", {
                                    attrs: {
                                      "show-ribbon": item.tvPackage.showRibbon,
                                      "ribbon-text": item.tvPackage.ribbonText,
                                      headline: item.tvPackage.name,
                                      name: item.tvPackage.description,
                                      description:
                                        item.tvPackage.channelDetails,
                                      "channel-description":
                                        item.tvPackage.channelDescription,
                                      price: item.tvPackage.priceValue,
                                      "price-label": item.tvPackage.priceLabel,
                                      "min-price":
                                        item.tvPackage.minimumPriceValue,
                                      url: item.tvPackage.readMoreButtonUrl,
                                      "button-text":
                                        item.tvPackage.readMoreButtonCaption,
                                      categories:
                                        item.tvPackage.includedPackagesText,
                                      channels: item.tvPackage.channels,
                                      "only-card": "true",
                                      "card-tall": "",
                                      "card-search-result": ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.template === "subscription"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "c-subcard c-subcard--tall c-subcard--search-result",
                                        attrs: {
                                          itemscope: "",
                                          itemtype: "http://schema.org/Product"
                                        }
                                      },
                                      [
                                        item.subscription.showRibbon
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-subcard__ribbon-wrapper"
                                              },
                                              [
                                                _c(
                                                  "em",
                                                  {
                                                    class:
                                                      "c-subcard__ribbon c-subcard__ribbon--" +
                                                      item.subscription.ribbonColorName.toLowerCase()
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.subscription
                                                            .ribbonText
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "c-subcard__inner" },
                                          [
                                            item.subscription.brandName ===
                                            "Telia"
                                              ? _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "c-subcard__headline",
                                                    attrs: { itemprop: "name" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.subscription.name
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.subscription.brandName ===
                                            "Telia"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-subcard__name",
                                                    attrs: {
                                                      itemprop: "alternateName"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.subscription
                                                            .talkDetails
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    item.subscription
                                                      .previousDataDetails
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "c-subcard__strikethrough"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item
                                                                    .subscription
                                                                    .previousDataDetails
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n\n                    " +
                                                        _vm._s(
                                                          item.subscription
                                                            .dataDetails
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.subscription.brandName !==
                                            "Telia"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-subcard__name",
                                                    attrs: {
                                                      itemprop: "alternateName"
                                                    }
                                                  },
                                                  [
                                                    item.subscription
                                                      .previousDataDetails
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "c-subcard__strikethrough"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item
                                                                    .subscription
                                                                    .previousDataDetails
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n\n                    " +
                                                        _vm._s(
                                                          item.subscription
                                                            .dataDetails
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      _vm._s(
                                                        item.subscription
                                                          .talkDetails
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-subcard__description",
                                                attrs: {
                                                  itemprop: "description"
                                                }
                                              },
                                              [
                                                item.subscription
                                                  .previousInternationalDataDetails
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "c-subcard__strikethrough"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              item.subscription
                                                                .previousInternationalDataDetails
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n\n                    " +
                                                    _vm._s(
                                                      item.subscription
                                                        .internationalDataDetails
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "c-subcard__offer",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            _c("link", {
                                              attrs: {
                                                itemprop: "availability",
                                                href:
                                                  "http://schema.org/InStock"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("meta", {
                                              attrs: {
                                                itemprop: "priceCurrency",
                                                content:
                                                  item.subscription
                                                    .priceCurrencyCode
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("meta", {
                                              attrs: {
                                                itemprop: "priceValidUntil",
                                                content: ""
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("meta", {
                                              attrs: {
                                                itemprop: "url",
                                                content: item.url
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-subcard__offer-spec",
                                                attrs: {
                                                  itemprop:
                                                    "priceSpecification",
                                                  itemscope: "",
                                                  itemtype:
                                                    "http://schema.org/UnitPriceSpecification"
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "c-subcard__offer-base"
                                                }),
                                                _vm._v(" "),
                                                item.subscription
                                                  .previousPriceValue
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "c-subcard__strikethrough"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              item.subscription
                                                                .previousPriceValue
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "c-subcard__offer-price",
                                                    attrs: {
                                                      itemprop: "price",
                                                      content:
                                                        item.subscription
                                                          .priceSchemaString
                                                    }
                                                  },
                                                  [
                                                    item.subscription
                                                      .brandName !== "Telia"
                                                      ? _c("shouting-font", {
                                                          attrs: {
                                                            text:
                                                              item.subscription
                                                                .priceValue
                                                          }
                                                        })
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.subscription
                                                                .priceValue
                                                            )
                                                          )
                                                        ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                item.subscription.brandName ===
                                                "Telia"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "c-subcard__offer-currency"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              item.subscription
                                                                .priceLabel
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("meta", {
                                                  attrs: {
                                                    itemprop: "unitCode",
                                                    content: "MON"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("meta", {
                                                  attrs: {
                                                    itemprop: "minPrice",
                                                    content:
                                                      item.subscription
                                                        .minimumPriceSchemaString
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-subcard__offer-description"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          item.subscription
                                                            .minimumPriceValue
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            item.subscription.brandName ===
                                            "Telia"
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "c-subcard__url",
                                                    attrs: {
                                                      href: item.url,
                                                      itemprop: "url"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.subscription
                                                            .readMoreButtonCaption
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        item.subscription.brandName === "CallMe"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-subcard__url-wrapper"
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "c-subcard__url",
                                                    attrs: {
                                                      href: item.url,
                                                      itemprop: "url"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.subscription
                                                          .readMoreButtonCaption
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("meta", {
                                          attrs: {
                                            itemprop: "brand",
                                            content: item.subscription.brandName
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("meta", {
                                          attrs: {
                                            itemprop: "sku mpn",
                                            content: item.subscription.code
                                          }
                                        }),
                                        _vm._v(" "),
                                        item.subscription.hasServices
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-subcard__services"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "c-subcard__services-headline"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.subscription
                                                          .servicesHeadline
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "c-subcard__services-list"
                                                  },
                                                  _vm._l(
                                                    item.subscription.services,
                                                    function(service, idx) {
                                                      return _c("img", {
                                                        key: idx,
                                                        staticClass:
                                                          "c-subcard__services-item",
                                                        attrs: {
                                                          src: service.imageUrl,
                                                          alt: service.name
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "c-subcard__services-plus"
                                                  },
                                                  [_vm._v("+")]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getContentType(category.facet.id) === "RichList"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "\n            c-search-results__content-wrapper\n            c-search-results__content-wrapper--center\n          "
                        },
                        _vm._l(category.items, function(item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass:
                                "\n              c-search-results__content-item\n              c-search-results__content-item--list\n            "
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: item.url },
                                  on: {
                                    click: function($event) {
                                      return _vm.track(item)
                                    }
                                  }
                                },
                                [
                                  item.date
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "c-search-results__content-date"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.date) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "c-search-results__content-headline"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.title) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-search-results__content-body"
                                    },
                                    [_vm._v(_vm._s(item.excerpt))]
                                  ),
                                  _vm._v(" "),
                                  item.image
                                    ? _c("img", {
                                        staticClass:
                                          "c-search-results__content-image",
                                        attrs: {
                                          src:
                                            item.image + "?preset=search-thumb"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getContentType(category.facet.id) === "List"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "\n            c-search-results__content-wrapper\n            c-search-results__content-wrapper--center\n          "
                        },
                        _vm._l(category.items, function(item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass:
                                "\n              c-search-results__content-item\n              c-search-results__content-item--list\n            "
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: item.url },
                                  on: {
                                    click: function($event) {
                                      return _vm.track(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "c-search-results__content-headline"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.title) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-search-results__content-body"
                                    },
                                    [_vm._v(_vm._s(item.excerpt))]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-search-results__load-more" }, [
                    !category.allLoaded
                      ? _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.loadMore(category.facet.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getLoadMoreText(category.facet.id)) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._t(category.slot)
                ],
                2
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }