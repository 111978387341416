<template>
  <div class="credit-check">
    <div class="credit-check__inner">
      <template v-if="!showErrorMessage">
        <h3 class="credit-check__title">
          {{ content.headline }}
        </h3>
        <ul class="credit-check__steps">
          <li
            v-for="(step, index) in steps"
            :key="index"
            class="credit-check__steps__item show"
          >
            <div
              class="credit-check__steps__item__icon"
              :class="lowerCaseStatus(step.status)"
            >
              <spinner-chase
                :class="{ show: step.status === 'loading' }"
              ></spinner-chase>
              <c-icon
                symbol-id="service-checkmark-filled"
                :class="{
                  show:
                    step.status === CREDIT_CHECK_RESPONSE.APPROVED ||
                    step.status === CREDIT_CHECK_RESPONSE.NOT_NEEDED
                }"
              />
              <c-icon
                symbol-id="service-warning-filled"
                :class="{
                  show:
                    step.status === CREDIT_CHECK_RESPONSE.REJECTED ||
                    step.status === CREDIT_CHECK_RESPONSE.NO_FINANCING
                }"
              />
            </div>
            {{ step.text }}
          </li>
        </ul>
      </template>
      <div v-else class="credit-check__error">
        <c-icon symbol-id="menu-close" class="credit-check__error__icon" />
        <h3 class="credit-check__title credit-check__title--error">
          {{ content.creditDeniedCompletelyHeader }}
        </h3>
        <div class="credit-check__error__content">
          {{ content.creditDeniedCompletelyText }}
        </div>
        <c-button :href="content.creditDeniedRedirectUrl">{{
          content.creditDeniedCompletelyButtonLabel
        }}</c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from 'olympus/components/index.js';
import spinnerChase from 'webshop/components/loaders/c-spinner-chase.vue';
import methods from './credit-check.methods.js';

import { get } from 'webshop/api/api.js';

import { CREDIT_CHECK_RESPONSE } from 'webshop/enums/credit-check-response.js';

export default {
  name: 'CreditCheck',

  components: {
    CButton,
    spinnerChase
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      cprStatus: 'loading',
      informationStatus: 'loading',
      creditStatus: 'loading',
      stepsApproved: 0,
      showErrorMessage: false,
      CREDIT_CHECK_RESPONSE: CREDIT_CHECK_RESPONSE
    };
  },

  computed: {
    creditCheckNeeded() {
      return !this.content.creditCheckNotNeeded;
    },

    steps() {
      const steps = [
        {
          text: this.content.cprValidationLabel,
          status: this.cprStatus
        },
        {
          text: this.content.addressRetrieveLabel,
          status: this.informationStatus
        }
      ];

      if (this.creditCheckNeeded) {
        steps.push({
          text: this.content.initialCreditCheckLabel,
          status: this.creditStatus
        });
      }

      return steps;
    }
  },

  watch: {
    stepsApproved: {
      handler(approvedSteps) {
        if (!this.creditCheckNeeded && approvedSteps === 2) {
          this.creditStatus = CREDIT_CHECK_RESPONSE.NOT_NEEDED;
          this.finalizeCheck();
        }
      }
    }
  },

  mounted() {
    this.approveStep('cprStatus');
    this.approveStep('informationStatus');

    if (this.creditCheckNeeded) {
      this.performCreditCheck();
    }
  },

  methods: {
    approveStep(stepStatus) {
      setTimeout(() => {
        this._data[stepStatus] = CREDIT_CHECK_RESPONSE.APPROVED;
        this.stepsApproved++;
      }, methods.randomInterval(this.content.spinTimeFrom, this.content.spinTimeTo));
    },

    performCreditCheck() {
      const options = {
        headers: {
          accept: 'application/vnd.api+json'
        }
      };

      return get('initialcreditcheck/', options)
        .then(response => {
          this.creditStatus = response.data.data.attributes.status;
          this.finalizeCheck();
        })
        .catch(() => {
          this.creditStatus = CREDIT_CHECK_RESPONSE.REJECTED;
          this.finalizeCheck();
        });
    },

    finalizeCheck() {
      setTimeout(() => {
        if (
          this.creditStatus === CREDIT_CHECK_RESPONSE.REJECTED ||
          this.creditStatus === CREDIT_CHECK_RESPONSE.NO_FINANCING
        ) {
          this.showErrorMessage = true;
        } else if (
          this.creditStatus === CREDIT_CHECK_RESPONSE.APPROVED ||
          this.creditStatus === CREDIT_CHECK_RESPONSE.NOT_NEEDED
        ) {
          window.location.assign(this.content.nextPageRedirectUrl);
        }
      }, 3000);
    },

    lowerCaseStatus(status) {
      return status.toLowerCase();
    }
  }
};
</script>

<style src="./credit-check.scss" />
