import Vue from 'vue';
import { uniqBy, uniq, merge, values, groupBy, orderBy } from 'lodash-es';
import {
  getProductVariantByCode,
  getProductRelations
} from 'webshop/api/product-service';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
import { GETTERS as NUMBER_SELECT_GETTERS } from 'webshop/store/number-select';

export const MUTATIONS = {
  SET_CONTENT_LOCAL: 'MUTATIONS/PRODUCT_PAGE/SET_CONTENT_LOCAL',
  SET_PRODUCT: 'MUTATIONS/PRODUCT_PAGE/SET_PRODUCT',
  SET_VARIANT_ATTRIBUTES: 'MUTATIONS/PRODUCT_PAGE/SET_VARIANT_ATTRIBUTES',
  SET_EXISTING_SUBSCRIPTIONS:
    'MUTATIONS/PRODUCT_PAGE/SET_EXISTING_SUBSCRIPTIONS',
  SET_SELECTED_VARIANT: 'MUTATIONS/PRODUCT_PAGE/SET_SELECTED_VARIANT',
  ADD_SELECTED_VAS: 'MUTATIONS/PRODUCT_PAGE/ADD_SELECTED_VAS',
  REMOVE_SELECTED_VAS: 'MUTATIONS/PRODUCT_PAGE/REMOVE_SELECTED_VAS',
  SET_ACTIVE_TAB: 'MUTATIONS/PRODUCT_PAGE/SET_ACTIVE_TAB',
  SET_PRODUCT_RELATIONS: 'MUTATIONS/PRODUCT_PAGE/SET_PRODUCT_RELATIONS'
};

export const ACTIONS = {
  SET_ACTIVE_TAB: 'ACTIONS/PRODUCT_PAGE/SET_ACTIVE_TAB',
  FETCH_VARIANT: 'ACTIONS/PRODUCT_PAGE/FETCH_VARIANT',
  FETCH_PRODUCT_RELATIONS: 'ACTIONS/PRODUCT_PAGE/FETCH_PRODUCT_RELATIONS'
};

export const GETTERS = {
  GET_CONTENT_LOCAL: 'GETTERS/PRODUCT_PAGE/GET_CONTENT_LOCAL',
  GET_PRODUCT: 'GETTERS/PRODUCT_PAGE/GET_PRODUCT',
  GET_VARIANT_ATTRIBUTES: 'GETTERS/PRODUCT_PAGE/GET_VARIANT_ATTRIBUTES',
  GET_SELECTED_VARIANT: 'GETTERS/PRODUCT_PAGE/GET_SELECTED_VARIANT',
  GET_SELECTED_SUBSCRIPTION: 'GETTERS/PRODUCT_PAGE/GET_SELECTED_SUBSCRIPTION',
  GET_SELECTED_INSTALLMENT: 'GETTERS/PRODUCT_PAGE/GET_SELECTED_INSTALLMENT',
  GET_SELECTED_BINDING_PERIOD:
    'GETTERS/PRODUCT_PAGE/GET_SELECTED_BINDING_PERIOD',
  GET_SELECTED_VAS_CODES: 'GETTERS/PRODUCT_PAGE/GET_SELECTED_VAS_CODES',
  GET_COMBINED_VARIANT_ATTRIBUTES:
    'GETTERS/PRODUCT_PAGE/GET_COMBINED_VARIANT_ATTRIBUTES',
  GET_COLOR_ATTRIBUTES: 'GETTERS/PRODUCT_PAGE/GET_COLOR_ATTRIBUTES',
  GET_CAPACITY_ATTRIBUTES: 'GETTERS/PRODUCT_PAGE/GET_CAPACITY_ATTRIBUTES',
  GET_INSTALLMENT_ATTRIBUTES: 'GETTERS/PRODUCT_PAGE/GET_INSTALLMENT_ATTRIBUTES',
  GET_BINDING_PERIOD_ATTRIBUTES:
    'GETTERS/PRODUCT_PAGE/GET_BINDING_PERIOD_ATTRIBUTES',
  GET_SUBSCRIPTIONS: 'GETTERS/PRODUCT_PAGE/GET_SUBSCRIPTIONS',
  GET_FILTERED_SUBSCRIPTIONS: 'GETTERS/PRODUCT_PAGE/GET_FILTERED_SUBSCRIPTIONS',
  GET_PRICES: 'GETTERS/PRODUCT_PAGE/GET_PRICES',
  GET_ACTIVE_TAB: 'GETTERS/PRODUCT_PAGE/GET_ACTIVE_TAB',
  HAS_INSTALLMENTS: 'GETTERS/PRODUCT_PAGE/HAS_INSTALLMENTS',
  GET_PRODUCT_RELATIONS: 'GETTERS/PRODUCT_PAGE/PRODUCT_RELATIONS'
};

const FULL_PRICE_INSTALLMENT = 1;

/**
 * Manages state for product details page.
 */
export default {
  state: {
    contentLocal: {},
    product: {},
    variantAttributes: [],
    combinedVariantAttributes: [],
    existingSubscriptions: [],
    selectedVariant: undefined,
    selectedVasCodes: [],
    productRelations: [],
    activeTab: {
      id: '',
      scrollToTab: false
    }
  },

  mutations: {
    [MUTATIONS.SET_CONTENT_LOCAL](state, { content, fallbackContent }) {
      Vue.set(state, 'contentLocal', { ...fallbackContent, ...content });
    },

    [MUTATIONS.SET_PRODUCT](
      state,
      { code, model, brand, name, description, descriptionLinkText }
    ) {
      state.product = {
        code,
        model,
        brand,
        name,
        description,
        descriptionLinkText
      };
    },

    [MUTATIONS.SET_VARIANT_ATTRIBUTES](state, variantAttributes) {
      state.variantAttributes = variantAttributes;
      state.combinedVariantAttributes = values(
        groupBy(state.variantAttributes, 'code')
      ).map(arr => merge(...arr));
    },

    [MUTATIONS.SET_EXISTING_SUBSCRIPTIONS](state, existingSubscriptions) {
      state.existingSubscriptions = existingSubscriptions;
    },

    [MUTATIONS.SET_SELECTED_VARIANT](state, selectedVariant) {
      state.selectedVariant = selectedVariant;
    },

    [MUTATIONS.ADD_SELECTED_VAS](state, { code }) {
      state.selectedVasCodes = uniq([...state.selectedVasCodes, code]);
    },

    [MUTATIONS.REMOVE_SELECTED_VAS](state, { code }) {
      state.selectedVasCodes = state.selectedVasCodes.filter(
        vasCode => vasCode !== code
      );
    },

    [MUTATIONS.SET_ACTIVE_TAB](state, { id, scrollToTab }) {
      state.activeTab = { id, scrollToTab };
    },

    [MUTATIONS.SET_PRODUCT_RELATIONS](state, productRelations) {
      state.productRelations = productRelations.sort(
        (a, b) => a.priority - b.priority
      );
    }
  },

  actions: {
    [ACTIONS.SET_ACTIVE_TAB]({ commit }, { id, scrollToTab }) {
      commit(MUTATIONS.SET_ACTIVE_TAB, { id, scrollToTab });
    },

    [ACTIONS.FETCH_VARIANT](
      { commit },
      {
        variantCode,
        subscriptionCode,
        installmentCode,
        bindingPeriodCode,
        vasCodes,
        existingmsisdn
      }
    ) {
      return getProductVariantByCode(
        variantCode,
        subscriptionCode,
        installmentCode,
        vasCodes,
        existingmsisdn
      ).then(res => {
        const variant = { ...res, bindingPeriodCode };
        commit(MUTATIONS.SET_SELECTED_VARIANT, variant);
        return variant;
      });
    },

    [ACTIONS.FETCH_PRODUCT_RELATIONS]({ commit }, { code }) {
      getProductRelations(code).then(res => {
        const productRelations = res.map(productRelation => {
          const { categoryLabel, categoryPageUrl, priority } = productRelation;
          return {
            name: categoryLabel,
            url: categoryPageUrl,
            priority
          };
        });
        commit(MUTATIONS.SET_PRODUCT_RELATIONS, productRelations);
      });
    }
  },

  getters: {
    [GETTERS.GET_ACTIVE_TAB]: state => state.activeTab,
    [GETTERS.GET_PRODUCT]: state => state.product,
    [GETTERS.GET_CONTENT_LOCAL]: state => state.contentLocal,
    [GETTERS.GET_VARIANT_ATTRIBUTES]: state => state.variantAttributes,
    [GETTERS.GET_SELECTED_VARIANT]: state => state.selectedVariant,
    [GETTERS.GET_PRODUCT_RELATIONS]: state => state.productRelations,

    /**
     * Subscription based on the selected variant.
     * The selected subscription is determined from selectedSubscriptionCode and msisdn.
     * For prolonging, multiple subscriptions can have the same code, but different msisdns.
     */
    [GETTERS.GET_SELECTED_SUBSCRIPTION](state, getters) {
      const selectedSubscriptionCode =
        state.selectedVariant?.selectedSubscription;

      return [
        ...(state.existingSubscriptions || []),
        ...(state.selectedVariant?.subscriptions || [])
      ].find(subscription => {
        const codeMatches = selectedSubscriptionCode === subscription.code;
        const msisdnMatches = subscription.msisdn
          ? subscription.msisdn ===
            getters[NUMBER_SELECT_GETTERS.GET_SELECTED_PHONE_NUMBER]
          : true;

        return codeMatches && msisdnMatches;
      });
    },

    /**
     * Installment plan based on the selected variant.
     */
    [GETTERS.GET_SELECTED_INSTALLMENT](state) {
      return state.selectedVariant?.installment?.data?.find(
        installment =>
          installment.code.toString() ===
          state.selectedVariant?.selectedInstallment.toString()
      );
    },

    /**
     * Selected binding period for the selected variant.
     * NB: binding period is a purely FE filter - just a property of a subscription.
     * It is not initialized by the BE and is completely managed by FE,
     * not requiring any API calls.
     */
    [GETTERS.GET_SELECTED_BINDING_PERIOD](state, getters) {
      const bindingPeriodCode = state.selectedVariant?.bindingPeriodCode;
      const { bindingPeriod, bindingPeriodFilterText } =
        getters[GETTERS.GET_SUBSCRIPTIONS]?.find(
          s => s.bindingPeriod.toString() === bindingPeriodCode?.toString()
        ) || {};

      return {
        code: bindingPeriod,
        name: bindingPeriodFilterText
          ? populateContentProperty(bindingPeriodFilterText, {
              bindingPeriod
            })
          : bindingPeriod
      };
    },

    /**
     * A list of vas codes selected for the product.
     *
     * @returns Array<string>
     */
    [GETTERS.GET_SELECTED_VAS_CODES](state) {
      return state.selectedVasCodes;
    },

    /**
     * TODO DKT-3279 & DKT-3119 this could be directly returned by BE - no need to map
     *
     * Combines all variant attributes with the same code.
     * That makes searching for a variant with a given color & storage easy.
     *
     * @returns
     * Array<
     *  {
     *    name: string,
     *    code: string,
     *    [property: string]: string | number
     *  }
     * >
     */
    [GETTERS.GET_COMBINED_VARIANT_ATTRIBUTES](state) {
      return state.combinedVariantAttributes;
    },

    /**
     * TODO DKT-3279 & DKT-3119 this could be directly returned by BE - no need to map
     *
     * All available colors for this product, in the original order they were
     * provided by the BE (order of items in variantAttributes).
     *
     * @returns
     * Array<
     *  {
     *    name: string,
     *    code: string,
     *    [property: string]: string | number
     *  }
     * >
     */
    [GETTERS.GET_COLOR_ATTRIBUTES](state) {
      const getColorIndex = color =>
        state.variantAttributes.findIndex(a => a.color === color);

      return orderBy(
        uniqBy(state.combinedVariantAttributes, 'color').map(
          ({ color, code, hex }) => {
            return { name: color, code, hex };
          }
        ),
        ({ name }) => getColorIndex(name)
      );
    },

    /**
     * TODO DKT-3279 & DKT-3119 this could be directly returned by BE - no need to map
     *
     * A filtered list of available capacities,
     * based on the selected variant color.
     *
     * @returns
     * Array<
     *  {
     *    name: string,
     *    code: string,
     *    [property: string]: string | number
     *  }
     * >
     */
    [GETTERS.GET_CAPACITY_ATTRIBUTES](state) {
      return orderBy(
        state.combinedVariantAttributes
          .filter(
            ({ color, capacity }) =>
              color?.toLowerCase() ===
                state.selectedVariant?.color?.toLowerCase() && capacity
          )
          .map(({ capacity, code, dataUnit, price, originalPrice }) => {
            return { name: capacity, code, dataUnit, price, originalPrice };
          }),
        ['name'],
        ['asc']
      );
    },

    /**
     * Available installments for the selected variant.
     *
     * @returns
     * Array<{
     *  {
     *    name: string,
     *    code: string,
     *    [property: string]: string | number
     *  }
     * }>
     */
    [GETTERS.GET_INSTALLMENT_ATTRIBUTES](state) {
      return state.selectedVariant?.installment?.data.map(
        ({ code, name, shortName, priceText, disabled }) => {
          return { name: name, code, shortName, priceText, disabled };
        }
      );
    },

    /**
     * Available binding periods for variant subscriptions.
     *
     * @returns
     * Array<{
     *  {
     *    name: string,
     *    code: string
     *  }
     * }>
     */
    [GETTERS.GET_BINDING_PERIOD_ATTRIBUTES](_state, getters) {
      const subscriptions = getters[GETTERS.GET_SUBSCRIPTIONS];

      if (!subscriptions) {
        return [];
      }

      return orderBy(
        uniqBy(subscriptions, 'bindingPeriod'),
        ['bindingPeriod'],
        ['desc']
      ).map(({ bindingPeriod, bindingPeriodFilterText }) => {
        return {
          code: bindingPeriod,
          name: bindingPeriodFilterText
            ? populateContentProperty(bindingPeriodFilterText, {
                bindingPeriod
              })
            : bindingPeriod
        };
      });
    },

    /**
     * All subscriptions for the selected variant.
     *
     * @returns
     * Array<Subscription{}>
     */
    [GETTERS.GET_SUBSCRIPTIONS](state) {
      return state.selectedVariant?.subscriptions;
    },

    /**
     * Subscriptions for the selected variant,
     * with optional filters applied.
     * Filters can be i.e. binding period.
     *
     * @returns
     * Array<Subscription{}>
     */
    [GETTERS.GET_FILTERED_SUBSCRIPTIONS](state, getters) {
      return getters[GETTERS.GET_SUBSCRIPTIONS]?.filter(
        ({ bindingPeriod }) =>
          bindingPeriod.toString() ===
          state.selectedVariant?.bindingPeriodCode?.toString()
      );
    },

    /**
     * Prices for the selected variant.
     *
     * @returns
     * Price{}
     */
    [GETTERS.GET_PRICES](state) {
      return state.selectedVariant?.price;
    },

    [GETTERS.HAS_INSTALLMENTS](state) {
      return (
        state.selectedVariant?.selectedInstallment !== FULL_PRICE_INSTALLMENT
      );
    }
  }
};
