<template>
  <label
    v-if="facetType === FACET_TYPES.BUTTON"
    class="
      cm-c-filter-nav__item cm-c-filter-nav__item--as-button
      c-btn c-btn--sm
    "
    :class="{
      'c-btn--empty-primary': !selected,
      'c-btn--empty': selected,
      'cm-c-filter-nav__item--as-button--disabled': !selected && item.disabled
    }"
  >
    <input
      v-model="selected"
      type="checkbox"
      class="cm-c-checkbox__o-input"
      :data-group="facet"
    />
    <span class="cm-c-checkbox__label cm-c-checkbox__label--center">
      {{ item.value }}
    </span>
  </label>

  <c-checkbox
    v-else-if="facetType === FACET_TYPES.CHECKBOX"
    :id="item.value"
    v-model="selected"
    class="cm-c-filter-nav__item"
    :name="item.value"
    :data-group="facet"
  >
    {{ item.value }}
  </c-checkbox>

  <label v-else class="cm-c-filter-nav__item cm-c-radio">
    <input
      :id="item.value"
      :name="facet"
      type="radio"
      class="cm-c-radio__o-input"
    />
    <span class="cm-c-radio__input"></span>
    <span class="cm-c-radio__label">{{ item.value }}</span>
  </label>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CCheckbox from 'olympus/components/c-checkbox/c-checkbox.vue';

const FACET_TYPES = {
  RADIO: 0,
  CHECKBOX: 1,
  BUTTON: 2
};

export default {
  name: 'ProductCategoryFacetItem',
  components: { CCheckbox },

  props: {
    facet: {
      type: String,
      required: true
    },
    facetType: {
      type: Number,
      required: true,
      validator: val => Object.values(FACET_TYPES).indexOf(val) !== -1
    },
    facetPropertyName: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      FACET_TYPES
    };
  },

  computed: {
    ...mapGetters({
      getFacetState: 'getFacetState'
    }),

    selected: {
      get() {
        return this.getFacetState({
          facetId: this.facet,
          value: this.item.value
        });
      },
      set(value) {
        const currentRoute = this.$router.currentRoute;
        const queryValues = (currentRoute.query[this.facetPropertyName] || '')
          .split(';')
          .shift()
          .split(',')
          .filter(x => x);
        const index = queryValues.indexOf(this.item.value);

        if (value && index === -1) {
          queryValues.push(this.item.value);
        } else if (index > -1) {
          queryValues.splice(index, 1);
        }

        const query = queryValues.length
          ? {
              ...currentRoute.query,
              [this.facetPropertyName]: `${queryValues.join(',')};${this.facet}`
            }
          : {
              ...Object.keys(currentRoute.query).reduce((obj, k) => {
                if (k !== this.facetPropertyName) {
                  obj[k] = currentRoute.query[k];
                }
                return obj;
              }, {})
            };

        this.$router.replace({
          ...currentRoute,
          query
        });

        this.setFacetState({
          id: this.facet,
          name: this.facetPropertyName,
          value: this.item.value
        });
      }
    }
  },

  methods: {
    ...mapActions({
      setFacetState: 'actions/SET_FACET_STATE'
    })
  }
};
</script>
