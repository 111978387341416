var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-form" },
    [
      _c(
        "c-card",
        {
          key: "numbermove" + _vm.keyToReRender,
          ref: "fieldset",
          staticClass: "cm-numcon"
        },
        [
          _c(
            "section",
            { staticClass: "cm-numcon__config" },
            [
              _c("div", { staticClass: "cm-numcon__product" }, [
                _c("div", { staticClass: "cm-numcon__product__info" }, [
                  _c("img", {
                    staticClass: "cm-numcon__image",
                    attrs: {
                      src: _vm.numberConfigActiveProduct.img,
                      alt: _vm.numberConfigActiveProduct.subscriptionName
                    }
                  }),
                  _vm._v(" "),
                  _c("section", { staticClass: "cm-numcon__product__text" }, [
                    _vm.numberConfigVM.subscriptionLabel
                      ? _c(
                          "p",
                          { staticClass: "cm-numcon__product__pre-label" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.numberConfigVM.subscriptionLabel) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numberConfigActiveProduct.subscriptionName
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "\n                cm-numcon__product__header\n                cm-numcon__product__header--specific\n              "
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.numberConfigActiveProduct.subscriptionName
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numberConfigActiveProduct.handsetName
                      ? _c(
                          "div",
                          { staticClass: "cm-numcon__product__header" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.numberConfigActiveProduct.handsetName
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-numcon__options" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "cm-numcon__options__item cm-numcon__options__item--active"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cm-numcon__options__link cm-numcon__options__link--default"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "cm-numcon__options__icon" },
                          [
                            _c("c-icon", {
                              attrs: { "symbol-id": "service-switch" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "cm-numcon__options__header" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.numberConfigVM.moveNumberOverviewHeadline
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cm-numcon__options__content" },
                      [
                        _vm.isEnterprise
                          ? _c("number-subinfo", {
                              key: "subinfo" + _vm.keyToReRender,
                              attrs: { "active-product": _vm.$data }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isEnterprise
                          ? _c("number-simcard-info", {
                              key: "simcardinfo" + _vm.keyToReRender,
                              attrs: { "active-product": _vm.$data }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.numberConfigVM.moveNumberDescription
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c("c-col", { staticClass: "cm-c-form__col" }, [
                                  _c(
                                    "h3",
                                    { staticClass: "cm-c-form__header" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.numberConfigVM
                                              .moveNumberDescription
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c(
                              "c-col",
                              { staticClass: "cm-c-form__col" },
                              [
                                _c("c-input-group", {
                                  attrs: {
                                    type: "tel",
                                    "input-error": _vm.phoneNumberError,
                                    "place-holder-label":
                                      _vm.numberConfigVM
                                        .numberToMovePlaceholderText
                                  },
                                  model: {
                                    value: _vm.phoneNumber,
                                    callback: function($$v) {
                                      _vm.phoneNumber = $$v
                                    },
                                    expression: "phoneNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c(
                              "c-col",
                              { staticClass: "cm-c-form__col" },
                              [
                                _c("c-input-group", {
                                  attrs: {
                                    disabled: _vm.simUnavailable,
                                    "input-error": _vm.simError,
                                    "place-holder-label":
                                      _vm.numberConfigVM
                                        .simNumberPlaceholderText
                                  },
                                  model: {
                                    value: _vm.$v.sim.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.sim,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "$v.sim.$model"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c("c-col", { staticClass: "cm-c-form__col" }, [
                              _c("h3", { staticClass: "cm-c-form__header" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.numberConfigVM.simNumberDescription
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "cm-c-checkout__textblock" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "cm-c-checkout__link",
                                      on: {
                                        click: function($event) {
                                          return _vm.SHOW_DIALOG({
                                            componentName: "content-dialog",
                                            componentProps: {
                                              contentObject: {
                                                headline:
                                                  _vm.numberConfigVM
                                                    .howToFindSimNumberHeadline,
                                                description:
                                                  _vm.numberConfigVM
                                                    .howToFindSimNumberDescription,
                                                image:
                                                  _vm.numberConfigVM
                                                    .dialogImageUrl,
                                                buttonLabel:
                                                  _vm.numberConfigVM
                                                    .howToFindSimNumberButtonText
                                              }
                                            }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.numberConfigVM
                                              .howToFindSimNumberHeadline
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row mb-4" },
                          [
                            _c(
                              "c-col",
                              { staticClass: "cm-c-form__col" },
                              [
                                _c(
                                  "c-checkbox",
                                  {
                                    attrs: {
                                      id: "sim-number-unavailable",
                                      checked: _vm.simUnavailable,
                                      name: "simNumberUnavailable",
                                      "styled-checkbox": ""
                                    },
                                    on: {
                                      "event-id": function($event) {
                                        return _vm.toggleSimNumberUnavailable()
                                      }
                                    },
                                    model: {
                                      value: _vm.simUnavailable,
                                      callback: function($$v) {
                                        _vm.simUnavailable = $$v
                                      },
                                      expression: "simUnavailable"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.numberConfigVM
                                            .noSimNumberAvailableLabel
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c("c-col", { staticClass: "cm-c-form__col" }, [
                              _c("h3", { staticClass: "cm-c-form__header" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.numberConfigVM.moveNumberDateHeadline
                                    ) +
                                    "\n                "
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c(
                              "c-col",
                              { staticClass: "cm-c-form__col" },
                              [
                                _c(
                                  "c-form-radio-group",
                                  {
                                    attrs: { name: "moveTime", stacked: "" },
                                    on: { input: _vm.toggleDate },
                                    model: {
                                      value: _vm.enableAsap,
                                      callback: function($$v) {
                                        _vm.enableAsap = $$v
                                      },
                                      expression: "enableAsap"
                                    }
                                  },
                                  [
                                    _c(
                                      "c-form-radio",
                                      {
                                        attrs: {
                                          id: "binding",
                                          "checked-border": "",
                                          value: "binding"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .bindingPeriodLabel
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "c-form-radio",
                                      {
                                        attrs: {
                                          id: "calendar",
                                          "checked-border": "",
                                          value: "calendar"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .moveNumberDateDescription
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-row",
                          { staticClass: "cm-c-form__row mb-4" },
                          [
                            _c(
                              "c-col",
                              { staticClass: "cm-c-form__col" },
                              [
                                _c(
                                  "date-picker",
                                  {
                                    attrs: {
                                      "date-locale": "da-DK",
                                      "date-selected": _vm.moveNumberDate,
                                      "disable-before": _vm.getMinimumDatePickerDate(),
                                      "disable-range": _vm.getDisabledDateRange(),
                                      "input-classes":
                                        "cm-c-form-control cm-c-form-control--filled cm-js-material-input",
                                      "input-value": _vm.isMoveNumberAfterCurrentBinding
                                        ? _vm.numberConfigVM
                                            .bindingEndPlaceholder
                                        : _vm.setInputDate(),
                                      disabled: !_vm.enableCalendar
                                    },
                                    on: { setDate: _vm.setMoveDate }
                                  },
                                  [
                                    _c("c-icon", {
                                      staticClass: "datepicker__icon",
                                      attrs: {
                                        slot: "icon",
                                        "symbol-id": "arrow-down"
                                      },
                                      slot: "icon"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "cm-c-form-control__label datepicker__label--ie",
                                        attrs: { slot: "label" },
                                        slot: "label"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .moveNumberDateLabel
                                            ) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("number-poa", {
                          key: "poa" + _vm.keyToReRender,
                          attrs: { product: _vm.$data }
                        }),
                        _vm._v(" "),
                        _vm.IS_THIRD_PARTY_RESELLER &&
                        _vm.numberConfigVM.showSimDeliveryOptions
                          ? _c("number-simcard-info", {
                              key: "simcardinfo" + _vm.keyToReRender,
                              attrs: { "active-product": _vm.$data }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("number-optional", {
                          key: "optional" + _vm.keyToReRender,
                          on: {
                            hiddenNumber: _vm.setHiddenNumber,
                            unlistedNumber: _vm.setUnlistedNumber
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.numberConfigVM.enableNumberConfigurationConsents
                ? _c("number-consents", {
                    key: "consent" + _vm.keyToReRender,
                    attrs: { "active-product": _vm.$data }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cm-numcon__actions" },
        [
          _c("c-button", { attrs: { to: "/", variant: "secondary" } }, [
            _vm._v("Tilbage")
          ]),
          _vm._v(" "),
          _c(
            "c-button",
            {
              attrs: { disabled: _vm.disableNumberPorting || !_vm.ready },
              on: { click: _vm.accept }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.numberConfigVM.moveNumberButtonText) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }