<template>
  <c-card v-if="configMode || product.configEdited" class="cm-numcon" no-shadow>
    <number-product :product="product">
      <c-collapse-trigger
        id="number-product-trigger"
        slot="number-product-trigger"
      >
        <template slot-scope="{ expanded }">
          <button type="button" class="cm-numcon__header--toggle-open">
            <c-icon
              :class="expanded ? 'open' : ''"
              symbol-id="arrow-down"
            ></c-icon>
          </button>
        </template>
      </c-collapse-trigger>
    </number-product>

    <c-collapse id="number-product-trigger" visible>
      <ul class="cm-numcon__options">
        <li
          v-for="configType in numberConfigVM.configTypes"
          :key="configType.value"
          :class="{
            'cm-numcon__options__item': true,
            'cm-numcon__options__item--hide':
              product.isSubscriptionMobileBroadBand &&
              configType.value === CONFIGURATION_TYPES.MOVE
          }"
        >
          <template v-if="configType.value === CONFIGURATION_TYPES.EXISTING">
            <div
              class="cm-numcon__options__link"
              @click="
                changeIsNotPossible(
                  {
                    name: 'number-existing',
                    params: {
                      configType: 2,
                      initMoveDateString: product.moveNumberDateString
                    }
                  },
                  product.isSIMOnly
                )
              "
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="service-sync" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{
                  numberConfigVM.existingCustomerOverviewHeadline
                }}</strong>
                <br />
                <span class="cm-numcon__options__meta">
                  {{ numberConfigVM.existingCustomerOverviewDescription }}
                </span>
              </span>
            </div>
          </template>
          <template
            v-else-if="
              !product.isSubscriptionMobileBroadBand &&
              configType.value === CONFIGURATION_TYPES.MOVE
            "
          >
            <router-link
              :to="{
                name: 'number-move',
                params: {
                  configType: 3,
                  datePickerMinDateString: product.minimumMoveNumberDateString,
                  initMoveDateString: product.moveNumberDateString
                }
              }"
              class="cm-numcon__options__link"
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="service-switch" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{ numberConfigVM.moveNumberOverviewHeadline }}</strong>
                <br />
                <span class="cm-numcon__options__meta">
                  {{ numberConfigVM.moveNumberOverviewDescription }}
                </span>
              </span>
            </router-link>
          </template>
          <template
            v-else-if="
              configType.value === CONFIGURATION_TYPES.NEW &&
              ((numberConfigVM.disableNewNumber && !isHandset) ||
                !numberConfigVM.disableNewNumber)
            "
          >
            <router-link
              :to="{
                name: 'number-new',
                params: {
                  configType: 1,
                  initMoveDateString: product.moveNumberDateString
                }
              }"
              class="cm-numcon__options__link"
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="menu-search" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{ numberConfigVM.newNumberOverviewHeadline }}</strong>
                <br />
                <span class="cm-numcon__options__meta">
                  {{ numberConfigVM.newNumberOverviewDescription }}
                </span>
              </span>
            </router-link>
          </template>
        </li>
      </ul>
    </c-collapse>
  </c-card>

  <c-card v-else class="cm-numcon" no-shadow>
    <number-product :product="product"></number-product>
  </c-card>
</template>

<script>
const CONFIGURATION_TYPES = {
  NEW: 1,
  EXISTING: 2,
  MOVE: 3
};

import NumberProduct from './number-product.vue';
import { mapGetters, mapMutations } from 'vuex';
import CCard from 'webshop/components/c-card/c-card.vue';
import { CCollapse, CCollapseTrigger } from 'olympus/components/index.js';
import { PRODUCT_TYPES } from 'webshop/enums/product-types';

export default {
  name: 'NumberConfig',
  components: {
    NumberProduct,
    CCard,
    CCollapse,
    CCollapseTrigger
  },

  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    configMode: {
      type: Boolean,
      default: false
    },
    moveNumberSelectButtonText: {
      type: String,
      default: 'Vælg'
    }
  },

  data() {
    return {
      CONFIGURATION_TYPES
    };
  },

  computed: {
    ...mapGetters([
      'isEnterprise',
      'numberConfigActive',
      'numberConfigActiveProduct',
      'numberConfigComplete',
      'numberConfigNumber',
      'numberConfigTotal',
      'numberConfigCompleteIndex',
      'numberConfigVM',
      'IS_THIRD_PARTY_RESELLER'
    ]),

    isHandset() {
      // TODO: we should have a better check for verifying if a product is a handset
      return this.product.equipmentType === PRODUCT_TYPES.HANDSET;
    }
  },
  methods: {
    ...mapMutations(['SET_NUMCONF_COMPLETE', 'SHOW_DIALOG']),
    scrollToTop() {
      this.$scrollTo('body');
    },
    changeIsNotPossible(paramsObj, isSimOrder) {
      const self = this;
      if (isSimOrder && !this.IS_THIRD_PARTY_RESELLER) {
        this.SHOW_DIALOG({
          componentName: 'content-dialog',
          componentProps: {
            contentObject: this.numberConfigVM.simOnlyOrderPopup
          }
        });
      } else {
        self.$router.push(paramsObj);
      }
    }
  }
};
</script>
