var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          key: "numberexisting" + _vm.keyToReRender,
          ref: "fieldset",
          staticClass: "cm-numcon"
        },
        [
          _c("div", { staticClass: "cm-numcon__config" }, [
            _c("div", { staticClass: "cm-numcon__product" }, [
              _c("div", { staticClass: "cm-numcon__product__info" }, [
                _c("img", {
                  staticClass: "cm-numcon__image",
                  attrs: {
                    src: _vm.numberConfigActiveProduct.img,
                    alt: _vm.numberConfigActiveProduct.subscriptionName
                  }
                }),
                _vm._v(" "),
                _c("section", { staticClass: "cm-numcon__product__text" }, [
                  _vm.numberConfigVM.subscriptionLabel
                    ? _c(
                        "p",
                        { staticClass: "cm-numcon__product__pre-label" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.numberConfigVM.subscriptionLabel) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.numberConfigActiveProduct.subscriptionName
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "\n                cm-numcon__product__header\n                cm-numcon__product__header--specific\n              "
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.numberConfigActiveProduct.subscriptionName
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.numberConfigActiveProduct.handsetName
                    ? _c("div", { staticClass: "cm-numcon__product__header" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.numberConfigActiveProduct.handsetName) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cm-numcon__options" }, [
              _c(
                "div",
                {
                  staticClass:
                    "cm-numcon__options__item cm-numcon__options__item--active"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-numcon__options__link cm-numcon__options__link--default"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cm-numcon__options__icon" },
                        [
                          _c("c-icon", {
                            attrs: { "symbol-id": "service-sync" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "cm-numcon__options__header" },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.numberConfigVM
                                  .existingCustomerOverviewHeadline
                              )
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "cm-numcon__options__content" },
                    [
                      !_vm.numberConfigVM.enableExistingNumberSelection
                        ? _c("div", { staticClass: "cm-grid mb-4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cm-grid__col--xs-12 cm-grid__col--sm"
                              },
                              [
                                _c("c-input-group", {
                                  attrs: {
                                    type: "tel",
                                    "input-error": _vm.phoneNumberError,
                                    "place-holder-label":
                                      _vm.numberConfigVM
                                        .existingNumberPlaceholderText
                                  },
                                  model: {
                                    value: _vm.phoneNumber,
                                    callback: function($$v) {
                                      _vm.phoneNumber = $$v
                                    },
                                    expression: "phoneNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : [
                            !_vm.init
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cm-c-spinner cm-c-spinner--local"
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading...")
                                    ])
                                  ]
                                )
                              : _vm.existingNumbers &&
                                _vm.existingNumbers.length
                              ? [
                                  _c(
                                    "p",
                                    { staticClass: "cm-c-checkout__text" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.numberConfigVM
                                              .existingCustomerDescription
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.existingNumbers, function(ref) {
                                    var id = ref.id
                                    var name = ref.name
                                    var phoneNumber = ref.phoneNumber
                                    var isAvailable = ref.isAvailable
                                    var errorMessage = ref.errorMessage
                                    return _c(
                                      "user-card",
                                      {
                                        key: id,
                                        attrs: {
                                          "subscription-name": name,
                                          "phone-number": phoneNumber,
                                          disabled: !isAvailable,
                                          "right-action-button-text":
                                            _vm.numberConfigVM
                                              .selectExistingNumberButtonText,
                                          "right-action-disabled-button-text":
                                            _vm.numberConfigVM
                                              .selectExistingNumberDisabledButtonText
                                        },
                                        on: {
                                          rightActionPressed:
                                            _vm.onRightActionPressed
                                        }
                                      },
                                      [
                                        errorMessage
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(errorMessage)
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  })
                                ]
                              : _c(
                                  "p",
                                  { staticClass: "cm-c-checkout__text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.numberConfigVM
                                            .noExistingNumberAvailableLabel
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                          ],
                      _vm._v(" "),
                      _vm.IS_THIRD_PARTY_RESELLER &&
                      _vm.numberConfigVM.showSimDeliveryOptions
                        ? _c("number-simcard-info", {
                            key: "simcardinfo" + _vm.keyToReRender,
                            attrs: {
                              "active-product": _vm.$data,
                              "is-existing-number": true
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cm-numcon__actions" },
        [
          _c("c-button", { attrs: { to: "/", variant: "secondary" } }, [
            _vm._v("Tilbage")
          ]),
          _vm._v(" "),
          !_vm.numberConfigVM.enableExistingNumberSelection ||
          (_vm.existingNumbers && _vm.existingNumbers.length)
            ? _c(
                "c-button",
                { attrs: { disabled: !_vm.ready }, on: { click: _vm.accept } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.numberConfigVM.acceptNumberButtonText) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }