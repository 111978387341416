<template>
  <div 
    class="c-load-more"
    :aria-label="ariaLabel"
  >
    <c-button 
      @click="loadMore" 
      :aria-controls="ariaControls"
      :disabled="canLoadMore"
    >
      <slot>{{ buttonText }}</slot>
    </c-button>
  </div>
</template>

<script>
import { CButton } from 'olympus/components/index.js';
import { debug } from 'util';

export default {
  name: 'c-load-more',

  components: {
    CButton
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    ariaControls: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Load More'
    },
    ariaLabel: {
      type: String,
      default: 'Pagination'
    }
  },

  data() {
    return {
      networking: false,
      offset: this.items.length,
      depleted: this.items.length < this.step
    };
  },

  computed: {
    canLoadMore() {
      return this.networking || this.depleted;
    }
  },

  watch: {
    items(arr) {
      this.offset = arr.length;
    },
    offset(_, oldValue) {
      this.depleted = this.items.length < oldValue + this.step;
    }
  },

  methods: {
    loadMore() {
      this.networking = true;

      const result = this.action();
      const isPromise = result && !!result.then;

      if (isPromise) {
        result.then(this.done);
      } else {
        this.done();
      }
    },

    done() {
      this.networking = false;
      this.$emit('loaded');
    }
  }
};
</script>