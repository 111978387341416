export const CTh = {
  name: 'c-th',

  render(h) {
    return h('th', {}, this.$slots.default);
  }
};

export const CTd = {
  name: 'c-td',

  render(h) {
    return h('td', {}, this.$slots.default);
  }
};
