var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "c-row",
            {
              staticClass:
                "price-summary__section price-summary__section--heading",
              attrs: { tag: "dl", "no-gutters": "" }
            },
            [
              _c("c-col", { attrs: { cols: "6", tag: "dt" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.headingLine.label))])
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                { staticClass: "text-right", attrs: { cols: "6", tag: "dd" } },
                [_c("strong", [_vm._v(_vm._s(_vm.headingLine.value))])]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "price-summary__section" },
            [
              _c(
                "c-row",
                {
                  staticClass: "price-summary__section-line",
                  attrs: { tag: "dl" }
                },
                [
                  _c("c-col", { attrs: { cols: "12", tag: "dt" } }, [
                    _vm._v(_vm._s(_vm.error))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("c-collapse-trigger", {
            staticClass: "price-summary__trigger",
            attrs: { id: "price-summary-sections" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var expanded = ref.expanded
                    return [
                      _c("c-icon", {
                        class: { expanded: expanded },
                        attrs: { "symbol-id": "arrow-chevron" }
                      }),
                      _vm._v(
                        "\n      " + _vm._s(_vm.minimumLine.label) + "\n      "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.minimumLine.value))])
                    ]
                  }
                }
              ],
              null,
              false,
              1961852657
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-collapse",
        { attrs: { id: "price-summary-sections" } },
        _vm._l(_vm.sections, function(section, sectionIdx) {
          return _c(
            "div",
            { key: sectionIdx, staticClass: "price-summary__section" },
            _vm._l(section, function(line, lineIdx) {
              return _c(
                "c-row",
                {
                  key: lineIdx,
                  staticClass: "price-summary__section-line",
                  attrs: { tag: "dl" }
                },
                [
                  _c("c-col", { attrs: { cols: "6", tag: "dt" } }, [
                    _vm._v(_vm._s(line.label))
                  ]),
                  _vm._v(" "),
                  _c("c-col", { attrs: { cols: "6", tag: "dd" } }, [
                    _vm._v("\n          " + _vm._s(line.value) + "\n        ")
                  ])
                ],
                1
              )
            }),
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("skeleton-loader", {
            staticClass: "price-summary__skeleton-loader"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("footer", [
        _vm.footerText
          ? _c("div", {
              staticClass: "price-summary__footer",
              domProps: { innerHTML: _vm._s(_vm.footerText) }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }