var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "in-viewport",
        rawName: "v-in-viewport",
        value: { enter: _vm.onViewportEnter },
        expression: "{ enter: onViewportEnter }"
      }
    ],
    ref: "lottieContainer"
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }