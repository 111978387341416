/**
 * Use as injector of theme variant values.
 *
 */
const providerKey = 'CHECKOUT_FLOW';

/**
 * Use this mixin with overrides to access
 * top level reference and functionality to
 * checkout pages and components.
 *
 */
export default {
  provide() {
    return {
      [providerKey]: {
        submit: () => this.$refs.checkoutForm.submit(),

        /**
         * Sets the next page button visibility state.
         *
         * @param {Boolean} state The visibility state of the next button reference element.
         *
         */
        toggleSubmitButton: state => {
          this.$refs.nextbtn.hidden = !state;
        },
        setDisableSubmitButton: state => {
          this.$refs.nextbtn.disabled = state;
        }
      }
    };
  }
};

export { providerKey };
