<script>
import { mapMutations, mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../store/basket';

import {
  CButton,
  CModal,
  CAccordion,
  CAccordionItem,
  CAccordionTrigger,
  CAccordionContent
} from 'olympus/components';
import MemberDescriptionList from './member-description-list.vue';
import SubscriptionValueAddedServicesSelector from './subscription-value-added-services-selector.vue';

export default {
  name: 'subscription-offering-card',
  components: {
    CButton,
    CModal,
    CAccordion,
    CAccordionItem,
    CAccordionTrigger,
    CAccordionContent,

    MemberDescriptionList,

    'subscription-value-added-services-selector': SubscriptionValueAddedServicesSelector
  },
  methods: {
    ...mapMutations(['SHOW_DIALOG']),
    ...mapActions({
      addToCart: 'addToCart',
      addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION
    })
  }
};
</script>
