function getCookie(name) {
  let start;
  let end;

  if (document.cookie.length > 0) {
    start = document.cookie.indexOf(`${name}=`);
    if (start !== -1) {
      start = start + name.length + 1;
      end = document.cookie.indexOf(';', start);
      if (end === -1) end = document.cookie.length;
      return unescape(document.cookie.substring(start, end));
    }
  }
  return '';
}

function setCookie(name, value, days) {
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + days);

  const strExpirationDate =
    days === null ? '' : `;expires=${expirationDate.toGMTString()}`;

  document.cookie = `${name}=${escape(value)}${strExpirationDate};path=/`;
}

function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export { setCookie, getCookie, removeCookie };
