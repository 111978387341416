var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.useCheckoutModuleStyling },
    [
      _c(
        "transition-group",
        { attrs: { name: "fade", tag: "div" } },
        _vm._l(_vm.sections, function(section) {
          return _c(
            "div",
            {
              key: section.title,
              staticClass: "checkout-finance-summary__section"
            },
            [
              _c(
                "div",
                { staticClass: "checkout-finance-summary__section-title" },
                [_vm._v("\n        " + _vm._s(section.title) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { attrs: { tag: "dl", "no-gutters": "" } },
                [
                  _vm._l(section.items, function(item, index) {
                    return [
                      _c(
                        "c-col",
                        {
                          key: "dt-" + item.label + "-" + index,
                          staticClass:
                            "checkout-finance-summary__section-content",
                          attrs: { cols: "6", tag: "dt" }
                        },
                        [_vm._v(_vm._s(item.label))]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-col",
                        {
                          key: "dd-" + item.label + "-" + index,
                          staticClass: "text-right",
                          attrs: { cols: "6", tag: "dd" }
                        },
                        [_vm._v(_vm._s(item.value))]
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }