var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c("c-col", { staticClass: "cm-c-form__col" }, [
            _c("h3", { staticClass: "cm-c-form__header" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.numberConfigVM.activateSIMHeading) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.isExistingNumber
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "cm-c-form__col" },
                [
                  _c(
                    "c-form-radio-group",
                    {
                      attrs: { name: "actsim", stacked: "" },
                      model: {
                        value: _vm.selectedSimOption,
                        callback: function($$v) {
                          _vm.selectedSimOption = $$v
                        },
                        expression: "selectedSimOption"
                      }
                    },
                    [
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "existingSimCard",
                            "checked-border": "",
                            value: _vm.simOptionType.existingSimCard
                          },
                          on: {
                            change: function($event) {
                              return _vm.OnSimOptionChanged(
                                _vm.simOptionType.existingSimCard
                              )
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.numberConfigVM.existingActiveSIMText)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "calendar",
                            "checked-border": "",
                            value: _vm.simOptionType.shipSimCard
                          },
                          on: {
                            change: function($event) {
                              return _vm.OnSimOptionChanged(
                                _vm.simOptionType.shipSimCard
                              )
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.numberConfigVM.acceptActivateSIMText)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "newSimCard",
                            "checked-border": "",
                            value: _vm.simOptionType.newSimCard
                          },
                          on: {
                            change: function($event) {
                              return _vm.OnSimOptionChanged(
                                _vm.simOptionType.newSimCard
                              )
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.numberConfigVM.refuseActivateSIMText)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "cm-c-form__col" },
                [
                  _c(
                    "c-form-radio-group",
                    {
                      attrs: { name: "actsim", stacked: "" },
                      model: {
                        value: _vm.selectedSimOption,
                        callback: function($$v) {
                          _vm.selectedSimOption = $$v
                        },
                        expression: "selectedSimOption"
                      }
                    },
                    [
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "calendar",
                            "checked-border": "",
                            value: _vm.simOptionType.shipSimCard
                          },
                          on: {
                            change: function($event) {
                              return _vm.OnSimOptionChanged(
                                _vm.simOptionType.shipSimCard
                              )
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.numberConfigVM.acceptActivateSIMText)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "newSimCard",
                            "checked-border": "",
                            value: _vm.simOptionType.newSimCard
                          },
                          on: {
                            change: function($event) {
                              return _vm.OnSimOptionChanged(
                                _vm.simOptionType.newSimCard
                              )
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.numberConfigVM.refuseActivateSIMText)
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _vm.selectedSimOption == _vm.simOptionType.newSimCard
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "cm-c-form__col" },
                [
                  _c("c-input-group", {
                    attrs: {
                      "input-error": _vm.simNumberError,
                      "place-holder-label":
                        _vm.numberConfigVM.simNumberPlaceholderText
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.stopPropagation()
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.product.activateSimCardNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.product, "activateSimCardNumber", $$v)
                      },
                      expression: "product.activateSimCardNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }