// TODO DKT-11861: This file should be added to the global store as a store module
/*
 * This module is used on the confirm page
 * to communicate between the checkbox and the confirm-button.
 */
import { put } from 'webshop/api/api.js';
import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';
import { MUTATIONS as COMMON_MUTATIONS } from 'webshop/store/shop/mutation-types.js';
import { MUTATIONS as HOME_OFFICE_MUTATIONS } from 'webshop/store/home-office';
import { SHIPPING_METHODS } from 'webshop/enums/pickup-points';

export const GETTERS = {
  IS_VALID_PAGE: 'IS_VALID_PAGE',
  IS_SIGNATURE_VALID: 'IS_SIGNATURE_VALID',
  // FYI: We will have a merge error here. We need both changes in here. And IS_VALID_FORM should be IS_VALID_PAGE instead.
  IS_TERMS_ACCEPTED: 'IS_TERMS_ACCEPTED',
  IS_EMPLOYEE_INFORMATION_VALID: 'IS_EMPLOYEE_INFORMATION_VALID',
  IS_EMPLOYEE_CONFIG_VALID: 'IS_EMPLOYEE_CONFIG_VALID',
  CHECKOUT_FORM_DATA: 'CHECKOUT_FORM_DATA',
  CHECKOUT_ENDPOINT: 'CHECKOUT_ENDPOINT'
};

export const MUTATIONS = {
  SET_TERM_VALIDATION: 'SET_TERM_VALIDATION',
  SET_SIGNATURE_VALIDITY: 'SET_SIGNATURE_VALIDITY',
  SET_EMPLOYEE_INFORMATION_VALIDATION: 'SET_EMPLOYEE_INFORMATION_VALIDATION',
  SET_EMPLOYEE_CONFIGURATION_VALIDATION:
    'SET_EMPLOYEE_CONFIGURATION_VALIDATION',
  SET_SHIPPING_VALIDATION: 'SET_SHIPPING_VALIDATION',
  SET_CHECKOUT_FORM_DATA: 'SET_CHECKOUT_FORM_DATA',
  SET_CHECKOUT_ENDPOINT: 'SET_CHECKOUT_ENDPOINT'
};

export const ACTIONS = {
  SUBMIT_CHECKOUT_FORM: 'SUBMIT_CHECKOUT_FORM',
  UPDATE_CONSENT_STATUS: 'UPDATE_CONSENT_STATUS'
};

export default {
  state: {
    /*
     * A form in the checkout-form is (in code) by default valid until "told" otherwise.
     * Currently, when the "terms-and-conditions.vue" component is mounted, it invokes a mutation
     * such that the form is no longer valid.
     */
    isSignatureValid: true,
    termsAccepted: true,
    employeeInformationValid: true,
    employeeConfigValid: true,
    shippingValid: true,
    formData: {},
    submitEndpoint: ''
  },

  getters: {
    [GETTERS.IS_TERMS_ACCEPTED]: state => state.termsAccepted,

    [GETTERS.IS_EMPLOYEE_INFORMATION_VALID]: state =>
      state.employeeInformationValid,

    [GETTERS.IS_EMPLOYEE_CONFIG_VALID]: state =>
      state.employeeConfigurationValid,

    [GETTERS.IS_SIGNATURE_VALID]: state => state.isSignatureValid,

    [GETTERS.IS_VALID_PAGE]: state =>
      state.termsAccepted &&
      state.employeeInformationValid &&
      state.employeeConfigValid &&
      state.shippingValid,

    [GETTERS.CHECKOUT_FORM_DATA]: state => state.formData,

    [GETTERS.CHECKOUT_ENDPOINT]: state => state.submitEndpoint
  },

  mutations: {
    [MUTATIONS.SET_TERM_VALIDATION](state, termsAccepted) {
      state.termsAccepted = Boolean(termsAccepted);
    },

    [MUTATIONS.SET_EMPLOYEE_INFORMATION_VALIDATION](
      state,
      employeeInformationValid
    ) {
      state.employeeInformationValid = Boolean(employeeInformationValid);
    },

    [MUTATIONS.SET_EMPLOYEE_CONFIGURATION_VALIDATION](
      state,
      employeeConfigurationValid
    ) {
      state.employeeConfigurationValid = Boolean(employeeConfigurationValid);
    },

    [MUTATIONS.SET_SHIPPING_VALIDATION](state, shippingValid) {
      state.shippingValid = Boolean(shippingValid);
    },

    [MUTATIONS.SET_SIGNATURE_VALIDITY](state, isSignatureValid) {
      state.isSignatureValid = Boolean(isSignatureValid);
    },

    [MUTATIONS.SET_CHECKOUT_FORM_DATA](state, formData) {
      state.formData = {
        data: {
          type: state.submitEndpoint,
          attributes: { ...state.formData?.data?.attributes, ...formData }
        }
      };
    },

    [MUTATIONS.SET_CHECKOUT_ENDPOINT](state, endPoint) {
      state.submitEndpoint = endPoint;
    }
  },

  actions: {
    [ACTIONS.SUBMIT_CHECKOUT_FORM]({ commit, state, rootState }) {
      commit(COMMON_MUTATIONS.SHOW_SPINNER);
      switch (state.submitEndpoint) {
        case HOME_OFFICE_STEPS.EMPLOYEE_INFORMATION:
          return put('/homeoffice/employeeinformation', state.formData, {
            headers: {
              accept: 'application/vnd.api+json'
            }
          }).catch(error => {
            if (error.status === 409) {
              commit(HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true, {
                root: true
              });
            }
            commit(COMMON_MUTATIONS.HIDE_SPINNER);
            return Promise.reject(error);
          });
        case HOME_OFFICE_STEPS.PRODUCT_CONFIGURATION:
          return put(
            '/homeoffice/productconfiguration',
            {
              data: {
                type: state.formData.data.type,
                attributes: {
                  activationDate:
                    state.formData.data.attributes.activationDate?.toLocaleDateString(
                      'en-CA'
                    ),
                  activationDateAsap:
                    state.formData.data.attributes.activationDateAsap,
                  cancelationAssist:
                    state.formData.data.attributes.cancelationAssist,
                  hasPlugTechnicianNotNeeded:
                    state.formData.data.attributes.hasPlug,
                  lineOwner: state.formData.data.attributes.lineOwner,
                  currentProvider:
                    state.formData.data.attributes.currentProvider?.value ||
                    state.formData.data.attributes.currentProvider,
                  ownerEmail: state.formData.data.attributes.ownerEmail
                }
              }
            },
            {
              headers: {
                accept: 'application/vnd.api+json'
              }
            }
          ).catch(error => {
            commit(HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true, {
              root: true
            });
            commit(COMMON_MUTATIONS.HIDE_SPINNER);
            return Promise.reject(error);
          });
        case HOME_OFFICE_STEPS.CHECKOUT_SHIPPING: {
          let shipppingMethodAttributes = {
            blockId: rootState.shipping.shippingMethod?.blockId,
            shippingMethod: rootState.shipping.shippingMethod?.shippingMethod,
            deliveryLocationCode:
              rootState.shipping.shippingMethod?.deliveryLocationCode,
            ignorePickupPoint:
              rootState.shipping.shippingMethod?.ignorePickupPoint
          };

          if (
            rootState.shipping.shippingMethod?.name ===
            SHIPPING_METHODS.HOME_OFFICE
          ) {
            shipppingMethodAttributes = {
              ...shipppingMethodAttributes,
              altAddressId: state.formData?.data?.attributes.addressId,
              firstName: state.formData?.data?.attributes.firstName,
              lastName: state.formData?.data?.attributes.lastName,
              companyName: state.formData?.data?.attributes.company
            };
          }

          return put(
            '/carts/shipping/method',
            {
              data: {
                id: null,
                type: 'ShippingMethodUpdateRequest',
                attributes: shipppingMethodAttributes
              }
            },
            {
              headers: {
                accept: 'application/vnd.api+json'
              }
            }
          ).catch(error => {
            commit(HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true, {
              root: true
            });
            commit(COMMON_MUTATIONS.HIDE_SPINNER);
            return Promise.reject(error);
          });
        }
        case HOME_OFFICE_STEPS.CHECKOUT_CONFIRMATION:
          return put('/homeoffice/submitorder', null, {
            headers: {
              accept: 'application/vnd.api+json'
            }
          }).catch(error => {
            commit(HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR, true, {
              root: true
            });
            commit(COMMON_MUTATIONS.HIDE_SPINNER);
            return Promise.reject(error);
          });
        default:
          break;
      }
    },

    [ACTIONS.UPDATE_CONSENT_STATUS](_, status) {
      return put(
        '/carts/groups/items/consents',
        {
          data: {
            type: 'ConsentUpdateRequest',
            attributes: {
              consentGiven: status
            }
          }
        },
        {
          headers: {
            accept: 'application/vnd.api+json',
            'Content-Type': 'application/json'
          }
        }
      );
    }
  }
};
