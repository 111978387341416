<script>
import { GETTERS } from 'webshop/components/product/store/product-page';
import { SPLASH_TYPES } from 'webshop/enums/splash-types';
import { mapGetters } from 'vuex';

import withTemplate from 'theme/templates/product-details.html';

import ProductShortDescription from './product-short-description.vue';

/**
 * Displays product details:
 *  - name
 *  - brand
 *  - summary
 *  - product description component
 */
export default withTemplate({
  name: 'product-details',

  components: {
    ProductShortDescription
  },

  props: {
    variantSummary: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      product: GETTERS.GET_PRODUCT,
      selectedVariant: GETTERS.GET_SELECTED_VARIANT,
      productRelations: GETTERS.GET_PRODUCT_RELATIONS
    }),

    brand() {
      return this.product.brand;
    },

    badgeText() {
      return this.selectedVariant && this.selectedVariant.badgeText
        ? this.selectedVariant.badgeText.item2
        : '';
    },

    badgeClassList() {
      let type =
        this.selectedVariant && this.selectedVariant.badgeText
          ? this.selectedVariant.badgeText.item1
          : '';

      switch (type) {
        //TODO DKT-1437: Remove comments after you identify how should look for all types. Now this supports the current design and the two new colours requested. Figure out why badge and splash styles are getting mixed and separate them.
        //case SPLASH_TYPES.OFFER:
        //return 'c-badge c-badge--offer';
        //case SPLASH_TYPES.SPECIAL_OFFER:
        //return 'c-badge c-badge--sp-offer';
        case SPLASH_TYPES.PRESALE:
          return 'c-badge cm-c-splash--presale';
        case SPLASH_TYPES.NEWS:
          return 'c-badge cm-c-splash--news';
        //case SPLASH_TYPES.OUT_OF_STOCK:
        //return 'c-badge c-badge--out';
        case SPLASH_TYPES.BLACK_FRIDAY:
          return 'c-badge cm-c-splash--black-friday';
        case SPLASH_TYPES.OFFER:
          return 'c-badge cm-c-splash--offer';
        case SPLASH_TYPES.SPECIAL_OFFER:
          return 'c-badge cm-c-splash--sp-offer';
        default:
          return 'c-badge c-badge--secondary';
      }
    },

    ribbonText() {
      return this.selectedVariant ? this.selectedVariant.ribbonText : '';
    },

    getDescription() {
      if (this.product?.description) {
        return this.product.description;
      }

      return this.selectedVariant?.campaignDescription;
    }
  }
});
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'src/shared/styles/tools/_tools.target.scss';

.product-details {
  // IE fallback.
  // (modern css below)
  display: flex;
  flex-direction: column;
  order: 1;
  width: 100%;

  // Modern css.
  // Due to the mobile layout (description is separated from other info),
  // the order of the containing element is ignored,
  // respecting the order of the children instead.
  display: contents;

  // .product-details__heading
  &__heading {
    order: map-deep-get($product-order, 'heading', 'xs');
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: center;

    /**
     * TODO DKT-1433: Remove when Edge 44 is not supported anymore
     *
     * HACK: overriding inherited and too specific styling
     * from product-wizard.vue
     */
    @include targetEdge() {
      width: 100% !important;
    }

    @include mq($screen-md, min) {
      text-align: left;
      order: map-deep-get($product-order, 'heading', 'md');
    }

    &__brand {
      width: 100%;
      font-size: $font-size-h6;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;

      a {
        color: $color-black;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__title {
      width: 100%;
      font-family: $global-font-family-2;
      font-size: $font-size-m;
      font-weight: 700;
      line-height: 1.3em;

      @include mq($screen-md, min) {
        font-size: $font-size-l;
      }
    }

    &__summary {
      width: 100%;
      order: -1;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;

      @include mq($screen-md, min) {
        justify-content: flex-start;
        align-items: center;
      }

      &__text {
        display: block;
        width: 100%;
        order: -1;
        flex-shrink: 0;
        font-weight: bold;

        @include mq($screen-md, min) {
          display: inline;
          width: auto;
          order: 1;
        }
      }

      .c-badge {
        margin: $u-200 $u-200 0 0;

        @include mq($screen-md, min) {
          margin: 0 $u-200 0 0;
        }
      }
    }
  }
}
</style>
