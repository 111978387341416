/**
 * Merges object key-values into string, based on mustache
 * syntax.
 *
 * @param  {String} property The source for the interpolation.
 * @param  {Object} values   The interpolation values.
 * @return {String}          Property with interpolated values.
 *
 */
export const populateContentProperty = (property, values) => {
  if (typeof property !== 'string') {
    throw new Error(`Cannot populate property of type [${typeof property}].`);
  }

  return property.replace(
    /(?:{{|<%)\s*([a-zA-Z]+)\s*(?:}}|%>)/g,
    function (_match, key) {
      return typeof values[key] !== 'undefined' ? values[key] : '';
    }
  );
};

/**
 * Gets the value of a content property if an object passed
 * or returns the string value.
 *
 * @param  { Object | String } property Property object or string.
 * @return { String }          Property value.
 *
 */
export const getContentPropertyValue = property => {
  let value;

  if (property) {
    const { propertyDataType } = property;

    if (propertyDataType === 'PropertyLongString') {
      value = property.value;
    } else if (propertyDataType === 'PropertyContentArea') {
      value = property.expandedValue;
    } else if (propertyDataType === 'PropertyBlock') {
      value = property.value;
    } else if (propertyDataType === 'PropertyUrl') {
      value = property.value;
    } else if (propertyDataType === 'PropertyXhtmlString') {
      value = property.value;
    } else if (propertyDataType === 'PropertyContentReference') {
      if (property.expandedValue) {
        if (property.expandedValue.contentType.indexOf('Image') !== -1) {
          value = property.expandedValue.url;
        } else {
          value = property.expandedValue;
        }
      } else {
        value = property.value;
      }
    } else if (propertyDataType === 'PropertyLinkCollection') {
      value = property.expandedValue;
    } else if (propertyDataType === 'CommonPropertyListProperty') {
      value = property.value;
    }
  }

  return value || property;
};

export default {
  methods: {
    populateContentProperty,
    getContentPropertyValue
  }
};
