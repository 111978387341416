var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass: "confirmation-details",
      attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h3", { staticClass: "cm-c-form__header" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.content.contactInformationSectionHeader) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _vm.customerData.name && _vm.customerData.name.value
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c("c-col", { attrs: { cols: "6", xs: "12" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.content.nameText))])
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-text-right-md",
                  attrs: { cols: "6", xs: "12" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.customerData.name.value) + "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customerData.phoneNumber && _vm.customerData.phoneNumber.value
        ? _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c("c-col", { attrs: { cols: "6", xs: "12" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.content.phoneNumberText))])
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-text-right-md",
                  attrs: { cols: "6", xs: "12" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.customerData.phoneNumber.value) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customerData.email && _vm.customerData.email.value
        ? _c(
            "c-row",
            { staticClass: "mb-2" },
            [
              _c("c-col", { attrs: { cols: "6", xs: "12" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.content.emailText))])
              ]),
              _vm._v(" "),
              _c(
                "c-col",
                {
                  staticClass: "cm-text-right-md",
                  attrs: { cols: "6", xs: "12" }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.customerData.email.value) + "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.divaSplitOrderDisclaimerEnabled
        ? _c(
            "c-row",
            { attrs: { tag: "p", "no-gutters": "", justified: "" } },
            [
              _c(
                "c-information-box",
                {
                  staticClass: "c-checkout-receipt__disclaimer",
                  attrs: { icon: "contact-information", type: "informative" }
                },
                [
                  _vm.content.divaSplitOrderDisclaimerTitle
                    ? _c("strong", { attrs: { name: "header" } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.content.divaSplitOrderDisclaimerTitle) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.content.divaSplitOrderDisclaimerText
                      )
                    }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }