<script>
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import ProductCardMetaMixin from 'olympus/mixins/product-card-meta-mixin.js';
import withTemplate from 'theme/templates/product-card.html';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'axios';

import SrcsetImage from '../../generic/srcset-image.vue';
import ProductCategoryGallery from '../product-category-gallery.vue';
import ProductInfo from '../product-info.vue';
import ColorMixin from 'webshop/mixins/color-mixin';
import ProductUrlHashMixin from 'webshop/mixins/product-url-hash-mixin';

import {
  providerKey as ToastProviderKey,
  eventToastKeys
} from '../../../providers/toast-provider.js';

import { ACTIONS as BASKET_ACTIONS } from '../../../store/basket';
import CCheckbox from '../../../../../../shared/components/c-checkbox/c-checkbox.vue';
import { CButton } from 'olympus/components';

export default withTemplate({
  name: 'product-card',

  mixins: [
    ColorMixin,
    ProductUrlHashMixin,
    ContentPropertyMixin,
    ProductCardMetaMixin
  ],

  inject: [ToastProviderKey],

  props: {
    selectLabel: {
      type: String
    },
    compareLabel: {
      type: String
    },
    addToBasketButtonText: {
      type: String
    },
    addToBasketButtonEnabled: {
      type: Boolean,
      value: false
    },
    addToBasketButtonClickEvent: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object
    },
    pricePlanCode: {
      type: String
    },
    compareDisabled: {
      type: Boolean
    },
    productPosition: {
      type: Number
    },
    subscriptionText: {
      type: String
    },
    link: {
      type: Boolean,
      default: true
    },
    storageSelectorEnabled: {
      type: Boolean,
      default: false
    },
    minimumPriceDisabled: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: String
    },
    meta: {
      type: Array,
      default: () => []
    },
    isNewProductCard: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CCheckbox,
    CButton,
    'srcset-image': SrcsetImage,
    'product-category-gallery': ProductCategoryGallery,
    'product-info': ProductInfo
  },

  data: function () {
    return {
      id: null,
      selectedVariant: {
        isDefaultVariant: true,
        code: '',
        name: '',
        minimumStorageCapacity: '',
        price: {
          value: ''
        },
        savedAmount: {
          value: ''
        },
        badgeText: {}
      },
      specifications: [],
      fetchingSpecifications: false,
      submitOptions: {
        classes: {
          success: 'success',
          error: 'error'
        },
        showSuccessIcon: true,
        addingToBasket: false,
        doButtonAnimation: false
      }
    };
  },

  computed: {
    ...mapGetters([
      'compareCount',
      'compareMax',
      'compareMin',
      'isCompareItem',
      'basket'
    ]),

    hasInstallments() {
      return this.product.installmentPeriod !== 1;
    },

    url() {
      return this.product.productUrl
        ? this.product.productUrl + this.variantFragment
        : null;
    },

    price() {
      return this.product.monthlyPrice !== null
        ? this.product.monthlyPrice.split(' ')[0]
        : null;
    },

    priceText() {
      return this.product.monthlyPrice !== null
        ? this.product.monthlyPrice.split(' ')[1]
        : null;
    },

    selectedColor() {
      return this.product.availableColors.find(x =>
        x.variants.find(v => v.code === this.selectedVariant.code)
      );
    },

    storages() {
      return this.selectedColor.variants.map(x => x.minimumStorageCapacity);
    },

    variantFragment() {
      if (!this.selectedColor?.colorName) {
        return '';
      }

      return (
        '#' +
        this.buildProductUrlFragment(
          this.selectedColor.colorName,
          this.selectedVariant?.minimumStorageCapacity,
          undefined,
          this.pricePlanCode
        )
      );
    },

    discount() {
      if (!this.selectedVariant) {
        return null;
      }
      if (
        this.selectedVariant.savedAmount &&
        this.selectedVariant.savedAmount.value > 0
      ) {
        return this.selectedVariant.savedAmount;
      }

      return null;
    },

    defaultImage() {
      let img = '';
      if (this.selectedColor) {
        if (
          this.selectedColor.galleryImages &&
          this.selectedColor.galleryImages.length > 0
        ) {
          img = this.selectedColor.galleryImages[0];
        } else {
          img = this.selectedColor.defaultImage;
        }
      } else if (this.product.productImageUrl) {
        img = this.product.productImageUrl;
      }

      return img !== ''
        ? (img += `?width=${window.__APP__?.productPreviewImageWidth || 200}`)
        : '';
    },

    variantImages() {
      if (this.selectedColor) {
        return this.selectedColor.galleryImages;
      }
      return [];
    },

    componentType() {
      return this.link && this.url ? 'a' : 'div';
    },

    componentProps() {
      const props = {};
      if (this.url) {
        props.href = this.url;
      }
      return props;
    },

    variantCode() {
      const color = this.selectedVariant.code;
      const variant = this.selectedColor?.variants?.find(item => {
        return item.code === color;
      });
      return variant?.code || this.product.code;
    },

    ribbonText() {
      return this.selectedVariant.ribbonText || this.product.ribbonText;
    },

    hasColorGroups() {
      return this.product.availableColors.every(x => !!x.hexCode);
    },

    addButtonDisabled() {
      // TODO DKT-1453: we need to align product cards and product states.
      return (
        this.submitOptions.addingToBasket ||
        (this.product.state && this.product.state.interaction === 'NoChange')
      );
    },

    animationClass() {
      if (this.submitOptions.doButtonAnimation) {
        return this.submitOptions.showSuccessIcon
          ? this.submitOptions.classes.success
          : this.submitOptions.classes.error;
      }
      return '';
    },

    metaLines() {
      return this.populateMetaLines();
    }
  },

  created() {
    this.product.availableColors.forEach(colorGroup => {
      colorGroup.variants.forEach(variant => {
        if (variant.badgeText && this.showBadge(variant.badgeText)) {
          variant.badge = true;
        } else {
          variant.badge = false;
        }

        if (variant.isDefaultVariant) {
          this.selectedVariant = variant;

          // TODO DKT-1454: use real data
          this.storageSelected = this.storages[0];
        }
      });
    });
  },

  methods: {
    ...mapActions({
      setCompareItem: 'setCompareItem',
      trackProduct: 'trackProduct',
      addAccessory: BASKET_ACTIONS.ADD_ACCESSORY,
      addSubscription: BASKET_ACTIONS.ADD_SUBSCRIPTION,
      addService: BASKET_ACTIONS.ADD_SERVICE
    }),

    onCardClick(e) {
      if (e.target === this.$refs.compare) {
        this.setCompareItem(this.product);
      } else if (this.url) {
        this.pushEEC(this.product, this.variantFragment);
      }
    },

    showBadge(badgeTextObj) {
      return badgeTextObj
        ? !Object.keys(badgeTextObj).reduce(
            (res, k) =>
              res &&
              !(
                !!badgeTextObj[k] ||
                badgeTextObj[k] === false ||
                !isNaN(parseInt(badgeTextObj[k], 10))
              ),
            true
          )
        : {};
    },

    selectColor(color) {
      var selected = color.variants[0];
      color.variants.forEach(variant => {
        if (variant.isDefaultVariant) {
          selected = variant;
        }
      });
      this.selectedVariant = selected;
    },

    checkColor(color) {
      return this.isColorWhite(color);
    },
    //Checks if GTM is fully loaded and working
    isGtmLoaded() {
      let gtm_started_event = window.dataLayer?.find(
        element => element['gtm.start']
      );

      if (!gtm_started_event) {
        return false; // Not even the GTM inline config has executed
      }

      if (!gtm_started_event['gtm.uniqueEventId']) {
        return false; // GTM inline config has ran, but main GTM js is not loaded (likely AdBlock, NoScript, URL blocking etc.)
      }

      return true; // GTM is fully loaded and working
    },

    pushEEC(product, variantFragment) {
      var url =
        '/api/olympus/commerce/catalog/products/variants/' +
        this.selectedVariant.code +
        '/price';

      if (!this.link) {
        this.onSelectVariant();
        return;
      }

      this.trackProduct(product);
      window.dataLayer = window.dataLayer || [];

      if (!this.isGtmLoaded()) {
        document.location = product.productUrl + variantFragment;
      }

      get(url, {
        params: {
          installmentPeriod: 1
        }
      }).then(json => {
        const variantColor = this.selectedColor.colorName?.toLowerCase();
        const variantCapacity = product?.productCapacity;
        const variantScreenInches = product?.productScreenSizeInches;

        const variantData = `${variantColor}|${variantCapacity}gb|${variantScreenInches}`;

        window.dataLayer.push({
          ecommerce: {
            click: {
              actionField: { list: 'category page' },
              products: [
                {
                  name: product.productName.toLowerCase(),
                  id: product.productId,
                  price: (json.data && json.data.formattedValue) || '0',
                  variant: variantData,
                  category: product?.productCategory?.toLowerCase(),
                  brand: product?.productBrand?.toLowerCase(),
                  position: this.productPosition
                }
              ]
            }
          },
          event: 'ecommerce',
          eventCallback: function () {
            document.location = product.productUrl + variantFragment;
          },
          eventTimeout: 2000
        });
      });
    },

    storageUpdate(variant) {
      this.selectVariant = variant;
    },

    onSelectVariant() {
      const payload = {
        product: this.product.productId,
        color: this.selectedColor.colorName,
        variant: this.selectedVariant.code
      };
      this.$emit('select', payload);
    },

    // Backend is unable to return prodtName without brandName, so we remove it from productName manually
    productName(name, brand) {
      return name.replace(`${brand} `, '');
    },

    // TODO DKT-10264: Remove this method when the new design of Callme product cards is implemented.
    getTooltipsExceedingWindow() {
      const tooltipsArray = document.querySelectorAll('.cm-c-product-tooltip');

      tooltipsArray.forEach(tooltip => {
        const bounding = tooltip.getBoundingClientRect();

        const tooltipExceedsWindow =
          bounding.right >
          (window.innerWidth || document.documentElement.clientWidth);

        if (tooltipExceedsWindow) {
          tooltip.classList.add('cm-c-product-tooltip--rightmost-placement');
        }
      });
    },

    fetchSpecifications() {
      var url = '/api/olympus/catalog/specifications';
      if (!this.fetchingSpecifications && this.specifications.length === 0) {
        this.fetchingSpecifications = true;

        get(url, {
          params: {
            code: this.product.productId
          }
        })
          .then(json => {
            this.specifications = json.data;
          })
          .then(() => {
            this.getTooltipsExceedingWindow();
          });
      }
    },

    addProductToBasket(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.submitOptions.addingToBasket) {
        return;
      }

      /**
       * Keys for strategy are named as AccessTechnology
       * in backend and is due to be refactored, until then
       * we hardcode them here.
       *
       */
      //TODO DKT-1454: filter on subtype when introduced so to know when it's a edc or a normal subscription
      const { action, payload } = {
        Subscription: {
          action: this.addSubscription,
          payload: {
            code: this.variantCode,
            groupId: this.groupId,
            upsale: false,
            relationType: 'extraDataCard'
          }
        },
        Service: {
          action: this.addService,
          payload: {
            vasCode: this.variantCode,
            groupId: this.groupId
          }
        },
        Accessories: {
          action: this.addAccessory,
          payload: {
            code: this.variantCode,
            quantity: 1,
            installmentType: this.product.installmentPeriod,
            upsale: false,
            skipSpinner: true
          }
        }
      }[this.product.type];

      this.submitOptions.addingToBasket = true;
      this.$emit('update', { networking: true });

      action(payload)
        .then(() => {
          this.submitOptions.showSuccessIcon = true;
          this.submitOptions.addingToBasket = false;

          this.emitState();
          this.showSuccessToast();
        })
        .catch(() => {
          this.submitOptions.showSuccessIcon = false;
          this.submitOptions.addingToBasket = false;

          this.emitState();
          this.showErrorToast();
        });
    },

    showSuccessToast() {
      const toastData = this[ToastProviderKey].getToastData(
        this.added
          ? eventToastKeys.ITEM_REMOVED_TO_BASKET_SUCCESS
          : eventToastKeys.ITEM_ADDED_TO_BASKET_SUCCESS
      );
      this.$addToast(
        {
          title: toastData.title,
          paragraph: toastData.subTitle,
          duration: 5000
        },
        title =>
          this.populateContentProperty(title, {
            product: this.selectedVariant.name
          })
      );
    },

    showErrorToast() {
      const toastData = this[ToastProviderKey].getToastData(
        eventToastKeys.ITEM_ADDED_TO_BASKET_ERROR
      );
      this.$addToast({
        title: toastData.title,
        paragraph: toastData.subTitle,
        getFormattedTitle: () =>
          this.populateContentProperty(toastData.title, {
            product: this.selectedVariant.name
          }),
        state: 'error',
        duration: 5000
      });
    },

    emitState() {
      this.$emit('update', { networking: this.submitOptions.addingToBasket });
      this.btnAnimate();
    },

    btnAnimate() {
      if (!this.submitOptions.addingToBasket) {
        this.submitOptions.doButtonAnimation = true;

        setTimeout(() => {
          this.submitOptions.doButtonAnimation = false;
        }, 1200);
      }
    },

    populateMetaLines() {
      return this.product
        ? this.productCardMetaData(
            {
              installmentPeriod: this.product.installmentPeriod,
              minimumPrice: this.product.minimumPrice,
              fullPrice: this.product.fullPrice,
              description: this.product.description
            },
            this.meta
          )
        : [];
    }
  }
});
</script>
