var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-card-product__gallery-viewport" }, [
    _c(
      "div",
      {
        staticClass: "cm-c-card-product__gallery-container",
        style: _vm.containerStyles
      },
      [
        _c(
          "transition",
          {
            attrs: { name: "slide", mode: "out-in" },
            on: {
              "after-enter": _vm.afterEnter,
              "before-leave": _vm.beforeLeave,
              leave: _vm.leave
            }
          },
          [
            !!_vm.currentImgSrc
              ? _c("img", {
                  key: _vm.currentImgSrc,
                  ref: "image",
                  class: {
                    "--left": _vm.direction == "left",
                    "--loaded": _vm.loaded
                  },
                  attrs: {
                    src: _vm.currentImgSrc,
                    alt: _vm.productName,
                    loading: "lazy"
                  },
                  on: { load: _vm.onImgLoad }
                })
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.hasGallery
          ? _c("button", {
              staticClass:
                "\n        cm-c-card-product__gallery-nav cm-c-card-product__gallery-nav--prev\n      ",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.prev($event)
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasGallery
          ? _c("button", {
              staticClass:
                "\n        cm-c-card-product__gallery-nav cm-c-card-product__gallery-nav--next\n      ",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.next($event)
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }