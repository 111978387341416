var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-card", class: _vm.classObject }, [
    _c(
      "div",
      {
        staticClass: "c-card__body",
        class: { "c-card__body--sections": _vm.$slots.sections },
        on: { click: _vm.handleClick }
      },
      [
        _vm.$slots.sections ? _vm._t("sections") : _vm._e(),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    ),
    _vm._v(" "),
    _vm.hasTongue
      ? _c("div", { staticClass: "c-card__tongue" }, [_vm._t("tongue")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }