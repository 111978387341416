import getSuggestions from '../../apps/webshop/shared/api/address-service';
import KEY_CODES from '../utils/key-codes';

// TODO: this need to be revisited
// to have it as proper mixin
export default {
  data() {
    return {
      suggestions: [],
      query: ''
    };
  },
  methods: {
    checkSelected(index) {
      return {
        selected: index === this.focusIndex
      };
    },

    selectAddress(index) {
      const addressRequest = this.suggestions[index];
      if (!addressRequest) {
        return false;
      }

      if (addressRequest.type === 'vejnavn') {
        this.addressRequest = null;
        this.query = addressRequest.displayName + ' ';
        this.suggestions = [];
        setTimeout(() => this.$refs.addressQuery.focus(), 10);
        getSuggestions({
          query: addressRequest.displayName,
          hasStreetName: true,
          callback: this.checkAddressCallback
        });
        return true;
      } else if (addressRequest.type === 'adgangsadresse') {
        this.addressRequest = null;
        this.query = addressRequest.displayName;
        this.suggestions = [];
        setTimeout(() => this.$refs.addressQuery.focus(), 10);
        getSuggestions({
          query: this.query,
          hasStreetName: true,
          addressId: addressRequest.id,
          callback: this.checkAddressCallback
        });
        return true;
      } else if (addressRequest.type === 'adresse') {
        this.query = addressRequest.displayName;
        this.suggestions = [];
        this.addressRequest = addressRequest;
        this.$globals.eventBus.$emit('address-selected', this.addressRequest);
        return true;
      }
      return false;
    },

    setFocus(e) {
      let keycode = e.keyCode;
      let listLn = this.suggestions.length;

      switch (keycode) {
        case KEY_CODES.DOWN:
          if (listLn - 1 > this.focusIndex) {
            this.focusIndex += 1;
          }
          break;
        case KEY_CODES.UP:
          if (this.focusIndex > 0) {
            this.focusIndex -= 1;
          }
          break;
        case KEY_CODES.ENTER:
          if (listLn < 1) {
            this.$globals.eventBus.$emit(
              'address-selected',
              this.addressRequest
            );
            break;
          }
          this.selectAddress(this.focusIndex);
          this.focusIndex = 0;
          e.target.blur();
          break;
        case KEY_CODES.ESC:
          this.suggestions = [];
          break;
        default:
          this.focusIndex = 0;
      }
    },

    checkAddressInput(e) {
      let query = e.target.value;
      let caretPosition = e.target.selectionStart;

      if (query.length >= 2) {
        getSuggestions({
          query: this.query,
          caretPosition: caretPosition,
          callback: this.checkAddressCallback
        });
      } else {
        this.suggestions = [];
      }
    },

    checkAddressCallback(addresses) {
      this.suggestions = addresses ?? [];
    }
  }
};
