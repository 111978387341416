import Vue from 'vue';
import { get, post } from '../../api/api.js';

const MUTATIONS = {
  SET_VAS_FOR_GROUP: 'MUTATIONS/SET_VAS_FOR_GROUP',
  SET_UPSALE_FOR_GROUP: 'SET_UPSALE_FOR_GROUP'
};

export const ACTIONS = {
  FETCH_VAS_FOR_GROUP: 'ACTIONS/FETCH_VAS_FOR_GROUP',
  ACCEPT_VAS_CONSENT: 'ACTIONS/ACCEPT_VAS_CONSENT',
  FETCH_UPSALE_FOR_GROUP: 'FETCH_UPSALE_FOR_GROUP'
};

export const GETTERS = {
  GET_VAS_FOR_GROUP: 'GETTERS/GET_VAS_FOR_GROUP',
  GET_UPSALE_FOR_GROUP: 'GET_UPSALE_FOR_GROUP'
};

/**
 * state {
 *  vas: { [groupId]: [vas1, vas2, ... vasX] },
 *  upsale: { [groupId]: [product1, product2, ... productX] }
 * }
 */
export default {
  state: {
    vas: {},
    upsale: {}
  },

  mutations: {
    [MUTATIONS.SET_VAS_FOR_GROUP](state, { groupId, data }) {
      Vue.set(state.vas, groupId, data);
    },

    [MUTATIONS.SET_UPSALE_FOR_GROUP](state, { groupId, data }) {
      Vue.set(state.upsale, groupId, data);
    }
  },

  actions: {
    [ACTIONS.FETCH_VAS_FOR_GROUP]({ commit }, { blockContextId, groupId }) {
      return get(`/cart/groups/${groupId}/vas/`, {
        params: { blockContextId }
      }).then(({ data }) => {
        commit(MUTATIONS.SET_VAS_FOR_GROUP, { groupId, data });
      });
    },

    [ACTIONS.ACCEPT_VAS_CONSENT](_, { groupId }) {
      return post(`/cart/groups/${groupId}/vas/accept`, {
        groupId
      });
    },

    [ACTIONS.FETCH_UPSALE_FOR_GROUP](
      { commit },
      { blockContextId, groupId, installment }
    ) {
      return get(`/cart/groups/${groupId}/upsale`, {
        params: { blockContextId, installment }
      }).then(({ data }) => {
        commit(MUTATIONS.SET_UPSALE_FOR_GROUP, { groupId, data });
      });
    }
  },

  getters: {
    [GETTERS.GET_VAS_FOR_GROUP]: state => groupId => state.vas[groupId],
    [GETTERS.GET_UPSALE_FOR_GROUP]: state => groupId => state.upsale[groupId]
  }
};
