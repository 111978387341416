var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-c-modal__content" }, [
    _c("div", { staticClass: "cm-c-modal__article" }, [
      _c("h3", { domProps: { innerHTML: _vm._s(_vm.headline) } }),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.description) } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cm-c-form" }, [
      _c("fieldset", [
        _c("div", { staticClass: "cm-c-form__row" }, [
          _c("div", { staticClass: "cm-c-form-control-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              staticClass: "cm-c-form-control cm-js-material-input",
              attrs: {
                id: _vm.nameField.id,
                name: _vm.nameField.name,
                required: "",
                type: "text",
                autocomplete: "new-password"
              },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-form-control__bar" }),
            _vm._v(" "),
            _c("label", {
              staticClass: "cm-c-form-control__label",
              domProps: { innerHTML: _vm._s(_vm.nameField.label) }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-form__row" }, [
          _c("div", { staticClass: "cm-c-form-control-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.phone,
                  expression: "phone"
                }
              ],
              staticClass: "cm-c-form-control cm-js-material-input",
              attrs: {
                id: _vm.phoneField.id,
                name: _vm.phoneField.name,
                required: "",
                type: "tel",
                autocomplete: "new-password"
              },
              domProps: { value: _vm.phone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.phone = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-form-control__bar" }),
            _vm._v(" "),
            _c("label", {
              staticClass: "cm-c-form-control__label",
              domProps: { innerHTML: _vm._s(_vm.phoneField.label) }
            })
          ])
        ]),
        _vm._v(" "),
        _vm.errors
          ? _c("div", { staticClass: "cm-c-form__row" }, [
              _c(
                "ul",
                _vm._l(_vm.errors, function(error, index) {
                  return _c("li", {
                    key: index,
                    staticClass: "cm-c-checkout__link",
                    domProps: { innerHTML: _vm._s(error) }
                  })
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cm-c-form__row cm-c-form__row--footer cm-text-right"
          },
          [
            _c("button", {
              staticClass: "c-btn c-btn--sm c-btn--inline",
              domProps: { innerHTML: _vm._s(_vm.buttons.cancel.label) },
              on: { click: _vm.HIDE_DIALOG }
            }),
            _vm._v(" "),
            _c("button", {
              staticClass: "c-btn c-btn--primary c-btn--sm c-btn--inline",
              attrs: { disabled: !_vm.name || !_vm.phone },
              domProps: { innerHTML: _vm._s(_vm.buttons.accept.label) },
              on: { click: _vm.accept }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }