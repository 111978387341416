<template>
  <!-- Internet carousel -->
  <div class="carousel">
    <c-row class="carousel__header">
      <c-col>
        <h1>{{ content.headline }}</h1>
        <p v-html="content.description" />
      </c-col>
    </c-row>
    <c-row v-if="!offerings.length && isLoading">
      <c-col
        v-for="(step, idx) in 2"
        :key="idx"
        cols="6"
        sm="12"
        class="carousel__page-step"
      >
        <skeleton-loader class="carousel__page-step__skeleton-loader" />
      </c-col>
    </c-row>
    <c-row v-else>
      <c-col v-if="offerings.length" class="carousel__wrapper">
        <c-carousel
          v-if="$resize && $mq.above(600)"
          v-bind="technologyCarouselOptions"
        >
          <template slot="slide" slot-scope="{ data: offering }">
            <subscription-card
              class="carousel__card subscription-card--fluid"
              :value="offering.code"
              :pre-selected="preSelectedCode"
              :selected="selectedCode"
              :title="
                getVariantPropsWhenAvailable(offering.variant, 'displayName')
              "
              :description="
                getVariantPropsWhenAvailable(
                  offering.variant,
                  'shortDescription'
                )
              "
              :action-selected-text="content.offeringCardButtonSelectedText"
              :action-text="content.offeringCardButtonText"
              :ribbon-text="
                getVariantPropsWhenAvailable(offering.variant, 'ribbon')
              "
              :employee-config-card="true"
              :badge-text="
                getVariantPropsWhenAvailable(offering.variant, 'text')
              "
              no-padding
              @change="select"
            >
              <template slot="attr">
                <div>
                  {{ getVariantPropsWhenAvailable(offering.variant, 'speed') }}
                  <span>{{
                    getVariantPropsWhenAvailable(offering.variant, 'unit')
                  }}</span>
                </div>
              </template>
            </subscription-card>
          </template>
        </c-carousel>
        <div
          v-for="(offering, index) in filteredOfferings"
          :key="index"
          class="carousel__mobile"
        >
          <div v-if="$resize && $mq.below(600)" class="carousel__mobile__card">
            <subscription-card
              class="carousel__card subscription-card--fluid"
              :value="offering.code"
              :selected="selectedCode"
              :title="
                getVariantPropsWhenAvailable(offering.variant, 'displayName')
              "
              :description="
                getVariantPropsWhenAvailable(
                  offering.variant,
                  'shortDescription'
                )
              "
              :action-selected-text="content.offeringCardButtonSelectedText"
              :action-text="content.offeringCardButtonText"
              :ribbon-text="
                getVariantPropsWhenAvailable(offering.variant, 'ribbon')
              "
              :employee-config-card="true"
              :badge-text="
                getVariantPropsWhenAvailable(offering.variant, 'text')
              "
              no-padding
              @change="select"
            >
              <template slot="attr">
                <div>
                  {{ getVariantPropsWhenAvailable(offering.variant, 'speed') }}
                  <span>{{
                    getVariantPropsWhenAvailable(offering.variant, 'unit')
                  }}</span>
                </div>
              </template>
            </subscription-card>
          </div>
        </div>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import { CRow, CCol } from 'olympus/components/index.js';
import CCarousel from 'webshop/components/c-carousel/c-carousel.vue';
import SubscriptionCard from 'webshop/components/subscription/subscription-card/subscription-card.vue';
import SkeletonLoader from 'webshop/components/skeleton-loader/skeleton-loader.vue';

import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  ACTIONS as HOME_OFFICE_ACTIONS,
  MUTATIONS as HOME_OFFICE_MUTATIONS
} from 'webshop/store/home-office/index.js';
import { GETTERS as BASKET_GETTERS } from 'webshop/store/basket/index.js';

import { BASKET_OPERATIONS } from 'webshop/enums/basket-operation-types.js';

export default {
  name: 'EmployeeConfigCarousel',

  components: {
    CRow,
    CCol,
    CCarousel,
    SubscriptionCard,
    SkeletonLoader
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectedCode: null,
      selectedTechnologyType: null,
      selectedOffering: {},
      addressId: '',
      orderId: '',
      order: {},
      perPage: 1,
      preSelectedCode: null
    };
  },
  computed: {
    ...mapGetters({
      isLoading: HOME_OFFICE_GETTERS.GET_IS_LOADING,
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA,
      offerings: HOME_OFFICE_GETTERS.GET_CARD_DATA,
      basketItems: BASKET_GETTERS.ALL_ITEMS
    }),
    technologyCarouselOptions() {
      const currentlySelectedOffering = this.offerings.find(
        offering => offering.code === this.selectedCode
      );
      const selectedIndex = this.offerings.indexOf(currentlySelectedOffering);
      const navigateToIdx = () => {
        if (this.offerings.length <= 2 && this.$mq.above(1200)) {
          return 0;
        }
        if (
          selectedIndex === this.offerings.length - 1 &&
          this.$mq.above(1200)
        ) {
          return selectedIndex - 1;
        }
        return selectedIndex;
      };

      return {
        slides: this.filteredOfferings,
        useSlideOffset: this.$resize && this.$mq.below(800),
        slideOffset: 10,
        navigateTo: navigateToIdx(),
        navigationEnabled: this.$resize && this.$mq.above(1200),
        navigationNextLabel:
          "<span class='VueCarousel-navigation-button__arrow VueCarousel-navigation-button__arrow--next'></span>",
        navigationPrevLabel:
          "<span class='VueCarousel-navigation-button__arrow VueCarousel-navigation-button__arrow--prev'></span>",
        navigationClickTargetSize: 4,
        scrollPerPage: false,
        paginationEnabled: this.$resize && this.$mq.below(800),
        paginationSize: 4,
        paginationActiveColor: '#222222',
        //33 is hex for 20% opacity, the format is #AARRGGBB
        paginationColor: '#33222222',
        paginationPadding: 2,
        perPage: this.perPage,
        homeOffice: true
      };
    },
    filteredOfferings() {
      if (this.preSelectedCode) {
        return this.offerings.filter(offering =>
          offering.code?.includes(this.preSelectedCode)
        );
      }
      return this.offerings;
    }
  },

  watch: {
    offerings(value) {
      if (
        !this.isLoading &&
        value.length &&
        this.basketItems.length &&
        !this.selectedCode
      ) {
        this.shiftSelectedOffering(this.basketItems[0].code);
        this.select(this.basketItems[0].code);
      } else if (!this.isLoading && value.length === 1 && !this.selectedCode) {
        this.select(value[0].code);
      }
    },
    technologyCarouselOptions() {
      // todo: remove current mq plugin and use something nicer with better api.
      // that will remove hardcoded values.
      if (this.$resize && this.$mq.above(600 * 2)) {
        this.perPage = 2;
      }
    }
  },
  mounted() {
    this.fetchEmployeeData().then(response => {
      const { employee, order, offering } =
        response?.data?.data?.attributes ?? {};

      this.addressId = employee?.address?.value?.addressId;
      this.orderId = employee?.orderId;
      this.order = order;
      this.preSelectedCode = offering?.preSelectedCode;

      this.getOfferings();
    });
  },

  methods: {
    ...mapMutations({
      shiftSelectedOffering: HOME_OFFICE_MUTATIONS.SHIFT_SELECTED_OFFERING
    }),

    ...mapActions({
      fetchEmployeeData: HOME_OFFICE_ACTIONS.FETCH_EMPLOYEE_DATA,
      fetchOfferings: HOME_OFFICE_ACTIONS.FETCH_OFFERINGS
    }),

    getOfferings() {
      return this.fetchOfferings({
        addressId: this.addressId,
        selectedProductId: this.selectedCode,
        orderId: this.orderId,
        selectedTechnologyType: this.selectedTechnologyType
      });
    },

    select(code) {
      const isProductAlreadySelected =
        !this.preSelectedCode || code === this.preSelectedCode;

      if (isProductAlreadySelected) {
        const selectedOffering = this.offerings.find(offering =>
          offering.code.includes(code)
        );

        this.selectedOffering = {
          code: selectedOffering?.variant.code,
          addressId: this.addressId,
          replaceCurrent: true,
          op: BASKET_OPERATIONS.ADD_HOME_OFFICE,
          continueWithoutLoyaltyVas: true,
          upsale: false,
          technologyType: selectedOffering?.variant.technologyType,
          orderId: this.orderId
        };

        this.selectedCode = selectedOffering?.variant.code;
        this.selectedTechnologyType = selectedOffering?.variant.technologyType;

        this.emitSelectedData();
      }
    },

    emitSelectedData() {
      this.$emit('selected', {
        selectedOffering: this.selectedOffering,
        order: this.order
      });
    },
    getVariantPropsWhenAvailable(variant, prop) {
      switch (prop) {
        case 'displayName':
          return variant?.displayName;
        case 'ribbon':
          return variant?.ribbon;
        case 'shortDescription':
          return variant?.shortDescription;
        case 'text':
          return variant?.badge?.text;
        case 'speed':
          return variant?.downloadSpeed?.value;
        case 'unit':
          return variant?.downloadSpeed?.unit;
        default:
          return;
      }
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.carousel {
  width: 100%;
  display: flex;
  flex-flow: column;

  &__header {
    text-align: center;
    margin-bottom: $u-500;
  }

  &__page-step {
    position: relative;

    &__skeleton-loader {
      width: 100%;
      height: 210px;
      margin-bottom: $u-800;
    }
  }

  &__mobile {
    display: flex;
    flex-flow: column;
    gap: $u-500;
    &__card {
      margin-bottom: $u-800;
    }
  }
}
#carousel .VueCarousel-navigation-next {
  transform: translateY(-55%) translateX(110%);
}
</style>
