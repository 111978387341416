var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cm-o-form-section product-login-prompt",
      class: { "product-login-prompt--attention": _vm.loginPromptInView }
    },
    [
      _c(
        "c-button",
        {
          directives: [
            {
              name: "in-viewport",
              rawName: "v-in-viewport",
              value: { enter: _vm.onViewportEnter, exit: _vm.onViewportExit },
              expression: "{ enter: onViewportEnter, exit: onViewportExit }"
            }
          ],
          on: { click: _vm.openLoginPage }
        },
        [
          _c("c-icon", {
            staticClass: "c-btn__icon",
            attrs: { "symbol-id": "menu-user" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "c-btn__text" }, [
            _c("span", { staticClass: "c-btn__primary" }, [
              _vm._v(_vm._s(_vm.content.loginTeaserText))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "c-btn__secondary" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.content.loginButtonCaption) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c("c-icon", {
            staticClass: "c-btn__arrow",
            attrs: { "symbol-id": "arrow-right-small" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }