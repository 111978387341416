<template>
  <portal v-if="!hidden" to="slim">
    <c-sticky-footer
      id="sticky-footer"
      ref="shopFooter"
      :class="classObject"
      :style="stickyFooterStyle"
    >
      <c-collapse-trigger
        v-if="expandableOnMobile"
        :id="'shop-footer-content'"
        class="shop-footer__trigger"
      >
        <c-icon
          class="shop-footer__trigger-icon"
          symbol-id="arrow-down"
        ></c-icon>
      </c-collapse-trigger>
      <div class="cm-container">
        <div class="cm-grid">
          <div
            class="
              cm-grid__col--xs-12 cm-grid__col--md-7 cm-grid__col--xl-offset-1
              shop-footer__grid-column
            "
          >
            <slot name="ribbon"></slot>
            <c-collapse
              :id="'shop-footer-content'"
              v-model="isExpanded"
              transition-duration="100ms"
              transition-easing="linear"
            >
              <slot name="columns">
                <div v-if="columns && columns.length" class="cm-grid">
                  <div
                    v-for="(column, index) in columns"
                    :key="index"
                    class="cm-grid__col--xs cm-grid__col--md-4"
                    :class="index > 0 && 'has-divider'"
                  >
                    <div class="shop-footer__column">
                      <slot name="column" :column="column">
                        <p class="shop-footer__item-label">
                          {{ column.label }}
                        </p>
                        <strong class="shop-footer__item-value">
                          {{ column.value }}
                        </strong>
                      </slot>
                    </div>
                  </div>
                </div>
              </slot>
            </c-collapse>
          </div>
          <div
            class="cm-grid__col--xs-12 cm-grid__col--md-5 cm-grid__col--xl-4"
          >
            <slot name="cta"></slot>
          </div>
        </div>
      </div>
    </c-sticky-footer>
  </portal>
</template>

<script>
import CStickyFooter from '../c-sticky-footer/c-sticky-footer.vue';
import { CIcon } from '../c-icon/index.js';
import { CCollapse, CCollapseTrigger } from 'olympus/components/index.js';

export default {
  name: 'ShopFooter',

  components: {
    CStickyFooter,
    CIcon,
    CCollapse,
    CCollapseTrigger
  },

  props: {
    expandableOnMobile: {
      type: Boolean,
      default: true
    },

    inOverlay: {
      type: Boolean,
      default: false
    },

    columns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      hidden: true,
      isExpanded: true,
      collapseBreakPoint: 600,
      footerBreakPoint: 800,
      timerCount: 0
    };
  },

  computed: {
    classObject() {
      // note: class binding is internal, until we
      // migrate to vue3.0, until then custom attr.
      const staticClass = this.$attrs['data-class'];
      return {
        'shop-footer': !this.isChannelHeaderAvailable,
        [staticClass]: !!staticClass,
        'is-collapsed': !this.isExpanded,
        'in-overlay': this.inOverlay,
        'shop-footer__small': this.isChannelHeaderAvailable
      };
    },

    stickyFooterStyle() {
      if (this.isChannelHeaderAvailable) {
        const channelHeaderId = 'channel-header';
        const channelHeaderElement = document.getElementById(channelHeaderId);
        return 'bottom:' + channelHeaderElement.offsetHeight + 'px';
      }
      return 'bottom:0';
    },

    isChannelHeaderAvailable() {
      const channelHeaderId = 'channel-header';
      const channelHeaderElement = document.getElementById(channelHeaderId);
      return !!channelHeaderElement;
    }
  },

  watch: {
    $resize() {
      if (this.$mq.above(this.collapseBreakPoint)) {
        this.isExpanded = true;
      }
      this.adjustFooterPadding();
    }
  },
  mounted() {
    this.$globals.eventBus.$on('shopFooterChanged', this.adjustFooterPadding);
    this.$nextTick(this.show);
  },
  methods: {
    getHeight() {
      return this.$refs.shopFooter?.$el.clientHeight || 0;
    },
    show() {
      this.adjustFooterPadding();
      this.hidden = false;
    },
    adjustFooterPadding() {
      // TODO DKT-1444: we should avoid querySelectors if possible
      const footerMain = document.querySelector('footer#footer-main');
      if (footerMain && this.$mq.below(this.footerBreakPoint)) {
        footerMain.style.paddingBottom = `${this.getHeight()}px`;
      } else {
        footerMain.style.paddingBottom = '';
      }
    }
  }
};
</script>
