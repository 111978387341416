<template>
  <div class="c-card-section" :class="classObject">
    <!-- TODO DKT-1458: this should be a seprate component -->
    <div
      v-if="hasBadge"
      class="c-card-section__badge c-badge c-badge--secondary c-badge--top-left"
    >
      <slot name="badge">{{ badgeText }}</slot>
    </div>
    <slot />
    <div v-if="hasRibbon" class="c-card-section__ribbon">
      <ribbon-banner flip-position="bottom-right" size="large" theme="assent">
        <slot name="ribbon">{{ ribbonText }}</slot>
      </ribbon-banner>
    </div>
  </div>
</template>
<script>
import RibbonBanner from '../ribbon-banner/ribbon-banner.vue';

export default {
  name: 'CCardSection',

  components: {
    RibbonBanner
  },

  props: {
    primary: {
      type: Boolean,
      default: false
    },
    badgeText: {
      type: String,
      default: ''
    },
    ribbonText: {
      type: String,
      default: ''
    }
  },

  computed: {
    classObject() {
      return {
        'c-card-section--primary': this.primary
      };
    },
    hasBadge() {
      return Boolean(this.badgeText || this.$slots.badge);
    },
    hasRibbon() {
      return Boolean(this.ribbonText || this.$slots.ribbon);
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
.c-card-section {
  padding: $u-400;
  background: $color-white;

  &__badge {
    position: absolute;
    left: 0;
    top: 0;
    white-space: normal;
  }

  &--primary {
    background: $c-pm-900;
    color: $color-white;
  }
}
</style>
