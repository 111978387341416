<template>
  <div v-if="discountEnabled" :class="voucherClasses">
    <template v-if="!hasCoupons">
      <c-collapse-trigger
        id="cart-voucher-collapse"
        class="voucher__header voucher__collapse-trigger"
      >
        <template slot-scope="slotProps">
          <c-icon
            class="voucher__icon voucher__icon--voucher"
            symbol-id="service-gift"
          ></c-icon>
          <h4 class="voucher__title">
            {{ contentLocal.discountCodeLabel }}
          </h4>

          <c-icon
            class="voucher__icon voucher__icon--arrow"
            :symbol-id="slotProps.expanded ? 'arrow-up' : 'arrow-down'"
          ></c-icon>
        </template>
      </c-collapse-trigger>

      <c-collapse
        id="cart-voucher-collapse"
        transition-duration="150ms"
        transition-easing="linear"
      >
        <div class="voucher__inputs">
          <input
            v-model="discountCode"
            type="text"
            name="discountcode"
            class="cm-c-form-control cm-js-material-input"
          />
          <label class="cm-c-form-control__label" for="discountcode">
            {{ contentLocal.discountCodePlaceholder }}
          </label>

          <button
            class="c-btn c-btn--sm voucher-code-btn"
            :class="[!discountCode ? 'c-btn--disabled' : 'c-btn--empty']"
            :disabled="!discountCode"
            @click="add"
          >
            {{ contentLocal.discountButtonText }}
          </button>
        </div>
      </c-collapse>
    </template>
    <template v-else>
      <div
        v-for="(coupon, couponsIndex) in coupons"
        :key="couponsIndex"
        class="voucher-container"
      >
        <span>
          <strong class="voucher-code-title">
            {{ contentLocal.discountSuccessText }}
          </strong>
          {{ getDiscountDescription(coupon) }}
        </span>
        <div
          class="voucher-remove-btn"
          role="button"
          @click="remove(coupon.coupon)"
        >
          <c-icon
            :symbol-id="
              contentLocal.discountTrashIcon ? 'service-trash' : 'menu-close'
            "
          ></c-icon>
          <span class="cm-sr-only">
            {{ contentLocal.removeButtonText }}
          </span>
        </div>
      </div>
    </template>
    <c-modal
      :visible="networking.hasNetworkError"
      :title="networking.errorPopUpHeaderText"
      :ok-button-text="networking.errorPopUpButtonText"
      :content-centered="!!networking.errorPopUpButtonText"
      centered
      show-ok-button
      @close="closeErrorModal"
    >
      <div
        class="basket-coupon__modal__message"
        v-html="networking.error || networking.errorPopUpMessageText"
      ></div>
    </c-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GETTERS as BASKET_GETTERS,
  ACTIONS as BASKET_ACTIONS
} from '../../store/basket';
import { CCollapse, CCollapseTrigger, CModal } from 'olympus/components';

const DEFAULT_CONTENT = {
  discountButtonText: 'Opdater',
  discountCodeLabel: 'Har du en rabatkode?',
  discountCodePlaceholder: 'Indtast rabatkode her',
  discountSuccessText: 'Rabatkode aktiveret:',
  removeButtonText: 'Fjern'
};

export default {
  name: 'BasketCoupon',

  components: {
    CCollapse,
    CCollapseTrigger,
    CModal
  },

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    blockId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      },
      discountCode: '',
      networking: {
        error: '',
        hasNetworkError: false
      }
    };
  },

  computed: {
    ...mapGetters({
      coupons: BASKET_GETTERS.COUPONS,
      hasCoupons: BASKET_GETTERS.HAS_COUPONS
    }),
    discountEnabled() {
      return this.content.discountEnabled;
    },
    voucherClasses() {
      return {
        voucher: true,
        'voucher--active': this.hasCoupons
      };
    }
  },

  methods: {
    ...mapActions({
      addDiscount: BASKET_ACTIONS.ADD_DISCOUNT,
      removeDiscount: BASKET_ACTIONS.REMOVE_DISCOUNT
    }),

    getDiscountDescription({ coupon, description }) {
      let desc = `${coupon}`;
      if (description) {
        desc += ` - ${description}`;
      }
      return desc;
    },

    add() {
      this.addDiscount({
        code: this.discountCode,
        blockId: this.blockId
      }).catch(error => this.handleErrorMessage(error.errors[0].detail));
    },

    remove(couponCode) {
      this.removeDiscount({
        code: couponCode,
        blockId: this.blockId
      })
        .then(() => {
          this.discountCode = '';
        })
        .catch(error => this.handleErrorMessage(error.errors[0].detail));
    },

    handleErrorMessage(error) {
      // TODO DKT-1427: We have a problem with error handling
      this.networking.hasNetworkError = true;

      if (typeof error === 'string' && error.length > 0) {
        this.networking.error = error;
        return;
      }
      Object.keys(error).forEach(key => {
        this.networking[key] = error[key];
      });
    },

    closeErrorModal() {
      this.hasNetworkError = false;
      this.networking.error = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.basket-coupon {
  &__modal__message {
    text-align: center;
    margin: $u-250;
  }
}
</style>
