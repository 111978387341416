<script>
export default {
  name: 'CheckoutFinancePayment',

  props: {
    token: {
      type: String,
      required: true
    },
    successRedirect: {
      type: String,
      required: true
    },
    errorRedirect: {
      type: String,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    paymentMethods: {
      type: Array,
      required: true
    },
    customCssUrl: {
      type: String,
      required: false,
      default: ''
    },
    brand: {
      type: String,
      required: false,
      default: ''
    },
    // If credit check is denied, this URL defines where to redirect the user
    creditDeniedRedirectUrl: {
      type: String,
      default: ''
    },
    // Auto-redirect to 'creditDeniedRedirectUrl' on credit check denial if checked
    redirectAutomaticallyOnCreditDenied: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      paymentWindowLoading: false,
      redirecting: false
    };
  },

  computed: {},

  mounted() {
    this.paymentWindowLoading = true;
    const self = this;

    window
      .initWebcheckout({
        element: '#paymentWindow',
        purchaseRequestToken: this.token,
        customCssUrl: this.customCssUrl
      })
      .then(webcheckout => {
        webcheckout.on('SUCCESS', event => self.onSuccess(event));
        webcheckout.on('creditCheckDenied', event =>
          self.onCreditDenied(event)
        );
        webcheckout.on('REDIRECT_AT_CREDIT_DENIED', event =>
          self.onCreditDeniedButtonCLick(event)
        );

        webcheckout.start();
      });
  },

  methods: {
    onCreditDenied() {
      if (
        this.redirectAutomaticallyOnCreditDenied &&
        this.creditDeniedRedirectUrl
      ) {
        window.location.href = this.creditDeniedRedirectUrl;
      }
    },

    onCreditDeniedButtonCLick() {
      if (this.creditDeniedRedirectUrl) {
        window.location.href = this.creditDeniedRedirectUrl;
      }
    },

    onSuccess(event) {
      const separator = this.successRedirect.includes('?') ? '&' : '?';

      if (event?.value?.cardPayment) {
        window.location.href = this.successRedirect + separator + 'months=1';
      } else if (event?.value?.partPayment) {
        window.location.href =
          this.successRedirect +
          separator +
          'months=' +
          event.value.partPayment.months;
      }
    }
  }
};
</script>
