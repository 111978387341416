var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c("c-col", { staticClass: "cm-c-form__col" }, [
            _c("h3", { staticClass: "cm-c-form__header" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.numberConfigVM.poaHeadline) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.numberConfigVM.poaDescription) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c("c-col", { staticClass: "cm-c-form__col" }, [
            _c("p", { staticClass: "cm-numcon__subtitle" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.numberConfigVM.subscriptionOwnerHeadline))
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-row",
        { staticClass: "cm-c-form__row" },
        [
          _c(
            "c-col",
            { staticClass: "cm-c-form__col" },
            [
              _c(
                "c-form-radio-group",
                {
                  attrs: { name: "ownNumber", stacked: "" },
                  model: {
                    value: _vm.ownNumber,
                    callback: function($$v) {
                      _vm.ownNumber = $$v
                    },
                    expression: "ownNumber"
                  }
                },
                [
                  _c(
                    "c-form-radio",
                    {
                      attrs: { id: "yes", "checked-border": "", value: "yes" }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.numberConfigVM.subscriptionOwnerYes))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "c-form-radio",
                    { attrs: { id: "no", "checked-border": "", value: "no" } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.numberConfigVM.subscriptionOwnerNo))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-collapse",
        { attrs: { id: "b2b-or-private", visible: _vm.ownNumber === "yes" } },
        [
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c("c-col", { staticClass: "cm-c-form__col" }, [
                _c("p", { staticClass: "cm-numcon__subtitle" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.numberConfigVM
                          .subscriptionOwnerCustomerSegmentHeadline
                      )
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c(
                "c-col",
                { staticClass: "cm-c-form__col" },
                [
                  _c(
                    "c-form-radio-group",
                    {
                      attrs: { name: "b2bOrPrivate", stacked: "" },
                      model: {
                        value: _vm.b2bOrPrivate,
                        callback: function($$v) {
                          _vm.b2bOrPrivate = $$v
                        },
                        expression: "b2bOrPrivate"
                      }
                    },
                    [
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "private",
                            "checked-border": "",
                            value: "private"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.numberConfigVM
                                  .subscriptionOwnerCustomerSegmentPrivate
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "c-form-radio",
                        {
                          attrs: {
                            id: "b2b",
                            "checked-border": "",
                            value: "b2b"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.numberConfigVM
                                  .subscriptionOwnerCustomerSegmentBusiness
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            {
              attrs: { id: "private", visible: _vm.b2bOrPrivate === "private" }
            },
            [
              _c(
                "c-row",
                { staticClass: "cm-c-form__row" },
                [
                  _c("c-col", { staticClass: "cm-c-form__col" }, [
                    _c("p", { staticClass: "cm-numcon__subtitle" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.numberConfigVM.poaHeadline) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "cm-c-form__row" },
                [
                  _c(
                    "c-col",
                    { staticClass: "cm-c-form__col" },
                    [
                      _c(
                        "c-checkbox",
                        {
                          attrs: {
                            id: "allowEpoa",
                            checked: _vm.productObj.allowEpoa,
                            name: "allowEpoa",
                            "styled-checkbox": ""
                          },
                          model: {
                            value: _vm.productObj.allowEpoa,
                            callback: function($$v) {
                              _vm.$set(_vm.productObj, "allowEpoa", $$v)
                            },
                            expression: "productObj.allowEpoa"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.numberConfigVM.poaLabel) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "b2b", visible: _vm.b2bOrPrivate === "b2b" } },
            [
              !_vm.numberConfigVM.companyManualSearch
                ? [
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c("c-col", { staticClass: "cm-c-form__col" }, [
                          _c("p", { staticClass: "cm-numcon__subtitle" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.numberConfigVM.poaHeadline))
                            ])
                          ])
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "cm-c-form__col",
                            attrs: { cols: "4" }
                          },
                          [
                            _c("c-input-group", {
                              attrs: {
                                type: "tel",
                                "input-error": _vm.cvrError,
                                "place-holder-label":
                                  _vm.numberConfigVM.b2BCvrPlaceholderText
                              },
                              on: { input: _vm.checkCVR },
                              model: {
                                value: _vm.productObj.b2BCvr,
                                callback: function($$v) {
                                  _vm.$set(_vm.productObj, "b2BCvr", $$v)
                                },
                                expression: "productObj.b2BCvr"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c(
                          "c-col",
                          { staticClass: "cm-c-form__col" },
                          [
                            _c("c-input-group", {
                              attrs: {
                                "input-error": _vm.organizationError,
                                "place-holder-label":
                                  _vm.numberConfigVM
                                    .b2BOrganizationPlaceholderText,
                                readonly: ""
                              },
                              model: {
                                value: _vm.productObj.b2BOrganization,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productObj,
                                    "b2BOrganization",
                                    $$v
                                  )
                                },
                                expression: "productObj.b2BOrganization"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c(
                          "c-col",
                          { staticClass: "cm-c-form__col" },
                          [
                            _c("c-input-group", {
                              attrs: {
                                "input-error": _vm.addressError,
                                "place-holder-label":
                                  _vm.numberConfigVM.b2BAddressPlaceholderText,
                                readonly: ""
                              },
                              model: {
                                value: _vm.productObj.b2BAddress,
                                callback: function($$v) {
                                  _vm.$set(_vm.productObj, "b2BAddress", $$v)
                                },
                                expression: "productObj.b2BAddress"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "cm-c-form__col",
                            attrs: { cols: "6" }
                          },
                          [
                            _c("c-input-group", {
                              attrs: {
                                "input-error": _vm.zipCodeError,
                                "place-holder-label":
                                  _vm.numberConfigVM.b2BZipCodePlaceholderText,
                                readonly: ""
                              },
                              model: {
                                value: _vm.productObj.b2BZipCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.productObj, "b2BZipCode", $$v)
                                },
                                expression: "productObj.b2BZipCode"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-col",
                          {
                            staticClass: "cm-c-form__col",
                            attrs: { cols: "6" }
                          },
                          [
                            _c("c-input-group", {
                              attrs: {
                                "input-error": _vm.cityError,
                                "place-holder-label":
                                  _vm.numberConfigVM.b2BCityPlaceholderText,
                                readonly: ""
                              },
                              model: {
                                value: _vm.productObj.b2BCity,
                                callback: function($$v) {
                                  _vm.$set(_vm.productObj, "b2BCity", $$v)
                                },
                                expression: "productObj.b2BCity"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "c-row",
                      { staticClass: "cm-c-form__row" },
                      [
                        _c(
                          "c-col",
                          {
                            staticClass: "cm-c-form__col",
                            attrs: { cols: "8", xs: "12" }
                          },
                          [
                            _c("c-input-group", {
                              attrs: {
                                "input-error": _vm.cvrError,
                                "place-holder-label":
                                  _vm.numberConfigVM.b2BCvrPlaceholderText
                              },
                              model: {
                                value: _vm.productObj.b2BCvr,
                                callback: function($$v) {
                                  _vm.$set(_vm.productObj, "b2BCvr", $$v)
                                },
                                expression: "productObj.b2BCvr"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "c-col",
                          {
                            staticClass: "cm-c-form__col",
                            attrs: { cols: "4", xs: "12" }
                          },
                          [
                            _c(
                              "c-button",
                              {
                                attrs: { small: "" },
                                on: { click: _vm.checkCVR }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.numberConfigVM.companyCheckButtonText
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.businessInfoAvailable
                      ? _c(
                          "c-row",
                          { staticClass: "cm-c-form__row" },
                          [
                            _c("c-col", { staticClass: "cm-c-form__col" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "cm-numcon__poa__business-info"
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "cm-numcon__subtitle" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.numberConfigVM
                                              .companyInfoSectionTitle
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.productObj.b2BOrganization)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.productObj.b2BAddress)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.productObj.b2BZipCode) +
                                      " " +
                                      _vm._s(_vm.productObj.b2BCity) +
                                      "\n            "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "cm-c-form__row" },
                [
                  _c("c-col", { staticClass: "cm-c-form__col" }, [
                    _c("p", { staticClass: "cm-numcon__subtitle" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.numberConfigVM.poaHeadline))
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "cm-c-form__row" },
                [
                  _c(
                    "c-col",
                    { staticClass: "cm-c-form__col" },
                    [
                      _c(
                        "c-checkbox",
                        {
                          attrs: {
                            id: "allowEpoa",
                            checked: _vm.productObj.allowEpoa,
                            name: "allowEpoa",
                            "styled-checkbox": ""
                          },
                          model: {
                            value: _vm.productObj.allowEpoa,
                            callback: function($$v) {
                              _vm.$set(_vm.productObj, "allowEpoa", $$v)
                            },
                            expression: "productObj.allowEpoa"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.numberConfigVM.poaLabel) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "cm-c-form__row" },
                [
                  _c("c-col", { staticClass: "cm-c-form__col" }, [
                    _c("p", [_vm._v(_vm._s(_vm.numberConfigVM.poaManualLabel))])
                  ])
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-collapse",
        { attrs: { id: "poa-info", visible: _vm.ownNumber === "no" } },
        [
          _c(
            "c-row",
            { staticClass: "cm-c-form__row" },
            [
              _c("c-col", { staticClass: "cm-c-form__col" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.numberConfigVM.notSubscriptionOwnerDescription
                      ) +
                      "\n        "
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }