var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.classObject },
    [
      _c(
        "header",
        { staticClass: "product-page__header" },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _vm.selectedVariant
        ? [
            _c(
              "product-wizard",
              {
                attrs: {
                  "product-code": _vm.productCode,
                  content: _vm.contentLocal,
                  "color-attributes": _vm.colorAttributes,
                  "capacity-attributes": _vm.capacityAttributes,
                  "installment-attributes": _vm.installmentAttributes,
                  "binding-period-attributes": _vm.bindingPeriodAttributes,
                  subscriptions: _vm.subscriptions,
                  "existing-subscriptions": _vm.existingSubscriptions,
                  "selected-variant": _vm.selectedVariant,
                  "selected-subscription": _vm.selectedSubscription,
                  "selected-installment": _vm.selectedInstallment,
                  "selected-binding-period": _vm.selectedBindingPeriod,
                  "is-logged-in": _vm.isLoggedIn,
                  "is-handset": _vm.isHandset,
                  "hide-price-plan-selector": _vm.external,
                  "disable-installments": _vm.disableInstallments,
                  "enable-prolonging": _vm.enableProlonging
                },
                on: {
                  "color-selected": _vm.onColorSelected,
                  "capacity-selected": _vm.onCapacitySelected,
                  "installment-selected": _vm.onInstallmentSelected,
                  "price-plan-selected": _vm.onPricePlanSelected,
                  "binding-period-selected": _vm.onBindingPeriodSelected
                }
              },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c(
                      "parallax-wrapper",
                      {
                        staticClass: "product-page__pebble-bg-wrapper",
                        attrs: { direction: -1, axis: "y", speed: 0.3 }
                      },
                      [_c("span", { staticClass: "product-page__pebble-bg" })]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "attribute-top-area" },
                  [
                    _c("product-details", {
                      attrs: { "variant-summary": _vm.selectedVariantSummary }
                    }),
                    _vm._v(" "),
                    _vm.selectedVariant.countdownTimer &&
                    _vm.selectedVariant.countdownTimer.isEnabled
                      ? _c("countdown", {
                          staticClass: "product-page__countdown",
                          attrs: { data: _vm.selectedVariant.countdownTimer }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.productPromotion && _vm.productPromotion.enabled
                      ? _c(
                          "product-promotion-block",
                          {
                            staticClass: "product-page__promotion",
                            attrs: {
                              "expire-date-time-label":
                                _vm.productPromotion.expireDateTimeLabel,
                              "expired-label":
                                _vm.productPromotion.expiredLabel,
                              "image-alt": _vm.productPromotion.imageAreaLabel,
                              "image-url": _vm.productPromotion.imageSrc,
                              heading: _vm.productPromotion.heading,
                              "price-text": _vm.productPromotion.priceText,
                              "end-time": _vm.productPromotion.endTime,
                              "show-counter": _vm.productPromotion.showCounter
                            }
                          },
                          [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.productPromotion.description
                                )
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.initialVariantLoaded &&
                    !_vm.contentLocal.disableLoginButton &&
                    !_vm.isLoggedIn &&
                    !_vm.contentLocal.loginButtonHidden &&
                    !_vm.isLoggedIn &&
                    !_vm.external
                      ? _c("product-login-prompt", {
                          attrs: {
                            content: _vm.contentLocal,
                            "login-url": _vm.loginUrl
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "product-footer",
                      {
                        attrs: {
                          content: _vm.contentLocal,
                          "is-accessory": _vm.isAccessory
                        },
                        on: {
                          "cta-pressed": function($event) {
                            return _vm.addToBasket(false)
                          },
                          "vas-selected": function($event) {
                            return _vm.getVariant()
                          },
                          "vas-deselected": function($event) {
                            return _vm.getVariant()
                          }
                        }
                      },
                      [
                        _c(
                          "shop-footer",
                          {
                            ref: "shopFooter",
                            attrs: {
                              detailed: true,
                              "in-overlay": _vm.external
                            }
                          },
                          [
                            _vm.hasDiscount
                              ? _c(
                                  "ribbon-banner",
                                  {
                                    attrs: {
                                      slot: "ribbon",
                                      "flip-position": "top-left",
                                      size: "large",
                                      theme: "discount"
                                    },
                                    slot: "ribbon"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.contentLocal.discountPriceHeading
                                        ) +
                                        "\n              " +
                                        _vm._s(
                                          _vm.selectedVariant.price
                                            .productDiscountedPrice
                                            .formattedValueCurrency
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedVariant.price
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "cm-grid",
                                    attrs: { slot: "columns" },
                                    slot: "columns"
                                  },
                                  _vm._l(_vm.shopFooterColumns, function(
                                    column,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "cm-grid__col--xs cm-grid__col--md-4",
                                        class: index > 0 && "has-divider"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "shop-footer__column"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "shop-footer__item-label"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(column.label) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "strong",
                                              {
                                                staticClass:
                                                  "shop-footer__item-value"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(column.value) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "shopFooterCtaContainer",
                                staticClass:
                                  "shop-footer__cta-container product-page__footer-cta",
                                attrs: { slot: "cta" },
                                slot: "cta"
                              },
                              [
                                _vm.derivedStatus === _vm.STOCK_STATUSES.NOTIFY
                                  ? [_vm._t("product-notify-form")]
                                  : _vm.shopFooterCta
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "shop-footer__cta c-btn c-btn--wide",
                                        class: _vm.shopFooterCta.btnClass,
                                        attrs: {
                                          disabled: !_vm.selectedVariant
                                            .inventoryStatus.isAvailable
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addToBasket(false)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "c-btn__column" },
                                          [
                                            _vm.shopFooterCta &&
                                            _vm.shopFooterCta.iconType
                                              ? _c("c-icon", {
                                                  staticClass: "c-btn--icon",
                                                  class:
                                                    _vm.shopFooterCta.iconClass,
                                                  attrs: {
                                                    "symbol-id":
                                                      _vm.shopFooterCta.iconType
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n\n                  " +
                                                _vm._s(
                                                  _vm.shopFooterCta.label
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectedVariant.inventoryStatus
                                  ? _c(
                                      "product-stock-status",
                                      {
                                        attrs: {
                                          status: _vm.derivedStatus,
                                          "status-text":
                                            _vm.selectedVariant.inventoryStatus
                                              .description,
                                          "countdown-date":
                                            _vm.selectedVariant.inventoryStatus
                                              .sameDayShipmentTime
                                        }
                                      },
                                      [
                                        _vm.selectedVariant.price &&
                                        _vm.selectedVariant.price.minimumPrice
                                          ? _c(
                                              "small",
                                              {
                                                class:
                                                  _vm.minimumPriceModifiers,
                                                attrs: { slot: "footer" },
                                                on: {
                                                  click:
                                                    _vm.onMinimumPricePressed
                                                },
                                                slot: "footer"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.contentLocal
                                                        .minimumPriceText
                                                    ) +
                                                    "\n                  " +
                                                    _vm._s(
                                                      _vm.selectedVariant.price
                                                        .minimumPrice
                                                        .formattedValueCurrency
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.prices &&
                                _vm.prices.productPrice.disclaimerText
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "product-page__price-disclaimer"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.prices.productPrice
                                                .disclaimerText
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "attribute-bottom-area" },
                  [_vm._t("attribute-bottom-area")],
                  2
                ),
                _vm._v(" "),
                _vm.initialVariantLoaded
                  ? _c(
                      "product-gallery",
                      {
                        staticClass: "product-page__gallery",
                        attrs: {
                          slot: "gallery-area",
                          "product-name": _vm.productName,
                          content: _vm.galleryContent,
                          "image-urls": _vm.selectedVariant.imageUrls
                        },
                        slot: "gallery-area"
                      },
                      [
                        _c(
                          "template",
                          { slot: "footer" },
                          [
                            _vm.selectedVariant.inventoryStatus
                              ? _c("product-stock-status", {
                                  attrs: {
                                    status: _vm.derivedStatus,
                                    "status-text":
                                      _vm.selectedVariant.inventoryStatus.name
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedVariant.inventoryStatus
                              .inventoryStatusInStoresEnabled
                              ? _c("store-stock-information", {
                                  attrs: {
                                    "selected-variant": _vm.selectedVariant
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selectedVariant.badgeText.item1 &&
                        _vm.selectedVariant.badgeText.item2 &&
                        _vm.$resize &&
                        _vm.$mq.above(800) &&
                        _vm.selectedVariant.badgeText
                          ? _c("product-splash", {
                              staticClass: "cm-c-product-image__splash",
                              attrs: {
                                slot: "splash",
                                "splash-text":
                                  _vm.selectedVariant.badgeText.item2,
                                type: _vm.selectedVariant.badgeText.item1,
                                size: "lg"
                              },
                              slot: "splash"
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("template", { slot: "footer" }, [_vm._t("wizard-footer")], 2)
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass:
                  "cm-o-layout cm-o-layout--bg-white product-page__tabs"
              },
              [
                _vm.selectedVariant
                  ? _c(
                      "product-tabs",
                      {
                        attrs: {
                          "is-accessory": _vm.isAccessory,
                          "marketing-data": _vm.marketingData,
                          specifications:
                            _vm.selectedVariant.groupedSpecifications,
                          "image-urls": _vm.selectedVariant.imageUrls,
                          "variant-summary": _vm.selectedVariantSummary,
                          external: _vm.external
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "marketing-alternative-content" },
                          [_vm._t("marketing-alternative-content")],
                          2
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.productRelations.length > 0
              ? _c(
                  "section",
                  { staticClass: "cm-o-layout cm-o-layout__link-cloud" },
                  [
                    _c("attribute-selector", {
                      attrs: {
                        title: _vm.contentLocal.productCategoriesBlockHeader,
                        attributes: _vm.productRelations,
                        "link-cloud": ""
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-modal",
        { ref: "installmentsModal", attrs: { centered: "" } },
        [
          _vm.installmentsMismatchError
            ? [
                _c(
                  "c-heading",
                  {
                    staticClass: "text-center px-3",
                    attrs: { slot: "header", level: "2", as: "1" },
                    slot: "header"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.installmentsMismatchError.title) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("c-text", {
                      staticClass: "mb-3",
                      attrs: {
                        content: _vm.installmentsMismatchError.description
                      }
                    }),
                    _vm._v(" "),
                    _c("c-heading", { attrs: { level: "4", as: "3" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.installmentsMismatchError.currentProductText
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "s-product-item",
                      _vm._b(
                        { staticClass: "px-2" },
                        "s-product-item",
                        _vm.selectedBasketProduct,
                        false
                      )
                    ),
                    _vm._v(" "),
                    _c(
                      "c-box",
                      [
                        _c("c-text", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.installmentsMismatchError.suggestedText
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.installmentMatchSuggest
                          ? _c(
                              "c-row",
                              [
                                _c("c-col", { attrs: { cols: "8" } }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.installmentMatchSuggest.name) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "c-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "4" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.installmentMatchSuggest.priceText
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("template", { slot: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "c-btn c-btn--primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resolveInstallmentMismatch(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.installmentsMismatchError.acceptButtonText
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "c-btn c-btn--as-link",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resolveInstallmentMismatch(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.installmentsMismatchError.rejectButtonText
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.restrictedBanModalContent
        ? _c(
            "c-modal",
            {
              ref: "banRestrictionModal",
              attrs: { title: _vm.restrictedBanModalContent.heading }
            },
            [
              _c("c-text", {
                staticClass: "mb-3",
                attrs: { content: _vm.restrictedBanModalContent.bodyText }
              }),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.hideRestrictionModal($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.restrictedBanModalContent.cancelButtonLabel
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--as-link",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.addToBasket(true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.restrictedBanModalContent.acceptButtonLabel
                        ) +
                        "\n      "
                    )
                  ]
                )
              ])
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }