import { post } from '../api/api.js';

export default {
  data() {
    return {
      parameters: {},
      showingError: false,
      isFetchingParameters: false,
      temporaryModel: {
        secret: '',
        token: '',
        cpr: '',
        cvr: '',
        accountId: ''
      }
    };
  },
  computed: {
    hasParameters() {
      return this.parameters !== null && this.parameters.iframeUrl;
    }
  },
  created() {
    window.addEventListener('message', this._handleFrameMessage);
  },
  beforeDestroy: function () {
    window.removeEventListener('message', this._handleFrameMessage);
  },
  methods: {
    _handleFrameMessage(event) {
      if (
        !this.parameters ||
        event.origin.replace(/\/?$/, '/') !== this.parameters.nemIdUrl
      ) {
        return;
      }

      const message = JSON.parse(event.data);

      if (message.command === 'SendParameters') {
        const body = JSON.stringify({
          command: 'parameters',
          content: this.parameters.params
        });
        this.$refs.nemIdFrame.contentWindow.postMessage(
          body,
          this.parameters.nemIdUrl
        );
      }

      if (message.command === 'changeResponseAndSubmit') {
        this.temporaryModel.secret = message.content;

        let payload = {
          Secret: message.content,
          Segment: this.segment
        };

        if (this.segment === '2') {
          //If b2b aadd cvr to api call
          payload = { ...payload, Cvr: this.cvrNumber };
        }

        this._fetchToken(payload)
          .then(data => {
            if (this.skipCpr && data.isLoggedIn) {
              if (!data.cvrMismatch && !data.isExistingCustomer) {
                this.temporaryModel.accountId = data.accountId;
                this.temporaryModel.token = data.token;
                this.temporaryModel.secret = data.secret;
                this.temporaryModel.cvr = data.organizationNumber;
                this.$nextTick(() => {
                  this.eidResponseSuccess();
                });
              } else {
                this.eidResponseError(data);
                this.showingError = true;
              }
            } else {
              this.temporaryModel.accountId = data.accountId;
              this.temporaryModel.token = data.token;
              this.entered = data.isLoggedIn;
            }
          })
          .catch(data => {
            this.eidResponseError(data);
            this.showingError = true;
          });
      }
    },

    _fetchParams(nemidMode = 1) {
      const isDesktop = this.$globals.isDesktop();
      this.isFetchingParameters = true;
      return post('/nemid/parameters', {
        mode: nemidMode,
        UseLimitedMode: !isDesktop
      }).then(({ data }) => {
        this.isFetchingParameters = false;
        this.parameters = data;
      });
    },
    _fetchToken(payload) {
      return post('/nemid/login', payload).then(({ data }) => {
        return data.errorMessage ? Promise.reject(data) : Promise.resolve(data);
      });
    }
  }
};
