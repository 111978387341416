<template>
  <div>
    <!-- @slot Maincontent goes into -->
    <slot />

    <c-modal
      :visible="content.showPoqErrorModal"
      :ok-button-text="content.poqErrorModal.acceptButtonText"
      :title="content.poqErrorModal.title"
      centered
      show-ok-button
    >
      <div
        class="broadband-landing-page__modal__message"
        v-html="content.poqErrorModal.message"
      ></div>
    </c-modal>
  </div>
</template>

<script>
import { CModal } from 'olympus/components/index.js';

export default {
  name: 'broadband-landing-page',

  components: {
    CModal
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

// .broadband-landing-page
.broadband-landing-page {
  // .broadband-landing-page__modal
  &__modal {
    // .broadband-landing-page__modal__message
    &__message {
      text-align: center;
      padding-top: $u-250;
    }
  }
}
</style>
