<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <component
      :is="tag"
      v-if="show"
      :id="id"
      :class="modifiers"
      :style="transitionProps"
      class="c-collapse"
      :aria-hidden="show ? 'false' : 'true'"
    >
      <slot :expanded="show" />
    </component>
  </transition>
</template>

<script>
import { getEventName } from '../../utils/event.js';
import EVENT_NAMESPACES from '../../constants/events.js';

const EVENT_STATE = getEventName(EVENT_NAMESPACES.COLLAPSE, 'state');
const EVENT_REQUEST_STATE = getEventName(
  EVENT_NAMESPACES.TOGGLE,
  'request-state'
);
const EVENT_TOGGLE = getEventName(EVENT_NAMESPACES.TOGGLE, 'collapse');

export default {
  name: 'CCollapse',

  model: {
    prop: 'visible',
    event: 'change'
  },

  props: {
    id: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    },
    transitionDuration: {
      type: String,
      default: '500ms'
    },
    transitionEasing: {
      type: String,
      default: 'ease-in-out'
    }
  },

  data() {
    return {
      show: this.visible,
      maxHeight: 0,
      paddingTop: '0px',
      paddingBottom: '0px'
    };
  },

  computed: {
    modifiers() {
      return {
        'c-collapse--expanded': this.show
      };
    },
    transitionProps() {
      return {
        transitionDuration: this.transitionDuration,
        transitionTimingFunction: this.transitionEasing
      };
    }
  },

  watch: {
    visible(value) {
      this.show = value;
    },
    show() {
      this.emitState();
    }
  },

  mounted() {
    this.$root.$on(EVENT_TOGGLE, this.handleToggleEvent);
    this.$root.$on(EVENT_REQUEST_STATE, this.handleToggleRequestStateEvent);

    this.$nextTick(() => {
      this.emitState();
    });
  },

  updated() {
    this.$nextTick(() => {
      let transitionChecker;
      this.maxHeight = this.$el.scrollHeight;
      transitionChecker = setInterval(() => {
        if (this.maxHeight !== this.$el.scrollHeight) {
          this.maxHeight = this.$el.scrollHeight;
        } else {
          clearInterval(transitionChecker);
        }
      }, 100);
    });
  },

  beforeDestroy() {
    this.$root.$off(EVENT_TOGGLE, this.handleToggleEvent);
    this.$root.$off(EVENT_REQUEST_STATE, this.handleToggleRequestStateEvent);
  },

  methods: {
    handleToggleEvent(target) {
      if (target !== this.id) {
        return;
      }
      this.toggle();
    },

    handleToggleRequestStateEvent(target) {
      if (target !== this.id) {
        return;
      }
      this.$root.$emit(EVENT_STATE, this.id, this.show);
    },

    toggle() {
      this.show = !this.show;
    },

    emitState() {
      this.$root.$emit(EVENT_STATE, this.id, this.show);
      this.$emit(this.show ? 'expand' : 'collapse');
      this.$emit('change', this.show);
    },

    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;

      element.style.width = '';
      element.style.position = '';
      element.style.visibility = '';
      element.style.height = '0px';

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },

    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      requestAnimationFrame(() => {
        element.style.height = '0px';
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.c-collapse {
  height: 0;

  // .c-collapse--expanded
  &--expanded {
    height: auto;
  }
}

.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  padding-bottom: 0;
  transition-property: height;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
