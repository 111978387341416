var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "address-search" }, [
    _c("div", { staticClass: "address-search__card", class: _vm.modifiers }, [
      _c("p", {
        staticClass: "address-search__description",
        domProps: { innerHTML: _vm._s(_vm.content.description) }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "address-search__group" },
        [
          _c(
            "c-dropdown",
            {
              attrs: {
                attributes: {
                  options: _vm.addresses,
                  noDrop: !_vm.showOptions,
                  maxInputLength: _vm.content.maxInputLength || 100
                },
                events: {
                  "option:selected": _vm.handleOptionSelected,
                  search: _vm.handleSearchInput,
                  "search:blur": _vm.handleSearchBlur,
                  "search:focus": _vm.handleSearchFocus
                },
                "search-input-placeholder": _vm.content.searchInputPlaceholder,
                "enable-local-loader": _vm.content.enableLocalLoader,
                "search-string": _vm.content.searchString,
                skinny: !_vm.isBlock
              },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [
              _c("template", { slot: "no-options" }, [
                _vm.showError && _vm.content.searchErrorText
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.content.searchErrorText)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showError && _vm.content.noResultsText
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.content.noResultsText) }
                    })
                  : _vm._e()
              ])
            ],
            2
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.content.triggerText
      ? _c(
          "div",
          { staticClass: "address-search__tongue" },
          [
            _vm.content.collapseContent
              ? [
                  _c("c-collapse-trigger", {
                    staticClass: "address-search__tongue-trigger",
                    attrs: {
                      id: "address-search-tongue",
                      "data-testid": "address-search-tongue"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var expanded = ref.expanded
                            return [
                              _vm.content.triggerImages &&
                              _vm.content.triggerImages.length
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "address-search__tongue-images"
                                    },
                                    _vm._l(_vm.content.triggerImages, function(
                                      image,
                                      i
                                    ) {
                                      return _c("img", {
                                        key: i,
                                        attrs: { src: image }
                                      })
                                    }),
                                    0
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "address-search__tongue-title" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.content.triggerText) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("c-icon", {
                                staticClass: "address-search__tongue-icon",
                                class: {
                                  "address-search__tongue-icon--expanded": expanded
                                },
                                attrs: { "symbol-id": "arrow-chevron" }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3426803928
                    )
                  }),
                  _vm._v(" "),
                  _c("c-collapse", { attrs: { id: "address-search-tongue" } }, [
                    _c("div", {
                      staticClass: "address-search__tongue-content",
                      domProps: {
                        innerHTML: _vm._s(_vm.content.collapseContent)
                      }
                    })
                  ])
                ]
              : [
                  _c("div", { staticClass: "address-search__tongue-footer" }, [
                    _vm.content.triggerImages &&
                    _vm.content.triggerImages.length
                      ? _c(
                          "span",
                          { staticClass: "address-search__tongue-images" },
                          _vm._l(_vm.content.triggerImages, function(image, i) {
                            return _c("img", { key: i, attrs: { src: image } })
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "address-search__tongue-title" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.content.triggerText) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }