<script>
import { get } from '../../../api/api.js';
import nemidMixin from '../../../mixins/nemid-mixin';
import { CButton, CInformationBox } from 'olympus/components';
import { providerKey as checkoutProviderKey } from '../../../providers/checkout-provider.js';

export default {
  name: 'checkout-nemid-enterprise',

  components: {
    CButton,
    CInformationBox
  },

  mixins: [nemidMixin],

  props: {
    skipNemId: Boolean
  },

  data() {
    return {
      cvrNumber: '',
      cvrConfirmed: false,
      isExistingCustomer: false,
      cvrMismatch: false,
      useKeyCard: true,
      companyData: '',
      animateIn: true,
      fetching: false,
      skipCpr: true,
      segment: '2'
    };
  },

  inject: [checkoutProviderKey],

  methods: {
    handleCVRchange(e) {
      this.cvrNumber = e.target.value;
    },

    fetchValidateCvr(e) {
      e.preventDefault();
      this.fetching = true;
      get('/company/' + this.cvrNumber)
        .then(json => {
          this.cvrConfirmed = true;
          this.showingError = false;
          this.renderNemidBlock();
          this.companyData = json.data;
          this.fetching = false;
        })
        .catch(() => {
          this.showingError = true;
          this.fetching = false;
        });
    },

    changeCVR(e) {
      e.preventDefault();
      this.cvrNumber = '';
      this.cvrConfirmed = false;
      this.showingError = false;
      this.isExistingCustomer = false;
      this.cvrMismatch = false;

      this.temporaryModel.secret = '';
      this.temporaryModel.token = '';
      this.temporaryModel.cvr = '';
      this.parameters = '';
    },

    renderNemidBlock() {
      this.temporaryModel.secret = this.secret ? this.secret : '';
      this.temporaryModel.token = this.token ? this.token : '';
      this.temporaryModel.cvr = this.cvrNumber ? this.cvrNumber : '';

      if (this.skipNemId) {
        this.$nextTick(() => {
          this.nemidResponseSuccess();
        });
      }

      if (!this.hasParameters) {
        this._fetchParams(this.useKeyCard ? 1 : 2);
      }
    },

    toggleNemIdMode() {
      this.useKeyCard = !this.useKeyCard;
      this._fetchParams(this.useKeyCard ? 1 : 2);
    },

    nemidResponseError(response) {
      this.cvrConfirmed = false;
      this.cvrMismatch = response.cvrMismatch;
      this.isExistingCustomer = response.isExistingCustomer;
    },
    nemidResponseSuccess() {
      this[checkoutProviderKey].submit();
    }
  }
};
</script>
