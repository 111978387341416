<template>
  <c-card
    no-padding
    no-shadow
    no-rounding
    class="
      cm-c-checkout__module cm-c-checkout__module--mb
      cm-c-total__section
      billing-address
    "
  >
    <h3 class="cm-c-checkout__subtitle">{{ content.header }}</h3>
    <c-row>
      <c-col sm="12" cols="6">
        <strong>{{ content.addressLabel }}</strong>
      </c-col>
      <c-col sm="12" cols="6" class="cm-text-right-md">
        <ul>
          <li>{{ data.organization }}</li>
          <li>{{ data.name }}</li>
          <li>{{ data.addressLine }}</li>
          <li v-if="data.postalCode && data.city">
            {{ `${data.postalCode} ${data.city}` }}
          </li>
        </ul>
      </c-col>
    </c-row>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import { CRow, CCol } from 'olympus/components';

export default {
  name: 'CheckoutConfirmationBillingAddress',

  components: {
    CCard,
    CRow,
    CCol
  },

  props: {
    content: {
      type: Object,
      default: () => ({
        header: '',
        addressLabel: ''
      })
    },
    data: {
      type: Object,
      default: () => ({
        organization: '',
        name: '',
        addressLine: '',
        postalCode: '',
        city: ''
      })
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';

.billing-address {
  font-size: $font-size-h5;
}
</style>
