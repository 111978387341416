<script>
import CFormSelect from '../../../../../shared/components/form/c-form-select.vue';
import CButton from '../../../../../shared/components/c-button/c-button.vue';

export default {
  name: 'topup-rcc',
  components: {
    CFormSelect,
    CButton
  },
  props: ['value', 'opts'],
  data() {
    return {
      isConfirmMode: false,
      selectedValue: '',
      limitValues: []
    };
  },
  mounted: function() {
    if (this.value === '0' || this.value === '450') {
      this.selectedValue = '450';
    }
    this.selectedValue = this.value;
    this.limitValues = JSON.parse(this.opts);
  },
  methods: {
    setConfirmMode() {
      this.isConfirmMode = true;
    },
    cancelConfirm() {
      this.isConfirmMode = false;
    }
  }
};
</script>
