var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classModifiers },
    [
      _vm._l(_vm.filteredItems, function(group, groupIndex) {
        return _c(
          "div",
          { key: groupIndex, staticClass: "c-theme-block__container" },
          _vm._l(group, function(ref, index) {
            var image = ref.image
            var link = ref.link
            var heading = ref.heading
            var subtitle = ref.tagLine
            var description = ref.teaser
            return _c("c-theme-block-item", {
              key: groupIndex + "_" + index,
              attrs: {
                subtitle: subtitle,
                heading: heading,
                description: description,
                image: image,
                link: link,
                index: index
              }
            })
          }),
          1
        )
      }),
      _vm._v(" "),
      !_vm.depleted && _vm.isGridLayout
        ? _c(
            "button",
            {
              staticClass: "c-theme-block__more c-link",
              on: {
                click: function($event) {
                  return _vm.fetchArticles(true)
                }
              }
            },
            [_vm._t("button")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }