var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "\n      cm-c-checkout__module\n      cm-c-checkout__module--pt\n      cm-c-checkout__module--mb\n    "
        },
        [
          _c(
            "c-heading",
            {
              staticClass: "mb-1",
              attrs: { slot: "header", level: "3", as: "3" },
              slot: "header"
            },
            [_vm._v("\n      " + _vm._s(_vm.contentModel.pageTitle) + "\n    ")]
          ),
          _vm._v(" "),
          _c("p", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.contentModel.pageDescription) }
          }),
          _vm._v(" "),
          _c("checkout-select-payment", {
            attrs: {
              "payment-methods": _vm.paymentMethods,
              "selected-payment-method-value": _vm.selectedPaymentMethodValue,
              "finance-type": _vm.parsedFinanceType,
              "is-tf-supported": _vm.contentModel.tfSupported,
              "loading-state": _vm.paymentOptionLoadingState
            },
            on: {
              "payment-method-selected": _vm.selectPaymentMethod,
              "update-credit-agreement-amount": _vm.updateCreditAgreementAmount
            }
          }),
          _vm._v(" "),
          _vm.showActivationBox
            ? _c(
                "div",
                { staticClass: "checkout-select-payment__activation-box" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "checkout-select-payment__activation-box-image"
                    },
                    [
                      _vm.contentModel.genericFinancePaymentOptionIcon
                        ? _c("img", {
                            staticClass:
                              "checkout-select-payment__activation-box-image__icon",
                            attrs: {
                              src:
                                _vm.contentModel
                                  .genericFinancePaymentOptionIcon,
                              role: "presentation"
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "checkout-select-payment__activation-box-content"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "checkout-select-payment__activation-box-content-title"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.contentModel
                                  .genericFinancePaymentOptionTitle
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "checkout-select-payment__activation-box-content-description",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.contentModel
                              .genericFinancePaymentOptionDescription
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "checkout-select-payment__activation-box-content-button"
                        },
                        [
                          !_vm.contentModel.disableFinance
                            ? _c(
                                "c-button",
                                { on: { click: _vm.openFinanceModal } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.contentModel
                                          .genericFinancePaymentOptionButtonText
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showResursBanner
            ? _c(
                "div",
                { staticClass: "checkout-select-payment__banner-container" },
                [_vm._t("banner")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.showChangeFinanceLink && !_vm.contentModel.disableFinance
                ? _c(
                    "c-button",
                    {
                      attrs: { variant: "link" },
                      on: { click: _vm.openFinanceModal }
                    },
                    [_vm._v(_vm._s(_vm.reOpenModalLabel))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("checkout-finance-summary", {
        attrs: { sections: _vm.paymentSummary, "full-width": true }
      }),
      _vm._v(" "),
      _vm.isButtonsShowing
        ? _c(
            "div",
            {
              staticClass:
                "cm-c-checkout cm-c-checkout-summary__button-container"
            },
            [
              _vm.contentModel.previousButtonText
                ? _c(
                    "c-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.goToPreviousPage }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.contentModel.previousButtonText) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contentModel.nextButtonText
                ? _c(
                    "c-button",
                    {
                      attrs: { disabled: !_vm.selectionIsValid },
                      on: { click: _vm.goToNextPage }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.contentModel.nextButtonText) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("c-modal", { ref: "financeModal", attrs: { gray: "" } }, [
        _c(
          "div",
          { staticClass: "checkout-select-payment__modal-container" },
          [
            _c(
              "h3",
              { staticClass: "checkout-select-payment__modal-heading" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.contentModel.modalDialogTitle) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "checkout-select-payment__modal-content",
              domProps: {
                innerHTML: _vm._s(_vm.contentModel.modalDialogDescription)
              }
            }),
            _vm._v(" "),
            _c(
              "c-form-radio-group",
              {
                ref: "financeRadioGroup",
                staticClass: "checkout-select-payment__modal-selection",
                attrs: {
                  name: "oldFinanceSelection",
                  highlighted: true,
                  stacked: ""
                },
                model: {
                  value: _vm.modalSelectedOption,
                  callback: function($$v) {
                    _vm.modalSelectedOption = $$v
                  },
                  expression: "modalSelectedOption"
                }
              },
              _vm._l(_vm.contentModel.modelDialogOptions, function(option) {
                return _c(
                  "c-form-radio",
                  {
                    key: option.upfrontPaymentMethodFilter.toString(),
                    attrs: {
                      id: option.upfrontPaymentMethodFilter.toString(),
                      value: option.upfrontPaymentMethodFilter.toString()
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "checkout-select-payment__modal-selection__label"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(option.optionText) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "checkout-select-payment__modal-button-container"
              },
              [
                _c(
                  "c-button",
                  {
                    attrs: { variant: "tertiary" },
                    on: {
                      click: function($event) {
                        return _vm.closeFinanceModal()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.contentModel.modalDialogCancelButtonText))]
                ),
                _vm._v(" "),
                _c(
                  "c-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.selectOldFinance()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.contentModel.modalDialogContinueButtonText)
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }