var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "product-number-selector" },
    [
      _c("c-icon", {
        staticClass: "product-number-selector__icon",
        attrs: { "symbol-id": "service-mobil" }
      }),
      _vm._v(" "),
      _c("h2", [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.anyNumbersRegistered
                ? _vm.content.headingText
                : _vm.content.unavailableNumbersHeadingText
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("h3", [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.anyNumbersRegistered
                ? _vm.content.subHeading
                : _vm.content.unavailableNumbersSubHeading
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "product-number-selector__numbers" },
        [
          !_vm.init
            ? _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" }, [
                _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
              ])
            : _vm.anyNumbersRegistered
            ? _vm._l(_vm.existingNumbers, function(ref) {
                var id = ref.id
                var name = ref.name
                var phoneNumber = ref.phoneNumber
                var isAvailable = ref.isAvailable
                var errorMessage = ref.errorMessage
                return _c(
                  "user-card",
                  {
                    key: id,
                    attrs: {
                      "subscription-name": name,
                      "phone-number": phoneNumber,
                      "right-action-button-text": _vm.content.selectButtonText,
                      "right-action-disabled-button-text":
                        _vm.content.disabledButtonText,
                      disabled: !isAvailable
                    },
                    on: { rightActionPressed: _vm.selectNumber }
                  },
                  [
                    errorMessage
                      ? _c("span", {
                          domProps: { innerHTML: _vm._s(errorMessage) }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedNumber === phoneNumber
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "product-number-selector__selected-label",
                            attrs: { slot: "right-action" },
                            slot: "right-action"
                          },
                          [
                            _c("c-icon", {
                              attrs: { "symbol-id": "service-checkmark" }
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.content.selectedNumberLabel) +
                                "\n        "
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "user-card",
            {
              attrs: {
                "subscription-name": _vm.content.noNumberHelpLabel,
                "phone-number": _vm.content.noNumberLabel
              }
            },
            [
              _c("c-icon", {
                attrs: { slot: "user-image", "symbol-id": "service-simcard" },
                slot: "user-image"
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "c-btn c-btn--sm",
                  attrs: { slot: "right-action", type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.selectNumber({ phoneNumber: null })
                    }
                  },
                  slot: "right-action"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.content.selectButtonText) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }