/* Tabs [data-comparison-tab] */
export const tabs = {
  init() {
    const tabs = document.querySelectorAll('[data-tab]');

    // Bind click events to tabs
    tabs.forEach(tab => {
      tab.addEventListener('click', function() {
        const elements = document.querySelectorAll(
          '[data-tab-item="' + tab.dataset.tab + '"]'
        );
        const alreadyActiveElements = document.querySelectorAll(
          '[data-tab-item].is-active'
        );
        const alreadyActiveTab = document.querySelector('[data-tab].is-active');

        // Toggle button active state
        alreadyActiveTab.classList.remove('is-active');
        tab.classList.add('is-active');

        // Toggle content active state
        alreadyActiveElements.forEach(alreadyActiveElement => {
          alreadyActiveElement.classList.remove('is-active');
        });
        elements.forEach(element => {
          element.classList.add('is-active');
        });
      });
    });
  }
};
