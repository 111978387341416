<template>
  <div :class="useCheckoutModuleStyling">
    <transition-group name="fade" tag="div">
      <div
        v-for="section in sections"
        :key="section.title"
        class="checkout-finance-summary__section"
      >
        <div class="checkout-finance-summary__section-title">
          {{ section.title }}
        </div>

        <c-row tag="dl" no-gutters>
          <template v-for="(item, index) in section.items">
            <c-col
              :key="`dt-${item.label}-${index}`"
              cols="6"
              tag="dt"
              class="checkout-finance-summary__section-content"
              >{{ item.label }}</c-col
            >
            <c-col
              :key="`dd-${item.label}-${index}`"
              cols="6"
              tag="dd"
              class="text-right"
              >{{ item.value }}</c-col
            >
          </template>
        </c-row>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { CRow, CCol } from 'olympus/components/index.js';

export default {
  name: 'CheckoutFinanceSummary',

  components: {
    CRow,
    CCol
  },

  props: {
    sections: {
      type: Array,
      required: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      errors: {}
    };
  },

  computed: {
    useCheckoutModuleStyling() {
      return this.fullWidth
        ? `cm-c-checkout__module
        cm-c-checkout__module--pt
        cm-c-checkout__module--mb
        cm-c-checkout__module--no-bg
        cm-c-total__section`
        : 'checkout-finance-summary__details';
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

// classes for transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// .checkout-finance-summary
.checkout-finance-summary {
  // .checkout-finance-summary__section
  &__section {
    display: flex;
    flex-direction: column;
    padding-bottom: $u-400;
    gap: $u-200;

    // .checkout-finance-summary__section-title
    &-title {
      font-size: $font-size-h4;
      font-weight: 600;
    }

    // .checkout-finance-summary__section-content
    &-content {
      margin-bottom: $u-200;
    }
  }

  // .checkout-finance-summary__details
  &__details {
    margin: $u-400 0;
    border-top: $u-100 solid $c-nt-600;
    padding-top: $u-400;
  }

  // .checkout-finance-summary__section:not(:last-child)
  &__section:not(:last-child) {
    border-bottom: 1px solid $c-nt-500;
    margin-bottom: $u-400;
  }
}
</style>
