var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-row",
    _vm._l(_vm.orderDetailSections, function(section) {
      return _c(
        "c-col",
        { key: section.title, attrs: { md: section.col } },
        [
          _c(
            "c-box",
            { attrs: { white: "" } },
            [
              _c("c-heading", { attrs: { as: 2 } }, [
                _vm._v("\n        " + _vm._s(section.title) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "dl",
                [
                  _vm._l(section.fields, function(value, name) {
                    return [
                      _c(
                        "dt",
                        { key: name + "-dt" },
                        [
                          _c("c-text", [
                            _vm._v(
                              "\n              " +
                                _vm._s(name) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "dd",
                        { key: name + "-dd" },
                        [
                          !value
                            ? _c("c-text", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.emptyValueText) +
                                    "\n            "
                                )
                              ])
                            : Array.isArray(value)
                            ? [
                                value.length === 0
                                  ? _c("c-text", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.emptyValueText) +
                                          "\n              "
                                      )
                                    ])
                                  : _c(
                                      "ul",
                                      _vm._l(value, function(column) {
                                        return _c(
                                          "li",
                                          { key: column.key },
                                          [
                                            _c("c-text", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(column) +
                                                  "\n                  "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                              ]
                            : _c("c-text", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(value) +
                                    "\n            "
                                )
                              ])
                        ],
                        2
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }