<template>
  <main>
    <c-card extra-padding no-shadow no-rounding>
      <employee-config-carousel
        :content="content"
        @selected="setSelectedOffering"
      />
      <employee-config-plug
        v-if="form.order && form.showPlug"
        :content="content"
        :offering="form.selectedOffering"
        @update="updateFormData"
      />
      <employee-config-poa
        v-if="form.order && form.showSbbu"
        :content="content"
        :offering="form.selectedOffering"
        @update="updateFormData"
      />
      <employee-config-datepicker
        v-if="form.order"
        :content="content"
        :has-cancellation-assistance-option="form.cancelationAssist"
        :offering="form.selectedOffering"
        @update="updateFormData"
      />
    </c-card>
    <home-office-validation-modal
      :title="content.validationErrorModalText.heading"
      :error-message="content.validationErrorModalText.content"
      :visible="showCheckoutError"
    />
  </main>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import EmployeeConfigCarousel from './employee-config-carousel.vue';
import EmployeeConfigPlug from './employee-config-plug.vue';
import EmployeeConfigPoa from './employee-config-poa.vue';
import EmployeeConfigDatepicker from './employee-config-datepicker.vue';
import HomeOfficeValidationModal from './home-office-validation-modal.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  MUTATIONS as HOME_OFFICE_MUTATIONS,
  ACTIONS as HOME_OFFICE_ACTIONS
} from 'webshop/store/home-office/index.js';
import { MUTATIONS as CHECKOUT_MUTATIONS } from 'webshop/components/checkout/store';

import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';

import { required, requiredIf, email } from 'vuelidate/lib/validators/index.js';

const formValidations = {
  selectedOffering: {
    required
  },

  hasPlug: {
    required: requiredIf(vm => vm.showPlug)
  },

  cancelationAssist: {
    required: requiredIf(vm => vm.showSbbu === true)
  },

  lineOwner: {
    required: requiredIf(vm => vm.cancelationAssist)
  },

  currentProvider: {
    required: requiredIf(vm => vm.isMandatory && vm.cancelationAssist === true)
  },

  activationDateAsap: {
    required
  },

  activationDate: {
    required: requiredIf(vm => vm.activationDateAsap === false)
  }
};

export default {
  name: 'EmployeeConfig',

  components: {
    CCard,
    EmployeeConfigCarousel,
    EmployeeConfigPlug,
    EmployeeConfigPoa,
    EmployeeConfigDatepicker,
    HomeOfficeValidationModal
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: {
        selectedOffering: null,
        hasPlug: null,
        order: {},
        cancelationAssist: null,
        lineOwner: null,
        currentProvider: '',
        ownerEmail: '',
        activationDateAsap: true,
        activationDate: '',
        isMandatory: this.content.isMandatory,
        showPlug: true,
        showSbbu: true
      },
      HOME_OFFICE_STEPS
    };
  },

  validations() {
    return {
      form: {
        ...formValidations,
        ownerEmail: this.isOwnerEmailRequired
          ? {
              required,
              email
            }
          : {}
      }
    };
  },

  computed: {
    ...mapGetters({
      showCheckoutError: HOME_OFFICE_GETTERS.GET_SHOW_CHECKOUT_ERROR,
      getOrderData: HOME_OFFICE_GETTERS.GET_ORDER_DATA
    }),

    ready() {
      return !this.$v.$invalid;
    },

    isOwnerEmailRequired() {
      return this.form.cancelationAssist && this.form.lineOwner === false;
    }
  },

  watch: {
    ready(val) {
      this.initializeComponentValidation(val);
    },
    getOrderData(val) {
      this.initialiseFormState(val);
    }
  },

  mounted() {
    this.setComponentValidation(false);
    this.setEndpoint(HOME_OFFICE_STEPS.PRODUCT_CONFIGURATION);
  },

  methods: {
    ...mapMutations({
      setIsLoading: HOME_OFFICE_MUTATIONS.SET_IS_LOADING,
      setComponentValidation:
        CHECKOUT_MUTATIONS.SET_EMPLOYEE_INFORMATION_VALIDATION,
      setFormData: CHECKOUT_MUTATIONS.SET_CHECKOUT_FORM_DATA,
      setEndpoint: CHECKOUT_MUTATIONS.SET_CHECKOUT_ENDPOINT,
      setShowCheckoutError: HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR
    }),

    ...mapActions({
      addOfferingToBasket: BASKET_ACTIONS.ADD_BROADBAND,
      fetchEmployeeData: HOME_OFFICE_ACTIONS.FETCH_EMPLOYEE_DATA
    }),

    initializeComponentValidation(val) {
      this.setComponentValidation(val);
    },

    initialiseFormState(val) {
      this.form.showPlug = val.askAboutPlug;
      this.form.showSbbu = val.askAboutSbbu;
      this.form.hasPlug = val.hasPlug;
      this.setFormData(this.form);
    },

    setSelectedOffering(e) {
      this.setIsLoading(true);
      this.addOfferingToBasket(e.selectedOffering)
        .then(() => {
          this.updateFormData(e.order);
          this.form.selectedOffering = e.selectedOffering;
          this.fetchEmployeeData();
          this.setIsLoading(false);
        })
        .catch(() => this.setShowCheckoutError(true));
    },

    updateFormData(e) {
      this.form = { ...this.form, ...e };
      this.setFormData(this.form);
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';

.employee-config {
  &__legend {
    margin-bottom: $u-500;
  }

  &__headline {
    margin-bottom: $u-200;
  }

  &__footer {
    margin-top: $u-300;
  }
}
</style>
