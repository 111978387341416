var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cm-c-product-tooltip-align",
      class: {
        "cm-c-product-tooltip-align--accordion": _vm.accordion
      }
    },
    [
      _c(
        "div",
        {
          ref: "content",
          staticClass: "cm-c-product-tooltip",
          class: {
            "-below": _vm.showBelow,
            "cm-c-product-tooltip--accordion": _vm.accordion
          }
        },
        [
          _vm.headline
            ? _c("h3", [_vm._v("\n      " + _vm._s(_vm.headline) + "\n    ")])
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "cm-mb-top--ssm",
            domProps: { innerHTML: _vm._s(_vm.description) }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cm-c-total cm-mb-top--ssm" },
            [
              _vm._l(_vm.details.rows, function(row, rowIndex) {
                return _c("div", { key: rowIndex }, [
                  _c(
                    "div",
                    {
                      class:
                        row.length > 0
                          ? "cm-c-total__row--bottom-separator--sm"
                          : ""
                    },
                    _vm._l(row, function(line, lineIndex) {
                      return _c(
                        "div",
                        { key: lineIndex, staticClass: "cm-c-total__row" },
                        [
                          _c("div", {
                            staticClass: "cm-c-total__name",
                            domProps: { innerHTML: _vm._s(line.property) }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cm-c-total__value" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(line.value) +
                                "\n              "
                            ),
                            _c("p", { staticClass: "cm-c-total__value--sm" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(line.quantity) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              }),
              _vm._v(" "),
              !_vm.hideTotals
                ? _c("div", { staticClass: "cm-c-total__row" }, [
                    _c(
                      "div",
                      { staticClass: "cm-c-total__name cm-c-total--bold" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.details.total.property) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cm-c-total__value cm-c-total--bold" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.details.total.value) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      !_vm.accordion
        ? _c(
            "a",
            {
              ref: "activator",
              staticClass: "cm-link--minimum-price",
              class: { "-below": _vm.showBelow }
            },
            [
              _c("c-icon", {
                staticClass: "cm-link--help-icon",
                attrs: { "symbol-id": "contact-information" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }