<template>
  <div class="c-accordion">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'CAccordion',

  provide() {
    return {
      accordionInject: {
        options: this.accordionOptions,
        register: this.register
      }
    };
  },

  props: {
    isModelBound: {
      type: Boolean,
      default: false
    },
    expandedId: {
      type: String,
      default: ''
    },
    transitionDuration: {
      type: String,
      default: '500ms'
    },
    transitionTimingFunction: {
      type: String,
      default: 'ease-in-out'
    }
  },

  data() {
    return {
      accordionOptions: {
        selectedId: '',
        transitionDuration: this.transitionDuration,
        transitionTimingFunction: this.transitionTimingFunction
      }
    };
  },

  watch: {
    expandedId: function (val) {
      // If the parent is handling the state then we should set the selected id here
      if (this.isModelBound) {
        this.setSelected(val);
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.expandedId) {
        this.setSelected(this.expandedId);
      }
    });
  },

  methods: {
    setSelected(value) {
      this.$set(
        this.accordionOptions,
        'selectedId',
        value === this.accordionOptions.selectedId ? undefined : value
      );
    },
    register(value, emit) {
      // If the parent is handling the state then we should not set the selected id here
      if (this.isModelBound) {
        if (emit) {
          this.$emit(
            'change',
            value === this.accordionOptions.selectedId ? undefined : value
          );
        }
      } else {
        this.setSelected(value);
        if (emit) {
          this.$emit('change', this.accordionOptions.selectedId);
        }
      }
    }
  }
};
</script>
<style lang="scss">
.c-accordion {
  &-item {
    &__trigger {
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
