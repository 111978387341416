var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "epi-form-text",
      class: { "epi-form-text--error": !!_vm.inputError }
    },
    [
      _c(
        "c-input",
        _vm._b(
          {
            on: {
              change: _vm.onChange,
              input: _vm.onInput,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.performSubmit($event)
              },
              keypress: _vm.keypress
            }
          },
          "c-input",
          _vm.$props,
          false
        )
      ),
      _vm._v(" "),
      _vm.inputError
        ? _c("span", { staticClass: "field-validation-error c-input__error" }, [
            _vm._v("\n    " + _vm._s(_vm.inputError) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "epi-form-text__label c-input__label",
          attrs: { for: "c-input" }
        },
        [_vm._v("\n    " + _vm._s(_vm.placeHolderLabel) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }