var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "config-group-item",
    {
      attrs: { title: _vm.title, icon: _vm.icon, expanded: _vm.expanded },
      on: { expanded: _vm.handleExpanded }
    },
    [
      _c(
        "fieldset",
        { staticClass: "porting-config" },
        [
          _c(
            "div",
            {
              staticClass:
                "porting-config__section porting-config__section--no-margin"
            },
            [
              _c(
                "c-row",
                { staticClass: "porting-config__section__row" },
                [
                  _c(
                    "c-col",
                    [
                      _c("c-input-group", {
                        attrs: {
                          name: "number",
                          "place-holder-label":
                            _vm.content.numberPorting.numberToBeMovedLabel,
                          "input-error": _vm.numberError,
                          type: "number"
                        },
                        model: {
                          value: _vm.$v.form.number.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.number, "$model", $$v)
                          },
                          expression: "$v.form.number.$model"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "porting-config__section__row" },
                [
                  _c(
                    "c-col",
                    [
                      _c("c-input-group", {
                        attrs: {
                          name: "portFromSimNumber",
                          "place-holder-label":
                            _vm.content.simCardNumberPlaceholder,
                          "input-error": _vm.portFromSimNumberError,
                          type: "number"
                        },
                        model: {
                          value: _vm.$v.form.simNumber.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.form.simNumber, "$model", $$v)
                          },
                          expression: "$v.form.simNumber.$model"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.numberPorting.noSimTitle
                ? _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "porting-config__section__row__tooltip"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "porting-config__section__row__sim-info-text"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.content.numberPorting.noSimTitle) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "popover-panel",
                              {
                                attrs: {
                                  description:
                                    _vm.content.numberPorting.noSimDescription,
                                  "popover-triggers": ["hover", "click"],
                                  "tooltip-screen-reader-text":
                                    _vm.content.tooltipScreenReaderText,
                                  "popover-offset-x": -90
                                }
                              },
                              [
                                _c("c-icon", {
                                  staticClass:
                                    "porting__tooltip__icon-wrapper__icon",
                                  attrs: { "symbol-id": "service-information" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.numberPorting.howToFindYourSimNumberLinkText
                ? _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", [
                        _c(
                          "button",
                          {
                            staticClass: "c-btn c-btn--as-link",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showSimModal($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.content.numberPorting
                                    .howToFindYourSimNumberLinkText
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.content.numberPorting.howToFindSimPopup
            ? _c(
                "c-modal",
                {
                  ref: "howToFindSimModal",
                  attrs: {
                    title: _vm.content.numberPorting.howToFindSimPopup.headline,
                    "ok-button-text":
                      _vm.content.numberPorting.howToFindSimPopup.buttonText,
                    centered: "",
                    "show-ok-button": ""
                  }
                },
                [
                  _c("div", { staticClass: "howToFindSimModal" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.content.numberPorting.howToFindSimPopup
                            .description
                        )
                      }
                    }),
                    _vm._v(" "),
                    _vm.content.numberPorting.howToFindSimPopup.imageSource
                      ? _c("img", {
                          staticClass: "howToFindSimModal__img",
                          attrs: {
                            src:
                              _vm.content.numberPorting.howToFindSimPopup
                                .imageSource,
                            alt: _vm.simImageAlt
                          }
                        })
                      : _vm._e()
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "porting-config__section" },
            [
              _c(
                "c-row",
                { staticClass: "porting-config__section__row" },
                [
                  _c("c-col", [
                    _c("h3", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.content.numberPorting.powerOfAttorneyHeader
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "porting-config__section__row" },
                [
                  _c("c-col", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.content.numberPorting.powerOfAttorneyLinkText
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "porting-config__section" },
            [
              _c("legend", { staticClass: "porting-config__legend" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.numberPorting.ownerOfNumberText) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "c-row",
                { staticClass: "porting-config__section__row" },
                [
                  _c(
                    "c-col",
                    {
                      staticClass: "porting-config__section__row__chip-wrapper",
                      attrs: { cols: "6", sm: "12" }
                    },
                    [
                      _c(
                        "c-chip",
                        {
                          attrs: {
                            name: "isLegalOwner",
                            "data-testid": "porting-config__chip-input",
                            "input-value": true
                          },
                          on: { change: _vm.setLegalOwner }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.content.numberPorting
                                .ownerOfNumberYesButtonText
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-col",
                    {
                      staticClass: "porting-config__section__row__chip-wrapper",
                      attrs: { cols: "6", sm: "12" }
                    },
                    [
                      _c(
                        "c-chip",
                        {
                          attrs: {
                            name: "isLegalOwner",
                            "data-testid": "porting-config__chip-input",
                            "input-value": false
                          },
                          on: { change: _vm.setLegalOwner }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.content.numberPorting
                                .ownerOfNumberNoButtonText
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "owner", visible: _vm.showPrivateOrBusinessStep } },
            [
              _c(
                "div",
                {
                  staticClass: "porting-config__section",
                  class: {
                    "porting-config__section--no-margin":
                      !_vm.showPoaBusinessStep && !_vm.showPoaPrivateStep
                  }
                },
                [
                  _c("legend", { staticClass: "porting-config__legend" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.numberPorting.b2COrB2BCustomerHeaderText
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-form-radio-group",
                    {
                      attrs: {
                        id: "private-or-business",
                        stacked: "",
                        name: "isB2BCustomer"
                      },
                      model: {
                        value: _vm.form.isB2BCustomer,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "isB2BCustomer", $$v)
                        },
                        expression: "form.isB2BCustomer"
                      }
                    },
                    [
                      _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c(
                            "c-col",
                            [
                              _c(
                                "c-form-radio",
                                {
                                  attrs: {
                                    id:
                                      _vm.content.numberPorting
                                        .b2CCustomerRadioButtonLabel,
                                    value: "false"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.content.numberPorting
                                          .b2CCustomerRadioButtonLabel
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c(
                            "c-col",
                            [
                              _c(
                                "c-form-radio",
                                {
                                  attrs: {
                                    id:
                                      _vm.content.numberPorting
                                        .b2BCustomerRadioButtonLabel,
                                    value: "true"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.content.numberPorting
                                          .b2BCustomerRadioButtonLabel
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "notOwner", visible: _vm.showOwnerDisclaimer } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "porting-config__section 'porting-config__section--no-margin"
                },
                [
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c(
                        "c-col",
                        [
                          _c(
                            "c-information-box",
                            {
                              attrs: {
                                icon: "contact-information",
                                type: "informative"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.content.numberPorting.notOwnerDisclaimer
                                  )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "poaBusiness", visible: _vm.showPoaBusinessStep } },
            [
              _c(
                "div",
                { staticClass: "porting-config__section" },
                [
                  _c(
                    "c-row",
                    {
                      staticClass:
                        "porting-config__section__row porting-config__fetch-cvr"
                    },
                    [
                      _c(
                        "c-col",
                        { attrs: { cols: "7", sm: "12" } },
                        [
                          _c("c-input-group", {
                            attrs: {
                              name: "b2bCvr",
                              type: "number",
                              "place-holder-label":
                                _vm.content.business.cvrNumberPlaceholder,
                              "input-error": _vm.b2bCvrError
                            },
                            on: { input: _vm.b2BCvrInput },
                            model: {
                              value: _vm.form.cvrNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "cvrNumber", _vm._n($$v))
                              },
                              expression: "form.cvrNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.getB2BAddress
                    ? _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c("c-col", { attrs: { cols: "12" } }, [
                            _vm.getB2BAddress.organization
                              ? _c("p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getB2BAddress.organization) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.getB2BAddress.address
                              ? _c("p", [
                                  _vm._v(_vm._s(_vm.getB2BAddress.address))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.b2BAddressZipcodeAndCity
                              ? _c("p", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.b2BAddressZipcodeAndCity) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.mappedB2BAddress, function(
                            value,
                            name,
                            idx
                          ) {
                            return _c("input", {
                              key: idx,
                              attrs: { name: name, type: "hidden" },
                              domProps: { value: value }
                            })
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            {
              attrs: {
                id: "poaPrivate",
                visible: _vm.showPoaPrivateStep || _vm.showPoaBusinessStep
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "porting-config__section",
                  class: {
                    "porting-config__section--no-margin": _vm.showPoaPrivateStep
                  }
                },
                [
                  _c("legend", { staticClass: "porting-config__legend" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.numberPorting.powerOfAttorneyHeaderText
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c(
                        "c-col",
                        {
                          staticClass: "porting-config__section__row__checkbox",
                          class: {
                            "porting-config__section__row__checkbox--checked-border":
                              _vm.form.poaAllowed
                          }
                        },
                        [
                          _c(
                            "c-checkbox",
                            {
                              attrs: {
                                id: "porting-allow-poa",
                                name: "isElectronicPowerOfAttorneyAllowed",
                                checked: _vm.form.poaAllowed,
                                value: _vm.form.poaAllowed
                              },
                              on: { "event-id": _vm.toggleCheckbox }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.content.numberPorting
                                      .powerOfAttorneyCheckboxLabel
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showPoaBusinessStep
                ? _c(
                    "div",
                    {
                      staticClass:
                        "porting-config__section porting-config__section--no-margin"
                    },
                    [
                      _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c(
                            "c-col",
                            [
                              _c(
                                "c-information-box",
                                {
                                  attrs: {
                                    icon: "contact-information",
                                    type: _vm.content.numberPorting.b2BPowerOfAttorneyInformationBox.messageBoxType.toLowerCase()
                                  }
                                },
                                [
                                  _c(
                                    "strong",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.content.numberPorting
                                            .b2BPowerOfAttorneyInformationBox
                                            .title
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.content.numberPorting
                                          .b2BPowerOfAttorneyInformationBox
                                          .message
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("p")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "preferences", visible: _vm.showShipmentStep } },
            [
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "porting-config__section" },
                [
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", [
                        _c("h3", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.content.numberPorting
                                  .yourPreferencesHeaderText
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.content.numberPorting.yourPreferencesText
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "porting-config__section" },
                [
                  _c("legend", { staticClass: "porting-config__legend" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.numberPorting.numberTransferTimeHeaderText
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c(
                        "c-col",
                        {
                          staticClass:
                            "porting-config__section__row__chip-wrapper",
                          attrs: { cols: "6", sm: "12" }
                        },
                        [
                          _c(
                            "c-chip",
                            {
                              attrs: {
                                name: "portAfterCurrentBindingPeriod",
                                "data-testid": "porting-config__chip-input",
                                "input-value": true,
                                preselected: ""
                              },
                              on: { change: _vm.setDateChip }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.content.numberPorting
                                    .numberTransferAsapButtonText
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "c-col",
                        {
                          staticClass:
                            "porting-config__section__row__chip-wrapper",
                          attrs: { cols: "6", sm: "12" }
                        },
                        [
                          _c(
                            "c-chip",
                            {
                              attrs: {
                                name: "portAfterCurrentBindingPeriod",
                                "data-testid": "porting-config__chip-input",
                                "input-value": false
                              },
                              on: { change: _vm.setDateChip }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.content.numberPorting
                                    .numberTransferSpecificDateButtonText
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-collapse",
                {
                  attrs: {
                    id: "number-porting-info",
                    visible: _vm.form.portNumberAsap === "true"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "porting-config__section" },
                    [
                      _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c(
                            "c-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "c-information-box",
                                {
                                  attrs: {
                                    icon: "contact-information",
                                    type: _vm.portingDateDisclaimerType
                                  }
                                },
                                [
                                  _c(
                                    "strong",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.content.numberPorting
                                            .portingDateDisclaimer.title
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.content.numberPorting
                                          .portingDateDisclaimer.message
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "c-collapse",
                {
                  attrs: { id: "datepicker", visible: _vm.showDateSelectorStep }
                },
                [
                  _c(
                    "div",
                    { staticClass: "porting-config__section" },
                    [
                      _c(
                        "c-row",
                        { staticClass: "porting-config__section__row" },
                        [
                          _c(
                            "c-col",
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  label:
                                    _vm.content.numberPorting
                                      .chooseDateDatePickerLabel,
                                  "disabled-dates": _vm.content.disabledDates,
                                  "initial-date": _vm.firstPortingDate,
                                  "data-testid": "porting-config__datepicker",
                                  "input-name": "portingDate",
                                  clearable: false
                                },
                                on: { change: _vm.setSelectedDate }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.content.hideSimTypeOptions
                ? _c("sim-card-selection", {
                    staticClass: "porting-config__section",
                    attrs: {
                      "config-data": _vm.configData,
                      options: _vm.simCardSettings,
                      "checked-sim-radio": _vm.checkedSimRadio
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.SIM_OPTIONS.SHIP_SIM,
                        expression: "SIM_OPTIONS.SHIP_SIM"
                      }
                    ],
                    attrs: { type: "hidden", name: "SimOption" },
                    domProps: { value: _vm.SIM_OPTIONS.SHIP_SIM },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.SIM_OPTIONS,
                          "SHIP_SIM",
                          $event.target.value
                        )
                      }
                    }
                  }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "porting-config__section porting-config__section--no-margin"
                },
                [
                  _c("legend", { staticClass: "porting-config__legend" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.content.optionalSettingsHeadline) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "porting-config__section__row__tooltip",
                            class: {
                              "porting-config__section__row__tooltip--checked-border":
                                _vm.form.secretNumber
                            }
                          },
                          [
                            _c(
                              "c-checkbox",
                              {
                                attrs: {
                                  id: _vm.content.secretNumberLabel,
                                  name: "isNumberHidden",
                                  checked: _vm.form.secretNumber
                                },
                                on: { "event-id": _vm.toggleCheckbox },
                                model: {
                                  value: _vm.form.secretNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "secretNumber", $$v)
                                  },
                                  expression: "form.secretNumber"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.content.secretNumberLabel) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "popover-panel",
                              {
                                attrs: {
                                  description:
                                    _vm.content.secretNumberDescription,
                                  "tooltip-screen-reader-text":
                                    _vm.content.tooltipScreenReaderText,
                                  "popover-triggers": ["hover", "click"],
                                  "base-classes": "porting__tooltip"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "porting__tooltip__icon-wrapper"
                                  },
                                  [
                                    _c("c-icon", {
                                      staticClass:
                                        "porting__tooltip__icon-wrapper__icon",
                                      attrs: {
                                        "symbol-id": "service-information"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-row",
                    { staticClass: "porting-config__section__row" },
                    [
                      _c("c-col", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "porting-config__section__row__tooltip",
                            class: {
                              "porting-config__section__row__tooltip--checked-border":
                                _vm.form.leaveOutNumber
                            }
                          },
                          [
                            _c(
                              "c-checkbox",
                              {
                                attrs: {
                                  id: _vm.content.unlistedNumberLabel,
                                  name: "isNumberUnlisted",
                                  checked: _vm.form.leaveOutNumber
                                },
                                on: { "event-id": _vm.toggleCheckbox },
                                model: {
                                  value: _vm.form.leaveOutNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "leaveOutNumber", $$v)
                                  },
                                  expression: "form.leaveOutNumber"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.content.unlistedNumberLabel) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "popover-panel",
                              {
                                attrs: {
                                  description:
                                    _vm.content.unlistedNumberDescription,
                                  "tooltip-screen-reader-text":
                                    _vm.content.tooltipScreenReaderText,
                                  "popover-triggers": ["hover", "click"],
                                  "base-classes": "porting__tooltip"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "porting__tooltip__icon-wrapper"
                                  },
                                  [
                                    _c("c-icon", {
                                      staticClass:
                                        "porting__tooltip__icon-wrapper__icon",
                                      attrs: {
                                        "symbol-id": "service-information"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }