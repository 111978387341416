<template>
  <div class="c-accordion-item">
    <div
      role="button"
      :aria-expanded="isSelected ? 'true' : 'false'"
      :aria-controls="'accordion-content-' + itemId"
      class="c-accordion-item__trigger"
      @click="toggleCollapse()"
    >
      <slot name="title" :expanded="isSelected" />
    </div>
    <c-collapse
      :id="'accordion-content-' + itemId"
      :visible="isSelected"
      role="region"
      :transition-duration="transitionDuration"
      :transation-easing="transitionEasing"
      :aria-labelled-by="'accordion-button-' + itemId"
      :aria-hidden="!isSelected ? 'true' : 'false'"
    >
      <slot name="content" :expanded="isSelected" />
    </c-collapse>
    <div :aria-controls="'accordion-content-' + itemId">
      <slot name="info-box" />
    </div>
  </div>
</template>
<script>
import CCollapse from 'olympus/components/c-collapse/c-collapse.vue';

export default {
  name: 'CAccordionItem',

  components: {
    CCollapse
  },

  inject: {
    accordionInject: {
      from: 'accordionInject',
      default: false
    }
  },

  props: {
    itemId: {
      type: String,
      default: 'default'
    }
  },

  computed: {
    accordionSelectId() {
      return this.accordionInject?.options.selectedId;
    },

    isSelected() {
      return this.accordionSelectId === this.itemId;
    },

    transitionDuration() {
      return this.accordionInject?.options.transitionDuration;
    },

    transitionEasing() {
      return this.accordionInject?.options.transitionTimingFunction;
    }
  },

  methods: {
    toggleCollapse() {
      // eslint-disable-next-line no-unused-expressions
      this.accordionInject?.register(this.itemId, true);
      this.$emit('change', this.itemId);
    }
  }
};
</script>
