import Bowser from 'bowser';

export function addUseragentClasses() {
  try {
    const bowserParsed = Bowser.parse(window.navigator.userAgent);

    document.documentElement.classList.add(
      `is-${bowserParsed.browser.name.toLowerCase()}`
    );
    document.documentElement.classList.add(
      `is-${bowserParsed.os.name.toLowerCase()}`
    );
  } catch (error) {
    // console.error(error);
  }
}

export function safariPolyFill() {
  try {
    const bowserParsed = Bowser.parse(window.navigator.userAgent);

    if (bowserParsed && bowserParsed.browser.name === 'Safari') {
      window.addEventListener('pageshow', event => {
        if (event.persisted) {
          window.location.reload();
        }
      });
    }
  } catch (err) {
    /* Nothing to do */
  }
}

export function iePolyFill() {
  (function() {
    /* Polyfill: Closest */
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

      if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
          var el = this;
          var ancestor = this;
          if (!document.documentElement.contains(el)) {
            return null;
          }
          do {
            if (ancestor.matches(s)) {
              return ancestor;
            }
            ancestor = ancestor.parentElement;
          } while (ancestor !== null);
          return null;
        };
      }
    }

    /* forEach on NodeList */
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = function(callback, thisArg) {
        const newthisArg = thisArg || window;
        let i;
        for (i = 0; i < this.length; i++) {
          callback.call(newthisArg, this[i], i, this);
        }
      };
    }
  })();

  if ('objectFit' in document.documentElement.style === false) {
    const objfit = document.querySelectorAll('[data-js*="use-object-fit"]');
    objfit.forEach(image => {
      image.parentNode.style.cssText = `background-image:url(${image.src}); background-size:cover; background-position:center center;`;
      image.parentNode.removeChild(image);
    });
  }
}
