export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  EXPRESSIVE: 'expressive',
  TRANSACTIONAL: 'transactional',
  TRANSACTIONAL_SECONDARY: 'transactional-secondary',
  DESTRUCTIVE: 'destructive',
  TERTIARY_DESTRUCTIVE: 'tertiary-destructive',
  PRIMARY_ALTERNATIVE: 'primary-alternative',
  SECONDARY_ALTERNATIVE: 'secondary-alternative',
  LINK: 'link',
  DEFAULT: ''
};

export const BUTTON_TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
  CLOSE: 'close'
};

export const BUTTON_STYLES = [
  BUTTON_VARIANTS.DEFAULT,
  BUTTON_VARIANTS.PRIMARY,
  BUTTON_VARIANTS.SECONDARY,
  BUTTON_VARIANTS.EXPRESSIVE,
  BUTTON_VARIANTS.TERTIARY,
  BUTTON_VARIANTS.PRIMARY_ALTERNATIVE,
  BUTTON_VARIANTS.SECONDARY_ALTERNATIVE,
  BUTTON_VARIANTS.TRANSACTIONAL
];
