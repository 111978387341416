<template>
  <div
    :class="[isInline ? 'imagegallery__hidden' : 'imagegallery__overlay']"
    @keydown.esc="closeGallery"
    ref="overlay"
  >
    <nav class="imagegallery__wrapper">
      <button
        v-if="!isInline"
        type="button"
        class="imagegallery__close"
        @click.prevent.stop="closeGallery"
        ref="close"
      >
        <slot name="nav-close">&#10005;</slot>
      </button>

      <div
        itemscope
        itemtype="http://schema.org/ImageGallery"
        class="imagegallery__inner"
        ref="inner"
      >
        <figure
          v-for="(image, imageindex) in images"
          :key="imageindex"
          itemprop="associatedMedia"
          itemscope
          :itemtype="[
            image.type === 'video'
              ? 'http://schema.org/VideoObject'
              : 'http://schema.org/ImageObject'
          ]"
          class="imagegallery__image__wrapper"
        >
          <slot v-if="image.type === 'video'">
            <meta itemprop="description" :content="image.description" />
            <meta itemprop="name" :content="image.caption" />
            <meta itemprop="thumbnailUrl" :content="domain + image.thumbnail" />
            <meta itemprop="uploadDate" :content="image.uploadDate" />
            <video
              v-if="videoLocal(image.src)"
              class="imagegallery__video"
              controls
            >
              <source
                :src="image.src"
                :type="videoType(image.src)"
                tabindex="-1"
              />
            </video>
            <iframe
              v-else
              :src="image.src.replace('watch?v=', 'embed/')"
              class="imagegallery__iframe"
              frameborder="0"
              tabindex="-1"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </slot>

          <img
            v-else
            :src="image.srcCurrent + '?w=768'"
            :srcset="getSrcSet(image.srcCurrent)"
            itemprop="image"
            :alt="image.caption"
            class="imagegallery__image"
            ondragstart="return false"
          />

          <figcaption
            v-if="!isInline"
            itemprop="caption description"
            class="imagegallery__caption"
          >
            <!-- Removed by request: {{ image.caption }} -->
          </figcaption>
          <meta
            v-if="image.copyright"
            itemprop="copyrightHolder"
            :content="image.copyright"
          />
        </figure>
      </div>

      <button
        type="button"
        class="imagegallery__nav--left"
        @click="navGallery(false)"
        rel="prev"
        :aria-label="labelPrev"
        v-if="
          imageList.length && imageList.length > 1 && selectedImageIndex != 0
        "
      >
        <slot name="nav-left">
          <svg class="imagegallery__svg" viewBox="0 0 50 100">
            <path d="M50 0 L0 50 L50 100"></path>
          </svg>
        </slot>
      </button>

      <button
        type="button"
        class="imagegallery__nav--right"
        @click="navGallery"
        rel="next"
        :aria-label="labelNext"
        v-if="
          imageList.length &&
            imageList.length > 1 &&
            selectedImageIndex != imageList.length - 1
        "
      >
        <slot name="nav-right">
          <svg class="imagegallery__svg" viewBox="0 0 50 100">
            <path d="M0 0 L50 50 L0 100"></path>
          </svg>
        </slot>
      </button>

      <button
        type="button"
        class="imagegallery__nav--zoom"
        @click="openGallery"
        :aria-label="labelZoom"
        v-show="isInline && zoomInline"
      >
        <slot name="nav-zoom">
          <svg class="imagegallery__svg" viewBox="0 0 100 100">
            <circle r="25" cx="40" cy="40" />
            <line x1="60" y1="60" x2="80" y2="80" />
          </svg>
        </slot>
      </button>

      <span class="imagegallery__hidden" aria-live="polite" aria-atomic="true">
        {{ selectedImageIndex + 1 }} / {{ imageLen }}
      </span>
    </nav>

    <nav
      itemscope
      itemtype="http://www.schema.org/SiteNavigationElement"
      class="imagegallery__dots"
      v-if="isInline"
    >
      <span
        v-for="(dot, dotindex) in images"
        :key="dotindex"
        class="imagegallery__dot"
        :class="{ '-selected': dotindex === selectedImageIndex }"
        @click="goToSlide(dotindex)"
      ></span>
    </nav>

    <nav
      itemscope
      itemtype="http://www.schema.org/SiteNavigationElement"
      class="imagegallery__thumbbar"
      v-if="!isInline && !thumbnailsInline"
    >
      <figure
        v-for="(thumb, thumbindex) in images"
        :key="thumbindex"
        class="imagegallery__thumb"
        :class="{
          '-selected': thumbindex === selectedImageIndex,
          '-videoicon': thumb.type === 'video' && thumbVideoIcon
        }"
        @click="goToSlide(thumbindex)"
      >
        <img
          :src="thumb.thumbnailCurrent + '?w=75'"
          itemprop="name"
          :alt="thumb.caption"
        />
      </figure>
    </nav>
  </div>
</template>

<script>
import Overlay from '../../js/overlay';
/**
 * ImageGallery component
 * @author Mads Stoumann
 * @version 1.1.0
 */
export default {
  name: 'ImageGallery',
  props: {
    domain: {
      type: String,
      default: 'https://example.com'
    },
    duration: {
      type: Number,
      default: 500
    },
    easing: {
      type: Function,
      default: function(t) {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      }
    },
    imageList: {
      type: Array,
      default: () => []
    },
    labelNext: {
      type: String,
      default: 'Next Image'
    },
    labelPrev: {
      type: String,
      default: 'Previous Image'
    },
    labelZoom: {
      type: String,
      default: 'Zoom In'
    },
    openLinkElm: String,
    thumbDistance: {
      type: Number,
      default: 100
    },
    thumbnailsInline: {
      type: Boolean,
      default: false
    },
    thumbVideoIcon: {
      type: Boolean,
      default: true
    },
    zoomInline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      images: [],
      isInline: true,
      loadImg: false,
      linkedImage: '',
      posX: 0,
      selectedImageIndex: 0
    };
  },
  computed: {
    imageLen() {
      return this.images.length;
    }
  },
  mounted() {
    setTimeout(() => {
      const link = document.querySelector(this.openLinkElm);
      const inner = this.$refs.inner;

      if (link) {
        this.isInline = true;
        this.linkedImage = link;
        link.setAttribute('tabindex', 0);
        link.addEventListener('click', this.openGallery);
        link.addEventListener('keydown', event => {
          if (event.keyCode === 13) {
            this.openGallery();
          }
        });
      }
      window.addEventListener('resize', () => this.goToSlide(0));
      document.body.addEventListener('keydown', event => {
        if (!this.isInline) {
          switch (event.keyCode) {
            case 27:
              this.closeGallery();
              break;
            case 37:
              this.navGallery(false);
              break;
            case 39:
              this.navGallery(true);
              break;
          }
        }
      });
      inner.addEventListener('mouseup', event => this.touchMove(event), {
        passive: true
      });
      inner.addEventListener('mousedown', event => this.touchInit(event), {
        passive: true
      });
      inner.addEventListener('touchmove', event => this.touchMove(event), {
        passive: true
      });
      inner.addEventListener('touchstart', event => this.touchInit(event), {
        passive: true
      });

      //set up images
      this.updateImages(this.imageList);
    });
  },
  methods: {
    closeGallery() {
      this.isInline = true;
      this.goToSlide(0);
      if (this.linkedImage) {
        this.linkedImage.focus();
      }
      Overlay(this.$refs.overlay, false);
    },
    getSrcSet(imageUrl) {
      return `
        ${imageUrl}?w=320 320w,
        ${imageUrl}?w=480 480w,
        ${imageUrl}?w=768 768w,
        ${imageUrl}?w=1024 1024w,
        ${imageUrl}?w=1600 1600w`;
    },
    goToSlide(index) {
      this.selectedImageIndex = index;
      const inner = this.$refs.inner;
      const width = Math.ceil(inner.scrollWidth / this.imageLen + 1);
      this.scrollTo(inner, index * width, this.duration, this.easing);
    },
    navGallery(dirRight = true) {
      this.loadImg = true;
      let index = dirRight
        ? this.selectedImageIndex + 1
        : this.selectedImageIndex - 1;
      if (index < 0 || index >= this.imageLen) index = 0;
      this.goToSlide(index);
    },
    openGallery() {
      this.loadImg = true;
      this.isInline = false;
      this.goToSlide(0);
      setTimeout(() => this.$refs.close.focus());
      Overlay(this.$refs.overlay, true);
    },
    scrollTo(image, dest, duration, easing) {
      const from = image.scrollLeft;
      const min = (a, b) => {
        return a < b ? a : b;
      };
      const start = Date.now();

      function scroll() {
        const curTime = Date.now();
        const time = min(1, (curTime - start) / duration);
        const easedTime = easing(time);
        const scrollDist = easedTime * (dest - from) + from;
        image.scrollLeft = scrollDist;
        if (time < 1) requestAnimationFrame(scroll);
      }
      requestAnimationFrame(scroll);
    },
    touchInit(event) {
      const curPos = event.clientX || event.changedTouches[0].pageX;
      this.posX = curPos;
    },
    touchMove(event) {
      const curPos = event.clientX || event.changedTouches[0].pageX;
      if (curPos < this.posX - this.thumbDistance) {
        this.posX = curPos;
        this.navGallery(true);
      } else if (curPos > this.posX + this.thumbDistance) {
        this.posX = curPos;
        this.navGallery(false);
      }
    },
    videoLocal(src) {
      return /\.(mp4|ogg|webm)$/i.test(src);
    },
    videoType(src) {
      if (src.includes('.mp4')) return 'video/mp4';
      if (src.includes('.ogg')) return 'video/ogg';
      if (src.includes('.webm')) return 'video/webm';
    },
    updateImages(arr) {
      const blankImage =
        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';
      let images = arr;
      images.forEach((item, index) => {
        item.thumbnailCurrent = blankImage;
        item.srcCurrent = index === 0 ? item.src : blankImage;
      });
      this.images = images;
    },
    getVideoThumbnail(src) {
      // Only for youtube
      const match = src.match(
        /(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
      );

      if (match && match.length > 1) {
        return `//img.youtube.com/vi/${match[2]}/0.jpg`;
      }

      return src;
    }
  },
  watch: {
    imageList(arr) {
      this.updateImages(arr);
    },
    loadImg(bool) {
      if (bool) {
        this.images.forEach(item => {
          item.srcCurrent = item.src;
          item.type = item.type ? item.type.toLowerCase() : item.type;
          item.thumbnailCurrent =
            item.type && item.type === 'video' && item.thumbnail == item.src
              ? this.getVideoThumbnail(item.thumbnail)
              : item.thumbnail;
        });

        this.loadImg = false;
      }
    }
  }
};
</script>
