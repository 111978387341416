<template>
  <div class="c-map" :style="calcMapHeight">
    <div ref="mapContainer" class="c-map__map c-map__map--large"></div>

    <article ref="storeInfo" class="c-map__store-info">
      <h5 class="c-typography-h5 c-map__title">
        {{ title }}
      </h5>

      <section>
        <section>
          <h4 class="fs__list-item-headline--md pb-1 text-bold">
            {{ openingHoursHeadline }}
          </h4>
          <dl
            v-for="(entry, i) in openingHours"
            :key="i"
            class="
              fs__list-item-hours
              fs__list-item-hours--line-below
              fs__list-item-hours--spacing
            "
          >
            <dt>{{ entry.key }}</dt>
            <dd>{{ entry.value }}</dd>
          </dl>
        </section>

        <section v-if="Array.isArray(specialHours) && specialHours.length > 0">
          <h6 class="fs__list-item-headline--sm pb-1 text-bold">
            {{ specialHoursHeadline }}
          </h6>
          <dl
            v-for="(entry, i) in specialHours"
            :key="i"
            class="
              fs__list-item-hours
              fs__list-item-hours--line-below
              fs__list-item-hours--spacing
            "
          >
            <dt>{{ entry.key }}</dt>
            <dd>{{ entry.value }}</dd>
          </dl>
        </section>
      </section>

      <section
        ref="mapContainerSmall"
        class="c-map__map c-map__map--small"
      ></section>

      <section>
        <h4 class="fs__list-item-headline--md pb-1 text-bold">
          {{ contactInfoHeadline }}
        </h4>

        <address class="c-map__address mb-3">
          <span>{{ streetAndNumber }}</span>
          <br />
          <span>{{ zipCode }}</span>
          <span>{{ city }}</span>
        </address>

        <address class="c-map__contact-info">
          <p v-if="phoneNumber" class="c-map__contact-info-item">
            <svg xlink="http://www.w3.org/2000/svg" class="c-map__contact-icon">
              <use :xlink:href="phoneIconPath"></use>
            </svg>
            <a
              class="fs__list-item-option fs__list-item-option--gap-left"
              :href="`tel:${phoneNumber}`"
              target="_blank"
            >
              {{ phoneNumber }}
            </a>
          </p>
          <p v-if="email" class="fs--padding-top-sm c-map__contact-info-item">
            <svg xlink="http://www.w3.org/2000/svg" class="c-map__contact-icon">
              <use :xlink:href="emailIconPath"></use>
            </svg>
            <a
              class="fs__list-item-option fs__list-item-option--gap-left"
              :href="`mailto:${email}`"
              target="_blank"
            >
              {{ email }}
            </a>
          </p>
          <p class="fs--padding-top-sm c-map__contact-info-item">
            <svg xlink="http://www.w3.org/2000/svg" class="c-map__contact-icon">
              <use :xlink:href="directionsIconPath"></use>
            </svg>
            <a
              class="fs__list-item-option fs__list-item-option--gap-left"
              @click.prevent="directionsLinkClickHandler"
            >
              {{ directionsLinkLabel }}
            </a>
          </p>
        </address>
      </section>
    </article>
  </div>
</template>

<script>
import geoLocationMixin from '../../mixins/geo-location-mixin.js';
import { mapMutations } from 'vuex';

export default {
  name: 'CMap',
  mixins: [geoLocationMixin],
  props: {
    title: {
      type: String,
      default: ''
    },
    storeName: {
      type: String,
      default: ''
    },
    streetAndNumber: {
      type: String,
      default: ''
    },
    zipCode: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    },
    phoneNumber: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
    openingHoursHeadline: {
      type: String,
      default: ''
    },
    openingHours: {
      type: [String, Array],
      default: () => []
    },
    specialHoursHeadline: {
      type: String,
      default: ''
    },
    specialHours: {
      type: Array,
      default: () => []
    },
    directionsLinkLabel: {
      type: String,
      default: ''
    },
    contactInfoHeadline: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    pageUrl: {
      type: String,
      default: ''
    },
    teliaShopPriceRange: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      userLocation: undefined,
      mapHeight: '100px',
      mapOptions: {
        zoom: 17,
        clickable: false,
        clickableIcons: false,
        disableDefaultUI: true,
        center: null,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            stylers: [{ visibility: 'on' }]
          }
        ]
      },
      markerOptions: {
        position: null,
        icon: {
          url: require('../../../norlys/images/find-store/maptsicon.png')
            .default,
          scaledSize: new window.google.maps.Size(50, 50)
        }
      },
      phoneIconPath: `${window.__APP__.iconsPath}#service-phone`,
      directionsIconPath: `${window.__APP__.iconsPath}#arrow-directions`,
      emailIconPath: `${window.__APP__.iconsPath}#contact-email`,
      structuredData: {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        image: this.imageUrl,
        '@id': this.pageUrl,
        name: this.storeName,
        address: {
          '@type': 'PostalAddress',
          streetAddress: this.streetAndNumber,
          addressLocality: this.city,
          postalCode: this.zipCode,
          addressCountry: 'DK'
        },
        geo: {
          '@type': 'GeoCoordinates',
          latitude: this.lat,
          longitude: this.lng
        },
        url: this.pageUrl,
        telephone: this.phoneNumber,
        priceRange: this.teliaShopPriceRange
      }
    };
  },

  computed: {
    calcMapHeight() {
      return {
        '--map-height': `${this.mapHeight}px`
      };
    }
  },

  mounted() {
    this.injectStructuredDataInHead();
    this.mapHeight = this.$refs.storeInfo.offsetHeight;
    this.initMap();
  },

  methods: {
    ...mapMutations(['HIDE_SPINNER', 'SHOW_SPINNER']),

    injectStructuredDataInHead() {
      let script = document.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.innerHTML = JSON.stringify(this.structuredData);
      document.head.appendChild(script);
    },

    initSmallMap(mapOption, markerOption) {
      const marker = new window.google.maps.Marker(markerOption);
      const map = new window.google.maps.Map(
        this.$refs.mapContainerSmall,
        mapOption
      );

      marker.setMap(map);
    },

    initLargeMap(mapOption, markerOption) {
      const marker = new window.google.maps.Marker(markerOption);
      const map = new window.google.maps.Map(
        this.$refs.mapContainer,
        mapOption
      );

      marker.setMap(map);
      map.panBy(-200, 0);
    },

    initMap() {
      const shopLatlng = new window.google.maps.LatLng(this.lat, this.lng);
      const mapOption = { ...this.mapOptions, center: shopLatlng };
      const markerOption = {
        ...this.markerOptions,
        position: shopLatlng
      };

      this.initSmallMap(mapOption, markerOption);

      this.initLargeMap(mapOption, markerOption);
    },

    createAndOpenUrl() {
      const streetName = this.streetAndNumber.replace(/\s/g, '+');
      const city = this.city.replace(/\s/g, '+');
      const path = 'https://www.google.com/maps/dir/?api=1';

      if (this.userLocation) {
        const lat = this.userLocation.coords.latitude;
        const lng = this.userLocation.coords.longitude;
        this.openBlank(
          `${path}&origin=${lat}%2C${lng}&destination=${streetName}+${city}`
        );
        return;
      }

      this.openBlank(`${path}&destination=${streetName}+${city}`);
    },

    async directionsLinkClickHandler() {
      if (this.userLocation) {
        this.createAndOpenUrl();
      } else {
        this.SHOW_SPINNER();
        this.getUserLocation()
          .then(value => {
            this.userLocation = value;
          })
          .then(() => {
            this.createAndOpenUrl();
            this.HIDE_SPINNER();
          })
          .catch(() => {
            this.createAndOpenUrl();
            this.HIDE_SPINNER();
          });
      }
    },

    openBlank(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss">
@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

$infoWidthMax: 396px;
$mapHeightSmall: 230px;
$iconSize: $u-400;

.c-map {
  position: relative;
  align-items: center;

  &__map {
    width: 100%;

    &--small {
      height: $mapHeightSmall;
    }

    &--large {
      display: none;
      height: 100%;
    }
  }

  @include from-tablet {
    display: flex;
    height: calc(var(--map-height) + #{$u-600 * 2});

    &__map--small {
      display: none;
    }

    &__map--large {
      display: block;
    }
  }

  &__title {
    margin-bottom: $u-500;

    @include from-tablet {
      margin-bottom: $u-600;
    }
  }

  &__store-info {
    position: relative;
    padding: $u-400 $u-300;
    width: 100%;
    background-color: $color-white;
    text-align: left;
    box-shadow: $box-shadow-default;
    border-radius: 4px;

    @include from-tablet {
      position: absolute;
      padding: $u-600;
      left: $u-700;
      max-width: $infoWidthMax;
    }

    > section:not(:last-child) {
      margin-bottom: $u-600;

      > section:not(:last-child) {
        margin-bottom: $u-400;
      }
    }
  }

  .c-typography-h4 {
    font-size: $font-size-h4;
  }

  .c-typography-h6 {
    font-size: $font-size-h5;
  }

  &__address {
    font-style: normal;
    font-size: $global-font-size-md;
    line-height: $u-350;
  }

  &__contact-info {
    font-style: normal;
    font-size: $global-font-size-md;
    line-height: $u-350;
  }

  &__contact-info-item {
    display: flex;
  }

  &__contact-icon {
    width: $iconSize;
    height: $iconSize;
  }

  .gm-style-cc {
    display: none;
  }
}
</style>
