/* eslint-disable no-magic-numbers */
function counter(element) {
  /* endTime must be a valid dateString/Iso8601String concatenated from:
  Date: YYYY-MM-DD
  Time: T00:00 // 24 hour-format
  GTM: +01:00 // Positive or negative offset-time
  Example: 2020-10-10T09:30-01:00
  */
  const endTime = new Date(element.dataset.endTime).getTime();
  const arrDays = element.dataset.lblDays && element.dataset.lblDays.split('|');
  const arrHours =
    element.dataset.lblHours && element.dataset.lblHours.split('|');
  const arrMins = element.dataset.lblMins && element.dataset.lblMins.split('|');
  const arrSecs = element.dataset.lblSecs && element.dataset.lblSecs.split('|');
  const options = JSON.parse(element.dataset.options);
  const hideDaysWhenZero = options.hideDaysWhenZero;
  const optionalTimesUpTitle = element.dataset.timesUpTitle;
  const wrapper = document.createElement('span');
  wrapper.className = 'c-cnt';
  if (element.querySelector('.c-cnt')) {
    element.replaceChild(wrapper, element.querySelector('.c-cnt'));
  } else {
    element.appendChild(wrapper);
  }

  function createLabel(array, value) {
    if (!array) {
      return '';
    }
    const [plural, singular] = [...array];
    const label = value === '01' ? singular : plural;
    return `<span class="c-cnt__item">${value}<span class="c-cnt__label">${label}</span></span>`;
  }

  function getRemainingTime(deadline) {
    const currentTime = new Date().getTime();
    const millisecondsPerSecond = 1000;
    const secondsPerMinute = 60;
    const minutesPerHour = 60;
    const hoursPerDay = 24;
    const millisecondsPerMinute = millisecondsPerSecond * secondsPerMinute;
    const millisecondsPerHour = millisecondsPerMinute * minutesPerHour;
    const millisecondsPerDay = millisecondsPerHour * hoursPerDay;

    const remainingTime = deadline - currentTime;
    const days = Math.floor(remainingTime / millisecondsPerDay);
    const hours = Math.floor(
      (remainingTime / millisecondsPerHour) % hoursPerDay
    );
    const minutes = Math.floor(
      (remainingTime / millisecondsPerMinute) % minutesPerHour
    );
    const seconds = Math.floor(
      (remainingTime / millisecondsPerSecond) % secondsPerMinute
    );

    return {
      days: days > 0 ? days : 0,
      hours: hours > 0 ? hours : 0,
      minutes: minutes > 0 ? minutes : 0,
      seconds: seconds > 0 ? seconds : 0,
      milliseconds: remainingTime
    };
  }

  function pad(value, cut) {
    return ('0' + value).slice(cut);
  }

  function formatDays(days) {
    if (days.toString().length < 3) {
      return days.toString().padStart(2, '0');
    }
    return days.toString();
  }

  function showTime() {
    const remainingTime = getRemainingTime(endTime);
    const seconds = pad(remainingTime.seconds, -2);
    const minutes = pad(remainingTime.minutes, -2);
    const hours = pad(remainingTime.hours, -2);
    const days = formatDays(remainingTime.days);
    const hideDays = hideDaysWhenZero && days === '00';

    wrapper.innerHTML = !hideDays
      ? `${createLabel(arrDays, days)}<span class="c-cnt__divider">:</span>`
      : '';
    wrapper.innerHTML += `${createLabel(
      arrHours,
      hours
    )}<span class="c-cnt__divider">:</span>`;
    wrapper.innerHTML += `${createLabel(
      arrMins,
      minutes
    )}<span class="c-cnt__divider">:</span>`;
    wrapper.innerHTML += `${createLabel(arrSecs, seconds)}`;

    if (remainingTime.milliseconds >= 1000) {
      requestAnimationFrame(showTime);
    } else if (optionalTimesUpTitle) {
      const title = element.querySelector('.c-cnt__headline');
      title.innerHTML = optionalTimesUpTitle;
    }
  }

  requestAnimationFrame(showTime);
}

/* Iterate Count-Downs */
export function countDown() {
  const countdowns = document.querySelectorAll('[data-js="countdown"]');
  countdowns.forEach(function (countdown) {
    return counter(countdown);
  });
}
