var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        { "c-theme-block__item--image": _vm.image },
        "c-theme-block__item"
      ]
    },
    [
      _vm.image
        ? _c("img", {
            staticClass: "c-theme-block__image",
            attrs: { src: _vm.image, alt: _vm.heading }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "c-theme-block__content" }, [
        _vm.subtitle
          ? _c("p", { staticClass: "c-theme-block__subtitle" }, [
              _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.heading
          ? _c("h3", { staticClass: "c-theme-block__heading" }, [
              _vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c("p", { staticClass: "c-theme-block__description" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.description.substring(0, _vm.ellipsisLimit) + "…"
                  ) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "c-theme-block__link", attrs: { href: _vm.link } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }