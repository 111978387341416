<script>
import { mapMutations, mapActions } from 'vuex';

import {
  CFormRadioGroup,
  CFormRadio,
  CText,
  CRow,
  CCol,
  CCollapse,
  CCollapseTrigger
} from 'olympus/components';

import CheckoutConfirmDetails from 'webshop/components/checkout/checkout-confirm-details.vue';
import CheckoutSummaryConfiguration from './checkout-summary-configuration/checkout-summary-configuration.vue';
import CheckoutConfirmationBilling from './checkout-confirmation/checkout-confirmation-billing.vue';
import CheckoutConfirmationBillingAddress from './checkout-confirmation/checkout-confirmation-billing-address.vue';

import { providerKey as ThemeProviderKey } from 'olympus/mixins/variant-mixin.js';
import numberMixin from '../../mixins/number-mixin.js';

import ProductMinimumPriceTooltip from '../product/minimum-price-tooltip.vue';

export default {
  name: 'CheckoutOrderSummary',

  components: {
    ProductMinimumPriceTooltip,

    CFormRadioGroup,
    CFormRadio,
    CText,
    CRow,
    CCol,
    CCollapse,
    CCollapseTrigger,
    CheckoutConfirmDetails,
    CheckoutSummaryConfiguration,
    CheckoutConfirmationBilling,
    CheckoutConfirmationBillingAddress
  },

  mixins: [numberMixin],

  inject: [ThemeProviderKey],

  props: {
    vm: {
      type: Object,
      required: true
    }
  },

  data() {
    const selected = this.vm.shippingMethods.find(x => x.isSelected);

    return {
      selectedShippingMethodId: (!!selected && selected.id) || '',
      pickupPoint: {
        locations: [],
        selected: this.vm.pickupPoint || null,
        networking: false,
        showList: false
      },
      showConfirm: false
    };
  },

  watch: {
    selectedShippingMethodId(value) {
      const pickupPointMethod = this.vm.shippingMethods.find(
        x => x.name === 'PickupPointFree' || x.name === 'CallmePickupPoint'
      );
      if (pickupPointMethod && !this.pickupPoint.selected) {
        return;
      }

      this.updateShippingMethod({ shippingId: value });
    }
  },

  methods: {
    ...mapMutations(['SHOW_DIALOG']),

    ...mapActions({
      updateShippingMethod: 'updateShippingMethod',
      fetchPickupPoints: 'FETCH_PICKUP_POINTS'
    }),

    fetchPickupPointsData() {
      this.pickupPoint.networking = true;

      this.fetchPickupPoints().then(data => {
        this.pickupPoint.locations = data;
        this.pickupPoint.networking = false;
      });
    },

    selectPickupPoint(locationCode) {
      this.updateShippingMethod({
        shippingId: this.selectedShippingMethodId,
        deliveryLocationCode: locationCode
      });
    },

    togglePickupPointsList() {
      this.pickupPoint.showList = !this.pickupPoint.showList;
    },

    formatNumber(number) {
      return this.formatNumberByDigits(number);
    },
    showConfirmDialog() {
      this.showConfirm = true;
    },
    onCloseConfirmDialog() {
      this.showConfirm = false;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';

$pickup-point-selector-height: 300px;
$pickup-point-selector-side-margin: 60px;
$pickup-point-selector-side-padding: 20px;

.cm-c-total {
  &--heading {
    font-weight: bold;
    position: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;

    & > span {
      align-items: center;
      width: 100%;
    }

    & > img {
      width: 50px;
      padding-right: 10px;
    }
  }
}

.order-summary {
  // .order-summary__shipping
  &__shipping {
    // .order-summary__shipping--methods
    &--methods {
      position: relative;

      // .order-summary__shipping--methods label
      & .c-form-radio--box > label {
        display: block;
        padding: 0;
      }
    }

    &--price {
      font-weight: 700;
    }

    // .order-summary__shipping--description
    &--description {
      display: block;
      width: 100%;
      font-size: 12px;
    }

    &-att {
      $radioBtn: $u-350;
      /* Taken from c-form-radio.scss */
      padding-left: calc(#{$u-250} + #{$radioBtn});
    }
  }

  // .order-summary__prices
  &__prices {
    &__section {
      position: relative;
      margin-bottom: $u-300;

      // .order-summary__prices:last-of-type
      &:last-of-type {
        border-bottom: 1px solid $c-nt-200;
        padding-bottom: $u-400;
        margin-bottom: $u-300;
      }
    }

    &__heading {
      font-weight: bold;
      position: inherit;
      display: flex;
      font-size: $font-size-h4;

      & > span {
        align-items: center;
        width: 100%;
      }

      & > img {
        width: 50px;
        padding-right: 10px;
      }

      &--total {
        font-size: $font-size-h3;
        border-bottom: none;

        &:last-of-type {
          border-bottom: 1px solid $c-nt-200;
          padding-bottom: $u-400;
          margin-bottom: $u-300;
        }
      }
    }

    // .order-summary__prices__subheader
    &__subheader {
      font-weight: 400;
      margin: 0 0 $u-400 0;
      border-bottom: $u-100 solid $c-nt-200;
      padding-bottom: $u-400;
      margin-bottom: $u-300;
    }

    &__data {
      margin-top: 10px;
    }

    // .order-summary__prices dl
    & dl {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;

      & > dt,
      dd {
        flex-grow: 1;
        margin-right: 4px;
      }

      & > dd {
        text-align: right;
        white-space: nowrap;
        justify-self: end;
      }
    }
  }

  // .order-summary__pickup
  &__pickup {
    &-trigger {
      padding: 10px $pickup-point-selector-side-padding;
      background-color: $c-nt-200;

      & svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: transform 0.3s ease-in-out;
      }

      & .expanded svg {
        transform: rotateZ(-180deg);
      }
    }

    // .order-summary__pickup-selected
    &-selected {
      background-color: $c-nt-200;
      padding: 10px $pickup-point-selector-side-padding;
    }
  }

  // .order-summary__locations
  &__locations {
    cursor: default;

    // .order-summary__locations-list
    &-list {
      margin: 0;
      max-height: $pickup-point-selector-height;
      overflow-y: scroll;
      padding: 10px $pickup-point-selector-side-padding;
      position: relative;

      & > li {
        display: flex;
        margin: 0px 0px 10px $pickup-point-selector-side-margin;
        padding-bottom: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid $c-nt-200;
        }
      }
    }

    // .order-summary__locations-collapse
    &-collapse {
      width: 100%;
      text-align: center;
      padding: 10px 0px;
      background-color: white;
    }

    // .order-summary__locations-distance
    &-distance {
      width: $pickup-point-selector-side-margin;
      left: $pickup-point-selector-side-padding;
      position: absolute;
      font-size: 12px;
    }

    // .order-summary__locations-address
    &-address {
      flex: 1;
    }

    // .order-summary__locations-button
    &-button {
      /**
        HACK: reused some classes from checkout on this button, but they also contained
        some pretty bad code. So overwritting those for the time being.
      */
      min-width: $pickup-point-selector-side-margin !important;
      height: 2rem;
      margin: 0 !important;
      align-self: center;
    }
  }
}
</style>
