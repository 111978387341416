var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "c-row",
        { staticClass: "c-order-list__filter" },
        [
          _c("c-col", { attrs: { md: "3" } }, [
            _c(
              "fieldset",
              {
                staticClass:
                  "c-order-list__filter__group c-order-list__filter__group--date"
              },
              [
                _c(
                  "date-picker",
                  {
                    attrs: {
                      "date-locale": "da-DK",
                      "input-classes":
                        "cm-c-form-control cm-c-form-control--filled cm-js-material-input",
                      "input-value": _vm.filter.fromDate
                    },
                    on: { setDate: _vm.setFromDate }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "cm-c-form-control__label datepicker__label--ie",
                        attrs: { slot: "label" },
                        slot: "label"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.content.listView.fromDateFilterPlaceholderText
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("c-col", { attrs: { md: "3" } }, [
            _c(
              "fieldset",
              {
                staticClass:
                  "c-order-list__filter__group c-order-list__filter__group--date"
              },
              [
                _c(
                  "date-picker",
                  {
                    attrs: {
                      "date-locale": "da-DK",
                      "input-classes":
                        "cm-c-form-control cm-c-form-control--filled cm-js-material-input",
                      "input-value": _vm.filter.toDate
                    },
                    on: { setDate: _vm.setToDate }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "cm-c-form-control__label datepicker__label--ie",
                        attrs: { slot: "label" },
                        slot: "label"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.content.listView.toDateFilterPlaceholderText
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("c-col", { attrs: { md: "3" } }, [
            _c(
              "fieldset",
              {
                staticClass:
                  "\n          c-order-list__filter__group c-order-list__filter__group--status\n          cm-c-form-control-group\n        "
              },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.status,
                        expression: "filter.status"
                      }
                    ],
                    staticClass:
                      "cm-c-form-control cm-c-form-select cm-js-material-input",
                    attrs: { id: "processingStatus", name: "processingStatus" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filter,
                          "status",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.content.listView.statusOptions, function(option) {
                    return _c(
                      "option",
                      { key: option.value, domProps: { value: option.value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(option.key) + "\n          "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "cm-c-form-control__label",
                    attrs: { for: "processingStatus" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.listView.orderStatusFilterPlaceholderText
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "cm-c-form-select__arrow" })
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-order-list__table-container" },
        [
          _c(
            "c-table",
            [
              _c(
                "c-table-head",
                [
                  _c(
                    "c-tr",
                    [
                      _vm._l(_vm.columns, function(column) {
                        return _c("c-th", { key: column.key }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(column.label) +
                              "\n          "
                          )
                        ])
                      }),
                      _vm._v(" "),
                      _c("c-th")
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-table-body",
                [
                  _vm.isLoading
                    ? _c(
                        "c-tr",
                        [
                          _c(
                            "c-td",
                            { attrs: { "col-span": _vm.columns.length } },
                            [
                              _c("div", {
                                staticClass: "cm-c-spinner cm-c-spinner--local"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : [
                        _vm.filteredOrders.length > 0
                          ? _vm._l(_vm.filteredOrders, function(row) {
                              return _c(
                                "c-tr",
                                { key: row.id },
                                [
                                  _vm._l(_vm.columns, function(column) {
                                    return _c(
                                      "c-td",
                                      { key: column.key },
                                      [
                                        column.render
                                          ? [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    column.render(
                                                      row[column.key]
                                                    )
                                                  )
                                                }
                                              })
                                            ]
                                          : [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(row[column.key]) +
                                                  "\n                "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "c-td",
                                    {
                                      staticClass:
                                        "c-order-list__table-container__action-cell"
                                    },
                                    [
                                      _vm.content.listView.showDetailsButton
                                        ? [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "c-btn c-btn--empty-secondary c-btn--xs",
                                                on: {
                                                  click: function() {
                                                    return _vm.handleViewClick(
                                                      row.orderId
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.content.listView
                                                        .orderDetailsPopupButtonText
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                2
                              )
                            })
                          : [
                              _c(
                                "c-tr",
                                [
                                  _c(
                                    "c-td",
                                    {
                                      attrs: { "col-span": _vm.columns.length }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.content.listView.emptyListText
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: {
            visible: _vm.showModal,
            title: _vm.content.listView.errorMessageHeader,
            centered: ""
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.content.listView.errorMessageDescription) +
              "\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }