<script>
import { CContainer, CRow, CCol } from 'olympus/components';
import { CCard } from 'webshop/components';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
import CheckoutConfirmDetails from 'webshop/components/checkout/checkout-confirm-details.vue';
import CheckoutSummaryConfiguration from 'webshop/components/checkout/checkout-summary-configuration/checkout-summary-configuration.vue';
import CheckoutDeliveryAddress from 'webshop/components/checkout/checkout-delivery-address.vue';
import PriceSummary from 'webshop/components/shopping-cart/price-summary.vue';

import basketStepProvider from 'webshop/components/shopping-cart/basket-step-provider.js';

import { mapMutations } from 'vuex';

export default {
  name: 'CheckoutReceipt',

  components: {
    CContainer,
    CRow,
    CCol,
    CCard,
    CheckoutConfirmDetails,
    CheckoutSummaryConfiguration,
    CheckoutDeliveryAddress,
    PriceSummary
  },

  mixins: [basketStepProvider],

  props: {
    gtmPurchaseObj: {
      type: Object,
      required: true
    },
    isOnlineChannel: {
      type: Boolean,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    contentLocal: {
      type: Object,
      default: () => ({})
    },
    orderNumber: {
      type: Number,
      default: null
    }
  },

  computed: {
    mappedDisclaimers() {
      return this.content?.disclaimers?.map(d => {
        return {
          visible: d.visible,
          title: d.title,
          text: populateContentProperty(
            d.text || '',
            this.content?.broadbandInstallationAddress
          )
        };
      });
    }
  },

  mounted() {
    if (this.orderNumber) {
      this.setOrderNumber(this.orderNumber);
    }

    if (this.isOnlineChannel && this.gtmPurchaseObj?.ecommerce) {
      this.$globals.pushToDataLayer(this.gtmPurchaseObj);
    }
  },

  methods: {
    ...mapMutations({
      setOrderNumber: 'mutations/SET_ORDER_NUMER'
    })
  }
};
</script>

<style lang="scss">
@import 'olympus/styles/tools/_tools.media-query.scss';
@import 'olympus/styles/breakpoints.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.checkout-receipt {
  // .checkout-receipt__shipping
  &__shipping {
    // .checkout-receipt__shipping--methods
    &--methods {
      position: relative;

      // .checkout-receipt__shipping--methods label
      & label {
        display: block;
      }
    }

    // .checkout-receipt__shipping--description
    &--description {
      font-size: $global-font-size-sm;
    }
  }
}
</style>
