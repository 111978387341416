<template>
  <c-modal ref="galleryOverlay" medium gray>
    <c-gallery-content
      v-bind="$parent.$props"
      :alt-props="{
        overlay: false,
        navigation: true,
        minVisible: 10,
        loop: false,
        thumbnailPosition: 'bottom',
        navigationRounded: true,
        smallImageSize: '250',
        largeImageSize: '450',
        smallImageWidth: '250px',
        largeImageWidth: '450px',
        selectedImageBreakpoint: '1100'
      }"
      class="c-gallery--modal"
    ></c-gallery-content>
  </c-modal>
</template>

<script>
import CGalleryContent from './c-gallery-content.vue';

export default {
  name: 'c-gallery-overlay',

  components: {
    CGalleryContent
  },

  methods: {
    openModal() {
      this.$refs.galleryOverlay.show();
    }
  }
};
</script>

<style lang="scss">
.c-gallery-overlay {
  position: relative;
}
</style>
