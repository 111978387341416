<template>
  <c-card
    class="checkout-summary-configuration"
    extra-padding
    no-shadow
    no-rounding
  >
    <h3 class="cm-c-form__header">
      {{ content.productConfigurationHeading }}
    </h3>
    <section
      v-if="subscriptionItems.length"
      :class="{ 'cm-c-checkout__text mb-4': broadbandItems.length }"
    >
      <c-row>
        <c-col>
          <strong>{{ content.mobileSubscriptionName }}</strong>
        </c-col>
      </c-row>
      <div
        v-for="(subscription, index) in subscriptionItems"
        :key="subscription.itemDisplayName + index"
        class="checkout-summary-configuration__subscription"
      >
        <c-row>
          <c-col cols="6" xs="12" class="mb-1">
            {{ subscription.itemDisplayName }}
          </c-col>
          <c-col
            v-if="subscription.phoneNumber"
            cols="6"
            xs="12"
            class="cm-text-right-md"
          >
            {{
              formatNumber(
                subscription.phoneNumber.substr(
                  Math.max(subscription.phoneNumber.length - 8, 0)
                ),
                subscription.subscriptionConfigurationType
              )
            }}
            <div
              v-for="(info, idx) in formatSubscriptionDetails(subscription)"
              :key="idx"
            >
              {{ info }}
            </div>
          </c-col>
          <c-col v-else cols="6" class="cm-text-right-md" xs="12">
            {{ content.numberSelectionFailedText }}
          </c-col>
        </c-row>
      </div>
    </section>
    <section v-if="broadbandItems.length" class="cm-c-checkout__text">
      <c-row v-if="!homeOfficeFlow">
        <c-col>
          <strong>{{ content.broadbandSubscriptionName }}</strong>
        </c-col>
      </c-row>
      <div
        v-for="(broadband, index) in broadbandItems"
        :key="broadband.itemDisplayName + index"
        class="checkout-summary-configuration__subscription"
      >
        <c-row>
          <c-col cols="6" xs="12" class="mb-1">
            {{ broadband.itemDisplayName }}
          </c-col>
        </c-row>
        <template v-if="homeOfficeFlow">
          <c-row class="mb-2">
            <c-col cols="6">
              {{ broadband.itemSpeedInfoText }}, {{ broadband.itemHelpText }}
            </c-col>
            <c-col cols="6" class="text-right">
              {{ content.activationDateText }}
            </c-col>
          </c-row>
          <c-row class="mb-2">
            <c-col cols="6">
              <strong>
                {{ content.installationAddressText }}
              </strong>
            </c-col>
            <c-col cols="6" class="text-right">
              {{ broadband.customerName }}<br />
              {{ formatStreet(broadband.installationAddress) }}<br />
              {{ formatCity(broadband.installationAddress) }}
            </c-col>
          </c-row>
          <c-row>
            <c-col cols="6">
              <strong>
                {{ content.additionalInformationText }}
              </strong>
            </c-col>
            <c-col cols="6" class="text-right">
              {{ showTechnicianNeededText(content) }}<br />
              {{ content.powerOfAttorneyMailText }}
            </c-col>
          </c-row>
        </template>
        <template v-else>
          <c-row>
            <c-col cols="6" xs="12">
              {{ broadband.itemDownSpeed }}/{{ broadband.itemUpSpeed }}
              {{ broadband.itemMonetaryUnit }}
            </c-col>
            <template>
              <c-col cols="6" xs="12">
                <ul class="cm-text-right-md">
                  <li v-if="broadband.installationAddress" class="mb-1">
                    {{ content.installationAddressText }}<br />
                    {{ formatStreet(broadband.installationAddress) }}<br />
                    {{ formatCity(broadband.installationAddress) }}
                  </li>
                  <li v-if="broadband.isDeliveryAsapRequired">
                    {{ content.itemDeliveryAsapMessage }}
                  </li>
                  <li v-else>
                    {{ content.itemDeliveryMessage }}
                    {{ broadband.deliveryDate }}
                  </li>
                  <li v-if="broadband.isTerminationRequired">
                    {{ content.itemTerminatedByUserMessage }}
                  </li>
                  <li v-else>{{ content.itemTerminatedByCompanyMessage }}</li>
                  <li v-if="broadband.isTechnicianRequired">
                    <popover-panel :description="content.technicianTooltipText">
                      {{ content.technicianLabel }}
                      <c-icon
                        class="
                          checkout-summary-configuration__technician-information-icon
                        "
                        symbol-id="service-information"
                      ></c-icon>
                    </popover-panel>
                  </li>
                </ul>
              </c-col>
            </template>
          </c-row>
        </template>
      </div>
    </section>
    <c-modal centered show-close-button :visible="hasConfigurationError">
      <c-heading slot="header" level="3" as="3">{{
        configurationErrorTitle
      }}</c-heading>
      <div v-html="configurationErrorDescription" />
    </c-modal>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';
import { CRow, CCol, CModal, CHeading } from 'olympus/components';

import numberMixin from 'webshop/mixins/number-mixin.js';
import addressMixin from 'webshop/mixins/address-mixin.js';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin.js';

import { ITEM_TYPES } from 'webshop/enums/access-technology-types.js';
import NUMBER_CONFIGURATION_TYPES from 'webshop/enums/number-configuration-types.js';

export default {
  name: 'CheckoutSummaryConfiguration',

  components: {
    CCard,
    CRow,
    CCol,
    CModal,
    CHeading,
    PopoverPanel
  },

  mixins: [numberMixin, addressMixin],

  props: {
    content: {
      type: Object,
      required: true,
      default: () => ({})
    },

    data: {
      type: Object,
      default: () => ({})
    },

    homeOfficeFlow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasConfigurationError: this.data.configurationErrorCode > 0,
      configurationErrorTitle:
        window.__APP__?.checkoutErrorMap &&
        window.__APP__.checkoutErrorMap[this.data.configurationErrorCode]
          ?.title,
      configurationErrorDescription:
        window.__APP__?.checkoutErrorMap &&
        window.__APP__.checkoutErrorMap[this.data.configurationErrorCode]
          ?.description
    };
  },

  computed: {
    subscriptionItems() {
      return this.data.subscriptionItems.filter(i => {
        return (
          i.itemType === ITEM_TYPES.SUBSCRIPTION ||
          i.type === ITEM_TYPES.SUBSCRIPTION
        );
      });
    },

    broadbandItems() {
      return this.data.subscriptionItems.filter(i => {
        return (
          i.itemType === ITEM_TYPES.BROADBAND || i.type === ITEM_TYPES.BROADBAND
        );
      });
    }
  },

  methods: {
    formatNumber(number, subscriptionType) {
      const formattedNumber = this.formatNumberByDigits(number).trim();
      let subscriptionTypeText = '';
      switch (subscriptionType) {
        case NUMBER_CONFIGURATION_TYPES.NEW:
          subscriptionTypeText =
            this.content.subscriptionNewNumberConfigurationDescription;
          break;
        case NUMBER_CONFIGURATION_TYPES.EXISTING:
          subscriptionTypeText =
            this.content.subscriptionExistingNumberConfigurationDescription;
          break;
        case NUMBER_CONFIGURATION_TYPES.MOVED:
          subscriptionTypeText =
            this.content.subscriptionMovedNumberConfigurationDescription;
          break;
        default:
          break;
      }
      return subscriptionTypeText
        ? populateContentProperty(subscriptionTypeText, {
            phoneNumber: formattedNumber
          })
        : formattedNumber;
    },

    returnFilteredSimCardTypeText(simCardTypeText) {
      const filteredSimCardTypeText = this.content.simCardTypeTexts?.find(
        text => text.key === simCardTypeText
      );

      if (filteredSimCardTypeText) {
        return filteredSimCardTypeText.value;
      }
    },

    mobileNumberServiceGenerator(item) {
      const services = [];

      if (item.simCardType) {
        const filteredSimCardTypeText = this.returnFilteredSimCardTypeText(
          item.simCardType
        );

        services.push(filteredSimCardTypeText);
      }

      if (item.isHidden) {
        services.push(this.content.numberInformationHiddenNumberLabel);
      }

      if (item.isUnlisted) {
        services.push(this.content.numberInformationUnlistedNumberLabel);
      }

      return services.join(', ');
    },

    formatStreet(address) {
      return this.createAddressStringFromData(address, false);
    },

    formatCity(address) {
      return this.createAddressStringFromData(address, false, true);
    },

    showTechnicianNeededText(content) {
      if (!this.homeOfficeFlow) {
        return content.technicianNeededText;
      }
      if (this.homeOfficeFlow && content.technicianRequiredDisclaimer) {
        return content.technicianNeededText;
      }
      return;
    },
    formatSubscriptionDetails(subscription) {
      const result = [];
      if (
        subscription.subscriptionConfigurationType ===
        NUMBER_CONFIGURATION_TYPES.MOVED
      ) {
        if (subscription.simNumber) {
          result.push(`SIM (${subscription.simNumber})`);
        }
        result.push(
          subscription.isLegalOwner
            ? this.content.ownerOfNumberText
            : this.content.notOwnerOfNumberText
        );
        result.push(
          subscription.isB2b
            ? this.content.businessCustomerText
            : this.content.privateCustomerText
        );
        if (subscription.isEpoaAllowed) {
          result.push(this.content.poaGivenText);
        }
        result.push(
          subscription.isMoveNumberAfterCurrentBinding
            ? this.content.movedAfterBindingPeriodText
            : subscription.numberPortingDate
        );
      }
      if (
        subscription.isHidden ||
        subscription.isUnlisted ||
        subscription.simCardType
      ) {
        result.push(this.mobileNumberServiceGenerator(subscription));
      }

      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.checkout-summary-configuration {
  &__subscription {
    font-size: $font-size-h5;
    margin-bottom: $u-300;

    &:last-of-type {
      margin-bottom: unset;
    }
  }

  $information-icon-size: 20px;

  &__technician-information-icon {
    margin-right: $u-100;
    max-height: $information-icon-size;
    max-width: $information-icon-size;
    vertical-align: text-bottom;
  }
}
</style>
