<template>
  <div :class="[{ 'c-video--paused': paused }, 'c-video']" ref="container">
    <div class="c-video__iframe" v-if="isExternalSrc">
      <img
        class="c-video__poster"
        :src="poster"
        v-if="paused && poster"
        ref="poster"
      />
      <iframe
        :src="src"
        frameborder="0"
        ref="iframe"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
    <video
      v-else
      :poster="poster"
      @click="setPlay"
      :src="src"
      @play="updatePlayState"
      @pause="updatePlayState"
      @ended="reset"
      :controls="!paused"
      ref="video"
    ></video>
    <slot name="play-icon"></slot>
  </div>
</template>

<script>
export default {
  name: 'c-video',
  data() {
    return {
      paused: true
    };
  },
  props: {
    isExternalSrc: {
      type: Boolean,
      default: false
    },
    src: String,
    poster: String
  },
  computed: {
    srcType: function() {
      return `video/${/(?:\.([^.]+))?$/.exec(this.src)}`;
    }
  },
  mounted: function() {
    if (!this.isExternalSrc) {
      return;
    }

    /*
        Detect first click inside iframe in order to remove play icon
    */
    window.addEventListener('blur', this.iframeClick);
  },
  beforeDestroy() {
    window.removeEventListener('blur', this.iframeClick);
  },
  methods: {
    updatePlayState({ target }) {
      this.setPaused(target.paused);
    },
    setPaused(value = false) {
      const { video } = this.$refs;
      this.paused = value;
    },
    iframeClick() {
      if (
        document.activeElement === this.$refs.iframe ||
        document.activeElement === this.$refs.poster
      ) {
        this.setPaused(false);
      }

      window.focus();
    },
    setPlay(e) {
      const { video } = this.$refs;

      if (!video.paused) return;

      video.play();
    },
    reset() {
      this.paused = true;
      this.$refs.video.load();
    }
  }
};
</script>
