var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    {
      staticClass:
        "\n    cm-c-checkout__module cm-c-checkout__module--mb\n    cm-c-total__section\n    billing-address\n  ",
      attrs: { "no-padding": "", "no-shadow": "", "no-rounding": "" }
    },
    [
      _c("h3", { staticClass: "cm-c-checkout__subtitle" }, [
        _vm._v(_vm._s(_vm.content.header))
      ]),
      _vm._v(" "),
      _c(
        "c-row",
        [
          _c("c-col", { attrs: { sm: "12", cols: "6" } }, [
            _c("strong", [_vm._v(_vm._s(_vm.content.addressLabel))])
          ]),
          _vm._v(" "),
          _c(
            "c-col",
            { staticClass: "cm-text-right-md", attrs: { sm: "12", cols: "6" } },
            [
              _c("ul", [
                _c("li", [_vm._v(_vm._s(_vm.data.organization))]),
                _vm._v(" "),
                _c("li", [_vm._v(_vm._s(_vm.data.name))]),
                _vm._v(" "),
                _c("li", [_vm._v(_vm._s(_vm.data.addressLine))]),
                _vm._v(" "),
                _vm.data.postalCode && _vm.data.city
                  ? _c("li", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.postalCode + " " + _vm.data.city) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }