<template>
  <c-card>
    <div class="config-group" :class="{ 'config-group--disabled': !enabled }">
      <article class="config-group__header">
        <div class="config-group__image">
          <img :src="image" role="presentation" />
        </div>

        <section class="config-group__info">
          <div class="config-group__info__left">
            <div
              class="config-group__info__left-title"
              data-testid="info-left-title"
            >
              {{ title }}
            </div>
            <div
              class="config-group__info__left-description"
              data-testid="info-left-description"
            >
              {{ subTitle }}
            </div>
            <!-- TODO DKT-1451: the configurationType is only set when the config-group is configured -->
            <div
              v-if="configurationType"
              class="config-group__info__left-completed-text"
            >
              {{ `${configurationTextPopulated}${numberConfigurationLabels}` }}
            </div>
          </div>

          <div class="config-group__info__right">
            <div class="config-group__info__right-title">
              {{ rightTitle }}
            </div>
            <div
              v-for="(rightSubTitle, idx) in rightSubTitleList"
              :key="idx"
              class="config-group__info__right-description text-right"
            >
              {{ rightSubTitle }}
            </div>
          </div>
        </section>

        <div v-if="enabled" class="config-group__toggle">
          <c-collapse-trigger id="product-config-group-trigger">
            <!-- // TODO DKT-1450: Handle focused state better(visually) for keyboard navigation -->
            <c-button
              reset
              type="button"
              class="config-group__toggle-button"
              @click="handleToggle"
            >
              <c-icon
                aria-hidden="true"
                :symbol-id="expanded ? 'arrow-up' : 'arrow-down'"
                class="config-group__toggle-button-icon"
              />

              <!-- Invisible, but accessible button label for screen readers -->
              <!-- TODO DKT-1449: Add a CMS label? -->
              <span class="cm-sr-only">Toggle content</span>
            </c-button>
          </c-collapse-trigger>
        </div>
      </article>

      <c-collapse
        v-if="expanded"
        id="product-config-group-trigger"
        :visible="expanded"
      >
        <c-form
          data-testid="config-group-slot-container"
          class="config-group__body"
          action=""
          @submit="handleFormSubmit"
        >
          <slot />
          <c-button
            type="submit"
            class="config-group__submit-button"
            :disabled="!enableSubmitButton"
          >
            {{ content.saveConfigurationButtonText }}
          </c-button>
        </c-form>
      </c-collapse>
    </div>
  </c-card>
</template>

<script>
import { mapGetters } from 'vuex';

import CCard from 'webshop/components/c-card/c-card.vue';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import formatNumberByDigits from 'webshop/mixins/number-mixin';
import {
  CCollapse,
  CCollapseTrigger,
  CButton,
  CForm
} from 'olympus/components/index.js';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin';

import { GETTERS as CATALOG_GETTERS } from '../../../../../../store/catalog/index.js';
import VARIANT_RESPONSE_TYPES from '../../../../../../enums/reference-types';
import { SIM_OPTIONS } from 'webshop/enums/sim-options';

export default {
  name: 'ConfigGroup',

  components: { CCard, CIcon, CCollapse, CCollapseTrigger, CButton, CForm },

  mixins: [formatNumberByDigits],

  props: {
    installationAddressObj: {
      type: Object,
      default: () => ({})
    },
    enabled: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    },
    enableSubmitButton: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => ({})
    },
    configurationType: {
      type: String,
      default: ''
    },
    number: {
      type: String,
      default: ''
    },
    configurationText: {
      type: String,
      default: ''
    },
    productCode: {
      type: String,
      default: ''
    },
    variants: {
      type: Object,
      default: () => ({})
    },
    isNumberHidden: {
      type: Boolean,
      default: false
    },
    isNumberUnlisted: {
      type: Boolean,
      default: false
    },
    configurationAttributes: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      SIM_OPTIONS
    };
  },

  computed: {
    ...mapGetters({
      getVariantByCode: CATALOG_GETTERS.GET_VARIANT_BY_CODE
    }),

    configurationTextPopulated() {
      return populateContentProperty(this.configurationText, {
        number: this.formatNumberByDigits(this.number).replace(/^ /, ''),
        activationDate: this.getFormattedActivationDate()
      });
    },

    numberConfigurationLabels() {
      const numberConfigurationLabels = [];

      if (this.configurationAttributes.simOption === SIM_OPTIONS.ESIM) {
        numberConfigurationLabels.push(this.content.cardSettings.esimCardText);
      }

      if (this.isNumberHidden) {
        numberConfigurationLabels.push(this.content.secretNumberLabel);
      }

      if (this.isNumberUnlisted) {
        numberConfigurationLabels.push(this.content.unlistedNumberLabel);
      }

      return numberConfigurationLabels.length
        ? `, ${numberConfigurationLabels.join(', ')}`
        : '';
    },

    title() {
      let title = this.variant.attributes?.displayName ?? '';
      if (this.variant.type === VARIANT_RESPONSE_TYPES.VARIANT_HANDSET) {
        title = this.variant.attributes?.simoDisplayName;
      }
      return title;
    },

    subTitle() {
      const variantAttributes = this.variant.attributes;
      const variantResponseType = this.variant.type;
      let subTitle = '';
      switch (variantResponseType) {
        case VARIANT_RESPONSE_TYPES.VARIANT_BROADBAND: {
          if (
            this.configurationAttributes.downloadSpeed &&
            this.configurationAttributes.uploadSpeed
          ) {
            subTitle = `${this.configurationAttributes.downloadSpeed.value}/${this.configurationAttributes.uploadSpeed.value} ${this.configurationAttributes.downloadSpeed.unit}`;
            break;
          }
          if (
            variantAttributes.downloadSpeed &&
            variantAttributes.uploadSpeed
          ) {
            subTitle = `${variantAttributes.downloadSpeed.value}/${variantAttributes.uploadSpeed.value} ${variantAttributes.downloadSpeed.unit}`;
          }

          break;
        }
        case VARIANT_RESPONSE_TYPES.VARIANT_HANDSET: {
          subTitle = populateContentProperty(
            this.content.configurationDescriptionLabel,
            {
              displayName: variantAttributes.handsetDisplayName
            }
          );

          break;
        }
        case VARIANT_RESPONSE_TYPES.VARIANT_MOBILE_SUBSCRIPTION: {
          subTitle = variantAttributes.includedData
            ? `${variantAttributes.includedData}`
            : '';
          if (variantAttributes.includedVoice) {
            subTitle += `, ${variantAttributes.includedVoice}`;
          }

          let binding = this.content.noBindingSubscriptionText;
          // If bindingPeriod has the value 0 or 1 there is no binding period
          if (
            variantAttributes.bindingPeriod &&
            variantAttributes.bindingPeriod !== 1
          ) {
            binding = populateContentProperty(
              this.content.paymentPeriodLabelTemplate,
              {
                paymentPeriod: variantAttributes.bindingPeriod
              }
            );
          }

          if (binding) {
            subTitle += ` - ${binding}`;
          }
          break;
        }
        default: {
          break;
        }
      }
      return subTitle;
    },

    rightTitle() {
      return this.variant.type === VARIANT_RESPONSE_TYPES.VARIANT_BROADBAND
        ? this.content.broadband.installationAddressLabel
        : '';
    },

    rightSubTitleList() {
      return this.variant.type === VARIANT_RESPONSE_TYPES.VARIANT_BROADBAND
        ? this.getAddressDisplayArray(this.installationAddressObj)
        : [];
    },

    image() {
      return this.variant?.attributes?.mediaResources.length
        ? this.variant?.attributes?.mediaResources[0].src
        : '';
    },

    variant() {
      const variantsLength = Object.keys(this.variants).length;
      if (variantsLength === 1) {
        return this.getVariantByCode(this.productCode);
      } else if (variantsLength === 2) {
        return this.mergeSimoAndHandsetVariants(this.variants);
      }
      return {};
    }
  },

  methods: {
    handleToggle() {
      this.$emit('click');
    },

    handleFormSubmit(e) {
      const formData = new FormData(e.target);
      let formDataAsObject = {};
      formData.forEach(function (value, key) {
        formDataAsObject[key] = value === 'on' ? true : value;
      });
      const finalData = {
        data: {
          type: this.$parent.expandedGroupItem,
          attributes: formDataAsObject
        }
      };
      // TODO DKT-1448: See task
      this.$emit('submit', {
        configType: this.$parent.expandedGroupItem,
        data: JSON.stringify(finalData)
      });
    },

    mergeSimoAndHandsetVariants(variants) {
      // If we have a handset, we want need to merge their displayNames together into one variant
      // (so we can display it in the configuration group description)
      const simoVariant =
        variants[
          Object.keys(variants).filter(
            key =>
              variants[key].type ===
              VARIANT_RESPONSE_TYPES.VARIANT_MOBILE_SUBSCRIPTION
          )[0]
        ];
      const handsetVariant =
        variants[
          Object.keys(variants).filter(
            key => variants[key].type === VARIANT_RESPONSE_TYPES.VARIANT_HANDSET
          )[0]
        ];

      const handsetDisplayName = handsetVariant.attributes.displayName;
      const handsetMediaResources = handsetVariant.attributes.mediaResources;
      // Update handset variant to only have the properties we create to show the configuration description
      const mergedVariant = JSON.parse(JSON.stringify(handsetVariant));
      mergedVariant.attributes = {
        handsetDisplayName: handsetDisplayName,
        simoDisplayName: simoVariant.attributes.displayName,
        mediaResources: handsetMediaResources
      };

      return mergedVariant;
    },

    getAddressDisplayArray(installationAddressObj) {
      const { streetName, houseNumber, floor, door, zipCode, postDistrict } =
        installationAddressObj;
      let addressArr = [];
      addressArr[0] = `${streetName} ${houseNumber}`;
      addressArr[1] = `${zipCode} ${postDistrict}`;

      if (floor) {
        addressArr[0] += `, ${floor}.`;
        if (door) {
          addressArr[0] += ` ${door}`;
        }
      }

      return addressArr;
    },
    getFormattedActivationDate() {
      if (this.configurationAttributes.activationDateAsap) {
        return this.content.broadband.activationAsapButtonText?.toLowerCase();
      }
      if (this.configurationAttributes.activationDate) {
        const newDate = new Date(this.configurationAttributes.activationDate);
        const day = newDate.getDate();
        const month = newDate.getMonth() + 1;
        const year = newDate.getFullYear().toString().slice(-2);

        return `${day}/${month}-${year}`;
      }
      return '';
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$config-image-width: $u-700;
$config-image-spacing: $u-350;

.config-group {
  // .config-group--disabled
  &--disabled {
    opacity: 0.5;
  }

  // .config-group__image
  &__image {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: $config-image-width;
    margin-right: $config-image-spacing;
  }

  // .congig-group__header
  &__header {
    display: flex;
  }

  // .config-group__info
  &__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-right: $u-400;

    @include mq($screen-md, max) {
      flex-direction: column;
    }

    // .config-group__info__left
    &__left {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;

      // .config-group__info__left-title
      &-title {
        color: $c-nt-900;
      }

      // .config-group__info__left-completed-text
      &-completed-text {
        color: $c-pm-500;
      }
    }
    // .config-group__info__right
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;

      @include mq($screen-md, max) {
        margin-top: $u-300;
        align-items: unset;
      }

      // .config-group__info__right-title
      &-title {
        color: $c-nt-600;
      }
    }

    // .config-group__info__left, .config-group__info__right
    &__left,
    &__right {
      // .config-group__info__left-title, .config-group__info__right-title
      &-title {
        font-weight: 700;
      }

      // .config-group__info__left-description, .config-group__info__right-description
      &-description {
        color: $c-nt-600;
      }
    }
  }

  // .config-group__toggle
  &__toggle {
    display: flex;
    align-items: center;
    position: relative;

    // .config-group__toggle-button
    &-button {
      display: flex;
      align-items: center;
      background: none;
      padding: $u-400 0 $u-400 $u-400;
      border-radius: 0;

      // .config-group__toggle-button-icon
      &-icon {
        height: $u-350;
        width: $u-350;
        fill: $c-pm-500;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  // .congig-group__body
  &__body {
    margin: $u-700 #{$config-image-width + $config-image-spacing} 0 #{$config-image-width +
      $config-image-spacing};

    @include mq($screen-md, max) {
      margin: $u-300 0 0 0;
    }
  }

  &__submit-button {
    display: block;
    margin: $u-500 auto 0;
  }
}
</style>
