<template>
  <input
    id="c-input"
    ref="input"
    :type="type"
    class="epi-form-text__input c-input__input"
    :readonly="readonly"
    :disabled="disabled"
    placeholder=" "
    :name="name"
    :value="value"
    :minlength="minlength"
    :maxlength="maxlength"
    :autocomplete="disableAutocomplete ? 'off' : 'on'"
    @change="onChange"
    @input="onInput"
    @keyup.enter="performSubmit"
    @keyup="onKeyup"
    @keypress="keypress"
  />
</template>

<script>
export default {
  name: 'CInput',
  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    placeHolderLabel: {
      type: String,
      default: ''
    },
    inputError: {
      type: String,
      default: ''
    },
    preventSubmit: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, InputEvent],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minlength: {
      type: Number,
      default: 0
    },
    maxlength: {
      type: Number,
      default: 999
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },

    onChange(event) {
      this.$emit('change', event.target.value);
    },

    onKeyup(event) {
      this.$emit('keyup', event);
    },

    performSubmit() {
      if (!this.preventSubmit) {
        this.$emit('submit', this.value);
      }
    },

    keypress(event) {
      this.$emit('keypress', event);
    },

    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>
<style lang="scss" scoped>
.c-input {
  &__input {
    border-radius: 3px;
  }
}
</style>
