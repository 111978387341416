<template>
  <div class="cm-c-checkout__module">
    <div
      v-for="(consent, consentGroupIndex) in numberConfigVM.consents"
      :key="consentGroupIndex"
    >
      <div class="cm-c-checkout__form-group">
        <div class="cm-c-checkout__subtitle">
          {{ consent.titleText }}
        </div>
        <div
          class="cm-c-checkout__consenttext"
          v-html="consent.description"
        ></div>
      </div>

      <label class="cm-c-checkbox">
        <input
          v-model="product.acceptedConsents"
          type="checkbox"
          class="cm-c-checkbox__o-input"
        />
        <span class="cm-c-checkbox__input"></span>
        <span class="cm-c-checkbox__label">
          {{ numberConfigVM.consentAcceptText }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/**
 * Component for rendering consents at subscription level.
 * Used in CallMe checkout flow in NewNumber/MoveNumber steps
 */
export default {
  name: 'NumberConsents',
  props: {
    activeProduct: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      product: this.activeProduct
    };
  },
  computed: {
    ...mapGetters(['numberConfigVM', 'numberConfigActiveProduct'])
  }
};
</script>
