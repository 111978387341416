<template>
  <div>
    <template v-if="priceRises && priceRises.length">
      <p>
        <strong>{{ contentLocal.riseHeader }}</strong>
      </p>
      <div class="c-vas-substitutes">
        <div
          v-for="(vas, index) in priceRises"
          :key="index"
          class="c-vas-substitutes__item"
        >
          <c-vas-card-substitute :vas="vas.variants[0]" current />
          <c-icon symbol-id="arrow-right" />
          <c-vas-card-substitute :vas="vas.variants[0].substitute" />
        </div>
      </div>
    </template>
    <template v-if="priceDrops && priceDrops.length">
      <p>
        <strong>{{ contentLocal.dropHeader }}</strong>
      </p>
      <div class="c-vas-substitutes">
        <div
          v-for="(vas, index) in priceDrops"
          :key="index"
          class="c-vas-substitutes__item"
        >
          <c-vas-card-substitute :vas="vas.variants[0]" current />
          <c-icon symbol-id="arrow-right" />
          <c-vas-card-substitute :vas="vas.variants[0].substitute" />
        </div>
      </div>
    </template>
    <template v-if="removables && removables.length">
      <p>
        <strong>{{ contentLocal.removablesHeader }}</strong>
      </p>
      <p v-if="showInsuranceDisclaimer" class="c-vas-substitutes__disclaimer">
        {{ contentLocal.insuranceDisclaimer }}
      </p>
      <div class="c-vas-substitutes c-vas-substitutes--list">
        <c-vas-card-substitute
          v-for="(vas, index) in removables"
          :key="index"
          :vas="vas.variants[0]"
        />
      </div>
    </template>
  </div>
</template>

<script>
import CVasCardSubstitute from './c-vas-card-substitute.vue';
import { ITEM_TYPES } from '../../enums/access-technology-types';
import { STATE_INTERACTION } from './vas-constants';

const DEFAULT_CONTENT = {
  riseHeader: 'Fordele der bliver dyrere',
  dropHeader: 'Fordele der bliver billigere',
  removablesHeader: 'Fordele der forsvinder',
  insuranceDisclaimer:
    'Når du køber en ny telefon, kan du desværre ikke fortsætte med den gamle forsikring. Du kan altid vælge forsikring til din nye telefon.'
};

export default {
  name: 'c-vas-substitutes',

  components: {
    CVasCardSubstitute
  },

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    vases: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  computed: {
    priceRises() {
      return this.vases.filter(vas => {
        return vas.variants.find(
          variant =>
            variant.substitute !== null &&
            variant.price.value < variant.substitute.price.value
        );
      });
    },

    priceDrops() {
      return this.vases.filter(vas => {
        return vas.variants.find(
          variant =>
            variant.substitute !== null &&
            variant.price.value > variant.substitute.price.value
        );
      });
    },

    removables() {
      return this.vases.filter(vas => {
        return vas.variants.find(
          variant => variant.state.interaction === STATE_INTERACTION.NO_CHANGE
        );
      });
    },

    showInsuranceDisclaimer() {
      return this.removables.find(vas => {
        return vas.variants.find(
          variant => variant.type === ITEM_TYPES.INSURANCE
        );
      });
    }
  }
};
</script>

<style lang="scss">
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.c-vas-substitutes {
  padding-bottom: $u-350;

  &__disclaimer {
    font-size: $font-size-h5;
  }

  &__item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $u-350;

    > svg {
      flex-shrink: 0;
      height: 19px;
      margin: 0 $u-450;
      width: 19px;

      @include mq($screen-md, max) {
        margin: 0 $u-150;
      }
    }
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin-bottom: $u-350;
    }
  }
}
</style>
