var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-layer-manager" },
    [
      _c("transition", { attrs: { name: "c-layer-manager--fade" } }, [
        _vm.hasLayerExpanded
          ? _c("div", {
              staticClass: "c-layer-manager__back",
              on: { click: _vm.close }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }