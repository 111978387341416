var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-short-description" }, [
    _c("p", { staticClass: "product-short-description__text" }, [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } })
    ]),
    _vm._v(" "),
    _vm.descriptionLinkText
      ? _c(
          "a",
          {
            staticClass: "product-short-description__link",
            on: { click: _vm.onDetailsPressed }
          },
          [_vm._v("\n    " + _vm._s(_vm.descriptionLinkText) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }