import { populateContentProperty } from './content-property-mixin.js';

export default {
  methods: {
    /**
     *
     * @param {Object} param - The values used for interpolation
     * @param {number} param.installmentPeriod - Installment period (in months)
     * @param {string} param.minimumPrice - A text sentence which describes the minimum price
     * @param {string} param.fullPrice - A string consisting of the price and suffixed with the local currency
     * @param {Object} metaDictionary - Key Value pair dict containing properties and text templates in mustache syntax
     * @returns {Array} A list of strings
     */
    productCardMetaData: (
      { installmentPeriod, minimumPrice, fullPrice, description },
      meta
    ) => {
      const metaDictionary = meta ?? [];
      const hasInstallments = installmentPeriod > 1;

      const metaDataProperties = {
        Installments: {
          condition: hasInstallments,
          text: template =>
            populateContentProperty(template, {
              installments: installmentPeriod
            })
        },

        MinimumPrice: {
          condition: Boolean(minimumPrice),
          text: () => minimumPrice
        },

        FullPrice: {
          condition: hasInstallments,
          text: template =>
            populateContentProperty(template, {
              fullPrice: fullPrice
            })
        },

        Description: {
          condition: !!description,
          text: () => description
        }
      };

      return metaDictionary.reduce((metaDataTextLines, current) => {
        const metaDataProperty = metaDataProperties[current.property];
        const condition = metaDataProperty ? metaDataProperty.condition : false;
        return condition
          ? [...metaDataTextLines, metaDataProperty.text(current.textTemplate)]
          : metaDataTextLines;
      }, []);
    }
  }
};
