<template>
  <div
    class="c-subcard c-subcard--generic"
    v-bind:class="[modifier ? 'c-subcard--' + modifier : '']"
  >
    <div class="c-subcard__inner">
      <strong class="c-subcard__headline">{{ subHeading }}</strong>

      <div class="c-subcard__name">
        {{ heading }}
      </div>

      <div
        class="c-subcard__description"
        itemprop="description"
        v-html="body"
      ></div>

      <a class="c-subcard__url" v-if="buttonText" v-bind:href="buttonUrl">{{
        buttonText
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'generic-product',

  props: {
    subHeading: String,
    heading: String,
    body: String,
    buttonUrl: String,
    buttonText: String,
    modifier: String
  },

  render(h) {
    return h();
  }
};
</script>
