<section class="product-details">
  <div class="product-details__heading">
    <h1 class="product-details__heading__title">
      {{ product.model }}
    </h1>
    <h2 class="product-details__heading__brand">
      <a v-if="productRelations && productRelations.length" :href="productRelations[0].url">
        {{ brand }}
      </a>
      <template v-else>
        {{ brand }}
      </template>
    </h2>

    <span v-if="selectedVariant" class="product-details__heading__summary">
      <template v-if="$resize && $mq.below(1180) && badgeText">
        <span :class="badgeClassList">{{ badgeText }}</span>
      </template>

      <template v-if="ribbonText">
        <span class="c-badge c-badge--primary">{{ ribbonText }}</span>
      </template>

      <p class="product-details__heading__summary__text">
        {{ variantSummary }}
      </p>
    </span>
  </div>

  <product-short-description v-if="selectedVariant" :description="getDescription" :descriptionLinkText="product.descriptionLinkText"></product-short-description>
</section>