<template>
  <c-modal :visible="showLoyaltyVasModal" gray @close="closeModal()">
    <template>
      <div slot="header" class="text-center">
        <h2>
          <strong>{{ content.loyaltyVasHeadline }}</strong>
        </h2>
      </div>
      <div class="mb-2 text-center">
        <h4>{{ content.loyaltyVasDescription }}</h4>
      </div>
    </template>

    <template>
      <div class="cm-grid-vaslist cm-c-category-col">
        <c-vas-card
          v-for="(loyaltyVas, index) in loyaltyVases"
          :key="index"
          :vas="loyaltyVas"
          :as-radio-select="true"
          @loyalty-vas-click="handleLoyaltyVasClick"
        >
        </c-vas-card>
      </div>
    </template>

    <template slot="footer">
      <c-button
        :disabled="networking"
        data-testid="continue"
        type="button"
        @click="handleLoyaltyVasSelected"
      >
        {{ content.loyaltyVasButtonText }}
      </c-button>

      <span
        v-if="showLoyaltyVasModalError"
        class="error-message"
        data-testid="error-message"
      >
        {{ content.loyaltyVasErrorMessage }}</span
      >

      <c-button
        data-testid="cancel"
        type="button"
        variant="link"
        :disabled="networking"
        @click="handleLoyaltyVasNotSelected"
      >
        {{ content.loyaltyVasLinkText }}
      </c-button>
    </template>
  </c-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';
import { GETTERS as NUMBER_GETTERS } from 'webshop/store/number-select/index.js';
import CButton from '../../../../../shared/components/c-button/c-button.vue';
import CVasCard from '../vas/c-vas-card.vue';
import CModal from '../../../../../shared/components/c-modal/c-modal.vue';

export default {
  name: 'SubscriptionPageLoyaltyOverlay',

  components: { CButton, CVasCard, CModal },

  props: {
    loyaltyVases: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => ({})
    },
    subscriptionCode: {
      type: String,
      default: ''
    },
    showLoyaltyVasModal: {
      type: Boolean,
      default: false
    },
    membersQuantity: {
      type: Number,
      default: 0
    },
    isBroadband: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedLoyaltyVas: null,
      showLoyaltyVasModalError: false,
      networking: false
    };
  },
  computed: {
    ...mapGetters({
      selectedPhoneNumber: NUMBER_GETTERS.GET_SELECTED_PHONE_NUMBER
    })
  },
  methods: {
    ...mapActions({
      addSubscription: BASKET_ACTIONS.ADD_LOYALTY_VAS_SUBSCRIPTION
    }),

    async handleLoyaltyVasSelected() {
      if (!this.selectedLoyaltyVas) {
        this.showLoyaltyVasModalError = true;
        return;
      }

      if (this.isBroadband) {
        this.$emit('buyWithVas', this.selectedLoyaltyVas.code);
        return;
      }

      this.networking = true;

      await this.addSubscription({
        code: this.subscriptionCode,
        bundleCode: this.selectedLoyaltyVas.code,
        upsale: true,
        membersQuantity: this.membersQuantity,
        pageId: this.content.id,
        existingMsisdn: this.selectedPhoneNumber
      });

      this.closeModal();
    },

    async handleLoyaltyVasNotSelected() {
      if (this.isBroadband) {
        this.$emit('buyWithVas');
        return;
      }

      this.networking = true;

      await this.addSubscription({
        code: this.subscriptionCode,
        continueWithoutLoyaltyVas: true,
        upsale: true,
        membersQuantity: this.membersQuantity,
        pageId: this.content.id,
        existingMsisdn: this.selectedPhoneNumber
      });

      this.closeModal();
    },

    handleLoyaltyVasClick({ vasProduct }) {
      this.selectedLoyaltyVas = vasProduct;
      this.showLoyaltyVasModalError = false;
    },

    closeModal() {
      this.selectedLoyaltyVas = null;
      this.$emit('close');
      this.networking = false;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';

.error-message {
  color: $c-sp-red-500;
}
</style>
