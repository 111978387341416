import { get } from './api.js';

export const getVasContent = (blockId, groupId) =>
  get(`/api/olympus/VasCheckoutModule/get`, {
    mvc: true,
    params: { blockId, groupId }
  }).then(res => res.data);

export const getLoyaltyVases = (productCode, addressId) =>
  get('/vas/loyalty', {
    params: { productCode, addressId }
  }).then(res => res.data);
