var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "c-btn shopping-cart-purchase",
      on: { click: _vm.handleClick }
    },
    [
      _c("c-icon", {
        staticClass:
          "shopping-cart-purchase__icon shopping-cart-purchase__icon--type",
        attrs: { "symbol-id": _vm.offering.icon }
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.offering.label))]),
      _vm._v(" "),
      _c("c-icon", {
        staticClass:
          "shopping-cart-purchase__icon shopping-cart-purchase__icon--plus",
        attrs: { "symbol-id": "service-compare" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }