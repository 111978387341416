<template>
  <transition name="c-layer-panel--slide">
    <section 
      v-show="localExpanded"
      class="c-layer-panel" 
      :class="contentModifiers"
    >
      <header>
        <h2>{{ title }}</h2>
        <c-button type="close" @click.prevent="collapse"/>
      </header>
      <div class="c-layer-panel-content">
        <slot />
      </div>
    </section>
  </transition>
</template>

<script>
import CButton from '../c-button/c-button.vue';

export default {
  name: 'c-layer-panel',

  model: {
    prop: 'expanded',
    event: 'change'
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    },
    noCloseButton: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CButton
  },

  data() {
    return {
      localExpanded: this.expanded
    }
  },

  computed: {
    contentModifiers() {
      return {
        'c-layer-panel__content--no-close': this.noCloseButton,
      }
    }
  },

  watch: {
    expanded(value) {
      const action = value ? this.expand : this.collapse;
      action();
    },
    localExpanded(value) {
      this.$emit('change', value);
    }
  },

  methods: {
    expand() {
      this.localExpanded = true;
    },

    collapse() {
      this.localExpanded = false;
    }
  }
}
</script>

<style lang="scss">
.c-layer-panel {
  position: relative;
  height: 100%;
  width: 75%;
  box-shadow: 15px 0 250px 15px #2d2d2d;
  pointer-events: auto;
  background-color: white;
  overflow: auto;

  & > header {
    padding: 20px 15px 20px;
    position: relative;
    margin-bottom: 20px;
    color: black;
  }

  &-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--slide {
    &-enter-active,
    &-leave-active {
      transition: transform .3s ease-in-out;
    }

    &-enter,
    &-leave-to {
      transform: translateX(-100%);
    }
  }

  &--no-close {
    padding-top: 0;
  }
}
</style>
