export const MEDIA_GROUP_NAMES = {
  DEFAULT: 'Default',
  PRODUCT: 'Product',
  GALLERY: 'Gallery',
  MARKETING_DATA: 'MarketingData',
  SPECIFICATIONS: 'Specifications',
  CATEGORY: 'Category',
  PRICE_SUMMARY: 'PriceSummary'
};

export const MEDIA_TYPES = {
  IMAGE: 'Image'
};
