<template>
  <div
    v-if="offerings.length && offering && !isLoading"
    class="date-config mb-3"
  >
    <!-- Home Office datepicker form -->
    <div class="mb-3">
      <h3 class="cm-c-form__header">
        {{ content.selectActivationDateHeader }}
      </h3>
    </div>
    <c-row class="mb-2">
      <c-col cols="12">
        <c-form-radio-group
          v-model="form.activationDateAsap"
          name="activationDateAsap"
          stacked
          highlighted
          @input="updateFormData"
        >
          <c-form-radio id="activateAsap" :value="true">
            <span>{{ content.activateASAPButtonText }}</span>
          </c-form-radio>
          <c-form-radio id="activateSelect" :value="false">
            <span>{{ content.selectActivationDateButtonText }}</span>
          </c-form-radio>
        </c-form-radio-group>
      </c-col>
    </c-row>
    <c-collapse id="asapFooter" :visible="showActivateAsapFooter">
      <c-row class="employee-config__footer">
        <c-col>
          <div v-html="content.activateAsapFooter"></div>
        </c-col>
      </c-row>
    </c-collapse>
    <c-collapse id="datepicker" :visible="showDateSelectorStep">
      <c-row>
        <c-col>
          <c-datepicker
            :label="content.datePickerPlaceholder"
            :disabled-dates="orderData ? orderData.restrictedDates : []"
            :initial-date="activationDate"
            :first-available-date="firstAvailableDate"
            data-testid="date-config__datepicker"
            input-name="activationDate"
            :clearable="false"
            @change="setDate"
          />
        </c-col>
      </c-row>
      <c-row class="employee-config__footer">
        <c-col>
          <div v-html="content.selectActivationDateFooter"></div>
        </c-col>
      </c-row>
    </c-collapse>
  </div>
</template>

<script>
import {
  CRow,
  CCol,
  CFormRadioGroup,
  CFormRadio,
  CCollapse
} from 'olympus/components/index.js';
import CDatepicker from 'webshop/components/c-datepicker/c-datepicker.vue';

import { mapGetters } from 'vuex';
import { GETTERS } from 'webshop/store/home-office';

export default {
  name: 'EmployeeConfigDatepicker',

  components: {
    CRow,
    CCol,
    CFormRadioGroup,
    CFormRadio,
    CCollapse,
    CDatepicker
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    offering: {
      type: Object,
      default: () => ({})
    },

    hasCancellationAssistanceOption: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        activationDateAsap: this.orderData?.activationDateAsap,
        activationDate: this.orderData?.activationDate
      }
    };
  },

  computed: {
    ...mapGetters({
      isLoading: GETTERS.GET_IS_LOADING,
      offerings: GETTERS.GET_CARD_DATA,
      orderData: GETTERS.GET_ORDER_DATA
    }),

    showActivateAsapFooter() {
      return this.form.activationDateAsap === true;
    },

    showDateSelectorStep() {
      return this.form.activationDateAsap === false;
    },

    activationDate() {
      return this.orderData?.activationDate || this.firstAvailableDate;
    },

    firstAvailableDate() {
      // If hasCancellationAssistanceOption is true && if ASAP chip is selected, set activationdate according to backend data
      if (
        this.hasCancellationAssistanceOption === true &&
        this.showActivateAsapFooter
      ) {
        return this.orderData?.firstAvailableDateChangeProviderActivationDate
          ?.value;
      }

      // If hasCancellationAssistanceOption is true && if custom date chip is selected, set activationdate according to backend data
      if (
        this.hasCancellationAssistanceOption === true &&
        this.showDateSelectorStep
      ) {
        return this.orderData?.firstAvailableDateProviderActivationDateSelected
          ?.value;
      }

      return this.orderData?.firstAvailableDate;
    }
  },

  watch: {
    orderData: {
      deep: true,
      handler(value) {
        this.form.activationDateAsap = value?.activationDateAsap;
        this.form.activationDate = value?.activationDate;
      }
    }
  },

  mounted() {
    this.updateFormData();
  },

  methods: {
    updateFormData() {
      this.$emit('update', this.form);
    },

    setDate(e) {
      this.form.activationDate = e;
      this.updateFormData();
    }
  }
};
</script>
