<script>
import { get } from '../../../api/api.js';
import eidMixin from '../../../mixins/eid-mixin';
import CheckoutEid from 'webshop/components/checkout/checkout-eid.vue';
import { CButton, CInformationBox } from 'olympus/components';
import { providerKey as checkoutProviderKey } from '../../../providers/checkout-provider.js';

export default {
  name: 'CheckoutEidEnterprise',

  components: {
    CheckoutEid,
    CButton,
    CInformationBox
  },

  mixins: [eidMixin],

  inject: [checkoutProviderKey],

  props: {
    skipEid: Boolean
  },

  data() {
    return {
      cvrNumber: '',
      cvrConfirmed: false,
      isExistingCustomer: false,
      cvrMismatch: false,
      useKeyCard: true,
      companyData: '',
      animateIn: true,
      fetching: false,
      skipCpr: true,
      segment: '2'
    };
  },

  methods: {
    handleCVRchange(e) {
      this.cvrNumber = e.target.value;
    },

    fetchValidateCvr(e) {
      e.preventDefault();
      this.fetching = true;
      get('/company/' + this.cvrNumber)
        .then(json => {
          this.cvrConfirmed = true;
          this.showingError = false;
          this.renderEidBlock();
          this.companyData = json.data;
          this.fetching = false;
        })
        .catch(() => {
          this.showingError = true;
          this.fetching = false;
        });
    },

    changeCVR(e) {
      e.preventDefault();
      this.cvrNumber = '';
      this.cvrConfirmed = false;
      this.showingError = false;
      this.isExistingCustomer = false;
      this.cvrMismatch = false;

      this.temporaryModel.secret = '';
      this.temporaryModel.token = '';
      this.temporaryModel.cvr = '';
      this.parameters = '';
    },

    renderEidBlock() {
      this.temporaryModel.secret = this.secret ? this.secret : '';
      this.temporaryModel.token = this.token ? this.token : '';
      this.temporaryModel.cvr = this.cvrNumber ? this.cvrNumber : '';

      if (this.skipEid) {
        this.$nextTick(() => {
          this.eidResponseSuccess();
        });
      }

      if (!this.hasParameters) {
        this._fetchParams(this.useKeyCard ? 1 : 2);
      }
    },

    toggleEidMode() {
      this.useKeyCard = !this.useKeyCard;
      this._fetchParams(this.useKeyCard ? 1 : 2);
    },

    eidResponseError(response) {
      this.cvrConfirmed = false;
      this.cvrMismatch = response.cvrMismatch;
      this.isExistingCustomer = response.isExistingCustomer;
    },
    eidResponseSuccess() {
      this[checkoutProviderKey].submit();
    }
  }
};
</script>
