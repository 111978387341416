var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.compare.length > 0,
          expression: "compare.length > 0"
        }
      ],
      staticClass: "cm-c-compare-layer",
      attrs: { id: "cm-c-compare-layer" }
    },
    [
      _c("div", { staticClass: "cm-container" }, [
        _c("div", { staticClass: "cm-grid" }, [
          _c(
            "div",
            {
              staticClass:
                "\n          cm-grid__col--md-9 cm-grid__col--md-offset-3 cm-grid__col--xs-12\n        "
            },
            [
              _c("div", { staticClass: "cm-grid cm-grid--bottom-md" }, [
                _c(
                  "div",
                  { staticClass: "cm-grid__col--md-8 cm-grid__col--xs-12" },
                  [
                    _c(
                      "ul",
                      { staticClass: "cm-c-compare-layer__list" },
                      [
                        _vm._l(_vm.compare, function(item) {
                          return _c(
                            "li",
                            {
                              key: item.productId,
                              staticClass: "cm-c-compare-layer__item",
                              on: {
                                click: function($event) {
                                  return _vm.setCompareItem(item)
                                }
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "cm-c-compare-layer__item-photo"
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.productImageUrl,
                                      alt: item.productName
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "cm-c-compare-layer__item-name"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.productName) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.compareMax - _vm.compareCount, function(
                          empty
                        ) {
                          return _c(
                            "li",
                            {
                              key: empty,
                              staticClass:
                                "\n                  cm-c-compare-layer__item cm-c-compare-layer__item--empty\n                "
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "cm-c-compare-layer__item-name"
                                },
                                [_vm._v(_vm._s(_vm.phoneLabel))]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cm-grid__col--md-4 cm-grid__col--xs-12" },
                  [
                    _c("p", { staticClass: "cm-c-compare-layer__text" }, [
                      _vm._v(_vm._s(_vm.compareText))
                    ]),
                    _vm._v(" "),
                    _c(
                      "c-button",
                      {
                        staticClass: "cm-c-compare-layer__button",
                        attrs: {
                          variant: _vm.BUTTON_VARIANTS.TRANSACTIONAL,
                          disabled: _vm.compareDisabled
                        },
                        on: { click: _vm.compareProducts }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.compareLabel) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }