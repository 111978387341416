import { get } from './api.js';

export const getVasConfiguration = (groupId, selectedSubscription) => {
  return get('/carts/groups/items/subscriptionchanges', {
    params: {
      groupId: groupId,
      selectedSubscription: selectedSubscription.selectedSubscription
    },
    headers: {
      accept: 'application/vnd.api+json'
    }
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error.errors);
    });
};
