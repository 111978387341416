var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      sizes: "(max-width: 1600px) 100vw, 1600px",
      srcset:
        "\n  " +
        _vm.imageUrl +
        "?w=320 320w,\n  " +
        _vm.imageUrl +
        "?w=480 480w,\n  " +
        _vm.imageUrl +
        "?w=768 768w,\n  " +
        _vm.imageUrl +
        "?w=1024 1024w,\n  " +
        _vm.imageUrl +
        "?w=1600 1600w",
      alt: _vm.altText
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }