<template>
  <div>
    <div class="form-group">
      <input
        id="tags"
        v-model="search"
        class="form-control"
        :placeholder="autoCompleteCaption"
        type="text"
        @input="onChange"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
      />
      <ul v-show="isOpen" class="autocomplete-results">
        <li v-if="isLoading" class="loading">
          {{ loadMoreText }}
        </li>
        <li
          v-for="(result, i) in results"
          v-else
          :key="i"
          class="autocomplete-result"
          :class="{ 'is-active': i === arrowCounter }"
          @click="setResult(result)"
        >
          {{ result.productName }}
        </li>
      </ul>
    </div>
    <div v-show="isSelected">
      <div class="question-item-wrapper">
        <div
          v-for="(questionitem, y) in questions.questions"
          :key="y"
          class="question-item"
        >
          {{ questionitem.name }}
          <div
            v-for="(answer, z) in questionitem.answers"
            :key="z"
            class="answer-item"
          >
            <input
              :id="'answer_' + questionitem.id + '_' + y"
              key="'answer_' + z"
              type="radio"
              :name="'answer_' + questionitem.id + '_' + y"
              :checked="tmp_answersArr[y] == z ? true : false"
              :value="questionitem.id + '_' + answer.id"
              @change="updatePrice($event, questionitem.id + '_' + answer.id)"
            />
            <div class="retail-radio-text">
              {{ answer.name }}
            </div>
            <span class="retail-replace-radio"></span>
          </div>
        </div>
      </div>
      <div class="replace-retail-price">
        {{ pricequote }}
      </div>
      <a :href="buttonLink" target="" class="c-btn">
        {{ buttonText }}
      </a>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'CReplaceRetail',

  props: {
    autoCompleteCaption: {
      type: String,
      default: ''
    },
    minimumSearchText: {
      type: String,
      default: ''
    },
    pricePrefix: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonLink: {
      type: String,
      default: ''
    },
    loadMoreText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isOpen: false,
      isSelected: false,
      results: [],
      questions: [],
      tmp_answers: {},
      tmp_answersArr: [],
      gradingforms: [],
      search: '',
      selectedId: 0,
      pricequote: '',
      cancelSource: null,
      isLoading: false,
      arrowCounter: 0,
      items: []
    };
  },

  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter]);
    },
    onChange() {
      this.isLoading = true;
      this.selectedId = 0;
      this.isSelected = false;
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      axios
        .get(`/api/olympus/replaceretail/models/?search= ${this.search}`, {
          cancelToken: this.cancelSource.token
        })
        .then(response => {
          if (response) {
            this.items = response.data;
            this.isLoading = false;
            this.cancelSource = null;
            this.filterResults();
            this.isOpen = true;
          }
        });
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
        this.selectedId = 0;
      }
    },
    filterResults() {
      this.results = this.items.filter(
        item =>
          item.productName.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
    setResult(result) {
      this.search = result.productName;
      this.selectedId = result.id;
      this.isOpen = false;
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      axios
        .get(`/api/olympus/replaceretail/${result.id}/gradingform`, {
          cancelToken: this.cancelSource.token
        })
        .then(response => {
          if (response) {
            this.questions = response.data;
            this.isSelected = this.questions ? true : false;
            this.cancelSource = null;
            if (this.isSelected) {
              for (
                let index = 0;
                index < this.questions.questions.length;
                index++
              ) {
                let tmpArr = new Array();
                tmpArr.push(0);
                this.tmp_answers[this.questions.questions[index].id] = tmpArr;
                this.tmp_answersArr[this.questions.questions[index].id] = 0;
              }
            }
            this.pricequote = this.pricePrefix
              ? this.pricePrefix + ' ' + result.offeredPriceInclVat
              : 'Get up to ' + result.offeredPriceInclVat;
          }
        });
    },
    updatePrice(event, value) {
      event.preventDefault();
      let tmp = {};
      tmp.ModelId = this.selectedId;
      let tmpArr = new Array();
      tmpArr.push(value.split('_')[1] === '0' ? 0 : 1);
      this.tmp_answersArr[value.split('_')[0]] =
        value.split('_')[1] === '0' ? 0 : 1;
      this.tmp_answers[value.split('_')[0]] = tmpArr;
      tmp.GradingForm = {};
      tmp.GradingForm.Answers = {};
      tmp.GradingForm.Answers = this.tmp_answers;
      this.gradingforms = tmp;
      axios
        .post(
          `/api/olympus/replaceretail/pricequote`,
          JSON.parse(JSON.stringify(this.gradingforms))
        )
        .then(response => {
          if (response) {
            this.pricequote = this.pricePrefix
              ? this.pricePrefix + ' ' + response.data.offeredPriceInclVat
              : 'Get up to ' + response.data.offeredPriceInclVat;
            this.cancelSource = null;
          }
        });
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  display: none;
  float: left;
  min-width: 160px;
  padding: $u-150 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: $font-size-h5;
  text-align: left;
  background-color: $color-white;
  border: $u-100 solid $c-nt-300;
  border: $u-100 solid rgba($color-black, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: $box-shadow-default;
  box-shadow: $box-shadow-default;
  background-clip: padding-box;
}

.ui-autocomplete > li > div {
  display: block;
  padding: $u-350 $u-350;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: $c-nt-850;
  white-space: nowrap;
}

.ui-state-hover,
.ui-state-active,
.ui-state-focus {
  text-decoration: none;
  color: $c-nt-800;
  background-color: $c-nt-50;
  cursor: pointer;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: $u-100;
  margin: -$u-100;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: $u-100;
}

.autocomplete {
  position: relative;
  width: 130px;
}

.autocomplete-results {
  margin: 0;
  border: $u-100 solid $c-nt-300;
  background-color: $color-white;
  height: 200px;
  overflow: auto;
  width: 250px;
  color: $color-black;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: $u-150 $u-250;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: $c-nt-50;
  color: $color-black;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: $c-nt-50;
  color: $color-black;
}

.question-item {
  padding: $u-350 $u-250 $u-350 0;
  display: inline-flex;
  align-items: center;
  width: 300px;
}

.form-control {
  width: 100%;
  padding: $u-250;
  border: $u-100 solid $c-nt-300;
  border-radius: 2px;
  height: 54px;
}

.form-control:hover {
  border: $u-100 solid #6e6e6e;
}

.form-control:focus {
  border: $u-100 solid #990ae3;
}

.replace-retail-header {
  font-family: 'Pebble';
  text-transform: uppercase;
  font-size: $font-size-h2;
}

.replace-retail-description {
  padding: $u-150 0;
}

.replace-retail-price {
  font-family: Pebble;
  font-size: $font-size-h2;
  text-transform: uppercase;
  color: $color-black;
  padding-top: $u-400;
  padding-bottom: $u-400;
}

.replace-retail-wrapper {
  padding: $u-250;
}

.answer-item {
  display: inline-flex;
  align-items: center;
  margin-left: $u-350;
}

.question-item-wrapper {
  display: inline-grid;
}

.retail-radio-text {
  padding-left: $u-250;
}
</style>
