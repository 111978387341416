<template>
  <div class="mb-4">
    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col" cols="6" xs="12">
        <c-input-group
          v-model="product.subscriptionReceiverFirstName"
          :input-error="firstNameError"
          :place-holder-label="numberConfigVM.subscriptionReceiverFirstNameText"
        ></c-input-group>
      </c-col>
      <c-col class="cm-c-form__col" cols="6" xs="12">
        <c-input-group
          v-model="product.subscriptionReceiverLastName"
          :input-error="lastNameError"
          :place-holder-label="numberConfigVM.subscriptionReceiverLastNameText"
        ></c-input-group>
      </c-col>
    </c-row>

    <c-row class="cm-c-form__row">
      <c-col class="cm-c-form__col" cols="6" xs="12">
        <c-input-group
          v-model="product.subscriptionReceiverEmail"
          type="email"
          :input-error="emailError"
          :place-holder-label="numberConfigVM.subscriptionReceiverEmailText"
        ></c-input-group>
      </c-col>
      <c-col class="cm-c-form__col" cols="6" xs="12">
        <c-input-group
          ref="repeatField"
          v-model="product.subscriptionReceiverRepeatedEmail"
          type="email"
          :input-error="repeatEmailError"
          :place-holder-label="
            numberConfigVM.subscriptionReceiverRepeatEmailText
          "
        ></c-input-group>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import { CRow, CCol } from 'olympus/components/index.js';

export default {
  name: 'NumberSubinfo',

  components: {
    CInputGroup,
    CRow,
    CCol
  },

  props: {
    activeProduct: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      product: this.activeProduct
    };
  },

  computed: {
    ...mapGetters(['numberConfigVM', 'numberConfigActiveProduct']),

    firstNameError() {
      return this.$v.subscriptionReceiverFirstName.$invalid &&
        this.$v.subscriptionReceiverFirstName.$model.length
        ? this.numberConfigVM.errorMessageSubscriptionReceiverFirstName
        : '';
    },

    lastNameError() {
      return this.$v.subscriptionReceiverLastName.$invalid &&
        this.$v.subscriptionReceiverLastName.$model.length
        ? this.numberConfigVM.errorMessageSubscriptionReceiverLastName
        : '';
    },

    emailError() {
      return this.$v.subscriptionReceiverEmail.$invalid &&
        this.$v.subscriptionReceiverEmail.$model.length
        ? this.numberConfigVM.errorMessageSubscriptionReceiverEmail
        : '';
    },

    repeatEmailError() {
      return this.$v.subscriptionReceiverRepeatedEmail.$invalid &&
        this.$v.subscriptionReceiverRepeatedEmail.$model.length
        ? this.numberConfigVM.errorMessageSubscriptionReceiverRepeatedEmail
        : '';
    }
  }
};
</script>
