<script>
import geoLocationMixin from '../../mixins/geo-location-mixin.js';
import CButton from '../../../../../shared/components/c-button/c-button.vue';
import StoreAutocomplete from 'webshop/components/store-autocomplete/store-autocomplete.vue';
import { CIcon } from '../c-icon/index.js';

export default {
  name: 'FindStore',
  components: {
    CButton,
    CIcon,
    StoreAutocomplete
  },
  mixins: [geoLocationMixin],
  props: {
    shopData: {
      type: Array,
      required: true
    },
    centerControlText: {
      type: String,
      default: 'Tæt på mig'
    },
    numberOfSearchResults: {
      type: Number,
      default: 5
    }
  },

  data() {
    return {
      copenhagenCoordinates: {
        lat: 55.6959315,
        lng: 12.4609883
      },
      shops: this.shopData.map(x => ({ ...x })),
      map: {},
      mapHeight: undefined,
      zoomLevels: {
        initial: 7,
        markerFocused: 15,
        closeToMe: 13
      },
      userLocationCoordinates: undefined,
      focusedMarker: {},
      infoWindow: {},
      markers: [],
      defaultMapHeight: 500,
      showLocationReminderModal: false,
      useRelationsEndpoint: true
    };
  },

  computed: {
    calcMapHeight() {
      return {
        '--map-height': `${this.mapHeight}px`
      };
    }
  },

  watch: {
    focusedMarker(marker) {
      if (marker && marker.id) {
        this.setupAndOpenInfoWindow(marker);
        this.centerOnFocusedMarker();
      } else {
        this.infoWindow.close(this.map);
      }
    }
  },

  mounted() {
    this.mapHeight = this.getNavBarHeight();
    this.initMap();
    this.createInfoWindowInstance();
    this.prompUserLocation();
    this.createMapCenterControl();
    this.shopData.forEach(shop => this.contructMarker(shop));
  },

  methods: {
    getNavBarHeight() {
      try {
        return document.querySelector('#header-element').offsetHeight;
      } catch (error) {
        return this.defaultMapHeight;
      }
    },

    /**
     * @function initMap
     * @description Creates a new google map instance.
     */
    initMap() {
      this.map = new window.google.maps.Map(this.$refs.mapContainer, {
        center: this.copenhagenCoordinates,
        clickable: true,
        zoom: this.zoomLevels.initial,
        disableDefaultUI: true,
        zoomControl: true,
        clickableIcons: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            stylers: [{ visibility: 'on' }]
          }
        ]
      });
    },

    /**
     * @function createMapCenterControl
     * @description Creates a new custom map-control element and places it in the specific 'ControlPosition'
     * @docs https://developers.google.com/maps/documentation/javascript/examples/control-custom
     */
    createMapCenterControl() {
      const controlElement = document.createElement('DIV');
      controlElement.innerHTML = `
      <div class="fs__map-control">
        <svg xmlns="http://www.w3.org/2000/svg" class="fs__icon fs__icon--fill-primary fs__icon--align-text-bottom">
          <use xlink:href="${window.__APP__.iconsPath}#service-direction"></use>
        </svg>
        <span class="fs__map-control-text fs__map-control-text--gap-left">${this.centerControlText}</span>
      </div>
      `;

      controlElement.addEventListener('click', () => {
        if (!this.userLocationCoordinates) {
          this.showLocationReminderModal = true;
          return;
        }

        const closestMarker = this.returnClosestMarker(
          this.userLocationCoordinates
        );
        if (closestMarker === this.focusedMarker) {
          this.centerOnFocusedMarker();
        } else {
          this.focusedMarker = closestMarker;
        }
      });

      this.map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(
        controlElement
      );
    },

    centerOnFocusedMarker() {
      this.map.setZoom(this.zoomLevels.markerFocused);
      this.map.setCenter(this.focusedMarker.position);
      this.scrollListToView();
    },

    /**
     * @function prompUserLocation
     * @description Prompts for geolocation access in the client, and handles the callback from the geolocator
     */
    async prompUserLocation() {
      this.getUserLocation()
        .then(value => {
          this.userLocationCoordinates = this.contructLatLngObject(
            value.coords.latitude,
            value.coords.longitude
          );
        })
        .then(() => {
          this.calculateShopDistances();
          this.dropLocationMarker(this.userLocationCoordinates);
          this.sortShops();

          // Allow sortShops() to finish rendering, since setting a new focused marker
          // will trigger a list scroll, which clashes with list sorting
          this.$nextTick(() => {
            this.focusedMarker = this.returnClosestMarker(
              this.userLocationCoordinates
            );
          });
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    /**
     * @function dropLocationMarker
     * @param {Object} position Object containing Lat & Lng coordinates
     * @description Creates a new map marker that acts a the 'location indicator', and displays it on the map
     */
    dropLocationMarker(position) {
      return new window.google.maps.Marker({
        position: position,
        map: this.map,
        Animation: window.google.maps.Animation.DROP,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 11,
          fillOpacity: 2,
          fillColor: '#4285f4',
          strokeColor: '#cddbf1',
          strokeWeight: 10.0,
          strokeOpacity: 1.0
        }
      });
    },

    /**
     * @function createInfoWindowInstance
     * @description Creates the Google Maps InfoWindow object, used for displaying things when user clicks a marker
     */
    createInfoWindowInstance() {
      const infoWindow = new window.google.maps.InfoWindow();

      infoWindow.addListener('closeclick', () => {
        this.focusedMarker = {};
      });
      this.infoWindow = infoWindow;
    },

    /**
     * @function contructMarker
     * @description Creates a new map marker/pin and displays it on the map instance
     */
    contructMarker({
      id,
      storeName,
      streetAndNumber,
      zipCode,
      city,
      image,
      displayAsPartnerShop,
      lat,
      lng
    }) {
      const marker = new window.google.maps.Marker({
        position: this.contructLatLngObject(lat, lng),
        map: this.map,
        id: id,
        // TODO DKT-1446: Import png images in component instead
        icon: {
          // Svg's does not look good on the map, so we're using png's instead
          url: require(`../../../norlys/images/find-store/${
            displayAsPartnerShop ? 'maptsicon-blue.png' : 'maptsicon.png'
          }`).default,
          scaledSize: new window.google.maps.Size(40, 40)
        },
        infoWindowMarkup: `
        <div class="fs__info-window-wrapper">
          <img src="${image.src}" alt="${image.alt}" class="fs__info-window-image" />
          <h4 class="fs__info-window-headline">${storeName}</h4>
          <p class="fs__info-window-description">
            ${streetAndNumber}
            <br>
            ${zipCode} ${city}
          </p>
        </div>`
      });

      marker.addListener('click', () => {
        this.focusedMarker = marker;
      });

      this.markers.push(marker);
    },

    getMarkerById(id) {
      return this.markers.find(
        marker => parseFloat(marker.id) === parseFloat(id)
      );
    },

    /**
     * @function returnClosestMarker
     * @description Returns the marker which is by distance, closest to the clients given position/geolocation
     */
    returnClosestMarker(coordinates) {
      // TODO DKT-1446: Create error handling
      return this.markers.reduce((previousMarker, currentMarker) => {
        const currentPosition = this.getDistanceBetween(
          coordinates,
          currentMarker.position
        );
        const previousPosition = this.getDistanceBetween(
          coordinates,
          previousMarker.position
        );
        return currentPosition < previousPosition
          ? currentMarker
          : previousMarker;
      });
    },

    /**
     * @function setupAndOpenInfoWindow
     * @param {google.maps.Marker} marker A Google Maps Marker object
     * @description Sets the content of the InfoWindow and opens it on the specific map position
     */
    setupAndOpenInfoWindow(marker) {
      const infoWindow = this.infoWindow;
      infoWindow.setContent(marker.infoWindowMarkup);
      infoWindow.setPosition(marker.position);
      infoWindow.setOptions({
        // Display infowindow correctly relatively to the marker position
        pixelOffset: new window.google.maps.Size(0, -35)
      });
      infoWindow.open(this.map);
      this.map.setCenter(marker.position);
    },

    /**
     * @function contructLatLngObject
     * @param {Number} lat Coordinate Latitude
     * @param {Number} lng Coordinate Longtitude
     * @description Returns a Google Maps specific coordinates object to be used as a markers position for example
     */
    contructLatLngObject(lat, lng) {
      return new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    },

    /**
     * @function getDistanceBetween
     * @param {google.maps.LatLng} from Google Maps coordinates object
     * @param {google.maps.LatLng} to Google Maps coordinates object
     * @description Returns the calculated distance between two coordinate sets
     */
    getDistanceBetween(from, to) {
      return window.google.maps.geometry.spherical.computeDistanceBetween(
        from,
        to
      );
    },

    calculateShopDistances() {
      this.shops.forEach(shop => {
        shop.distance = Math.ceil(
          this.getDistanceBetween(
            this.userLocationCoordinates,
            this.contructLatLngObject(shop.lat, shop.lng)
          )
        );
      });
    },

    sortShops() {
      this.shops.sort((a, b) => (a.distance > b.distance ? 1 : -1));
    },

    /**
     * @function getDistanceText
     * @param {number} distanceInMeters Coordinate Latitude
     * @description Returns a string showing the distance in meters/kilometers, based on the shops distance in meters
     */
    getDistanceText(distanceInMeters) {
      if (distanceInMeters < 99) {
        return `${distanceInMeters} m`;
      }
      const distanceInKilometers = distanceInMeters / 1000;
      return `${distanceInKilometers.toFixed(1)} km`;
    },

    /**
     * @function createMapsUrl
     * @param {object} addressData Raw address data of a shop
     * @description Generates and opens a Google Maps url with specified 'from' and 'to' params
     */
    createAndOpenUrl(addressData) {
      const streetName = addressData.streetName.replace(/\s/g, '+');
      const city = addressData.city.replace(/\s/g, '+');
      const path = 'https://www.google.com/maps/dir/?api=1';

      if (this.userLocationCoordinates) {
        const lat = this.userLocationCoordinates.lat();
        const lng = this.userLocationCoordinates.lng();
        this.openBlank(
          `${path}&origin=${lat}%2C${lng}&destination=${streetName}+${city}`
        );
        return;
      }

      this.openBlank(`${path}&destination=${streetName}+${city}`);
    },

    openBlank(url) {
      window.open(url, '_blank');
    },

    /**
     * @function scrollListToView
     * @description Scrolls the list of shops into view of the focused shop element
     */
    scrollListToView() {
      const target = this.$refs.listItems.find(
        item => item.getAttribute('data-key') === this.focusedMarker.id
      );
      const options = {
        container: this.$refs.scrollableElement,
        offset: -1
      };
      if (!target || !options.container) {
        throw new Error(
          'Element scroll has failed. Could not find either list or container element.'
        );
      }

      // We need to make sure that the current list-item is closed before scrolling,
      // to prevent the offsets from changing while the scroll is in progress.
      this.$nextTick(() => {
        this.$scrollTo(target, options);
      });
    },

    /**
     * @function selectAutoCompleteResult
     * @param {Number} id
     * @description Acts as a click handler for the click event on the autocomplete search results
     */
    selectAutoCompleteResult(id) {
      const selectedMarker = this.getMarkerById(id);
      if (selectedMarker === this.focusedMarker) {
        this.centerOnFocusedMarker();
      } else {
        this.focusedMarker = selectedMarker;
      }
    },

    selectAddressResult(pos) {
      const closestMarker = this.returnClosestMarker(
        this.contructLatLngObject(pos.lat, pos.lng)
      );
      if (closestMarker === this.focusedMarker) {
        this.centerOnFocusedMarker();
      } else {
        this.focusedMarker = closestMarker;
      }
    }
  }
};
</script>
