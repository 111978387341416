var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classModifiers }, [
    _c("input", {
      ref: "radio",
      staticClass: "chip__input",
      attrs: { type: "radio", name: _vm.name },
      domProps: { value: _vm.inputValue },
      on: { change: _vm.handleChange }
    }),
    _vm._v(" "),
    _c("span", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }