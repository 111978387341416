export default function Overlay(element, bool) {
  /*
  https://codepen.io/thuijssoon/pen/prwNjO 
  https://gist.github.com/thuijssoon/fd238517b487a45ce78d8f7ddfa7fee9
  https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi
  https://github.com/willmcpo/body-scroll-lock/blob/master/src/bodyScrollLock.js
  */
  let posY = null;
  element.style.cssText = '-webkit-overflow-scrolling: touch;';
  /*
  const isIOS =
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.platform &&
      /iPad|iPhone|iPod|(iPad Simulator)|(iPhone Simulator)|(iPod Simulator)/.test(
          window.navigator.platform
      );
  */
  const touchStart = function(event) {
    if (event.targetTouches.length === 1) {
      posY = event.targetTouches[0].clientY;
    }
  };

  const touchMove = function(event) {
    if (event.targetTouches.length === 1) {
      disableRubberBand(event);
    }
  };

  if (bool) {
    document.body.style.cssText =
      'overflow:hidden; touch-action:none; max-width:100vw; height:100vh';
    element.addEventListener('touchstart', touchStart, false);
    element.addEventListener('touchmove', touchMove, false);
  } else {
    document.body.style.cssText = '';
    element.removeEventListener('touchstart', touchStart, false);
    element.removeEventListener('touchmove', touchMove, false);
  }

  function disableRubberBand(event) {
    const clientY = event.targetTouches[0].clientY - posY;

    if (element.scrollTop === 0 && clientY > 0) {
      event.preventDefault();
    }

    if (isOverlayTotallyScrolled() && clientY < 0) {
      event.preventDefault();
    }
  }

  function isOverlayTotallyScrolled() {
    return element.scrollHeight - element.scrollTop <= element.clientHeight;
  }
}
