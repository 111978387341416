/**
 * @param { HTMLElement } el
 * @param { object } binding
 * @property { number } [binding.text] An optional text binding to use instead of the element's inner text.
 */
const trim = (el, binding) => {
  const text = binding.value?.text;

  if (text) {
    el.innerText = text.trim();
  } else {
    el.innerText = el.innerText?.trim();
  }
};

/**
 * Use this directive to trim an element's text of white space.
 * For dynamic content, pass a text binding for this directive to work properly -> v-trim="{ text: someProp }"
 * For static content, it is enough to wrap the text in a container with the [v-trim] attribute.
 */
export default {
  inserted: trim,
  componentUpdated: trim
};
