const Validation = {
  install: function(Vue, options) {
    Vue.directive('disable-on-check', function(el, binding) {
      let checkbox = document.querySelector(
        `[name="${binding.value.checkbox}"]`
      );

      let toggleDisabledAttribute = () => {
        el.removeAttribute('disabled');

        if (checkbox.checked) {
          el.value = '';
          el.setAttribute('disabled', checkbox.checked);
        }
      };

      if (checkbox) {
        toggleDisabledAttribute();

        checkbox.addEventListener('change', function() {
          toggleDisabledAttribute();
        });
      }
    });

    Vue.directive('hide-checkout-controls', function(el, binding) {
      let checkoutControls = document.querySelectorAll(
        `.${binding.value.btnClass}`
      );

      [].forEach.call(checkoutControls, item => {
        item.style.display = binding.value.expression ? 'none' : 'block';
      });
    });
  }
};

export default Validation;
