<template>
  <div>
    <c-row class="cm-c-form__row">
      <c-col cols="6" sm="12" class="text-bold">
        <strong>{{ content.orderReferenceLabel }}</strong>
      </c-col>
      <c-col cols="6" sm="12" class="text-right">
        {{ orderData.orderId }}
      </c-col>
    </c-row>
    <c-row class="cm-c-form__row">
      <c-col cols="6" sm="12" class="text-bold">
        <strong>{{ content.activationDateLabel }}</strong>
      </c-col>
      <c-col cols="6" sm="12" class="text-right">
        {{ formatDate(orderData.orderDate) }}
      </c-col>
    </c-row>
    <c-row v-if="content.shippingInfoLabelRouter" class="cm-c-form__row">
      <c-col cols="6" sm="12" class="text-bold">
        <strong>{{ content.shippingInfoLabel }}</strong>
      </c-col>
      <c-col cols="6" sm="12" class="text-right">
        <p
          v-if="
            orderData.shippingInformation &&
            orderData.shippingInformation.companyName
          "
        >
          {{ orderData.shippingInformation.companyName }}
        </p>
        <p>
          {{ `${firstName} ${lastName}` }}
        </p>
        <p>
          {{ formatStreet(shippingAddress) }}
        </p>
        <p>
          {{ formatCity(shippingAddress) }}
        </p>
      </c-col>
    </c-row>
    <c-row
      v-if="!isShippingAddressAvailable && content.shippingInfoLabelBroadband"
      class="cm-c-form__row"
    >
      <c-col cols="6" sm="12" class="text-bold">
        <strong>{{ content.shippingInfoLabelBroadband }}</strong>
      </c-col>
      <c-col cols="6" sm="12" class="text-right">
        <p>
          {{ `${installationAddressFirstName} ${installationAddressLastName}` }}
        </p>
        <p>
          {{ formatStreet(businessAddress) }}
        </p>
        <p>
          {{ formatCity(businessAddress) }}
        </p>
      </c-col>
    </c-row>
  </div>
</template>

<script>
import { CRow, CCol } from 'olympus/components/index.js';

import format from 'date-fns/format';

import addressMixin from 'webshop/mixins/address-mixin.js';

export default {
  name: 'EmployeeReceiptOrderDetails',

  components: {
    CRow,
    CCol
  },

  mixins: [addressMixin],

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    orderData: {
      type: Object,
      default: () => ({})
    },

    employeeData: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    shippingAddress() {
      return (
        this.orderData?.shippingInformation?.shippingAddress ||
        this.employeeData?.address?.value
      );
    },
    firstName() {
      return (
        this.orderData?.shippingInformation?.firstName ||
        this.employeeData?.firstName?.value ||
        ''
      );
    },
    lastName() {
      return (
        this.orderData?.shippingInformation?.lastName ||
        this.employeeData?.lastName?.value ||
        ''
      );
    },
    businessAddress() {
      return this.employeeData?.address?.value;
    },
    isShippingAddressAvailable() {
      return (
        this.orderData?.shippingInformation?.shippingAddress.addressId !== null
      );
    },
    installationAddressFirstName() {
      return this.employeeData?.firstName?.value || '';
    },
    installationAddressLastName() {
      return this.employeeData?.lastName?.value || '';
    }
  },

  methods: {
    formatStreet(address) {
      return this.createAddressStringFromData(address, false);
    },

    formatCity(address) {
      return this.createAddressStringFromData(address, false, true);
    },

    formatDate(orderDate) {
      if (!orderDate) {
        return '';
      }

      const date = format(new Date(orderDate), 'dd/M-yyyy');
      const time = format(new Date(orderDate), 'HH:mm');
      return `${date}, kl. ${time}`;
    }
  }
};
</script>

<style lang="scss"></style>
