var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "tags",
          placeholder: _vm.autoCompleteCaption,
          type: "text"
        },
        domProps: { value: _vm.search },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            },
            _vm.onChange
          ],
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              return _vm.onArrowDown($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              return _vm.onArrowUp($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter($event)
            }
          ]
        }
      }),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          staticClass: "autocomplete-results"
        },
        [
          _vm.isLoading
            ? _c("li", { staticClass: "loading" }, [
                _vm._v("\n        " + _vm._s(_vm.loadMoreText) + "\n      ")
              ])
            : _vm._l(_vm.results, function(result, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "autocomplete-result",
                    class: { "is-active": i === _vm.arrowCounter },
                    on: {
                      click: function($event) {
                        return _vm.setResult(result)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(result.productName) + "\n      "
                    )
                  ]
                )
              })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSelected,
            expression: "isSelected"
          }
        ]
      },
      [
        _c(
          "div",
          { staticClass: "question-item-wrapper" },
          _vm._l(_vm.questions.questions, function(questionitem, y) {
            return _c(
              "div",
              { key: y, staticClass: "question-item" },
              [
                _vm._v("\n        " + _vm._s(questionitem.name) + "\n        "),
                _vm._l(questionitem.answers, function(answer, z) {
                  return _c("div", { key: z, staticClass: "answer-item" }, [
                    _c("input", {
                      key: "'answer_' + z",
                      attrs: {
                        id: "answer_" + questionitem.id + "_" + y,
                        type: "radio",
                        name: "answer_" + questionitem.id + "_" + y
                      },
                      domProps: {
                        checked: _vm.tmp_answersArr[y] == z ? true : false,
                        value: questionitem.id + "_" + answer.id
                      },
                      on: {
                        change: function($event) {
                          return _vm.updatePrice(
                            $event,
                            questionitem.id + "_" + answer.id
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "retail-radio-text" }, [
                      _vm._v(
                        "\n            " + _vm._s(answer.name) + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "retail-replace-radio" })
                  ])
                })
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "replace-retail-price" }, [
          _vm._v("\n      " + _vm._s(_vm.pricequote) + "\n    ")
        ]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "c-btn", attrs: { href: _vm.buttonLink, target: "" } },
          [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }