var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasConfigurationGroups
        ? _vm._l(_vm.configGroups, function(group, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "product-configuration" },
              [
                _c(
                  "config-group",
                  _vm._b(
                    {
                      staticClass: "product-configuration__group",
                      attrs: {
                        content: _vm.content,
                        "enable-submit-button": _vm.enableSubmitButton,
                        "configuration-text": _vm.getConfigurationText(
                          group.configurationType
                        )
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleConfigGroupClick(idx)
                        },
                        submit: _vm.handleConfigGroupItemSubmission
                      }
                    },
                    "config-group",
                    group,
                    false
                  ),
                  _vm._l(_vm.availableConfigurationItemTypes, function(
                    type,
                    index
                  ) {
                    return _c(type.component, {
                      key: index,
                      ref: type.config.id,
                      refInFor: true,
                      tag: "component",
                      attrs: {
                        id: type.config.id,
                        title: type.content.text,
                        icon: type.content.icon,
                        "config-data": type.configData,
                        "checked-sim-radio": group.selectedSimOption,
                        "group-id": group.groupId,
                        "prevent-expand": !type.config.attributes.isAvailable,
                        content: _vm.content,
                        errors: _vm.errors,
                        expanded: type.expanded,
                        "selected-number": group.number
                      },
                      on: {
                        expanded: function($event) {
                          return _vm.configGroupExpanded(type.config.id)
                        },
                        fetchMoreNumbers: function($event) {
                          return _vm.setNewNumberConfigData(true)
                        },
                        fetchAndSearchNewNumbers: _vm.fetchAndSearchNewNumbers,
                        removeErrors: _vm.removeErrors,
                        ready: _vm.toggleSubmitButton
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          ref: "prolongingUnavailableModal",
          attrs: {
            title: _vm.content.prolongingUnavailablePopUpHeading,
            centered: ""
          }
        },
        [
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.content.prolongingUnavailablePopUpText) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "c-button",
            {
              attrs: {
                slot: "footer",
                href: _vm.content.prolongingUnavailablePopUpButtonLink,
                variant: "link"
              },
              slot: "footer"
            },
            [_vm._v(_vm._s(_vm.content.prolongingUnavailablePopUpButtonText))]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }