var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-stock-status" }, [
    _c("div", { staticClass: "product-stock-status__container" }, [
      _vm.statusText
        ? _c(
            "span",
            {
              staticClass: "product-stock-status__text",
              class: _vm.statusColor
            },
            [
              _vm._v("\n      " + _vm._s(_vm.statusText) + "\n\n      "),
              _c(
                "span",
                { staticClass: "product-stock-status__date" },
                [
                  _vm.countdownData
                    ? _c("countdown", { attrs: { data: _vm.countdownData } })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "product-stock-status__footer" },
        [_vm._t("footer")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }