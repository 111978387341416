var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "credit-check" }, [
    _c(
      "div",
      { staticClass: "credit-check__inner" },
      [
        !_vm.showErrorMessage
          ? [
              _c("h3", { staticClass: "credit-check__title" }, [
                _vm._v("\n        " + _vm._s(_vm.content.headline) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "credit-check__steps" },
                _vm._l(_vm.steps, function(step, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "credit-check__steps__item show"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "credit-check__steps__item__icon",
                          class: _vm.lowerCaseStatus(step.status)
                        },
                        [
                          _c("spinner-chase", {
                            class: { show: step.status === "loading" }
                          }),
                          _vm._v(" "),
                          _c("c-icon", {
                            class: {
                              show:
                                step.status ===
                                  _vm.CREDIT_CHECK_RESPONSE.APPROVED ||
                                step.status ===
                                  _vm.CREDIT_CHECK_RESPONSE.NOT_NEEDED
                            },
                            attrs: { "symbol-id": "service-checkmark-filled" }
                          }),
                          _vm._v(" "),
                          _c("c-icon", {
                            class: {
                              show:
                                step.status ===
                                  _vm.CREDIT_CHECK_RESPONSE.REJECTED ||
                                step.status ===
                                  _vm.CREDIT_CHECK_RESPONSE.NO_FINANCING
                            },
                            attrs: { "symbol-id": "service-warning-filled" }
                          })
                        ],
                        1
                      ),
                      _vm._v("\n          " + _vm._s(step.text) + "\n        ")
                    ]
                  )
                }),
                0
              )
            ]
          : _c(
              "div",
              { staticClass: "credit-check__error" },
              [
                _c("c-icon", {
                  staticClass: "credit-check__error__icon",
                  attrs: { "symbol-id": "menu-close" }
                }),
                _vm._v(" "),
                _c(
                  "h3",
                  {
                    staticClass:
                      "credit-check__title credit-check__title--error"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.content.creditDeniedCompletelyHeader) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "credit-check__error__content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.content.creditDeniedCompletelyText) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "c-button",
                  { attrs: { href: _vm.content.creditDeniedRedirectUrl } },
                  [
                    _vm._v(
                      _vm._s(_vm.content.creditDeniedCompletelyButtonLabel)
                    )
                  ]
                )
              ],
              1
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }