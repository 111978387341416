var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plug-config" },
    [
      _vm.offerings.length && !_vm.offering && _vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "c-row",
                [
                  _c(
                    "c-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("skeleton-loader", {
                        staticClass: "plug-config__skeleton-loader__title"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                [
                  _c(
                    "c-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("skeleton-loader", {
                        staticClass: "plug-config__skeleton-loader__description"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-row",
                [
                  _c(
                    "c-col",
                    { attrs: { cols: "6", sm: "12" } },
                    [
                      _c("skeleton-loader", {
                        staticClass: "plug-config__skeleton-loader__plug-type"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "c-col",
                    { attrs: { cols: "6", sm: "12", "align-self": "center" } },
                    [
                      _c("skeleton-loader", {
                        staticClass: "plug-config__skeleton-loader__radio"
                      }),
                      _vm._v(" "),
                      _c("skeleton-loader", {
                        staticClass: "plug-config__skeleton-loader__radio"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.offerings.length && _vm.offering && !_vm.isLoading && _vm.askAboutPlug
        ? [
            _c("div", { staticClass: "mb-3" }, [
              _c("h3", { staticClass: "cm-c-form__header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.plugSectionHeader) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.plugSectionDescription) +
                    "\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "c-row",
              { staticClass: "mb-3" },
              [
                _c(
                  "c-col",
                  { attrs: { cols: "6", sm: "12" } },
                  _vm._l(_vm.selectedPlug, function(plug) {
                    return _c("c-icon", {
                      key: plug,
                      staticClass: "plug-config__plug__icon",
                      attrs: { "symbol-id": plug }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "c-col",
                  { attrs: { cols: "6", sm: "12", "align-self": "center" } },
                  [
                    _c(
                      "c-form-radio-group",
                      {
                        attrs: {
                          name: "hasPlug",
                          stacked: "",
                          highlighted: ""
                        },
                        on: { input: _vm.updatePlugInfo },
                        model: {
                          value: _vm.form.hasPlug,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "hasPlug", $$v)
                          },
                          expression: "form.hasPlug"
                        }
                      },
                      [
                        _c(
                          "c-form-radio",
                          { attrs: { id: "plugDoesExist", value: true } },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.content.plugExistButtonLabel))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "c-form-radio",
                          { attrs: { id: "plugDoesNotExist", value: false } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.content.plugDoesntExistButtonLabel)
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "c-collapse",
              {
                attrs: {
                  id: "plugDoesNotExistInformation",
                  visible:
                    _vm.technicianRequiredDisclaimer ||
                    _vm.showPlugDoesNotExistDisclaimer
                }
              },
              [
                _c(
                  "c-row",
                  [
                    _c(
                      "c-col",
                      [
                        _c(
                          "c-information-box",
                          {
                            staticClass: "plug-config__plug__information",
                            attrs: {
                              icon: "contact-information",
                              type: "informative"
                            }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.content.technicianHelpRequiredDisclaimer
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }