<template>
  <div
    v-if="offerings.length && offering && !isLoading"
    class="poa-config mb-3"
  >
    <!-- Power of attorney form -->
    <template v-if="offerings.length && !isLoading">
      <div class="mb-3">
        <h3 class="cm-c-form__header">
          {{ content.changeProviderCancellationAssistHeader }}
        </h3>
      </div>
      <c-row class="mb-3">
        <c-col>
          <c-form-radio-group
            v-model="form.cancelationAssist"
            name="cancelationAssist"
            stacked
            highlighted
            @input="updatePoaInfo"
          >
            <c-form-radio id="helpIsNeeded" :value="true">
              <span>{{ content.needHelpToCancelSubscriptionLabel }}</span>
            </c-form-radio>
            <c-form-radio id="helpNotNeeded" :value="false">
              <span>{{ content.noNeedHelpToCancelSubscriptionLabel }}</span>
            </c-form-radio>
          </c-form-radio-group>
        </c-col>
      </c-row>

      <c-information-box
        v-if="!showLineOwnerForm"
        icon="contact-information"
        type="informative"
      >
        <span v-html="content.rememberCancelSubscriptionDisclaimer"></span>
      </c-information-box>

      <c-collapse id="showLineOwnerForm" :visible="showLineOwnerForm">
        <div class="mb-3">
          <h3 class="cm-c-form__header">
            {{ content.areYouTheOwnerOfLineHeader }}
          </h3>
        </div>
        <c-row class="mb-1">
          <c-col>
            <c-form-radio-group
              v-model="form.lineOwner"
              name="lineOwner"
              stacked
              highlighted
              @input="updatePoaInfo"
            >
              <c-form-radio id="iAmOwner" :value="true">
                <span>{{ content.yesIAmTheOwnerButtonText }}</span>
              </c-form-radio>
              <c-form-radio id="iAmNotOwner" :value="false">
                <span>{{ content.noIAmNotTheOwnerButtonText }}</span>
              </c-form-radio>
            </c-form-radio-group>
          </c-col>
        </c-row>
      </c-collapse>

      <c-collapse id="lineOwnerInformation" :visible="showLineOwnerForm">
        <c-row>
          <c-col>
            <c-information-box icon="contact-information" type="informative">
              <span v-html="content.signingOfPowerOfAtorneyDisclaimer"></span>
            </c-information-box>
          </c-col>
        </c-row>
      </c-collapse>

      <c-collapse
        id="isNotOwnerInformation"
        :visible="showIsNotOwnerInformation"
      >
        <c-row>
          <c-col>
            <c-input-group
              v-model="form.ownerEmail"
              name="ownerEmail"
              :place-holder-label="content.customerEmailPlaceholder"
              @input="updatePoaInfo"
            />
          </c-col>
        </c-row>
      </c-collapse>

      <c-collapse id="providerList" :visible="showProviderList">
        <div class="mt-3">
          <h3 class="cm-c-form__header">
            {{ content.currentProviderHeadline }}
          </h3>
        </div>
        <c-row>
          <c-col>
            <c-dropdown
              v-model="form.currentProvider"
              input-name="currentProvider"
              input-value-key="value"
              :attributes="{
                options: providers
              }"
              :search-input-placeholder="content.providerPlaceholder"
              @change="updatePoaInfo"
            >
              <div slot="no-options" v-html="content.noResultsText"></div>
            </c-dropdown>
          </c-col>
        </c-row>
      </c-collapse>
    </template>
  </div>
</template>

<script>
import {
  CRow,
  CCol,
  CFormRadioGroup,
  CFormRadio,
  CCollapse,
  CInformationBox
} from 'olympus/components/index.js';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import CDropdown from 'webshop/components/c-dropdown/c-dropdown.vue';

import { mapGetters } from 'vuex';
import { GETTERS } from 'webshop/store/home-office';

export default {
  name: 'EmployeeConfigPoa',

  components: {
    CRow,
    CCol,
    CFormRadioGroup,
    CFormRadio,
    CCollapse,
    CInputGroup,
    CInformationBox,
    CDropdown
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    offering: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: {
        cancelationAssist: this.orderData?.cancelationAssist,
        lineOwner: this.orderData?.lineOwner,
        currentProvider: this.providers?.find(
          p => p.value === this.orderData?.currentProvider
        ),
        ownerEmail: this.orderData?.ownerEmail
      }
    };
  },

  computed: {
    ...mapGetters({
      isLoading: GETTERS.GET_IS_LOADING,
      offerings: GETTERS.GET_CARD_DATA,
      orderData: GETTERS.GET_ORDER_DATA
    }),

    showLineOwnerForm() {
      return this.form.cancelationAssist === true;
    },

    showIsNotOwnerInformation() {
      return this.showLineOwnerForm && this.form.lineOwner === false;
    },

    showProviderList() {
      return this.orderData?.providers && this.form.cancelationAssist === true;
    },

    providers() {
      return this.orderData?.providers?.map(p => ({
        value: p.name,
        label: p.name,
        hasCollaboration: p.hasCollaboration
      }));
    }
  },

  watch: {
    orderData: {
      deep: true,
      handler(value) {
        if (!value) {
          return;
        }

        this.form.cancelationAssist = value.cancelationAssist;
        this.form.lineOwner = value.lineOwner;
        this.form.currentProvider = this.providers?.find(
          p => p.value === value.currentProvider
        );
        this.form.ownerEmail = value.ownerEmail;

        this.updatePoaInfo();
      }
    }
  },

  methods: {
    updatePoaInfo() {
      this.$emit('update', this.form);
    }
  }
};
</script>

<style lang="scss"></style>
