<template>
  <span
    class="
      c-badge
      c-badge--tertiary
      c-badge--round
      c-badge--round--small
    "
    :class="{ 'c-badge--active': hasBasketItems }"
  >
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { GETTERS as BASKET_GETTERS } from '../../store/basket';

/**
 * A stateful component that checks the number of items in the shopping cart (basket)
 * and displays a badge.
 */
export default {
  name: 'shopping-cart-badge',

  computed: {
    ...mapGetters({
      basketEmpty: BASKET_GETTERS.IS_EMPTY
    }),

    hasBasketItems() {
      return !this.basketEmpty;
    }
  }
};
</script>

<style scoped lang="scss">
.c-badge {
  position: absolute;
  top: 0;
  right: -1px; // visually align dot badge with icon
  transform: scale(0.5);
  transform-origin: left center;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  &--active {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
