var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", class: [{ "c-video--paused": _vm.paused }, "c-video"] },
    [
      _vm.isExternalSrc
        ? _c("div", { staticClass: "c-video__iframe" }, [
            _vm.paused && _vm.poster
              ? _c("img", {
                  ref: "poster",
                  staticClass: "c-video__poster",
                  attrs: { src: _vm.poster }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("iframe", {
              ref: "iframe",
              attrs: {
                src: _vm.src,
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                allowfullscreen: ""
              }
            })
          ])
        : _c("video", {
            ref: "video",
            attrs: { poster: _vm.poster, src: _vm.src, controls: !_vm.paused },
            on: {
              click: _vm.setPlay,
              play: _vm.updatePlayState,
              pause: _vm.updatePlayState,
              ended: _vm.reset
            }
          }),
      _vm._v(" "),
      _vm._t("play-icon")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }