<script>
export default {
  name: 'CheckoutPayment',

  props: {
    token: {
      type: String,
      required: true
    },
    successRedirect: {
      type: String,
      required: true
    },
    errorRedirect: {
      type: String,
      required: true
    },
    customTranslation: {
      type: Object,
      required: false,
      default: () => ({})
    },
    customTheme: {
      type: String,
      required: false,
      default: ''
    },
    language: {
      type: String,
      required: true
    },
    paymentMethods: {
      type: Array,
      required: true
    },
    customCssUrl: {
      type: String,
      required: false,
      default: ''
    },
    brand: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      paymentWindowLoading: false,
      redirecting: false
    };
  },

  computed: {
    showSpinner: function () {
      return this.paymentWindowLoading || this.redirecting;
    }
  },

  mounted() {
    this.paymentWindowLoading = true;

    const config = {
      element: this.$refs.paymentWindow,
      purchaseRequestToken: this.token,
      paymentMethods: this.paymentMethods
    };

    if (this.customCssUrl !== '') {
      config.customCssUrl = this.customCssUrl;
    }

    if (this.brand === 'callme') {
      config.customTheme = {
        font: { primary: 'Verdana', title: 'Verdana' },
        color: {
          primary: '#eb0000',
          lightPrimary: '#ff3863',
          darkSecondary: '#0a0055',
          lightSecondary: '#1500b3',
          monthButtonSelected: '#eb0000'
        },
        borderRadius: { monthPicker: '4px', button: '4px' }
      };
    }

    window
      .initWebcheckout(config)
      .then(webcheckoutApi => {
        this.paymentWindowLoading = false;
        this.webcheckoutApi = webcheckoutApi;

        webcheckoutApi
          .start()
          .then(webcheckoutCompleteResponse => {
            if (webcheckoutCompleteResponse.type === 'SUCCESS') {
              this.redirecting = true;
              let downPaymentMonths = 1;
              if (
                webcheckoutCompleteResponse.paymentMethod === 'card' ||
                webcheckoutCompleteResponse.paymentMethod === 'partPayment'
              ) {
                if (
                  webcheckoutCompleteResponse.paymentMethod === 'partPayment'
                ) {
                  downPaymentMonths = webcheckoutCompleteResponse.downPayTime;
                }

                const separator = this.successRedirect.includes('?')
                  ? '&'
                  : '?';
                window.location.href =
                  this.successRedirect +
                  separator +
                  'months=' +
                  downPaymentMonths +
                  '&paymentmethod=' +
                  webcheckoutCompleteResponse.paymentMethod;
              }
            }
          })
          .catch(() => {
            //TODO DKT-1452: - Find out what to do if an error occurs
          });
      })
      .catch(() => {
        //TODO DKT-1452: - Find out what to do if an error occurs
      });
  }
};
</script>
