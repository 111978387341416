<template>
  <div v-html="content"></div>
</template>
<script>
/**
 * @description this is a helper wrapper component
 * for content area static html, that has external
 * functionality, which is not part of the Vue app
 * CMSEPICON project introduced a set of vanilla JS
 * 'components' to keep the SEO as good as possible
 * but once these are rendered inside our app lifecycle
 * it will not regenerate the functionality as its being
 * run only once at the bottom of the page. We instead
 * forcefully regen the entire show once again.
 *
 * Note: this will run regen on every 'component' from
 * above per instance of @this. We need to improve to spec
 * only descendent nodes of @this to be regen.
 *
 */
export default {
  name: 'epi-content',

  props: {
    content: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      init: false
    };
  },

  watch: {
    content(val) {
      this.regen(val);
    }
  },

  mounted() {
    this.regen(this.content);
  },

  methods: {
    regen(content) {
      this.$nextTick(() => {
        if (content && document.__regen) {
          document.__regen();
          this.init = true;
        }
      });
    }
  }
};
</script>
