<template>
  <c-card extra-padding no-shadow no-rounding class="confirmation-details">
    <!-- Confirmation details -->
    <h3 class="cm-c-form__header">
      {{ content.contactInformationSectionHeader }}
    </h3>
    <c-row
      v-if="customerData.name && customerData.name.value"
      class="cm-c-form__row"
    >
      <c-col cols="6" xs="12">
        <strong>{{ content.nameText }}</strong>
      </c-col>
      <c-col cols="6" xs="12" class="cm-text-right-md">
        {{ customerData.name.value }}
      </c-col>
    </c-row>
    <c-row
      v-if="customerData.phoneNumber && customerData.phoneNumber.value"
      class="cm-c-form__row"
    >
      <c-col cols="6" xs="12">
        <strong>{{ content.phoneNumberText }}</strong>
      </c-col>
      <c-col cols="6" xs="12" class="cm-text-right-md">
        {{ customerData.phoneNumber.value }}
      </c-col>
    </c-row>
    <c-row class="mb-2" v-if="customerData.email && customerData.email.value">
      <c-col cols="6" xs="12">
        <strong>{{ content.emailText }}</strong>
      </c-col>
      <c-col cols="6" xs="12" class="cm-text-right-md">
        {{ customerData.email.value }}
      </c-col>
    </c-row>
    <c-row
      tag="p"
      no-gutters
      v-if="content.divaSplitOrderDisclaimerEnabled"
      justified
    >
      <c-information-box
        icon="contact-information"
        type="informative"
        class="c-checkout-receipt__disclaimer"
      >
        <strong v-if="content.divaSplitOrderDisclaimerTitle" name="header">
          {{ content.divaSplitOrderDisclaimerTitle }}
        </strong>
        <div v-html="content.divaSplitOrderDisclaimerText" />
      </c-information-box>
    </c-row>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import { CRow, CCol, CInformationBox } from 'olympus/components/index.js';

export default {
  name: 'CheckoutConfirmDetails',

  components: {
    CCard,
    CRow,
    CCol,
    CInformationBox
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    customerData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';

.confirmation-details {
  font-size: $font-size-h5;
}
</style>
