import Vue from 'vue';
import { get } from '../../api/api.js';
import { createNamespace } from '../utils.js';

import { SUBSCRIPTION_SORT_METHODS } from '../../enums/subscriptions.js';

const MUTATIONS = createNamespace('OFFERINGS/MUTATIONS', [
  'SET_BROADBAND_OFFERINGS_FOR_ADDRESS',
  'SET_OFFERING_PRICE_SUMMARY'
]);

export const ACTIONS = createNamespace('OFFERINGS/ACTIONS', [
  'FETCH_BROADBAND_OFFERINGS',
  'FETCH_OFFERING_PRICES'
]);

export const GETTERS = createNamespace('OFFERINGS/GETTERS', [
  'BROADBAND_OFFERINGS_BY_ADDRESS_ID',
  'OFFERING_PRICE_SUMMARY_BY_CODE'
]);

/**
 * Sorts offerings based on method and override options.
 *
 * @param {Array} subscriptions
 * @param {SUBSCRIPTION_SORT_METHODS} method
 * @param {Array} overrides
 * @returns
 */
export const sortOfferings = (offerings, method, overrides = []) => {
  const getPrice = ({ price }) => price.monthly.value;

  const strategy = {
    [SUBSCRIPTION_SORT_METHODS.PRICE_DESC]: (a, b) => {
      return getPrice(a) < getPrice(b);
    },
    [SUBSCRIPTION_SORT_METHODS.PRICE_ASC]: (a, b) => {
      return getPrice(a) > getPrice(b);
    }
  };

  const cmp = strategy[method];
  offerings.sort((a, b) => {
    return cmp(a, b) ? 1 : -1;
  });

  // the items in the overrides list are moved to the beggining
  // the rest of the order remains unaffected
  if (overrides.length) {
    offerings.sort((a, b) => {
      let indexA = overrides.indexOf(a.code);
      if (indexA < 0) {
        indexA = overrides.length;
      }

      let indexB = overrides.indexOf(b.code);
      if (indexB < 0) {
        indexB = overrides.length;
      }

      return indexA > indexB ? 1 : indexA < indexB ? -1 : 0;
    });
  }

  return offerings;
};

export default {
  state: {
    broadbandAddressOfferings: {},
    offeringPriceSummary: {}
  },

  mutations: {
    [MUTATIONS.SET_BROADBAND_OFFERINGS_FOR_ADDRESS](
      state,
      { addressId, offerings, selectedProductId }
    ) {
      if (!selectedProductId) {
        Vue.set(state.broadbandAddressOfferings, addressId, offerings);
      }
      offerings.forEach(offering => {
        state.broadbandAddressOfferings[addressId].forEach(
          (offeringInState, index) => {
            if (offeringInState.code === offering.code) {
              state.broadbandAddressOfferings[addressId][index] = offering;
            }
          }
        );
      });
    },
    [MUTATIONS.SET_OFFERING_PRICE_SUMMARY](state, { code, data }) {
      Vue.set(state.offeringPriceSummary, code, data);
    }
  },

  actions: {
    [ACTIONS.FETCH_BROADBAND_OFFERINGS](
      { commit, getters },
      { addressId, force = false, selectedProductId = undefined }
    ) {
      if (!force) {
        const existing =
          getters[GETTERS.BROADBAND_OFFERINGS_BY_ADDRESS_ID](addressId);
        if (existing && existing.length) {
          return;
        }
      }

      return get('/offerings/broadband', {
        params: { addressId, selectedProductId }
      }).then(({ data }) => {
        commit(MUTATIONS.SET_BROADBAND_OFFERINGS_FOR_ADDRESS, {
          addressId,
          offerings: data,
          selectedProductId
        });
      });
    },

    /**
     * @description Fetches price summary for offering by code.
     */
    [ACTIONS.FETCH_OFFERING_PRICES](
      { commit, getters },
      { code, addressId, force = false }
    ) {
      const existingOfferingPriceSummary =
        getters[GETTERS.OFFERING_PRICE_SUMMARY_BY_CODE](code);

      if (!force && existingOfferingPriceSummary) {
        return Promise.resolve(existingOfferingPriceSummary);
      }

      return get(`/offerings/${code}/price`, { params: { addressId } }).then(
        ({ data }) => {
          commit(MUTATIONS.SET_OFFERING_PRICE_SUMMARY, { code, data });
          return data;
        }
      );
    }
  },

  getters: {
    [GETTERS.BROADBAND_OFFERINGS_BY_ADDRESS_ID]:
      state =>
      ({ addressId, sortMethod, sortOverrides = [] }) => {
        let result = state.broadbandAddressOfferings[addressId] || [];
        if (sortMethod) {
          result = sortOfferings([...result], sortMethod, sortOverrides);
        }
        return result;
      },

    [GETTERS.OFFERING_PRICE_SUMMARY_BY_CODE]: state => code =>
      state.offeringPriceSummary[code] || null
  }
};
