<template>
  <div
    class="config-group-item"
    :class="{
      'config-group-item--active': expanded,
      'config-group-item--integrated': integrated
    }"
  >
    <c-button
      v-if="!integrated"
      reset
      class="config-group-item__button"
      type="button"
      @click="handleToggle"
    >
      <section class="config-group-item__info">
        <div class="config-group-item__image">
          <c-icon class="config-group-item__icon" :symbol-id="icon" />
        </div>

        <div class="config-group-item__text">
          <div class="config-group-item__title">{{ title }}</div>
          <slot name="description" />
        </div>

        <div
          v-if="rightText"
          :class="{
            'config-group-item__right-text': true,
            'config-group-item__right-text--center': rightTextCentered
          }"
        >
          {{ rightText }}
        </div>
      </section>

      <!-- Invisible, but accessible button label for screen readers -->
      <!-- // TODO DKT-1449: Add a CMS label? -->
      <span class="cm-sr-only">Toggle content</span>
    </c-button>

    <c-collapse
      v-if="expanded"
      id="product-config-group-item-trigger"
      :visible="expanded && !preventExpand"
    >
      <article
        data-testid="config-group-item-slot"
        class="config-group-item__body"
        :class="{
          'config-group-item__body--integrated': integrated
        }"
      >
        <slot />
      </article>
    </c-collapse>
  </div>
</template>

<script>
import CCollapse from 'olympus/components/c-collapse/c-collapse.vue';
import CButton from 'olympus/components/c-button/c-button.vue';
import CIcon from 'webshop/components/c-icon/c-icon.vue';

export default {
  name: 'ConfigGroupItem',

  components: { CButton, CIcon, CCollapse },

  props: {
    title: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    rightTextCentered: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    preventExpand: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    integrated: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleToggle() {
      this.$emit('expanded');
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.config-group-item {
  border: 1px solid $c-nt-300;
  border-radius: $u-200;
  background: $color-white;
  margin-bottom: $u-250;

  // .config-group-item--active
  &--active {
    border: 2px solid $color-border-focus;
  }

  // .config-group-item--integrated
  &--integrated {
    border: none !important;
  }

  // .config-group-item__image
  &__image {
    min-width: $u-600;
    min-height: $u-600;
    overflow: hidden;
    margin-right: $u-250;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($screen-sm, min) {
      margin-right: $u-350;
    }
  }

  // .config-group-item__icon
  &__icon {
    color: $color-black;
    fill: $color-black;
    width: $u-400;
    height: $u-400;
  }

  // .config-group-item__info
  &__info {
    width: 100%;
    padding: $u-250;
    display: flex;
  }

  // .config-group-item__text
  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $c-nt-600;
    text-align: left;
    font-size: $font-size-h5;
    font-weight: 400;
  }

  // .config-group-item__title
  &__title {
    display: flex;
    color: $c-nt-900;
    font-size: $font-size-h4;
    font-weight: 700;
  }

  // .config-group-item__right-text
  &__right-text {
    color: $c-nt-900;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-weight: 700;
    justify-content: flex-start;

    &--center {
      justify-content: center;
    }
  }

  // .config-group-item__button
  &__button {
    padding: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  // .congig-group-item__body
  &__body {
    margin: 0 $u-250 $u-250;

    // config-group-item_body--integrated
    &--integrated {
      margin: 0 !important;
    }

    @include mq($screen-sm, min) {
      margin: 0 $u-850 $u-700 $u-850;
    }
  }
}
</style>
