<template>
  <div class="product-color" :class="productColorClass" @click="colorPressed">
    <span
      class="product-color__circle"
      :class="{
        'product-color__circle--light': isBackgroundLight(value)
      }"
      :style="{ backgroundColor: value }"
    ></span>
    <span class="product-color__text">{{ name }}</span>
  </div>
</template>

<script>
import ColorMixin from 'webshop/mixins/color-mixin';

/**
 * Generically displays a color.
 * Used as part of the product wizard;
 * Meant to be included inside an attribute selector but can also be used by itself.
 */
export default {
  name: 'ProductColor',

  mixins: [ColorMixin],

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    selected: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    productColorClass() {
      return {
        'product-color--selected': this.selected
      };
    }
  },

  methods: {
    isBackgroundLight(color) {
      return this.isColorLight(color);
    },

    colorPressed() {
      /**
       * Triggers when a color is pressed.
       * Typically a parent component would capture this event
       * and set the 'selected' prop to this component.
       */
      this.$emit('color-pressed', { value: this.value, name: this.name });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$color-circle-border-size: 3px;
$color-circle-diameter-md: 36px;
$color-circle-diameter-sm: 28px;

// Faking outer border by using the box-shadow property
$color-circle-outer-border: 0 0 0 1px $c-nt-300;
$color-circle-selected-outer-border: 0 0 0 2px $c-pm-500;

.product-color {
  $itemSize: $color-circle-diameter-sm;

  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  flex: 0 0 $itemSize;
  max-width: $itemSize;
  -webkit-tap-highlight-color: transparent;

  @include mq($screen-md, min) {
    $itemSize: $color-circle-diameter-md;

    flex: 0 0 $itemSize;
    max-width: $itemSize;
  }

  // .product-color__circle
  &__circle {
    flex-shrink: 0;
    margin-top: $color-circle-border-size;
    box-shadow: $color-circle-outer-border;
    border: $color-circle-border-size solid $color-white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    height: $color-circle-diameter-sm;
    width: $color-circle-diameter-sm;

    @include mq($screen-md, min) {
      height: $color-circle-diameter-md;
      width: $color-circle-diameter-md;
    }
  }

  // .product-color__text
  &__text {
    color: $color-black;
    opacity: 0;
    font-size: $font-size-xs;
    margin-top: $u-200;
    white-space: pre;
    transition: 0.3s opacity;

    @include mq($screen-md, min) {
      font-size: $font-size-h6;
    }
  }

  // .product-color--selected
  &--selected {
    .product-color__circle {
      border: 2px solid $color-white;
      box-shadow: $color-circle-selected-outer-border;

      &:before {
        @include checkmark();
        left: 40%;
        color: $color-white;
      }

      // .product-color__circle--light
      &--light {
        &:before {
          color: $color-black;
          border-color: $color-black;
        }
      }
    }

    .product-color__text {
      opacity: 1;
    }
  }
}
</style>
