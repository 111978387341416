<template>
  <div class="split-navigation c-wrapper">
    <div class="split-navigation__header">
      <a href="/">
        <c-icon
          class="split-navigation__menu-icon"
          symbol-id="menu-logo"
        ></c-icon>
      </a>
    </div>

    <div class="split-navigation__container">
      <section class="split-navigation__option">
        <div class="split-navigation__symbols">
          <div v-if="legacyBadgeText" class="split-navigation__badge">
            {{ legacyBadgeText }}
          </div>

          <c-icon
            class="split-navigation__icon"
            :symbol-id="legacyIcon.markupId"
          ></c-icon>
        </div>

        <h2 class="split-navigation__heading">{{ legacyHeadline }}</h2>

        <div
          class="split-navigation__description"
          v-html="legacyDescription"
        ></div>

        <c-button class="c-btn--inline" @click="goTo(legacyButtonUrl)">
          {{ legacyButtonText }}
        </c-button>
      </section>

      <div class="split-navigation__divider"></div>

      <section class="split-navigation__option">
        <div class="split-navigation__symbols">
          <div v-if="betaBadgeText" class="split-navigation__badge">
            {{ betaBadgeText }}
          </div>

          <c-icon
            class="split-navigation__icon"
            :symbol-id="betaIcon.markupId"
          ></c-icon>
        </div>

        <h2 class="split-navigation__heading">{{ betaHeadline }}</h2>

        <div>
          <div
            class="split-navigation__description"
            v-html="betaDescription"
          ></div>

          <c-button class="c-btn--inline" @click="goTo(betaButtonUrl)">
            {{ betaButtonText }}
          </c-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { CButton } from 'olympus/components/';
import { CIcon } from '../c-icon/index.js';

export default {
  name: 'SplitNavigation',
  components: { CButton, CIcon },
  props: {
    legacyHeadline: {
      type: String,
      default: ''
    },
    legacyBadgeText: {
      type: String,
      default: ''
    },
    legacyDescription: {
      type: String,
      default: ''
    },
    legacyButtonUrl: {
      type: String,
      default: ''
    },
    legacyButtonText: {
      type: String,
      default: ''
    },
    legacyIcon: {
      type: Object,
      default: () => ({})
    },
    betaHeadline: {
      type: String,
      default: ''
    },
    betaBadgeText: {
      type: String,
      default: ''
    },
    betaDescription: {
      type: String,
      default: ''
    },
    betaButtonUrl: {
      type: String,
      default: ''
    },
    betaButtonText: {
      type: String,
      default: ''
    },
    betaIcon: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {};
  },

  methods: {
    goTo(url) {
      window.location.href = url;
    }
  }
};
</script>

<style lang="scss">
@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

.split-navigation {
  display: flex;
  height: 100%;
  padding: $u-300;
  flex-direction: column;
  // .split-navigation__menu-icon
  &__menu-icon {
    width: 100px;
    height: 46px;
    fill: $c-pm-600;

    @include from-tablet {
      width: 124px;
      height: 80px;
    }
  }
  // .split-navigation__container
  &__container {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: column;

    @include from-tablet {
      flex-direction: row;
      padding: unset;
    }
  }
  // .split-navigation__symbols
  &__symbols {
    display: flex;
    flex-direction: column;
    max-width: 384px;
    margin: 0 auto $u-150;
    flex-direction: column-reverse;
  }
  // .split-navigation__heading
  &__heading {
    text-align: left;
    font-size: $font-size-h2;
    font-family: $global-font-family-2;
    max-width: 384px;
    margin: 0 auto $u-150;

    @include from-tablet {
      margin: 0 auto $u-200;
    }
  }
  // .split-navigation__description
  &__description {
    text-align: left;
    font-size: $global-font-size-md;
    max-width: 384px;
    margin: 0 auto $u-300;

    @include from-tablet {
      margin: 0 auto $u-400;
    }
  }
  // .split-navigation__badge
  &__badge {
    background: $c-sp-teal-500;
    color: $color-white;
    width: fit-content;
    padding: 2px $u-250;
    border-radius: 3px;
    font-size: $global-font-size-md;
    margin: 0 auto $u-200 0;
    position: absolute;
  }
  // .split-navigation__icon
  &__icon {
    width: $u-900;
    height: $u-900;
    fill: $c-pm-600;
    margin: 0 auto $u-500 auto;
  }
  // .split-navigation__option
  &__option {
    text-align: left;
    max-width: 384px;
    margin: 0 auto $u-150;

    @include from-tablet {
      margin: 0 auto $u-200;
    }
  }
  // .split-navigation__divider
  &__divider {
    border-top: 1.5px solid $c-nt-300;
    padding: 0 0 $u-600;
    margin: $u-600 0 0;

    @include from-tablet {
      border-top: unset;
      height: 180px;
      border-right: 1.5px solid $c-nt-300;
      padding: 0 0 0 $u-300;
      margin: auto $u-300 auto 0;
    }
  }
}
</style>
