var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: "numberlist" + _vm.keyToReRender, staticClass: "cm-numcon__list" },
    [
      _c(
        "section",
        {
          staticClass:
            "c-section c-section--default c-section--mb c-section--pt"
        },
        [
          _vm.numberConfigVM.headline
            ? _c(
                "h2",
                {
                  staticClass: "c-section__headline c-typography-h4 norlys-only"
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.numberConfigVM.headline) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.numberConfigComplete, function(product, index) {
        return _c("number-config", {
          key: "complete" + index,
          attrs: { product: product }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.numberConfigNumber, function(product) {
        return _c("number-config", {
          key: product.code,
          attrs: {
            "config-mode": product.code === _vm.numberConfigActive,
            product: product
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }