<template>
  <CVASCard
    :vas="displayedVasVariant"
    :promoted="promoted"
    :promoted-image-url="promotedImageUrl"
    :read-more="readMore"
    :old-vas-price-description="oldVasPriceDescription"
    :close="close"
    :local-loading="localLoading"
    :networking="networking"
    :handle-add-click-event="handleAddClickEvent"
    :class-object="classObject"
    :added="added"
    :price-disclaimer-text="priceDisclaimerText"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { ACTIONS as BASKET_ACTIONS } from '../../store/basket';
import {
  providerKey as ToastProviderKey,
  eventToastKeys
} from '../../providers/toast-provider.js';
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import CVASCard from '../vas/c-vas-card.vue';
import { STATE_INTERACTION } from '../vas/vas-constants';

export default {
  name: 'CProductCardVas',

  components: {
    CVASCard
  },

  mixins: [ContentPropertyMixin],

  inject: [ToastProviderKey],

  props: {
    promoted: {
      type: Boolean,
      default: false
    },
    vasProduct: {
      type: Object,
      default: () => ({})
    },
    brand: {
      type: [String, null],
      default: null
    },
    groupId: {
      type: String,
      required: true
    },
    readMore: {
      type: String,
      default: ''
    },
    oldVasPriceDescription: {
      type: String,
      default: ''
    },
    close: {
      type: String,
      default: ''
    },
    localLoading: {
      type: Boolean,
      default: true
    },
    priceDisclaimerText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      networking: false
    };
  },

  computed: {
    classObject() {
      return {
        'c-vas-card--promotion': this.promoted,
        'c-vas-card--added': this.added,
        'c-vas-card--disabled': this.disabled
      };
    },

    disabled() {
      return (
        this.displayedVasVariant.state.interaction ===
        STATE_INTERACTION.DISABLED
      );
    },

    // TODO DKT-3127: consider reversing this prop: either rename to 'toBeRemoved' ,
    // or introduce a 'current' state prop = 'Added' that can be used instead of 'interaction'.
    added() {
      return (
        this.displayedVasVariant.state.interaction === STATE_INTERACTION.REMOVE
      );
    },

    toBeRemoved() {
      return (
        this.displayedVasVariant.state.interaction ===
        STATE_INTERACTION.NO_CHANGE
      );
    },

    toShowMultipleOverlay() {
      return (
        this.displayedVasVariant.state.interaction ===
        STATE_INTERACTION.OPEN_POPUP
      );
    },

    /**
     * A vas product can contain multiple variants but only one is displayed at a time, based on the state interaction of the variants and the product itself.
     *
     * Rules:
     *  1. Show promoted product or promoted variant (if it exists).
     *    - the promoted variant will be prioritized
     *    - a promoted vas product will be shown if there is:
     *      + no featured variant
     *      + no added variant (all have state.interaction is ADDED)
     *      + it contains > 1 variant
     *  2. Show product for
     *    - a vas product with multiple variants, with none added (all have state.interaction is ADDED)
     *    - (as disabled product) a non-promoted, multi-variant vas that a) has a variant promoted & b) has the promoted variant added.
     *  3. Show 1st variant for
     *    - a vas product with only one variant. It will always show the variant no matter the state interaction
     *    - if a product is promoted and has a single variant
     *  4. Show variant 'to remove' if present
     *    - a vas product with multiple variants will show the variant with the 'REMOVE' state interaction, if it exists.
     *    - even if the product is featured, the variant with 'REMOVE' interaction will be shown.
     */
    displayedVasVariant() {
      const isVasProductPromoted = this.vasProduct.isFeatured;
      const isMultiVariant = this.vasProduct.variants.length > 1;
      const featuredVariant = this.vasProduct.variants.find(v => v.isFeatured);
      const variantToRemove = this.vasProduct.variants.find(
        v => v.state.interaction === STATE_INTERACTION.REMOVE
      );

      if (
        !this.promoted &&
        featuredVariant &&
        featuredVariant === variantToRemove
      ) {
        return {
          ...this.vasProduct,
          state: {
            ...this.vasProduct.state,
            interaction: STATE_INTERACTION.DISABLED
          }
        };
      }

      if (
        (!this.promoted && variantToRemove) ||
        (this.promoted && isVasProductPromoted && variantToRemove)
      ) {
        return variantToRemove;
      }

      if (this.promoted) {
        if (isVasProductPromoted && !isMultiVariant) {
          return this.vasProduct.variants[0];
        }

        return featuredVariant || this.vasProduct;
      }

      if (isMultiVariant) {
        return this.vasProduct;
      }

      return this.vasProduct.variants[0];
    },

    /**
     * If the vas is promoted, find the promoted image url.
     * NB: the promoted image can also be placed on a variant.
     */
    promotedImageUrl() {
      if (this.promoted) {
        return (
          this.vasProduct.featuredImageUrl ||
          this.vasProduct.variants.find(v => v.isFeatured)?.featuredImageUrl
        );
      }

      return null;
    }
  },
  methods: {
    ...mapActions({
      addService: BASKET_ACTIONS.ADD_SERVICE,
      removeFromBasket: BASKET_ACTIONS.REMOVE_FROM_BASKET
    }),
    handleAddClickEvent() {
      if (this.toBeRemoved) {
        return;
      }

      if (this.toShowMultipleOverlay) {
        return this.$emit('show-multiple-overlay', {
          vasProduct: this.vasProduct
        });
      }

      this.networking = true;
      let promise = null;
      if (this.added) {
        promise = this.removeFromBasket({
          code: this.displayedVasVariant.code,
          groupId: this.groupId
        });
      } else {
        promise = this.addService({
          vasCode: this.displayedVasVariant.code,
          groupId: this.groupId
        });

        this.pushEecObjectToGtm();
      }

      this.emitState();
      promise
        .then(() => {
          this.networking = false;
          this.emitState();

          const toastData = this[ToastProviderKey].getToastData(
            this.added
              ? eventToastKeys.ITEM_REMOVED_TO_BASKET_SUCCESS
              : eventToastKeys.ITEM_ADDED_TO_BASKET_SUCCESS
          );
          this.$addToast(
            {
              title: toastData.title,
              paragraph: toastData.subTitle,
              duration: 5000
            },
            title =>
              this.populateContentProperty(title, {
                product: this.displayedVasVariant.displayName
              })
          );
        })
        .catch(error => {
          this.networking = false;
          this.emitState();
          const toastData = this[ToastProviderKey].getToastData(
            eventToastKeys.ITEM_ADDED_TO_BASKET_ERROR
          );
          this.$addToast(
            {
              title: toastData.title,
              paragraph: toastData.subTitle,
              state: 'error',
              duration: 5000
            },
            title =>
              this.populateContentProperty(title, {
                product: this.displayedVasVariant.displayName
              })
          );

          console.error(error);
        });
    },

    emitState() {
      this.$emit('update', { networking: this.networking });
    },

    /**
     * Push Enhanced E-Commerce object to GTM data layer.
     */
    pushEecObjectToGtm() {
      try {
        const _brand = this.brand || this.vasProduct.brand;
        const product = {
          id: this.displayedVasVariant.code.toLowerCase(),
          name: this.displayedVasVariant.displayName.toLowerCase(),
          brand: _brand ? _brand.toLowerCase() : _brand,
          category: 'vas',
          price: this.displayedVasVariant.price.formattedValue,
          quantity: 1
        };

        const eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            ['add']: {
              products: [product]
            }
          },
          addToCartLocation: 'vas in checkout',
          event: 'addToCart'
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eecObject);
      } catch {
        // Silently fail adding to GTM
      }
    }
  }
};
</script>
