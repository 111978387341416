<template>
  <div class="shipping">
    <div class="shipping__inner-wrapper cm-c-checkout__module">
      <c-card no-padding no-shadow no-rounding>
        <h2 class="cm-c-checkout__subtitle">{{ content.block.headline }}</h2>

        <div
          class="cm-c-checkout__text shipping__description-text"
          v-html="showShippingBlockText"
        />

        <template v-for="(disclaimer, index) in mappedDisclaimers">
          <c-information-box
            v-if="disclaimer.visible && disclaimer.text"
            :key="index"
            class="shipping__disclaimers"
            :icon="disclaimer.icon"
            type="informative"
            animatein
          >
            <div v-html="disclaimer.text" />
          </c-information-box>
        </template>

        <div class="shipping__options">
          <config-group-item
            v-for="method in content.shippingMethods"
            :key="method.id"
            :title="method.displayName"
            :right-text="method.price && method.price.formattedValueCurrency"
            :right-text-centered="true"
            :icon="getShippingMethodIcon(method.name)"
            :prevent-expand="!expand"
            :expanded="method.name === selectedShippingMethod.name"
            @expanded="handleExpanded(method)"
          >
            <div v-if="showAddress && content.billingAddress">
              <p v-if="content.billingAddress.organization">
                {{ content.billingAddress.organization }}
              </p>

              <p>
                {{ content.billingAddress.line1 }}
                {{ content.billingAddress.houseNumber
                }}{{ content.billingAddress.houseLetter }}
                {{ content.billingAddress.floor }}
                {{ content.billingAddress.doorNumber }}
                {{ content.billingAddress.direction }}
              </p>

              <p>
                {{ content.billingAddress.postalCode }}
                {{ content.billingAddress.city }}
              </p>

              <div
                v-if="fetchingUserData && !isGlobalSpinnerOpen"
                class="cm-c-spinner cm-c-spinner--local"
              />

              <div v-else-if="method.allowOtherAddress">
                <c-row class="mt-2 mb-4">
                  <c-col>
                    <c-checkbox
                      id="show-address-form"
                      v-model="form.showAddressForm"
                      styled-checkbox
                      @event-id="toggleShowAddressForm"
                    >
                      {{ method.allowOtherAddressLabel }}
                    </c-checkbox>
                  </c-col>
                </c-row>

                <c-collapse
                  id="show-address-form"
                  :visible="form.showAddressForm"
                >
                  <h3 class="cm-c-form__header">
                    {{ method.deliveryAddressTitle }}
                  </h3>

                  <c-row class="cm-c-form__row">
                    <c-col class="cm-c-form__col" cols="6" sm="12">
                      <c-input-group
                        v-model="$v.form.firstName.$model"
                        name="firstName"
                        :place-holder-label="method.firstNameLabel"
                    /></c-col>

                    <c-col class="cm-c-form__col" cols="6" sm="12">
                      <c-input-group
                        v-model="$v.form.lastName.$model"
                        name="lastName"
                        :place-holder-label="method.lastNameLabel"
                    /></c-col>
                  </c-row>
                  <c-row class="cm-c-form__row cm-c-form__row--extra-spacing">
                    <c-col cols="12">
                      <c-input-group
                        v-model="form.company"
                        name="company"
                        :place-holder-label="method.companyLabel"
                      />
                    </c-col>
                  </c-row>

                  <c-row class="cm-c-form__row">
                    <c-col cols="12">
                      <address-search
                        :content="{
                          searchInputPlaceholder: method.addressSearchLabel,
                          searchString: prefilledAddress
                        }"
                        :is-block="false"
                        @select="setAddress"
                      ></address-search>
                    </c-col>
                  </c-row>

                  <c-row class="cm-c-form__row">
                    <c-col cols="12">
                      <c-input-group
                        v-model="$v.form.streetName.$model"
                        name="streetname"
                        :place-holder-label="method.addressStreetNameLabel"
                        disabled
                      />
                    </c-col>
                  </c-row>

                  <c-row class="cm-c-form__row">
                    <c-col class="cm-c-form__col" cols="4" sm="12">
                      <c-input-group
                        v-model="$v.form.houseNumber.$model"
                        name="houseNumber"
                        :place-holder-label="method.addressHouseNumberLabel"
                        disabled
                    /></c-col>

                    <c-col class="cm-c-form__col" cols="8" sm="12">
                      <c-input-group
                        v-model="form.houseLetter"
                        name="houseLetter"
                        :place-holder-label="method.addressHouseLetterLabel"
                        disabled
                    /></c-col>
                  </c-row>

                  <c-row class="cm-c-form__row">
                    <c-col class="cm-c-form__col" cols="4" sm="12">
                      <c-input-group
                        v-model="form.floor"
                        name="floor"
                        :place-holder-label="method.addressFloorLabel"
                        disabled
                    /></c-col>

                    <c-col class="cm-c-form__col" cols="8" sm="12">
                      <c-input-group
                        v-model="form.apartment"
                        name="Apartment"
                        :place-holder-label="method.addressApartmentLabel"
                        disabled
                    /></c-col>
                  </c-row>

                  <c-row class="cm-c-form__row">
                    <c-col class="cm-c-form__col" cols="4" sm="12">
                      <c-input-group
                        v-model="$v.form.postalCode.$model"
                        name="postalcode"
                        :place-holder-label="method.addressZipCodeLabel"
                        disabled
                    /></c-col>

                    <c-col class="cm-c-form__col" cols="8" sm="12">
                      <c-input-group
                        v-model="$v.form.city.$model"
                        name="city"
                        :place-holder-label="method.addressTownLabel"
                        disabled
                    /></c-col>
                  </c-row>
                </c-collapse>
              </div>
            </div>

            <div v-if="hasNoShippingRequired">
              {{ method.description }}
            </div>

            <div v-if="method.hasDescription && !hasNoShippingRequired">
              {{ method.description }} {{ selectedPhoneNumber }}
              <c-button
                v-if="method.modifyTelephoneNumber"
                variant="link"
                @click.stop="openNewNumberOverlay"
                >{{ method.modifyTelephoneNumberLabel }}</c-button
              >
            </div>

            <div
              v-if="
                selectedShippingMethod.name === pickupPointFreeMethod ||
                selectedShippingMethod.name === callmePickupPointMethod
              "
              class="shipping__pickup-points"
            >
              <store-autocomplete
                :placeholder="
                  content.block.searchInputPlaceholder
                    ? content.block.searchInputPlaceholder
                    : ''
                "
                :shop-data="mappedPickupPoints"
                :address-title="content.block.addressSearchSuggestionTitle"
                @select-shop="selectShop"
                @select-address="selectAddress"
              />

              <div class="shipping__geolocation">
                <c-button icon-button @click="searchByGeolocation">
                  <c-icon symbol-id="service-markeroutline" />
                  {{ content.block.useMyLocationLinkText }}
                </c-button>
              </div>

              <div
                v-if="pickupPoints.length || pickupPoint.networking"
                class="shipping__locations"
              >
                <c-form-radio-group
                  v-model="pickupPoint.selected"
                  name="selectedPickupPoint"
                  stacked
                  highlighted
                >
                  <c-form-radio
                    v-for="location in mappedPickupPoints"
                    :id="location.id"
                    :key="location.id"
                    :ref="`location-${location.id}`"
                    :value="location.id"
                  >
                    <div class="shipping__locations__pickuppoint">
                      <div class="shipping__locations__pickuppoint__text">
                        <div class="shipping__locations__pickuppoint__distance">
                          {{ location.distance }}
                        </div>
                        <strong>{{ location.storeName }}</strong
                        ><br />
                        <p>
                          {{ location.streetAndNumber }},
                          {{ location.postCode }}
                        </p>
                      </div>
                      <div
                        v-if="location.openingHours"
                        class="shipping__locations__pickuppoint__opening-hours"
                      >
                        <p
                          v-for="(hours, index) in location.openingHours"
                          :key="index"
                        >
                          {{ hours.firstDay
                          }}<span v-if="hours.lastDay"
                            >-{{ hours.lastDay }}</span
                          >: {{ hours.from }}-{{ hours.to }}
                        </p>
                      </div>
                    </div>
                  </c-form-radio>
                </c-form-radio-group>
              </div>
            </div>
          </config-group-item>
        </div>

        <c-modal
          id="changeContactNumberModal"
          ref="changeContactNumberModal"
          gray
          centered
        >
          <div>
            <c-heading
              slot="header"
              class="shipping__overlay__title text-center px-3"
              level="2"
              as="1"
            >
              {{ content.block.changeContactNumberHeadline }}
            </c-heading>
            <div class="shipping__overlay__body">
              <div v-html="content.block.changeContactNumberDescription"></div>
              <c-input
                v-model="newPhoneNumber"
                :place-holder-label="
                  content.block.changeContactNumberInputLabel
                "
                type="text"
              />
            </div>

            <div class="shipping__overlay__footer">
              <c-button
                type="button"
                :disabled="!ready"
                @click="closeNewNumberOverlay"
              >
                {{ content.block.changeContactNumberOkButtonText }}
              </c-button>
            </div>
          </div>
        </c-modal>
        <home-office-validation-modal
          :title="content.block.validationErrorModalText.heading"
          :error-message="content.block.validationErrorModalText.content"
          :visible="showCheckoutError"
        />
      </c-card>
    </div>
  </div>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import ConfigGroupItem from 'webshop/components/checkout/checkout-product-configuration/product-config/private/config-group-item/config-group-item.vue';
import StoreAutocomplete from 'webshop/components/store-autocomplete/store-autocomplete.vue';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import CInput from 'webshop/components/c-input/c-input.vue';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import HomeOfficeValidationModal from 'webshop/components/checkout/checkout-product-configuration/product-config/home-office/employee-config/home-office-validation-modal.vue';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
import {
  CRow,
  CCol,
  CCollapse,
  CInformationBox,
  CHeading,
  CButton,
  CFormRadio,
  CFormRadioGroup,
  CCheckbox
} from 'olympus/components';
import AddressSearch from 'webshop/components/address-search/address-search.vue';

import {
  required,
  requiredIf,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators/index.js';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

import geoLocationMixin from 'webshop/mixins/geo-location-mixin.js';
import addressMixin from 'webshop/mixins/address-mixin.js';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
  GETTERS as SHIPPING_GETTERS,
  ACTIONS as SHIPPING_ACTIONS,
  MUTATIONS as SHIPPING_MUTATIONS
} from 'webshop/store/shipping';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  ACTIONS as HOME_OFFICE_ACTIONS
} from 'webshop/store/home-office';
import { GETTERS as SHOP_GETTERS } from 'webshop/store/shop';
import checkoutStore, {
  MUTATIONS as CHECKOUT_MUTATIONS
} from 'webshop/components/checkout/store';

import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';

import {
  SHIPPING_METHODS,
  SHIPPING_METHOD_ICONS
} from 'webshop/enums/pickup-points';

const CHECKOUT_STORE_NAMESPACE = 'checkoutPage';

export default {
  name: 'CheckoutShipping',

  components: {
    CCard,
    ConfigGroupItem,
    CRow,
    CCol,
    CCollapse,
    CInformationBox,
    CHeading,
    StoreAutocomplete,
    CInputGroup,
    CInput,
    CButton,
    CIcon,
    CFormRadio,
    CFormRadioGroup,
    CCheckbox,
    AddressSearch,
    HomeOfficeValidationModal
  },

  mixins: [geoLocationMixin, addressMixin],

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedShippingMethod:
        this.content.shippingMethods?.find(
          s => s.id === this.content.selectedShippingMethod
        ) || {},
      selectedPhoneNumber: this.content.contactPhoneNumber,
      newPhoneNumber: this.content.contactPhoneNumber || '',
      searchAddress: '',
      autoCompleteValue: null,
      pickupPoint: {
        locations: [],
        selected: this.content.selectedPickupPoint?.deliveryLocationCode,
        networking: false,
        showList: false
      },
      searchResults: {
        type: undefined,
        data: []
      },
      form: {
        showAddressForm: false,
        firstName: '',
        lastName: '',
        company: '',
        address: '',
        addressId: null,
        streetName: '',
        houseNumber: '',
        houseLetter: '',
        floor: '',
        apartment: '',
        postalCode: '',
        city: ''
      },
      prefilledAddress: '',
      fetchingUserData: false,
      pickupPointFreeMethod: SHIPPING_METHODS.PICKUP_POINT_FREE,
      callmePickupPointMethod: SHIPPING_METHODS.CALLME_PICKUP_POINT
    };
  },

  validations: {
    newPhoneNumber: {
      required,
      numeric,
      dkPhoneNumber,
      maxLength: maxLength(8),
      minLength: minLength(8)
    },
    form: {
      firstName: {
        required: requiredIf(vm => vm.showAddressForm)
      },
      lastName: {
        required: requiredIf(vm => vm.showAddressForm)
      },
      addressId: {
        required: requiredIf(vm => vm.showAddressForm)
      },
      streetName: {
        required: requiredIf(vm => vm.showAddressForm)
      },
      houseNumber: {
        required: requiredIf(vm => vm.showAddressForm),
        numeric
      },
      postalCode: {
        required: requiredIf(vm => vm.showAddressForm),
        numeric
      },
      city: {
        required: requiredIf(vm => vm.showAddressForm)
      }
    }
  },

  computed: {
    ...mapGetters({
      pickupPoints: SHIPPING_GETTERS.GET_PICKUP_POINTS,
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA,
      orderData: HOME_OFFICE_GETTERS.GET_ORDER_DATA,
      showCheckoutError: HOME_OFFICE_GETTERS.GET_SHOW_CHECKOUT_ERROR,
      isGlobalSpinnerOpen: SHOP_GETTERS.IS_GLOBAL_SPINNER_OPEN
    }),

    mappedPickupPoints() {
      return this.pickupPoints.map(pickupPoint => {
        return {
          storeName: pickupPoint.attributes.name,
          displayName: `${pickupPoint.attributes.address}, ${pickupPoint.attributes.postalCode} ${pickupPoint.attributes.city}`,
          type: pickupPoint.type,
          id: pickupPoint.attributes.deliveryLocationCode,
          streetAndNumber: pickupPoint.attributes.address,
          postCode: pickupPoint.attributes.postalCode,
          city: pickupPoint.attributes.city,
          kvhx: '',
          distance: pickupPoint.attributes.distance,
          openingHours: this.formatOpeningHours(
            pickupPoint.attributes.openingHours
          )
        };
      });
    },

    expand() {
      switch (this.selectedShippingMethod.name) {
        case SHIPPING_METHODS.NORLYS_NEAREST_POST_OFFICE:
          return false;
        default:
          return true;
      }
    },

    showAddress() {
      switch (this.selectedShippingMethod.name) {
        case SHIPPING_METHODS.NORLYS_LEGAL_ADDRESS:
        case SHIPPING_METHODS.FOLKEREGISTERADRESSE:
        case SHIPPING_METHODS.LEGAL_ADDRESS:
        case SHIPPING_METHODS.LEGAL_ADDRESS_FREE:
        case SHIPPING_METHODS.HOME_OFFICE:
          return true;
        default:
          return false;
      }
    },

    hasNoShippingRequired() {
      return (
        this.selectedShippingMethod.name ===
        SHIPPING_METHODS.NO_SHIPPING_REQUIRED
      );
    },

    showShippingBlockText() {
      return !this.hasNoShippingRequired
        ? this.content.block.description
        : this.selectedShippingMethod?.tooltip;
    },

    pickupPointLocationCode() {
      return this.pickupPoint.selected;
    },

    mappedDisclaimers() {
      return this.content.disclaimers.map(d => {
        return {
          visible: d.visible,
          icon: d.icon,
          text: populateContentProperty(
            d.text || '',
            this.content.broadbandInstallationAddress
          )
        };
      });
    },

    anyMethodAllowsOtherAddress() {
      const shippingMethods = this.content.shippingMethods.filter(
        method => method.allowOtherAddress
      );

      if (!shippingMethods.length) {
        return false;
      }

      return shippingMethods;
    },

    formReady() {
      return (
        !this.form.showAddressForm ||
        (!this.$v.form.firstName.$invalid &&
          !this.$v.form.lastName.$invalid &&
          !this.$v.form.addressId.$invalid &&
          !this.$v.form.streetName.$invalid &&
          !this.$v.form.houseNumber.$invalid &&
          !this.$v.form.postalCode.$invalid &&
          !this.$v.form.city.$invalid)
      );
    },

    ready() {
      return !this.$v?.newPhoneNumber?.$invalid;
    },

    showAddressForm() {
      return this.form.showAddressForm;
    },

    firstName() {
      return this.form.firstName;
    },

    lastName() {
      return this.form.lastName;
    },

    company() {
      return this.form.company;
    },

    altAddressId() {
      return this.form.addressId;
    }
  },

  watch: {
    pickupPointLocationCode() {
      if (this.pickupPointLocationCode) {
        this.updateShipping();
      }
    },

    selectedPhoneNumber() {
      if (this.selectedPhoneNumber) {
        this.setPhoneNumber({
          phoneNumber: this.selectedPhoneNumber
        });
      }
    },

    selectedShippingMethod(val) {
      this.setComponentValidation(!!val);
      this.setFormData(this.form);
    },

    formReady(val) {
      this.setComponentValidation(val);
      this.setFormData(this.form);
    },

    showAddressForm(value) {
      if (!value) {
        this.resetForm();
      }

      this.setFormData(this.form);
    },

    firstName() {
      this.setFormData({ firstName: this.form.firstName });
    },

    lastName() {
      this.setFormData({ lastName: this.form.lastName });
    },

    company() {
      this.setFormData({ company: this.form.company });
    },

    altAddressId() {
      this.setFormData({ addressId: this.form.addressId });
    }
  },

  mounted() {
    this.setEndpoint(HOME_OFFICE_STEPS.CHECKOUT_SHIPPING);

    if (
      this.selectedShippingMethod.name === SHIPPING_METHODS.PICKUP_POINT_FREE ||
      this.selectedShippingMethod.name === SHIPPING_METHODS.CALLME_PICKUP_POINT
    ) {
      this.fetchPickupPointsData();
    }

    this.setComponentValidation(!!this.selectedShippingMethod?.id);

    if (this.anyMethodAllowsOtherAddress) {
      this.fetchingUserData = true;
      this.fetchEmployeeData().then(() => {
        this.fetchingUserData = false;
        this.populateEmployeeData();
        if (this.orderData.shippingInformation) {
          this.setAddress(this.orderData.shippingInformation.shippingAddress);
          this.setOwnerInfo(this.orderData.shippingInformation);
          this.toggleShowAddressForm();
        }
      });
    }

    // preselect shipping if shipping hasnt been selected before and theres only 1 method available
    if (
      !this.selectedShippingMethod.name &&
      this.content.shippingMethods.length === 1
    ) {
      this.handleExpanded(this.content.shippingMethods[0]);
    }

    this.setFormData(this.form);
  },

  beforeCreate() {
    if (!this.$store.hasModule(CHECKOUT_STORE_NAMESPACE)) {
      this.$store.registerModule(CHECKOUT_STORE_NAMESPACE, checkoutStore);
    }
  },

  beforeDestroy() {
    if (!this.$store.hasModule(CHECKOUT_STORE_NAMESPACE)) {
      this.$store.unregisterModule(CHECKOUT_STORE_NAMESPACE);
    }
  },

  methods: {
    ...mapMutations({
      setComponentValidation: CHECKOUT_MUTATIONS.SET_SHIPPING_VALIDATION,
      setFormData: CHECKOUT_MUTATIONS.SET_CHECKOUT_FORM_DATA,
      setEndpoint: CHECKOUT_MUTATIONS.SET_CHECKOUT_ENDPOINT,
      setShippingMethod: SHIPPING_MUTATIONS.SET_SHIPPING_METHOD
    }),

    ...mapActions({
      fetchPickupPoints: SHIPPING_ACTIONS.FETCH_PICKUP_POINTS,
      fetchPickupPointsByCoordinates:
        SHIPPING_ACTIONS.FETCH_PICKUP_POINTS_BY_COORDINATES,
      updateShippingMethod: SHIPPING_ACTIONS.UPDATE_SHIPPING_METHOD,
      setPhoneNumber: SHIPPING_ACTIONS.SET_PHONE_NUMBER,
      fetchEmployeeData: HOME_OFFICE_ACTIONS.FETCH_EMPLOYEE_DATA
    }),

    selectShop(id) {
      this.pickupPoint.selected = id;
      this.scrollToPickupPoint(id);
    },

    selectAddress(data) {
      this.fetchPickupPoints({
        street: data.street,
        houseNumber: data.streetNumber,
        postCode: data.postCode,
        includeOpeningHours: true
      });
    },

    updateShipping() {
      this.updateShippingMethod({
        blockId: this.content.blockId,
        shippingMethod: this.selectedShippingMethod.id,
        deliveryLocationCode: this.pickupPoint.selected,
        ignorePickupPoint: !this.pickupPointLocationCode
      });
    },

    scrollToPickupPoint(pickupPoint) {
      const refElement = this.$refs[`location-${pickupPoint}`];
      if (refElement) {
        refElement[0].$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    },

    toggleShowAddressForm() {
      this.form.showAddressForm = !this.form.showAddressForm;
    },

    openNewNumberOverlay() {
      this.$refs.changeContactNumberModal.show();
    },

    closeNewNumberOverlay() {
      this.selectedPhoneNumber = this.newPhoneNumber;
      this.$refs.changeContactNumberModal.hide();
    },

    handleExpanded(shippingMethod) {
      const previousShippingMethod = this.selectedShippingMethod;
      this.selectedShippingMethod = shippingMethod;
      this.pickupPoint.selected = null;

      this.setShippingMethod({
        blockId: this.content.blockId,
        shippingMethod: this.selectedShippingMethod.id,
        name: this.selectedShippingMethod.name,
        deliveryLocationCode: this.pickupPoint.selected,
        ignorePickupPoint: !this.pickupPointLocationCode
      });

      if (
        shippingMethod.name === SHIPPING_METHODS.PICKUP_POINT_FREE ||
        shippingMethod.name === SHIPPING_METHODS.CALLME_PICKUP_POINT
      ) {
        this.fetchPickupPointsData();
      }

      if (previousShippingMethod.id !== shippingMethod.id) {
        this.updateShipping();
      }
    },

    searchByGeolocation() {
      this.getUserLocation()
        .then(value => {
          this.fetchPickupPointsByCoordinates({
            x: value.coords.longitude,
            y: value.coords.latitude,
            includeOpeningHours: true
          });
        })
        .catch(error => {
          throw new Error(error);
        });
    },

    fetchPickupPointsData() {
      this.fetchPickupPoints({ includeOpeningHours: true }).then(() => {
        if (this.pickupPoint.selected) {
          this.scrollToPickupPoint(this.pickupPoint.selected);
          return;
        }
        if (this.pickupPoints[0]) {
          this.selectShop(this.pickupPoints[0].attributes.deliveryLocationCode);
        }
      });
    },

    getShippingMethodIcon(name) {
      switch (name) {
        case SHIPPING_METHODS.NORLYS_LEGAL_ADDRESS:
        case SHIPPING_METHODS.FOLKEREGISTERADRESSE:
        case SHIPPING_METHODS.LEGAL_ADDRESS_FREE:
        case SHIPPING_METHODS.LEGAL_ADDRESS:
        case SHIPPING_METHODS.HOME_OFFICE:
        case SHIPPING_METHODS.NO_SHIPPING_REQUIRED:
          return SHIPPING_METHOD_ICONS.ADDRESS;

        case SHIPPING_METHODS.CALLME_PICKUP_POINT:
        case SHIPPING_METHODS.PICKUP_POINT_FREE:
        case SHIPPING_METHODS.NEAREST_POST_OFFICE:
          return SHIPPING_METHOD_ICONS.PICKUP_POINT;

        default:
          return SHIPPING_METHOD_ICONS.SHOP;
      }
    },

    formatOpeningHours(week) {
      const weekDays = [];

      let currentTimeSpan = {};
      week.forEach(({ day, from, to }, index) => {
        if (!currentTimeSpan.firstDay) {
          currentTimeSpan = {
            firstDay: this.content.block.weekdays.find(d => d.day === day)
              .short,
            lastDay: null,
            from,
            to
          };
        } else if (currentTimeSpan.from !== from || currentTimeSpan.to !== to) {
          weekDays.push(currentTimeSpan);
          currentTimeSpan = {
            firstDay: this.content.block.weekdays.find(d => d.day === day)
              .short,
            lastDay: null,
            from,
            to
          };
        } else {
          currentTimeSpan.lastDay = this.content.block.weekdays.find(
            d => d.day === day
          ).short;
        }

        if (index === week.length - 1) {
          weekDays.push(currentTimeSpan);
          currentTimeSpan = {};
        }
      });

      return weekDays;
    },

    populateEmployeeData() {
      const addressData = this.employeeData.address.value;
      const address = this.unifiedAddressObject(addressData);

      // Set billingAddress in order to support existing component behavior
      this.content.billingAddress.firstName = this.employeeData.firstName.value; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.lastName = this.employeeData.lastName.value; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.line1 = address.streetName; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.houseNumber = address.houseNumber; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.houseLetter = address.houseLetter; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.postalCode = address.postalCode; // eslint-disable-line vue/no-mutating-props
      this.content.billingAddress.city = address.city; // eslint-disable-line vue/no-mutating-props
    },

    setAddress(addressData) {
      const address = this.unifiedAddressObject(addressData);
      this.form.address = this.createAddressStringFromData(addressData);
      this.form.addressId = address.addressId || addressData.altAddressId;
      this.form.streetName = address.streetName;
      this.form.houseNumber = address.houseNumber;
      this.form.houseLetter = address.houseLetter;
      this.form.floor = address.floor;
      this.form.apartment = address.door;
      this.form.postalCode = address.postalCode;
      this.form.city = address.city;

      this.$nextTick(() => {
        if (address.streetName) {
          this.prefilledAddress = this.createAddressStringFromData(address);
        }
      });
    },

    setOwnerInfo(shippingInformation) {
      this.form.firstName = shippingInformation.firstName;
      this.form.lastName = shippingInformation.lastName;
      this.form.company = shippingInformation.companyName;
    },

    resetForm() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.company = '';
      this.form.address = '';
      this.form.addressId = null;
      this.form.streetName = '';
      this.form.houseNumber = '';
      this.form.houseLetter = '';
      this.form.floor = '';
      this.form.apartment = '';
      this.form.postalCode = '';
      this.form.city = '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.shipping {
  position: relative;

  &__options {
    margin-bottom: $u-750;
  }

  &__disclaimer {
    margin-bottom: $u-400;
  }

  &__radio {
    &__title {
      display: flex;
      font-weight: 700;
      justify-content: space-between;
    }

    &__text {
      font-size: $font-size-h5;
    }
  }

  &__locations {
    margin-top: $u-300;
    max-height: 450px;
    overflow: auto;
    padding-right: $u-250;
    position: relative;

    .cm-spinner {
      min-height: 300px;
    }

    &__pickuppoint {
      color: $c-nt-600;
      font-size: $font-size-h5;
      line-height: 20px;

      &__text {
        margin-bottom: $u-100;

        strong {
          color: $c-nt-900;
          font-size: $font-size-h4;
        }

        p {
          margin-bottom: $u-100;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__distance {
        float: right;
      }

      &__opening-hours {
        width: 100%;

        p {
          margin-bottom: $u-100;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__overlay {
    &__title {
      font-family: $global-font-family-2;
    }

    &__body {
      margin: $u-400 auto $u-550;

      ::v-deep p {
        margin-bottom: $u-350;
      }
    }

    &__footer {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__geolocation {
    align-items: center;
    display: flex;
    margin-top: $u-450;
  }

  .c-btn--icon {
    height: auto;
    padding: $u-150 $u-300;
    // TODO: Icon button needs attention
    max-width: unset;
    max-height: unset;

    svg {
      fill: $color-white;
      height: 17px;
      margin-right: $u-200;
      width: 13px;
    }
  }

  .c-btn--link {
    height: 1rem;
    vertical-align: initial;
  }
}
</style>
