<template>
  <c-modal
    ref="substitutesOverlay"
    :visible="open"
    gray
    :show-close-button="false"
    @close="accept"
  >
    <h2 class="vas-consent__title">{{ contentLocal.overlayHeader }}</h2>
    <div
      class="vas-consent__description"
      v-html="contentLocal.overlayManchet"
    ></div>
    <c-vas-substitutes :content="content.substitutes" :vases="vases" />
    <c-button class="c-vas-consent-overlay__button" @click="close">
      {{ contentLocal.closeButton }}
    </c-button>
  </c-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { ACTIONS as APP_ACTIONS } from '../../store/shop/mutation-types.js';
import { ACTIONS as GROUP_ACTIONS } from '../../store/groups/index.js';
import { CModal, CButton } from 'olympus/components';
import CVasSubstitutes from './c-vas-substitutes.vue';

const DEFAULT_CONTENT = {
  overlayHeader: 'Du har fordele som ændrer pris',
  overlayManchet:
    '<p>Du har i forbindelse med dit nuværende abonnement adgang til fordele, som vil ændre pris med dit nye abonnement.</p><p>Du kan vælge at fortsætte dine fordele til den nye pris eller vælge at opsige dine fordele på fordelssiden.</p>',
  closeButton: 'Forstået'
};

export default {
  name: 'CVasConsentOverlay',

  components: {
    CModal,
    CVasSubstitutes,
    CButton
  },

  props: {
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },
    open: {
      type: Boolean,
      default: false
    },
    vases: {
      type: Array,
      default: () => []
    },
    groupId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      contentLocal: {
        ...DEFAULT_CONTENT,
        ...this.content
      }
    };
  },

  methods: {
    ...mapActions({
      wait: APP_ACTIONS.WAIT,
      acceptVasConsent: GROUP_ACTIONS.ACCEPT_VAS_CONSENT
    }),
    close() {
      /**
       * CModal does not support close delagate so for now
       * we close the modal and await the action. We might want
       * to change that.
       */
      this.$refs.substitutesOverlay.hide();
    },
    accept() {
      this.wait({
        promise: this.acceptVasConsent({ groupId: this.groupId }),
        throttle: 300
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.vas-consent {
  &__title {
    // TODO DKT-COMING SOON: Use variable once fonts in settings.global.scss have been updated.
    font-size: 24px;
    margin-bottom: $u-400;
  }

  &__description {
    margin-bottom: $u-550;

    ::v-deep p {
      margin-bottom: $u-350;
    }
  }
}

.c-btn {
  display: block;
  margin: 0 auto;
}
</style>
