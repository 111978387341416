export const TYPES = {
  PICKUP_POINT: 'pickupPoint',
  SHOP: 'shop',
  ADDRESS: 'address'
};

export const SHIPPING_METHODS = {
  NORLYS_LEGAL_ADDRESS: 'NorlysLegalAddress',
  LEGAL_ADDRESS_FREE: 'LegalAddressFree',
  PICKUP_POINT_FREE: 'PickupPointFree',
  CALLME_PICKUP_POINT: 'CallmePickupPoint',
  NORLYS_NEAREST_POST_OFFICE: 'NorlysNearestPostOffice',
  NEAREST_POST_OFFICE: 'NearestPostOffice',
  LEGAL_ADDRESS: 'LegalAddress',
  HOME_OFFICE: 'HomeOfficeInstallationAddress',
  NO_SHIPPING_REQUIRED: 'NoShippingRequired',
  FOLKEREGISTERADRESSE: 'Folkeregisteradresse'
};

export const SHIPPING_METHOD_ICONS = {
  PICKUP_POINT: 'service-box',
  SHOP: 'service-store',
  ADDRESS: 'service-homedelivery'
};
