<div
  class="cm-grid__col--xs-12"
>

  <div
    v-for="(relatedGroup, relatedGroupsIndex) in relatedGroups"
    :key="relatedGroupsIndex"
    class="shopping-cart-related-group"
  >
    <basket-group
      v-for="group in relatedGroup.groups"
      :key="group.id"
      :data-groupid="group.id"
      :group="group"
      class="shopping-cart-group"
      :content="content"
    >
    </basket-group>

    <template v-for="group in relatedGroup.groups">
      <template v-for="offering in group.offerings">
        <purchase-member
          v-if="offering.type === 'PurchaseMember'"
          :key="offering.code"
          :offering="offering"
          :group="group"
          :content="content"
        >
        </purchase-member>
      </template>
    </template>
  </div>

  <basket-coupon :content="content" :block-id="blockId"></basket-coupon>

  <shop-footer v-cloak v-if="!isEmpty">
    <div slot="columns" class="cm-grid">
      <div
        v-for="(column, index) in shopFooterColumns"
        :key="index"
        :class="index > 0 && 'has-divider'"
        class="cm-grid__col--xs-6 cm-grid__col--xl-4"
      >
        <div class="shop-footer__column">
          <p class="shop-footer__item-label">
            {{ column.label }}
          </p>

          <strong class="shop-footer__item-value">
            {{ column.value }}
          </strong>
        </div>
      </div>
    </div>

    <div
      slot="cta"
      ref="shopFooterCtaContainer"
      class="shopping-cart__next shop-footer__cta-container"
    >
      <button
        class="c-btn c-btn--wide c-btn--transactional"
        @click="onNextButtonClicked"
        v-if="nextPage"
      >
        <span class="c-btn__column">
          {{ nextPageButtonText }}
        </span>
      </button>

      <small v-if="basketPrices.data.minimum" class="shop-footer__minimum-price shop-footer__minimum-price--single">
        {{ basketPrices.data.minimum.label }}: {{ basketPrices.data.minimum.value }}
      </small>

      <small v-if="basketPrices.data.priceDisclaimerText">
        {{ basketPrices.data.priceDisclaimerText }}
      </small>
    </div>
  </shop-footer>

  <add-edc-overlay
    ref="addEdcOverlay"
    :content="content"
  />

  <add-hardware-overlay
    ref="addHardwareOverlay"
    :content="content">
	    <template slot="product-notify-form">
			<slot name="product-notify-form"></slot>
      </template>
  </add-hardware-overlay>

  <add-vas-overlay
    ref="addVasOverlay"
    :content="content"
    :vas-checkout-module-block-id="vasCheckoutModuleBlockId"
  />
  
  <financing-conflict-overlay
    v-if='checkoutFinancingValidation.isOverlayShown'
    :content="financingOverlayContent"
    :ref="checkoutFinancingValidation.MODAL_REF"
    @continue-shopping="checkoutFinancingValidation.hideFinancingConflictOverlay()" 
    @remove-financing="checkoutFinancingValidation.removeFinancing()" 
  />
</div>
