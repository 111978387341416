<template>
  <c-modal ref="handsetsModal" wide large @close="handleClose">
    <div slot="header">
      <button
        v-if="wizard.currentState === wizard.states.CUSTOMIZE"
        type="button"
        class="c-btn c-btn--as-link c-btn--back-link"
        @click="wizard.currentState = wizard.states.LISTING"
      >
        {{ content.hardwareWizardStepBackwardText }}
      </button>
      <header class="cm-c-product-headline">
        <h2 class="cm-c-product-headline--caption">
          {{ content.hardwareWizardHeading }}
        </h2>
        <p v-if="content.hardwareWizardSubHeading">
          {{ content.hardwareWizardSubHeading }}
        </p>
      </header>
    </div>
    <transition name="slide-up" mode="out-in">
      <div
        v-if="wizard.currentState == wizard.states.FETCHING"
        :key="wizard.states.FETCHING"
      >
        <div class="cm-c-spinner">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-else-if="wizard.currentState == wizard.states.LISTING"
        :key="wizard.states.LISTING"
        class="cm-grid"
      >
        <div
          v-for="(product, item) in wizard.products"
          :key="product.productId + item"
          class="
            cm-grid__col--xs-12
            cm-grid__col--sm-6
            cm-grid__col--lg-4
            cm-grid__col--xxl-3
          "
        >
          <product-card
            track-by="product.productId"
            :product="product"
            :select-label="content.hardwareWizardSelectPhoneText"
            :compare-label="content.hardwareWizardCompareText"
            :compare-disabled="true"
            :minimum-price-disabled="false"
            :subscription-text="product.subscriptionText"
            :product-position="item"
            :link="false"
            @select="handleHandsetSelected"
          ></product-card>
        </div>
      </div>
      <div
        v-else-if="wizard.currentState == wizard.states.CUSTOMIZE"
        :key="wizard.states.CUSTOMIZE"
      >
        <product-page
          v-if="wizard.product && wizard.product.code"
          :product-code="wizard.product.code"
          :product-brand="wizard.product.brand"
          :product-model="wizard.product.model"
          :product-name="wizard.product.displayName"
          :product-description="wizard.product.description"
          :product-equipment-type="wizard.product.itemEquipmentType"
          :product-description-link-text="wizard.product.descriptionLinkText"
          :content="wizard.product.content"
          :gallery-content="wizard.product.gallery"
          :restricted-ban-modal-content="wizard.product.restrictedBanModal"
          :default-variant="wizard.product.selectedVariant"
          :default-variant-attributes="wizard.product.variantAttributes"
          :marketing-data="wizard.product.marketingData"
          :specifications="wizard.product.groupedSpecifications"
          :existing-subscriptions="wizard.product.existingSubscriptions"
          :payment-code="String(installments)"
          :disable-installments="hasFinancedItems"
          external
          @submit="handleVariantSelected"
        >
          <template slot="product-notify-form">
            <slot name="product-notify-form"></slot>
          </template>
        </product-page>
        <div v-if="networking" class="cm-c-spinner">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </transition>
  </c-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ACTIONS as BASKET_ACTIONS,
  GETTERS as BASKET_GETTERS
} from '../../store/basket';
import {
  getOfferingsBySubscriptionId,
  getProductDetailsByProductId
} from '../../api/add-hardware-service.js';
import ProductCard from '../catalog/product-card/product-card.vue';
import ProductPage from '../product/product-page.vue';

import { ITEM_TYPES } from '../../enums/access-technology-types.js';

var PRODUCT_WIZARD_STATES = {
  NONE: 'NONE',
  FETCHING: 'FETCHING',
  LISTING: 'LISTING',
  CUSTOMIZE: 'CUSTOMIZE'
};

export default {
  name: 'AddHardwareOverlay',

  components: {
    ProductCard,
    ProductPage
  },

  props: {
    content: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      networking: false,
      discountCode: '',
      removeMasterModal: {
        productCode: '',
        groupId: ''
      },
      isInitialCartLoaded: false,
      wizard: {
        products: [],
        product: null,
        states: PRODUCT_WIZARD_STATES,
        currentState: PRODUCT_WIZARD_STATES.NONE,
        context: {
          subscriptionCode: '',
          relatedSubscriptionCode: '',
          groupId: ''
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      installments: BASKET_GETTERS.INSTALLMENTS,
      hasFinancedItems: BASKET_GETTERS.HAS_FINANCED_ITEMS
    }),

    selectedVariantAttributes() {
      return this.wizard.product.variants;
    }
  },

  mounted() {
    this.$root.$on('add-hardware-overlay', this.openHandsetsModal);
  },

  methods: {
    ...mapActions({
      addHandset: BASKET_ACTIONS.ADD_HANDSET
    }),

    openHandsetsModal(e) {
      const isSubscription = e.group.items.find(
        x => x.type === ITEM_TYPES.SUBSCRIPTION
      );
      const hasRelation = e.relations.find(rel => {
        return rel.type === ITEM_TYPES.SUBSCRIPTION;
      });

      if (!isSubscription || !hasRelation) {
        return;
      }
      this.$refs.handsetsModal.show();

      this.wizard.context.groupId = e.group.id;
      this.wizard.context.subscriptionCode = isSubscription.code;
      this.wizard.context.relatedSubscriptionCode = hasRelation.code;

      this.fetchHandsetsForSubscription(this.wizard.context.subscriptionCode);
    },
    fetchHandsetsForSubscription(subscriptionCode) {
      this.wizard.currentState = PRODUCT_WIZARD_STATES.FETCHING;

      // TODO DKT-1428: Add action to vuex store when basket store is ready
      getOfferingsBySubscriptionId(subscriptionCode).then(({ data }) => {
        this.wizard.currentState = PRODUCT_WIZARD_STATES.LISTING;
        this.wizard.products = data;
      });
    },
    handleHandsetSelected({ product }) {
      this.wizard.currentState = PRODUCT_WIZARD_STATES.FETCHING;

      // TODO DKT-1428: Add action to vuex store when basket store is ready
      getProductDetailsByProductId(product)
        .then(({ data }) => {
          this.wizard.product = data;
          this.wizard.currentState = PRODUCT_WIZARD_STATES.CUSTOMIZE;
        })
        .catch(() => {
          this.$refs.handsetsModal.hide();
        });
    },
    handleVariantSelected({ variantCode, paymentCode }) {
      this.networking = true;
      this.addHandset({
        handsetCode: variantCode,
        installments: paymentCode,
        subscriptionCode: this.wizard.context.subscriptionCode,
        groupId: this.wizard.context.groupId,
        continueWithoutLoyaltyVas: true
      }).then(() => {
        this.$refs.handsetsModal.hide();
      });
    },
    handleClose() {
      this.networking = false;
    }
  }
};
</script>

<style lang="scss"></style>
