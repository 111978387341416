var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.modifiers },
    [
      _vm.showNextButton
        ? _c(
            "c-button",
            {
              attrs: {
                type: "button",
                disabled: !_vm.isValidPage,
                variant: _vm.BUTTON_STYLES[_vm.content.nextButtonColor]
              },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm($event)
                }
              }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.content.nextButtonLabelText) + "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPrevButton
        ? _c(
            "c-button",
            {
              attrs: {
                href: _vm.content.previousButtonLink,
                variant: "secondary"
              }
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.content.previousButtonLabelText) + "\n  "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }