var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: _vm.transitionName, css: false },
      on: {
        "before-enter": _vm.beforeEnter,
        enter: _vm.enter,
        "after-enter": _vm.afterEnter,
        "enter-cancelled": _vm.enterCancelled,
        "before-leave": _vm.beforeLeave,
        leave: _vm.leave,
        "after-leave": _vm.afterLeave,
        "leave-cancelled": _vm.leaveCancelled
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }