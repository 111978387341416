import { post } from '../../api/api.js';

const MUTATIONS = {
  SET_CREDIT_CHECK_DETAILS: 'MUTATIONS/SET_CREDIT_CHECK_DETAILS'
};

export const ACTIONS = {
  PERFORM_CREDIT_CHECK: 'ACTIONS/PERFORM_CREDIT_CHECK',
  SET_CREDIT_CHECK_DETAILS: 'ACTIONS/SET_CREDIT_CHECK_DETAILS'
};

export const GETTERS = {
  GET_CREDIT_CHECK_DETAILS: 'GETTERS/GET_CREDIT_CHECK_DETAILS'
};

export default {
  state: {
    creditcheckdetails: {
      cpr: ''
    }
  },

  mutations: {
    [MUTATIONS.SET_CREDIT_CHECK_DETAILS](state, { data }) {
      state.creditcheckdetails = data;
    }
  },

  actions: {
    [ACTIONS.PERFORM_CREDIT_CHECK](
      { commit },
      { searchText, withNumberPorting, withHardware, withPaymentMethod }
    ) {
      return post(
        '/customers/creditcheck',
        {
          data: {
            id: null,
            type: 'CreditCheckRequest',
            attributes: {
              searchText,
              persistCreditCheckDetails: true,
              withNumberPorting,
              withHardwareCredit: withHardware,
              withPaymentMethod
            }
          }
        },
        {
          headers: {
            accept: 'application/vnd.api+json',
            'Content-Type': 'application/json'
          }
        }
      ).then(({ data }) => {
        commit(MUTATIONS.SET_CREDIT_CHECK_DETAILS, {
          data: data.data.attributes
        });
      });
    },

    [ACTIONS.SET_CREDIT_CHECK_DETAILS]({ commit }, { data }) {
      commit(MUTATIONS.SET_CREDIT_CHECK_DETAILS, { data });
    }
  },

  getters: {
    [GETTERS.GET_CREDIT_CHECK_DETAILS]: state => state.creditcheckdetails
  }
};
