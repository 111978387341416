/**
 * Mobile browsers cannot scroll through internal links to scroll targets that are placed in details-summary,
 * as they are hidden from the view. Thus, the browser does not know where to scroll to. This script has
 * the capability of identifying the scroll target and scrolling into it when the internal link is pressed.
 * The need for it emerged, as internal links did not work on all mobile devices for that specific case.
 */
export function focusDetailsHiddenScrollTarget() {
  const openAccordion = targetId => {
    const target = document.getElementById(targetId);
    if (!target) return;
    const details = target.closest('details');
    if (!details) return;
    const summary = details.querySelector('summary');
    if (!details.hasAttribute('open') && summary) {
      summary.click();
    }
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  const getHash = () =>
    location.hash && location.hash.length > 1
      ? location.hash.substring(1)
      : null;

  const hashChangedHandler = () => {
    const targetIdFromHash = getHash();
    if (targetIdFromHash) openAccordion(targetIdFromHash);
  };

  if (getHash()) hashChangedHandler();

  window.addEventListener('hashchange', hashChangedHandler);
}
