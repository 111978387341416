/**
 *
 * Helpers to interact with sessionStorage
 *
 */

export function safeWrite(key, value, storage = sessionStorage) {
  try {
    storage.setItem(key, JSON.stringify(value));
  } catch (exception) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('safeWrite failed', exception); // eslint-disable-line no-console
    }
  }
}

export function safeRead(key, defaultValue, storage = sessionStorage) {
  try {
    const value = JSON.parse(storage.getItem(key));
    return value !== undefined && value !== null ? value : defaultValue;
  } catch (exception) {
    if (process.env.NODE_ENV === 'development') {
      console.warn("safeRead failed. Returning 'defaultValue'", exception); // eslint-disable-line no-console
    }

    return defaultValue;
  }
}

export function safeDelete(key, storage = sessionStorage) {
  try {
    storage.removeItem(key);
  } catch (exception) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('safeDelete failed', exception); // eslint-disable-line no-console
    }
  }
}
