var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "resurs-banner__container" },
    [
      _c("c-icon", {
        staticClass: "resurs-banner__resurs-logo",
        attrs: { "symbol-id": "logo-resurs-bank" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "resurs-banner__header" }, [
        _c("span", { staticClass: "resurs-banner__title" }, [
          _vm._v("\n      " + _vm._s(_vm.content.header) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.content.elaborativeHeader
          ? _c("span", { staticClass: "resurs-banner__title--elaborative" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.content.elaborativeHeader) + "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "resurs-banner__description" }, [
        _c("div", {
          staticClass: "resurs-banner__content",
          domProps: { innerHTML: _vm._s(_vm.content.description) }
        })
      ]),
      _vm._v(" "),
      _vm.content.buttonText
        ? _c("div", { staticClass: "resurs-banner__button" }, [
            _c(
              "a",
              {
                staticClass: "c-btn c-btn--xs resurs-banner__link",
                attrs: { target: _vm.content.linkTarget, href: _vm.content.url }
              },
              [_vm._v("\n      " + _vm._s(_vm.content.buttonText) + "\n    ")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }