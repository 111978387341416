<template>
  <div>
    <c-row class="c-order-list__filter">
      <c-col md="3">
        <fieldset
          class="c-order-list__filter__group c-order-list__filter__group--date"
        >
          <date-picker
            v-cloak
            date-locale="da-DK"
            input-classes="cm-c-form-control cm-c-form-control--filled cm-js-material-input"
            :input-value="filter.fromDate"
            @setDate="setFromDate"
          >
            <label
              slot="label"
              class="cm-c-form-control__label datepicker__label--ie"
            >
              {{ content.listView.fromDateFilterPlaceholderText }}
            </label>
          </date-picker>
        </fieldset>
      </c-col>

      <c-col md="3">
        <fieldset
          class="c-order-list__filter__group c-order-list__filter__group--date"
        >
          <date-picker
            v-cloak
            date-locale="da-DK"
            input-classes="cm-c-form-control cm-c-form-control--filled cm-js-material-input"
            :input-value="filter.toDate"
            @setDate="setToDate"
          >
            <label
              slot="label"
              class="cm-c-form-control__label datepicker__label--ie"
            >
              {{ content.listView.toDateFilterPlaceholderText }}
            </label>
          </date-picker>
        </fieldset>
      </c-col>

      <c-col md="3">
        <fieldset
          class="
            c-order-list__filter__group c-order-list__filter__group--status
            cm-c-form-control-group
          "
        >
          <select
            id="processingStatus"
            v-model="filter.status"
            name="processingStatus"
            class="cm-c-form-control cm-c-form-select cm-js-material-input"
          >
            <option
              v-for="option in content.listView.statusOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.key }}
            </option>
          </select>
          <label for="processingStatus" class="cm-c-form-control__label">
            {{ content.listView.orderStatusFilterPlaceholderText }}
          </label>
          <span class="cm-c-form-select__arrow" />
        </fieldset>
      </c-col>
    </c-row>

    <div class="c-order-list__table-container">
      <c-table>
        <c-table-head>
          <c-tr>
            <c-th v-for="column in columns" :key="column.key">
              {{ column.label }}
            </c-th>
            <c-th />
          </c-tr>
        </c-table-head>

        <c-table-body>
          <c-tr v-if="isLoading">
            <c-td :col-span="columns.length">
              <div class="cm-c-spinner cm-c-spinner--local"></div>
            </c-td>
          </c-tr>
          <template v-else>
            <template v-if="filteredOrders.length > 0">
              <c-tr v-for="row in filteredOrders" :key="row.id">
                <c-td v-for="column in columns" :key="column.key">
                  <template v-if="column.render">
                    <span v-html="column.render(row[column.key])"></span>
                  </template>
                  <template v-else>
                    {{ row[column.key] }}
                  </template>
                </c-td>
                <c-td class="c-order-list__table-container__action-cell">
                  <template v-if="content.listView.showDetailsButton">
                    <button
                      class="c-btn c-btn--empty-secondary c-btn--xs"
                      @click="() => handleViewClick(row.orderId)"
                    >
                      {{ content.listView.orderDetailsPopupButtonText }}
                    </button>
                  </template>
                </c-td>
              </c-tr>
            </template>

            <template v-else>
              <c-tr>
                <c-td :col-span="columns.length">
                  {{ content.listView.emptyListText }}
                </c-td>
              </c-tr>
            </template>
          </template>
        </c-table-body>
      </c-table>
    </div>
    <c-modal
      :visible="showModal"
      :title="content.listView.errorMessageHeader"
      centered
    >
      {{ content.listView.errorMessageDescription }}
    </c-modal>
  </div>
</template>

<script>
import CTable from 'olympus/components/table/c-table.vue';
import {
  CTableHead,
  CTableBody,
  CTr
} from 'olympus/components/table/c-table.js';
import { CTh, CTd } from 'olympus/components/table/c-cell.js';
import CRow from 'olympus/components/layout/c-row.vue';
import CCol from 'olympus/components/layout/c-col.vue';
import DatePicker from '../../../shared/components/date-picker.vue';

let columns = [];

export default {
  name: 'OrderList',

  components: {
    CTable,
    CTableHead,
    CTableBody,
    CTr,
    CTh,
    CTd,
    CRow,
    CCol,
    DatePicker
  },

  props: {
    content: {
      type: Object,
      required: true
    },
    orders: {
      type: Array,
      required: true
    },
    handleViewClick: {
      type: Function,
      required: true
    },
    fetchOrders: {
      type: Function,
      required: true
    },
    getFromDate: {
      type: Function,
      required: true
    },
    getToDate: {
      type: Function,
      required: true
    },
    isFetching: {
      type: Boolean,
      required: true
    },
    isApiError: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      columns,
      filter: {
        fromDate: this.getFromDate(),
        toDate: this.getToDate(),
        status: this.content.listView.statusOptions[0].value
      },
      filteredOrders: [...this.orders],
      isLoading: this.isFetching,
      showModal: this.isApiError
    };
  },

  watch: {
    filter: {
      handler(filter) {
        this.handleFilterChange(filter);
      },
      deep: true
    },
    orders(value) {
      this.filteredOrders = value;
    },
    isFetching(value) {
      this.isLoading = value;
    },
    isApiError(value) {
      this.showModal = value;
    }
  },

  created() {
    this.columns = [
      {
        key: 'customer',
        label: this.content.listView.customerNameTableHeader,
        render: value => `<strong>${value.firstName} ${value.lastName}</strong>`
      },
      {
        key: 'contactNumber',
        label: this.content.listView.contactNumberTableHeader
      },
      {
        key: 'createdDate',
        label: this.content.listView.orderCreationDateTableHeader
      },
      {
        key: 'completedDate',
        label: this.content.listView.orderCompletionDateTableHeader
      },
      {
        key: 'processingStatus',
        label: this.content.listView.orderStatusTableHeader
      },
      {
        key: 'processingStatusDetail',
        label: this.content.listView.orderStatusDetailTableHeader
      }
    ];
    this.filter.status = this.content.listView.statusOptions[0].value;
  },

  methods: {
    handleFilterChange(filter) {
      this.fetchOrders(filter.fromDate, filter.toDate, filter.status);
    },
    setFromDate(date) {
      const [day, month, year] = date.split('/');
      this.filter.fromDate = `${year}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0'
      )}`;
    },
    setToDate(date) {
      const [day, month, year] = date.split('/');
      this.filter.toDate = `${year}-${month.padStart(2, '0')}-${day.padStart(
        2,
        '0'
      )}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'olympus/styles/media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-order-list {
  &__filter {
    margin-bottom: $u-400;

    &__group {
      &--status {
        margin: 0;
      }
      &--status,
      &--date {
        @include till-phablet {
          margin-top: $u-200;
        }
      }
    }
  }

  &__table-container {
    overflow: auto;

    table {
      width: 100%;
    }

    td,
    th {
      border-width: 1px 0 0 0;
    }

    &__action-cell {
      text-align: right;
    }
  }
}

.load-more {
  padding: $u-200;
  background: $color-white;
  text-align: center;
  cursor: pointer;
}
</style>
