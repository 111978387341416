<template>
  <div
    class="c-tvcard"
    :class="[
      modifierClass ? 'c-tvcard--' + modifierClass : '',
      onlyChannels ? 'c-tvcard--only-channels' : ''
    ]"
  >
    <div class="c-tvcard__inner">
      <div v-if="onlyChannels != 'true'" class="c-tvcard__main">
        <div class="c-subcard c-subcard--tv-package" :class="subcardClasses">
          <div v-if="showRibbon" class="c-subcard__ribbon-wrapper">
            <em v-if="ribbonText" class="c-subcard__ribbon">{{
              ribbonText
            }}</em>
          </div>

          <div class="c-subcard__inner">
            <div class="c-subcard__headline">
              {{ headline }}
            </div>
            <div class="c-subcard__name">
              {{ name }}
            </div>
            <div class="c-subcard__description">
              {{ description }}
            </div>
            <p>{{ channelDescription }}</p>
          </div>

          <div class="c-subcard__offer">
            <div class="c-subcard__offer-spec">
              <span class="c-subcard__offer-price">{{ price }}</span>
              <div class="c-subcard__offer-price-labels">
                <span class="c-subcard__offer-currency">
                  {{ priceLabel }}
                </span>
              </div>
              <div class="c-subcard__offer-description">
                {{ minPrice }}
              </div>
            </div>
            <a :href="url" class="c-subcard__url c-subcard__url--list">{{
              buttonText
            }}</a>
          </div>
        </div>
      </div>
      <div v-if="onlyCard != 'true'" class="c-tvcard__details">
        <div v-if="categories" class="c-tvcard__categories">
          <p>
            <slot name="checkmark-icon" />
            {{ categories }}
          </p>
        </div>
        <h3 v-if="channelsHeadline" class="c-tvcard__channels-headline">
          {{ channelsHeadline }}
        </h3>
        <ul
          v-if="channels.length > 0"
          class="c-tvcard__channels"
          :class="[channelsWide ? 'c-tvcard__channels--wide' : '']"
        >
          <li v-for="(channel, idx) in channels" :key="idx">
            <img :src="channel.item1" :alt="channel.item2" />
          </li>
          <li v-if="description" class="c-tvcard__channels--remaining">
            +{{ description.substring(0, 2) - channelsToDisplay }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TvCard',

  props: {
    showRibbon: {
      type: Boolean,
      default: false
    },
    ribbonText: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    channelDescription: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    priceLabel: {
      type: String,
      default: ''
    },
    minPrice: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    categories: {
      type: String,
      default: ''
    },
    channels: {
      type: Array,
      default: () => []
    },
    onlyChannels: {
      type: String,
      default: ''
    },
    channelsWide: {
      type: String,
      default: ''
    },
    channelsHeadline: {
      type: String,
      default: ''
    },
    onlyCard: {
      type: String,
      default: ''
    },
    cardWide: {
      type: String,
      default: ''
    },
    cardTall: {
      type: Boolean,
      default: false
    },
    cardSearchResult: {
      type: Boolean,
      default: false
    },
    modifierClass: {
      type: String,
      default: ''
    },
    channelsToDisplay: {
      type: Number,
      default: 5
    }
  },

  computed: {
    subcardClasses() {
      return {
        'c-subcard--tv-package-wide': this.cardWide,
        'c-subcard--list': this.cardWide,
        'c-subcard--tall': this.cardTall,
        'c-subcard--search-result': this.cardSearchResult
      };
    }
  },

  render(h) {
    return h();
  }
};
</script>
