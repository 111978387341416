var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "member-description-list" },
    _vm._l(_vm.items, function(item, idx) {
      return _c(
        "c-row",
        { key: idx, attrs: { tag: "li", justified: "", "no-gutters": "" } },
        [
          _c("div", { staticClass: "member-description-list__image" }, [
            _c("span", [_c("strong", [_vm._v(_vm._s(item.label))])]),
            _vm._v(" "),
            _c("div", { staticClass: "text-left" }, [
              _c("span", { staticClass: "member" }, [_vm._v(" ")]),
              idx > 0
                ? _c(
                    "span",
                    [
                      _c("span", { staticClass: "member-separator" }, [
                        _vm._v("+")
                      ]),
                      _vm._l(idx, function(i) {
                        return _c("span", { key: i, staticClass: "member" }, [
                          _vm._v(" ")
                        ])
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(item.formattedValueCurrency) + " per person")
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }