<template>
  <c-card
    no-padding
    no-shadow
    no-rounding
    class="
      cm-c-checkout__module cm-c-checkout__module--mb
      cm-c-total__section
      billing-address
    "
  >
    <h3 class="cm-c-checkout__subtitle">{{ content.header }}</h3>
    <c-row>
      <c-col>
        <strong>{{ content.paymentTypeLabel }}</strong>
      </c-col>
    </c-row>
    <c-row>
      <c-col sm="12" cols="6">{{ content.paymentMethodLabel }}</c-col>
      <c-col sm="12" cols="6" class="cm-text-right-md">
        <ul>
          <li>{{ data.recurringPaymentTypeName }}</li>
          <li
            v-if="
              data.recurringPaymentType === 'Pbs' &&
              data.pbsRegNumber &&
              data.pbsAccountNumber
            "
          >
            {{ `${data.pbsRegNumber} ${data.pbsAccountNumber}` }}
          </li>
        </ul>
      </c-col>
    </c-row>
    <br/>
    <template v-if="content.upfrontPaymentMethodLabel">
      <c-row>
        <c-col>
          <strong>{{ content.productHeadingLabel }}</strong>
        </c-col>
      </c-row>
      <c-row>
        <c-col sm="12" cols="6">{{ content.paymentMethodLabel }}</c-col>
        <c-col sm="12" cols="6" class="cm-text-right-md">
          <ul>
            <li>
              {{ `${content.cardPaymentIncludedLabel} ${content.upfrontPaymentMethodLabel}` }}
            </li>
            <li v-if="data.installmentPeriod && data.installmentPeriodUnit">
              {{ `${data.installmentPeriod} ${data.installmentPeriodUnit}` }}
            </li>
          </ul>
        </c-col>
      </c-row>
    </template>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import { CRow, CCol } from 'olympus/components';

export default {
  name: 'CheckoutConfirmationBilling',

  components: {
    CCard,
    CRow,
    CCol
  },

  props: {
    content: {
      type: Object,
      default: () => ({
        header: '',
        paymentTypeLabel: '',
        paymentMethodLabel: '',
        productHeadingLabel: '',
        cardPaymentIncludedLabel: '',
        upfrontPaymentMethodLabel: ''
      })
    },
    data: {
      type: Object,
      default: () => ({
        recurringPaymentTypeName: '',
        pbsAccountNumber: '',
        pbsRegNumber: '',
        recurringPaymentType: '',
        installmentPeriod: 0,
        installmentPeriodUnit: ''
      })
    }
  }
};
</script>
<style lang="scss" scoped />
