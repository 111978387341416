<template>
  <div class="checkout-payment-adyen-b2c">
    <div
      v-if="displayForm"
      class="
        cm-c-checkout__module
        cm-c-checkout__module--pt
        cm-c-checkout__module--mb
        cm-c-total__section
      "
    >
      <!-- TODO: Add use data from EPI -->
      <c-heading slot="header" level="3" as="3" class="mb-1">{{
        content.title
      }}</c-heading>
      <div v-html="getDescription()"></div>

      <div
        id="adyen-payment-form"
        class="
          checkout-payment-adyen-b2c__form
          mt-3
          checkout-creditcheck checkout-creditcheck--active
        "
      >
        <div class="checkout-creditcheck__info">
          <div class="checkout-creditcheck__info__left">
            <div class="checkout-creditcheck__image">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                  fill="#EB0000"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.75 23.5H24.25C24.4492 23.5 24.6402 23.4208 24.7809 23.2797L26.2809 21.7764C26.4212 21.6358 26.5 21.4453 26.5 21.2467V13.75V10.75C26.5 10.5511 26.421 10.3603 26.2803 10.2197L24.7803 8.71967C24.6397 8.57902 24.4489 8.5 24.25 8.5H7.75C7.55109 8.5 7.36032 8.57902 7.21967 8.71967L5.71967 10.2197C5.57902 10.3603 5.5 10.5511 5.5 10.75V13.75V21.25C5.5 21.4489 5.57902 21.6397 5.71967 21.7803L7.21967 23.2803C7.36032 23.421 7.55109 23.5 7.75 23.5ZM25 20.9373V14.5H7V20.9395L8.0605 22H23.9387L25 20.9373ZM7 13H25V11.062L23.938 10H8.06125L7 11.0613V13ZM19 17.4357L19.3107 17.125H21.5018L22 17.6232V19.0643L21.6893 19.375H19.3107L19 19.0643V17.4357ZM19.1553 15.625C18.857 15.625 18.5708 15.7435 18.3598 15.9545L17.8295 16.4848C17.6185 16.6958 17.5 16.982 17.5 17.2803V19.2197C17.5 19.518 17.6185 19.8042 17.8295 20.0152L18.3598 20.5455C18.5708 20.7565 18.857 20.875 19.1553 20.875H21.8447C22.143 20.875 22.4292 20.7565 22.6402 20.5455L23.1705 20.0152C23.3815 19.8042 23.5 19.518 23.5 19.2197V17.4678C23.5 17.1695 23.3815 16.8833 23.1705 16.6723L22.4527 15.9545C22.2417 15.7435 21.9555 15.625 21.6572 15.625H19.1553Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <span class="checkout-creditcheck__info__left__title">{{
              content.newCardTitle
            }}</span>
          </div>
        </div>

        <div class="c-row">
          <div class="c-col c-col--12 mb-2">
            <div class="checkout-payment-adyen-b2c__accepted-cards">
              <div
                v-for="acceptedCard in paymentOptionsData.supportedBrands"
                :key="acceptedCard"
              >
                <img
                  :src="`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${acceptedCard}.svg`"
                />
              </div>
            </div>
          </div>

          <div class="c-col c-col--12 mb-2">
            <div
              class="checkout-payment-adyen-b2c__input-container"
              :class="{
                active: activeFormElement === 'encryptedCardNumber',
                valid: validFormElements.encryptedCardNumber,
                invalid: paymentErrors.encryptedCardNumber
              }"
            >
              <label>{{ content.cardNumberLabel }}</label>
              <span class="card-input" data-cse="encryptedCardNumber"></span>
            </div>
            <div v-if="paymentErrors.encryptedCardNumber" class="error-message">
              {{ errorMessages[paymentErrors.encryptedCardNumber] }}
            </div>
          </div>

          <div class="c-col c-col--6 mb-2">
            <div
              class="checkout-payment-adyen-b2c__input-container"
              :class="{
                active: activeFormElement === 'encryptedExpiryDate',
                valid:
                  validFormElements.encryptedExpiryMonth &&
                  validFormElements.encryptedExpiryYear,
                invalid: paymentErrors.encryptedExpiryDate
              }"
            >
              <label>{{ content.cardExpiryDateLabel }}</label>
              <span class="card-input" data-cse="encryptedExpiryDate"></span>
            </div>
            <div v-if="paymentErrors.encryptedExpiryDate" class="error-message">
              {{ errorMessages[paymentErrors.encryptedExpiryDate] }}
            </div>
          </div>

          <div class="c-col c-col--6 mb-2">
            <div
              class="checkout-payment-adyen-b2c__input-container"
              :class="{
                active: activeFormElement === 'encryptedSecurityCode',
                valid: validFormElements.encryptedSecurityCode,
                invalid: paymentErrors.encryptedSecurityCode
              }"
            >
              <label>{{ content.cvcCodeLabel }}</label>
              <span class="card-input" data-cse="encryptedSecurityCode"></span>
            </div>
            <div
              v-if="paymentErrors.encryptedSecurityCode"
              class="error-message"
            >
              {{ errorMessages[paymentErrors.encryptedSecurityCode] }}
            </div>
            <popover-panel
              :tooltip-screen-reader-text="content.findCVCDescription"
              :popover-triggers="['hover', 'click']"
              base-classes="find-cvc-tooltip"
            >
              <div slot="popoverContent">
                <c-heading class="mb-1" :level="4">{{
                  content.findCVCTitle
                }}</c-heading>
                <div
                  v-if="content.findCVCDescription"
                  class="mb-2"
                  v-html="content.findCVCDescription"
                ></div>
                <img
                  v-if="content.findCVCImageUrl"
                  :src="content.findCVCImageUrl"
                />
              </div>
              <div class="input-help">
                {{ content.findCVCLabel }}
              </div>
            </popover-panel>
          </div>

          <div v-if="false" class="c-col c-col--12 mb-2">
            <label
              class="checkout-payment-adyen-b2c__checkbox-container"
              :class="{ selected: saveCard }"
            >
              <input v-model="saveCard" type="checkbox" />
              <div class="checkout-payment-adyen-b2c__checkbox-container-label">
                {{ content.saveCardTitle }}
                <small v-html="content.saveCardDescription"> </small>
              </div>
            </label>
          </div>

          <div
            v-if="paymentOptionsData.showConsent"
            class="c-col c-col--12 mb-2"
          >
            <label
              class="checkout-payment-adyen-b2c__checkbox-container"
              :class="{ selected: giveConsent }"
            >
              <input v-model="giveConsent" type="checkbox" />
              <div class="checkout-payment-adyen-b2c__checkbox-container-label">
                {{ content.consentLabel }}
                <small v-html="content.consentDescription"></small>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <c-modal
      ref="consent-payment-terms-modal"
      :title="content.consentModalTitle"
      centered
    >
      <div v-html="content.consentModalDescription"></div>
    </c-modal>

    <c-modal
      ref="payment-failed-modal"
      :title="paymentErrorMessages.title"
      centered
    >
      <div v-html="paymentErrorMessages.description"></div>
    </c-modal>

    <div
      v-if="
        (content.nextPageUrl || content.previousPageUrl) &&
        (content.nextButtonText || content.previousButtonText) &&
        displayForm
      "
      class="cm-c-checkout cm-c-checkout-summary__button-container"
      :class="buttonClass"
    >
      <c-button v-if="content.previousButtonText" @click="goToPreviousPage">
        {{ content.previousButtonText }}
      </c-button>

      <c-button
        v-if="content.nextButtonText"
        :disabled="!paymentFormIsValid"
        @click="createPayment"
      >
        {{ displayTotalValue(content.nextButtonText) }}
      </c-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AdyenCheckout from '@adyen/adyen-web';
import cHeading from '../../../../../../shared/components/c-heading/c-heading.vue';
import { CModal, CButton } from 'olympus/components';
import PopoverPanel from '../../popover-panel/popover-panel.vue';
import {
  createPayment,
  validatePaymentDetails,
  getPaymentOptions
} from '../../../api/payment-service';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin';

const FAILED_PAYMENT_VALIDATION_HASH = 'failed-payment-validation';

export default {
  name: 'CheckoutPaymentAdyenB2c',
  components: {
    cHeading,
    CModal,
    CButton,
    PopoverPanel
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      displayForm: false,
      savedCards: [],
      saveCard: false,
      assignSavedCard: false,
      activeFormElement: '',
      validFormElements: {
        encryptedCardNumber: false,
        encryptedExpiryMonth: false,
        encryptedExpiryYear: false,
        encryptedSecurityCode: false
      },
      rememberCard: false,
      giveConsent: false,
      paymentErrors: {},
      // TODO: Add proper stuff
      sum: '155',
      paymentErrorMessages: {
        title: '',
        description: ''
      },
      paymentOptionsData: {
        clientKey: '',
        orderTotal: '',
        environment: '',
        supportedBrands: [],
        showConsent: false
      },
      isValid: false,
      errorMessages: {
        'error.va.gen.01': 'Incomplete field',
        'error.va.gen.02': 'Field not valid',
        'error.va.sf-cc-num.01': 'Invalid card number',
        'error.va.sf-cc-num.02': `Typed card number doesn't match card brand`,
        'error.va.sf-cc-num.03': 'Unsupported card entered',
        'error.va.sf-cc-dat.01': 'Card too old',
        'error.va.sf-cc-dat.02': 'Date too far in the future'
      },
      ThreeDSecureData: {
        md: 'MD',
        paReq: 'PaReq',
        termUrl: 'TermUrl'
      }
    };
  },

  computed: {
    isAdditionalDetailsRequired() {
      return (
        this.$route.query.threeDSecureId && this.$route.query.redirectResult
      );
    },

    paymentFormIsValid() {
      return (
        this.isValid &&
        (!this.paymentOptionsData.showConsent || this.giveConsent)
      );
    },

    buttonClass() {
      const hasPreviousButton =
        this.content.previousButtonText && this.content.previousPageUrl;
      return hasPreviousButton
        ? ''
        : 'cm-c-checkout-summary__button-container--centered';
    }
  },

  mounted() {
    const returningFromThreeDSecure =
      this.$route.query.threeDSecureId && this.$route.query.redirectResult;
    let self = this;
    this.giveConsent = false;

    if (returningFromThreeDSecure) {
      this.validatePayment()
        .then(response => {
          this.goToNextPage(response.orderGroupId);
        })
        .catch(error => {
          this.displayError(error[0]);
          this.displayForm = true;
          // Adding hash to URL in order to prevent user from going back to 3DSecure
          window.location.hash = FAILED_PAYMENT_VALIDATION_HASH;
        });
    } else {
      this.getPaymentOptions().then(response => {
        this.paymentOptionsData = response.data.data.attributes;
        this.displayForm = true;
        if (document.querySelector('.display-terms')) {
          document
            .querySelector('.display-terms')
            .addEventListener('click', e => {
              e.preventDefault();
              this.displayTermsAndCondition();
            });
        }
        this.$nextTick(() => {
          self.initPayment();
        });
      });
    }

    // Prevent the user from going back to the 3DSecure page
    window.addEventListener('hashchange', event => {
      if (event.oldURL.includes(FAILED_PAYMENT_VALIDATION_HASH)) {
        window.location.href = this.content.previousPageUrl;
      }
    });
  },

  methods: {
    ...mapMutations(['SHOW_SPINNER', 'HIDE_SPINNER']),

    goToNextPage(orderNumber) {
      window.location.href = `${this.content.nextPageUrl}?orderNumber=${orderNumber}&oref=${this.content.orderReference}`;
    },

    goToPreviousPage() {
      window.location.href = `${this.content.previousPageUrl}`;
    },

    initPayment() {
      this.giveConsent = false;
      const cardBrands =
        this.selectedOption !== 'newcard'
          ? [
              this.savedCards.find(card => card.id === this.selectedOption)
                ?.brand
            ]
          : this.paymentOptionsData.supportedBrands;
      const paymentMethodsResponseConfig = {
        paymentMethods: [
          {
            brands: cardBrands,
            type: 'scheme',
            // HACK: Styling bellow "hides"(changes actual placeholder to be transparent) Adyen input placeholders
            // in order to display input placeholder/label animation with the rest of the project
            styles: {
              base: {
                color: '#001b2b',
                fontSize: '16px',
                fontWeight: '400'
              },
              placeholder: {
                color: '#90a2bd',
                fontWeight: '200'
              },
              error: {
                color: '#001b2b'
              }
            }
          }
        ]
      };

      const configuration = {
        // TODO: From backend
        environment: this.paymentOptionsData.environment,
        paymentMethodsResponse: paymentMethodsResponseConfig,
        clientKey: this.paymentOptionsData.clientKey,
        locale: 'da-DK',
        onChange: state => {
          this.isValid = state.isValid;
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: 'Credit or debit card',
            brand: 'mc'
          }
        }
      };

      const checkout = new AdyenCheckout(configuration);
      checkout
        .create('securedfields', {
          // Optional configuration
          type: 'card',
          brands: cardBrands,
          styles: {
            error: {
              // TODO: If selected option is saved card, only send that brand
              color: 'red'
            },
            validated: {
              color: 'green'
            },
            placeholder: {
              color: '#d8d8d8'
            }
          },
          // Only for Web Components before 4.0.0.
          // For Web Components 4.0.0 and above, configure aria-label attributes in translation files
          ariaLabels: {
            lang: 'en-GB',
            encryptedCardNumber: {
              label: 'Credit or debit card number field',
              iframeTitle: 'Iframe for secured card number',
              error:
                'Message that gets read out when the field is in the error state'
            }
          },

          // Events
          // onChange: function () {},
          onChange: state => {
            const paymentMethod = state.data.paymentMethod;
            this.saveCard = state.data.storePaymentMethod;
            this.encryptedCardNumber = paymentMethod.encryptedCardNumber;
            this.encryptedExpiryMonth = paymentMethod.encryptedExpiryMonth;
            this.encryptedExpiryYear = paymentMethod.encryptedExpiryYear;
            this.encryptedSecurityCode = paymentMethod.encryptedSecurityCode;
            this.validFormElements = state.valid;
            this.paymentErrors = state.errors;
            this.isValid = state.isValid;
          },
          onFocus: e => {
            if (e.focus) {
              this.activeFormElement = e.fieldType;
            } else {
              this.activeFormElement = '';
            }
          }
        })
        .mount('#adyen-payment-form');
    },

    async createPayment() {
      let paymentMethodId = '';
      if (this.selectedOption !== 'newcard') {
        paymentMethodId = this.selectedOption;
      }

      const {
        saveCard,
        encryptedCardNumber,
        encryptedExpiryMonth,
        encryptedExpiryYear,
        encryptedSecurityCode
      } = this;

      const request = {
        saveCard,
        encryptedCardNumber,
        encryptedExpiryMonth,
        encryptedExpiryYear,
        encryptedSecurityCode,
        consentGiven: this.giveConsent,
        returnUrl: window.location.href,
        cardDetailsReference: paymentMethodId
      };
      this.SHOW_SPINNER();
      createPayment(request)
        .then(response => {
          if (!response.data.data.attributes.action) {
            this.goToNextPage(response.data.data.attributes.orderGroupId);
          } else {
            this.submitPaymentForm(response.data.data.attributes);
          }
        })
        .catch(error => {
          this.displayError(error[0]);
        })
        .finally(() => {
          this.HIDE_SPINNER();
        });
    },

    validatePayment() {
      this.SHOW_SPINNER();
      const threeDSecureId = this.$route.query.threeDSecureId;
      const redirectResult = this.$route.query.redirectResult;
      const saveCard = this.saveCard;

      return new Promise((resolve, reject) => {
        validatePaymentDetails({ threeDSecureId, redirectResult, saveCard })
          .then(response => {
            return resolve({
              paymentId: response.data.data.attributes.paymentId,
              status: response.data.data.attributes.status,
              orderGroupId: response.data.data.attributes.orderGroupId
            });
          })
          .catch(error => {
            return reject(error);
          })
          .finally(() => {
            this.HIDE_SPINNER();
          });
      });
    },

    getPaymentOptions() {
      this.SHOW_SPINNER();

      return new Promise((resolve, reject) => {
        getPaymentOptions()
          .then(response => {
            return resolve(response);
          })
          .catch(error => {
            return reject(error);
          })
          .finally(() => {
            this.HIDE_SPINNER();
          });
      });
    },

    submitPaymentForm(payload) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = payload.action.url;

      Object.keys(payload.action.data).forEach(name => {
        const value = payload.action.data[name];
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = this.ThreeDSecureData[name];
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      this.$nextTick(() => {
        form.submit();
      });
    },

    displayTermsAndCondition() {
      this.$refs['consent-payment-terms-modal'].show();
    },

    displayTotalValue(value) {
      return populateContentProperty(value, {
        sum: this.paymentOptionsData.orderTotal
      });
    },

    displayError(error) {
      if (
        window.__APP__.checkoutErrorMap &&
        window.__APP__.checkoutErrorMap[error.code]
      ) {
        this.paymentErrorMessages = window.__APP__.checkoutErrorMap[error.code];
        this.$refs['payment-failed-modal'].show();
      }
    },

    getDescription() {
      return this.content[this.paymentOptionsData.descriptionToUse];
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
@import 'olympus/styles/breakpoints.scss';

.checkout-payment-adyen-b2c {
  .cm-c-checkout__module {
  }
  &__form {
    .c-row {
      padding: 0 $u-300;
      @include mq($breakpoint-desktop, min) {
        padding: 0 $u-800;
      }
    }
  }

  &__checkbox-container {
    outline: $u-100 solid $c-nt-300;
    outline-offset: 0;
    padding: $u-300 $u-250;
    border-radius: $u-150;
    display: flex;
    gap: $u-250;
    &.selected {
      outline-color: $color-black;
      outline-width: 2px;
      outline-offset: -$u-100;
      input[type='checkbox'] {
        accent-color: $color-black;
      }
    }
    input[type='checkbox'] {
      width: $u-300;
      height: $u-300;
      border: $u-100 solid $c-nt-300;
    }
    &-label {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $u-200;
      small {
        font-size: $font-size-h6;
        color: $c-nt-700;
        .clickable {
          cursor: pointer;
          color: $c-pm-500;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__input-container {
    outline: $u-100 solid $c-nt-300;
    outline-offset: 0;
    padding: $u-200 0.75rem;
    &.valid,
    &.invalid,
    &.active {
      outline-width: 2px;
      outline-offset: -$u-100;
    }
    &.valid {
      outline-color: $color-black;
    }
    &.invalid {
      outline-color: $c-pm-500;
    }
    &.active {
      outline-color: $c-sp-teal-500;
      label {
        color: $c-sp-teal-500;
      }
    }
    .card-input {
      display: block;
      height: $u-300;
    }
    label {
      font-size: $font-size-h6;
      color: $c-nt-800;
    }
  }
  .error-message {
    margin-top: $u-150;
    font-size: $font-size-h6;
  }

  .input-help {
    cursor: pointer;
    color: $c-pm-500;
    font-size: $font-size-h6;
    margin-top: $u-150;
    &:hover {
      text-decoration: underline;
    }
  }
  &__accepted-cards {
    display: flex;
    gap: $u-200;
    div {
      height: $u-300;
      border-radius: $u-150;
      overflow: hidden;
      border: $u-100 solid $c-nt-200;
      img {
        height: 100%;
        display: block;
      }
    }
  }
}
</style>
