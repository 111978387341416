import { get } from './api.js';

export const getOfferingsBySubscriptionId = subscriptionCode =>
  get(
    `/commerce/catalog/mobile-subscription-variants/${subscriptionCode}/offerings`
  );

export const getProductDetailsByProductId = productId =>
  get(`/commerce/catalog/products/${productId}`, {
    excludeContext: true
  });
