<template>
  <div>
    <c-card ref="fieldset" class="cm-numcon">
      <div v-if="numberConfigActiveProduct" class="cm-numcon__config">
        <div class="cm-numcon__product">
          <div class="cm-numcon__product__info">
            <img
              :src="numberConfigActiveProduct.img"
              class="cm-numcon__image"
              :alt="numberConfigActiveProduct.subscriptionName"
            />
            <section class="cm-numcon__product__text">
              <p
                v-if="numberConfigVM.subscriptionLabel"
                class="cm-numcon__product__pre-label"
              >
                {{ numberConfigVM.subscriptionLabel }}
              </p>

              <h2
                v-if="numberConfigActiveProduct.subscriptionName"
                class="
                  cm-numcon__product__header
                  cm-numcon__product__header--specific
                "
              >
                {{ numberConfigActiveProduct.subscriptionName }}
              </h2>
              <div
                v-if="numberConfigActiveProduct.handsetName"
                class="cm-numcon__product__header"
              >
                {{ numberConfigActiveProduct.handsetName }}
              </div>
            </section>
          </div>
        </div>

        <div class="cm-numcon__options">
          <div
            class="cm-numcon__options__item cm-numcon__options__item--active"
          >
            <div
              class="cm-numcon__options__link cm-numcon__options__link--default"
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="menu-search" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{ numberConfigVM.newNumberOverviewHeadline }}</strong>
              </span>
            </div>

            <div class="cm-numcon__options__content">
              <c-row
                v-if="
                  phoneNumberIsNotInList &&
                  configType === numberConfigActiveProduct.configType
                "
                class="cm-c-form__row"
              >
                <c-col
                  class="
                    cm-c-form__col
                    cm-numcon__product__number
                    cm-numcon__product__number--not-in-list
                  "
                >
                  <svg class="cm-icon--teal cm-icon--md">
                    <use
                      xlink:href="/src/apps/webshop/callme/images/icons.svg#icon-checkmark"
                    />
                  </svg>
                  {{ numberConfigText(configType) }}:
                  <strong>
                    {{ showNewNumber(numberConfigActiveProduct.phoneNumber) }}
                  </strong>
                </c-col>
              </c-row>

              <c-row
                v-if="hasNumberSearchError && errorDescriptionTitle"
                class="cm-c-form__row"
              >
                <c-col
                  class="
                    cm-c-form__col
                    cm-numcon__number-search-error-description
                  "
                >
                  <h4>{{ errorDescriptionTitle }}</h4>
                  <span>{{ errorDescription }}</span>
                </c-col>
              </c-row>

              <c-row
                v-if="numberConfigVM.enableNumberSearch"
                class="cm-c-form__row mb-4"
              >
                <c-col class="cm-c-form__col">
                  <c-input-group
                    ref="search"
                    v-model="searchPattern"
                    :input-error="numberSearchError"
                    :place-holder-label="
                      hasNumberSearchError
                        ? numberConfigVM.findNewNumberLabel
                        : numberConfigVM.searchNewNumberLabel
                    "
                    name="numbersearchpattern"
                    :maxlength="8"
                    @keydown="searchPatternKeydown($event)"
                  ></c-input-group>
                  <button
                    class="cm-c-form-control__button"
                    @click.prevent="fetchContent(true)"
                  >
                    <c-icon
                      class="cm-numcon__number-search-button--icon"
                      symbol-id="menu-search"
                    />
                  </button>
                </c-col>
              </c-row>

              <c-row
                v-if="!showSearchHelpTip && numberConfigVM.numberSearchHelpTip"
                class="cm-c-form__row"
              >
                <c-col class="cm-c-form__col">
                  <div class="cm-numcon__number-search-help">
                    <button
                      class="cm-numcon__number-search-help-button"
                      @click.prevent="toggleSearchHelp"
                    >
                      <c-icon
                        class="cm-numcon__number-search-help--icon"
                        symbol-id="contact-information"
                      />
                    </button>
                    <div
                      v-if="showSearchHelpTip"
                      v-html="numberConfigVM.numberSearchHelpTip"
                    ></div>
                  </div>
                </c-col>
              </c-row>

              <c-row
                v-if="showSearchHelpTip && numberConfigVM.numberSearchHelpTip"
                class="cm-c-form__row"
              >
                <c-col
                  class="cm-c-form__col cm-numcon__number-search-help-expanded"
                >
                  <button
                    class="cm-numcon__number-search-help-expanded--button"
                    @click.prevent="toggleSearchHelp"
                  >
                    <c-icon
                      class="cm-numcon__number-search-help-expanded--icon"
                      symbol-id="contact-information"
                    />
                  </button>
                  <div v-html="numberConfigVM.numberSearchHelpTip"></div>
                </c-col>
              </c-row>

              <c-row v-if="!hasNumberSearchError" class="cm-c-form__row">
                <c-col class="cm-c-form__col cm-numcon__product__number-group">
                  <div class="cm-numcon__numbers">
                    <c-form-radio-group v-model="phoneNumber" name="number-new">
                      <c-form-radio
                        v-for="n in numbersToShow"
                        :id="n.number"
                        :key="n.number"
                        :value="n.number"
                      >
                        <span v-html="n.formattedNumber"></span>
                      </c-form-radio>
                    </c-form-radio-group>
                  </div>
                  <button
                    v-if="checkNumberPool().length > numbersToShow.length"
                    type="button"
                    class="
                      cm-c-checkout__link cm-c-checkout__link--center
                      cm-numcon__see-more-numbers-link
                    "
                    @click="fetchContent(true)"
                  >
                    <c-icon
                      class="cm-numcon__see-more-numbers-link__icon"
                      symbol-id="general-shuffle"
                    />
                    {{ numberConfigVM.seeMoreNumbersLabel }}
                  </button>
                </c-col>
              </c-row>

              <number-subinfo
                v-if="isEnterprise"
                :key="`subinfo${keyToReRender}`"
                :active-product="$data"
              />

              <number-simcard-info
                v-if="
                  isEnterprise ||
                  (IS_THIRD_PARTY_RESELLER &&
                    numberConfigVM.showSimDeliveryOptions)
                "
                :key="`simcardinfo${keyToReRender}`"
                :active-product="$data"
              />

              <number-optional
                v-if="
                  numberConfigActiveProduct &&
                  !numberConfigActiveProduct.isSubscriptionMobileBroadBand
                "
                :key="`optional${keyToReRender}`"
                @hiddenNumber="setHiddenNumber"
                @unlistedNumber="setUnlistedNumber"
              />
            </div>
          </div>
        </div>
      </div>
    </c-card>

    <div
      v-if="numberConfigVM.consents && numberConfigVM.consents.length"
      class="cm-numcon__actions"
    >
      <number-consents
        :key="`consent${keyToReRender}`"
        :active-product="$data"
      />
    </div>

    <div class="cm-numcon__actions">
      <c-button to="/" variant="secondary">Tilbage</c-button>
      <c-button :disabled="!ready" @click="accept('newnum')">
        {{ numberConfigVM.acceptNumberButtonText }}
      </c-button>
    </div>
  </div>
</template>

<script>
import numberConfigMixin from './numberConfigMixin.js';
import numberMixin from '../../mixins/number-mixin.js';
import NumberOptional from './number-optional.vue';
import NumberSubinfo from './number-subinfo.vue';
import {
  CFormRadioGroup,
  CFormRadio,
  CRow,
  CCol,
  CButton
} from 'olympus/components';
import NumberSimcardInfo from './number-simcard-info.vue';
import NumberConsents from './number-consents.vue';
import CCard from 'webshop/components/c-card/c-card.vue';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';

import {
  required,
  numeric,
  minLength,
  maxLength,
  requiredIf
} from 'vuelidate/lib/validators/index.js';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

const initialState = () => ({
  searchPattern: '',
  usedSearchPattern: '',
  searchPatternNumberMustEndWith: '',
  hasNumberSearchError: false,
  numberSearchError: '',
  errorDescriptionTitle: '',
  errorDescription: '',
  phoneNumberIsNotInList: false,
  showSearchHelpTip: false,
  hasSearched: false
});

export default {
  name: 'NumberNew',

  components: {
    NumberOptional,
    NumberSubinfo,
    CFormRadioGroup,
    CFormRadio,
    CRow,
    CCol,
    NumberSimcardInfo,
    NumberConsents,
    CCard,
    CInputGroup,
    CButton
  },

  mixins: [numberConfigMixin, numberMixin],

  data() {
    return {
      ...initialState()
    };
  },

  computed: {
    ready() {
      return !this.$v.$invalid;
    }
  },

  validations: {
    phoneNumber: {
      required,
      numeric,
      dkPhoneNumber,
      minLength: minLength(8),
      maxLength: maxLength(8)
    },
    productObj: {
      acceptedConsents: {
        required: requiredIf(function () {
          return this.numberConfigVM.consents; // eslint-disable-line no-invalid-this
        })
      }
    }
  },

  watch: {
    $route(to) {
      if (
        !this.numberConfigVM.availableNumbers ||
        (this.numberConfigVM.availableNumbers.length === 0 &&
          to.name === 'number-new')
      ) {
        this.searchPattern = '';
        this.setErrorTexts('', '', '');
        Object.assign(this.$data, initialState());
        this.fetchContent();
      }
    },
    searchPattern(val) {
      this.searchPattern = [...val]
        .filter(c => this.searchPatternFilter(c))
        .join('');
    }
  },

  mounted() {
    if (this.configType) {
      this.fetchContent();
    }
  },

  methods: {
    searchPatternFilter(c) {
      return (c >= '0' && c <= '9') || c === '*';
    },

    searchPatternKeydown(e) {
      if (
        !(
          (
            this.searchPatternFilter(e.key) ||
            e.keyCode === 8 || // Backspace
            e.keyCode === 13 || // Enter
            e.keyCode === 35 || // End
            e.keyCode === 36 || // Home
            e.keyCode === 37 || // Left arrow
            e.keyCode === 39 || // Right arrow
            e.keyCode === 46
          ) // Delete
        )
      ) {
        e.preventDefault();
      }

      if (!e.target.validity.valid) {
        e.target.setCustomValidity('');
      }
    },

    fetchContent(reindex = false) {
      /**
       * Users not entering * probably want to search for the occurance of the pattern
       * they entered. Let's be nice and help them.
       */
      if (this.searchPattern) {
        this.hasSearched = true;
        this.usedSearchPattern =
          this.searchPattern.indexOf('*') === -1
            ? `*${this.searchPattern}*`
            : this.searchPattern;
      } else {
        this.usedSearchPattern = '';
      }

      this.hasNumberSearchError = false;

      this.SHOW_SPINNER();

      const noAvailableNumbersHandler = () => {
        this.phoneNumber = this.numberConfigVM.noNumberPoolNumber;
        this.SHOW_DIALOG({
          componentName: 'content-dialog',
          componentProps: {
            contentObject: {
              headline: '',
              description: this.numberConfigVM.errorMessageNumberPool,
              buttonLabel: this.numberConfigVM.errorMessageButtonText
            }
          }
        });
        this.setErrorTexts('', '', '');
      };

      if (!this.checkNumberPool().length || reindex) {
        fetch(
          `${
            this.numberConfigVM.apiSearchNumber + this.usedSearchPattern
          }&pagesize=${this.numberPoolSize}`,
          {
            keepalive: true
          }
        )
          .then(response =>
            response.ok
              ? response.json()
              : response
                  .json()
                  .then(error =>
                    Promise.reject({ status: response.status, data: error })
                  )
          )
          .then(r => {
            if (!r || !r.data.numbers || !r.data.numbers.length) {
              if (this.searchPattern) {
                this.setErrorTexts(
                  this.numberConfigVM.errorMessageNumberSearchNoMatchingNumbers,
                  this.numberConfigVM
                    .errorMessageNumberSearchNoMatchingNumbersTitle,
                  this.numberConfigVM
                    .errorMessageNumberSearchNoMatchingNumbersDescription
                );
              } else {
                noAvailableNumbersHandler();
              }
            } else {
              this.reInitData(r.data);
              this.HIDE_SPINNER();
            }
          })
          .catch(error => {
            if (error.status === 400) {
              this.setErrorTexts(
                this.numberConfigVM.errorMessageNumberSearchInvalidPattern,
                this.numberConfigVM.errorMessageNumberSearchInvalidPatternTitle,
                this.numberConfigVM
                  .errorMessageNumberSearchInvalidPatternDescription
              );
            } else {
              noAvailableNumbersHandler();
            }
          });
      } else {
        this.reInitData();
        this.HIDE_SPINNER();
      }
    },

    reInitData(data = false) {
      this.availableNumbers = data
        ? data.numbers.map(n => {
            return {
              number: this.$globals.formatNumber(n),
              formattedNumber: this.highlightSearch(
                this.$globals.formatNumber(n)
              )
            };
          })
        : this.checkNumberPool();
      this.updateNumberPool(Boolean(data));
    },

    showNewNumber(number) {
      /* If number equals "noNumberPoolNumber" (numberppol down or similar), show label, otherwise return number */
      return number === this.numberConfigVM.noNumberPoolNumber
        ? this.numberConfigVM.selectedNumberWhenNoNumberPool
        : this.formatNumberByDigits(number);
    },

    setErrorTexts(errorText, errorTitle, errorDescription) {
      this.numberSearchError = errorText;
      this.errorDescriptionTitle = errorTitle;
      this.errorDescription = errorDescription;
      this.hasNumberSearchError = true;

      this.HIDE_SPINNER();
    },

    toggleSearchHelp() {
      this.showSearchHelpTip = !this.showSearchHelpTip;
    },

    highlightSearch(numbersToHighlight) {
      try {
        if (!this.usedSearchPattern) {
          return this.formatNumberByDigits(numbersToHighlight);
        }

        const convertDigitToSpan = digit => {
          return `<span class="cm-numcon__numbers__digit">${digit}</span>`;
        };

        const wrapNumberDigitsInSpans = numberToWrap =>
          numberToWrap
            ? numberToWrap
                .toString()
                .split('')
                .map(number => convertDigitToSpan(number))
                .join('')
            : '';

        return wrapNumberDigitsInSpans(numbersToHighlight);
      } catch (error) {
        console.error(error);
      }
      return numbersToHighlight;
    }
  }
};
</script>
