var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    { ref: "edcModal", attrs: { id: "edcModal", gray: "", centered: "" } },
    [
      _c(
        "div",
        { staticClass: "extra-data-card-overlay" },
        [
          _c(
            "c-heading",
            {
              staticClass: "extra-data-card-overlay__title text-center",
              attrs: { slot: "header", level: "2", as: "1" },
              slot: "header"
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "extra-data-card-overlay__description",
            domProps: {
              innerHTML: _vm._s(_vm.content.extraDataCardDescriptionText)
            }
          }),
          _vm._v(" "),
          _vm.product.productImageUrl
            ? _c("product-card", {
                attrs: {
                  product: _vm.product,
                  "group-id": _vm.group.id,
                  "compare-disabled": true,
                  "add-to-basket-button-text":
                    _vm.content.extraDataCardButtonText,
                  "add-to-basket-button-enabled": true,
                  "add-to-basket-button-click-event": true,
                  meta: [
                    {
                      property: "Description",
                      textTemplate: ""
                    }
                  ]
                },
                on: { update: _vm.handleProductStateUpdate }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }