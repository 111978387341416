var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cm-numcon__product__number" }, [
      _c("svg", { staticClass: "cm-icon--teal cm-icon--md" }, [
        _c("use", {
          attrs: {
            "xlink:href":
              "/src/apps/webshop/callme/images/icons.svg#icon-checkmark"
          }
        })
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.moveLabel) + ":")]),
      _c("strong", [_vm._v(_vm._s(_vm.simData.phoneNumber))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "cm-grid cm-c-checkout__form-group cm-c-checkout-multinumbers"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "cm-grid__col--xs-12 cm-grid__col--md-8 cm-c-form-control-group"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.simData.sim,
                  expression: "simData.sim"
                }
              ],
              staticClass: "cm-c-form-control cm-js-material-input",
              attrs: {
                required: !_vm.simData.simUnavailable,
                disabled: _vm.simData.simUnavailable,
                pattern: "^\\d{11,20}$",
                type: "text"
              },
              domProps: { value: _vm.simData.sim },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.simData, "sim", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "cm-c-form-control__bar" }),
            _vm._v(" "),
            _c("label", { staticClass: "cm-c-form-control__label" }, [
              _vm._v(_vm._s(_vm.simLabel))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "field-validation-error", attrs: { hidden: "" } },
              [_vm._v(_vm._s(_vm.simInvalid))]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }