<template>
  <div class="c-sticky-footer" :class="{ 'c-sticky-footer--small': small }">
    <slot />
  </div>
</template>
<script>
/**
 * A dummy component used to apply a common sticky styling
 * that also applies mobile rules accross different browsers.
 *
 */
export default {
  name: 'c-sticky-footer',

  props: {
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>
