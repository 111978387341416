<template>
  <div class="c-compare__product-card">
    <h4 class="c-compare__product-card__product-brand c-typography-caption">
      {{ product.productBrand }}
    </h4>
    <h3 class="c-compare__product-card__product-title c-typography-h6">
      <a href="item.key.ProductUrl">{{
        product.productName.replace(product.productBrand, '')
      }}</a>
    </h3>
    <div class="c-compare__product-card__product-photo">
      <img :src="product.productImageUrl" :alt="product.productName" />
    </div>
    <div class="c-compare__product-card__product-price-block">
      <p class="c-compare__product-card--product-price">
        <span class="c-typography-h4">{{ productPricePerMonth }}</span>
        <small>{{ productPricePerMonthSuffix }}</small>
      </p>
      <p
        class="
          c-compare__product-card__text
          c-compare__product-card__text--sm
          c-compare__product-card__text--light
        "
      >
        {{ product.minimumPrice }}
      </p>
    </div>
    <c-button
      class="c-link-button c-link-button--small c-link-button--primary"
      :href="product.productUrl"
    >
      {{ buttonCaption }}
    </c-button>
  </div>
</template>

<script>
import { CButton } from 'olympus/components/index.js';
export default {
  name: 'CCompareProductCard',

  components: {
    CButton
  },

  props: {
    product: {
      type: Object,
      required: true
    },
    buttonCaption: {
      type: String,
      required: true
    }
  },

  computed: {
    productName() {
      return this.product.productName.replace(this.product.productBrand, '');
    },
    productPricePerMonth() {
      return this.product.monthlyPrice.split(' ')[0];
    },
    productPricePerMonthSuffix() {
      return this.product.monthlyPrice.replace(this.productPricePerMonth, '');
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare__product-card {
  padding: $u-500;
  background-color: $color-white;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: $box-shadow-default;
  flex: 1;
  max-width: 304px;
  text-align: center;
  @include mq($screen-md, max) {
    padding: $u-300;
  }

  &__product-price-block {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $u-300;
  }

  &__product-brand {
    margin-bottom: $u-150;
  }

  &__product-title {
    margin-bottom: $u-150;
  }

  &__product-photo {
    margin-bottom: $u-250;
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__text {
    &--light {
      color: $c-nt-600;
    }
    &--sm {
      font-size: $font-size-h6;
    }
  }
}
</style>
