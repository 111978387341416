var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.symbolId
    ? _c("svg", [
        _c("use", { attrs: { "xlink:href": _vm.icons(_vm.symbolId) } })
      ])
    : _c("i", { staticClass: "c-icon", class: _vm.modifiers })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }