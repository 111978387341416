import { VARIANTS } from '../constants/theme.js'

/**
 * Use this mixin with overrides to specify
 * component theming.
 * 
 */
export default {
  props: {
    /**
     * Determines the computed class properties for the
     * components modifiers. The css classes are globally
     * defined for the theme.
     * 
     */
    variant: {
      type: String,
      default: VARIANTS.DEFAULT,
      validator(val) {
        return Object.keys(VARIANTS).some(x => VARIANTS[x] === val);
      }
    }
  },

  /**
   * Use merge strategy to override this wherver used,
   * to produce multiple variants of object types.
   * 
   */
  data() {
    return {
      classVariantType: 'text',
    }
  },

  computed: {
    variantModifier() {
      return `t-${this.classVariantType}--${this.variant}`;
    },
    /**
     * Theme class that adds color css styling.
     * 
     */
    textColorClass() {
      return `t-text--${this.variant}`;
    },

    /**
     * Theme class that adds border-color css styling.
     * 
     */
    borderColorClass() {
      return `t-border--${this.variant}`;
    },
  }
};

/**
 * Use as injector of theme variant values.
 * 
 */
const providerKey = 'THEME_VARIANTS';

/**
 * Use as provider of theme variant values.
 * 
 */
const providerMixin = {
  provide: {
    [providerKey]: VARIANTS
  },
}

export { providerKey, providerMixin }