var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-vas-card-substitute",
      class: { "c-vas-card-substitute--current": _vm.current }
    },
    [
      _c("div", { staticClass: "c-vas-card-substitute__image" }, [
        _c("img", {
          attrs: { src: _vm.vas.imageUrl, alt: _vm.vas.displayName }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-vas-card-substitute__text" }, [
        _c("div", { staticClass: "c-vas-card-substitute__name" }, [
          _vm._v("\n      " + _vm._s(_vm.vas.displayName) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-vas-card-substitute__price" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.vas.price.formattedValueCurrency) + "\n    "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }