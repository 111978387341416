/*This is an example of how we can move some logic to separate files to unload the clutter in vue files
like broadband-offerings-page.vue We can either move in this direction to simplify vue files, 
or split the components into smaller components. But even smaller components can benefit from keeping the logic
separate - it's easier to test(we don't care about framework context), easier to read.
Need a discussion, then we can delete this comment and create
some stories*/

import ITEM_EQUIPMENT_TYPES from 'webshop/enums/item-equipment-types.js';
import INSTALLATION_STEP_TYPES from 'webshop/enums/instalation-step-types.js';

const getInstallationSteps = (content, product, offering, fees = []) => {
  if (!product) {
    return [];
  }
  const steps = content.reduce((arr, contentMap) => {
    if ((contentMap.productsFilter || []).includes(product.id)) {
      const filteredSteps = contentMap?.installationSteps?.filter(step => {
        const typeStrategy =
          {
            [INSTALLATION_STEP_TYPES.DEFAULT]: () => true,
            [INSTALLATION_STEP_TYPES.INSTALLATION_TECHNICIAN]: () =>
              !!fees.find(
                f =>
                  f.attributes.feeType ===
                    ITEM_EQUIPMENT_TYPES.INSTALLATIONFEE &&
                  offering.offeringFees.some(
                    fee =>
                      fee.code === f.id &&
                      fee.feeType === ITEM_EQUIPMENT_TYPES.TECHNICIANFEE
                  )
              ),
            [INSTALLATION_STEP_TYPES.DIY]: () =>
              !!fees.find(
                f =>
                  !f.attributes.feeType ===
                    ITEM_EQUIPMENT_TYPES.INSTALLATIONFEE ||
                  !offering.offeringFees.some(
                    fee =>
                      fee.code === f.id &&
                      fee.feeType === ITEM_EQUIPMENT_TYPES.TECHNICIANFEE
                  )
              )
          }[step.type] || (() => false);
        return typeStrategy();
      }) || [];
      
      return arr.concat(filteredSteps);
    }
    return arr;
  }, []);
  return steps;
};

export default {
  getInstallationSteps
};
