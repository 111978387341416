<template>
  <transition
    :name="transitionName"
    :css="false"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @enter-cancelled="enterCancelled"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    @leave-cancelled="leaveCancelled"
  >
    <slot />
  </transition>
</template>
<script>
import { SLIDE_DIR } from './a-directions.js';
export default {
  name: 'a-slide',

  props: {
    direction: {
      type: String,
      default: SLIDE_DIR.LEFT,
      validator(val) {
        return Object.keys(SLIDE_DIR).some(k => SLIDE_DIR[k] === val);
      }
    },
    duration: {
      type: Number,
      default: 300,
      validator(val) {
        return val >= 0;
      }
    },
    start: {
      type: String,
      default: '0'
    },
    offset: {
      type: String,
      default: '10px'
    },
    useOpacity: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    transitionName() {
      return `a-slide--${this.direction.toLowerCase()}`;
    }
  },

  methods: {
    beforeEnter(el) {
      el.style.transform = getComputedStyle(el).transform;
      el.style.transformOrigin = 'left';
      el.style.transition = `transform ${(this.duration % 60000) /
        1000}s, max-height ${(this.duration % 60000) / 1000}s, opacity ${(this
        .duration %
        60000) /
        1000}s`;

      const { sign, axel } = this.getAnimationParams();
      el.style.transform = `translate${axel}(${sign}${this.offset})`;
      if (this.useOpacity) {
        el.style.opacity = '0';
      }

      this.$emit('before-enter', el);
    },
    enter(el, done) {
      requestAnimationFrame(() => {
        const { axel } = this.getAnimationParams();
        el.style.transform = `translate${axel}(${this.start})`;
        if (this.useOpacity) {
          el.style.opacity = '1';
        }
        this.$emit('enter', el);
      });
      setTimeout(() => {
        done();
      }, this.duration);
    },
    afterEnter(el) {
      this.$emit('after-enter', el);
    },
    enterCancelled(el) {
      this.$emit('enter-cancelled', el);
    },
    beforeLeave(el) {
      this.$emit('before-leave', el);
    },
    leave(el, done) {
      const { sign, axel } = this.getAnimationParams();
      el.style.transform = `translate${axel}(${sign}${this.offset})`;
      if (this.useOpacity) {
        el.style.opacity = '0';
      }
      this.$emit('leave', el);
      setTimeout(() => {
        done();
      }, this.duration);
    },
    afterLeave(el) {
      this.$emit('after-leave', el);
    },
    leaveCancelled(el) {
      this.$emit('leave-cancelled', el);
    },
    getAnimationParams() {
      const sign =
        this.direction === SLIDE_DIR.DOWN || this.direction === SLIDE_DIR.LEFT
          ? '-'
          : '';

      const axel =
        this.direction === SLIDE_DIR.UP || this.direction === SLIDE_DIR.DOWN
          ? 'Y'
          : 'X';

      return {
        sign,
        axel
      };
    }
  }
};
</script>
