export const epiFormsSteps = {
  init() {
    if (typeof $$epiforms !== 'undefined') {
      $$epiforms(document).ready(function() {
        $$epiforms('.EPiServerForms').on(
          'formsNavigationNextStep formsNavigationPrevStep',
          function(e, p1, p2) {
            const form = $$epiforms(this).get(0);
            const steps = form.querySelectorAll('.epi-form-progress li');

            for (const step of steps) {
              if (step.getAttribute('data-step') == e.targetStep.index + 1) {
                step.classList.add('is-active');
              } else {
                step.classList.remove('is-active');
              }
            }
          }
        );
      });
    }
  }
};
