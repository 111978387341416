<template>
  <main>
    <checkout-confirm-details
      class="cm-c-form__row"
      :content="content"
      :customer-data="employeeData"
    />

    <checkout-summary-configuration
      class="cm-c-form__row"
      :content="configurationContent"
      :data="configurationData"
      home-office-flow
    />

    <checkout-delivery-address
      class="cm-c-form__row"
      :content="addressContent"
      :data="addressData"
      home-office-flow
    />

    <home-office-validation-modal
      :title="content.validationErrorModalText.heading"
      :error-message="content.validationErrorModalText.content"
      :visible="showCheckoutError"
    />
  </main>
</template>

<script>
import CheckoutConfirmDetails from 'webshop/components/checkout/checkout-confirm-details.vue';
import CheckoutSummaryConfiguration from 'webshop/components/checkout/checkout-summary-configuration/checkout-summary-configuration.vue';
import CheckoutDeliveryAddress from 'webshop/components/checkout/checkout-delivery-address.vue';
import HomeOfficeValidationModal from 'webshop/components/checkout/checkout-product-configuration/product-config/home-office/employee-config/home-office-validation-modal.vue';

import format from 'date-fns/format';
import addressMixin from 'webshop/mixins/address-mixin.js';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  ACTIONS as HOME_OFFICE_ACTIONS
} from 'webshop/store/home-office/index.js';
import { MUTATIONS as CHECKOUT_MUTATIONS } from 'webshop/components/checkout/store';

import { SHIPPING_TYPES } from 'webshop/enums/shipping-types.js';
import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin';

export default {
  name: 'EmployeeConfirmation',

  components: {
    CheckoutConfirmDetails,
    CheckoutSummaryConfiguration,
    CheckoutDeliveryAddress,
    HomeOfficeValidationModal
  },

  mixins: [addressMixin],

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      HOME_OFFICE_STEPS
    };
  },

  computed: {
    ...mapGetters({
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA,
      orderData: HOME_OFFICE_GETTERS.GET_ORDER_DATA,
      selectedOffering: HOME_OFFICE_GETTERS.GET_SELECTED_OFFERING,
      showCheckoutError: HOME_OFFICE_GETTERS.GET_SHOW_CHECKOUT_ERROR
    }),

    configurationContent() {
      return {
        ...this.content,
        productConfigurationHeading: this.content.internetSectionHeader,
        activationDateText: populateContentProperty(
          this.content.activationDateText,
          {
            activationDate: this.orderData?.activationDateAsap
              ? this.content.activationDateAsapText
              : this.formatDate(this.orderData?.activationDate)
          }
        ),
        powerOfAttorneyMailText: this.orderData?.ownerEmail
          ? populateContentProperty(this.content.powerOfAttorneyMailText, {
              email: this.orderData?.ownerEmail
            })
          : null,
        technicianRequiredDisclaimer:
          this.orderData?.technicianRequiredDisclaimer
      };
    },

    configurationData() {
      return {
        subscriptionItems: [
          {
            itemDisplayName: this.selectedOffering.displayName,
            itemSpeedInfoText: this.getFormattedSpeedText(),
            itemHelpText: this.selectedOffering.itemHelpText,
            installationAddress: this.employeeData?.address?.value,
            customerName: `${this.employeeData?.firstName?.value} ${this.employeeData?.lastName?.value}`,
            type: this.orderData.type
          }
        ]
      };
    },

    addressContent() {
      return {
        ...this.content,
        title: this.content.leveringSectionHeader,
        headlines: [
          {
            type: SHIPPING_TYPES.INSTALLATION_ADDRESS_HEADLINE,
            text: this.content.deliveryToText
          }
        ]
      };
    },

    addressData() {
      const shippingInformation = this.orderData.shippingInformation;
      return {
        items: [
          {
            headlineType: SHIPPING_TYPES.INSTALLATION_ADDRESS_HEADLINE,
            name: `${
              shippingInformation
                ? shippingInformation.firstName
                : this.employeeData.firstName?.value
            } ${
              shippingInformation
                ? shippingInformation.lastName
                : this.employeeData.lastName?.value
            }`,
            addressLine: this.createAddressStringFromData(
              shippingInformation
                ? shippingInformation.shippingAddress
                : this.employeeData.address?.value,
              false
            ),
            zipCity: `${
              shippingInformation
                ? shippingInformation.shippingAddress.postalCode
                : this.employeeData.address?.value.postalCode
            } ${
              shippingInformation
                ? shippingInformation.shippingAddress.city
                : this.employeeData.address?.value.city
            }`,
            organization: shippingInformation?.companyName
          }
        ]
      };
    }
  },

  mounted() {
    this.setEndpoint(HOME_OFFICE_STEPS.CHECKOUT_CONFIRMATION);
    this.fetchEmployeeData();
  },

  methods: {
    ...mapMutations({
      setEndpoint: CHECKOUT_MUTATIONS.SET_CHECKOUT_ENDPOINT
    }),

    ...mapActions({
      fetchEmployeeData: HOME_OFFICE_ACTIONS.FETCH_EMPLOYEE_DATA
    }),

    formatDate(date) {
      if (!date) {
        return '';
      }

      return format(new Date(date), 'dd/MM-yyyy');
    },

    getFormattedSpeedText() {
      return `${this.selectedOffering?.downloadSpeed?.value}/${this.selectedOffering?.uploadSpeed?.value} ${this.selectedOffering?.downloadSpeed?.unit}`;
    }
  }
};
</script>

<style lang="scss"></style>
