var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "c-layer-panel--slide" } }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.localExpanded,
            expression: "localExpanded"
          }
        ],
        staticClass: "c-layer-panel",
        class: _vm.contentModifiers
      },
      [
        _c(
          "header",
          [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("c-button", {
              attrs: { type: "close" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.collapse($event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "c-layer-panel-content" },
          [_vm._t("default")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }