<template>
  <div :key="`numberlist${keyToReRender}`" class="cm-numcon__list">
    <section class="c-section c-section--default c-section--mb c-section--pt">
      <h2
        v-if="numberConfigVM.headline"
        class="c-section__headline c-typography-h4 norlys-only"
      >
        {{ numberConfigVM.headline }}
      </h2>
    </section>

    <number-config
      v-for="(product, index) in numberConfigComplete"
      :key="`complete${index}`"
      :product="product"
    >
    </number-config>

    <number-config
      v-for="product in numberConfigNumber"
      :key="product.code"
      :config-mode="product.code === numberConfigActive"
      :product="product"
    >
    </number-config>
  </div>
</template>

<script>
import NumberConfig from './number-config.vue';

import { mapGetters } from 'vuex';
export default {
  name: 'NumberList',

  components: {
    NumberConfig
  },

  data() {
    return {
      keyToReRender: 0
    };
  },

  computed: {
    ...mapGetters([
      'numberConfigActive',
      'numberConfigComplete',
      'numberConfigNumber',
      'numberConfigVM'
    ])
  },
  mounted() {
    this.keyToReRender += 1;
  }
};
</script>
