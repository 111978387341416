<script>
import { mapGetters } from 'vuex';
import {
  CheckoutOrderSummary,
  CheckoutConsents,
  CheckoutTermsAndConditions,
  CheckoutOneConsent
} from './';
import PriceSummary from 'webshop/components/shopping-cart/price-summary.vue';

import confirmPageStore, {
  GETTERS as CONFIRM_PAGE_GETTERS
} from 'webshop/components/checkout/store';
import basketStepProvider from 'webshop/components/shopping-cart/basket-step-provider.js';

const CHECKOUT_CONFIRM_STORE_NAMESPACE = 'confirmPage';

export default {
  name: 'CheckoutConfirm',

  components: {
    CheckoutOrderSummary,
    CheckoutConsents,
    CheckoutTermsAndConditions,
    CheckoutOneConsent,
    PriceSummary
  },

  mixins: [basketStepProvider],

  props: {
    contentLocal: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters({
      isSignatureValid: CONFIRM_PAGE_GETTERS.IS_SIGNATURE_VALID,
      isValid: CONFIRM_PAGE_GETTERS.IS_VALID_PAGE
    })
  },

  beforeCreate() {
    this.$store.registerModule(
      CHECKOUT_CONFIRM_STORE_NAMESPACE,
      confirmPageStore
    );
  },

  beforeDestroy() {
    this.$store.unregisterModule(CHECKOUT_CONFIRM_STORE_NAMESPACE);
  }
};
</script>

<style lang="scss"></style>
