<template>
  <section class="product-promotion-block" :class="modifiers">
    <ribbon-banner
      v-show="showCounter"
      v-if="endTime"
      :theme="notExpired ? 'discount' : 'assent'"
      :flip-position="flipPosition"
      size="small"
    >
      <!-- @slot All content in this slot will be shown in the ribbon. -->
      <slot name="ribbon">
        <span v-if="notExpired" class="product-promotion-block__ribbon">
          <span class="product-promotion-block__ribbon__text">
            {{ expireDateTimeLabel }}
          </span>
          <countdown
            v-if="countdownData"
            :data="countdownData"
            @countdownEnded="onCountdownEnded"
          >
          </countdown>
        </span>
        <span v-else>
          {{ expiredLabel }}
        </span>
      </slot>
    </ribbon-banner>

    <figure v-if="imageUrl" class="product-promotion-block__image">
      <img :src="imageUrl" :alt="imageAlt" />
    </figure>

    <div class="product-promotion-block__content">
      <h3 class="product-promotion-block__heading">
        {{ heading }}
      </h3>
      <div class="product-promotion-block__description">
        <!-- @slot Content in the default slot will be used as description text. -->
        <slot></slot>
        <span v-html="priceText"></span>
      </div>
    </div>
  </section>
</template>

<script>
import RibbonBanner from '../ribbon-banner/ribbon-banner.vue';
import Countdown from './private/countdown/countdown.vue';

/**
 * A generic product discount box that can be used for all products.
 * The discount box can have an optional countdown.
 * None of the properties are mandatory, so it can also be used as an info box.
 *
 * Depends on a global vue-media-queries instance to correctly implement responsive breakpoints.
 */
export default {
  name: 'ProductPromotionBlock',

  components: {
    RibbonBanner,
    Countdown
  },

  props: {
    imageUrl: {
      type: String,
      default: undefined
    },
    imageAlt: {
      type: String,
      default: undefined
    },
    heading: {
      type: String,
      default: undefined
    },
    endTime: {
      type: String,
      default: undefined
    },
    expireDateTimeLabel: {
      type: String,
      default: undefined
    },
    expiredLabel: {
      type: String,
      default: undefined
    },
    priceText: {
      type: String,
      default: undefined
    },
    showCounter: {
      type: Boolean,
      default: undefined
    }
  },

  data() {
    return {
      countdownEnded: false,
      flipPosition: this.getFlipPosition()
    };
  },

  computed: {
    modifiers() {
      return {
        'product-promotion-block__informational':
          !this.heading && !this.imageUrl
      };
    },

    countdownData() {
      if (this.endTimeUnix) {
        return {
          startDateTime: Math.round(new Date().valueOf() / 1000),
          endDateTime: Math.round(this.endTimeUnix / 1000),
          isEnabled: true
        };
      }

      return undefined;
    },

    notExpired() {
      if (this.countdownEnded) {
        return false;
      }

      if (this.endTimeUnix) {
        return this.endTimeUnix > new Date().valueOf();
      }
      return false;
    },

    endTimeUnix() {
      try {
        return new Date(this.endTime).valueOf();
      } catch (error) {
        console.error('Failed to parse end time', error);
        return undefined;
      }
    }
  },

  watch: {
    $resize() {
      // Change flip position based on viewport width
      this.flipPosition = this.getFlipPosition();
    }
  },

  methods: {
    onCountdownEnded() {
      this.countdownEnded = true;
    },
    getFlipPosition() {
      return this.$mq.above(600) ? 'top-left' : 'bottom-right';
    }
  }
};
</script>
