<!--
 * TODO DKT-1460: This vue component should definitely be refactored because:
 * 1. Writing your own logic for date-components is extremely prone to errors
 * e.g. bug TC-2236 occurs because the prop that holds the selectedDay sometimes 
 * is set _with_ respect to the local timezone and sometimes _without_
 * 2. The parent of this vue component uses the mixin numberConfigMixin.js  
 * which overrides the whole $data object instead of proper state manangement
 * in the component.
 * NB: Atlas uses a library for handling date logic which might be helpful.
-->
<template>
  <div class="datepicker">
    <input
      ref="input"
      type="text"
      :class="inputClasses"
      :name="inputName"
      :placeholder="placeholder"
      :value="inputValue"
      :disabled="disabled"
      @click.stop="toggleCalendar()"
      @keydown="handleKeys"
    />
    <label class="datepicker__label" @click.stop="toggleCalendar()">
      <slot name="icon"></slot>
    </label>
    <slot name="label"></slot>
    <div
      v-show="isOpen"
      ref="calendar"
      v-click-outside="hideCal"
      class="calendar"
      role="grid"
    >
      <slot name="header"></slot>

      <div class="calendar__header__wrapper">
        <nav class="calendar__nav">
          <button
            type="button"
            class="calendar__nav--left"
            @click="setCal(year, month - 1, 1)"
          >
            <c-icon
              class="cm-numcon__number-search-help--icon"
              symbol-id="arrow-left-small"
            />
          </button>
          <span role="header" class="calendar__header">
            {{ caption }} {{ year }}
          </span>
          <button
            type="button"
            class="calendar__nav--right"
            @click="setCal(year, month + 1, 1)"
          >
            <c-icon
              class="cm-numcon__number-search-help--icon"
              symbol-id="arrow-right-small"
            />
          </button>
        </nav>
      </div>
      <span
        v-for="(weekday, weekdayindex) in weekdays"
        :key="weekdayindex"
        class="calendar__days"
        role="columnheader"
      >
        {{ weekday }}
      </span>
      <span v-for="day in days" :key="day.timestamp" class="calendar__day">
        <a
          v-if="day.value"
          href="#"
          :class="dayClasses(day)"
          @click="insertDate(day, $event)"
        >
          {{ dayFormat(day.value) }}
        </a>
      </span>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'DatePicker',
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.event);
      },

      unbind: function (el) {
        document.body.removeEventListener('click', el.event);
      }
    }
  },
  props: {
    dateFormatOptions: {
      type: Object,
      default: () => ({})
    },
    dateHighlight: {
      type: Array,
      default: () => []
    },
    dateLocale: {
      type: String,
      default: ''
    },
    dateSelected: {
      type: Number,
      default: 0
    },
    disableAfter: {
      type: Number,
      default: 0
    },
    disableBefore: {
      type: Number,
      default: 0
    },
    disableRange: {
      type: Array,
      default: () => []
    },
    inputClasses: {
      type: String,
      default: ''
    },
    inputName: {
      type: String,
      default: ''
    },
    inputValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select date'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      caption: '',
      firstday: 1,
      firstDayOfWeek: 1,
      isOpen: false,
      month: 1,
      months: [],
      numOfDays: 31,
      selectedDay: 1,
      today: 1,
      weekdays: [],
      weekend: [0, 6],
      year: 1970
    };
  },
  computed: {
    ...mapGetters(['numberConfigActiveProduct', 'numberConfigVM']),
    days: function () {
      let days = [];

      for (let i = 0; i < this.rows * 7; i++) {
        const day = {};
        const dayValue = this.dayNum(i);
        let isDisabled = false;

        if (dayValue) {
          const date = new Date(this.year, this.month, dayValue);
          const time = date.getTime();

          if (this.disableRange && this.disableRange.includes(time)) {
            isDisabled = true;
          }

          if (this.disableAfter && this.disableAfter < time) {
            isDisabled = true;
          }

          if (this.disableBefore > time) {
            isDisabled = true;
          }

          day.date = date;
          day.day = date.getDay();
          day.disabled = isDisabled;
          day.highlight =
            this.dateHighlight && this.dateHighlight.includes(time);
          day.selected = this.selectedDay === time;
          day.timestamp = time;
          day.today = this.today === time;
          day.value = dayValue;
          day.weekend = this.weekend.includes(day.day);
        }
        days.push(day);
      }
      return days;
    },
    locale: function () {
      return this.dateLocale
        ? this.dateLocale
        : document.documentElement.getAttribute('lang') || 'en-US';
    },

    region: function () {
      return this.locale ? this.locale.split('-')[1] : 'US';
    },

    rows: function () {
      return Math.ceil(
        (this.firstday - 1 + this.numOfDays) / this.weekdays.length
      );
    }
  },

  watch: {
    dateSelected: function () {
      this.selectedDay = this.dateSelected;
      const date = new Date(this.selectedDay);
      let formatDate = this.formatDate(date);
      this.$refs.input.value = formatDate;
      this.setCal(date.getFullYear(), date.getMonth(), date.getDate());
    }
  },

  mounted() {
    // TODO DKT-1460: Investigate if this method should be called here. It doesn't seem like the function exists in this component.
    // Answer: The method is from the numberConfigMixin (which parent component uses)
    // It should most likely _not_ be used here (since it overrides $data...)
    if (typeof this.changeLocalData === 'function') {
      this.changeLocalData();
    }

    if (this.selectedDay) {
      const date = new Date(this.selectedDay);
      let formatDate = this.formatDate(date);
      this.$refs.input.value = formatDate;
    }
  },

  created() {
    this.setRegionInfo();

    let current;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.today = today.getTime();

    if (this.dateSelected) {
      this.selectedDay = this.dateSelected;
      current = new Date(this.dateSelected);
    } else {
      current = today;
    }

    this.setCal(current.getFullYear(), current.getMonth(), current.getDate());
  },

  methods: {
    ...mapMutations(['SET_NUMCONF_BINDING_PERIOD']),
    UpdateBindingPeriodState(code) {
      this.SET_NUMCONF_BINDING_PERIOD(code);
    },
    dayClasses: function (day) {
      return {
        '-disabled': day.disabled,
        '-highlight': day.highlight,
        '-selected': day.selected,
        '-today': day.today,
        '-weekend': day.weekend
      };
    },

    dayFormat: function (day) {
      return day ? new Intl.NumberFormat(this.locale).format(day) : '';
    },

    dayNum: function (index) {
      let num = index - (this.firstday - 2);
      num = num > 0 && num <= this.numOfDays ? num : 0;
      return num;
    },

    formatDate: function (date) {
      const month = date.getMonth() + 1;
      return `${date.getDate()}/${month}/${date.getFullYear()}`;
      /*
                    return new Intl.DateTimeFormat(
                        this.locale,
                        this.dateFormatOptions
                    ).format(date);

        */
    },

    handleKeys: function (event) {
      switch (event.key) {
        case 'Enter':
          this.isOpen = true;
          break;
        case 'Escape':
          this.isOpen = false;
          break;
        case 'Tab':
          break;
        default:
          /* Disable input */
          event.preventDefault();
      }
    },

    hideCal: function () {
      this.isOpen = false;
    },

    insertDate: function (day, event) {
      event.preventDefault();
      this.selectedDay = day.timestamp;
      let formatDate = this.formatDate(day.date);
      //formatDate = formatDate.replace(/-/g, '/');
      this.$refs.input.value = formatDate;
      this.$emit('setDate', formatDate);
      this.hideCal();
    },

    toggleCalendar: function () {
      this.isOpen = !this.isOpen;
    },

    setCal: function (year, month, day) {
      const date = new Date(year, month, day);
      let firstDay = new Date(year, month, 1).getDay();

      if (this.firstDayOfWeek > 1) {
        firstDay += 8 - this.firstDayOfWeek;
      }

      this.month = date.getMonth();
      this.year = date.getFullYear();
      this.firstday = firstDay < 1 ? 7 : firstDay;
      this.numOfDays = 32 - new Date(year, month, 32).getDate();
      this.caption = this.months[this.month];
    },

    setRegionInfo: function () {
      /* First Day of Week: Friday */
      if (~'BD MV'.indexOf(this.region)) {
        this.firstDayOfWeek = 5;
      }

      /* First Day of Week: Saturday */
      if (
        ~'AE AF BH DJ DZ EG IQ IR JO KW LY MA OM QA SD SY'.indexOf(this.region)
      ) {
        this.firstDayOfWeek = 6;
      }

      /* First Day of Week: Sunday */
      if (
        ~'AG AR AS AU BR BS BT BW BY BZ CA CN CO DM DO ET GT GU HK HN ID IE IL IN JM JP KE KH KR LA MH MM MO MT MX MZ NI NP NZ PA PE PH PK PR PY SA SG SV TH TN TT TW UM US VE VI WS YE ZA ZW'.indexOf(
          this.region
        )
      ) {
        this.firstDayOfWeek = 7;
        this.weekend = [0, 6];
      }

      /* Weekend Friday-Saturday, first day Saturday */
      if ('AE BH EG IQ JO KW LY MA QA SD SY'.indexOf(this.region) > -1) {
        this.weekend = [0, 6];
      }

      /* Weekend Friday-Saturday, first day Sunday */
      if ('IL SA TN YE'.indexOf(this.region) > -1) {
        this.weekend = [5, 6];
      }

      /* Weekend Sunday-Sunday */
      if (this.region === 'IN') {
        this.weekend = [0];
      }

      const date = new Date(0);

      /* Populate weekdays array with local day-names */
      for (let i = 0; i < 7; i++) {
        date.setDate(5 + i);
        this.weekdays.push(
          new Intl.DateTimeFormat([this.locale], {
            weekday: 'short'
          }).format(date)
        );
      }

      /* Populate months array with local month-names */
      for (let i = 0; i < 12; i++) {
        date.setMonth(i);
        this.months.push(
          new Intl.DateTimeFormat([this.locale], {
            month: 'long'
          }).format(date)
        );
      }

      if (this.firstDayOfWeek > 1) {
        for (let i = 0; i < 8 - this.firstDayOfWeek; i++) {
          /* Move last item to first position in array */
          this.weekdays.splice(0, 0, this.weekdays.pop());
        }
      }
    }
  }
};
</script>
