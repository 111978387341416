var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-checkout-summary__content signature" },
    [
      _c(
        "div",
        {
          staticClass:
            "\n      cm-c-checkout__module\n      cm-c-checkout__module--pt\n      cm-c-checkout__module--mb\n      cm-c-total__section\n    "
        },
        [
          _vm.networking
            ? _c("div", { staticClass: "cm-c-spinner cm-c-spinner--local" }, [
                _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "h3",
            {
              staticClass: "cm-c-checkout__subtitle cm-c-checkout__subtitle--mb"
            },
            [_vm._v("\n      " + _vm._s(_vm.content.headline) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "mb-2",
            domProps: { innerHTML: _vm._s(_vm.content.descriptionText) }
          }),
          _vm._v(" "),
          _c(
            "c-form-radio-group",
            {
              staticClass: "mb-2",
              attrs: {
                id: "nemid-or-digital-or-mitid",
                stacked: "",
                name: "digital"
              },
              model: {
                value: _vm.digital,
                callback: function($$v) {
                  _vm.digital = $$v
                },
                expression: "digital"
              }
            },
            [
              _vm.content.epadSignatureRadioText
                ? _c(
                    "c-form-radio",
                    {
                      attrs: { id: "digital", value: 1 },
                      on: {
                        change: function($event) {
                          return _vm.setSignatureValidity(_vm.sigImageData)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.content.epadSignatureRadioText) + "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.digital
                ? _c(
                    "c-button",
                    {
                      staticClass: "mb-2",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.startSign($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.content.signatureButtonText)
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.digitalNemIdSignatureRadioText
                ? _c(
                    "c-form-radio",
                    {
                      attrs: { id: "nemid", value: 0 },
                      on: {
                        change: function($event) {
                          return _vm.postSignature("nemid")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.content.digitalNemIdSignatureRadioText) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.digitalMitIdSignatureRadioText
                ? _c(
                    "c-form-radio",
                    {
                      attrs: { id: "mitid", value: 0 },
                      on: {
                        change: function($event) {
                          return _vm.postSignature("mitid")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.content.digitalMitIdSignatureRadioText) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.digital
            ? _c(
                "c-information-box",
                { attrs: { icon: "contact-information", type: "informative" } },
                [
                  _c("p", [
                    _vm._v(_vm._s(_vm.content.digitalSignatureInformationText))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.sigImageData && _vm.digital,
                  expression: "sigImageData && digital"
                }
              ]
            },
            [
              _c("canvas", {
                ref: "signature",
                class: _vm.canvasClasses,
                attrs: { width: "400", height: "200" }
              })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          ref: "chromeExtensionNotInstalled",
          attrs: {
            title: _vm.content.chromeExtensionFailedText,
            centered: "",
            modern: ""
          }
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.content.chromeExtensionDescription)
            }
          }),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "cm-c-btn cm-c-btn--primary",
              attrs: {
                slot: "footer",
                href: _vm.chromeExtensionLink,
                target: "_blank",
                variant: "primary"
              },
              slot: "footer"
            },
            [
              _vm._v(
                _vm._s(_vm.content.installChromeExtensionButtonText) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }