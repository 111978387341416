<template>
  <div class="c-search-results">
    <div class="c-search-results__header">
      <div class="c-wrapper">
        <div class="c-search-results__header-inner">
          <div class="epi-form-text">
            <input
              id="c-search-results"
              ref="input"
              v-model="searchString"
              type="text"
              class="epi-form-text__input c-search-results__input"
              placeholder=" "
              @keyup.enter.native="inputKeyupEnter"
              @keyup.enter="inputKeyupEnter"
            />
            <label for="c-search-results" class="epi-form-text__label">
              {{ placeholder }}
            </label>
            <span class="epi-form-text__input-ui">
              <span @click="clearInput">
                <slot v-if="!searchString" name="input-icon-search" />
                <slot v-if="searchString" name="input-icon-clear" />
              </span>
            </span>
          </div>

          <div class="c-search-results__term">
            <span v-if="!searchResults.loading">
              {{ termSentence }}

              <span
                v-if="segments.length > 1"
                class="c-search-results__term-dropdown"
              >
                <select v-model="segment">
                  <option
                    v-for="item in segments"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="networking" class="cm-c-spinner cm-c-spinner--local">
      <span class="sr-only">Loading...</span>
    </div>

    <div
      v-if="searchResults.categories.length > 0"
      class="c-search-results__body"
    >
      <div class="c-search-results__tabs">
        <div class="c-wrapper">
          <ul>
            <li v-for="(item, index) in searchResults.categories" :key="index">
              <button
                :class="{ 'is-active': item.facet.id === tab }"
                @click="tab = item.facet.id"
              >
                {{ getCategoryName(item.facet.id) }} ({{ item.facet.total }})
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="c-wrapper">
        <div
          v-for="(category, index) in searchResults.categories"
          v-show="tab === category.facet.id"
          :key="index"
          class="c-search-results__content"
        >
          <div
            v-if="getContentType(category.facet.id) === 'Grid'"
            class="c-search-results__content-wrapper"
          >
            <div
              v-for="(item, itemsIndex) in category.items"
              :key="itemsIndex"
              class="
                c-search-results__content-item
                c-search-results__content-item--grid
              "
              @click="handleCardClick(item, itemsIndex)"
            >
              <c-product-card
                v-if="item.template === 'product'"
                :minimum-price="item.product.minimumPrice"
                :monthly-price="item.product.monthlyPrice"
                :product-image-url="item.image"
                :product-name="item.title"
                :product-url="item.url"
                :variants="item.product.availableColors"
                :badge-text="{ props1: '', item2: item.product.badgeText }"
                :product-brand="item.product.productBrand"
                :product-model="item.product.productModel"
                modifier="c-product-card--variant-details"
              >
                <template slot="action-button">
                  {{ item.title }}
                </template>
              </c-product-card>

              <generic-product
                v-if="item.template === 'genericproduct'"
                :sub-heading="item.genericProduct.subHeading"
                :heading="item.genericProduct.heading"
                :body="item.genericProduct.body"
                :button-url="item.genericProduct.buttonUrl"
                :button-text="item.genericProduct.readMoreButtonCaption"
                modifier="generic-search"
              />

              <tv-card
                v-if="item.template === 'tvpackage'"
                :show-ribbon="item.tvPackage.showRibbon"
                :ribbon-text="item.tvPackage.ribbonText"
                :headline="item.tvPackage.name"
                :name="item.tvPackage.description"
                :description="item.tvPackage.channelDetails"
                :channel-description="item.tvPackage.channelDescription"
                :price="item.tvPackage.priceValue"
                :price-label="item.tvPackage.priceLabel"
                :min-price="item.tvPackage.minimumPriceValue"
                :url="item.tvPackage.readMoreButtonUrl"
                :button-text="item.tvPackage.readMoreButtonCaption"
                :categories="item.tvPackage.includedPackagesText"
                :channels="item.tvPackage.channels"
                only-card="true"
                card-tall
                card-search-result
              />

              <div v-if="item.template === 'subscription'">
                <div
                  class="c-subcard c-subcard--tall c-subcard--search-result"
                  itemscope
                  itemtype="http://schema.org/Product"
                >
                  <div
                    v-if="item.subscription.showRibbon"
                    class="c-subcard__ribbon-wrapper"
                  >
                    <em
                      :class="`c-subcard__ribbon c-subcard__ribbon--${item.subscription.ribbonColorName.toLowerCase()}`"
                    >
                      {{ item.subscription.ribbonText }}
                    </em>
                  </div>

                  <div class="c-subcard__inner">
                    <strong
                      v-if="item.subscription.brandName === 'Telia'"
                      class="c-subcard__headline"
                      itemprop="name"
                    >
                      {{ item.subscription.name }}
                    </strong>

                    <div
                      v-if="item.subscription.brandName === 'Telia'"
                      class="c-subcard__name"
                      itemprop="alternateName"
                    >
                      {{ item.subscription.talkDetails }}
                      <br />

                      <small
                        v-if="item.subscription.previousDataDetails"
                        class="c-subcard__strikethrough"
                      >
                        {{ item.subscription.previousDataDetails }}
                      </small>

                      {{ item.subscription.dataDetails }}
                    </div>
                    <div
                      v-if="item.subscription.brandName !== 'Telia'"
                      class="c-subcard__name"
                      itemprop="alternateName"
                    >
                      <small
                        v-if="item.subscription.previousDataDetails"
                        class="c-subcard__strikethrough"
                      >
                        {{ item.subscription.previousDataDetails }}
                      </small>

                      {{ item.subscription.dataDetails }}<br />{{
                        item.subscription.talkDetails
                      }}
                    </div>
                    <div class="c-subcard__description" itemprop="description">
                      <small
                        v-if="
                          item.subscription.previousInternationalDataDetails
                        "
                        class="c-subcard__strikethrough"
                      >
                        {{ item.subscription.previousInternationalDataDetails }}
                      </small>

                      {{ item.subscription.internationalDataDetails }}
                    </div>
                  </div>
                  <div
                    class="c-subcard__offer"
                    itemprop="offers"
                    itemscope
                    itemtype="http://schema.org/Offer"
                  >
                    <link
                      itemprop="availability"
                      href="http://schema.org/InStock"
                    />
                    <meta
                      itemprop="priceCurrency"
                      :content="item.subscription.priceCurrencyCode"
                    />
                    <meta itemprop="priceValidUntil" content="" />
                    <meta itemprop="url" :content="item.url" />
                    <div
                      class="c-subcard__offer-spec"
                      itemprop="priceSpecification"
                      itemscope
                      itemtype="http://schema.org/UnitPriceSpecification"
                    >
                      <span class="c-subcard__offer-base" />
                      <small
                        v-if="item.subscription.previousPriceValue"
                        class="c-subcard__strikethrough"
                      >
                        {{ item.subscription.previousPriceValue }}
                      </small>
                      <span
                        class="c-subcard__offer-price"
                        itemprop="price"
                        :content="item.subscription.priceSchemaString"
                      >
                        <shouting-font
                          v-if="item.subscription.brandName !== 'Telia'"
                          :text="item.subscription.priceValue"
                        ></shouting-font>
                        <span v-else>{{ item.subscription.priceValue }}</span>
                      </span>
                      <span
                        v-if="item.subscription.brandName === 'Telia'"
                        class="c-subcard__offer-currency"
                      >
                        {{ item.subscription.priceLabel }}
                      </span>
                      <meta itemprop="unitCode" content="MON" />
                      <meta
                        itemprop="minPrice"
                        :content="item.subscription.minimumPriceSchemaString"
                      />
                      <div class="c-subcard__offer-description">
                        {{ item.subscription.minimumPriceValue }}
                      </div>
                    </div>

                    <a
                      v-if="item.subscription.brandName === 'Telia'"
                      class="c-subcard__url"
                      :href="item.url"
                      itemprop="url"
                    >
                      {{ item.subscription.readMoreButtonCaption }}
                    </a>
                  </div>

                  <div
                    v-if="item.subscription.brandName === 'CallMe'"
                    class="c-subcard__url-wrapper"
                  >
                    <a class="c-subcard__url" :href="item.url" itemprop="url">{{
                      item.subscription.readMoreButtonCaption
                    }}</a>
                  </div>

                  <meta
                    itemprop="brand"
                    :content="item.subscription.brandName"
                  />
                  <meta itemprop="sku mpn" :content="item.subscription.code" />

                  <div
                    v-if="item.subscription.hasServices"
                    class="c-subcard__services"
                  >
                    <span class="c-subcard__services-headline">{{
                      item.subscription.servicesHeadline
                    }}</span>
                    <div class="c-subcard__services-list">
                      <img
                        v-for="(service, idx) in item.subscription.services"
                        :key="idx"
                        class="c-subcard__services-item"
                        :src="service.imageUrl"
                        :alt="service.name"
                      />
                    </div>
                    <span class="c-subcard__services-plus">+</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="getContentType(category.facet.id) === 'RichList'"
            class="
              c-search-results__content-wrapper
              c-search-results__content-wrapper--center
            "
          >
            <div
              v-for="(item, i) in category.items"
              :key="i"
              class="
                c-search-results__content-item
                c-search-results__content-item--list
              "
            >
              <a :href="item.url" @click="track(item)">
                <div v-if="item.date" class="c-search-results__content-date">
                  {{ item.date }}
                </div>
                <h4 class="c-search-results__content-headline">
                  {{ item.title }}
                </h4>
                <p class="c-search-results__content-body">{{ item.excerpt }}</p>
                <img
                  v-if="item.image"
                  :src="item.image + '?preset=search-thumb'"
                  class="c-search-results__content-image"
                />
              </a>
            </div>
          </div>

          <div
            v-if="getContentType(category.facet.id) === 'List'"
            class="
              c-search-results__content-wrapper
              c-search-results__content-wrapper--center
            "
          >
            <div
              v-for="(item, i) in category.items"
              :key="i"
              class="
                c-search-results__content-item
                c-search-results__content-item--list
              "
            >
              <a :href="item.url" @click="track(item)">
                <h4 class="c-search-results__content-headline">
                  {{ item.title }}
                </h4>
                <p class="c-search-results__content-body">{{ item.excerpt }}</p>
              </a>
            </div>
          </div>

          <div class="c-search-results__load-more">
            <button
              v-if="!category.allLoaded"
              @click="loadMore(category.facet.id)"
            >
              {{ getLoadMoreText(category.facet.id) }}
            </button>
          </div>

          <slot :name="category.slot" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from 'axios';
import { mapGetters } from 'vuex';
import CProductCard from '../c-product-card/c-product-card.vue';
import GenericProduct from '../../../telia/components/c-generic-product/c-generic-product.vue';
import CTvCard from '../../../telia/components/c-tvcard/c-tvcard.vue';
import trackingService from '../../api/TrackingService.js';
import ShoutingFont from '../generic/shouting-font.vue';
import { GTM_TYPES } from '../../enums/gtm-types';

export default {
  name: 'SearchResults',

  components: {
    'c-product-card': CProductCard,
    'tv-card': CTvCard,
    'generic-product': GenericProduct,
    ShoutingFont
  },

  props: {
    categories: { type: Array, default: () => [] },
    apiUrl: { type: String, default: '' },
    culture: { type: String, default: '' },
    segments: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
    resultsummary: { type: String, default: '' },
    emptysummary: { type: String, default: '' }
  },

  data() {
    return {
      searchString: '',
      searchedSearchString: '',
      tab: this.categories[0].id,
      searchResults: { categories: [], loading: true },
      segment: this.$route.query.segment,
      termSentence: '',
      networking: false
    };
  },

  computed: {
    ...mapGetters(['contextId'])
  },

  watch: {
    segment: function () {
      this.$router.push({
        query: { query: this.searchedSearchString, segment: this.segment }
      });
      this.getResults();
    },
    '$route.query.query'() {
      this.searchedSearchString = this.$route.query.query;
      this.searchString = this.$route.query.query;
      this.getResults();
    }
  },

  created() {
    this.searchedSearchString = this.$route.query.query;
    this.searchString = this.$route.query.query;

    if (this.searchString) {
      this.getResults();
    }

    if (!this.$route.query.segment || !this.searchString) {
      this.searchString = this.searchedSearchString;
      this.segment = this.segments[0].id;
    }
  },

  mounted() {
    if (!this.$route.query.query) {
      this.$router.push({
        query: { query: '' }
      });
    }
  },

  methods: {
    getContentType(id) {
      return this.categories.find(o => o.id === id).contentType;
    },
    getCategoryName(id) {
      return this.categories.find(o => o.id === id).name;
    },
    getLoadMoreText(id) {
      return this.categories.find(o => o.id === id).loadMoreText;
    },
    getCategoryIndex(id) {
      return this.categories.findIndex(o => o.id === id);
    },
    getResultCategoryIndex(id) {
      return this.searchResults.categories.findIndex(o => o.facet.id === id);
    },
    checkIfLoadMoreIsNeeded() {
      this.searchResults.categories.forEach(category => {
        if (category.facet.total === category.items.length) {
          this.searchResults.categories[
            this.getResultCategoryIndex(category.facet.id)
          ].allLoaded = true;
        }
      });
    },
    getTotalResultCount() {
      let count = 0;

      if (this.searchResults.categories.length > 0) {
        this.searchResults.categories.forEach(result => {
          count += result.facet.total;
        });
      }

      return count;
    },
    updateTermSentence() {
      if (this.searchResults.categories.length > 0) {
        this.termSentence = this.resultsummary
          .replace('{0}', this.getTotalResultCount())
          .replace('{1}', this.searchedSearchString);
      } else {
        this.termSentence = this.emptysummary.replace(
          '{1}',
          this.searchedSearchString
        );
      }
    },
    getProductVariant(product) {
      const variantColor = product?.defaultVariant?.color
        ? product?.defaultVariant?.color.toLowerCase()
        : '';
      const variantCapacity = product?.defaultVariant?.capacity;
      const variantScreenInches = product?.defaultVariant?.screenSizeInches;

      return variantColor && variantCapacity && variantScreenInches
        ? `${variantColor}|${variantCapacity}gb|${variantScreenInches}`
        : variantColor;
    },
    /**
     * Filter a given array against a test condition
     *
     * @param {Array} arrayToFilter The array to be filtered
     * @callback testCallback The test condition
     *
     * todo: Make a universal function to handle chained and nested maps and filters
     */
    getFilteredArray(arrayToFilter, testCallback) {
      if (!arrayToFilter.some(element => testCallback(element))) {
        return [];
      }

      return arrayToFilter.reduce((previousElement, currentElement) => {
        if (testCallback(currentElement)) {
          previousElement.push(currentElement);
        }
        return previousElement;
      }, []);
    },
    /**
     * Push Enhanced E-Commerce object from search results to GTM data layer.
     *
     * @param {Array} searchResultCategories The retrieved products from the search that are pushed to the data layer
     */
    pushSearchResultsEecObjectToGtm(searchResultsCategories) {
      window.dataLayer = [];

      if (searchResultsCategories.length) {
        const retrievedProducts = searchResultsCategories.flatMap(category => {
          return this.getFilteredArray(
            category.items,
            retrievedItem => retrievedItem.template === 'product'
          );
        });

        const productsWithDefaultVariant = this.getFilteredArray(
          retrievedProducts,
          element => element?.product?.defaultVariant
        );

        const productImpressions = productsWithDefaultVariant.map(
          (element, index) => {
            const productName =
              element?.product?.productModel ?? element?.product?.productName;
            return {
              name: productName?.toLowerCase(),
              brand: element?.product?.productBrand?.toLowerCase(),
              category:
                element?.product?.defaultVariant?.category?.toLowerCase(),
              list: 'search result',
              position: index,
              variant: element?.product
                ? this.getProductVariant(element.product)
                : ''
            };
          }
        );

        const eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            productImpressions
          },
          event: GTM_TYPES.ECOMMERCE
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eecObject);
      }
    },
    /**
     * Push Enhanced E-Commerce object when a product card is clicked to GTM data layer.
     *
     * @param {Object} card The card that was clicked by the user
     */
    pushClickedCardEecObjectToGtm(card) {
      const { product } = card;

      const productModel = product?.productModel ?? product?.productName;
      const productPrice = product?.minimumPrice
        ? product.minimumPrice.split(' ')[1]
        : '';

      const eecProduct = {
        name: productModel.toLowerCase(),
        id: product?.productCode,
        price: productPrice,
        variant: product ? this.getProductVariant(product) : '',
        category: product?.productCategory.toLowerCase(),
        brand: product?.productBrand.toLowerCase(),
        position: card?.position
      };

      const eecObject = {
        ecommerce: {
          currencyCode: 'DKK',
          [GTM_TYPES.CLICK]: {
            actionField: { list: 'search result' },
            products: [eecProduct]
          }
        },
        event: GTM_TYPES.CLICK
      };

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eecObject);
    },
    orderSearchResults(results) {
      let orderedResults = {
        segmentFacets: results.segmentFacets,
        categories: []
      };

      if (results.categories.length > 0) {
        this.categories.forEach(category => {
          const index = results.categories.findIndex(
            o => o.facet.id === category.id
          );

          if (results.categories[index]) {
            orderedResults.categories.push(results.categories[index]);
          }
        });
      }

      return orderedResults;
    },
    clearInput() {
      this.searchString = '';
      this.$refs.input.focus();
    },
    inputKeyupEnter() {
      this.getResults();
      if (this.$route.query.query !== this.searchString) {
        this.$router.push({
          query: { query: this.searchString, segment: this.segment }
        });
      }
    },
    track(item) {
      const parameters = {
        query: this.raw.data.queryForTracking,
        hitId: item.hitId,
        trackId: this.raw.data.trackId
      };
      trackingService.track(parameters);
    },
    handleCardClick(card, index) {
      this.track(card);
      const cardToPush = {
        ...card,
        position: index
      };
      this.pushClickedCardEecObjectToGtm(cardToPush);
    },
    async loadMore(id) {
      const index = this.getCategoryIndex(id);
      if (!this.categories[index].offset) {
        // eslint-disable-next-line vue/no-mutating-props
        this.categories[index].offset = 2;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.categories[index].offset++;
      }

      this.networking = true;

      let categoryPagings = [];
      categoryPagings.push({
        category: this.categories[index].id,
        page: this.categories[index].offset,
        pagesize: this.categories[index].pageSize
      });

      const result = await post(this.apiUrl + `?contextId=${this.contextId}`, {
        query: this.searchString,
        culture: this.culture,
        filtersegments: [this.segment],
        filtercategories: [this.categories[index].id],
        categorypaging: categoryPagings
      });

      if (
        this.categories[index].offset * categoryPagings[0].pagesize >=
        this.searchResults.categories[this.getResultCategoryIndex(id)].facet
          .total
      ) {
        this.searchResults.categories[
          this.getResultCategoryIndex(id)
        ].allLoaded = true;
      }

      this.searchResults.categories[
        this.getResultCategoryIndex(id)
      ].items.push.apply(
        this.searchResults.categories[this.getResultCategoryIndex(id)].items,
        result.data.categories[0].items
      );

      this.pushSearchResultsEecObjectToGtm(this.searchResults?.categories);
      this.networking = false;
    },
    async getResults() {
      if (this.searchString.length === 0) {
        return false;
      }

      this.networking = true;

      let categoryPagings = [];
      this.categories.forEach(cat => {
        categoryPagings.push({
          category: cat.id,
          page: 1,
          pagesize: cat.pageSize
        });
      });

      const result = await post(this.apiUrl + `?contextId=${this.contextId}`, {
        query: this.searchString,
        culture: this.culture,
        filtersegments: [this.segment],
        categorypaging: categoryPagings
      });

      this.raw = result;
      this.searchedSearchString = this.searchString;
      this.searchResults = this.orderSearchResults(result.data);

      if (this.searchResults.categories[0]) {
        this.tab = this.searchResults.categories[0].facet.id;
      }

      this.updateTermSentence();
      this.checkIfLoadMoreIsNeeded();

      this.pushSearchResultsEecObjectToGtm(this.searchResults?.categories);
      this.networking = false;
    }
  },

  render(h) {
    return h();
  }
};
</script>
