<template>
  <div
    class="cm-o-form-section product-login-prompt"
    :class="{ 'product-login-prompt--attention': loginPromptInView }"
  >
    <!-- TODO DKT-1436: sticky cta offset needed? -->
    <c-button
      v-in-viewport="{ enter: onViewportEnter, exit: onViewportExit }"
      @click="openLoginPage"
    >
      <c-icon class="c-btn__icon" symbol-id="menu-user"></c-icon>
      <div class="c-btn__text">
        <span class="c-btn__primary">{{ content.loginTeaserText }}</span>
        <span class="c-btn__secondary">
          {{ content.loginButtonCaption }}
        </span>
      </div>
      <c-icon class="c-btn__arrow" symbol-id="arrow-right-small"></c-icon>
    </c-button>
  </div>
</template>

<script>
import InViewport from 'olympus/directives/in-viewport-directive';
import { CButton } from 'olympus/components/index.js';

/**
 * A prompt that redirects to the login page,
 * Animating everytime it enters the viewport
 */
export default {
  name: 'ProductLoginPrompt',

  components: {
    CButton
  },

  directives: { InViewport },

  props: {
    loginUrl: {
      type: String,
      default: ''
    },

    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loginPromptInView: false
    };
  },

  methods: {
    openLoginPage() {
      if (this.loginUrl) {
        window.location.href = this.loginUrl;
      }
    },

    onViewportEnter() {
      this.loginPromptInView = true;
    },

    onViewportExit() {
      this.loginPromptInView = false;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';

.product-login-prompt {
  order: map-deep-get($product-order, 'prompt', 'xs');
  padding-top: $u-600;
  margin-bottom: 0;

  .c-btn {
    border-radius: 0.5rem;
    height: unset;
    padding: 0 1rem;
    text-align: left;
    width: 100%;
    justify-content: space-between;

    svg {
      fill: currentColor;
      flex: 0 0 auto;
      height: 20px;
      width: 20px;
    }

    &__icon {
      margin-right: $u-300;
    }

    &__arrow {
      margin-left: $u-350;
    }

    &__text {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      padding: $u-250 0;
      flex: 1;
      align-items: center;
    }

    &__primary {
      margin-right: $u-250;
    }

    &__secondary {
      font-size: $font-size-h5;
      font-weight: 400;
    }
  }

  &--attention {
    .c-btn {
      animation: attention 1s ease-out;
    }
  }
}

@keyframes attention {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
</style>
