import "./c-data-list.scss";

export default {
  name: 'c-data-list',

  props: {
    tabled: {
      type: Boolean,
      default: false
    }
  },

  render(h) {
    return h(
      'dl',
      {
        class: {
          'c-dl': true,
          'c-dl--tabled': this.tabled
        }
      },
      this.$slots.default
    );
  }
}