// todo: refactor getters - use refs instead of hardcoded strings!
// todo dan: split this into modules

export const isCompareItem = state => id => {
  const productIdx = state.compare
    .map(function (product) {
      return product.productId;
    })
    .indexOf(id);
  // eslint-disable-next-line
  return productIdx > -1 ? true : false;
};

export const compare = state => {
  return state.compare;
};

export const compareCount = state => {
  return state.compare.length;
};

export const compareMax = state => {
  return state.compareSettings.maxCompare;
};

export const compareMin = state => {
  return state.compareSettings.minCompare;
};

export const compareUrl = state => {
  return state.compareSettings.url;
};

export const ban = state => {
  return state.checkout.ban;
};

export const minimumPrice = state => {
  return state.minimumPrice;
};

export const orderNumber = state => {
  return state.orderNumber;
};

export const prices = state => {
  return state.prices;
};

export const banSelectorEnabled = state => {
  return state.checkout.banSelectorEnabled;
};

/* Work-In-Progress: Number Configuration */
// TODO dan: still WIP 2 years later, since we should refactor this entire thing.
export const numberConfigItems = state => state.numberConfig.items;

export const numberConfigActive = state => {
  return state.numberConfig.active;
};

export const numberConfigActiveProduct = state => {
  return state.numberConfig.items.find(
    product => product.code === state.numberConfig.active
  );
};

export const numberConfigComplete = state => {
  return state.numberConfig.items.filter(product => product.configComplete);
};

export const numberConfigCompleteIndex = state => {
  const items = state.numberConfig.items.filter(
    product => product.configComplete
  );
  const index = items.findIndex(
    product => product.code === state.numberConfig.active
  );
  return index === -1 ? (items.length === 0 ? 1 : items.length + 1) : index + 1;
};

export const numberConfigCompleteLength = state => {
  return state.numberConfig.items.filter(product => product.configComplete)
    .length;
};

export const numberConfigNumber = state => {
  return state.numberConfig.items.filter(product => !product.configComplete);
};

export const numberConfigText = state => configType => {
  const index = state.numberConfig.vm.configTypes.find(
    config => config.value === configType
  );
  return index && index.text;
};

export const numberConfigTotal = state => {
  return state.numberConfig.items.length;
};

export const numberConfigVM = state => {
  return state.numberConfig.vm;
};

export const errors = state => {
  return state.errors;
};

export const isEnterprise = state => {
  return state.global.enterprise;
};

export const isExternalReseller = state => {
  return state.global.externalReseller;
};

export const currentBalance = state => {
  return state.global.currentBalance;
};

export const contextId = state => {
  return state.global.contextId;
};

export const loading = state => state.spinnerOpen;

export const numberOfActiveFilters = state => {
  return state.selectedFacets.reduce(
    (count, selectedFacet) => count + selectedFacet.values.length,
    0
  );
};

export const getFacetState =
  state =>
  ({ facetId, value }) => {
    const group = state.selectedFacets.find(x => x.id === facetId);
    return group && group.values.indexOf(value) !== -1;
  };

export const productSearchResult = state => state.productSearchResult;

export const pageCount = state => state.searchSettings.page;

export const sortBy = state => state.sortBy;

export const pricePlanFilterValue = state =>
  state.searchSettings.pricePlanFilterValue;
