var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cm-c-product-vas-item" },
    [
      _c("label", {
        staticClass: "cm-c-vaslabel",
        attrs: { for: _vm.item.code }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "vas-description" }, [
        _vm.item.imageUrl
          ? _c("div", {
              staticClass: "vas-description__image",
              style: { backgroundImage: "url(" + _vm.item.imageUrl + ")" }
            })
          : _c(
              "div",
              {
                staticClass: "vas-description__image",
                attrs: { title: _vm.item.displayName }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      viewBox: "0 0 28 34",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      width: "100%",
                      height: "100%"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "fill-rule": "nonzero",
                              fill: "currentColor"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0,5.00001915 C0,2.24055264 2.23523267,0 4.9910904,0 L23.0089096,0 C25.7657475,0 28,2.24307056 28,5.00001915 L28,28.9999809 C28,31.7594474 25.7647673,34 23.0089096,34 L4.9910904,34 C2.23425247,34 0,31.7569294 0,28.9999809 L0,5.00001915 Z M13.7369812,15.4615038 L11.53317,13.2576926 C11.1896416,12.9141642 10.6177185,12.9120896 10.2662466,13.2635615 C9.9123244,13.6174837 9.9121472,14.1822542 10.2603778,14.5304848 L12.8176999,17.087807 C12.9134124,17.1835194 13.0268544,17.2527259 13.1483182,17.2951649 C13.2086842,17.387318 13.2870476,17.4697707 13.3826176,17.5366896 C13.792621,17.823777 14.3499098,17.7243554 14.6336974,17.3190646 L18.7681995,11.4143838 C19.0468199,11.0164726 18.9519702,10.449421 18.5448055,10.1643212 C18.1348022,9.8772338 17.5775134,9.9766555 17.2937258,10.3819462 L13.7369812,15.4615038 Z M8.5108108,25.8783784 C8.5108108,27.8074417 10.0782003,29.3783784 12.0116733,29.3783784 L18.0099483,29.3783784 C19.9427071,29.3783784 21.5108108,27.811375 21.5108108,25.8783784 C21.5108108,23.949315 19.9434213,22.3783784 18.0099483,22.3783784 L12.0116733,22.3783784 C10.0789146,22.3783784 8.5108108,23.9453818 8.5108108,25.8783784 Z M16,25.75 C16,24.7835017 16.7767634,24 17.75,24 C18.7164983,24 19.5,24.7767634 19.5,25.75 C19.5,26.7164983 18.7232366,27.5 17.75,27.5 C16.7835017,27.5 16,26.7232366 16,25.75 Z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-checkbox" }, [
          _c("p", { staticClass: "cm-c-checkbox__label" }, [
            _c("span", [_vm._v(_vm._s(_vm.item.displayName))]),
            _vm._v(" "),
            _vm.item.shortDescription || _vm.item.description
              ? _c(
                  "a",
                  {
                    staticClass: "cm-c-product-subscriptions__expand-link",
                    class: {
                      "cm-c-product-subscriptions__expand-link--hidden": _vm.isExpanded(
                        _vm.item
                      )
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.expandOption(_vm.item)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.expandLabel) + "\n        "
                    )
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cm-c-total__value" }, [
          _vm.item.originalPrice
            ? _c(
                "span",
                { class: { "discounted-price": _vm.item.originalPrice } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.item.originalPrice.value) +
                      "\n      "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.item.price.value))
          ]),
          _vm._v(" " + _vm._s(_vm.item.price.label) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isExpanded(_vm.item) && _vm.item.shortDescription
          ? _c("div", [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.item.shortDescription || _vm.item.description
                  )
                }
              }),
              _vm._v(" "),
              _vm.item.shortDescription || _vm.item.description
                ? _c(
                    "a",
                    {
                      staticClass:
                        "cm-c-product-subscriptions__expand-link close",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.collapseOption(_vm.item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.collapseLabel) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }