var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      ref: "sessionTimeoutOverlay",
      attrs: {
        centered: "",
        gray: "",
        "non-closable-modal": "",
        "show-close-button": false
      }
    },
    [
      _c(
        "div",
        { staticClass: "session-timeout-overlay text-center" },
        [
          _c(
            "c-heading",
            {
              staticClass: "session-timeout-overlay__title text-center px-3",
              attrs: { slot: "header", level: "2", as: "1" },
              slot: "header"
            },
            [_vm._v("\n      " + _vm._s(_vm.content.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "session-timeout-overlay__description" }, [
            _vm._v("\n      " + _vm._s(_vm.populatedTexts.subTitle) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "session-timeout-overlay__footer" }, [
            _c(
              "button",
              {
                staticClass: "c-btn c-btn--primary",
                domProps: { innerHTML: _vm._s(_vm.content.primaryButtonText) },
                on: { click: _vm.reloadPage }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.primaryButtonText) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                ref: "logoutForm",
                attrs: { action: "/api/olympus/users/logout", method: "post" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--as-link",
                    attrs: { type: "submit" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.content.logoutButtonText) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "session-timeout-overlay__countdown" }, [
            _c("p", [_vm._v(_vm._s(_vm.populatedTexts.autoLogoutText))])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }