var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-wrapper" },
    [
      _c(
        "keep-alive",
        [
          _c(_vm.activeView, {
            tag: "component",
            attrs: {
              content: _vm.content,
              orders: _vm.orders,
              "handle-view-click": _vm.handleViewClick,
              "fetch-orders": _vm.fetchOrders,
              "get-from-date": _vm.getFromDate,
              "get-to-date": _vm.getToDate,
              "order-id": _vm.orderId,
              "is-fetching": _vm.isFetching,
              "is-api-error": _vm.isApiError
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }