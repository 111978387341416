<template>
  <div
    class="c-gallery"
    :class="`c-gallery--${
      altProps ? altProps.thumbnailPosition : thumbnailPosition
    }-thumbnails`"
  >
    <div class="c-gallery__selected">
      <slot name="selected" :data="selected">
        <c-carousel
          v-model="selectedIndexLocal"
          :data="items"
          :navigation="altProps ? altProps.navigation : navigation"
          :rounded-navigation-buttons="
            altProps ? altProps.navigationRounded : navigationRounded
          "
          :loop="altProps ? altProps.loop : loop"
          :single-slider-speed="300"
        >
          <template slot="slide" slot-scope="{ data }">
            <template v-if="data.type && data.type.toLowerCase() === 'video'">
              <meta
                v-if="data.description"
                itemprop="description"
                :content="data.description"
              />
              <meta
                v-if="data.caption"
                itemprop="name"
                :content="data.caption"
              />
              <meta
                v-if="data.thumbnail"
                itemprop="thumbnailUrl"
                :content="data.thumbnail"
              />
              <meta
                v-if="data.uploadDate"
                itemprop="uploadDate"
                :content="data.uploadDate"
              />
              <video v-if="videoLocal(data.src)" controls>
                <source
                  :src="data.src"
                  :type="videoType(data.src)"
                  tabindex="-1"
                />
              </video>
              <iframe
                v-else
                :src="data.src.replace('watch?v=', 'embed/')"
                frameborder="0"
                tabindex="-1"
                allow="autoplay; encrypted-media"
                allowfullscreen
              ></iframe>
            </template>
            <img
              v-else
              :alt="data.alt"
              :src="getSrc(data.src)"
              :selected="data.caption"
              :srcset="getSrcSet(data.src)"
              :sizes="getSizes()"
              :class="{ 'allow-click': altProps ? altProps.overlay : overlay }"
              @click="handleSlideClick"
            />
          </template>
        </c-carousel>
      </slot>
      <slot name="selectedFooter" :data="selected" />
    </div>
    <ul
      v-if="hideSingle && items.length && items.length !== 1"
      class="c-gallery__thumbnails"
      itemscope
      itemtype="http://schema.org/ImageGallery"
    >
      <li
        v-for="(item, idx) in shownItems"
        :key="idx"
        :class="{ selected: idx === selectedIndexLocal }"
        role="button"
        @click="handleThumbnailClick(idx)"
      >
        <slot name="item" :data="item" :selected="idx === selectedIndexLocal">
          <div
            :style="{ backgroundImage: 'url(' + getThumbnail(item) + ')' }"
            :class="{
              'c-gallery__thumbnails-image--dim':
                collapsed && idx === getMinVisible
            }"
            class="c-gallery__thumbnails-image"
          ></div>
        </slot>
        <div
          v-if="collapsed && idx === getMinVisible"
          class="c-gallery__thumbnails-expand"
        >
          + {{ collapsedCount }}
        </div>
      </li>
      <li
        v-show="collapsable"
        class="c-gallery__thumbnails-collapse"
        @click="collapse"
      >
        <span>Hide</span>
      </li>
    </ul>
  </div>
</template>

<script>
import CCarousel from '../c-carousel/c-carousel.vue';

const defaultTransform = x => {
  // todo: create fail safe object map later.
  return x;
};

export default {
  name: 'c-gallery-content',

  components: {
    CCarousel
  },

  props: [
    'items',
    'transform',
    'selectedIndex',
    'minVisible',
    'smallImageSize',
    'largeImageSize',
    'smallImageWidth',
    'largeImageWidth',
    'selectedImageBreakpoint',
    'navigation',
    'navigationRounded',
    'hideSingle',
    'thumbnailPosition',
    'selectedSlidePressCallback',
    'overlay',
    'loop',
    'altProps'
  ],

  data() {
    return {
      collapsed: this.altProps
        ? this.altProps.minVisible
        : this.minVisible < this.items.length,
      selectedIndexLocal: this.selectedIndex
    };
  },

  computed: {
    selected() {
      return this.items[this.selectedIndexLocal];
    },
    collapsedCount() {
      return Math.max(0, this.items.length - this.getMinVisible);
    },
    collapsable() {
      return !this.collapsed && this.getMinVisible < this.items.length;
    },
    shownItems() {
      return (
        this.collapsed
          ? this.items.slice(0, this.getMinVisible + 1)
          : this.items
      ).map(this.transform || defaultTransform);
    },
    getMinVisible() {
      return this.altProps ? this.altProps.minVisible : this.minVisible;
    },
    getSmallImageSize() {
      return this.altProps ? this.altProps.smallImageSize : this.smallImageSize;
    },
    getLargeImageSize() {
      return this.altProps ? this.altProps.largeImageSize : this.largeImageSize;
    },
    getSmallImageWidth() {
      return this.altProps
        ? this.altProps.smallImageWidth
        : this.smallImageWidth;
    },
    getLargeImageWidth() {
      return this.altProps
        ? this.altProps.largeImageWidth
        : this.largeImageWidth;
    }
  },

  watch: {
    selectedIndexLocal(val) {
      if (this.collapsed && val >= this.getMinVisible) {
        this.expand();
      }
    }
  },

  methods: {
    handleThumbnailClick(idx) {
      if (this.overlay && idx === this.getMinVisible) {
        this.$parent.$refs.galleryOverlay.openModal();
      } else if (this.collapsed && idx === this.getMinVisible) {
        if (this.$listeners.expand) {
          this.$emit('expand');
        } else {
          this.expand();
        }
      } else {
        this.showIndex(idx);
      }
    },
    handleSlideClick() {
      if (this.overlay) {
        this.$parent.$refs.galleryOverlay.openModal();
      }

      if (this.selectedSlidePressCallback) {
        this.selectedSlidePressCallback(this.selectedIndexLocal);
      }
    },
    showIndex(idx) {
      this.selectedIndexLocal = Math.min(
        Math.max(idx, 0),
        this.items.length - 1
      );
    },
    expand() {
      this.collapsed = false;
    },
    collapse() {
      this.collapsed = true;
      this.selectedIndexLocal = 0;
    },
    videoLocal(src) {
      return /\.(mp4|ogg|webm)$/i.test(src);
    },
    videoType(src) {
      if (src.includes('.mp4')) {
        return 'video/mp4';
      }
      if (src.includes('.ogg')) {
        return 'video/ogg';
      }
      if (src.includes('.webm')) {
        return 'video/webm';
      }
    },
    getSrcSet(imageUrl) {
      return `
        ${imageUrl}?w=${this.getSmallImageSize} ${this.getSmallImageSize}w,
        ${imageUrl}?w=${this.getLargeImageSize} ${this.getLargeImageSize}w`;
    },
    getSrc(imageUrl) {
      return `${imageUrl}?w=${this.getLargeImageSize}`;
    },
    getSizes() {
      return `(max-width: ${
        this.altProps
          ? this.altProps.selectedImageBreakpoint
          : this.selectedImageBreakpoint
      }px) ${this.getSmallImageWidth}, ${this.getLargeImageWidth}`;
    },

    // TODO: refactor
    // Currently supporting only youtube
    getThumbnail(item) {
      const thumbnail = item.thumbnail;

      if (!thumbnail) {
        return item.src;
      }

      const match = thumbnail.match(
        /(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
      );

      if (match && match.length > 1) {
        return `//img.youtube.com/vi/${match[2]}/0.jpg`;
      }

      return item.thumbnail;
    },
    // TODO: use in optimization and remove from here.
    updateImages(arr) {
      const blankImage =
        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D';
      let images = arr;
      images.forEach((item, index) => {
        item.thumbnailCurrent = blankImage;
        item.srcCurrent = index === 0 ? item.src : blankImage;
      });
      this.images = images;
    }
  }
};
</script>

<style lang="scss">
.c-gallery {
  position: relative;

  &__selected {
    position: relative;
    text-align: center;
    flex-shrink: 0;
  }

  &__thumbnails {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    & > li {
      width: 80px;
      height: 80px;
      border: 1px solid #e2e2e2;
      cursor: pointer;
      text-align: center;
      position: relative;
      margin-bottom: 5px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.selected {
        border-color: black;
      }
    }

    &-image {
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      &--dim {
        opacity: 0.2;
      }
    }

    &-expand {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-collapse {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        border-bottom: 3px solid black;
        border-right: 3px solid black;
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        transform: rotate(135deg);
      }

      & > span {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }

  &--left-thumbnails {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .c-gallery__thumbnails {
      flex-direction: column;
      justify-content: flex-end;

      & > li {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &-collapse {
        &:before {
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>
