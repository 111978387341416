<template>
  <c-card extra-padding no-shadow no-rounding>
    <h3 class="cm-c-form__header">
      {{ content.title }}
    </h3>
    <div
      v-for="(item, idx) in data.items"
      :key="idx"
      class="cm-c-checkout-summary__section"
    >
      <div class="cm-c-checkout-summary__section__delivery-group">
        <p class="cm-c-checkout-summary__section__delivery-group__title">
          <strong v-if="content.headlines">{{
            getHeadline(item.headlineType)
          }}</strong>
        </p>
        <div
          v-if="item.headlineType === ADDRESS_HEADLINES.NO_ADDRESS_NECESSARY"
          class="cm-c-checkout-summary__section__delivery-group__address"
        >
          <p v-html="content.noShippingNecessaryLabel"></p>
        </div>
        <ul
          v-else
          class="cm-c-checkout-summary__section__delivery-group__address"
        >
          <li>{{ item.organization }}</li>
          <li>{{ item.name }}</li>
          <li>{{ item.addressLine }}</li>
          <li v-if="item.postalCode && item.city">
            {{ `${item.postalCode} ${item.city}` }}
          </li>
          <li v-else-if="item.zipCity">
            {{ item.zipCity }}
          </li>
        </ul>
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';

import { ADDRESS_HEADLINES } from 'webshop/enums/address-headlines.js';

export default {
  name: 'CheckoutDeliveryAddress',

  components: {
    CCard
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    data: {
      type: Object,
      default: () => ({})
    },

    homeOfficeFlow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ADDRESS_HEADLINES
    };
  },

  methods: {
    getHeadline(headlineType) {
      return this.content.headlines?.find(
        headline => headline.type === headlineType
      )?.text;
    }
  }
};
</script>
<style lang="scss" />
