<template>
  <c-row>
    <c-col
      v-for="section in orderDetailSections"
      :key="section.title"
      :md="section.col"
    >
      <c-box white>
        <c-heading :as="2">
          {{ section.title }}
        </c-heading>

        <dl>
          <template v-for="(value, name) in section.fields">
            <dt :key="`${name}-dt`">
              <c-text>
                {{ name }}
              </c-text>
            </dt>
            <dd :key="`${name}-dd`">
              <c-text v-if="!value">
                {{ emptyValueText }}
              </c-text>

              <template v-else-if="Array.isArray(value)">
                <c-text v-if="value.length === 0">
                  {{ emptyValueText }}
                </c-text>

                <ul v-else>
                  <li v-for="column in value" :key="column.key">
                    <c-text>
                      {{ column }}
                    </c-text>
                  </li>
                </ul>
              </template>

              <c-text v-else>
                {{ value }}
              </c-text>
            </dd>
          </template>
        </dl>
      </c-box>
    </c-col>
  </c-row>
</template>

<script>
import { CHeading, CText, CBox, CRow, CCol } from 'olympus/components/';

export default {
  name: 'order-detail',

  components: {
    CText,
    CHeading,
    CBox,
    CRow,
    CCol
  },

  props: {
    content: {
      type: Object,
      required: true
    },
    orders: {
      type: Array,
      required: true
    },
    orderId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      order: {},
      productDetails: {
        handsets: [],
        subscriptions: [],
        accessories: []
      },
      emptyValueText: '-'
    };
  },

  computed: {
    orderDetailSections() {
      return [
        {
          col: 6,
          title: this.content.detailView.customerDetailsHeader,
          fields: {
            [this.content.detailView
              .customerNameLabel]: `${this.order.customer.firstName} ${this.order.customer.lastName}`,
            [this.content.detailView.customerNumberLabel]:
              this.order.contactNumber,
            [this.content.detailView.customerEmailLabel]:
              this.order.customer.email
          }
        },
        {
          col: 6,
          title: this.content.detailView.orderDetailsHeader,
          fields: {
            [this.content.detailView.orderIdLabel]: this.order.orderId,
            [this.content.detailView.createdDateLabel]: this.order.createdDate,
            [this.content.detailView.completedDateLabel]:
              this.order.completedDate,
            [this.content.detailView.channelLabel]: this.order.channel,
            [this.content.detailView.salesAgentIdLabel]:
              this.order.dealer.salesAgentCode,
            [this.content.detailView.dealerIdLabel]:
              this.order.dealer.dealerCode,
            [this.content.detailView
              .statusLabel]: `${this.order.processingStatus}, ${this.order.processingStatusDetail}`
          }
        },
        {
          col: 12,
          title: this.content.detailView.productDetailsHeader,
          fields: {
            [this.content.detailView.handsetsLabel]: this.order.handsets,
            [this.content.detailView.subscriptionsLabel]:
              this.order.subscriptions,
            [this.content.detailView.accessoriesLabel]: this.order.accessories
          }
        }
      ];
    }
  },

  watch: {
    orderId: {
      handler() {
        this.order = this.orders.find(order => order.orderId === this.orderId);
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.c-box {
  height: calc(100% - #{$u-300});
  margin-bottom: $u-300;
}

dl {
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: $u-150;
}

dt {
  grid-column: 1;
}

dd {
  grid-column: 2;
  font-weight: bold;
}

dt,
dd {
  margin-bottom: $u-150;
}
</style>
