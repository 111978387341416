<template>
  <div>
    <div class="cm-c-total__row cm-c-total__row--bold cm-c-total-toggleitem">
      <div class="cm-c-total__name">
        <div class="cm-c-toggle">
          <input
            :id="item.code"
            v-model="selectedLocal"
            class="cm-c-toggle__checkbox"
            type="checkbox"
            :value="item.code"
            @click="toggleSelection"
          />
          <span class="cm-c-toggle__btn"></span>
          <label :for="item.code" class="cm-c-toggle__label">{{
            item.displayName
          }}</label>
        </div>
      </div>
      <div class="cm-c-total__value">
        {{ priceText }}
      </div>
    </div>
    <div class="cm-c-total__row--bottom-separator">
      <ul class="cm-c-total__description">
        <li
          v-for="(prop, idx) in item.uniqueSellingPointsPlus"
          :key="idx"
          :class="
            cannotBeInterpolated(prop)
              ? 'cm-c-total__description__list-item'
              : 'cm-c-total__description__link-item'
          "
          v-html="populateInsuranceLink(prop)"
        ></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
// TODO DKT-1429: this component could be removed in favor of using shared\components\form\c-checkbox.vue inside product-footer.
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    headline: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedLocal: this.selected
    };
  },

  computed: {
    priceText() {
      return this.item.price?.productPrice?.formattedValueCurrency;
    }
  },

  methods: {
    toggleSelection() {
      if (this.selectedLocal) {
        this.$emit('deselected', this.item);
      } else {
        this.$emit('selected', this.item);
      }
    },
    populateInsuranceLink(text) {
      if (!this.item.insuranceLink || !this.item.insuranceLinkText) {
        return text;
      }

      return populateContentProperty(text, {
        link: `<a href="${this.item.insuranceLink}"> ${this.item.insuranceLinkText} </a>`
      });
    },
    cannotBeInterpolated(text) {
      return text && !/(?:{{|<%)\s*([a-zA-Z]+)\s*(?:}}|%>)/g.test(text);
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

.cm-c-total__description {
  &__list-item {
    list-style-type: disc;
    margin-left: 16px;
  }

  &__link-item {
    margin-top: 8px;
    a {
      color: $c-pm-500;
      line-height: $u-300;
      font-size: $font-size-h5;
    }
  }
}
</style>
