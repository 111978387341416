export default function (Vue) {
  Vue.mixin({ beforeCreate: globalsInit })

  function globalsInit() {
    const options = this.$options

    // globals injection
    if (options.globals) {
      this.$globals = typeof options.globals === 'function'
        ? options.globals()
        : options.globals
    } else if (options.parent && options.parent.$globals) {
      this.$globals = options.parent.$globals
    }
  }
}