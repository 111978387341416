<template>
  <div ref="parallax-wrapper" :style="calcStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    axis: {
      type: String,
      default: 'y',
      validator: value => {
        return ['x', 'y'].indexOf(value) !== -1;
      }
    },
    speed: {
      type: Number,
      default: 0.5
    },
    direction: {
      type: Number,
      default: -1,
      validator: value => {
        return [-1, 1].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      position: {
        start: {
          x: 0,
          y: 0
        },
        dest: {
          x: 0,
          y: 0
        }
      }
    };
  },
  computed: {
    calcStyle() {
      return {
        transform: `translate3d(${this.position.dest.x}px, ${this.position.dest.y}px, 0)`
      };
    }
  },
  mounted() {
    this.setInitialValues();
    this.setupIntersectionObserver();
    this.adjustPosition();
  },
  methods: {
    setInitialValues() {
      // Catch slot component if loaded, else wrapper el.
      let el =
        this.$refs['parallax-wrapper'].firstElementChild ||
        this.$refs['parallax-wrapper'];
      // Cache initial DOM positions for later calculations
      this.position.start.y = el.getBoundingClientRect().top;
      this.position.start.x = el.getBoundingClientRect().left;
    },
    setupIntersectionObserver() {
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            window.addEventListener('scroll', this.onScroll);
          } else {
            window.removeEventListener('scroll', this.onScroll);
          }
        });
      });
      observer.observe(this.$refs['parallax-wrapper']);
    },
    onScroll() {
      this.adjustPosition();
    },
    adjustPosition() {
      this.position.dest[this.axis] =
        (this.position.start[this.axis] - window.pageYOffset) *
        this.speed *
        this.direction;
    }
  }
};
</script>
