<script>
import {
  GETTERS,
  MUTATIONS
} from 'webshop/components/product/store/product-page';
import { mapGetters, mapMutations } from 'vuex';
import ProductMinimumPriceTooltip from 'webshop/components/product/minimum-price-tooltip.vue';
import ValueAddingService from 'webshop/components/product/value-adding-service.vue';

import withTemplate from 'theme/templates/product-footer.html';

/**
 * Display summary (totals) of a product,
 * with an optional CTA.
 */
export default withTemplate({
  name: 'product-footer',

  components: {
    ProductMinimumPriceTooltip,
    ValueAddingService
  },

  props: {
    isEnterprise: {
      type: Boolean,
      default: false
    },

    isAccessory: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      contentLocal: GETTERS.GET_CONTENT_LOCAL,
      selectedVariant: GETTERS.GET_SELECTED_VARIANT,
      selectedSubscription: GETTERS.GET_SELECTED_SUBSCRIPTION,
      selectedInstallment: GETTERS.GET_SELECTED_INSTALLMENT,
      selectedVasCodes: GETTERS.GET_SELECTED_VAS_CODES,
      prices: GETTERS.GET_PRICES
    }),

    valueAddedServices() {
      return this.selectedVariant?.valueAddedServices;
    }
  },

  methods: {
    ...mapMutations({
      addVas: MUTATIONS.ADD_SELECTED_VAS,
      removeVas: MUTATIONS.REMOVE_SELECTED_VAS
    }),

    ctaPressed() {
      /**
       * Triggers when the CTA button is pressed.
       */
      this.$emit('cta-pressed');
    },

    onValueAddingServiceSelected(vas) {
      this.addVas(vas);

      /**
       * Event triggered when a vas is added for the current product.
       */
      this.$emit('vas-selected', vas);
    },

    onValueAddingServiceDeselected(vas) {
      this.removeVas(vas);

      /**
       * Event triggered when a vas is removed for the current product.
       */
      this.$emit('vas-deselected', vas);
    }
  }
});
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';

.product-footer {
  order: map-deep-get($product-order, 'cta', 'xs');
  margin-top: $u-500;

  &.cm-c-total {
    margin-bottom: $u-700;
  }
}
</style>
