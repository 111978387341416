var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-search-panel" }, [
    _c("div", { staticClass: "epi-form-text" }, [
      _c("input", {
        ref: "input",
        staticClass: "epi-form-text__input c-search-panel__input",
        attrs: { id: "c-search-panel", type: "text", placeholder: " " },
        domProps: { value: _vm.searchString },
        on: {
          input: _vm.handleInputChange,
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.inputKeyupEnter($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "epi-form-text__label",
          attrs: { for: "c-search-panel" }
        },
        [_vm._v("\n      " + _vm._s(_vm.placeholder) + "\n    ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "epi-form-text__input-ui" }, [
        _c(
          "span",
          { on: { click: _vm.clearInput } },
          [
            _vm.searchString.length === 0
              ? _vm._t("input-icon-search")
              : _vm._e(),
            _vm._v(" "),
            _vm.searchString.length > 0 ? _vm._t("input-icon-clear") : _vm._e()
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _vm.searchResults.length === 0
      ? _c("div", { staticClass: "c-search-panel__quick-links" }, [
          _c("h3", { staticClass: "c-search-panel__quick-links-headline" }, [
            _vm._v("\n      " + _vm._s(_vm.quickLinksHeader) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.quickLinksList, function(quickLink) {
              return _c("li", { key: quickLink.url + quickLink.title }, [
                _c("a", { attrs: { href: quickLink.url } }, [
                  _vm._v(_vm._s(quickLink.title))
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.searchResults.length > 0
      ? _c("div", { staticClass: "c-search-panel__results" }, [
          _c(
            "ul",
            _vm._l(_vm.searchResults, function(result) {
              return _c("li", { key: result.url + result.title }, [
                _c("a", { attrs: { href: result.url } }, [
                  _c(
                    "span",
                    { staticClass: "c-search-panel__result-headline" },
                    [
                      _vm._v(
                        "\n            " + _vm._s(result.title) + "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  result.excerpt
                    ? _c(
                        "span",
                        { staticClass: "c-search-panel__result-excerpt" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(result.excerpt) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("span", { staticClass: "c-search-panel__view-all" }, [
            _c(
              "a",
              { attrs: { href: _vm.searchUrl }, on: { click: _vm.closePanel } },
              [_vm._v(_vm._s(_vm.viewAllText))]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }