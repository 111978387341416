var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.content.isMatchingCpr && _vm.content.isSuccessfulLogin
      ? _c("p", { staticClass: "cm-c-checkout__error" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.content.errorMessageCprMisMatch) + "\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("p", {
      staticClass: "cm-c-checkout__text",
      domProps: { innerHTML: _vm._s(_vm.content.description) }
    }),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "c-btn c-btn--primary c-btn--block",
        attrs: { href: _vm.content.brokerUrl }
      },
      [_vm._v("\n    " + _vm._s(_vm.content.buttonText) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }