<template>
  <div>
    <c-modal
      id="homeOfficeValidationModal"
      ref="homeOfficeValidationModal"
      centered
      show-close-button
      :visible="visible"
      @close="onModalClose"
    >
      <c-heading slot="header" level="3" as="3">
        {{ modalTitle }}
      </c-heading>
      <div v-html="validationMessage" />
    </c-modal>
  </div>
</template>

<script>
import CModal from 'olympus/components/c-modal/c-modal.vue';
import CHeading from 'olympus/components/c-heading/c-heading.vue';

import { mapMutations } from 'vuex';
import { MUTATIONS as HOME_OFFICE_MUTATIONS } from 'webshop/store/home-office/index.js';

export default {
  name: 'HomeOfficeValidationModal',

  components: {
    CModal,
    CHeading
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      modalTitle: this.getModalTitle(),
      validationMessage: this.getValidationMessage()
    };
  },

  methods: {
    ...mapMutations({
      setShowCheckoutError: HOME_OFFICE_MUTATIONS.SET_SHOW_CHECKOUT_ERROR
    }),
    getValidationMessage() {
      return this.errorMessage;
    },
    getModalTitle() {
      return this.title;
    },
    onModalClose() {
      this.setShowCheckoutError(false);
    }
  }
};
</script>
