var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "carousel",
    _vm._b(
      {
        ref: "carousel",
        attrs: { id: "carousel" },
        on: {
          "page-change": _vm.handlePageChange,
          "navigation-click": _vm.navClick
        }
      },
      "carousel",
      _vm.pluginProps,
      false
    ),
    _vm._l(_vm.slides, function(slide, index) {
      return _c(
        "slide",
        { key: index, ref: "slides", refInFor: true },
        [_vm._t("slide", [_vm._v(_vm._s(slide))], { data: slide })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }