var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-load-more", attrs: { "aria-label": _vm.ariaLabel } },
    [
      _c(
        "c-button",
        {
          attrs: {
            "aria-controls": _vm.ariaControls,
            disabled: _vm.canLoadMore
          },
          on: { click: _vm.loadMore }
        },
        [_vm._t("default", [_vm._v(_vm._s(_vm.buttonText))])],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }