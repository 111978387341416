<template>
  <div ref="fieldset">
    <h2 class="cm-c-checkout__title cm-c-checkout__title--center">
      {{ divaSimHeadline }}
    </h2>
    <number-diva-items
      v-for="(simData, index) in simDataList"
      :key="index"
      :sim-data="simData"
      :move-label="divaMoveLabel"
      :sim-label="divaSimLabel"
      :sim-invalid="divaSimInvalid"
    ></number-diva-items>
    <div v-if="simDataList.length" class="cm-c-checkout__simnumber-help">
      <div v-html="divaHowToFindSim"></div>
      <img :src="divaImageUrl" />
    </div>
    <button
      id="c-btn-fake-submit"
      type="button"
      class="c-btn c-btn--secondary cm-c-checkout__btn"
      @click="accept"
    ></button>
  </div>
</template>

<script>
import NumberDivaItems from './number-diva-items.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'NumberDiva',
  components: {
    NumberDivaItems
  },
  props: {
    divaSimActionUrl: {
      type: String,
      default: '',
      required: true
    },
    divaImageUrl: {
      type: String,
      default: ''
    },
    divaSimHeadline: {
      type: String,
      default: '',
      required: true
    },
    divaHowToFindSim: {
      type: String,
      default: '',
      required: false
    },
    divaSimLabel: {
      type: String,
      default: '',
      required: true
    },
    divaMoveLabel: {
      type: String,
      default: '',
      required: true
    },
    divaSimInvalid: {
      type: String,
      default: '',
      required: true
    },
    simDataList: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['contextId'])
  },
  mounted() {
    const submitBtn = document.getElementById('c-btn-submit');
    const fakeSubmit = document.getElementById('c-btn-fake-submit');
    submitBtn.style.visibility = 'hidden';
    fakeSubmit.textContent = submitBtn.textContent;
  },
  methods: {
    ...mapMutations(['HIDE_SPINNER', 'SHOW_SPINNER']),
    async accept() {
      this.SHOW_SPINNER();
      if (this.checkRequired()) {
        this.setSimnumbers();
      }
      this.HIDE_SPINNER();
    },
    checkRequired() {
      /* Basic validation */
      const required = this.$refs.fieldset.querySelectorAll(
        '[required]:not([disabled])'
      );
      let valid = true;
      required.forEach(input => {
        let parent = input.parentNode;
        if (parent.tagName.toLowerCase() === 'label') {
          parent = parent.parentNode;
        }
        const error = parent.querySelector('.field-validation-error');
        if (error) {
          error.hidden = true;
        }
        if (!input.checkValidity()) {
          valid = false;
          if (error) {
            error.hidden = false;
          }
        }
      });
      this.$scrollTo('body');
      return valid;
    },
    setSimnumbers: function () {
      fetch(this.divaSimActionUrl + `?contextId=${this.contextId}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.simDataList)
      }).then(response => {
        if (response.status === 200) {
          //submit form
          document.getElementById('c-btn-submit').click();
        }
      });
    }
  }
};
</script>
