var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sim-card-selection" },
    [
      _c("legend", { staticClass: "sim-card-selection__legend" }, [
        _vm._v("\n    " + _vm._s(_vm.options.legendText) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "c-form-radio-group",
        {
          attrs: { name: "simOption", stacked: "", highlighted: "" },
          model: {
            value: _vm.localSelectedSimcardType,
            callback: function($$v) {
              _vm.localSelectedSimcardType = $$v
            },
            expression: "localSelectedSimcardType"
          }
        },
        _vm._l(_vm.filteredSimOptions, function(option) {
          return _c(
            "c-form-radio",
            {
              key: option.value,
              attrs: {
                id: option.value,
                value: option.value,
                "description-text": option.descriptionText,
                "has-tooltip": _vm.radioHasTooltip(option),
                "tooltip-description-text": _vm.showTooltipDescriptionText(
                  option
                ),
                "tooltip-trigger-text": _vm.showTooltipTriggerText(option)
              }
            },
            [_c("span", [_vm._v(_vm._s(option.label))])]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.localSelectedSimcardType === _vm.options.newSimOptionValue
        ? _c("c-input-group", {
            attrs: {
              name: "newSimNumber",
              "input-error": _vm.newSimNumberError,
              "place-holder-label": _vm.options.placeholderLabel
            },
            model: {
              value: _vm.localNewSimNumber,
              callback: function($$v) {
                _vm.localNewSimNumber = $$v
              },
              expression: "localNewSimNumber"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }