<script>
import { setCookie, getCookie } from '../../../../../shared/utils/cookies.js';

export default {
  name: 'cookie-disclaimer',
  props: ['cookieName', 'cookieText', 'cookieButtonLabel'],
  methods: {
    hideDisclaimer: function() {
      setCookie(this.cookieName, 'true', 365);

      this.$refs.container.classList.add('cookieDisclaimer--hidden');
    }
  },
  mounted() {
    var cookie = getCookie(this.cookieName);
    if (cookie) {
      this.$refs.container.classList.add('cookieDisclaimer--hidden');
    }
  }
};
</script>
