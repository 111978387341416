var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-datepicker" },
    [
      _c(
        "DatePicker",
        {
          attrs: {
            lang: "da",
            range: _vm.range,
            "popup-class": _vm.popupModifiers,
            "disabled-date": _vm.isDisabledDate,
            clearable: _vm.clearable
          },
          on: {
            change: _vm.onChange,
            open: function($event) {
              _vm.open = true
            },
            close: function($event) {
              _vm.open = false
            }
          },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        },
        [
          _c("c-icon", {
            staticClass: "icon-calendar",
            attrs: {
              slot: "icon-calendar",
              "symbol-id": _vm.open ? "arrow-up-small" : "arrow-down-small"
            },
            slot: "icon-calendar"
          }),
          _vm._v(" "),
          _vm.clearable
            ? _c("c-icon", {
                staticClass: "icon-clear",
                attrs: { slot: "icon-clear", "symbol-id": "menu-close" },
                slot: "icon-clear"
              })
            : _vm._e(),
          _vm._v(" "),
          _c("c-input-group", {
            attrs: {
              slot: "input",
              value: _vm.stringifyDate,
              "disable-clear": true,
              "place-holder-label": _vm.label
            },
            slot: "input"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.inputName },
        domProps: { value: _vm.formDate }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }