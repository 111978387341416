<template>
  <div class="cm-o-layout cm-o-layout--main">
    <div class="cm-container">
      <div>
        <a :href="productComparison.previousPageLink" class="cm-link--back">{{
          productComparison.previousPageText
        }}</a>
      </div>
      <fieldset>
        <section class="c-compare">
          <h2 class="sr-only">Comparison</h2>
          <h2 class="c-compare__headline c-typography-h4">
            {{ productComparison.pageHeadLine }}
          </h2>

          <header class="c-compare__header">
            <template v-for="(item, itemIdx) in productComparison.products">
              <c-compare-product-card
                :key="itemIdx"
                :product="item.key"
                :button-caption="productComparison.buttonCaption"
              />
            </template>
          </header>

          <section class="c-compare__body">
            <template
              v-for="(
                specificationLabel, specificationLabelIdx
              ) in productComparison.specificationLabels"
            >
              <div
                :key="specificationLabelIdx"
                class="c-compare__specification_container"
              >
                <h2 class="c-typography-h5">
                  {{ specificationLabel.headLine }}
                </h2>
                <c-compare-icon-row
                  :specification-label="specificationLabel"
                  :product-property-values="productPropertyValues"
                />
                <button
                  class="c-compare__toggle-button c-typography-body-sm"
                  @click.prevent="toggleSection(specificationLabelIdx)"
                >
                  {{
                    expandedSections.includes(specificationLabelIdx)
                      ? productComparison.showLessText
                      : productComparison.showMoreText
                  }}
                  <c-icon
                    :symbol-id="
                      expandedSections.includes(specificationLabelIdx)
                        ? 'arrow-up'
                        : 'arrow-down'
                    "
                  />
                </button>
                <div
                  class="c-compare__specification-list"
                  :class="{
                    expanded: expandedSections.includes(specificationLabelIdx)
                  }"
                >
                  <template v-if="specificationLabel.propertyGroups.length > 0">
                    <div
                      v-for="(
                        propertyGroup, propertyGroupIdx
                      ) in specificationLabel.propertyGroups"
                      :key="propertyGroupIdx"
                    >
                      <h3 class="c-typography-body">
                        {{ propertyGroup.headLine }}
                      </h3>
                      <c-compare-specification-list-row
                        :specification-list="propertyGroup.specificationList"
                        :headline="propertyGroup.headLine"
                        :product-property-values="productPropertyValues"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <c-compare-specification-list-row
                      :specification-list="specificationLabel.specificationList"
                      :headline="specificationLabel.headLine"
                      :product-property-values="productPropertyValues"
                    />
                  </template>
                </div>
              </div>
            </template>
          </section>
        </section>
      </fieldset>
    </div>
  </div>
</template>

<script>
import CCompareProductCard from './c-compare-product-card.vue';
import CCompareSpecificationListRow from './c-compare-specification-list-row.vue';
import CCompareIconRow from './c-compare-icon-row.vue';

export default {
  name: 'CCompare',

  components: {
    CCompareProductCard,
    CCompareSpecificationListRow,
    CCompareIconRow
  },

  props: {
    productComparison: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      expandedSections: [],
      productPropertyValues: []
    };
  },

  created() {
    this.mapProductProperties(this.productComparison.products);
  },

  methods: {
    toggleSection(sectionId) {
      const idx = this.expandedSections.indexOf(sectionId);
      if (idx > -1) {
        this.expandedSections.splice(idx, 1);
      } else {
        this.expandedSections.push(sectionId);
      }
    },
    mapProductProperties(products) {
      products.forEach(product => {
        this.productPropertyValues.push(
          product.value.groupedSpecifications
            .map(spec => spec.specifications)
            .flat()
            .reduce((acc, specification) => {
              acc[specification.property] = specification.value;
              return acc;
            }, {})
        );
      });
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare {
  $root: &;
  max-width: 960px;
  margin: auto;
  &__headline {
    text-align: center;
    margin-bottom: $u-600;
  }
  &__header {
    display: flex;
    justify-content: space-evenly;
    gap: $u-400;
    margin-bottom: $u-800;
    @include mq($screen-md, max) {
      gap: $u-250;
    }
  }
  &__specification_container {
    border-top: 1px solid $c-nt-500;
    padding-top: $u-500;
  }
  &__toggle-button {
    background: transparent;
    border: none;
    color: $c-pm-500;
    display: flex;
    align-items: center;
    gap: $u-250;
    margin-bottom: $u-300;
    svg {
      width: $u-300;
      height: $u-300;
      fill: $c-pm-500;
    }
  }
  &__specification-list {
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
    pointer-events: none;
    transition: all 0.125s ease-in-out;
    max-height: 0;
    will-change: opacity, transform, max-height;
    h3 {
      padding-top: $u-300;
      font-weight: 600;
    }
    &.expanded {
      border-top: 1px solid $c-nt-300;
      opacity: 1;
      transform: scaleY(1);
      pointer-events: inherit;
      max-height: 100%;
    }
  }
}
</style>
