export const SLIDE_DIR = {
  UP: 'UP',
  RIGHT: 'RIGHT',
  DOWN: 'DOWN',
  LEFT: 'LEFT'
};

export const AXELS = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL'
};