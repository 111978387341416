<template>
  <div>
    <div class="cm-numcon__product__number">
      <svg class="cm-icon--teal cm-icon--md">
        <use
          xlink:href="/src/apps/webshop/callme/images/icons.svg#icon-checkmark"
        ></use>
      </svg>
      <span>{{ moveLabel }}:</span><strong>{{ simData.phoneNumber }}</strong>
    </div>
    <div class="cm-grid cm-c-checkout__form-group cm-c-checkout-multinumbers">
      <div
        class="cm-grid__col--xs-12 cm-grid__col--md-8 cm-c-form-control-group"
      >
        <input
          v-model="simData.sim"
          :required="!simData.simUnavailable"
          :disabled="simData.simUnavailable"
          pattern="^\d{11,20}$"
          type="text"
          class="cm-c-form-control cm-js-material-input"
        />
        <span class="cm-c-form-control__bar"></span>
        <label class="cm-c-form-control__label">{{ simLabel }}</label>
        <span class="field-validation-error" hidden>{{ simInvalid }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import NumberDiva from './number-diva.vue';
export default {
  name: 'number-diva-items',
  components: {
    NumberDiva
  },
  props: {
    simData: Object,
    simLabel: {
      type: String,
      default: '',
      required: true
    },
    moveLabel: {
      type: String,
      default: '',
      required: true
    },
    simInvalid: {
      type: String,
      default: '',
      required: false
    }
  },
  watch: {
    simData: {
      handler: function(newValue) {},
      deep: true
    }
  }
};
</script>
