var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showErrorState
      ? _c("div", [
          _c(
            "div",
            {
              staticClass:
                "c-checkout-resurs-financing__phone-secure-icon__container"
            },
            [
              _c("c-icon", {
                staticClass: "c-checkout-resurs-financing__phone-secure-icon",
                attrs: { "symbol-id": "logo-phone-secure" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-checkout-resurs-financing__header" }, [
            _c("h2", { staticClass: "c-checkout-resurs-financing__subtitle" }, [
              _vm._v("\n        " + _vm._s(_vm.content.pageTitle) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "c-checkout-resurs-financing__content",
              domProps: { innerHTML: _vm._s(_vm.content.pageDescription) }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-checkout-resurs-financing__btn-container" },
            [
              _c(
                "c-button",
                {
                  staticClass: "c-btn",
                  attrs: { loading: _vm.goToResursButtonIsLoading },
                  on: { click: _vm.redirectToResurs }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.content.goToResursButtonText) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-checkout-resurs-financing__resurs-logo__container"
            },
            [
              _c("c-icon", {
                staticClass: "c-checkout-resurs-financing__resurs-logo",
                attrs: { "symbol-id": "logo-resurs-bank" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showRedirectionError
            ? _c(
                "div",
                { staticClass: "c-checkout-resurs-financing__redirect-error" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-checkout-resurs-financing__redirect-error__text"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.content.redirectionErrorText) +
                          "\n      "
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "c-checkout-resurs-financing__failure-section" },
            [
              _c("c-icon", {
                staticClass:
                  "c-checkout-resurs-financing__failure-section__icon",
                attrs: { "symbol-id": "general-crossmark" }
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "c-checkout-resurs-financing__content",
                domProps: { innerHTML: _vm._s(_vm.content.paymentErrorText) }
              }),
              _vm._v(" "),
              _c(
                "c-button",
                { staticClass: "c-btn", on: { click: _vm.showFinancePage } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.content.tryAgainButtonText) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }