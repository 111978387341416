import Vue from 'vue';
import VueResource from 'vue-resource';
import {
  ajaxGet,
  ajaxPost,
  toFullQuery
} from '../../../../shared/utils/ajax.js';

export default {
  track(parameters) {
    const url = toFullQuery('/api/olympus/track', parameters);
    Vue.http.get(url).then(response => {
      return {};
    });
  }
};
