/**
 * todo: write
 *
 * @param {*} val
 */
export const isUndefined = val => val === undefined;

/**
 * todo: write
 *
 * @param {*} val
 */
export const isNull = val => val === null;

/**Checks if the param is in the union of null and undefined
 *
 * @param {*} val
 */
export const isNullOrUndefined = value => isUndefined(value) || isNull(value);

/**
 * checks if object is string
 *
 * @param {*} val
 */
export const isString = val => typeof val === 'string';
