<template>
  <c-card extra-padding no-shadow no-rounding class="upfront-payment">
    <h1 class="c-typography-h5">{{content.headline}}</h1>
    <p v-html="content.description"></p>
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';

export default {
  name: 'UpfrontPayment',

  components: {
    CCard,
  },

  props: {
    blockId: {
      type: Number,
      default: 56157
    },

    content: {
      type: Object,
      default: () => ({})
    },

    contentLocal: {
      type: Object,
      default: () => ({})
    }
  },
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';

.upfront-payment {
  margin: $u-600 0;

  .c-card__body {
    $card-padding-v: $u-800;
    padding-top: $card-padding-v;
    padding-bottom: $card-padding-v;

    h1{
      margin-bottom: $u-200;
    }

    p{
      font-size: $global-font-size;
    }
  }
}
</style>
