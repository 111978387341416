var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vas
    ? _c(
        "article",
        {
          staticClass: "c-vas-card",
          class: [
            !_vm.blockItem ? "c-vas-card--bottom-gap" : "",
            _vm.classObject
          ]
        },
        [
          _vm.promoted
            ? _c(
                "div",
                {
                  staticClass: "c-vas-card__promotion",
                  style: {
                    backgroundImage: "url(" + _vm.promotedImageUrl + ")"
                  },
                  attrs: { "aria-label": _vm.promotedVasAriaLabel }
                },
                [
                  _c("div", { staticClass: "c-vas-card__badge" }, [
                    _vm.vas.badgeText && _vm.vas.badgeText.item2 !== ""
                      ? _c(
                          "span",
                          {
                            staticClass: "c-badge c-badge--top-left",
                            class: _vm.vas.badgeText.item1
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.vas.badgeText.item2) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.vas.ribbonText
            ? _c(
                "div",
                {
                  class:
                    "c-vas-card__badge c-badge c-badge--top-left c-badge--" +
                    _vm.vas.ribbonColor
                },
                [_vm._v("\n    " + _vm._s(_vm.vas.ribbonText) + "\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class:
                "c-vas-card__card " +
                (_vm.blockItem ? "c-vas-card__card--block-item" : ""),
              on: { click: _vm.handleAddClickEvent }
            },
            [
              _c("div", { staticClass: "c-vas-card__image" }, [
                _c("img", {
                  attrs: { src: _vm.vas.imageUrl, alt: _vm.vas.displayName }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { ref: "info", staticClass: "c-vas-card__info" },
                [
                  _c(
                    "hgroup",
                    { class: "" + (_vm.blockItem ? "c-vas-card__hgroup" : "") },
                    [
                      _c("h5", { staticClass: "c-vas-card__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.vas.displayName) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.vas.price
                        ? _c(
                            "div",
                            { staticClass: "c-vas-card__price" },
                            [
                              _vm.blockItem
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "c-vas-card__price--original"
                                      },
                                      [
                                        _c("del", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.vas.originalPrice) +
                                              "\n              "
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "c-vas-card__price--new" },
                                      [_vm._v(_vm._s(_vm.vas.price))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "c-vas-card__price-label"
                                      },
                                      [_vm._v(_vm._s(_vm.vas.priceLabel))]
                                    )
                                  ]
                                : [
                                    _vm.vas.originalPrice || _vm.vas.substitute
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "c-vas-card__price--gray"
                                          },
                                          [
                                            _c("del", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.vas.originalPrice
                                                    ? _vm.vas.originalPrice
                                                        .formattedValueCurrency
                                                    : _vm.vas.price
                                                        .formattedValueCurrency
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.vas.substitute
                                            ? _vm.vas.substitute.price
                                                .formattedValueCurrency
                                            : _vm.vas.price
                                                .formattedValueCurrency
                                        )
                                      )
                                    ])
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.vas.descriptionShort
                    ? _c("p", { staticClass: "c-vas-card__summary" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.vas.descriptionShort) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vas.state && _vm.vas.state.description
                    ? _c("p", { staticClass: "c-vas-card__status" }, [
                        _c("em", [_vm._v(_vm._s(_vm.vas.state.description))])
                      ])
                    : _vm.vas.description
                    ? [
                        _c(
                          "c-collapse",
                          {
                            attrs: {
                              id: "vas-expand-" + _vm.vas.code + "-" + _vm._uid
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "c-vas-card__description",
                              domProps: {
                                innerHTML: _vm._s(_vm.vas.description)
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _c("c-collapse-trigger", {
                              staticClass: "c-vas-card__expand",
                              attrs: {
                                id:
                                  "vas-expand-" + _vm.vas.code + "-" + _vm._uid
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var expanded = ref.expanded
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              expanded
                                                ? _vm.close
                                                : _vm.readMore
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3919360645
                              )
                            })
                          ],
                          1
                        )
                      ]
                    : _c(
                        "p",
                        {
                          staticClass:
                            "c-vas-card__expand c-vas-card__expand--is-hidden"
                        },
                        [_vm._v("\n         \n      ")]
                      )
                ],
                2
              ),
              _vm._v(" "),
              _vm.asRadioSelect
                ? _c("c-input", {
                    attrs: {
                      "data-testid": "radio-select",
                      name: "vas",
                      type: "radio"
                    },
                    on: { change: _vm.handleLoyaltyVasClick }
                  })
                : !_vm.blockItem
                ? _c("div", { staticClass: "c-vas-card__action" }, [
                    _vm.vas.state &&
                    _vm.vas.state.interaction !==
                      _vm.STATE_INTERACTION.NO_CHANGE
                      ? _c(
                          "div",
                          [
                            _vm.networking && _vm.localLoading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cm-c-spinner cm-c-spinner--local"
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading...")
                                    ])
                                  ]
                                )
                              : _c("c-icon", {
                                  class: _vm.iconClassModifier,
                                  attrs: {
                                    role: "img",
                                    "aria-label": _vm.removeVasAriaLabel,
                                    "symbol-id": _vm.added
                                      ? "service-trash"
                                      : "service-addbasket"
                                  }
                                })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.priceDisclaimerText
            ? _c("p", { staticClass: "c-vas-card__price-disclaimer" }, [
                _vm._v("\n    " + _vm._s(_vm.priceDisclaimerText) + "\n  ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.vas.substitute
            ? _c(
                "div",
                { staticClass: "c-vas-card__banner" },
                [
                  _c("c-icon", {
                    attrs: { "symbol-id": "service-information" }
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.oldVasPrice))])
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }