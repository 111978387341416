import { get, post } from './api';

export const getBillingOptions = async () => {
  const response = await get('api/olympus/payments/billingOptions', {
    mvc: true,
    headers: {
      accept: 'application/vnd.api+json'
    }
  });

  return response.data?.data?.attributes?.billingMethods ?? [];
};

export const getPaymentOptions = () => {
  return get('api/olympus/payments/paymentOptions', {
    mvc: true,
    headers: {
      accept: 'application/vnd.api+json'
    }
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error.errors);
    });
};

export const validatePaymentOption = (
  selectedPaymentType,
  pbsRegistrationNumber,
  pbsAccountNumber
) => {
  const data = {
    data: {
      attributes: {
        selectedType: selectedPaymentType,
        pbsRegistrationNumber: pbsRegistrationNumber,
        pbsAccountNumber: pbsAccountNumber
      },
      type: 'SelectBillingOptionRequest'
    }
  };

  return post('/api/olympus/payments/billingOptions', data, {
    mvc: true,
    headers: {
      accept: 'application/vnd.api+json'
    }
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error.errors);
    });
};

export const createPayment = data => {
  return post('/api/olympus/payments/createpayment', data, {
    mvc: true,
    headers: {
      accept: 'application/vnd.api+json'
    }
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error.errors);
    });
};

export const validatePaymentDetails = params => {
  return get('/api/olympus/payments/validatePaymentDetails', {
    params,
    mvc: true,
    headers: {
      accept: 'application/vnd.api+json'
    }
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error.errors);
    });
};

export const getResursBankPaymentUrl = params => {
  return post('/finance-payments/create-payment', params);
};
