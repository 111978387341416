<template>
  <div>
    <c-gallery-content v-bind="$props">
      <template slot="selectedFooter">
        <slot name="selectedFooter" />
      </template>
    </c-gallery-content>
    <c-gallery-overlay v-if="overlay" ref="galleryOverlay"></c-gallery-overlay>
  </div>
</template>

<script>
import CGalleryContent from './c-gallery-content.vue';
import CGalleryOverlay from './c-gallery-overlay.vue';

/**
 * A gallery with generic input scoped data. Mandatory
 * properties per object are { src: , thumbnail: , type: , ... }
 * rest of the data can be used from within the scoped slots.
 *
 */

export default {
  name: 'c-gallery',

  components: {
    CGalleryContent,
    CGalleryOverlay
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    transform: {
      type: Function,
      default: undefined
    },
    selectedIndex: {
      type: Number,
      default: 0
    },
    minVisible: {
      type: Number,
      default: 5
    },
    smallImageSize: {
      type: Number,
      default: 320
    },
    largeImageSize: {
      type: Number,
      default: 480
    },
    smallImageWidth: {
      type: String,
      default: '100vw'
    },
    largeImageWidth: {
      type: String,
      default: '480px'
    },
    selectedImageBreakpoint: {
      type: Number,
      default: 1600
    },
    navigation: {
      type: Boolean,
      default: true
    },
    navigationRounded: {
      type: Boolean,
      default: false
    },
    hideSingle: {
      type: Boolean,
      default: true
    },
    thumbnailPosition: {
      type: String,
      default: 'bottom',
      validator(val) {
        return ['bottom', 'left'].indexOf(val) !== -1;
      }
    },
    selectedSlidePressCallback: {
      type: Function,
      default: undefined
    },
    overlay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss"></style>
