<script>
import { mapGetters } from 'vuex';
import { GETTERS as APP_GETTERS } from '../../../../shared/store/store.js';
import { GETTERS as BASKET_GETTERS } from '../../../../shared/store/basket/index.js';

import { ITEM_TYPES } from '../../../enums/access-technology-types.js';
import ITEM_EQUIPMENT_TYPES from '../../../enums/item-equipment-types.js';
import {
  BASKET_ITEM_SUB_TYPES,
  BASKET_ITEM_ACTIONS
} from '../basket-constants.js';

import {
  CCheckbox,
  CCollapse,
  CModal,
  CButton
} from 'olympus/components/index.js';

import { CIcon } from 'webshop/components';
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import ExistingSubscriptionSelector from 'webshop/components/product/existing-subscription-selector.vue';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';

import withTemplate from 'theme/templates/shopping-cart-item.html';

export default withTemplate({
  components: {
    CCollapse,
    CCheckbox,
    CModal,
    CButton,
    CIcon,
    ExistingSubscriptionSelector,
    PopoverPanel
  },

  mixins: [ContentPropertyMixin],

  props: {
    item: {
      type: Object,
      required: true
    },
    bindingPeriodText: {
      type: String,
      required: true
    },
    installmentForMonthsTextTemplate: {
      type: String,
      required: true
    },
    removeButtonIcon: {
      type: String,
      default: 'menu-close'
    },
    isBundle: {
      type: Boolean,
      default: false
    },
    promotionCodeAppliedLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      show: false,
      ITEM_TYPES,
      BASKET_ITEM_SUB_TYPES,
      BASKET_ITEM_ACTIONS
    };
  },

  computed: {
    ...mapGetters({
      authenticated: APP_GETTERS.USER_AUTHENTICATED,
      numberConfigurationEnabled: BASKET_GETTERS.NUMBER_CONFIGURATION_ENABLED,
      isThirdPartyReseller: APP_GETTERS.IS_THIRD_PARTY_RESELLER,
      disclaimers: APP_GETTERS.DISCLAIMERS
    }),
    attributes() {
      return this.item.attributes || {};
    },
    selectedPhoneNumberSubTypeCheck() {
      return (
        this.item.subType === this.BASKET_ITEM_SUB_TYPES.VOICE ||
        this.item.subType === this.BASKET_ITEM_SUB_TYPES.DATA
      );
    },
    showSelectedPhoneNumber() {
      return (
        this.numberConfigurationEnabled &&
        this.authenticated &&
        this.item.type === this.ITEM_TYPES.SUBSCRIPTION &&
        this.selectedPhoneNumberSubTypeCheck
      );
    },
    selectedPhoneNumber() {
      return this.attributes.number || null;
    },
    installments() {
      return (
        this.item.installments &&
        this.item.installments > 1 &&
        this.populateContentProperty(this.installmentForMonthsTextTemplate, {
          period: this.item.installments
        })
      );
    },
    binding() {
      return (
        this.item.bindingPeriod &&
        this.item.bindingPeriod > 1 &&
        this.populateContentProperty(this.bindingPeriodText, {
          paymentPeriod: this.item.bindingPeriod
        })
      );
    },
    hasImage() {
      return Boolean(this.item.image && this.item.image.src);
    },
    productDisclaimerTexts() {
      // TODO DKT-1419: Should be changed when all dislaimers is provided in a list
      let productDisclaimers = this.disclaimers
        ?.filter(
          o =>
            this.item.productDisclaimers?.some(d => d === o.code) &&
            o.name !== ITEM_EQUIPMENT_TYPES.TECHNICIANFEE
        )
        .map(o => o.message);

      if (productDisclaimers) {
        return productDisclaimers.slice(0, 2);
      }

      return;
    },
    technicianDisclaimer() {
      if (this.item.type === ITEM_TYPES.INSTALLATION_FEE) {
        return this.disclaimers?.find(
          o => o.name === ITEM_EQUIPMENT_TYPES.TECHNICIANFEE
        );
      }
      return false;
    }
  },

  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 300);
  },

  methods: {
    toggle() {
      this.$emit('basket-item-action', {
        type: BASKET_ITEM_ACTIONS.TOGGLE,
        item: this.item
      });
    },
    remove() {
      this.$emit('basket-item-action', {
        type: BASKET_ITEM_ACTIONS.REMOVE,
        item: this.item
      });
    }
  }
});
</script>

<style lang="scss">
.shopping-cart-item {
  transform: scale(0);
  transition: transform 0.3s ease;
  will-change: transform;

  &.show {
    transform: scale(1);
  }
}
</style>
