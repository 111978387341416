<template>
  <div class="c-modal-wrapper-inner">
    <header class="c-modal-content__header">
      <h3 v-html="errorObject.errorHeader"></h3>
      <p v-html="errorObject.errorText"></p>
      <p v-html="errorObject.errorTextSub"></p>
    </header>

    <footer v-if="errorObject.errorLink" class="c-modal-content__footer">
      <button
        v-if="errorObject.errorLink.post"
        type="button"
        class="c-btn c-btn--primary"
        @click="postURL(errorObject.errorLink.href)"
      >
        {{ errorObject.errorLink.label || 'OK' }}
      </button>
      <a
        v-else-if="errorObject.errorLink.href"
        :href="errorObject.errorLink.href"
        class="c-btn c-btn--primary"
        v-html="errorObject.errorLink.label || 'OK'"
      ></a>
      <a v-else class="c-btn c-btn--primary" @click="HIDE_DIALOG">
        {{ errorObject.errorLink.label || 'OK' }}
      </a>
    </footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ErrorDialog',
  props: {
    errorObject: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapMutations(['HIDE_DIALOG']),
    postURL(url) {
      fetch(url, {
        credentials: 'include',
        method: 'post',
        body: ''
      }).then(this.HIDE_DIALOG());
    }
  }
};
</script>
