var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("c-card", { ref: "fieldset", staticClass: "cm-numcon" }, [
        _vm.numberConfigActiveProduct
          ? _c("div", { staticClass: "cm-numcon__config" }, [
              _c("div", { staticClass: "cm-numcon__product" }, [
                _c("div", { staticClass: "cm-numcon__product__info" }, [
                  _c("img", {
                    staticClass: "cm-numcon__image",
                    attrs: {
                      src: _vm.numberConfigActiveProduct.img,
                      alt: _vm.numberConfigActiveProduct.subscriptionName
                    }
                  }),
                  _vm._v(" "),
                  _c("section", { staticClass: "cm-numcon__product__text" }, [
                    _vm.numberConfigVM.subscriptionLabel
                      ? _c(
                          "p",
                          { staticClass: "cm-numcon__product__pre-label" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.numberConfigVM.subscriptionLabel) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numberConfigActiveProduct.subscriptionName
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "\n                cm-numcon__product__header\n                cm-numcon__product__header--specific\n              "
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.numberConfigActiveProduct.subscriptionName
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.numberConfigActiveProduct.handsetName
                      ? _c(
                          "div",
                          { staticClass: "cm-numcon__product__header" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.numberConfigActiveProduct.handsetName
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-numcon__options" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "cm-numcon__options__item cm-numcon__options__item--active"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cm-numcon__options__link cm-numcon__options__link--default"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "cm-numcon__options__icon" },
                          [
                            _c("c-icon", {
                              attrs: { "symbol-id": "menu-search" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "cm-numcon__options__header" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.numberConfigVM.newNumberOverviewHeadline
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "cm-numcon__options__content" },
                      [
                        _vm.phoneNumberIsNotInList &&
                        _vm.configType ===
                          _vm.numberConfigActiveProduct.configType
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "\n                  cm-c-form__col\n                  cm-numcon__product__number\n                  cm-numcon__product__number--not-in-list\n                "
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "cm-icon--teal cm-icon--md"
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/src/apps/webshop/callme/images/icons.svg#icon-checkmark"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.numberConfigText(_vm.configType)
                                        ) +
                                        ":\n                "
                                    ),
                                    _c("strong", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.showNewNumber(
                                              _vm.numberConfigActiveProduct
                                                .phoneNumber
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasNumberSearchError && _vm.errorDescriptionTitle
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "\n                  cm-c-form__col\n                  cm-numcon__number-search-error-description\n                "
                                  },
                                  [
                                    _c("h4", [
                                      _vm._v(_vm._s(_vm.errorDescriptionTitle))
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.errorDescription))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.numberConfigVM.enableNumberSearch
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row mb-4" },
                              [
                                _c(
                                  "c-col",
                                  { staticClass: "cm-c-form__col" },
                                  [
                                    _c("c-input-group", {
                                      ref: "search",
                                      attrs: {
                                        "input-error": _vm.numberSearchError,
                                        "place-holder-label": _vm.hasNumberSearchError
                                          ? _vm.numberConfigVM
                                              .findNewNumberLabel
                                          : _vm.numberConfigVM
                                              .searchNewNumberLabel,
                                        name: "numbersearchpattern",
                                        maxlength: 8
                                      },
                                      on: {
                                        keydown: function($event) {
                                          return _vm.searchPatternKeydown(
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.searchPattern,
                                        callback: function($$v) {
                                          _vm.searchPattern = $$v
                                        },
                                        expression: "searchPattern"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "cm-c-form-control__button",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.fetchContent(true)
                                          }
                                        }
                                      },
                                      [
                                        _c("c-icon", {
                                          staticClass:
                                            "cm-numcon__number-search-button--icon",
                                          attrs: { "symbol-id": "menu-search" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.showSearchHelpTip &&
                        _vm.numberConfigVM.numberSearchHelpTip
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c("c-col", { staticClass: "cm-c-form__col" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "cm-numcon__number-search-help"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "cm-numcon__number-search-help-button",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.toggleSearchHelp(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("c-icon", {
                                            staticClass:
                                              "cm-numcon__number-search-help--icon",
                                            attrs: {
                                              "symbol-id": "contact-information"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.showSearchHelpTip
                                        ? _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.numberConfigVM
                                                  .numberSearchHelpTip
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showSearchHelpTip &&
                        _vm.numberConfigVM.numberSearchHelpTip
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "cm-c-form__col cm-numcon__number-search-help-expanded"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "cm-numcon__number-search-help-expanded--button",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.toggleSearchHelp($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("c-icon", {
                                          staticClass:
                                            "cm-numcon__number-search-help-expanded--icon",
                                          attrs: {
                                            "symbol-id": "contact-information"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.numberConfigVM.numberSearchHelpTip
                                        )
                                      }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.hasNumberSearchError
                          ? _c(
                              "c-row",
                              { staticClass: "cm-c-form__row" },
                              [
                                _c(
                                  "c-col",
                                  {
                                    staticClass:
                                      "cm-c-form__col cm-numcon__product__number-group"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "cm-numcon__numbers" },
                                      [
                                        _c(
                                          "c-form-radio-group",
                                          {
                                            attrs: { name: "number-new" },
                                            model: {
                                              value: _vm.phoneNumber,
                                              callback: function($$v) {
                                                _vm.phoneNumber = $$v
                                              },
                                              expression: "phoneNumber"
                                            }
                                          },
                                          _vm._l(_vm.numbersToShow, function(
                                            n
                                          ) {
                                            return _c(
                                              "c-form-radio",
                                              {
                                                key: n.number,
                                                attrs: {
                                                  id: n.number,
                                                  value: n.number
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      n.formattedNumber
                                                    )
                                                  }
                                                })
                                              ]
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.checkNumberPool().length >
                                    _vm.numbersToShow.length
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "\n                    cm-c-checkout__link cm-c-checkout__link--center\n                    cm-numcon__see-more-numbers-link\n                  ",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchContent(true)
                                              }
                                            }
                                          },
                                          [
                                            _c("c-icon", {
                                              staticClass:
                                                "cm-numcon__see-more-numbers-link__icon",
                                              attrs: {
                                                "symbol-id": "general-shuffle"
                                              }
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.numberConfigVM
                                                    .seeMoreNumbersLabel
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isEnterprise
                          ? _c("number-subinfo", {
                              key: "subinfo" + _vm.keyToReRender,
                              attrs: { "active-product": _vm.$data }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isEnterprise ||
                        (_vm.IS_THIRD_PARTY_RESELLER &&
                          _vm.numberConfigVM.showSimDeliveryOptions)
                          ? _c("number-simcard-info", {
                              key: "simcardinfo" + _vm.keyToReRender,
                              attrs: { "active-product": _vm.$data }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.numberConfigActiveProduct &&
                        !_vm.numberConfigActiveProduct
                          .isSubscriptionMobileBroadBand
                          ? _c("number-optional", {
                              key: "optional" + _vm.keyToReRender,
                              on: {
                                hiddenNumber: _vm.setHiddenNumber,
                                unlistedNumber: _vm.setUnlistedNumber
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.numberConfigVM.consents && _vm.numberConfigVM.consents.length
        ? _c(
            "div",
            { staticClass: "cm-numcon__actions" },
            [
              _c("number-consents", {
                key: "consent" + _vm.keyToReRender,
                attrs: { "active-product": _vm.$data }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cm-numcon__actions" },
        [
          _c("c-button", { attrs: { to: "/", variant: "secondary" } }, [
            _vm._v("Tilbage")
          ]),
          _vm._v(" "),
          _c(
            "c-button",
            {
              attrs: { disabled: !_vm.ready },
              on: {
                click: function($event) {
                  return _vm.accept("newnum")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.numberConfigVM.acceptNumberButtonText) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }