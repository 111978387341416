import { createNamespace } from '../utils.js';

import { setSignature } from 'webshop/api/checkout-service.js';

export const MUTATIONS = createNamespace('SIGNATURE/MUTATIONS', [
  'SET_SIGNATURE'
]);

export const ACTIONS = createNamespace('SIGNATURE/ACTIONS', ['SET_SIGNATURE']);

export const GETTERS = createNamespace('SIGNATURE/GETTERS', ['GET_SIGNATURE']);

/**
 * Manages state for signature block.
 */
export default {
  state: {
    signatureImage: ''
  },

  mutations: {
    [MUTATIONS.SET_SIGNATURE](state, imageString) {
      state.signatureImage = imageString;
    }
  },

  actions: {
    [ACTIONS.SET_SIGNATURE]: ({ commit }, params) => {
      commit(MUTATIONS.SET_SIGNATURE, params);
      return setSignature(params);
    }
  },

  getters: {
    [GETTERS.GET_SIGNATURE]: state => {
      return state.signatureImage;
    }
  }
};
