<template>
  <c-modal ref="vasModal" wide large gray>
    <c-vas-overview :block-id="vasCheckoutModuleBlockId" :group-id="group.id" />
    <button
      v-if="group"
      class="c-btn c-btn--wide c-btn--block c-btn--align-center"
      @click="closeVasModal"
    >
      {{ content.closeVasOverlayText }}
    </button>
  </c-modal>
</template>

<script>
import { CVasOverview } from '../vas';

export default {
  name: 'AddVasOverlay',

  components: {
    CVasOverview
  },

  props: {
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    vasCheckoutModuleBlockId: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      group: false
    };
  },

  mounted() {
    this.$root.$on('add-vas-overlay', this.openVasModal);
  },

  methods: {
    openVasModal(e) {
      if (!this.vasCheckoutModuleBlockId) {
        return;
      }

      this.group = e.group;
      this.$refs.vasModal.show(e);
    },
    closeVasModal(e) {
      this.$refs.vasModal.hide(e);
    }
  }
};
</script>

<style lang="scss"></style>
