class Ribbon {
  constructor(element, settings = {}) {
    this.settings = {
      ...{
        marqueeClassName: 'c-usp--marquee',
        stackClassName: 'c-usp--stack',
        listSelectorDesktop: '.c-usp__list--desktop',
        listSelectorMobile: '.c-usp__list--mobile'
      },
      ...settings
    };
    this.animationController = {
      delay: 2500,
      stackOnMobile: false,
      isAnimating: false,
      containerElement: undefined,
      currentElement: undefined
    };
    this.init(element);
  }

  init(element) {
    this.animationController.stackOnMobile =
      element.dataset.stackOnMobile || false;
    this.animationController.containerElement = element.querySelector(
      this.settings.listSelectorMobile
    );

    const ro = new ResizeObserver(entries => {
      entries.forEach(({ target, contentRect: { width } }) => {
        const { offsetWidth } = target.querySelector(
          this.settings.listSelectorDesktop
        );

        if (offsetWidth >= width) {
          if (this.animationController.stackOnMobile === 'true') {
            element.classList.add(this.settings.stackClassName);
          } else if (!this.animationController.isAnimating) {
            element.classList.add(this.settings.marqueeClassName);
            this.animationController.isAnimating = true;
            this.animationController.currentElement = this.animationController.containerElement.firstElementChild;
            this.animationController.currentElement.classList.add(
              'slide-in-right'
            );
            this.animationController.intervalHandler = setInterval(
              this.animateNext.bind(this),
              this.animationController.delay
            );
          }
        } else if (this.animationController.isAnimating) {
          element.classList.remove(this.settings.marqueeClassName);
          this.animationController.currentElement.classList.remove(
            'slide-in-right'
          );
          clearInterval(this.animationController.intervalHandler);
          this.animationController.isAnimating = false;
        } else {
          element.classList.remove(this.settings.stackClassName);
        }
      });
    });

    ro.observe(element);
  }

  switchToNextAnimationElement() {
    this.animationController.currentElement =
      this.animationController.currentElement.nextElementSibling ||
      this.animationController.containerElement.firstElementChild;
  }

  animateNext() {
    this.animationController.currentElement.classList.add('slide-out-left');
    this.animationController.currentElement.classList.remove('slide-in-right');
    this.switchToNextAnimationElement();
    this.animationController.currentElement.classList.remove('slide-out-left');
    this.animationController.currentElement.classList.add('slide-in-right');
  }
}

export const uspRibbon = element => new Ribbon(element);
