<template>
  <c-card extra-padding no-shadow no-rounding>
    <h3 class="cm-c-checkout__subtitle--mb">{{ content.headline }}</h3>
    <p class="cm-c-checkout__text" v-html="description"></p>

    <employee-receipt-disclaimers :disclaimers="disclaimers" />
    <employee-receipt-order-details
      :content="content"
      :order-data="orderData"
      :employee-data="employeeData"
    />
  </c-card>
</template>

<script>
import CCard from 'webshop/components/c-card/c-card.vue';
import EmployeeReceiptDisclaimers from './employee-receipt-disclaimers.vue';
import EmployeeReceiptOrderDetails from './employee-receipt-order-details.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MUTATIONS as CHECKOUT_MUTATIONS } from 'webshop/components/checkout/store';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  ACTIONS as HOME_OFFICE_ACTIONS
} from 'webshop/store/home-office/index.js';

import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';

import { populateContentProperty } from 'olympus/mixins/content-property-mixin';

export default {
  name: 'EmployeeReceipt',

  components: {
    CCard,
    EmployeeReceiptDisclaimers,
    EmployeeReceiptOrderDetails
  },

  mixins: [],

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    orderId: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters({
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA,
      orderData: HOME_OFFICE_GETTERS.GET_ORDER_DATA
    }),

    description() {
      return populateContentProperty(this.content.description, {
        userName: this.employeeData.firstName?.value,
        userEmail: this.employeeData.email?.value || this.orderData?.ownerEmail
      });
    },

    disclaimers() {
      const disclaimers = [];

      if (!this.orderData.cancelationAssist) {
        disclaimers.push(this.content.cancelYourSubscriptionDislaimer);
      }
      if (!this.orderData.hasPlug) {
        disclaimers.push(this.content.technicianNeededDislaimer);
      }
      if (this.orderData.cancelationAssist) {
        if (this.orderData.lineOwner) {
          disclaimers.push(
            populateContentProperty(
              this.content.specificLineOwnerSignPoaDislaimer,
              {
                ownerEmail:
                  this.orderData?.ownerEmail || this.employeeData.email?.value
              }
            )
          );
        } else {
          disclaimers.push(
            populateContentProperty(this.content.generalSignPoaDislaimer, {
              ownerEmail:
                this.orderData?.ownerEmail || this.employeeData.email?.value
            })
          );
        }
      }
      return disclaimers;
    }
  },

  mounted() {
    this.setEndpoint(HOME_OFFICE_STEPS.CHECKOUT_RECEIPT);
    this.fetchReceiptData(this.orderId);
  },

  methods: {
    ...mapMutations({
      setEndpoint: CHECKOUT_MUTATIONS.SET_CHECKOUT_ENDPOINT
    }),

    ...mapActions({
      fetchReceiptData: HOME_OFFICE_ACTIONS.FETCH_RECEIPT_DATA
    })
  }
};
</script>

<style lang="scss"></style>
