var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "store-autocomplete" }, [
    _c(
      "div",
      { staticClass: "store-autocomplete__search" },
      [
        _c("c-input-group", {
          ref: "autoComplete",
          attrs: {
            id: "search",
            name: "searchAddress",
            "place-holder-label": _vm.placeholder,
            "disable-autocomplete": "",
            "prevent-submit": ""
          },
          on: {
            input: function($event) {
              _vm.showAutoCompleteResults = true
            },
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.onArrowDown($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.onArrowUp($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onEnter($event)
              }
            ],
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
            },
            blur: function($event) {
              _vm.resultsIndexCounter = 0
            }
          },
          model: {
            value: _vm.autoCompleteValue,
            callback: function($$v) {
              _vm.autoCompleteValue = $$v
            },
            expression: "autoCompleteValue"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              "store-autocomplete__search__icon": true,
              "store-autocomplete__search__icon--fill": !_vm.autoCompleteValue,
              "store-autocomplete__search__icon--stroke": _vm.autoCompleteValue
            },
            on: { click: _vm.autoCompleteCloseClickHandler }
          },
          [
            !_vm.autoCompleteValue
              ? _c("c-icon", { attrs: { "symbol-id": "menu-search" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.autoCompleteValue
              ? _c("c-icon", {
                  staticClass: "close",
                  attrs: { "symbol-id": "menu-close" }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.showAutoCompleteResults &&
    _vm.searchResults.data.length &&
    _vm.searchResults.type === _vm.searchResultTypes.shop
      ? _c(
          "ul",
          {
            staticClass:
              "\n      store-autocomplete__results store-autocomplete__results--is-full-width\n    "
          },
          _vm._l(_vm.searchResults.data, function(result, i) {
            return _c(
              "li",
              {
                key: result.id,
                class: {
                  "store-autocomplete__results__item": true,
                  "store-autocomplete__results__item--flex": true,
                  "store-autocomplete__results__item--is-focused":
                    i === _vm.resultsIndexCounter
                },
                on: {
                  mouseOver: function($event) {
                    _vm.resultsIndexCounter = i
                  },
                  click: function($event) {
                    return _vm.selectAutoCompleteResult(
                      result.id,
                      result.storeName
                    )
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "store-autocomplete__results__item-texts" },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "store-autocomplete__results__item__headline"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(result.storeName) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "store-autocomplete__results__item__address"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(result.streetAndNumber) +
                            ", " +
                            _vm._s(result.zipCode) +
                            " " +
                            _vm._s(result.city) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                result.distance
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "store-autocomplete__results__item__distance"
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(result.distance) + "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm.showAutoCompleteResults &&
        _vm.searchResults.data.length &&
        _vm.searchResults.type === _vm.searchResultTypes.address
      ? _c(
          "ul",
          {
            staticClass:
              "\n      store-autocomplete__results store-autocomplete__results--is-full-width\n    "
          },
          [
            _vm.addressTitle
              ? _c(
                  "li",
                  {
                    staticClass:
                      "\n        store-autocomplete__results__item\n        store-autocomplete__results__item--is-caption\n      "
                  },
                  [_vm._v("\n      " + _vm._s(_vm.addressTitle) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.searchResults.data, function(result, i) {
              return _c(
                "li",
                {
                  key: "results-" + i,
                  ref: "result-" + i,
                  refInFor: true,
                  class: {
                    "store-autocomplete__results__item": true,
                    "store-autocomplete__results__item--is-focused":
                      i === _vm.resultsIndexCounter
                  },
                  on: {
                    mouseOver: function($event) {
                      _vm.resultsIndexCounter = i
                    },
                    click: _vm.selectAddressResult
                  }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "store-autocomplete__results__item__headline"
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(result.displayName) + "\n      "
                      )
                    ]
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }