<template>
  <!-- TODO: need to make a VM for SideMenuItem and have different Interface rendering per item (soem are content
  areas, some are custom markup like the one bellow (credit-check). For now hardcoded button with content.-->
  <div class="c-menu__side-button c-menu__side-button--box">
    <label
      class="c-menu__item c-menu__item--icon"
      for="menu-credit-check"
      tabindex="-1"
      @click="$refs.creditCheckModal.show()"
    >
      <c-icon :symbol-id="content.icon.markupId" />
      <span class="c-menu__item-txt">{{ content.openPopUpButtonCaption }}</span>
    </label>

    <c-modal
      id="creditCheckModal"
      ref="creditCheckModal"
      centered
      is-credit-check
      :title="content.popUpHeaderText"
    >
      <div>
        <div class="credit-check__body">
          <div v-if="!showDetails" class="credit-check__form">
            <div
              class="credit-check__form__input"
              :class="{ 'credit-check__form__input--error': inputError }"
            >
              <c-input-group
                v-model="searchText"
                :place-holder-label="content.cprInputLabel"
                :input-error="inputError"
                disable-autocomplete
                type="text"
                @keypress="clearErrors"
              />
              <c-icon
                class="credit-check__form__input__icon"
                symbol-id="menu-search"
              />
            </div>

            <c-checkbox
              id="acceptTerms"
              v-model="acceptCheckbox"
              class="credit-check__form__terms-checkbox"
              :checked="acceptCheckbox"
              @event-id="toggleAcceptCheckbox"
            >
              {{ content.checkboxLabelText }}
            </c-checkbox>

            <p
              v-if="showSearchErrorMessage"
              class="credit-check__form__error-message"
            >
              {{ searchErrorMessage }}
            </p>

            <c-heading
              v-if="
                content.enableNumberPortingOnCreditCheckModal ||
                content.enableBuyHardwareOnCreditCheckModal
              "
              class="credit-check__form__header"
              level="2"
              as="4"
            >
              {{ content.creditCheckModalMoreInformationHeader }}
            </c-heading>

            <c-checkbox
              v-if="content.enableNumberPortingOnCreditCheckModal"
              id="numberPorting"
              v-model="numberPorting"
              class="credit-check__form__nr-porting-checkbox"
              @event-id="toggleNumberPortingCheckbox"
            >
              {{ content.numberPortingCheckBoxText }}
            </c-checkbox>

            <c-checkbox
              v-if="content.enableBuyHardwareOnCreditCheckModal"
              id="hardwareWithCredit"
              v-model="hardwareWithCredit"
              @event-id="toggleHardwareWithCreditCheckbox"
            >
              {{ content.buyHardwareOnCreditCheckBoxText }}
            </c-checkbox>

            <c-heading
              v-if="content.enablePaymentOptionsOnCreditCheckModal"
              class="credit-check__form__header"
              level="2"
              as="4"
            >
              {{ content.paymentMethodHeader }}
            </c-heading>

            <c-form-radio-group
              v-if="content.enablePaymentOptionsOnCreditCheckModal"
              id="paymentMethod"
              v-model="paymentMethod"
              name="paymentMethod"
              stacked
            >
              <c-form-radio id="option1" :value="PAYMENT_METHODS.PBS">
                {{ content.pbsPaymentRadioButtonLabel }}
              </c-form-radio>

              <c-form-radio id="option2" :value="PAYMENT_METHODS.GIRO">
                {{ content.giroPaymentRadioButtonLabel }}
              </c-form-radio>
            </c-form-radio-group>
          </div>

          <div v-else class="credit-check__result">
            <c-data-list tabled>
              <dt>{{ content.popUpNameLabel }}</dt>
              <dd>{{ creditCheckDetails.name }}</dd>
              <dt v-if="content.popUpCprLabel">{{ content.popUpCprLabel }}</dt>
              <dd v-if="content.popUpCprLabel">{{ creditCheckDetails.cpr }}</dd>
              <dt>{{ content.popUpCurrentSubscriptionsLabel }}</dt>
              <dd>{{ creditCheckDetails.currentSubscriptions }}</dd>
              <dt>{{ content.popUpMaxSubscriptionsLabel }}</dt>
              <dd>{{ creditCheckDetails.maxSubscriptions }}</dd>
              <dt>{{ content.popUpStatusLabel }}</dt>
              <dd :class="statusClassModifier">
                {{ creditCheckDetails.status }}
              </dd>
              <dt>{{ content.popUpStatusMessageLabel }}</dt>
              <dd>{{ message }}</dd>
            </c-data-list>
          </div>
        </div>

        <div
          v-if="
            content.enablePaymentOptionsOnCreditCheckModal &&
            content.popUpCreditCheckLink.value &&
            content.popUpCreditCheckLink.key
          "
          class="credit-check-info"
        >
          <h4
            v-if="content.popUpCreditCheckTitle"
            class="credit-check-info__title"
          >
            {{ content.popUpCreditCheckTitle }}
          </h4>
          <p
            v-if="content.popUpCreditCheckDescription"
            class="credit-check-info__description"
          >
            {{ content.popUpCreditCheckDescription }}
          </p>
          <div class="credit-check-info__button">
            <a
              key="credit-check-button"
              :href="content.popUpCreditCheckLink.key"
              class="c-btn--secondary c-btn"
            >
              {{ content.popUpCreditCheckLink.value }}
            </a>
          </div>
        </div>

        <div v-if="!showDetails" class="credit-check__footer">
          <c-button
            type="button"
            class="credit-check__footer__initiate-btn"
            :disabled="!ready"
            @click="initiateCreditCheck"
          >
            {{ content.searchButtonCaption }}
          </c-button>

          <c-button :variant="BUTTON_VARIANTS.TERTIARY" @click="closeModal">
            {{ content.cancelButtonText }}
          </c-button>
        </div>

        <div v-else class="credit-check__footer">
          <c-button
            class="credit-check__footer__initiate-btn"
            type="button"
            @click="initiateCreditCheck"
          >
            {{ content.reloadButtonCaption }}
          </c-button>

          <c-button
            :variant="BUTTON_VARIANTS.TERTIARY"
            @click="handleClearBasket"
          >
            {{ content.resetButtonText }}
          </c-button>
        </div>
      </div>
    </c-modal>
  </div>
</template>

<script>
import {
  CHeading,
  CDataList,
  CCheckbox,
  CModal,
  CButton
} from 'olympus/components/index.js';
import CInputGroup from '../c-input/c-input-group.vue';
import { CIcon } from '../c-icon/index.js';

import { PAYMENT_METHODS } from '../../enums/payment-methods';
import RootBusMixin from 'olympus/mixins/root-bus-mixin.js';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin.js';

import {
  required,
  numeric,
  sameAs,
  requiredIf
} from 'vuelidate/lib/validators/index.js';

import { mapActions, mapGetters } from 'vuex';
import { GETTERS, ACTIONS } from 'webshop/store/credit-check/index.js';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';
import CFormRadioGroup from 'olympus/components/form/c-form-radio-group.vue';
import CFormRadio from 'olympus/components/form/c-form-radio.vue';
import { BUTTON_VARIANTS } from 'olympus/components/c-button/types/c-button.types';

const STATUS_TYPES = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  CPR_NO_MATCH: 'CPR_NO_MATCH'
};

export default {
  name: 'CreditCheck',

  components: {
    CIcon,
    CHeading,
    CDataList,
    CInputGroup,
    CCheckbox,
    CModal,
    CButton,
    CFormRadioGroup,
    CFormRadio
  },

  mixins: [RootBusMixin],

  props: {
    dataProp: {
      type: Object,
      required: true
    },
    content: {
      type: Object,
      required: true
    },
    modalId: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      loading: false,
      searchText: '',
      acceptCheckbox: false,
      inputError: '',
      showErrorMessage: false,
      errorMessageString: '',
      numberPorting: false,
      hardwareWithCredit: false,
      paymentMethod: PAYMENT_METHODS.PBS,
      BUTTON_VARIANTS
    };
  },

  validations: {
    searchText: {
      required,
      numeric
    },
    acceptCheckbox: {
      sameAs: sameAs(() => true)
    },
    paymentMethod: {
      required: requiredIf('paymentMethodRequired')
    }
  },

  computed: {
    ...mapGetters({
      creditCheckDetails: GETTERS.GET_CREDIT_CHECK_DETAILS
    }),

    paymentMethodRequired() {
      return this.content.enablePaymentOptionsOnCreditCheckModal;
    },

    PAYMENT_METHODS() {
      return PAYMENT_METHODS;
    },

    showDetails: function () {
      return this.creditCheckDetails?.cpr && this.creditCheckDetails.status;
    },

    showSearchErrorMessage() {
      return (
        this.searchText &&
        this.errorMessageString === this.searchText &&
        this.showErrorMessage
      );
    },

    searchErrorMessage() {
      return populateContentProperty(this.content.cprErrorMessage || '', {
        searchText: this.searchText
      });
    },

    statusClassModifier() {
      return {
        'credit-check__result__status': true,
        'credit-check__result__status--approved':
          this.creditCheckDetails?.status === STATUS_TYPES.APPROVED,
        'credit-check__result__status--denied':
          this.creditCheckDetails?.status === STATUS_TYPES.DENIED
      };
    },

    message() {
      return this.creditCheckDetails?.status
        ? this.content.messageList.find(message => {
            return message.status === this.creditCheckDetails.status;
          })?.message
        : '';
    },

    ready() {
      return !this.$v.$invalid;
    }
  },

  mounted() {
    this.setCreditCheckDetails({ data: this.dataProp });
  },

  methods: {
    ...mapActions({
      performCreditCheck: ACTIONS.PERFORM_CREDIT_CHECK,
      setCreditCheckDetails: ACTIONS.SET_CREDIT_CHECK_DETAILS,
      clearBasket: BASKET_ACTIONS.CLEAR_BASKET
    }),

    initiateCreditCheck() {
      this.emitOnRoot('v::modal::show::spinner', this.modalId);
      this.errorMessageString = this.searchText;
      this.showErrorMessage = false;

      this.performCreditCheck({
        searchText: this.searchText,
        withNumberPorting: this.numberPorting,
        withHardware: this.hardwareWithCredit,
        withPaymentMethod: this.paymentMethod
      })
        .then(() => {
          this.emitOnRoot('v::modal::hide::spinner', this.modalId);
        })
        .catch(() => {
          this.showErrorMessage = true;
          this.emitOnRoot('v::modal::hide::spinner', this.modalId);
        });
    },

    toggleAcceptCheckbox() {
      this.acceptCheckbox = !this.acceptCheckbox;
    },

    toggleNumberPortingCheckbox() {
      this.numberPorting = !this.numberPorting;
    },

    toggleHardwareWithCreditCheckbox() {
      this.hardwareWithCredit = !this.hardwareWithCredit;
    },

    closeModal() {
      this.emitOnRoot('v::hide::modal', this.modalId);
    },

    clearErrors() {
      this.inputError = '';
    },

    clearInputFields() {
      this.searchText = '';
      this.numberPorting = false;
      this.hardwareWithCredit = false;
      this.acceptCheckbox = false;

      //paymentMethod default value should be PBS as specified in requirements
      this.paymentMethod = PAYMENT_METHODS.PBS;
    },

    handleClearBasket() {
      this.emitOnRoot('v::modal::show::spinner', this.modalId);
      this.clearBasket().then(() => {
        this.setCreditCheckDetails({ data: { cpr: '' } });
        this.clearInputFields();
        this.emitOnRoot('v::modal::hide::spinner', this.modalId);
      });
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.credit-check {
  &__body {
    margin: $u-200 auto $u-500;
    padding: 0 $u-200;
    width: 100%;

    & dd {
      margin-left: $u-250;
      text-align: left;
      word-wrap: break-word;
    }

    & dt {
      font-weight: 600;
      margin-right: $u-250;
      text-align: right;
    }

    & dt,
    & dd {
      flex: 1 1 calc(50% - #{$u-250});
      margin-bottom: $u-150;
    }
  }

  &__description {
    font-weight: 700;
  }

  &__result {
    width: 600px;

    &__status {
      font-weight: 600;

      &--approved {
        color: $c-sp-green-500;
      }

      &--denied {
        color: $c-sp-red-500;
      }
    }

    @include mq($container-sm, max) {
      width: 400px;
    }

    @include mq($container-xs, max) {
      width: 230px;
    }
  }

  &-info {
    border: $u-100 solid $c-nt-300;
    margin: $u-500 0;
    padding: $u-400 $u-300;

    &__description {
      color: $c-nt-600;
      font-size: 14px;
    }

    &__button {
      margin-top: $u-300;
      text-align: center;
    }
  }

  &__form {
    &__input {
      margin-bottom: $u-250;
      position: relative;

      &__icon {
        height: $u-350;
        position: absolute;
        right: $u-350;
        stroke: $c-nt-900;
        top: 50%;
        transform: translateY(-50%);
        width: $u-350;
        z-index: 1;
      }

      &--error {
        margin-bottom: $u-400;
      }
    }

    &__error-message {
      margin-top: $u-250;
      text-align: center;
      font-size: $font-size-h2;
      line-height: 2rem;
    }

    &__nr-porting-checkbox {
      margin-bottom: $u-300;
    }

    &__header {
      margin-top: $u-400;
      margin-bottom: $u-300;
      line-height: $global-line-height-sm;
    }

    &__terms-checkbox {
      @include mq($container-sm, max) {
        margin: $u-400 auto;
      }

      @include mq($container-xs, max) {
        margin: $u-600 auto;
      }
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__initiate-btn {
      margin-bottom: $u-200;
    }
  }
}
</style>
