<template>
  <article
    v-if="vas"
    class="c-vas-card"
    :class="[!blockItem ? 'c-vas-card--bottom-gap' : '', classObject]"
  >
    <div
      v-if="promoted"
      class="c-vas-card__promotion"
      :style="{ backgroundImage: 'url(' + promotedImageUrl + ')' }"
      :aria-label="promotedVasAriaLabel"
    >
      <div class="c-vas-card__badge">
        <span
          v-if="vas.badgeText && vas.badgeText.item2 !== ''"
          class="c-badge c-badge--top-left"
          :class="vas.badgeText.item1"
        >
          {{ vas.badgeText.item2 }}
        </span>
      </div>
    </div>

    <div
      v-if="vas.ribbonText"
      :class="`c-vas-card__badge c-badge c-badge--top-left c-badge--${vas.ribbonColor}`"
    >
      {{ vas.ribbonText }}
    </div>

    <div
      :class="`c-vas-card__card ${
        blockItem ? 'c-vas-card__card--block-item' : ''
      }`"
      @click="handleAddClickEvent"
    >
      <div class="c-vas-card__image">
        <img :src="vas.imageUrl" :alt="vas.displayName" />
      </div>

      <div ref="info" class="c-vas-card__info">
        <hgroup :class="`${blockItem ? 'c-vas-card__hgroup' : ''}`">
          <h5 class="c-vas-card__title">
            {{ vas.displayName }}
          </h5>
          <div v-if="vas.price" class="c-vas-card__price">
            <template v-if="blockItem">
              <span class="c-vas-card__price--original">
                <del>
                  {{ vas.originalPrice }}
                </del>
              </span>
              <span class="c-vas-card__price--new">{{ vas.price }}</span>
              <span class="c-vas-card__price-label">{{ vas.priceLabel }}</span>
            </template>

            <template v-else>
              <span
                v-if="vas.originalPrice || vas.substitute"
                class="c-vas-card__price--gray"
              >
                <del>{{
                  vas.originalPrice
                    ? vas.originalPrice.formattedValueCurrency
                    : vas.price.formattedValueCurrency
                }}</del>
              </span>
              <span>{{
                vas.substitute
                  ? vas.substitute.price.formattedValueCurrency
                  : vas.price.formattedValueCurrency
              }}</span>
            </template>
          </div>
        </hgroup>

        <p v-if="vas.descriptionShort" class="c-vas-card__summary">
          {{ vas.descriptionShort }}
        </p>

        <p v-if="vas.state && vas.state.description" class="c-vas-card__status">
          <em>{{ vas.state.description }}</em>
        </p>

        <template v-else-if="vas.description">
          <c-collapse :id="`vas-expand-${vas.code}-${_uid}`">
            <div class="c-vas-card__description" v-html="vas.description" />
          </c-collapse>
          <div @click.stop="">
            <c-collapse-trigger
              :id="`vas-expand-${vas.code}-${_uid}`"
              class="c-vas-card__expand"
            >
              <template slot-scope="{ expanded }">
                {{ expanded ? close : readMore }}
              </template>
            </c-collapse-trigger>
          </div>
        </template>
        <p v-else class="c-vas-card__expand c-vas-card__expand--is-hidden">
          &nbsp;
        </p>
      </div>

      <c-input
        v-if="asRadioSelect"
        data-testid="radio-select"
        name="vas"
        type="radio"
        @change="handleLoyaltyVasClick"
      />

      <div v-else-if="!blockItem" class="c-vas-card__action">
        <div
          v-if="
            vas.state && vas.state.interaction !== STATE_INTERACTION.NO_CHANGE
          "
        >
          <div
            v-if="networking && localLoading"
            class="cm-c-spinner cm-c-spinner--local"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <c-icon
            v-else
            role="img"
            :aria-label="removeVasAriaLabel"
            :class="iconClassModifier"
            :symbol-id="added ? 'service-trash' : 'service-addbasket'"
          />
        </div>
      </div>
    </div>
    <p v-if="priceDisclaimerText" class="c-vas-card__price-disclaimer">
      {{ priceDisclaimerText }}
    </p>
    <div v-if="vas.substitute" class="c-vas-card__banner">
      <c-icon symbol-id="service-information" />
      <p>{{ oldVasPrice }}</p>
    </div>
  </article>
</template>

<script>
import { CCollapse, CCollapseTrigger } from 'olympus/components';
import { STATE_INTERACTION } from './vas-constants';
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import CInput from '../c-input/c-input.vue';

export default {
  name: 'CVasCard',

  components: {
    CCollapse,
    CCollapseTrigger,
    CInput
  },

  mixins: [ContentPropertyMixin],

  props: {
    asRadioSelect: {
      type: Boolean,
      default: false
    },
    networking: {
      type: Boolean,
      default: false
    },
    localLoading: {
      type: Boolean,
      default: false
    },
    promoted: {
      type: Boolean,
      default: false
    },
    promotedImageUrl: {
      type: String,
      default: ''
    },
    vas: {
      type: Object,
      required: true
    },
    readMore: {
      type: String,
      default: 'Læs mere'
    },
    oldVasPriceDescription: {
      type: String,
      default: 'Din før pris var {{ oldPrice }}'
    },
    close: {
      type: String,
      default: 'Luk'
    },
    handleAddClickEvent: {
      type: Function,
      default: () => {
        return false;
      }
    },
    classObject: {
      type: Object,
      default: () => ({})
    },
    added: {
      type: Boolean,
      default: false
    },
    blockItem: {
      type: Boolean,
      default: false
    },
    priceDisclaimerText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      STATE_INTERACTION
    };
  },

  computed: {
    iconClassModifier() {
      return {
        'c-vas-card__icon': true,
        'c-vas-card__icon--add': !this.added,
        'c-vas-card__icon--remove': this.added
      };
    },
    oldVasPrice() {
      return this.populateContentProperty(this.oldVasPriceDescription, {
        oldPrice: this.vas.price.formattedValueCurrency
      });
    },
    promotedVasAriaLabel() {
      return `${this.vas.displayName} Promotional Image`;
    },
    removeVasAriaLabel() {
      return `Remove ${this.vas.displayName}`;
    }
  },

  mounted() {
    if (!this.blockItem) {
      return;
    }

    this.$el.style.minHeight = this.$el.parentElement.offsetHeight + 'px';
  },

  methods: {
    handleLoyaltyVasClick() {
      this.$emit('loyalty-vas-click', {
        vasProduct: this.vas
      });
    }
  }
};
</script>

<style lang="scss">
@import './../../../../../shared/styles/media-query.scss';
@import './../../../../../shared/styles/settings/settings.media-query.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';

$cardColor: #fff;
$iconAddWidth: 28px;
$iconAddHeight: 26px;
$iconRemoveHeight: 18px;
$iconRemoveWidth: 16px;
$imageSize: 40px;
$borderRadius: 3px;
$promotionHeightSmall: 124px;
$promotionHeightMedium: 184px;
$priceSmall: 18px;
$priceLarge: 22px;
$priceLabel: 14px;

.c-vas-card {
  position: relative;
  flex-basis: 100%;
  display: flex;
  flex-direction: column-reverse;

  $card: '';

  &--bottom-gap {
    margin-bottom: $u-300;
  }

  input[type='radio'] {
    align-self: center;
    width: 20px;
    height: 20px;
  }

  @include mq($screen-md, min) {
    flex-basis: calc(50% - #{$u-300});
  }

  &__price-disclaimer {
    text-align: center;
    color: $c-nt-400;
    font-size: $global-font-size-md;
    margin-top: $u-150;
    text-align: right;
    order: -1;
  }

  &__promotion {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $promotionHeightSmall;
    padding: $u-300;
    background-size: cover;
    background-position: center;
    order: 1;

    @include mq($screen-md, min) {
      height: $promotionHeightMedium;
    }
  }

  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &.c-badge {
      min-height: 24px;
      line-height: 24px;
    }
  }

  &__card {
    background-color: $cardColor;
    border: 2px solid transparent;
    border-radius: $borderRadius;
    box-shadow: $box-shadow-default;
    cursor: pointer;
    display: flex;
    padding: $u-300;
    transition: border-color 0.3s ease, box-shadow 0.3s ease,
      opacity 0.5s linear;
    will-change: border-color, box-shadow, opacity;

    $card: &;

    @include mq($screen-md, min) {
      &:not(.c-vas-card--disabled):hover {
        box-shadow: $box-shadow-hover;
      }
    }

    &--block-item {
      padding: $u-500 $u-300;
      flex-grow: 1;
    }
  }

  &__image {
    flex-shrink: 0;
    flex-basis: $imageSize;
    margin-right: $u-300;
  }

  &__info {
    flex-basis: calc(100% - #{$imageSize});
  }

  &__hgroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $imageSize;
  }

  &__title,
  &__status {
    display: block;
    font-size: $global-font-size-md;
  }

  &__title {
    font-weight: 700;
  }

  &__price {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    & > span + span {
      margin-left: $u-150;
    }

    &--gray {
      margin-right: $u-150;
      color: $c-nt-400;
    }

    &--original,
    &--new {
      font-weight: 400;
      font-family: $global-font-family-2;
    }

    &--original {
      font-size: $priceSmall;
    }

    &--new {
      font-size: $priceLarge;
    }

    &-label {
      font-size: $priceLabel;
    }
  }

  &__description {
    padding: $u-200 0;
    margin-bottom: $u-200;
  }

  &__action {
    position: relative;
    flex-shrink: 0;
    width: $iconAddWidth;
    margin-left: $u-300;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    &--add {
      fill: $c-pm-500;
      height: $iconAddHeight;
      width: $iconAddWidth;
    }

    &--remove {
      fill: $c-nt-500;
      height: $iconRemoveHeight;
      width: $iconRemoveWidth;
    }
  }

  &__expand {
    color: $c-pm-500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    // c-vas-card__expand--is-hidden
    &--is-hidden {
      visibility: hidden;
    }
  }

  &__description,
  &__expand,
  &__summary,
  &__badge {
    font-size: $global-font-size-md;
    line-height: 20px;
  }

  &__summary {
    margin-bottom: $u-150;
  }

  &__banner {
    align-items: center;
    background: $color-white;
    border-radius: 0 0 3px 3px;
    display: flex;
    font-size: $font-size-h6;
    margin: 0 $u-300;
    padding: 6px;

    > svg {
      fill: $c-pm-500;
      height: $u-350;
      margin-right: $u-200;
      width: $u-350;
    }
  }

  &--promotion {
    flex-basis: 100%;

    @include mq($screen-md, max) {
      margin-left: $u-300;
      margin-right: $u-300;
    }
  }

  &--added {
    #{$card} {
      border-color: $c-pm-500;
      box-shadow: $box-shadow-default;

      @include mq($screen-md, min) {
        &:hover {
          box-shadow: $box-shadow-default;
        }
      }
    }
  }

  .cm-c-spinner {
    background-color: transparent;

    &::before {
      height: $u-350;
      left: calc(50% - (#{$u-350} / 2));
      top: calc(50% - (#{$u-350} / 2));
      width: $u-350;
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: default;

    & > * {
      opacity: 0.5;
    }
  }
}
</style>
