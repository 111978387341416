<script>
import { CCheckbox } from 'olympus/components';

export default {
  name: 'checkout-payment-acceptance',

  components: {
    CCheckbox
  },

  data() {
    return {
      financingAccepted: false,
      purchaseAccepted: false
    };
  }
};
</script>

<style lang="scss">
.checkout-payment-acceptance {
  // .checkout-payment-acceptance__description
  &__description {
    & ul {
      list-style-type: disc;
      margin: 16px 0;
      padding-left: 40px;
    }
  }
}
</style>
