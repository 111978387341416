/**
 * Checks if value is between two numbers.
 *
 * @param {Number, String} min The minimum value to check [including].
 * @param {*} max The maximum value to check [including].
 * @param {*} val The value to check.
 *
 * @return {Boolean} True or False.
 */
export const between = (min, max, val) => {
  return new RegExp(`^[${min}-${max}]$`).test(val);
};
