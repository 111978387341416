<component class="cm-c-product-card-simple"
  :class="product.priceDisclaimerText ? 'cm-c-product-card-simple--extra-margin' : ''" :is="componentType"
  v-bind="componentProps" @click.prevent="onCardClick">
  <div class="cm-c-product-card-simple__inner" :class="{ 'is-active' : isCompareItem(product.productId) }">
    <div class="cm-c-product-card-simple__ribbon"
      v-if="selectedVariant && (selectedVariant.ribbonText || product.ribbonText)">
      <span class="c-badge c-badge--primary c-badge--top-left">
        {{ ribbonText }}
      </span>
    </div>

    <div class="cm-c-product-card-simple__left">
      <figure class="cm-c-product-card-simple__photo">
        <!-- TODO DKT-1381: add alt text from cms -->
        <img v-if="!!defaultImage" ref="image" :key="defaultImage" :src="defaultImage" :alt="product.productName" />
      </figure>

      <div class=" cm-c-splash" :class="selectedVariant.badgeText.item1" v-if="
          selectedVariant &&
          selectedVariant.badge &&
          selectedVariant.badgeText &&
          selectedVariant.badgeText.item2
        ">
        <span class="cm-c-splash__wrap" v-html="selectedVariant.badgeText.item2"></span>
      </div>

      <div v-if="!compareDisabled" class="cm-c-product-card-simple__action">
        <label class="cm-c-checkbox cm-c-checkbox--center">
          <input :id="id" type="checkbox" :value="product.productId" :checked="isCompareItem(product.productId)"
            ref="compare" :disabled="compareCount >= compareMax && !isCompareItem(product.productId)"
            class="cm-c-checkbox__o-input" />
          <span class="cm-c-checkbox__icon">
            <c-icon class="cm-c-checkbox__compare" symbol-id="service-compare" />
            <c-icon class="cm-c-checkbox__comparing" symbol-id="service-comparing" />
          </span>

          <!-- DKT-19854: Replace Tilføjet with CMS property -->
          <span class="cm-c-checkbox__label cm-c-checkbox__label--center" :style="
                `
                --added-string: 'Tilføjet';
                --compare-string: '${compareLabel}';
              `
              "></span>
        </label>
      </div>
    </div>
    <div class="cm-c-product-card-simple__right">
      <div class="cm-c-product-card-simple__top">
        <p class="cm-c-product-card-simple__brand" v-trim>
          {{ product.productBrand }}
        </p>
        <p class="cm-c-product-card-simple__title" v-trim>
          {{ productName(product.productName, product.productBrand) }}
        </p>
        <nav class="cm-c-product-colors cm-c-product-colors--left" v-if="hasColorGroups">
          <!-- TODO DKT-1381: Replace "colorArray" with "product.colorArray" below, remove colorArray(){ .,..} from "computed" -->
          <div v-for="(color, index) in product.availableColors" :key="index" :style="{ background: color.hexCode }"
            class="cm-c-product-colors-color cm-c-product-colors-color--small" :class="{
                'cm-c-product-colors-color--border': checkColor(color.hexCode),
                'cm-c-product-colors-color--selected':
                  color &&
                  selectedColor &&
                  color.colorName === selectedColor.colorName
              }"></div>
        </nav>
      </div>
      <div class="cm-c-product-card-simple__bottom"
        :class="{ 'cm-c-product-card-simple__bottom--with-button': addToBasketButtonEnabled }">
        <p v-if="product.supplementaryPriceText && product.supplementaryPriceText !== ''"
          class="cm-c-product-card-simple__price-text" v-trim>
          {{ product.supplementaryPriceText }}
        </p>
        <p class="cm-c-product-card-simple__price">
          <template>
            <span>{{ price }}</span>
            <span>
              <span>{{ priceText }}</span>
            </span>
          </template>
        </p>
        <div class="cm-c-product-card-simple__desc">
          <p v-for="text in metaLines" :key="text" v-trim>
            {{ text }}
          </p>
          <button type="button" class="c-btn c-btn--sm" :class="animationClass" :disabled="addButtonDisabled"
            v-if="addToBasketButtonEnabled" @click="addToBasketButtonClickEvent ? addProductToBasket($event) : null">
            <span class="c-btn__text">
              <c-icon v-if="addToBasketButtonClickEvent && submitOptions.showSuccessIcon" class="c-btn__icon"
                symbol-id="service-checkmark" />
              <c-icon v-else-if="addToBasketButtonClickEvent" class="c-btn__icon c-btn__icon--error "
                symbol-id="menu-close" />
              <span v-trim>{{ addToBasketButtonText }}</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <p v-if="product.priceDisclaimerText" class="cm-c-product-card-simple__vat-disclaimer">
    {{ product.priceDisclaimerText }}
  </p>
</component>