<template>
  <div role="group" class="c-input-group" :class="modifiers">
    <div v-if="showPrepend" class="c-input-group-prepend">
      <slot name="prepend">
        <div class="c-input-group-text">
          <span>
            {{ prependText }}
          </span>
        </div>
      </slot>
    </div>
    <slot />
    <div v-if="showAppend" class="c-input-group-append">
      <slot name="append">
        <div class="c-input-group-text">
          <span>
            {{ appendText }}
          </span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
// TODO TC-4911: Remove this component and all references to it. Make sure to test everything works and looks correct!
// todo: need to polish state
export default {
  name: 'CInputGroup',

  props: {
    prependText: {
      type: [String, Number],
      default: ''
    },
    appendText: {
      type: [String, Number],
      default: ''
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modifiers() {
      return {
        'c-input-group--rounded': this.rounded
      };
    },
    showPrepend() {
      return !!this.prependText || !!this.$slots.prepend;
    },
    showAppend() {
      return !!this.appendText || !!this.$slots.append;
    }
  }
};
</script>

<style lang="scss">
.c-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  // .c-input-group:first-child:last-child
  &:first-child:last-child {
    display: flex;
  }

  // .c-input-group-text
  &-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  // .c-input-group-prepend
  &-prepend {
    position: relative;
    margin-right: -1px;

    & .c-input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & > input,
  & > select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // .c-input-group-append
  &-append {
    display: inline-flex;
    position: relative;
    margin-left: -1px;

    & .c-input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > *:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    & > *:not(:last-child) {
      border-radius: 0;
    }

    & > * {
      flex: 1 1 auto;
    }
  }

  // .c-input-group--rounded
  &--rounded {
    border-radius: 1000px;

    & > input:first-child {
      border-top-left-radius: 1000px;
      border-bottom-left-radius: 1000px;
    }

    & .c-input-group-append > *:last-child {
      border-bottom-right-radius: 1000px;
      border-top-right-radius: 1000px;
    }
  }
}
</style>
