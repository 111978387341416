<template>
  <c-modal
    :id="MODAL_ID"
    :hidden="false"
    ref="financingConflictModalCModal"
    visible
    centered
    small
    gray
    :show-close-button="false"
    non-closable-modal
  >
    <div slot="header" class="text-center">
      <h2>
        <strong>{{ content.title }}</strong>
      </h2>
    </div>
    <div class="mb-2 text-center">
      <p>{{ content.message }}</p>
    </div>

    <template slot="footer">
      <c-button type="button" @click="$emit('remove-financing')">
        {{ content.approveButton }}
      </c-button>

      <c-button
        type="button"
        variant="link"
        @click="$emit('continue-shopping')"
      >
        {{ content.declineButton }}
      </c-button>
    </template>
  </c-modal>
</template>

<script>
import { CButton } from 'olympus/components';
import { MODAL_ID } from './checkout-financing-validation.js';

export default {
  name: 'FinancingConflictOverlay',

  components: { CButton },

  data() {
    return {
      MODAL_ID
    };
  },

  methods: {
    hide() {
      this.$refs.financingConflictModalCModal.removeFromBody();
    }
  },

  props: {
    content: {
      type: Object,
      default() {
        return {
          title: '',
          message: '',
          approveButton: '',
          declineButton: ''
        };
      }
    }
  }
};
</script>
