<template>
  <config-group-item
    :title="title"
    :icon="icon"
    :expanded="expanded"
    @expanded="handleExpanded"
  >
    <fieldset class="new-number-config">
      <div class="new-number-config__number">
        <div class="cm-c-form-control-group new-number-config__input-wrapper">
          <c-input-group
            v-model="search"
            class="new-number-config__input-wrapper__search"
            :class="{
              'new-number-config__input-wrapper__search--with-error':
                numberSearchError
            }"
            name="numbersearch"
            :place-holder-label="
              content.newNumber.numberSearchBoxPlaceholderText
            "
            :input-error="numberSearchError"
            :maxlength="8"
            @submit="fetchAndSearchNewNumbers"
            @keypress="handleNumberInput($event)"
          />
          <button
            :disabled="!search"
            class="
              cm-c-form-control__button
              new-number-config__input-wrapper__fetch-btn
            "
            @click.prevent="fetchAndSearchNewNumbers"
          >
            <c-icon
              class="cm-numcon__number-search-button--icon"
              symbol-id="menu-search"
            />
          </button>
        </div>
        <div
          v-if="content.newNumber.numberSearchTipsLabel"
          class="new-number-config__number__search-tips"
        >
          <popover-panel
            :description="content.newNumber.numberSearchTipsText"
            :tooltip-screen-reader-text="content.tooltipScreenReaderText"
            :popover-triggers="['hover', 'click']"
            base-classes="new-number-tooltip"
          >
            <div class="new-number-tooltip__icon-wrapper">
              <label>{{ content.newNumber.numberSearchTipsLabel }}</label>
              <c-icon
                class="
                  new-number-tooltip__icon-wrapper__icon
                  new-number-tooltip__icon-wrapper__icon--purple
                "
                :symbol-id="content.newNumber.numberSearchTipsIcon"
              />
            </div>
          </popover-panel>
        </div>
        <div class="new-number-config__number__no-numbers">
          <label v-if="!(numbersToShow && numbersToShow.length)">
            {{ content.newNumber.noResultText }}
          </label>
        </div>

        <div class="new-number-config__number-chips">
          <c-chip
            v-for="numberObj in numbersToShow"
            :key="numberObj.number"
            data-testid="new-number-config__number-chips-item"
            name="number"
            :input-value="numberObj.number"
            @change="chipChanged(numberObj)"
          >
            {{ formatNumber(numberObj.number) }}
          </c-chip>
        </div>

        <div
          v-if="showMoreNumbersButton"
          class="new-number-config__number__more-numbers"
        >
          <c-button variant="link" @click="fetchMoreNumbers">
            <c-icon
              class="new-number-config__number__more-numbers__icon"
              :symbol-id="content.newNumber.seeMoreNumbersIcon"
            />
            {{ content.newNumber.seeMoreNumbersLabel }}
          </c-button>
        </div>
      </div>
      <hr class="new-number-config__separator" />

      <sim-card-selection
        v-if="!content.hideSimTypeOptions"
        :config-data="configData"
        :checked-sim-radio="checkedSimRadio"
        :options="simCardSettings"
        class="new-number-config__simcard-selection"
      />

      <!-- HardCode for mittele, form works incorrectly unless backend receives some sort of sim option -->
      <input
        v-else
        v-model="SIM_OPTIONS.SHIP_SIM"
        type="hidden"
        name="SimOption"
      />

      <div class="new-number-config__hidden-or-unlisted">
        <legend class="new-number-config__hidden-or-unlisted__legend">
          {{ content.optionalSettingsHeadline }}
        </legend>

        <c-row class="new-number-config__section__row">
          <c-col>
            <div
              class="new-number-config__section__row__tooltip"
              :class="{
                'new-number-config__section__row__tooltip--checked-border':
                  isNumberHidden
              }"
            >
              <c-checkbox
                id="new-number-config-hidden"
                v-model="isNumberHidden"
                :checked="isNumberHidden"
                name="isNumberHidden"
                styled-checkbox
                @event-id="toggleIsNumberHidden()"
              >
                {{ content.secretNumberLabel }}
                <span class="description">{{
                  content.secretNumberDescription
                }}</span>
              </c-checkbox>
              <popover-panel
                :description="content.secretNumberDescription"
                :tooltip-screen-reader-text="content.tooltipScreenReaderText"
                :popover-triggers="['hover', 'click']"
                base-classes="new-number-tooltip"
              >
                <div class="new-number-tooltip__icon-wrapper">
                  <c-icon
                    class="new-number-tooltip__icon-wrapper__icon"
                    :class="{
                      'new-number-tooltip__icon-wrapper__icon--disabled':
                        !isNumberHidden
                    }"
                    symbol-id="service-information"
                  />
                </div>
              </popover-panel>
            </div>
          </c-col>
        </c-row>

        <c-row class="new-number-config__section__row">
          <c-col>
            <div
              class="new-number-config__section__row__tooltip"
              :class="{
                'new-number-config__section__row__tooltip--checked-border':
                  isNumberUnlisted
              }"
            >
              <c-checkbox
                id="new-number-config-unlisted"
                v-model="isNumberUnlisted"
                :checked="isNumberUnlisted"
                name="isNumberUnlisted"
                styled-checkbox
                @event-id="toggleIsNumberUnlisted()"
              >
                {{ content.unlistedNumberLabel }}
                <span class="description">{{
                  content.unlistedNumberDescription
                }}</span>
              </c-checkbox>
              <popover-panel
                :description="content.unlistedNumberDescription"
                :tooltip-screen-reader-text="content.tooltipScreenReaderText"
                :popover-triggers="['hover', 'click']"
                base-classes="new-number-tooltip"
              >
                <div class="new-number-tooltip__icon-wrapper">
                  <c-icon
                    symbol-id="service-information"
                    class="new-number-tooltip__icon-wrapper__icon"
                    :class="{
                      'new-number-tooltip__icon-wrapper__icon--disabled':
                        !isNumberUnlisted
                    }"
                  />
                </div>
              </popover-panel>
            </div>
          </c-col>
        </c-row>
      </div>
    </fieldset>
  </config-group-item>
</template>

<script>
import { mapGetters } from 'vuex';

import { GETTERS as NUMBER_CONFIG_GETTERS } from 'webshop/store/product-config/new-number-config/index.js';
import { GETTERS as PRODUCT_CONFIG_GETTERS } from 'webshop/store/product-config/index.js';
import { CButton, CCheckbox, CRow, CCol } from 'olympus/components';
import { CIcon } from 'webshop/components';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import CChip from 'webshop/components/c-chip/c-chip.vue';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';
import ConfigGroupItem from '../config-group-item/config-group-item.vue';
import { SIM_OPTIONS } from 'webshop/enums/sim-options';
import numberMixin from 'webshop/mixins/number-mixin.js';
import { getSimCardSettings } from '../product-config-utils.js';
import { required, requiredIf } from 'vuelidate/lib/validators/index.js';
import SimCardSelection from '../sim-card-selection/sim-card-selection.vue';

export default {
  name: 'NewNumberConfig',

  components: {
    CButton,
    CCheckbox,
    CIcon,
    ConfigGroupItem,
    CChip,
    CInputGroup,
    PopoverPanel,
    CRow,
    CCol,
    SimCardSelection
  },

  mixins: [numberMixin],

  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    },
    configData: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Array,
      default: () => []
    },
    checkedSimRadio: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selectedNumber: '',
      selectedSimcardType: '',
      isNumberHidden: false,
      isNumberUnlisted: false,
      inputError: 'TODO input error from backend',
      search: '',
      SIM_OPTIONS: SIM_OPTIONS
    };
  },

  validations: {
    selectedNumber: {
      required
    },
    selectedSimcardType: {
      required: requiredIf(
        vm =>
          !vm.content.hideSimTypeOptions &&
          vm.content.showSimDeliveryOptionsForPartners
      )
    }
  },

  computed: {
    ...mapGetters({
      getNumberpool: NUMBER_CONFIG_GETTERS.GET_NUMBERPOOL,
      getNumbersToShowFromNumberpool: NUMBER_CONFIG_GETTERS.GET_NUMBERS_TO_SHOW,
      getNumberpoolSize: NUMBER_CONFIG_GETTERS.GET_NUMBERPOOL_SIZE,
      getErrorByMemberName: PRODUCT_CONFIG_GETTERS.GET_ERROR_BY_MEMBER_NAME
    }),

    simCardSettings() {
      return getSimCardSettings(this.content, this.configData);
    },

    newNumbers() {
      return (
        this.configData.attributes && this.configData.attributes.newNumbers
      );
    },
    /**
     * @description: this.configData will be null if we have numbers in the numberPool.
     */
    numbersToShow() {
      return this.configData
        ? this.newNumbers
        : this.getNumbersToShowFromNumberpool;
    },
    // TODO DKT-1448: Validation should work differently (see task)
    ready() {
      return !this.$v.$invalid;
    },

    numberSearchError() {
      return this.getErrorByMemberName(['NumberSearch']);
    },

    showMoreNumbersButton() {
      return (
        this.numbersToShow &&
        this.numbersToShow.length === this.getNumberpoolSize / 2
      );
    }
  },

  watch: {
    ready(value) {
      this.$emit('ready', value);
    }
  },

  methods: {
    toggleIsNumberUnlisted() {
      this.isNumberUnlisted = !this.isNumberUnlisted;
    },
    toggleIsNumberHidden() {
      this.isNumberHidden = !this.isNumberHidden;
    },
    chipChanged(e) {
      this.selectedNumber = e.number;
    },
    formatNumber(number) {
      return this.formatNumberByDigits(number);
    },
    handleExpanded(expanded) {
      this.$emit('expanded');

      // Reset vuelidate and component state on collapse
      if (!expanded) {
        if (this.$v) {
          this.$v.$reset();
        }
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    fetchMoreNumbers() {
      if (this.search) {
        this.fetchAndSearchNewNumbers();
        return;
      }
      this.$emit('fetchMoreNumbers');
    },
    fetchAndSearchNewNumbers() {
      this.$emit('fetchAndSearchNewNumbers', this.search);
    },
    /**
     * @description: only numbers or stars are allowed.
     */
    handleNumberInput(event) {
      const char = String.fromCharCode(event.keyCode);
      const regex = /^[\d|*]+$/;
      if (regex.test(char)) {
        return true;
      }
      return event.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

// new-number-config
.new-number-config {
  // new-number-config__section
  &__section {
    // new-number-config__section__row:not(:last-of-type)
    &__row:not(:last-of-type) {
      margin-bottom: $u-250;
    }
    // new-number-config__section__row
    &__row {
      // new-number-config__section__row__tooltip
      &__tooltip {
        display: flex;
      }
    }
  }
  // new-number-config__input-wrapper
  &__input-wrapper {
    button:disabled {
      cursor: initial;
    }

    margin-bottom: $u-150;
    // new-number-config__input-wrapper__search
    &__search {
      &--with-error {
        // If there is an error that is displayed below the input field
        // we add its line-height ($u-300) to the margin-bottom.
        margin-bottom: $u-400 + $u-300;
      }
    }

    &__fetch-btn {
      padding-right: $u-250;
    }
  }
  // new-number-config__number
  &__number {
    // new-number-config__number__more-numbers
    &__more-numbers {
      display: flex;
      justify-content: center;
      // new-number-config__number__more-numbers__icon
      &__icon {
        $link-icon-size: 20px;

        fill: currentColor;
        width: $link-icon-size;
        height: $link-icon-size;
        margin-right: $u-150;
      }
    }
    // new-number-config__number__search-tips
    &__search-tips {
      margin-bottom: $u-500;
      margin-left: $u-250;
      display: flex;
      align-items: baseline;
      label {
        font-style: normal;
        color: $c-pm-500;
        font-size: $font-size-h6;
      }
    }
    // new-number-config__number__no-numbers
    &__no-numbers {
      display: flex;
      justify-content: center;
      font-size: $font-size-h5;
      font-style: italic;
    }
    // new-number-config__number-chips
    &-chips {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: $u-250;
      column-gap: $u-250;
      margin-bottom: $u-350;

      ::v-deep .chip {
        flex-basis: 100%;

        @include mq($screen-sm, min) {
          flex-basis: calc(50% - #{$u-200});
        }

        @include mq($screen-md, min) {
          flex-basis: calc(33.3% - #{$u-200});
        }

        &__input + span {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  // new-number-config__separator
  &__separator {
    border: 0;
    height: $u-100;
    background: $c-nt-300;
    margin: $u-400 0 $u-550 0;
  }

  // new-number-config__simcard-selection
  &__simcard-selection {
    margin-bottom: $u-700;
  }

  // new-number-config____hidden-or-unlisted
  &__hidden-or-unlisted {
    // new-number-config____hidden-or-unlisted__legend
    &__legend {
      margin-bottom: $u-300;
      font-size: $font-size-h4;
      font-weight: bolder;
    }
  }
}

$info-icon-size: 20px;
// new-number__tooltip
.new-number-tooltip {
  display: flex;
  // new-number-tooltip__icon-wrapper
  // new-number-tooltip__icon-wrapper
  &__icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    // new-number-tooltip__icon-wrapper__icon
    &__icon {
      max-height: $info-icon-size;
      max-width: $info-icon-size;
      margin-left: $u-200;
      &--purple {
        fill: $c-pm-500;
      }
    }
  }
}
</style>
