<template>
  <section class="subscription-overview">
    <div class="subscription-overview__image">
      <picture class="subscription-overview__image__hero">
        <source media="(max-width: 400px)" :srcset="content.imageMobile.path" />
        <img :src="content.imageDesktop.path" />
      </picture>
    </div>
    <div class="subscription-overview__header">
      <div class="c-wrapper">
        <h2 class="subscription-overview__headline" v-html="content.headline" />
        <div class="subscription-overview__description">
          <slot name="description" />
        </div>
      </div>
    </div>
    <div class="subscription-overview__rows">
      <div class="c-wrapper">
        <div
          :class="[
            'subscription-overview__chips',
            `subscription-overview__chips--${selectedChip}`
          ]"
        >
          <c-chip
            v-for="(item, index) in content.groups"
            :key="index"
            :class="{ checked: index === selectedChip }"
            name="selectedRow"
            :input-value="item.selectionLabel"
            :preselected="index === 0"
            wide
            @change="setSelectedChip"
          >
            {{ item.selectionLabel }}
          </c-chip>
        </div>

        <slot name="subscription-cards" />
      </div>
    </div>
  </section>
</template>

<script>
import CChip from 'webshop/components/c-chip/c-chip.vue';

export default {
  name: 'subscription-overview',

  components: {
    CChip
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selectedChip: 1
    };
  },

  methods: {
    setSelectedChip(e) {
      this.selectedChip = e;
    }
  }
};
</script>

<style scoped lang="scss"></style>
