var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "broadband-offerings-page" },
    [
      [
        _c(
          "parallax-wrapper",
          {
            staticClass: "broadband-offerings-page__pebble-bg-wrapper",
            attrs: { direction: -1, axis: "y", speed: 0.3 }
          },
          [_c("span", { staticClass: "broadband-offerings-page__pebble-bg" })]
        )
      ],
      _vm._v(" "),
      _vm.networking.pricesError
        ? _c(
            "div",
            { staticClass: "broadband-offerings-page__network-banner" },
            [
              _c(
                "div",
                {
                  staticClass: "broadband-offerings-page__network-banner__inner"
                },
                [
                  _c("c-icon", {
                    staticClass:
                      "broadband-offerings-page__network-banner__icon",
                    attrs: { "symbol-id": "service-alert" }
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.content.networkErrorDisclaimer) +
                      "\n    "
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.init && _vm.hasHeaderContent
        ? _c(
            "header",
            { staticClass: "broadband-offerings-page__header" },
            [
              _vm.selectedHeaderHtml
                ? _c("epi-content", {
                    domProps: { innerHTML: _vm._s(_vm.selectedHeaderHtml) }
                  })
                : _vm._t("header")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      [
        _c(
          "section",
          {
            staticClass:
              "\n        broadband-offerings-page__wizard\n        c-wrapper\n        cm-o-layout cm-o-layout--main\n      "
          },
          [
            _c(
              "c-row",
              {
                staticClass: "broadband-offerings-page__wizard-grid",
                class: {
                  "broadband-offerings-page__wizard-grid-no-offerings": !_vm.hasOfferings
                },
                attrs: { dir: "column", "align-items": "flex-end" }
              },
              [
                _vm.selectedImage
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__gallery",
                        attrs: {
                          cols: "6",
                          sm: "12",
                          "align-self": "flex-start"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "broadband-offerings-page__image" },
                          [
                            _vm.selectedImage
                              ? _c("img", { attrs: { src: _vm.selectedImage } })
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _vm.selectedImageSplash
                          ? _c(
                              "product-splash",
                              _vm._b(
                                {
                                  staticClass:
                                    "broadband-offerings-page__splash"
                                },
                                "product-splash",
                                _vm.selectedImageSplash,
                                false
                              )
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        false
                          ? _c("product-gallery", {
                              attrs: { "image-urls": _vm.selectedGalleryImages }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.init || (!_vm.selectedImage && _vm.offerings.length)
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__gallery",
                        attrs: {
                          cols: "6",
                          sm: "12",
                          "align-self": "flex-start"
                        }
                      },
                      [
                        _c("skeleton-loader", {
                          staticClass:
                            "broadband-offerings-page__gallery__skeleton-loader"
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__heading",
                        attrs: { cols: _vm.hasOfferings ? 6 : 12, sm: "12" }
                      },
                      [
                        _c("h1", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.broadbandOfferingsPageHeading) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.init
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__heading",
                        attrs: { cols: _vm.hasOfferings ? 6 : 12, sm: "12" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "broadband-offerings-page__heading__address"
                          },
                          [
                            _c("c-icon", {
                              staticClass:
                                "broadband-offerings-page__heading__address__checkmark",
                              attrs: { "symbol-id": "service-added" }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.address.tekst))]),
                            _vm._v(" "),
                            _vm.content.changeAddressLink
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.content.changeAddressLink
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.content.changeAddressLinkText)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__heading",
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _vm.selected &&
                        _vm.selected.hasExistingBroadbandConnection
                          ? _c(
                              "c-information-box",
                              {
                                attrs: {
                                  icon: "contact-information",
                                  type: "informative"
                                }
                              },
                              [
                                _c(
                                  "strong",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.content.existingBroadbandAlert
                                          ? ""
                                          : _vm.content.existingBroadbandAlert
                                              .heading
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.content.existingBroadbandAlert.content
                                    )
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.init && !_vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__heading",
                        class: {
                          "skeleton-loader-margin":
                            !_vm.init && !_vm.hasOfferings
                        },
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _c("skeleton-loader", {
                          staticClass:
                            "broadband-offerings-page__heading__skeleton-loader"
                        }),
                        _vm._v(" "),
                        _c("skeleton-loader", {
                          staticClass:
                            "\n              broadband-offerings-page__heading__address__skeleton-loader\n            "
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.init && _vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__technology",
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _c(
                          "c-carousel",
                          _vm._b(
                            {
                              staticClass:
                                "broadband-offerings-page__technology-carousel",
                              on: {
                                closeTongue: function($event) {
                                  _vm.closeTongue = true
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "slide",
                                    fn: function(ref) {
                                      var offering = ref.data
                                      return [
                                        _c(
                                          "subscription-card",
                                          {
                                            staticClass:
                                              "broadband-offerings-page__technology-card",
                                            attrs: {
                                              value: offering.code,
                                              selected: _vm.selectedCode,
                                              title:
                                                offering.variant.displayName,
                                              description:
                                                offering.variant
                                                  .shortDescription,
                                              "price-text":
                                                "" +
                                                offering.price.monthly
                                                  .formattedValueCurrency,
                                              "action-selected-text":
                                                _vm.content.selectedButtonText,
                                              "action-text":
                                                _vm.content.selectButtonText,
                                              "tongue-trigger-text":
                                                _vm.content
                                                  .technologyCardTongueText,
                                              "is-vas-display-disabled":
                                                _vm.isVasDisplayDisabled,
                                              vas: offering.vas,
                                              "close-tongue": _vm.closeTongue,
                                              "ribbon-text":
                                                offering.variant.ribbon,
                                              "badge-text": _vm.getVariantPropsWhenAvailable(
                                                offering.variant,
                                                "text"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.select(offering.code)
                                              },
                                              collapsed: function($event) {
                                                _vm.closeTongue = false
                                              }
                                            }
                                          },
                                          [
                                            _c("template", { slot: "attr" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "broadband-offerings-page__technology-speed"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getVariantPropsWhenAvailable(
                                                          offering.variant,
                                                          "speed"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getVariantPropsWhenAvailable(
                                                          offering.variant,
                                                          "unit"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                          ],
                                          2
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4003869756
                              )
                            },
                            "c-carousel",
                            _vm.technologyCarouselOptions,
                            false
                          )
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.init && !_vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__technology",
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _c("skeleton-loader", {
                          staticClass:
                            "\n              broadband-offerings-page__technology-carousel__skeleton-loader\n            "
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOfferings &&
                _vm.content.fiveGFallBackToggle &&
                _vm.selected &&
                _vm.selected.broadband5GFallback
                  ? _c(
                      "c-col",
                      {
                        staticClass:
                          "broadband-offerings-page__5G-fallback-disclaimer",
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _c(
                          "c-information-box",
                          {
                            attrs: {
                              icon: "contact-information",
                              type: "informative"
                            }
                          },
                          [
                            _c(
                              "strong",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.content
                                        .fiveGFallBackDisclaimerHeadLine
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.content.fiveGFallBackDisclaimerText
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedInstallationFees.length
                  ? [
                      _vm.init && !_vm.loading
                        ? _vm._l(_vm.selectedInstallationFees, function(
                            fee,
                            index
                          ) {
                            return _c(
                              "c-col",
                              {
                                key: index,
                                staticClass:
                                  "broadband-offerings-page__technician",
                                attrs: { cols: "6", sm: "12" }
                              },
                              [
                                _c(
                                  "c-information-box",
                                  {
                                    attrs: {
                                      icon: "contact-information",
                                      type: "informative",
                                      animatein: ""
                                    }
                                  },
                                  [
                                    fee.shortDescription
                                      ? _c(
                                          "strong",
                                          {
                                            attrs: { slot: "header" },
                                            slot: "header"
                                          },
                                          [_vm._v(_vm._s(fee.shortDescription))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    fee.description
                                      ? [
                                          _c("p", [
                                            _vm._v(_vm._s(fee.description))
                                          ]),
                                          _vm._v(" "),
                                          _vm.content.feesDescriptionRedirectUrl
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "broadband-offerings-page__technician-link",
                                                  attrs: {
                                                    href:
                                                      _vm.content
                                                        .feesDescriptionRedirectUrl,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      fee.descriptionLink ||
                                                        _vm.content
                                                          .feesDescriptionTrigger
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.init || _vm.loading
                        ? _c(
                            "c-col",
                            {
                              staticClass: "broadband-offerings-page__info",
                              attrs: { cols: "6", sm: "12" }
                            },
                            [
                              _c("skeleton-loader", {
                                staticClass:
                                  "broadband-offerings-page__info__skeleton-loader",
                                attrs: { id: "skeleton-loader" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasOfferings
                  ? _c(
                      "c-col",
                      {
                        staticClass: "broadband-offerings-page__info",
                        attrs: { cols: "6", sm: "12" }
                      },
                      [
                        _vm.selected && _vm.selected.variant
                          ? _c("product-short-description", {
                              attrs: {
                                description:
                                  _vm.broadbandOfferingsPageDescription,
                                "description-link-text":
                                  _vm.selected.variant.shortDescriptionLinkText
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.init &&
        _vm.selected &&
        _vm.selected.installationSteps &&
        _vm.selected.installationSteps.length
          ? _c(
              "section",
              {
                staticClass:
                  "\n        broadband-offerings-page__steps\n        c-wrapper\n        cm-o-layout cm-o-layout--main\n      "
              },
              [
                _vm.content.installationStepsSectionHeading && !_vm.loading
                  ? _c("h2", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.content.installationStepsSectionHeading) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.content.installationStepsSectionHeading && _vm.loading
                  ? _c("skeleton-loader", {
                      staticClass:
                        "broadband-offerings-page__steps__heading-skeleton-loader"
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.init && !_vm.loading
                  ? _c(
                      "c-row",
                      _vm._l(_vm.selected.installationSteps, function(
                        step,
                        idx
                      ) {
                        return _c(
                          "c-col",
                          {
                            key: idx,
                            staticClass: "broadband-offerings-page__step",
                            attrs: {
                              cols:
                                _vm.selected.installationSteps.length > 3
                                  ? 3
                                  : 4,
                              sm: "12"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "broadband-offerings-page__step-wrapper"
                              },
                              [
                                step.animationUrl
                                  ? _c("a-lottie-animation", {
                                      staticClass:
                                        "broadband-offerings-page__step-animation",
                                      attrs: {
                                        path: step.animationUrl,
                                        "in-view-play": ""
                                      }
                                    })
                                  : step.iconUrl
                                  ? _c("img", {
                                      attrs: {
                                        src: step.iconUrl,
                                        alt: step.iconAlt
                                      }
                                    })
                                  : _c("c-icon", {
                                      attrs: { "symbol-id": step.iconId }
                                    }),
                                _vm._v(" "),
                                _c("div", [
                                  _c("strong", [_vm._v(_vm._s(step.headline))])
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(step.text))])
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.init || _vm.loading
                  ? _c(
                      "c-row",
                      _vm._l(4, function(step, idx) {
                        return _c(
                          "c-col",
                          {
                            key: idx,
                            staticClass: "broadband-offerings-page__step",
                            attrs: { cols: "3", sm: "12" }
                          },
                          [
                            _c("skeleton-loader", {
                              staticClass:
                                "broadband-offerings-page__step__skeleton-loader"
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.init && !_vm.hasOfferings,
              expression: "init && !hasOfferings"
            }
          ],
          staticClass: "broadband-offerings-page__mobile-offerings-only-content"
        },
        [_vm._t("mobile-only")],
        2
      ),
      _vm._v(" "),
      _c(
        "c-layout-section",
        { attrs: { white: "" } },
        [
          _vm.hasOfferings
            ? _c(
                "c-tabs",
                {
                  model: {
                    value: _vm.tabs.active,
                    callback: function($$v) {
                      _vm.$set(_vm.tabs, "active", $$v)
                    },
                    expression: "tabs.active"
                  }
                },
                [
                  _vm.hasMarketingContent
                    ? _c(
                        "c-tab",
                        {
                          attrs: {
                            id: _vm.tabs.ids.MARKETING,
                            title: _vm.content.marketingTabButtonText
                          }
                        },
                        [
                          _vm.selectedMarketingHtml
                            ? _c("epi-content", {
                                attrs: { content: _vm.selectedMarketingHtml }
                              })
                            : _vm._t("marketing")
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.content.priceSummaryButtonText
                    ? _c(
                        "c-tab",
                        {
                          attrs: {
                            id: _vm.tabs.ids.PRICES,
                            title: _vm.content.priceSummaryButtonText
                          }
                        },
                        [
                          _c(
                            "c-row",
                            {
                              staticClass: "broadband-offerings-page__prices",
                              attrs: { justify: "end" }
                            },
                            [
                              _vm.selectedPriceSummaryImage
                                ? _c("div", {
                                    staticClass:
                                      "broadband-offerings-page__prices-image",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        _vm.selectedPriceSummaryImage.src +
                                        ")"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "c-col",
                                { attrs: { cols: "6", sm: "12" } },
                                [
                                  _vm.selectedPriceSummary
                                    ? _c(
                                        "price-summary",
                                        _vm._b(
                                          { attrs: { loading: _vm.loading } },
                                          "price-summary",
                                          _vm.selectedPriceSummary,
                                          false
                                        )
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasFaqContent
                    ? _c(
                        "c-tab",
                        {
                          staticClass: "broadband-offerings-page__faq",
                          attrs: {
                            id: _vm.tabs.ids.FAQ,
                            title: _vm.content.faqTabButtonText
                          }
                        },
                        [
                          _vm.selectedFaqHtml
                            ? _c("epi-content", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.selectedFaqHtml)
                                }
                              })
                            : _vm._t("faq")
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.init && _vm.hasOfferings
        ? _c(
            "shop-footer",
            { attrs: { columns: _vm.shopFooterColumns } },
            [
              _vm.selected
                ? _c("template", { slot: "cta" }, [
                    _c(
                      "div",
                      { staticClass: "broadband-offerings-page__footer-cta" },
                      [
                        !_vm.loading
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "shop-footer__cta c-btn c-btn--wide",
                                class: _vm.shopFooterCta.buttonClassModifier,
                                attrs: { disabled: _vm.shopFooterCta.disabled },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.buy()
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "c-btn__column" },
                                  [
                                    _vm.shopFooterCta.iconSymbolId
                                      ? _c("c-icon", {
                                          staticClass: "c-btn--icon",
                                          attrs: {
                                            "symbol-id":
                                              _vm.shopFooterCta.iconSymbolId
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.shopFooterCta.buttonText) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.shopFooterCta.offering
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "c-btn__column shop-footer__cta-price"
                                      },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _c("count-up", {
                                              attrs: {
                                                "end-val":
                                                  _vm.shopFooterCta.offering
                                                    .value,
                                                options:
                                                  _vm.shopFooterCta.offering
                                                    .countUpOptions
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v("\n             \n            "),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.shopFooterCta.offering.label
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.loading
                          ? _c("skeleton-loader", {
                              staticClass:
                                "\n            c-btn--wide\n            shop-footer__cta shop-footer__cta--skeleton-loader\n          "
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.loading
                          ? _c("product-stock-status", [
                              _vm.shopFooterCta.disclaimer
                                ? _c(
                                    "small",
                                    {
                                      staticClass:
                                        "\n              shop-footer__minimum-price shop-footer__minimum-price--clickable\n            ",
                                      attrs: { slot: "footer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleTotalPriceClicked()
                                        }
                                      },
                                      slot: "footer"
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.shopFooterCta.disclaimer) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cm-grid",
                  attrs: { slot: "columns" },
                  slot: "columns"
                },
                _vm._l(_vm.shopFooterColumns, function(column, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "cm-grid__col--xs-6 cm-grid__col--xl-4",
                      class: index > 0 && "has-divider"
                    },
                    [
                      _c("div", { staticClass: "shop-footer__column" }, [
                        _c("p", { staticClass: "shop-footer__item-label" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(column.label) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        column.label
                          ? _c(
                              "strong",
                              { staticClass: "shop-footer__item-value" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(column.value) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          ref: "singleBroadbandErrorModal",
          attrs: {
            centered: "",
            title: _vm.content.singleBroadbandErrorModal.heading,
            "content-centered": "",
            "show-close-button": ""
          }
        },
        [
          _c("div", { staticClass: "broadband-offerings-page__modal" }, [
            _c("div", {
              staticClass: "broadband-offerings-page__modal__message",
              domProps: {
                innerHTML: _vm._s(
                  _vm.content.singleBroadbandErrorModal.bodyText
                )
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "broadband-offerings-page__modal__buttons" },
              [
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--light",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeErrorModal("singleBroadbandErrorModal")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.singleBroadbandErrorModal
                            .cancelButtonLabel
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.replace()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.singleBroadbandErrorModal
                            .acceptButtonLabel
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("subscription-page-loyalty-overlay", {
        attrs: {
          "show-loyalty-vas-modal": _vm.showLoyaltyVasModal,
          content: _vm.content,
          "loyalty-vases": _vm.loyaltyVases,
          "is-broadband": true
        },
        on: {
          buyWithVas: _vm.buyWithVas,
          close: function($event) {
            return _vm.closeLoyaltyVasModal()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: {
            visible: _vm.showTooManyNodesModal,
            centered: "",
            "content-centered": "",
            "show-close-button": ""
          },
          on: {
            close: function($event) {
              return _vm.closeTooManyNodesModal()
            }
          }
        },
        [
          _c("div", { staticClass: "broadband-offerings-page__modal" }, [
            _c(
              "h2",
              { staticClass: "broadband-offerings-page__modal__title" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.content.tooManyNodesBroadbandAlert.heading) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "broadband-offerings-page__modal__message text-center",
              domProps: {
                innerHTML: _vm._s(
                  _vm.content.tooManyNodesBroadbandAlert.content
                )
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "broadband-offerings-page__modal__buttons" },
              [
                _c(
                  "button",
                  {
                    staticClass: "c-btn c-btn--primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeTooManyNodesModal()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.content.tooManyNodesBroadbandAlert.buttonText
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }