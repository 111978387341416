<template>
  <div class="cm-c-modal__content">
    <div class="cm-c-modal__article">
      <h3 v-html="headline"></h3>
      <p v-html="description"></p>
    </div>
    <div class="cm-c-form">
      <fieldset>
        <div class="cm-c-form__row">
          <div class="cm-c-form-control-group">
            <input
              :id="nameField.id"
              v-model="name"
              :name="nameField.name"
              required
              type="text"
              class="cm-c-form-control cm-js-material-input"
              autocomplete="new-password"
            />
            <span class="cm-c-form-control__bar"></span>
            <label
              class="cm-c-form-control__label"
              v-html="nameField.label"
            ></label>
          </div>
        </div>
        <div class="cm-c-form__row">
          <div class="cm-c-form-control-group">
            <input
              :id="phoneField.id"
              v-model="phone"
              :name="phoneField.name"
              required
              type="tel"
              class="cm-c-form-control cm-js-material-input"
              autocomplete="new-password"
            />
            <span class="cm-c-form-control__bar"></span>
            <label
              class="cm-c-form-control__label"
              v-html="phoneField.label"
            ></label>
          </div>
        </div>
        <div v-if="errors" class="cm-c-form__row">
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="cm-c-checkout__link"
              v-html="error"
            ></li>
          </ul>
        </div>
        <div class="cm-c-form__row cm-c-form__row--footer cm-text-right">
          <button
            class="c-btn c-btn--sm c-btn--inline"
            @click="HIDE_DIALOG"
            v-html="buttons.cancel.label"
          ></button>
          <button
            class="c-btn c-btn--primary c-btn--sm c-btn--inline"
            :disabled="!name || !phone"
            @click="accept"
            v-html="buttons.accept.label"
          ></button>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default {
  name: 'CallBackDialog',
  props: {
    apiUrl: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    hiddenField: {
      id: String,
      name: String,
      value: String,
      default: ''
    },
    nameField: {
      label: String,
      id: String,
      name: String,
      default: ''
    },
    phoneField: {
      label: String,
      id: String,
      name: String,
      default: ''
    },
    buttons: {
      accept: {
        label: String
      },
      cancel: {
        label: String
      },
      default: () => ({})
    },
    errorMessages: {
      name: String,
      phone: String,
      default: ''
    }
  },
  data() {
    return {
      name: null,
      phone: null,
      errors: []
    };
  },
  methods: {
    ...mapMutations(['HIDE_DIALOG']),
    accept() {
      if (this.isValidForm()) {
        Vue.http
          .post(this.apiUrl, {
            Name: this.name,
            Phone: this.phone,
            BlockIdFromPost: this.hiddenField.value
          })
          .then(response => response.json())
          .then(data => {
            if (data.errorMessage) {
              this.errors.push(data.errorMessage);
              Promise.reject(Error(data.errorMessage));
            } else {
              // reset data
              Object.assign(this.$data, this.$options.data.apply(this));
              this.HIDE_DIALOG();
            }
          });
      }
    },
    isValidForm() {
      this.errors = [];

      if (this.name && this.phone) {
        return true;
      }

      if (!this.name) {
        this.errors.push(this.errorMessages.name);
      }

      if (!this.phone) {
        this.errors.push(this.errorMessages.phone);
      }
    }
  }
};
</script>
