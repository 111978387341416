var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-gallery",
      class:
        "c-gallery--" +
        (_vm.altProps
          ? _vm.altProps.thumbnailPosition
          : _vm.thumbnailPosition) +
        "-thumbnails"
    },
    [
      _c(
        "div",
        { staticClass: "c-gallery__selected" },
        [
          _vm._t(
            "selected",
            [
              _c("c-carousel", {
                attrs: {
                  data: _vm.items,
                  navigation: _vm.altProps
                    ? _vm.altProps.navigation
                    : _vm.navigation,
                  "rounded-navigation-buttons": _vm.altProps
                    ? _vm.altProps.navigationRounded
                    : _vm.navigationRounded,
                  loop: _vm.altProps ? _vm.altProps.loop : _vm.loop,
                  "single-slider-speed": 300
                },
                scopedSlots: _vm._u([
                  {
                    key: "slide",
                    fn: function(ref) {
                      var data = ref.data
                      return [
                        data.type && data.type.toLowerCase() === "video"
                          ? [
                              data.description
                                ? _c("meta", {
                                    attrs: {
                                      itemprop: "description",
                                      content: data.description
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.caption
                                ? _c("meta", {
                                    attrs: {
                                      itemprop: "name",
                                      content: data.caption
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.thumbnail
                                ? _c("meta", {
                                    attrs: {
                                      itemprop: "thumbnailUrl",
                                      content: data.thumbnail
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              data.uploadDate
                                ? _c("meta", {
                                    attrs: {
                                      itemprop: "uploadDate",
                                      content: data.uploadDate
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.videoLocal(data.src)
                                ? _c("video", { attrs: { controls: "" } }, [
                                    _c("source", {
                                      attrs: {
                                        src: data.src,
                                        type: _vm.videoType(data.src),
                                        tabindex: "-1"
                                      }
                                    })
                                  ])
                                : _c("iframe", {
                                    attrs: {
                                      src: data.src.replace(
                                        "watch?v=",
                                        "embed/"
                                      ),
                                      frameborder: "0",
                                      tabindex: "-1",
                                      allow: "autoplay; encrypted-media",
                                      allowfullscreen: ""
                                    }
                                  })
                            ]
                          : _c("img", {
                              class: {
                                "allow-click": _vm.altProps
                                  ? _vm.altProps.overlay
                                  : _vm.overlay
                              },
                              attrs: {
                                alt: data.alt,
                                src: _vm.getSrc(data.src),
                                selected: data.caption,
                                srcset: _vm.getSrcSet(data.src),
                                sizes: _vm.getSizes()
                              },
                              on: { click: _vm.handleSlideClick }
                            })
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedIndexLocal,
                  callback: function($$v) {
                    _vm.selectedIndexLocal = $$v
                  },
                  expression: "selectedIndexLocal"
                }
              })
            ],
            { data: _vm.selected }
          ),
          _vm._v(" "),
          _vm._t("selectedFooter", null, { data: _vm.selected })
        ],
        2
      ),
      _vm._v(" "),
      _vm.hideSingle && _vm.items.length && _vm.items.length !== 1
        ? _c(
            "ul",
            {
              staticClass: "c-gallery__thumbnails",
              attrs: {
                itemscope: "",
                itemtype: "http://schema.org/ImageGallery"
              }
            },
            [
              _vm._l(_vm.shownItems, function(item, idx) {
                return _c(
                  "li",
                  {
                    key: idx,
                    class: { selected: idx === _vm.selectedIndexLocal },
                    attrs: { role: "button" },
                    on: {
                      click: function($event) {
                        return _vm.handleThumbnailClick(idx)
                      }
                    }
                  },
                  [
                    _vm._t(
                      "item",
                      [
                        _c("div", {
                          staticClass: "c-gallery__thumbnails-image",
                          class: {
                            "c-gallery__thumbnails-image--dim":
                              _vm.collapsed && idx === _vm.getMinVisible
                          },
                          style: {
                            backgroundImage:
                              "url(" + _vm.getThumbnail(item) + ")"
                          }
                        })
                      ],
                      { data: item, selected: idx === _vm.selectedIndexLocal }
                    ),
                    _vm._v(" "),
                    _vm.collapsed && idx === _vm.getMinVisible
                      ? _c(
                          "div",
                          { staticClass: "c-gallery__thumbnails-expand" },
                          [
                            _vm._v(
                              "\n        + " +
                                _vm._s(_vm.collapsedCount) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.collapsable,
                      expression: "collapsable"
                    }
                  ],
                  staticClass: "c-gallery__thumbnails-collapse",
                  on: { click: _vm.collapse }
                },
                [_c("span", [_vm._v("Hide")])]
              )
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }