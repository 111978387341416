var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cm-container" }, [
    _c("div", { staticClass: "cm-o-layout cm-o-layout--main" }, [
      _c("div", { staticClass: "cm-grid shopping-cart" }, [
        _c("div", { staticClass: "cm-grid cm-grid__col--xs-12" }, [
          _c("div", { staticClass: "cm-grid cm-grid__col--xs-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n              cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2\n              shopping-cart__header\n            "
              },
              [
                _c("h2", { staticClass: "shopping-cart__title" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.basketPageHeading) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.content.clearBasketEnabled && !_vm.isEmpty
                  ? _c("empty-basket", {
                      attrs: { content: _vm.emptyBasketModal }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        !_vm.isInit || _vm.isUpdating
          ? _c("div", { staticClass: "cm-c-spinner" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isInit,
                expression: "isInit"
              }
            ],
            staticClass: "cm-grid cm-grid__col--xs-12"
          },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.content.emptyBasketPageDescription && _vm.isEmpty,
                  expression: "content.emptyBasketPageDescription && isEmpty"
                }
              ],
              staticClass:
                "\n            shopping-cart--empty\n            cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2\n          ",
              domProps: {
                innerHTML: _vm._s(_vm.content.emptyBasketPageDescription)
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isEmpty,
                    expression: "!isEmpty"
                  }
                ],
                staticClass:
                  "\n            cm-grid__col--xs-12 cm-grid__col--xl-8 cm-grid__col--xl-offset-2\n          "
              },
              [_vm._t("main-content")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isEmpty,
                    expression: "!isEmpty"
                  }
                ],
                staticClass: "cm-grid__col--xs-12"
              },
              [_vm._t("full-width-content")],
              2
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }