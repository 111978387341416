var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "config-group-item",
    {
      attrs: {
        "prevent-expand": _vm.preventExpand,
        title: _vm.title,
        icon: _vm.icon,
        expanded: _vm.expanded
      },
      on: { expanded: _vm.handleExpanded }
    },
    [
      _c(
        "fieldset",
        { staticClass: "existing-number-config" },
        [
          _c(
            "div",
            { staticClass: "existing-number-config__number-options" },
            [
              _vm.content.prolonging.existingSubscriptionListHeaderText
                ? _c(
                    "legend",
                    {
                      staticClass:
                        "existing-number-config__number-options__legend"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.content.prolonging
                              .existingSubscriptionListHeaderText
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "c-form-radio-group",
                {
                  attrs: { name: "numberOption", stacked: "", highlighted: "" },
                  on: { input: _vm.getSubscriptionConfig },
                  model: {
                    value: _vm.form.selectedPhoneNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "selectedPhoneNumber", $$v)
                    },
                    expression: "form.selectedPhoneNumber"
                  }
                },
                _vm._l(_vm.availableNumbers, function(number) {
                  return _c(
                    "c-form-radio",
                    {
                      key: number.number,
                      attrs: {
                        id: number.number,
                        value: number.number,
                        "description-text": number.name
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "existing-number-config__number-options__subscription-name"
                        },
                        [_vm._v(_vm._s(number.number))]
                      )
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "c-collapse",
                {
                  attrs: {
                    id: "existing-number-config-number-option-item-unavailable",
                    visible: _vm.showHiddenNumbers
                  }
                },
                [
                  _c(
                    "c-form-radio-group",
                    {
                      attrs: {
                        name: "numberOption",
                        stacked: "",
                        highlighted: ""
                      }
                    },
                    _vm._l(_vm.unAvailableNumbers, function(number) {
                      return _c(
                        "c-form-radio",
                        {
                          key: number.subscription.number,
                          attrs: {
                            id: number.subscription.number,
                            value: number.subscription.number,
                            "description-text": number.subscription.name,
                            "is-disabled": !number.subscription.isAvailable,
                            "additional-text": number.message
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "\n                existing-number-config__number-options__subscription-name\n              "
                            },
                            [_vm._v(_vm._s(number.subscription.number))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "existing-number-config__number-options__show-more"
                },
                [
                  !_vm.showHiddenNumbers
                    ? _c(
                        "c-button",
                        {
                          attrs: {
                            variant: "link",
                            "data-testid":
                              "existing-number-config-show-more-numbers"
                          },
                          on: { click: _vm.toggleHiddenNumbers }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.seeMoreLinkText) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "number" },
                domProps: { value: _vm.form.selectedPhoneNumber }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.content.hideSimTypeOptions &&
          _vm.availableNumbers &&
          _vm.availableNumbers.length > 0
            ? _c("sim-card-selection", {
                staticClass: "existing-number-config__simcard-selection",
                attrs: {
                  "checked-sim-radio": _vm.checkedSimRadio,
                  "config-data": _vm.configData,
                  options: _vm.simCardSettings
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.SIM_OPTIONS.SHIP_SIM,
                    expression: "SIM_OPTIONS.SHIP_SIM"
                  }
                ],
                attrs: { type: "hidden", name: "SimOption" },
                domProps: { value: _vm.SIM_OPTIONS.SHIP_SIM },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.SIM_OPTIONS, "SHIP_SIM", $event.target.value)
                  }
                }
              }),
          _vm._v(" "),
          _c(
            "c-modal",
            {
              ref: "prolongingConfigurationModal",
              attrs: { centered: "" },
              on: { close: _vm.close }
            },
            [
              _c("div", { staticClass: "existing-number-config__container" }, [
                _c("h2", { staticClass: "existing-number-config__header" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.content.prolonging.newVasModalPopupHeaderText
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "existing-number-config__brand" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.form.selectedPhoneNumber) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.content.prolonging
                              .newVasModalServicesAffectedbyChangingNumberText
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("c-vas-configuration", {
                    attrs: {
                      vases: _vm.localResponse,
                      "group-id": _vm.groupId,
                      content: _vm.content.prolonging
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "c-button",
                {
                  staticClass: "c-btn--primary",
                  attrs: { slot: "footer", type: "submit" },
                  on: { click: _vm.hideDialog },
                  slot: "footer"
                },
                [
                  _vm._v(
                    _vm._s(_vm.content.prolonging.newVasModalAgreeButtonText)
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "existing-number-config__info-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.content.prolonging.newVasModalDisclaimerLinkText
                          ) +
                          "\n          "
                      ),
                      _c(
                        "c-button",
                        {
                          attrs: { variant: "link" },
                          on: {
                            click: function($event) {
                              return _vm.openSelfservice(
                                _vm.content.prolongingUnavailablePopUpButtonLink
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.content.prolongingUnavailablePopUpButtonText
                            ) + "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }