export const BASKET_OPERATIONS = {
  ADD_HARDWARE: 'AddHardware',
  ADD_SUBSCRIPTION: 'AddSubscription',
  ADD_SERVICE: 'AddValueAddedService',
  ADD_ACCESSORY: 'AddAccessories',
  ADD_BROADBAND: 'AddBroadband',
  ADD_HOME_OFFICE: 'addHomeOffice',
  REMOVE: 'Remove',
  REMOVE_EXISTING: 'RemoveExisting'
};
