import { get, put, post } from './api.js';

export const getNumberConfigurationGroupsByProductId = productCode =>
  get(
    `/checkout/numberConfigurationGroups/product/${productCode}/existing/numbers`
  );

export const getNumberConfigurationGroupsByGroupId = groupId =>
  get(`/checkout/numberConfigurationGroups/${groupId}/existing/numbers`);

export const getPickupPoints = params => {
  let config = {
    headers: {
      accept: 'application/vnd.api+json'
    },
    params
  };
  return get('/carts/shipping/pickuppoints', config);
};

export const getPickupPointsByCoordinates = params => {
  let config = {
    headers: {
      accept: 'application/vnd.api+json'
    },
    params
  };
  return get('/location/addresses', config);
};

export const setShippingMethod = params => {
  return put(
    '/carts/shipping/method',
    {
      data: {
        id: null,
        type: 'ShippingMethodUpdateRequest',
        attributes: {
          blockId: params.blockId,
          shippingMethod: params.shippingMethod,
          deliveryLocationCode: params.deliveryLocationCode,
          ignorePickupPoint: params.ignorePickupPoint
        }
      }
    },
    {
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const setPhoneNumber = params => {
  return put(
    '/carts/contactdetails/phone',
    {
      data: {
        id: null,
        type: 'ContactPhoneUpdateRequest',
        attributes: {
          phone: params.phoneNumber
        }
      }
    },
    {
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }
    }
  );
};

export const setSignature = params => {
  return post(
    '/carts/signing/signature',
    {
      data: {
        type: 'signingrequest',
        attributes: {
          image: params.image,
          type: params.type
        }
      }
    },
    {
      headers: {
        accept: 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }
    }
  );
};
