import { render, staticRenderFns } from "./form-dialog.vue?vue&type=template&id=1c666906&"
import script from "./form-dialog.vue?vue&type=script&lang=js&"
export * from "./form-dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c666906')) {
      api.createRecord('1c666906', component.options)
    } else {
      api.reload('1c666906', component.options)
    }
    module.hot.accept("./form-dialog.vue?vue&type=template&id=1c666906&", function () {
      api.rerender('1c666906', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/webshop/shared/components/generic/dialogs/form-dialog.vue"
export default component.exports