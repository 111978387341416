export const DEFAULT_SEPARATOR = '::';

export const eventOn = (el, eventName, handler) => {
  if (el && el.addEventListener) {
    el.addEventListener(eventName, handler);
  }
};

export const eventOff = (el, eventName, handler) => {
  if (el && el.removeEventListener) {
    el.removeEventListener(eventName, handler);
  }
};

/**
 * Generates an event name.
 * @description ensures a common way of how event names are created.
 *
 * @param {string} namespace - common grouping for events
 * @param {string} name - name of the event
 * @param {string} separator - defaults to ::
 * @returns formated event name
 */
export const getEventName = (namespace, name, separator = DEFAULT_SEPARATOR) =>
  `v${separator}${namespace}${separator}${name}`;
