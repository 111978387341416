<template>
  <config-group-item
    :prevent-expand="preventExpand"
    :title="title"
    :icon="icon"
    :expanded="expanded"
    @expanded="handleExpanded"
  >
    <fieldset class="existing-number-config">
      <div class="existing-number-config__number-options">
        <legend
          v-if="content.prolonging.existingSubscriptionListHeaderText"
          class="existing-number-config__number-options__legend"
        >
          {{ content.prolonging.existingSubscriptionListHeaderText }}
        </legend>
        <!-- Available numbers -->
        <c-form-radio-group
          v-model="form.selectedPhoneNumber"
          name="numberOption"
          stacked
          highlighted
          @input="getSubscriptionConfig"
        >
          <c-form-radio
            v-for="number in availableNumbers"
            :id="number.number"
            :key="number.number"
            :value="number.number"
            :description-text="number.name"
          >
            <span
              class="existing-number-config__number-options__subscription-name"
              >{{ number.number }}</span
            >
          </c-form-radio>
        </c-form-radio-group>
        <!-- Unavailable numbers -->

        <c-collapse
          id="existing-number-config-number-option-item-unavailable"
          :visible="showHiddenNumbers"
        >
          <c-form-radio-group name="numberOption" stacked highlighted>
            <c-form-radio
              v-for="number in unAvailableNumbers"
              :id="number.subscription.number"
              :key="number.subscription.number"
              :value="number.subscription.number"
              :description-text="number.subscription.name"
              :is-disabled="!number.subscription.isAvailable"
              :additional-text="number.message"
            >
              <span
                class="
                  existing-number-config__number-options__subscription-name
                "
                >{{ number.subscription.number }}</span
              >
            </c-form-radio>
          </c-form-radio-group>
        </c-collapse>

        <div class="existing-number-config__number-options__show-more">
          <c-button
            v-if="!showHiddenNumbers"
            variant="link"
            data-testid="existing-number-config-show-more-numbers"
            @click="toggleHiddenNumbers"
          >
            {{ seeMoreLinkText }}
          </c-button>
        </div>
        <input type="hidden" name="number" :value="form.selectedPhoneNumber" />
      </div>

      <sim-card-selection
        v-if="
          !content.hideSimTypeOptions &&
          availableNumbers &&
          availableNumbers.length > 0
        "
        class="existing-number-config__simcard-selection"
        :checked-sim-radio="checkedSimRadio"
        :config-data="configData"
        :options="simCardSettings"
      />
      <!-- HardCode for mittele, form works incorrectly unless backend receives some sort of sim option -->
      <input
        v-else
        v-model="SIM_OPTIONS.SHIP_SIM"
        type="hidden"
        name="SimOption"
      />
      <c-modal ref="prolongingConfigurationModal" centered @close="close">
        <div class="existing-number-config__container">
          <h2 class="existing-number-config__header">
            {{ content.prolonging.newVasModalPopupHeaderText }}
          </h2>
          <h2 class="existing-number-config__brand">
            {{ form.selectedPhoneNumber }}
          </h2>
        </div>
        <div>
          <p>
            <strong>
              {{
                content.prolonging
                  .newVasModalServicesAffectedbyChangingNumberText
              }}
            </strong>
          </p>
          <c-vas-configuration
            :vases="localResponse"
            :group-id="groupId"
            :content="content.prolonging"
          />
        </div>
        <c-button
          slot="footer"
          class="c-btn--primary"
          type="submit"
          @click="hideDialog"
          >{{ content.prolonging.newVasModalAgreeButtonText }}</c-button
        >
        <div slot="footer" class="existing-number-config__info-footer">
          <span>
            {{ content.prolonging.newVasModalDisclaimerLinkText }}
            <c-button
              variant="link"
              @click="
                openSelfservice(content.prolongingUnavailablePopUpButtonLink)
              "
              >{{ content.prolongingUnavailablePopUpButtonText }}
            </c-button>
          </span>
        </div>
      </c-modal>
    </fieldset>
  </config-group-item>
</template>

<script>
import {
  CButton,
  CCollapse,
  CFormRadio,
  CFormRadioGroup
} from 'olympus/components';
import ConfigGroupItem from '../config-group-item/config-group-item.vue';
import { SIM_OPTIONS } from 'webshop/enums/sim-options';
import { getSimCardSettings } from '../product-config-utils.js';
import SimCardSelection from '../sim-card-selection/sim-card-selection.vue';
import CVasConfiguration from '../../../../../vas/c-vas-configuration.vue';

import { required, requiredIf } from 'vuelidate/lib/validators/index.js';
import { mapMutations } from 'vuex';

import { getVasConfiguration } from '../../../../../../api/vasconfiguration-service.js';

export default {
  name: 'ExistingNumberConfig',

  components: {
    CButton,
    CCollapse,
    CFormRadio,
    CFormRadioGroup,
    ConfigGroupItem,
    SimCardSelection,
    CVasConfiguration
  },

  props: {
    preventExpand: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    groupId: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    numbersNoteLabel: {
      type: String,
      default: ''
    },
    numberOptions: {
      type: Array,
      default: () => []
    },
    expanded: {
      type: Boolean,
      default: false
    },
    configData: {
      type: Object,
      default: () => ({})
    },
    content: {
      type: Object,
      default: () => ({})
    },
    checkedSimRadio: {
      type: String,
      default: ''
    },
    selectedNumber: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isModalOpen: false,
      showHiddenNumbers: false,
      form: {
        selectedPhoneNumber: '',
        selectedSimcardType: ''
      },
      SIM_OPTIONS: SIM_OPTIONS,
      localContent: this.content,
      localResponse: null,
      configAccepted: false
    };
  },

  validations: {
    form: {
      selectedPhoneNumber: {
        required
      },
      selectedSimcardType: {
        required: requiredIf(function () {
          /* eslint-disable no-invalid-this */
          return (
            !this.content.hideSimTypeOptions &&
            this.content.showSimDeliveryOptionsForPartners
          );
        })
      }
    }
  },

  computed: {
    simCardSettings() {
      return getSimCardSettings(this.content, this.configData);
    },

    availableNumbers() {
      return (
        this.configData.attributes &&
        this.configData.attributes.subscriptions.filter(n => n.isAvailable)
      );
    },

    unAvailableNumbers() {
      return (
        this.configData.attributes &&
        this.configData.attributes.subscriptions
          .filter(n => !n.isAvailable)
          .map(n => {
            const errorMessage = this.content.prolongingRejectionMessages.find(
              m => m.code === n.unavailabilityReasonCode
            );
            return {
              subscription: n,
              message: errorMessage && errorMessage.message
            };
          })
      );
    },

    seeMoreLinkText() {
      return `${
        this.content?.prolonging?.seeMoreExistingSubscriptionsLabel
      } (+${this.unAvailableNumbers && this.unAvailableNumbers.length})`;
    },

    ready() {
      return !this.$v.$invalid;
    }
  },

  watch: {
    ready(value) {
      this.$emit('ready', value);
    },
    availableNumbers(value) {
      if (value) {
        let selectedOne = value.find(
          item => item.number === this.selectedNumber
        );
        if (!selectedOne) {
          return;
        }
        this.setSelectedSubscription({
          phoneNumber: selectedOne.number,
          subscriptionName: selectedOne.name
        });
      }
    }
  },

  methods: {
    ...mapMutations(['HIDE_SPINNER', 'SHOW_DIALOG', 'SHOW_SPINNER']),

    getReasonCode(code) {
      this.content.prolongingRejectionMessages.find(m => m.code === code);
    },
    showModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      // ! Is comment relevant here Georgi?
      /**
       * CModal does not support close delagate so for now
       * we close the modal and await the action. We might want
       * to change that.
       */
      this.isModalOpen = false;
      this.$refs.configurationOverlay.hide();
    },
    handleExpanded(expanded) {
      this.$emit('expanded');

      // Reset vuelidate and component state on collapse
      if (!expanded) {
        if (this.$v) {
          this.$v.$reset();
        }
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    toggleHiddenNumbers() {
      this.showHiddenNumbers = !this.showHiddenNumbers;
    },
    setSelectedSubscription(e) {
      this.form.selectedPhoneNumber = e.phoneNumber;
    },
    showDialog() {
      this.$refs.prolongingConfigurationModal.show();
    },
    hideDialog() {
      this.configAccepted = true;
      this.$refs.prolongingConfigurationModal.hide();
    },
    getSubscriptionConfig(selectedSubscription) {
      this.configAccepted = false;
      if (selectedSubscription) {
        this.SHOW_SPINNER();
        return new Promise((resolve, reject) => {
          getVasConfiguration(this.groupId, { selectedSubscription })
            .then(response => {
              if (response?.data?.data?.length) {
                this.localResponse = response.data.data;
                this.showDialog();
              }
              return resolve({ response });
            })
            .catch(error => {
              return reject(error);
            })
            .finally(() => {
              this.HIDE_SPINNER();
            });
        });
      }
    },
    close() {
      if (!this.configAccepted) {
        this.form.selectedPhoneNumber = '';
      }
    },
    openSelfservice(url) {
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';

.existing-number-config {
  // .existing-number-config__number-options
  &__number-options {
    display: flex;
    flex-direction: column;
    margin-top: $u-200;

    // .existing-number-config__number-options__show-more
    &__show-more {
      align-self: flex-start;
    }

    &__legend {
      margin-bottom: $u-250;
      font-weight: 700;
    }

    &__subscription-name {
      font-weight: 700;
    }
  }

  // .existing-number-config__aditional-options
  &__additional-options {
    // .existing-number-config__aditional-options-label
    &-label {
      font-weight: 700;
      margin-top: $u-450;
      margin-bottom: $u-300;
    }
  }

  // .existing-number-config__aditional-option
  &__additional-option {
    display: flex;
    align-items: center;
    margin-bottom: $u-300;

    // .existing-number-config__additional-option-icon
    &-icon {
      margin-left: $u-150;
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }

  &__info-box {
    margin-top: 48px;
  }

  // existing-number-config__simcard-selection
  &__simcard-selection {
    margin-top: $u-500;
  }

  &__container {
    margin-right: 0.5em;
    margin-block: 0.5em;
    margin-bottom: 1em;
  }

  &__brand {
    display: inline;
    color: $c-pm-600;
  }

  &__header {
    display: inline;
  }

  &__info-footer {
    display: inline;
    margin-top: 1em;
  }
}

.c-btn--link {
  height: 1rem;
  vertical-align: initial;
  margin: 0px;
}
</style>
