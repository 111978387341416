<template>
  <div class="cm-c-modal__content">
    <div class="cm-c-modal__article">
      <h3 v-html="contentObject.headline"></h3>
      <p v-html="contentObject.description"></p>
    </div>
    <div class="cm-c-form">
      <fieldset>
        <div
          v-for="(input, inputindex) in inputs"
          :key="inputindex"
          class="cm-c-form__row"
          :class="{ hidden: input.type == 'hidden' }"
        >
          <div class="cm-c-form-control-group">
            <input
              v-model="inputs[inputindex].value"
              required
              :type="input.type"
              :name="input.name"
              class="cm-c-form-control cm-js-material-input"
              autocomplete="new-password"
            />
            <span class="cm-c-form-control__bar"></span>
            <label
              class="cm-c-form-control__label"
              v-html="input.label"
            ></label>
          </div>
        </div>
        <div class="cm-c-form__row">
          <ul>
            <li
              v-for="(error, index) in errors"
              :key="index"
              class="cm-c-checkout__link"
              v-html="error"
            ></li>
          </ul>
        </div>
      </fieldset>
    </div>
    <div class="cm-c-form__row cm-c-form__row--footer cm-text-right">
      <button
        class="c-btn c-btn--primary c-btn--sm c-btn--inline"
        :disabled="!isValid"
        @click="accept()"
        v-html="contentObject.buttonLabel"
      ></button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default {
  name: 'FormDialog',
  props: {
    contentObject: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      errors: [],
      inputs: this.contentObject.inputs.map(input => ({ ...input })),
      isValid: false,
      reqObject: {}
    };
  },
  watch: {
    inputs: {
      handler: function () {
        this.validateAndBuildReqObject();
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['HIDE_DIALOG', 'RELOAD_PAGE']),
    accept() {
      if (!this.isValid) {
        return;
      }

      this.errors = [];

      Vue.http
        .put(this.contentObject.apiUrl, this.reqObject)
        .then(() => {
          this.HIDE_DIALOG();
          this.RELOAD_PAGE();
        })
        .catch(error => {
          this.errors.push(error.body.message);
          Promise.reject(Error(error.body.message));
        });
    },
    validateAndBuildReqObject() {
      [].forEach.call(this.inputs, input => {
        if (input.value.length <= 0) {
          delete this.reqObject[input.name];

          return;
        }

        this.reqObject[input.name] = input.value;
      });

      this.isValid = Object.keys(this.reqObject).length === this.inputs.length;
    }
  }
};
</script>
