<template>
  <carousel
    id="carousel"
    ref="carousel"
    v-bind="pluginProps"
    @page-change="handlePageChange"
    @navigation-click="navClick"
  >
    <slide v-for="(slide, index) in slides" :key="index" ref="slides">
      <slot :data="slide" name="slide">{{ slide }}</slot>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

/**
 * @description a wrapper component to hide our caraousel
 * implementation requirements. This is based on vue-carousel
 * as third party, since our carousel implementation blew
 * out of proportion as is no where near to being reusable.
 * @see https://github.com/SSENSE/vue-carousel for more details
 * regarding the underlaying component.
 *
 * Notes:
 * @property props.spacePadding this gets overriden when @property slideOffset
 * is used, to accomodate extra spacing.
 *
 * @method adjustCarouselInner is used to accomodate for extra padding added
 * to the inner wrapper, only on the first page.
 */
export default {
  name: 'CCarousel',

  components: {
    Carousel,
    Slide
  },

  props: {
    slides: {
      type: Array,
      required: true
    },
    slideOffset: {
      type: Number,
      default: null
    },
    useSlideOffset: {
      type: Boolean,
      default: false
    },
    scrollPerPage: {
      type: Boolean,
      default: true
    },
    navigateTo: {
      type: Number,
      default: 0
    },
    navigationEnabled: {
      type: Boolean,
      default: false
    },
    navigationPrevLabel: {
      type: String,
      default: ''
    },
    navigationNextLabel: {
      type: String,
      default: ''
    },
    navigationClickTargetSize: {
      type: Number,
      default: 0
    },
    spacePadding: {
      type: Number,
      default: 0
    },
    paginationEnabled: {
      type: Boolean,
      default: false
    },
    paginationSize: {
      type: Number,
      default: 0
    },
    paginationActiveColor: {
      type: String,
      default: ''
    },
    paginationColor: {
      type: String,
      default: ''
    },
    paginationPadding: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 2
    },
    homeOffice: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    spacePaddingLocal() {
      return this.slideOffset ? this.slideOffset * 2 : this.spacePadding;
    },

    /**
     * @see https://github.com/SSENSE/vue-carousel for more details.
     *
     */
    pluginProps() {
      const { slideOffset, spacePadding, ...props } = this.$props;
      return {
        ...props,
        spacePadding: this.useSlideOffset ? this.spacePaddingLocal : 0
      };
    }
  },

  mounted() {
    this.adjustSpacing();

    if (this.navigateTo === 0) {
      this.adjustCarouselInner(0);
    }

    if (this.homeOffice) {
      document.getElementById('carousel').classList.add('home-office');
    }
  },

  methods: {
    /**
     * Vue-Carousel 3rd party component, does not support offset between
     * the slides, so we fake it till we make it. Applying space on both
     * sides of the wrapper allows seeing prev and next slides, but does
     * not accomodate extra space between the slides. Below fixes that.
     *
     */
    adjustSpacing() {
      if (this.slideOffset && this.$refs.slides && this.$refs.slides.length) {
        this.$refs.slides.forEach(({ $el }, idx) => {
          if (idx !== 0) {
            $el.style.paddingLeft = `${this.slideOffset}px`;
          }

          if (idx !== this.slides.length - 1) {
            $el.style.paddingRight = `${this.slideOffset}px`;
          }
        });
      }
    },

    adjustCarouselInner(paddingLeft) {
      const target =
        this.$refs.carousel?.$el?.querySelector('.VueCarousel-inner');
      if (target) {
        target.style.paddingLeft = `${paddingLeft}px`;
      }
    },

    handlePageChange(pageIndex) {
      if (this.useSlideOffset) {
        this.adjustCarouselInner(pageIndex === 0 ? 5 : this.spacePaddingLocal);
      }
    },
    navClick() {
      this.$emit('closeTongue');
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$button-size: $u-350;
$arrow-width: 3px;
$button-rounded-size: 68px;
$button-rounded-size-small: $u-600;

#carousel .VueCarousel-navigation-button {
  align-items: center;
  background: $color-white;
  border-radius: 50%;
  box-shadow: $box-shadow-default;
  display: flex;
  height: $button-rounded-size;
  width: $button-rounded-size;
  justify-content: center;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
  top: 190px;

  &__arrow {
    border-bottom: $arrow-width solid black;
    border-right: $arrow-width solid black;
    content: '';
    display: block;
    height: $button-size;
    width: $button-size;
    border-color: $c-pm-500;
    border-width: 2px;
    transition: border-color 0.2s ease;
    will-change: border-color, transform;

    &--next {
      transform: translateX(-25%) rotate(-45deg);
    }

    &--prev {
      transform: translateX(25%) rotate(135deg);
    }
  }
}

#carousel .VueCarousel-navigation-prev {
  transform: translateY(-55%) translateX(-110%);
}

#carousel .VueCarousel-navigation-next {
  transform: translateY(-55%) translateX(35%);

  .broadband-offerings-page & {
    transform: translateY(-55%) translateX(110%);

    @include mq($screen-xxl, max) {
      transform: translateY(-55%) translateX(10%);
    }
  }
}

#carousel .VueCarousel-navigation--disabled {
  display: none;
}

.home-office {
  top: 0;

  .VueCarousel-inner {
    padding-bottom: $u-800;
  }
}
</style>
