import { toFullQuery } from '../../../../shared/utils/ajax.js';
import { get } from 'axios';

let cache = {};

// TODO: fix below - code is doing a lot more it should
//function getSuggestions(query, caretPosition, hasStreetName, addressId, streetNameAndPostCodeRelationOnly, callback, errorCallback) {
function getSuggestions(suggestionData) {
  if (!suggestionData.query.length) {
    return false;
  }

  let params = {};
  params.q = suggestionData.query;
  params.fuzzy = '';
  params.type = 'adresse';
  params.per_side = suggestionData.pageSize ? suggestionData.pageSize : 100;

  let zip = /(\d{4})/g.exec(suggestionData.query);
  if (zip !== null) {
    zip = zip[0];
  } else {
    zip = false;
  }

  if (suggestionData.addressId) {
    params.adgangsadresseid = suggestionData.addressId;
  }

  if (suggestionData.caretposition) {
    params.caretpos = suggestionData.caretPosition;
  }

  if (suggestionData.hasStreetName) {
    params.startfra = 'adgangsadresse';
  }

  if (zip) {
    // this is to accomodate a difference in the API requests
    if (suggestionData.streetNameAndPostCodeRelationOnly) {
      params.postNumber = zip;
    } else {
      params.postnr = zip;
    }
  }

  const paramsString = JSON.stringify(params);
  if (cache[paramsString]) {
    return suggestionData.callback(cache[paramsString]);
  }

  const base = '/api/olympus/dawa';

  const query = toFullQuery(
    `${base}${
      suggestionData.streetNameAndPostCodeRelationOnly
        ? '/relations'
        : '/search'
    }`,
    params
  );

  function mapDefaultResponse(data) {
    return data.map(s => {
      return {
        displayName: s.forslagstekst,
        type: s.type,
        caretposition: s.caretpos,
        id: s.data?.id,
        municipalityCode: s.data?.kommunekode,
        streetName: s.data?.vejnavn,
        streetNumber: s.data?.husnr,
        houseLetter: s.houseLetter,
        streetCode: s.data?.vejkode,
        floorNumber: s.data?.etage || '',
        floorSide: s.data?.dør || '',
        postCode: s.data?.postnr,
        city: s.data?.postnrnavn,
        kvhx: '',
        coordinate_x: s.data?.x,
        coordinate_y: s.data?.y
      };
    });
  }

  function mapStreetNameAndPostCodeRelationResponse(data) {
    return data.map(s => {
      return {
        displayName: s.tekst,
        streetName: s.vejnavnPostNummerRelation.vejnavn,
        postCode: s.vejnavnPostNummerRelation.postnr,
        postCodeName: s.vejnavnPostNummerRelation.postnrnavn,
        coordinate_x: s.vejnavnPostNummerRelation.visueltcenter_x,
        coordinate_y: s.vejnavnPostNummerRelation.visueltcenter_y
      };
    });
  }

  setTimeout(() => {
    get(query)
      .then(result => {
        if (!result) {
          return;
        }

        const addresses = suggestionData.streetNameAndPostCodeRelationOnly
          ? mapStreetNameAndPostCodeRelationResponse(result.data)
          : mapDefaultResponse(result.data);

        cache[paramsString] = addresses;

        suggestionData.callback(addresses);
      })
      .catch(suggestionData.errorCallback);
  }, 100);
}

export default getSuggestions;
