var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card",
    { attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" } },
    [
      _c("h3", { staticClass: "cm-c-form__header" }, [
        _vm._v("\n    " + _vm._s(_vm.content.title) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.data.items, function(item, idx) {
        return _c(
          "div",
          { key: idx, staticClass: "cm-c-checkout-summary__section" },
          [
            _c(
              "div",
              { staticClass: "cm-c-checkout-summary__section__delivery-group" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "cm-c-checkout-summary__section__delivery-group__title"
                  },
                  [
                    _vm.content.headlines
                      ? _c("strong", [
                          _vm._v(_vm._s(_vm.getHeadline(item.headlineType)))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                item.headlineType === _vm.ADDRESS_HEADLINES.NO_ADDRESS_NECESSARY
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "cm-c-checkout-summary__section__delivery-group__address"
                      },
                      [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.content.noShippingNecessaryLabel
                            )
                          }
                        })
                      ]
                    )
                  : _c(
                      "ul",
                      {
                        staticClass:
                          "cm-c-checkout-summary__section__delivery-group__address"
                      },
                      [
                        _c("li", [_vm._v(_vm._s(item.organization))]),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(item.addressLine))]),
                        _vm._v(" "),
                        item.postalCode && item.city
                          ? _c("li", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.postalCode + " " + item.city) +
                                  "\n        "
                              )
                            ])
                          : item.zipCity
                          ? _c("li", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.zipCity) +
                                  "\n        "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
              ]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }