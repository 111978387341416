<template>
  <div :class="classObject">
    <div class="c-tabs-nav">
      <ul>
        <li
          v-for="(tab, idx) in items"
          :key="tab.uid"
          :class="{ active: tab.uid === activeLocal.uid }"
        >
          <!-- prettier-ignore -->
          <a
            role="tab"
            href="#"
            target="_self"
            :aria-selected="tab.uid === activeLocal.uid"
            :aria-controls="tab.uid"
            :aria-posinset="idx"
            :aria-setsize="items.length"
            @click.prevent.stop="toggle(tab)"
          >{{ tab.title }}</a>
        </li>
      </ul>
    </div>
    <div v-cloak ref="content" class="c-tabs-content">
      <slot />
    </div>
  </div>
</template>
<script>
const MODEL = {
  PROP: 'active',
  EVENT: 'activated'
};

/**
 * TODO TC-4920: we need to ensure unique ids in case of
 * multiple Tabs on the same page.
 *
 */
export default {
  name: 'c-tabs',

  model: {
    prop: MODEL.PROP,
    event: MODEL.EVENT
  },

  props: {
    [MODEL.PROP]: {
      type: String,
      default: null
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: [],
      activeLocal: null
    };
  },

  computed: {
    classObject() {
      return {
        'c-tabs': true,
        'c-tabs--vertical': this.vertical
      };
    }
  },

  watch: {
    [MODEL.PROP](val, old) {
      if (val !== old) {
        this.toggleById(val);
      }
    }
  },

  mounted() {
    this.items.forEach(x => {
      if (x.activeLocal) {
        if (this.activeLocal) {
          x.deactivate();
        } else {
          this.activeLocal = x;
        }
      }
    });

    if (!this.activeLocal) {
      this.toggle(this.items[0]);
    }
  },

  methods: {
    toggle(tab) {
      if (tab) {
        if (this.activeLocal) {
          this.activeLocal.deactivate();
        }

        tab.activate();
        this.activeLocal = tab;
        const { uid } = tab;
        this.$emit(MODEL.EVENT, uid);
      }
    },

    toggleById(tabId) {
      const tab = this.items.find(t => tabId === t.uid);

      if (tab) {
        this.toggle(tab);
      }
    }
  }
};
</script>
<style lang="scss">
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-tabs {
  &-nav > ul {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 0;
    list-style: none;
    flex-wrap: nowrap; // On mobile, make it seem like the content flows edge to edge while horizontal scrolling.
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }

    @include mq($screen-md, max) {
      overflow: auto;
      white-space: nowrap;
    }

    li {
      margin-right: 10px;

      @include mq($screen-md, min) {
        flex: 0 1 auto;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        a {
          color: black;
          border-bottom-color: black;
        }
      }
    }

    a {
      font-weight: 700;
      line-height: 2;
      flex: 0 1 50%;
      padding-bottom: 5px;
      border-bottom: 4px solid transparent;
      transition: color 0.2s ease, border-color 0.2s ease;
      will-change: color, border-color;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: gray;
      }
    }
  }

  &--vertical {
    display: flex;
    flex-wrap: wrap;

    & .c-tabs-nav > ul {
      flex-direction: column;
    }
  }
}
</style>
