<template>
  <div
    class="cm-c-product-tooltip-align"
    :class="{
      'cm-c-product-tooltip-align--accordion': accordion
    }"
  >
    <div
      ref="content"
      class="cm-c-product-tooltip"
      :class="{
        '-below': showBelow,
        'cm-c-product-tooltip--accordion': accordion
      }"
    >
      <h3 v-if="headline">
        {{ headline }}
      </h3>

      <div class="cm-mb-top--ssm" v-html="description"></div>

      <div class="cm-c-total cm-mb-top--ssm">
        <div v-for="(row, rowIndex) in details.rows" :key="rowIndex">
          <div
            :class="
              row.length > 0 ? 'cm-c-total__row--bottom-separator--sm' : ''
            "
          >
            <div
              v-for="(line, lineIndex) in row"
              :key="lineIndex"
              class="cm-c-total__row"
            >
              <div class="cm-c-total__name" v-html="line.property"></div>
              <div class="cm-c-total__value">
                {{ line.value }}
                <p class="cm-c-total__value--sm">
                  {{ line.quantity }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!hideTotals" class="cm-c-total__row">
          <div class="cm-c-total__name cm-c-total--bold">
            {{ details.total.property }}
          </div>
          <div class="cm-c-total__value cm-c-total--bold">
            {{ details.total.value }}
          </div>
        </div>
      </div>
    </div>
    <a
      v-if="!accordion"
      ref="activator"
      class="cm-link--minimum-price"
      :class="{ '-below': showBelow }"
    >
      <c-icon class="cm-link--help-icon" symbol-id="contact-information" />
    </a>
  </div>
</template>

<script>
import CIcon from 'webshop/components/c-icon/c-icon.vue';

export default {
  name: 'product-minimum-price-tooltip',

  components: { CIcon },

  props: {
    data: {
      type: Object,
      default: undefined
    },
    accordion: {
      type: Boolean,
      default: false
    },
    hideHeadlines: {
      type: Boolean,
      default: false
    },
    hideTotals: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      lastUpdateFrame: 0,
      showBelow: false
    };
  },

  computed: {
    headline() {
      if (this.hideHeadlines) {
        return '';
      }

      return this.data ? this.data.headline : '';
    },
    description() {
      return this.data ? this.data.description : '';
    },
    details() {
      if (this.data && this.data.details) {
        return this.data.details;
      }
      return {
        rows: [],
        total: {}
      };
    }
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('orientationchange', this.assignDirection);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('orientationchange', this.assignDirection);
  },

  mounted() {
    this.assignDirection();
  },

  methods: {
    handleScroll() {
      this.requestFrame(() => {
        this.assignDirection();
      });
    },

    requestFrame(callback) {
      const time = new Date().getTime();
      const deltaTime = time - this.lastUpdateFrame;
      if (deltaTime < 200) {
        return;
      }
      this.lastUpdateFrame = deltaTime;

      if (callback && typeof callback === 'function') {
        callback();
      }
    },

    assignDirection() {
      /**
       * HACK: since this is very stupid component we use 145 hardcoded
       * height of the header. We need better component that is window
       * aware.
       *
       */
      if (!this.accordion) {
        const rect = this.$refs.activator.getBoundingClientRect();

        const contentHeight = this.$refs.content.offsetHeight;
        const toActivator = rect.top - 145;

        this.showBelow = contentHeight > toActivator;
      }
    }
  }
};
</script>
