export default {
  methods: {
    registerStoreModule(name, module) {
      const store = this.$store;

      if (!(store && store.hasModule(name))) {
        store.registerModule(name, module);
      }
    },
    unregisterStoreModule(name) {
      const store = this.$store;

      if (store && store.hasModule(name)) {
        store.unregisterModule(name);
      }
    }
  }
};
