<template>
  <div class="skeleton-loader">
    <div
      class="skeleton-loader__inner"
      :class="{ 'skeleton-loader__inner--round-border': roundedCorners }"
    />
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SkeletonLoader',
  props: {
    roundedCorners: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" src="./skeleton-loader.scss" scoped></style>
