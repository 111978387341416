<template>
  <portal v-if="!hidden" to="portal">
    <div :class="modifiers" class="c-modal">
      <transition
        name="cm"
        @before-enter="onBeforeEnter"
        @after-leave="onAfterLeave"
      >
        <div
          v-show="isVisible"
          ref="modal"
          :aria-hidden="isVisible ? 'false' : 'true'"
          class="c-modal-wrapper"
          role="dialog"
          tabindex="0"
          @keyup="onKeyUp"
          @click="onClick"
        >
          <div class="c-modal-wrapper-inner">
            <!-- @slot markup in this slot is rendered in the modal inner container, before the header. It can typically be used to inject content that covers the entire modal content, such as a full page spinner. -->
            <slot name="inner" />

            <div
              ref="content"
              class="c-modal-content"
              :class="modifiersContent"
            >
              <div
                v-if="showLocalSpinner"
                class="cm-c-spinner cm-c-spinner--local"
              ></div>
              <header
                v-if="showHeader"
                :class="modifiersHeader"
                class="c-modal-content__header"
              >
                <slot v-if="showHeaderText" name="header">
                  <h3 class="c-modal-content__header--text">
                    {{ title }}
                  </h3>
                </slot>
                <c-button v-if="showCloseButton" reset @click.prevent="hide">
                  <slot name="close" />
                </c-button>
              </header>
              <div class="c-modal-content__body">
                <slot />
              </div>
              <footer v-if="showFooter" class="c-modal-content__footer">
                <slot name="footer" />
                <c-button
                  v-if="showOkButton"
                  class="co-c-btn co-c-btn__shout"
                  @click.prevent="hide"
                >
                  {{ okButtonText }}
                </c-button>
              </footer>
            </div>
          </div>
        </div>
      </transition>
      <transition
        name="cm-backdrop"
        @before-enter="onBeforeEnter"
        @after-leave="onAfterLeave"
      >
        <div v-if="isVisible" class="c-modal-backdrop" />
      </transition>
    </div>
  </portal>
</template>

<script>
import {
  addClass,
  removeClass,
  getAttr,
  bodyElement
} from '../../utils/dom.js';
import KEY_CODES from '../../utils/key-codes.js';
import TRANSITION_STATES from '../../utils/transition-states.js';

import RootBusMixin from '../../mixins/root-bus-mixin.js';

import CButton from '../c-button/c-button.vue';

export default {
  name: 'CModal',

  components: {
    CButton
  },

  mixins: [RootBusMixin],

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showOkButton: {
      type: Boolean,
      default: false
    },
    okButtonText: {
      type: String,
      default: 'Ok'
    },
    title: {
      type: String,
      default: ''
    },
    large: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    gray: {
      type: Boolean,
      default: false
    },
    /**
     * Applies a different style to the modal.
     */
    noContentPadding: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    },
    nonClosableModal: {
      type: Boolean,
      default: false
    },
    contentCentered: {
      type: Boolean,
      default: false
    },
    isCreditCheck: {
      type: Boolean,
      default: false
    },
    isFullHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hidden: true,
      isVisible: false,
      animations: {
        modal: {
          state: TRANSITION_STATES.IDLE
        },
        backdrop: {
          state: TRANSITION_STATES.IDLE
        }
      },
      showLocalSpinner: false
    };
  },

  computed: {
    modifiers() {
      return {
        'c-modal--centered': this.centered,
        'c-modal--no-footer': !this.showFooter,
        'c-modal--large': this.large,
        'c-modal--medium': this.medium,
        'c-modal--gray': this.gray,
        'c-modal--content-centered': this.contentCentered,
        'c-modal--wide': this.wide,
        'c-modal--credit-check': this.isCreditCheck,
        'c-modal--full-height': this.isFullHeight
      };
    },
    modifiersHeader() {
      return {
        'c-modal-header--close': this.showCloseButton
      };
    },
    modifiersContent() {
      return {
        'c-modal-content--no-padding': this.noContentPadding,
        'c-modal-content--with-footer': this.showFooter
      };
    },
    showHeader() {
      return this.showHeaderText || this.showCloseButton;
    },
    showHeaderText() {
      return !!this.$slots.header || !!this.title;
    },
    showFooter() {
      return !!this.$slots.footer || this.showOkButton;
    }
  },

  watch: {
    visible(value) {
      this.$nextTick(value ? this.show : this.hide);
    },
    isVisible(value) {
      if (value) {
        this.$refs.modal.focus();
      }
    },
    animations: {
      deep: true,
      handler(value) {
        if (
          value.modal.state === TRANSITION_STATES.IDLE &&
          value.backdrop.state === TRANSITION_STATES.IDLE &&
          !this.hidden
        ) {
          this.hidden = true;
        }
      }
    }
  },

  created() {
    document.addEventListener('keydown', this.onKeyUp);
  },

  destroyed() {
    document.removeEventListener('keydown', this.onKeyUp);
  },

  mounted() {
    this.listenOnRoot('v::show::modal', this.showHandler);
    this.listenOnRoot('v::hide::modal', this.hideHandler);
    this.listenOnRoot('v::toggle::modal', this.toggleHandler);
    // Use this to activate spinner from subcomponent.
    /*
      When local spinner is used in component inside modal it doesn't cover the whole modal
      since .c-modal-content__body has position: relative;
    */
    this.listenOnRoot('v::modal::show::spinner', this.showSpinnerHandler);
    this.listenOnRoot('v::modal::hide::spinner', this.hideSpinnerHandler);

    if (this.visible === true) {
      this.$nextTick(this.show);
    }
  },

  methods: {
    showHandler(target) {
      if (this.$attrs.id === target) {
        this.show();
      }
    },
    hideHandler(target) {
      if (this.$attrs.id === target) {
        this.hide();
      }
    },
    toggleHandler(target) {
      if (this.$attrs.id === target) {
        this.toggle();
      }
    },
    showSpinnerHandler(target) {
      if (this.$attrs.id === target) {
        this.showLocalSpinner = true;
      }
    },
    hideSpinnerHandler(target) {
      if (this.$attrs.id === target) {
        this.showLocalSpinner = false;
      }
    },
    show() {
      this.hidden = false;
      this.$nextTick(() => {
        this.isVisible = true;
        addClass(bodyElement, 'c-model-opened');
      });
    },
    hide() {
      if (!this.nonClosableModal) {
        this.isVisible = false;
        this.removeFromBody();
        this.$emit('hide');
      }
    },
    removeFromBody() {
      removeClass(bodyElement, 'c-model-opened');
    },
    toggle() {
      (this.hidden ? this.show : this.hide)();
    },
    onKeyUp(evt) {
      if (
        evt.keyCode === KEY_CODES.ESC &&
        this.isVisible &&
        !this.nonClosableModal
      ) {
        this.hide();
      }
    },
    onClick(evt) {
      if (!this.nonClosableModal && !this.$refs.content.contains(evt.target)) {
        this.hide();
      }
    },
    onBeforeEnter(el) {
      this.setAnimationState(el, TRANSITION_STATES.BEFORE_ENTER);
    },
    onAfterLeave(el) {
      this.setAnimationState(el, TRANSITION_STATES.IDLE);
      this.$emit('close');
    },
    setAnimationState(el, state) {
      const transition = getAttr(el, 'role') ? 'modal' : 'backdrop';
      this.animations[transition].state = state;
    }
  }
};
</script>

<style lang="scss">
$scroll-width: 17px;
$modal-body-padding: 2rem;

body.c-model-opened {
  overflow: hidden;
  padding-right: $scroll-width;
}

.c-modal {
  position: absolute;
  z-index: 999;

  // .c-modal-wrapper
  &-wrapper {
    position: fixed;
    display: block;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    z-index: 1000;
    padding: 0px 10px;

    // .c-modal-wrapper-inner
    &-inner {
      position: relative;
      width: auto;
      max-width: 500px;
      margin: 2rem auto 0 auto;
      max-height: calc(100vh - 3.5rem);
    }
  }

  // .c-modal-content
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    outline: 0;

    // .c-modal-content__header
    &__header {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    // .c-modal-content__body
    &__body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      max-height: 50vh;
      overflow-y: auto;
    }

    // .c-modal-content__footer
    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      padding: 1rem;
      border-bottom-right-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;

      button,
      a {
        &:first-child:not(:only-child) {
          margin-right: 1rem;
        }
      }
    }
  }

  // .c-modal-backdrop
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000070;
  }

  // .c-modal--centered
  &--centered {
    .c-modal-wrapper-inner {
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }

  &--content-centered {
    .c-modal-content__header,
    .c-modal-content__body,
    .c-modal-content__footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--large {
    & .c-modal-wrapper-inner {
      max-width: 100vw;
      height: calc(100vh - 3.5rem);
    }

    & .c-modal-content {
      height: 100%;
    }
  }

  &--medium {
    & .c-modal-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    & .c-modal-wrapper-inner {
      max-width: 1200px;
      width: 100%;
    }

    & .c-modal-content {
      height: 100%;

      &__body {
        max-height: calc(100vh - 2 * #{$modal-body-padding});
        padding: $modal-body-padding;
      }
    }
  }

  // .c-modal-header--close
  &-header--close {
    padding-top: 3rem;

    & > button {
      position: absolute;
      right: 20px;
      top: 20px;
      margin-left: auto;
      width: 40px;
      height: 40px;
      opacity: 0.3;
      transition: opacity 0.3s;
      z-index: 1010;

      &:hover {
        opacity: 1;
      }

      &:before,
      &:after {
        position: absolute;
        top: 8px;
        left: calc(50% - 4px);
        content: ' ';
        height: 24px;
        width: 4px;
        background-color: #333;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.cm-enter-active,
.cm-leave-active {
  transition: opacity 0.3s, transform 0.5s;
}
.cm-enter,
.cm-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.cm-backdrop-enter-active,
.cm-backdrop-leave-active {
  transition: opacity 0.5s;
}
.cm-backdrop-enter,
.cm-backdrop-leave-to {
  opacity: 0;
}
</style>
