<script>
import { CModal, CButton, CText } from 'olympus/components';

export default {
  name: 'checkout-consents',

  components: {
    CModal,
    CButton,
    CText
  },

  methods: {
    showConsentModal(id) {
      this.$refs[`consent-modal-${id}`].show();
    }
  }
};
</script>

<style lang="scss">
.checkout {
  &-consents {
    // .checkout-consents-label
    &-label {
      font-style: normal;
      margin: 0 auto;
      justify-content: center;
    }

    // .checkout-consents-more
    &-more {
      font-style: normal;
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
</style>
