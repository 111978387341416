var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.modifiers }, [
    _vm.useArrowIcon
      ? _c(
          "label",
          {
            staticClass: "cm-c-form-control__select__label",
            attrs: { for: _vm.id }
          },
          [
            _vm._v("\n    " + _vm._s(_vm.labelText) + "\n    "),
            _c("c-icon", {
              staticClass: "cm-c-form-control__select__label__icon",
              attrs: { "symbol-id": "arrow-down" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue"
          }
        ],
        staticClass: "c-form-select",
        attrs: { id: _vm.id, name: _vm.name, disabled: _vm.disabled },
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.localValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c("option", { attrs: { value: "" } }, [
          _vm._v("\n      " + _vm._s(_vm.placeholder) + "\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function(option, index) {
          return _c(
            "option",
            {
              key: index,
              attrs: {
                disabled: typeof option === "object" && option.disabled
              },
              domProps: {
                value: typeof option === "object" ? option.value : option
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(typeof option === "object" ? option.text : option) +
                  "\n    "
              )
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }