import shop from './shop';
import basket from './basket';
import { SEGMENT } from 'webshop/enums/segment';
import { CHANNEL } from 'webshop/enums/channel';

import catalog from './catalog';
import offerings from './offerings';
import groups from './groups';
import numberSelect from './number-select';
import productPage from '../components/product/store/product-page';
import creditCheck from './credit-check';
import productConfig from './product-config';
import newNumberConfig from './product-config/new-number-config';
import numberPortingConfig from './product-config/number-porting-config';
import shipping from './shipping';
import signature from './signature';
import homeOffice from './home-office';

import content from './content';

const debug = process.env.NODE_ENV !== 'production';
const bootstrapData = window.__APP__;

export const GETTERS = {
  IS_B2C: 'IS_B2C',
  IS_B2B: 'IS_B2B',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  IS_THIRD_PARTY_RESELLER: 'IS_THIRD_PARTY_RESELLER',
  LOGIN_URL: 'LOGIN_URL',
  DISCLAIMERS: 'DISCLAIMERS',
  IS_AGENT: 'IS_AGENT'
};

export default {
  state: {
    user: {
      authenticated: bootstrapData?.user.isLoggedIn,
      isAgent: bootstrapData?.user.isAgent
    },

    api: {
      login: '',
      ...bootstrapData?.api
    },

    segment: bootstrapData?.segment,

    channel: bootstrapData?.channel,

    disclaimers: bootstrapData?.disclaimers
  },
  getters: {
    [GETTERS.USER_AUTHENTICATED]: state => state.user.authenticated,
    [GETTERS.LOGIN_URL]: state => state.api.login,
    [GETTERS.IS_B2C]: state => state.segment === SEGMENT.B2C,
    [GETTERS.IS_B2B]: state => state.segment === SEGMENT.B2B,
    [GETTERS.IS_THIRD_PARTY_RESELLER]: state =>
      state.channel === CHANNEL.THIRD_PARTY,
    [GETTERS.DISCLAIMERS]: state => state.disclaimers,
    [GETTERS.IS_AGENT]: state => state.user.isAgent
  },
  modules: {
    catalog,
    offerings,
    basket,
    shop,
    groups,
    numberSelect,
    productPage,
    creditCheck,
    productConfig,
    newNumberConfig,
    numberPortingConfig,
    shipping,
    signature,
    homeOffice,
    content
  },
  strict: debug
};
