<template>
  <div class="cm-c-list-select__vas">
    <div class="cm-c-list-select__vas-services">
      <div class="cm-c-list-select__vas-info">
        <span v-html="vasText"></span>
      </div>
      <div class="cm-c-product-vas">
        <subscription-value-added-service
          v-for="item in services"
          :key="item.code"
          :item="item"
          :expand-label="expandServiceLabel"
          :collapse-label="collapseLabel"
        >
        </subscription-value-added-service>
      </div>
    </div>
  </div>
</template>
<script>
import SubscriptionValueAddedService from './subscription-value-added-service.vue';

// TODO DKT-1430: [2020-04-07] refactor this to a shared component
export default {
  name: 'SubscriptionValueAddedServicesSelector',

  components: {
    'subscription-value-added-service': SubscriptionValueAddedService
  },

  props: ['services', 'vasText', 'collapseLabel', 'expandServiceLabel']
};
</script>
