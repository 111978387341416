<template>
  <ul class="member-description-list">
    <c-row
      v-for="(item, idx) in items"
      :key="idx"
      tag="li"
      justified
      no-gutters
    >
      <div class="member-description-list__image">
        <span
          ><strong>{{ item.label }}</strong></span
        >
        <div class="text-left">
          <span class="member">&nbsp;</span
          ><span v-if="idx > 0"
            ><span class="member-separator">+</span
            ><span v-for="i in idx" :key="i" class="member">&nbsp;</span></span
          >
        </div>
      </div>
      <div>{{ item.formattedValueCurrency }} per person</div>
    </c-row>
  </ul>
</template>
<script>
import { CRow } from 'olympus/components';
export default {
  name: 'MemberDescriptionList',
  components: {
    CRow
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="scss">
$icon-size: 20px;
.member-description-list {
  padding: 10px 20px;

  & > li {
    margin-bottom: 20px;

    & span.member {
      // TODO: DKT-39277 replace with norlys when it's time to do cleanup.
      background: url('../../../telia/images/icon-user.svg') no-repeat center
        center;
      background-size: $icon-size $icon-size;
      height: $icon-size;
      width: $icon-size;
      display: inline-block;
      margin-right: 5px;

      &-separator {
        margin-right: 5px;
      }
    }
  }

  &__image {
    line-height: $icon-size;
    vertical-align: middle;
  }
}
</style>
