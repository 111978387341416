/* eslint-disable no-magic-numbers */

// TODO: this entire things needs massive refactoring due
// to improper state management, wrong invocation order,
// nonstandard mutations of state and too much transformation
// of the same data, leading to way harder solution that it should be...
function initLocalData(moveDate) {
  return {
    activateSimCard: false,
    activateSimCardNumber: '',
    hasExistingActiveSim: true,
    allowEpoa: false,
    b2BAddress: '',
    b2BCity: '',
    b2BCvr: '',
    b2BOrganization: '',
    b2BZipCode: '',
    enableAsap: 'binding',
    hiddenNumber: false,
    moveNumberDate: moveDate,
    keyToReRender: 0,
    isB2BCustomer: false,
    isMoveNumberAfterCurrentBinding: true,
    isNotLegalOwner: false,
    phoneNumber: '',
    sim: '',
    simUnavailable: false,
    unlistedNumber: false,
    subscriptionReceiverFirstName: '',
    subscriptionReceiverLastName: '',
    subscriptionReceiverEmail: '',
    subscriptionReceiverRepeatedEmail: '',
    isNoNewNumberavailable: false,
    disableNumberPorting: false,
    availableNumbers: [],
    numberPoolSize: 16, // Double the shown numbers size to have pool of similar numbers
    numbersToShow: [],
    acceptedConsents: false
  };
}

const numberConfigType = {
  NewNumber: 1,
  ExisitngNumber: 2,
  Moved: 3
};

import { cloneDeep } from 'lodash-es';
import { mapGetters, mapMutations } from 'vuex';
import contentProviderMixin from '../../../../../shared/mixins/content-property-mixin.js';
import { safeWrite, safeRead, safeDelete } from 'olympus/utils/storage.js';

export default {
  mixins: [contentProviderMixin],

  props: {
    configType: Number,
    datePickerMinDateString: String,
    initMoveDateString: String
  },
  data: function () {
    return initLocalData(0);
  },
  computed: {
    ...mapGetters([
      'isEnterprise',
      'IS_THIRD_PARTY_RESELLER',
      'errors',
      'numberConfigActive',
      'numberConfigActiveProduct',
      'numberConfigComplete',
      'numberConfigNumber',
      'numberConfigCompleteLength',
      'numberConfigText',
      'numberConfigVM',
      'numberConfigText'
    ])
  },
  watch: {
    initMoveDate() {
      /* On init, set moveNumberDate to initMoveDate -/+ local timeZone offset */
      this.moveNumberDate = this.getInitMoveNumberDate();
    },
    $route(to) {
      if (to) {
        if (
          to.name === 'number-new' ||
          to.name === 'number-move' ||
          to.name === 'number-existing'
        ) {
          this.changeLocalData();
          this.forceRerender();
          this.setPhoneNumber(to);
        }
        if (to.path === '/') {
          this.resetData();
        }
      }
    }
  },
  created() {
    /*
    Redirect to root if configType is null/undefined.
    This happens if user refreshes page while on a sub-route
    */
    this.pageRefreshAction();
  },
  mounted() {
    /*  moveNumberDate should initially be set to initMoveDate.
    initMoveDate will be the same for all items in the basket, set from BE. */
    this.moveNumberDate = this.getInitMoveNumberDate();
    this.pageRefreshAction();
    this.changeLocalData();
  },

  methods: {
    ...mapMutations([
      'HIDE_SPINNER',
      'SET_NUMCONF_ACTIVE',
      'SET_NUMCONF_COMPLETE',
      'SET_NUMCONF_ITEM',
      'SHOW_DIALOG',
      'SHOW_SPINNER',
      'SET_AVAILABLE_NUMBERS',
      'CLEAR_AVAILABLE_NUMBERS',
      'SET_NUMCONF_ACTIVE_ClOSE'
    ]),
    async accept() {
      /* Check, if number is already configured */
      if (this.checkExisting()) {
        let errorHeadLine = '';
        let errorMessage = '';

        if (this.configType === numberConfigType.ExisitngNumber) {
          errorHeadLine = this.numberConfigVM.headlineErrorExistingNumberUsed;
          errorMessage = this.numberConfigVM.errorMessageExistingNumberUsed;
        }

        if (this.configType === numberConfigType.Moved) {
          errorHeadLine = this.numberConfigVM.configureNumberHeadline;
          errorMessage =
            this.numberConfigVM.errorMessageNumberAlreadyConfigured;
        }

        if (this.configType === numberConfigType.NewNumber) {
          errorHeadLine = this.numberConfigVM.configureNumberHeadline;
          errorMessage =
            this.numberConfigVM.errorMessageNumberAlreadyConfigured;
        }
        this.SHOW_DIALOG({
          componentName: 'content-dialog',
          componentProps: {
            contentObject: {
              headline: errorHeadLine,
              description: errorMessage,
              buttonLabel: 'OK'
            }
          }
        });
        return false;
      }

      /* Validate */
      if (this.checkRequired()) {
        /* Existing number, do BAN-check */
        if (this.configType === 2) {
          this.SHOW_SPINNER();
          let url = `${this.numberConfigVM.apiBan}&phonenumber=${this.phoneNumber}&lineitemid=${this.numberConfigActiveProduct.lineItemId}`;
          const response = await fetch(url, { cache: 'no-cache' });

          const content = await response.json();
          if (!content.conflictingBans) {
            await this.pushData();
          } else {
            /* BAN conflict:
            Show dialog: content.headline, description: content.description
            */
            this.SHOW_DIALOG({
              componentName: 'content-dialog',
              componentProps: {
                contentObject: {
                  headline: this.numberConfigVM.conflictingBansHeading,
                  description: this.numberConfigVM.errorMessageConflictingBans,
                  buttonLabel: 'OK'
                }
              }
            });

            this.HIDE_SPINNER();
            return false;
          }
          this.HIDE_SPINNER();
        } else {
          /* Move number, New number */
          this.pushData();
        }
      }
    },
    changeLocalData() {
      this.CLEAR_AVAILABLE_NUMBERS();
      /* If number was already configured and user wants to change something, prefill with exisitng data */
      if (
        this.configType &&
        this.configType === this.numberConfigActiveProduct.configType
      ) {
        Object.assign(this.$data, this.numberConfigActiveProduct);
        this.moveNumberDate = this.setMoveDateFromObj(
          this.getInitMoveNumberDate()
        );
        this.sim = this.simUnavailable ? '' : this.sim;
      } else {
        Object.assign(
          this.$data,
          initLocalData(this.moveNumberDate || this.getInitMoveNumberDate())
        );
      }
    },
    checkExisting() {
      let exists = this.numberConfigComplete.some(
        item =>
          item.phoneNumber === this.phoneNumber &&
          item.configType === this.configType
      );
      exists = this.isNoNewNumberavailable ? false : exists;
      if (
        exists &&
        this.phoneNumber === this.numberConfigActiveProduct.phoneNumber
      ) {
        exists = false;
      }
      return exists;
    },
    checkRequired() {
      /* Basic validation */
      const required = this.$refs.fieldset.$el.querySelectorAll(
        '[required]:not([disabled])'
      );
      let valid = true;

      required.forEach(input => {
        let parent = input.parentNode;
        if (parent.tagName.toLowerCase() === 'label') {
          parent = parent.parentNode;
        }
        const error = parent.querySelector('.field-validation-error');
        if (error) {
          error.hidden = true;
        }
        if (!input.checkValidity()) {
          valid = false;
          if (error) {
            error.hidden = false;
          }
        }
      });
      this.$scrollTo('body');
      return valid;
    },
    mergeObjectsExistingOptions(obj1, obj2) {
      let obj = Object.keys(obj1).reduce(
        (a, key) => ({ ...a, [key]: obj2[key] }),
        {}
      );
      obj = Object.keys(obj).forEach(key =>
        obj[key] === undefined ? delete obj[key] : ''
      );
      return obj;
    },
    setPhoneNumber(to) {
      if (to) {
        if (to.name === 'number-new') {
          this.phoneNumber = this.phoneNumber ? this.phoneNumber : '';
          this.phoneNumber = this.phoneNumber
            ? this.numbersToShow.findIndex(
                x => x.number === this.phoneNumber
              ) !== -1
              ? this.phoneNumber
              : this.numbersToShow[
                  Math.floor(Math.random() * this.numbersToShow.length)
                ]?.number
            : this.numbersToShow[
                Math.floor(Math.random() * this.numbersToShow.length)
              ]?.number;
          this.phoneNumberIsNotInList =
            this.numberConfigActiveProduct.phoneNumber !== null
              ? this.phoneNumber === this.numberConfigActiveProduct.phoneNumber
                ? false
                : true
              : false;
          this.isNoNewNumberavailable =
            this.numberConfigVM.availableNumbers.length === 0;

          if (this.phoneNumber) {
            this.phoneNumber = this.$globals.formatNumber(this.phoneNumber);
          } else {
            this.phoneNumber = this.numberConfigVM.noNumberPoolNumber;
          }
        } else {
          if (
            to.params &&
            to.params.configType === this.numberConfigActiveProduct.configType
          ) {
            this.phoneNumber =
              this.phoneNumber !== ''
                ? this.phoneNumber
                : this.numberConfigActiveProduct
                ? this.numberConfigActiveProduct.phoneNumber
                : '';
          }
        }
      }
    },
    pageRefreshAction() {
      if (!this.configType) {
        this.$router.push({ path: '/' });
      }
    },
    pushData() {
      // TODO: to reiterate the above, this method, the mixin & the module's state management should be rewritten. It is too complex and full of side affects due to mutation and reassignment of $data across all numcon modules.
      const { keyToReRender, phoneNumberIsNotInList, ...activeProduct } =
        cloneDeep(this.numberConfigActiveProduct);

      const numberConfigProductToPost = {
        ...activeProduct,
        isNotLegalOwner: this.isNotLegalOwner,
        isB2BCustomer: this.isB2BCustomer,
        b2BCvr: this.b2BCvr,
        b2BOrganization: this.b2BOrganization,
        b2BAddress: this.b2BAddress,
        b2BZipCode: this.b2BZipCode,
        b2BCity: this.b2BCity,
        allowEpoa: this.allowEpoa,
        sim: this.sim,
        simUnavailable: this.simUnavailable,
        configType: this.configType,
        phoneNumber: this.phoneNumber || this.noNumberPoolNumber,
        consents: this.acceptedConsents ? this.numberConfigVM.consents : null,
        hiddenNumber: this.hiddenNumber,
        unlistedNumber: this.unlistedNumber,
        hasExistingActiveSim: this.hasExistingActiveSim,
        activateSimCardNumber: this.activateSimCardNumber,
        activateSimCard: this.activateSimCard,
        acceptedConsents: this.acceptedConsents,
        isMoveNumberAfterCurrentBinding: this.isMoveNumberAfterCurrentBinding,
        moveNumberDateString: this.moveNumberDateString
          ? this.moveNumberDateString
          : this.initMoveDateString
      };

      /* Post data to BE */
      const request = async () => {
        this.SHOW_SPINNER();
        const response = await fetch(this.numberConfigVM.apiSet, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'cache-control': 'no-cache'
          },
          body: JSON.stringify(numberConfigProductToPost)
        });

        const content = await response.json();
        const banInfoPopup = content.banInformationPopup;
        /* Show Ban Information popup if one existing and X amount another types */
        if (banInfoPopup && banInfoPopup.showBanInformationPopup) {
          this.SHOW_DIALOG({
            componentName: 'content-dialog',
            componentProps: {
              contentObject: {
                headline: banInfoPopup.singleBanInformationPopupHeading,
                description: banInfoPopup.singleBanInformationPopupText,
                buttonLabel: banInfoPopup.singleBanInformationPopupButtonText
              }
            }
          });
        }
        /* Something happened, not approved in BE */
        if (content.modelState) {
          let errStr = '';
          for (const err in content.modelState) {
            errStr += content.modelState[err][0] + '<br>';
          }
          /* REFS! */
          this.SHOW_DIALOG({
            componentName: 'content-dialog',
            componentProps: {
              contentObject: {
                headline: this.numberConfigVM.errorMessageHeadline,
                description: errStr,
                buttonLabel: this.numberConfigVM.errorMessageButtonText
              }
            }
          });
        } else {
          this.removeItemFromNumberPool(numberConfigProductToPost.phoneNumber);
          this.SET_NUMCONF_ITEM(numberConfigProductToPost);
          this.SET_NUMCONF_COMPLETE({
            code: this.numberConfigActive,
            configEdited: false
          });

          /*
           * moveNumberDateString needs to be reset such that it gets an initial
           * value assigned to it for each product where number is ported
           * (otherwise nr. 2 product will sent the moveNumberDateString from nr. 1 product).
           * Proper fix (refactoring): Make sure the moveNumerDateString is directly bound
           * to the input-value-field that holds and displays the date.
           */
          this.moveNumberDateString = '';

          if (this.numberConfigNumber.length) {
            this.SET_NUMCONF_ACTIVE_ClOSE(this.numberConfigActive);
          }
          this.$router.push({ path: '/' });
        }

        this.HIDE_SPINNER();
      };

      return request();
    },
    resetData() {
      Object.assign(this.$data, initLocalData(this.initMoveDate));
      const checkboxes = document.getElementsByTagName('input');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = false;
        }
        if (checkboxes[i].type === 'text') {
          checkboxes[i].value = '';
        }
      }
      document
        .querySelectorAll('.field-validation-error')
        .forEach(x => x.setAttribute('hidden', true));
      this.enableCalendar = false;
    },
    forceRerender() {
      this.keyToReRender += 1;
    },
    setMoveAfterCurrentBinding(bool) {
      this.isMoveNumberAfterCurrentBinding = bool;
    },
    setMoveDateFromObj(date) {
      return date.toString().length <= 10 ? date * 1000 : date;
    },
    setHiddenNumber(bool) {
      this.hiddenNumber = bool;
    },
    setUnlistedNumber(bool) {
      this.unlistedNumber = bool;
    },
    timeZoneOffset() {
      if (this.isDaylightSaving()) {
        return new Date().getTimezoneOffset() * 60000 + 120 * 60000 || 0;
      }

      return new Date().getTimezoneOffset() * 60000 + 60 * 60000 || 0;
    },
    dateParseFromString(dateString) {
      return new Date(dateString);
    },
    getMinimumDatePickerDate() {
      var datePickerMinDate =
        this.datePickerMinDateString === ''
          ? new Date()
          : new Date(this.datePickerMinDateString);
      return (
        datePickerMinDate.getTime() +
        datePickerMinDate.getTimezoneOffset() * 60000
      );
    },
    /*
     * This method splits up the date-string and parses it as 3 separate arguments to the Date-constructor.
     * This way, it uses the same Date-constructor as the date-picker.vue component.
     * Using the same constructor is necessary because the different Date-constructors will return different unix time stamps (they are uncomparable and inconsistent)
     * For more info, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
     */
    getInitMoveNumberDate() {
      const dateRegex = /\d+-\d+-\d+/;

      // Check if we received a string representing a date
      if (
        this.initMoveDateString !== '' &&
        dateRegex.test(this.initMoveDateString)
      ) {
        const [year, month, day] = this.initMoveDateString.split('-');
        // subtract one from month because months in the date object are 0 indexed
        return new Date(year, month - 1, day).getTime();
      }
      return new Date().getTime();
    },
    isDaylightSaving() {
      var today = new Date();
      var jan = new Date(today.getFullYear(), 0, 1);
      var jul = new Date(today.getFullYear(), 6, 1);
      var negativeTimeZones = Math.max(
        jan.getTimezoneOffset(),
        jul.getTimezoneOffset()
      );
      return today.getTimezoneOffset() < negativeTimeZones;
    },
    checkNumberPool() {
      const latestUpdateMilliseconds = Math.abs(
        new Date(this.checkNumberPoolLifeSpan()) - new Date()
      );
      if (!latestUpdateMilliseconds) {
        return [];
      } else if (
        latestUpdateMilliseconds / 1000 <
        this.numberConfigVM.numberPoolLifeSpan
      ) {
        const numberPool = safeRead('numberPool', false, localStorage);
        return numberPool?.length ? numberPool : [];
      }

      this.clearNumberPool();
      return [];
    },
    updateNumberPool(resetData) {
      if (resetData) {
        safeWrite('numberPool', this.availableNumbers, localStorage);
        this.updateNumberPoolEditedTime();
      }

      this.numbersToShow = [...this.availableNumbers].splice(
        0,
        Math.ceil(this.numberPoolSize / 2)
      );
      this.setPhoneNumber({ name: 'number-new' });
    },
    checkNumberPoolLifeSpan() {
      return safeRead('numberPoolEdited', false, localStorage);
    },
    updateNumberPoolEditedTime() {
      safeWrite('numberPoolEdited', new Date(), localStorage);
    },
    removeItemFromNumberPool(phoneNumber) {
      const numberPool = this.checkNumberPool();
      const newNumberPool = [...numberPool].filter(
        item => item.number !== phoneNumber
      );
      if (newNumberPool.length) {
        this.availableNumbers = newNumberPool;
        safeWrite('numberPool', this.availableNumbers, localStorage);
        this.updateNumberPoolEditedTime();
      }
    },
    clearNumberPool() {
      safeDelete('numberPool', localStorage);
    }
  }
};
