import Vue from 'vue';
import store from '../store/shop/index.js';
import {
  ajaxGet,
  ajaxPost,
  toFullQuery
} from '../../../../shared/utils/ajax.js';

export default {
  basketServiceRequest(
    url = null,
    method,
    action,
    parameters = null,
    callback,
    errorCallback
  ) {
    const contextId = store.state.global.contextId;
    const params =
      parameters !== null
        ? Object.assign(parameters, { contextId: contextId })
        : { contextId: contextId };
    const reqUrl = !url
      ? toFullQuery(`/api/olympus/cart/${action}`, params)
      : url;

    switch (method) {
      case 'POST':
        return ajaxPost(reqUrl, params, true)
          .then(callback)
          .catch(errorCallback);
      case 'GET':
        return ajaxGet(reqUrl, true, true)
          .then(callback)
          .catch(errorCallback);
      case 'DELETE':
        return Vue.http
          .delete(reqUrl)
          .then(callback)
          .catch(errorCallback);
      default:
        errorCallback();
    }
  }
};
