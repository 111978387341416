export const hasKey = (obj, pattern) =>
  Object.keys(obj).some(function (key) {
    return new RegExp(pattern).test(key);
  });

export const keys = obj => Object.keys(obj);

export const resetProp = (el, prop) => {
  el[prop] = null;
  delete el[prop];
};
