var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-modal",
    {
      ref: "financingConflictModalCModal",
      attrs: {
        id: _vm.MODAL_ID,
        hidden: false,
        visible: "",
        centered: "",
        small: "",
        gray: "",
        "show-close-button": false,
        "non-closable-modal": ""
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
          attrs: { slot: "header" },
          slot: "header"
        },
        [_c("h2", [_c("strong", [_vm._v(_vm._s(_vm.content.title))])])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-2 text-center" }, [
        _c("p", [_vm._v(_vm._s(_vm.content.message))])
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "c-button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("remove-financing")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.content.approveButton) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              attrs: { type: "button", variant: "link" },
              on: {
                click: function($event) {
                  return _vm.$emit("continue-shopping")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.content.declineButton) + "\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }