import { get, put } from 'axios';
import Vue from 'vue';

const ERROR = {
  NAVIGATE_TO_NEXT_MISSING:
    'useCheckoutFinancingValidation: navigateToNextPage must exist on the component instance',
  MISSING_COMPONENT_INSTANCE:
    'useCheckoutFinancingValidation: Component instance must be a vue component (this)',
  EMIT_ON_ROOT_MISSING:
    'useCheckoutFinancingValidation: emitOnRoot is missing on the component instance.',
  FEATURE_FLAG_MISSING:
    'useCheckoutFinancingValidation: isFinancingConflictEnabled is missing on the component instance.'
};

function _validateComponentInstance(componentInstance) {
  if (!(componentInstance instanceof Vue)) {
    throw new Error(ERROR.MISSING_COMPONENT_INSTANCE);
  }
  if (typeof componentInstance.navigateToNextPage !== 'function') {
    throw new Error(ERROR.NAVIGATE_TO_NEXT_MISSING);
  }

  if (typeof componentInstance.emitOnRoot !== 'function') {
    throw new Error(ERROR.EMIT_ON_ROOT_MISSING);
  }
}

export const MODAL_ID = 'checkoutFinancingConflictModal';

/**
 * @description This hook is used for validating the checkout financing rules.
 * It is a reusable hook that can be implemented regardless of the brand.
 */
export function useCheckoutFinancingValidation(componentInstance) {
  _validateComponentInstance(componentInstance);

  const CART_API_PATH = '/api/olympus/cart';

  const MODAL_REF = 'financingConflictOverlay';

  const state = Vue.observable({
    isFinancingConflictOverlayShown: false
  });

  function showSpinner() {
    componentInstance.emitOnRoot('v::modal::show::spinner', MODAL_ID);
  }

  function hideOverlayAndSpinner() {
    componentInstance.emitOnRoot('v::modal::hide::spinner', MODAL_ID);
    state.isFinancingConflictOverlayShown = false;
  }

  function showErrorToast() {
    if (
      !componentInstance.content.financingConflictErrorTitle ||
      !componentInstance.content.financingConflictErrorText
    ) {
      throw new Error(
        'showErrorToast in useCheckoutFinancingValidation: Required content properties are missing in the component instance.'
      );
    }

    const tenSecondsInMs = 10000;

    componentInstance.$addToast({
      title: componentInstance.content.financingConflictErrorTitle,
      paragraph: componentInstance.content.financingConflictErrorText,
      duration: tenSecondsInMs,
      state: 'error'
    });
  }

  return {
    /**
     * @description Checks if the basket has a conflict and if it does, opens a dialog where
     * the user can choose their next actions.
     */
    checkForFinancingConflict() {
      if (!componentInstance.isFinancingConflictEnabled) {
        componentInstance.navigateToNextPage();
        return;
      }

      get(`${CART_API_PATH}/GetInstallableAmount`)
        .then(response => {
          if (response.data.hasFinancingConflict) {
            state.isFinancingConflictOverlayShown = true;
          } else {
            componentInstance.navigateToNextPage();
          }
        })
        .catch(() => {
          showErrorToast();
        });
    },

    /**
     * @description Removes the financing (installments) from the basket and navigates to the next step.
     */
    removeFinancing() {
      showSpinner();

      put(`${CART_API_PATH}/SetUpfrontPayment`)
        .then(() => {
          componentInstance.navigateToNextPage();
        })
        .catch(() => {
          showErrorToast();
        })
        .finally(() => {
          hideOverlayAndSpinner();
        });
    },

    /**
     * @description Hides the financing conflict dialog.
     */
    hideFinancingConflictOverlay() {
      state.isFinancingConflictOverlayShown = false;
      componentInstance.$refs[MODAL_REF].hide();
    },

    get isOverlayShown() {
      return state.isFinancingConflictOverlayShown;
    },

    MODAL_REF
  };
}
