<template>
  <config-group-item
    :title="title"
    :icon="icon"
    :expanded="expanded"
    @expanded="handleExpanded"
  >
    <fieldset class="porting-config">
      <div class="porting-config__section porting-config__section--no-margin">
        <c-row class="porting-config__section__row">
          <c-col>
            <c-input-group
              v-model="$v.form.number.$model"
              name="number"
              :place-holder-label="content.numberPorting.numberToBeMovedLabel"
              :input-error="numberError"
              type="number"
            ></c-input-group>
          </c-col>
        </c-row>
        <c-row class="porting-config__section__row">
          <c-col>
            <c-input-group
              v-model="$v.form.simNumber.$model"
              name="portFromSimNumber"
              :place-holder-label="content.simCardNumberPlaceholder"
              :input-error="portFromSimNumberError"
              type="number"
            ></c-input-group>
          </c-col>
        </c-row>

        <!-- SIM is not mandatory text -->
        <c-row
          v-if="content.numberPorting.noSimTitle"
          class="porting-config__section__row"
        >
          <c-col cols="12">
            <div class="porting-config__section__row__tooltip">
              <span class="porting-config__section__row__sim-info-text"
                >{{ content.numberPorting.noSimTitle }}
              </span>
              <popover-panel
                :description="content.numberPorting.noSimDescription"
                :popover-triggers="['hover', 'click']"
                :tooltip-screen-reader-text="content.tooltipScreenReaderText"
                :popover-offset-x="-90"
              >
                <c-icon
                  class="porting__tooltip__icon-wrapper__icon"
                  symbol-id="service-information"
                />
              </popover-panel>
            </div>
          </c-col>
        </c-row>

        <!-- How to find your sim number link -->
        <c-row
          v-if="content.numberPorting.howToFindYourSimNumberLinkText"
          class="porting-config__section__row"
        >
          <c-col>
            <button class="c-btn c-btn--as-link" @click.prevent="showSimModal">
              {{ content.numberPorting.howToFindYourSimNumberLinkText }}
            </button>
          </c-col>
        </c-row>
      </div>

      <!-- Modal for 'how to find your SIM number' -->
      <c-modal
        v-if="content.numberPorting.howToFindSimPopup"
        ref="howToFindSimModal"
        :title="content.numberPorting.howToFindSimPopup.headline"
        :ok-button-text="content.numberPorting.howToFindSimPopup.buttonText"
        centered
        show-ok-button
      >
        <div class="howToFindSimModal">
          <div
            v-html="content.numberPorting.howToFindSimPopup.description"
          ></div>
          <img
            v-if="content.numberPorting.howToFindSimPopup.imageSource"
            class="howToFindSimModal__img"
            :src="content.numberPorting.howToFindSimPopup.imageSource"
            :alt="simImageAlt"
          />
        </div>
      </c-modal>

      <hr />

      <div class="porting-config__section">
        <c-row class="porting-config__section__row">
          <c-col>
            <h3>
              {{ content.numberPorting.powerOfAttorneyHeader }}
            </h3>
          </c-col>
        </c-row>
        <c-row class="porting-config__section__row">
          <c-col>
            <p>{{ content.numberPorting.powerOfAttorneyLinkText }}</p>
          </c-col>
        </c-row>
      </div>

      <div class="porting-config__section">
        <legend class="porting-config__legend">
          {{ content.numberPorting.ownerOfNumberText }}
        </legend>
        <c-row class="porting-config__section__row">
          <c-col
            cols="6"
            sm="12"
            class="porting-config__section__row__chip-wrapper"
          >
            <c-chip
              name="isLegalOwner"
              data-testid="porting-config__chip-input"
              :input-value="true"
              @change="setLegalOwner"
              >{{ content.numberPorting.ownerOfNumberYesButtonText }}</c-chip
            >
          </c-col>
          <c-col
            cols="6"
            sm="12"
            class="porting-config__section__row__chip-wrapper"
          >
            <c-chip
              name="isLegalOwner"
              data-testid="porting-config__chip-input"
              :input-value="false"
              @change="setLegalOwner"
              >{{ content.numberPorting.ownerOfNumberNoButtonText }}</c-chip
            >
          </c-col>
        </c-row>
      </div>

      <!-- Step 1A: If owner of number chip is selected -->
      <c-collapse id="owner" :visible="showPrivateOrBusinessStep">
        <div
          class="porting-config__section"
          :class="{
            'porting-config__section--no-margin':
              !showPoaBusinessStep && !showPoaPrivateStep
          }"
        >
          <legend class="porting-config__legend">
            {{ content.numberPorting.b2COrB2BCustomerHeaderText }}
          </legend>
          <c-form-radio-group
            id="private-or-business"
            v-model="form.isB2BCustomer"
            stacked
            name="isB2BCustomer"
          >
            <c-row class="porting-config__section__row">
              <c-col>
                <c-form-radio
                  :id="content.numberPorting.b2CCustomerRadioButtonLabel"
                  value="false"
                  ><span>{{
                    content.numberPorting.b2CCustomerRadioButtonLabel
                  }}</span>
                </c-form-radio>
              </c-col>
            </c-row>
            <c-row class="porting-config__section__row">
              <c-col>
                <c-form-radio
                  :id="content.numberPorting.b2BCustomerRadioButtonLabel"
                  value="true"
                >
                  <span>{{
                    content.numberPorting.b2BCustomerRadioButtonLabel
                  }}</span>
                </c-form-radio>
              </c-col>
            </c-row>
          </c-form-radio-group>
        </div>
      </c-collapse>

      <!-- Step 1B: If not owner of number chip is selected -->
      <c-collapse id="notOwner" :visible="showOwnerDisclaimer">
        <div
          class="porting-config__section 'porting-config__section--no-margin"
        >
          <c-row class="porting-config__section__row">
            <c-col>
              <c-information-box icon="contact-information" type="informative">
                <!--<strong slot="header">Header</strong> -->
                <p>{{ content.numberPorting.notOwnerDisclaimer }}</p>
              </c-information-box>
              <p></p>
            </c-col>
          </c-row>
        </div>
      </c-collapse>

      <!-- Step 2B: If business customer radio is selected -->
      <c-collapse id="poaBusiness" :visible="showPoaBusinessStep">
        <div class="porting-config__section">
          <c-row class="porting-config__section__row porting-config__fetch-cvr">
            <c-col cols="7" sm="12">
              <c-input-group
                v-model.number="form.cvrNumber"
                name="b2bCvr"
                type="number"
                :place-holder-label="content.business.cvrNumberPlaceholder"
                :input-error="b2bCvrError"
                @input="b2BCvrInput"
              ></c-input-group>
            </c-col>
          </c-row>
          <c-row v-if="getB2BAddress" class="porting-config__section__row">
            <c-col cols="12">
              <p v-if="getB2BAddress.organization">
                {{ getB2BAddress.organization }}
              </p>
              <p v-if="getB2BAddress.address">{{ getB2BAddress.address }}</p>
              <p v-if="b2BAddressZipcodeAndCity">
                {{ b2BAddressZipcodeAndCity }}
              </p>
            </c-col>
            <input
              v-for="(value, name, idx) in mappedB2BAddress"
              :key="idx"
              :value="value"
              :name="name"
              type="hidden"
            />
          </c-row>
        </div>
      </c-collapse>

      <!-- Step 2A: If private customer radio is selected -->
      <c-collapse
        id="poaPrivate"
        :visible="showPoaPrivateStep || showPoaBusinessStep"
      >
        <div
          class="porting-config__section"
          :class="{ 'porting-config__section--no-margin': showPoaPrivateStep }"
        >
          <legend class="porting-config__legend">
            {{ content.numberPorting.powerOfAttorneyHeaderText }}
          </legend>
          <c-row class="porting-config__section__row">
            <c-col
              class="porting-config__section__row__checkbox"
              :class="{
                'porting-config__section__row__checkbox--checked-border':
                  form.poaAllowed
              }"
            >
              <c-checkbox
                id="porting-allow-poa"
                name="isElectronicPowerOfAttorneyAllowed"
                :checked="form.poaAllowed"
                :value="form.poaAllowed"
                @event-id="toggleCheckbox"
              >
                {{ content.numberPorting.powerOfAttorneyCheckboxLabel }}
              </c-checkbox>
            </c-col>
          </c-row>
        </div>

        <!-- For business customers we show an information box that a physical POA may or may not be required -->
        <div
          v-if="showPoaBusinessStep"
          class="porting-config__section porting-config__section--no-margin"
        >
          <c-row class="porting-config__section__row">
            <c-col>
              <c-information-box
                icon="contact-information"
                :type="
                  content.numberPorting.b2BPowerOfAttorneyInformationBox.messageBoxType.toLowerCase()
                "
              >
                <strong slot="header">{{
                  content.numberPorting.b2BPowerOfAttorneyInformationBox.title
                }}</strong>
                <p
                  v-html="
                    content.numberPorting.b2BPowerOfAttorneyInformationBox
                      .message
                  "
                ></p>
              </c-information-box>
              <p></p>
            </c-col>
          </c-row>
        </div>
      </c-collapse>

      <!-- Show preferences when POA checkbox is checked OR if "Business" radio is selected -->
      <c-collapse id="preferences" :visible="showShipmentStep">
        <hr />

        <div class="porting-config__section">
          <c-row class="porting-config__section__row">
            <c-col>
              <h3>
                {{ content.numberPorting.yourPreferencesHeaderText }}
              </h3>
            </c-col>
          </c-row>
          <c-row class="porting-config__section__row">
            <c-col>
              <p>{{ content.numberPorting.yourPreferencesText }}</p>
            </c-col>
          </c-row>
        </div>

        <div class="porting-config__section">
          <legend class="porting-config__legend">
            {{ content.numberPorting.numberTransferTimeHeaderText }}
          </legend>
          <c-row class="porting-config__section__row">
            <c-col
              cols="6"
              sm="12"
              class="porting-config__section__row__chip-wrapper"
            >
              <c-chip
                name="portAfterCurrentBindingPeriod"
                data-testid="porting-config__chip-input"
                :input-value="true"
                preselected
                @change="setDateChip"
                >{{
                  content.numberPorting.numberTransferAsapButtonText
                }}</c-chip
              >
            </c-col>
            <c-col
              cols="6"
              sm="12"
              class="porting-config__section__row__chip-wrapper"
            >
              <c-chip
                name="portAfterCurrentBindingPeriod"
                data-testid="porting-config__chip-input"
                :input-value="false"
                @change="setDateChip"
                >{{
                  content.numberPorting.numberTransferSpecificDateButtonText
                }}</c-chip
              >
            </c-col>
          </c-row>
        </div>

        <c-collapse
          id="number-porting-info"
          :visible="form.portNumberAsap === 'true'"
        >
          <div class="porting-config__section">
            <c-row class="porting-config__section__row">
              <c-col cols="12">
                <c-information-box
                  icon="contact-information"
                  :type="portingDateDisclaimerType"
                >
                  <strong slot="header">{{
                    content.numberPorting.portingDateDisclaimer.title
                  }}</strong>
                  <p
                    v-html="content.numberPorting.portingDateDisclaimer.message"
                  ></p>
                </c-information-box>
              </c-col>
            </c-row>
          </div>
        </c-collapse>

        <c-collapse id="datepicker" :visible="showDateSelectorStep">
          <div class="porting-config__section">
            <c-row class="porting-config__section__row">
              <c-col>
                <c-datepicker
                  :label="content.numberPorting.chooseDateDatePickerLabel"
                  :disabled-dates="content.disabledDates"
                  :initial-date="firstPortingDate"
                  data-testid="porting-config__datepicker"
                  input-name="portingDate"
                  :clearable="false"
                  @change="setSelectedDate"
                />
              </c-col>
            </c-row>
          </div>
        </c-collapse>

        <sim-card-selection
          v-if="!content.hideSimTypeOptions"
          class="porting-config__section"
          :config-data="configData"
          :options="simCardSettings"
          :checked-sim-radio="checkedSimRadio"
        />

        <!-- HardCode for mittele, form works incorrectly unless backend receives some sort of sim option -->
        <input
          v-else
          v-model="SIM_OPTIONS.SHIP_SIM"
          type="hidden"
          name="SimOption"
        />

        <div class="porting-config__section porting-config__section--no-margin">
          <legend class="porting-config__legend">
            {{ content.optionalSettingsHeadline }}
          </legend>
          <c-row class="porting-config__section__row">
            <c-col>
              <div
                class="porting-config__section__row__tooltip"
                :class="{
                  'porting-config__section__row__tooltip--checked-border':
                    form.secretNumber
                }"
              >
                <c-checkbox
                  :id="content.secretNumberLabel"
                  v-model="form.secretNumber"
                  name="isNumberHidden"
                  :checked="form.secretNumber"
                  @event-id="toggleCheckbox"
                >
                  {{ content.secretNumberLabel }}
                </c-checkbox>

                <popover-panel
                  :description="content.secretNumberDescription"
                  :tooltip-screen-reader-text="content.tooltipScreenReaderText"
                  :popover-triggers="['hover', 'click']"
                  base-classes="porting__tooltip"
                >
                  <div class="porting__tooltip__icon-wrapper">
                    <c-icon
                      class="porting__tooltip__icon-wrapper__icon"
                      symbol-id="service-information"
                    />
                  </div>
                </popover-panel>
              </div>
            </c-col>
          </c-row>
          <c-row class="porting-config__section__row">
            <c-col>
              <div
                class="porting-config__section__row__tooltip"
                :class="{
                  'porting-config__section__row__tooltip--checked-border':
                    form.leaveOutNumber
                }"
              >
                <c-checkbox
                  :id="content.unlistedNumberLabel"
                  v-model="form.leaveOutNumber"
                  name="isNumberUnlisted"
                  :checked="form.leaveOutNumber"
                  @event-id="toggleCheckbox"
                >
                  {{ content.unlistedNumberLabel }}
                </c-checkbox>

                <popover-panel
                  :description="content.unlistedNumberDescription"
                  :tooltip-screen-reader-text="content.tooltipScreenReaderText"
                  :popover-triggers="['hover', 'click']"
                  base-classes="porting__tooltip"
                >
                  <div class="porting__tooltip__icon-wrapper">
                    <c-icon
                      class="porting__tooltip__icon-wrapper__icon"
                      symbol-id="service-information"
                    />
                  </div>
                </popover-panel>
              </div>
            </c-col>
          </c-row>
        </div>
      </c-collapse>
    </fieldset>
  </config-group-item>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import {
  CInformationBox,
  CCheckbox,
  CFormRadioGroup,
  CFormRadio,
  CModal,
  CRow,
  CCol,
  CCollapse
} from 'olympus/components/index.js';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import CDatepicker from 'webshop/components/c-datepicker/c-datepicker.vue';
import CChip from 'webshop/components/c-chip/c-chip.vue';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';
import ConfigGroupItem from '../config-group-item/config-group-item.vue';
import SimCardSelection from '../sim-card-selection/sim-card-selection.vue';
import { getSimCardSettings } from '../product-config-utils.js';
import { SIM_OPTIONS } from 'webshop/enums/sim-options';

import {
  GETTERS as NUMBER_CONFIG_GETTERS,
  MUTATIONS as NUMBER_CONFIG_MUTATIONS,
  ACTIONS as NUMBER_CONFIG_ACTIONS
} from 'webshop/store/product-config/number-porting-config/index.js';
import {
  ACTIONS as PRODUCT_CONFIG_ACTIONS,
  GETTERS as PRODUCT_CONFIG_GETTERS
} from 'webshop/store/product-config/index.js';

import {
  required,
  requiredIf,
  numeric,
  minLength,
  maxLength,
  sameAs
} from 'vuelidate/lib/validators/index.js';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

export default {
  name: 'PortingConfig',

  components: {
    CInformationBox,
    CInputGroup,
    CChip,
    CCheckbox,
    CFormRadioGroup,
    CFormRadio,
    CModal,
    ConfigGroupItem,
    CRow,
    CCol,
    CCollapse,
    CDatepicker,
    CIcon,
    PopoverPanel,
    SimCardSelection
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Array,
      default: () => []
    },
    configData: {
      type: Object,
      default: () => ({})
    },
    checkedSimRadio: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      form: {
        number: '',
        simNumber: '',
        isLegalOwner: '',
        isB2BCustomer: '',
        cvrNumber: '',
        poaAllowed: false,
        portNumberAsap: '',
        portingDate: '',
        secretNumber: false,
        leaveOutNumber: false
      },
      cvrNumberNotFoundError: '',
      SIM_OPTIONS: SIM_OPTIONS
    };
  },

  validations: {
    getB2BAddress: {
      required: requiredIf(vm => vm.showPoaBusinessStep)
    },
    form: {
      number: {
        required,
        numeric,
        dkPhoneNumber,
        minLength: minLength(8),
        maxLength: maxLength(8)
      },
      simNumber: {
        numeric
      },
      isLegalOwner: {
        required
      },
      isB2BCustomer: {
        required: requiredIf(function () {
          return this.showPrivateOrBusinessStep; // eslint-disable-line no-invalid-this
        })
      },
      cvrNumber: {
        required: requiredIf(function () {
          return this.showPoaBusinessStep; // eslint-disable-line no-invalid-this
        }),
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(8)
      },
      poaAllowed: {
        /*
         * Checks whether the value of the checkbox 'poaAllowed' is:
         * true if we show the poa step or if the checkbox is already checked (that is, when the user has opened it up and checked it, and closed it again)
         * false if do not show the step & it's not checked.
         */
        sameAs: sameAs(function (form) {
          return (
            this.showPoaPrivateStep || // eslint-disable-line no-invalid-this
            this.showPoaBusinessStep || // eslint-disable-line no-invalid-this
            form.poaAllowed
          ); // eslint-disable-line no-invalid-this
        })
      },
      portNumberAsap: {
        required: requiredIf(function () {
          return this.showShipmentStep; // eslint-disable-line no-invalid-this
        })
      },
      portingDate: {
        required: requiredIf(function () {
          return this.showDateSelectorStep; // eslint-disable-line no-invalid-this
        })
      },
      selectedSimcardType: {
        required: requiredIf(function () {
          /* eslint-disable */
          if (this.content.hideSimTypeOptions) {
            return false;
          }
          if (this.form.isLegalOwner === 'false') {
            return false;
          }
          return this.content.showSimDeliveryOptionsForPartners;
          /* eslint-enable */
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      getB2BAddress: NUMBER_CONFIG_GETTERS.GET_B2BADDRESS,
      getErrorByMemberName: PRODUCT_CONFIG_GETTERS.GET_ERROR_BY_MEMBER_NAME
    }),

    mappedB2BAddress() {
      return Object.keys(this.getB2BAddress).reduce(
        (acc, key) => ({
          ...acc,
          ...{
            [`B2B${key.charAt(0).toUpperCase() + key.slice(1)}`]:
              this.getB2BAddress[key]
          }
        }),
        {}
      );
    },

    simCardSettings() {
      return getSimCardSettings(this.content, this.configData);
    },

    showPrivateOrBusinessStep() {
      return this.form.isLegalOwner === 'true';
    },

    showOwnerDisclaimer() {
      return this.form.isLegalOwner === 'false';
    },

    showPoaPrivateStep() {
      return (
        this.showPrivateOrBusinessStep && this.form.isB2BCustomer === 'false'
      );
    },

    showPoaBusinessStep() {
      return (
        this.showPrivateOrBusinessStep && this.form.isB2BCustomer === 'true'
      );
    },

    showShipmentStep() {
      return (
        (this.showPoaPrivateStep || this.showPoaBusinessStep) &&
        this.form.poaAllowed
      );
    },

    showDateSelectorStep() {
      return this.showShipmentStep && this.form.portNumberAsap === 'false';
    },

    ready() {
      return !this.$v.$invalid;
    },

    numberError() {
      if (this.form.number) {
        return this.getErrorByMemberName(['Number']);
      }

      this.removeErrors(['Number']);
      return '';
    },

    portFromSimNumberError() {
      return this.getErrorByMemberName(['PortFromSimNumber']);
    },

    b2bCvrError() {
      return this.getErrorByMemberName(['Cvr', 'B2BCvr']);
    },

    portingDateDisclaimerType() {
      return (
        this.content.numberPorting.portingDateDisclaimer?.messageBoxType?.toLowerCase() ??
        'warning'
      );
    },
    firstPortingDate() {
      return this.content.firstPortingDate?.value;
    },
    simImageAlt() {
      return this.content.numberPorting.howToFindSimPopup.imageAlt ?? '';
    },
    b2BAddressZipcodeAndCity() {
      return this.getB2BAddress
        ? `${this.getB2BAddress.city} ${this.getB2BAddress.zipCode}`
        : '';
    }
  },

  watch: {
    ready(value) {
      this.$emit('ready', value);
    },
    showPoaBusinessStep(value) {
      /* if PoaBusinessStep is not shown cvr-input field will be cleared
      and B2BAddress set to null */
      if (!value) {
        this.form.cvrNumber = '';
        this.setB2BAddress(null);
      }
    }
  },

  methods: {
    ...mapActions({
      fetchB2BAddress: NUMBER_CONFIG_ACTIONS.FETCH_B2BADDRESS,
      setErrors: PRODUCT_CONFIG_ACTIONS.SET_ERRORS,
      removeErrors: PRODUCT_CONFIG_ACTIONS.REMOVE_ERRORS
    }),
    ...mapMutations({
      setB2BAddress: NUMBER_CONFIG_MUTATIONS.SET_B2BADDRESS
    }),
    ...mapMutations(['HIDE_SPINNER', 'SHOW_SPINNER']),
    toggleCheckbox(id) {
      if (id === 'porting-allow-poa') {
        this.form.poaAllowed = !this.form.poaAllowed;
      }

      if (id === this.content.secretNumberLabel) {
        this.form.secretNumber = !this.form.secretNumber;
      }

      if (id === this.content.unlistedNumberLabel) {
        this.form.leaveOutNumber = !this.form.leaveOutNumber;
      }
    },
    handleExpanded(expanded) {
      this.$emit('expanded');
      // Reset vuelidate and component state on collapse
      if (!expanded) {
        if (this.$v) {
          this.removeErrors(['PortFromSimNumber', 'Number', 'NewSimNumber']);
          this.$v.$reset();
        }
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    setLegalOwner(e) {
      this.form.isLegalOwner = e;
    },
    setDateChip(e) {
      this.form.portNumberAsap = e;
    },
    setSelectedDate(e) {
      this.form.portingDate = e;
    },
    showSimModal() {
      this.$refs.howToFindSimModal.show();
    },
    b2BCvrInput() {
      if (String(this.form.cvrNumber).length === 8) {
        this.SHOW_SPINNER();
        this.fetchB2BAddress(this.form.cvrNumber)
          .then(({ data }) => {
            this.setB2BAddress({
              ...data.data?.attributes,
              cvr: this.form.cvrNumber
            });

            this.removeErrors(['B2BCvr', 'Cvr']);
          })
          .catch(({ errors }) => {
            this.setB2BAddress(null);
            this.setErrors(errors);
          })
          .finally(() => {
            this.HIDE_SPINNER();
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.porting-config {
  font-size: $global-font-size-md;

  &__section {
    margin-bottom: $u-400;

    &--no-margin {
      margin-bottom: 0;
    }

    &__row:not(:last-of-type) {
      margin-bottom: $u-250;
    }

    &__row {
      @include mq($screen-md, max) {
        &__chip-wrapper:last-of-type {
          margin-top: $u-200;
        }
      }

      // porting-config__section__row__tooltip
      &__tooltip {
        display: flex;
      }

      &__sim-info-text {
        margin-right: $u-200;
      }
    }
  }

  h3 {
    font-size: $font-size-h3;
    line-height: $title-line-height;
    font-weight: 700;
  }

  &__legend {
    font-size: $global-font-size;
    font-weight: 700;
    margin-bottom: $u-300;
  }

  hr {
    background: $c-nt-300;
    border: none;
    height: $u-100;
    margin: $u-600 0;

    @include mq($screen-md, max) {
      margin: $u-350 0;
    }
  }

  &__info-box {
    margin-top: $u-400;
  }

  &__input {
    margin-top: $u-350;
  }
}
.howToFindSimModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__img {
    margin-top: $u-250;
    flex-grow: 0;
  }
}

// porting__tooltip
.porting__tooltip {
  margin-left: $u-200;
  display: flex;

  // porting__tooltip__icon-wrapper
  &__icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    // porting__tooltip__icon-wrapper__icon
    &__icon {
      max-height: $u-350;
      max-width: $u-350;
    }
  }
}
</style>
