<c-collapse :id="`${item.groupId}`" :visible="show" transition-duration="200ms" class="shopping-cart-item-container">
  <div
    class="shopping-cart-item"
    :class="{ 'is-primary': item.primary, 'is-mandatory': item.isMandatory, show: show }"
  >
    <div class="shopping-cart-item__main">
      <div v-if="hasImage" class="shopping-cart-item__image">
        <img :src="item.image.src" :alt="item.image.alt" />
      </div>

      <div class="shopping-cart-item__info-container">
        <div class="shopping-cart-item__action">
          <c-button
            reset
            icon-button
            v-if="item.action === BASKET_ITEM_ACTIONS.REMOVE && !item.isMandatory"
            @click="remove"
          >
            <c-icon symbol-id="service-trash"></c-icon>
            <span class="cm-sr-only">Remove</span>
          </c-button>

          <c-checkbox
            v-else-if="item.action === BASKET_ITEM_ACTIONS.TOGGLE"
            :id="item.groupId + item.code"
            switch
            :name="item.groupId + item.code"
            :checked="!item.isOffering"
            @change="toggle"
            class="shopping-cart-item__action--toggle"
          />
        </div>

        <div v-if="item.title" class="shopping-cart-item__brand">
          {{ item.title }}
        </div>

        <div class="shopping-cart-item__info-bottom-row">
          <div class="shopping-cart-item__details-container">
            <div class="shopping-cart-item__title">
              {{ item.name }}
              <popover-panel
                v-if="technicianDisclaimer"
                :description="technicianDisclaimer.message"
                :popover-triggers="['hover', 'click']"
                base-classes="shopping-cart-item__tooltip"
              >
                <c-icon
                  class="shopping-cart-item__title__icon"
                  symbol-id="contact-information"
                />
              </popover-panel>
            </div>

            <div class="shopping-cart-item__meta">
              <span v-for="(meta, i) in item.meta" :key="i">{{ meta }}</span>
            </div>
          </div>

          <div class="shopping-cart-item__price-container">

            <span v-if="item.hasPromotion" class="shopping-cart-item__voucher">{{ promotionCodeAppliedLabel }}</span>

            <div class="shopping-cart-item__price-details">
              <div v-if="item.discountText" class="shopping-cart-item__discount">
                {{ item.discountText }}
              </div>
              
              <div v-if="item.prices" class="shopping-cart-item__price">
                <span v-if="item.hasPromotion && item.prices.monthlyPriceBeforeDiscount" class="shopping-cart-item__base-price">
                  {{ item.prices.monthlyPriceBeforeDiscount.formattedValueCurrency }}
                </span>
                <span v-if="item.hasPromotion && item.prices.fullPriceBeforeDiscount" class="shopping-cart-item__base-price">
                  {{ item.prices.fullPriceBeforeDiscount.formattedValueCurrency }}
                </span>
                <span v-if="item.prices.base && item.type !== ITEM_TYPES.SUBSCRIPTION" class="shopping-cart-item__base-price">
                  {{ item.prices.base.formattedValueCurrency }}
                </span>
                <span v-if="item.prices.monthly">
                  {{ item.prices.monthly.formattedValueCurrency }}
                </span>
                <span v-if="item.prices.full">
                  {{ item.prices.full.formattedValueCurrency }}
                </span>
                <span v-if="item.campaignText || (productDisclaimerTexts && productDisclaimerTexts.length > 0)">
                  *
                </span>
              </div>
            </div>

            <div class="shopping-cart-item__details">
              <div v-if="installments" class="shopping-cart-item__payment-period">
                {{ installments }}
              </div>

              <div v-if="binding" class="shopping-cart-item__binding-period">
                {{ binding }}
              </div>

              <div v-if="item.campaignText" class="shopping-cart-item__campaign-text">
                {{ item.campaignText }}
              </div>

              <div class="shopping-cart-item__product-disclaimer-text">
                <div v-for="(text, i) in productDisclaimerTexts" :key="i">{{ text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showSelectedPhoneNumber"
      class="shopping-cart-item__number"
    >
      <existing-subscription-selector
        class="shopping-cart-item__number-select"
        :product-code="item.code"
        :preselected-phone-number="selectedPhoneNumber"
        :allow-actions="false"
      />
    </div>
  </div>
</c-collapse>
