<template>
  <div class="cm-c-checkout-billing__content">
    <div
      class="
        cm-c-checkout__module
        cm-c-checkout__module--pt
        cm-c-checkout__module--mb
        cm-c-total__section
      "
    >
      <c-heading slot="header" level="3" as="3" class="mb-1">
        {{ headline }}
      </c-heading>
      <div class="description" v-html="description"></div>

      <c-accordion
        :expanded-id="selectedPaymentOption"
        :is-model-bound="true"
        class="mt-3"
      >
        <div
          v-for="paymentOption in selectablePaymentOptions"
          :key="paymentOption.type"
          class="checkout-creditcheck"
          :class="{
            'checkout-creditcheck--active':
              selectedPaymentOption === paymentOption.type
          }"
        >
          <c-accordion-item
            :item-id="paymentOption.type"
            @change="setSelectedPaymentOption"
          >
            <div slot="title" class="checkout-creditcheck__info">
              <div class="checkout-creditcheck__info__left">
                <div class="checkout-creditcheck__image">
                  <img
                    v-if="paymentOption.imageUrl"
                    :src="paymentOption.imageUrl"
                    role="presentation"
                    class="checkout-creditcheck__image__icon"
                  />
                </div>
                <span class="checkout-creditcheck__info__left__title">{{
                  paymentOption.name
                }}</span>
              </div>
              <div class="checkout-creditcheck__info__right__title">
                {{ paymentOption.fee }}
              </div>
            </div>

            <div slot="content" class="checkout-creditcheck__content">
              <div v-if="paymentOption.type === 'Pbs'">
                <c-row>
                  <c-col
                    cols="4"
                    :class="{
                      ValidationFail: errors['PbsRegistrationNumber'],
                      Form__Element: errors['PbsRegistrationNumber']
                    }"
                  >
                    <div class="epi-form-text">
                      <input
                        id="pbs-registration-number"
                        ref="input"
                        v-model="pbsRegistrationNumber"
                        type="number"
                        class="epi-form-text__input c-search-panel__input"
                        placeholder=""
                      />
                      <label
                        for="pbs-registration-number"
                        class="epi-form-text__label"
                      >
                        {{ pbsRegistrationNumberLabel }}
                      </label>
                      <span
                        v-if="errors['PbsRegistrationNumber']"
                        data-valmsg-for="PbsRegistrationNumber"
                        data-valmsg-replace="true"
                        class="field-validation-error"
                        v-html="errors['PbsRegistrationNumber'].description"
                      >
                      </span>
                    </div>
                  </c-col>
                  <c-col
                    cols="8"
                    :class="{
                      ValidationFail: errors['PbsAccountNumber'],
                      Form__Element: errors['PbsAccountNumber']
                    }"
                  >
                    <div class="epi-form-text">
                      <input
                        id="pbs-account-number"
                        ref="input"
                        v-model="pbsAccountNumber"
                        type="number"
                        class="epi-form-text__input c-search-panel__input"
                        placeholder=""
                      />
                      <label
                        for="pbs-account-number"
                        class="epi-form-text__label"
                      >
                        {{ pbsAccountNumberLabel }}
                      </label>
                      <span
                        v-if="errors['PbsAccountNumber']"
                        data-valmsg-for="PbsAccountNumber"
                        data-valmsg-replace="true"
                        class="field-validation-error"
                        v-html="errors['PbsAccountNumber'].description"
                      >
                      </span>
                    </div>
                  </c-col>
                </c-row>
              </div>
              <div
                v-if="paymentOption.description"
                class="checkout-creditcheck__content__description"
              >
                <div v-html="paymentOption.description"></div>
              </div>
            </div>
          </c-accordion-item>
        </div>
      </c-accordion>
    </div>
  </div>
</template>

<script>
import {
  getBillingOptions,
  validatePaymentOption
} from '../../api/payment-service';
import { mapMutations } from 'vuex';
import { CHeading, CRow, CCol } from 'olympus/components';
import CAccordion from '../c-accordion/c-accordion.vue';
import CAccordionItem from '../c-accordion/c-accordion-item.vue';
import { providerKey as checkoutProviderKey } from '../../../../webshop/shared/providers/checkout-provider.js';

export default {
  name: 'CheckoutBilling',

  components: {
    CAccordion,
    CAccordionItem,
    CCol,
    CHeading,
    CRow
  },

  inject: [checkoutProviderKey],

  props: {
    paymentOptions: {
      type: Array,
      required: true
    },
    pbsAccountNumberLabel: {
      type: String,
      default: ''
    },
    pbsRegistrationNumberLabel: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    creditCheckFailedContent: {
      type: Object,
      default: () => ({
        creditCheckFailedHeader: '',
        creditCheckFailedDescription: '',
        creditCheckFailedLinkLabel: '',
        underWritingLinkLabel: '',
        underWritingMessageText: '',
        notAllowedUnderWritingLinkLabel: '',
        notAllowedUnderWritingMessageText: '',
        creditCheckFailedLink: '',
        creditCheckFailedLinkShop: '',
        creditCheckFailedUnderWritingLink: ''
      })
    },
    isOnlineChannel: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      allowedPaymentOptions: [],
      errors: {},
      pbsAccountNumber: '',
      pbsRegistrationNumber: '',
      selectedPaymentOption: ''
    };
  },

  computed: {
    selectablePaymentOptions() {
      return this.paymentOptions.filter(x =>
        this.allowedPaymentOptions.includes(x.type)
      );
    }
  },

  watch: {
    selectedPaymentOption(newValue) {
      this[checkoutProviderKey].setDisableSubmitButton(newValue === 'None');
    }
  },

  async mounted() {
    const form = Array.from(document.querySelectorAll('form')).find(element =>
      element.action.includes(window.location.pathname)
    );

    try {
      form.addEventListener('submit', async event => {
        if (event.submitter.value === 'previous') {
          return;
        }
        this.SHOW_SPINNER();
        event.preventDefault();
        this.validatePaymentOption()
          .then(response => {
            if (this.validateResponse(response.data.data.attributes)) {
              form.submit();
            }
          })
          .catch(errors => {
            this.handlePaymentValidationErrors(errors);
            return;
          })
          .finally(() => {
            this.HIDE_SPINNER();
          });
      });
    } catch (error) {
      return;
    }
    await this.getPaymentOptions();
  },

  methods: {
    ...mapMutations(['SHOW_SPINNER', 'HIDE_SPINNER', 'SHOW_DIALOG']),

    async getPaymentOptions() {
      var self = this;
      const response = await getBillingOptions();
      this.allowedPaymentOptions = [
        ...new Set(response.map(paymentOption => paymentOption.paymentType))
      ];
      let preselectedType = response.find(e => e.isSelected);
      if (preselectedType) {
        self.setSelectedPaymentOption(preselectedType.paymentType);
      } else {
        self.setSelectedPaymentOption('None');
      }
    },

    setSelectedPaymentOption(paymentOption) {
      this.selectedPaymentOption = paymentOption;
    },

    validatePaymentOption() {
      return validatePaymentOption(
        this.selectedPaymentOption,
        this.pbsRegistrationNumber,
        this.pbsAccountNumber
      );
    },

    validateResponse(response) {
      if (!response.creditCheckNotApproved) {
        return true;
      }
      let model = this.createCreditCheckFailedModel(
        response.creditCheckMessage,
        response.joiceId
      );
      this.SHOW_DIALOG({
        componentName: 'error-dialog',
        componentProps: {
          errorObject: {
            errorHeader: model.creditCheckFailedHeader,
            errorText: model.message,
            errorTextSub: model.joiceId,
            errorLink: {
              label: model.creditCheckFailedLinkLabel,
              href: model.creditCheckFailedLink,
              post: model.shouldSendEmail
            }
          },
          hideCloseButton: true
        }
      });
      return false;
    },

    createCreditCheckFailedModel(creditCheckMessage, joiceId) {
      let model = {
        shouldSendEmail: false,
        creditCheckFailedLinkLabel: '',
        underWritingMessageText: '',
        creditCheckFailedLink:
          this.creditCheckFailedContent?.creditCheckFailedLink,
        creditCheckFailedHeader:
          this.creditCheckFailedContent?.creditCheckFailedHeader,
        message: creditCheckMessage.message,
        joiceId: joiceId
      };
      if (this.isOnlineChannel) {
        model.creditCheckFailedLinkLabel =
          this.creditCheckFailedContent.creditCheckFailedLinkLabel;
        return model;
      }
      switch (creditCheckMessage.joiceCategory) {
        case 'Underwriting':
          model.creditCheckFailedLinkLabel =
            this.creditCheckFailedContent?.underWritingLinkLabel;
          model.underWrittingMessageText =
            this.creditCheckFailedContent?.underWritingMessageText;
          model.creditCheckFailedLink =
            this.creditCheckFailedContent?.creditCheckFailedUnderWritingLink;
          model.shouldSendEmail = true;
          break;
        case 'Denied':
          model.creditCheckFailedLinkLabel =
            this.creditCheckFailedContent?.notAllowedUnderWritingLinkLabel;
          model.underWritingMessageText =
            this.creditCheckFailedContent?.notAllowedUnderWritingMessageText;
          break;
        default:
          break;
      }
      model.creditCheckFailedLink =
        this.creditCheckFailedContent?.creditCheckFailedLinkShop ?? '#';
      return model;
    },

    handlePaymentValidationErrors(errors = []) {
      this.errors = {};
      errors.forEach(error => {
        const mappedError =
          window.__APP__?.checkoutErrorMap &&
          window.__APP__.checkoutErrorMap[error.code]
            ? window.__APP__.checkoutErrorMap[error.code]
            : null;
        if (mappedError && error.meta?.memberName) {
          this.errors[error.meta.memberName] = mappedError;
        }
      });
    }
  }
};
</script>
