<template>
  <div v-if="splashText" class="cm-c-splash" :class="classList">
    <span class="cm-c-splash__wrap" v-html="splashText"> </span>
  </div>
</template>

<script>
/**
 * A generic splash, containing all size & type variations.
 * TODO DKT-1432 should use badgeText.item1 as a custom class modifier (if still needed?)
 * TODO DKT-1432: use in product cards.
 * TODO DKT-1432: the splash is positioned absolute by default, but should probably be an option. That requires aligning cm-c-splash styles with product overview & product details pages.
 */
import { SPLASH_TYPES } from 'webshop/enums/splash-types';

export default {
  name: 'ProductSplash',

  props: {
    splashText: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].indexOf(value) !== -1;
      }
    },

    type: {
      type: String,
      default: SPLASH_TYPES.OFFER,
      validator(value) {
        return (
          [
            SPLASH_TYPES.OFFER,
            SPLASH_TYPES.SPECIAL_OFFER,
            SPLASH_TYPES.PRESALE,
            SPLASH_TYPES.NEWS,
            SPLASH_TYPES.OUT_OF_STOCK,
            SPLASH_TYPES.BLACK_FRIDAY
          ].indexOf(value) !== -1
        );
      }
    }
  },

  computed: {
    classList() {
      return {
        [`cm-c-splash--${this.size}`]: this.size,
        [`${this.type}`]: this.type
      };
    }
  }
};
</script>
