var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mini-cart-item", class: _vm.getClass }, [
    _vm.spinning ? _c("div", { staticClass: "cm-c-spinner" }) : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "mini-cart-item__image" }, [
      _vm.item.image.src
        ? _c("img", {
            attrs: { src: _vm.item.image.src, alt: _vm.item.image.alt }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mini-cart-item__info-container" }, [
      _vm.title
        ? _c("small", { staticClass: "mini-cart-item__brand" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mini-cart-item__specs-container" }, [
        _c("div", { staticClass: "mini-cart-item__title-container" }, [
          _c("h3", { staticClass: "mini-cart-item__title" }, [
            _vm._v("\n          " + _vm._s(_vm.name) + "\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mini-cart-item__meta" },
            _vm._l(_vm.item.meta, function(meta, i) {
              return _c("span", { key: i }, [
                _vm._v("\n            " + _vm._s(meta) + "\n          ")
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mini-cart-item__montly-price-label" },
            [
              _vm.item.installments === 1
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.content.upFrontPriceLabel) +
                        "\n          "
                    )
                  ]
                : [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.content.pricePerMonthLabel) +
                        "\n          "
                    )
                  ]
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mini-cart-item__price-container" }, [
          _vm.item.prices.monthly
            ? _c("p", { staticClass: "mini-cart-item__price" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.prices.monthly.formattedValueCurrency) +
                    "\n        "
                )
              ])
            : _c("p", { staticClass: "mini-cart-item__price" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.prices.full.formattedValueCurrency) +
                    "\n        "
                )
              ]),
          _vm._v(" "),
          _vm.item.paymentPeriod
            ? _c("div", { staticClass: "mini-cart-item__price-info" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.item.paymentPeriodText) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mini-cart__remove-btn-container" }, [
        _c(
          "button",
          {
            staticClass: "mini-cart__remove-btn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("removeProduct")
              }
            }
          },
          [
            _c("c-icon", { attrs: { "symbol-id": "service-trash" } }),
            _vm._v(" "),
            _c("span", { staticClass: "cm-sr-only" }, [_vm._v("Removebutton")])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }