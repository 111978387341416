const TRANSITION_STATES = {
  IDLE: 'idle',
  BEFORE_ENTER: 'before-enter',
  ENTER: 'enter',
  AFTER_ENTER: 'after-enter',
  BEFORE_LEAVE: 'before-leave',
  LEAVE: 'leave',
  AFTER_LEAVE: 'after-leave'
};

export default TRANSITION_STATES;
