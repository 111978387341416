import { toFullQuery } from '../../../../shared/utils/ajax.js';
import { get } from 'axios';
import store from '../store/shop/index.js';

function lookupProductsForAddress(address) {
  const contextId = store.state.global.contextId;
  const parameters =
    address !== null
      ? Object.assign(address, { contextId: contextId })
      : { contextId: contextId };
  const reqUrl = toFullQuery(
    `/api/olympus/addressTechnology/products`,
    parameters
  );
  return get(reqUrl);
}

export default lookupProductsForAddress;
