<script>
import { mapGetters, mapMutations } from 'vuex';

import ProductCategoryFacet from './product-category-facet.vue';
import ProductCategoryPrice from './product-category-price.vue';
import ProductCard from './product-card/product-card.vue';
import ProductCategorySort from './product-category-sort.vue';
import ProductCompare from './product-compare.vue';
import CStickyFooter from '../c-sticky-footer/c-sticky-footer.vue';

import { CButton, CCollapse, CCollapseTrigger } from 'olympus/components';

export default {
  name: 'ProductCategoryOverview',
  components: {
    'product-category-facet': ProductCategoryFacet,
    'product-category-price': ProductCategoryPrice,
    'product-card': ProductCard,
    'product-category-sort': ProductCategorySort,
    'product-compare': ProductCompare,
    CStickyFooter,
    CButton,
    CCollapse,
    CCollapseTrigger
  },

  props: {
    blockid: {
      type: String,
      default: ''
    },
    culture: {
      type: String,
      default: 'da'
    },
    compareUrl: {
      type: String,
      default: ''
    },
    maxCompare: {
      type: Number,
      default: 3
    },
    minCompare: {
      type: Number,
      default: 2
    },
    pricePlan: {
      type: Number,
      default: 0
    },
    searchurl: {
      type: String,
      default: ''
    },
    queryfortracking: {
      type: String,
      default: '/'
    },
    pageSize: {
      type: Number,
      default: 12
    },
    compareDisabled: Boolean,
    minimumPriceDisabled: Boolean,
    installmentsDisabled: Boolean
  },

  data() {
    return {
      pagination: null,
      allowPaginationReset: true
    };
  },

  computed: {
    // TODO DKT-1455: using vuex to maintain state for search is not necessary.
    // The state can be local and to <product-category-overview> and passed
    // down to components that need it (there are no siblings that should share the state).
    // All catalog search-related logic in the vuex store should be refactored and made local.
    ...mapGetters([
      'loading',
      'productSearchResult',
      'pageCount',
      'numberOfActiveFilters',
      'pricePlanFilterValue'
    ]),

    products() {
      return this.productSearchResult.hits
        ? this.productSearchResult.hits.length
        : 0;
    },

    total() {
      return this.productSearchResult.totalHits;
    },

    indexSubtraction() {
      return this.productSearchResult.pageSize * this.pagination;
    },

    pageSizeRequest() {
      return this.pagination ? this.pagination * this.pageSize : this.pageSize;
    },

    totalPages() {
      return Math.ceil(this.productSearchResult.totalHits / this.pageSize);
    },

    shouldDisplayLoadMoreButton() {
      return (this.pagination || 1) < this.totalPages;
    }
  },

  watch: {
    numberOfActiveFilters() {
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query.page && this.allowPaginationReset) {
        this.$store.commit('SET_PAGE', 1);
        this.pagination = null;

        const query = Object.assign({}, currentRoute.query);
        delete query.page;

        this.$router.replace({
          ...currentRoute,
          query
        });
      }

      this.allowPaginationReset = true;
    }
  },

  mounted: function () {
    this.$store.commit('COMPARE_SETTINGS', {
      maxCompare: this.maxCompare,
      minCompare: this.minCompare,
      url: this.compareUrl
    });

    const currentRoute = this.$router.currentRoute;
    Object.keys(currentRoute.query).forEach(key => {
      const query = currentRoute.query[key].split(';');

      if (query.length === 2) {
        const values = query[0].split(',');
        const facetId = query[1];

        this.allowPaginationReset = false;

        values.forEach(value => {
          this.setFacetState({
            id: facetId,
            name: key,
            value
          });
        });
      }
    });

    if (currentRoute.query.page) {
      this.pagination = parseInt(currentRoute.query.page, 10);
    }

    this.$store.commit('SEARCH_SETTINGS', {
      blockId: this.blockid,
      searchUrl: this.searchurl,
      queryForTracking: this.queryfortracking,
      culture: this.culture,
      pricePlanFilterValue: this.pricePlan.toString(),
      page: 1,
      size: this.pageSizeRequest
    });
    this.search();
  },
  methods: {
    ...mapMutations({
      setFacetState: 'mutations/SET_FACET_STATE'
    }),

    search: function (incrementPageCount = false) {
      let pageCount = this.pagination ? this.pagination : this.pageCount;

      if (incrementPageCount) {
        pageCount = pageCount + 1;
        this.setPagination(pageCount);
      }

      this.$store.dispatch('searchProducts').then(() => {
        if (pageCount > 1) {
          this.scrollToResults();
        }
      });
    },

    // TODO DKT-1455: this should be refactored to eliminate any query selectors. Facet items can react to a state change themselves.
    resetAllFacetGroups: function (facetGroup) {
      this.productSearchResult.facets.forEach(facet => {
        const items = document.querySelectorAll(
          '[data-group=' + facet.name + ']'
        );

        if (items.length) {
          items.forEach(item => (item.checked = false));
        }
      });

      this.pagination = null;
      this.$router.replace({ query: null });
      this.$store.dispatch('resetAllFacetGroups', { name: facetGroup });
    },

    loadMoreProducts: function () {
      this.search(true);
    },

    setPagination: function (pageCount) {
      const currentRoute = this.$router.currentRoute;

      const query = {
        ...currentRoute.query,
        page: pageCount
      };

      this.$router.replace({
        ...currentRoute,
        query
      });

      this.pagination = pageCount;
      this.$store.commit('REMOVE_SIZE');
      this.$store.commit('SET_PAGE', pageCount);
    },

    scrollToResults() {
      const windowHeight = parseInt(`-${window.innerHeight}`, 10);
      const resultsCount = this.$refs.resultsCount;
      const resultsCountHeight = resultsCount.offsetHeight;
      const bufferSpacing = 70;

      this.$scrollTo(`#${resultsCount.id}`, 1000, {
        offset: windowHeight + resultsCountHeight + bufferSpacing
      });
    },

    beforeEnter(el) {
      el.style.opacity = 0;
    },

    enter(el) {
      var delay = this.indexSubtraction
        ? (el.dataset.index - this.indexSubtraction) * 150
        : el.dataset.index * 150;
      setTimeout(function () {
        el.style.opacity = 1;
      }, delay);
    },

    leave(el) {
      var delay = 0;
      setTimeout(function () {
        el.style.opacity = 0;
      }, delay);
    }
  },
  template: '#product-category-overview-template'
};
</script>
