export function humany() {
  (function() {
    const container = document.getElementById('FAQHumany');

    if (container) {
      (function(n, t, i, r, u, f, e) {
        f = t.createElement(u);
        f.async = !0;
        f.src = i;
        e = t.getElementsByTagName(u)[0];
        e.parentNode.insertBefore(f, e);
        n[r] = n[r] || {
          _c: [],
          configure: function(t) {
            n[r]._c.push(t);
          }
        };
      })(window, document, container.dataset.javascriptUrl, 'Humany', 'script');
    }
  })();
}
