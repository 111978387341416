<template>
  <div class="limited-menu">
    <div class="c-wrapper limited-menu__inner-wrapper">
      <a class="limited-menu__logo" href="/">
        <c-icon class="limited-menu__logo-icon" symbol-id="menu-logo" />
      </a>
      <div class="limited-menu__contact">
        <span
          v-for="(item, index) in content.items"
          :key="index"
          v-html="item.rawHtml"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CIcon from '../c-icon/c-icon.vue';

export default {
  name: 'LimitedMenuHeader',

  components: { CIcon },

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style></style>
