<template>
  <div :class="classObject">
    <span ref="activityIndicator" class="activity-indicator__animation"></span>
    <p>
      <strong>{{ label }}</strong>
    </p>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import CorePurple500x64 from './core-purple-500x64.json';

export default {
  name: 'ActivityIndicator',
  props: {
    label: {
      type: String,
      default: ''
    },

    fullscreen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classObject() {
      return {
        'activity-indicator': true,
        'activity-indicator--fullscreen': this.fullscreen
      };
    }
  },

  mounted() {
    lottie.loadAnimation({
      container: this.$refs.activityIndicator, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: CorePurple500x64
    });

    lottie.play();
  },

  beforeDestroy() {
    lottie.destroy();
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/sass/settings/_settings.vars.scss';

.activity-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    max-width: 152px;
    text-align: center;
    margin-top: 26px;
  }

  // .activity-indicator__animation
  &__animation {
    width: 64px;
    height: 64px;
  }

  // .activity-indicator--fullscreen
  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: map-get($zindex, 'activity-indicator');

    &:before {
      content: '';
      position: fixed;
      z-index: -1;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: white;
      opacity: 0.9;
    }
  }
}
</style>
