var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vm.accessories.variants.length
    ? _c(
        "div",
        {
          staticClass:
            "\n    cm-grid__col--md-4 cm-grid__col--xs-12\n    cm-c-power-step-accessory-card\n  "
        },
        [
          _c(
            "div",
            {
              staticClass: "cm-c-power-step-accessory-card__image-container",
              attrs: { id: "gallery-" + _vm.accessoryIndex }
            },
            [
              _c(
                "figure",
                { staticClass: "cm-c-card-product__photo cm-accessory-card" },
                [
                  _vm.selectedVariant &&
                  _vm.selectedVariant.badge &&
                  _vm.selectedVariant.badgeText
                    ? _c(
                        "div",
                        {
                          staticClass: "cm-c-card-product__splash cm-c-splash",
                          class: _vm.selectedVariant.badgeText.item1
                        },
                        [
                          _c("span", { staticClass: "cm-c-splash__wrap" }, [
                            _vm._v(_vm._s(_vm.selectedVariant.badgeText.item2))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "cm-c-card-product__gallery-container cm-c-accesory-photo"
                    },
                    [
                      _vm.selectedImage
                        ? _c("img", {
                            staticClass:
                              "cm-c-power-step-accessory-card__image",
                            attrs: {
                              src: _vm.selectedImage.src,
                              alt: _vm.selectedImage.caption
                            }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cm-c-power-step-accessory-card__content" },
            [
              _c("h3", { staticClass: "cm-c-cart-list__product-title" }, [
                _c(
                  "nav",
                  { staticClass: "cm-c-product-colors" },
                  _vm._l(_vm.vm.accessories.colors.data, function(
                    color,
                    index
                  ) {
                    return _c("div", {
                      key: index,
                      staticClass: "cm-c-product-colors-color",
                      class: {
                        "-border": _vm.checkColor(color.description),
                        "-selected":
                          color &&
                          _vm.selectedVariant &&
                          color.name === _vm.selectedVariant.name
                      },
                      style: { background: color.description },
                      on: {
                        click: function($event) {
                          return _vm.selectVariant(color)
                        }
                      }
                    })
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "cm-c-power-step-accessory-card__url",
                    attrs: { href: _vm.accessoryUrl }
                  },
                  [_vm._v(_vm._s(_vm.vm.displayName))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "cm-c-power-step-accessory-card__price" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.cashPriceLabel) +
                        " " +
                        _vm._s(_vm.vm.price.formattedValueCurrency) +
                        "\n      "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cm-c-cart-list__product-desc" }, [
                _vm.vm.shortDescription
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.vm.shortDescription) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.vm.description
                  ? _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.vm.description) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "cm-c-power-step-accessory-card__link",
                  attrs: { href: _vm.accessoryUrl }
                },
                [_vm._v(_vm._s(_vm.readMoreLabel))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "cm-c-power-step-accessory-card__call-to-action-container"
            },
            [
              _c("transition", { attrs: { name: "flip" } }, [
                !_vm.inBasket
                  ? _c(
                      "a",
                      {
                        key: "add",
                        staticClass:
                          "\n          c-btn\n          c-btn--block\n          c-btn--secondary\n          c-btn--secondary-transactional\n          c-btn--md\n          cm-mb\n          cm-c-power-step-accessory-card__call-to-action\n        ",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.addToBasket($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.addToBasketLabel) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "flip" } }, [
                _vm.inBasket
                  ? _c(
                      "a",
                      {
                        key: "navigate",
                        staticClass:
                          "\n          c-btn c-btn--block c-btn--secondary c-btn--md\n          cm-mb\n          cm-c-power-step-accessory-card__call-to-action\n        ",
                        attrs: { href: _vm.basketUrl }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.goToBasketLabel) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "flip" } }, [
                _vm.inBasket
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "cm-c-power-step-accessory-card__in-basket-msg"
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.addedToBasketLabel) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("image-gallery", {
            attrs: {
              "open-link-elm": "#gallery-" + _vm.accessoryIndex,
              "image-list": _vm.images,
              "zoom-inline": false
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }