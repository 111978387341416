<template>
  <div class="mb-2">
    <c-information-box
      v-for="(disclaimer, index) in disclaimers"
      :key="index"
      icon="service-warning"
      type="informative"
      animatein
    >
      <div v-html="disclaimer" />
    </c-information-box>
  </div>
</template>

<script>
import { CInformationBox } from 'olympus/components';

export default {
  name: 'EmployeeReceiptDisclaimers',

  components: {
    CInformationBox
  },

  props: {
    disclaimers: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss"></style>
