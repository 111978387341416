function ajaxGet(url, parseJson, noCache) {
  return new Promise(function(resolve, reject) {
    const req = new XMLHttpRequest();

    if (noCache) {
      if (url.indexOf('?') > -1) {
        url = url + '&t=' + new Date().getTime();
      } else {
        url = url + '?t=' + new Date().getTime();
      }
    }

    req.open('GET', url);
    req.onload = function() {
      if (req.status >= 200 && req.status < 300) {
        resolve(parseJson ? JSON.parse(req.response) : req.response);
      } else {
        reject(new Error(req.statusText));
      }
    };

    req.onerror = function() {
      reject(new Error('Network error'));
    };

    req.send();
  });
}

function ajaxPost(url, data, parseJson) {
  return new Promise(function(resolve, reject) {
    const body = toQueryString(data);

    const req = new XMLHttpRequest();
    req.open('POST', url);
    req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    req.onload = function() {
      if (req.status >= 200 && req.status < 300) {
        resolve(parseJson ? JSON.parse(req.response) : req.response);
      } else {
        reject(new Error(req.statusText));
      }
    };

    req.onerror = function() {
      reject(new Error('Network error'));
    };

    req.send(body);
  });
}

function ajaxPostWithJSON(url, data, parseJson) {
  return new Promise(function(resolve, reject) {
    const body = JSON.stringify(data);

    const req = new XMLHttpRequest();
    req.open('POST', url);
    req.setRequestHeader('Content-Type', 'application/json');
    req.onload = function() {
      if (req.status >= 200 && req.status < 300) {
        resolve(parseJson ? JSON.parse(req.response) : req.response);
      } else {
        reject(new Error(req.statusText));
      }
    };

    req.onerror = function() {
      reject(new Error('Network error'));
    };

    req.send(body);
  });
}

function toFullQuery(url, obj) {
  const queryString = toQueryString(obj);

  return url + (queryString ? '?' + queryString : '');
}

function toQueryString(obj) {
  if (!obj) {
    return null;
  }

  let parts = [];
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    const key = encodeURIComponent(i);
    const values = obj[i];

    if (values && typeof values === 'object') {
      if (values instanceof Array) {
        for (let j = 0; j < values.length; j++) {
          const value = values[j];
          parts.push(key + '=' + encodeURIComponent(value));
        }
      }
    } else {
      if (values === null) {
        parts.push(key + '=');
      } else {
        parts.push(key + '=' + encodeURIComponent(values));
      }
    }
  }

  return parts.join('&');
}

function getQueryString(field, url) {
  var href = url ? url : window.location.href;
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  var string = reg.exec(href);

  return string ? string[1] : null;
}

export {
  ajaxGet,
  ajaxPost,
  ajaxPostWithJSON,
  toFullQuery,
  toQueryString,
  getQueryString
};
