var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "keep-alive",
    [
      _c("h1", [_vm._v(_vm._s(_vm.vm.headline))]),
      _vm._v(" "),
      _c("router-view", {})
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }