var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      directives: [
        { name: "toggle", rawName: "v-toggle", value: _vm.id, expression: "id" }
      ],
      tag: "component"
    },
    [_vm._t("default", null, { expanded: _vm.expanded })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }