export const BROADBAND_SUBSCRIPTION_TYPES = {
  COAX: 'COAX',
  FIBER: 'FIBER',
  DSL: 'DSL',
  FWA: 'FWA'
};

export const PLUGS_TYPES = {
  COAX: ['plugs-coax'],
  FIBER: ['plugs-fiber'],
  DSL: ['plugs-dsl-a', 'plugs-dsl-b']
};
