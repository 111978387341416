var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "c-modal",
        {
          attrs: {
            visible: _vm.content.showPoqErrorModal,
            "ok-button-text": _vm.content.poqErrorModal.acceptButtonText,
            title: _vm.content.poqErrorModal.title,
            centered: "",
            "show-ok-button": ""
          }
        },
        [
          _c("div", {
            staticClass: "broadband-landing-page__modal__message",
            domProps: { innerHTML: _vm._s(_vm.content.poqErrorModal.message) }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }