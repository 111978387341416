<template>
  <c-card class="c-employee-contact-info" extra-padding no-shadow no-rounding>
    <h3 class="c-employee-contact-info__contact__headline">
      {{ content.headline }}
    </h3>
    <p
      class="c-employee-contact-info__contact__description"
      v-html="content.description"
    ></p>
    <c-row class="mb-4">
      <c-col class="cm-c-form__col" cols="6" sm="12">
        <c-input-group
          v-model="$v.form.phoneNumber.value.$model"
          name="number"
          :place-holder-label="content.telephoneNumberLabel"
          type="number"
          :readonly="form.phoneNumber.isReadOnly"
          @change="setFormData(filteredFormData)"
        ></c-input-group
      ></c-col>
      <c-col class="cm-c-form__col" cols="6" sm="12">
        <c-input-group
          v-model="$v.form.email.value.$model"
          name="email"
          :place-holder-label="content.emailLabel"
          type="email"
          :readonly="form.email.isReadOnly"
          @change="setFormData(filteredFormData)"
        ></c-input-group
      ></c-col>
    </c-row>
    <div class="mb-4">
      <h3 class="cm-c-form__header">
        {{ content.installationAddressTitle }}
      </h3>
      <c-row class="cm-c-form__row cm-c-form__row--extra-spacing">
        <c-col class="cm-c-form__col" cols="6" sm="12">
          <c-input-group
            v-model="$v.form.firstName.value.$model"
            name="firstName"
            :place-holder-label="content.firstNameLabel"
            :readonly="form.firstName.isReadOnly"
            @change="setFormData(filteredFormData)"
        /></c-col>
        <c-col class="cm-c-form__col" cols="6" sm="12">
          <c-input-group
            v-model="$v.form.lastName.value.$model"
            name="lastName"
            :place-holder-label="content.lastNameLabel"
            :readonly="form.lastName.isReadOnly"
            @change="setFormData(filteredFormData)"
        /></c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col cols="12">
          <c-input-group
            v-if="employeeData.address && employeeData.address.isReadOnly"
            v-model="form.address"
            name="address"
            :place-holder-label="content.addressSearchLabel"
            readonly
          />
          <address-search
            v-else
            :content="{
              searchInputPlaceholder: content.addressSearchLabel,
              searchString: prefilledAddress
            }"
            :is-block="false"
            @select="setAddress"
          ></address-search>
        </c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col cols="12">
          <c-input-group
            v-model="form.street"
            name="street"
            :place-holder-label="content.addressStreetNameLabel"
            disabled
          />
        </c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col" cols="4" sm="12">
          <c-input-group
            v-model="form.houseNumber"
            name="houseNumber"
            :place-holder-label="content.addressHouseNumberLabel"
            disabled
        /></c-col>
        <c-col class="cm-c-form__col" cols="8" sm="12">
          <c-input-group
            v-model="form.houseLetter"
            name="houseLetter"
            :place-holder-label="content.addressHouseLetterLabel"
            disabled
        /></c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col" cols="4" sm="12">
          <c-input-group
            v-model="form.floor"
            name="floor"
            :place-holder-label="content.addressFloorLabel"
            disabled
        /></c-col>
        <c-col class="cm-c-form__col" cols="8" sm="12">
          <c-input-group
            v-model="form.apartment"
            name="Apartment"
            :place-holder-label="content.addressApartmentLabel"
            disabled
        /></c-col>
      </c-row>
      <c-row class="cm-c-form__row">
        <c-col class="cm-c-form__col" cols="4" sm="12">
          <c-input-group
            v-model="form.zipCode"
            name="zipcode"
            :place-holder-label="content.addressZipCodeLabel"
            disabled
        /></c-col>
        <c-col class="cm-c-form__col" cols="8" sm="12">
          <c-input-group
            v-model="form.city"
            name="city"
            :place-holder-label="content.addressCityLabel"
            disabled
        /></c-col>
      </c-row>
    </div>
    <div
      class="c-employee-contact-info__disclaimer"
      v-html="content.footer"
    ></div>
    <home-office-validation-modal
      v-if="content.validationErrorModalText"
      :title="content.validationErrorModalText.heading"
      :error-message="content.validationErrorModalText.content"
      :visible="showCheckoutError"
    />
  </c-card>
</template>

<script>
import Vue from 'vue';

import CCard from 'webshop/components/c-card/c-card.vue';
import { CRow, CCol } from 'olympus/components/index.js';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import AddressSearch from 'webshop/components/address-search/address-search.vue';
import HomeOfficeValidationModal from 'webshop/components/checkout/checkout-product-configuration/product-config/home-office/employee-config/home-office-validation-modal.vue';

import {
  required,
  numeric,
  minLength,
  maxLength
} from 'vuelidate/lib/validators/index.js';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

import addressMixin from 'webshop/mixins/address-mixin.js';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import {
  GETTERS as HOME_OFFICE_GETTERS,
  ACTIONS
} from 'webshop/store/home-office/index.js';
import { MUTATIONS as CHECKOUT_MUTATIONS } from 'webshop/components/checkout/store';

import { HOME_OFFICE_STEPS } from 'webshop/enums/home-office.js';

import { cloneDeep } from 'lodash-es';

export default {
  name: 'EmployeeContactInformation',

  components: {
    CCard,
    CInputGroup,
    CRow,
    CCol,
    AddressSearch,
    HomeOfficeValidationModal
  },

  mixins: [addressMixin],

  props: {
    content: {
      type: Object,
      default: () => ({})
    },

    orderId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      form: {
        phoneNumber: '',
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        addressId: null,
        street: '',
        houseNumber: '',
        houseLetter: '',
        floor: '',
        apartment: '',
        zipCode: '',
        city: ''
      },
      prefilledAddress: '',
      HOME_OFFICE_STEPS
    };
  },

  validations: {
    form: {
      phoneNumber: {
        value: {
          required,
          numeric,
          dkPhoneNumber,
          minLength: minLength(8),
          maxLength: maxLength(8)
        }
      },
      email: {
        value: {
          required,
          customEmail: value => {
            // Vuelidate default Regex pattern for emails does not accept capital
            // letters for the domain. For example we have issues with emails like
            // hey@Leo-pharma.com. Thus, we use the vuelidate pattern as custom, and
            // modify it to accept capital case letters after @. Will be thinking of
            // a better solution. But this needs to be an immediate business hotfix.
            if (!value) {
              return true;
            }

            const emailRegex =
              // eslint-disable-next-line no-control-regex
              /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z0-9]{2,}(?:[A-z0-9-]*[A-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-z0-9-]*[A-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

            return emailRegex.test(value);
          }
        }
      },

      firstName: {
        value: {
          required
        }
      },
      lastName: {
        value: {
          required
        }
      },
      addressId: {
        required
      }
    }
  },

  computed: {
    ...mapGetters({
      employeeData: HOME_OFFICE_GETTERS.GET_EMPLOYEE_DATA,
      showCheckoutError: HOME_OFFICE_GETTERS.GET_SHOW_CHECKOUT_ERROR
    }),

    // We should only send data to the backend that has been changed by the user
    filteredFormData() {
      const data = {};
      Object.keys(this.form).forEach(k => {
        // if any input has been changed by user (is dirty) OR
        // if input has a value, but is not readonly - which would
        // mean that the user has hit the "Back" button.
        if (
          this.$v?.form[k]?.$dirty ||
          (this.form[k] && this.form[k].value && !this.form[k].isReadOnly)
        ) {
          data[k] = this.form[k].value || this.form[k];
        }

        // Backend always expects addressId if address is not readonly
        if (
          this.employeeData.address &&
          !this.employeeData.address.isReadOnly
        ) {
          data.addressId = this.form.addressId;
        }
      });
      return data;
    },

    ready() {
      return !this.$v.$invalid;
    }
  },

  watch: {
    ready(val) {
      this.setComponentValidation(val);
      this.setFormData(this.filteredFormData);
    }
  },

  mounted() {
    if (this.orderId) {
      this.setComponentValidation(false);
      this.setEndpoint(HOME_OFFICE_STEPS.EMPLOYEE_INFORMATION);

      this.fetchEmployeeData(this.orderId).then(() => {
        this.fetchEmployeeData().then(() => {
          this.populateEmployeeDataForm();
        });
      });
    }
  },

  methods: {
    ...mapMutations({
      setComponentValidation:
        CHECKOUT_MUTATIONS.SET_EMPLOYEE_INFORMATION_VALIDATION,
      setFormData: CHECKOUT_MUTATIONS.SET_CHECKOUT_FORM_DATA,
      setEndpoint: CHECKOUT_MUTATIONS.SET_CHECKOUT_ENDPOINT
    }),

    ...mapActions({
      fetchEmployeeData: ACTIONS.FETCH_EMPLOYEE_DATA
    }),

    populateEmployeeDataForm() {
      this.form = {
        ...cloneDeep(this.employeeData)
      };

      if (this.employeeData?.address?.value?.streetName) {
        this.prefilledAddress = this.createAddressStringFromData(
          this.employeeData.address.value
        );

        this.setAddress(this.employeeData.address.value);
      }
    },

    setAddress(addressData) {
      const address = this.unifiedAddressObject(addressData);
      this.form.address = this.createAddressStringFromData(addressData);
      this.form.addressId = address.addressId;
      Vue.set(this.form, 'street', address.streetName);
      Vue.set(this.form, 'houseNumber', address.houseNumber);
      Vue.set(this.form, 'houseLetter', address.houseLetter);
      Vue.set(this.form, 'floor', address.floor);
      Vue.set(this.form, 'apartment', address.door);
      Vue.set(this.form, 'zipCode', address.postalCode);
      Vue.set(this.form, 'city', address.city);

      this.setFormData(this.filteredFormData);
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.c-employee-contact-info {
  &__contact {
    &__headline,
    &__description {
      margin-bottom: $u-300;
    }
  }

  &__disclaimer {
    [class^='c-typography-h'] {
      font-size: $font-size-h3;
      margin-bottom: $u-150;
    }

    p {
      font-size: $font-size-h5;
      margin-bottom: $u-250;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
