<template>
  <div :class="modifiers">
    <label
      v-if="useArrowIcon"
      :for="id"
      class="cm-c-form-control__select__label"
    >
      {{ labelText }}
      <c-icon
        class="cm-c-form-control__select__label__icon"
        symbol-id="arrow-down"
      />
    </label>
    <select
      :id="id"
      v-model="localValue"
      class="c-form-select"
      :name="name"
      :disabled="disabled"
    >
      <option value="">
        {{ placeholder }}
      </option>
      <!-- Todo: Make sure that BE always supply frontend with options as objects and not strings -->
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="typeof option === 'object' ? option.value : option"
        :disabled="typeof option === 'object' && option.disabled"
      >
        {{ typeof option === 'object' ? option.text : option }}
      </option>
    </select>
  </div>
</template>

<script>
import FormInputMixin from '../../mixins/form-input-mixin.js';

export default {
  name: 'c-form-select',

  mixins: [FormInputMixin],

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    options: {
      type: Array,
      default: () => []
    },
    simple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localValue: this.value
    };
  },

  computed: {
    modifiers() {
      return {
        'cm-c-form-control__select': true,
        'cm-c-form-control__select--simple': this.simple
      };
    },

    labelText() {
      const selectedOption = this.options.find(option =>
        typeof option === 'object'
          ? option.value.toString()
          : option === this.localValue.toString()
      );

      if (selectedOption) {
        return typeof selectedOption === 'object'
          ? selectedOption.text
          : selectedOption;
      }

      return this.placeholder;
    },

    useArrowIcon() {
      return this.simple;
    }
  },

  watch: {
    value(newValue) {
      if (newValue !== this.localValue) {
        this.localValue = newValue;
      }
    },
    localValue(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>

<style lang="scss">
.c-form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}
</style>
