<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getLoyaltyVases } from 'webshop/api/vas-service';
import { safeWrite, safeRead } from 'olympus/utils/storage.js';
import SubscriptionUrlHashMixin from 'webshop/mixins/subscription-url-hash-mixin';
import AttributeSelector from 'webshop/components/product/attribute-selector.vue';
import ShoutingFont from 'webshop/components/generic/shouting-font.vue';
import ExistingSubscriptionSelector from 'webshop/components/product/existing-subscription-selector.vue';
import SubscriptionPageLoyaltyOverlay from 'webshop/components/subscription/subscription-page-loyalty-overlay.vue';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
import { ACTIONS as BASKET_ACTIONS } from 'webshop/store/basket';
import {
  default as pageModule,
  MUTATIONS as PAGE_MUTATIONS,
  ACTIONS as PAGE_ACTIONS,
  GETTERS as PAGE_GETTERS
} from 'webshop/store/subscription-page';
import {
  GETTERS as NUMBER_GETTERS,
  ACTIONS as NUMBER_ACTIONS
} from 'webshop/store/number-select/index.js';

import { ACTIONS as SHOP_ACTIONS } from 'webshop/store/shop/mutation-types.js';
import { GETTERS as CREDIT_CHECK_GETTERS } from 'webshop/store/credit-check/index.js';
import StoreRegisterMixin from '../../mixins/store-register-mixin.js';

const NUMBER_MODAL_LOCAL_STORAGE_KEY = 'show-number-modal';
const STORE_MODULE_NAME = 'subscriptionPage';

/**
 * Subscription page wrapper.
 * Top level component for the subscription page, handles
 * state required to show & configure subscriptions. Initial
 * variant is bootstrapped as a property.
 */

export default {
  name: 'SubscriptionPage',

  components: {
    AttributeSelector,
    ShoutingFont,
    ExistingSubscriptionSelector,
    SubscriptionPageLoyaltyOverlay
  },

  mixins: [StoreRegisterMixin, SubscriptionUrlHashMixin],

  props: {
    subscription: {
      type: Object,
      required: true
    },
    variants: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => ({})
    },
    membersQuantity: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      init: false,
      selectedBindingPeriodCode: null,
      bindingPeriodAttributes: this.variants.map(({ key, value }) => {
        return {
          code: key,
          name: this.getBindingPeriodName(value)
        };
      }),
      loggedIn: window.__APP__?.user.isLoggedIn,
      showLoyaltyVasModal: false,
      loyaltyVases: []
    };
  },

  computed: {
    ...mapGetters({
      getVariantByCode: PAGE_GETTERS.GET_VARIANT_BY_CODE,
      creditCheckDetails: CREDIT_CHECK_GETTERS.GET_CREDIT_CHECK_DETAILS,
      selectedPhoneNumber: NUMBER_GETTERS.GET_SELECTED_PHONE_NUMBER
    }),

    ...mapState({
      contextId: state => state.shop.global.contextId
    }),

    hasReference() {
      return !!this.subscription.code;
    },

    selected() {
      if (!this.hasReference) {
        return this.subscription;
      }

      const variant = this.variants.find(
        x => x.value === this.selectedBindingPeriodCode
      );

      const code = variant?.key || this.subscription.code;

      return this.getVariantByCode(code);
    },

    selectedBindingPeriodName() {
      if (!this.hasReference) {
        return null;
      }
      return this.getBindingPeriodName(this.selectedBindingPeriodCode);
    },

    creditCheckActive() {
      return (
        this.creditCheckDetails &&
        this.creditCheckDetails.cpr !== '' &&
        this.creditCheckDetails.cpr !== null
      );
    },

    showExistingSubscriptionSelector() {
      return this.loggedIn && this.creditCheckActive;
    }
  },

  created() {
    this.registerStoreModule(STORE_MODULE_NAME, pageModule);
    if (this.hasReference) {
      this.$store.commit(PAGE_MUTATIONS.SET_VARIANT, {
        data: this.subscription
      });

      this.setDefaultVariant();

      const variant = this.variants.find(
        x => x.value === this.selectedBindingPeriodCode
      );

      /**
       * NOTE: temp solution for mismatch between bootstrap
       * and current hash.
       */
      if (variant && variant.key !== this.subscription.code) {
        this.fetchVariant({
          code: variant.key,
          contentId: this.content.id
        }).then(() => {
          this.init = true;
        });
      } else {
        this.init = true;
      }
    } else {
      this.init = true;
    }
  },

  async mounted() {
    this.pushEecObjectToGtm(true);

    // fetches loyaltyVases for current chosen subscription
    this.loyaltyVases = await getLoyaltyVases(this.subscription.code);

    // sorts loyaltyVases if a sorting order exists
    if (this.content.sortVasItemCodes) {
      this.sortLoyaltyVases();
    }

    // Mobile subscription page should show a number selection modal upon login.
    // A local storage value is set on beforeunload for logged out users to track this; if a logged in user returns to a product page, the modal will be shown.
    this.checkNumberModalValue();
    this.unsetNumberModalValue();
    window.addEventListener('beforeunload', this.setNumberModalValue);
  },

  beforeDestroy() {
    this.unregisterStoreModule(STORE_MODULE_NAME);
  },

  methods: {
    ...mapActions({
      addSubscription: BASKET_ACTIONS.ADD_LOYALTY_VAS_SUBSCRIPTION,
      fetchVariant: PAGE_ACTIONS.FETCH_VARIANT,
      wait: SHOP_ACTIONS.WAIT,
      showNumberSelectModal: NUMBER_ACTIONS.SHOW_NUMBER_MODAL
    }),

    // sorts loyaltyVases based on the product codes in the sorting
    // order provided in bootstraped page view model.
    sortLoyaltyVases() {
      const sortingArr = this.content.sortVasItemCodes;
      this.loyaltyVases.sort(
        (a, b) => sortingArr.indexOf(a.code) - sortingArr.indexOf(b.code)
      );
    },

    getBindingPeriodName(value) {
      const { bindingPeriodFilterText, bindingPeriodZeroText } = this.content;

      if (bindingPeriodZeroText && value === 0) {
        return bindingPeriodZeroText;
      }

      return bindingPeriodFilterText
        ? populateContentProperty(bindingPeriodFilterText, {
            bindingPeriod: value
          })
        : value;
    },

    onBindingPeriodSelected({ code }) {
      const variant = this.variants.find(x => x.key === code);
      if (!variant) {
        return;
      }

      this.wait({
        promise: this.fetchVariant({
          code: variant.key,
          contentId: this.content.id,
          subscriptionExistingMsisdn: this.selectedPhoneNumber
        }).then(() => {
          this.selectedBindingPeriodCode = variant.value;
          this.setSubscriptionUrlFragment(variant.value);
        }),
        throttleTime: 300
      });
    },

    setDefaultVariant() {
      if (!this.variants.length) {
        return;
      }

      let defaultBindingPeriodCode =
        this.parseSubscriptionUrlFragments()?.bindingPeriodCode;
      if (!defaultBindingPeriodCode) {
        defaultBindingPeriodCode = this.subscription.bindingPeriod;
      }

      //TODO DKT-11545: Investigate what radix should be used instead of 0
      // eslint-disable-next-line radix
      this.selectedBindingPeriodCode = parseInt(defaultBindingPeriodCode, 0);
      this.setSubscriptionUrlFragment(defaultBindingPeriodCode);
    },

    buy() {
      if (!this.hasReference) {
        return;
      }

      this.wait({
        promise: this.addSubscription({
          code: this.selected.code,
          upsale: true,
          continueWithoutLoyaltyVas: !this.content.enableTryAndBuyOffering,
          membersQuantity: this.membersQuantity,
          pageId: this.content.id,
          existingMsisdn: this.selectedPhoneNumber
        }).catch(error => {
          if (error.status === 409) {
            this.showLoyaltyVasModal = true;
          }
        }),
        throttleTime: 300
      });
    },

    // Are we coming from an external link, e.g. Google, or did we find the subscrption subcard by navigating on Norlys
    isExternalList() {
      // TODO DKT-39277 Remove Telia
      return (
        !document.referrer.includes('telia') &&
        !document.referrer.includes('norlys')
      );
    },

    /**
     * Sets a local storage value that will show the number select modal the next a user hits the product page.
     */
    setNumberModalValue() {
      if (!this.showExistingSubscriptionSelector) {
        safeWrite(NUMBER_MODAL_LOCAL_STORAGE_KEY, true, localStorage);
      }
    },

    /**
     * Clears the number select modal local storage value, so it is not shown again until it is set once more.
     */
    unsetNumberModalValue() {
      safeWrite(NUMBER_MODAL_LOCAL_STORAGE_KEY, false, localStorage);
    },

    /**
     * If a local storage value is present, shows the select number modal to logged in users.
     */
    checkNumberModalValue() {
      const shouldShowNumbeModal =
        safeRead(NUMBER_MODAL_LOCAL_STORAGE_KEY, false, localStorage) === true;

      if (this.showExistingSubscriptionSelector && shouldShowNumbeModal) {
        this.showNumberSelectModal();
      }
    },

    /**
     * Push Enhanced E-Commerce object to GTM data layer.
     * param 'onMount' changes the obj sent to GTM when the page is loaded
     * as opposed to when the subscription-card is clicked upon.
     */
    pushEecObjectToGtm(isDetailView) {
      try {
        const isDetailViewkey = isDetailView ? 'detail' : 'add';
        const eventValue = isDetailView ? 'productDetailView' : 'addToCart';
        const actionFieldList = {
          list: this.isExternalList() ? 'search results' : 'category page'
        };

        const product = {
          id: this.selected.code.toLowerCase(),
          name: this.selected.productName.toLowerCase(),
          brand: this.selected.brandName.toLowerCase(),
          category: 'sim',
          price: this.selected.minimumPriceFloatingValue,
          ...(!isDetailView && { quantity: 1 })
        };

        const eecObject = {
          ecommerce: {
            currencyCode: 'DKK',
            [isDetailViewkey]: {
              ...(isDetailView && { actionField: actionFieldList }),
              products: [product]
            }
          },
          addToCartLocation: 'detail page',
          ...(!isDetailView && { addToCartLocation: 'detail page' }),
          event: eventValue
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eecObject);
      } catch {
        // Silently fail adding to GTM
      }
    },

    closeModal() {
      this.showLoyaltyVasModal = false;
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.subscription-page {
  @include mq($screen-md, max) {
    background: $color-white;
    padding: 0 $u-300;
  }

  // .subscription-page__attribute-selector
  &__attribute-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 100%;
    padding: 0 $u-250 $u-850 $u-250;
  }
}
</style>
