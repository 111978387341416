const providerKey = 'BASKET_STEP';

export default {
  provide() {
    const { nextPageButtonText, nextPage } = this.contentLocal;
    return {
      [providerKey]: {
        nextPageButtonText,
        nextPage
      }
    };
  }
};

export { providerKey };
