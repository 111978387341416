<template>
  <component
    :is="computedTag"
    v-bind="computedProps"
    :class="modifiers"
    class="c-btn"
    @click="$emit('click', $event)"
  >
    <span v-if="isClose">Close</span>
    <slot v-else />
    <div class="cm-c-spinner cm-c-spinner--local">
      <span class="sr-only">Loading...</span>
    </div>
  </component>
</template>

<script>
import { setAttr } from '../../utils/dom.js';
import { BUTTON_VARIANTS, BUTTON_TYPES } from './types/c-button.types';

export default {
  name: 'CButton',

  props: {
    // TODO TC-4926: add validation
    // TODO TC-4926: add role button if not button
    tag: {
      type: String,
      default: 'button'
    },
    iconButton: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button',
      validator: val => Object.values(BUTTON_TYPES).indexOf(val) !== -1
    },
    rounded: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: null
    },
    href: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: BUTTON_VARIANTS.PRIMARY,
      validator(val) {
        return Object.keys(BUTTON_VARIANTS).some(
          x => BUTTON_VARIANTS[x] === val
        );
      }
    }
  },

  data() {
    return {
      classVariantType: 'button'
    };
  },

  computed: {
    modifiers() {
      return {
        [`c-btn--${this.variant}`]: !this.reset && !this.isClose,
        'c-btn--reset': this.reset || this.isClose,
        'c-btn--rounded': this.rounded,
        'c-btn--close': this.isClose,
        'c-btn--icon': this.iconButton,
        'c-btn--loading': this.loading
      };
    },

    isClose() {
      return this.type === BUTTON_TYPES.CLOSE;
    },

    computedType() {
      return this.isClose ? BUTTON_TYPES.BUTTON : this.type;
    },

    computedTag() {
      if (this.to) {
        return 'router-link';
      } else if (this.href) {
        return 'a';
      }
      return this.tag;
    },

    computedProps() {
      const props = {};
      if (this.to) {
        props.to = this.to;
      } else if (this.href) {
        props.href = this.href;
      } else {
        props.type = this.computedType;
      }

      return props;
    }
  },

  mounted() {
    // TODO TC-4926: remove 'button' string
    if (this.tag !== 'button') {
      setAttr(this.$el, 'role', 'button');
    }
  }
};
</script>

<style lang="scss"></style>
