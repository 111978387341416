const VARIANTS = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACCEPT: 'accept',
  REJECT: 'reject',
  DISABLED: 'disabled',
  GRAY: 'gray',
  LINK: 'link'
};

export { VARIANTS };
