<script>
import { mapGetters, mapMutations } from 'vuex';

import termsAndConditionsStore, {
  GETTERS as TERMS_AND_CONDITIONS_GETTERS,
  MUTATIONS as TERMS_AND_CONDITIONS_MUTATIONS
} from 'webshop/components/checkout/store';

import CCheckbox from 'olympus/components/c-checkbox/c-checkbox.vue';

const CHECKOUT_TERMS_STORE_NAMESPACE = 'TermsAndConditions';

export default {
  name: 'CheckoutTermsAndConditions',

  components: { CCheckbox },

  props: {
    initHasAcceptedTerms: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isCheckboxChecked: this.hasAcceptedTerms || false
    };
  },

  computed: {
    ...mapGetters({
      hasAcceptedTerms: TERMS_AND_CONDITIONS_GETTERS.IS_VALID_PAGE
    })
  },

  beforeCreate() {
    this.$store.registerModule(
      CHECKOUT_TERMS_STORE_NAMESPACE,
      termsAndConditionsStore
    );
  },

  mounted() {
    this.$store.commit(
      TERMS_AND_CONDITIONS_MUTATIONS.SET_TERM_VALIDATION,
      this.initHasAcceptedTerms
    );
  },

  beforeDestroy() {
    this.$store.unregisterModule(CHECKOUT_TERMS_STORE_NAMESPACE);
  },

  methods: {
    ...mapMutations({
      setTerms: TERMS_AND_CONDITIONS_MUTATIONS.SET_TERM_VALIDATION
    }),

    setTermsAndConditions(id) {
      if (id === 'hasAcceptedTerms') {
        this.isCheckboxChecked = !this.isCheckboxChecked;
        this.setTerms(this.isCheckboxChecked);
      }
    }
  }
};
</script>

<style lang="scss"></style>
