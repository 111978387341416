var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-card-section", class: _vm.classObject },
    [
      _vm.hasBadge
        ? _c(
            "div",
            {
              staticClass:
                "c-card-section__badge c-badge c-badge--secondary c-badge--top-left"
            },
            [_vm._t("badge", [_vm._v(_vm._s(_vm.badgeText))])],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasRibbon
        ? _c(
            "div",
            { staticClass: "c-card-section__ribbon" },
            [
              _c(
                "ribbon-banner",
                {
                  attrs: {
                    "flip-position": "bottom-right",
                    size: "large",
                    theme: "assent"
                  }
                },
                [_vm._t("ribbon", [_vm._v(_vm._s(_vm.ribbonText))])],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }