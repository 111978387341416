<template>
  <section :class="classObject">
    <!-- @slot Content in this slot will be shown at the the top of the product page -->
    <header class="product-page__header">
      <slot name="header"></slot>
    </header>

    <template v-if="selectedVariant">
      <product-wizard
        :product-code="productCode"
        :content="contentLocal"
        :color-attributes="colorAttributes"
        :capacity-attributes="capacityAttributes"
        :installment-attributes="installmentAttributes"
        :binding-period-attributes="bindingPeriodAttributes"
        :subscriptions="subscriptions"
        :existing-subscriptions="existingSubscriptions"
        :selected-variant="selectedVariant"
        :selected-subscription="selectedSubscription"
        :selected-installment="selectedInstallment"
        :selected-binding-period="selectedBindingPeriod"
        :is-logged-in="isLoggedIn"
        :is-handset="isHandset"
        :hide-price-plan-selector="external"
        :disable-installments="disableInstallments"
        :enable-prolonging="enableProlonging"
        @color-selected="onColorSelected"
        @capacity-selected="onCapacitySelected"
        @installment-selected="onInstallmentSelected"
        @price-plan-selected="onPricePlanSelected"
        @binding-period-selected="onBindingPeriodSelected"
      >
        <template slot="header">
          <parallax-wrapper
            :direction="-1"
            axis="y"
            :speed="0.3"
            class="product-page__pebble-bg-wrapper"
          >
            <span class="product-page__pebble-bg"></span>
          </parallax-wrapper>
        </template>

        <template slot="attribute-top-area">
          <product-details
            :variant-summary="selectedVariantSummary"
          ></product-details>

          <countdown
            v-if="
              selectedVariant.countdownTimer &&
              selectedVariant.countdownTimer.isEnabled
            "
            :data="selectedVariant.countdownTimer"
            class="product-page__countdown"
          ></countdown>

          <product-promotion-block
            v-if="productPromotion && productPromotion.enabled"
            :expire-date-time-label="productPromotion.expireDateTimeLabel"
            :expired-label="productPromotion.expiredLabel"
            :image-alt="productPromotion.imageAreaLabel"
            :image-url="productPromotion.imageSrc"
            :heading="productPromotion.heading"
            :price-text="productPromotion.priceText"
            :end-time="productPromotion.endTime"
            :show-counter="productPromotion.showCounter"
            class="product-page__promotion"
          >
            <div v-html="productPromotion.description"></div>
          </product-promotion-block>

          <product-login-prompt
            v-if="
              initialVariantLoaded &&
              !contentLocal.disableLoginButton &&
              !isLoggedIn &&
              !contentLocal.loginButtonHidden &&
              !isLoggedIn &&
              !external
            "
            :content="contentLocal"
            :login-url="loginUrl"
          ></product-login-prompt>

          <product-footer
            :content="contentLocal"
            :is-accessory="isAccessory"
            @cta-pressed="addToBasket(false)"
            @vas-selected="getVariant()"
            @vas-deselected="getVariant()"
          >
            <!-- Hack: the content in the default slot is ignored for themes that do not show a shop-footer. This is done to prevent splitting the entire template of the product page, but there might be a more elegant way to achieve the same. -->
            <shop-footer
              ref="shopFooter"
              :detailed="true"
              :in-overlay="external"
            >
              <ribbon-banner
                v-if="hasDiscount"
                slot="ribbon"
                flip-position="top-left"
                size="large"
                theme="discount"
              >
                {{ contentLocal.discountPriceHeading }}
                {{
                  selectedVariant.price.productDiscountedPrice
                    .formattedValueCurrency
                }}
              </ribbon-banner>

              <div v-if="selectedVariant.price" slot="columns" class="cm-grid">
                <div
                  v-for="(column, index) in shopFooterColumns"
                  :key="index"
                  class="cm-grid__col--xs cm-grid__col--md-4"
                  :class="index > 0 && 'has-divider'"
                >
                  <div class="shop-footer__column">
                    <p class="shop-footer__item-label">
                      {{ column.label }}
                    </p>

                    <strong class="shop-footer__item-value">
                      {{ column.value }}
                    </strong>
                  </div>
                </div>
              </div>

              <div
                ref="shopFooterCtaContainer"
                slot="cta"
                class="shop-footer__cta-container product-page__footer-cta"
              >
                <template v-if="derivedStatus === STOCK_STATUSES.NOTIFY">
                  <!-- @slot Holds markup to submit details in order to be notified of a status change. -->
                  <slot name="product-notify-form"></slot>
                </template>

                <button
                  v-else-if="shopFooterCta"
                  class="shop-footer__cta c-btn c-btn--wide"
                  :class="shopFooterCta.btnClass"
                  :disabled="!selectedVariant.inventoryStatus.isAvailable"
                  @click="addToBasket(false)"
                >
                  <span class="c-btn__column">
                    <c-icon
                      v-if="shopFooterCta && shopFooterCta.iconType"
                      class="c-btn--icon"
                      :class="shopFooterCta.iconClass"
                      :symbol-id="shopFooterCta.iconType"
                    ></c-icon>

                    {{ shopFooterCta.label }}
                  </span>
                </button>

                <product-stock-status
                  v-if="selectedVariant.inventoryStatus"
                  :status="derivedStatus"
                  :status-text="selectedVariant.inventoryStatus.description"
                  :countdown-date="
                    selectedVariant.inventoryStatus.sameDayShipmentTime
                  "
                >
                  <small
                    v-if="
                      selectedVariant.price &&
                      selectedVariant.price.minimumPrice
                    "
                    slot="footer"
                    :class="minimumPriceModifiers"
                    @click="onMinimumPricePressed"
                  >
                    {{ contentLocal.minimumPriceText }}
                    {{
                      selectedVariant.price.minimumPrice.formattedValueCurrency
                    }}
                  </small>
                </product-stock-status>

                <span
                  v-if="prices && prices.productPrice.disclaimerText"
                  class="product-page__price-disclaimer"
                >
                  {{ prices.productPrice.disclaimerText }}
                </span>
              </div>
            </shop-footer>
          </product-footer>
        </template>

        <template slot="attribute-bottom-area">
          <slot name="attribute-bottom-area"></slot>
        </template>

        <product-gallery
          v-if="initialVariantLoaded"
          slot="gallery-area"
          :product-name="productName"
          :content="galleryContent"
          :image-urls="selectedVariant.imageUrls"
          class="product-page__gallery"
        >
          <template slot="footer">
            <product-stock-status
              v-if="selectedVariant.inventoryStatus"
              :status="derivedStatus"
              :status-text="selectedVariant.inventoryStatus.name"
            ></product-stock-status>
            <store-stock-information
              v-if="
                selectedVariant.inventoryStatus.inventoryStatusInStoresEnabled
              "
              :selected-variant="selectedVariant"
            ></store-stock-information>
          </template>

          <product-splash
            v-if="
              selectedVariant.badgeText.item1 &&
              selectedVariant.badgeText.item2 &&
              $resize &&
              $mq.above(800) &&
              selectedVariant.badgeText
            "
            slot="splash"
            class="cm-c-product-image__splash"
            :splash-text="selectedVariant.badgeText.item2"
            :type="selectedVariant.badgeText.item1"
            size="lg"
          >
          </product-splash>
        </product-gallery>

        <template slot="footer">
          <!-- @slot Content in this slot will be rendered in the product wizard footer -->
          <slot name="wizard-footer"></slot>
        </template>
      </product-wizard>

      <section class="cm-o-layout cm-o-layout--bg-white product-page__tabs">
        <product-tabs
          v-if="selectedVariant"
          :is-accessory="isAccessory"
          :marketing-data="marketingData"
          :specifications="selectedVariant.groupedSpecifications"
          :image-urls="selectedVariant.imageUrls"
          :variant-summary="selectedVariantSummary"
          :external="external"
        >
          <template slot="marketing-alternative-content">
            <slot name="marketing-alternative-content"></slot>
          </template>
        </product-tabs>
      </section>

      <section
        v-if="productRelations.length > 0"
        class="cm-o-layout cm-o-layout__link-cloud"
      >
        <attribute-selector
          :title="contentLocal.productCategoriesBlockHeader"
          :attributes="productRelations"
          link-cloud
        />
      </section>
    </template>

    <c-modal ref="installmentsModal" centered>
      <template v-if="installmentsMismatchError">
        <c-heading slot="header" class="text-center px-3" level="2" as="1">
          {{ installmentsMismatchError.title }}
        </c-heading>
        <div>
          <c-text
            :content="installmentsMismatchError.description"
            class="mb-3"
          ></c-text>

          <c-heading level="4" as="3">
            {{ installmentsMismatchError.currentProductText }}
          </c-heading>

          <s-product-item
            v-bind="selectedBasketProduct"
            class="px-2"
          ></s-product-item>

          <c-box>
            <c-text>
              <strong>{{ installmentsMismatchError.suggestedText }}</strong>
            </c-text>
            <c-row v-if="installmentMatchSuggest">
              <c-col cols="8">
                {{ installmentMatchSuggest.name }}
              </c-col>

              <c-col cols="4" class="text-right">
                {{ installmentMatchSuggest.priceText }}
              </c-col>
            </c-row>
          </c-box>
        </div>

        <template slot="footer">
          <button
            type="button"
            class="c-btn c-btn--primary"
            @click.prevent="resolveInstallmentMismatch(true)"
          >
            {{ installmentsMismatchError.acceptButtonText }}
          </button>

          <button
            type="button"
            class="c-btn c-btn--as-link"
            @click.prevent="resolveInstallmentMismatch(false)"
          >
            {{ installmentsMismatchError.rejectButtonText }}
          </button>
        </template>
      </template>
    </c-modal>

    <c-modal
      v-if="restrictedBanModalContent"
      ref="banRestrictionModal"
      :title="restrictedBanModalContent.heading"
    >
      <c-text
        :content="restrictedBanModalContent.bodyText"
        class="mb-3"
      ></c-text>

      <template slot="footer">
        <button
          type="button"
          class="c-btn c-btn--primary"
          @click.prevent="hideRestrictionModal"
        >
          {{ restrictedBanModalContent.cancelButtonLabel }}
        </button>

        <button
          type="button"
          class="c-btn c-btn--as-link"
          @click.prevent="addToBasket(true)"
        >
          {{ restrictedBanModalContent.acceptButtonLabel }}
        </button>
      </template>
    </c-modal>
  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { isNumber } from 'lodash-es';
import { safeWrite, safeRead } from 'olympus/utils/storage.js';
import { PRODUCT_TYPES } from 'webshop/enums/product-types';
import { PRODUCT_TABS } from 'webshop/enums/product-tabs';
import STOCK_STATUSES from 'webshop/enums/stock-statuses.js';
import { GETTERS } from 'webshop/store/store';
import { ACTIONS } from 'webshop/store/shop/mutation-types';
import {
  GETTERS as NUMBER_SELECT_GETTERS,
  ACTIONS as NUMBER_SELECT_ACTIONS
} from 'webshop/store/number-select';
import productPageStore, {
  GETTERS as PRODUCT_PAGE_GETTERS,
  MUTATIONS as PRODUCT_PAGE_MUTATIONS,
  ACTIONS as PRODUCT_PAGE_ACTIONS
} from 'webshop/components/product/store/product-page';
import {
  ACTIONS as BASKET_ACTIONS,
  GETTERS as BASKET_GETTERS
} from 'webshop/store/basket';

import ProductUrlHashMixin from 'webshop/mixins/product-url-hash-mixin';
import ProductMismatchMixin from './product-mismatch-mixin.js';

import ParallaxWrapper from 'webshop/components/generic/parallax-wrapper.vue';
import AttributeSelector from './attribute-selector.vue';
import ProductDetails from 'webshop/components/product/product-details.vue';
import ProductLoginPrompt from 'webshop/components/product/product-login-prompt.vue';
import ProductGallery from 'webshop/components/product/product-gallery.vue';
import ProductSplash from 'webshop/components/product/product-splash.vue';
import ProductWizard from 'webshop/components/product/product-wizard.vue';
import ProductStockStatus from 'webshop/components/product/product-stock-status.vue';
import ProductPromotionBlock from './product-promotion-block.vue';
import ProductFooter from 'webshop/components/product/product-footer.vue';
import ProductTabs from './product-tabs.vue';
import RibbonBanner from 'webshop/components/ribbon-banner/ribbon-banner.vue';
import Countdown from 'webshop/components/product/private/countdown/countdown.vue';
import StoreStockInformation from 'webshop/components/store-stock-information/store-stock-information.vue';

import { CModal, CHeading, CRow, CCol, CText } from 'olympus/components';
import SProductItem from 'webshop/components/shopping-cart/s-product-item.vue';
import { populateContentProperty } from '../../../../../shared/mixins/content-property-mixin.js';

const DEFAULT_CONTENT = {
  colorHeading: 'Vælg farve',
  capacityHeading: 'Vælg kapacitet',
  loginTeaserText: 'Er du allerede kunde?',
  loginButtonCaption: 'Log ind her',
  subscriptionHeading: 'Vælg abonnement',
  existingSubscriptionHeading: 'Behold dit nuværende abonnement',
  showSubscriptionDetailsText: 'Læs mere',
  hideSubscriptionDetailsText: 'Luk',
  showExistingSubscriptionsText:
    'Klik her, hvis du vil beholde dit nuværende abonnement',
  showNewSubscriptionsText: 'Klik her, hvis du vil købe et nyt abonnement',
  existingSubscriptionNote:
    'Bemærk: Hvis du i sin tid købte dit abonnement til en anden pris end den viste, skal du naturligvis fortsat betale din vante pris.',
  specificationsHeading: 'Specifikationer',
  productDetailsHeading: 'Beskrivelse',
  productTermsTabLabel: 'Priser & betingelser',
  minimumPriceText: 'Mindstepris ',
  basePriceLabel: 'Mobil uden abonnement',
  discountPriceLabel: 'Mobilrabat',
  discountPriceHeading: 'Mobilrabat',
  productPriceLabel: 'Mobil med abonnement',
  valueAddedServicesHeading: 'Få billigere services',
  selectedSubscriptionPriceLabel: 'Abonnement',
  payNowPriceText: 'Betal nu',
  monthlyPriceText: 'Månedlig pris',
  oneTimePriceText: 'Pris',
  showDetailsText: 'Vis detalje',
  hideDetailsText: 'Luk',
  buyButtonText: 'Læg i kurv',
  bindingPeriodFilterHeading: 'Bindingsperiode på abonnement',
  shippingFromText: 'Fragt fra'
};

const NUMBER_MODAL_LOCAL_STORAGE_KEY = 'show-number-modal';
const PRODUCT_PAGE_STORE_NAMESPACE = 'productPage';

/**
 * Product page wrapper.
 * Top level component for the product page, handles API calls and state management required to show & configure products.
 */
export default {
  name: 'ProductPage',

  components: {
    ParallaxWrapper,
    AttributeSelector,
    ProductDetails,
    ProductLoginPrompt,
    ProductGallery,
    ProductSplash,
    ProductWizard,
    ProductStockStatus,
    ProductPromotionBlock,
    ProductFooter,
    ProductTabs,
    RibbonBanner,
    Countdown,
    CModal,
    CHeading,
    CRow,
    CCol,
    CText,
    SProductItem,
    StoreStockInformation
  },

  mixins: [ProductUrlHashMixin, ProductMismatchMixin],

  props: {
    productCode: {
      type: String,
      default: ''
    },

    productBrand: {
      type: String,
      default: ''
    },

    productName: {
      type: String,
      default: ''
    },

    productModel: {
      type: String,
      default: ''
    },

    productDescription: {
      type: String,
      default: ''
    },

    productEquipmentType: {
      type: String,
      default: ''
    },

    productDescriptionLinkText: {
      type: String,
      default: ''
    },

    /**
     * Optional product promotion properties.
     *
     * @example
     * {
     *   enabled: boolean,
     *   expireDateTimeLabel: string,
     *   expiredLabel: string,
     *   imageAreaLabel: string,
     *   imageSrc: string,
     *   heading: string,
     *   priceText: string, // HTML string
     *   description: string, // HTML string
     *   endTime: date
     * }
     */
    productPromotion: {
      type: Object,
      default: undefined
    },

    /**
     * CMS content (i.e. labels) needed for the product page.
     */
    content: {
      type: Object,
      default: () => DEFAULT_CONTENT
    },

    /**
     * CMS content (i.e. labels) needed for the product gallery.
     */
    galleryContent: {
      type: Object,
      default: () => ({})
    },

    /**
     * CMS content for the restricted ban modal.
     */
    restrictedBanModalContent: {
      type: Object,
      default: () => ({})
    },

    /**
     * Optional product marketing data.
     */
    marketingData: {
      type: Object,
      default: undefined
    },

    /**
     * Optional product specifications.
     */
    specifications: {
      type: [Array, null],
      default: () => []
    },

    /**
     * Default selected variant, initialized by the server.
     *
     * @example
     * {
     *   code: string,
     *   type: 'Accessory' | 'Handset',
     *   selectedSubscription: string, // code
     *   selectedInstallment: string, // code
     *   capacity: string,
     *   color: string,
     *   installment: { data: Array<Installment{}>, ... },
     *   subscriptions: Array<Subscription>,
     *   inventoryStatus: { name: string, status: string, ... },
     *   countdownTimer: { isEnabled: boolean, startDateTime: date, endDateTime: date, ... },
     *   ribbonText: string,
     *   [key: string]: string | number | object
     * }
     */
    defaultVariant: {
      type: Object,
      default: () => ({})
    },

    /**
     * A flat list of variant attributes.
     *
     * @example
     * Array<{
     *   type: string,
     *   code: string,
     *   [key: string]: string | number
     * }>
     */
    defaultVariantAttributes: {
      type: Array,
      default: () => []
    },

    /**
     * Existing subscriptions for logged-in users.
     *
     * @example
     * Array<Subscription{}>
     */
    existingSubscriptions: {
      type: Array,
      default: () => []
    },

    paymentCode: {
      type: String,
      default: undefined
    },

    /**
     * Used in add-hardware-overlay to disable installments when basket already contains financed items
     */
    disableInstallments: {
      type: Boolean,
      default: false
    },

    /**
     * Used in add-hardware-overlay to hide <price-plan-selector />, since this is not needed when adding hardware to subscription.
     */
    external: {
      type: Boolean,
      default: false
    },

    /**
     * Used to enable disable number prolonging inside PDP
     */
    enableProlonging: {
      type: Boolean,
      default: true
    },

    brand: {
      type: String,
      default: undefined
    },

    shippingPrice: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      initialVariantLoaded: false,
      installmentsMismatchError: null,
      STOCK_STATUSES
    };
  },

  computed: {
    ...mapGetters({
      loginUrl: GETTERS.LOGIN_URL,
      isLoggedIn: GETTERS.USER_AUTHENTICATED,
      selectedPhoneNumber: NUMBER_SELECT_GETTERS.GET_SELECTED_PHONE_NUMBER,
      contentLocal: PRODUCT_PAGE_GETTERS.GET_CONTENT_LOCAL,
      variantAttributes: PRODUCT_PAGE_GETTERS.GET_VARIANT_ATTRIBUTES,
      combinedVariantAttributes:
        PRODUCT_PAGE_GETTERS.GET_COMBINED_VARIANT_ATTRIBUTES,
      colorAttributes: PRODUCT_PAGE_GETTERS.GET_COLOR_ATTRIBUTES,
      capacityAttributes: PRODUCT_PAGE_GETTERS.GET_CAPACITY_ATTRIBUTES,
      installmentAttributes: PRODUCT_PAGE_GETTERS.GET_INSTALLMENT_ATTRIBUTES,
      bindingPeriodAttributes:
        PRODUCT_PAGE_GETTERS.GET_BINDING_PERIOD_ATTRIBUTES,
      selectedVariant: PRODUCT_PAGE_GETTERS.GET_SELECTED_VARIANT,
      selectedSubscription: PRODUCT_PAGE_GETTERS.GET_SELECTED_SUBSCRIPTION,
      selectedInstallment: PRODUCT_PAGE_GETTERS.GET_SELECTED_INSTALLMENT,
      selectedBindingPeriod: PRODUCT_PAGE_GETTERS.GET_SELECTED_BINDING_PERIOD,
      selectedVasCodes: PRODUCT_PAGE_GETTERS.GET_SELECTED_VAS_CODES,
      subscriptions: PRODUCT_PAGE_GETTERS.GET_FILTERED_SUBSCRIPTIONS,
      prices: PRODUCT_PAGE_GETTERS.GET_PRICES,
      hasInstallments: PRODUCT_PAGE_GETTERS.HAS_INSTALLMENTS,
      relatedGroups: BASKET_GETTERS.GROUPS,
      preselectedInstallmentCode: BASKET_GETTERS.INSTALLMENTS,
      productRelations: PRODUCT_PAGE_GETTERS.GET_PRODUCT_RELATIONS
    }),

    classObject() {
      return {
        'product-page': true,
        'product-page--in-overlay': this.external
      };
    },

    minimumPriceModifiers() {
      return {
        'shop-footer__minimum-price': true,
        'shop-footer__minimum-price--clickable': this.brand !== 'Call me'
      };
    },

    isAccessory() {
      return (
        this.selectedVariant?.itemEquipmentType ===
        PRODUCT_TYPES.ACCESSORY.toUpperCase()
      );
    },

    isHandset() {
      return this.productEquipmentType === PRODUCT_TYPES.HANDSET;
    },

    installmentPriceTextFromString() {
      const splitString = this.selectedInstallment?.priceText?.split(/ (.+)/);
      splitString[0] = parseFloat(
        splitString[0].replace(/\./g, '').replace(/,/g, '.')
      );

      return splitString;
    },

    hasDiscount() {
      const discountedPrice =
        this.selectedVariant?.price?.productDiscountedPrice;

      return discountedPrice && discountedPrice.value !== 0;
    },

    /**
     * Status based on original status and business logic regarding notify & isAvailable flags.
     *
     * @return { string }
     */
    derivedStatus() {
      const inventoryStatus = this.selectedVariant?.inventoryStatus;
      let status = STOCK_STATUSES.OUT_OF_STOCK;

      if (inventoryStatus?.status === STOCK_STATUSES.OUT_OF_STOCK) {
        if (inventoryStatus.notify === true) {
          status = STOCK_STATUSES.NOTIFY;
        } else if (inventoryStatus.isAvailable) {
          status = STOCK_STATUSES.BACK_ORDER;
        }
      } else {
        status = inventoryStatus?.status;
      }

      return status;
    },

    /**
     * Text columns to be rendered in the shop footer.
     *
     * @returns Array<{ label: string, value: string }>
     */
    shopFooterColumns() {
      let columns = [];

      if (this.selectedSubscription) {
        columns.push({
          label: this.contentLocal.selectedSubscriptionPriceLabel,
          value:
            this.selectedSubscription.price.productBasePrice
              ?.formattedValueCurrency
        });
      }

      if (this.hasInstallments) {
        columns.push({
          label: this.contentLocal.financeMonthlyColumnLabel
            ? populateContentProperty(
                this.contentLocal.financeMonthlyColumnLabel,
                {
                  installment: this.selectedInstallment?.code
                }
              )
            : '',
          value: this.prices?.financeMonthlyPrice.formattedValueCurrency
        });
      } else {
        columns.push({
          label: this.contentLocal.financeColumnLabel,
          value: this.prices?.financeFullPrice.formattedValueCurrency
        });
      }

      columns.push({
        label: this.contentLocal.shippingFromText,
        value: this.shippingPrice
      });

      return columns;
    },

    /**
     * CTA button to be used in the shop footer.
     *
     * @returns Object{
     *   iconType: string,
     *   iconClass?: string,
     *   btnClass: string,
     *   label: string,
     *   price: string,
     *   monthlyPriceLabel: string,
     *   countUpOptions: ICountUpOptions
     * }
     */
    shopFooterCta() {
      if (!this.selectedVariant) {
        return null;
      }

      if (this.isAccessory) {
        if (!this.selectedVariant.inventoryStatus) {
          return null;
        }
      } else {
        if (
          !this.selectedVariant.price ||
          !this.selectedVariant.price.monthlyPrice ||
          !this.derivedStatus
        ) {
          return null;
        }
      }

      const statusSettings = {
        [STOCK_STATUSES.IN_STOCK]: {
          iconType: 'menu-buy',
          btnClass: 'c-btn--transactional'
        },
        [STOCK_STATUSES.BACK_ORDER]: {
          iconType: 'menu-buy',
          btnClass: 'c-btn--transactional'
        },
        [STOCK_STATUSES.PRE_ORDER]: {
          iconType: 'menu-buy',
          iconClass: 'c-btn--bordered',
          btnClass: 'c-btn--transactional'
        },
        [STOCK_STATUSES.NOTIFY]: {
          iconType: 'contact-email',
          btnClass: 'c-btn--light'
        },
        [STOCK_STATUSES.OUT_OF_STOCK]: {
          iconType: '',
          btnClass: 'c-btn--disabled'
        }
      };

      const selectedPrice =
        this.isAccessory && this.selectedInstallment
          ? this.installmentPriceTextFromString[0]
          : this.selectedVariant.price.bundleMonthlyPrice?.value;

      const countUpOptions = {
        duration: 0.5,
        decimalPlaces: selectedPrice - Math.floor(selectedPrice) !== 0 ? 2 : 0,
        separator: '.',
        decimal: ','
      };

      this.$globals.eventBus.$emit('shopFooterChanged');

      return {
        iconType: statusSettings[this.derivedStatus]?.iconType,
        iconClass: statusSettings[this.derivedStatus]?.iconClass,
        btnClass: statusSettings[this.derivedStatus]?.btnClass,
        label: this.selectedVariant.inventoryStatus.ctaLabel,
        price: selectedPrice,
        monthlyPriceLabel:
          this.isAccessory && this.selectedInstallment
            ? this.installmentPriceTextFromString[1]
            : this.selectedVariant.price.monthlyPrice?.label,
        countUpOptions: countUpOptions
      };
    },

    /**
     * A summary of the selected variant color & capacity
     *
     * @returns { string } text summary
     */
    selectedVariantSummary() {
      if (!this.selectedVariant) {
        return '';
      }

      const { color, capacity } = this.selectedVariant;

      if (isNumber(capacity) && capacity !== 0) {
        const dataUnit = this.getCapacityDataUnit(capacity);
        return `${capacity} ${dataUnit}, ${color}`;
      }

      return color;
    },

    preselectedInstallment() {
      return this.installmentAttributes.find(installment => {
        return (
          parseInt(installment.code, 10) === this.preselectedInstallmentCode
        );
      });
    }
  },

  watch: {
    shopFooterCta(newData, oldData) {
      if (!this.$refs.shopFooterCtaContainer) {
        return;
      }
      if (
        !newData ||
        !oldData ||
        !this.selectedVariant?.inventoryStatus?.isAvailable
      ) {
        return;
      }
      if (newData.price === oldData.price) {
        return;
      }

      const onCtaAnimationEnd = () => {
        this.$refs.shopFooterCtaContainer.classList.remove('animate-bounce');
        this.$refs.shopFooterCtaContainer.removeEventListener(
          'animationend',
          onCtaAnimationEnd
        );
      };
      this.$refs.shopFooterCtaContainer.addEventListener(
        'animationend',
        onCtaAnimationEnd
      );
      this.$refs.shopFooterCtaContainer.classList.add('animate-bounce');
    },
    selectedPhoneNumber() {
      this.getVariant();
    }
  },

  beforeCreate() {
    this.$store.registerModule(PRODUCT_PAGE_STORE_NAMESPACE, productPageStore);
  },
  beforeDestroy() {
    this.$store.unregisterModule(PRODUCT_PAGE_STORE_NAMESPACE);
  },

  async mounted() {
    const firstSubscriptionBindingCode = this.defaultVariant.subscriptions
      ? this.defaultVariant.subscriptions[0]?.bindingPeriod
      : null;
    const selectedSubscriptionBindingCode = this.defaultVariant.subscriptions
      ? this.defaultVariant.subscriptions.find(
          s => s.code === this.defaultVariant.selectedSubscription
        )?.bindingPeriod
      : null;
    const defaultBindingPeriodCode =
      this.parseProductUrlFragments()?.bindingPeriodCode ||
      selectedSubscriptionBindingCode ||
      firstSubscriptionBindingCode;

    // fetch related products for chosen variant
    this.fetchProductRelations({
      code: this.defaultVariant?.code
    });

    // The default variant is assigned to the selected variant initially
    // allowing generic product content to render quickly.
    // Non-generic product content (images etc.) should be guarded by `initialVariantLoaded`,
    // which will only be set after the selected variant has been fetched.
    this.setSelectedVariant({
      ...this.defaultVariant,
      bindingPeriodCode: defaultBindingPeriodCode
    });
    this.setVariantAttributes(this.defaultVariantAttributes);
    this.setProduct({
      code: this.productCode,
      model: this.productModel,
      brand: this.productBrand,
      name: this.productName,
      description: this.productDescription,
      descriptionLinkText: this.productDescriptionLinkText
    });
    this.setExistingSubscriptions(this.existingSubscriptions);

    if (!this.external) {
      await this.getVariantFromUrlFragment();
    }

    if (this.external && this.disableInstallments) {
      this.onInstallmentSelected({ code: this.preselectedInstallmentCode });
    }

    this.initialVariantLoaded = true;

    this.pushEecObjectToGtm('detail');

    this.setContent({
      content: this.content,
      fallbackContent: DEFAULT_CONTENT
    });

    // Product pages should show a number selection modal upon login.
    // A local storage value is set on beforeunload for logged out users to track this; if a logged in user returns to a product page, the modal will be shown.
    this.checkNumberModalValue();
    this.unsetNumberModalValue();
    window.addEventListener('beforeunload', this.setNumberModalValue);
  },

  methods: {
    ...mapMutations({
      setContent: PRODUCT_PAGE_MUTATIONS.SET_CONTENT_LOCAL,
      setProduct: PRODUCT_PAGE_MUTATIONS.SET_PRODUCT,
      setExistingSubscriptions:
        PRODUCT_PAGE_MUTATIONS.SET_EXISTING_SUBSCRIPTIONS,
      setSelectedVariant: PRODUCT_PAGE_MUTATIONS.SET_SELECTED_VARIANT,
      setVariantAttributes: PRODUCT_PAGE_MUTATIONS.SET_VARIANT_ATTRIBUTES,
      setProductRelations: PRODUCT_PAGE_MUTATIONS.SET_PRODUCT_RELATIONS
    }),

    ...mapActions({
      addHandset: BASKET_ACTIONS.ADD_HANDSET,
      addAccessory: BASKET_ACTIONS.ADD_ACCESSORY,
      showNumberSelectModal: NUMBER_SELECT_ACTIONS.SHOW_NUMBER_MODAL,
      setActiveTab: PRODUCT_PAGE_ACTIONS.SET_ACTIVE_TAB,
      fetchVariant: PRODUCT_PAGE_ACTIONS.FETCH_VARIANT,
      fetchProductRelations: PRODUCT_PAGE_ACTIONS.FETCH_PRODUCT_RELATIONS,
      wait: ACTIONS.WAIT
    }),

    onCapacitySelected({ name }) {
      this.setSelectedVariant({ ...this.selectedVariant, capacity: name });
      return this.getVariant(null, name, null, null);
    },

    onColorSelected({ name }) {
      this.setSelectedVariant({ ...this.selectedVariant, color: name });
      return this.getVariant(name, null, null, null);
    },

    onPricePlanSelected({ code }) {
      this.setSelectedVariant({
        ...this.selectedVariant,
        selectedSubscription: code
      });
      return this.getVariant(null, null, code, null);
    },

    onInstallmentSelected({ code }) {
      this.setSelectedVariant({
        ...this.selectedVariant,
        selectedInstallment: code
      });
      return this.getVariant(null, null, null, code);
    },

    onBindingPeriodSelected({ code }) {
      this.setSelectedVariant({
        ...this.selectedVariant,
        bindingPeriodCode: code
      });

      /*
       We need to call  setSelectedVariant a second time to set selected subscription
       because first time, when changing binding period, the mutations is triggering
       a change in the store and this.subscriptions is changed.
      */
      this.setSelectedVariant({
        ...this.selectedVariant,
        selectedSubscription: this.subscriptions[0].code
      });

      this.setProductUrlFragment(
        this.selectedVariant.color,
        this.selectedVariant.capacity,
        this.selectedVariant.dataUnit,
        this.selectedVariant.selectedInstallment,
        code
      );
      return this.getVariant(null, null, null, null, code);
    },

    onMinimumPricePressed() {
      if (this.brand === 'Call me') {
        return;
      }
      this.setActiveTab({ id: PRODUCT_TABS.PRICES, scrollToTab: true });
    },

    /**
     * @description Parses the url fragment and gets the matching variant,
     * if it's not matching the default variant returned by the server. if
     * we receive disabled installments from BE, and user is trying to reach
     * a page with such installment, we will override the hash segment for it,
     * refs to ensureInstallmentPeriod local func.
     */
    async getVariantFromUrlFragment() {
      const productUrlFragmentInfo = this.parseProductUrlFragments();

      const ensureInstallmentPeriod = code => {
        const { installment } = this.selectedVariant;
        if (code) {
          const result = installment.data.find(x => x.code === code);
          if (result && !result.disabled) {
            return result.code;
          }
        }

        return installment.data.find(x => !x.disabled).code;
      };

      const setFragmentToDefaultVariant = () => {
        const { capacity, color, bindingPeriodCode, selectedInstallment } =
          this.selectedVariant;
        const capacityDataUnit = this.getCapacityDataUnit(capacity);

        this.setProductUrlFragment(
          color,
          capacity,
          capacityDataUnit,
          ensureInstallmentPeriod(selectedInstallment.toString()),
          productUrlFragmentInfo?.bindingPeriodCode || bindingPeriodCode
        );
      };

      if (productUrlFragmentInfo) {
        const {
          color = '',
          capacity,
          paymentPlanCode = '',
          bindingPeriodCode
        } = productUrlFragmentInfo;

        const urlProductIsDefault =
          color?.toLowerCase() === this.selectedVariant.color?.toLowerCase() &&
          capacity === this.selectedVariant.capacity &&
          paymentPlanCode.toString() ===
            this.selectedVariant.selectedInstallment.toString();

        if (
          bindingPeriodCode > 0 &&
          this.selectedSubscription?.bindingPeriod !== bindingPeriodCode
        ) {
          this.onPricePlanSelected({ code: this.subscriptions[0].code });
        }

        if (!urlProductIsDefault) {
          await this.getVariant(
            color,
            capacity,
            this.selectedVariant.selectedSubscription,
            ensureInstallmentPeriod(paymentPlanCode),
            bindingPeriodCode
          );
        } else {
          setFragmentToDefaultVariant();
        }
      } else {
        setFragmentToDefaultVariant();
      }
    },

    /**
     * Gets the data unit for a given capacity if available.
     *
     * @param { number } capacity
     *
     * @return { string }
     */
    getCapacityDataUnit(capacity) {
      const variantForCapacity = this.variantAttributes?.find(
        v => v.capacity === capacity
      );

      return variantForCapacity ? variantForCapacity.dataUnit : '';
    },

    /**
     * Looks up a variant code and gets it from BE.
     *
     * @param { string } [color] optional color attribute
     * @param { string } [capacity] optional capacity attribute
     * @param { string } [subscription] optional subscription code
     * @param { string } [installment] optional installment code
     * @param { string } [bindingPeriodCode] optional binding period code
     *
     * @returns { Promise<Object | null> } variant if found via the provided attributes.
     */
    getVariant(color, capacity, subscription, installment, bindingPeriodCode) {
      const variantSubscriptionCode =
        subscription || this.selectedVariant.selectedSubscription;
      const variantInstallmentCode =
        installment || this.selectedVariant.selectedInstallment;
      const variantBindingPeriodCode =
        bindingPeriodCode || this.selectedVariant.bindingPeriodCode;
      const variantColor = color || this.selectedVariant.color;
      const variantCapacity = capacity || this.selectedVariant.capacity;
      const variantsWithColor = this.combinedVariantAttributes.filter(
        v => v.color?.toLowerCase() === variantColor.toLowerCase()
      );
      const variantWithCapacity = variantsWithColor.find(
        v => v.capacity === variantCapacity
      );

      // If no variant with the existing capacity is found, reset capacity to the 1st variant that matches the color.
      const matchedVariant =
        variantCapacity && variantWithCapacity
          ? variantWithCapacity
          : variantsWithColor[0];

      const promise = this.fetchVariant({
        variantCode: matchedVariant?.code,
        subscriptionCode: variantSubscriptionCode,
        installmentCode: variantInstallmentCode,
        bindingPeriodCode: variantBindingPeriodCode,
        vasCodes: this.selectedVasCodes,
        existingmsisdn: this.selectedPhoneNumber || null
      })
        .then(() => {
          this.setProductUrlFragment(
            matchedVariant.color,
            matchedVariant.capacity,
            matchedVariant.dataUnit,
            variantInstallmentCode,
            variantBindingPeriodCode
          );
        })
        .catch(error => {
          // TODO DKT-1434: show error in UI?
          console.error('Failed to get variant', error);
        });

      return this.wait({
        promise,
        throttleTime: 400
      });
    },

    /**
     * Adds the configured product to basket.
     *
     * @param { boolean } acceptBanRestriction Bypass the ban restriction modal for restricted subscriptions.
     */
    addToBasket(acceptBanRestriction) {
      if (this.selectedSubscription?.isRestricted && !acceptBanRestriction) {
        this.$refs.banRestrictionModal.show();
        return;
      }

      if (this.external) {
        this.$emit('submit', {
          productCode: this.productCode,
          variantCode: this.selectedVariant.code,
          paymentCode: this.selectedInstallment.code
        });
        return;
      }

      this.pushEecObjectToGtm('add');

      let promise;

      if (this.isAccessory) {
        const payload = {
          code: this.selectedVariant.code,
          groupId: null,
          quantity: 1,
          installmentType: this.selectedInstallment?.code || 1,
          upsale: true
        };
        promise = this.addAccessory(payload);
      } else {
        promise = this.addHandset({
          handsetCode: this.selectedVariant.code,
          installments: this.selectedInstallment?.code || 1,
          subscriptionCode:
            this.selectedSubscription && this.selectedSubscription.code,
          subscriptionExistingMsisdn: this.selectedPhoneNumber,
          vas: this.selectedVasCodes,
          acceptBanRestriction,
          upsale: true,
          continueWithoutLoyaltyVas: true
        });
      }

      promise.catch(e => {
        if (e.code === 409) {
          this.installmentsMismatchError = e.errors[0].detail;
          this.$refs.installmentsModal.show();
        }
      });

      this.wait({ promise });
    },

    /**
     * Dismesses the ban restriction modal.
     */
    hideRestrictionModal() {
      this.$refs.banRestrictionModal.hide();
    },

    /**
     * Sets a local storage value that will show the number select modal the next a user hits the product page.
     */
    setNumberModalValue() {
      if (!this.isLoggedIn) {
        safeWrite(NUMBER_MODAL_LOCAL_STORAGE_KEY, true, localStorage);
      }
    },

    /**
     * Clears the number select modal local storage value, so it is not shown again until it is set once more.
     */
    unsetNumberModalValue() {
      safeWrite(NUMBER_MODAL_LOCAL_STORAGE_KEY, false, localStorage);
    },

    /**
     * If a local storage value is present, shows the select number modal to logged in users.
     */
    checkNumberModalValue() {
      const shouldShowNumbeModal =
        safeRead(NUMBER_MODAL_LOCAL_STORAGE_KEY, false, localStorage) ===
          true && !this.isAccessory;

      if (this.isLoggedIn && shouldShowNumbeModal) {
        this.showNumberSelectModal();
      }
    },

    /**
     * Push Enhanced E-Commerce object to GTM data layer.
     *
     * @param { "add" | "detail" } type The type of object to push.
     */
    pushEecObjectToGtm(type) {
      try {
        const variantColor = this.selectedVariant?.color?.toLowerCase();
        const variantCapacity = this.selectedVariant?.capacity;
        const variantScreenInches =
          this.selectedVariant?.productScreenSizeInches;

        const variantData = `${variantColor}|${variantCapacity}gb|${variantScreenInches}`;

        const product = {
          name: this.productName.toLowerCase(),
          id: this.selectedVariant.code,
          brand: this.productBrand.toLowerCase(),
          category: this.selectedVariant.itemEquipmentType.toLowerCase(),
          capacity: variantCapacity,
          variant: variantData
        };

        if (type === 'add') {
          product.quantity = 1;

          if (this.isAccessory) {
            product.price = this.prices?.minimumPrice?.value;
            product.category = 'Accessory';
          } else {
            product.price = this.prices?.minimumPrice?.value;
            product.subscriptionId =
              this.selectedSubscription.code.toLowerCase();
            product.subscriptionName =
              this.selectedSubscription.displayName.toLowerCase();
          }
        }

        const eecObject = {
          ecommerce: {
            [type]: {
              products: [product]
            }
          },
          event: 'ecommerce'
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(eecObject);
      } catch {
        // Silently fail adding to GTM
      }
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

$product-bg-color: $c-nt-200;

.product-page,
.broadband-offerings-page {
  // .product-page--in-overlay
  &--in-overlay {
    margin-bottom: 150px; // Height of shop footer
  }

  // .product-page__header
  &__header {
    position: relative;
    z-index: 1;
  }

  // .product-page__wizard-footer
  &__wizard-footer {
    margin-top: $u-250;
    order: map-deep-get($product-order, 'usp', 'xs');

    @include mq($screen-md, min) {
      width: 100%;
    }

    .c-section__item {
      margin-bottom: $u-300;

      @include mq($screen-md, max) {
        margin-bottom: 0;
      }
    }
  }

  // .product-page__footer-cta
  &__footer-cta {
    & > button:first-child {
      margin-bottom: $u-200;
    }
  }

  // .product-page__price-disclaimer
  &__price-disclaimer {
    font-size: $global-font-size-sm;
  }

  // .product-page__pebble-bg-wrapper
  &__pebble-bg-wrapper {
    position: absolute;
    top: -5vw;
    width: 80vw;
    height: 80vw;
    max-height: 900px;
    max-width: 900px;
    overflow: hidden;
    right: 0;
    left: 20vw;
    margin: 0 auto;

    @include mq($screen-xxl, max) {
      right: 0;
      left: auto;
      margin: 0;
    }
    @include mq($screen-md, max) {
      top: $u-800;
    }
    @include mq($screen-sm, max) {
      width: 100vw;
      height: 100vw;
    }
  }

  // .product-page__pebble-bg
  &__pebble-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url('#{$images-path}/pebble-bg.svg') center / contain no-repeat;
    @include mq($screen-xxl, max) {
      transform: translateX(5vw);
    }
  }

  // .product-page__promotion
  &__promotion {
    order: map-deep-get($product-order, 'promotion', 'xs');
  }

  // .product-page__countdown
  &__countdown {
    order: map-deep-get($product-order, 'countdown', 'xs');

    @include mq($screen-md, min) {
      order: map-deep-get($product-order, 'countdown', 'md');
    }
  }

  // .cm-o-layout
  .cm-o-layout {
    z-index: unset;
    overflow-x: hidden;
    padding-top: 0;
    position: relative;

    @include mq($screen-md, min) {
      margin-top: $u-600;
      padding: 0 $u-700;
      overflow: unset;
    }

    // .cm-o-layout--bg-white
    &--bg-white & {
      background-color: $color-white;
    }

    // .cm-o-layout--no-padding-desktop
    &--no-padding-desktop {
      @include mq($screen-sm, min) {
        padding: 0 0 0 0;
      }
    }

    &__link-cloud {
      margin: $u-600 0;
    }
  }
}
</style>
