<template>
  <div class="c-form-radio">
    <input
      :id="id"
      ref="input"
      v-model="computedLocalChecked"
      :name="getName"
      :disabled="isDisabled"
      :value="value"
      type="radio"
      autocomplete="off"
      @change="onChange"
    />
    <input
      v-if="!isGrouped && !computedLocalChecked"
      type="hidden"
      :name="name"
      value=""
    />
    <label :for="id">
      <slot />

      <p
        v-if="descriptionText"
        class="c-form-radio__description-text"
        v-html="descriptionText"
      />

      <p
        v-if="additionalText"
        class="c-form-radio__additional-text"
        v-html="additionalText"
      />
    </label>
    <slot name="footer" />

    <popover-panel
      v-if="hasTooltip"
      :popover-triggers="['hover', 'click']"
      :description="tooltipDescriptionText"
    >
      <div class="c-form-radio__info-tooltip">
        <c-icon
          class="c-form-radio__info-tooltip-icon"
          symbol-id="contact-information"
        />
        <span>{{ tooltipTriggerText }}</span>
      </div>
    </popover-panel>
  </div>
</template>

<script>
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import PopoverPanel from 'webshop/components/popover-panel/popover-panel.vue';
import FormInputMixin from '../../mixins/form-input-mixin.js';

/**
 *
 * @Props
 * - default - should the default styles be used.
 *
 */
export default {
  name: 'CFormRadio',
  components: { PopoverPanel, CIcon },

  mixins: [FormInputMixin],

  inject: {
    bvGroup: {
      from: 'bvRadioGroup',
      default: false
    }
  },

  model: {
    prop: 'checked',
    event: 'input'
  },

  props: {
    checked: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Object, Number, Boolean],
      default: null
    },
    additionalText: {
      type: String,
      default: ''
    },
    descriptionText: {
      type: String,
      default: ''
    },
    hasTooltip: {
      type: Boolean,
      default: false
    },
    tooltipTriggerText: {
      type: String,
      default: ''
    },
    tooltipDescriptionText: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      localChecked: this.isGrouped ? this.bvGroup.checked : this.checked
    };
  },

  computed: {
    isGrouped() {
      return Boolean(this.bvGroup);
    },

    isChecked() {
      return this.value === this.computedLocalChecked;
    },

    computedLocalChecked: {
      get() {
        return this.isGrouped ? this.bvGroup.localChecked : this.localChecked;
      },
      set(val) {
        if (this.isGrouped) {
          this.bvGroup.localChecked = val;
        } else {
          this.localChecked = val;
        }
      }
    },

    getName() {
      return (this.isGrouped ? this.bvGroup.groupName : this.name) || null;
    }
  },

  methods: {
    onChange() {
      this.$emit('change', this.$refs.input.value);
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

.c-form-radio {
  position: relative;
  display: block;
  padding-left: $u-400;
  min-height: $u-400;
  margin-bottom: $u-250;

  &__info-tooltip {
    font-size: $global-font-size-md;
    display: flex;
    margin-top: $u-150;
    align-items: center;
    color: $c-pm-500;

    &-icon {
      height: $u-350;
      width: $u-350;
      fill: $c-pm-500;
      padding-right: $u-150 + $u-100;
    }
  }

  &__description-text {
    margin-top: $u-200;
  }

  &__description-text,
  &__additional-text {
    font-size: $global-font-size-md;
    line-height: $u-350;
    color: $c-nt-700;
  }

  // .c-form-radio > label
  & > label {
    position: relative;
    margin-bottom: 0;
    line-height: $global-line-height-sm;
    display: block;
    padding-left: $u-250;
    padding-top: $u-100;

    // .c-form-radio > label:before,
    // .c-form-radio > label:after
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: -$u-400;
      display: block;
      width: $u-400;
      height: $u-400;
      content: '';
    }

    // .c-form-radio > label:before
    &:before {
      transition: all 0.15s ease-in-out;
      pointer-events: none;
      background-color: $color-white;

      border-radius: 50%;
      border: $u-100 solid $c-nt-500;
    }

    &:hover:before {
      border: $u-125 solid $c-nt-900;
    }

    // .c-form-radio > label:after
    &:after {
      background: no-repeat 50%/50% 50%;
    }
  }

  // .c-form-radio > input[type='radio']
  & > input[type='radio'] {
    position: absolute;
    z-index: -1;
    opacity: 0;

    // .c-form-radio > input[type='radio']:checked
    &:checked {
      & + label:after {
        background-color: $color-white;
        border-radius: 50%;
        content: '';
        display: block;

        height: $u-300;
        width: $u-300;
        left: -$u-250;
        position: absolute;
        top: $u-250;

        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {
      & + label {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
