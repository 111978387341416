/**
 * OneBlock Templates
 * @module oneblock-templates.mjs
 * @version 1.2.30
 * @summary 03-03-2020
 * @author Mads Stoumann
 * @description Templates for OneBlock elements
 */

import * as Section from './oneblock-templates-sections.mjs';
import COUNTDOWN_PLACEMENT from './enums/countdown-placement.mjs';
import { getBrand } from './oneblock-widgets.mjs';
/**
 * @function mergeSplashData
 * @param {Object} splash
 * @param {Object} preset
 * @description Merge splash-data with splash-data from preset
 */
export function mergeSplashData(splash, preset) {
	const content = [
		...splash.content.map((item, i) => {
			return {
				...item,
				...preset.content[i]
			};
		})
	];
	return { content, file: splash.file, name: preset.name };
}

/**
 * @function renderAll
 * @param {Object} data
 * @description Renders all sections and OneBlocks from json
 */
export function renderAll(data) {
	return data
		.map(section => {
			return tmplSection(section);
		})
		.join('');
}

/**
 * @function tmplLink
 * @param {Object} data
 * @param {Boolean} editMode
 * @param {Boolean} hasVideo
 * @description Renders inline link-text, with optional url-wrapper, if video url exists and video is present.
 */
function tmplLink(data, editMode, hasVideo) {
	return `
		<nav class="c-ob__link-wrapper"${editMode ? ` data-ob-group="link"` : ''}>
			${
				data.url && hasVideo && !editMode
					? `<a class="c-ob__link-inline" href="${data.url}" itemprop="url">`
					: ''
			}
				${
					data.url && data.linkText
						? `<span class="c-ob__link-text" ${
								data.jsHook
									? `data-js="${
											data.jsHook
									  }" data-js-event="${data.jsHookEvent ||
											''}" data-js-param="${data.jsHookParam || ''}"`
									: ``
						  }${
								editMode
									? ` data-ob-group="link" data-ob-subgroup="primary"`
									: ''
						  }>${data.linkText}</span>`
						: ''
				}
			${data.url && hasVideo && !editMode ? `</a>` : ''}

			${
				data.urlSecondary && !editMode
					? `<a class="c-ob__link-inline" href="${data.urlSecondary}" itemprop="url">`
					: ''
			}
				${
					data.urlSecondary && data.linkTextSecondary
						? `<span class="c-ob__link-text-sec"${
								editMode
									? ` data-ob-group="link" data-ob-subgroup="secondary"`
									: ''
						  }>${data.linkTextSecondary}</span>`
						: ''
				}
			${data.urlSecondary && !editMode ? `</a>` : ''}
		</nav>`;
}

/**
 * @function tmplMedia
 * @param {Object} data
 * @param {Boolean} editMode
 * @description A solid background-color/gradient can be set, so both image and background-image is optional. However, if neither caption or splash are present as well, the element won't render. In production, add `srcset` to image and bgImage
 */
function tmplMedia(data, editMode) {
	const hasSplash = (data.splash && data.splash.content) || false;
	const hasVideo = (data.video && data.video.src) || false;
	const showCountdownInImageCaption =
		data.countdown?.countdownPlacement === COUNTDOWN_PLACEMENT.ImageCaption;

	return data.bgImage ||
		data.image ||
		data.imageCaption ||
		data.splash ||
		hasVideo
		? `
	<figure class="c-ob__bg-wrapper" itemscope itemtype="${
		hasVideo ? 'http://schema.org/VideoObject' : 'http://schema.org/ImageObject'
	}">
		${hasVideo ? tmplVideo(data, editMode) : ''}
		${
			hasVideo && data.bgImage && !data.video.overlay
				? ''
				: data.bgImage
				? `<img title="${data.imageCaption}" class="c-ob__bg ${
						hasVideo ? 'c-ob__bg-poster' : ''
				  }" itemprop="image" src="${data.bgImage}" loading="lazy"${
						editMode ? ` data-ob-group="bgmedia"` : ''
				  } data-obj-fit="true" />`
				: ``
		}
		${
			hasVideo && data.video.overlay
				? `<div class="c-ob__bg-play"><svg viewBox="0 0 24 24"><path d="M3.9,18.9V5.1c0-1.6,1.7-2.6,3-1.8l12,6.9c1.4,0.8,1.4,2.9,0,3.7l-12,6.9C5.6,21.5,3.9,20.5,3.9,18.9z"></path></svg></div>`
				: ''
		}
		${
			data.image
				? `<img alt="${data.headline ||
						'ALT-TEXT MISSING'}" class="c-ob__img" itemprop="image" src="${
						data.image
				  }" loading="lazy"${editMode ? ` data-ob-group="image"` : ''} />`
				: ``
		}
		${hasSplash ? tmplSplash(data, editMode) : ``}
		${
			data.imageCaption || showCountdownInImageCaption
				? `<figcaption class="c-ob__bg-caption" itemprop="caption"${
						editMode ? ` data-ob-group="imagecaption"` : ''
				  }>
			${data.imageCaption}
			<span class="c-ob__bg-caption-countdown">
				${showCountdownInImageCaption ? tmplCountDown(data) : ''}
			</span>
		</figcaption>`
				: ''
		}
	</figure>`
		: ``;
}

/**
 * @function tmplMeta
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders meta-section
 */

function tmplMeta(data, editMode) {
	return `
	<nav class="c-ob__meta"${editMode ? ` data-ob-group="meta"` : ''}>
	${data
		.map(item => {
			return `<${editMode ? 'span' : 'a'} class="c-ob__meta-item" href="${
				item.link
			}" target="_blank" rel="noopener noreferrer" itemprop="potentialAction" content="http://schema.org/ShareAction"${
				editMode ? ` data-ob-group="meta"` : ''
			}>
			<svg class="c-ob__meta-icon"><use xlink:href="${item.icon}"></use></svg>
			<span class="c-ob__meta-text" itemprop="commentCount">${item.text}</span>
		</${editMode ? 'span' : 'a'}>`;
		})
		.join('')}
	</nav>`;
}

/**
 * @function tmplOffer
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders an Offer (Prices) Block
 */

function tmplOffer(data, editMode) {
	return `
	<div class="c-ob__offer"${editMode ? ` data-ob-group="offer"` : ''}>
        <div class="c-ob__offer-price-label"${
					editMode
						? ` data-ob-group="offer" data-ob-subgroup="offerpricelabel"`
						: ''
				}>${data.offerPriceLabel}</div>
		<span class="c-ob__offer-base"${
			editMode ? ` data-ob-group="offer" data-ob-subgroup="offerbase"` : ''
		}>${data.offerBase}</span>
		<span class="c-ob__offer-price"${
			editMode ? ` data-ob-group="offer" data-ob-subgroup="offerprice"` : ''
		}>${data.offerPrice}</span>
		<span class="c-ob__offer-currency"${
			editMode ? ` data-ob-group="offer" data-ob-subgroup="offercurrency"` : ''
		}>${data.offerCurrency}</span>
		<div class="c-ob__offer-text"${
			editMode ? ` data-ob-group="offer" data-ob-subgroup="offertext"` : ''
		}>${data.offerText}</div>
	</div>`;
}

/**
 * @function tmplOneBlock
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Main rendering template for OneBlock
 */
export function tmplOneBlock(data, editMode = false) {
	const hasVideo = (data.video && data.video.src !== '') || false;
	const countdownPlacement = data.countdown?.countdownPlacement || '';
	const showCountdownInContentRibbon =
		countdownPlacement === COUNTDOWN_PLACEMENT.ContentRibbon;

	return `
	<div class="c-ob" ${
		data.preset
			? `data-ob-ps="${encodeURIComponent(JSON.stringify(data.preset))}`
			: ''
	}" itemscope itemtype="http://schema.org/CreativeWork">
		${tmplMedia(data, editMode)}
		<section class="c-ob__content" data-ob-group="contentbox">
			${
				data.contentRibbon || showCountdownInContentRibbon
					? `<div class="c-ob__ribbon"${
							editMode ? ` data-ob-group="contentRibbon"` : ''
					  }>
						${data.contentRibbon}
						<span class="c-ob__ribbon-countdown">
							${showCountdownInContentRibbon ? tmplCountDown(data) : ''}
						</span>
					  </div>`
					: ``
			}
			${
				data.tagline
					? `<div class="c-ob__tagline"${
							editMode ? ` data-ob-group="tagline"` : ''
					  }>${data.tagline}</div>`
					: ``
			}
			${
				countdownPlacement === COUNTDOWN_PLACEMENT.AboveHeadline
					? `<div class="c-ob__countdown">${tmplCountDown(data)}</div>`
					: ''
			}
			${
				data.headline
					? `<div class="c-ob__headline" itemprop="headline"${
							editMode ? ` data-ob-group="headline"` : ''
					  }>${data.headline}</div>`
					: ``
			}
			${
				data.additionalHeadline
					? `<div class="c-ob__additional-headline" itemprop="additionalHeadline"${
							editMode ? ` data-ob-group="additionalHeadline"` : ''
					  }>${data.additionalHeadline}</div>`
					: ``
			}
			${
				data.displayHeadline
					? `<div class="c-ob__display-headline" itemprop="displayHeadline"${
							editMode ? `data-ob-group="displayHeadline"` : ''
					  }>
              			${data.displayHeadline}
            		</div>`
					: ``
			}
			${
				countdownPlacement === COUNTDOWN_PLACEMENT.BelowHeadline
					? `<div class="c-ob__countdown">${tmplCountDown(data)}</div>`
					: ''
			}
			${
				data.description
					? `<div class="c-ob__text" itemprop="description"${
							editMode ? ` data-ob-group="text"` : ''
					  }>${data.description}</div>`
					: ''
			}
			${
				countdownPlacement === COUNTDOWN_PLACEMENT.BelowText
					? `<div class="c-ob__countdown">${tmplCountDown(data)}</div>`
					: ''
			}
			${data.offerPrice || data.offerText ? tmplOffer(data, editMode) : ``}
			${data.meta ? tmplMeta(data.meta, editMode) : ``}
			${data.linkText ? tmplLink(data, editMode, hasVideo) : ``}
		</section>
		${data.url && !hasVideo && !editMode ? tmplUrl(data) : ``}
	</div>`;
}

/**
 * @function tmplCountDown
 * @param {Object} data
 * @description Renders a count-down block
 */

function tmplCountDown(data) {
	if (!data.countdown) {
		return '';
	}

	const options = JSON.stringify({
		hideDaysWhenZero: data.countdown.hideDaysWhenZero
	});

	return `
	<div data-js="countdown"
		data-end-time="${data.countdown.endTime}"
		data-lbl-days="${data.countdown.daysLabel}"
		data-lbl-hours="${data.countdown.hoursLabel}"
		data-lbl-mins="${data.countdown.minutesLabel}"
		data-lbl-secs="${data.countdown.secondsLabel}"
		data-times-up-title="${data.countdown.optionalTimesUpTitle}"
		data-options=${options}>
	    <span class="c-cnt__headline">${data.countdown.title || ''}</span>
	</div>`;
}

/**
 * @function tmplSection
 * @param {Object} data
 * @description Main template for Sections, wrapping OneBlocks
 */

function tmplSection(data) {
	return `
	<section class="${data.section}">
		<div class="grid__row">
			${
				data.type
					? Section[data.type](data)
					: data.content
							.map(item => {
								return item.displayOption
									? `<div class="${item.displayOption}">${tmplOneBlock(
											item
									  )}</div>`
									: tmplOneBlock(item);
							})
							.join('')
			}
		</div>
	</section>`;
}

/**
 * @function tmplSplash
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders a splash and `x` amount of `<text>`-elements
 */

function tmplSplash(data, editMode) {
	const splash = data.splash.content
		.map(item => {
			return item.text;
		})
		.join('|');
	const splashData = mergeSplashData(
		data.splash,
		data.preset.splash
	); /* Only for editMode */

	return splashData.content.length > 0
		? `
	<div class="c-ob__splash"${
		editMode
			? ` data-ob-group="splash"`
			: `data-splash-lines="${splash}" data-splash-file="${data.splash.file}"`
	}>
		${
			editMode
				? `<svg viewBox="0 0 100 100">
				<use class="c-ob__splash-elm" xlink:href="${splashData.file}#${
						splashData.name
				  }" />
				${splashData.content
					.map((item, index) => {
						return `<text x="${item.x || 50}" y="${item.y ||
							50}" text-anchor="middle" class="c-ob__splash-line-${index +
							1}" ${
							editMode
								? ` data-ob-group="splash" data-ob-subgroup="text${index + 1}"`
								: ''
						}>${item.text}</text>`;
					})
					.join('')}
			</svg>`
				: ''
		}
	</div>`
		: '';
}

/**
 * @function tmplUrl
 * @param {Object} data
 * @description Renders url and link-text
 */

function tmplUrl(data) {
	return `<a class="c-ob__link" href="${
		data.url
	}" itemprop="url">${data.linkText || 'LINK-TEXT MISSING'}</a>`;
}

/**
 * @function tmplVideo
 * @param {Object} data
 * @param {Boolean} editMode
 * @description Renders a background-video, either locally hosted or iframe-based
 */

function tmplVideo(data, editMode) {
	return `
		<meta itemprop="description" content="${data.description}">
		<meta itemprop="name" content="${data.headline}">
		<meta itemprop="thumbnailUrl" content="${data.bgImage}">
		<meta itemprop="uploadDate" content="${data.video.date}"></meta>
		${
			data.video.type === 2
				? `<iframe class="c-ob__bg" itemprop="video" src="${data.video.src}${
						data.video.autoPlay ? '?autoplay=1' : ''
				  }" frameborder="0" tabindex="-1" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"${
						editMode ? ` data-ob-group="bgmedia"` : ''
				  }></iframe>`
				: `<video class="c-ob__bg" itemprop="video" src="${data.video.src}"${
						data.bgImage ? ` poster="${data.bgImage}"` : ''
				  }${data.video.autoPlay ? ` autoplay` : ''}${
						data.video.controls ? ` controls` : ''
				  }${data.video.loop ? ` loop` : ''}${
						data.video.muted ? ` muted` : ''
				  }${editMode ? ` data-ob-group="bgmedia"` : ''}></video>`
		}
	`;
}

/**
 * @function tmplShoutingHeadline
 * @param {String} headline
 * @description Renders shouting headline
 */

 function tmplShoutingHeadline(headline) {
	const FONT_INCREMENT = 15;
	const CONTAINER = document.createElement('div');
	const WORDS = headline.trim().split(' ');

  WORDS.forEach(word => {
    const WORD_CONTAINER = document.createElement('span');
    WORD_CONTAINER.className = 'shouting-font';
    // Initialize font size
    let currentFontSize = 100;
    const LETTERS = word.split('').forEach((letter, letterIndex) => {
      if (letterIndex > 0) {
        currentFontSize += FONT_INCREMENT;
      }

      const LETTER_ELEMENT = document.createElement('span');
      LETTER_ELEMENT.className = 'shouting-font__letter';
      LETTER_ELEMENT.innerHTML = letter;
	  LETTER_ELEMENT.setAttribute("data-ob-group", "displayHeadline");
      LETTER_ELEMENT.style.fontSize = `${String(currentFontSize)}%`;
      WORD_CONTAINER.appendChild(LETTER_ELEMENT);
    });
    CONTAINER.appendChild(WORD_CONTAINER);
  });

  return CONTAINER.outerHTML;
}
