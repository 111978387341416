<template>
  <div class="cm-c-checkout-summary__content signature">
    <div
      class="
        cm-c-checkout__module
        cm-c-checkout__module--pt
        cm-c-checkout__module--mb
        cm-c-total__section
      "
    >
      <div v-if="networking" class="cm-c-spinner cm-c-spinner--local">
        <span class="sr-only">Loading...</span>
      </div>
      <h3 class="cm-c-checkout__subtitle cm-c-checkout__subtitle--mb">
        {{ content.headline }}
      </h3>
      <div class="mb-2" v-html="content.descriptionText"></div>
      <c-form-radio-group
        id="nemid-or-digital-or-mitid"
        v-model="digital"
        stacked
        name="digital"
        class="mb-2"
      >
        <c-form-radio
          v-if="content.epadSignatureRadioText"
          id="digital"
          :value="1"
          @change="setSignatureValidity(sigImageData)"
          >{{ content.epadSignatureRadioText }}
        </c-form-radio>
        <c-button v-if="digital" class="mb-2" @click.prevent="startSign">
          {{ content.signatureButtonText }}</c-button
        >
        <c-form-radio
          v-if="content.digitalNemIdSignatureRadioText"
          id="nemid"
          :value="0"
          @change="postSignature('nemid')"
        >
          {{ content.digitalNemIdSignatureRadioText }}
        </c-form-radio>
        <c-form-radio
          v-if="content.digitalMitIdSignatureRadioText"
          id="mitid"
          :value="0"
          @change="postSignature('mitid')"
        >
          {{ content.digitalMitIdSignatureRadioText }}
        </c-form-radio>
      </c-form-radio-group>
      <c-information-box
        v-if="!digital"
        icon="contact-information"
        type="informative"
      >
        <p>{{ content.digitalSignatureInformationText }}</p>
      </c-information-box>
      <div v-show="sigImageData && digital">
        <canvas
          ref="signature"
          width="400"
          height="200"
          :class="canvasClasses"
        ></canvas>
      </div>
    </div>
    <c-modal
      ref="chromeExtensionNotInstalled"
      :title="content.chromeExtensionFailedText"
      centered
      modern
    >
      <div v-html="content.chromeExtensionDescription"></div>
      <c-button
        slot="footer"
        class="cm-c-btn cm-c-btn--primary"
        :href="chromeExtensionLink"
        target="_blank"
        variant="primary"
        >{{ content.installChromeExtensionButtonText }}
      </c-button>
    </c-modal>
  </div>
</template>

<script>
import { CFormRadioGroup, CFormRadio, CButton } from 'olympus/components';

import { mapMutations, mapActions } from 'vuex';
import { ACTIONS as SHIPPING_ACTIONS } from 'webshop/store/signature/index.js';
import { MUTATIONS as SIGNATURE_MUTATIONS } from 'webshop/components/checkout/store';

export default {
  name: 'CheckoutSignature',

  components: {
    CFormRadioGroup,
    CFormRadio,
    CButton
  },

  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      networking: false,
      digital: 1,
      context: '',
      canvas: '',
      canvasBackgroundColor: '#f2f2f2',
      sigImageData: '',
      imgWidth: 400,
      imgHeight: 250,
      message: {
        firstName: '',
        lastName: '',
        eMail: '',
        location: '',
        imageFormat: 1,
        imageX: this.imgWidth,
        imageY: this.imgHeight,
        imageTransparency: false,
        imageScaling: false,
        maxUpScalePercent: 0.0,
        rawDataFormat: 'ENC',
        minSigPoints: 25
      },
      chromeExtensionInstalled: false,
      chromeExtensionLink:
        'https://chrome.google.com/webstore/detail/epadlink-sigcaptureweb-sd/idldbjenlmipmpigmfamdlfifkkeaplc/'
    };
  },

  computed: {
    canvasClasses() {
      return {
        signature__canvas: true,
        'signature__canvas--hidden': !this.sigImageData || !this.digital
      };
    }
  },

  mounted() {
    this.setSignatureValidity(false);

    this.$nextTick(() => {
      this.canvas = this.$refs.signature; //retrieve canvas from dom by id that we have assigned
      this.context = this.canvas.getContext('2d'); //retrieve context

      this.canvas.style.background = this.canvasBackgroundColor;

      this.imgWidth = this.canvas.width;
      this.imgHeight = this.canvas.height;
    });
  },

  methods: {
    ...mapMutations({
      setSignatureValidity: SIGNATURE_MUTATIONS.SET_SIGNATURE_VALIDITY
    }),

    ...mapActions({
      addSignature: SHIPPING_ACTIONS.SET_SIGNATURE
    }),

    startSign() {
      const messageData = JSON.stringify(this.message);
      const element = document.createElement('SigCaptureWeb_ExtnDataElem');
      const evt = new Event('SigCaptureWeb_SignStartEvent', {
        bubbles: true,
        cancelable: false
      });

      this.chromeExtensionInstalled = !!document.documentElement.getAttribute(
        'sigcapturewebextension-installed'
      );
      if (!this.chromeExtensionInstalled) {
        this.$refs.chromeExtensionNotInstalled.show();
        return;
      }

      this.networking = true;
      document.addEventListener(
        'SigCaptureWeb_SignResponse',
        this.signResponse,
        false
      );
      element.setAttribute('SigCaptureWeb_MsgAttribute', messageData);
      document.documentElement.appendChild(element);
      element.dispatchEvent(evt);
    },

    signResponse(e) {
      const str = e.target.getAttribute('SigCaptureWeb_msgAttri');
      const obj = JSON.parse(str);
      this.setValues(obj, this.imgWidth, this.imgHeight);
    },

    setValues(objResponse, imageWidth, imageHeight) {
      const obj = JSON.parse(JSON.stringify(objResponse));

      if (Object.keys(obj) && obj.errorMsg) {
        this.$addToast({
          title: this.content.ePadSignatureFailureHeader,
          paragraph: obj.errorMsg,
          state: 'error',
          duration: 5000
        });
        this.networking = false;
        this.resetSignatureValidity();
      } else {
        if (obj.isSigned) {
          this.sigImageData = obj.imageData;
          const img = new Image();
          img.onload = () => {
            this.context.drawImage(img, 0, 0, imageWidth, imageHeight);
          };
          img.src = `data:image/png;base64, ${obj.imageData}`;

          this.postSignature('epad', obj.imageData);
        }
      }
    },

    postSignature(type, image = null) {
      this.addSignature({
        type: type,
        image: image
      })
        .then(() => {
          if (type === 'epad') {
            this.$addToast({
              title: this.content.ePadSignatureSuccessHeader,
              paragraph: this.content.ePadSignatureSuccessText,
              duration: 5000
            });
          }
          this.networking = false;
          this.setSignatureValidity(true);
        })
        .catch(() => {
          this.$addToast({
            title: this.digital
              ? this.content.ePadSignatureFailureHeader
              : this.content.digitalSignatureFailureHeader,
            paragraph: this.digital
              ? this.content.ePadSignatureFailureText
              : this.content.digitalSignatureFailureText,
            state: 'error',
            duration: 5000
          });
          this.networking = false;
        });
    },

    resetSignatureValidity() {
      this.sigImageData = null;
      this.setSignatureValidity(false);
    }
  }
};
</script>
<style lang="scss">
.signature {
  position: relative;

  &__canvas {
    &--hidden {
      display: none;
    }
  }
}
</style>
