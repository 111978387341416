/**
 * Generic method to update the url via history if available.
 * Falls back to url replacement.
 *
 * @param { string } val url fragment value, like /some/url/fragment.
 * @param { boolean } useHash defaults to true - if false: use native ?key=value&key=value syntax
 */
export const setUrlFragment = (val, useHash = true) => {
  if (!useHash) {
    const url = new URL(document.location);
    const params = url.searchParams;
    Object.entries(val).forEach(item => {
      const [key, value] = item;
      params.set(key, value);
    });
    history.replaceState(undefined, undefined, `?${params.toString()}`);
  } else if (history.replaceState) {
    history.replaceState(undefined, undefined, `#${val}`);
  } else {
    document.location.hash = val;
  }
};
