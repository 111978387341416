<template>
  <span>
    <span
      v-for="(word, i) in shoutingText"
      :key="word + i"
      class="shouting-font"
    >
      <span
        v-for="(character, ci) in word"
        :key="character + ci"
        :class="
          isAlphaNumeric(character)
            ? 'shouting-font__letter'
            : 'shouting-font__other'
        "
        >{{ character }}</span
      >
      <span v-if="shoutingText.length !== i + 1"> </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'shouting-font',

  props: {
    text: {
      type: String,
      default: ''
    },
    alwaysIncrement: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    shoutingText() {
      return this.text.split(' ');
    }
  },

  methods: {
    isAlphaNumeric(character) {
      return this?.alwaysIncrement ?? !character.match(/[^a-z0-9]/gi);
    }
  }
};
</script>

<style lang="scss">
// Use this to prevent deprecation warning
@function safe-unquote($param) {
  @return if(type-of($param) == string, unquote($param), $param);
}

$fontSize: 100;
$increment: 15; // Font size should increment by 15% for each letter

.shouting-font {
  font-style: italic;
  font-weight: 900;

  &__letter {
    // It is unlikely that we will need more than 20 characters
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        font-size: #{$fontSize}#{'%'};
      }
      $fontSize: safe-unquote($fontSize) + safe-unquote($increment);
    }
  }
}
</style>
