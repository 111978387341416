<script>
// TODO TC-4911: Remove this component and all references to it. Make sure to test everything works and looks correct!
import { includes } from '../../utils/array.js';

import FormInputMixin from '../../mixins/form-input-mixin.js';
import FormTextMixin from '../../mixins/form-text-mixin.js';

const TYPES = [
  'text',
  'password',
  'email',
  'number',
  'url',
  'tel',
  'search',
  'range',
  'color',
  'date',
  'time',
  'datetime',
  'datetime-local',
  'month',
  'week'
];

export default {
  name: 'CInput',

  mixins: [FormInputMixin, FormTextMixin],

  props: {
    type: {
      type: String,
      default: 'text',
      validator: val => includes(TYPES, val)
    },
    min: {
      type: [String, Number],
      default: null
    },
    max: {
      type: [String, Number],
      default: null
    },
    step: {
      type: [String, Number],
      default: null
    }
  },

  render(h) {
    const self = this;

    return h('input', {
      ref: 'input',
      class: 'c-input',
      directives: [
        {
          name: 'model',
          rawName: 'v-model',
          value: self.localValue,
          expression: 'localValue'
        }
      ],
      attrs: {
        id: self.id,
        name: self.name,
        type: self.type,
        disabled: self.disabled,
        placeholder: self.placeholder,
        step: self.step
      },
      domProps: {
        value: self.localValue
      },
      on: {
        input: self.onInput,
        change: self.onChange,
        blur: self.onBlur
      }
    });
  }
};
</script>

<style lang="scss">
// Remove arrows when type=number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Remove arrows when type=number
input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.c-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;

  &.focus-visible {
    outline: none;
    border-color: #007bff;
  }
}
</style>
