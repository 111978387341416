<template>
  <div
    class="cm-c-sort-nav"
    :class="{
      'cm-c-sort-nav--simple': simple,
      'cm-c-sort-nav--push-right': isInstallmentsDisabled
    }"
  >
    <div class="cm-c-sort-nav__control-group">
      <label v-if="!simple" for="s-1" class="cm-c-sort-nav__label">
        {{ sortLabel }}
      </label>
      <div class="cm-c-form-control__select cm-c-sort-nav__control">
        <label v-if="simple" for="s-1" class="cm-c-sort-nav__label">
          {{ sortByLabel }}
          <c-icon class="cm-c-sort-nav__label__icon" symbol-id="arrow-down" />
        </label>

        <select id="s-1" name="s-1" @change="sortBy">
          <option
            v-for="option in sortOptions"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="!simple || !hideFilter" class="cm-c-sort-nav__btn">
      <a class="c-btn c-btn--empty c-btn--xs cm-js-open-filter-nav">
        {{ filterLabel }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GETTERS } from '../../store/shop/index.js';

export default {
  name: 'ProductCategorySort',

  props: {
    filterLabel: {
      type: String,
      default: ''
    },
    sortLabel: {
      type: String,
      default: ''
    },
    sortOptions: {
      type: Array,
      default: () => []
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    isInstallmentsDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({ sortByItemId: GETTERS.SORT_BY }),

    sortByLabel() {
      const selectedSortOption = this.sortByItemId
        ? this.sortOptions.find(
            item => item.id.toString() === this.sortByItemId
          )
        : this.sortOptions[0];

      return selectedSortOption?.name;
    }
  },

  methods: {
    sortBy: function (event) {
      this.$store.commit('SORT_BY', event.target.value);
      this.$store.dispatch('searchProducts');
    }
  }
};
</script>
