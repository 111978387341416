<template>
  <div class="cm-c-product-tooltip" :class="className">
    <div
      v-if="data && !data.length"
      class="cm-c-spinner cm-c-spinner--local"
    ></div>
    <div
      v-for="(row, rowIndex) in data"
      :key="rowIndex"
      class="cm-c-product-tooltip--row"
    >
      <div class="cm-c-total__row">
        <div class="cm-c-total__name">
          {{ row.key }}
        </div>
        <div class="cm-c-total__value">
          {{ row.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-info',
  props: {
    data: {
      type: [Array, Object],
      default: () => ({})
    },
    className: {
      type: String,
      default: ''
    },
    headLine: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      $activator: null
    };
  },
  mounted() {
    const $a = document.querySelector('.js-minimum-price-tooltip-activator');
    this.$activator = $a;
  }
};
</script>
