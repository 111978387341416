var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "config-group-item",
      class: {
        "config-group-item--active": _vm.expanded,
        "config-group-item--integrated": _vm.integrated
      }
    },
    [
      !_vm.integrated
        ? _c(
            "c-button",
            {
              staticClass: "config-group-item__button",
              attrs: { reset: "", type: "button" },
              on: { click: _vm.handleToggle }
            },
            [
              _c("section", { staticClass: "config-group-item__info" }, [
                _c(
                  "div",
                  { staticClass: "config-group-item__image" },
                  [
                    _c("c-icon", {
                      staticClass: "config-group-item__icon",
                      attrs: { "symbol-id": _vm.icon }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "config-group-item__text" },
                  [
                    _c("div", { staticClass: "config-group-item__title" }, [
                      _vm._v(_vm._s(_vm.title))
                    ]),
                    _vm._v(" "),
                    _vm._t("description")
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.rightText
                  ? _c(
                      "div",
                      {
                        class: {
                          "config-group-item__right-text": true,
                          "config-group-item__right-text--center":
                            _vm.rightTextCentered
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.rightText) + "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "cm-sr-only" }, [
                _vm._v("Toggle content")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.expanded
        ? _c(
            "c-collapse",
            {
              attrs: {
                id: "product-config-group-item-trigger",
                visible: _vm.expanded && !_vm.preventExpand
              }
            },
            [
              _c(
                "article",
                {
                  staticClass: "config-group-item__body",
                  class: {
                    "config-group-item__body--integrated": _vm.integrated
                  },
                  attrs: { "data-testid": "config-group-item-slot" }
                },
                [_vm._t("default")],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }