<script>
import CButton from '../../../../../shared/components/c-button/c-button.vue';

export default {
  name: 'topup-tsrr',
  components: {
    CButton
  },
  props: [],
  data() {
    return {
      isConfirmMode: false,
      selectedSoc: null
    };
  },
  computed: {},
  methods: {
    setConfirmMode(soc) {
      this.isConfirmMode = true;
      this.selectedSoc = soc;
    },
    cancelConfirm() {
      this.isConfirmMode = false;
      this.selectedSoc = null;
    }
  }
};
</script>
