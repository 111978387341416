import KeyCodes from '../utils/key-codes'
import { getAttr, hasAttr, isDisabled, matches, select, setAttr } from '../utils/dom'
import { isString } from '../utils/inspect'
import { keys } from '../utils/object'

// TODO: move to utils
const eventOn = (el, eventName, handler) => {
  if (el && el.addEventListener) {
    el.addEventListener(eventName, handler);
  }
}
const eventOff = (el, eventName, handler) => {
  if (el && el.removeEventListener) {
    el.removeEventListener(eventName, handler);
  }
}

const EVENT_SHOW = 'v::show::modal'

// Prop name we use to store info on root element
const PROPERTY = '__v_modal_directive__'

const getTarget = ({ modifiers = {}, arg, value }) => {
  // Try value, then arg, otherwise pick last modifier
  return isString(value) ? value : isString(arg) ? arg : keys(modifiers).reverse()[0]
}

const getTriggerElement = el => {
  // If root element is a dropdown-item or nav-item, we
  // need to target the inner link or button instead
  return el && matches(el, '.dropdown-menu > li, li.nav-item') ? select('a, button', el) || el : el
}

const setRole = trigger => {
  // Ensure accessibility on non button elements
  if (trigger && trigger.tagName !== 'BUTTON') {
    // Only set a role if the trigger element doesn't have one
    if (!hasAttr(trigger, 'role')) {
      setAttr(trigger, 'role', 'button')
    }
    // Add a tabindex is not a button or link, and tabindex is not provided
    if (trigger.tagName !== 'A' && !hasAttr(trigger, 'tabindex')) {
      setAttr(trigger, 'tabindex', '0')
    }
  }
}

const bind = (el, binding, vnode) => {
  const target = getTarget(binding)
  const trigger = getTriggerElement(el)
  if (target && trigger) {
    const handler = evt => {
      const currentTarget = evt.currentTarget
      if (!isDisabled(currentTarget)) {
        const type = evt.type
        const key = evt.keyCode
        if (
          type === 'click' ||
          (type === 'keydown' &&
           (key === KeyCodes.ENTER || 
            key === KeyCodes.SPACE))
        ) {
          vnode.context.$root.$emit(EVENT_SHOW, target, currentTarget)
        }
      }
    }
    el[PROPERTY] = { handler, target, trigger }
    // If element is not a button, we add `role="button"` for accessibility
    setRole(trigger)
    eventOn(trigger, 'click', handler)
    if (trigger.tagName !== 'BUTTON' && getAttr(trigger, 'role') === 'button') {
      // If trigger isn't a button but has role button,
      // we also listen for `keydown.space` && `keydown.enter`
      eventOn(trigger, 'keydown', handler)
    }
  }
}

const unbind = el => {
  const oldProp = el[PROPERTY] || {}
  const trigger = oldProp.trigger
  const handler = oldProp.handler
  if (trigger && handler) {
    eventOff(trigger, 'click', handler)
    eventOff(trigger, 'keydown', handler)
    eventOff(el, 'click', handler)
    eventOff(el, 'keydown', handler)
  }
  delete el[PROPERTY]
}

const componentUpdated = (el, binding, vnode) => {
  const oldProp = el[PROPERTY] || {}
  const target = getTarget(binding)
  const trigger = getTriggerElement(el)
  if (target !== oldProp.target || trigger !== oldProp.trigger) {
    // We bind and rebind if the target or trigger changes
    unbind(el, binding, vnode)
    bind(el, binding, vnode)
  }
  setRole(trigger)
}

const updated = () => {}

/*
 * Export our directive
 */
export const VModal = {
  inserted: componentUpdated,
  updated,
  componentUpdated,
  unbind
}