<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import ProductColor from './product-color.vue';
import AttributeSelector from './attribute-selector.vue';
import PricePlanSelector from './price-plan-selector.vue';
import ValueAddingService from 'webshop/components/product/value-adding-service.vue';
import { ACTIONS as NUMBER_SELECT_ACTIONS } from 'webshop/store/number-select';
import {
  GETTERS as PRODUCT_PAGE_GETTERS,
  MUTATIONS as PRODUCT_PAGE_MUTATIONS
} from 'webshop/components/product/store/product-page';

import withTemplate from 'theme/templates/product-wizard.html';

/**
 * Allows configuring a product.
 */
export default withTemplate({
  name: 'product-wizard',

  components: {
    AttributeSelector,
    ProductColor,
    PricePlanSelector,
    ValueAddingService
  },

  mixins: [ContentPropertyMixin],

  props: {
    productCode: {
      type: String,
      default: ''
    },

    content: {
      type: Object,
      default: () => ({})
    },

    colorAttributes: {
      type: Array,
      default: () => []
    },

    capacityAttributes: {
      type: Array,
      default: () => []
    },

    installmentAttributes: {
      type: Array,
      default: () => []
    },

    bindingPeriodAttributes: {
      type: Array,
      default: () => []
    },

    subscriptions: {
      type: Array,
      default: () => []
    },

    existingSubscriptions: {
      type: Array,
      default: () => []
    },

    selectedVariant: {
      type: Object,
      default: () => ({})
    },

    selectedSubscription: {
      type: Object,
      default: () => undefined
    },

    selectedInstallment: {
      type: Object,
      default: () => undefined
    },

    selectedBindingPeriod: {
      type: Object,
      default: () => undefined
    },

    isLoggedIn: {
      type: Boolean,
      default: () => false
    },

    disableInstallments: {
      type: Boolean,
      default: false
    },

    isHandset: {
      type: Boolean,
      default: () => false
    },

    hidePricePlanSelector: {
      type: Boolean,
      default: false
    },

    enableProlonging: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return { viewExistingSubscriptions: false };
  },

  computed: {
    ...mapGetters({
      selectedVasCodes: PRODUCT_PAGE_GETTERS.GET_SELECTED_VAS_CODES
    }),

    allowSelectingExistingSubscriptions() {
      return (
        this.isLoggedIn &&
        this.content.showExistingSubscription &&
        this.existingSubscriptions?.length
      );
    },

    installmentHeading() {
      return this.selectedVariant?.installment?.name;
    },

    disabledInstallments() {
      return this.disableInstallments
        ? this.disableAllInstallments
        : this.disableSpecificInstallments;
    },

    disableSpecificInstallments() {
      return this.installmentAttributes
        .filter(({ disabled }) => disabled)
        .map(({ name }) => name);
    },

    installmentSelectorDescription() {
      return this.disableInstallments
        ? this.selectedVariant.installment.disabledReason
        : this.selectedVariant.installment.disabled
        ? this.content.financingDisabledDescription
        : this.content.installmentSelectorDescription;
    },

    disableAllInstallments() {
      return this.installmentAttributes.map(({ name }) => name);
    },

    valueAddedServices() {
      return this.selectedVariant?.valueAddedServices;
    }
  },

  methods: {
    ...mapMutations({
      addVas: PRODUCT_PAGE_MUTATIONS.ADD_SELECTED_VAS,
      removeVas: PRODUCT_PAGE_MUTATIONS.REMOVE_SELECTED_VAS
    }),

    ...mapActions({
      setSelectedPhoneNumber: NUMBER_SELECT_ACTIONS.SET_SELECTED_PHONE_NUMBER
    }),

    /**
     * Toggles between viewing existing and new subscriptions, setting:
     * - selected phone number to null (new plan) or msisdn (existing plan)
     * - selected subscription is reset to the 1st item available. This is important to ensure users do not submit a prolonging when looking at existing subscriptions.
     */
    toggleExistingSubscriptionsView() {
      if (this.viewExistingSubscriptions) {
        const { name, code } = this.subscriptions[0]; // get subscription id from default variant
        this.setSelectedPhoneNumber({ selectedPhoneNumber: null });
        this.$emit('price-plan-selected', { name, code });
      } else {
        const { name, code, msisdn } = this.existingSubscriptions[0];
        this.setSelectedPhoneNumber({ selectedPhoneNumber: msisdn });
        this.$emit('price-plan-selected', { name, code, msisdn });
      }

      this.viewExistingSubscriptions = !this.viewExistingSubscriptions;
    },

    onCapacitySelected({ name, code }) {
      /**
       * Triggers when a capacity is selected,
       * passing on the new capacity name & id.
       */
      this.$emit('capacity-selected', { name, code });
    },

    onColorSelected({ name, code }) {
      /**
       * Triggers when a color is selected,
       * passing on the new color name & id.
       */
      this.$emit('color-selected', { name, code });
    },

    onInstallmentSelected({ name, code }) {
      /**
       * Triggers when an installment is selected,
       * passing on the new installment name & id.
       */
      this.$emit('installment-selected', { name, code });
    },

    onBindingPeriodSelected({ name, code }) {
      /**
       * Triggers when a binding period is selected,
       * passing on the new binding period name & id.
       */
      this.$emit('binding-period-selected', { name, code });
    },

    onPricePlanSelected({ name, code, msisdn }) {
      // Prolonging for callme is done via msisdn attached to subscription.
      // If the selected item contains an msisdn, it is treated as the selected number,
      // which in i.e. Norlys is being picked from a modal based on existing numbers instead.
      if (msisdn) {
        this.setSelectedPhoneNumber({ selectedPhoneNumber: msisdn });
      }

      /**
       * Triggers when a price plan is selected,
       * passing on the new price plan name, id and optional msisdn.
       */
      this.$emit('price-plan-selected', { name, code, msisdn });
    },

    showPricePlanSelector() {
      return (
        !!this.subscriptions &&
        !!this.subscriptions.length &&
        !this.hidePricePlanSelector &&
        !!Object.keys(this.content).length
      );
    },

    onValueAddingServiceSelected(vas) {
      this.addVas(vas);

      /**
       * Event triggered when a vas is added for the current product.
       */
      this.$emit('vas-selected', vas);
    },

    onValueAddingServiceDeselected(vas) {
      this.removeVas(vas);

      /**
       * Event triggered when a vas is removed for the current product.
       */
      this.$emit('vas-deselected', vas);
    }
  }
});
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';
@import 'webshop/styles/sass/generic/_generic.mixins.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';

.product-wizard {
  // .product-wizard__container
  &__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    & > *,
    .product-details > * {
      width: 100%;
    }

    @include mq($screen-md, min) {
      align-items: flex-end;

      &.product-wizard__container__top {
        &:before {
          content: '';
          display: inline-block;
          margin-top: -$product-gallery-height;
          order: 1;
        }
      }

      & > *,
      .product-details > * {
        width: 50%;
      }
    }
  }

  // .product-wizard__color-selector
  &__color-selector {
    order: map-deep-get($product-order, 'color', 'xs');

    h3 {
      display: none;

      @include mq($screen-md, min) {
        display: block;
      }
    }

    ul {
      justify-content: center;

      @include mq($screen-md, min) {
        justify-content: flex-start;
      }
    }

    @include mq($screen-md, min) {
      order: map-deep-get($product-order, 'color', 'md');
    }
  }

  // .product-wizard__capacity-selector
  &__capacity-selector {
    order: map-deep-get($product-order, 'storage', 'xs');
  }

  // .product-wizard__payment-selector,
  &__payment-selector {
    // .product-wizard__payment-selector__description,
    &__description {
      font-size: $font-size-h5;
      margin: -$u-200 0 $u-300;

      a {
        color: $c-pm-500;
      }
    }
  }

  // .product-wizard__payment-selector,
  // .product-wizard__binding-selector
  &__payment-selector,
  &__binding-selector {
    order: map-deep-get($product-order, 'payment', 'xs');
  }

  // .product-wizard__insurance-selector
  &__insurance-selector {
    order: map-deep-get($product-order, 'insurance', 'xs');
    padding-top: $u-500;

    .cm-c-total__row--bottom-separator {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  // .product-wizard__price-plan-selector
  &__price-plan-selector {
    order: map-deep-get($product-order, 'subscription', 'xs');
  }

  // .product-wizard__configuration-footer
  &__configuration-footer {
    order: map-deep-get($product-order, 'configuration-footer', 'xs');

    &:not(:empty) {
      margin-top: $u-500;
    }
  }

  // .product-wizard__existing-subscriptions-button
  &__existing-subscriptions-button {
    text-align: right;
    margin-bottom: $u-300;
  }
}
</style>
