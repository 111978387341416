<template>
  <div>
    <div v-if="!showErrorState">
      <div class="c-checkout-resurs-financing__phone-secure-icon__container">
        <c-icon
          class="c-checkout-resurs-financing__phone-secure-icon"
          symbol-id="logo-phone-secure"
        />
      </div>

      <div class="c-checkout-resurs-financing__header">
        <h2 class="c-checkout-resurs-financing__subtitle">
          {{ content.pageTitle }}
        </h2>
        <div
          class="c-checkout-resurs-financing__content"
          v-html="content.pageDescription"
        ></div>
      </div>

      <div class="c-checkout-resurs-financing__btn-container">
        <c-button
          class="c-btn"
          :loading="goToResursButtonIsLoading"
          @click="redirectToResurs"
        >
          {{ content.goToResursButtonText }}
        </c-button>
      </div>

      <div class="c-checkout-resurs-financing__resurs-logo__container">
        <c-icon
          class="c-checkout-resurs-financing__resurs-logo"
          symbol-id="logo-resurs-bank"
        />
      </div>

      <div
        v-if="showRedirectionError"
        class="c-checkout-resurs-financing__redirect-error"
      >
        <p class="c-checkout-resurs-financing__redirect-error__text">
          {{ content.redirectionErrorText }}
        </p>
      </div>
    </div>

    <div v-else>
      <div class="c-checkout-resurs-financing__failure-section">
        <c-icon
          class="c-checkout-resurs-financing__failure-section__icon"
          symbol-id="general-crossmark"
        />

        <p
          class="c-checkout-resurs-financing__content"
          v-html="content.paymentErrorText"
        ></p>

        <c-button class="c-btn" @click="showFinancePage">
          {{ content.tryAgainButtonText }}
        </c-button>
      </div>
    </div>
  </div>
</template>
<script>
import { CButton } from 'olympus/components/index.js';
import CIcon from 'webshop/components/c-icon/c-icon.vue';
import { getResursBankPaymentUrl } from 'webshop/api/payment-service.js';

export default {
  name: 'CheckoutFinance',

  components: {
    CIcon,
    CButton
  },

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showErrorState: false,
      goToResursButtonIsLoading: false,
      showRedirectionError: false
    };
  },

  mounted() {
    this.showErrorState = this.content.state === 'FAILURE';
  },

  methods: {
    redirectToResurs() {
      this.goToResursButtonIsLoading = true;
      getResursBankPaymentUrl({
        callbackUrl: this.content.resursReturnUrl
          ? this.content.resursReturnUrl
          : window.location.href
      })
        .then(response => {
          if (response.data?.redirectUrl) {
            window.location.href = response.data?.redirectUrl;
          }
        })
        .catch(() => {
          this.goToResursButtonIsLoading = false;
          this.showRedirectionError = true;
        });
    },

    showFinancePage() {
      this.showErrorState = false;
    }
  }
};
</script>
