var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configMode || _vm.product.configEdited
    ? _c(
        "c-card",
        { staticClass: "cm-numcon", attrs: { "no-shadow": "" } },
        [
          _c(
            "number-product",
            { attrs: { product: _vm.product } },
            [
              _c("c-collapse-trigger", {
                attrs: {
                  slot: "number-product-trigger",
                  id: "number-product-trigger"
                },
                slot: "number-product-trigger",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var expanded = ref.expanded
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "cm-numcon__header--toggle-open",
                              attrs: { type: "button" }
                            },
                            [
                              _c("c-icon", {
                                class: expanded ? "open" : "",
                                attrs: { "symbol-id": "arrow-down" }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3952659636
                )
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-collapse",
            { attrs: { id: "number-product-trigger", visible: "" } },
            [
              _c(
                "ul",
                { staticClass: "cm-numcon__options" },
                _vm._l(_vm.numberConfigVM.configTypes, function(configType) {
                  return _c(
                    "li",
                    {
                      key: configType.value,
                      class: {
                        "cm-numcon__options__item": true,
                        "cm-numcon__options__item--hide":
                          _vm.product.isSubscriptionMobileBroadBand &&
                          configType.value === _vm.CONFIGURATION_TYPES.MOVE
                      }
                    },
                    [
                      configType.value === _vm.CONFIGURATION_TYPES.EXISTING
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "cm-numcon__options__link",
                                on: {
                                  click: function($event) {
                                    return _vm.changeIsNotPossible(
                                      {
                                        name: "number-existing",
                                        params: {
                                          configType: 2,
                                          initMoveDateString:
                                            _vm.product.moveNumberDateString
                                        }
                                      },
                                      _vm.product.isSIMOnly
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cm-numcon__options__icon" },
                                  [
                                    _c("c-icon", {
                                      attrs: { "symbol-id": "service-sync" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "cm-numcon__options__header" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberConfigVM
                                            .existingCustomerOverviewHeadline
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cm-numcon__options__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .existingCustomerOverviewDescription
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : !_vm.product.isSubscriptionMobileBroadBand &&
                          configType.value === _vm.CONFIGURATION_TYPES.MOVE
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "cm-numcon__options__link",
                                attrs: {
                                  to: {
                                    name: "number-move",
                                    params: {
                                      configType: 3,
                                      datePickerMinDateString:
                                        _vm.product.minimumMoveNumberDateString,
                                      initMoveDateString:
                                        _vm.product.moveNumberDateString
                                    }
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cm-numcon__options__icon" },
                                  [
                                    _c("c-icon", {
                                      attrs: { "symbol-id": "service-switch" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "cm-numcon__options__header" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberConfigVM
                                            .moveNumberOverviewHeadline
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cm-numcon__options__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .moveNumberOverviewDescription
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : configType.value === _vm.CONFIGURATION_TYPES.NEW &&
                          ((_vm.numberConfigVM.disableNewNumber &&
                            !_vm.isHandset) ||
                            !_vm.numberConfigVM.disableNewNumber)
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "cm-numcon__options__link",
                                attrs: {
                                  to: {
                                    name: "number-new",
                                    params: {
                                      configType: 1,
                                      initMoveDateString:
                                        _vm.product.moveNumberDateString
                                    }
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cm-numcon__options__icon" },
                                  [
                                    _c("c-icon", {
                                      attrs: { "symbol-id": "menu-search" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "cm-numcon__options__header" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.numberConfigVM
                                            .newNumberOverviewHeadline
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cm-numcon__options__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.numberConfigVM
                                                .newNumberOverviewDescription
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      )
    : _c(
        "c-card",
        { staticClass: "cm-numcon", attrs: { "no-shadow": "" } },
        [_c("number-product", { attrs: { product: _vm.product } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }