<script>
import { mapMutations, mapGetters } from 'vuex';
import lookupTechnologies from '../../api/address-technology-service';
import { FIBER, DSL, COAX } from './access-types-enum';
import { FIBER_NAME, DSL_NAME, COAX_NAME } from './access-types-names';
import { FIBER_VALUE, DSL_VALUE, COAX_VALUE } from './access-types-values';
import * as businessOrderService from '../../api/business-order-service';
import {
  required,
  maxLength,
  requiredIf,
  email
} from 'vuelidate/lib/validators';
import ContentPropertyMixin from 'olympus/mixins/content-property-mixin.js';
import addressMixin from '../../../../../shared/mixins/address-autocomplete-mixin.js';

const initialState = {
  broadbandAccessTypeSelected: null,
  broadbandInstallationSelected: null,
  productSelected: null,
  vendorSelected: '',
  orders: [],
  errors: [],
  id: 0,
  accountNumber: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  cpr: null,
  streetname: null,
  housenumber: null,
  houseletter: null,
  floor: null,
  apartment: null,
  zipcode: null,
  city: null,
  isPorting: false,
  currentProvider: null,
  currentProviderReference: null,
  showSuccessMessage: false,
  focusIndex: 0,
  address: null,
  availableTechnologies: [],
  availableSpeeds: [],
  noTechnologiesFound: false
};

export default {
  name: 'business-orders',
  mixins: [ContentPropertyMixin, addressMixin],
  props: ['broadbandInstallationTypeList', 'validationMessage'],
  data() {
    return initialState;
  },
  computed: {
    ...mapGetters(['contextId'])
  },
  validations: {
    accountNumber: {
      required,
      maxLength: maxLength(10)
    },
    firstName: {
      required,
      maxLength: maxLength(200)
    },
    lastName: {
      required,
      maxLength: maxLength(200)
    },
    phone: {
      required,
      maxLength: maxLength(10)
    },
    email: {
      required,
      email,
      maxLength: maxLength(200)
    },
    currentProvider: {
      required: requiredIf('isPorting')
    },
    currentProviderReference: {
      required: requiredIf('isPorting')
    },
    broadbandAccessTypeSelected: {
      required
    },
    broadbandInstallationSelected: {
      required
    },
    productSelected: {
      required
    }
  },
  watch: {
    broadbandAccessTypeSelected: function (newValue, oldValue) {
      if (oldValue !== newValue) {
        this.availableSpeeds = this.availableProducts[newValue];
        if (this.availableSpeeds && this.availableSpeeds.length > 0) {
          this.productSelected = this.availableSpeeds[0].ProductCode;
          this.vendorSelected = this.availableSpeeds[0].Vendor;
          this.broadbandInstallationSelected =
            this.broadbandInstallationTypeList[0].key;
        }
      }
    }
  },
  created() {
    this.$globals.eventBus.$on('address-selected', payload => {
      this.getAvailableProducts(payload);
    });
  },
  mounted() {
    this.updateOrderLines();
  },
  methods: {
    ...mapMutations(['SHOW_SPINNER', 'HIDE_SPINNER']),
    clickAddressSuggestion(index) {
      this.selectAddress(index);
    },

    removeFromOrder(id) {
      this.SHOW_SPINNER();
      businessOrderService
        .removeFromOrder(id)
        .then(() => {
          this.updateOrderLines();
        })
        .catch(() => {
          this.HIDE_SPINNER();
        });
    },

    finalizeOrder() {
      var orderId = this.orders[0].orderId;

      businessOrderService.finalizeOrder(orderId).then(response => {
        if (response.data) {
          this.resetForm();
          this.showSuccessMessage = true;
        }
        this.HIDE_SPINNER();
      });
    },

    updateOrderLines() {
      businessOrderService.updateOrderLines().then(response => {
        this.orders = response.data;
        this.HIDE_SPINNER();
      });
    },

    productsFetchedCallback(products) {
      this.availableProducts = products;

      if (products.Fiber) {
        this.availableTechnologies.push({
          name: FIBER_NAME,
          value: FIBER
        });
      }
      if (products.Coax) {
        this.availableTechnologies.push({
          name: COAX_NAME,
          value: COAX
        });
      }
      if (products.Dsl) {
        this.availableTechnologies.push({
          name: DSL_NAME,
          value: DSL
        });
      }

      if (this.availableTechnologies && this.availableTechnologies.length > 0) {
        this.broadbandAccessTypeSelected = this.availableTechnologies[0].value;
      }

      if (!products.Fiber && !products.Coax && !products.Dsl) {
        this.noTechnologiesFound = true;
      }

      this.HIDE_SPINNER();
    },

    getAvailableProducts(address) {
      this.SHOW_SPINNER();
      this.broadbandAccessTypeSelected = null;
      this.availableTechnologies = [];
      this.availableSpeeds = [];
      this.broadbandInstallationSelected = null;

      const addressRequest = {
        streetName: address.streetName,
        streetNumber: address.streetNumber,
        floorNumber: address.floorNumber,
        door: address.floorSide,
        zipCode: address.postCode,
        city: address.city
      };

      lookupTechnologies(addressRequest)
        .then(result => {
          this.productsFetchedCallback(result.data);
        })
        .catch(() => {
          this.availableProducts = [];
          this.availableTechnologies = [];
          this.HIDE_SPINNER();
        });
    },

    mapAccessType(type) {
      switch (type) {
        case DSL:
          return DSL_VALUE;
        case COAX:
          return COAX_VALUE;
        case FIBER:
          return FIBER_VALUE;
        default:
          return null;
      }
    },

    addToOrder() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.SHOW_SPINNER();

      const order = {
        id: this.id,
        accountNumber: this.accountNumber,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.email,
        cpr: this.cpr,
        streetname: this.address.streetName,
        housenumber: this.address.streetNumber,
        floor: this.address.floorNumber,
        apartment: this.address.floorSide,
        zipcode: this.address.postCode,
        city: this.address.city,
        broadbandAccessTypeSelected: this.mapAccessType(
          this.broadbandAccessTypeSelected
        ),
        broadbandInstallationSelected: this.broadbandInstallationSelected,
        productSelected: this.productSelected,
        vendorSelected: this.vendorSelected,
        isPorting: this.isPorting,
        currentProvider: this.currentProvider,
        currentProviderReference: this.currentProviderReference
      };

      businessOrderService
        .addOrderLine(order)
        .then(() => {
          this.updateOrderLines();
          this.resetForm();
          this.HIDE_SPINNER();
        })
        .catch(() => this.HIDE_SPINNER());
    },

    resetForm() {
      Object.assign(this.$data, initialState);
    }
  }
};
</script>
