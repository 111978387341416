// TODO: need to rename file to types.js only
// TODO: remove all single modules and use namespaced objects instead
// TODO dan: using vuex to maintain state for search is not necessary. The state can be local and to <product-category-overview> and passed down to components that need it (there are no siblings that should share the state). All catalog search-related logic in the vuex store should be refactored and made local.

export const SHOW_ALERT = 'SHOW_ALERT';

export const RELOAD_PAGE = 'RELOAD_PAGE';

export const STORE_TO_CART = 'STORE_TO_CART';

export const UPDATE_CART = 'UPDATE_CART';

export const APPLY_DISCOUNT = 'APPLY_DISCOUNT_CODE';

export const ADD_COMPARE = 'ADD_COMPARE';
export const REM_COMPARE = 'REM_COMPARE';

export const ADD_FACET = 'ADD_FACET';
export const REM_FACET = 'REM_FACET';
export const UPD_FACET = 'UPD_FACET';
export const REM_FACETS = 'REM_FACETS';

export const MODAL = 'MODAL';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SET_PRICEPLAN = 'SET_PRICEPLAN';
export const SET_PAGE = 'SET_PAGE';
export const RESET_PAGE = 'RESET_PAGE';
export const REMOVE_SIZE = 'REMOVE_SIZE';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const WAIT = 'WAIT';

export const UPDATE_PRICES = 'UPDATE_PRICES';
export const UPDATE_MINIMUM_PRICE = 'UPDATE_MINIMUM_PRICE';
export const UPDATE_BAN = 'UPDATE_BAN';
export const UPDATE_BAN_SELECTOR_ENABLED = 'UPDATE_BAN_SELECTOR_ENABLED';

// TODO: align naming
export const ACTIONS = {
  GO_TO_CHECKOUT_FLOW:
    'gotoCheckoutFlow' /* TODO: how is this a state action */,
  RESET_FACET_GROUP: 'resetFacetGroup',
  RESET_ALL_FACET_GROUP:
    'resetAllFacetGroups' /* TODO: should be an argument for prev action */,
  UPDATE_SHIPPING_METHOD: 'updateShippingMethod',
  SELECT_FACET: 'selectFacet',
  SELECT_PRICE_PLAN: 'selectPricePlan',
  SEARCH_PRODUCTS: 'searchProducts',
  SET_COMPARE_ITEM: 'setCompareItem' /* TODO: this is a mutation... */,
  UPDATE_PRICES: 'updatePrices',
  TRACK_PRODUCT: 'trackProduct',
  ADD_ACTIVATE_SIM: 'addActivateSim',
  CHANGE_QUANTITY: 'changeQuantityb',
  FETCH_PICKUP_POINTS: 'FETCH_PICKUP_POINTS',
  SET_FACET_STATE: 'actions/SET_FACET_STATE',
  FETCH_MINIMUM_PRICE_DETAILS:
    'getMinimumPrice' /* TODO: add namespace and rename*/,
  FETCH_BASKET_PRICE_DETAILS: 'actions/FETCH_BASKET_PRICE_DETAILS',
  WAIT,
  FETCH_BASKET_PRICE_SUMMARY: 'actions/FETCH_BASKET_PRICE_SUMMARY'
};

export const MUTATIONS = {
  UPDATE_MINI_BASKET: 'mutations/UPDATE_MINI_BASKET',
  UPDATE_CART: 'UPDATE_CART',
  SHOW_DIALOG: 'SHOW_DIALOG',
  HIDE_DIALOG: 'HIDE_DIALOG',
  SHOW_SPINNER: 'SHOW_SPINNER',
  HIDE_SPINNER: 'HIDE_SPINNER',
  RELOAD_PAGE: 'RELOAD_PAGE' /* TODO: remove - this is not state mutation... */,
  SHOW_ALERT: 'SHOW_ALERT',
  STORE_TO_CART: 'STORE_TO_CART',
  ADD_FACET: 'ADD_FACET' /* TODO: refactor */,
  UPD_FACET: 'UPD_FACET' /* TODO: refactor */,
  REM_FACET: 'REM_FACET' /* TODO: refactor */,
  REM_FACETS: 'REM_FACETS' /* TODO: refactor */,
  SET_PRICEPLAN: 'SET_PRICEPLAN',
  SET_PAGE: 'SET_PAGE',
  RESET_PAGE: 'RESET_PAGE',
  REMOVE_SIZE: 'REMOVE_SIZE',
  SEARCH_PRODUCTS: 'SEARCH_PRODUCTS' /* TODO: how is this a mutation? */,
  REM_COMPARE: 'REM_COMPARE',
  ADD_COMPARE: 'ADD_COMPARE',
  UPDATE_PRICES: 'UPDATE_PRICES',
  SET_FACET_STATE: 'mutations/SET_FACET_STATE',
  /* TODO: create separate basket module */
  SET_ORDER_NUMER: 'mutations/SET_ORDER_NUMER',
  SET_MINIMUM_PRICE_DETAILS: 'mutations/SET_MINIMUM_PRICE_DETAILS',
  SET_BASKET_PRICE_DETAILS: 'mutations/SET_BASKET_PRICES_DETAILS',
  SET_BASKET_PRICE_NETWORKING: 'mutations/SET_BASKET_PRICES_NETWORKING',
  SET_BASKET_PRICE_SUMMARY: 'mutations/SET_BASKET_PRICES_SUMMARY',
  SET_BASKET_PRICE_SUMMARY_NETWORKING:
    'mutations/SET_BASKET_PRICE_SUMMARY_NETWORKING'
};
