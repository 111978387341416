var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c(
        "c-card",
        { attrs: { "extra-padding": "", "no-shadow": "", "no-rounding": "" } },
        [
          _c("employee-config-carousel", {
            attrs: { content: _vm.content },
            on: { selected: _vm.setSelectedOffering }
          }),
          _vm._v(" "),
          _vm.form.order && _vm.form.showPlug
            ? _c("employee-config-plug", {
                attrs: {
                  content: _vm.content,
                  offering: _vm.form.selectedOffering
                },
                on: { update: _vm.updateFormData }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.order && _vm.form.showSbbu
            ? _c("employee-config-poa", {
                attrs: {
                  content: _vm.content,
                  offering: _vm.form.selectedOffering
                },
                on: { update: _vm.updateFormData }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.order
            ? _c("employee-config-datepicker", {
                attrs: {
                  content: _vm.content,
                  "has-cancellation-assistance-option":
                    _vm.form.cancelationAssist,
                  offering: _vm.form.selectedOffering
                },
                on: { update: _vm.updateFormData }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("home-office-validation-modal", {
        attrs: {
          title: _vm.content.validationErrorModalText.heading,
          "error-message": _vm.content.validationErrorModalText.content,
          visible: _vm.showCheckoutError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }