<template>
  <div id="c-toast-manager">
    <div class="top-left">
      <portal-target name="top-left" slim />
    </div>

    <div class="top-right">
      <portal-target name="top-right" slim />
    </div>
    <div class="bottom-right">
      <portal-target name="bottom-right" slim />
    </div>
    <div class="bottom-left">
      <portal-target name="bottom-left" slim />
    </div>
    <c-toast
      v-for="toast in toastCollection"
      :key="toast.id"
      position="TOP_RIGHT"
      :state="toast.state"
      :index="toast.id"
      visible
      :duration="toast.duration"
    >
      {{ toast.title }}
      <template slot="paragraph">
        {{ toast.paragraph }}
      </template>
    </c-toast>
  </div>
</template>
<script>
export default {
  name: 'CToastManager',

  data() {
    return {
      toastCollection: []
    };
  },

  mounted() {
    this.$root.$on('add-toast', this.addToast);
    this.$root.$on('remove-toast', this.removeToast);
  },

  methods: {
    addToast(toast) {
      this.toastCollection.push(toast);
    },
    removeToast(uniqueKey) {
      const index = this.toastCollection
        .map(x => {
          return x.id;
        })
        .indexOf(uniqueKey);
      this.toastCollection.splice(index, 1);
    }
  }
};
</script>
<style lang="scss">
// TODO TC-4916: areas (apart from top-left) have issues.
#c-toast-manager {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > div {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.top-left,
    &.bottom-left {
      justify-content: flex-start;
    }

    &.top-right,
    &.bottom-right {
      align-items: flex-end;
      justify-content: flex-end;
    }

    &.bottom-right,
    &.bottom-left {
      align-items: flex-end;
      height: 100%;
    }

    & > * {
      pointer-events: auto;
    }
  }
}
</style>
