<template>
  <div class="c-compare__icon-row">
    <template
      v-for="(productValues, productValuesIdx) in productPropertyValues"
    >
      <div :key="productValuesIdx" class="c-compare__icon-row--item">
        <c-icon :symbol-id="specificationLabel.icon" />
        <template v-if="specificationLabel.iconProperties">
          <div
            v-for="(line, lineIdx) in iconProperties(productValues)"
            :key="lineIdx"
            class="c-typography-body-sm"
            v-html="line"
          ></div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { populateContentProperty } from 'olympus/mixins/content-property-mixin';
export default {
  name: 'CCompareIconRow',

  props: {
    specificationLabel: {
      type: Object,
      required: true
    },
    productPropertyValues: {
      type: Array,
      required: true
    }
  },

  methods: {
    iconProperties(productValues) {
      return populateContentProperty(
        this.specificationLabel.iconProperties,
        productValues
      ).split('\n');
    }
  }
};
</script>
<style lang="scss">
@import 'theme/sass/settings/_settings.colors.scss';
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'src/shared/styles/settings/_settings.media-query.scss';
@import 'src/shared/styles/tools/_tools.media-query.scss';
.c-compare__icon-row {
  display: flex;
  gap: $u-400;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: $u-300 0 $u-500;
  padding: 0 $u-400;
  &--item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: $u-150;
    svg {
      height: 128px;
      width: 128px;
      margin-bottom: $u-150;
      @include mq($screen-md, max) {
        height: 92px;
        width: 92px;
      }
    }
  }
}
</style>
