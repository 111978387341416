var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-quick-links" }, [
    _c("div", { staticClass: "c-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "c-quick-links__list",
          class: { "c-quick-links__stacked-list": _vm.stack }
        },
        _vm._l(_vm.items, function(item, itemIdx) {
          return _c(
            "a",
            {
              key: itemIdx,
              staticClass: "c-quick-links__item",
              attrs: { href: item.url }
            },
            [
              _c(
                "div",
                { staticClass: "c-quick-links__icon-container" },
                [
                  _c("c-icon", {
                    staticClass: "icon-calendar",
                    attrs: { slot: "icon-calendar", "symbol-id": item.iconId },
                    slot: "icon-calendar"
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "c-quick-links__link-text" }, [
                _vm._v("\n          " + _vm._s(item.name) + "\n          "),
                _c("span")
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }