var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidden
    ? _c(
        "portal",
        { attrs: { to: _vm.container } },
        [
          _c(
            "a-slide",
            {
              attrs: {
                direction: _vm.direction,
                offset: "30px",
                "use-opacity": true
              },
              on: {
                "after-leave": function($event) {
                  return _vm.onAfterLeave($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isVisible,
                      expression: "isVisible"
                    }
                  ],
                  staticClass: "c-toast"
                },
                [
                  _c("div", { staticClass: "c-toast__container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "c-toast__container-icon",
                        class: _vm.toastState.iconClass
                      },
                      [
                        _c("c-icon", {
                          attrs: { "symbol-id": _vm.toastState.iconType }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "c-toast__container-text" }, [
                      _c(
                        "div",
                        { staticClass: "c-toast__header" },
                        [_vm._t("default")],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "c-toast__paragraph" },
                        [_vm._t("paragraph")],
                        2
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  !_vm.showCloseButton
                    ? _c(
                        "button",
                        { on: { click: _vm.hide } },
                        [
                          _vm._t("close", [
                            _c("c-icon", {
                              attrs: { "symbol-id": "menu-close" }
                            })
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }