var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-compare__specification-list-row" },
    _vm._l(_vm.specificationList, function(item, itemIdx) {
      return _c(
        "div",
        {
          key: itemIdx,
          staticClass: "c-compare__specification-list-row--item"
        },
        [
          _c("h5", { staticClass: "c-typography-body-sm" }, [
            _vm._v("\n      " + _vm._s(item.propertyLabel) + "\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-compare__specification-list-row--values" },
            _vm._l(_vm.productPropertyValues, function(
              productPropertyValue,
              productPropertyValueIdx
            ) {
              return _c(
                "div",
                {
                  key: productPropertyValueIdx,
                  staticClass: "c-typography-body-sm"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.productPropertyValues[productPropertyValueIdx][
                          item.property
                        ] || "-"
                      ) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }