<template>
  <c-modal id="edcModal" ref="edcModal" gray centered>
    <div class="extra-data-card-overlay">
      <c-heading
        slot="header"
        class="extra-data-card-overlay__title text-center"
        level="2"
        as="1"
      >
        {{ title }}
      </c-heading>
      <div
        class="extra-data-card-overlay__description"
        v-html="content.extraDataCardDescriptionText"
      ></div>
      <product-card
        v-if="product.productImageUrl"
        :product="product"
        :group-id="group.id"
        :compare-disabled="true"
        :add-to-basket-button-text="content.extraDataCardButtonText"
        :add-to-basket-button-enabled="true"
        :add-to-basket-button-click-event="true"
        :meta="[
          {
            property: 'Description',
            textTemplate: ''
          }
        ]"
        @update="handleProductStateUpdate"
      ></product-card>
    </div>
  </c-modal>
</template>

<script>
import { CHeading } from 'olympus/components';
import ProductCard from '../catalog/product-card/product-card.vue';

import RootBusMixin from 'olympus/mixins/root-bus-mixin.js';
import { populateContentProperty } from 'olympus/mixins/content-property-mixin.js';

import { BASKET_ITEM_TYPES } from './basket-constants.js';

export default {
  name: 'add-edc-overlay',

  components: {
    CHeading,
    ProductCard
  },

  mixins: [RootBusMixin],

  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      group: {},
      data: {}
    };
  },

  computed: {
    subscriptionName() {
      return this.group?.items?.find(
        x => x.type === BASKET_ITEM_TYPES.SUBSCRIPTION
      )?.name?.display;
    },

    title() {
      return (
        this.content.extraDataCardHeadlineText &&
        populateContentProperty(this.content.extraDataCardHeadlineText, {
          subscriptionName: this.subscriptionName
        })
      );
    },

    product() {
      return {
        availableColors: [],
        code: this.data?.code,
        description: this.data?.shortDescription,
        installmentPeriod: this.data?.bindingPeriod,
        monthlyPrice: this.data?.price?.formattedValueCurrency,
        priceDisclaimerText: this.data?.price?.disclaimerText,
        productBrand: this.data?.brand,
        productImageUrl: this.data?.image?.src,
        productName: this.data?.name?.display,
        ribbonText: this.content.extraDataCardRibbonLabel,
        type: 'Subscription'
      };
    }
  },

  mounted() {
    this.$root.$on('add-edc-overlay', this.openEdcModal);
  },

  methods: {
    openEdcModal({ group, data }) {
      this.group = group;
      this.data = data;
      this.$refs.edcModal.show();
    },
    closeEdcModal() {
      this.$refs.edcModal.hide();
    },
    handleProductStateUpdate({ networking }) {
      if (networking) {
        this.emitOnRoot('v::modal::show::spinner', 'edcModal');
      } else {
        this.emitOnRoot('v::modal::hide::spinner', 'edcModal');
        this.$refs.edcModal.hide();
      }
    }
  }
};
</script>

<style lang="scss">
@import 'theme/sass/settings/_settings.vars.scss';
@import 'theme/sass/settings/_settings.global.scss';
@import 'theme/sass/settings/_settings.colors.scss';

$content-width: 384px;

.extra-data-card-overlay {
  margin: 0 auto;
  max-width: $content-width;
  text-align: center;

  &__title {
    font-family: $global-font-family-2;
    margin-bottom: $u-150;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: $u-800;
  }

  .cm-c-product-card-simple {
    margin-left: 0;
    text-align: initial;
  }
}
</style>
