import { render, staticRenderFns } from "./number-list.vue?vue&type=template&id=49e446e6&"
import script from "./number-list.vue?vue&type=script&lang=js&"
export * from "./number-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\olympus\\olympus\\src\\Olympus.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49e446e6')) {
      api.createRecord('49e446e6', component.options)
    } else {
      api.reload('49e446e6', component.options)
    }
    module.hot.accept("./number-list.vue?vue&type=template&id=49e446e6&", function () {
      api.rerender('49e446e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/webshop/shared/components/number-configuration/number-list.vue"
export default component.exports