<template>
  <div class="cm-c-form">
    <c-card
      ref="fieldset"
      :key="`numbermove${keyToReRender}`"
      class="cm-numcon"
    >
      <section class="cm-numcon__config">
        <div class="cm-numcon__product">
          <div class="cm-numcon__product__info">
            <img
              :src="numberConfigActiveProduct.img"
              class="cm-numcon__image"
              :alt="numberConfigActiveProduct.subscriptionName"
            />
            <section class="cm-numcon__product__text">
              <p
                v-if="numberConfigVM.subscriptionLabel"
                class="cm-numcon__product__pre-label"
              >
                {{ numberConfigVM.subscriptionLabel }}
              </p>

              <h2
                v-if="numberConfigActiveProduct.subscriptionName"
                class="
                  cm-numcon__product__header
                  cm-numcon__product__header--specific
                "
              >
                {{ numberConfigActiveProduct.subscriptionName }}
              </h2>
              <div
                v-if="numberConfigActiveProduct.handsetName"
                class="cm-numcon__product__header"
              >
                {{ numberConfigActiveProduct.handsetName }}
              </div>
            </section>
          </div>
        </div>

        <div class="cm-numcon__options">
          <div
            class="cm-numcon__options__item cm-numcon__options__item--active"
          >
            <div
              class="cm-numcon__options__link cm-numcon__options__link--default"
            >
              <div class="cm-numcon__options__icon">
                <c-icon symbol-id="service-switch" />
              </div>
              <span class="cm-numcon__options__header">
                <strong>{{ numberConfigVM.moveNumberOverviewHeadline }}</strong>
              </span>
            </div>

            <div class="cm-numcon__options__content">
              <number-subinfo
                v-if="isEnterprise"
                :key="`subinfo${keyToReRender}`"
                :active-product="$data"
              />

              <number-simcard-info
                v-if="isEnterprise"
                :key="`simcardinfo${keyToReRender}`"
                :active-product="$data"
              />

              <c-row
                v-if="numberConfigVM.moveNumberDescription"
                class="cm-c-form__row"
              >
                <c-col class="cm-c-form__col">
                  <h3 class="cm-c-form__header">
                    {{ numberConfigVM.moveNumberDescription }}
                  </h3>
                </c-col>
              </c-row>
              <c-row class="cm-c-form__row">
                <c-col class="cm-c-form__col">
                  <c-input-group
                    v-model="phoneNumber"
                    type="tel"
                    :input-error="phoneNumberError"
                    :place-holder-label="
                      numberConfigVM.numberToMovePlaceholderText
                    "
                  ></c-input-group>
                </c-col>
              </c-row>
              <c-row class="cm-c-form__row">
                <c-col class="cm-c-form__col">
                  <c-input-group
                    v-model.trim="$v.sim.$model"
                    :disabled="simUnavailable"
                    :input-error="simError"
                    :place-holder-label="
                      numberConfigVM.simNumberPlaceholderText
                    "
                  ></c-input-group>
                </c-col>
              </c-row>
              <c-row class="cm-c-form__row">
                <c-col class="cm-c-form__col">
                  <h3 class="cm-c-form__header">
                    {{ numberConfigVM.simNumberDescription }}
                  </h3>
                  <p class="cm-c-checkout__textblock">
                    <a
                      class="cm-c-checkout__link"
                      @click="
                        SHOW_DIALOG({
                          componentName: 'content-dialog',
                          componentProps: {
                            contentObject: {
                              headline:
                                numberConfigVM.howToFindSimNumberHeadline,
                              description:
                                numberConfigVM.howToFindSimNumberDescription,
                              image: numberConfigVM.dialogImageUrl,
                              buttonLabel:
                                numberConfigVM.howToFindSimNumberButtonText
                            }
                          }
                        })
                      "
                    >
                      {{ numberConfigVM.howToFindSimNumberHeadline }}
                    </a>
                  </p>
                </c-col>
              </c-row>

              <c-row class="cm-c-form__row mb-4">
                <c-col class="cm-c-form__col">
                  <c-checkbox
                    id="sim-number-unavailable"
                    v-model="simUnavailable"
                    :checked="simUnavailable"
                    name="simNumberUnavailable"
                    styled-checkbox
                    @event-id="toggleSimNumberUnavailable()"
                  >
                    {{ numberConfigVM.noSimNumberAvailableLabel }}
                  </c-checkbox>
                </c-col>
              </c-row>

              <c-row class="cm-c-form__row">
                <c-col class="cm-c-form__col">
                  <h3 class="cm-c-form__header">
                    {{ numberConfigVM.moveNumberDateHeadline }}
                  </h3>
                </c-col>
              </c-row>
              <c-row class="cm-c-form__row">
                <c-col class="cm-c-form__col">
                  <c-form-radio-group
                    v-model="enableAsap"
                    name="moveTime"
                    stacked
                    @input="toggleDate"
                  >
                    <c-form-radio id="binding" checked-border value="binding">
                      <span>{{ numberConfigVM.bindingPeriodLabel }}</span>
                    </c-form-radio>
                    <c-form-radio id="calendar" checked-border value="calendar">
                      <span>{{
                        numberConfigVM.moveNumberDateDescription
                      }}</span>
                    </c-form-radio>
                  </c-form-radio-group>
                </c-col>
              </c-row>
              <c-row class="cm-c-form__row mb-4">
                <c-col class="cm-c-form__col">
                  <date-picker
                    v-cloak
                    date-locale="da-DK"
                    :date-selected="moveNumberDate"
                    :disable-before="getMinimumDatePickerDate()"
                    :disable-range="getDisabledDateRange()"
                    input-classes="cm-c-form-control cm-c-form-control--filled cm-js-material-input"
                    :input-value="
                      isMoveNumberAfterCurrentBinding
                        ? numberConfigVM.bindingEndPlaceholder
                        : setInputDate()
                    "
                    :disabled="!enableCalendar"
                    @setDate="setMoveDate"
                  >
                    <c-icon
                      slot="icon"
                      class="datepicker__icon"
                      symbol-id="arrow-down"
                    />
                    <label
                      slot="label"
                      class="cm-c-form-control__label datepicker__label--ie"
                    >
                      {{ numberConfigVM.moveNumberDateLabel }}
                    </label>
                  </date-picker>
                </c-col>
              </c-row>

              <number-poa :key="`poa${keyToReRender}`" :product="$data" />

              <number-simcard-info
                v-if="
                  IS_THIRD_PARTY_RESELLER &&
                  numberConfigVM.showSimDeliveryOptions
                "
                :key="`simcardinfo${keyToReRender}`"
                :active-product="$data"
              />

              <number-optional
                :key="`optional${keyToReRender}`"
                @hiddenNumber="setHiddenNumber"
                @unlistedNumber="setUnlistedNumber"
              >
              </number-optional>
            </div>
          </div>
        </div>

        <number-consents
          v-if="numberConfigVM.enableNumberConfigurationConsents"
          :key="`consent${keyToReRender}`"
          :active-product="$data"
        />
      </section>
    </c-card>
    <div class="cm-numcon__actions">
      <c-button to="/" variant="secondary">Tilbage</c-button>
      <c-button :disabled="disableNumberPorting || !ready" @click="accept">
        {{ numberConfigVM.moveNumberButtonText }}
      </c-button>
    </div>
  </div>
</template>

<script>
import numberConfigMixin from './numberConfigMixin.js';
import DatePicker from '../../../shared/components/date-picker.vue';
import NumberOptional from './number-optional.vue';
import NumberPoa from './number-poa.vue';
import NumberSubinfo from './number-subinfo.vue';
import NumberSimcardInfo from './number-simcard-info.vue';
import NumberConsents from './number-consents.vue';
import CCard from 'webshop/components/c-card/c-card.vue';
import CInputGroup from 'webshop/components/c-input/c-input-group.vue';
import {
  CRow,
  CCol,
  CCheckbox,
  CFormRadio,
  CFormRadioGroup,
  CButton
} from 'olympus/components/index.js';

import {
  required,
  requiredIf,
  requiredUnless,
  numeric,
  minLength,
  maxLength,
  email,
  sameAs
} from 'vuelidate/lib/validators';
import { dkPhoneNumber } from 'olympus/utils/customFormValidators.js';

export default {
  name: 'NumberMove',

  components: {
    DatePicker,
    NumberOptional,
    NumberPoa,
    NumberSubinfo,
    NumberSimcardInfo,
    NumberConsents,
    CCard,
    CInputGroup,
    CRow,
    CCol,
    CCheckbox,
    CFormRadio,
    CFormRadioGroup,
    CButton
  },

  mixins: [numberConfigMixin],

  data() {
    return {
      enableCalendar: false
    };
  },

  computed: {
    phoneNumberError() {
      return this.$v.phoneNumber.$invalid && this.$v.phoneNumber.$model.length
        ? this.numberConfigVM.errorMessageNumberNotValidMoveNumber
        : '';
    },

    simError() {
      return this.$v.sim.$invalid && this.$v.sim.$model.length
        ? this.numberConfigVM.errorMessageSimNumberInvalid
        : '';
    },

    ready() {
      return !this.$v.$invalid;
    }
  },

  validations: {
    phoneNumber: {
      required,
      numeric,
      dkPhoneNumber,
      minLength: minLength(8),
      maxLength: maxLength(8)
    },
    sim: {
      required: requiredUnless('simUnavailable'),
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(20)
    },
    subscriptionReceiverFirstName: {
      required: requiredIf(function () {
        return this.isEnterprise; // eslint-disable-line no-invalid-this
      })
    },
    subscriptionReceiverLastName: {
      required: requiredIf(function () {
        return this.isEnterprise; // eslint-disable-line no-invalid-this
      })
    },
    subscriptionReceiverEmail: {
      required: requiredIf(function () {
        return this.isEnterprise; // eslint-disable-line no-invalid-this
      }),
      email
    },
    subscriptionReceiverRepeatedEmail: {
      required: requiredIf(function () {
        return this.isEnterprise; // eslint-disable-line no-invalid-this
      }),
      email,
      sameAsEmail: sameAs('subscriptionReceiverRepeatedEmail')
    },
    activateSimCardNumber: {
      required: requiredIf(function () {
        return this.isEnterprise && this.productObj.activateSimCard; // eslint-disable-line no-invalid-this
      }),
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(20)
    },
    b2BCvr: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      }),
      numeric,
      minLength: minLength(8),
      maxLength: maxLength(8)
    },
    b2BOrganization: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BAddress: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BZipCode: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    b2BCity: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    },
    allowEpoa: {
      required: requiredIf(function () {
        return this.isB2BCustomer; // eslint-disable-line no-invalid-this
      })
    }
  },

  watch: {
    phoneNumber() {
      if (
        this.numberConfigVM.validateNumberPorting &&
        this.configType === this.$route.params.configType &&
        this.phoneNumber &&
        this.phoneNumber.length === 8
      ) {
        this.SHOW_SPINNER();

        (async () => {
          let url = `${this.numberConfigVM.apiNumberStatus}&phonenumber=${this.phoneNumber}`;
          const response = await fetch(url, { cache: 'no-cache' });
          const content = await response.json();

          if (content) {
            if (
              content.isActive ||
              content.isReserved ||
              content.isPortingInProgress
            ) {
              this.disableNumberPorting = true;

              let errorHeadline = '';
              let errorMessage = '';

              if (content.isActive) {
                errorHeadline =
                  this.numberConfigVM.existingActiveNumberErrorHeadline;
                errorMessage =
                  this.numberConfigVM.existingActiveNumberErrorMessage;
              } else if (content.isReserved && !content.isPortingInProgress) {
                errorHeadline = this.numberConfigVM.reservedNumberErrorHeadline;
                errorMessage = this.numberConfigVM.reservedNumberErrorMessage;
              } else if (content.isPortingInProgress) {
                errorHeadline =
                  this.numberConfigVM.existingNumberPortingErrorHeadline;
                if (content.orderId) {
                  errorMessage = this.populateContentProperty(
                    this.numberConfigVM
                      .existingNumberPortingErrorMessageWithDetails,
                    { orderId: content.orderId, msisdn: this.phoneNumber }
                  );
                } else {
                  errorMessage =
                    this.numberConfigVM.existingNumberPortingErrorMessage;
                }
              }

              this.SHOW_DIALOG({
                componentName: 'content-dialog',
                componentProps: {
                  contentObject: {
                    headline: errorHeadline,
                    description: errorMessage,
                    buttonLabel: this.numberConfigVM.errorMessageButtonText
                  }
                }
              });
            } else {
              this.disableNumberPorting = false;
            }
          }
          this.HIDE_SPINNER();
        })();
      }
    }
  },

  created: function () {
    this.previousMoveNumberDate = 0;
  },

  methods: {
    toggleSimNumberUnavailable() {
      this.simUnavailable = !this.simUnavailable;
    },

    getDisabledDateRange() {
      return (
        this.numberConfigVM.disabledDates &&
        this.numberConfigVM.disabledDates.map(date => {
          return new Date(date).getTime();
        })
      );
    },
    setInputDate() {
      const date = new Date(this.moveNumberDate);
      const month = date.getMonth() + 1;
      return `${date.getDate()}/${month}/${date.getFullYear()}`;
    },
    setMoveDate(date) {
      if (!this.isMoveNumberAfterCurrentBinding) {
        this.useFallbackText = false;
        const [day, month, year] = date.split('/');
        const moveDate = new Date(`${month}/${day}/${year}`);
        /* Adjusting for danish timezone */
        this.moveNumberDate = moveDate.getTime() - this.timeZoneOffset();
        this.moveNumberDateString = `${year}-${month.padStart(
          2,
          '0'
        )}-${day.padStart(2, '0')}`;
      }
    },
    /*
     * TODO DKT-10800: This could be done WAY better (e.g. using computed properties and a v-model on the radio buttons)
     * However, this is necessary because we set the whole '$data' object from the mixin
     * based on data from BE. This requires a larger refactor to fix this properly.
     */
    toggleDate(event) {
      this.enableCalendar = Boolean(event === 'calendar');
      this.setMoveAfterCurrentBinding(Boolean(event === 'binding'));
    }
  }
};
</script>
