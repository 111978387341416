/* eslint-disable */ // Todo: Remove this line later
import { createNamespace } from '../utils.js';

import { MUTATIONS as COMMON_MUTATIONS } from '../shop/mutation-types.js';

import {
  getPickupPoints,
  getPickupPointsByCoordinates,
  setShippingMethod,
  setPhoneNumber
} from 'webshop/api/checkout-service.js';

export const MUTATIONS = createNamespace('SHIPPING/MUTATIONS', [
  'SET_PICKUP_POINTS',
  'SET_SHIPPING_METHOD'
]);

export const ACTIONS = createNamespace('SHIPPING/ACTIONS', [
  'FETCH_PICKUP_POINTS',
  'FETCH_PICKUP_POINTS_BY_COORDINATES',
  'UPDATE_SHIPPING_METHOD',
  'SET_PHONE_NUMBER'
]);

export const GETTERS = createNamespace('SHIPPING/GETTERS', [
  'GET_PICKUP_POINTS'
]);

/**
 * Manages state for shipping block.
 */
export default {
  state: {
    pickupPoints: [],
    shippingMethod: {
      blockId: '',
      deliveryLocationCode: null,
      //ignore unless selected otherwise
      ignorePickupPoint: true,
      shippingMethod: '',
      name: ''
    }
  },

  mutations: {
    [MUTATIONS.SET_PICKUP_POINTS](state, pickupPoints) {
      state.pickupPoints = pickupPoints;
    },
    [MUTATIONS.SET_SHIPPING_METHOD](state, shippingMethod) {
      state.shippingMethod = { ...state.shippingMethod, ...shippingMethod };
    }
  },

  actions: {
    [ACTIONS.FETCH_PICKUP_POINTS]: async ({ commit }, params) => {
      commit(COMMON_MUTATIONS.SHOW_SPINNER);
      const { data } = await getPickupPoints(params);
      commit(MUTATIONS.SET_PICKUP_POINTS, data.data);
      commit(COMMON_MUTATIONS.HIDE_SPINNER);
    },

    [ACTIONS.FETCH_PICKUP_POINTS_BY_COORDINATES]: async (
      { commit, dispatch },
      params
    ) => {
      commit(COMMON_MUTATIONS.SHOW_SPINNER);
      const { data } = await getPickupPointsByCoordinates(params);

      if (!data) {
        // Add error handling
        return;
      }

      dispatch(ACTIONS.FETCH_PICKUP_POINTS, {
        street: data.data[0]?.attributes?.streetName,
        houseNumber: data.data[0]?.attributes?.houseNumber,
        postCode: data.data[0]?.attributes?.postalCode,
        includeOpeningHours: true
      });
    },

    [ACTIONS.UPDATE_SHIPPING_METHOD]: async ({ commit }, params) => {
      commit(MUTATIONS.SET_SHIPPING_METHOD, params);
      await setShippingMethod(params);
    },

    [ACTIONS.SET_PHONE_NUMBER]: async ({}, params) => {
      await setPhoneNumber(params);
    }
  },

  getters: {
    [GETTERS.GET_PICKUP_POINTS]: state => {
      return state.pickupPoints;
    }
  }
};
